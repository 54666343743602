import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	Account,
	AggregateBrokerageAccountDTO,
	Count,
	AccountFilterDTO,
	AccountSortByDTO,
	Query_AccountsArgs,
} from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

type Query_AccountsWithAggregateArgs = {
	limit?: number | undefined;
	offset?: number | undefined;
	orderBy?: AccountSortByDTO | undefined;
	scopeId?: string | undefined;
	sortBy?: AccountSortByDTO | undefined;
	where?: AccountFilterDTO | undefined;
	aggregateWhere?: AccountFilterDTO | undefined;
};

export const GET_BROKERAGE_ACCOUNTS = gql`
	query BrokerageAccounts(
		$limit: Float
		$offset: Float
		$orderBy: AccountSortByDTO
		$where: AccountFilterDTO
		$aggregateWhere: AccountFilterDTO
	) {
		Accounts(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
			id
			name
			accountNumber
			hasWriteAccess
			Customer {
				name
			}
			... on BrokerageAccount {
				marginExcessDeficit
				liquidatingValue
				parentAccountId
				isParentAccount
				currentBalance
				FCM {
					id
					name
				}
			}
			... on SwapAccount {
				SwapDealer {
					id
					name
				}
			}
		}
		AccountCount(where: $where) {
			count
		}
		AggregateBrokerageAccounts(
			where: { AsAccount: $aggregateWhere }
			calc: { sum: { liquidatingValue: true, currentBalance: true } }
			groupBy: {}
		) {
			sum {
				liquidatingValue
				currentBalance
			}
		}
	}
`;

type GetBrokerageAccounts = {
	__typename?: 'Query';

	AccountCount: Count;
	Accounts: Array<Account>;
	AggregateBrokerageAccounts: Array<AggregateBrokerageAccountDTO>;
};

export default class AccountsIndexRoute extends Route {
	@service applicationScope: any;
	@tracked variables: Query_AccountsArgs | Query_AccountsWithAggregateArgs = {};

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
	};

	templateName = 'accounts/index';

	getBrokerageAccounts = useQuery<GetBrokerageAccounts, Query_AccountsArgs>(this, () => [
		GET_BROKERAGE_ACCOUNTS,
		{ variables: this.variables },
	]);

	query: any = GET_BROKERAGE_ACCOUNTS;

	generateWhere(organizationId: null | string, customerId: undefined | null | string): AccountFilterDTO {
		const where: AccountFilterDTO = {};

		if (customerId) {
			where.customerId = { equals: customerId };
			return where;
		}
		where.OR = [
			{
				Customer: { organizationId: { equals: organizationId } },
			},
			{
				ownerId: { equals: organizationId },
			},
		];

		return where;
	}

	generateAggregateWhere(organizationId: null | string, customerId: undefined | null | string): AccountFilterDTO {
		const where: AccountFilterDTO = {};
		where.isParentAccount = {
			not: {
				equals: true,
			},
		};
		if (customerId) {
			where.customerId = { equals: customerId };
			return where;
		}
		where.OR = [
			{
				Customer: { organizationId: { equals: organizationId } },
			},
			{
				ownerId: { equals: organizationId },
			},
		];

		return where;
	}

	generateOrderBy(sorts: SortObj[]): AccountSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}
}
