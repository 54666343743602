import { guidFor } from '@ember/object/internals';
import {
	Chart,
	ChartDataset,
	ChartData,
	ChartTypeRegistry,
	ScriptableTooltipContext,
	Tick,
	TooltipItem,
	CoreScaleOptions,
	PointElement,
	Scale,
} from 'chart.js';

import { DateTime } from 'luxon';
import { InternalChartType } from 'vault-client/types/vault-client';
import { HistoricalPercentileChartDatasetLabel } from 'vault-client/components/historical-percentile-chart';
import type { PartialEventContext } from 'chartjs-plugin-annotation';
import arraysEqual from './arrays-equal';
import { analyticsCustomTracking } from './analytics-tracking';

type CustomTooltipOptions = {
	titleFormatter?: (val: string | number) => string;
	valueFormatter?: (val: string | number | unknown, dataPoint?: TooltipItem<keyof ChartTypeRegistry>) => string;
	valueExtractor?: (dataPoint: TooltipItem<keyof ChartTypeRegistry>) => number | string | null;
	dataPointFilter?: (dataPoint: TooltipItem<keyof ChartTypeRegistry>, index: number) => boolean;
	hideTitles?: boolean;
	useBorderColorForBorder?: boolean;
};

function getOrCreateTooltip(chart: Chart): HTMLDivElement {
	let tooltipEl: HTMLDivElement | null | undefined = chart.canvas.parentNode?.querySelector('div');
	if (!tooltipEl) {
		tooltipEl = document.createElement('div');
		tooltipEl.classList.add(
			'line-chart-tooltip',
			'line-chart-tooltip-bottom',
			'border',
			'border-brand-blue-80',
			'bg-white',
			'text-brand-gray-90',
			'absolute',
			'pointer-events-none',
			'transform',
			'-translate-x-2/4',
			'transition-all',
			'ease-linear',
			'duration-50',
			'opacity-1',
			'z-10',
			'text-left',
			'p-2',
			'rounded-sm',
		);

		const tooltipUL = document.createElement('ul');
		tooltipUL.classList.add('tooltipul');

		tooltipEl.append(tooltipUL);
		chart.canvas.parentNode?.append(tooltipEl);
	}
	return tooltipEl;
}

function getCustomTooltip(options: CustomTooltipOptions = {}) {
	return function bindedCustomTooltip(this: CustomTooltipOptions, context: ScriptableTooltipContext<keyof ChartTypeRegistry>) {
		const { chart, tooltip } = context;
		const tooltipEl = getOrCreateTooltip(chart);
		const tooltipUL = tooltipEl.querySelector('ul')!;

		if (tooltip.opacity === 0) {
			tooltipEl.classList.replace('opacity-1', 'opacity-0');
			return;
		}

		if (tooltip.body) {
			const titleLines = tooltip.title || [];
			const dataPoints = tooltip.dataPoints;
			const tooltipLI = document.createElement('li');
			tooltipLI.classList.add('whitespace-nowrap');

			// Title Loop
			if (!this.hideTitles) {
				titleLines.forEach((title: string) => {
					tooltipUL.appendChild(tooltipLI);

					const tooltipSpan = document.createElement('span');
					tooltipSpan.classList.add('text-sm', 'font-semibold', 'text-brand-blue-80', 'block');
					tooltipLI.appendChild(tooltipSpan);

					const tooltipTitle = document.createTextNode(this.titleFormatter ? this.titleFormatter(title) : title);
					tooltipSpan.appendChild(tooltipTitle);
				});
			}

			// Body Loop
			const tooltipBodyP = document.createElement('p');
			(this.dataPointFilter ? dataPoints.filter(this.dataPointFilter) : dataPoints).forEach((dataPoint) => {
				const displayBlockSpan = document.createElement('span');
				displayBlockSpan.classList.add('block', 'text-xs');
				const colorCircle = document.createElement('span');
				colorCircle.classList.add('rounded-full', 'w-2', 'h-2', 'inline-block', 'mr-2');
				colorCircle.style.background = (dataPoint.dataset.backgroundColor as string) ?? (dataPoint.dataset.borderColor as string);
				colorCircle.style.border =
					(this.useBorderColorForBorder
						? (dataPoint.dataset.borderColor as string) ?? (dataPoint.dataset.backgroundColor as string)
						: (dataPoint.dataset.backgroundColor as string) ?? (dataPoint.dataset.borderColor as string)) + ' solid 1px';

				let formattedValue;
				if (this.valueExtractor) {
					const extractedValue = this.valueExtractor(dataPoint);
					formattedValue = this.valueFormatter ? this.valueFormatter(extractedValue, dataPoint) : extractedValue;
				} else {
					formattedValue = this.valueFormatter ? this.valueFormatter(dataPoint.raw, dataPoint) : dataPoint.formattedValue;
				}

				const textLabel = document.createTextNode(`${formattedValue} ${dataPoint.dataset.label}`);

				// Append color label and text
				displayBlockSpan.appendChild(colorCircle);
				displayBlockSpan.appendChild(textLabel);
				tooltipBodyP.appendChild(displayBlockSpan);
			});

			// Remove old children
			while (tooltipUL.firstChild) {
				tooltipUL.firstChild.remove();
			}

			// Add new children
			tooltipUL.appendChild(tooltipLI);
			tooltipLI.appendChild(tooltipBodyP);
			tooltipEl.classList.replace('opacity-0', 'opacity-1');

			// Position tooltip
			const position = context.chart.canvas.getBoundingClientRect();
			const { width: tooltipElWidth, height: tooltipElHeight } = tooltipEl.getBoundingClientRect();

			let offsetX = 0;

			if (tooltip.caretX < tooltipElWidth / 3) {
				offsetX = tooltip.caretX + tooltipElWidth / 2 + 12;

				tooltipEl.classList.remove('line-chart-tooltip-bottom', 'line-chart-tooltip-right');
				tooltipEl.classList.add('line-chart-tooltip-left');
			} else if (position.width - tooltip.caretX < tooltipElWidth / 3) {
				offsetX = tooltip.caretX - tooltipElWidth / 2 - 12;

				tooltipEl.classList.remove('line-chart-tooltip-bottom', 'line-chart-tooltip-left');
				tooltipEl.classList.add('line-chart-tooltip-right');
			} else {
				offsetX = tooltip.caretX;
				tooltipEl.classList.remove('line-chart-tooltip-right', 'line-chart-tooltip-left');
				tooltipEl.classList.add('line-chart-tooltip-bottom');
			}

			tooltipEl.style.left = offsetX + 'px';
			tooltipEl.style.top = -tooltipElHeight + 'px';
		}
	}.bind(options);
}

type CustomInnerHTMLFunction<T = ChartDataset> = (
	dataset: T,
	value: string | number | null,
	valueFormatter: ((val: string | number | null) => string) | undefined,
) => string;

function getCustomLegend(
	chartId: string,
	legendId: string,
	valueFormatter?: (val: string | number | null) => string,
	valueExtractor?: (dataset: ChartDataset, index: number) => number | string | null,
	customInnerHTML?: CustomInnerHTMLFunction<ChartDataset<keyof ChartTypeRegistry, unknown>>,
) {
	return function (chart: Chart) {
		function toggleDataset(event: PointerEvent): void {
			const index = (event.currentTarget as HTMLElement).dataset.index;
			if (index) {
				const meta = chart.getDatasetMeta(+index);
				meta.hidden = !meta.hidden ? true : false;
				chart.update('none');
			}
		}

		// Make sure we're applying the legend to the right chart
		if (chart.canvas.id === chartId) {
			const legend = document.getElementById(legendId)!;
			const ul = legend?.querySelector('ul') ?? document.createElement('ul');
			ul.classList.add('flex', 'flex-wrap');

			// Remove old legend items
			while (ul.firstChild) {
				ul.firstChild.remove();
			}

			while (legend.firstChild) {
				legend.firstChild.remove();
			}

			chart.data.datasets.forEach((dataset, index) => {
				const legendItem = document.createElement('li');
				legendItem.classList.add('flex', 'flex-col', 'items-center', 'mr-6', 'mb-3', 'text-brand-blue-80', 'cursor-pointer');

				// Strike out legend item if hidden
				const meta = chart.getDatasetMeta(index);
				if (meta.hidden) {
					legendItem.classList.add('line-through');
				}

				legendItem.dataset.index = index.toString(10);
				legendItem.onclick = toggleDataset;

				const value = valueExtractor ? valueExtractor(dataset, index) : (dataset.data?.[0] as number | string | undefined) ?? null;

				if (customInnerHTML) {
					legendItem.innerHTML += customInnerHTML(dataset, value, valueFormatter);
				} else if (valueFormatter) {
					legendItem.innerHTML += `
					<div class="grid" style="grid-template-columns: auto 1fr;">
						<span
							style="background-color: ${dataset.backgroundColor};
							border: ${dataset.borderColor ?? dataset.backgroundColor} solid 1px"
							class="mr-2 my-auto w-2 h-2 rounded-full inline-block col-span-1 row-span-1">
						</span>
						<span class="col-span-1 row-span-1">${valueFormatter(value)}</span>
						<span class="col-span-1 row-span-1"></span>
						<span class="col-span-1 row-span-1 whitespace-nowrap">${dataset.label}</span>
					</div>
				`;
				} else {
					legendItem.innerHTML += `
					<div class="text-sm flex items-center">
						<span
							style="background-color: ${dataset.backgroundColor};
							border: ${dataset.borderColor ?? dataset.backgroundColor} solid 1px"
							class="w-2 h-2 rounded-full inline-block mr-1">
						</span>
						<span class="whitespace-nowrap">${dataset.label}</span>
					<div/>
				`;
				}

				ul.append(legendItem);
			});

			return legend?.insertBefore(ul, legend.firstChild);
		}
		return;
	};
}

// In mixed bar and line charts, the lines only extend to the middle of the bars due to the offset added to all
// This plugin extends the line to the edges. Intended to only be used with stepped or vertical lines, as the drawn
// line is straight. There might be a better way to accomplish this.
// If there are more data points than labels, the current implementation will not extend the last line
// Set clipping to false in chart to prevent drawn lines from looking thicker when using a min / max
const extendLineChartToEdgesPlugin = {
	id: 'extendLineChartToEdges',
	afterDatasetsDraw: (chart: Chart) => {
		const {
			config,
			chartArea: { left: chartLeft, right: chartRight },
			scales: { x: xScale },
		} = chart;
		const ctx = chart.ctx;

		// Offset is only available on certain scaleOptions, unsure about the best way to narrow without extra complexity
		if (!(xScale.options as any)?.offset) return;

		const visibleMetas = chart.getSortedVisibleDatasetMetas();

		visibleMetas
			.sortBy('order')
			.reverse()
			.forEach((metaset) => {
				// @ts-expect-error property does not exist on type
				const type = metaset.type ?? (config.type as string | undefined);
				if (type === ('line' as const)) {
					const firstPoint = metaset.data.firstObject;
					const lastPoint = metaset.data.lastObject;
					const strokeColor = (metaset.dataset?.options?.borderColor as string | undefined)?.trim();
					const lineWidth = metaset.dataset?.options?.borderWidth as number | undefined;

					if (firstPoint) {
						const [firstStartX, firstStartY] = [firstPoint.x, firstPoint.y];
						const [firstEndX, firstEndY] = [chartLeft, firstPoint.y];

						ctx.save();

						if (strokeColor) {
							ctx.strokeStyle = strokeColor;
						}

						if (lineWidth) {
							ctx.lineWidth = lineWidth;
						}

						ctx.beginPath();
						ctx.moveTo(firstStartX, firstStartY);
						ctx.lineTo(firstEndX, firstEndY);
						ctx.stroke();
						ctx.restore();
					}

					if (lastPoint) {
						const [lastStartX, lastStartY] = [lastPoint.x, lastPoint.y];
						const [lastEndX, lastEndY] = [chartRight, lastPoint.y];

						ctx.save();

						if (strokeColor) {
							ctx.strokeStyle = strokeColor;
						}

						if (lineWidth) {
							ctx.lineWidth = lineWidth;
						}
						ctx.beginPath();
						ctx.moveTo(lastStartX, lastStartY);
						ctx.lineTo(lastEndX, lastEndY);
						ctx.stroke();
						ctx.restore();
					}
				}
			});
	},
};

function downloadChartAsCSV(chartId: string, fileName?: string) {
	const chart = Chart.getChart(chartId);
	if (!chart) {
		console.error(`Could not find chart with id ${chartId}`);
		return;
	}

	const canvas = chart.canvas;
	const internalChartType = (canvas?.dataset?.chartType ?? null) as InternalChartType | null;
	const scaleNames = Object.keys(chart.scales);
	const indexAxis = chart.options.indexAxis ?? 'x';
	const dataAxis = scaleNames.filter((scaleName) => scaleName !== indexAxis)[0] ?? 'y';
	const indexScale = chart.scales?.[indexAxis] ?? {};
	const datasets = chart.data.datasets;
	const dataIncludesIndexValues = datasets.some((dataset) => {
		return dataset.data.some((entry) => typeof entry === 'object' && entry != null && indexAxis in entry);
	});

	// Call build ticks so that we get the ones that may have been autoskipped
	const indexTicks = indexScale.buildTicks();

	// Ticks are not neccessarily created for all values in the data, so we need to generate them manually if
	// index values are included in the data
	const dataGeneratedTicks = dataIncludesIndexValues ? generateTicksFromDataSets(datasets, indexAxis) : [];

	// Use either default or data generated ticks based on if the data includes index values
	const ticks = (dataIncludesIndexValues ? dataGeneratedTicks : indexTicks).sortBy('value');

	//Creates tick labels in place
	indexScale?.generateTickLabels(ticks);

	const headerRow = `,${ticks.map((tick) => `"${Array.isArray(tick.label) ? tick.label.join(' ') : tick.label}"`).join(',')}`;
	const csvRows = [headerRow];

	datasets.forEach((dataset, index) => {
		// Ignore certain data sets, for exmaple current market data in historical percentile charts
		if (!includeDataset(dataset, internalChartType)) return;

		const meta = chart.getDatasetMeta(index);

		if (meta.type === 'boxplot') {
			// This internal property is the only place I could find the parsed values - Preston
			const data = meta._parsed as { min: number; q1: number; median: number; mean: number; q3: number; max: number }[];
			const rowData = data.reduce(
				(acc, point) => {
					[point.min, point.q1, point.median, point.mean, point.q3, point.max].forEach((value, index) => {
						acc[index].push(`"${value}"`);
					});
					return acc;
				},
				[['Min'], ['Q1'], ['Median'], ['Mean'], ['Q3'], ['Max']],
			);

			rowData.forEach((data: string[]) => {
				csvRows.push(data.join(','));
			});
		} else {
			const label = dataset.label;
			const rawData = dataset.data;

			const data = parseData(rawData, dataAxis, indexAxis, ticks);

			const row = `"${label}", ${data.join(',')}`;
			csvRows.push(row);
		}
	});

	//
	//create blob
	//
	const formattedCSVData = csvRows.join('\n');

	const blob = new Blob([formattedCSVData], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	const fileNameRaw = fileName || `${guidFor(chart)}-chart.csv`;
	const fileNameWithExtension = fileNameRaw.includes('.') ? fileNameRaw : fileNameRaw.concat('.csv');

	a.setAttribute('hidden', '');
	a.setAttribute('href', url);
	a.setAttribute('download', fileNameWithExtension);
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);

	// Tell browser that the url can be released
	window.URL.revokeObjectURL(url);

	// Tracks when Chart is Downloaded to CSV
	analyticsCustomTracking(`Chart Downloaded as CSV`, {});

	function parseData(data: unknown[], dataAxis: string, indexAxis: string, ticks: Tick[]): (number | null)[] {
		if (data.length === 0) return [];

		const dataHasObjects = data.some((entry) => typeof entry === 'object' && entry != null && dataAxis in entry);

		if (dataHasObjects) {
			// This logic accomdates instances where objects are passed as data

			return ticks.map((tick) => {
				const indexValue = tick.value;

				for (let i = 0; i < data.length; i++) {
					const entry = data[i] as { [key: string]: number | string | null };

					if (!(indexAxis in entry) || !(dataAxis in entry)) return null;

					if (indexScale.type === 'time') {
						// Compare index values as timestamps
						const entryIndexValue = entry?.[indexAxis] as string;
						const entryTimestamp = entryIndexValue ? DateTime.fromISO(entryIndexValue).toMillis() : null;

						if (indexValue === entryTimestamp) {
							return entry[dataAxis] as number | null;
						}
					} else {
						// Compare index values as numbers (default)
						const entryIndexValue = entry?.[indexAxis] as number;
						if (indexValue === entryIndexValue) {
							return entry[dataAxis] as number | null;
						}
					}
				}

				return null;
			});
		} else {
			return data as (number | null)[];
		}
	}

	function includeDataset(dataset: ChartDataset, chartType: InternalChartType | null): boolean {
		const label = dataset.label;
		if (chartType === InternalChartType.HistoricalPercentile) {
			return label !== HistoricalPercentileChartDatasetLabel.CurrentFuture;
		}

		if (chartType === InternalChartType.HistoricalFuturePrices) {
			return !label || +label < DateTime.local().year;
		}

		return true;
	}

	function generateTicksFromDataSets(datasets: Chart['data']['datasets'], indexAxis: string) {
		const existingTickValues = new Set<number>();
		const ticks: Tick[] = [];

		datasets.forEach((dataset) => {
			const data = dataset.data;
			const datasetTicks = generateTicksFromData(data, indexAxis, existingTickValues);
			ticks.push(...datasetTicks);
		});

		return ticks;
	}

	function generateTicksFromData(data: unknown[], indexAxis: string, existingTickValues: Set<number>): Tick[] {
		const ticks: Tick[] = [];

		data.forEach((entry) => {
			if (typeof entry === 'object' && entry != null && indexAxis in entry) {
				// @ts-ignore
				const indexValue = entry[indexAxis];
				if (indexValue != null && !existingTickValues.has(indexValue)) {
					ticks.push({
						value: indexValue,
					});
					existingTickValues.add(indexValue);
				}
			}
		});

		return ticks;
	}
}

function _calculateLabelXRange(todayXPositionPixels: number, xScale: Scale<CoreScaleOptions>, labelWidth: number = 0) {
	let xMin = 0;

	if (todayXPositionPixels + labelWidth / 2 > xScale.right) {
		xMin = xScale.right - labelWidth;
	} else if (todayXPositionPixels - labelWidth / 2 > 0) {
		xMin = todayXPositionPixels - labelWidth / 2;
	}

	const xMax = xMin + labelWidth;

	return { xMin, xMax };
}

function _pointInRange(
	point: any,
	yScale: Scale<CoreScaleOptions>,
	xScale: Scale<CoreScaleOptions>,
	labelHeight: number,
	xMin: number,
	xMax: number,
	yPixelPosition: number,
	labelYBaseOffsetPixels: number,
) {
	const context = point?.$context; // Chart Js types weren't cooperating, hence the any

	const y = yScale.getPixelForValue(context?.parsed.y ?? 0);

	const x = xScale.getPixelForValue(context?.parsed.x ?? 0);

	if (x < xMin || x > xMax) return false;

	// Check if point is within y axis range
	const yMin = yPixelPosition - labelHeight / 2 > labelYBaseOffsetPixels ? yPixelPosition - labelHeight / 2 : labelYBaseOffsetPixels;
	const yMax = yMin + labelHeight;
	if (y < yMin || y > yMax) {
		return false;
	}

	return true;
}

// ChartJs Annotation Label helper. Automatically vertically positions the
// label to avoid overlapping data points. Intended for use with the "Today" labels (static width and height).
// Returned offset should be passed to yAdjust;
function calculateAutoLabelYOffset(
	ctx: PartialEventContext,
	todayIndex: number,
	labelWidth: number = 60,
	labelHeight: number = 40,
	xScaleId = 'x',
	yScaleId = 'y',
) {
	const chart = ctx.chart as Chart<'line'>;
	const xScale = chart.scales[xScaleId];
	const yScale = chart.scales[yScaleId];
	const yScaleHeightPixels = yScale.height;
	const todayXPositionPixels = xScale.getPixelForValue(todayIndex ?? 0);
	const { xMin, xMax } = _calculateLabelXRange(todayXPositionPixels, xScale, labelWidth);
	const labelYBaseOffsetPixels = yScale.top;
	const metaSets = chart.getSortedVisibleDatasetMetas();

	let blocked = false;
	let labelYOffsetPixels = 0;

	while (labelYBaseOffsetPixels + labelYOffsetPixels < yScaleHeightPixels) {
		const labelYPositionPixels = labelYBaseOffsetPixels + labelYOffsetPixels;
		blocked = false;

		for (const meta of metaSets) {
			const dataPoints = meta.data as unknown as PointElement[];
			for (const point of dataPoints) {
				if (_pointInRange(point, yScale, xScale, labelHeight, xMin, xMax, labelYPositionPixels, labelYBaseOffsetPixels)) {
					blocked = true;
					break;
				}
			}

			if (blocked) break;
		}

		if (blocked) {
			labelYOffsetPixels += 2;
		} else {
			break;
		}
	}

	// Handle no valid position found
	if (labelYOffsetPixels >= yScale.height) {
		// Place on top
		labelYOffsetPixels = 0;
	}

	return labelYOffsetPixels;
}

// This function will use the appropriate method for updating the data  based on the current datasets
// 1. If the dataset label have not changed, the data will be updated in place
// 2. If the dataset labels have changed, the data will be replaced
// This behavior handles market price updates without flickering/resetting datasets
function smartUpdateChartData(chart: Chart, newChartData: ChartData<any>) {
	const newDatasetLabels = newChartData.datasets.map((set) => set.label).sort();
	const currentDatasetLabels = chart.data.datasets.map((set) => set.label).sort();
	if (arraysEqual(newDatasetLabels, currentDatasetLabels)) {
		chart.data.datasets.forEach((dataset) => {
			const newData = newChartData.datasets.find((set) => set.label === dataset.label)?.data ?? [];
			const currentData = dataset.data;

			if (!arraysEqual(newData, currentData)) {
				dataset.data = newData;
			}
		});
	} else {
		chart.data = newChartData;
	}
}

// This function will use the appropriate method for updating the data  based on the current datasets
// 1. If the dataset labels have changed, chart.data.labels is updated with the new labels.
// 2. If the chart's labels have not been changed, nothing happens
// This behavior handles time frame changes
function smartUpdateChartLabels(chart: Chart, newChartData: ChartData<any>) {
	const newLabels = newChartData.labels ?? [];
	const currentLabels = chart.data.labels ?? [];
	if (!arraysEqual(newLabels, currentLabels)) {
		chart.data.labels = newLabels;
	}
}

export {
	getCustomTooltip,
	CustomTooltipOptions,
	getCustomLegend,
	extendLineChartToEdgesPlugin,
	downloadChartAsCSV,
	calculateAutoLabelYOffset,
	CustomInnerHTMLFunction,
	smartUpdateChartData,
	smartUpdateChartLabels,
};
