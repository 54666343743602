/** Types pulled from ember-power-select and ember-basic-dropdown until they are made publicly available.*/
export type RepositionChanges = {
	hPosition: string;
	vPosition: string;
	otherStyles: Record<string, string | number | undefined>;
	top?: string;
	left?: string;
	right?: string;
	width?: string;
	height?: string;
};

export interface DropdownActions {
	toggle: (e?: Event) => void;
	close: (e?: Event, skipFocus?: boolean) => void;
	open: (e?: Event) => void;
	reposition: (...args: any[]) => undefined | RepositionChanges;
}

export interface SelectActions extends DropdownActions {
	search: (term: string) => void;
	highlight: (option: any) => void;
	select: (selected: any, e?: Event) => void;
	choose: (selected: any, e?: Event) => void;
	scrollTo: (option: any) => void;
}

export interface Dropdown {
	uniqueId: string;
	disabled: boolean;
	isOpen: boolean;
	actions: DropdownActions;
}

export interface Select extends Dropdown {
	selected: any;
	highlighted: any;
	options: any[];
	results: any[];
	resultsCount: number;
	loading: boolean;
	isActive: boolean;
	searchText: string;
	lastSearchedText: string;
	actions: SelectActions;
}
