import Controller from '@ember/controller';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';

export default class MarketingIndexController extends Controller {
	cropPlansRoute = '';
	cropTargetsRoute = '';
	cropFillsRoute = '';
  fieldsRoute = '';

	get columns(): TableColumn[] {
		return [
			{
				id: '8980c5b8-6fc4-4062-9fc1-e58ac351aa71',
				name: 'Marketing Views',
				valuePath: 'name',
				cellComponent: CellComponents.String,
				linkRoutePath: 'route',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				textAlign: 'left',
			},
		];
	}

	get rows() {
		return [
			{
				name: 'Crop Plans',
				route: this.cropPlansRoute,
			},
			{
				name: 'Targets',
				route: this.cropTargetsRoute,
			},
			{
				name: 'Fills',
				route: this.cropFillsRoute,
			},
      {
				name: 'Fields',
				route: this.fieldsRoute,
			},
		];
	}
}


// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'business/business/marketing': MarketingIndexController;
	}
}
