import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation } from 'glimmer-apollo';

const UPDATE_MEMBER = gql`
	mutation updateEntityMember($data: UpdateEntityMemberDTO!, $id: String!) {
		updateEntityMember(data: $data, id: $id) {
			id
			userEmail
			canReadBrokerage
			canWriteBrokerage
			canReadBusiness
			canWriteBusiness
			canReadEntityGroup
			canWriteEntityGroup
			canReadFeedAndCropPlan
			canWriteFeedAndCropPlan
			canReadInsurance
			canWriteInsurance
			canReadOperations
			canWriteOperations
			canReadSwap
			canWriteSwap
			updatedAt
		}
	}
`;

type UpdateEntityMemberMutation = {
	__typename?: 'Mutation';
	updateEntityMember: {
		id: string;
		__typename: 'UpdatedResource';
	};
};

interface EditMemberModalArgs {
	id: string;
	closeModalFn: Function;
	canWriteBrokerage: boolean;
	canReadBrokerage: boolean;
	canWriteFeedAndCropPlan: boolean;
	canReadFeedAndCropPlan: boolean;
	canWriteInsurance: boolean;
	canReadInsurance: boolean;
	canWriteOperations: boolean;
	canReadOperations: boolean;
	canWriteSwap: boolean;
	canReadSwap: boolean;
	canWriteOrganization: boolean;
	canReadOrganization: boolean;
	canReadBusiness: boolean;
	canWriteBusiness: boolean;
	canReadEntityGroup: boolean;
	canWriteEntityGroup: boolean;
	memberData: MemberData;
	model: any;
}

interface MemberData {
	id: string;
	userEmail: string | null;
	canWriteBrokerage: boolean | null;
	canReadBrokerage: boolean | null;
	canWriteFeedAndCropPlan: boolean | null;
	canReadFeedAndCropPlan: boolean | null;
	canWriteInsurance: boolean | null;
	canReadInsurance: boolean | null;
	canWriteOperations: boolean | null;
	canReadOperations: boolean | null;
	canWriteSwap: boolean | null;
	canReadSwap: boolean | null;
	canWriteOrganization: boolean | null;
	canReadOrganization: boolean | null;
	canReadBusiness: boolean | null;
	canWriteBusiness: boolean | null;
	canReadEntityGroup: boolean | null;
	canWriteEntityGroup: boolean | null;
	User: User;
}

interface User {
	email: string | null;
	id: string;
}

export default class EditMemberModal extends Component<EditMemberModalArgs> {
	updateEntityMember = useMutation<UpdateEntityMemberMutation>(this, () => [
		UPDATE_MEMBER,
		{
			/* options */
		},
	]);
	@tracked member: any;
	@tracked _canWriteBrokerage = this.args.memberData.canWriteBrokerage;
	@tracked _canReadBrokerage = this.args.memberData.canReadBrokerage;
	@tracked _canWriteFeedAndCropPlan = this.args.memberData.canWriteFeedAndCropPlan;
	@tracked _canReadFeedAndCropPlan = this.args.memberData.canReadFeedAndCropPlan;
	@tracked _canWriteInsurance = this.args.memberData.canWriteInsurance;
	@tracked _canReadInsurance = this.args.memberData.canReadInsurance;
	@tracked _canWriteOperations = this.args.memberData.canWriteOperations;
	@tracked _canReadOperations = this.args.memberData.canReadOperations;
	@tracked _canWriteSwap = this.args.memberData.canWriteSwap;
	@tracked _canReadSwap = this.args.memberData.canReadSwap;
	@tracked _canWriteOrganization = this.args.memberData.canWriteOrganization;
	@tracked _canReadOrganization = this.args.memberData.canReadOrganization;
	@tracked _canReadBusiness = this.args.memberData.canReadBusiness;
	@tracked _canWriteBusiness = this.args.memberData.canWriteBusiness;
	@tracked _canReadEntityGroup = this.args.memberData.canReadEntityGroup;
	@tracked _canWriteEntityGroup = this.args.memberData.canWriteEntityGroup;

	get canWriteBrokerage() {
		return this._canWriteBrokerage ?? this.args.memberData.canWriteBrokerage;
	}

	set canWriteBrokerage(value) {
		this._canWriteBrokerage = value;
	}

	get canReadBrokerage() {
		return this._canReadBrokerage ?? this.args.memberData.canReadBrokerage;
	}

	set canReadBrokerage(value) {
		this._canReadBrokerage = value;
	}

	get canWriteFeedAndCropPlan() {
		return this._canWriteFeedAndCropPlan ?? this.args.memberData.canWriteFeedAndCropPlan;
	}

	set canWriteFeedAndCropPlan(value) {
		this._canWriteFeedAndCropPlan = value;
	}

	get canReadFeedAndCropPlan() {
		return this._canReadFeedAndCropPlan ?? this.args.memberData.canReadFeedAndCropPlan;
	}

	set canReadFeedAndCropPlan(value) {
		this._canReadFeedAndCropPlan = value;
	}

	get canWriteInsurance() {
		return this._canWriteInsurance ?? this.args.memberData.canWriteInsurance;
	}

	set canWriteInsurance(value) {
		this._canWriteInsurance = value;
	}

	get canReadInsurance() {
		return this._canReadInsurance ?? this.args.memberData.canReadInsurance;
	}

	set canReadInsurance(value) {
		this._canReadInsurance = value;
	}

	get canWriteOperations() {
		return this._canWriteOperations ?? this.args.memberData.canWriteOperations;
	}

	set canWriteOperations(value) {
		this._canWriteOperations = value;
	}

	get canReadOperations() {
		return this._canReadOperations ?? this.args.memberData.canReadOperations;
	}

	set canReadOperations(value) {
		this._canReadOperations = value;
	}

	get canReadBusiness() {
		return this._canReadBusiness ?? this.args.memberData.canReadBusiness;
	}

	set canReadBusiness(value) {
		this._canReadBusiness = value;
	}

	get canWriteBusiness() {
		return this._canWriteBusiness ?? this.args.memberData.canWriteBusiness;
	}

	set canWriteBusiness(value) {
		this._canWriteBusiness = value;
	}

	get canReadSwap() {
		return this._canReadSwap ?? this.args.memberData.canReadSwap;
	}

	set canReadSwap(value) {
		this._canReadSwap = value;
	}

	get canWriteSwap() {
		return this._canWriteSwap ?? this.args.memberData.canWriteSwap;
	}

	set canWriteSwap(value) {
		this._canWriteSwap = value;
	}

	get canReadEntityGroup() {
		return this._canReadEntityGroup ?? this.args.memberData.canReadEntityGroup;
	}

	set canReadEntityGroup(value) {
		this._canReadEntityGroup = value;
	}

	get canWriteEntityGroup() {
		return this._canWriteEntityGroup ?? this.args.memberData.canWriteEntityGroup;
	}

	set canWriteEntityGroup(value) {
		this._canWriteEntityGroup = value;
	}

	get canReadOrganization() {
		return this._canReadOrganization ?? this.args.memberData.canReadOrganization;
	}

	set canReadOrganization(value) {
		this._canReadOrganization = value;
	}

	get canWriteOrganization() {
		return this._canWriteOrganization ?? this.args.memberData.canWriteOrganization;
	}

	set canWriteOrganization(value) {
		this._canWriteOrganization = value;
	}

	@action
	async submit() {
		const data = {
			canReadBrokerage: this.canReadBrokerage,
			canWriteBrokerage: this.canWriteBrokerage,
			canReadBusiness: this.canReadBusiness,
			canWriteBusiness: this.canWriteBusiness,
			canReadEntityGroup: this.canReadEntityGroup,
			canWriteEntityGroup: this.canWriteEntityGroup,
			canReadFeedAndCropPlan: this.canReadFeedAndCropPlan,
			canWriteFeedAndCropPlan: this.canWriteFeedAndCropPlan,
			canReadInsurance: this.canReadInsurance,
			canWriteInsurance: this.canWriteInsurance,
			canReadOperations: this.canReadOperations,
			canWriteOperations: this.canWriteOperations,
			canReadSwap: this.canReadSwap,
			canWriteSwap: this.canWriteSwap,
			canReadOrganization: this.canReadOrganization,
			canWriteOrganization: this.canWriteOrganization,
		};

		await this.updateEntityMember.mutate({
			id: this.args.memberData.id || this.args.memberData.User.id,
			data: data,
		});

		await this.args.model.refetch();

		this.closeModal();
	}

	@action
	closeModal() {
		this._canWriteBrokerage = null;
		this._canReadBrokerage = null;
		this._canWriteFeedAndCropPlan = null;
		this._canReadFeedAndCropPlan = null;
		this._canWriteInsurance = null;
		this._canReadInsurance = null;
		this._canWriteOperations = null;
		this._canReadOperations = null;
		this._canWriteSwap = null;
		this._canReadSwap = null;
		this._canWriteOrganization = null;
		this._canReadOrganization = null;
		this._canReadBusiness = null;
		this._canWriteBusiness = null;
		this._canReadEntityGroup = null;
		this._canWriteEntityGroup = null;
		this.args.closeModalFn();
	}
}
