import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-insert (perform this.fetchFeedIngredients)}}>\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.feedIngredients}}\n\t\t@searchEnabled={{true}}\n\t\t@searchField='name'\n\t\t@onChange={{@onChange}}\n\t\t@disabled={{(or this.getFeedIngredients.isRunning @disabled)}}\n\t\t@placeholder={{if this.getFeedIngredients.isRunning 'Loading...'}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\tas |feedIngredient|\n\t>\n\t\t{{feedIngredient.name}}\n\t</PowerSelect>\n</span>", {"contents":"<span {{did-insert (perform this.fetchFeedIngredients)}}>\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.feedIngredients}}\n\t\t@searchEnabled={{true}}\n\t\t@searchField='name'\n\t\t@onChange={{@onChange}}\n\t\t@disabled={{(or this.getFeedIngredients.isRunning @disabled)}}\n\t\t@placeholder={{if this.getFeedIngredients.isRunning 'Loading...'}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\tas |feedIngredient|\n\t>\n\t\t{{feedIngredient.name}}\n\t</PowerSelect>\n</span>","moduleName":"vault-client/components/feed-ingredient-select.hbs","parseOptions":{"srcName":"vault-client/components/feed-ingredient-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { FeedIngredient, Query, Query_FeedIngredientsArgs } from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';
import { task } from 'ember-concurrency';

interface FeedIngredientSelectArgs {
	renderInPlace?: boolean;
	onChange: (value: FeedIngredient) => void;
	selected: FeedIngredient | null;
	scopeId?: string;
}

const GET_FEED_INGREDIENTS = gql`
	query FeedIngredients($scopeId: String) {
		FeedIngredients(orderBy: { name: Asc }, scopeId: $scopeId, where: { FeedIngredientVersion: { isCurrent: { equals: true } } }) {
			id
			name
		}
	}
`;

export default class FeedIngredientSelect extends Component<FeedIngredientSelectArgs> {
	@tracked feedIngredients: FeedIngredient[] = [];
	@tracked variables: Query_FeedIngredientsArgs = {
		scopeId: this.args.scopeId ?? null,
	};

	getFeedIngredients = useQuery<{ FeedIngredients: Query['FeedIngredients'] }, Query_FeedIngredientsArgs>(this, () => [
		GET_FEED_INGREDIENTS,
		{
			variables: this.variables,
		},
	]);

	fetchFeedIngredients = task({ drop: true }, async () => {
		await this.getFeedIngredients.promise;
		const feedIngredients = this.getFeedIngredients.data?.FeedIngredients ?? [];
		this.feedIngredients = feedIngredients;
	});
}
