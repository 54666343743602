import Controller from '@ember/controller';
import { CellComponents } from 'vault-client/types/vault-table';

export default class LivestockFeedController extends Controller {
	feedOverviewRoutePath = '';
	feedExpensesRoutePath = '';
	feedFillsRoutePath = '';

	get columns() {
		const baseColumns = [
			{
				id: '714828ca-1116-4888-9da2-14f1ce3a8c34',
				name: 'Name',
				valuePath: 'name',
				minWidth: 225,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoutePath: 'linkRoute',
				linkModelPath: 'id',
			},
		];

		return baseColumns;
	}

	get rows() {
		return [
			{
				name: 'Feed Overview',
				linkRoute: this.feedOverviewRoutePath,
			},
			{
				name: 'Total Feed Expenses',
				linkRoute: this.feedExpensesRoutePath,
			},
			{
				name: 'Feed Contracts',
				linkRoute: this.feedFillsRoutePath,
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/livestock-feed': LivestockFeedController;
	}
}
