import Controller from '@ember/controller';
import {
	ForecastedMilkUtilization,
	Future,
	AggregateLedgerEntryDTO,
	AggregateFeedIngredientForecastedUsageDTO,
	AggregateForecastedMilkProductionByMonthDTO,
	ForecastedMilkProductionByMonth,
	TypeOfLedgerCategory,
	EntityAllocatedExposureRatio,
	InsuranceEndorsementAllocationRatio,
	AggregateCurrentAllocationPositionDTO,
	NumericAggregateCurrentAllocationPositionDTO,
	AggregateLedgerForecastedEntryDTO,
	AggregateAllocatedForecastedHedgedAndCappedVolumeDTO,
	AggregateFeedIngredientConsumedAndPurchasedVolumeDTO,
} from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import MilkCheck from 'vault-client/models/milk-check';
import { getOwner, setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { DashboardModel } from 'vault-client/routes/businesses/business/dashboard';
import { guidFor } from '@ember/object/internals';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import { InsuranceMonth, InsuranceQuarterWithMonths } from 'vault-client/models/insurance-quarter';
import { action } from '@ember/object';
import { CustomTooltipOptions, getCustomLegend, getCustomTooltip } from 'vault-client/utils/chart-utils';
import getCSSVariable from 'vault-client/utils/get-css-variable';
import { cmeFutureMonthToRelevantMonths } from 'vault-client/utils/cme-future-month-to-relevant-months';
import {
	calculateMonthlyWeightedPricesAndBasisValues,
	calculateUtilizationValuesFromForecastedProduction,
} from 'vault-client/utils/milk-check-utils';
import isTouchDevice from 'vault-client/utils/is-touch-device';
export class Month {
	@service marketData: any;
	registeredSymbols: string[] = [];
	date: string;
	forecastedMilkProduction: AggregateForecastedMilkProductionByMonthDTO | null;
	brokeragePosition: NumericAggregateCurrentAllocationPositionDTO | null;
	dairyBrokeragePosition: NumericAggregateCurrentAllocationPositionDTO | null;
	feedBrokeragePosition: NumericAggregateCurrentAllocationPositionDTO | null;
	forecastedMilkUtilization: ForecastedMilkUtilization | null;
	classiiiExposureRatio: EntityAllocatedExposureRatio | null;
	classivExposureRatio: EntityAllocatedExposureRatio | null;
	butterExposureRatio: EntityAllocatedExposureRatio | null;
	cheeseExposureRatio: EntityAllocatedExposureRatio | null;
	dryWheyExposureRatio: EntityAllocatedExposureRatio | null;
	nonfatExposureRatio: EntityAllocatedExposureRatio | null;
	classiiiFuture: Future | null;
	classivFuture: Future | null;
	butterFuture: Future | null;
	cheeseFuture: Future | null;
	dryWheyFuture: Future | null;
	nonfatDryMilkFuture: Future | null;
	advancedButterFuture: Future | null;
	advancedNonfatDryMilkFuture: Future | null;
	advancedCheeseFuture: Future | null;
	advancedDryWheyFuture: Future | null;
	soybeanMealFuture: Future | null;
	cornFuture: Future | null;
	operation: number | null | undefined;
	revenues: number | null | undefined;
	expenses: number | null | undefined;
	fixedFeedExpenses: number | null | undefined;
	feedIngredientsUsage: AggregateFeedIngredientForecastedUsageDTO[];
	milkCheck: MilkCheck | undefined;
	classiiiBrokerageHedgedVolume: number | null;
	classivBrokerageHedgedVolume: number | null;
	monthlyCalculatedAmount: number | null | undefined;
	displayMonthName: boolean;
	allocatedDrpInsuranceEndorsementRatios: InsuranceEndorsementAllocationRatio[];
	insuranceMonth: InsuranceMonth;
	feedFills: AggregateFeedIngredientConsumedAndPurchasedVolumeDTO[];

	constructor(month: IMonthObject) {
		setOwner(this, month.owner);
		this.date = month.date;
		this.forecastedMilkProduction = month.forecastedMilkProduction;
		this.brokeragePosition = month.brokeragePosition;
		this.dairyBrokeragePosition = month.dairyBrokeragePosition;
		this.feedBrokeragePosition = month.feedBrokeragePosition;
		this.forecastedMilkUtilization = month.milkCheckUtilizations;
		this.classiiiExposureRatio = month.classiiiExposure;
		this.classivExposureRatio = month.classivExposure;
		this.butterExposureRatio = month.butterExposure;
		this.cheeseExposureRatio = month.cheeseExposure;
		this.dryWheyExposureRatio = month.dryWheyExposure;
		this.nonfatExposureRatio = month.nonfatExposure;
		this.classiiiFuture = month.classiiiFuture;
		this.classivFuture = month.classivFuture;
		this.butterFuture = month.butterFuture;
		this.advancedButterFuture = month.advancedButterFuture;
		this.advancedNonfatDryMilkFuture = month.advancedNonfatDryMilkFuture;
		this.advancedCheeseFuture = month.advancedCheeseFuture;
		this.advancedDryWheyFuture = month.advancedDryWheyFuture;
		this.cheeseFuture = month.cheeseFuture;
		this.dryWheyFuture = month.dryWheyFuture;
		this.nonfatDryMilkFuture = month.nonfatDryMilkFuture;
		this.soybeanMealFuture = month.soybeanMealFuture;
		this.cornFuture = month.cornFuture;
		this.operation = month.operation;
		this.revenues = month.revenues;
		this.expenses = month.expenses;
		this.fixedFeedExpenses = month.fixedFeedExpenses;
		this.classiiiBrokerageHedgedVolume = month.classiiiBrokerageHedgedVolume;
		this.classivBrokerageHedgedVolume = month.classivBrokerageHedgedVolume;
		this.feedIngredientsUsage = month.feedIngredientsUsage;
		this.monthlyCalculatedAmount = month.monthlyCalculatedAmount;
		this.displayMonthName = true;
		this.allocatedDrpInsuranceEndorsementRatios = month.allocatedDrpInsuranceEndorsementRatios;
		this.feedFills = month.feedFills;

		this.insuranceMonth = new InsuranceMonth(month.date);

		if (month.allocatedDrpInsuranceEndorsementRatios) {
			month.allocatedDrpInsuranceEndorsementRatios.forEach((endorsement) => {
				this.insuranceMonth.addAllocatedEndorsementData(endorsement);
			});
		}
		if (month.forecastedMilkProduction) {
			this.insuranceMonth.addProductionData(month.forecastedMilkProduction);
		}

		if (this.butterFuture?.barchartSymbol && !this.registeredSymbols.includes(this.butterFuture.barchartSymbol)) {
			this.marketData.register(this.butterFuture.barchartSymbol);
			this.registeredSymbols.push(this.butterFuture.barchartSymbol);
		}

		if (this.cheeseFuture?.barchartSymbol && !this.registeredSymbols.includes(this.cheeseFuture.barchartSymbol)) {
			this.marketData.register(this.cheeseFuture.barchartSymbol);
			this.registeredSymbols.push(this.cheeseFuture.barchartSymbol);
		}

		if (this.dryWheyFuture?.barchartSymbol && !this.registeredSymbols.includes(this.dryWheyFuture.barchartSymbol)) {
			this.marketData.register(this.dryWheyFuture.barchartSymbol);
			this.registeredSymbols.push(this.dryWheyFuture.barchartSymbol);
		}

		if (this.nonfatDryMilkFuture?.barchartSymbol && !this.registeredSymbols.includes(this.nonfatDryMilkFuture.barchartSymbol)) {
			this.marketData.register(this.nonfatDryMilkFuture.barchartSymbol);
			this.registeredSymbols.push(this.nonfatDryMilkFuture.barchartSymbol);
		}

		if (this.cornFuture?.barchartSymbol && !this.registeredSymbols.includes(this.cornFuture.barchartSymbol)) {
			this.marketData.register(this.cornFuture.barchartSymbol);
			this.registeredSymbols.push(this.cornFuture.barchartSymbol);
		}

		if (this.soybeanMealFuture?.barchartSymbol && !this.registeredSymbols.includes(this.soybeanMealFuture.barchartSymbol)) {
			this.marketData.register(this.soybeanMealFuture.barchartSymbol);
			this.registeredSymbols.push(this.soybeanMealFuture.barchartSymbol);
		}

		if (month.milkCheckUtilizations) {
			this.milkCheck = new MilkCheck(
				month.owner,
				month.date,
				month.everAgBasis,
				month.milkCheckUtilizations,
				month.classiiiFuture,
				month.classivFuture,
				month.butterFuture,
				month.advancedNonfatDryMilkFuture,
				month.advancedButterFuture,
				month.advancedCheeseFuture,
				month.advancedDryWheyFuture,
				month.classiDifferential,
				month.monthlyCalculatedAmount,
				month.forecastedMilkProduction as AggregateForecastedMilkProductionByMonthDTO,
				this.otherSolidsPercentage,
				this.butterfatPercentage,
				this.proteinPercentage,
			);
		}
	}

	get brokerageRevenue() {
		return this.brokeragePosition?.grossPnl ?? null;
	}

	get dairyBrokerageRevenue() {
		return this.dairyBrokeragePosition?.grossPnl ?? null;
	}

	get dairyBrokerageRevenueCwt() {
		if (!this.grossProduction || this.dairyBrokerageRevenue == null) return null;

		return (this.dairyBrokerageRevenue / this.grossProduction) * 100;
	}

	get feedBrokerageRevenue() {
		return this.feedBrokeragePosition?.grossPnl ?? null;
	}

	get feedBrokerageRevenueCwt() {
		if (!this.grossProduction || this.feedBrokerageRevenue == null) return null;

		return (this.feedBrokerageRevenue / this.grossProduction) * 100;
	}

	get classiiiBrokerageHedgedPercent() {
		if (!this.classiiiExposure) return null;

		return (this.classiiiBrokerageHedgedVolume ?? 0) / this.classiiiExposure;
	}

	get classivBrokerageHedgedPercent() {
		if (!this.classivExposure) return null;

		return (this.classivBrokerageHedgedVolume ?? 0) / this.classivExposure;
	}

	get milkNetBrokerageHedgedPercent() {
		if (this.classiiiExposure == null && this.classivExposure == null) {
			return null;
		}

		const exposure = (this.classiiiExposure ?? 0) + (this.classivExposure ?? 0);
		const hedgedVolume = (this.classiiiBrokerageHedgedVolume ?? 0) + (this.classivBrokerageHedgedVolume ?? 0);

		if (!exposure) return 0;
		return hedgedVolume / exposure;
	}

	get grossProduction() {
		return this.forecastedMilkProduction?.sum.grossProduction;
	}

	get grossProtein() {
		return this.forecastedMilkProduction?.sum.grossProteinProduction;
	}

	get proteinPercentage() {
		if (this.grossProtein == null || !this.grossProduction) return null;
		return this.grossProtein / this.grossProduction;
	}

	get grossButterfat() {
		return this.forecastedMilkProduction?.sum.grossButterfatProduction;
	}

	get butterfatPercentage() {
		if (this.grossButterfat == null || !this.grossProduction) return null;
		return this.grossButterfat / this.grossProduction;
	}

	get grossOtherSolids() {
		return this.forecastedMilkProduction?.sum.grossOtherSolidsProduction;
	}

	get otherSolidsPercentage() {
		if (this.grossOtherSolids == null || !this.grossProduction) return null;
		return this.grossOtherSolids / this.grossProduction;
	}

	// Production != exposure for dairies, due to Class I
	get grossExposure() {
		if (this.classiiiExposure == null && this.classivExposure == null) return null;

		return (this.classiiiExposure ?? 0) + (this.classivExposure ?? 0);
	}

	get milkCheckRevenue() {
		if (!this.grossProduction || this.milkCheck?.mailboxPrice == null) return null;
		return (this.milkCheck.mailboxPrice * this.grossProduction) / 100;
	}

	get milkCheckRevenueCwt() {
		if (!this.grossProduction || this.milkCheckRevenue == null) return null;
		return (this.milkCheckRevenue / this.grossProduction) * 100;
	}

	get classiiiExposure() {
		return this.classiiiExposureRatio?.productionExposure;
	}

	get productionClassiiiExposure() {
		const classiii = this.forecastedMilkUtilization?.grossClassiiiPounds;
		const classi = this.forecastedMilkUtilization?.grossClassiPounds;

		if (classi == null && classiii == null) return null;

		return (classiii ?? 0) + (classi ?? 0) / 2;
	}

	get classiiiVolumeHedged() {
		// Factor in DRP and Brokerage for Class IV Hedged Volume
		return (this.classiiiBrokerageHedgedVolume ?? 0) + (this.insuranceMonth.classIiiEffectiveCoveredMilkProduction ?? 0);
	}

	get classivExposure() {
		return this.classivExposureRatio?.productionExposure;
	}

	get productionClassivExposure() {
		const classiv = this.forecastedMilkUtilization?.grossClassivPounds;
		const classii = this.forecastedMilkUtilization?.grossClassiiPounds;
		const classi = this.forecastedMilkUtilization?.grossClassiPounds;
		if (classi == null && classii == null && classiv == null) return null;

		return (classiv ?? 0) + (classii ?? 0) + (classi ?? 0) / 2;
	}

	get classivVolumeHedged() {
		// Factor in DRP and Brokerage for Class IV Hedged Volume
		return (this.classivBrokerageHedgedVolume ?? 0) + (this.insuranceMonth.classIvEffectiveCoveredMilkProduction ?? 0);
	}

	// classiii = (9.63933096 * cheese) + (8.0532099445 * butter) + (5.864305 * dryWhey)
	get butterExposure() {
		const productionExposure = this.butterExposureRatio?.productionExposure;
		const classiiiExposure = this.classiiiExposure;
		const classivExposure = this.classivExposure;

		if (productionExposure == null && classiiiExposure == null && classivExposure == null) return null;

		return (productionExposure || 0) + (classiiiExposure || 0) * 0.080532099445 + ((classivExposure || 0) * 0.035) / 1.211;
	}

	get butterVolumeHedged() {
		const butterHedged = this.butterExposureRatio?.totalVolumeHedged;
		const classiiiHedged = this.classiiiVolumeHedged;
		const classivHedged = this.classivVolumeHedged;

		if (butterHedged == null && classiiiHedged == null && classivHedged == null) return null;

		return (butterHedged || 0) + (classiiiHedged || 0) * 0.080532099445 + ((classivHedged || 0) * 0.035) / 1.211;
	}

	get cheeseExposure() {
		const productionExposure = this.cheeseExposureRatio?.productionExposure;
		const classiiiExposure = this.classiiiExposure;

		if (productionExposure == null && classiiiExposure == null) return null;

		return (productionExposure || 0) + (classiiiExposure || 0) * 0.0963933096;
	}

	get cheeseVolumeHedged() {
		const cheeseHedged = this.cheeseExposureRatio?.totalVolumeHedged;
		const classiiiHedged = this.classiiiVolumeHedged;

		if (cheeseHedged == null && classiiiHedged == null) return null;

		return (cheeseHedged || 0) + (classiiiHedged || 0) * 0.0963933096;
	}

	get dryWheyExposure() {
		const productionExposure = this.dryWheyExposureRatio?.productionExposure;
		const classiiiExposure = this.classiiiExposure;

		if (productionExposure == null && classiiiExposure == null) return null;

		return (productionExposure || 0) + (classiiiExposure || 0) * 0.05864305;
	}

	get dryWheyVolumeHedged() {
		const dryWheyHedged = this.dryWheyExposureRatio?.totalVolumeHedged;
		const classiiiHedged = this.classiiiVolumeHedged;

		if (dryWheyHedged == null && classiiiHedged == null) return null;

		return (dryWheyHedged || 0) + (classiiiHedged || 0) * 0.05864305;
	}

	get nonfatExposure() {
		const productionExposure = this.nonfatExposureRatio?.productionExposure;
		const classivExposure = this.classivExposure;

		if (productionExposure == null && classivExposure == null) return null;

		return (productionExposure || 0) + ((classivExposure || 0) * 0.08685) / 0.99;
	}

	get nonfatVolumeHedged() {
		const nonfatHedged = this.nonfatExposureRatio?.totalVolumeHedged;
		const classivHedged = this.classivVolumeHedged;

		if (nonfatHedged == null && classivHedged == null) return null;

		return (nonfatHedged || 0) + ((classivHedged || 0) * 0.08685) / 0.99;
	}

	// For now, we're going to calculate % hedged based on LBS hedged.
	// I don't think this is a super exact concept, but it is heavily requested by RJY
	get percentHedged() {
		if (this.nonfatExposure == null && this.dryWheyExposure == null && this.cheeseExposure == null && this.butterExposure == null)
			return null;

		const exposure = (this.nonfatExposure ?? 0) + (this.dryWheyExposure ?? 0) + (this.cheeseExposure ?? 0) + (this.butterExposure ?? 0);
		const volumeHedged =
			(this.nonfatVolumeHedged ?? 0) + (this.dryWheyVolumeHedged ?? 0) + (this.cheeseVolumeHedged ?? 0) + (this.butterVolumeHedged ?? 0);

		if (exposure == 0) {
			return 0;
		}

		return volumeHedged / exposure;
	}

	get butterPrice(): number | null {
		if (this.butterFuture?.barchartSymbol) {
			const marketDatum = this.marketData.getMarketDatum(this.butterFuture.barchartSymbol);

			const displayFactor = this.butterFuture?.SymbolGroup.displayFactor;
			const price = displayFactor ? marketDatum?.lastPrice * displayFactor : marketDatum?.lastPrice;

			return price;
		} else {
			return null;
		}
	}

	get cheesePrice(): number | null {
		if (this.cheeseFuture?.barchartSymbol) {
			const marketDatum = this.marketData.getMarketDatum(this.cheeseFuture.barchartSymbol);

			const displayFactor = this.cheeseFuture?.SymbolGroup.displayFactor;
			const price = displayFactor ? marketDatum?.lastPrice * displayFactor : marketDatum?.lastPrice;

			return price;
		} else {
			return null;
		}
	}

	get dryWheyPrice(): number | null {
		if (this.dryWheyFuture?.barchartSymbol) {
			const marketDatum = this.marketData.getMarketDatum(this.dryWheyFuture.barchartSymbol);

			const displayFactor = this.dryWheyFuture?.SymbolGroup.displayFactor;
			const price = displayFactor ? marketDatum?.lastPrice * displayFactor : marketDatum?.lastPrice;

			return price;
		} else {
			return null;
		}
	}

	get nonfatDryMilkPrice(): number | null {
		if (this.nonfatDryMilkFuture?.barchartSymbol) {
			const marketDatum = this.marketData.getMarketDatum(this.nonfatDryMilkFuture.barchartSymbol);

			const displayFactor = this.nonfatDryMilkFuture?.SymbolGroup.displayFactor;
			const price = displayFactor ? marketDatum?.lastPrice * displayFactor : marketDatum?.lastPrice;

			return price;
		} else {
			return null;
		}
	}

	get cornFuturePrice(): number | null {
		if (this.cornFuture?.barchartSymbol) {
			const marketDatum = this.marketData.getMarketDatum(this.cornFuture.barchartSymbol) as { lastPrice?: number | null } | undefined;
			if (marketDatum?.lastPrice == null) return null;

			const displayFactor = this.cornFuture?.SymbolGroup.displayFactor;
			const price = displayFactor ? marketDatum.lastPrice * displayFactor : marketDatum.lastPrice;

			return price;
		} else {
			return null;
		}
	}

	get soybeanMealFuturePrice(): number | null {
		if (this.soybeanMealFuture?.barchartSymbol) {
			const marketDatum = this.marketData.getMarketDatum(this.soybeanMealFuture.barchartSymbol) as
				| { lastPrice?: number | null }
				| undefined;
			if (marketDatum?.lastPrice == null) return null;

			const price: number = this.soybeanMealFuture?.SymbolGroup.displayFactor
				? marketDatum.lastPrice * this.soybeanMealFuture.SymbolGroup.displayFactor
				: marketDatum.lastPrice;

			return price;
		} else {
			return null;
		}
	}

	get totalExposureByValue() {
		if (this.butterExposure == null && this.cheeseExposure == null && this.nonfatExposure == null && this.dryWheyExposure == null)
			return null;

		const butterExposure = this.butterPrice ? (this.butterExposure ?? 0) * this.butterPrice : 0;
		const cheeseExposure = this.cheesePrice ? (this.cheeseExposure ?? 0) * this.cheesePrice : 0;
		const nonfatDryMilkExposure = this.nonfatDryMilkPrice ? this.nonfatDryMilkPrice * (this.nonfatExposure ?? 0) : 0;
		const dryWheyExposure = this.dryWheyPrice ? this.dryWheyPrice * (this.dryWheyExposure ?? 0) : 0;

		return butterExposure + cheeseExposure + nonfatDryMilkExposure + dryWheyExposure;
	}

	get totalValueHedged() {
		if (
			this.butterVolumeHedged == null &&
			this.cheeseVolumeHedged == null &&
			this.nonfatVolumeHedged == null &&
			this.dryWheyVolumeHedged == null
		)
			return null;
		const butterHedged = this.butterPrice ? (this.butterVolumeHedged ?? 0) * this.butterPrice : 0;
		const cheeseHedged = this.cheesePrice ? (this.cheeseVolumeHedged ?? 0) * this.cheesePrice : 0;
		const nonfatDryMilkHedged = this.nonfatDryMilkPrice ? this.nonfatDryMilkPrice * (this.nonfatVolumeHedged ?? 0) : 0;
		const dryWheyHedged = this.dryWheyPrice ? this.dryWheyPrice * (this.dryWheyVolumeHedged ?? 0) : 0;

		return butterHedged + cheeseHedged + nonfatDryMilkHedged + dryWheyHedged;
	}

	get feedExpenses() {
		const monthFixedFeedExpensesSum = this.fixedFeedExpenses ? this.fixedFeedExpenses * -1 : 0;

		const monthFeedIngredientsExpensesSum =
			this.feedIngredientsUsage.reduce((monthFeedIngredientsSumUsd, currentItem) => {
				return (monthFeedIngredientsSumUsd += currentItem.sum.forecastedTotalExpenseInUsd ?? 0);
			}, 0) * -1;

		const monthFeedFillsExpensesSum =
			this.feedFills.reduce((monthFeedFillSumUsd, currentItem) => {
				return (monthFeedFillSumUsd += currentItem.sum.totalPurchasedCostInUsd ?? 0);
			}, 0) * -1;

		if (monthFeedIngredientsExpensesSum == 0 && monthFixedFeedExpensesSum == 0 && monthFeedFillsExpensesSum == 0) return null;

		return (monthFeedIngredientsExpensesSum ?? 0) + (monthFixedFeedExpensesSum ?? 0) + (monthFeedFillsExpensesSum ?? 0);
	}

	get feedExpensesCwt() {
		if (!this.grossProduction || this.feedExpenses == null) return null;

		return (this.feedExpenses / this.grossProduction) * 100;
	}

	get revenuesTotal() {
		return this.revenues;
	}

	get revenuesTotalCwt() {
		if (!this.grossProduction || this.revenues == null) return null;
		return (this.revenues / this.grossProduction) * 100;
	}

	get insuranceRevenue() {
		return this.allocatedDrpInsuranceEndorsementRatios?.reduce<number | null>((acc, endorsement) => {
			const derivedDrpEndorsement = endorsement.RatioAdjustedDerivedDrpInsuranceEndorsement;
			if (derivedDrpEndorsement?.pnl) {
				acc = (acc ?? 0) + derivedDrpEndorsement?.pnl;
			}
			return acc;
		}, null);
	}

	get insuranceRevenueCwt() {
		if (!this.grossProduction || this.insuranceRevenue == null) return null;
		return (this.insuranceRevenue / this.grossProduction) * 100;
	}

	get expensesTotal() {
		return this.expenses;
	}

	get expensesTotalCwt() {
		if (!this.grossProduction || this.expensesTotal == null) return null;
		return (this.expensesTotal / this.grossProduction) * 100;
	}

	get totalRevenue() {
		if (
			this.insuranceRevenue == null &&
			this.dairyBrokerageRevenue == null &&
			this.milkCheckRevenue == null &&
			this.revenuesTotal === null
		) {
			return null;
		}

		const insuranceRevenue = this.insuranceRevenue ?? 0;
		const dairyBrokerageRevenue = this.dairyBrokerageRevenue ?? 0;
		const milkCheckRevenue = this.milkCheckRevenue ?? 0;
		const revenuesTotal = this.revenuesTotal ?? 0;

		return insuranceRevenue + dairyBrokerageRevenue + milkCheckRevenue + revenuesTotal;
	}

	get totalRevenueCwt() {
		if (!this.grossProduction || this.totalRevenue == null) return null;

		return (this.totalRevenue / this.grossProduction) * 100;
	}

	get netRevenue() {
		if (this.totalRevenue === null && this.expensesTotal == null && this.feedExpenses == null && this.feedBrokerageRevenue == null) {
			return null;
		}

		const totalRevenue = this.totalRevenue ?? 0;
		const expensesTotal = this.expensesTotal ?? 0;
		const feedExpenses = this.feedExpenses ?? 0;
		const feedBrokerageRevenue = this.feedBrokerageRevenue ?? 0;

		return totalRevenue + expensesTotal + feedExpenses + feedBrokerageRevenue;
	}

	get netRevenueCwt() {
		if (!this.grossProduction || this.netRevenue == null) return null;
		return (this.netRevenue / this.grossProduction) * 100;
	}

	get effectiveClassiiiUtilization() {
		if (!this.grossExposure) return null;

		return (this.classiiiExposure ?? 0) / this.grossExposure;
	}

	get productionClassiiiPercentHedged() {
		if (!this.productionClassiiiExposure) return null;

		return (this.classiiiVolumeHedged ?? 0) / this.productionClassiiiExposure;
	}

	get productionClassiiiVolumeOpen() {
		if (this.productionClassiiiExposure == null && this.classiiiVolumeHedged == null) return null;
		return (this.productionClassiiiExposure ?? 0) - (this.classiiiVolumeHedged ?? 0);
	}

	get effectiveClassivUtilization() {
		if (!this.grossExposure) return null;
		return (this.classivExposure ?? 0) / this.grossExposure;
	}

	get productionClassivPercentHedged() {
		if (!this.productionClassivExposure) return null;

		return (this.classivVolumeHedged ?? 0) / this.productionClassivExposure;
	}

	get productionClassivVolumeOpen() {
		if (this.productionClassivExposure == null && this.classivVolumeHedged == null) return null;
		return (this.productionClassivExposure ?? 0) - (this.classivVolumeHedged ?? 0);
	}

	get productionPercentHedged() {
		const exposure = this.grossProduction;
		const volumeHedged = (this.classiiiVolumeHedged ?? 0) + (this.classivVolumeHedged ?? 0);

		if (!exposure) {
			return null;
		}

		return volumeHedged / exposure;
	}
}

export class Quarter {
	date: string;
	// This property of Quarter would be best called "Months" to be more descriptive.
	// However, for emberTable to recognize it as nested rows (tree structure) it needs to be called children
	children: Month[];
	insuranceQuarter: InsuranceQuarterWithMonths;
	isCollapsed: boolean;

	constructor(quarterStartDate: string, months: IMonthObjects) {
		this.date = quarterStartDate;
		this.isCollapsed = true;

		this.children = Object.values(months)
			.map((m) => {
				return new Month(m);
			})
			.sortBy('date');

		this.insuranceQuarter = new InsuranceQuarterWithMonths(
			quarterStartDate,
			this.children.map((m) => m.insuranceMonth),
		);
	}

	get grossProduction() {
		return this.children.reduce<number | null>((total, month) => {
			const monthProduction = month.forecastedMilkProduction?.sum.grossProduction;

			if (monthProduction == null) return total;

			if (total == null) {
				total = monthProduction;
			} else {
				total += monthProduction;
			}

			return total;
		}, null);
	}

	get grossProtein() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.grossProtein == null) return total;

			if (total == null) {
				total = month.grossProtein;
			} else {
				total += month.grossProtein;
			}

			return total;
		}, null);
	}

	get grossButterfat() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.grossButterfat == null) return total;

			if (total == null) {
				total = month.grossButterfat;
			} else {
				total += month.grossButterfat;
			}

			return total;
		}, null);
	}

	get proteinPercentage() {
		if (!this.grossProduction || this.grossProtein == null) return null;
		return this.grossProtein / this.grossProduction;
	}

	get butterfatPercentage() {
		if (!this.grossProduction || this.grossButterfat == null) return null;
		return this.grossButterfat / this.grossProduction;
	}

	get grossExposure() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.grossExposure == null) return total;

			if (total == null) {
				total = month.grossExposure;
			} else {
				total += month.grossExposure;
			}

			return total;
		}, null);
	}

	get milkCheckRevenue() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.milkCheckRevenue == null) return total;

			if (total == null) {
				total = month.milkCheckRevenue;
			} else {
				total += month.milkCheckRevenue;
			}

			return total;
		}, null);
	}

	get milkCheckRevenueCwt() {
		if (!this.grossProduction || this.milkCheckRevenue == null) return null;
		return (this.milkCheckRevenue / this.grossProduction) * 100;
	}

	get brokerageRevenue() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.brokerageRevenue == null) return total;

			if (total == null) {
				total = month.brokerageRevenue;
			} else {
				total += month.brokerageRevenue;
			}

			return total;
		}, null);
	}

	get brokerageRevenueCwt() {
		if (!this.grossProduction || this.brokerageRevenue == null) return null;

		return (this.brokerageRevenue / this.grossProduction) * 100;
	}

	get dairyBrokerageRevenue() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.dairyBrokerageRevenue == null) return total;

			if (total == null) {
				total = month.dairyBrokerageRevenue;
			} else {
				total += month.dairyBrokerageRevenue;
			}

			return total;
		}, null);
	}

	get dairyBrokerageRevenueCwt() {
		if (!this.grossProduction || this.dairyBrokerageRevenue == null) return null;

		return (this.dairyBrokerageRevenue / this.grossProduction) * 100;
	}

	get feedBrokerageRevenue() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.feedBrokerageRevenue == null) return total;

			if (total == null) {
				total = month.feedBrokerageRevenue;
			} else {
				total += month.feedBrokerageRevenue;
			}

			return total;
		}, null);
	}

	get feedBrokerageRevenueCwt() {
		if (!this.grossProduction || this.feedBrokerageRevenue == null) return null;

		return (this.feedBrokerageRevenue / this.grossProduction) * 100;
	}

	get feedExpenses() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.feedExpenses == null) return total;

			if (total == null) {
				total = month.feedExpenses;
			} else {
				total += month.feedExpenses;
			}

			return total;
		}, null);
	}

	get feedExpensesCwt() {
		if (!this.grossProduction || this.feedExpenses == null) return null;

		return (this.feedExpenses / this.grossProduction) * 100;
	}

	get fixedFeedExpenses() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.fixedFeedExpenses == null) return total;

			if (total == null) {
				total = month.fixedFeedExpenses;
			} else {
				total += month.fixedFeedExpenses;
			}

			return total;
		}, null);
	}

	get fixedFeedExpensesCwt() {
		if (!this.grossProduction || this.fixedFeedExpenses == null) return null;

		return (this.fixedFeedExpenses / this.grossProduction) * 100;
	}

	get feedIngredientsUsage() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.feedIngredientsUsage == null) return total;

			// Calculate the sum for the current month
			const monthSum = month.feedIngredientsUsage.reduce<number>((acc, ingredient) => {
				return acc + (ingredient.sum?.forecastedTotalExpenseInUsd || 0);
			}, 0);

			// Add the month's sum to the total
			if (total == null) {
				total = monthSum;
			} else {
				total += monthSum;
			}

			return total;
		}, null);
	}

	get feedIngredientsUsageCwt() {
		if (!this.grossProduction || this.feedIngredientsUsage == null) return null;

		return (this.feedIngredientsUsage / this.grossProduction) * 100;
	}

	get insuranceRevenue() {
		return (
			this.children
				.flatMap((child) => child.allocatedDrpInsuranceEndorsementRatios)
				?.reduce<number | null>((acc, endorsement) => {
					const derivedDrpEndorsement = endorsement.RatioAdjustedDerivedDrpInsuranceEndorsement;
					if (derivedDrpEndorsement?.pnl) {
						acc = (acc ?? 0) + derivedDrpEndorsement?.pnl;
					}
					return acc;
				}, null) ?? null
		);
	}

	get insuranceRevenueCwt() {
		if (!this.grossProduction || this.insuranceRevenue == null) return null;
		return (this.insuranceRevenue / this.grossProduction) * 100;
	}

	get classiiiExposure() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.classiiiExposure == null) return total;

			if (total == null) {
				total = month.classiiiExposure;
			} else {
				total += month.classiiiExposure;
			}
			return total;
		}, null);
	}

	get productionClassiiiExposure() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.productionClassiiiExposure == null) return total;

			if (total == null) {
				total = month.productionClassiiiExposure;
			} else {
				total += month.productionClassiiiExposure;
			}
			return total;
		}, null);
	}

	get classiiiVolumeHedged() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.classiiiVolumeHedged == null) return total;

			if (total == null) {
				total = month.classiiiVolumeHedged;
			} else {
				total += month.classiiiVolumeHedged;
			}
			return total;
		}, null);
	}

	get classiiiVolumeOpen() {
		if (this.classiiiExposure == null && this.classiiiVolumeHedged == null) return null;
		return (this.classiiiExposure ?? 0) - (this.classiiiVolumeHedged ?? 0);
	}

	get productionClassiiiVolumeOpen() {
		if (this.productionClassiiiExposure == null && this.classiiiVolumeHedged == null) return null;
		return (this.productionClassiiiExposure ?? 0) - (this.classiiiVolumeHedged ?? 0);
	}

	get classiiiPercentHedged() {
		if (!this.classiiiExposure) return null;

		return (this.classiiiVolumeHedged ?? 0) / this.classiiiExposure;
	}

	get productionClassiiiPercentHedged() {
		if (!this.productionClassiiiExposure) return null;

		return (this.classiiiVolumeHedged ?? 0) / this.productionClassiiiExposure;
	}

	get effectiveClassiiiUtilization() {
		if (!this.grossExposure) return null;

		return (this.classiiiExposure ?? 0) / this.grossExposure;
	}

	get classivExposure() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.classivExposure == null) return total;

			if (total == null) {
				total = month.classivExposure;
			} else {
				total += month.classivExposure;
			}

			return total;
		}, null);
	}

	get productionClassivExposure() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.productionClassivExposure == null) return total;

			if (total == null) {
				total = month.productionClassivExposure;
			} else {
				total += month.productionClassivExposure;
			}

			return total;
		}, null);
	}

	get classivVolumeHedged() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.classivVolumeHedged == null) return total;

			if (total == null) {
				total = month.classivVolumeHedged;
			} else {
				total += month.classivVolumeHedged;
			}

			return total;
		}, null);
	}

	get classivVolumeOpen() {
		if (this.classivExposure == null && this.classivVolumeHedged == null) return null;
		return (this.classivExposure ?? 0) - (this.classivVolumeHedged ?? 0);
	}

	get productionClassivVolumeOpen() {
		if (this.productionClassivExposure == null && this.classivVolumeHedged == null) return null;
		return (this.productionClassivExposure ?? 0) - (this.classivVolumeHedged ?? 0);
	}

	get classivPercentHedged() {
		if (!this.classivExposure) return null;

		return (this.classivVolumeHedged ?? 0) / this.classivExposure;
	}

	get productionClassivPercentHedged() {
		if (!this.productionClassivExposure) return null;

		return (this.classivVolumeHedged ?? 0) / this.productionClassivExposure;
	}

	get effectiveClassivUtilization() {
		if (!this.grossExposure) return null;
		return (this.classivExposure ?? 0) / this.grossExposure;
	}

	get totalRevenue() {
		if (
			this.insuranceRevenue == null &&
			this.dairyBrokerageRevenue == null &&
			this.milkCheckRevenue == null &&
			this.revenuesTotal === null
		) {
			return null;
		}

		const insuranceRevenue = this.insuranceRevenue ?? 0;
		const dairyBrokerageRevenue = this.dairyBrokerageRevenue ?? 0;
		const milkCheckRevenue = this.milkCheckRevenue ?? 0;
		const revenuesTotal = this.revenuesTotal ?? 0;

		return insuranceRevenue + dairyBrokerageRevenue + milkCheckRevenue + revenuesTotal;
	}

	get totalRevenueCwt() {
		if (!this.grossProduction || this.totalRevenue == null) return null;

		return (this.totalRevenue / this.grossProduction) * 100;
	}

	get netRevenue() {
		if (this.totalRevenue === null && this.expensesTotal == null && this.feedExpenses == null && this.feedBrokerageRevenue == null) {
			return null;
		}

		const totalRevenue = this.totalRevenue ?? 0;
		const expensesTotal = this.expensesTotal ?? 0;
		const feedExpenses = this.feedExpenses ?? 0;
		const feedBrokerageRevenue = this.feedBrokerageRevenue ?? 0;

		return totalRevenue + expensesTotal + feedExpenses + feedBrokerageRevenue;
	}

	get netRevenueCwt() {
		if (!this.grossProduction || this.netRevenue == null) return null;
		return (this.netRevenue / this.grossProduction) * 100;
	}

	get butterExposure() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.butterExposure == null) return total;

			if (total == null) {
				total = month.butterExposure;
			} else {
				total += month.butterExposure;
			}
			return total;
		}, null);
	}

	get butterVolumeHedged() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.butterVolumeHedged == null) return total;

			if (total == null) {
				total = month.butterVolumeHedged;
			} else {
				total += month.butterVolumeHedged;
			}
			return total;
		}, null);
	}

	get butterPercentHedged() {
		if (!this.butterExposure) return null;

		return (this.butterVolumeHedged ?? 0) / this.butterExposure;
	}

	get butterPoundsOpen() {
		if (this.butterExposure == null && this.butterVolumeHedged == null) return null;
		return (this.butterExposure ?? 0) - (this.butterVolumeHedged ?? 0);
	}

	get cheeseExposure() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.cheeseExposure == null) return total;

			if (total == null) {
				total = month.cheeseExposure;
			} else {
				total += month.cheeseExposure;
			}
			return total;
		}, null);
	}

	get cheeseVolumeHedged() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.cheeseVolumeHedged == null) return total;

			if (total == null) {
				total = month.cheeseVolumeHedged;
			} else {
				total += month.cheeseVolumeHedged;
			}
			return total;
		}, null);
	}

	get cheesePercentHedged() {
		if (!this.cheeseExposure) return null;

		return (this.cheeseVolumeHedged ?? 0) / this.cheeseExposure;
	}

	get cheesePoundsOpen() {
		if (this.cheeseExposure == null && this.cheeseVolumeHedged == null) return null;
		return (this.cheeseExposure ?? 0) - (this.cheeseVolumeHedged ?? 0);
	}

	get dryWheyExposure() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.dryWheyExposure == null) return total;

			if (total == null) {
				total = month.dryWheyExposure;
			} else {
				total += month.dryWheyExposure;
			}
			return total;
		}, null);
	}

	get dryWheyVolumeHedged() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.dryWheyVolumeHedged == null) return total;

			if (total == null) {
				total = month.dryWheyVolumeHedged;
			} else {
				total += month.dryWheyVolumeHedged;
			}
			return total;
		}, null);
	}

	get dryWheyPercentHedged() {
		if (!this.dryWheyExposure) return null;

		return (this.dryWheyVolumeHedged ?? 0) / this.dryWheyExposure;
	}

	get dryWheyPoundsOpen() {
		if (this.dryWheyExposure == null && this.dryWheyVolumeHedged == null) return null;
		return (this.dryWheyExposure ?? 0) - (this.dryWheyVolumeHedged ?? 0);
	}

	get nonfatExposure() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.nonfatExposure == null) return total;

			if (total == null) {
				total = month.nonfatExposure;
			} else {
				total += month.nonfatExposure;
			}
			return total;
		}, null);
	}

	get nonfatVolumeHedged() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.nonfatVolumeHedged == null) return total;

			if (total == null) {
				total = month.nonfatVolumeHedged;
			} else {
				total += month.nonfatVolumeHedged;
			}
			return total;
		}, null);
	}

	get nonfatPercentHedged() {
		if (!this.nonfatExposure) return null;

		return (this.nonfatVolumeHedged ?? 0) / this.nonfatExposure;
	}

	get nonfatPoundsOpen() {
		if (this.nonfatExposure == null && this.nonfatVolumeHedged == null) return null;
		return (this.nonfatExposure ?? 0) - (this.nonfatVolumeHedged ?? 0);
	}

	// For now, we're going to calculate % hedged based on LBS hedged.
	// I don't think this is a super exact concept, but it is heavily requested by RJY
	get percentHedged() {
		if (this.nonfatExposure == null && this.dryWheyExposure == null && this.cheeseExposure == null && this.butterExposure == null)
			return null;
		const exposure = (this.nonfatExposure ?? 0) + (this.dryWheyExposure ?? 0) + (this.cheeseExposure ?? 0) + (this.butterExposure ?? 0);
		const volumeHedged =
			(this.nonfatVolumeHedged ?? 0) + (this.dryWheyVolumeHedged ?? 0) + (this.cheeseVolumeHedged ?? 0) + (this.butterVolumeHedged ?? 0);
		if (exposure == 0) {
			return 0;
		}
		return volumeHedged / exposure;
	}

	//
	//	A calculation of percent hedged based on a dairy's production for that period, not the exposure.
	//	(The exposure is different than production due to Class I pricing.)
	//
	//	Like percentHedged(), this is based on LBS hedged.
	//	This doesn't include component excesses / deficits so that this matches the insurance overview page.
	//	This is designed to allow users to get a quick understanding of the amount of insurance still available to purchase.
	//
	get productionPercentHedged() {
		const exposure = this.grossProduction;
		const volumeHedged = (this.classiiiVolumeHedged ?? 0) + (this.classivVolumeHedged ?? 0);

		if (!exposure) {
			return null;
		}

		return volumeHedged / exposure;
	}

	get totalExposureByValue() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.totalExposureByValue == null) return total;

			if (total == null) {
				total = month.totalExposureByValue;
			} else {
				total += month.totalExposureByValue;
			}
			return total;
		}, null);
	}

	get totalValueHedged() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.totalValueHedged == null) return total;

			if (total == null) {
				total = month.totalValueHedged;
			} else {
				total += month.totalValueHedged;
			}
			return total;
		}, null);
	}

	get percentHedgedByValue() {
		if (!this.totalExposureByValue) return null;
		return (this.totalValueHedged ?? 0) / this.totalExposureByValue;
	}

	get operationsTotal() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.operation == null) return total;

			if (total == null) {
				total = month.operation;
			} else {
				total += month.operation;
			}
			return total;
		}, null);
	}

	get operationsTotalCwt() {
		if (!this.grossProduction || this.operationsTotal == null) return null;
		return ((this.operationsTotal ?? 0) / this.grossProduction) * 100;
	}

	get revenuesTotal() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.revenues == null) return total;

			if (total == null) {
				total = month.revenues;
			} else {
				total += month.revenues;
			}
			return total;
		}, null);
	}

	get revenuesTotalCwt() {
		if (!this.grossProduction || this.revenuesTotal == null) return null;
		return (this.revenuesTotal / this.grossProduction) * 100;
	}

	get expensesTotal() {
		return this.children.reduce<number | null>((total, month) => {
			if (month.expenses == null) return total;

			if (total == null) {
				total = month.expenses;
			} else {
				total += month.expenses;
			}
			return total;
		}, null);
	}

	get expensesTotalCwt() {
		if (!this.grossProduction || this.expensesTotal == null) return null;
		return (this.expensesTotal / this.grossProduction) * 100;
	}
}

export interface IMonthObject {
	date: string;
	owner: any;
	classiiiExposure: EntityAllocatedExposureRatio | null;
	classivExposure: EntityAllocatedExposureRatio | null;
	butterExposure: EntityAllocatedExposureRatio | null;
	cheeseExposure: EntityAllocatedExposureRatio | null;
	nonfatExposure: EntityAllocatedExposureRatio | null;
	dryWheyExposure: EntityAllocatedExposureRatio | null;
	forecastedMilkProduction: AggregateForecastedMilkProductionByMonthDTO | null;
	brokeragePosition: NumericAggregateCurrentAllocationPositionDTO | null;
	dairyBrokeragePosition: NumericAggregateCurrentAllocationPositionDTO | null;
	feedBrokeragePosition: NumericAggregateCurrentAllocationPositionDTO | null;
	milkCheckUtilizations: ForecastedMilkUtilization | null;
	classiiiFuture: Future | null;
	classivFuture: Future | null;
	butterFuture: Future | null;
	cheeseFuture: Future | null;
	nonfatDryMilkFuture: Future | null;
	dryWheyFuture: Future | null;
	advancedButterFuture: Future | null;
	advancedNonfatDryMilkFuture: Future | null;
	advancedCheeseFuture: Future | null;
	advancedDryWheyFuture: Future | null;
	soybeanMealFuture: Future | null;
	cornFuture: Future | null;
	operation: number | null | undefined;
	expenses: number | null | undefined;
	fixedFeedExpenses: number | null | undefined;
	revenues: number | null | undefined;
	classiiiBrokerageHedgedVolume: number | null;
	classivBrokerageHedgedVolume: number | null;
	classiDifferential: number | undefined;
	feedIngredientsUsage: AggregateFeedIngredientForecastedUsageDTO[];
	everAgBasis: number;
	monthlyCalculatedAmount: number | null | undefined;
	allocatedDrpInsuranceEndorsementRatios: InsuranceEndorsementAllocationRatio[];
	feedFills: AggregateFeedIngredientConsumedAndPurchasedVolumeDTO[];
}

export interface IMonthObjects {
	[key: string]: IMonthObject;
}

export interface IQuarterObject {
	allocatedDrpInsuranceEndorsementRatios: InsuranceEndorsementAllocationRatio[];
	children: IMonthObjects;
}

export interface IQuarterObjects {
	[key: string]: IQuarterObject;
}

export function getOrCreateQuarter(quarterStartDate: string, map: IQuarterObjects): IQuarterObject {
	let quarter = map[quarterStartDate];

	if (quarter === undefined) {
		quarter = {
			allocatedDrpInsuranceEndorsementRatios: [],
			children: {},
		};
		map[quarterStartDate] = quarter;
	}

	return quarter;
}

export function getOrCreateMonth(monthStartDate: string, quarter: IQuarterObject, owner: any): IMonthObject {
	let month = quarter.children[monthStartDate];

	if (month === undefined) {
		month = {
			date: monthStartDate,
			owner,
			everAgBasis: 0,
			monthlyCalculatedAmount: null,
			classiiiExposure: null,
			classivExposure: null,
			butterExposure: null,
			cheeseExposure: null,
			nonfatExposure: null,
			dryWheyExposure: null,
			forecastedMilkProduction: null,
			brokeragePosition: null,
			dairyBrokeragePosition: null,
			feedBrokeragePosition: null,
			milkCheckUtilizations: null,
			classiiiFuture: null,
			classivFuture: null,
			butterFuture: null,
			advancedButterFuture: null,
			advancedNonfatDryMilkFuture: null,
			advancedCheeseFuture: null,
			advancedDryWheyFuture: null,
			soybeanMealFuture: null,
			cornFuture: null,
			classiDifferential: undefined,
			cheeseFuture: null,
			dryWheyFuture: null,
			nonfatDryMilkFuture: null,
			operation: null,
			expenses: null,
			fixedFeedExpenses: null,
			revenues: null,
			classiiiBrokerageHedgedVolume: null,
			classivBrokerageHedgedVolume: null,
			feedIngredientsUsage: [],
			allocatedDrpInsuranceEndorsementRatios: [],
			feedFills: [],
		};
		quarter.children[monthStartDate] = month;
	}

	return month;
}

enum HedgeChart {
	MilkHedgedTotal = 'Milk % Hedged (Total)',
	MilkHedgedInsurance = 'Milk % Hedged (Insurance)',
	MilkHedgedBrokerage = 'Milk % Hedged (Brokerage)',
}

enum PnlChart {
	Revenues = 'Revenues',
	Expenses = 'Expenses',
	NetPnl = 'Net P&L',
}

export default class BusinessDashboard extends Controller {
	@tracked projectedRevenueSorts: SortObj[] = [{ valuePath: 'date', isAscending: true }];
	@tracked percentHedgedSorts: SortObj[] = [{ valuePath: 'date', isAscending: true }];
	@tracked unit: 'CWT' | 'Total' = 'Total';
	@tracked activePnlChart: PnlChart = PnlChart.Revenues;
	@tracked activeHedgeChart: HedgeChart = HedgeChart.MilkHedgedTotal;
	@tracked startDate = DateTime.now().minus({ months: 2 }).startOf('quarter').toISODate();
	@tracked endDate = DateTime.now().plus({ years: 2 }).endOf('quarter').toISODate();

	id = guidFor(this);
	historicalChartsNumberOfYears = 10;

	declare model: DashboardModel;

	queryParams = ['startDate', 'endDate'];

	get lastUpdatedAtString() {
		const lastUpdatedAt = DateTime.fromISO(this.model.lastUpdatedAt);
		return `Last updated: ${lastUpdatedAt.toLocaleString(DateTime.DATE_SHORT)} at ${lastUpdatedAt.toLocaleString(DateTime.TIME_SIMPLE)}`;
	}

	get chartOptions() {
		return [PnlChart.Revenues, PnlChart.Expenses, PnlChart.NetPnl];
	}

	get hedgedChartOptions() {
		return [HedgeChart.MilkHedgedTotal, HedgeChart.MilkHedgedInsurance, HedgeChart.MilkHedgedBrokerage];
	}

	get milkHedgedTotalChartId() {
		return `milk-percentage-hedged-chart-${this.id}`;
	}

	get insuredMilkHedgedChartId() {
		return `insured-milk-percentage-hedged-chart-${this.id}`;
	}

	get milkHedgedTotalChartLegendId() {
		return `milk-percentage-hedged-chart-legend-${this.id}`;
	}

	get insuredMilkHedgedLegendId() {
		return `insured-milk-percentage-hedged-chart-legend-${this.id}`;
	}

	get brokerageMilkHedgedChartId() {
		return `brokerage-milk-hedged-chart-${this.id}`;
	}

	get brokerageMilkHedgedLegendId() {
		return `brokerage-milk-hedged-chart-legend-${this.id}`;
	}

	get expensesChartId() {
		return `expenses-chart-${this.id}`;
	}

	get revenuesChartId() {
		return `revenues-chart-${this.id}`;
	}

	get netPnlChartId() {
		return `net-pnl-chart-${this.id}`;
	}

	get netPnlChartLegendId() {
		return `net-pnl-chart-legend-${this.id}`;
	}

	get classIIIProductID() {
		return this.model.ClassIIIFutures[0].Product.id;
	}

	get classIVProductID() {
		return this.model.ClassIVFutures[0].Product.id;
	}

	get cornProductID() {
		return this.model.CornFutures[0].Product.id;
	}

	get soybeanMealProductID() {
		return this.model.SoybeanMealFutures[0].Product.id;
	}

	get pnlYScaleMin() {
		if (this.activePnlChartIsEmpty) return undefined;
		let min = 0;
		let minDataset = 0;

		// Had to split this out to get the type correct
		if (this.activePnlChart === PnlChart.Expenses) {
			min = Math.min(...this.expensesChartData['CME (Feed)']);
			minDataset = Math.min(
				...this.expensesChartData['Feed Ingredients'],
				...this.expensesChartData['Fixed Feed Costs'],
				...this.expensesChartData['Non-Feed Expenses'],
			);
		} else if (this.activePnlChart === PnlChart.Revenues) {
			min = Math.min(...this.revenuesChartData['Milk Check']);
			minDataset = Math.min(
				...this.revenuesChartData['DRP'],
				...this.revenuesChartData['CME (Dairy)'],
				...this.revenuesChartData['Non-Milk Revenues'],
			);
		} else {
			min = Math.min(...this.netPnlChartRawData['Total Revenues']);
			minDataset = Math.min(...this.netPnlChartRawData['Total Expenses'], ...this.netPnlChartRawData['Net P & L']);
		}

		// If dataset contains a negative value use that as the min, otherwise it would not be visible.
		if (minDataset < 0) {
			min = minDataset;
		}

		const offset = Math.abs(this.unit === 'CWT' ? 2 : min * 0.5);

		if (min < 0) {
			return min;
		} else if (min - offset < 0) {
			return 0;
		} else {
			return min - offset;
		}
	}

	get activePnlChartId() {
		if (this.activePnlChart === PnlChart.Expenses) {
			return this.expensesChartId;
		} else if (this.activePnlChart === PnlChart.Revenues) {
			return this.revenuesChartId;
		} else {
			return this.netPnlChartId;
		}
	}

	get activeHedgedChartId() {
		if (this.activeHedgeChart === HedgeChart.MilkHedgedTotal) {
			return this.milkHedgedTotalChartId;
		} else if (this.activeHedgeChart === HedgeChart.MilkHedgedInsurance) {
			return this.insuredMilkHedgedChartId;
		} else if (this.activeHedgeChart === HedgeChart.MilkHedgedBrokerage) {
			return this.brokerageMilkHedgedChartId;
		} else {
			return '';
		}
	}

	get activeHedgedChartLegendId() {
		if (this.activeHedgeChart === HedgeChart.MilkHedgedTotal) {
			return this.milkHedgedTotalChartLegendId;
		} else if (this.activeHedgeChart === HedgeChart.MilkHedgedInsurance) {
			return this.insuredMilkHedgedLegendId;
		} else if (this.activeHedgeChart === HedgeChart.MilkHedgedBrokerage) {
			return this.brokerageMilkHedgedLegendId;
		} else {
			return '';
		}
	}

	get activePnlChartIsEmpty() {
		const isEmpty = (data: Record<string, number[]>) => !Object.keys(data).some((key) => data[key].some((v) => !!v));

		if (this.activePnlChart === PnlChart.Expenses) {
			return isEmpty(this.expensesChartData);
		} else if (this.activePnlChart === PnlChart.Revenues) {
			return isEmpty(this.revenuesChartData);
		} else {
			return isEmpty(this.netPnlChartRawData);
		}
	}

	get quarterChartLabels() {
		return this.quarters.map((quarter: Quarter) => {
			const luxonDate = DateTime.fromISO(quarter.date);
			return `${luxonDate.year} Q${luxonDate.quarter}`;
		});
	}

	get monthChartLabels() {
		return this.quarters.flatMap((quarter: Quarter) => {
			return quarter.children.map((month) => {
				const luxonDate = DateTime.fromISO(month.date);
				return luxonDate.toFormat('LLL yyyy');
			});
		});
	}

	get revenuesChartData() {
		return {
			'Milk Check': this.quarters.map((v) => (this.unit === 'CWT' ? v.milkCheckRevenueCwt : v.milkCheckRevenue) ?? 0),
			DRP: this.quarters.map((v) => (this.unit === 'CWT' ? v.insuranceRevenueCwt : v.insuranceRevenue) ?? 0),
			'CME (Dairy)': this.quarters.map((v) => (this.unit === 'CWT' ? v.dairyBrokerageRevenueCwt : v.dairyBrokerageRevenue) ?? 0),
			'Non-Milk Revenues': this.quarters.map((v) => (this.unit === 'CWT' ? v.revenuesTotalCwt : v.revenuesTotal) ?? 0),
		};
	}

	get expensesChartData() {
		// Flip expense values sign to make the graph clearer
		// feed ingredients and fixed feed are meant to be positive values, so don't flip those
		return {
			'CME (Feed)': this.quarters.map((v) => {
				const value = this.unit === 'CWT' ? v.feedBrokerageRevenueCwt : v.feedBrokerageRevenue;
				return value ? value * -1 : 0;
			}),
			'Feed Ingredients': this.quarters.map((v) => {
				const value = this.unit === 'CWT' ? v.feedIngredientsUsageCwt : v.feedIngredientsUsage;
				return value ? value : 0;
			}),
			'Fixed Feed Costs': this.quarters.map((v) => {
				const value = this.unit === 'CWT' ? v.fixedFeedExpensesCwt : v.fixedFeedExpenses;
				return value ? value : 0;
			}),
			'Non-Feed Expenses': this.quarters.map((v) => {
				const value = this.unit === 'CWT' ? v.expensesTotalCwt : v.expensesTotal;
				return value ? value * -1 : 0;
			}),
		};
	}

	get netPnlChartRawData() {
		const totalRevenues: number[] = [];
		const totalExpenses: number[] = [];
		const netPnLs: number[] = [];

		this.quarters.forEach((quarter) => {
			let revenuesTotal = quarter.totalRevenue ?? 0;
			let expensesTotal = (quarter.feedBrokerageRevenue ?? 0) + (quarter.expensesTotal ?? 0) + (quarter.feedExpenses ?? 0);
			let netPnlTotal = quarter.netRevenue ?? 0;

			if (this.unit === 'CWT') {
				revenuesTotal = quarter.totalRevenueCwt ?? 0;
				expensesTotal = (quarter.feedBrokerageRevenueCwt ?? 0) + (quarter.expensesTotalCwt ?? 0) + (quarter.feedExpensesCwt ?? 0);
				netPnlTotal = quarter.netRevenueCwt ?? 0;
			}

			totalRevenues.push(revenuesTotal);
			totalExpenses.push(expensesTotal);
			netPnLs.push(netPnlTotal);
		});

		return {
			'Total Revenues': totalRevenues,
			'Total Expenses': totalExpenses,
			'Net P & L': netPnLs,
		};
	}

	get netPnlChartData(): ChartData<'line' | 'bar'> {
		return {
			labels: this.quarterChartLabels,
			datasets: [
				{
					label: 'Total Expenses',
					data: this.netPnlChartRawData['Total Expenses'],
					borderColor: getCSSVariable('--brand-lime-40'),
					backgroundColor: getCSSVariable('--brand-lime-40'),
					order: 2,
				},
				{
					label: 'Total Revenues',
					data: this.netPnlChartRawData['Total Revenues'],
					borderColor: getCSSVariable('--brand-interactive-blue-70'),
					backgroundColor: getCSSVariable('--brand-interactive-blue-70'),
					order: 1,
				},
				{
					type: 'line',
					label: 'Net P & L',
					data: this.netPnlChartRawData['Net P & L'],
					borderColor: getCSSVariable('--brand-orange-40'),
					backgroundColor: getCSSVariable('--brand-orange-40'),
					pointRadius: 4,
					pointBackgroundColor: '#E3E3E3',
					order: 0,
				},
			],
		};
	}

	get netPnlChartOptions(): ChartOptions<'bar'> {
		const customTooltipOptions: CustomTooltipOptions = {
			valueFormatter: (val: number | string) => {
				if (val == null) return '-';
				if (typeof val === 'string') return val;
				return Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					// If a total value falls between -1 and 1 or CWT mode, show 2 decimal places. Otherwise show 0
					minimumFractionDigits: (val !== 0 && val > -1 && val < 1) || this.unit === 'CWT' ? 2 : 0,
					maximumFractionDigits: (val !== 0 && val > -1 && val < 1) || this.unit === 'CWT' ? 2 : 0,
					currencySign: 'accounting',
				}).format(val);
			},
		};

		const chartOptions: ChartOptions<'bar'> = {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				tooltip: {
					mode: 'index',
					external: getCustomTooltip(customTooltipOptions),
					enabled: false,
					displayColors: false,
				},
				legend: {
					display: false,
				},
			},
			interaction: {
				intersect: false,
				mode: 'index',
			},
			scales: {
				x: {
					grid: {
						display: false,
					},
					stacked: true,
				},
				y: {
					ticks: {
						callback: (tickValue) => {
							if (typeof tickValue === 'string') {
								return tickValue;
							} else {
								return Intl.NumberFormat('en-US', {
									minimumFractionDigits: this.unit === 'CWT' ? 2 : undefined,
									maximumFractionDigits: this.unit === 'CWT' ? 2 : undefined,
									style: 'currency',
									currency: 'USD',
									currencySign: 'accounting',
									notation: 'compact',
									compactDisplay: 'short',
								}).format(tickValue);
							}
						},
					},
					min: this.pnlYScaleMin,
				},
			},
		};
		return chartOptions;
	}

	get milkHedgedTotalRawData() {
		type DatasetLabel = 'Class III % Hedged' | 'Class IV % Hedged' | 'Net % Hedged';
		const data: {
			[key in DatasetLabel]: number[];
		} = {
			'Class III % Hedged': [],
			'Class IV % Hedged': [],
			'Net % Hedged': [],
		};

		// Show the next 5 quarters of Milk Hedged Data
		this.quarters.slice(0, 5).forEach((quarter) => {
			quarter.children.forEach((month) => {
				const classiiiVolumeHedged = month.classiiiVolumeHedged ?? 0;
				const classivVolumeHedged = month.classivVolumeHedged ?? 0;

				const classiiiProductionPercentHedged = month.productionClassiiiExposure
					? classiiiVolumeHedged / month.productionClassiiiExposure
					: null;

				const classivProductionPercentHedged = month.productionClassivExposure
					? classivVolumeHedged / month.productionClassivExposure
					: null;

				const netProductionPercentHedged = month.grossProduction
					? (classiiiVolumeHedged + classivVolumeHedged) / month.grossProduction
					: null;

				data['Class III % Hedged'].push(classiiiProductionPercentHedged ?? 0);
				data['Class IV % Hedged'].push(classivProductionPercentHedged ?? 0);
				data['Net % Hedged'].push(netProductionPercentHedged ?? 0);
			});
		});

		return data;
	}

	get milkHedgedTotalChartLabels() {
		// Show the next 5 quarters of Milk Hedged Data
		return this.quarters.slice(0, 5).flatMap((quarter: Quarter) => {
			return quarter.children.map((month) => {
				const luxonDate = DateTime.fromISO(month.date);
				return luxonDate.toFormat('LLL yyyy');
			});
		});
	}

	get milkHedgedTotalChartData(): ChartData<'bar' | 'line'> {
		return {
			labels: this.milkHedgedTotalChartLabels,
			datasets: [
				{
					type: 'bar',
					label: 'Class III % Hedged',
					data: this.milkHedgedTotalRawData['Class III % Hedged'],
					borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
					backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
					order: 1,
				},
				{
					type: 'bar',
					label: 'Class IV % Hedged',
					data: this.milkHedgedTotalRawData['Class IV % Hedged'],
					borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-purple-50'),
					backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-purple-50'),
					order: 1,
				},
				{
					type: 'line',
					label: 'Net % Hedged',
					data: this.milkHedgedTotalRawData['Net % Hedged'],
					borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-50'),
					backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-50'),
					pointRadius: isTouchDevice() ? 0 : 4,
					pointBackgroundColor: '#E3E3E3',
					order: 0,
				},
			],
		};
	}

	get insuredMilkHedgedRawData() {
		type DatasetLabel = 'Insured Class III % Hedged' | 'Insured Class IV % Hedged' | 'Net Insured % Hedged';

		const data: {
			[key in DatasetLabel]: number[];
		} = {
			'Insured Class III % Hedged': [],
			'Insured Class IV % Hedged': [],
			'Net Insured % Hedged': [],
		};

		// Show the next 5 quarters of Insurance Data
		this.insuranceQuarters.slice(0, 5).forEach((v) => {
			data['Insured Class III % Hedged'].push(v.classIiiPercentInsured ?? 0);
			data['Insured Class IV % Hedged'].push(v.classIvPercentInsured ?? 0);
			data['Net Insured % Hedged'].push(v.percentInsured ?? 0);
		});

		return data;
	}

	get insuredMilkHedgedChartLabels() {
		// Show the next 5 quarters of Insurance Data
		return this.quarterChartLabels.slice(0, 5);
	}

	get insuredMilkHedgedChartData(): ChartData<'bar' | 'line'> {
		return {
			labels: this.insuredMilkHedgedChartLabels,
			datasets: [
				{
					label: 'Insured Class III % Hedged',
					data: this.insuredMilkHedgedRawData['Insured Class III % Hedged'],
					borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
					backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
					order: 2,
				},
				{
					label: 'Insured Class IV % Hedged',
					data: this.insuredMilkHedgedRawData['Insured Class IV % Hedged'],
					borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-purple-50'),
					backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-purple-50'),
					order: 1,
				},
				{
					type: 'line',
					label: 'Net Insured % Hedged',
					data: this.insuredMilkHedgedRawData['Net Insured % Hedged'],
					borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-50'),
					backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-50'),
					pointRadius: isTouchDevice() ? 0 : 4,
					pointBackgroundColor: '#E3E3E3',
					order: 0,
				},
			],
		};
	}

	get brokerageMilkHedgedChartData(): ChartData<'line'> {
		return {
			labels: this.brokerageMilkHedgedChartLabels,
			datasets: [
				{
					label: 'Class III % Hedged',
					data: this.brokerageMilkHedgedRawData['Class III % Hedged'],
					borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
					backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
					order: 1,
				},
				{
					label: 'Class IV % Hedged',
					data: this.brokerageMilkHedgedRawData['Class IV % Hedged'],
					borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-purple-50'),
					backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-purple-50'),
					order: 1,
				},
				{
					label: 'Net % Hedged',
					type: 'line',
					data: this.brokerageMilkHedgedRawData['Net % Hedged'],
					borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-50'),
					backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-50'),
					pointRadius: isTouchDevice() ? 0 : 4,
					pointBackgroundColor: '#E3E3E3',
					order: 0,
				},
			],
		};
	}

	get brokerageMilkHedgedRawData() {
		type DatasetLabel = 'Class III % Hedged' | 'Class IV % Hedged' | 'Net % Hedged';

		const data: {
			[key in DatasetLabel]: number[];
		} = {
			'Class III % Hedged': [],
			'Class IV % Hedged': [],
			'Net % Hedged': [],
		};

		// Show the next 5 quarters of Brokerage Data
		this.quarters.slice(0, 5).forEach((quarter) => {
			quarter.children.forEach((month) => {
				data['Class III % Hedged'].push(month.classiiiBrokerageHedgedPercent ?? 0);
				data['Class IV % Hedged'].push(month.classivBrokerageHedgedPercent ?? 0);
				data['Net % Hedged'].push(month.milkNetBrokerageHedgedPercent ?? 0);
			});
		});

		return data;
	}

	get brokerageMilkHedgedChartLabels() {
		// Show the next 5 quarters of Brokerage Data
		return this.quarters.slice(0, 5).flatMap((quarter: Quarter) => {
			return quarter.children.map((month) => {
				const luxonDate = DateTime.fromISO(month.date);
				return luxonDate.toFormat('LLL yyyy');
			});
		});
	}

	get netPnlChartPlugins() {
		const chartId = this.netPnlChartId;
		const legendId = this.netPnlChartLegendId;
		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId),
			},
		];
	}

	get percentHedgedPlugins() {
		const chartId = this.activeHedgedChartId;
		const legendId = this.activeHedgedChartLegendId;
		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId),
			},
		];
	}

	get insuredMilkHedgedChartOptions(): ChartOptions<'bar'> {
		const tooltipOptions: CustomTooltipOptions = {
			valueFormatter: (val: number | string) => {
				if (val == null) return '-';
				if (typeof val === 'string') return val;
				return new Intl.NumberFormat('en-US', {
					style: 'percent',
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				}).format(val);
			},
		};

		return {
			maintainAspectRatio: false,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			datasets: {
				bar: {
					borderRadius: 2,
					categoryPercentage: 0.8,
				},
			},
			layout: {
				padding: {
					left: 16,
					right: 16,
				},
			},
			scales: {
				y: {
					ticks: {
						autoSkip: false,
						font: {
							size: 12,
						},
						callback: (tickValue) => {
							if (typeof tickValue === 'string') {
								return tickValue;
							} else {
								return new Intl.NumberFormat('en-us', { style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(
									tickValue,
								);
							}
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
					},
				},
				x: {
					ticks: {
						autoSkip: false,
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
						font: {
							size: 12,
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
			},
		};
	}

	get percentHedgedChartOptions(): ChartOptions<'bar' | 'line'> {
		const tooltipOptions: CustomTooltipOptions = {
			valueFormatter: (val: number | string) => {
				if (val == null) return '-';
				if (typeof val === 'string') return val;
				return new Intl.NumberFormat('en-US', {
					style: 'percent',
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				}).format(val);
			},
		};

		return {
			maintainAspectRatio: false,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					left: 16,
					right: 16,
				},
			},
			datasets: {
				bar: {
					borderRadius: 2,
					categoryPercentage: 0.8,
				},
			},
			scales: {
				y: {
					min: 0,
					ticks: {
						autoSkip: false,
						font: {
							size: 12,
						},
						callback: (tickValue) => {
							if (typeof tickValue === 'string') {
								return tickValue;
							} else {
								return new Intl.NumberFormat('en-us', { style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(
									tickValue,
								);
							}
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
					},
				},
				x: {
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
			},
		};
	}

	get percentHedgedColumns(): TableColumn[] {
		return [
			{
				id: '77f77205-8ec5-46b8-9bbb-a087d7cfb43c',
				name: 'Date',
				valuePath: 'date',
				width: 90,
				cellComponent: CellComponents.QuarterFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b6581e84-8447-4bfb-b74c-0ff07b965ec4',
				name: 'Production',
				valuePath: 'grossProduction',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '63b8e098-dac7-4894-acd4-8033b07bfde4',
				name: 'Class III',
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'fd66c5b9-494d-404b-92a4-a38581da02dd',
						name: 'Util %',
						valuePath: 'effectiveClassiiiUtilization',

						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '0d3d200c-56b3-4a0e-a8a7-86d13380808d',
						name: 'LBS',
						valuePath: 'productionClassiiiExposure',
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						componentArgs: {
							maximumFractionDigits: 0,
						},
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '58257200-f036-457f-b58f-f675dfd75147',
						name: '% Hedged',
						valuePath: 'productionClassiiiPercentHedged',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '6a7c2987-37fb-4692-a75a-2df9330393f6',
						name: 'LBS Open',
						valuePath: 'productionClassiiiVolumeOpen',
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						componentArgs: {
							maximumFractionDigits: 0,
						},
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '75d7c585-1ade-4b9e-8bf7-3d3d16f57735',
				name: 'Class IV',
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '8e3389cf-5e08-41c7-a4de-ffff5782b673',
						name: 'Util %',
						valuePath: 'effectiveClassivUtilization',
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						componentArgs: {
							style: 'percent',
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '7898059f-2632-4ae8-9790-d190f1874695',
						name: 'LBS',
						valuePath: 'productionClassivExposure',
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						componentArgs: {
							maximumFractionDigits: 0,
						},
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'e60d5501-85e9-44f3-a18e-78b2ff750bc0',
						name: '% Hedged',
						valuePath: 'productionClassivPercentHedged',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '4f0fe479-0ca8-4fa8-b9f2-6bb93f3c65a2',
						name: 'LBS Open',
						valuePath: 'productionClassivVolumeOpen',
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						componentArgs: {
							maximumFractionDigits: 0,
						},
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9d427842-61d7-4bcb-bb14-067756401e24',
				name: 'Net % Hedged',
				valuePath: 'productionPercentHedged',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get projectedRevenueColumns(): TableColumn[] {
		return [
			{
				id: 'cebc8b62-084f-46fc-8247-4294bb09a1ba',
				name: 'Date',
				valuePath: 'date',
				width: 90,
				cellComponent: CellComponents.QuarterFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '01fab77a-20ec-4549-b1f9-1e33bfc88d4f',
				name: 'Milk Check',
				valuePath: this.unit === 'CWT' ? 'milkCheckRevenueCwt' : 'milkCheckRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e12e7dee-6db8-4bfa-8ed0-987818b648be',
				name: 'DRP',

				valuePath: this.unit === 'CWT' ? 'insuranceRevenueCwt' : 'insuranceRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '467f0487-09bc-40fd-896f-aea5d82d0f2c',
				name: 'CME (Dairy)',
				valuePath: this.unit === 'CWT' ? 'dairyBrokerageRevenueCwt' : 'dairyBrokerageRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '20a02df8-1a4c-440a-9d77-5933d43c331e',
				name: 'Non-Milk Revenues',
				valuePath: this.unit === 'CWT' ? 'revenuesTotalCwt' : 'revenuesTotal',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				width: 120,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'df34a119-1522-46e7-a0a4-26380c58eab1',
				name: 'CME (Feed)',
				valuePath: this.unit === 'CWT' ? 'feedBrokerageRevenueCwt' : 'feedBrokerageRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f78c576d-d19b-4744-98e4-06e156e33d31',
				name: 'Feed Expenses',
				valuePath: this.unit === 'CWT' ? 'feedExpensesCwt' : 'feedExpenses',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8106632d-32f7-47ab-b470-aab72c28ecd9',
				name: 'Non-Feed Expenses',
				valuePath: this.unit === 'CWT' ? 'expensesTotalCwt' : 'expensesTotal',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				width: 120,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd79f2008-2a36-43de-bd72-836b4d9e065f',
				name: 'Net P/L',
				valuePath: this.unit === 'CWT' ? 'netRevenueCwt' : 'netRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get quarters() {
		const quarterMap: IQuarterObjects = {};

		this.model.InsuranceEndorsementAllocationRatios.forEach((endorsementRatio: InsuranceEndorsementAllocationRatio) => {
			const month = endorsementRatio.effectiveHedgeDate;
			if (month == undefined) return;

			const quarterStartDate = DateTime.fromISO(month).startOf('quarter').toISODate();
			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);

			const monthObject = getOrCreateMonth(month, quarter, getOwner(this));

			monthObject.allocatedDrpInsuranceEndorsementRatios.push(endorsementRatio);
			quarter.allocatedDrpInsuranceEndorsementRatios.push(endorsementRatio);
		});

		this.model.ForecastedMilkProductionByMonths.forEach((production: ForecastedMilkProductionByMonth) => {
			if (production.date == undefined) return;

			const monthDate = DateTime.fromISO(production.date);

			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));

			if (month.forecastedMilkProduction?.sum) {
				// Component Production
				month.forecastedMilkProduction.sum.grossProduction =
					(month.forecastedMilkProduction.sum.grossProduction ?? 0) + production.grossProduction;
				month.forecastedMilkProduction.sum.grossButterfatProduction =
					(month.forecastedMilkProduction.sum.grossButterfatProduction ?? 0) + production.grossButterfatProduction;
				month.forecastedMilkProduction.sum.grossProteinProduction =
					(month.forecastedMilkProduction.sum.grossProteinProduction ?? 0) + production.grossProteinProduction;
				month.forecastedMilkProduction.sum.grossOtherSolidsProduction =
					(month.forecastedMilkProduction.sum.grossOtherSolidsProduction ?? 0) + production.grossOtherSolidsProduction;

				// Class Production
				month.forecastedMilkProduction.sum.grossClassiProduction =
					(month.forecastedMilkProduction.sum.grossClassiProduction ?? 0) + production.grossClassiProduction;
				month.forecastedMilkProduction.sum.grossClassiiProduction =
					(month.forecastedMilkProduction.sum.grossClassiiProduction ?? 0) + production.grossClassiiProduction;
				month.forecastedMilkProduction.sum.grossClassiiiProduction =
					(month.forecastedMilkProduction.sum.grossClassiiiProduction ?? 0) + production.grossClassiiiProduction;
				month.forecastedMilkProduction.sum.grossClassivProduction =
					(month.forecastedMilkProduction.sum.grossClassivProduction ?? 0) + production.grossClassivProduction;
			} else {
				month.forecastedMilkProduction = {
					sum: {
						grossProduction: production.grossProduction,
						grossButterfatProduction: production.grossButterfatProduction,
						grossProteinProduction: production.grossProteinProduction,
						grossOtherSolidsProduction: production.grossOtherSolidsProduction,
						grossClassiProduction: production.grossClassiProduction,
						grossClassiiProduction: production.grossClassiiProduction,
						grossClassiiiProduction: production.grossClassiiiProduction,
						grossClassivProduction: production.grossClassivProduction,
					},
				} as AggregateForecastedMilkProductionByMonthDTO;
			}
		});

		calculateUtilizationValuesFromForecastedProduction(this.model.ForecastedMilkProductionByMonths).forEach((v) => {
			if (v.date == undefined) return;
			const monthDate = DateTime.fromISO(v.date);

			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));

			month.milkCheckUtilizations = {
				classiUtilization: v.classIUtilization,
				classiiUtilization: v.classIIUtilization,
				classiiiUtilization: v.classIIIUtilization,
				classivUtilization: v.classIVUtilization,
				grossClassiPounds: v.grossClassIProduction,
				grossClassiiPounds: v.grossClassIIProduction,
				grossClassiiiPounds: v.grossClassIIIProduction,
				grossClassivPounds: v.grossClassIVProduction,
			} as ForecastedMilkUtilization;
			month.classiDifferential = v.classIDifferential;
		});

		this.model.AggregateCurrentAllocationPositions.forEach((aggregate: AggregateCurrentAllocationPositionDTO) => {
			if (aggregate.effectiveHedgeDate == undefined) return;

			const monthDate = DateTime.fromISO(aggregate.effectiveHedgeDate);

			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.brokeragePosition = aggregate.sum;
		});

		this.model.AggregateDairyBrokerageHedgedValues.forEach(
			(forecastedHedgedAndCappedVolume: AggregateAllocatedForecastedHedgedAndCappedVolumeDTO) => {
				if (forecastedHedgedAndCappedVolume.date == undefined) return;

				const monthDate = DateTime.fromISO(forecastedHedgedAndCappedVolume.date).startOf('month');

				const quarterStartDate = monthDate.startOf('quarter').toISODate();

				const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
				const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));

				if (forecastedHedgedAndCappedVolume?.Product && forecastedHedgedAndCappedVolume.Product.slug === 'us-dairy-class-iii') {
					month.classiiiBrokerageHedgedVolume = forecastedHedgedAndCappedVolume?.sum.naturallyLongHedged ?? 0;
				} else if (forecastedHedgedAndCappedVolume?.Product && forecastedHedgedAndCappedVolume.Product.slug === 'us-dairy-class-iv') {
					month.classivBrokerageHedgedVolume = forecastedHedgedAndCappedVolume?.sum.naturallyLongHedged ?? 0;
				}
			},
		);

		this.model.AggregateDairyPositions.forEach((aggregate: AggregateCurrentAllocationPositionDTO) => {
			if (aggregate.effectiveHedgeDate == undefined) return;

			const monthDate = DateTime.fromISO(aggregate.effectiveHedgeDate);

			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.dairyBrokeragePosition = aggregate.sum;
		});

		this.model.AggregateFeedPositions.forEach((aggregate: AggregateCurrentAllocationPositionDTO) => {
			if (aggregate.effectiveHedgeDate == undefined) return;

			const monthDate = DateTime.fromISO(aggregate.effectiveHedgeDate);

			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.feedBrokeragePosition = aggregate.sum;
		});

		this.model.ClassiiiExposure.forEach((aggregate: EntityAllocatedExposureRatio) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.classiiiExposure = aggregate;
		});

		this.model.ClassivExposure.forEach((aggregate: EntityAllocatedExposureRatio) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.classivExposure = aggregate;
		});

		this.model.CheeseExposure.forEach((aggregate: EntityAllocatedExposureRatio) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.cheeseExposure = aggregate;
		});

		this.model.ButterExposure.forEach((aggregate: EntityAllocatedExposureRatio) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.butterExposure = aggregate;
		});

		this.model.DryWheyExposure.forEach((aggregate: EntityAllocatedExposureRatio) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.dryWheyExposure = aggregate;
		});

		this.model.NonfatExposure.forEach((aggregate: EntityAllocatedExposureRatio) => {
			if (aggregate.date == undefined) return;

			const quarterStartDate = DateTime.fromISO(aggregate.date).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(aggregate.date, quarter, getOwner(this));
			month.nonfatExposure = aggregate;
		});

		this.model.ClassIIIFutures?.forEach((future: Future) => {
			const quarterStartDate = DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(future.displayExpiresAt, quarter, getOwner(this));
			month.classiiiFuture = future;
		});

		this.model.ClassIVFutures?.forEach((future: Future) => {
			const quarterStartDate = DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(future.displayExpiresAt, quarter, getOwner(this));
			month.classivFuture = future;
		});

		this.model.ButterFutures?.forEach((future: Future) => {
			const quarterStartDate = DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(future.displayExpiresAt, quarter, getOwner(this));
			month.butterFuture = future;

			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();

			const advancedQuarterStartDate = DateTime.fromISO(advancedDate).startOf('quarter').toISODate();

			const advancedQuarter = getOrCreateQuarter(advancedQuarterStartDate, quarterMap);
			const advancedMonth = getOrCreateMonth(advancedDate, advancedQuarter, getOwner(this));
			advancedMonth.advancedButterFuture = future;
		});

		this.model.NonfatDryMilkFutures?.forEach((future: Future) => {
			const quarterStartDate = DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(future.displayExpiresAt, quarter, getOwner(this));
			month.nonfatDryMilkFuture = future;

			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			const advancedQuarterStartDate = DateTime.fromISO(advancedDate).startOf('quarter').toISODate();

			const advancedQuarter = getOrCreateQuarter(advancedQuarterStartDate, quarterMap);
			const advancedMonth = getOrCreateMonth(advancedDate, advancedQuarter, getOwner(this));
			advancedMonth.advancedNonfatDryMilkFuture = future;
		});

		this.model.CheeseFutures?.forEach((future: Future) => {
			const quarterStartDate = DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(future.displayExpiresAt, quarter, getOwner(this));
			month.cheeseFuture = future;

			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();

			const advancedQuarterStartDate = DateTime.fromISO(advancedDate).startOf('quarter').toISODate();

			const advancedQuarter = getOrCreateQuarter(advancedQuarterStartDate, quarterMap);
			const advancedMonth = getOrCreateMonth(advancedDate, advancedQuarter, getOwner(this));
			advancedMonth.advancedCheeseFuture = future;
		});

		this.model.DryWheyFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();

			const advancedQuarterStartDate = DateTime.fromISO(advancedDate).startOf('quarter').toISODate();

			const advancedQuarter = getOrCreateQuarter(advancedQuarterStartDate, quarterMap);
			const advancedMonth = getOrCreateMonth(advancedDate, advancedQuarter, getOwner(this));
			advancedMonth.advancedDryWheyFuture = future;
		});

		this.model.SoybeanMealFutures?.forEach((future: Future) => {
			const relevantDates = cmeFutureMonthToRelevantMonths(future.displayExpiresAt, 'grain-soybean-meal');

			relevantDates.forEach((date) => {
				const quarterStartDate = DateTime.fromISO(date).startOf('quarter').toISODate();

				const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
				const month = getOrCreateMonth(date, quarter, getOwner(this));
				month.soybeanMealFuture = future;
			});
		});

		this.model.CornFutures?.forEach((future: Future) => {
			const relevantDates = cmeFutureMonthToRelevantMonths(future.displayExpiresAt, 'grain-corn');

			relevantDates.forEach((date) => {
				const quarterStartDate = DateTime.fromISO(date).startOf('quarter').toISODate();
				const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
				const month = getOrCreateMonth(date, quarter, getOwner(this));
				month.cornFuture = future;
			});
		});

		this.model.AggregateLedgerEntries?.forEach((aggregateLedgerEntry: AggregateLedgerEntryDTO) => {
			if (aggregateLedgerEntry.month == undefined || aggregateLedgerEntry.year == undefined) return;

			const monthDate = DateTime.local(aggregateLedgerEntry.year, aggregateLedgerEntry.month).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.operation = aggregateLedgerEntry.sum.calculatedAmount;
		});

		this.model.AggregateRevenueLedgerEntries?.forEach((aggregateLedgerEntry: AggregateLedgerEntryDTO) => {
			if (aggregateLedgerEntry.month == undefined || aggregateLedgerEntry.year == undefined) return;

			const monthDate = DateTime.local(aggregateLedgerEntry.year, aggregateLedgerEntry.month).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.revenues = aggregateLedgerEntry.sum.calculatedAmount;
		});

		this.model.AggregateExpenseLedgerEntries?.forEach((aggregateLedgerEntry: AggregateLedgerEntryDTO) => {
			if (aggregateLedgerEntry.month == undefined || aggregateLedgerEntry.year == undefined) return;

			const monthDate = DateTime.local(aggregateLedgerEntry.year, aggregateLedgerEntry.month).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			if (aggregateLedgerEntry.LedgerCategory?.type == TypeOfLedgerCategory.Expense) {
				month.expenses = aggregateLedgerEntry.sum.calculatedAmount;
			}
			if (aggregateLedgerEntry.LedgerCategory?.type == TypeOfLedgerCategory.Feed) {
				month.fixedFeedExpenses = aggregateLedgerEntry.sum.calculatedAmount;
			}
		});

		this.model.AggregateLedgerForecastedEntries?.forEach((aggregateLedgerForecastedEntry: AggregateLedgerForecastedEntryDTO) => {
			if (aggregateLedgerForecastedEntry.month == undefined || aggregateLedgerForecastedEntry.year == undefined) return;

			const monthDate = DateTime.local(aggregateLedgerForecastedEntry.year, aggregateLedgerForecastedEntry.month).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.monthlyCalculatedAmount = aggregateLedgerForecastedEntry.sum.calculatedAmount;
		});

		this.model.ForcastedFeedUsage?.forEach((aggregateFeedUsage: AggregateFeedIngredientForecastedUsageDTO) => {
			if (aggregateFeedUsage.month == undefined || aggregateFeedUsage.year == undefined) return;

			const monthDate = DateTime.local(aggregateFeedUsage.year, aggregateFeedUsage.month).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
			month.feedIngredientsUsage.push(aggregateFeedUsage);
		});

		this.model.AggregateFeedIngredientConsumedAndPurchasedVolumes?.forEach(
			(feedFillUsage: AggregateFeedIngredientConsumedAndPurchasedVolumeDTO) => {
				if (feedFillUsage.sum == undefined || feedFillUsage.monthStartDate == undefined) return;

				const date = DateTime.fromISO(feedFillUsage.monthStartDate);

				if (!date.isValid) return;

				const monthDate = date.startOf('month');
				const quarterStartDate = monthDate.startOf('quarter').toISODate();

				const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
				const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));
				month.feedFills.push(feedFillUsage);
			},
		);

		calculateMonthlyWeightedPricesAndBasisValues(this.startDate, this.endDate, this.model.ForecastedMilkProductionByMonths).forEach((v) => {
			const monthDate = DateTime.fromISO(v.date).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));

			month.everAgBasis = v.locationCurrentBasis;
		});

		calculateUtilizationValuesFromForecastedProduction(this.model.ForecastedMilkProductionByMonths).forEach((v) => {
			const monthDate = DateTime.fromISO(v.date).startOf('month');
			const quarterStartDate = monthDate.startOf('quarter').toISODate();

			const quarter = getOrCreateQuarter(quarterStartDate, quarterMap);
			const month = getOrCreateMonth(monthDate.toISODate(), quarter, getOwner(this));

			month.milkCheckUtilizations = {
				classiUtilization: v.classIUtilization,
				classiiUtilization: v.classIIUtilization,
				classiiiUtilization: v.classIIIUtilization,
				classivUtilization: v.classIVUtilization,
				grossClassiPounds: v.grossClassIProduction,
				grossClassiiPounds: v.grossClassIIProduction,
				grossClassiiiPounds: v.grossClassIIIProduction,
				grossClassivPounds: v.grossClassIVProduction,
			} as ForecastedMilkUtilization;
			month.classiDifferential = v.classIDifferential;
		});

		const results = Object.entries(quarterMap).map(([date, quarterObject]) => {
			return new Quarter(date, quarterObject.children);
		});

		return results
			.sort((a, b) => (a.date < b.date ? -1 : 1))
			.slice(1, results.length - 1)
			.sortBy('date');
	}

	get percentHedgedQuarters() {
		return this.quarters.map((quarter) => {
			return {
				children: quarter.children,
				date: quarter.date,
				grossProduction: quarter.grossProduction,
				effectiveClassiiiUtilization: quarter.effectiveClassiiiUtilization,
				productionClassiiiExposure: quarter.productionClassiiiExposure,
				productionClassiiiPercentHedged: quarter.productionClassiiiPercentHedged,
				productionClassiiiVolumeOpen: quarter.productionClassiiiVolumeOpen,
				effectiveClassivUtilization: quarter.effectiveClassivUtilization,
				productionClassivExposure: quarter.productionClassivExposure,
				productionClassivPercentHedged: quarter.productionClassivPercentHedged,
				productionClassivVolumeOpen: quarter.productionClassivVolumeOpen,
				productionPercentHedged: quarter.productionPercentHedged,
				isCollapsed: true,
			};
		});
	}

	get insuranceQuarters(): InsuranceQuarterWithMonths[] {
		return this.quarters.map((quarter) => quarter.insuranceQuarter);
	}

	get mostCurrentFutures(): Record<string, Future | undefined | null> {
		return {
			classIII: this.model.MostCurrentFutures.find((product: any) => product.slug == 'us-dairy-class-iii')?.MostCurrentFuture,
			classIV: this.model.MostCurrentFutures.find((product: any) => product.slug == 'us-dairy-class-iv')?.MostCurrentFuture,
			nonfatDryMilk: this.model.MostCurrentFutures.find((product: any) => product.slug == 'us-dairy-nonfat-milk')?.MostCurrentFuture,
			dryWhey: this.model.MostCurrentFutures.find((product: any) => product.slug == 'us-dairy-dry-whey')?.MostCurrentFuture,
			butter: this.model.MostCurrentFutures.find((product: any) => product.slug == 'us-dairy-butter')?.MostCurrentFuture,
			cheese: this.model.MostCurrentFutures.find((product: any) => product.slug == 'us-dairy-cheese')?.MostCurrentFuture,
			corn: this.model.MostCurrentFutures.find((product: any) => product.slug == 'grain-corn')?.MostCurrentFuture,
			soybeanMeal: this.model.MostCurrentFutures.find((product: any) => product.slug == 'grain-soybean-meal')?.MostCurrentFuture,
			soybeans: this.model.MostCurrentFutures.find((product: any) => product.slug == 'grain-soybeans')?.MostCurrentFuture,
			chicagoSoftRedWinterWheat: this.model.MostCurrentFutures.find((product: any) => product.slug == 'grain-chicago-soft-red-winter-wheat')
				?.MostCurrentFuture,
		};
	}

	get marketPriceGroups() {
		const orderedFutures: Future[] = [];

		Object.keys(this.mostCurrentFutures).forEach((productName) => {
			const future = this.mostCurrentFutures[productName];
			if (future) {
				orderedFutures.push(future);
			}
		});

		return [
			{
				name: 'Futures',
				prices: orderedFutures,
			},
		];
	}

	@action
	updatePercentHedgedChart(chart: Chart, data: Record<string, (number | null)[]>) {
		chart.data.datasets.forEach((dataset) => {
			if (dataset.label) {
				dataset.data = data[dataset.label];
			}
		});
		chart.update('none');
	}

	@action
	updateNetPnlChart(chart: Chart, data: Record<string, (number | null)[]>) {
		chart.data.datasets.forEach((dataset) => {
			if (dataset.label) {
				dataset.data = data[dataset.label];
			}
		});
		if (chart.options?.scales?.y?.min) {
			chart.options.scales.y.min = this.pnlYScaleMin;
		}

		chart.update('none');
	}

	@action
	historicalPercentileDairyChartFuturesFilter(future: Future) {
		const startDate = DateTime.now().startOf('month').toISODate();
		const endDate = DateTime.now().plus({ months: 18 }).startOf('month').toISODate();

		return future.displayExpiresAt >= startDate && future.displayExpiresAt <= endDate;
	}

	get chartCornFutures() {
		return this.filterFeedChartFutures(this.model.CornFutures ?? []);
	}

	get chartSoybeanMealFutures() {
		return this.filterFeedChartFutures(this.model.SoybeanMealFutures ?? []);
	}

	filterFeedChartFutures(futures: Future[]) {
		const currentDate = DateTime.now().startOf('month').toISODate();
		const endDate = DateTime.now().plus({ months: 18 }).startOf('month').toISODate();
		const startIndex = futures.findIndex((future) => {
			return future.displayExpiresAt >= currentDate;
		});
		const endIndex = futures.findIndex((future) => {
			return future.displayExpiresAt >= endDate;
		});

		if (startIndex == -1) return [];

		return futures.slice(startIndex, endIndex !== -1 ? endIndex + 1 : undefined);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/dashboard': BusinessDashboard;
	}
}
