import ExposureShowRoute from 'vault-client/routes/exposure/show';
import {
	DerivedDrpEndorsementFilterDTO,
	FutureFilterDTO,
	ProductFilterDTO,
	TypeOfInsuranceEndorsement,
	InsuranceEndorsementAllocationRatioFilterDTO,
	CurrentAllocationPositionFilterDTO,
	Query,
} from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';
import { InsuranceProducts } from 'vault-client/utils/exposure-utils';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	slug: string;
	accountId?: string | null;
	exposureMonthStartDate?: string | null;
	exposureMonthEndDate?: string | null;
	sorts: SortObj[];
	id: string;
}

export default class OrganizationsOrganizationExposureRoute extends ExposureShowRoute {
	beforeModel() {
		this.scopeId = (this.paramsFor('organizations.organization') as { id: string } | undefined)?.id ?? null;
	}

	async model(params: QueryParams) {
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };

		const productWhere: ProductFilterDTO = {};
		const futureWhere: FutureFilterDTO = {};
		const positionWhere: CurrentAllocationPositionFilterDTO = {};
		const endorsementWhere: DerivedDrpEndorsementFilterDTO = {};
		const lgmEndorsementRatiosWhere: InsuranceEndorsementAllocationRatioFilterDTO = {
			InsuranceEndorsement: {
				type: {
					equals: TypeOfInsuranceEndorsement.Lgm,
				},
			},
		};

		const lrpEndorsementRatiosWhere: InsuranceEndorsementAllocationRatioFilterDTO = {
			InsuranceEndorsement: {
				type: {
					equals: TypeOfInsuranceEndorsement.Lrp,
				},
			},
		};

		if (this.applicationScope.globalCustomerId) {
			endorsementWhere.InsurancePolicy = {
				customerId: {
					equals: this.applicationScope.globalCustomerId,
				},
			};
			positionWhere.businessId = {
				equals: this.applicationScope.globalCustomerId,
			};
		} else if (organizationParams.id) {
			endorsementWhere.InsurancePolicy = {
				Owner: {
					id: {
						equals: organizationParams.id,
					},
				},
			};
			positionWhere.Account = {
				OR: [
					{
						Customer: {
							organizationId: { equals: organizationParams.id },
						},
					},
					{
						ownerId: { equals: organizationParams.id },
					},
				],
			};
		}

		if (params) {
			if (params.slug) {
				productWhere.slug = { equals: params.slug };
				futureWhere.Product = {
					slug: { equals: params.slug },
				};
				positionWhere.Product = {
					slug: {
						equals: params.slug,
					},
				};

				lgmEndorsementRatiosWhere.InsuranceEndorsement = {
					...lgmEndorsementRatiosWhere.InsuranceEndorsement,
					AsLgmInsuranceEndorsement: {
						Product: {
							slug: {
								equals: params.slug,
							},
						},
					},
				};

				lrpEndorsementRatiosWhere.InsuranceEndorsement = {
					...lrpEndorsementRatiosWhere.InsuranceEndorsement,
					AsLrpInsuranceEndorsement: {
						commodityCode: {
							equals: InsuranceProducts.find((product) => product.slug === params.slug)?.code ?? null,
						},
					},
				};
			}

			if (params.accountId) {
				positionWhere.accountId = {
					equals: params.accountId,
				};
			}

			const exposureMonthStartDate = params.exposureMonthStartDate;
			const exposureMonthEndDate = params.exposureMonthEndDate;
			if (exposureMonthStartDate && exposureMonthEndDate) {
				if (!futureWhere.AND) {
					futureWhere.AND = [];
				}
				if (!endorsementWhere.AND) {
					endorsementWhere.AND = [];
				}
				if (!positionWhere.AND) {
					positionWhere.AND = [];
				}

				futureWhere.AND.push({
					displayExpiresAt: { gte: exposureMonthStartDate },
				});
				futureWhere.AND.push({
					displayExpiresAt: { lte: exposureMonthEndDate },
				});
				endorsementWhere.AND.push({
					quarterStartDate: {
						gte: DateTime.fromISO(exposureMonthStartDate).startOf('quarter').toISODate(),
					},
				});
				endorsementWhere.AND.push({
					quarterStartDate: { lte: exposureMonthEndDate },
				});

				positionWhere.AND.push({
					effectiveHedgeDate: { gte: exposureMonthStartDate },
				});
				positionWhere.AND.push({
					effectiveHedgeDate: { lte: exposureMonthEndDate },
				});
				lgmEndorsementRatiosWhere.effectiveHedgeDate = {
					gte: exposureMonthStartDate,
					lte: exposureMonthEndDate,
				};

				lrpEndorsementRatiosWhere.effectiveHedgeDate = {
					gte: exposureMonthStartDate,
					lte: exposureMonthEndDate,
				};
			} else if (exposureMonthStartDate) {
				futureWhere.displayExpiresAt = {
					gte: exposureMonthStartDate,
				};
				endorsementWhere.quarterStartDate = {
					gte: DateTime.fromISO(exposureMonthStartDate).startOf('quarter').toISODate(),
				};

				positionWhere.effectiveHedgeDate = {
					gte: exposureMonthStartDate,
				};
				lgmEndorsementRatiosWhere.effectiveHedgeDate = {
					gte: exposureMonthStartDate,
				};
				lrpEndorsementRatiosWhere.effectiveHedgeDate = {
					gte: exposureMonthStartDate,
				};
			} else if (exposureMonthEndDate) {
				futureWhere.displayExpiresAt = {
					lte: exposureMonthEndDate,
				};
				endorsementWhere.quarterStartDate = {
					lte: DateTime.fromISO(exposureMonthEndDate).startOf('quarter').toISODate(),
				};
				positionWhere.effectiveHedgeDate = {
					lte: exposureMonthEndDate,
				};
				lgmEndorsementRatiosWhere.effectiveHedgeDate = {
					lte: exposureMonthEndDate,
				};
				lrpEndorsementRatiosWhere.effectiveHedgeDate = {
					lte: exposureMonthEndDate,
				};
			}
		}

		const variables = {
			productWhere,
			futureWhere,
			positionWhere,
			endorsementWhere,
			lgmEndorsementRatiosWhere,
			lrpEndorsementRatiosWhere,
			scopeId: this.applicationScope.globalCustomerId ?? organizationParams.id,
		};

		const physicalFeedVariables = {
			scopeId: organizationParams.id,
			slug: params.slug,
			startDate: params.exposureMonthStartDate,
			endDate: params.exposureMonthEndDate,
		};

		const [generalResult, physicalFeedResult] = await Promise.all([
			this.apollo.watchQuery({ query: this.query, variables }),
			this.apollo.watchQuery({ query: this.physicalFeedTransactionQuery, variables: physicalFeedVariables }),
		]);

		const data = {
			Products: generalResult.Products,
			Futures: generalResult.Futures,
			AggregateDerivedDrpEndorsements: generalResult.AggregateDerivedDrpEndorsements,
			AggregateCurrentAllocationPositions: generalResult.AggregateCurrentAllocationPositions,
			InsuranceEndorsementAllocationRatios: generalResult.InsuranceEndorsementAllocationRatios,
			LgmInsuranceEndorsements: generalResult.LgmInsuranceEndorsementAllocationRatios,
			LrpInsuranceEndorsements: generalResult.LrpInsuranceEndorsementAllocationRatios,
			PhysicalFeedTransactions: physicalFeedResult.PhysicalFeedTransactions,
		} as {
			Products: Query['Products'];
			Futures: Query['Futures'];
			AggregateDerivedDrpEndorsements: Query['AggregateDerivedDrpEndorsements'];
			AggregateCurrentAllocationPositions: Query['AggregateCurrentAllocationPositions'];
			LgmInsuranceEndorsements: Query['InsuranceEndorsementAllocationRatios'];
			LrpInsuranceEndorsements: Query['InsuranceEndorsementAllocationRatios'];
			PhysicalFeedTransactions: Query['PhysicalFeedTransactions'];
		};

		return data;
	}
}
