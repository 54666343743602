import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import { Query_DrpEndorsementArgs, DerivedDrpEndorsement } from 'vault-client/types/graphql-types';

interface ModelParams {
	endorsement_id: string;
}

const GET_INSURANCE_ENDORSEMENT_QUERY = gql`
	query InsuranceEndorsement($id: String!) {
		DerivedDrpEndorsement(id: $id) {
			actualYield
			lastYield
			insuredYield
			currentYield
			classPriceWeightingFactor
			componentPriceWeightingFactor
			coverageLevelPercent
			declaredButterfatTest
			declaredCoveredMilkProduction
			declaredProteinTest
			declaredShare
			effectiveCoveredMilkProduction
			effectiveExpectedRevenueGuarantee
			pnl
			expectedRevenueGuarantee
			id
			indemnity
			InsuranceEndorsement {
				id
			}
			InsurancePolicy {
				id
				producerName
				State {
					id
					abbreviation
				}
			}
			producerPremiumAmount
			projectedRevenue
			protectionFactor
			quarterStartDate
			salesEffectiveDate
			totalPremiumAmount
			indemnityEffectButter
			indemnityEffectButterfat
			indemnityEffectCheese
			indemnityEffectClassIii
			indemnityEffectClassIv
			indemnityEffectExcessProtein
			indemnityEffectNonfatSolids
			indemnityEffectOtherSolids
			indemnityEffectProtein
			insuredButterfatPrice
			insuredButterPrice
			insuredCheesePrice
			insuredClassIiiPrice
			insuredClassIvPrice
			insuredNonfatSolidsPrice
			insuredOtherSolidsPrice
			insuredProteinPrice
			butterfatPounds
			classIiiPounds
			classIvPounds
			excessButterPounds
			excessCheesePounds
			excessProteinPoundsAfterCheese
			proteinPounds
			otherSolidsPounds
			nonfatSolidsPounds
			projectedRevenue
			hasWriteAccess
		}
	}
`;

type GetInsuranceEndorsementQuery = {
	__typename?: 'Query';

	DerivedDrpEndorsement: DerivedDrpEndorsement;
};

export default class DRPInsuranceEndorsementsShowRoute extends Route {
	@tracked errorMessage: string | null = null;
	@tracked variables: Query_DrpEndorsementArgs = {
		id: '',
		scopeId: '',
	};

	templateName: string = 'drp-insurance-endorsements/show';

	getInsuranceEndorsement = useQuery<GetInsuranceEndorsementQuery, Query_DrpEndorsementArgs>(this, () => [
		GET_INSURANCE_ENDORSEMENT_QUERY,
		{
			variables: this.variables,
			onComplete: () => {
				this.errorMessage = null;
			},
			onError: (error) => {
				this.errorMessage = 'There was an error. Insurance endorsement was not retrieved.';
				console.error('Error while attempting to retrieve insurance endorsement.', error.message);
			},
		},
	]);

	async model(params: ModelParams) {
		this.variables = {
			id: params.endorsement_id,
		};

		await this.getInsuranceEndorsement.promise;
		return this.getInsuranceEndorsement;
	}
}
