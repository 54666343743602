import MarketingIndexController from 'vault-client/controllers/marketing/index';

export default class OrganizationsOrganizationMarketingController extends MarketingIndexController {
	cropPlansRoute = 'organizations.organization.crop-plans';
	cropTargetsRoute = 'organizations.organization.crop-targets';
	cropFillsRoute = 'organizations.organization.crop-fills';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/marketing': OrganizationsOrganizationMarketingController;
	}
}
