export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Object: any;
};

export type AccessControlListItemFilterDTO = {
  AND?: InputMaybe<Array<AccessControlListItemFilterDTO>>;
  NOT?: InputMaybe<AccessControlListItemFilterDTO>;
  OR?: InputMaybe<Array<AccessControlListItemFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  action?: InputMaybe<TypeOfAclActionFilter>;
  batchId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  parentIds?: InputMaybe<StringArrayFilter>;
  resources?: InputMaybe<TypeOfAclResourceArrayFilterDTO>;
  sourceId?: InputMaybe<StringFilter>;
  targetId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type Account = IAccount & {
  __typename?: 'Account';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  CurrentPositions: Array<CurrentPosition>;
  Customer?: Maybe<CustomerEntity>;
  Notes: Array<AccountNote>;
  Orders: Array<Order>;
  /** @deprecated Use "Owner" instead */
  Organization?: Maybe<OrganizationEntity>;
  Owner?: Maybe<IEntity>;
  Transactions: Array<Transaction>;
  accountNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  inputSource: InputSource;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isClosed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  type: TypeOfAccount;
  updatedAt: Scalars['DateTime'];
};


export type Account_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type Account_CurrentPositionsArgs = {
  orderBy?: InputMaybe<CurrentPositionSortByDTO>;
  where?: InputMaybe<CurrentPositionFilterDTO>;
};


export type Account_NotesArgs = {
  orderBy?: InputMaybe<AccountNoteSortByDTO>;
  where?: InputMaybe<AccountNoteFilterDTO>;
};


export type Account_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type Account_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type AccountBalance = {
  __typename?: 'AccountBalance';
  Account: BrokerageAccount;
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currentBalance: Scalars['Float'];
  date: Scalars['String'];
  entityIntegrationInstallationId: Scalars['String'];
  futuresMarketValue: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  liquidatingValue: Scalars['Float'];
  marginExcessDeficit: Scalars['Float'];
  openTradeEquity: Scalars['Float'];
  optionsMarketValue: Scalars['Float'];
  purchasingPower: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type AccountBalanceDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentBalance?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityIntegrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  futuresMarketValue?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  liquidatingValue?: InputMaybe<Scalars['Boolean']>;
  marginExcessDeficit?: InputMaybe<Scalars['Boolean']>;
  openTradeEquity?: InputMaybe<Scalars['Boolean']>;
  optionsMarketValue?: InputMaybe<Scalars['Boolean']>;
  purchasingPower?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AccountBalanceFilterDTO = {
  AND?: InputMaybe<Array<AccountBalanceFilterDTO>>;
  Account?: InputMaybe<BrokerageAccountFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  NOT?: InputMaybe<AccountBalanceFilterDTO>;
  OR?: InputMaybe<Array<AccountBalanceFilterDTO>>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentBalance?: InputMaybe<NumberFilter>;
  date?: InputMaybe<StringFilter>;
  entityIntegrationInstallationId?: InputMaybe<StringFilter>;
  futuresMarketValue?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  liquidatingValue?: InputMaybe<NumberFilter>;
  marginExcessDeficit?: InputMaybe<NumberFilter>;
  openTradeEquity?: InputMaybe<NumberFilter>;
  optionsMarketValue?: InputMaybe<NumberFilter>;
  purchasingPower?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AccountBalanceSortByDTO = {
  Account?: InputMaybe<BrokerageAccountSortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  currentBalance?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  entityIntegrationInstallationId?: InputMaybe<SortByDirection>;
  futuresMarketValue?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  liquidatingValue?: InputMaybe<SortByDirection>;
  marginExcessDeficit?: InputMaybe<SortByDirection>;
  openTradeEquity?: InputMaybe<SortByDirection>;
  optionsMarketValue?: InputMaybe<SortByDirection>;
  purchasingPower?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type AccountDistinctOnDTO = {
  accountNumber?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  counterPartyId?: InputMaybe<Scalars['Boolean']>;
  cqgId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentBalance?: InputMaybe<Scalars['Boolean']>;
  custodianAccountType?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  fcmAccountLastActiveDate?: InputMaybe<Scalars['Boolean']>;
  fcmAccountLastUpdatedDate?: InputMaybe<Scalars['Boolean']>;
  fcmAccountOpenDate?: InputMaybe<Scalars['Boolean']>;
  fcmId?: InputMaybe<Scalars['Boolean']>;
  futuresMarketValue?: InputMaybe<Scalars['Boolean']>;
  hasTradedThisYear?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isDiscretionaryAccount?: InputMaybe<Scalars['Boolean']>;
  isHedgeAccount?: InputMaybe<Scalars['Boolean']>;
  isNetLiquidationZero?: InputMaybe<Scalars['Boolean']>;
  isParentAccount?: InputMaybe<Scalars['Boolean']>;
  liquidatingValue?: InputMaybe<Scalars['Boolean']>;
  marginCredit?: InputMaybe<Scalars['Boolean']>;
  marginExcessDeficit?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  openTradeEquity?: InputMaybe<Scalars['Boolean']>;
  optionsMarketValue?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  parentAccountId?: InputMaybe<Scalars['Boolean']>;
  positionMargin?: InputMaybe<Scalars['Boolean']>;
  previousAccountBalance?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  purchasingPower?: InputMaybe<Scalars['Boolean']>;
  salesCode?: InputMaybe<Scalars['Boolean']>;
  swapDealerId?: InputMaybe<Scalars['Boolean']>;
  totalMargin?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AccountFile = {
  __typename?: 'AccountFile';
  Account: IAccount;
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  fileType: TypeOfAccountFile;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type AccountFileDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  fileName?: InputMaybe<Scalars['Boolean']>;
  fileType?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['Boolean']>;
};

export type AccountFileFilterDTO = {
  AND?: InputMaybe<Array<AccountFileFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  NOT?: InputMaybe<AccountFileFilterDTO>;
  OR?: InputMaybe<Array<AccountFileFilterDTO>>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fileName?: InputMaybe<StringFilter>;
  fileType?: InputMaybe<TypeOfAccountFileFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type AccountFileSortByDTO = {
  Account?: InputMaybe<AccountSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  fileName?: InputMaybe<SortByDirection>;
  fileType?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  url?: InputMaybe<SortByDirection>;
};

export type AccountFilterDTO = {
  AND?: InputMaybe<Array<AccountFilterDTO>>;
  AccountFiles?: InputMaybe<AccountFileFilterDTO>;
  AccountNotes?: InputMaybe<AccountNoteFilterDTO>;
  AsBrokerageAccount?: InputMaybe<BrokerageAccountFilterDTO>;
  AsForwardContractAccount?: InputMaybe<ForwardContractAccountFilterDTO>;
  AsSwapAccount?: InputMaybe<SwapAccountFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  IntegrationMappings?: InputMaybe<IntegrationMappingFilterDTO>;
  NOT?: InputMaybe<AccountFilterDTO>;
  OR?: InputMaybe<Array<AccountFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  Owner?: InputMaybe<EntityFilterDTO>;
  PositionComponent?: InputMaybe<PositionComponentFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  accountNumber?: InputMaybe<NullableStringFilter>;
  contractNumber?: InputMaybe<NullableStringFilter>;
  counterPartyId?: InputMaybe<NullableStringFilter>;
  cqgId?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentBalance?: InputMaybe<NullableNumberFilter>;
  custodianAccountType?: InputMaybe<NullableTypeOfCustodianAccountFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  fcmAccountLastActiveDate?: InputMaybe<NullableStringFilter>;
  fcmAccountLastUpdatedDate?: InputMaybe<NullableStringFilter>;
  fcmAccountOpenDate?: InputMaybe<NullableStringFilter>;
  fcmId?: InputMaybe<NullableStringFilter>;
  futuresMarketValue?: InputMaybe<NullableNumberFilter>;
  hasTradedThisYear?: InputMaybe<NullableBooleanFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  isClosed?: InputMaybe<BooleanFilter>;
  isDiscretionaryAccount?: InputMaybe<NullableBooleanFilter>;
  isHedgeAccount?: InputMaybe<NullableBooleanFilter>;
  isNetLiquidationZero?: InputMaybe<NullableBooleanFilter>;
  isParentAccount?: InputMaybe<NullableBooleanFilter>;
  liquidatingValue?: InputMaybe<NullableNumberFilter>;
  marginCredit?: InputMaybe<NullableNumberFilter>;
  marginExcessDeficit?: InputMaybe<NullableNumberFilter>;
  name?: InputMaybe<NullableStringFilter>;
  openTradeEquity?: InputMaybe<NullableNumberFilter>;
  optionsMarketValue?: InputMaybe<NullableNumberFilter>;
  ownerId?: InputMaybe<StringFilter>;
  parentAccountId?: InputMaybe<NullableStringFilter>;
  positionMargin?: InputMaybe<NullableNumberFilter>;
  previousAccountBalance?: InputMaybe<NullableNumberFilter>;
  productId?: InputMaybe<NullableStringFilter>;
  purchasingPower?: InputMaybe<NullableNumberFilter>;
  salesCode?: InputMaybe<NullableStringFilter>;
  swapDealerId?: InputMaybe<NullableStringFilter>;
  totalMargin?: InputMaybe<NullableNumberFilter>;
  type?: InputMaybe<TypeOfAccountFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AccountGroupByDTO = {
  AsBrokerageAccount?: InputMaybe<BrokerageAccountGroupByDTO>;
  AsForwardContractAccount?: InputMaybe<ForwardContractAccountGroupByDTO>;
  AsSwapAccount?: InputMaybe<SwapAccountGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  Owner?: InputMaybe<EntityGroupByDTO>;
  OwnerAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  OwnerAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  OwnerAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  OwnerAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  accountNumber?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  counterPartyId?: InputMaybe<Scalars['Boolean']>;
  cqgId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentBalance?: InputMaybe<Scalars['Boolean']>;
  custodianAccountType?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  fcmAccountLastActiveDate?: InputMaybe<Scalars['Boolean']>;
  fcmAccountLastUpdatedDate?: InputMaybe<Scalars['Boolean']>;
  fcmAccountOpenDate?: InputMaybe<Scalars['Boolean']>;
  fcmId?: InputMaybe<Scalars['Boolean']>;
  futuresMarketValue?: InputMaybe<Scalars['Boolean']>;
  hasTradedThisYear?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isDiscretionaryAccount?: InputMaybe<Scalars['Boolean']>;
  isHedgeAccount?: InputMaybe<Scalars['Boolean']>;
  isNetLiquidationZero?: InputMaybe<Scalars['Boolean']>;
  isParentAccount?: InputMaybe<Scalars['Boolean']>;
  liquidatingValue?: InputMaybe<Scalars['Boolean']>;
  marginCredit?: InputMaybe<Scalars['Boolean']>;
  marginExcessDeficit?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  openTradeEquity?: InputMaybe<Scalars['Boolean']>;
  optionsMarketValue?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  parentAccountId?: InputMaybe<Scalars['Boolean']>;
  positionMargin?: InputMaybe<Scalars['Boolean']>;
  previousAccountBalance?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  purchasingPower?: InputMaybe<Scalars['Boolean']>;
  salesCode?: InputMaybe<Scalars['Boolean']>;
  swapDealerId?: InputMaybe<Scalars['Boolean']>;
  totalMargin?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AccountNote = {
  __typename?: 'AccountNote';
  Account: IAccount;
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AccountNoteCreateDTO = {
  accountId: Scalars['String'];
  text: Scalars['String'];
};

export type AccountNoteDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AccountNoteFilterDTO = {
  AND?: InputMaybe<Array<AccountNoteFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  NOT?: InputMaybe<AccountNoteFilterDTO>;
  OR?: InputMaybe<Array<AccountNoteFilterDTO>>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AccountNoteSortByDTO = {
  Account?: InputMaybe<AccountSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  text?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type AccountNoteUpdateDTO = {
  accountId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type AccountSortByDTO = {
  AsBrokerageAccount?: InputMaybe<BrokerageAccountSortByDTO>;
  AsForwardContractAccount?: InputMaybe<ForwardContractAccountSortByDTO>;
  AsSwapAccount?: InputMaybe<SwapAccountSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  Owner?: InputMaybe<EntitySortByDTO>;
  accountNumber?: InputMaybe<NullableSortByDirection>;
  contractNumber?: InputMaybe<NullableSortByDirection>;
  counterPartyId?: InputMaybe<NullableSortByDirection>;
  cqgId?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  currentBalance?: InputMaybe<NullableSortByDirection>;
  custodianAccountType?: InputMaybe<NullableSortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  fcmAccountLastActiveDate?: InputMaybe<NullableSortByDirection>;
  fcmAccountLastUpdatedDate?: InputMaybe<NullableSortByDirection>;
  fcmAccountOpenDate?: InputMaybe<NullableSortByDirection>;
  fcmId?: InputMaybe<NullableSortByDirection>;
  futuresMarketValue?: InputMaybe<NullableSortByDirection>;
  hasTradedThisYear?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  isClosed?: InputMaybe<SortByDirection>;
  isDiscretionaryAccount?: InputMaybe<NullableSortByDirection>;
  isHedgeAccount?: InputMaybe<NullableSortByDirection>;
  isNetLiquidationZero?: InputMaybe<NullableSortByDirection>;
  isParentAccount?: InputMaybe<NullableSortByDirection>;
  liquidatingValue?: InputMaybe<NullableSortByDirection>;
  marginCredit?: InputMaybe<NullableSortByDirection>;
  marginExcessDeficit?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  openTradeEquity?: InputMaybe<NullableSortByDirection>;
  optionsMarketValue?: InputMaybe<NullableSortByDirection>;
  ownerId?: InputMaybe<SortByDirection>;
  parentAccountId?: InputMaybe<NullableSortByDirection>;
  positionMargin?: InputMaybe<NullableSortByDirection>;
  previousAccountBalance?: InputMaybe<NullableSortByDirection>;
  productId?: InputMaybe<NullableSortByDirection>;
  purchasingPower?: InputMaybe<NullableSortByDirection>;
  salesCode?: InputMaybe<NullableSortByDirection>;
  swapDealerId?: InputMaybe<NullableSortByDirection>;
  totalMargin?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

/** The actual blended milk price for the given entity / date */
export type ActualBlendedMilkPrice = {
  __typename?: 'ActualBlendedMilkPrice';
  Entity: IEntity;
  /** The basis between the forecasted blended millk price and the actual blended milk price. This value is set automatically by the system, and will be updated whenever the forecasted or actual prices change. This value will be null if no forecasted milk price is available. */
  basis?: Maybe<Scalars['Float']>;
  /** The basis between the USDA actual class price and the actual blended milk price. This value will be null if USDA actual prices haven't yet been published for this date, or if no USDA actual prices available for this location's county. */
  classiiiBasis?: Maybe<Scalars['Float']>;
  /** The basis between the USDA actual class price and the actual blended milk price. This value will be null if USDA actual prices haven't yet been published for this date, or if no USDA actual prices available for this location's county. */
  classivBasis?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  /** The first date of the month during which this value represents the actual milk price */
  date: Scalars['String'];
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  price: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type ActualBlendedMilkPriceAggregateAllDTO = {
  basis?: InputMaybe<Scalars['Boolean']>;
  classiiiBasis?: InputMaybe<Scalars['Boolean']>;
  classivBasis?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ActualBlendedMilkPriceAggregateDTO = {
  avg?: InputMaybe<ActualBlendedMilkPriceAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ActualBlendedMilkPriceAggregateAllDTO>;
  min?: InputMaybe<ActualBlendedMilkPriceAggregateAllDTO>;
  sum?: InputMaybe<ActualBlendedMilkPriceAggregateNumericDTO>;
};

export type ActualBlendedMilkPriceAggregateNumericDTO = {
  basis?: InputMaybe<Scalars['Boolean']>;
  classiiiBasis?: InputMaybe<Scalars['Boolean']>;
  classivBasis?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
};

export type ActualBlendedMilkPriceCreateDTO = {
  date: Scalars['String'];
  entityId: Scalars['String'];
  price: Scalars['Float'];
};

export type ActualBlendedMilkPriceDistinctOnDTO = {
  basis?: InputMaybe<Scalars['Boolean']>;
  classiiiBasis?: InputMaybe<Scalars['Boolean']>;
  classivBasis?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ActualBlendedMilkPriceFilterDTO = {
  AND?: InputMaybe<Array<ActualBlendedMilkPriceFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<ActualBlendedMilkPriceFilterDTO>;
  OR?: InputMaybe<Array<ActualBlendedMilkPriceFilterDTO>>;
  basis?: InputMaybe<NullableNumberFilter>;
  classiiiBasis?: InputMaybe<NullableNumberFilter>;
  classivBasis?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  price?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActualBlendedMilkPriceGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  basis?: InputMaybe<Scalars['Boolean']>;
  classiiiBasis?: InputMaybe<Scalars['Boolean']>;
  classivBasis?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ActualBlendedMilkPriceSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  basis?: InputMaybe<NullableSortByDirection>;
  classiiiBasis?: InputMaybe<NullableSortByDirection>;
  classivBasis?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  price?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type ActualMilkProduction = {
  __typename?: 'ActualMilkProduction';
  Location: LocationEntity;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  butterfatPercent: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  /** The first date of the month within which the "date" value falls */
  firstDateOfMonth: Scalars['String'];
  grossButterfat: Scalars['Float'];
  grossOtherSolids: Scalars['Float'];
  grossProduction: Scalars['Float'];
  grossProtein: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  locationId: Scalars['String'];
  month: Scalars['Int'];
  numberOfCows: Scalars['Int'];
  otherSolidsPercent: Scalars['Float'];
  productionPerCow: Scalars['Float'];
  proteinPercent: Scalars['Float'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type ActualMilkProductionAggregateAllDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossButterfat?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolids?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProtein?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  productionPerCow?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualMilkProductionAggregateDTO = {
  avg?: InputMaybe<ActualMilkProductionAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ActualMilkProductionAggregateAllDTO>;
  min?: InputMaybe<ActualMilkProductionAggregateAllDTO>;
  sum?: InputMaybe<ActualMilkProductionAggregateNumericDTO>;
};

export type ActualMilkProductionAggregateNumericDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  grossButterfat?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolids?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProtein?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  productionPerCow?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

/** The actual milk production for the given entity aggregated by month */
export type ActualMilkProductionByMonth = {
  __typename?: 'ActualMilkProductionByMonth';
  Entity: IEntity;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  averageDailyProductionPerCow: Scalars['Float'];
  butterfatPercent: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  entityId: Scalars['String'];
  /** The average number of cattle that exist per-day during the month */
  exactNumberOfCows: Scalars['Float'];
  grossButterfatProduction: Scalars['Float'];
  grossOtherSolidsProduction: Scalars['Float'];
  grossProduction: Scalars['Float'];
  grossProteinProduction: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** The average number of cattle that exist per-day during the month, rounded to the nearest int */
  numberOfCows: Scalars['Int'];
  otherSolidsPercent: Scalars['Float'];
  proteinPercent: Scalars['Float'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type ActualMilkProductionByMonthAggregateAllDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  averageDailyProductionPerCow?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualMilkProductionByMonthAggregateDTO = {
  avg?: InputMaybe<ActualMilkProductionByMonthAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ActualMilkProductionByMonthAggregateAllDTO>;
  min?: InputMaybe<ActualMilkProductionByMonthAggregateAllDTO>;
  sum?: InputMaybe<ActualMilkProductionByMonthAggregateNumericDTO>;
};

export type ActualMilkProductionByMonthAggregateNumericDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  averageDailyProductionPerCow?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualMilkProductionByMonthDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  averageDailyProductionPerCow?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualMilkProductionByMonthFilterDTO = {
  AND?: InputMaybe<Array<ActualMilkProductionByMonthFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<ActualMilkProductionByMonthFilterDTO>;
  OR?: InputMaybe<Array<ActualMilkProductionByMonthFilterDTO>>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  averageDailyProductionPerCow?: InputMaybe<NumberFilter>;
  butterfatPercent?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  exactNumberOfCows?: InputMaybe<NumberFilter>;
  grossButterfatProduction?: InputMaybe<NumberFilter>;
  grossOtherSolidsProduction?: InputMaybe<NumberFilter>;
  grossProduction?: InputMaybe<NumberFilter>;
  grossProteinProduction?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  numberOfCows?: InputMaybe<NumberFilter>;
  otherSolidsPercent?: InputMaybe<NumberFilter>;
  proteinPercent?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  readableQuarter?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type ActualMilkProductionByMonthGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  averageDailyProductionPerCow?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualMilkProductionByMonthSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  averageDailyProductionPerCow?: InputMaybe<SortByDirection>;
  butterfatPercent?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  exactNumberOfCows?: InputMaybe<SortByDirection>;
  grossButterfatProduction?: InputMaybe<SortByDirection>;
  grossOtherSolidsProduction?: InputMaybe<SortByDirection>;
  grossProduction?: InputMaybe<SortByDirection>;
  grossProteinProduction?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  numberOfCows?: InputMaybe<SortByDirection>;
  otherSolidsPercent?: InputMaybe<SortByDirection>;
  proteinPercent?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type ActualMilkProductionDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossButterfat?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolids?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProtein?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  productionPerCow?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualMilkProductionFilterDTO = {
  AND?: InputMaybe<Array<ActualMilkProductionFilterDTO>>;
  Location?: InputMaybe<LocationEntityFilterDTO>;
  NOT?: InputMaybe<ActualMilkProductionFilterDTO>;
  OR?: InputMaybe<Array<ActualMilkProductionFilterDTO>>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  butterfatPercent?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  firstDateOfMonth?: InputMaybe<StringFilter>;
  grossButterfat?: InputMaybe<NumberFilter>;
  grossOtherSolids?: InputMaybe<NumberFilter>;
  grossProduction?: InputMaybe<NumberFilter>;
  grossProtein?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  numberOfCows?: InputMaybe<NumberFilter>;
  otherSolidsPercent?: InputMaybe<NumberFilter>;
  productionPerCow?: InputMaybe<NumberFilter>;
  proteinPercent?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  readableQuarter?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type ActualMilkProductionGroupByDTO = {
  Location?: InputMaybe<LocationEntityGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossButterfat?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolids?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProtein?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  productionPerCow?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualMilkProductionSetDTO = {
  butterfatPercent?: InputMaybe<Scalars['Float']>;
  locationId: Scalars['String'];
  numberOfCows: Scalars['Int'];
  otherSolidsPercent?: InputMaybe<Scalars['Float']>;
  production: Scalars['Float'];
  proteinPercent?: InputMaybe<Scalars['Float']>;
};

export type ActualMilkProductionSortByDTO = {
  Location?: InputMaybe<LocationEntitySortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  butterfatPercent?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  firstDateOfMonth?: InputMaybe<SortByDirection>;
  grossButterfat?: InputMaybe<SortByDirection>;
  grossOtherSolids?: InputMaybe<SortByDirection>;
  grossProduction?: InputMaybe<SortByDirection>;
  grossProtein?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  locationId?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  numberOfCows?: InputMaybe<SortByDirection>;
  otherSolidsPercent?: InputMaybe<SortByDirection>;
  productionPerCow?: InputMaybe<SortByDirection>;
  proteinPercent?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type ActualProduction = {
  __typename?: 'ActualProduction';
  Location: LocationEntity;
  Product: Product;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  /** The first date of the month within which the "date" value falls */
  firstDateOfMonth: Scalars['String'];
  grossProduction: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  locationId: Scalars['String'];
  month: Scalars['Int'];
  productId: Scalars['String'];
  productionPerUnit: Scalars['Float'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['String'];
  units: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type ActualProductionAggregateAllDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionPerUnit?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualProductionAggregateDTO = {
  avg?: InputMaybe<ActualProductionAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ActualProductionAggregateAllDTO>;
  min?: InputMaybe<ActualProductionAggregateAllDTO>;
  sum?: InputMaybe<ActualProductionAggregateNumericDTO>;
};

export type ActualProductionAggregateNumericDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  productionPerUnit?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualProductionDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionPerUnit?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualProductionFilterDTO = {
  AND?: InputMaybe<Array<ActualProductionFilterDTO>>;
  Location?: InputMaybe<LocationEntityFilterDTO>;
  NOT?: InputMaybe<ActualProductionFilterDTO>;
  OR?: InputMaybe<Array<ActualProductionFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  firstDateOfMonth?: InputMaybe<StringFilter>;
  grossProduction?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  productId?: InputMaybe<StringFilter>;
  productionPerUnit?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  readableQuarter?: InputMaybe<StringFilter>;
  units?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type ActualProductionGroupByDTO = {
  Location?: InputMaybe<LocationEntityGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionPerUnit?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ActualProductionSetDTO = {
  locationId: Scalars['String'];
  productId: Scalars['String'];
  production: Scalars['Float'];
  units: Scalars['Float'];
};

export type ActualProductionSortByDTO = {
  Location?: InputMaybe<LocationEntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  firstDateOfMonth?: InputMaybe<SortByDirection>;
  grossProduction?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  locationId?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productionPerUnit?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  units?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type AggregateActualBlendedMilkPriceDTO = {
  __typename?: 'AggregateActualBlendedMilkPriceDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  avg: NumericAggregateActualBlendedMilkPriceDTO;
  basis?: Maybe<Scalars['Float']>;
  classiiiBasis?: Maybe<Scalars['Float']>;
  classivBasis?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateActualBlendedMilkPriceDTO;
  min: AllAggregateActualBlendedMilkPriceDTO;
  price?: Maybe<Scalars['Float']>;
  sum: NumericAggregateActualBlendedMilkPriceDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateActualMilkProductionByMonthDTO = {
  __typename?: 'AggregateActualMilkProductionByMonthDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  averageDailyProductionPerCow?: Maybe<Scalars['Float']>;
  avg: NumericAggregateActualMilkProductionByMonthDTO;
  butterfatPercent?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  exactNumberOfCows?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateActualMilkProductionByMonthDTO;
  min: AllAggregateActualMilkProductionByMonthDTO;
  month?: Maybe<Scalars['Int']>;
  numberOfCows?: Maybe<Scalars['Int']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['String']>;
  sum: NumericAggregateActualMilkProductionByMonthDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateActualMilkProductionDTO = {
  __typename?: 'AggregateActualMilkProductionDTO';
  Location?: Maybe<GroupedByLocationEntityDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  avg: NumericAggregateActualMilkProductionDTO;
  butterfatPercent?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  grossButterfat?: Maybe<Scalars['Float']>;
  grossOtherSolids?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProtein?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  max: AllAggregateActualMilkProductionDTO;
  min: AllAggregateActualMilkProductionDTO;
  month?: Maybe<Scalars['Int']>;
  numberOfCows?: Maybe<Scalars['Int']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  productionPerCow?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['String']>;
  sum: NumericAggregateActualMilkProductionDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateActualProductionDTO = {
  __typename?: 'AggregateActualProductionDTO';
  Location?: Maybe<GroupedByLocationEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  avg: NumericAggregateActualProductionDTO;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  grossProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  max: AllAggregateActualProductionDTO;
  min: AllAggregateActualProductionDTO;
  month?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  productionPerUnit?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['String']>;
  sum: NumericAggregateActualProductionDTO;
  units?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateAllocatedForecastedHedgedAndCappedVolumeDTO = {
  __typename?: 'AggregateAllocatedForecastedHedgedAndCappedVolumeDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  avg: NumericAggregateAllocatedForecastedHedgedAndCappedVolumeDTO;
  contractType?: Maybe<TypeOfContract>;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  hedgeType?: Maybe<TypeOfAllocatedHedge>;
  id?: Maybe<Scalars['String']>;
  instrumentType?: Maybe<TypeOfInstrument>;
  max: AllAggregateAllocatedForecastedHedgedAndCappedVolumeDTO;
  min: AllAggregateAllocatedForecastedHedgedAndCappedVolumeDTO;
  naturallyLongCapped?: Maybe<Scalars['Float']>;
  naturallyLongHedged?: Maybe<Scalars['Float']>;
  naturallyShortCapped?: Maybe<Scalars['Float']>;
  naturallyShortHedged?: Maybe<Scalars['Float']>;
  optionType?: Maybe<TypeOfOption>;
  productId?: Maybe<Scalars['String']>;
  sum: NumericAggregateAllocatedForecastedHedgedAndCappedVolumeDTO;
};

export type AggregateBrokerageAccountDTO = {
  __typename?: 'AggregateBrokerageAccountDTO';
  AsAccount?: Maybe<GroupedByAccountDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  Fcm?: Maybe<GroupedByFcmDTO>;
  Owner?: Maybe<GroupedByEntityDTO>;
  OwnerAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  OwnerAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  OwnerAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  OwnerAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  ParentAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  accountNumber?: Maybe<Scalars['String']>;
  avg: NumericAggregateBrokerageAccountDTO;
  count?: Maybe<Scalars['Int']>;
  cqgId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentBalance?: Maybe<Scalars['Float']>;
  custodianAccountType?: Maybe<TypeOfCustodianAccount>;
  customerId?: Maybe<Scalars['String']>;
  fcmAccountLastActiveDate?: Maybe<Scalars['String']>;
  fcmAccountLastUpdatedDate?: Maybe<Scalars['String']>;
  fcmAccountOpenDate?: Maybe<Scalars['String']>;
  fcmId?: Maybe<Scalars['String']>;
  futuresMarketValue?: Maybe<Scalars['Float']>;
  hasTradedThisYear?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isDiscretionaryAccount?: Maybe<Scalars['Boolean']>;
  isHedgeAccount?: Maybe<Scalars['Boolean']>;
  isNetLiquidationZero?: Maybe<Scalars['Boolean']>;
  isParentAccount?: Maybe<Scalars['Boolean']>;
  liquidatingValue?: Maybe<Scalars['Float']>;
  marginCredit?: Maybe<Scalars['Float']>;
  marginExcessDeficit?: Maybe<Scalars['Float']>;
  max: AllAggregateBrokerageAccountDTO;
  min: AllAggregateBrokerageAccountDTO;
  name?: Maybe<Scalars['String']>;
  openTradeEquity?: Maybe<Scalars['Float']>;
  optionsMarketValue?: Maybe<Scalars['Float']>;
  ownerId?: Maybe<Scalars['String']>;
  parentAccountId?: Maybe<Scalars['String']>;
  positionMargin?: Maybe<Scalars['Float']>;
  previousAccountBalance?: Maybe<Scalars['Float']>;
  purchasingPower?: Maybe<Scalars['Float']>;
  salesCode?: Maybe<Scalars['String']>;
  sum: NumericAggregateBrokerageAccountDTO;
  totalMargin?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfAccount>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateCropHarvestDTO = {
  __typename?: 'AggregateCropHarvestDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  Crop?: Maybe<GroupedByCropDTO>;
  Field?: Maybe<GroupedByFieldDTO>;
  acres?: Maybe<Scalars['Float']>;
  avg: NumericAggregateCropHarvestDTO;
  businessId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cropId?: Maybe<Scalars['String']>;
  fieldId?: Maybe<Scalars['String']>;
  forecastedHarvestDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateCropHarvestDTO;
  min: AllAggregateCropHarvestDTO;
  sum: NumericAggregateCropHarvestDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yieldPerAcre?: Maybe<Scalars['Float']>;
};

export type AggregateCurrentAllocationPositionDTO = {
  __typename?: 'AggregateCurrentAllocationPositionDTO';
  Account?: Maybe<GroupedByAccountDTO>;
  AccountAsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AccountAsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AccountAsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  Owner?: Maybe<GroupedByEntityDTO>;
  OwnerAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  OwnerAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  OwnerAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  OwnerAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  accountId?: Maybe<Scalars['String']>;
  allocationPositionId?: Maybe<Scalars['String']>;
  avg: NumericAggregateCurrentAllocationPositionDTO;
  businessId?: Maybe<Scalars['String']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWeightedAveragePrice?: Maybe<Scalars['Float']>;
  effectiveHedgeDate?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  instrumentType?: Maybe<TypeOfInstrument>;
  lifetimeWeightedAveragePrice?: Maybe<Scalars['Float']>;
  max: AllAggregateCurrentAllocationPositionDTO;
  min: AllAggregateCurrentAllocationPositionDTO;
  openSideWeightedAveragePrice?: Maybe<Scalars['Float']>;
  openWeightedAveragePrice?: Maybe<Scalars['Float']>;
  optionType?: Maybe<TypeOfOption>;
  ownerId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  positionSide?: Maybe<Side>;
  productId?: Maybe<Scalars['String']>;
  sum: NumericAggregateCurrentAllocationPositionDTO;
  unitQuantity?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateCurrentPositionComponentDTO = {
  __typename?: 'AggregateCurrentPositionComponentDTO';
  Account?: Maybe<GroupedByAccountDTO>;
  AccountAsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AccountAsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AccountAsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  CurrentPosition?: Maybe<GroupedByCurrentPositionDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  IntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  accountId?: Maybe<Scalars['String']>;
  aclResourceType?: Maybe<TypeOfAclResource>;
  avg: NumericAggregateCurrentPositionComponentDTO;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  max: AllAggregateCurrentPositionComponentDTO;
  min: AllAggregateCurrentPositionComponentDTO;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sum: NumericAggregateCurrentPositionComponentDTO;
  tradeDate?: Maybe<Scalars['String']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateCurrentPositionDTO = {
  __typename?: 'AggregateCurrentPositionDTO';
  Account?: Maybe<GroupedByAccountDTO>;
  AccountAsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AccountAsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AccountAsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  Owner?: Maybe<GroupedByEntityDTO>;
  OwnerAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  OwnerAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  OwnerAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  OwnerAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  PositionReconciliation?: Maybe<GroupedByPositionReconciliationDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  accountId?: Maybe<Scalars['String']>;
  accountType?: Maybe<TypeOfAccount>;
  avg: NumericAggregateCurrentPositionDTO;
  calculatedRealizedPnl?: Maybe<Scalars['Float']>;
  calculatedUnrealizedPnl?: Maybe<Scalars['Float']>;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  currentWeightedAveragePrice?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['String']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  feeTotal?: Maybe<Scalars['Float']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  instrumentType?: Maybe<TypeOfInstrument>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isUnrealizedPnlFromFcmComponents?: Maybe<Scalars['Boolean']>;
  lifetimeWeightedAveragePrice?: Maybe<Scalars['Float']>;
  max: AllAggregateCurrentPositionDTO;
  min: AllAggregateCurrentPositionDTO;
  optionType?: Maybe<TypeOfOption>;
  ownerId?: Maybe<Scalars['String']>;
  positionComponentRealizedPnl?: Maybe<Scalars['Float']>;
  positionComponentUnrealizedPnl?: Maybe<Scalars['Float']>;
  positionSide?: Maybe<Side>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  sum: NumericAggregateCurrentPositionDTO;
  totalTradeValue?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnlPriceDate?: Maybe<Scalars['String']>;
  weightedAveragePrice?: Maybe<Scalars['Float']>;
};

export type AggregateDerivedDrpEndorsementDTO = {
  __typename?: 'AggregateDerivedDrpEndorsementDTO';
  DrpInsuranceEndorsement?: Maybe<GroupedByDrpInsuranceEndorsementDTO>;
  InsurancePolicy?: Maybe<GroupedByInsurancePolicyDTO>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  avg: NumericAggregateDerivedDrpEndorsementDTO;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classIiiGrossProtection?: Maybe<Scalars['Float']>;
  classIiiPounds?: Maybe<Scalars['Float']>;
  classIvGrossProtection?: Maybe<Scalars['Float']>;
  classIvPounds?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassIiiPrice?: Maybe<Scalars['Float']>;
  expectedClassIvPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassIii?: Maybe<Scalars['Float']>;
  indemnityEffectClassIv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassIiiPrice?: Maybe<Scalars['Float']>;
  insuredClassIvPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  isLong?: Maybe<Scalars['Boolean']>;
  lastYield?: Maybe<Scalars['Float']>;
  max: AllAggregateDerivedDrpEndorsementDTO;
  min: AllAggregateDerivedDrpEndorsementDTO;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  quarterEndDate?: Maybe<Scalars['String']>;
  quarterStartDate?: Maybe<Scalars['String']>;
  reinsuranceYear?: Maybe<Scalars['Float']>;
  rmaPracticeCode?: Maybe<Scalars['Float']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  sum: NumericAggregateDerivedDrpEndorsementDTO;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type AggregateDrpEndorsementDTO = {
  __typename?: 'AggregateDrpEndorsementDTO';
  Agent?: Maybe<GroupedByInsuranceAgentDTO>;
  AsInsuranceEndorsement?: Maybe<GroupedByInsuranceEndorsementDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  InsurancePolicy?: Maybe<GroupedByInsurancePolicyDTO>;
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  InsuredYieldAndPrice?: Maybe<GroupedByInsuredYieldAndPriceDTO>;
  Policy?: Maybe<GroupedByInsurancePolicyDTO>;
  Practice?: Maybe<GroupedByInsurancePracticeDTO>;
  RmaType?: Maybe<GroupedByRmaTypeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipDrpPremiumKey?: Maybe<Scalars['String']>;
  avg: NumericAggregateDrpEndorsementDTO;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueAmount?: Maybe<Scalars['String']>;
  expectedRevenueGuarantee?: Maybe<Scalars['String']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  inputSource?: Maybe<InputSource>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isShort?: Maybe<Scalars['Boolean']>;
  lastYield?: Maybe<Scalars['Float']>;
  max: AllAggregateDrpEndorsementDTO;
  min: AllAggregateDrpEndorsementDTO;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  rmaPracticeCode?: Maybe<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  sum: NumericAggregateDrpEndorsementDTO;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceEndorsement>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type AggregateDrpInsuranceEndorsementDTO = {
  __typename?: 'AggregateDrpInsuranceEndorsementDTO';
  Agent?: Maybe<GroupedByInsuranceAgentDTO>;
  AsInsuranceEndorsement?: Maybe<GroupedByInsuranceEndorsementDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  InsurancePolicy?: Maybe<GroupedByInsurancePolicyDTO>;
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  InsuredYieldAndPrice?: Maybe<GroupedByInsuredYieldAndPriceDTO>;
  Policy?: Maybe<GroupedByInsurancePolicyDTO>;
  Practice?: Maybe<GroupedByInsurancePracticeDTO>;
  RmaType?: Maybe<GroupedByRmaTypeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipDrpPremiumKey?: Maybe<Scalars['String']>;
  avg: NumericAggregateDrpInsuranceEndorsementDTO;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueAmount?: Maybe<Scalars['String']>;
  expectedRevenueGuarantee?: Maybe<Scalars['String']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  inputSource?: Maybe<InputSource>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isShort?: Maybe<Scalars['Boolean']>;
  lastYield?: Maybe<Scalars['Float']>;
  max: AllAggregateDrpInsuranceEndorsementDTO;
  min: AllAggregateDrpInsuranceEndorsementDTO;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  rmaPracticeCode?: Maybe<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  sum: NumericAggregateDrpInsuranceEndorsementDTO;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceEndorsement>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type AggregateEntityAllocatedExposureRatioByOwnerDTO = {
  __typename?: 'AggregateEntityAllocatedExposureRatioByOwnerDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  avg: NumericAggregateEntityAllocatedExposureRatioByOwnerDTO;
  brokerageVolumeCapped?: Maybe<Scalars['Float']>;
  brokerageVolumeHedged?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  drpVolumeCapped?: Maybe<Scalars['Float']>;
  drpVolumeHedged?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  exposure?: Maybe<Scalars['Float']>;
  inventoryExposure?: Maybe<Scalars['Float']>;
  isNaturallyLong?: Maybe<Scalars['Boolean']>;
  isNaturallyShort?: Maybe<Scalars['Boolean']>;
  lgmVolumeCapped?: Maybe<Scalars['Float']>;
  lgmVolumeHedged?: Maybe<Scalars['Float']>;
  lrpVolumeCapped?: Maybe<Scalars['Float']>;
  lrpVolumeHedged?: Maybe<Scalars['Float']>;
  max: AllAggregateEntityAllocatedExposureRatioByOwnerDTO;
  min: AllAggregateEntityAllocatedExposureRatioByOwnerDTO;
  percentBrokerageVolumeCapped?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeHedged?: Maybe<Scalars['Float']>;
  percentLgmVolumeCapped?: Maybe<Scalars['Float']>;
  percentLgmVolumeHedged?: Maybe<Scalars['Float']>;
  percentLrpVolumeCapped?: Maybe<Scalars['Float']>;
  percentLrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeCapped?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeHedged?: Maybe<Scalars['Float']>;
  physicalVolumeCapped?: Maybe<Scalars['Float']>;
  physicalVolumeHedged?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productionExposure?: Maybe<Scalars['Float']>;
  sum: NumericAggregateEntityAllocatedExposureRatioByOwnerDTO;
  totalPercentOpen?: Maybe<Scalars['Float']>;
  totalPercentVolumeCapped?: Maybe<Scalars['Float']>;
  totalPercentVolumeHedged?: Maybe<Scalars['Float']>;
  totalVolumeCapped?: Maybe<Scalars['Float']>;
  totalVolumeHedged?: Maybe<Scalars['Float']>;
};

export type AggregateEntityAllocatedExposureRatioDTO = {
  __typename?: 'AggregateEntityAllocatedExposureRatioDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  avg: NumericAggregateEntityAllocatedExposureRatioDTO;
  brokerageVolumeCapped?: Maybe<Scalars['Float']>;
  brokerageVolumeHedged?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  drpVolumeCapped?: Maybe<Scalars['Float']>;
  drpVolumeHedged?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  exposure?: Maybe<Scalars['Float']>;
  inventoryExposure?: Maybe<Scalars['Float']>;
  isNaturallyLong?: Maybe<Scalars['Boolean']>;
  isNaturallyShort?: Maybe<Scalars['Boolean']>;
  lgmVolumeCapped?: Maybe<Scalars['Float']>;
  lgmVolumeHedged?: Maybe<Scalars['Float']>;
  lrpVolumeCapped?: Maybe<Scalars['Float']>;
  lrpVolumeHedged?: Maybe<Scalars['Float']>;
  max: AllAggregateEntityAllocatedExposureRatioDTO;
  min: AllAggregateEntityAllocatedExposureRatioDTO;
  netProductionExposure?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeCapped?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeHedged?: Maybe<Scalars['Float']>;
  percentLgmVolumeCapped?: Maybe<Scalars['Float']>;
  percentLgmVolumeHedged?: Maybe<Scalars['Float']>;
  percentLrpVolumeCapped?: Maybe<Scalars['Float']>;
  percentLrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeCapped?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeHedged?: Maybe<Scalars['Float']>;
  physicalVolumeCapped?: Maybe<Scalars['Float']>;
  physicalVolumeHedged?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productionExposure?: Maybe<Scalars['Float']>;
  sum: NumericAggregateEntityAllocatedExposureRatioDTO;
  totalPercentOpen?: Maybe<Scalars['Float']>;
  totalPercentVolumeCapped?: Maybe<Scalars['Float']>;
  totalPercentVolumeHedged?: Maybe<Scalars['Float']>;
  totalVolumeCapped?: Maybe<Scalars['Float']>;
  totalVolumeHedged?: Maybe<Scalars['Float']>;
};

export type AggregateEntityExposureRatioDTO = {
  __typename?: 'AggregateEntityExposureRatioDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  avg: NumericAggregateEntityExposureRatioDTO;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  exposure?: Maybe<Scalars['Float']>;
  inventoryExposure?: Maybe<Scalars['Float']>;
  isNaturallyLong?: Maybe<Scalars['Boolean']>;
  isNaturallyShort?: Maybe<Scalars['Boolean']>;
  max: AllAggregateEntityExposureRatioDTO;
  min: AllAggregateEntityExposureRatioDTO;
  netProductionExposure?: Maybe<Scalars['Float']>;
  percentCapped?: Maybe<Scalars['Float']>;
  percentCappedCumulative?: Maybe<Scalars['Float']>;
  percentHedged?: Maybe<Scalars['Float']>;
  percentHedgedCumulative?: Maybe<Scalars['Float']>;
  percentOpen?: Maybe<Scalars['Float']>;
  percentOpenCumulative?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productionExposure?: Maybe<Scalars['Float']>;
  sum: NumericAggregateEntityExposureRatioDTO;
  volumeCapped?: Maybe<Scalars['Float']>;
  volumeCappedCumulative?: Maybe<Scalars['Float']>;
  volumeHedged?: Maybe<Scalars['Float']>;
  volumeHedgedCumulative?: Maybe<Scalars['Float']>;
};

export type AggregateFeedIngredientActualUsageDTO = {
  __typename?: 'AggregateFeedIngredientActualUsageDTO';
  AsFeedIngredientUsage?: Maybe<GroupedByFeedIngredientUsageDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  FeedIngredient?: Maybe<GroupedByFeedIngredientDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  avg: NumericAggregateFeedIngredientActualUsageDTO;
  basisPrice?: Maybe<Scalars['Float']>;
  cashPrice?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb?: Maybe<Scalars['Float']>;
  dmiUsageInTons?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  feedIngredientSeriesId?: Maybe<Scalars['String']>;
  feedUsageInLb?: Maybe<Scalars['Float']>;
  feedUsageInTons?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateFeedIngredientActualUsageDTO;
  min: AllAggregateFeedIngredientActualUsageDTO;
  month?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['Int']>;
  sum: NumericAggregateFeedIngredientActualUsageDTO;
  type?: Maybe<TypeOfFeedIngredientUsage>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateFeedIngredientConsumedAndPurchasedVolumeDTO = {
  __typename?: 'AggregateFeedIngredientConsumedAndPurchasedVolumeDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  FeedIngredient?: Maybe<GroupedByFeedIngredientDTO>;
  avg: NumericAggregateFeedIngredientConsumedAndPurchasedVolumeDTO;
  count?: Maybe<Scalars['Int']>;
  entityId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  forecastedConsumptionInTons?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateFeedIngredientConsumedAndPurchasedVolumeDTO;
  min: AllAggregateFeedIngredientConsumedAndPurchasedVolumeDTO;
  monthStartDate?: Maybe<Scalars['String']>;
  purchasedInTons?: Maybe<Scalars['Float']>;
  sum: NumericAggregateFeedIngredientConsumedAndPurchasedVolumeDTO;
  totalPurchasedCostInUsd?: Maybe<Scalars['Float']>;
  volumePurchasedWithBasisPriceOnlyInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithFlatPriceInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithFuturesPriceOnlyInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithoutPriceInTons?: Maybe<Scalars['Float']>;
};

export type AggregateFeedIngredientForecastedUsageDTO = {
  __typename?: 'AggregateFeedIngredientForecastedUsageDTO';
  AsFeedIngredientUsage?: Maybe<GroupedByFeedIngredientUsageDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  FeedIngredient?: Maybe<GroupedByFeedIngredientDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  avg: NumericAggregateFeedIngredientForecastedUsageDTO;
  basisPrice?: Maybe<Scalars['Float']>;
  cashPrice?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb?: Maybe<Scalars['Float']>;
  dmiUsageInTons?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  feedIngredientSeriesId?: Maybe<Scalars['String']>;
  feedUsageInLb?: Maybe<Scalars['Float']>;
  feedUsageInTons?: Maybe<Scalars['Float']>;
  forecastedPerLbExpenseInUsd?: Maybe<Scalars['Float']>;
  forecastedTotalExpenseInUsd?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateFeedIngredientForecastedUsageDTO;
  min: AllAggregateFeedIngredientForecastedUsageDTO;
  month?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['Int']>;
  sum: NumericAggregateFeedIngredientForecastedUsageDTO;
  type?: Maybe<TypeOfFeedIngredientUsage>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateFieldDTO = {
  __typename?: 'AggregateFieldDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  acres?: Maybe<Scalars['Float']>;
  avg: NumericAggregateFieldDTO;
  businessId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateFieldDTO;
  min: AllAggregateFieldDTO;
  name?: Maybe<Scalars['String']>;
  sum: NumericAggregateFieldDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateForecastedHedgedAndCappedVolumeDTO = {
  __typename?: 'AggregateForecastedHedgedAndCappedVolumeDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  avg: NumericAggregateForecastedHedgedAndCappedVolumeDTO;
  contractType?: Maybe<TypeOfContract>;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  hedgeType?: Maybe<TypeOfHedge>;
  instrumentType?: Maybe<TypeOfInstrument>;
  max: AllAggregateForecastedHedgedAndCappedVolumeDTO;
  min: AllAggregateForecastedHedgedAndCappedVolumeDTO;
  naturallyLongCapped?: Maybe<Scalars['Float']>;
  naturallyLongHedged?: Maybe<Scalars['Float']>;
  naturallyShortCapped?: Maybe<Scalars['Float']>;
  naturallyShortHedged?: Maybe<Scalars['Float']>;
  optionType?: Maybe<TypeOfOption>;
  productId?: Maybe<Scalars['String']>;
  sum: NumericAggregateForecastedHedgedAndCappedVolumeDTO;
};

export type AggregateForecastedMilkProductionByMonthDTO = {
  __typename?: 'AggregateForecastedMilkProductionByMonthDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  averageDailyProductionPerCow?: Maybe<Scalars['Float']>;
  avg: NumericAggregateForecastedMilkProductionByMonthDTO;
  butterfatPercent?: Maybe<Scalars['Float']>;
  classiUtilization?: Maybe<Scalars['Float']>;
  classiiUtilization?: Maybe<Scalars['Float']>;
  classiiiUtilization?: Maybe<Scalars['Float']>;
  classivUtilization?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  exactNumberOfCows?: Maybe<Scalars['Float']>;
  forecastedBlendedMilkPrice?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossClassiProduction?: Maybe<Scalars['Float']>;
  grossClassiiProduction?: Maybe<Scalars['Float']>;
  grossClassiiiProduction?: Maybe<Scalars['Float']>;
  grossClassivProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateForecastedMilkProductionByMonthDTO;
  min: AllAggregateForecastedMilkProductionByMonthDTO;
  month?: Maybe<Scalars['Int']>;
  numberOfCows?: Maybe<Scalars['Int']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['String']>;
  sum: NumericAggregateForecastedMilkProductionByMonthDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateForecastedMilkProductionByMonthRolledUpDTO = {
  __typename?: 'AggregateForecastedMilkProductionByMonthRolledUpDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  avg: NumericAggregateForecastedMilkProductionByMonthRolledUpDTO;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  exactNumberOfCows?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossClassiProduction?: Maybe<Scalars['Float']>;
  grossClassiiProduction?: Maybe<Scalars['Float']>;
  grossClassiiiProduction?: Maybe<Scalars['Float']>;
  grossClassivProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateForecastedMilkProductionByMonthRolledUpDTO;
  min: AllAggregateForecastedMilkProductionByMonthRolledUpDTO;
  month?: Maybe<Scalars['Int']>;
  numberOfCows?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['String']>;
  sum: NumericAggregateForecastedMilkProductionByMonthRolledUpDTO;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateForecastedMilkProductionCoverageDTO = {
  __typename?: 'AggregateForecastedMilkProductionCoverageDTO';
  CustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  avg: NumericAggregateForecastedMilkProductionCoverageDTO;
  butterfatPounds?: Maybe<Scalars['Float']>;
  classIiiPounds?: Maybe<Scalars['Float']>;
  classIvPounds?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossClassIProduction?: Maybe<Scalars['Float']>;
  grossClassIiProduction?: Maybe<Scalars['Float']>;
  grossClassIiiProduction?: Maybe<Scalars['Float']>;
  grossClassIvProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  max: AllAggregateForecastedMilkProductionCoverageDTO;
  min: AllAggregateForecastedMilkProductionCoverageDTO;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  percentInsured?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  quarterStartDate?: Maybe<Scalars['String']>;
  readableQuarter?: Maybe<Scalars['String']>;
  sum: NumericAggregateForecastedMilkProductionCoverageDTO;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateForecastedMilkProductionDTO = {
  __typename?: 'AggregateForecastedMilkProductionDTO';
  Location?: Maybe<GroupedByLocationEntityDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  avg: NumericAggregateForecastedMilkProductionDTO;
  butterfatPercent?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  grossButterfat?: Maybe<Scalars['Float']>;
  grossOtherSolids?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProtein?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  max: AllAggregateForecastedMilkProductionDTO;
  min: AllAggregateForecastedMilkProductionDTO;
  month?: Maybe<Scalars['Int']>;
  numberOfCows?: Maybe<Scalars['Int']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  productionPerCow?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['String']>;
  sum: NumericAggregateForecastedMilkProductionDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateForecastedMilkUtilizationDTO = {
  __typename?: 'AggregateForecastedMilkUtilizationDTO';
  County?: Maybe<GroupedByCountyDTO>;
  Location?: Maybe<GroupedByLocationEntityDTO>;
  avg: NumericAggregateForecastedMilkUtilizationDTO;
  classiDifferential?: Maybe<Scalars['Float']>;
  classiUtilization?: Maybe<Scalars['Float']>;
  classiiUtilization?: Maybe<Scalars['Float']>;
  classiiiUtilization?: Maybe<Scalars['Float']>;
  classivUtilization?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  countyId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  grossClassiDifferentialPrice?: Maybe<Scalars['Float']>;
  grossClassiPounds?: Maybe<Scalars['Float']>;
  grossClassiiPounds?: Maybe<Scalars['Float']>;
  grossClassiiiPounds?: Maybe<Scalars['Float']>;
  grossClassivPounds?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  max: AllAggregateForecastedMilkUtilizationDTO;
  min: AllAggregateForecastedMilkUtilizationDTO;
  month?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  sum: NumericAggregateForecastedMilkUtilizationDTO;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateForecastedProductionDTO = {
  __typename?: 'AggregateForecastedProductionDTO';
  Location?: Maybe<GroupedByLocationEntityDTO>;
  Products?: Maybe<GroupedByProductDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  avg: NumericAggregateForecastedProductionDTO;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  grossProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  max: AllAggregateForecastedProductionDTO;
  min: AllAggregateForecastedProductionDTO;
  month?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  productionPerUnit?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['String']>;
  sum: NumericAggregateForecastedProductionDTO;
  units?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateForecastedSwineLivestockFeedUsageAtFinishDTO = {
  __typename?: 'AggregateForecastedSwineLivestockFeedUsageAtFinishDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  avg: NumericAggregateForecastedSwineLivestockFeedUsageAtFinishDTO;
  businessId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  max: AllAggregateForecastedSwineLivestockFeedUsageAtFinishDTO;
  min: AllAggregateForecastedSwineLivestockFeedUsageAtFinishDTO;
  quantityInLbs?: Maybe<Scalars['Float']>;
  quantityInTons?: Maybe<Scalars['Float']>;
  sum: NumericAggregateForecastedSwineLivestockFeedUsageAtFinishDTO;
  totalExpenseInUsd?: Maybe<Scalars['Float']>;
};

export type AggregateForecastedSwineLivestockFeedUsageDTO = {
  __typename?: 'AggregateForecastedSwineLivestockFeedUsageDTO';
  AsForecastedLivestockFeedUsage?: Maybe<GroupedByForecastedLivestockFeedUsageDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  Ingredient?: Maybe<GroupedByFeedIngredientDTO>;
  LivestockGroupFeedUsage?: Maybe<GroupedByLivestockGroupFeedUsageDTO>;
  LivestockGroupFeedUsageAsSwineLivestockGroupFeedUsage?: Maybe<GroupedBySwineLivestockGroupFeedUsageDTO>;
  ageInWeeks?: Maybe<Scalars['Int']>;
  avg: NumericAggregateForecastedSwineLivestockFeedUsageDTO;
  businessId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  hedgePriceInUsd?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredientId?: Maybe<Scalars['String']>;
  livestockGroupFeedUsageId?: Maybe<Scalars['String']>;
  max: AllAggregateForecastedSwineLivestockFeedUsageDTO;
  min: AllAggregateForecastedSwineLivestockFeedUsageDTO;
  perLbExpenseInUsd?: Maybe<Scalars['Float']>;
  quantityInLbs?: Maybe<Scalars['Float']>;
  quantityInTons?: Maybe<Scalars['Float']>;
  readableWeek?: Maybe<Scalars['String']>;
  sum: NumericAggregateForecastedSwineLivestockFeedUsageDTO;
  totalExpenseInUsd?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfForecastedLivestockFeedUsage>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateGrainFillOrderDTO = {
  __typename?: 'AggregateGrainFillOrderDTO';
  AsGrainOrder?: Maybe<GroupedByGrainOrderDTO>;
  Buyer?: Maybe<GroupedByBuyerDTO>;
  DeliveryLocation?: Maybe<GroupedByGrainLocationDTO>;
  GrainTargetOrder?: Maybe<GroupedByGrainTargetOrderDTO>;
  Location?: Maybe<GroupedByGrainLocationDTO>;
  Plan?: Maybe<GroupedByGrainPlanDTO>;
  PlanAsGrainCropPlan?: Maybe<GroupedByGrainCropPlanDTO>;
  PlanAsGrainFeedPlan?: Maybe<GroupedByGrainFeedPlanDTO>;
  Seller?: Maybe<GroupedBySellerDTO>;
  avg: NumericAggregateGrainFillOrderDTO;
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  feedFlatPrice?: Maybe<Scalars['Float']>;
  feedPrice?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee?: Maybe<Scalars['Float']>;
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  htaFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isFob?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  max: AllAggregateGrainFillOrderDTO;
  min: AllAggregateGrainFillOrderDTO;
  planId?: Maybe<Scalars['String']>;
  rollFee?: Maybe<Scalars['Float']>;
  salesType?: Maybe<TypeOfGrainOrderSales>;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  sum: NumericAggregateGrainFillOrderDTO;
  targetId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfGrainOrder>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
};

export type AggregateGrainTargetOrderDTO = {
  __typename?: 'AggregateGrainTargetOrderDTO';
  AsGrainOrder?: Maybe<GroupedByGrainOrderDTO>;
  Buyer?: Maybe<GroupedByBuyerDTO>;
  BuyerRelationsOwner?: Maybe<GroupedByUserDTO>;
  DeliveryLocation?: Maybe<GroupedByGrainLocationDTO>;
  Location?: Maybe<GroupedByGrainLocationDTO>;
  Plan?: Maybe<GroupedByGrainPlanDTO>;
  PlanAsGrainCropPlan?: Maybe<GroupedByGrainCropPlanDTO>;
  PlanAsGrainFeedPlan?: Maybe<GroupedByGrainFeedPlanDTO>;
  Seller?: Maybe<GroupedBySellerDTO>;
  avg: NumericAggregateGrainTargetOrderDTO;
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  buyerRelationsOwnerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  expirationDate?: Maybe<Scalars['String']>;
  feedFlatPrice?: Maybe<Scalars['Float']>;
  feedPrice?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee?: Maybe<Scalars['Float']>;
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  htaFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isFob?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  max: AllAggregateGrainTargetOrderDTO;
  min: AllAggregateGrainTargetOrderDTO;
  planId?: Maybe<Scalars['String']>;
  rollFee?: Maybe<Scalars['Float']>;
  salesType?: Maybe<TypeOfGrainOrderSales>;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  status?: Maybe<TypeOfGrainOrderStatus>;
  sum: NumericAggregateGrainTargetOrderDTO;
  type?: Maybe<TypeOfGrainOrder>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
};

export type AggregateHedgeStrategyCurrentPositionDTO = {
  __typename?: 'AggregateHedgeStrategyCurrentPositionDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  avg: NumericAggregateHedgeStrategyCurrentPositionDTO;
  businessId?: Maybe<Scalars['String']>;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  feeTotal?: Maybe<Scalars['Float']>;
  firstTradeDate?: Maybe<Scalars['String']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  lastTradeDate?: Maybe<Scalars['String']>;
  max: AllAggregateHedgeStrategyCurrentPositionDTO;
  min: AllAggregateHedgeStrategyCurrentPositionDTO;
  positionSide?: Maybe<Side>;
  realizedPnl?: Maybe<Scalars['Float']>;
  sum: NumericAggregateHedgeStrategyCurrentPositionDTO;
  unitQuantity?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnlPriceDate?: Maybe<Scalars['String']>;
};

export type AggregateHedgeStrategyHistoricalPnlDTO = {
  __typename?: 'AggregateHedgeStrategyHistoricalPnlDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  avg: NumericAggregateHedgeStrategyHistoricalPnlDTO;
  businessId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  grossPnl?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceNetPremium?: Maybe<Scalars['Float']>;
  insuranceRealizedPnl?: Maybe<Scalars['Float']>;
  insuranceUnrealizedPnl?: Maybe<Scalars['Float']>;
  max: AllAggregateHedgeStrategyHistoricalPnlDTO;
  min: AllAggregateHedgeStrategyHistoricalPnlDTO;
  netPnl?: Maybe<Scalars['Float']>;
  netPremium?: Maybe<Scalars['Float']>;
  positionNetPremium?: Maybe<Scalars['Float']>;
  positionRealizedPnl?: Maybe<Scalars['Float']>;
  positionUnrealizedPnl?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  sum: NumericAggregateHedgeStrategyHistoricalPnlDTO;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateHistoricalPnlDTO = {
  __typename?: 'AggregateHistoricalPnlDTO';
  avg: NumericAggregateHistoricalPnlDTO;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  displayExpirationDate?: Maybe<Scalars['String']>;
  feeTotal?: Maybe<Scalars['Float']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossPnlChange?: Maybe<Scalars['Float']>;
  instrumentType?: Maybe<TypeOfInstrument>;
  marketValue?: Maybe<Scalars['Float']>;
  marketValueChange?: Maybe<Scalars['Float']>;
  max: AllAggregateHistoricalPnlDTO;
  min: AllAggregateHistoricalPnlDTO;
  nextDate?: Maybe<Scalars['String']>;
  optionPremium?: Maybe<Scalars['Float']>;
  optionType?: Maybe<TypeOfOption>;
  previousCommissionAndFeeTotal?: Maybe<Scalars['Float']>;
  previousCommissionTotal?: Maybe<Scalars['Float']>;
  previousDate?: Maybe<Scalars['String']>;
  previousFeeTotal?: Maybe<Scalars['Float']>;
  previousGrossPnl?: Maybe<Scalars['Float']>;
  previousMarketValue?: Maybe<Scalars['Float']>;
  previousRealizedPnl?: Maybe<Scalars['Float']>;
  previousUnrealizedPnl?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  realizedPnlChange?: Maybe<Scalars['Float']>;
  side?: Maybe<Side>;
  sum: NumericAggregateHistoricalPnlDTO;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnlChange?: Maybe<Scalars['Float']>;
};

export type AggregateInsuranceHistoricalDrpPremiumDTO = {
  __typename?: 'AggregateInsuranceHistoricalDrpPremiumDTO';
  AsInsuranceHistoricalPremium?: Maybe<GroupedByInsuranceHistoricalPremiumDTO>;
  InsuranceHistoricalPremiumInput?: Maybe<GroupedByInsuranceHistoricalPremiumInputDTO>;
  InsuranceHistoricalPremiumInputAsInsuranceHistoricalDrpPremiumInput?: Maybe<GroupedByInsuranceHistoricalDrpPremiumInputDTO>;
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  avg: NumericAggregateInsuranceHistoricalDrpPremiumDTO;
  count?: Maybe<Scalars['Int']>;
  coverageLevel?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  grossPremium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  insurancePracticeDisplayDate?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  liability?: Maybe<Scalars['Float']>;
  max: AllAggregateInsuranceHistoricalDrpPremiumDTO;
  min: AllAggregateInsuranceHistoricalDrpPremiumDTO;
  netPremium?: Maybe<Scalars['Float']>;
  protectedPrice?: Maybe<Scalars['Float']>;
  revenueGuarantee?: Maybe<Scalars['Float']>;
  stateId?: Maybe<Scalars['String']>;
  subsidy?: Maybe<Scalars['Float']>;
  sum: NumericAggregateInsuranceHistoricalDrpPremiumDTO;
  type?: Maybe<TypeOfInsuranceHistoricalPremium>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateInventoryLevelDTO = {
  __typename?: 'AggregateInventoryLevelDTO';
  GroupedByLocation?: Maybe<LocationEntity>;
  GroupedByProduct?: Maybe<Product>;
  Location?: Maybe<GroupedByLocationEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  avg: NumericAggregateInventoryLevelDTO;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  max: AllAggregateInventoryLevelDTO;
  min: AllAggregateInventoryLevelDTO;
  productId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  sum: NumericAggregateInventoryLevelDTO;
  timestamp?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateLedgerActualEntryDTO = {
  __typename?: 'AggregateLedgerActualEntryDTO';
  AsLedgerEntry?: Maybe<GroupedByLedgerEntryDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  LedgerCategory?: Maybe<GroupedByLedgerCategoryDTO>;
  LedgerCategoryAsLedgerExpenseCategory?: Maybe<GroupedByLedgerExpenseCategoryDTO>;
  LedgerCategoryAsLedgerFeedCategory?: Maybe<GroupedByLedgerFeedCategoryDTO>;
  LedgerCategoryAsLedgerMilkCheckCategory?: Maybe<GroupedByLedgerMilkCheckCategoryDTO>;
  LedgerCategoryAsLedgerRevenueCategory?: Maybe<GroupedByLedgerRevenueCategoryDTO>;
  amount?: Maybe<Scalars['Float']>;
  avg: NumericAggregateLedgerActualEntryDTO;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateLedgerActualEntryDTO;
  min: AllAggregateLedgerActualEntryDTO;
  month?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  sum: NumericAggregateLedgerActualEntryDTO;
  type?: Maybe<TypeOfLedgerEntry>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateLedgerEntryDTO = {
  __typename?: 'AggregateLedgerEntryDTO';
  AsLedgerActualEntry?: Maybe<GroupedByLedgerActualEntryDTO>;
  AsLedgerForecastedEntry?: Maybe<GroupedByLedgerForecastedEntryDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  LedgerCategory?: Maybe<GroupedByLedgerCategoryDTO>;
  LedgerCategoryAsLedgerExpenseCategory?: Maybe<GroupedByLedgerExpenseCategoryDTO>;
  LedgerCategoryAsLedgerFeedCategory?: Maybe<GroupedByLedgerFeedCategoryDTO>;
  LedgerCategoryAsLedgerMilkCheckCategory?: Maybe<GroupedByLedgerMilkCheckCategoryDTO>;
  LedgerCategoryAsLedgerRevenueCategory?: Maybe<GroupedByLedgerRevenueCategoryDTO>;
  amount?: Maybe<Scalars['Float']>;
  avg: NumericAggregateLedgerEntryDTO;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateLedgerEntryDTO;
  min: AllAggregateLedgerEntryDTO;
  month?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  sum: NumericAggregateLedgerEntryDTO;
  type?: Maybe<TypeOfLedgerEntry>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateLedgerForecastedEntryDTO = {
  __typename?: 'AggregateLedgerForecastedEntryDTO';
  AsLedgerEntry?: Maybe<GroupedByLedgerEntryDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  LedgerCategory?: Maybe<GroupedByLedgerCategoryDTO>;
  LedgerCategoryAsLedgerExpenseCategory?: Maybe<GroupedByLedgerExpenseCategoryDTO>;
  LedgerCategoryAsLedgerFeedCategory?: Maybe<GroupedByLedgerFeedCategoryDTO>;
  LedgerCategoryAsLedgerMilkCheckCategory?: Maybe<GroupedByLedgerMilkCheckCategoryDTO>;
  LedgerCategoryAsLedgerRevenueCategory?: Maybe<GroupedByLedgerRevenueCategoryDTO>;
  amount?: Maybe<Scalars['Float']>;
  avg: NumericAggregateLedgerForecastedEntryDTO;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateLedgerForecastedEntryDTO;
  min: AllAggregateLedgerForecastedEntryDTO;
  month?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  sum: NumericAggregateLedgerForecastedEntryDTO;
  type?: Maybe<TypeOfLedgerEntry>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type AggregateLivestockGroupContractPeriodAllocationDTO = {
  __typename?: 'AggregateLivestockGroupContractPeriodAllocationDTO';
  LivestockGroupContract?: Maybe<GroupedBySwineLivestockGroupContractDTO>;
  allocatedQuantity?: Maybe<Scalars['Int']>;
  avg: NumericAggregateLivestockGroupContractPeriodAllocationDTO;
  businessId?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  contractedQuantity?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['String']>;
  exactAllocatedQuantity?: Maybe<Scalars['Float']>;
  exactOpenQuantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  max: AllAggregateLivestockGroupContractPeriodAllocationDTO;
  min: AllAggregateLivestockGroupContractPeriodAllocationDTO;
  openQuantity?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  sum: NumericAggregateLivestockGroupContractPeriodAllocationDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateLrpInsuranceEndorsementDTO = {
  __typename?: 'AggregateLrpInsuranceEndorsementDTO';
  Agent?: Maybe<GroupedByInsuranceAgentDTO>;
  AsInsuranceEndorsement?: Maybe<GroupedByInsuranceEndorsementDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  InsurancePolicy?: Maybe<GroupedByInsurancePolicyDTO>;
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  Policy?: Maybe<GroupedByInsurancePolicyDTO>;
  Practice?: Maybe<GroupedByInsurancePracticeDTO>;
  RevenueHedgeProduct?: Maybe<GroupedByProductDTO>;
  RmaCommodity?: Maybe<GroupedByRmaCommodityDTO>;
  RmaType?: Maybe<GroupedByRmaTypeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipLrpPremiumKey?: Maybe<Scalars['String']>;
  avg: NumericAggregateLrpInsuranceEndorsementDTO;
  commodityCode?: Maybe<Scalars['String']>;
  commodityCodeYear?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['String']>;
  commodityPrice?: Maybe<Scalars['Float']>;
  commodityPriceIsEstimated?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['Int']>;
  coverageEndDate?: Maybe<Scalars['String']>;
  coveragePrice?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  declaredShare?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  headCount?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  inputSource?: Maybe<InputSource>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredValue?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isShort?: Maybe<Scalars['Boolean']>;
  lengthInWeeks?: Maybe<Scalars['Int']>;
  livestockRate?: Maybe<Scalars['Float']>;
  max: AllAggregateLrpInsuranceEndorsementDTO;
  min: AllAggregateLrpInsuranceEndorsementDTO;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  revenueHedgeProductId?: Maybe<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  rmaTypeId?: Maybe<Scalars['String']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  sum: NumericAggregateLrpInsuranceEndorsementDTO;
  targetWeightCwt?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceEndorsement>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregatePhysicalFeedTransactionDTO = {
  __typename?: 'AggregatePhysicalFeedTransactionDTO';
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  FeedIngredient?: Maybe<GroupedByFeedIngredientDTO>;
  avg: NumericAggregatePhysicalFeedTransactionDTO;
  basisPrice?: Maybe<Scalars['Float']>;
  businessId?: Maybe<Scalars['String']>;
  contractIdentifier?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  feedIngredientId?: Maybe<Scalars['String']>;
  flatPrice?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  futuresPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  max: AllAggregatePhysicalFeedTransactionDTO;
  min: AllAggregatePhysicalFeedTransactionDTO;
  pricingType?: Maybe<TypeOfPhysicalFeedTransactionPricing>;
  seller?: Maybe<Scalars['String']>;
  sum: NumericAggregatePhysicalFeedTransactionDTO;
  tons?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregatePositionComponentAllocationDTO = {
  __typename?: 'AggregatePositionComponentAllocationDTO';
  Account?: Maybe<GroupedByAccountDTO>;
  AccountAsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AccountAsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AccountAsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  CurrentAllocationPosition?: Maybe<GroupedByCurrentAllocationPositionDTO>;
  CurrentPosition?: Maybe<GroupedByCurrentPositionDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  accountId?: Maybe<Scalars['String']>;
  allocationPositionId?: Maybe<Scalars['String']>;
  avg: NumericAggregatePositionComponentAllocationDTO;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveHedgeDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  longContractQuantity?: Maybe<Scalars['Float']>;
  longUnitQuantity?: Maybe<Scalars['Float']>;
  max: AllAggregatePositionComponentAllocationDTO;
  min: AllAggregatePositionComponentAllocationDTO;
  netContractQuantity?: Maybe<Scalars['Float']>;
  netUnitQuantity?: Maybe<Scalars['Float']>;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  shortContractQuantity?: Maybe<Scalars['Float']>;
  shortUnitQuantity?: Maybe<Scalars['Float']>;
  sum: NumericAggregatePositionComponentAllocationDTO;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregatePositionComponentDTO = {
  __typename?: 'AggregatePositionComponentDTO';
  Account?: Maybe<GroupedByAccountDTO>;
  AccountAsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AccountAsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AccountAsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  CurrentPosition?: Maybe<GroupedByCurrentPositionDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  IntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  accountId?: Maybe<Scalars['String']>;
  aclResourceType?: Maybe<TypeOfAclResource>;
  avg: NumericAggregatePositionComponentDTO;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  max: AllAggregatePositionComponentDTO;
  min: AllAggregatePositionComponentDTO;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sum: NumericAggregatePositionComponentDTO;
  tradeDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateRmaSummaryOfBusinessDTO = {
  __typename?: 'AggregateRmaSummaryOfBusinessDTO';
  Commodity?: Maybe<GroupedByRmaCommodityDTO>;
  InsurancePlan?: Maybe<GroupedByRmaInsurancePlanDTO>;
  State?: Maybe<GroupedByStateDTO>;
  avg: NumericAggregateRmaSummaryOfBusinessDTO;
  commodityId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  countyCode?: Maybe<Scalars['String']>;
  coverageFlag?: Maybe<Scalars['String']>;
  coverageLevel?: Maybe<Scalars['Int']>;
  cropYear?: Maybe<Scalars['Int']>;
  deliverySystem?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Int']>;
  insurancePlanId?: Maybe<Scalars['String']>;
  liability?: Maybe<Scalars['Int']>;
  max: AllAggregateRmaSummaryOfBusinessDTO;
  min: AllAggregateRmaSummaryOfBusinessDTO;
  netAcres?: Maybe<Scalars['Int']>;
  policiesEarningPremium?: Maybe<Scalars['Int']>;
  policiesIndemnified?: Maybe<Scalars['Int']>;
  policiesSold?: Maybe<Scalars['Int']>;
  stateId?: Maybe<Scalars['String']>;
  subsidy?: Maybe<Scalars['Int']>;
  sum: NumericAggregateRmaSummaryOfBusinessDTO;
  totalPremium?: Maybe<Scalars['Int']>;
  unitsEarningPremium?: Maybe<Scalars['Int']>;
  unitsIndemnified?: Maybe<Scalars['Int']>;
};

export type AggregateSwineLivestockPopulationChangeDTO = {
  __typename?: 'AggregateSwineLivestockPopulationChangeDTO';
  AsLivestockPopulationChange?: Maybe<GroupedByLivestockPopulationChangeDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  ContractPeriodAllocation?: Maybe<GroupedByLivestockGroupContractPeriodAllocationDTO>;
  avg: NumericAggregateSwineLivestockPopulationChangeDTO;
  businessId?: Maybe<Scalars['String']>;
  contractPeriodAllocationId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  dobWeek?: Maybe<Scalars['Int']>;
  exactQuantity?: Maybe<Scalars['Float']>;
  exactTotalValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  marketingWeekStartDate?: Maybe<Scalars['String']>;
  max: AllAggregateSwineLivestockPopulationChangeDTO;
  min: AllAggregateSwineLivestockPopulationChangeDTO;
  quantity?: Maybe<Scalars['Int']>;
  readableDobWeek?: Maybe<Scalars['String']>;
  readableWeek?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reasonType?: Maybe<TypeOfLivestockPopulationChangeReason>;
  startOfMonth?: Maybe<Scalars['String']>;
  sum: NumericAggregateSwineLivestockPopulationChangeDTO;
  systemComment?: Maybe<Scalars['String']>;
  totalValue?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfLivestockPopulationChange>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valuePerHead?: Maybe<Scalars['Float']>;
  valueType?: Maybe<TypeOfLivestockPopulationChangeValue>;
  week?: Maybe<Scalars['Int']>;
};

export type AggregateTransactionDTO = {
  __typename?: 'AggregateTransactionDTO';
  Account?: Maybe<GroupedByAccountDTO>;
  AccountAsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AccountAsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AccountAsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  CurrentPosition?: Maybe<GroupedByCurrentPositionDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  HedgeStrategyCurrentPosition?: Maybe<GroupedByHedgeStrategyCurrentPositionDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  Order?: Maybe<GroupedByOrderDTO>;
  PositionReconciliation?: Maybe<GroupedByPositionReconciliationDTO>;
  TransactionGroup?: Maybe<GroupedByTransactionGroupDTO>;
  accountId?: Maybe<Scalars['String']>;
  avg: NumericAggregateTransactionDTO;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  executionType?: Maybe<TypeOfTransactionExecution>;
  feeTotal?: Maybe<Scalars['Float']>;
  hedgeStrategyCurrentPositionId?: Maybe<Scalars['String']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  max: AllAggregateTransactionDTO;
  min: AllAggregateTransactionDTO;
  occurredAt?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sum: NumericAggregateTransactionDTO;
  total?: Maybe<Scalars['Float']>;
  tradeDate?: Maybe<Scalars['String']>;
  tradeMonth?: Maybe<Scalars['Int']>;
  tradeYear?: Maybe<Scalars['Int']>;
  transactionGroupId?: Maybe<Scalars['String']>;
  unitQuantity?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AggregateTransactionFeeDTO = {
  __typename?: 'AggregateTransactionFeeDTO';
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  Transaction?: Maybe<GroupedByTransactionDTO>;
  avg: NumericAggregateTransactionFeeDTO;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  max: AllAggregateTransactionFeeDTO;
  min: AllAggregateTransactionFeeDTO;
  sum: NumericAggregateTransactionFeeDTO;
  transactionId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfTransactionFee>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type AggregateUsdaMonthlyNationalAverageDairyPriceDTO = {
  __typename?: 'AggregateUsdaMonthlyNationalAverageDairyPriceDTO';
  advancedSkimMilkClassIiPrice?: Maybe<Scalars['Float']>;
  avg: NumericAggregateUsdaMonthlyNationalAverageDairyPriceDTO;
  butterMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  butterfatPrice?: Maybe<Scalars['Float']>;
  cheeseMonthly40Price?: Maybe<Scalars['Float']>;
  cheeseMonthly500Price?: Maybe<Scalars['Float']>;
  cheeseMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  classiiButterfatPrice?: Maybe<Scalars['Float']>;
  classiiPrice?: Maybe<Scalars['Float']>;
  classiiiPrice?: Maybe<Scalars['Float']>;
  classiiiSkimMilkPrice?: Maybe<Scalars['Float']>;
  classivPrice?: Maybe<Scalars['Float']>;
  classivSkimMilkPrice?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  max: AllAggregateUsdaMonthlyNationalAverageDairyPriceDTO;
  min: AllAggregateUsdaMonthlyNationalAverageDairyPriceDTO;
  nfdmMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  nonfatSolidsPrice?: Maybe<Scalars['Float']>;
  otherSolidsPrice?: Maybe<Scalars['Float']>;
  proteinPrice?: Maybe<Scalars['Float']>;
  somaticCellAdjustmentRate?: Maybe<Scalars['Float']>;
  sum: NumericAggregateUsdaMonthlyNationalAverageDairyPriceDTO;
  wheyMonthlyAvgPrice?: Maybe<Scalars['Float']>;
};

export type AggregateUsdaMonthlyRegionalMilkComponentPriceDTO = {
  __typename?: 'AggregateUsdaMonthlyRegionalMilkComponentPriceDTO';
  MilkOrder?: Maybe<GroupedByMilkOrderDTO>;
  avg: NumericAggregateUsdaMonthlyRegionalMilkComponentPriceDTO;
  butterfat?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  fmmoRegionCode?: Maybe<Scalars['Float']>;
  fmmoRegionId?: Maybe<Scalars['String']>;
  max: AllAggregateUsdaMonthlyRegionalMilkComponentPriceDTO;
  min: AllAggregateUsdaMonthlyRegionalMilkComponentPriceDTO;
  nfs?: Maybe<Scalars['Float']>;
  otherSolids?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Scalars['Float']>;
  somcell?: Maybe<Scalars['Float']>;
  sum: NumericAggregateUsdaMonthlyRegionalMilkComponentPriceDTO;
};

export type AggregateUsdaMonthlyRegionalMilkUtilizationDTO = {
  __typename?: 'AggregateUsdaMonthlyRegionalMilkUtilizationDTO';
  MilkOrder?: Maybe<GroupedByMilkOrderDTO>;
  avg: NumericAggregateUsdaMonthlyRegionalMilkUtilizationDTO;
  classiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiUtilizationPercent?: Maybe<Scalars['Float']>;
  classiiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiiCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classiiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiiUtilizationPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionOtherSolidsPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionProteinPercent?: Maybe<Scalars['Float']>;
  classiiiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiiiUtilizationPercent?: Maybe<Scalars['Float']>;
  classivCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classivCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classivUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classivUtilizationPercent?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  fmmoRegionCode?: Maybe<Scalars['Float']>;
  fmmoRegionId?: Maybe<Scalars['String']>;
  max: AllAggregateUsdaMonthlyRegionalMilkUtilizationDTO;
  min: AllAggregateUsdaMonthlyRegionalMilkUtilizationDTO;
  sum: NumericAggregateUsdaMonthlyRegionalMilkUtilizationDTO;
};

export type AggregateUsdaRetailSummaryDTO = {
  __typename?: 'AggregateUsdaRetailSummaryDTO';
  activityIndex?: Maybe<Scalars['Float']>;
  avg: NumericAggregateUsdaRetailSummaryDTO;
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  featureRate?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isOrganic?: Maybe<Scalars['Boolean']>;
  isSpecialty?: Maybe<Scalars['Boolean']>;
  issuingOffice?: Maybe<Scalars['String']>;
  levelFive?: Maybe<Scalars['String']>;
  levelFour?: Maybe<Scalars['String']>;
  levelOfTrade?: Maybe<Scalars['String']>;
  levelOne?: Maybe<Scalars['String']>;
  levelThree?: Maybe<Scalars['String']>;
  levelTwo?: Maybe<Scalars['String']>;
  locallyGrownPercentage?: Maybe<Scalars['Float']>;
  max: AllAggregateUsdaRetailSummaryDTO;
  min: AllAggregateUsdaRetailSummaryDTO;
  priceHigh?: Maybe<Scalars['Float']>;
  priceLow?: Maybe<Scalars['Float']>;
  productQuality?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['String']>;
  specialRate?: Maybe<Scalars['Float']>;
  storeOutlets?: Maybe<Scalars['Int']>;
  storesWithAds?: Maybe<Scalars['Int']>;
  sum: NumericAggregateUsdaRetailSummaryDTO;
  unit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weightedAveragePrice?: Maybe<Scalars['Float']>;
};

export type Aip = {
  __typename?: 'Aip';
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rmaCode: RmaAipCode;
  twoLetterRmaCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AipDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  rmaCode?: InputMaybe<Scalars['Boolean']>;
  twoLetterRmaCode?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AipFilterDTO = {
  AND?: InputMaybe<Array<AipFilterDTO>>;
  InsurancePolicies?: InputMaybe<InsurancePolicyFilterDTO>;
  NOT?: InputMaybe<AipFilterDTO>;
  OR?: InputMaybe<Array<AipFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  externalId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  rmaCode?: InputMaybe<RmaAipCodeFilter>;
  twoLetterRmaCode?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AipGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  rmaCode?: InputMaybe<Scalars['Boolean']>;
  twoLetterRmaCode?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type AipSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  rmaCode?: InputMaybe<SortByDirection>;
  twoLetterRmaCode?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type AllAggregateActualBlendedMilkPriceDTO = {
  __typename?: 'AllAggregateActualBlendedMilkPriceDTO';
  basis?: Maybe<Scalars['Float']>;
  classiiiBasis?: Maybe<Scalars['Float']>;
  classivBasis?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateActualMilkProductionByMonthDTO = {
  __typename?: 'AllAggregateActualMilkProductionByMonthDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  averageDailyProductionPerCow?: Maybe<Scalars['Float']>;
  butterfatPercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  exactNumberOfCows?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  numberOfCows?: Maybe<Scalars['Float']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateActualMilkProductionDTO = {
  __typename?: 'AllAggregateActualMilkProductionDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  butterfatPercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  grossButterfat?: Maybe<Scalars['Float']>;
  grossOtherSolids?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProtein?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  numberOfCows?: Maybe<Scalars['Float']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  productionPerCow?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateActualProductionDTO = {
  __typename?: 'AllAggregateActualProductionDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  grossProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productionPerUnit?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateAllocatedForecastedHedgedAndCappedVolumeDTO = {
  __typename?: 'AllAggregateAllocatedForecastedHedgedAndCappedVolumeDTO';
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  naturallyLongCapped?: Maybe<Scalars['Float']>;
  naturallyLongHedged?: Maybe<Scalars['Float']>;
  naturallyShortCapped?: Maybe<Scalars['Float']>;
  naturallyShortHedged?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
};

export type AllAggregateBrokerageAccountDTO = {
  __typename?: 'AllAggregateBrokerageAccountDTO';
  accountNumber?: Maybe<Scalars['String']>;
  cqgId?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentBalance?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['String']>;
  fcmAccountLastActiveDate?: Maybe<Scalars['String']>;
  fcmAccountLastUpdatedDate?: Maybe<Scalars['String']>;
  fcmAccountOpenDate?: Maybe<Scalars['String']>;
  fcmId?: Maybe<Scalars['String']>;
  futuresMarketValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  liquidatingValue?: Maybe<Scalars['Float']>;
  marginCredit?: Maybe<Scalars['Float']>;
  marginExcessDeficit?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  openTradeEquity?: Maybe<Scalars['Float']>;
  optionsMarketValue?: Maybe<Scalars['Float']>;
  ownerId?: Maybe<Scalars['String']>;
  parentAccountId?: Maybe<Scalars['String']>;
  positionMargin?: Maybe<Scalars['Float']>;
  previousAccountBalance?: Maybe<Scalars['Float']>;
  purchasingPower?: Maybe<Scalars['Float']>;
  salesCode?: Maybe<Scalars['String']>;
  totalMargin?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateCropHarvestDTO = {
  __typename?: 'AllAggregateCropHarvestDTO';
  acres?: Maybe<Scalars['Float']>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cropId?: Maybe<Scalars['String']>;
  fieldId?: Maybe<Scalars['String']>;
  forecastedHarvestDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yieldPerAcre?: Maybe<Scalars['Float']>;
};

export type AllAggregateCurrentAllocationPositionDTO = {
  __typename?: 'AllAggregateCurrentAllocationPositionDTO';
  accountId?: Maybe<Scalars['String']>;
  allocationPositionId?: Maybe<Scalars['String']>;
  businessId?: Maybe<Scalars['String']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWeightedAveragePrice?: Maybe<Scalars['Float']>;
  effectiveHedgeDate?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  lifetimeWeightedAveragePrice?: Maybe<Scalars['Float']>;
  openSideWeightedAveragePrice?: Maybe<Scalars['Float']>;
  openWeightedAveragePrice?: Maybe<Scalars['Float']>;
  ownerId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  unitQuantity?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateCurrentPositionComponentDTO = {
  __typename?: 'AllAggregateCurrentPositionComponentDTO';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  tradeDate?: Maybe<Scalars['String']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateCurrentPositionDTO = {
  __typename?: 'AllAggregateCurrentPositionDTO';
  accountId?: Maybe<Scalars['String']>;
  calculatedRealizedPnl?: Maybe<Scalars['Float']>;
  calculatedUnrealizedPnl?: Maybe<Scalars['Float']>;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  currentWeightedAveragePrice?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['String']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  feeTotal?: Maybe<Scalars['Float']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  lifetimeWeightedAveragePrice?: Maybe<Scalars['Float']>;
  ownerId?: Maybe<Scalars['String']>;
  positionComponentRealizedPnl?: Maybe<Scalars['Float']>;
  positionComponentUnrealizedPnl?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  totalTradeValue?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnlPriceDate?: Maybe<Scalars['String']>;
  weightedAveragePrice?: Maybe<Scalars['Float']>;
};

export type AllAggregateDerivedDrpEndorsementDTO = {
  __typename?: 'AllAggregateDerivedDrpEndorsementDTO';
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classIiiGrossProtection?: Maybe<Scalars['Float']>;
  classIiiPounds?: Maybe<Scalars['Float']>;
  classIvGrossProtection?: Maybe<Scalars['Float']>;
  classIvPounds?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassIiiPrice?: Maybe<Scalars['Float']>;
  expectedClassIvPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassIii?: Maybe<Scalars['Float']>;
  indemnityEffectClassIv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassIiiPrice?: Maybe<Scalars['Float']>;
  insuredClassIvPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  quarterEndDate?: Maybe<Scalars['String']>;
  quarterStartDate?: Maybe<Scalars['String']>;
  reinsuranceYear?: Maybe<Scalars['Float']>;
  rmaPracticeCode?: Maybe<Scalars['Float']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type AllAggregateDrpEndorsementDTO = {
  __typename?: 'AllAggregateDrpEndorsementDTO';
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipDrpPremiumKey?: Maybe<Scalars['String']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueAmount?: Maybe<Scalars['String']>;
  expectedRevenueGuarantee?: Maybe<Scalars['String']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Float']>;
  rmaPracticeCode?: Maybe<Scalars['String']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type AllAggregateDrpInsuranceEndorsementDTO = {
  __typename?: 'AllAggregateDrpInsuranceEndorsementDTO';
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipDrpPremiumKey?: Maybe<Scalars['String']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueAmount?: Maybe<Scalars['String']>;
  expectedRevenueGuarantee?: Maybe<Scalars['String']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Float']>;
  rmaPracticeCode?: Maybe<Scalars['String']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type AllAggregateEntityAllocatedExposureRatioByOwnerDTO = {
  __typename?: 'AllAggregateEntityAllocatedExposureRatioByOwnerDTO';
  brokerageVolumeCapped?: Maybe<Scalars['Float']>;
  brokerageVolumeHedged?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  drpVolumeCapped?: Maybe<Scalars['Float']>;
  drpVolumeHedged?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  exposure?: Maybe<Scalars['Float']>;
  inventoryExposure?: Maybe<Scalars['Float']>;
  lgmVolumeCapped?: Maybe<Scalars['Float']>;
  lgmVolumeHedged?: Maybe<Scalars['Float']>;
  lrpVolumeCapped?: Maybe<Scalars['Float']>;
  lrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeCapped?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeHedged?: Maybe<Scalars['Float']>;
  percentLgmVolumeCapped?: Maybe<Scalars['Float']>;
  percentLgmVolumeHedged?: Maybe<Scalars['Float']>;
  percentLrpVolumeCapped?: Maybe<Scalars['Float']>;
  percentLrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeCapped?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeHedged?: Maybe<Scalars['Float']>;
  physicalVolumeCapped?: Maybe<Scalars['Float']>;
  physicalVolumeHedged?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productionExposure?: Maybe<Scalars['Float']>;
  totalPercentOpen?: Maybe<Scalars['Float']>;
  totalPercentVolumeCapped?: Maybe<Scalars['Float']>;
  totalPercentVolumeHedged?: Maybe<Scalars['Float']>;
  totalVolumeCapped?: Maybe<Scalars['Float']>;
  totalVolumeHedged?: Maybe<Scalars['Float']>;
};

export type AllAggregateEntityAllocatedExposureRatioDTO = {
  __typename?: 'AllAggregateEntityAllocatedExposureRatioDTO';
  brokerageVolumeCapped?: Maybe<Scalars['Float']>;
  brokerageVolumeHedged?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  drpVolumeCapped?: Maybe<Scalars['Float']>;
  drpVolumeHedged?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  exposure?: Maybe<Scalars['Float']>;
  inventoryExposure?: Maybe<Scalars['Float']>;
  lgmVolumeCapped?: Maybe<Scalars['Float']>;
  lgmVolumeHedged?: Maybe<Scalars['Float']>;
  lrpVolumeCapped?: Maybe<Scalars['Float']>;
  lrpVolumeHedged?: Maybe<Scalars['Float']>;
  netProductionExposure?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeCapped?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeHedged?: Maybe<Scalars['Float']>;
  percentLgmVolumeCapped?: Maybe<Scalars['Float']>;
  percentLgmVolumeHedged?: Maybe<Scalars['Float']>;
  percentLrpVolumeCapped?: Maybe<Scalars['Float']>;
  percentLrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeCapped?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeHedged?: Maybe<Scalars['Float']>;
  physicalVolumeCapped?: Maybe<Scalars['Float']>;
  physicalVolumeHedged?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productionExposure?: Maybe<Scalars['Float']>;
  totalPercentOpen?: Maybe<Scalars['Float']>;
  totalPercentVolumeCapped?: Maybe<Scalars['Float']>;
  totalPercentVolumeHedged?: Maybe<Scalars['Float']>;
  totalVolumeCapped?: Maybe<Scalars['Float']>;
  totalVolumeHedged?: Maybe<Scalars['Float']>;
};

export type AllAggregateEntityExposureRatioDTO = {
  __typename?: 'AllAggregateEntityExposureRatioDTO';
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  exposure?: Maybe<Scalars['Float']>;
  inventoryExposure?: Maybe<Scalars['Float']>;
  netProductionExposure?: Maybe<Scalars['Float']>;
  percentCapped?: Maybe<Scalars['Float']>;
  percentCappedCumulative?: Maybe<Scalars['Float']>;
  percentHedged?: Maybe<Scalars['Float']>;
  percentHedgedCumulative?: Maybe<Scalars['Float']>;
  percentOpen?: Maybe<Scalars['Float']>;
  percentOpenCumulative?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productionExposure?: Maybe<Scalars['Float']>;
  volumeCapped?: Maybe<Scalars['Float']>;
  volumeCappedCumulative?: Maybe<Scalars['Float']>;
  volumeHedged?: Maybe<Scalars['Float']>;
  volumeHedgedCumulative?: Maybe<Scalars['Float']>;
};

export type AllAggregateFeedIngredientActualUsageDTO = {
  __typename?: 'AllAggregateFeedIngredientActualUsageDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  basisPrice?: Maybe<Scalars['Float']>;
  cashPrice?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb?: Maybe<Scalars['Float']>;
  dmiUsageInTons?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  feedIngredientSeriesId?: Maybe<Scalars['String']>;
  feedUsageInLb?: Maybe<Scalars['Float']>;
  feedUsageInTons?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateFeedIngredientConsumedAndPurchasedVolumeDTO = {
  __typename?: 'AllAggregateFeedIngredientConsumedAndPurchasedVolumeDTO';
  entityId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  forecastedConsumptionInTons?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  monthStartDate?: Maybe<Scalars['String']>;
  purchasedInTons?: Maybe<Scalars['Float']>;
  totalPurchasedCostInUsd?: Maybe<Scalars['Float']>;
  volumePurchasedWithBasisPriceOnlyInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithFlatPriceInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithFuturesPriceOnlyInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithoutPriceInTons?: Maybe<Scalars['Float']>;
};

export type AllAggregateFeedIngredientForecastedUsageDTO = {
  __typename?: 'AllAggregateFeedIngredientForecastedUsageDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  basisPrice?: Maybe<Scalars['Float']>;
  cashPrice?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb?: Maybe<Scalars['Float']>;
  dmiUsageInTons?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  feedIngredientSeriesId?: Maybe<Scalars['String']>;
  feedUsageInLb?: Maybe<Scalars['Float']>;
  feedUsageInTons?: Maybe<Scalars['Float']>;
  forecastedPerLbExpenseInUsd?: Maybe<Scalars['Float']>;
  forecastedTotalExpenseInUsd?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateFieldDTO = {
  __typename?: 'AllAggregateFieldDTO';
  acres?: Maybe<Scalars['Float']>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateForecastedHedgedAndCappedVolumeDTO = {
  __typename?: 'AllAggregateForecastedHedgedAndCappedVolumeDTO';
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  naturallyLongCapped?: Maybe<Scalars['Float']>;
  naturallyLongHedged?: Maybe<Scalars['Float']>;
  naturallyShortCapped?: Maybe<Scalars['Float']>;
  naturallyShortHedged?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
};

export type AllAggregateForecastedMilkProductionByMonthDTO = {
  __typename?: 'AllAggregateForecastedMilkProductionByMonthDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  averageDailyProductionPerCow?: Maybe<Scalars['Float']>;
  butterfatPercent?: Maybe<Scalars['Float']>;
  classiUtilization?: Maybe<Scalars['Float']>;
  classiiUtilization?: Maybe<Scalars['Float']>;
  classiiiUtilization?: Maybe<Scalars['Float']>;
  classivUtilization?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  exactNumberOfCows?: Maybe<Scalars['Float']>;
  forecastedBlendedMilkPrice?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossClassiProduction?: Maybe<Scalars['Float']>;
  grossClassiiProduction?: Maybe<Scalars['Float']>;
  grossClassiiiProduction?: Maybe<Scalars['Float']>;
  grossClassivProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  numberOfCows?: Maybe<Scalars['Float']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateForecastedMilkProductionByMonthRolledUpDTO = {
  __typename?: 'AllAggregateForecastedMilkProductionByMonthRolledUpDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  exactNumberOfCows?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossClassiProduction?: Maybe<Scalars['Float']>;
  grossClassiiProduction?: Maybe<Scalars['Float']>;
  grossClassiiiProduction?: Maybe<Scalars['Float']>;
  grossClassivProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  numberOfCows?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateForecastedMilkProductionCoverageDTO = {
  __typename?: 'AllAggregateForecastedMilkProductionCoverageDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  classIiiPounds?: Maybe<Scalars['Float']>;
  classIvPounds?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossClassIProduction?: Maybe<Scalars['Float']>;
  grossClassIiProduction?: Maybe<Scalars['Float']>;
  grossClassIiiProduction?: Maybe<Scalars['Float']>;
  grossClassIvProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  percentInsured?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  quarterStartDate?: Maybe<Scalars['String']>;
  readableQuarter?: Maybe<Scalars['String']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateForecastedMilkProductionDTO = {
  __typename?: 'AllAggregateForecastedMilkProductionDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  butterfatPercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  grossButterfat?: Maybe<Scalars['Float']>;
  grossOtherSolids?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProtein?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  numberOfCows?: Maybe<Scalars['Float']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  productionPerCow?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateForecastedMilkUtilizationDTO = {
  __typename?: 'AllAggregateForecastedMilkUtilizationDTO';
  classiDifferential?: Maybe<Scalars['Float']>;
  classiUtilization?: Maybe<Scalars['Float']>;
  classiiUtilization?: Maybe<Scalars['Float']>;
  classiiiUtilization?: Maybe<Scalars['Float']>;
  classivUtilization?: Maybe<Scalars['Float']>;
  countyId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  grossClassiDifferentialPrice?: Maybe<Scalars['Float']>;
  grossClassiPounds?: Maybe<Scalars['Float']>;
  grossClassiiPounds?: Maybe<Scalars['Float']>;
  grossClassiiiPounds?: Maybe<Scalars['Float']>;
  grossClassivPounds?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateForecastedProductionDTO = {
  __typename?: 'AllAggregateForecastedProductionDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  grossProduction?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productionPerUnit?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['String']>;
  units?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateForecastedSwineLivestockFeedUsageAtFinishDTO = {
  __typename?: 'AllAggregateForecastedSwineLivestockFeedUsageAtFinishDTO';
  businessId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  quantityInLbs?: Maybe<Scalars['Float']>;
  quantityInTons?: Maybe<Scalars['Float']>;
  totalExpenseInUsd?: Maybe<Scalars['Float']>;
};

export type AllAggregateForecastedSwineLivestockFeedUsageDTO = {
  __typename?: 'AllAggregateForecastedSwineLivestockFeedUsageDTO';
  ageInWeeks?: Maybe<Scalars['Float']>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  hedgePriceInUsd?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredientId?: Maybe<Scalars['String']>;
  livestockGroupFeedUsageId?: Maybe<Scalars['String']>;
  perLbExpenseInUsd?: Maybe<Scalars['Float']>;
  quantityInLbs?: Maybe<Scalars['Float']>;
  quantityInTons?: Maybe<Scalars['Float']>;
  readableWeek?: Maybe<Scalars['String']>;
  totalExpenseInUsd?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateGrainFillOrderDTO = {
  __typename?: 'AllAggregateGrainFillOrderDTO';
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  feedFlatPrice?: Maybe<Scalars['Float']>;
  feedPrice?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee?: Maybe<Scalars['Float']>;
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  htaFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  rollFee?: Maybe<Scalars['Float']>;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  targetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Float']>;
};

export type AllAggregateGrainTargetOrderDTO = {
  __typename?: 'AllAggregateGrainTargetOrderDTO';
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  buyerRelationsOwnerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  feedFlatPrice?: Maybe<Scalars['Float']>;
  feedPrice?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee?: Maybe<Scalars['Float']>;
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  htaFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  rollFee?: Maybe<Scalars['Float']>;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Float']>;
};

export type AllAggregateHedgeStrategyCurrentPositionDTO = {
  __typename?: 'AllAggregateHedgeStrategyCurrentPositionDTO';
  businessId?: Maybe<Scalars['String']>;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  feeTotal?: Maybe<Scalars['Float']>;
  firstTradeDate?: Maybe<Scalars['String']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  lastTradeDate?: Maybe<Scalars['String']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  unitQuantity?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnlPriceDate?: Maybe<Scalars['String']>;
};

export type AllAggregateHedgeStrategyHistoricalPnlDTO = {
  __typename?: 'AllAggregateHedgeStrategyHistoricalPnlDTO';
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  grossPnl?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insuranceNetPremium?: Maybe<Scalars['Float']>;
  insuranceRealizedPnl?: Maybe<Scalars['Float']>;
  insuranceUnrealizedPnl?: Maybe<Scalars['Float']>;
  netPnl?: Maybe<Scalars['Float']>;
  netPremium?: Maybe<Scalars['Float']>;
  positionNetPremium?: Maybe<Scalars['Float']>;
  positionRealizedPnl?: Maybe<Scalars['Float']>;
  positionUnrealizedPnl?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateHistoricalPnlDTO = {
  __typename?: 'AllAggregateHistoricalPnlDTO';
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  displayExpirationDate?: Maybe<Scalars['String']>;
  feeTotal?: Maybe<Scalars['Float']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossPnlChange?: Maybe<Scalars['Float']>;
  marketValue?: Maybe<Scalars['Float']>;
  marketValueChange?: Maybe<Scalars['Float']>;
  nextDate?: Maybe<Scalars['String']>;
  optionPremium?: Maybe<Scalars['Float']>;
  previousCommissionAndFeeTotal?: Maybe<Scalars['Float']>;
  previousCommissionTotal?: Maybe<Scalars['Float']>;
  previousDate?: Maybe<Scalars['String']>;
  previousFeeTotal?: Maybe<Scalars['Float']>;
  previousGrossPnl?: Maybe<Scalars['Float']>;
  previousMarketValue?: Maybe<Scalars['Float']>;
  previousRealizedPnl?: Maybe<Scalars['Float']>;
  previousUnrealizedPnl?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  realizedPnlChange?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnlChange?: Maybe<Scalars['Float']>;
};

export type AllAggregateInsuranceHistoricalDrpPremiumDTO = {
  __typename?: 'AllAggregateInsuranceHistoricalDrpPremiumDTO';
  coverageLevel?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  grossPremium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  insurancePracticeDisplayDate?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  liability?: Maybe<Scalars['Float']>;
  netPremium?: Maybe<Scalars['Float']>;
  protectedPrice?: Maybe<Scalars['Float']>;
  revenueGuarantee?: Maybe<Scalars['Float']>;
  stateId?: Maybe<Scalars['String']>;
  subsidy?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateInventoryLevelDTO = {
  __typename?: 'AllAggregateInventoryLevelDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateLedgerActualEntryDTO = {
  __typename?: 'AllAggregateLedgerActualEntryDTO';
  amount?: Maybe<Scalars['Float']>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateLedgerEntryDTO = {
  __typename?: 'AllAggregateLedgerEntryDTO';
  amount?: Maybe<Scalars['Float']>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateLedgerForecastedEntryDTO = {
  __typename?: 'AllAggregateLedgerForecastedEntryDTO';
  amount?: Maybe<Scalars['Float']>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Float']>;
};

export type AllAggregateLivestockGroupContractPeriodAllocationDTO = {
  __typename?: 'AllAggregateLivestockGroupContractPeriodAllocationDTO';
  allocatedQuantity?: Maybe<Scalars['Float']>;
  businessId?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  contractedQuantity?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['String']>;
  exactAllocatedQuantity?: Maybe<Scalars['Float']>;
  exactOpenQuantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  openQuantity?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateLrpInsuranceEndorsementDTO = {
  __typename?: 'AllAggregateLrpInsuranceEndorsementDTO';
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipLrpPremiumKey?: Maybe<Scalars['String']>;
  commodityCode?: Maybe<Scalars['String']>;
  commodityCodeYear?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['String']>;
  commodityPrice?: Maybe<Scalars['Float']>;
  coverageEndDate?: Maybe<Scalars['String']>;
  coveragePrice?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  declaredShare?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  headCount?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredValue?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  lengthInWeeks?: Maybe<Scalars['Float']>;
  livestockRate?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Float']>;
  revenueHedgeProductId?: Maybe<Scalars['String']>;
  rmaTypeId?: Maybe<Scalars['String']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  targetWeightCwt?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregatePhysicalFeedTransactionDTO = {
  __typename?: 'AllAggregatePhysicalFeedTransactionDTO';
  basisPrice?: Maybe<Scalars['Float']>;
  businessId?: Maybe<Scalars['String']>;
  contractIdentifier?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  flatPrice?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  futuresPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  tons?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregatePositionComponentAllocationDTO = {
  __typename?: 'AllAggregatePositionComponentAllocationDTO';
  accountId?: Maybe<Scalars['String']>;
  allocationPositionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  effectiveHedgeDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  longContractQuantity?: Maybe<Scalars['Float']>;
  longUnitQuantity?: Maybe<Scalars['Float']>;
  netContractQuantity?: Maybe<Scalars['Float']>;
  netUnitQuantity?: Maybe<Scalars['Float']>;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  shortContractQuantity?: Maybe<Scalars['Float']>;
  shortUnitQuantity?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregatePositionComponentDTO = {
  __typename?: 'AllAggregatePositionComponentDTO';
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  tradeDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateRmaSummaryOfBusinessDTO = {
  __typename?: 'AllAggregateRmaSummaryOfBusinessDTO';
  commodityId?: Maybe<Scalars['String']>;
  countyCode?: Maybe<Scalars['String']>;
  coverageFlag?: Maybe<Scalars['String']>;
  coverageLevel?: Maybe<Scalars['Float']>;
  cropYear?: Maybe<Scalars['Float']>;
  deliverySystem?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  insurancePlanId?: Maybe<Scalars['String']>;
  liability?: Maybe<Scalars['Float']>;
  netAcres?: Maybe<Scalars['Float']>;
  policiesEarningPremium?: Maybe<Scalars['Float']>;
  policiesIndemnified?: Maybe<Scalars['Float']>;
  policiesSold?: Maybe<Scalars['Float']>;
  stateId?: Maybe<Scalars['String']>;
  subsidy?: Maybe<Scalars['Float']>;
  totalPremium?: Maybe<Scalars['Float']>;
  unitsEarningPremium?: Maybe<Scalars['Float']>;
  unitsIndemnified?: Maybe<Scalars['Float']>;
};

export type AllAggregateSwineLivestockPopulationChangeDTO = {
  __typename?: 'AllAggregateSwineLivestockPopulationChangeDTO';
  businessId?: Maybe<Scalars['String']>;
  contractPeriodAllocationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  dobWeek?: Maybe<Scalars['Float']>;
  exactQuantity?: Maybe<Scalars['Float']>;
  exactTotalValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  marketingWeekStartDate?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  readableDobWeek?: Maybe<Scalars['String']>;
  readableWeek?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startOfMonth?: Maybe<Scalars['String']>;
  systemComment?: Maybe<Scalars['String']>;
  totalValue?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valuePerHead?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
};

export type AllAggregateTransactionDTO = {
  __typename?: 'AllAggregateTransactionDTO';
  accountId?: Maybe<Scalars['String']>;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feeTotal?: Maybe<Scalars['Float']>;
  hedgeStrategyCurrentPositionId?: Maybe<Scalars['String']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  occurredAt?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  tradeDate?: Maybe<Scalars['String']>;
  tradeMonth?: Maybe<Scalars['Float']>;
  tradeYear?: Maybe<Scalars['Float']>;
  transactionGroupId?: Maybe<Scalars['String']>;
  unitQuantity?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllAggregateTransactionFeeDTO = {
  __typename?: 'AllAggregateTransactionFeeDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type AllAggregateUsdaMonthlyNationalAverageDairyPriceDTO = {
  __typename?: 'AllAggregateUsdaMonthlyNationalAverageDairyPriceDTO';
  advancedSkimMilkClassIiPrice?: Maybe<Scalars['Float']>;
  butterMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  butterfatPrice?: Maybe<Scalars['Float']>;
  cheeseMonthly40Price?: Maybe<Scalars['Float']>;
  cheeseMonthly500Price?: Maybe<Scalars['Float']>;
  cheeseMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  classiiButterfatPrice?: Maybe<Scalars['Float']>;
  classiiPrice?: Maybe<Scalars['Float']>;
  classiiiPrice?: Maybe<Scalars['Float']>;
  classiiiSkimMilkPrice?: Maybe<Scalars['Float']>;
  classivPrice?: Maybe<Scalars['Float']>;
  classivSkimMilkPrice?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  nfdmMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  nonfatSolidsPrice?: Maybe<Scalars['Float']>;
  otherSolidsPrice?: Maybe<Scalars['Float']>;
  proteinPrice?: Maybe<Scalars['Float']>;
  somaticCellAdjustmentRate?: Maybe<Scalars['Float']>;
  wheyMonthlyAvgPrice?: Maybe<Scalars['Float']>;
};

export type AllAggregateUsdaMonthlyRegionalMilkComponentPriceDTO = {
  __typename?: 'AllAggregateUsdaMonthlyRegionalMilkComponentPriceDTO';
  butterfat?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  fmmoRegionCode?: Maybe<Scalars['Float']>;
  fmmoRegionId?: Maybe<Scalars['String']>;
  nfs?: Maybe<Scalars['Float']>;
  otherSolids?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Scalars['Float']>;
  somcell?: Maybe<Scalars['Float']>;
};

export type AllAggregateUsdaMonthlyRegionalMilkUtilizationDTO = {
  __typename?: 'AllAggregateUsdaMonthlyRegionalMilkUtilizationDTO';
  classiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiUtilizationPercent?: Maybe<Scalars['Float']>;
  classiiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiiCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classiiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiiUtilizationPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionOtherSolidsPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionProteinPercent?: Maybe<Scalars['Float']>;
  classiiiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiiiUtilizationPercent?: Maybe<Scalars['Float']>;
  classivCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classivCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classivUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classivUtilizationPercent?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  fmmoRegionCode?: Maybe<Scalars['Float']>;
  fmmoRegionId?: Maybe<Scalars['String']>;
};

export type AllAggregateUsdaRetailSummaryDTO = {
  __typename?: 'AllAggregateUsdaRetailSummaryDTO';
  activityIndex?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  featureRate?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  issuingOffice?: Maybe<Scalars['String']>;
  levelFive?: Maybe<Scalars['String']>;
  levelFour?: Maybe<Scalars['String']>;
  levelOfTrade?: Maybe<Scalars['String']>;
  levelOne?: Maybe<Scalars['String']>;
  levelThree?: Maybe<Scalars['String']>;
  levelTwo?: Maybe<Scalars['String']>;
  locallyGrownPercentage?: Maybe<Scalars['Float']>;
  priceHigh?: Maybe<Scalars['Float']>;
  priceLow?: Maybe<Scalars['Float']>;
  productQuality?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['String']>;
  specialRate?: Maybe<Scalars['Float']>;
  storeOutlets?: Maybe<Scalars['Float']>;
  storesWithAds?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weightedAveragePrice?: Maybe<Scalars['Float']>;
};

export type AllocatedForecastedHedgedAndCappedVolume = {
  __typename?: 'AllocatedForecastedHedgedAndCappedVolume';
  Entity: IEntity;
  Product: Product;
  contractType: TypeOfContract;
  date: Scalars['String'];
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  hedgeType: TypeOfAllocatedHedge;
  id: Scalars['ID'];
  instrumentType: TypeOfInstrument;
  naturallyLongCapped?: Maybe<Scalars['Float']>;
  naturallyLongHedged?: Maybe<Scalars['Float']>;
  naturallyShortCapped?: Maybe<Scalars['Float']>;
  naturallyShortHedged?: Maybe<Scalars['Float']>;
  optionType?: Maybe<TypeOfOption>;
  productId: Scalars['String'];
};

export type AllocatedForecastedHedgedAndCappedVolumeAggregateAllDTO = {
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  naturallyLongCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyLongHedged?: InputMaybe<Scalars['Boolean']>;
  naturallyShortCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyShortHedged?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
};

export type AllocatedForecastedHedgedAndCappedVolumeAggregateDTO = {
  avg?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeAggregateAllDTO>;
  min?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeAggregateAllDTO>;
  sum?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeAggregateNumericDTO>;
};

export type AllocatedForecastedHedgedAndCappedVolumeAggregateNumericDTO = {
  naturallyLongCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyLongHedged?: InputMaybe<Scalars['Boolean']>;
  naturallyShortCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyShortHedged?: InputMaybe<Scalars['Boolean']>;
};

export type AllocatedForecastedHedgedAndCappedVolumeDistinctOnDTO = {
  contractType?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  hedgeType?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentType?: InputMaybe<Scalars['Boolean']>;
  naturallyLongCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyLongHedged?: InputMaybe<Scalars['Boolean']>;
  naturallyShortCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyShortHedged?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
};

export type AllocatedForecastedHedgedAndCappedVolumeFilterDTO = {
  AND?: InputMaybe<Array<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>;
  OR?: InputMaybe<Array<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  contractType?: InputMaybe<TypeOfContractFilter>;
  date?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  hedgeType?: InputMaybe<TypeOfAllocatedHedgeFilter>;
  id?: InputMaybe<StringFilter>;
  instrumentType?: InputMaybe<TypeOfInstrumentFilter>;
  naturallyLongCapped?: InputMaybe<NullableNumberFilter>;
  naturallyLongHedged?: InputMaybe<NullableNumberFilter>;
  naturallyShortCapped?: InputMaybe<NullableNumberFilter>;
  naturallyShortHedged?: InputMaybe<NullableNumberFilter>;
  optionType?: InputMaybe<NullableTypeOfOptionFilter>;
  productId?: InputMaybe<StringFilter>;
};

export type AllocatedForecastedHedgedAndCappedVolumeGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  contractType?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  hedgeType?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentType?: InputMaybe<Scalars['Boolean']>;
  naturallyLongCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyLongHedged?: InputMaybe<Scalars['Boolean']>;
  naturallyShortCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyShortHedged?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
};

export type AllocatedForecastedHedgedAndCappedVolumeSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  contractType?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  hedgeType?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  instrumentType?: InputMaybe<SortByDirection>;
  naturallyLongCapped?: InputMaybe<NullableSortByDirection>;
  naturallyLongHedged?: InputMaybe<NullableSortByDirection>;
  naturallyShortCapped?: InputMaybe<NullableSortByDirection>;
  naturallyShortHedged?: InputMaybe<NullableSortByDirection>;
  optionType?: InputMaybe<NullableSortByDirection>;
  productId?: InputMaybe<SortByDirection>;
};

export type ApplePushNotificationSetting = INotificationSetting & {
  __typename?: 'ApplePushNotificationSetting';
  Notification: Array<Notification>;
  User?: Maybe<User>;
  apnToken: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: TypeOfNotification;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type ApplePushNotificationSetting_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type ApplePushNotificationSettingCreateDTO = {
  apnToken: Scalars['String'];
  deviceId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type ApplePushNotificationSettingDistinctOnDTO = {
  apnToken?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deviceId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type ApplePushNotificationSettingFilterDTO = {
  AND?: InputMaybe<Array<ApplePushNotificationSettingFilterDTO>>;
  AsNotificationSetting?: InputMaybe<NotificationSettingFilterDTO>;
  NOT?: InputMaybe<ApplePushNotificationSettingFilterDTO>;
  Notifications?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<ApplePushNotificationSettingFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  apnToken?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export type ApplePushNotificationSettingSortByDTO = {
  AsNotificationSetting?: InputMaybe<NotificationSettingSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  apnToken?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deviceId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type ApplePushNotificationSettingUpdateDTO = {
  apnToken?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type BasisInstrument = IInstrument & {
  __typename?: 'BasisInstrument';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  UnderlyingFuture: Future;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deliveryEndAt: Scalars['DateTime'];
  deliveryStartAt: Scalars['DateTime'];
  displayDeliveryDate: Scalars['DateTime'];
  displayExpiresAt: Scalars['String'];
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  isStandardContractSize: Scalars['Boolean'];
  lastTradeAt: Scalars['DateTime'];
  locationId: Scalars['String'];
  name: Scalars['String'];
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  type: TypeOfInstrument;
  underlyingFutureId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type BasisInstrument_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type BasisInstrument_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type BasisInstrument_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type BasisInstrument_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type BasisInstrumentCreateDTO = {
  barchartSymbol?: InputMaybe<Scalars['String']>;
  cqgSymbol?: InputMaybe<Scalars['String']>;
  deliveryEndAt: Scalars['DateTime'];
  deliveryStartAt: Scalars['DateTime'];
  displayDeliveryDate: Scalars['DateTime'];
  displayExpiresAt: Scalars['String'];
  exchangeSymbol?: InputMaybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  lastTradeAt: Scalars['DateTime'];
  locationId: Scalars['String'];
  name: Scalars['String'];
  productId: Scalars['String'];
  underlyingFutureId: Scalars['String'];
};

export type BasisInstrumentDistinctOnDTO = {
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndAt?: InputMaybe<Scalars['Boolean']>;
  deliveryStartAt?: InputMaybe<Scalars['Boolean']>;
  displayDeliveryDate?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  underlyingFutureId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BasisInstrumentFilterDTO = {
  AND?: InputMaybe<Array<BasisInstrumentFilterDTO>>;
  AsInstrument?: InputMaybe<InstrumentFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  DerivedSpreadLegs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  LegacyFeeds?: InputMaybe<LegacyFeedFilterDTO>;
  LegacyScenarioData?: InputMaybe<LegacyScenarioDataFilterDTO>;
  NOT?: InputMaybe<BasisInstrumentFilterDTO>;
  OR?: InputMaybe<Array<BasisInstrumentFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationFilterDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  UnderlyingFuture?: InputMaybe<FutureFilterDTO>;
  barchartSymbol?: InputMaybe<NullableStringFilter>;
  cqgSymbol?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deliveryEndAt?: InputMaybe<DateTimeFilter>;
  deliveryStartAt?: InputMaybe<DateTimeFilter>;
  displayDeliveryDate?: InputMaybe<DateTimeFilter>;
  displayExpiresAt?: InputMaybe<StringFilter>;
  exchangeSymbol?: InputMaybe<NullableStringFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentSymbolGroupId?: InputMaybe<StringFilter>;
  isStandardContractSize?: InputMaybe<BooleanFilter>;
  lastTradeAt?: InputMaybe<DateTimeFilter>;
  locationId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  productLotSpecificationId?: InputMaybe<StringFilter>;
  underlyingFutureId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BasisInstrumentGroupByDTO = {
  AsInstrument?: InputMaybe<InstrumentGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationGroupByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  UnderlyingFuture?: InputMaybe<FutureGroupByDTO>;
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndAt?: InputMaybe<Scalars['Boolean']>;
  deliveryStartAt?: InputMaybe<Scalars['Boolean']>;
  displayDeliveryDate?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  underlyingFutureId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BasisInstrumentSortByDTO = {
  AsInstrument?: InputMaybe<InstrumentSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationSortByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  UnderlyingFuture?: InputMaybe<FutureSortByDTO>;
  barchartSymbol?: InputMaybe<NullableSortByDirection>;
  cqgSymbol?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deliveryEndAt?: InputMaybe<SortByDirection>;
  deliveryStartAt?: InputMaybe<SortByDirection>;
  displayDeliveryDate?: InputMaybe<SortByDirection>;
  displayExpiresAt?: InputMaybe<SortByDirection>;
  exchangeSymbol?: InputMaybe<NullableSortByDirection>;
  expiresAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentSymbolGroupId?: InputMaybe<SortByDirection>;
  isStandardContractSize?: InputMaybe<SortByDirection>;
  lastTradeAt?: InputMaybe<SortByDirection>;
  locationId?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productLotSpecificationId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  underlyingFutureId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type BasisInstrumentUpdateDTO = {
  barchartSymbol?: InputMaybe<Scalars['String']>;
  cqgSymbol?: InputMaybe<Scalars['String']>;
  deliveryEndAt?: InputMaybe<Scalars['DateTime']>;
  deliveryStartAt?: InputMaybe<Scalars['DateTime']>;
  displayDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  displayExpiresAt?: InputMaybe<Scalars['String']>;
  exchangeSymbol?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  lastTradeAt?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  underlyingFutureId?: InputMaybe<Scalars['String']>;
};

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilter>;
};

export type BrokerageAccount = IAccount & {
  __typename?: 'BrokerageAccount';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  CurrentPositions: Array<CurrentPosition>;
  Customer?: Maybe<CustomerEntity>;
  FCM: Fcm;
  Notes: Array<AccountNote>;
  Orders: Array<Order>;
  /** @deprecated Use "Owner" instead */
  Organization?: Maybe<OrganizationEntity>;
  Owner?: Maybe<IEntity>;
  Transactions: Array<Transaction>;
  accountNumber: Scalars['String'];
  cqgId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  currentBalance: Scalars['Float'];
  custodianAccountType?: Maybe<TypeOfCustodianAccount>;
  customerId?: Maybe<Scalars['String']>;
  fcmAccountLastActiveDate?: Maybe<Scalars['String']>;
  fcmAccountLastUpdatedDate?: Maybe<Scalars['String']>;
  fcmAccountOpenDate?: Maybe<Scalars['String']>;
  fcmId: Scalars['String'];
  futuresMarketValue: Scalars['Float'];
  hasTradedThisYear?: Maybe<Scalars['Boolean']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  inputSource: InputSource;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isClosed: Scalars['Boolean'];
  isDiscretionaryAccount?: Maybe<Scalars['Boolean']>;
  isHedgeAccount?: Maybe<Scalars['Boolean']>;
  isNetLiquidationZero?: Maybe<Scalars['Boolean']>;
  isParentAccount: Scalars['Boolean'];
  liquidatingValue: Scalars['Float'];
  marginCredit: Scalars['Float'];
  marginExcessDeficit: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  openTradeEquity: Scalars['Float'];
  optionsMarketValue: Scalars['Float'];
  ownerId: Scalars['String'];
  parentAccountId?: Maybe<Scalars['String']>;
  positionMargin: Scalars['Float'];
  previousAccountBalance?: Maybe<Scalars['Float']>;
  purchasingPower: Scalars['Float'];
  salesCode?: Maybe<Scalars['String']>;
  totalMargin: Scalars['Float'];
  type: TypeOfAccount;
  updatedAt: Scalars['DateTime'];
};


export type BrokerageAccount_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type BrokerageAccount_CurrentPositionsArgs = {
  orderBy?: InputMaybe<CurrentPositionSortByDTO>;
  where?: InputMaybe<CurrentPositionFilterDTO>;
};


export type BrokerageAccount_NotesArgs = {
  orderBy?: InputMaybe<AccountNoteSortByDTO>;
  where?: InputMaybe<AccountNoteFilterDTO>;
};


export type BrokerageAccount_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type BrokerageAccount_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type BrokerageAccountAggregateAllDTO = {
  accountNumber?: InputMaybe<Scalars['Boolean']>;
  cqgId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentBalance?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  fcmAccountLastActiveDate?: InputMaybe<Scalars['Boolean']>;
  fcmAccountLastUpdatedDate?: InputMaybe<Scalars['Boolean']>;
  fcmAccountOpenDate?: InputMaybe<Scalars['Boolean']>;
  fcmId?: InputMaybe<Scalars['Boolean']>;
  futuresMarketValue?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  liquidatingValue?: InputMaybe<Scalars['Boolean']>;
  marginCredit?: InputMaybe<Scalars['Boolean']>;
  marginExcessDeficit?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  openTradeEquity?: InputMaybe<Scalars['Boolean']>;
  optionsMarketValue?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  parentAccountId?: InputMaybe<Scalars['Boolean']>;
  positionMargin?: InputMaybe<Scalars['Boolean']>;
  previousAccountBalance?: InputMaybe<Scalars['Boolean']>;
  purchasingPower?: InputMaybe<Scalars['Boolean']>;
  salesCode?: InputMaybe<Scalars['Boolean']>;
  totalMargin?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BrokerageAccountAggregateDTO = {
  avg?: InputMaybe<BrokerageAccountAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<BrokerageAccountAggregateAllDTO>;
  min?: InputMaybe<BrokerageAccountAggregateAllDTO>;
  sum?: InputMaybe<BrokerageAccountAggregateNumericDTO>;
};

export type BrokerageAccountAggregateNumericDTO = {
  cqgId?: InputMaybe<Scalars['Boolean']>;
  currentBalance?: InputMaybe<Scalars['Boolean']>;
  futuresMarketValue?: InputMaybe<Scalars['Boolean']>;
  liquidatingValue?: InputMaybe<Scalars['Boolean']>;
  marginCredit?: InputMaybe<Scalars['Boolean']>;
  marginExcessDeficit?: InputMaybe<Scalars['Boolean']>;
  openTradeEquity?: InputMaybe<Scalars['Boolean']>;
  optionsMarketValue?: InputMaybe<Scalars['Boolean']>;
  positionMargin?: InputMaybe<Scalars['Boolean']>;
  previousAccountBalance?: InputMaybe<Scalars['Boolean']>;
  purchasingPower?: InputMaybe<Scalars['Boolean']>;
  totalMargin?: InputMaybe<Scalars['Boolean']>;
};

export type BrokerageAccountCreateDTO = {
  accountNumber: Scalars['String'];
  cqgId?: InputMaybe<Scalars['Int']>;
  currentBalance?: InputMaybe<Scalars['Float']>;
  custodianAccountType?: InputMaybe<TypeOfCustodianAccount>;
  customerId?: InputMaybe<Scalars['String']>;
  fcmAccountLastActiveDate?: InputMaybe<Scalars['String']>;
  fcmAccountLastUpdatedDate?: InputMaybe<Scalars['String']>;
  fcmAccountOpenDate?: InputMaybe<Scalars['String']>;
  fcmId: Scalars['String'];
  futuresMarketValue?: InputMaybe<Scalars['Float']>;
  hasTradedThisYear?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isDiscretionaryAccount?: InputMaybe<Scalars['Boolean']>;
  isHedgeAccount?: InputMaybe<Scalars['Boolean']>;
  isNetLiquidationZero?: InputMaybe<Scalars['Boolean']>;
  isParentAccount?: InputMaybe<Scalars['Boolean']>;
  liquidatingValue?: InputMaybe<Scalars['Float']>;
  marginCredit?: InputMaybe<Scalars['Float']>;
  marginExcessDeficit?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  openTradeEquity?: InputMaybe<Scalars['Float']>;
  optionsMarketValue?: InputMaybe<Scalars['Float']>;
  ownerId: Scalars['String'];
  parentAccountId?: InputMaybe<Scalars['String']>;
  positionMargin?: InputMaybe<Scalars['Float']>;
  previousAccountBalance?: InputMaybe<Scalars['Float']>;
  purchasingPower?: InputMaybe<Scalars['Float']>;
  salesCode?: InputMaybe<Scalars['String']>;
  totalMargin?: InputMaybe<Scalars['Float']>;
};

export type BrokerageAccountDistinctOnDTO = {
  accountNumber?: InputMaybe<Scalars['Boolean']>;
  cqgId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentBalance?: InputMaybe<Scalars['Boolean']>;
  custodianAccountType?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  fcmAccountLastActiveDate?: InputMaybe<Scalars['Boolean']>;
  fcmAccountLastUpdatedDate?: InputMaybe<Scalars['Boolean']>;
  fcmAccountOpenDate?: InputMaybe<Scalars['Boolean']>;
  fcmId?: InputMaybe<Scalars['Boolean']>;
  futuresMarketValue?: InputMaybe<Scalars['Boolean']>;
  hasTradedThisYear?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isDiscretionaryAccount?: InputMaybe<Scalars['Boolean']>;
  isHedgeAccount?: InputMaybe<Scalars['Boolean']>;
  isNetLiquidationZero?: InputMaybe<Scalars['Boolean']>;
  isParentAccount?: InputMaybe<Scalars['Boolean']>;
  liquidatingValue?: InputMaybe<Scalars['Boolean']>;
  marginCredit?: InputMaybe<Scalars['Boolean']>;
  marginExcessDeficit?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  openTradeEquity?: InputMaybe<Scalars['Boolean']>;
  optionsMarketValue?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  parentAccountId?: InputMaybe<Scalars['Boolean']>;
  positionMargin?: InputMaybe<Scalars['Boolean']>;
  previousAccountBalance?: InputMaybe<Scalars['Boolean']>;
  purchasingPower?: InputMaybe<Scalars['Boolean']>;
  salesCode?: InputMaybe<Scalars['Boolean']>;
  totalMargin?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BrokerageAccountFilterDTO = {
  AND?: InputMaybe<Array<BrokerageAccountFilterDTO>>;
  AccountBalances?: InputMaybe<AccountBalanceFilterDTO>;
  AccountFiles?: InputMaybe<AccountFileFilterDTO>;
  AccountNotes?: InputMaybe<AccountNoteFilterDTO>;
  AsAccount?: InputMaybe<AccountFilterDTO>;
  ChildAccounts?: InputMaybe<BrokerageAccountFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  Fcm?: InputMaybe<FcmFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  IntegrationMappings?: InputMaybe<IntegrationMappingFilterDTO>;
  NOT?: InputMaybe<BrokerageAccountFilterDTO>;
  OR?: InputMaybe<Array<BrokerageAccountFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  Owner?: InputMaybe<EntityFilterDTO>;
  ParentAccount?: InputMaybe<BrokerageAccountFilterDTO>;
  PositionComponent?: InputMaybe<PositionComponentFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  accountNumber?: InputMaybe<StringFilter>;
  cqgId?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentBalance?: InputMaybe<NumberFilter>;
  custodianAccountType?: InputMaybe<NullableTypeOfCustodianAccountFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  fcmAccountLastActiveDate?: InputMaybe<NullableStringFilter>;
  fcmAccountLastUpdatedDate?: InputMaybe<NullableStringFilter>;
  fcmAccountOpenDate?: InputMaybe<NullableStringFilter>;
  fcmId?: InputMaybe<StringFilter>;
  futuresMarketValue?: InputMaybe<NumberFilter>;
  hasTradedThisYear?: InputMaybe<NullableBooleanFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  isClosed?: InputMaybe<BooleanFilter>;
  isDiscretionaryAccount?: InputMaybe<NullableBooleanFilter>;
  isHedgeAccount?: InputMaybe<NullableBooleanFilter>;
  isNetLiquidationZero?: InputMaybe<NullableBooleanFilter>;
  isParentAccount?: InputMaybe<BooleanFilter>;
  liquidatingValue?: InputMaybe<NumberFilter>;
  marginCredit?: InputMaybe<NumberFilter>;
  marginExcessDeficit?: InputMaybe<NumberFilter>;
  name?: InputMaybe<NullableStringFilter>;
  openTradeEquity?: InputMaybe<NumberFilter>;
  optionsMarketValue?: InputMaybe<NumberFilter>;
  ownerId?: InputMaybe<StringFilter>;
  parentAccountId?: InputMaybe<NullableStringFilter>;
  positionMargin?: InputMaybe<NumberFilter>;
  previousAccountBalance?: InputMaybe<NullableNumberFilter>;
  purchasingPower?: InputMaybe<NumberFilter>;
  salesCode?: InputMaybe<NullableStringFilter>;
  totalMargin?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BrokerageAccountGroupByDTO = {
  AsAccount?: InputMaybe<AccountGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  Fcm?: InputMaybe<FcmGroupByDTO>;
  Owner?: InputMaybe<EntityGroupByDTO>;
  OwnerAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  OwnerAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  OwnerAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  OwnerAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  ParentAccount?: InputMaybe<BrokerageAccountGroupByDTO>;
  accountNumber?: InputMaybe<Scalars['Boolean']>;
  cqgId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentBalance?: InputMaybe<Scalars['Boolean']>;
  custodianAccountType?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  fcmAccountLastActiveDate?: InputMaybe<Scalars['Boolean']>;
  fcmAccountLastUpdatedDate?: InputMaybe<Scalars['Boolean']>;
  fcmAccountOpenDate?: InputMaybe<Scalars['Boolean']>;
  fcmId?: InputMaybe<Scalars['Boolean']>;
  futuresMarketValue?: InputMaybe<Scalars['Boolean']>;
  hasTradedThisYear?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isDiscretionaryAccount?: InputMaybe<Scalars['Boolean']>;
  isHedgeAccount?: InputMaybe<Scalars['Boolean']>;
  isNetLiquidationZero?: InputMaybe<Scalars['Boolean']>;
  isParentAccount?: InputMaybe<Scalars['Boolean']>;
  liquidatingValue?: InputMaybe<Scalars['Boolean']>;
  marginCredit?: InputMaybe<Scalars['Boolean']>;
  marginExcessDeficit?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  openTradeEquity?: InputMaybe<Scalars['Boolean']>;
  optionsMarketValue?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  parentAccountId?: InputMaybe<Scalars['Boolean']>;
  positionMargin?: InputMaybe<Scalars['Boolean']>;
  previousAccountBalance?: InputMaybe<Scalars['Boolean']>;
  purchasingPower?: InputMaybe<Scalars['Boolean']>;
  salesCode?: InputMaybe<Scalars['Boolean']>;
  totalMargin?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BrokerageAccountSortByDTO = {
  AsAccount?: InputMaybe<AccountSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  Fcm?: InputMaybe<FcmSortByDTO>;
  Owner?: InputMaybe<EntitySortByDTO>;
  ParentAccount?: InputMaybe<BrokerageAccountSortByDTO>;
  accountNumber?: InputMaybe<SortByDirection>;
  cqgId?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  currentBalance?: InputMaybe<SortByDirection>;
  custodianAccountType?: InputMaybe<NullableSortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  fcmAccountLastActiveDate?: InputMaybe<NullableSortByDirection>;
  fcmAccountLastUpdatedDate?: InputMaybe<NullableSortByDirection>;
  fcmAccountOpenDate?: InputMaybe<NullableSortByDirection>;
  fcmId?: InputMaybe<SortByDirection>;
  futuresMarketValue?: InputMaybe<SortByDirection>;
  hasTradedThisYear?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  isClosed?: InputMaybe<SortByDirection>;
  isDiscretionaryAccount?: InputMaybe<NullableSortByDirection>;
  isHedgeAccount?: InputMaybe<NullableSortByDirection>;
  isNetLiquidationZero?: InputMaybe<NullableSortByDirection>;
  isParentAccount?: InputMaybe<SortByDirection>;
  liquidatingValue?: InputMaybe<SortByDirection>;
  marginCredit?: InputMaybe<SortByDirection>;
  marginExcessDeficit?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  openTradeEquity?: InputMaybe<SortByDirection>;
  optionsMarketValue?: InputMaybe<SortByDirection>;
  ownerId?: InputMaybe<SortByDirection>;
  parentAccountId?: InputMaybe<NullableSortByDirection>;
  positionMargin?: InputMaybe<SortByDirection>;
  previousAccountBalance?: InputMaybe<NullableSortByDirection>;
  purchasingPower?: InputMaybe<SortByDirection>;
  salesCode?: InputMaybe<NullableSortByDirection>;
  totalMargin?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type BrokerageAccountUpdateDTO = {
  accountNumber?: InputMaybe<Scalars['String']>;
  cqgId?: InputMaybe<Scalars['Int']>;
  currentBalance?: InputMaybe<Scalars['Float']>;
  custodianAccountType?: InputMaybe<TypeOfCustodianAccount>;
  customerId?: InputMaybe<Scalars['String']>;
  fcmAccountLastActiveDate?: InputMaybe<Scalars['String']>;
  fcmAccountLastUpdatedDate?: InputMaybe<Scalars['String']>;
  fcmAccountOpenDate?: InputMaybe<Scalars['String']>;
  fcmId?: InputMaybe<Scalars['String']>;
  futuresMarketValue?: InputMaybe<Scalars['Float']>;
  hasTradedThisYear?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isDiscretionaryAccount?: InputMaybe<Scalars['Boolean']>;
  isHedgeAccount?: InputMaybe<Scalars['Boolean']>;
  isNetLiquidationZero?: InputMaybe<Scalars['Boolean']>;
  isParentAccount?: InputMaybe<Scalars['Boolean']>;
  liquidatingValue?: InputMaybe<Scalars['Float']>;
  marginCredit?: InputMaybe<Scalars['Float']>;
  marginExcessDeficit?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  openTradeEquity?: InputMaybe<Scalars['Float']>;
  optionsMarketValue?: InputMaybe<Scalars['Float']>;
  ownerId?: InputMaybe<Scalars['String']>;
  parentAccountId?: InputMaybe<Scalars['String']>;
  positionMargin?: InputMaybe<Scalars['Float']>;
  previousAccountBalance?: InputMaybe<Scalars['Float']>;
  purchasingPower?: InputMaybe<Scalars['Float']>;
  salesCode?: InputMaybe<Scalars['String']>;
  totalMargin?: InputMaybe<Scalars['Float']>;
};

export type BusinessContact = {
  __typename?: 'BusinessContact';
  Business: CustomerEntity;
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type BusinessContactCreateDTO = {
  businessId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type BusinessContactDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessContactFilterDTO = {
  AND?: InputMaybe<Array<BusinessContactFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  NOT?: InputMaybe<BusinessContactFilterDTO>;
  OR?: InputMaybe<Array<BusinessContactFilterDTO>>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<NullableStringFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isPrimary?: InputMaybe<BooleanFilter>;
  lastName?: InputMaybe<NullableStringFilter>;
  phone?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusinessContactSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  email?: InputMaybe<NullableSortByDirection>;
  firstName?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isPrimary?: InputMaybe<SortByDirection>;
  lastName?: InputMaybe<NullableSortByDirection>;
  phone?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type BusinessContactUpdateDTO = {
  businessId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type BusinessDataVersion = IVersion & {
  __typename?: 'BusinessDataVersion';
  Business: CustomerEntity;
  CreatedByUser?: Maybe<User>;
  LastUpdatedByUser?: Maybe<User>;
  VersionedBusinessData?: Maybe<VersionedBusinessData>;
  /** The business entity (customer entity) on behalf of which this version exists */
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The user that triggered the creation of this version. If this value is null, then this version was created via the system. */
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The final date for which this version is in effect, inclusive. If this value is null, this version is in effect for all dates greater than startDate */
  endDate?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  /** The user that last updated the concepts linked to this version. When a version is first created, the lastUpdatedByUserId value will equal the user id that triggered the version creation. If this value is null, then this version has only been created & updated via the system. */
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nextVersionId?: Maybe<Scalars['String']>;
  /** The version that preceeds this version. If this value is null, this is the first version for this businessId / type combination. */
  previousVersionId?: Maybe<Scalars['String']>;
  /** The first date for which this version is in effect (inclusive) */
  startDate: Scalars['String'];
  type: TypeOfVersion;
  updatedAt: Scalars['DateTime'];
};

export type BusinessDataVersionDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  nextVersionId?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessDataVersionFilterDTO = {
  AND?: InputMaybe<Array<BusinessDataVersionFilterDTO>>;
  AsVersion?: InputMaybe<VersionFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  NOT?: InputMaybe<BusinessDataVersionFilterDTO>;
  NextVersion?: InputMaybe<VersionFilterDTO>;
  OR?: InputMaybe<Array<BusinessDataVersionFilterDTO>>;
  PreviousVersion?: InputMaybe<VersionFilterDTO>;
  VersionedBusinessData?: InputMaybe<VersionedBusinessDataFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isCurrent?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  name?: InputMaybe<StringFilter>;
  nextVersionId?: InputMaybe<NullableStringFilter>;
  previousVersionId?: InputMaybe<NullableStringFilter>;
  startDate?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusinessDataVersionGroupByDTO = {
  AsVersion?: InputMaybe<VersionGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  NextVersion?: InputMaybe<VersionGroupByDTO>;
  PreviousVersion?: InputMaybe<VersionGroupByDTO>;
  VersionedBusinessData?: InputMaybe<VersionedBusinessDataGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  nextVersionId?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessDataVersionSortByDTO = {
  AsVersion?: InputMaybe<VersionSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  NextVersion?: InputMaybe<VersionSortByDTO>;
  PreviousVersion?: InputMaybe<VersionSortByDTO>;
  VersionedBusinessData?: InputMaybe<VersionedBusinessDataSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isCurrent?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<SortByDirection>;
  nextVersionId?: InputMaybe<NullableSortByDirection>;
  previousVersionId?: InputMaybe<NullableSortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export enum BusinessEntityRole {
  CattleProducer = 'CattleProducer',
  Consumer = 'Consumer',
  DairyEndUser = 'DairyEndUser',
  DairyProcessor = 'DairyProcessor',
  DairyProducer = 'DairyProducer',
  GrainProducer = 'GrainProducer',
  HogProducer = 'HogProducer'
}

export type BusinessEntityRoleArrayFilterDTO = {
  /** Matches rows where the column contains ALL values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  contains?: InputMaybe<Array<BusinessEntityRole>>;
  /** Matches rows where the column array is an exact match to the given filter (order & duplicates matter!) */
  equals?: InputMaybe<Array<BusinessEntityRole>>;
  /** Matches rows where the column is contained within the values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  isContainedBy?: InputMaybe<Array<BusinessEntityRole>>;
  /** Filter results by the number of values contained within this array. `null` values will always be filtered out (even with a length filter = 0) */
  length?: InputMaybe<NumberFilter>;
  not?: InputMaybe<BusinessEntityRoleArrayFilterDTO>;
  /** Matches rows where the column contains any of the values included within this filter */
  overlapsWith?: InputMaybe<Array<BusinessEntityRole>>;
};

export type BusinessMemberCreateDTO = {
  canReadBrokerage?: InputMaybe<Scalars['Boolean']>;
  canReadEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canReadFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canReadInsurance?: InputMaybe<Scalars['Boolean']>;
  canReadOperations?: InputMaybe<Scalars['Boolean']>;
  canReadSwap?: InputMaybe<Scalars['Boolean']>;
  canWriteBrokerage?: InputMaybe<Scalars['Boolean']>;
  canWriteBusiness?: InputMaybe<Scalars['Boolean']>;
  canWriteFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canWriteInsurance?: InputMaybe<Scalars['Boolean']>;
  canWriteOperations?: InputMaybe<Scalars['Boolean']>;
  canWriteSwap?: InputMaybe<Scalars['Boolean']>;
  entityId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type BusinessMemberUpdateDTO = {
  canReadBrokerage?: InputMaybe<Scalars['Boolean']>;
  canReadEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canReadFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canReadInsurance?: InputMaybe<Scalars['Boolean']>;
  canReadOperations?: InputMaybe<Scalars['Boolean']>;
  canReadSwap?: InputMaybe<Scalars['Boolean']>;
  canWriteBrokerage?: InputMaybe<Scalars['Boolean']>;
  canWriteBusiness?: InputMaybe<Scalars['Boolean']>;
  canWriteFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canWriteInsurance?: InputMaybe<Scalars['Boolean']>;
  canWriteOperations?: InputMaybe<Scalars['Boolean']>;
  canWriteSwap?: InputMaybe<Scalars['Boolean']>;
};

export type Buyer = {
  __typename?: 'Buyer';
  GrainOrders: Array<IGrainOrder>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vgsId?: Maybe<Scalars['Int']>;
};


export type Buyer_GrainOrdersArgs = {
  orderBy?: InputMaybe<GrainOrderSortByDTO>;
  where?: InputMaybe<GrainOrderFilterDTO>;
};

export type BuyerCreateDTO = {
  name: Scalars['String'];
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type BuyerDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type BuyerFilterDTO = {
  AND?: InputMaybe<Array<BuyerFilterDTO>>;
  GrainOrders?: InputMaybe<GrainOrderFilterDTO>;
  NOT?: InputMaybe<BuyerFilterDTO>;
  OR?: InputMaybe<Array<BuyerFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
};

export type BuyerGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type BuyerSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
};

export type BuyerUpdateDTO = {
  name?: InputMaybe<Scalars['String']>;
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type CashInstrument = IInstrument & {
  __typename?: 'CashInstrument';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  exchangeSymbol?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  isStandardContractSize: Scalars['Boolean'];
  name: Scalars['String'];
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  type: TypeOfInstrument;
  updatedAt: Scalars['DateTime'];
};


export type CashInstrument_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type CashInstrument_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type CashInstrument_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type CashInstrument_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type CashInstrumentDistinctOnDTO = {
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CashInstrumentFilterDTO = {
  AND?: InputMaybe<Array<CashInstrumentFilterDTO>>;
  AsInstrument?: InputMaybe<InstrumentFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  DerivedSpreadLegs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  LegacyFeeds?: InputMaybe<LegacyFeedFilterDTO>;
  LegacyScenarioData?: InputMaybe<LegacyScenarioDataFilterDTO>;
  NOT?: InputMaybe<CashInstrumentFilterDTO>;
  OR?: InputMaybe<Array<CashInstrumentFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationFilterDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  barchartSymbol?: InputMaybe<NullableStringFilter>;
  cqgSymbol?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  exchangeSymbol?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentSymbolGroupId?: InputMaybe<StringFilter>;
  isStandardContractSize?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  productLotSpecificationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CashInstrumentGroupByDTO = {
  AsInstrument?: InputMaybe<InstrumentGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationGroupByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CashInstrumentSortByDTO = {
  AsInstrument?: InputMaybe<InstrumentSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationSortByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  barchartSymbol?: InputMaybe<NullableSortByDirection>;
  cqgSymbol?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  exchangeSymbol?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentSymbolGroupId?: InputMaybe<SortByDirection>;
  isStandardContractSize?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productLotSpecificationId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type CloneGrainFillOrderNoteInput = {
  fromFillId?: InputMaybe<Scalars['String']>;
  toFillId?: InputMaybe<Scalars['String']>;
};

export type CommitmentInstrument = IInstrument & {
  __typename?: 'CommitmentInstrument';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  createdAt: Scalars['DateTime'];
  deliveryEndAt: Scalars['DateTime'];
  deliveryStartAt: Scalars['DateTime'];
  displayDeliveryDate: Scalars['DateTime'];
  displayExpiresAt: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  isStandardContractSize: Scalars['Boolean'];
  lastTradeAt: Scalars['DateTime'];
  locationId: Scalars['String'];
  name: Scalars['String'];
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  type: TypeOfInstrument;
  updatedAt: Scalars['DateTime'];
};


export type CommitmentInstrument_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type CommitmentInstrument_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type CommitmentInstrument_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type CommitmentInstrument_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type CommitmentInstrumentCreateDTO = {
  deliveryEndAt: Scalars['DateTime'];
  deliveryStartAt: Scalars['DateTime'];
  displayDeliveryDate: Scalars['DateTime'];
  displayExpiresAt: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  lastTradeAt: Scalars['DateTime'];
  locationId: Scalars['String'];
  name: Scalars['String'];
  productId: Scalars['String'];
};

export type CommitmentInstrumentDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndAt?: InputMaybe<Scalars['Boolean']>;
  deliveryStartAt?: InputMaybe<Scalars['Boolean']>;
  displayDeliveryDate?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CommitmentInstrumentFilterDTO = {
  AND?: InputMaybe<Array<CommitmentInstrumentFilterDTO>>;
  AsInstrument?: InputMaybe<InstrumentFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  DerivedSpreadLegs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  LegacyFeeds?: InputMaybe<LegacyFeedFilterDTO>;
  LegacyScenarioData?: InputMaybe<LegacyScenarioDataFilterDTO>;
  NOT?: InputMaybe<CommitmentInstrumentFilterDTO>;
  OR?: InputMaybe<Array<CommitmentInstrumentFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationFilterDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deliveryEndAt?: InputMaybe<DateTimeFilter>;
  deliveryStartAt?: InputMaybe<DateTimeFilter>;
  displayDeliveryDate?: InputMaybe<DateTimeFilter>;
  displayExpiresAt?: InputMaybe<StringFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentSymbolGroupId?: InputMaybe<StringFilter>;
  isStandardContractSize?: InputMaybe<BooleanFilter>;
  lastTradeAt?: InputMaybe<DateTimeFilter>;
  locationId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  productLotSpecificationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CommitmentInstrumentGroupByDTO = {
  AsInstrument?: InputMaybe<InstrumentGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationGroupByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndAt?: InputMaybe<Scalars['Boolean']>;
  deliveryStartAt?: InputMaybe<Scalars['Boolean']>;
  displayDeliveryDate?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CommitmentInstrumentSortByDTO = {
  AsInstrument?: InputMaybe<InstrumentSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationSortByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  deliveryEndAt?: InputMaybe<SortByDirection>;
  deliveryStartAt?: InputMaybe<SortByDirection>;
  displayDeliveryDate?: InputMaybe<SortByDirection>;
  displayExpiresAt?: InputMaybe<SortByDirection>;
  expiresAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentSymbolGroupId?: InputMaybe<SortByDirection>;
  isStandardContractSize?: InputMaybe<SortByDirection>;
  lastTradeAt?: InputMaybe<SortByDirection>;
  locationId?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productLotSpecificationId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type CommitmentInstrumentUpdateDTO = {
  deliveryEndAt?: InputMaybe<Scalars['DateTime']>;
  deliveryStartAt?: InputMaybe<Scalars['DateTime']>;
  displayDeliveryDate?: InputMaybe<Scalars['DateTime']>;
  displayExpiresAt?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  lastTradeAt?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
};

export type ComponentAllocationsInput = {
  effectiveHedgeDate: Scalars['String'];
  longQuantity: Scalars['Float'];
  quantityType: TypeOfPositionComponentAllocationQuantity;
  shortQuantity: Scalars['Float'];
};

export type Count = {
  __typename?: 'Count';
  count: Scalars['Float'];
  pageCount: Scalars['Float'];
  resultsPerPage: Scalars['Float'];
};

export type County = {
  __typename?: 'County';
  Businesses: Array<CustomerEntity>;
  LocationEntities: Array<LocationEntity>;
  MilkOrder: MilkOrder;
  State: State;
  classIDifferential?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  milkOrderId: Scalars['String'];
  name: Scalars['String'];
  regionId: Scalars['String'];
  stateId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type County_BusinessesArgs = {
  orderBy?: InputMaybe<CustomerEntitySortByDTO>;
  where?: InputMaybe<CustomerEntityFilterDTO>;
};


export type County_LocationEntitiesArgs = {
  orderBy?: InputMaybe<LocationEntitySortByDTO>;
  where?: InputMaybe<LocationEntityFilterDTO>;
};

export type CountyDistinctOnDTO = {
  classIDifferential?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  regionId?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CountyFilterDTO = {
  AND?: InputMaybe<Array<CountyFilterDTO>>;
  Businesses?: InputMaybe<CustomerEntityFilterDTO>;
  ForecastedMilkUtilization?: InputMaybe<ForecastedMilkUtilizationFilterDTO>;
  ForecastedMilkUtilizations?: InputMaybe<ForecastedMilkUtilizationFilterDTO>;
  GrainBuyerLocations?: InputMaybe<GrainBuyerLocationFilterDTO>;
  LocationEntities?: InputMaybe<LocationEntityFilterDTO>;
  MilkOrder?: InputMaybe<MilkOrderFilterDTO>;
  NOT?: InputMaybe<CountyFilterDTO>;
  OR?: InputMaybe<Array<CountyFilterDTO>>;
  Region?: InputMaybe<RegionFilterDTO>;
  State?: InputMaybe<StateFilterDTO>;
  classIDifferential?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  milkOrderId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  regionId?: InputMaybe<StringFilter>;
  stateId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CountyGroupByDTO = {
  MilkOrder?: InputMaybe<MilkOrderGroupByDTO>;
  Region?: InputMaybe<RegionGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  classIDifferential?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  regionId?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CountySortByDTO = {
  MilkOrder?: InputMaybe<MilkOrderSortByDTO>;
  Region?: InputMaybe<RegionSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  classIDifferential?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  milkOrderId?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  regionId?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type CoverageLevelsRequestDTO = {
  butterfat?: InputMaybe<Scalars['Float']>;
  classThree?: InputMaybe<Scalars['Float']>;
  componentWeightingFactor?: InputMaybe<Scalars['Float']>;
  practiceCode: Scalars['String'];
  protein?: InputMaybe<Scalars['Float']>;
  salesEffectiveDate?: InputMaybe<Scalars['String']>;
  stateAbbreviation: Scalars['String'];
};

export type CreateEntityMemberDTO = {
  canReadBrokerage?: InputMaybe<Scalars['Boolean']>;
  canReadBusiness?: InputMaybe<Scalars['Boolean']>;
  canReadEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canReadFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canReadInsurance?: InputMaybe<Scalars['Boolean']>;
  canReadOperations?: InputMaybe<Scalars['Boolean']>;
  canReadOrganization?: InputMaybe<Scalars['Boolean']>;
  canReadSwap?: InputMaybe<Scalars['Boolean']>;
  canWriteBrokerage?: InputMaybe<Scalars['Boolean']>;
  canWriteBusiness?: InputMaybe<Scalars['Boolean']>;
  canWriteEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canWriteFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canWriteInsurance?: InputMaybe<Scalars['Boolean']>;
  canWriteOperations?: InputMaybe<Scalars['Boolean']>;
  canWriteOrganization?: InputMaybe<Scalars['Boolean']>;
  canWriteSwap?: InputMaybe<Scalars['Boolean']>;
  entityId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type Crop = {
  __typename?: 'Crop';
  Business: CustomerEntity;
  Category: CropCategory;
  CropHarvests: Array<CropHarvest>;
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  cropCategoryId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type Crop_CropHarvestsArgs = {
  orderBy?: InputMaybe<CropHarvestSortByDTO>;
  where?: InputMaybe<CropHarvestFilterDTO>;
};

export type CropCategory = {
  __typename?: 'CropCategory';
  Crops: Array<Crop>;
  HedgeProduct?: Maybe<Product>;
  Plans: Array<GrainCropPlan>;
  createdAt: Scalars['DateTime'];
  hedgeProductId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vgsId?: Maybe<Scalars['Int']>;
};


export type CropCategory_CropsArgs = {
  orderBy?: InputMaybe<CropSortByDTO>;
  where?: InputMaybe<CropFilterDTO>;
};


export type CropCategory_PlansArgs = {
  orderBy?: InputMaybe<GrainCropPlanSortByDTO>;
  where?: InputMaybe<GrainCropPlanFilterDTO>;
};

export type CropCategoryCreateDTO = {
  hedgeProductId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type CropCategoryDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  hedgeProductId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type CropCategoryFilterDTO = {
  AND?: InputMaybe<Array<CropCategoryFilterDTO>>;
  Crop?: InputMaybe<CropFilterDTO>;
  HedgeProduct?: InputMaybe<ProductFilterDTO>;
  NOT?: InputMaybe<CropCategoryFilterDTO>;
  OR?: InputMaybe<Array<CropCategoryFilterDTO>>;
  Plans?: InputMaybe<GrainCropPlanFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  hedgeProductId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
};

export type CropCategoryGroupByDTO = {
  HedgeProduct?: InputMaybe<ProductGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  hedgeProductId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type CropCategorySortByDTO = {
  HedgeProduct?: InputMaybe<ProductSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  hedgeProductId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
};

export type CropCategoryUpdateDTO = {
  hedgeProductId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type CropCreateDTO = {
  businessId: Scalars['String'];
  cropCategoryId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CropDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  cropCategoryId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CropFilterDTO = {
  AND?: InputMaybe<Array<CropFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  Category?: InputMaybe<CropCategoryFilterDTO>;
  CropHarvests?: InputMaybe<CropHarvestFilterDTO>;
  NOT?: InputMaybe<CropFilterDTO>;
  OR?: InputMaybe<Array<CropFilterDTO>>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  cropCategoryId?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CropGroupByDTO = {
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  Category?: InputMaybe<CropCategoryGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  cropCategoryId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CropHarvest = {
  __typename?: 'CropHarvest';
  Business: CustomerEntity;
  Crop: Crop;
  Field?: Maybe<Field>;
  acres: Scalars['Float'];
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  cropId: Scalars['String'];
  fieldId?: Maybe<Scalars['String']>;
  forecastedHarvestDate: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  yieldPerAcre: Scalars['Float'];
};

export type CropHarvestAggregateAllDTO = {
  acres?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  cropId?: InputMaybe<Scalars['Boolean']>;
  fieldId?: InputMaybe<Scalars['Boolean']>;
  forecastedHarvestDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldPerAcre?: InputMaybe<Scalars['Boolean']>;
};

export type CropHarvestAggregateDTO = {
  avg?: InputMaybe<CropHarvestAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<CropHarvestAggregateAllDTO>;
  min?: InputMaybe<CropHarvestAggregateAllDTO>;
  sum?: InputMaybe<CropHarvestAggregateNumericDTO>;
};

export type CropHarvestAggregateNumericDTO = {
  acres?: InputMaybe<Scalars['Boolean']>;
  yieldPerAcre?: InputMaybe<Scalars['Boolean']>;
};

export type CropHarvestCreateDTO = {
  acres: Scalars['Float'];
  cropId: Scalars['String'];
  fieldId?: InputMaybe<Scalars['String']>;
  forecastedHarvestDate: Scalars['String'];
  yieldPerAcre: Scalars['Float'];
};

export type CropHarvestDistinctOnDTO = {
  acres?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  cropId?: InputMaybe<Scalars['Boolean']>;
  fieldId?: InputMaybe<Scalars['Boolean']>;
  forecastedHarvestDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldPerAcre?: InputMaybe<Scalars['Boolean']>;
};

export type CropHarvestFilterDTO = {
  AND?: InputMaybe<Array<CropHarvestFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  Crop?: InputMaybe<CropFilterDTO>;
  Field?: InputMaybe<FieldFilterDTO>;
  NOT?: InputMaybe<CropHarvestFilterDTO>;
  OR?: InputMaybe<Array<CropHarvestFilterDTO>>;
  acres?: InputMaybe<NumberFilter>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  cropId?: InputMaybe<StringFilter>;
  fieldId?: InputMaybe<NullableStringFilter>;
  forecastedHarvestDate?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  yieldPerAcre?: InputMaybe<NumberFilter>;
};

export type CropHarvestGroupByDTO = {
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  Crop?: InputMaybe<CropGroupByDTO>;
  Field?: InputMaybe<FieldGroupByDTO>;
  acres?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  cropId?: InputMaybe<Scalars['Boolean']>;
  fieldId?: InputMaybe<Scalars['Boolean']>;
  forecastedHarvestDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldPerAcre?: InputMaybe<Scalars['Boolean']>;
};

export type CropHarvestSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  Crop?: InputMaybe<CropSortByDTO>;
  Field?: InputMaybe<FieldSortByDTO>;
  acres?: InputMaybe<SortByDirection>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  cropId?: InputMaybe<SortByDirection>;
  fieldId?: InputMaybe<NullableSortByDirection>;
  forecastedHarvestDate?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  yieldPerAcre?: InputMaybe<SortByDirection>;
};

export type CropHarvestUpdateDTO = {
  acres?: InputMaybe<Scalars['Float']>;
  fieldId?: InputMaybe<Scalars['String']>;
  forecastedHarvestDate?: InputMaybe<Scalars['String']>;
  yieldPerAcre?: InputMaybe<Scalars['Float']>;
};

export type CropSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  Category?: InputMaybe<CropCategorySortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  cropCategoryId?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type CropUpdateDTO = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CurrentAllocationPosition = {
  __typename?: 'CurrentAllocationPosition';
  Account: IAccount;
  Business?: Maybe<CustomerEntity>;
  Instrument: IInstrument;
  Owner: IEntity;
  PositionComponentAllocations: Array<PositionComponentAllocation>;
  Product: Product;
  accountId: Scalars['String'];
  /** A concatenation of "positionId" + "+" + "effectiveHedgeDate" */
  allocationPositionId: Scalars['String'];
  businessId?: Maybe<Scalars['String']>;
  contractQuantity: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  /** The weighted average price of transactions contained within this position that have not been offset (i.e. the open transactions). This value will be null for all positions not populated via an FCM integration, or if the allocations for this position do not all fall in one month. */
  currentWeightedAveragePrice?: Maybe<Scalars['Float']>;
  effectiveHedgeDate: Scalars['String'];
  entityId: Scalars['String'];
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  instrumentId: Scalars['String'];
  instrumentType: TypeOfInstrument;
  /** The net-quantity-weighted average price of transactions constituting the portion of the position which are allocated to this month, including closed transactions. */
  lifetimeWeightedAveragePrice: Scalars['Float'];
  openSideWeightedAveragePrice?: Maybe<Scalars['Float']>;
  /** The weighted average price of open transactions that constitute this position. If this position is populated via and FCM integration and the position is allocated entirely to one month, then this will be the current weighted price. Else, this will be the open side weighted average price. */
  openWeightedAveragePrice?: Maybe<Scalars['Float']>;
  optionType?: Maybe<TypeOfOption>;
  ownerId: Scalars['String'];
  /** A concatenation of "accountId" + "+" + "instrumentId" */
  positionId: Scalars['String'];
  positionSide: Side;
  productId: Scalars['String'];
  unitQuantity: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};


export type CurrentAllocationPosition_PositionComponentAllocationsArgs = {
  orderBy?: InputMaybe<PositionComponentAllocationSortByDTO>;
  where?: InputMaybe<PositionComponentAllocationFilterDTO>;
};

export type CurrentAllocationPositionAggregateAllDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  allocationPositionId?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeDate?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  lifetimeWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  openSideWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  openWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentAllocationPositionAggregateDTO = {
  avg?: InputMaybe<CurrentAllocationPositionAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<CurrentAllocationPositionAggregateAllDTO>;
  min?: InputMaybe<CurrentAllocationPositionAggregateAllDTO>;
  sum?: InputMaybe<CurrentAllocationPositionAggregateNumericDTO>;
};

export type CurrentAllocationPositionAggregateNumericDTO = {
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  currentWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  lifetimeWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  openSideWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  openWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentAllocationPositionDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  allocationPositionId?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeDate?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  instrumentType?: InputMaybe<Scalars['Boolean']>;
  lifetimeWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  openSideWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  openWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  positionSide?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentAllocationPositionFilterDTO = {
  AND?: InputMaybe<Array<CurrentAllocationPositionFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  OR?: InputMaybe<Array<CurrentAllocationPositionFilterDTO>>;
  Owner?: InputMaybe<EntityFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  accountId?: InputMaybe<StringFilter>;
  allocationPositionId?: InputMaybe<StringFilter>;
  businessId?: InputMaybe<NullableStringFilter>;
  contractQuantity?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentWeightedAveragePrice?: InputMaybe<NullableNumberFilter>;
  effectiveHedgeDate?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  grossPnl?: InputMaybe<NullableNumberFilter>;
  grossProtection?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  instrumentType?: InputMaybe<TypeOfInstrumentFilter>;
  lifetimeWeightedAveragePrice?: InputMaybe<NumberFilter>;
  openSideWeightedAveragePrice?: InputMaybe<NullableNumberFilter>;
  openWeightedAveragePrice?: InputMaybe<NullableNumberFilter>;
  optionType?: InputMaybe<NullableTypeOfOptionFilter>;
  ownerId?: InputMaybe<StringFilter>;
  positionId?: InputMaybe<StringFilter>;
  positionSide?: InputMaybe<SideFilter>;
  productId?: InputMaybe<StringFilter>;
  unitQuantity?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CurrentAllocationPositionGroupByDTO = {
  Account?: InputMaybe<AccountGroupByDTO>;
  AccountAsBrokerageAccount?: InputMaybe<BrokerageAccountGroupByDTO>;
  AccountAsForwardContractAccount?: InputMaybe<ForwardContractAccountGroupByDTO>;
  AccountAsSwapAccount?: InputMaybe<SwapAccountGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  Instrument?: InputMaybe<InstrumentGroupByDTO>;
  InstrumentAsBasisInstrument?: InputMaybe<BasisInstrumentGroupByDTO>;
  InstrumentAsCashInstrument?: InputMaybe<CashInstrumentGroupByDTO>;
  InstrumentAsCommitmentInstrument?: InputMaybe<CommitmentInstrumentGroupByDTO>;
  InstrumentAsFutureInstrument?: InputMaybe<FutureGroupByDTO>;
  InstrumentAsOptionInstrument?: InputMaybe<OptionGroupByDTO>;
  InstrumentAsPhysicalInstrument?: InputMaybe<PhysicalInstrumentGroupByDTO>;
  InstrumentAsSpreadInstrument?: InputMaybe<SpreadInstrumentGroupByDTO>;
  InstrumentAsSwapInstrument?: InputMaybe<SwapGroupByDTO>;
  InstrumentAsSwaptionInstrument?: InputMaybe<SwaptionGroupByDTO>;
  Owner?: InputMaybe<EntityGroupByDTO>;
  OwnerAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  OwnerAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  OwnerAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  OwnerAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  accountId?: InputMaybe<Scalars['Boolean']>;
  allocationPositionId?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeDate?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  instrumentType?: InputMaybe<Scalars['Boolean']>;
  lifetimeWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  openSideWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  openWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  positionSide?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentAllocationPositionSortByDTO = {
  Account?: InputMaybe<AccountSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  Instrument?: InputMaybe<InstrumentSortByDTO>;
  Owner?: InputMaybe<EntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  allocationPositionId?: InputMaybe<SortByDirection>;
  businessId?: InputMaybe<NullableSortByDirection>;
  contractQuantity?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  currentWeightedAveragePrice?: InputMaybe<NullableSortByDirection>;
  effectiveHedgeDate?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  grossPnl?: InputMaybe<NullableSortByDirection>;
  grossProtection?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  instrumentId?: InputMaybe<SortByDirection>;
  instrumentType?: InputMaybe<SortByDirection>;
  lifetimeWeightedAveragePrice?: InputMaybe<SortByDirection>;
  openSideWeightedAveragePrice?: InputMaybe<NullableSortByDirection>;
  openWeightedAveragePrice?: InputMaybe<NullableSortByDirection>;
  optionType?: InputMaybe<NullableSortByDirection>;
  ownerId?: InputMaybe<SortByDirection>;
  positionId?: InputMaybe<SortByDirection>;
  positionSide?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  unitQuantity?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type CurrentFeedIngredients = {
  __typename?: 'CurrentFeedIngredients';
  FeedIngredients: Array<FeedIngredient>;
  Version: FeedIngredientVersion;
};

export type CurrentInventoryLevelFilterDTO = {
  AND?: InputMaybe<Array<CurrentInventoryLevelFilterDTO>>;
  Location?: InputMaybe<LocationEntityFilterDTO>;
  NOT?: InputMaybe<CurrentInventoryLevelFilterDTO>;
  OR?: InputMaybe<Array<CurrentInventoryLevelFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<NumberFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

/** The most current position for the given account / instrument. The current position values are aggregated by account / instrument, with no per-day or per-price data available. */
export type CurrentPosition = {
  __typename?: 'CurrentPosition';
  Account: IAccount;
  /** The currently open quantity breakdown for this position by tradeDate / price */
  CurrentPositionComponents: Array<CurrentPositionComponent>;
  Instrument: IInstrument;
  Transactions: Array<Transaction>;
  accountId: Scalars['String'];
  accountType: TypeOfAccount;
  /** The realized P/L as calculated based upon the existing transactions in order of occurence */
  calculatedRealizedPnl?: Maybe<Scalars['Float']>;
  /** The unrealized P/L as calculated based upon the existing transactions in order of occurence */
  calculatedUnrealizedPnl?: Maybe<Scalars['Float']>;
  commissionAndFeeTotal: Scalars['Float'];
  commissionTotal: Scalars['Float'];
  /** The weighted average price of transactions contained within this position that have not been offset (i.e. the open transactions). This value will be null for all positions not populated via an FCM integration. */
  currentWeightedAveragePrice?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['String']>;
  displayExpiresAt: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  feeTotal: Scalars['Float'];
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  /** A concatenation of "accountId" + "+" + "instrumentId */
  id: Scalars['ID'];
  instrumentId: Scalars['String'];
  instrumentType: TypeOfInstrument;
  isExpired: Scalars['Boolean'];
  /** An indicator as to whether the realized / unrealized p/l values were computed using the current position components (on true) or the existing transactions (on false). */
  isUnrealizedPnlFromFcmComponents: Scalars['Boolean'];
  /** The weighted average price of all transactions contained within this position, even if they have been offset. */
  lifetimeWeightedAveragePrice: Scalars['Float'];
  optionType?: Maybe<TypeOfOption>;
  ownerId: Scalars['String'];
  /** The realized P/L as calculated from the position components received from the FCM */
  positionComponentRealizedPnl?: Maybe<Scalars['Float']>;
  /** The unrealized P/L as calculated from the position components received from the FCM */
  positionComponentUnrealizedPnl?: Maybe<Scalars['Float']>;
  positionSide: Side;
  productId: Scalars['String'];
  productName: Scalars['String'];
  quantity: Scalars['Float'];
  realizedPnl?: Maybe<Scalars['Float']>;
  totalTradeValue: Scalars['Float'];
  unrealizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnlPriceDate?: Maybe<Scalars['String']>;
  /**
   * The weighted average price of all transactions contained within this position, even if they have been offset.
   * @deprecated Use "lifetimeWeightedAveragePrice" instead
   */
  weightedAveragePrice: Scalars['Float'];
};


/** The most current position for the given account / instrument. The current position values are aggregated by account / instrument, with no per-day or per-price data available. */
export type CurrentPosition_CurrentPositionComponentsArgs = {
  orderBy?: InputMaybe<CurrentPositionComponentSortByDTO>;
  where?: InputMaybe<CurrentPositionComponentFilterDTO>;
};


/** The most current position for the given account / instrument. The current position values are aggregated by account / instrument, with no per-day or per-price data available. */
export type CurrentPosition_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type CurrentPositionAggregateAllDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  calculatedRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  calculatedUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  currentWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  lifetimeWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  positionComponentRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionComponentUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productName?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  totalTradeValue?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnlPriceDate?: InputMaybe<Scalars['Boolean']>;
  weightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentPositionAggregateDTO = {
  avg?: InputMaybe<CurrentPositionAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<CurrentPositionAggregateAllDTO>;
  min?: InputMaybe<CurrentPositionAggregateAllDTO>;
  sum?: InputMaybe<CurrentPositionAggregateNumericDTO>;
};

export type CurrentPositionAggregateNumericDTO = {
  calculatedRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  calculatedUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  currentWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  lifetimeWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  positionComponentRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionComponentUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  totalTradeValue?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  weightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
};

/** The most current components of a position for the given account / instrument. The position components represent the sub-quantities of each position by price & trade date. */
export type CurrentPositionComponent = {
  __typename?: 'CurrentPositionComponent';
  Account: IAccount;
  Instrument: IInstrument;
  accountId: Scalars['String'];
  aclResourceType: TypeOfAclResource;
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  instrumentId: Scalars['String'];
  integrationInstallationId: Scalars['String'];
  /** A generated field containing accountId + '+' + instrumentId. This matches the position id format used by current positions and transactions. */
  positionId: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  tradeDate: Scalars['String'];
  unrealizedPnl?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type CurrentPositionComponentAggregateAllDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  tradeDate?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentPositionComponentAggregateDTO = {
  avg?: InputMaybe<CurrentPositionComponentAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<CurrentPositionComponentAggregateAllDTO>;
  min?: InputMaybe<CurrentPositionComponentAggregateAllDTO>;
  sum?: InputMaybe<CurrentPositionComponentAggregateNumericDTO>;
};

export type CurrentPositionComponentAggregateNumericDTO = {
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentPositionComponentDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  aclResourceType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  tradeDate?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentPositionComponentFilterDTO = {
  AND?: InputMaybe<Array<CurrentPositionComponentFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionFilterDTO>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  IntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  NOT?: InputMaybe<CurrentPositionComponentFilterDTO>;
  OR?: InputMaybe<Array<CurrentPositionComponentFilterDTO>>;
  accountId?: InputMaybe<StringFilter>;
  aclResourceType?: InputMaybe<TypeOfAclResourceFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  integrationInstallationId?: InputMaybe<StringFilter>;
  positionId?: InputMaybe<StringFilter>;
  price?: InputMaybe<NumberFilter>;
  quantity?: InputMaybe<NumberFilter>;
  tradeDate?: InputMaybe<StringFilter>;
  unrealizedPnl?: InputMaybe<NullableNumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CurrentPositionComponentGroupByDTO = {
  Account?: InputMaybe<AccountGroupByDTO>;
  AccountAsBrokerageAccount?: InputMaybe<BrokerageAccountGroupByDTO>;
  AccountAsForwardContractAccount?: InputMaybe<ForwardContractAccountGroupByDTO>;
  AccountAsSwapAccount?: InputMaybe<SwapAccountGroupByDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionGroupByDTO>;
  Instrument?: InputMaybe<InstrumentGroupByDTO>;
  InstrumentAsBasisInstrument?: InputMaybe<BasisInstrumentGroupByDTO>;
  InstrumentAsCashInstrument?: InputMaybe<CashInstrumentGroupByDTO>;
  InstrumentAsCommitmentInstrument?: InputMaybe<CommitmentInstrumentGroupByDTO>;
  InstrumentAsFutureInstrument?: InputMaybe<FutureGroupByDTO>;
  InstrumentAsOptionInstrument?: InputMaybe<OptionGroupByDTO>;
  InstrumentAsPhysicalInstrument?: InputMaybe<PhysicalInstrumentGroupByDTO>;
  InstrumentAsSpreadInstrument?: InputMaybe<SpreadInstrumentGroupByDTO>;
  InstrumentAsSwapInstrument?: InputMaybe<SwapGroupByDTO>;
  InstrumentAsSwaptionInstrument?: InputMaybe<SwaptionGroupByDTO>;
  IntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  accountId?: InputMaybe<Scalars['Boolean']>;
  aclResourceType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  tradeDate?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentPositionComponentSortByDTO = {
  Account?: InputMaybe<AccountSortByDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionSortByDTO>;
  Instrument?: InputMaybe<InstrumentSortByDTO>;
  IntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  aclResourceType?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  instrumentId?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<SortByDirection>;
  positionId?: InputMaybe<SortByDirection>;
  price?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  tradeDate?: InputMaybe<SortByDirection>;
  unrealizedPnl?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type CurrentPositionDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  accountType?: InputMaybe<Scalars['Boolean']>;
  calculatedRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  calculatedUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  currentWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  instrumentType?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  isUnrealizedPnlFromFcmComponents?: InputMaybe<Scalars['Boolean']>;
  lifetimeWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  positionComponentRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionComponentUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionSide?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productName?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  totalTradeValue?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnlPriceDate?: InputMaybe<Scalars['Boolean']>;
  weightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentPositionFilterDTO = {
  AND?: InputMaybe<Array<CurrentPositionFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<CurrentPositionFilterDTO>;
  OR?: InputMaybe<Array<CurrentPositionFilterDTO>>;
  Owner?: InputMaybe<EntityFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliation?: InputMaybe<PositionReconciliationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  accountId?: InputMaybe<StringFilter>;
  accountType?: InputMaybe<TypeOfAccountFilter>;
  calculatedRealizedPnl?: InputMaybe<NullableNumberFilter>;
  calculatedUnrealizedPnl?: InputMaybe<NullableNumberFilter>;
  commissionAndFeeTotal?: InputMaybe<NumberFilter>;
  commissionTotal?: InputMaybe<NumberFilter>;
  currentWeightedAveragePrice?: InputMaybe<NullableNumberFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  displayExpiresAt?: InputMaybe<StringFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  feeTotal?: InputMaybe<NumberFilter>;
  grossPnl?: InputMaybe<NullableNumberFilter>;
  grossProtection?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  instrumentType?: InputMaybe<TypeOfInstrumentFilter>;
  isExpired?: InputMaybe<BooleanFilter>;
  isUnrealizedPnlFromFcmComponents?: InputMaybe<BooleanFilter>;
  lifetimeWeightedAveragePrice?: InputMaybe<NumberFilter>;
  optionType?: InputMaybe<NullableTypeOfOptionFilter>;
  ownerId?: InputMaybe<StringFilter>;
  positionComponentRealizedPnl?: InputMaybe<NullableNumberFilter>;
  positionComponentUnrealizedPnl?: InputMaybe<NullableNumberFilter>;
  positionSide?: InputMaybe<SideFilter>;
  productId?: InputMaybe<StringFilter>;
  productName?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<NumberFilter>;
  realizedPnl?: InputMaybe<NullableNumberFilter>;
  totalTradeValue?: InputMaybe<NumberFilter>;
  unrealizedPnl?: InputMaybe<NullableNumberFilter>;
  unrealizedPnlPriceDate?: InputMaybe<NullableStringFilter>;
  weightedAveragePrice?: InputMaybe<NumberFilter>;
};

export type CurrentPositionGroupByDTO = {
  Account?: InputMaybe<AccountGroupByDTO>;
  AccountAsBrokerageAccount?: InputMaybe<BrokerageAccountGroupByDTO>;
  AccountAsForwardContractAccount?: InputMaybe<ForwardContractAccountGroupByDTO>;
  AccountAsSwapAccount?: InputMaybe<SwapAccountGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  Instrument?: InputMaybe<InstrumentGroupByDTO>;
  InstrumentAsBasisInstrument?: InputMaybe<BasisInstrumentGroupByDTO>;
  InstrumentAsCashInstrument?: InputMaybe<CashInstrumentGroupByDTO>;
  InstrumentAsCommitmentInstrument?: InputMaybe<CommitmentInstrumentGroupByDTO>;
  InstrumentAsFutureInstrument?: InputMaybe<FutureGroupByDTO>;
  InstrumentAsOptionInstrument?: InputMaybe<OptionGroupByDTO>;
  InstrumentAsPhysicalInstrument?: InputMaybe<PhysicalInstrumentGroupByDTO>;
  InstrumentAsSpreadInstrument?: InputMaybe<SpreadInstrumentGroupByDTO>;
  InstrumentAsSwapInstrument?: InputMaybe<SwapGroupByDTO>;
  InstrumentAsSwaptionInstrument?: InputMaybe<SwaptionGroupByDTO>;
  Owner?: InputMaybe<EntityGroupByDTO>;
  OwnerAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  OwnerAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  OwnerAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  OwnerAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  PositionReconciliation?: InputMaybe<PositionReconciliationGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  accountId?: InputMaybe<Scalars['Boolean']>;
  accountType?: InputMaybe<Scalars['Boolean']>;
  calculatedRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  calculatedUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  currentWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  instrumentType?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  isUnrealizedPnlFromFcmComponents?: InputMaybe<Scalars['Boolean']>;
  lifetimeWeightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  positionComponentRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionComponentUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionSide?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productName?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  totalTradeValue?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnlPriceDate?: InputMaybe<Scalars['Boolean']>;
  weightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
};

export type CurrentPositionSortByDTO = {
  Account?: InputMaybe<AccountSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  Instrument?: InputMaybe<InstrumentSortByDTO>;
  Owner?: InputMaybe<EntitySortByDTO>;
  PositionReconciliation?: InputMaybe<PositionReconciliationSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  accountType?: InputMaybe<SortByDirection>;
  calculatedRealizedPnl?: InputMaybe<NullableSortByDirection>;
  calculatedUnrealizedPnl?: InputMaybe<NullableSortByDirection>;
  commissionAndFeeTotal?: InputMaybe<SortByDirection>;
  commissionTotal?: InputMaybe<SortByDirection>;
  currentWeightedAveragePrice?: InputMaybe<NullableSortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  displayExpiresAt?: InputMaybe<SortByDirection>;
  expiresAt?: InputMaybe<SortByDirection>;
  feeTotal?: InputMaybe<SortByDirection>;
  grossPnl?: InputMaybe<NullableSortByDirection>;
  grossProtection?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  instrumentId?: InputMaybe<SortByDirection>;
  instrumentType?: InputMaybe<SortByDirection>;
  isExpired?: InputMaybe<SortByDirection>;
  isUnrealizedPnlFromFcmComponents?: InputMaybe<SortByDirection>;
  lifetimeWeightedAveragePrice?: InputMaybe<SortByDirection>;
  optionType?: InputMaybe<NullableSortByDirection>;
  ownerId?: InputMaybe<SortByDirection>;
  positionComponentRealizedPnl?: InputMaybe<NullableSortByDirection>;
  positionComponentUnrealizedPnl?: InputMaybe<NullableSortByDirection>;
  positionSide?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productName?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  realizedPnl?: InputMaybe<NullableSortByDirection>;
  totalTradeValue?: InputMaybe<SortByDirection>;
  unrealizedPnl?: InputMaybe<NullableSortByDirection>;
  unrealizedPnlPriceDate?: InputMaybe<NullableSortByDirection>;
  weightedAveragePrice?: InputMaybe<SortByDirection>;
};

export type CustomerEntity = IEntity & {
  __typename?: 'CustomerEntity';
  Accounts: Array<IAccount>;
  ActiveProducts: Array<Product>;
  AgreedToOtcContracts: Array<OtcContract>;
  BrokerageAccounts: Array<BrokerageAccount>;
  BusinessContacts: Array<BusinessContact>;
  County?: Maybe<County>;
  CreatedByUser?: Maybe<User>;
  CropHarvests: Array<CropHarvest>;
  Crops: Array<Crop>;
  CurrentUserPermissions: UserPermissionsForTarget;
  EntityUsers: Array<EntityUser>;
  ExposureRatios: Array<EntityExposureRatio>;
  FeedIngredientConsumedAndPurchasedVolumes: Array<FeedIngredientConsumedAndPurchasedVolume>;
  FeedIngredientPrices: Array<FeedIngredientPrice>;
  Fields: Array<Field>;
  ForecastedLivestockFeedUsages: Array<ILivestockGroupFeedUsage>;
  ForecastedSwineLivestockFeedUsages: Array<ForecastedSwineLivestockFeedUsage>;
  ForwardContracts: Array<ForwardContractAccount>;
  GrainPlans: Array<IGrainPlan>;
  GroupEntries: Array<EntityGroupEntry>;
  Groups: Array<EntityGroup>;
  HedgeStrategies: Array<HedgeStrategy>;
  HedgeStrategyHistoricalPnls: Array<HedgeStrategyHistoricalPnl>;
  InitiatedOtcContracts: Array<OtcContract>;
  InsurancePolicies: Array<InsurancePolicy>;
  LastUpdatedByUser?: Maybe<User>;
  LedgerCategories: Array<ILedgerCategory>;
  LedgerEntries: Array<ILedgerEntry>;
  LivestockGroupFeedUsages: Array<ILivestockGroupFeedUsage>;
  LivestockGroups: Array<LivestockGroup>;
  Locations: Array<LocationEntity>;
  Members: Array<EntityMember>;
  MilkOrder?: Maybe<MilkOrder>;
  Notes: Array<EntityNote>;
  Organization?: Maybe<OrganizationEntity>;
  OwnedAccounts: Array<IAccount>;
  OwnedOtcContracts: Array<OtcContract>;
  Parent?: Maybe<IEntity>;
  ParentOrganization: NamedResource;
  PhysicalFeedTransactions: Array<PhysicalFeedTransaction>;
  PhysicalSales: Array<PhysicalSale>;
  RelationshipOwner?: Maybe<User>;
  SubBusinesses: Array<CustomerEntity>;
  SwineLivestockGroupFeedUsages: Array<SwineLivestockGroupFeedUsage>;
  SwineLivestockGroups: Array<SwineLivestockGroup>;
  SwineLivestockPopulationForecastsByWeek: Array<SwineLivestockPopulationForecastByWeek>;
  /** The average age in weeks at which a pig is ready for market */
  averageFinishAgeInWeeks: Scalars['Int'];
  /** The average weight in pounds at which a pig is ready for market */
  averageFinishWeightInLbs: Scalars['Int'];
  businessRoles: Array<BusinessEntityRole>;
  countyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  identificationNumber: Scalars['Int'];
  isVgs: Scalars['Boolean'];
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  milkOrderId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  parentId: Scalars['String'];
  relationshipOwnerId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type: TypeOfEntity;
  updatedAt: Scalars['DateTime'];
  vgsCustomerId?: Maybe<Scalars['Int']>;
};


export type CustomerEntity_AccountsArgs = {
  orderBy?: InputMaybe<AccountSortByDTO>;
  where?: InputMaybe<AccountFilterDTO>;
};


export type CustomerEntity_AgreedToOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type CustomerEntity_BrokerageAccountsArgs = {
  orderBy?: InputMaybe<BrokerageAccountSortByDTO>;
  where?: InputMaybe<BrokerageAccountFilterDTO>;
};


export type CustomerEntity_BusinessContactsArgs = {
  orderBy?: InputMaybe<BusinessContactSortByDTO>;
  where?: InputMaybe<BusinessContactFilterDTO>;
};


export type CustomerEntity_CropHarvestsArgs = {
  orderBy?: InputMaybe<CropHarvestSortByDTO>;
  where?: InputMaybe<CropHarvestFilterDTO>;
};


export type CustomerEntity_CropsArgs = {
  orderBy?: InputMaybe<CropSortByDTO>;
  where?: InputMaybe<CropFilterDTO>;
};


export type CustomerEntity_EntityUsersArgs = {
  orderBy?: InputMaybe<EntityUserSortByDTO>;
  where?: InputMaybe<EntityUserFilterDTO>;
};


export type CustomerEntity_ExposureRatiosArgs = {
  orderBy?: InputMaybe<EntityExposureRatioSortByDTO>;
  where?: InputMaybe<EntityExposureRatioFilterDTO>;
};


export type CustomerEntity_FeedIngredientConsumedAndPurchasedVolumesArgs = {
  orderBy?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeSortByDTO>;
  where?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
};


export type CustomerEntity_FeedIngredientPricesArgs = {
  orderBy?: InputMaybe<FeedIngredientPriceSortByDTO>;
  where?: InputMaybe<FeedIngredientPriceFilterDTO>;
};


export type CustomerEntity_FieldsArgs = {
  orderBy?: InputMaybe<FieldSortByDTO>;
  where?: InputMaybe<FieldFilterDTO>;
};


export type CustomerEntity_ForecastedLivestockFeedUsagesArgs = {
  orderBy?: InputMaybe<ForecastedLivestockFeedUsageSortByDTO>;
  where?: InputMaybe<ForecastedLivestockFeedUsageFilterDTO>;
};


export type CustomerEntity_ForecastedSwineLivestockFeedUsagesArgs = {
  orderBy?: InputMaybe<ForecastedSwineLivestockFeedUsageSortByDTO>;
  where?: InputMaybe<ForecastedSwineLivestockFeedUsageFilterDTO>;
};


export type CustomerEntity_ForwardContractsArgs = {
  orderBy?: InputMaybe<ForwardContractAccountSortByDTO>;
  where?: InputMaybe<ForwardContractAccountFilterDTO>;
};


export type CustomerEntity_GrainPlansArgs = {
  orderBy?: InputMaybe<GrainPlanSortByDTO>;
  where?: InputMaybe<GrainPlanFilterDTO>;
};


export type CustomerEntity_GroupEntriesArgs = {
  orderBy?: InputMaybe<EntityGroupEntrySortByDTO>;
  where?: InputMaybe<EntityGroupEntryFilterDTO>;
};


export type CustomerEntity_HedgeStrategiesArgs = {
  orderBy?: InputMaybe<HedgeStrategySortByDTO>;
  where?: InputMaybe<HedgeStrategyFilterDTO>;
};


export type CustomerEntity_HedgeStrategyHistoricalPnlsArgs = {
  orderBy?: InputMaybe<HedgeStrategyHistoricalPnlSortByDTO>;
  where?: InputMaybe<HedgeStrategyHistoricalPnlFilterDTO>;
};


export type CustomerEntity_InitiatedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type CustomerEntity_InsurancePoliciesArgs = {
  orderBy?: InputMaybe<ProducerAndPolicySortByDTO>;
  where?: InputMaybe<ProducerAndPolicyFilterDTO>;
};


export type CustomerEntity_LedgerCategoriesArgs = {
  orderBy?: InputMaybe<LedgerCategorySortByDTO>;
  where?: InputMaybe<LedgerCategoryFilterDTO>;
};


export type CustomerEntity_LedgerEntriesArgs = {
  orderBy?: InputMaybe<LedgerEntrySortByDTO>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};


export type CustomerEntity_LivestockGroupFeedUsagesArgs = {
  orderBy?: InputMaybe<LivestockGroupFeedUsageSortByDTO>;
  where?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
};


export type CustomerEntity_LivestockGroupsArgs = {
  orderBy?: InputMaybe<LivestockGroupSortByDTO>;
  where?: InputMaybe<LivestockGroupFilterDTO>;
};


export type CustomerEntity_LocationsArgs = {
  orderBy?: InputMaybe<LocationEntitySortByDTO>;
  where?: InputMaybe<LocationEntityFilterDTO>;
};


export type CustomerEntity_MembersArgs = {
  orderBy?: InputMaybe<EntityMemberSortByDTO>;
  where?: InputMaybe<EntityMemberFilterDTO>;
};


export type CustomerEntity_NotesArgs = {
  orderBy?: InputMaybe<EntityNoteSortByDTO>;
  where?: InputMaybe<EntityNoteFilterDTO>;
};


export type CustomerEntity_OwnedAccountsArgs = {
  orderBy?: InputMaybe<AccountSortByDTO>;
  where?: InputMaybe<AccountFilterDTO>;
};


export type CustomerEntity_OwnedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type CustomerEntity_PhysicalFeedTransactionsArgs = {
  orderBy?: InputMaybe<PhysicalFeedTransactionSortByDTO>;
  where?: InputMaybe<PhysicalFeedTransactionFilterDTO>;
};


export type CustomerEntity_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type CustomerEntity_SubBusinessesArgs = {
  orderBy?: InputMaybe<CustomerEntitySortByDTO>;
  where?: InputMaybe<CustomerEntityFilterDTO>;
};


export type CustomerEntity_SwineLivestockGroupFeedUsagesArgs = {
  orderBy?: InputMaybe<SwineLivestockGroupFeedUsageSortByDTO>;
  where?: InputMaybe<SwineLivestockGroupFeedUsageFilterDTO>;
};


export type CustomerEntity_SwineLivestockGroupsArgs = {
  orderBy?: InputMaybe<SwineLivestockGroupSortByDTO>;
  where?: InputMaybe<SwineLivestockGroupFilterDTO>;
};


export type CustomerEntity_SwineLivestockPopulationForecastsByWeekArgs = {
  orderBy?: InputMaybe<SwineLivestockPopulationForecastByWeekSortByDTO>;
  where?: InputMaybe<SwineLivestockPopulationForecastByWeekFilterDTO>;
};

export type CustomerEntityBusinessRelationshipOwnerSetInput = {
  businessId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
};

export type CustomerEntityCreateDTO = {
  averageFinishAgeInWeeks?: InputMaybe<Scalars['Int']>;
  averageFinishWeightInLbs?: InputMaybe<Scalars['Int']>;
  businessRoles?: InputMaybe<Array<BusinessEntityRole>>;
  countyId?: InputMaybe<Scalars['String']>;
  deactivatedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  relationshipOwnerId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  vgsCustomerId?: InputMaybe<Scalars['Int']>;
};

export type CustomerEntityDistinctOnDTO = {
  averageFinishAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  averageFinishWeightInLbs?: InputMaybe<Scalars['Boolean']>;
  businessRoles?: InputMaybe<Scalars['Boolean']>;
  countyId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  deactivatedAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Boolean']>;
  relationshipOwnerId?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsCustomerId?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerEntityFilterDTO = {
  AND?: InputMaybe<Array<CustomerEntityFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  Accounts?: InputMaybe<AccountFilterDTO>;
  ActualBlendedMilkPrices?: InputMaybe<ActualBlendedMilkPriceFilterDTO>;
  ActualMilkProductionByMonth?: InputMaybe<ActualMilkProductionByMonthFilterDTO>;
  AgreedToOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  AllocatedForecastedHedgedAndCappedVolumes?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>;
  AsEntity?: InputMaybe<EntityFilterDTO>;
  BusinessContacts?: InputMaybe<BusinessContactFilterDTO>;
  Children?: InputMaybe<EntityFilterDTO>;
  County?: InputMaybe<CountyFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  CropHarvests?: InputMaybe<CropHarvestFilterDTO>;
  Crops?: InputMaybe<CropFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  EntityActiveProducts?: InputMaybe<EntityActiveProductFilterDTO>;
  EntityAllocatedExposureRatios?: InputMaybe<EntityAllocatedExposureRatioFilterDTO>;
  EntityAllocatedExposureRatiosByOwner?: InputMaybe<EntityAllocatedExposureRatioByOwnerFilterDTO>;
  EntityAllocatedForecastedBrokerageVolumesHedged?: InputMaybe<EntityAllocatedForecastedBrokerageVolumeHedgedFilterDTO>;
  EntityAllocatedForecastedVolumesHedgedByOwner?: InputMaybe<EntityAllocatedForecastedVolumeHedgedByOwnerFilterDTO>;
  EntityForecastedPhysicalVolumeHedged?: InputMaybe<EntityForecastedPhysicalVolumeHedgedFilterDTO>;
  EntityUsers?: InputMaybe<EntityUserFilterDTO>;
  ExposureRatios?: InputMaybe<EntityExposureRatioFilterDTO>;
  FeedIngredientConsumedAndPurchasedVolumes?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
  FeedIngredientPrices?: InputMaybe<FeedIngredientPriceFilterDTO>;
  FeedIngredientUsages?: InputMaybe<FeedIngredientUsageFilterDTO>;
  FeedIngredients?: InputMaybe<FeedIngredientFilterDTO>;
  FeedTransactions?: InputMaybe<FeedTransactionFilterDTO>;
  Fields?: InputMaybe<FieldFilterDTO>;
  ForecastedHedgedAndCappedVolume?: InputMaybe<ForecastedHedgedAndCappedVolumeFilterDTO>;
  ForecastedLivestockFeedUsages?: InputMaybe<ForecastedLivestockFeedUsageFilterDTO>;
  ForecastedMilkProductionByMonth?: InputMaybe<ForecastedMilkProductionByMonthFilterDTO>;
  ForecastedMilkProductionByMonthRolledUp?: InputMaybe<ForecastedMilkProductionByMonthRolledUpFilterDTO>;
  ForecastedMilkProductionCoverage?: InputMaybe<ForecastedMilkProductionCoverageFilterDTO>;
  ForecastedSwineLivestockFeedUsageAtFinish?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishFilterDTO>;
  GrainPlans?: InputMaybe<GrainPlanFilterDTO>;
  GroupEntries?: InputMaybe<EntityGroupEntryFilterDTO>;
  HedgeStrategies?: InputMaybe<HedgeStrategyFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  HedgeStrategyHistoricalPnls?: InputMaybe<HedgeStrategyHistoricalPnlFilterDTO>;
  InitiatedOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  InsurancePolicies?: InputMaybe<InsurancePolicyFilterDTO>;
  IntegrationInstallations?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  LedgerCategories?: InputMaybe<LedgerCategoryFilterDTO>;
  LedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  LivestockGroupFeedUsages?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
  LivestockGroups?: InputMaybe<LivestockGroupFilterDTO>;
  LivestockPopulationChanges?: InputMaybe<LivestockPopulationChangeFilterDTO>;
  Locations?: InputMaybe<LocationEntityFilterDTO>;
  Members?: InputMaybe<EntityMemberFilterDTO>;
  MilkOrder?: InputMaybe<MilkOrderFilterDTO>;
  NOT?: InputMaybe<CustomerEntityFilterDTO>;
  Notes?: InputMaybe<EntityNoteFilterDTO>;
  OR?: InputMaybe<Array<CustomerEntityFilterDTO>>;
  Organization?: InputMaybe<OrganizationEntityFilterDTO>;
  OwnedOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  Parent?: InputMaybe<EntityFilterDTO>;
  PhysicalFeedTransactions?: InputMaybe<PhysicalFeedTransactionFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  RelationshipOwner?: InputMaybe<UserFilterDTO>;
  SwineLivestockGroupContracts?: InputMaybe<SwineLivestockGroupContractFilterDTO>;
  SwineLivestockPopulationForecastsByWeek?: InputMaybe<SwineLivestockPopulationForecastByWeekFilterDTO>;
  VersionedBusinessData?: InputMaybe<VersionedBusinessDataFilterDTO>;
  Versions?: InputMaybe<VersionFilterDTO>;
  averageFinishAgeInWeeks?: InputMaybe<NumberFilter>;
  averageFinishWeightInLbs?: InputMaybe<NumberFilter>;
  businessRoles?: InputMaybe<BusinessEntityRoleArrayFilterDTO>;
  countyId?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  deactivatedAt?: InputMaybe<NullableDateTimeFilter>;
  externalId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  identificationNumber?: InputMaybe<NumberFilter>;
  isVgs?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  milkOrderId?: InputMaybe<NullableStringFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<StringFilter>;
  relationshipOwnerId?: InputMaybe<NullableStringFilter>;
  slug?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsCustomerId?: InputMaybe<NullableNumberFilter>;
};

export type CustomerEntityGroupByDTO = {
  AsEntity?: InputMaybe<EntityGroupByDTO>;
  County?: InputMaybe<CountyGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  MilkOrder?: InputMaybe<MilkOrderGroupByDTO>;
  Organization?: InputMaybe<OrganizationEntityGroupByDTO>;
  Parent?: InputMaybe<EntityGroupByDTO>;
  RelationshipOwner?: InputMaybe<UserGroupByDTO>;
  averageFinishAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  averageFinishWeightInLbs?: InputMaybe<Scalars['Boolean']>;
  businessRoles?: InputMaybe<Scalars['Boolean']>;
  countyId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  deactivatedAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Boolean']>;
  relationshipOwnerId?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsCustomerId?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerEntitySortByDTO = {
  AsEntity?: InputMaybe<EntitySortByDTO>;
  County?: InputMaybe<CountySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  MilkOrder?: InputMaybe<MilkOrderSortByDTO>;
  Organization?: InputMaybe<OrganizationEntitySortByDTO>;
  Parent?: InputMaybe<EntitySortByDTO>;
  RelationshipOwner?: InputMaybe<UserSortByDTO>;
  averageFinishAgeInWeeks?: InputMaybe<SortByDirection>;
  averageFinishWeightInLbs?: InputMaybe<SortByDirection>;
  countyId?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  deactivatedAt?: InputMaybe<NullableSortByDirection>;
  externalId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  identificationNumber?: InputMaybe<SortByDirection>;
  isVgs?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  milkOrderId?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<SortByDirection>;
  organizationId?: InputMaybe<SortByDirection>;
  parentId?: InputMaybe<SortByDirection>;
  relationshipOwnerId?: InputMaybe<NullableSortByDirection>;
  slug?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsCustomerId?: InputMaybe<NullableSortByDirection>;
};

export type CustomerEntityUpdateDTO = {
  /** The average age in weeks at which a pig is ready for market */
  averageFinishAgeInWeeks?: InputMaybe<Scalars['Int']>;
  /** The average weight in pounds at which a pig is ready for market */
  averageFinishWeightInLbs?: InputMaybe<Scalars['Int']>;
  businessRoles?: InputMaybe<Array<BusinessEntityRole>>;
  countyId?: InputMaybe<Scalars['String']>;
  deactivatedAt?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  relationshipOwnerId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  vgsCustomerId?: InputMaybe<Scalars['Int']>;
};

export type DairyRPPracticeCodeType = {
  __typename?: 'DairyRPPracticeCodeType';
  classPriceWeightingFactorRestrictedValue?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactorRestrictedValue?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedClassIIIPrice?: Maybe<Scalars['Float']>;
  expectedClassIVPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedYield: Scalars['Float'];
  expectedYieldStandardDeviation: Scalars['Float'];
  name: Scalars['String'];
  practiceAbbreviation: Scalars['String'];
  practiceCode: Scalars['String'];
  quarter: Scalars['String'];
  reinsuranceYear: Scalars['Int'];
  year: Scalars['String'];
};

export type DairyRpEndorsement = {
  __typename?: 'DairyRpEndorsement';
  code?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  grossPremium: Scalars['Float'];
  level: Scalars['Float'];
  liability: Scalars['Float'];
  netPremium: Scalars['Float'];
  percentCoverage?: Maybe<Scalars['Float']>;
  protectedPrice: Scalars['Float'];
  revenueGuarantee: Scalars['Float'];
  subsidy: Scalars['Float'];
};

export type DairyRpQuote = {
  __typename?: 'DairyRpQuote';
  ClassEndorsement?: Maybe<DairyRpEndorsement>;
  ComponentEndorsement?: Maybe<DairyRpEndorsement>;
  coverageLevel: Scalars['Float'];
  declaredProduction: Scalars['Float'];
  hasClassQuote: Scalars['Boolean'];
  hasComponentQuote: Scalars['Boolean'];
  practiceCode: Scalars['String'];
  protectionFactor: Scalars['Float'];
  salesEffectiveDate: Scalars['String'];
  stateAbbreviation: Scalars['String'];
};

export enum DateIncrement {
  Day = 'Day',
  Month = 'Month',
  Quarter = 'Quarter',
  Week = 'Week',
  Year = 'Year'
}

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DeletedResource = {
  __typename?: 'DeletedResource';
  id: Scalars['ID'];
};

/** **This field is deprecated! All fields on this model are now available on DrpInsuranceEndorsement** */
export type DerivedDrpEndorsement = {
  __typename?: 'DerivedDrpEndorsement';
  InsuranceEndorsement: DrpInsuranceEndorsement;
  InsurancePolicy: InsurancePolicy;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classIiiGrossProtection?: Maybe<Scalars['Float']>;
  classIiiPounds?: Maybe<Scalars['Float']>;
  classIvGrossProtection?: Maybe<Scalars['Float']>;
  classIvPounds?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  /** @deprecated Use "forecastedPnl" instead */
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  /** @deprecated This field is no longer provided */
  excessProteinPoundsAfterCheese: Scalars['Float'];
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassIiiPrice?: Maybe<Scalars['Float']>;
  expectedClassIvPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** This will contain either the actual indemnity value if provided or the forecasted indemnity value otherwise. */
  indemnity?: Maybe<Scalars['Float']>;
  /** @deprecated Use "forecastedIndemnity" instead */
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassIii?: Maybe<Scalars['Float']>;
  indemnityEffectClassIv?: Maybe<Scalars['Float']>;
  /** @deprecated This field is no longer provided */
  indemnityEffectExcessProtein: Scalars['Float'];
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  insurancePolicyId: Scalars['String'];
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassIiiPrice?: Maybe<Scalars['Float']>;
  insuredClassIvPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  isLong: Scalars['Boolean'];
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  /** This will contain either the actual pnl value if provided or the forecasted pnl value otherwise. */
  pnl?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  quarterEndDate: Scalars['String'];
  quarterStartDate: Scalars['String'];
  reinsuranceYear?: Maybe<Scalars['Float']>;
  rmaPracticeCode?: Maybe<Scalars['Float']>;
  salesEffectiveDate: Scalars['String'];
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type DerivedDrpEndorsementAggregateAllDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIiiPounds?: InputMaybe<Scalars['Boolean']>;
  classIvGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIvPounds?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIvPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassIii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassIv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassIiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassIvPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  quarterEndDate?: InputMaybe<Scalars['Boolean']>;
  quarterStartDate?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DerivedDrpEndorsementAggregateDTO = {
  avg?: InputMaybe<DerivedDrpEndorsementAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<DerivedDrpEndorsementAggregateAllDTO>;
  min?: InputMaybe<DerivedDrpEndorsementAggregateAllDTO>;
  sum?: InputMaybe<DerivedDrpEndorsementAggregateNumericDTO>;
};

export type DerivedDrpEndorsementAggregateNumericDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIiiPounds?: InputMaybe<Scalars['Boolean']>;
  classIvGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIvPounds?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIvPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassIii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassIv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassIiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassIvPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DerivedDrpEndorsementDistinctOnDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIiiPounds?: InputMaybe<Scalars['Boolean']>;
  classIvGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIvPounds?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIvPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassIii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassIv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassIiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassIvPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  quarterEndDate?: InputMaybe<Scalars['Boolean']>;
  quarterStartDate?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DerivedDrpEndorsementFilterDTO = {
  AND?: InputMaybe<Array<DerivedDrpEndorsementFilterDTO>>;
  DrpInsuranceEndorsement?: InputMaybe<DrpInsuranceEndorsementFilterDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyFilterDTO>;
  NOT?: InputMaybe<DerivedDrpEndorsementFilterDTO>;
  OR?: InputMaybe<Array<DerivedDrpEndorsementFilterDTO>>;
  actualIndemnity?: InputMaybe<NullableNumberFilter>;
  actualPnl?: InputMaybe<NullableNumberFilter>;
  actualYield?: InputMaybe<NullableNumberFilter>;
  butterGrossProtection?: InputMaybe<NullableNumberFilter>;
  butterfatGrossProtection?: InputMaybe<NullableNumberFilter>;
  butterfatPounds?: InputMaybe<NullableNumberFilter>;
  cheeseGrossProtection?: InputMaybe<NullableNumberFilter>;
  classIiiGrossProtection?: InputMaybe<NullableNumberFilter>;
  classIiiPounds?: InputMaybe<NullableNumberFilter>;
  classIvGrossProtection?: InputMaybe<NullableNumberFilter>;
  classIvPounds?: InputMaybe<NullableNumberFilter>;
  classPriceWeightingFactor?: InputMaybe<NullableNumberFilter>;
  componentPriceWeightingFactor?: InputMaybe<NullableNumberFilter>;
  coverageLevelPercent?: InputMaybe<NullableNumberFilter>;
  currentYield?: InputMaybe<NullableNumberFilter>;
  declaredButterfatTest?: InputMaybe<NullableNumberFilter>;
  declaredCoveredMilkProduction?: InputMaybe<NullableNumberFilter>;
  declaredProteinTest?: InputMaybe<NullableNumberFilter>;
  declaredShare?: InputMaybe<NullableNumberFilter>;
  effectiveCoveredMilkProduction?: InputMaybe<NullableNumberFilter>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<NullableNumberFilter>;
  estimatedPl?: InputMaybe<NullableNumberFilter>;
  excessButterPounds?: InputMaybe<NullableNumberFilter>;
  excessCheesePounds?: InputMaybe<NullableNumberFilter>;
  excessProteinPoundsAfterCheese?: InputMaybe<NumberFilter>;
  expectedButterPrice?: InputMaybe<NullableNumberFilter>;
  expectedButterfatPrice?: InputMaybe<NullableNumberFilter>;
  expectedCheesePrice?: InputMaybe<NullableNumberFilter>;
  expectedClassIiiPrice?: InputMaybe<NullableNumberFilter>;
  expectedClassIvPrice?: InputMaybe<NullableNumberFilter>;
  expectedNonfatSolidsPrice?: InputMaybe<NullableNumberFilter>;
  expectedOtherSolidsPrice?: InputMaybe<NullableNumberFilter>;
  expectedProteinPrice?: InputMaybe<NullableNumberFilter>;
  expectedRevenueGuarantee?: InputMaybe<NullableNumberFilter>;
  forecastedIndemnity?: InputMaybe<NullableNumberFilter>;
  forecastedPnl?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  indemnity?: InputMaybe<NullableNumberFilter>;
  indemnityAmount?: InputMaybe<NullableNumberFilter>;
  indemnityEffectButter?: InputMaybe<NullableNumberFilter>;
  indemnityEffectButterfat?: InputMaybe<NullableNumberFilter>;
  indemnityEffectCheese?: InputMaybe<NullableNumberFilter>;
  indemnityEffectClassIii?: InputMaybe<NullableNumberFilter>;
  indemnityEffectClassIv?: InputMaybe<NullableNumberFilter>;
  indemnityEffectExcessProtein?: InputMaybe<NumberFilter>;
  indemnityEffectNonfatSolids?: InputMaybe<NullableNumberFilter>;
  indemnityEffectOtherSolids?: InputMaybe<NullableNumberFilter>;
  indemnityEffectProtein?: InputMaybe<NullableNumberFilter>;
  insurancePolicyId?: InputMaybe<StringFilter>;
  insuredButterPrice?: InputMaybe<NullableNumberFilter>;
  insuredButterfatPrice?: InputMaybe<NullableNumberFilter>;
  insuredCheesePrice?: InputMaybe<NullableNumberFilter>;
  insuredClassIiiPrice?: InputMaybe<NullableNumberFilter>;
  insuredClassIvPrice?: InputMaybe<NullableNumberFilter>;
  insuredNonfatSolidsPrice?: InputMaybe<NullableNumberFilter>;
  insuredOtherSolidsPrice?: InputMaybe<NullableNumberFilter>;
  insuredProteinPrice?: InputMaybe<NullableNumberFilter>;
  insuredYield?: InputMaybe<NullableNumberFilter>;
  isLong?: InputMaybe<BooleanFilter>;
  lastYield?: InputMaybe<NullableNumberFilter>;
  nonfatSolidsGrossProtection?: InputMaybe<NullableNumberFilter>;
  nonfatSolidsPounds?: InputMaybe<NullableNumberFilter>;
  otherPoundsGrossProtection?: InputMaybe<NullableNumberFilter>;
  otherSolidsPounds?: InputMaybe<NullableNumberFilter>;
  pnl?: InputMaybe<NullableNumberFilter>;
  producerPremiumAmount?: InputMaybe<NullableNumberFilter>;
  projectedRevenue?: InputMaybe<NullableNumberFilter>;
  protectionFactor?: InputMaybe<NullableNumberFilter>;
  proteinGrossProtection?: InputMaybe<NullableNumberFilter>;
  proteinPounds?: InputMaybe<NullableNumberFilter>;
  quarterEndDate?: InputMaybe<StringFilter>;
  quarterStartDate?: InputMaybe<StringFilter>;
  reinsuranceYear?: InputMaybe<NullableNumberFilter>;
  rmaPracticeCode?: InputMaybe<NullableNumberFilter>;
  salesEffectiveDate?: InputMaybe<StringFilter>;
  totalPremiumAmount?: InputMaybe<NullableNumberFilter>;
  yieldAdjustmentFactor?: InputMaybe<NullableNumberFilter>;
};

export type DerivedDrpEndorsementGroupByDTO = {
  DrpInsuranceEndorsement?: InputMaybe<DrpInsuranceEndorsementGroupByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyGroupByDTO>;
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIiiPounds?: InputMaybe<Scalars['Boolean']>;
  classIvGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classIvPounds?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIvPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassIii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassIv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassIiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassIvPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  quarterEndDate?: InputMaybe<Scalars['Boolean']>;
  quarterStartDate?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DerivedDrpEndorsementSortByDTO = {
  DrpInsuranceEndorsement?: InputMaybe<DrpInsuranceEndorsementSortByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicySortByDTO>;
  actualIndemnity?: InputMaybe<NullableSortByDirection>;
  actualPnl?: InputMaybe<NullableSortByDirection>;
  actualYield?: InputMaybe<NullableSortByDirection>;
  butterGrossProtection?: InputMaybe<NullableSortByDirection>;
  butterfatGrossProtection?: InputMaybe<NullableSortByDirection>;
  butterfatPounds?: InputMaybe<NullableSortByDirection>;
  cheeseGrossProtection?: InputMaybe<NullableSortByDirection>;
  classIiiGrossProtection?: InputMaybe<NullableSortByDirection>;
  classIiiPounds?: InputMaybe<NullableSortByDirection>;
  classIvGrossProtection?: InputMaybe<NullableSortByDirection>;
  classIvPounds?: InputMaybe<NullableSortByDirection>;
  classPriceWeightingFactor?: InputMaybe<NullableSortByDirection>;
  componentPriceWeightingFactor?: InputMaybe<NullableSortByDirection>;
  coverageLevelPercent?: InputMaybe<NullableSortByDirection>;
  currentYield?: InputMaybe<NullableSortByDirection>;
  declaredButterfatTest?: InputMaybe<NullableSortByDirection>;
  declaredCoveredMilkProduction?: InputMaybe<NullableSortByDirection>;
  declaredProteinTest?: InputMaybe<NullableSortByDirection>;
  declaredShare?: InputMaybe<NullableSortByDirection>;
  effectiveCoveredMilkProduction?: InputMaybe<NullableSortByDirection>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<NullableSortByDirection>;
  estimatedPl?: InputMaybe<NullableSortByDirection>;
  excessButterPounds?: InputMaybe<NullableSortByDirection>;
  excessCheesePounds?: InputMaybe<NullableSortByDirection>;
  excessProteinPoundsAfterCheese?: InputMaybe<SortByDirection>;
  expectedButterPrice?: InputMaybe<NullableSortByDirection>;
  expectedButterfatPrice?: InputMaybe<NullableSortByDirection>;
  expectedCheesePrice?: InputMaybe<NullableSortByDirection>;
  expectedClassIiiPrice?: InputMaybe<NullableSortByDirection>;
  expectedClassIvPrice?: InputMaybe<NullableSortByDirection>;
  expectedNonfatSolidsPrice?: InputMaybe<NullableSortByDirection>;
  expectedOtherSolidsPrice?: InputMaybe<NullableSortByDirection>;
  expectedProteinPrice?: InputMaybe<NullableSortByDirection>;
  expectedRevenueGuarantee?: InputMaybe<NullableSortByDirection>;
  forecastedIndemnity?: InputMaybe<NullableSortByDirection>;
  forecastedPnl?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  indemnity?: InputMaybe<NullableSortByDirection>;
  indemnityAmount?: InputMaybe<NullableSortByDirection>;
  indemnityEffectButter?: InputMaybe<NullableSortByDirection>;
  indemnityEffectButterfat?: InputMaybe<NullableSortByDirection>;
  indemnityEffectCheese?: InputMaybe<NullableSortByDirection>;
  indemnityEffectClassIii?: InputMaybe<NullableSortByDirection>;
  indemnityEffectClassIv?: InputMaybe<NullableSortByDirection>;
  indemnityEffectExcessProtein?: InputMaybe<SortByDirection>;
  indemnityEffectNonfatSolids?: InputMaybe<NullableSortByDirection>;
  indemnityEffectOtherSolids?: InputMaybe<NullableSortByDirection>;
  indemnityEffectProtein?: InputMaybe<NullableSortByDirection>;
  insurancePolicyId?: InputMaybe<SortByDirection>;
  insuredButterPrice?: InputMaybe<NullableSortByDirection>;
  insuredButterfatPrice?: InputMaybe<NullableSortByDirection>;
  insuredCheesePrice?: InputMaybe<NullableSortByDirection>;
  insuredClassIiiPrice?: InputMaybe<NullableSortByDirection>;
  insuredClassIvPrice?: InputMaybe<NullableSortByDirection>;
  insuredNonfatSolidsPrice?: InputMaybe<NullableSortByDirection>;
  insuredOtherSolidsPrice?: InputMaybe<NullableSortByDirection>;
  insuredProteinPrice?: InputMaybe<NullableSortByDirection>;
  insuredYield?: InputMaybe<NullableSortByDirection>;
  isLong?: InputMaybe<SortByDirection>;
  lastYield?: InputMaybe<NullableSortByDirection>;
  nonfatSolidsGrossProtection?: InputMaybe<NullableSortByDirection>;
  nonfatSolidsPounds?: InputMaybe<NullableSortByDirection>;
  otherPoundsGrossProtection?: InputMaybe<NullableSortByDirection>;
  otherSolidsPounds?: InputMaybe<NullableSortByDirection>;
  pnl?: InputMaybe<NullableSortByDirection>;
  producerPremiumAmount?: InputMaybe<NullableSortByDirection>;
  projectedRevenue?: InputMaybe<NullableSortByDirection>;
  protectionFactor?: InputMaybe<NullableSortByDirection>;
  proteinGrossProtection?: InputMaybe<NullableSortByDirection>;
  proteinPounds?: InputMaybe<NullableSortByDirection>;
  quarterEndDate?: InputMaybe<SortByDirection>;
  quarterStartDate?: InputMaybe<SortByDirection>;
  reinsuranceYear?: InputMaybe<NullableSortByDirection>;
  rmaPracticeCode?: InputMaybe<NullableSortByDirection>;
  salesEffectiveDate?: InputMaybe<SortByDirection>;
  totalPremiumAmount?: InputMaybe<NullableSortByDirection>;
  yieldAdjustmentFactor?: InputMaybe<NullableSortByDirection>;
};

/** **This field is deprecated! Use "DrpInsuranceEndorsement" instead** */
export type DrpEndorsement = IInsuranceEndorsement & {
  __typename?: 'DrpEndorsement';
  AllocationRatios: Array<InsuranceEndorsementAllocationRatio>;
  HedgeStrategy?: Maybe<HedgeStrategy>;
  InsuranceAgent?: Maybe<InsuranceAgent>;
  InsurancePolicy: InsurancePolicy;
  InsurancePractice?: Maybe<InsurancePractice>;
  RevenueHedgeProducts: Array<Product>;
  RmaType?: Maybe<RmaType>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipDrpPremiumKey?: Maybe<Scalars['String']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction: Scalars['Float'];
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare: Scalars['Float'];
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  /** The revenue that the endorsement guarantees. */
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  /** The ending date (inclusive) of the endorsement's coverage period */
  endDate: Scalars['String'];
  /** @deprecated Use "forecastedPnl" instead */
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  /** @deprecated This field is no longer provided */
  excessProteinPoundsAfterCheese: Scalars['Float'];
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueAmount: Scalars['String'];
  expectedRevenueGuarantee: Scalars['String'];
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** This will contain either the actual indemnity value if provided or the forecasted indemnity value otherwise. */
  indemnity?: Maybe<Scalars['Float']>;
  /** @deprecated Use "forecastedIndemnity" instead */
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  /** @deprecated This field is no longer provided */
  indemnityEffectExcessProtein: Scalars['Float'];
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  inputSource: InputSource;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode: Scalars['String'];
  insurancePracticeId: Scalars['String'];
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong: Scalars['Boolean'];
  isShort: Scalars['Boolean'];
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  /** This will contain either the actual pnl value if provided or the forecasted pnl value otherwise. */
  pnl?: Maybe<Scalars['Float']>;
  policyId: Scalars['String'];
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount: Scalars['Float'];
  /** The revenue projected based on the current prices. */
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor: Scalars['Float'];
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear: Scalars['Int'];
  revenueHedgeProductIds: Array<Scalars['String']>;
  /** @deprecated Use "insurancePracticeCode" instead */
  rmaPracticeCode: Scalars['String'];
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  salesEffectiveDate: Scalars['String'];
  /** The starting date (inclusive) of the endorsement's coverage period */
  startDate: Scalars['String'];
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode: Scalars['String'];
  stateId: Scalars['String'];
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount: Scalars['Float'];
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount: Scalars['Float'];
  type: TypeOfInsuranceEndorsement;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};


/** **This field is deprecated! Use "DrpInsuranceEndorsement" instead** */
export type DrpEndorsement_AllocationRatiosArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementAllocationRatioSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
};

export type DrpEndorsementAggregateAllDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  classiiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classiiiPounds?: InputMaybe<Scalars['Boolean']>;
  classivGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classivPounds?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassivPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueAmount?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassivPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DrpEndorsementAggregateDTO = {
  avg?: InputMaybe<DrpEndorsementAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<DrpEndorsementAggregateAllDTO>;
  min?: InputMaybe<DrpEndorsementAggregateAllDTO>;
  sum?: InputMaybe<DrpEndorsementAggregateNumericDTO>;
};

export type DrpEndorsementAggregateNumericDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  classiiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classiiiPounds?: InputMaybe<Scalars['Boolean']>;
  classivGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classivPounds?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassivPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassivPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DrpEndorsementCreateDTO = {
  agentId?: InputMaybe<Scalars['String']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['String']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Float']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Float']>;
  coverageLevelPercent: Scalars['Float'];
  declaredButterfatTest?: InputMaybe<Scalars['Float']>;
  declaredCoveredMilkProduction: Scalars['Float'];
  declaredProteinTest?: InputMaybe<Scalars['Float']>;
  declaredShare: Scalars['Float'];
  expectedRevenueAmount: Scalars['String'];
  expectedRevenueGuarantee: Scalars['String'];
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode?: InputMaybe<Scalars['String']>;
  insurancePracticeId: Scalars['String'];
  isLong: Scalars['Boolean'];
  producerPremiumAmount: Scalars['Float'];
  protectionFactor: Scalars['Float'];
  reinsuranceYear: Scalars['Int'];
  rmaPracticeCode?: InputMaybe<Scalars['String']>;
  rmaStatus?: InputMaybe<TypeOfRmaStatus>;
  salesEffectiveDate: Scalars['String'];
  stateCode?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  subsidyAmount: Scalars['Float'];
  totalPremiumAmount: Scalars['Float'];
  typeCode?: InputMaybe<Scalars['String']>;
};

export type DrpEndorsementDistinctOnDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  classiiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classiiiPounds?: InputMaybe<Scalars['Boolean']>;
  classivGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classivPounds?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassivPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueAmount?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassivPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DrpEndorsementFilterDTO = {
  AND?: InputMaybe<Array<DrpEndorsementFilterDTO>>;
  Agent?: InputMaybe<InsuranceAgentFilterDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementFilterDTO>;
  DerivedDrpEndorsement?: InputMaybe<DerivedDrpEndorsementFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyFilterDTO>;
  InsuranceEndorsementAllocationRatios?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyFilterDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeFilterDTO>;
  InsuredYieldAndPrice?: InputMaybe<InsuredYieldAndPriceFilterDTO>;
  NOT?: InputMaybe<DrpEndorsementFilterDTO>;
  OR?: InputMaybe<Array<DrpEndorsementFilterDTO>>;
  Policy?: InputMaybe<InsurancePolicyFilterDTO>;
  Practice?: InputMaybe<InsurancePracticeFilterDTO>;
  RmaType?: InputMaybe<RmaTypeFilterDTO>;
  State?: InputMaybe<StateFilterDTO>;
  actualIndemnity?: InputMaybe<NullableNumberFilter>;
  actualPnl?: InputMaybe<NullableNumberFilter>;
  actualYield?: InputMaybe<NullableNumberFilter>;
  agentId?: InputMaybe<NullableStringFilter>;
  aipDrpPremiumKey?: InputMaybe<NullableStringFilter>;
  butterGrossProtection?: InputMaybe<NullableNumberFilter>;
  butterfatGrossProtection?: InputMaybe<NullableNumberFilter>;
  butterfatPounds?: InputMaybe<NullableNumberFilter>;
  cheeseGrossProtection?: InputMaybe<NullableNumberFilter>;
  classPriceWeightingFactor?: InputMaybe<NullableNumberFilter>;
  classiiiGrossProtection?: InputMaybe<NullableNumberFilter>;
  classiiiPounds?: InputMaybe<NullableNumberFilter>;
  classivGrossProtection?: InputMaybe<NullableNumberFilter>;
  classivPounds?: InputMaybe<NullableNumberFilter>;
  componentPriceWeightingFactor?: InputMaybe<NullableNumberFilter>;
  coverageLevelPercent?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentYield?: InputMaybe<NullableNumberFilter>;
  declaredButterfatTest?: InputMaybe<NullableNumberFilter>;
  declaredCoveredMilkProduction?: InputMaybe<NumberFilter>;
  declaredProteinTest?: InputMaybe<NullableNumberFilter>;
  declaredShare?: InputMaybe<NumberFilter>;
  effectiveCoveredMilkProduction?: InputMaybe<NullableNumberFilter>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<NullableNumberFilter>;
  endDate?: InputMaybe<StringFilter>;
  estimatedPl?: InputMaybe<NullableNumberFilter>;
  excessButterPounds?: InputMaybe<NullableNumberFilter>;
  excessCheesePounds?: InputMaybe<NullableNumberFilter>;
  excessProteinPoundsAfterCheese?: InputMaybe<NumberFilter>;
  expectedButterPrice?: InputMaybe<NullableNumberFilter>;
  expectedButterfatPrice?: InputMaybe<NullableNumberFilter>;
  expectedCheesePrice?: InputMaybe<NullableNumberFilter>;
  expectedClassiiiPrice?: InputMaybe<NullableNumberFilter>;
  expectedClassivPrice?: InputMaybe<NullableNumberFilter>;
  expectedNonfatSolidsPrice?: InputMaybe<NullableNumberFilter>;
  expectedOtherSolidsPrice?: InputMaybe<NullableNumberFilter>;
  expectedProteinPrice?: InputMaybe<NullableNumberFilter>;
  expectedRevenueAmount?: InputMaybe<StringFilter>;
  expectedRevenueGuarantee?: InputMaybe<StringFilter>;
  forecastedIndemnity?: InputMaybe<NullableNumberFilter>;
  forecastedPnl?: InputMaybe<NullableNumberFilter>;
  hedgeStrategyId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  indemnity?: InputMaybe<NullableNumberFilter>;
  indemnityAmount?: InputMaybe<NullableNumberFilter>;
  indemnityEffectButter?: InputMaybe<NullableNumberFilter>;
  indemnityEffectButterfat?: InputMaybe<NullableNumberFilter>;
  indemnityEffectCheese?: InputMaybe<NullableNumberFilter>;
  indemnityEffectClassiii?: InputMaybe<NullableNumberFilter>;
  indemnityEffectClassiv?: InputMaybe<NullableNumberFilter>;
  indemnityEffectExcessProtein?: InputMaybe<NumberFilter>;
  indemnityEffectNonfatSolids?: InputMaybe<NullableNumberFilter>;
  indemnityEffectOtherSolids?: InputMaybe<NullableNumberFilter>;
  indemnityEffectProtein?: InputMaybe<NullableNumberFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  insurancePolicyId?: InputMaybe<StringFilter>;
  insurancePracticeCode?: InputMaybe<StringFilter>;
  insurancePracticeId?: InputMaybe<StringFilter>;
  insuredButterPrice?: InputMaybe<NullableNumberFilter>;
  insuredButterfatPrice?: InputMaybe<NullableNumberFilter>;
  insuredCheesePrice?: InputMaybe<NullableNumberFilter>;
  insuredClassiiiPrice?: InputMaybe<NullableNumberFilter>;
  insuredClassivPrice?: InputMaybe<NullableNumberFilter>;
  insuredNonfatSolidsPrice?: InputMaybe<NullableNumberFilter>;
  insuredOtherSolidsPrice?: InputMaybe<NullableNumberFilter>;
  insuredProteinPrice?: InputMaybe<NullableNumberFilter>;
  insuredYield?: InputMaybe<NullableNumberFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  isLong?: InputMaybe<BooleanFilter>;
  isShort?: InputMaybe<BooleanFilter>;
  lastYield?: InputMaybe<NullableNumberFilter>;
  nonfatSolidsGrossProtection?: InputMaybe<NullableNumberFilter>;
  nonfatSolidsPounds?: InputMaybe<NullableNumberFilter>;
  otherPoundsGrossProtection?: InputMaybe<NullableNumberFilter>;
  otherSolidsPounds?: InputMaybe<NullableNumberFilter>;
  pnl?: InputMaybe<NullableNumberFilter>;
  policyId?: InputMaybe<StringFilter>;
  producerPremiumAmount?: InputMaybe<NumberFilter>;
  projectedRevenue?: InputMaybe<NullableNumberFilter>;
  protectionFactor?: InputMaybe<NumberFilter>;
  proteinGrossProtection?: InputMaybe<NullableNumberFilter>;
  proteinPounds?: InputMaybe<NullableNumberFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  revenueHedgeProductIds?: InputMaybe<StringArrayFilter>;
  rmaPracticeCode?: InputMaybe<StringFilter>;
  rmaStatus?: InputMaybe<NullableTypeOfRmaStatusFilter>;
  salesEffectiveDate?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<StringFilter>;
  stateCode?: InputMaybe<StringFilter>;
  stateId?: InputMaybe<StringFilter>;
  subsidyAmount?: InputMaybe<NumberFilter>;
  totalPremiumAmount?: InputMaybe<NumberFilter>;
  typeCode?: InputMaybe<NullableStringFilter>;
  typeCodeYear?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  yieldAdjustmentFactor?: InputMaybe<NullableNumberFilter>;
};

export type DrpEndorsementGroupByDTO = {
  Agent?: InputMaybe<InsuranceAgentGroupByDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyGroupByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyGroupByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeGroupByDTO>;
  InsuredYieldAndPrice?: InputMaybe<InsuredYieldAndPriceGroupByDTO>;
  Policy?: InputMaybe<InsurancePolicyGroupByDTO>;
  Practice?: InputMaybe<InsurancePracticeGroupByDTO>;
  RmaType?: InputMaybe<RmaTypeGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  classiiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classiiiPounds?: InputMaybe<Scalars['Boolean']>;
  classivGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classivPounds?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassivPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueAmount?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassivPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DrpEndorsementSortByDTO = {
  Agent?: InputMaybe<InsuranceAgentSortByDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementSortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategySortByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicySortByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeSortByDTO>;
  InsuredYieldAndPrice?: InputMaybe<InsuredYieldAndPriceSortByDTO>;
  Policy?: InputMaybe<InsurancePolicySortByDTO>;
  Practice?: InputMaybe<InsurancePracticeSortByDTO>;
  RmaType?: InputMaybe<RmaTypeSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  actualIndemnity?: InputMaybe<NullableSortByDirection>;
  actualPnl?: InputMaybe<NullableSortByDirection>;
  actualYield?: InputMaybe<NullableSortByDirection>;
  agentId?: InputMaybe<NullableSortByDirection>;
  aipDrpPremiumKey?: InputMaybe<NullableSortByDirection>;
  butterGrossProtection?: InputMaybe<NullableSortByDirection>;
  butterfatGrossProtection?: InputMaybe<NullableSortByDirection>;
  butterfatPounds?: InputMaybe<NullableSortByDirection>;
  cheeseGrossProtection?: InputMaybe<NullableSortByDirection>;
  classPriceWeightingFactor?: InputMaybe<NullableSortByDirection>;
  classiiiGrossProtection?: InputMaybe<NullableSortByDirection>;
  classiiiPounds?: InputMaybe<NullableSortByDirection>;
  classivGrossProtection?: InputMaybe<NullableSortByDirection>;
  classivPounds?: InputMaybe<NullableSortByDirection>;
  componentPriceWeightingFactor?: InputMaybe<NullableSortByDirection>;
  coverageLevelPercent?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  currentYield?: InputMaybe<NullableSortByDirection>;
  declaredButterfatTest?: InputMaybe<NullableSortByDirection>;
  declaredCoveredMilkProduction?: InputMaybe<SortByDirection>;
  declaredProteinTest?: InputMaybe<NullableSortByDirection>;
  declaredShare?: InputMaybe<SortByDirection>;
  effectiveCoveredMilkProduction?: InputMaybe<NullableSortByDirection>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<SortByDirection>;
  estimatedPl?: InputMaybe<NullableSortByDirection>;
  excessButterPounds?: InputMaybe<NullableSortByDirection>;
  excessCheesePounds?: InputMaybe<NullableSortByDirection>;
  excessProteinPoundsAfterCheese?: InputMaybe<SortByDirection>;
  expectedButterPrice?: InputMaybe<NullableSortByDirection>;
  expectedButterfatPrice?: InputMaybe<NullableSortByDirection>;
  expectedCheesePrice?: InputMaybe<NullableSortByDirection>;
  expectedClassiiiPrice?: InputMaybe<NullableSortByDirection>;
  expectedClassivPrice?: InputMaybe<NullableSortByDirection>;
  expectedNonfatSolidsPrice?: InputMaybe<NullableSortByDirection>;
  expectedOtherSolidsPrice?: InputMaybe<NullableSortByDirection>;
  expectedProteinPrice?: InputMaybe<NullableSortByDirection>;
  expectedRevenueAmount?: InputMaybe<SortByDirection>;
  expectedRevenueGuarantee?: InputMaybe<SortByDirection>;
  forecastedIndemnity?: InputMaybe<NullableSortByDirection>;
  forecastedPnl?: InputMaybe<NullableSortByDirection>;
  hedgeStrategyId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  indemnity?: InputMaybe<NullableSortByDirection>;
  indemnityAmount?: InputMaybe<NullableSortByDirection>;
  indemnityEffectButter?: InputMaybe<NullableSortByDirection>;
  indemnityEffectButterfat?: InputMaybe<NullableSortByDirection>;
  indemnityEffectCheese?: InputMaybe<NullableSortByDirection>;
  indemnityEffectClassiii?: InputMaybe<NullableSortByDirection>;
  indemnityEffectClassiv?: InputMaybe<NullableSortByDirection>;
  indemnityEffectExcessProtein?: InputMaybe<SortByDirection>;
  indemnityEffectNonfatSolids?: InputMaybe<NullableSortByDirection>;
  indemnityEffectOtherSolids?: InputMaybe<NullableSortByDirection>;
  indemnityEffectProtein?: InputMaybe<NullableSortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  insurancePolicyId?: InputMaybe<SortByDirection>;
  insurancePracticeCode?: InputMaybe<SortByDirection>;
  insurancePracticeId?: InputMaybe<SortByDirection>;
  insuredButterPrice?: InputMaybe<NullableSortByDirection>;
  insuredButterfatPrice?: InputMaybe<NullableSortByDirection>;
  insuredCheesePrice?: InputMaybe<NullableSortByDirection>;
  insuredClassiiiPrice?: InputMaybe<NullableSortByDirection>;
  insuredClassivPrice?: InputMaybe<NullableSortByDirection>;
  insuredNonfatSolidsPrice?: InputMaybe<NullableSortByDirection>;
  insuredOtherSolidsPrice?: InputMaybe<NullableSortByDirection>;
  insuredProteinPrice?: InputMaybe<NullableSortByDirection>;
  insuredYield?: InputMaybe<NullableSortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  isLong?: InputMaybe<SortByDirection>;
  isShort?: InputMaybe<SortByDirection>;
  lastYield?: InputMaybe<NullableSortByDirection>;
  nonfatSolidsGrossProtection?: InputMaybe<NullableSortByDirection>;
  nonfatSolidsPounds?: InputMaybe<NullableSortByDirection>;
  otherPoundsGrossProtection?: InputMaybe<NullableSortByDirection>;
  otherSolidsPounds?: InputMaybe<NullableSortByDirection>;
  pnl?: InputMaybe<NullableSortByDirection>;
  policyId?: InputMaybe<SortByDirection>;
  producerPremiumAmount?: InputMaybe<SortByDirection>;
  projectedRevenue?: InputMaybe<NullableSortByDirection>;
  protectionFactor?: InputMaybe<SortByDirection>;
  proteinGrossProtection?: InputMaybe<NullableSortByDirection>;
  proteinPounds?: InputMaybe<NullableSortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  rmaPracticeCode?: InputMaybe<SortByDirection>;
  rmaStatus?: InputMaybe<NullableSortByDirection>;
  salesEffectiveDate?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  stateCode?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  subsidyAmount?: InputMaybe<SortByDirection>;
  totalPremiumAmount?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  typeCode?: InputMaybe<NullableSortByDirection>;
  typeCodeYear?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  yieldAdjustmentFactor?: InputMaybe<NullableSortByDirection>;
};

export type DrpEndorsementUpdateDTO = {
  agentId?: InputMaybe<Scalars['String']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['String']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Float']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Float']>;
  coverageLevelPercent?: InputMaybe<Scalars['Float']>;
  declaredButterfatTest?: InputMaybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Float']>;
  declaredProteinTest?: InputMaybe<Scalars['Float']>;
  declaredShare?: InputMaybe<Scalars['Float']>;
  expectedRevenueAmount?: InputMaybe<Scalars['String']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['String']>;
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insurancePolicyId?: InputMaybe<Scalars['String']>;
  insurancePracticeCode?: InputMaybe<Scalars['String']>;
  insurancePracticeId?: InputMaybe<Scalars['String']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount?: InputMaybe<Scalars['Float']>;
  protectionFactor?: InputMaybe<Scalars['Float']>;
  reinsuranceYear?: InputMaybe<Scalars['Int']>;
  rmaPracticeCode?: InputMaybe<Scalars['String']>;
  rmaStatus?: InputMaybe<TypeOfRmaStatus>;
  salesEffectiveDate?: InputMaybe<Scalars['String']>;
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount?: InputMaybe<Scalars['Float']>;
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount?: InputMaybe<Scalars['Float']>;
  typeCode?: InputMaybe<Scalars['String']>;
};

export type DrpInsuranceEndorsement = IInsuranceEndorsement & {
  __typename?: 'DrpInsuranceEndorsement';
  AllocationRatios: Array<InsuranceEndorsementAllocationRatio>;
  DerivedEndorsement?: Maybe<DerivedDrpEndorsement>;
  HedgeStrategy?: Maybe<HedgeStrategy>;
  InsuranceAgent?: Maybe<InsuranceAgent>;
  InsurancePolicy: InsurancePolicy;
  InsurancePractice?: Maybe<InsurancePractice>;
  InsuredYieldAndPrice?: Maybe<InsuredYieldAndPrice>;
  RevenueHedgeProducts: Array<Product>;
  RmaType?: Maybe<RmaType>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipDrpPremiumKey?: Maybe<Scalars['String']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction: Scalars['Float'];
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare: Scalars['Float'];
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  /** The revenue that the endorsement guarantees. */
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  /** The ending date (inclusive) of the endorsement's coverage period */
  endDate: Scalars['String'];
  /** @deprecated Use "forecastedPnl" instead */
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  /** @deprecated This field is no longer provided */
  excessProteinPoundsAfterCheese: Scalars['Float'];
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueAmount: Scalars['String'];
  expectedRevenueGuarantee: Scalars['String'];
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** This will contain either the actual indemnity value if provided or the forecasted indemnity value otherwise. */
  indemnity?: Maybe<Scalars['Float']>;
  /** @deprecated Use "forecastedIndemnity" instead */
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  /** @deprecated This field is no longer provided */
  indemnityEffectExcessProtein: Scalars['Float'];
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  inputSource: InputSource;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode: Scalars['String'];
  insurancePracticeId: Scalars['String'];
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong: Scalars['Boolean'];
  isShort: Scalars['Boolean'];
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  /** This will contain either the actual pnl value if provided or the forecasted pnl value otherwise. */
  pnl?: Maybe<Scalars['Float']>;
  policyId: Scalars['String'];
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount: Scalars['Float'];
  /** The revenue projected based on the current prices. */
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor: Scalars['Float'];
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear: Scalars['Int'];
  revenueHedgeProductIds: Array<Scalars['String']>;
  /** @deprecated Use "insurancePracticeCode" instead */
  rmaPracticeCode: Scalars['String'];
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  salesEffectiveDate: Scalars['String'];
  /** The starting date (inclusive) of the endorsement's coverage period */
  startDate: Scalars['String'];
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode: Scalars['String'];
  stateId: Scalars['String'];
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount: Scalars['Float'];
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount: Scalars['Float'];
  type: TypeOfInsuranceEndorsement;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};


export type DrpInsuranceEndorsement_AllocationRatiosArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementAllocationRatioSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
};

export type DrpInsuranceEndorsementAggregateAllDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  classiiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classiiiPounds?: InputMaybe<Scalars['Boolean']>;
  classivGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classivPounds?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassivPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueAmount?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassivPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DrpInsuranceEndorsementAggregateDTO = {
  avg?: InputMaybe<DrpInsuranceEndorsementAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<DrpInsuranceEndorsementAggregateAllDTO>;
  min?: InputMaybe<DrpInsuranceEndorsementAggregateAllDTO>;
  sum?: InputMaybe<DrpInsuranceEndorsementAggregateNumericDTO>;
};

export type DrpInsuranceEndorsementAggregateNumericDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  classiiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classiiiPounds?: InputMaybe<Scalars['Boolean']>;
  classivGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classivPounds?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassivPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassivPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DrpInsuranceEndorsementCreateDTO = {
  agentId?: InputMaybe<Scalars['String']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['String']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Float']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Float']>;
  coverageLevelPercent: Scalars['Float'];
  declaredButterfatTest?: InputMaybe<Scalars['Float']>;
  declaredCoveredMilkProduction: Scalars['Float'];
  declaredProteinTest?: InputMaybe<Scalars['Float']>;
  declaredShare: Scalars['Float'];
  expectedRevenueAmount: Scalars['String'];
  expectedRevenueGuarantee: Scalars['String'];
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode?: InputMaybe<Scalars['String']>;
  insurancePracticeId: Scalars['String'];
  isLong: Scalars['Boolean'];
  producerPremiumAmount: Scalars['Float'];
  protectionFactor: Scalars['Float'];
  reinsuranceYear: Scalars['Int'];
  rmaPracticeCode?: InputMaybe<Scalars['String']>;
  rmaStatus?: InputMaybe<TypeOfRmaStatus>;
  salesEffectiveDate: Scalars['String'];
  stateCode?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  subsidyAmount: Scalars['Float'];
  totalPremiumAmount: Scalars['Float'];
  typeCode?: InputMaybe<Scalars['String']>;
};

export type DrpInsuranceEndorsementDistinctOnDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  classiiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classiiiPounds?: InputMaybe<Scalars['Boolean']>;
  classivGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classivPounds?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassivPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueAmount?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassivPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DrpInsuranceEndorsementFilterDTO = {
  AND?: InputMaybe<Array<DrpInsuranceEndorsementFilterDTO>>;
  Agent?: InputMaybe<InsuranceAgentFilterDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementFilterDTO>;
  DerivedDrpEndorsement?: InputMaybe<DerivedDrpEndorsementFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyFilterDTO>;
  InsuranceEndorsementAllocationRatios?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyFilterDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeFilterDTO>;
  InsuredYieldAndPrice?: InputMaybe<InsuredYieldAndPriceFilterDTO>;
  NOT?: InputMaybe<DrpInsuranceEndorsementFilterDTO>;
  OR?: InputMaybe<Array<DrpInsuranceEndorsementFilterDTO>>;
  Policy?: InputMaybe<InsurancePolicyFilterDTO>;
  Practice?: InputMaybe<InsurancePracticeFilterDTO>;
  RmaType?: InputMaybe<RmaTypeFilterDTO>;
  State?: InputMaybe<StateFilterDTO>;
  actualIndemnity?: InputMaybe<NullableNumberFilter>;
  actualPnl?: InputMaybe<NullableNumberFilter>;
  actualYield?: InputMaybe<NullableNumberFilter>;
  agentId?: InputMaybe<NullableStringFilter>;
  aipDrpPremiumKey?: InputMaybe<NullableStringFilter>;
  butterGrossProtection?: InputMaybe<NullableNumberFilter>;
  butterfatGrossProtection?: InputMaybe<NullableNumberFilter>;
  butterfatPounds?: InputMaybe<NullableNumberFilter>;
  cheeseGrossProtection?: InputMaybe<NullableNumberFilter>;
  classPriceWeightingFactor?: InputMaybe<NullableNumberFilter>;
  classiiiGrossProtection?: InputMaybe<NullableNumberFilter>;
  classiiiPounds?: InputMaybe<NullableNumberFilter>;
  classivGrossProtection?: InputMaybe<NullableNumberFilter>;
  classivPounds?: InputMaybe<NullableNumberFilter>;
  componentPriceWeightingFactor?: InputMaybe<NullableNumberFilter>;
  coverageLevelPercent?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentYield?: InputMaybe<NullableNumberFilter>;
  declaredButterfatTest?: InputMaybe<NullableNumberFilter>;
  declaredCoveredMilkProduction?: InputMaybe<NumberFilter>;
  declaredProteinTest?: InputMaybe<NullableNumberFilter>;
  declaredShare?: InputMaybe<NumberFilter>;
  effectiveCoveredMilkProduction?: InputMaybe<NullableNumberFilter>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<NullableNumberFilter>;
  endDate?: InputMaybe<StringFilter>;
  estimatedPl?: InputMaybe<NullableNumberFilter>;
  excessButterPounds?: InputMaybe<NullableNumberFilter>;
  excessCheesePounds?: InputMaybe<NullableNumberFilter>;
  excessProteinPoundsAfterCheese?: InputMaybe<NumberFilter>;
  expectedButterPrice?: InputMaybe<NullableNumberFilter>;
  expectedButterfatPrice?: InputMaybe<NullableNumberFilter>;
  expectedCheesePrice?: InputMaybe<NullableNumberFilter>;
  expectedClassiiiPrice?: InputMaybe<NullableNumberFilter>;
  expectedClassivPrice?: InputMaybe<NullableNumberFilter>;
  expectedNonfatSolidsPrice?: InputMaybe<NullableNumberFilter>;
  expectedOtherSolidsPrice?: InputMaybe<NullableNumberFilter>;
  expectedProteinPrice?: InputMaybe<NullableNumberFilter>;
  expectedRevenueAmount?: InputMaybe<StringFilter>;
  expectedRevenueGuarantee?: InputMaybe<StringFilter>;
  forecastedIndemnity?: InputMaybe<NullableNumberFilter>;
  forecastedPnl?: InputMaybe<NullableNumberFilter>;
  hedgeStrategyId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  indemnity?: InputMaybe<NullableNumberFilter>;
  indemnityAmount?: InputMaybe<NullableNumberFilter>;
  indemnityEffectButter?: InputMaybe<NullableNumberFilter>;
  indemnityEffectButterfat?: InputMaybe<NullableNumberFilter>;
  indemnityEffectCheese?: InputMaybe<NullableNumberFilter>;
  indemnityEffectClassiii?: InputMaybe<NullableNumberFilter>;
  indemnityEffectClassiv?: InputMaybe<NullableNumberFilter>;
  indemnityEffectExcessProtein?: InputMaybe<NumberFilter>;
  indemnityEffectNonfatSolids?: InputMaybe<NullableNumberFilter>;
  indemnityEffectOtherSolids?: InputMaybe<NullableNumberFilter>;
  indemnityEffectProtein?: InputMaybe<NullableNumberFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  insurancePolicyId?: InputMaybe<StringFilter>;
  insurancePracticeCode?: InputMaybe<StringFilter>;
  insurancePracticeId?: InputMaybe<StringFilter>;
  insuredButterPrice?: InputMaybe<NullableNumberFilter>;
  insuredButterfatPrice?: InputMaybe<NullableNumberFilter>;
  insuredCheesePrice?: InputMaybe<NullableNumberFilter>;
  insuredClassiiiPrice?: InputMaybe<NullableNumberFilter>;
  insuredClassivPrice?: InputMaybe<NullableNumberFilter>;
  insuredNonfatSolidsPrice?: InputMaybe<NullableNumberFilter>;
  insuredOtherSolidsPrice?: InputMaybe<NullableNumberFilter>;
  insuredProteinPrice?: InputMaybe<NullableNumberFilter>;
  insuredYield?: InputMaybe<NullableNumberFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  isLong?: InputMaybe<BooleanFilter>;
  isShort?: InputMaybe<BooleanFilter>;
  lastYield?: InputMaybe<NullableNumberFilter>;
  nonfatSolidsGrossProtection?: InputMaybe<NullableNumberFilter>;
  nonfatSolidsPounds?: InputMaybe<NullableNumberFilter>;
  otherPoundsGrossProtection?: InputMaybe<NullableNumberFilter>;
  otherSolidsPounds?: InputMaybe<NullableNumberFilter>;
  pnl?: InputMaybe<NullableNumberFilter>;
  policyId?: InputMaybe<StringFilter>;
  producerPremiumAmount?: InputMaybe<NumberFilter>;
  projectedRevenue?: InputMaybe<NullableNumberFilter>;
  protectionFactor?: InputMaybe<NumberFilter>;
  proteinGrossProtection?: InputMaybe<NullableNumberFilter>;
  proteinPounds?: InputMaybe<NullableNumberFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  revenueHedgeProductIds?: InputMaybe<StringArrayFilter>;
  rmaPracticeCode?: InputMaybe<StringFilter>;
  rmaStatus?: InputMaybe<NullableTypeOfRmaStatusFilter>;
  salesEffectiveDate?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<StringFilter>;
  stateCode?: InputMaybe<StringFilter>;
  stateId?: InputMaybe<StringFilter>;
  subsidyAmount?: InputMaybe<NumberFilter>;
  totalPremiumAmount?: InputMaybe<NumberFilter>;
  typeCode?: InputMaybe<NullableStringFilter>;
  typeCodeYear?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  yieldAdjustmentFactor?: InputMaybe<NullableNumberFilter>;
};

export type DrpInsuranceEndorsementGroupByDTO = {
  Agent?: InputMaybe<InsuranceAgentGroupByDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyGroupByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyGroupByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeGroupByDTO>;
  InsuredYieldAndPrice?: InputMaybe<InsuredYieldAndPriceGroupByDTO>;
  Policy?: InputMaybe<InsurancePolicyGroupByDTO>;
  Practice?: InputMaybe<InsurancePracticeGroupByDTO>;
  RmaType?: InputMaybe<RmaTypeGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  classiiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classiiiPounds?: InputMaybe<Scalars['Boolean']>;
  classivGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classivPounds?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassivPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueAmount?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassivPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type DrpInsuranceEndorsementSortByDTO = {
  Agent?: InputMaybe<InsuranceAgentSortByDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementSortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategySortByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicySortByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeSortByDTO>;
  InsuredYieldAndPrice?: InputMaybe<InsuredYieldAndPriceSortByDTO>;
  Policy?: InputMaybe<InsurancePolicySortByDTO>;
  Practice?: InputMaybe<InsurancePracticeSortByDTO>;
  RmaType?: InputMaybe<RmaTypeSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  actualIndemnity?: InputMaybe<NullableSortByDirection>;
  actualPnl?: InputMaybe<NullableSortByDirection>;
  actualYield?: InputMaybe<NullableSortByDirection>;
  agentId?: InputMaybe<NullableSortByDirection>;
  aipDrpPremiumKey?: InputMaybe<NullableSortByDirection>;
  butterGrossProtection?: InputMaybe<NullableSortByDirection>;
  butterfatGrossProtection?: InputMaybe<NullableSortByDirection>;
  butterfatPounds?: InputMaybe<NullableSortByDirection>;
  cheeseGrossProtection?: InputMaybe<NullableSortByDirection>;
  classPriceWeightingFactor?: InputMaybe<NullableSortByDirection>;
  classiiiGrossProtection?: InputMaybe<NullableSortByDirection>;
  classiiiPounds?: InputMaybe<NullableSortByDirection>;
  classivGrossProtection?: InputMaybe<NullableSortByDirection>;
  classivPounds?: InputMaybe<NullableSortByDirection>;
  componentPriceWeightingFactor?: InputMaybe<NullableSortByDirection>;
  coverageLevelPercent?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  currentYield?: InputMaybe<NullableSortByDirection>;
  declaredButterfatTest?: InputMaybe<NullableSortByDirection>;
  declaredCoveredMilkProduction?: InputMaybe<SortByDirection>;
  declaredProteinTest?: InputMaybe<NullableSortByDirection>;
  declaredShare?: InputMaybe<SortByDirection>;
  effectiveCoveredMilkProduction?: InputMaybe<NullableSortByDirection>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<SortByDirection>;
  estimatedPl?: InputMaybe<NullableSortByDirection>;
  excessButterPounds?: InputMaybe<NullableSortByDirection>;
  excessCheesePounds?: InputMaybe<NullableSortByDirection>;
  excessProteinPoundsAfterCheese?: InputMaybe<SortByDirection>;
  expectedButterPrice?: InputMaybe<NullableSortByDirection>;
  expectedButterfatPrice?: InputMaybe<NullableSortByDirection>;
  expectedCheesePrice?: InputMaybe<NullableSortByDirection>;
  expectedClassiiiPrice?: InputMaybe<NullableSortByDirection>;
  expectedClassivPrice?: InputMaybe<NullableSortByDirection>;
  expectedNonfatSolidsPrice?: InputMaybe<NullableSortByDirection>;
  expectedOtherSolidsPrice?: InputMaybe<NullableSortByDirection>;
  expectedProteinPrice?: InputMaybe<NullableSortByDirection>;
  expectedRevenueAmount?: InputMaybe<SortByDirection>;
  expectedRevenueGuarantee?: InputMaybe<SortByDirection>;
  forecastedIndemnity?: InputMaybe<NullableSortByDirection>;
  forecastedPnl?: InputMaybe<NullableSortByDirection>;
  hedgeStrategyId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  indemnity?: InputMaybe<NullableSortByDirection>;
  indemnityAmount?: InputMaybe<NullableSortByDirection>;
  indemnityEffectButter?: InputMaybe<NullableSortByDirection>;
  indemnityEffectButterfat?: InputMaybe<NullableSortByDirection>;
  indemnityEffectCheese?: InputMaybe<NullableSortByDirection>;
  indemnityEffectClassiii?: InputMaybe<NullableSortByDirection>;
  indemnityEffectClassiv?: InputMaybe<NullableSortByDirection>;
  indemnityEffectExcessProtein?: InputMaybe<SortByDirection>;
  indemnityEffectNonfatSolids?: InputMaybe<NullableSortByDirection>;
  indemnityEffectOtherSolids?: InputMaybe<NullableSortByDirection>;
  indemnityEffectProtein?: InputMaybe<NullableSortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  insurancePolicyId?: InputMaybe<SortByDirection>;
  insurancePracticeCode?: InputMaybe<SortByDirection>;
  insurancePracticeId?: InputMaybe<SortByDirection>;
  insuredButterPrice?: InputMaybe<NullableSortByDirection>;
  insuredButterfatPrice?: InputMaybe<NullableSortByDirection>;
  insuredCheesePrice?: InputMaybe<NullableSortByDirection>;
  insuredClassiiiPrice?: InputMaybe<NullableSortByDirection>;
  insuredClassivPrice?: InputMaybe<NullableSortByDirection>;
  insuredNonfatSolidsPrice?: InputMaybe<NullableSortByDirection>;
  insuredOtherSolidsPrice?: InputMaybe<NullableSortByDirection>;
  insuredProteinPrice?: InputMaybe<NullableSortByDirection>;
  insuredYield?: InputMaybe<NullableSortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  isLong?: InputMaybe<SortByDirection>;
  isShort?: InputMaybe<SortByDirection>;
  lastYield?: InputMaybe<NullableSortByDirection>;
  nonfatSolidsGrossProtection?: InputMaybe<NullableSortByDirection>;
  nonfatSolidsPounds?: InputMaybe<NullableSortByDirection>;
  otherPoundsGrossProtection?: InputMaybe<NullableSortByDirection>;
  otherSolidsPounds?: InputMaybe<NullableSortByDirection>;
  pnl?: InputMaybe<NullableSortByDirection>;
  policyId?: InputMaybe<SortByDirection>;
  producerPremiumAmount?: InputMaybe<SortByDirection>;
  projectedRevenue?: InputMaybe<NullableSortByDirection>;
  protectionFactor?: InputMaybe<SortByDirection>;
  proteinGrossProtection?: InputMaybe<NullableSortByDirection>;
  proteinPounds?: InputMaybe<NullableSortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  rmaPracticeCode?: InputMaybe<SortByDirection>;
  rmaStatus?: InputMaybe<NullableSortByDirection>;
  salesEffectiveDate?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  stateCode?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  subsidyAmount?: InputMaybe<SortByDirection>;
  totalPremiumAmount?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  typeCode?: InputMaybe<NullableSortByDirection>;
  typeCodeYear?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  yieldAdjustmentFactor?: InputMaybe<NullableSortByDirection>;
};

export type DrpInsuranceEndorsementUpdateDTO = {
  agentId?: InputMaybe<Scalars['String']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['String']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Float']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Float']>;
  coverageLevelPercent?: InputMaybe<Scalars['Float']>;
  declaredButterfatTest?: InputMaybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Float']>;
  declaredProteinTest?: InputMaybe<Scalars['Float']>;
  declaredShare?: InputMaybe<Scalars['Float']>;
  expectedRevenueAmount?: InputMaybe<Scalars['String']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['String']>;
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insurancePolicyId?: InputMaybe<Scalars['String']>;
  insurancePracticeCode?: InputMaybe<Scalars['String']>;
  insurancePracticeId?: InputMaybe<Scalars['String']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount?: InputMaybe<Scalars['Float']>;
  protectionFactor?: InputMaybe<Scalars['Float']>;
  reinsuranceYear?: InputMaybe<Scalars['Int']>;
  rmaPracticeCode?: InputMaybe<Scalars['String']>;
  rmaStatus?: InputMaybe<TypeOfRmaStatus>;
  salesEffectiveDate?: InputMaybe<Scalars['String']>;
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount?: InputMaybe<Scalars['Float']>;
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount?: InputMaybe<Scalars['Float']>;
  typeCode?: InputMaybe<Scalars['String']>;
};

export type DrpNewDailyPricesSubscription = INotificationSubscription & {
  __typename?: 'DrpNewDailyPricesSubscription';
  Notification: Array<Notification>;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  deviceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: TypeOfSubscription;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type DrpNewDailyPricesSubscription_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type DrpNewDailyPricesSubscriptionCreateDTO = {
  name?: InputMaybe<Scalars['String']>;
};

export type DrpNewDailyPricesSubscriptionDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deviceId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type DrpNewDailyPricesSubscriptionFilterDTO = {
  AND?: InputMaybe<Array<DrpNewDailyPricesSubscriptionFilterDTO>>;
  AsNotificationSubscription?: InputMaybe<NotificationSubscriptionFilterDTO>;
  NOT?: InputMaybe<DrpNewDailyPricesSubscriptionFilterDTO>;
  Notifications?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<DrpNewDailyPricesSubscriptionFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export type DrpNewDailyPricesSubscriptionSortByDTO = {
  AsNotificationSubscription?: InputMaybe<NotificationSubscriptionSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  deviceId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type DrpNewDailyPricesSubscriptionUpdateDTO = {
  name?: InputMaybe<Scalars['String']>;
};

export type DrpSubscription = INotificationSubscription & {
  __typename?: 'DrpSubscription';
  Notification: Array<Notification>;
  User?: Maybe<User>;
  butterfat: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  deviceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  protein: Scalars['Float'];
  stateCode: Scalars['String'];
  type: TypeOfSubscription;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type DrpSubscription_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type DrpSubscriptionCreateDTO = {
  butterfat: Scalars['Float'];
  name?: InputMaybe<Scalars['String']>;
  protein: Scalars['Float'];
  stateCode: Scalars['String'];
};

export type DrpSubscriptionDistinctOnDTO = {
  butterfat?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deviceId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  protein?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type DrpSubscriptionFilterDTO = {
  AND?: InputMaybe<Array<DrpSubscriptionFilterDTO>>;
  AsNotificationSubscription?: InputMaybe<NotificationSubscriptionFilterDTO>;
  NOT?: InputMaybe<DrpSubscriptionFilterDTO>;
  Notifications?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<DrpSubscriptionFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  butterfat?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<NullableStringFilter>;
  protein?: InputMaybe<NumberFilter>;
  stateCode?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export type DrpSubscriptionSortByDTO = {
  AsNotificationSubscription?: InputMaybe<NotificationSubscriptionSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  butterfat?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deviceId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  protein?: InputMaybe<SortByDirection>;
  stateCode?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type DrpSubscriptionUpdateDTO = {
  butterfat?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  protein?: InputMaybe<Scalars['Float']>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type EmailNotificationSetting = INotificationSetting & {
  __typename?: 'EmailNotificationSetting';
  Notification: Array<Notification>;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: TypeOfNotification;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type EmailNotificationSetting_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type EmailNotificationSettingCreateDTO = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type EmailNotificationSettingDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type EmailNotificationSettingFilterDTO = {
  AND?: InputMaybe<Array<EmailNotificationSettingFilterDTO>>;
  AsNotificationSetting?: InputMaybe<NotificationSettingFilterDTO>;
  NOT?: InputMaybe<EmailNotificationSettingFilterDTO>;
  Notifications?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<EmailNotificationSettingFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export type EmailNotificationSettingSortByDTO = {
  AsNotificationSetting?: InputMaybe<NotificationSettingSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  email?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type EmailNotificationSettingUpdateDTO = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Entity = IEntity & {
  __typename?: 'Entity';
  ActiveProducts: Array<Product>;
  AgreedToOtcContracts: Array<OtcContract>;
  BrokerageAccounts: Array<BrokerageAccount>;
  CreatedByUser?: Maybe<User>;
  CurrentUserPermissions: UserPermissionsForTarget;
  EntityUsers: Array<EntityUser>;
  ExposureRatios: Array<EntityExposureRatio>;
  FeedIngredientConsumedAndPurchasedVolumes: Array<FeedIngredientConsumedAndPurchasedVolume>;
  ForwardContracts: Array<ForwardContractAccount>;
  GroupEntries: Array<EntityGroupEntry>;
  Groups: Array<EntityGroup>;
  InitiatedOtcContracts: Array<OtcContract>;
  LastUpdatedByUser?: Maybe<User>;
  Members: Array<EntityMember>;
  Notes: Array<EntityNote>;
  OwnedAccounts: Array<IAccount>;
  OwnedOtcContracts: Array<OtcContract>;
  PhysicalSales: Array<PhysicalSale>;
  createdAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  identificationNumber: Scalars['Int'];
  isVgs: Scalars['Boolean'];
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type: TypeOfEntity;
  updatedAt: Scalars['DateTime'];
};


export type Entity_AgreedToOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type Entity_BrokerageAccountsArgs = {
  orderBy?: InputMaybe<BrokerageAccountSortByDTO>;
  where?: InputMaybe<BrokerageAccountFilterDTO>;
};


export type Entity_EntityUsersArgs = {
  orderBy?: InputMaybe<EntityUserSortByDTO>;
  where?: InputMaybe<EntityUserFilterDTO>;
};


export type Entity_ExposureRatiosArgs = {
  orderBy?: InputMaybe<EntityExposureRatioSortByDTO>;
  where?: InputMaybe<EntityExposureRatioFilterDTO>;
};


export type Entity_FeedIngredientConsumedAndPurchasedVolumesArgs = {
  orderBy?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeSortByDTO>;
  where?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
};


export type Entity_ForwardContractsArgs = {
  orderBy?: InputMaybe<ForwardContractAccountSortByDTO>;
  where?: InputMaybe<ForwardContractAccountFilterDTO>;
};


export type Entity_GroupEntriesArgs = {
  orderBy?: InputMaybe<EntityGroupEntrySortByDTO>;
  where?: InputMaybe<EntityGroupEntryFilterDTO>;
};


export type Entity_InitiatedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type Entity_MembersArgs = {
  orderBy?: InputMaybe<EntityMemberSortByDTO>;
  where?: InputMaybe<EntityMemberFilterDTO>;
};


export type Entity_NotesArgs = {
  orderBy?: InputMaybe<EntityNoteSortByDTO>;
  where?: InputMaybe<EntityNoteFilterDTO>;
};


export type Entity_OwnedAccountsArgs = {
  orderBy?: InputMaybe<AccountSortByDTO>;
  where?: InputMaybe<AccountFilterDTO>;
};


export type Entity_OwnedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type Entity_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};

export type EntityActiveProductFilterDTO = {
  AND?: InputMaybe<Array<EntityActiveProductFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<EntityActiveProductFilterDTO>;
  OR?: InputMaybe<Array<EntityActiveProductFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
};

export type EntityAllocatedExposureRatio = {
  __typename?: 'EntityAllocatedExposureRatio';
  Entity: IEntity;
  Product: Product;
  brokerageVolumeCapped?: Maybe<Scalars['Float']>;
  brokerageVolumeHedged?: Maybe<Scalars['Float']>;
  date: Scalars['String'];
  drpVolumeCapped?: Maybe<Scalars['Float']>;
  drpVolumeHedged?: Maybe<Scalars['Float']>;
  entityId: Scalars['String'];
  exposure?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  inventoryExposure?: Maybe<Scalars['Float']>;
  isNaturallyLong?: Maybe<Scalars['Boolean']>;
  isNaturallyShort?: Maybe<Scalars['Boolean']>;
  lgmVolumeCapped?: Maybe<Scalars['Float']>;
  lgmVolumeHedged?: Maybe<Scalars['Float']>;
  lrpVolumeCapped?: Maybe<Scalars['Float']>;
  lrpVolumeHedged?: Maybe<Scalars['Float']>;
  /** The entity's net exposure due to consumption and production of a given product. If naturally long, this will be production exposure minus consumption exposure. If naturally short, thils will be consumption exposure minus production exposure. */
  netProductionExposure?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeCapped?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeHedged?: Maybe<Scalars['Float']>;
  percentLgmVolumeCapped?: Maybe<Scalars['Float']>;
  percentLgmVolumeHedged?: Maybe<Scalars['Float']>;
  percentLrpVolumeCapped?: Maybe<Scalars['Float']>;
  percentLrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeCapped?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeHedged?: Maybe<Scalars['Float']>;
  physicalVolumeCapped?: Maybe<Scalars['Float']>;
  physicalVolumeHedged?: Maybe<Scalars['Float']>;
  productId: Scalars['String'];
  /**
   * The entity's net exposure due to consumption and production of a given product. If naturally long, this will be production exposure minus consumption exposure. If naturally short, thils will be consumption exposure minus production exposure.
   * @deprecated Use "netProductionExposure" instead
   */
  productionExposure?: Maybe<Scalars['Float']>;
  totalPercentOpen?: Maybe<Scalars['Float']>;
  totalPercentVolumeCapped?: Maybe<Scalars['Float']>;
  totalPercentVolumeHedged?: Maybe<Scalars['Float']>;
  totalVolumeCapped?: Maybe<Scalars['Float']>;
  totalVolumeHedged?: Maybe<Scalars['Float']>;
};

export type EntityAllocatedExposureRatioAggregateAllDTO = {
  brokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  brokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  drpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  drpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  netProductionExposure?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  totalPercentOpen?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  totalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
};

export type EntityAllocatedExposureRatioAggregateDTO = {
  avg?: InputMaybe<EntityAllocatedExposureRatioAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<EntityAllocatedExposureRatioAggregateAllDTO>;
  min?: InputMaybe<EntityAllocatedExposureRatioAggregateAllDTO>;
  sum?: InputMaybe<EntityAllocatedExposureRatioAggregateNumericDTO>;
};

export type EntityAllocatedExposureRatioAggregateNumericDTO = {
  brokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  brokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  drpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  drpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  netProductionExposure?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  totalPercentOpen?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  totalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
};

export type EntityAllocatedExposureRatioByOwner = {
  __typename?: 'EntityAllocatedExposureRatioByOwner';
  Entity: IEntity;
  Product: Product;
  brokerageVolumeCapped?: Maybe<Scalars['Float']>;
  brokerageVolumeHedged?: Maybe<Scalars['Float']>;
  date: Scalars['String'];
  drpVolumeCapped?: Maybe<Scalars['Float']>;
  drpVolumeHedged?: Maybe<Scalars['Float']>;
  entityId: Scalars['String'];
  exposure?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  inventoryExposure?: Maybe<Scalars['Float']>;
  isNaturallyLong?: Maybe<Scalars['Boolean']>;
  isNaturallyShort?: Maybe<Scalars['Boolean']>;
  lgmVolumeCapped?: Maybe<Scalars['Float']>;
  lgmVolumeHedged?: Maybe<Scalars['Float']>;
  lrpVolumeCapped?: Maybe<Scalars['Float']>;
  lrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeCapped?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeHedged?: Maybe<Scalars['Float']>;
  percentLgmVolumeCapped?: Maybe<Scalars['Float']>;
  percentLgmVolumeHedged?: Maybe<Scalars['Float']>;
  percentLrpVolumeCapped?: Maybe<Scalars['Float']>;
  percentLrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeCapped?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeHedged?: Maybe<Scalars['Float']>;
  physicalVolumeCapped?: Maybe<Scalars['Float']>;
  physicalVolumeHedged?: Maybe<Scalars['Float']>;
  productId: Scalars['String'];
  productionExposure?: Maybe<Scalars['Float']>;
  totalPercentOpen?: Maybe<Scalars['Float']>;
  totalPercentVolumeCapped?: Maybe<Scalars['Float']>;
  totalPercentVolumeHedged?: Maybe<Scalars['Float']>;
  totalVolumeCapped?: Maybe<Scalars['Float']>;
  totalVolumeHedged?: Maybe<Scalars['Float']>;
};

export type EntityAllocatedExposureRatioByOwnerAggregateAllDTO = {
  brokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  brokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  drpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  drpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  totalPercentOpen?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  totalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
};

export type EntityAllocatedExposureRatioByOwnerAggregateDTO = {
  avg?: InputMaybe<EntityAllocatedExposureRatioByOwnerAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<EntityAllocatedExposureRatioByOwnerAggregateAllDTO>;
  min?: InputMaybe<EntityAllocatedExposureRatioByOwnerAggregateAllDTO>;
  sum?: InputMaybe<EntityAllocatedExposureRatioByOwnerAggregateNumericDTO>;
};

export type EntityAllocatedExposureRatioByOwnerAggregateNumericDTO = {
  brokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  brokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  drpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  drpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  totalPercentOpen?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  totalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
};

export type EntityAllocatedExposureRatioByOwnerDistinctOnDTO = {
  brokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  brokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  drpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  drpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  isNaturallyLong?: InputMaybe<Scalars['Boolean']>;
  isNaturallyShort?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  totalPercentOpen?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  totalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
};

export type EntityAllocatedExposureRatioByOwnerFilterDTO = {
  AND?: InputMaybe<Array<EntityAllocatedExposureRatioByOwnerFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<EntityAllocatedExposureRatioByOwnerFilterDTO>;
  OR?: InputMaybe<Array<EntityAllocatedExposureRatioByOwnerFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  brokerageVolumeCapped?: InputMaybe<NullableNumberFilter>;
  brokerageVolumeHedged?: InputMaybe<NullableNumberFilter>;
  date?: InputMaybe<StringFilter>;
  drpVolumeCapped?: InputMaybe<NullableNumberFilter>;
  drpVolumeHedged?: InputMaybe<NullableNumberFilter>;
  endDate: Scalars['String'];
  entityId?: InputMaybe<StringFilter>;
  exposure?: InputMaybe<NullableNumberFilter>;
  inventoryExposure?: InputMaybe<NullableNumberFilter>;
  isNaturallyLong?: InputMaybe<NullableBooleanFilter>;
  isNaturallyShort?: InputMaybe<NullableBooleanFilter>;
  lgmVolumeCapped?: InputMaybe<NullableNumberFilter>;
  lgmVolumeHedged?: InputMaybe<NullableNumberFilter>;
  lrpVolumeCapped?: InputMaybe<NullableNumberFilter>;
  lrpVolumeHedged?: InputMaybe<NullableNumberFilter>;
  percentBrokerageVolumeCapped?: InputMaybe<NullableNumberFilter>;
  percentBrokerageVolumeHedged?: InputMaybe<NullableNumberFilter>;
  percentLgmVolumeCapped?: InputMaybe<NullableNumberFilter>;
  percentLgmVolumeHedged?: InputMaybe<NullableNumberFilter>;
  percentLrpVolumeCapped?: InputMaybe<NullableNumberFilter>;
  percentLrpVolumeHedged?: InputMaybe<NullableNumberFilter>;
  percentPhysicalVolumeCapped?: InputMaybe<NullableNumberFilter>;
  percentPhysicalVolumeHedged?: InputMaybe<NullableNumberFilter>;
  physicalVolumeCapped?: InputMaybe<NullableNumberFilter>;
  physicalVolumeHedged?: InputMaybe<NullableNumberFilter>;
  productId?: InputMaybe<StringFilter>;
  productionExposure?: InputMaybe<NullableNumberFilter>;
  startDate: Scalars['String'];
  totalPercentOpen?: InputMaybe<NullableNumberFilter>;
  totalPercentVolumeCapped?: InputMaybe<NullableNumberFilter>;
  totalPercentVolumeHedged?: InputMaybe<NullableNumberFilter>;
  totalVolumeCapped?: InputMaybe<NullableNumberFilter>;
  totalVolumeHedged?: InputMaybe<NullableNumberFilter>;
};

export type EntityAllocatedExposureRatioByOwnerGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  brokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  brokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  drpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  drpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  isNaturallyLong?: InputMaybe<Scalars['Boolean']>;
  isNaturallyShort?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  totalPercentOpen?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  totalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
};

export type EntityAllocatedExposureRatioByOwnerSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  brokerageVolumeCapped?: InputMaybe<NullableSortByDirection>;
  brokerageVolumeHedged?: InputMaybe<NullableSortByDirection>;
  date?: InputMaybe<SortByDirection>;
  drpVolumeCapped?: InputMaybe<NullableSortByDirection>;
  drpVolumeHedged?: InputMaybe<NullableSortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  exposure?: InputMaybe<NullableSortByDirection>;
  inventoryExposure?: InputMaybe<NullableSortByDirection>;
  isNaturallyLong?: InputMaybe<NullableSortByDirection>;
  isNaturallyShort?: InputMaybe<NullableSortByDirection>;
  lgmVolumeCapped?: InputMaybe<NullableSortByDirection>;
  lgmVolumeHedged?: InputMaybe<NullableSortByDirection>;
  lrpVolumeCapped?: InputMaybe<NullableSortByDirection>;
  lrpVolumeHedged?: InputMaybe<NullableSortByDirection>;
  percentBrokerageVolumeCapped?: InputMaybe<NullableSortByDirection>;
  percentBrokerageVolumeHedged?: InputMaybe<NullableSortByDirection>;
  percentLgmVolumeCapped?: InputMaybe<NullableSortByDirection>;
  percentLgmVolumeHedged?: InputMaybe<NullableSortByDirection>;
  percentLrpVolumeCapped?: InputMaybe<NullableSortByDirection>;
  percentLrpVolumeHedged?: InputMaybe<NullableSortByDirection>;
  percentPhysicalVolumeCapped?: InputMaybe<NullableSortByDirection>;
  percentPhysicalVolumeHedged?: InputMaybe<NullableSortByDirection>;
  physicalVolumeCapped?: InputMaybe<NullableSortByDirection>;
  physicalVolumeHedged?: InputMaybe<NullableSortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productionExposure?: InputMaybe<NullableSortByDirection>;
  totalPercentOpen?: InputMaybe<NullableSortByDirection>;
  totalPercentVolumeCapped?: InputMaybe<NullableSortByDirection>;
  totalPercentVolumeHedged?: InputMaybe<NullableSortByDirection>;
  totalVolumeCapped?: InputMaybe<NullableSortByDirection>;
  totalVolumeHedged?: InputMaybe<NullableSortByDirection>;
};

export type EntityAllocatedExposureRatioDistinctOnDTO = {
  brokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  brokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  drpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  drpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  isNaturallyLong?: InputMaybe<Scalars['Boolean']>;
  isNaturallyShort?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  netProductionExposure?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  totalPercentOpen?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  totalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
};

export type EntityAllocatedExposureRatioFilterDTO = {
  AND?: InputMaybe<Array<EntityAllocatedExposureRatioFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<EntityAllocatedExposureRatioFilterDTO>;
  OR?: InputMaybe<Array<EntityAllocatedExposureRatioFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  brokerageVolumeCapped?: InputMaybe<NullableNumberFilter>;
  brokerageVolumeHedged?: InputMaybe<NullableNumberFilter>;
  date?: InputMaybe<StringFilter>;
  drpVolumeCapped?: InputMaybe<NullableNumberFilter>;
  drpVolumeHedged?: InputMaybe<NullableNumberFilter>;
  endDate: Scalars['String'];
  entityId?: InputMaybe<StringFilter>;
  exposure?: InputMaybe<NullableNumberFilter>;
  inventoryExposure?: InputMaybe<NullableNumberFilter>;
  isNaturallyLong?: InputMaybe<NullableBooleanFilter>;
  isNaturallyShort?: InputMaybe<NullableBooleanFilter>;
  lgmVolumeCapped?: InputMaybe<NullableNumberFilter>;
  lgmVolumeHedged?: InputMaybe<NullableNumberFilter>;
  lrpVolumeCapped?: InputMaybe<NullableNumberFilter>;
  lrpVolumeHedged?: InputMaybe<NullableNumberFilter>;
  netProductionExposure?: InputMaybe<NullableNumberFilter>;
  percentBrokerageVolumeCapped?: InputMaybe<NullableNumberFilter>;
  percentBrokerageVolumeHedged?: InputMaybe<NullableNumberFilter>;
  percentLgmVolumeCapped?: InputMaybe<NullableNumberFilter>;
  percentLgmVolumeHedged?: InputMaybe<NullableNumberFilter>;
  percentLrpVolumeCapped?: InputMaybe<NullableNumberFilter>;
  percentLrpVolumeHedged?: InputMaybe<NullableNumberFilter>;
  percentPhysicalVolumeCapped?: InputMaybe<NullableNumberFilter>;
  percentPhysicalVolumeHedged?: InputMaybe<NullableNumberFilter>;
  physicalVolumeCapped?: InputMaybe<NullableNumberFilter>;
  physicalVolumeHedged?: InputMaybe<NullableNumberFilter>;
  productId?: InputMaybe<StringFilter>;
  productionExposure?: InputMaybe<NullableNumberFilter>;
  startDate: Scalars['String'];
  totalPercentOpen?: InputMaybe<NullableNumberFilter>;
  totalPercentVolumeCapped?: InputMaybe<NullableNumberFilter>;
  totalPercentVolumeHedged?: InputMaybe<NullableNumberFilter>;
  totalVolumeCapped?: InputMaybe<NullableNumberFilter>;
  totalVolumeHedged?: InputMaybe<NullableNumberFilter>;
};

export type EntityAllocatedExposureRatioGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  brokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  brokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  drpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  drpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  isNaturallyLong?: InputMaybe<Scalars['Boolean']>;
  isNaturallyShort?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  lrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  netProductionExposure?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentBrokerageVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLgmVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentLrpVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  percentPhysicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  physicalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  totalPercentOpen?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalPercentVolumeHedged?: InputMaybe<Scalars['Boolean']>;
  totalVolumeCapped?: InputMaybe<Scalars['Boolean']>;
  totalVolumeHedged?: InputMaybe<Scalars['Boolean']>;
};

export type EntityAllocatedExposureRatioSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  brokerageVolumeCapped?: InputMaybe<NullableSortByDirection>;
  brokerageVolumeHedged?: InputMaybe<NullableSortByDirection>;
  date?: InputMaybe<SortByDirection>;
  drpVolumeCapped?: InputMaybe<NullableSortByDirection>;
  drpVolumeHedged?: InputMaybe<NullableSortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  exposure?: InputMaybe<NullableSortByDirection>;
  inventoryExposure?: InputMaybe<NullableSortByDirection>;
  isNaturallyLong?: InputMaybe<NullableSortByDirection>;
  isNaturallyShort?: InputMaybe<NullableSortByDirection>;
  lgmVolumeCapped?: InputMaybe<NullableSortByDirection>;
  lgmVolumeHedged?: InputMaybe<NullableSortByDirection>;
  lrpVolumeCapped?: InputMaybe<NullableSortByDirection>;
  lrpVolumeHedged?: InputMaybe<NullableSortByDirection>;
  netProductionExposure?: InputMaybe<NullableSortByDirection>;
  percentBrokerageVolumeCapped?: InputMaybe<NullableSortByDirection>;
  percentBrokerageVolumeHedged?: InputMaybe<NullableSortByDirection>;
  percentLgmVolumeCapped?: InputMaybe<NullableSortByDirection>;
  percentLgmVolumeHedged?: InputMaybe<NullableSortByDirection>;
  percentLrpVolumeCapped?: InputMaybe<NullableSortByDirection>;
  percentLrpVolumeHedged?: InputMaybe<NullableSortByDirection>;
  percentPhysicalVolumeCapped?: InputMaybe<NullableSortByDirection>;
  percentPhysicalVolumeHedged?: InputMaybe<NullableSortByDirection>;
  physicalVolumeCapped?: InputMaybe<NullableSortByDirection>;
  physicalVolumeHedged?: InputMaybe<NullableSortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productionExposure?: InputMaybe<NullableSortByDirection>;
  totalPercentOpen?: InputMaybe<NullableSortByDirection>;
  totalPercentVolumeCapped?: InputMaybe<NullableSortByDirection>;
  totalPercentVolumeHedged?: InputMaybe<NullableSortByDirection>;
  totalVolumeCapped?: InputMaybe<NullableSortByDirection>;
  totalVolumeHedged?: InputMaybe<NullableSortByDirection>;
};

export type EntityAllocatedForecastedBrokerageVolumeHedgedFilterDTO = {
  AND?: InputMaybe<Array<EntityAllocatedForecastedBrokerageVolumeHedgedFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<EntityAllocatedForecastedBrokerageVolumeHedgedFilterDTO>;
  OR?: InputMaybe<Array<EntityAllocatedForecastedBrokerageVolumeHedgedFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  instrumentType?: InputMaybe<TypeOfInstrumentFilter>;
  optionType?: InputMaybe<NullableTypeOfOptionFilter>;
  productId?: InputMaybe<StringFilter>;
  strike?: InputMaybe<NullableNumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  volumeHedgedInContracts?: InputMaybe<NumberFilter>;
};

export type EntityAllocatedForecastedVolumeHedgedByOwnerFilterDTO = {
  AND?: InputMaybe<Array<EntityAllocatedForecastedVolumeHedgedByOwnerFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<EntityAllocatedForecastedVolumeHedgedByOwnerFilterDTO>;
  OR?: InputMaybe<Array<EntityAllocatedForecastedVolumeHedgedByOwnerFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  date?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  naturallyLongCapped?: InputMaybe<NumberFilter>;
  naturallyLongHedged?: InputMaybe<NumberFilter>;
  naturallyShortCapped?: InputMaybe<NumberFilter>;
  naturallyShortHedged?: InputMaybe<NumberFilter>;
  productId?: InputMaybe<StringFilter>;
};

export type EntityDistinctOnDTO = {
  averageFinishAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  averageFinishWeightInLbs?: InputMaybe<Scalars['Boolean']>;
  businessRoles?: InputMaybe<Scalars['Boolean']>;
  countyId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  currentBasis?: InputMaybe<Scalars['Boolean']>;
  customBasis?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  deactivatedAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Boolean']>;
  relationshipOwnerId?: InputMaybe<Scalars['Boolean']>;
  rollingBasisNumberOfMonths?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsCustomerId?: InputMaybe<Scalars['Boolean']>;
};

export type EntityExposureRatio = {
  __typename?: 'EntityExposureRatio';
  Entity: IEntity;
  Product?: Maybe<Product>;
  date: Scalars['String'];
  entityId: Scalars['String'];
  exposure?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  inventoryExposure?: Maybe<Scalars['Float']>;
  isNaturallyLong?: Maybe<Scalars['Boolean']>;
  isNaturallyShort?: Maybe<Scalars['Boolean']>;
  /** The entity's net exposure due to consumption and production of a given product. If naturally long, this will be production exposure minus consumption exposure. If naturally short, thils will be consumption exposure minus production exposure. */
  netProductionExposure?: Maybe<Scalars['Float']>;
  percentCapped?: Maybe<Scalars['Float']>;
  percentCappedCumulative?: Maybe<Scalars['Float']>;
  percentHedged?: Maybe<Scalars['Float']>;
  percentHedgedCumulative?: Maybe<Scalars['Float']>;
  percentOpen?: Maybe<Scalars['Float']>;
  percentOpenCumulative?: Maybe<Scalars['Float']>;
  productId: Scalars['String'];
  /**
   * The entity's net exposure due to consumption and production of a given product. If naturally long, this will be production exposure minus consumption exposure. If naturally short, thils will be consumption exposure minus production exposure.
   * @deprecated Use "netProductionExposure" instead
   */
  productionExposure?: Maybe<Scalars['Float']>;
  volumeCapped?: Maybe<Scalars['Float']>;
  volumeCappedCumulative?: Maybe<Scalars['Float']>;
  volumeHedged?: Maybe<Scalars['Float']>;
  volumeHedgedCumulative?: Maybe<Scalars['Float']>;
};

export type EntityExposureRatioAggregateAllDTO = {
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  netProductionExposure?: InputMaybe<Scalars['Boolean']>;
  percentCapped?: InputMaybe<Scalars['Boolean']>;
  percentCappedCumulative?: InputMaybe<Scalars['Boolean']>;
  percentHedged?: InputMaybe<Scalars['Boolean']>;
  percentHedgedCumulative?: InputMaybe<Scalars['Boolean']>;
  percentOpen?: InputMaybe<Scalars['Boolean']>;
  percentOpenCumulative?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  volumeCapped?: InputMaybe<Scalars['Boolean']>;
  volumeCappedCumulative?: InputMaybe<Scalars['Boolean']>;
  volumeHedged?: InputMaybe<Scalars['Boolean']>;
  volumeHedgedCumulative?: InputMaybe<Scalars['Boolean']>;
};

export type EntityExposureRatioAggregateDTO = {
  avg?: InputMaybe<EntityExposureRatioAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<EntityExposureRatioAggregateAllDTO>;
  min?: InputMaybe<EntityExposureRatioAggregateAllDTO>;
  sum?: InputMaybe<EntityExposureRatioAggregateNumericDTO>;
};

export type EntityExposureRatioAggregateNumericDTO = {
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  netProductionExposure?: InputMaybe<Scalars['Boolean']>;
  percentCapped?: InputMaybe<Scalars['Boolean']>;
  percentCappedCumulative?: InputMaybe<Scalars['Boolean']>;
  percentHedged?: InputMaybe<Scalars['Boolean']>;
  percentHedgedCumulative?: InputMaybe<Scalars['Boolean']>;
  percentOpen?: InputMaybe<Scalars['Boolean']>;
  percentOpenCumulative?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  volumeCapped?: InputMaybe<Scalars['Boolean']>;
  volumeCappedCumulative?: InputMaybe<Scalars['Boolean']>;
  volumeHedged?: InputMaybe<Scalars['Boolean']>;
  volumeHedgedCumulative?: InputMaybe<Scalars['Boolean']>;
};

export type EntityExposureRatioDistinctOnDTO = {
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  isNaturallyLong?: InputMaybe<Scalars['Boolean']>;
  isNaturallyShort?: InputMaybe<Scalars['Boolean']>;
  netProductionExposure?: InputMaybe<Scalars['Boolean']>;
  percentCapped?: InputMaybe<Scalars['Boolean']>;
  percentCappedCumulative?: InputMaybe<Scalars['Boolean']>;
  percentHedged?: InputMaybe<Scalars['Boolean']>;
  percentHedgedCumulative?: InputMaybe<Scalars['Boolean']>;
  percentOpen?: InputMaybe<Scalars['Boolean']>;
  percentOpenCumulative?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  volumeCapped?: InputMaybe<Scalars['Boolean']>;
  volumeCappedCumulative?: InputMaybe<Scalars['Boolean']>;
  volumeHedged?: InputMaybe<Scalars['Boolean']>;
  volumeHedgedCumulative?: InputMaybe<Scalars['Boolean']>;
};

export type EntityExposureRatioFilterDTO = {
  AND?: InputMaybe<Array<EntityExposureRatioFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<EntityExposureRatioFilterDTO>;
  OR?: InputMaybe<Array<EntityExposureRatioFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  date?: InputMaybe<StringFilter>;
  endDate: Scalars['String'];
  entityId?: InputMaybe<StringFilter>;
  exposure?: InputMaybe<NullableNumberFilter>;
  inventoryExposure?: InputMaybe<NullableNumberFilter>;
  isNaturallyLong?: InputMaybe<NullableBooleanFilter>;
  isNaturallyShort?: InputMaybe<NullableBooleanFilter>;
  netProductionExposure?: InputMaybe<NullableNumberFilter>;
  percentCapped?: InputMaybe<NullableNumberFilter>;
  percentCappedCumulative?: InputMaybe<NullableNumberFilter>;
  percentHedged?: InputMaybe<NullableNumberFilter>;
  percentHedgedCumulative?: InputMaybe<NullableNumberFilter>;
  percentOpen?: InputMaybe<NullableNumberFilter>;
  percentOpenCumulative?: InputMaybe<NullableNumberFilter>;
  productId?: InputMaybe<StringFilter>;
  productionExposure?: InputMaybe<NullableNumberFilter>;
  startDate: Scalars['String'];
  volumeCapped?: InputMaybe<NullableNumberFilter>;
  volumeCappedCumulative?: InputMaybe<NullableNumberFilter>;
  volumeHedged?: InputMaybe<NullableNumberFilter>;
  volumeHedgedCumulative?: InputMaybe<NullableNumberFilter>;
};

export type EntityExposureRatioGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exposure?: InputMaybe<Scalars['Boolean']>;
  inventoryExposure?: InputMaybe<Scalars['Boolean']>;
  isNaturallyLong?: InputMaybe<Scalars['Boolean']>;
  isNaturallyShort?: InputMaybe<Scalars['Boolean']>;
  netProductionExposure?: InputMaybe<Scalars['Boolean']>;
  percentCapped?: InputMaybe<Scalars['Boolean']>;
  percentCappedCumulative?: InputMaybe<Scalars['Boolean']>;
  percentHedged?: InputMaybe<Scalars['Boolean']>;
  percentHedgedCumulative?: InputMaybe<Scalars['Boolean']>;
  percentOpen?: InputMaybe<Scalars['Boolean']>;
  percentOpenCumulative?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionExposure?: InputMaybe<Scalars['Boolean']>;
  volumeCapped?: InputMaybe<Scalars['Boolean']>;
  volumeCappedCumulative?: InputMaybe<Scalars['Boolean']>;
  volumeHedged?: InputMaybe<Scalars['Boolean']>;
  volumeHedgedCumulative?: InputMaybe<Scalars['Boolean']>;
};

export type EntityExposureRatioSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  date?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  exposure?: InputMaybe<NullableSortByDirection>;
  inventoryExposure?: InputMaybe<NullableSortByDirection>;
  isNaturallyLong?: InputMaybe<NullableSortByDirection>;
  isNaturallyShort?: InputMaybe<NullableSortByDirection>;
  netProductionExposure?: InputMaybe<NullableSortByDirection>;
  percentCapped?: InputMaybe<NullableSortByDirection>;
  percentCappedCumulative?: InputMaybe<NullableSortByDirection>;
  percentHedged?: InputMaybe<NullableSortByDirection>;
  percentHedgedCumulative?: InputMaybe<NullableSortByDirection>;
  percentOpen?: InputMaybe<NullableSortByDirection>;
  percentOpenCumulative?: InputMaybe<NullableSortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productionExposure?: InputMaybe<NullableSortByDirection>;
  volumeCapped?: InputMaybe<NullableSortByDirection>;
  volumeCappedCumulative?: InputMaybe<NullableSortByDirection>;
  volumeHedged?: InputMaybe<NullableSortByDirection>;
  volumeHedgedCumulative?: InputMaybe<NullableSortByDirection>;
};

export type EntityFilterDTO = {
  AND?: InputMaybe<Array<EntityFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  ActualBlendedMilkPrices?: InputMaybe<ActualBlendedMilkPriceFilterDTO>;
  ActualMilkProductionByMonth?: InputMaybe<ActualMilkProductionByMonthFilterDTO>;
  AgreedToOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  AllocatedForecastedHedgedAndCappedVolumes?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>;
  AsCustomerEntity?: InputMaybe<CustomerEntityFilterDTO>;
  AsEntityGroup?: InputMaybe<EntityGroupFilterDTO>;
  AsLocationEntity?: InputMaybe<LocationEntityFilterDTO>;
  AsOrganizationEntity?: InputMaybe<OrganizationEntityFilterDTO>;
  Children?: InputMaybe<EntityFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  EntityActiveProducts?: InputMaybe<EntityActiveProductFilterDTO>;
  EntityAllocatedExposureRatios?: InputMaybe<EntityAllocatedExposureRatioFilterDTO>;
  EntityAllocatedExposureRatiosByOwner?: InputMaybe<EntityAllocatedExposureRatioByOwnerFilterDTO>;
  EntityAllocatedForecastedBrokerageVolumesHedged?: InputMaybe<EntityAllocatedForecastedBrokerageVolumeHedgedFilterDTO>;
  EntityAllocatedForecastedVolumesHedgedByOwner?: InputMaybe<EntityAllocatedForecastedVolumeHedgedByOwnerFilterDTO>;
  EntityForecastedPhysicalVolumeHedged?: InputMaybe<EntityForecastedPhysicalVolumeHedgedFilterDTO>;
  EntityUsers?: InputMaybe<EntityUserFilterDTO>;
  ExposureRatios?: InputMaybe<EntityExposureRatioFilterDTO>;
  FeedIngredientConsumedAndPurchasedVolumes?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
  FeedIngredientUsages?: InputMaybe<FeedIngredientUsageFilterDTO>;
  ForecastedHedgedAndCappedVolume?: InputMaybe<ForecastedHedgedAndCappedVolumeFilterDTO>;
  ForecastedMilkProductionByMonth?: InputMaybe<ForecastedMilkProductionByMonthFilterDTO>;
  ForecastedMilkProductionByMonthRolledUp?: InputMaybe<ForecastedMilkProductionByMonthRolledUpFilterDTO>;
  GroupEntries?: InputMaybe<EntityGroupEntryFilterDTO>;
  InitiatedOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  InsurancePolicies?: InputMaybe<InsurancePolicyFilterDTO>;
  IntegrationInstallations?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  LedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  Members?: InputMaybe<EntityMemberFilterDTO>;
  NOT?: InputMaybe<EntityFilterDTO>;
  Notes?: InputMaybe<EntityNoteFilterDTO>;
  OR?: InputMaybe<Array<EntityFilterDTO>>;
  OwnedOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  Parent?: InputMaybe<EntityFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  averageFinishAgeInWeeks?: InputMaybe<NullableNumberFilter>;
  averageFinishWeightInLbs?: InputMaybe<NullableNumberFilter>;
  businessRoles?: InputMaybe<BusinessEntityRoleArrayFilterDTO>;
  countyId?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  currentBasis?: InputMaybe<NullableNumberFilter>;
  customBasis?: InputMaybe<NullableNumberFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  deactivatedAt?: InputMaybe<NullableDateTimeFilter>;
  externalId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  identificationNumber?: InputMaybe<NumberFilter>;
  isVgs?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  milkOrderId?: InputMaybe<NullableStringFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<NullableStringFilter>;
  parentId?: InputMaybe<NullableStringFilter>;
  relationshipOwnerId?: InputMaybe<NullableStringFilter>;
  rollingBasisNumberOfMonths?: InputMaybe<NullableNumberFilter>;
  slug?: InputMaybe<NullableStringFilter>;
  type?: InputMaybe<TypeOfEntityFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsCustomerId?: InputMaybe<NullableNumberFilter>;
};

export type EntityForecastedPhysicalVolumeHedgedFilterDTO = {
  AND?: InputMaybe<Array<EntityForecastedPhysicalVolumeHedgedFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<EntityForecastedPhysicalVolumeHedgedFilterDTO>;
  OR?: InputMaybe<Array<EntityForecastedPhysicalVolumeHedgedFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  date?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<NumberFilter>;
};

export type EntityGroup = IEntity & {
  __typename?: 'EntityGroup';
  ActiveProducts: Array<Product>;
  AgreedToOtcContracts: Array<OtcContract>;
  BrokerageAccounts: Array<BrokerageAccount>;
  CreatedByUser?: Maybe<User>;
  CurrentUserPermissions: UserPermissionsForTarget;
  Customers: Array<CustomerEntity>;
  EntityUsers: Array<EntityUser>;
  Entries: Array<EntityGroupEntry>;
  ExposureRatios: Array<EntityExposureRatio>;
  FeedIngredientConsumedAndPurchasedVolumes: Array<FeedIngredientConsumedAndPurchasedVolume>;
  ForwardContracts: Array<ForwardContractAccount>;
  GroupEntries: Array<EntityGroupEntry>;
  Groups: Array<EntityGroup>;
  InitiatedOtcContracts: Array<OtcContract>;
  LastUpdatedByUser?: Maybe<User>;
  Members: Array<EntityMember>;
  Notes: Array<EntityNote>;
  Organization: OrganizationEntity;
  OwnedAccounts: Array<IAccount>;
  OwnedOtcContracts: Array<OtcContract>;
  Parent?: Maybe<IEntity>;
  PhysicalSales: Array<PhysicalSale>;
  createdAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  identificationNumber: Scalars['Int'];
  isVgs: Scalars['Boolean'];
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  parentId: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  type: TypeOfEntity;
  updatedAt: Scalars['DateTime'];
};


export type EntityGroup_AgreedToOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type EntityGroup_BrokerageAccountsArgs = {
  orderBy?: InputMaybe<BrokerageAccountSortByDTO>;
  where?: InputMaybe<BrokerageAccountFilterDTO>;
};


export type EntityGroup_CustomersArgs = {
  orderBy?: InputMaybe<CustomerEntitySortByDTO>;
  where?: InputMaybe<CustomerEntityFilterDTO>;
};


export type EntityGroup_EntityUsersArgs = {
  orderBy?: InputMaybe<EntityUserSortByDTO>;
  where?: InputMaybe<EntityUserFilterDTO>;
};


export type EntityGroup_EntriesArgs = {
  orderBy?: InputMaybe<EntityGroupEntrySortByDTO>;
  where?: InputMaybe<EntityGroupEntryFilterDTO>;
};


export type EntityGroup_ExposureRatiosArgs = {
  orderBy?: InputMaybe<EntityExposureRatioSortByDTO>;
  where?: InputMaybe<EntityExposureRatioFilterDTO>;
};


export type EntityGroup_FeedIngredientConsumedAndPurchasedVolumesArgs = {
  orderBy?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeSortByDTO>;
  where?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
};


export type EntityGroup_ForwardContractsArgs = {
  orderBy?: InputMaybe<ForwardContractAccountSortByDTO>;
  where?: InputMaybe<ForwardContractAccountFilterDTO>;
};


export type EntityGroup_GroupEntriesArgs = {
  orderBy?: InputMaybe<EntityGroupEntrySortByDTO>;
  where?: InputMaybe<EntityGroupEntryFilterDTO>;
};


export type EntityGroup_InitiatedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type EntityGroup_MembersArgs = {
  orderBy?: InputMaybe<EntityMemberSortByDTO>;
  where?: InputMaybe<EntityMemberFilterDTO>;
};


export type EntityGroup_NotesArgs = {
  orderBy?: InputMaybe<EntityNoteSortByDTO>;
  where?: InputMaybe<EntityNoteFilterDTO>;
};


export type EntityGroup_OwnedAccountsArgs = {
  orderBy?: InputMaybe<AccountSortByDTO>;
  where?: InputMaybe<AccountFilterDTO>;
};


export type EntityGroup_OwnedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type EntityGroup_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};

export type EntityGroupByDTO = {
  AsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  AsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  AsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  AsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  Parent?: InputMaybe<EntityGroupByDTO>;
  ParentAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  ParentAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  ParentAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  ParentAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  averageFinishAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  averageFinishWeightInLbs?: InputMaybe<Scalars['Boolean']>;
  businessRoles?: InputMaybe<Scalars['Boolean']>;
  countyId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  currentBasis?: InputMaybe<Scalars['Boolean']>;
  customBasis?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  deactivatedAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Boolean']>;
  relationshipOwnerId?: InputMaybe<Scalars['Boolean']>;
  rollingBasisNumberOfMonths?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsCustomerId?: InputMaybe<Scalars['Boolean']>;
};

export type EntityGroupCreateDTO = {
  externalId?: InputMaybe<Scalars['String']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type EntityGroupDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type EntityGroupEntry = {
  __typename?: 'EntityGroupEntry';
  Entity: IEntity;
  Group: EntityGroup;
  createdAt: Scalars['DateTime'];
  entityGroupId: Scalars['String'];
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type EntityGroupEntryCreateDTO = {
  entityGroupId: Scalars['String'];
  entityId: Scalars['String'];
};

export type EntityGroupEntryDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  entityGroupId?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type EntityGroupEntryFilterDTO = {
  AND?: InputMaybe<Array<EntityGroupEntryFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  Group?: InputMaybe<EntityGroupFilterDTO>;
  NOT?: InputMaybe<EntityGroupEntryFilterDTO>;
  OR?: InputMaybe<Array<EntityGroupEntryFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityGroupId?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EntityGroupEntrySortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  Group?: InputMaybe<EntityGroupSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  entityGroupId?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type EntityGroupEntryUpdateDTO = {
  entityGroupId?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
};

export type EntityGroupFilterDTO = {
  AND?: InputMaybe<Array<EntityGroupFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  ActualBlendedMilkPrices?: InputMaybe<ActualBlendedMilkPriceFilterDTO>;
  ActualMilkProductionByMonth?: InputMaybe<ActualMilkProductionByMonthFilterDTO>;
  AgreedToOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  AllocatedForecastedHedgedAndCappedVolumes?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>;
  AsEntity?: InputMaybe<EntityFilterDTO>;
  Children?: InputMaybe<EntityFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  EntityActiveProducts?: InputMaybe<EntityActiveProductFilterDTO>;
  EntityAllocatedExposureRatios?: InputMaybe<EntityAllocatedExposureRatioFilterDTO>;
  EntityAllocatedExposureRatiosByOwner?: InputMaybe<EntityAllocatedExposureRatioByOwnerFilterDTO>;
  EntityAllocatedForecastedBrokerageVolumesHedged?: InputMaybe<EntityAllocatedForecastedBrokerageVolumeHedgedFilterDTO>;
  EntityAllocatedForecastedVolumesHedgedByOwner?: InputMaybe<EntityAllocatedForecastedVolumeHedgedByOwnerFilterDTO>;
  EntityForecastedPhysicalVolumeHedged?: InputMaybe<EntityForecastedPhysicalVolumeHedgedFilterDTO>;
  EntityUsers?: InputMaybe<EntityUserFilterDTO>;
  Entries?: InputMaybe<EntityGroupEntryFilterDTO>;
  ExposureRatios?: InputMaybe<EntityExposureRatioFilterDTO>;
  FeedIngredientConsumedAndPurchasedVolumes?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
  FeedIngredientUsages?: InputMaybe<FeedIngredientUsageFilterDTO>;
  ForecastedHedgedAndCappedVolume?: InputMaybe<ForecastedHedgedAndCappedVolumeFilterDTO>;
  ForecastedMilkProductionByMonth?: InputMaybe<ForecastedMilkProductionByMonthFilterDTO>;
  ForecastedMilkProductionByMonthRolledUp?: InputMaybe<ForecastedMilkProductionByMonthRolledUpFilterDTO>;
  GroupEntries?: InputMaybe<EntityGroupEntryFilterDTO>;
  InitiatedOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  InsurancePolicies?: InputMaybe<InsurancePolicyFilterDTO>;
  IntegrationInstallations?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  LedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  Members?: InputMaybe<EntityMemberFilterDTO>;
  NOT?: InputMaybe<EntityGroupFilterDTO>;
  Notes?: InputMaybe<EntityNoteFilterDTO>;
  OR?: InputMaybe<Array<EntityGroupFilterDTO>>;
  Organization?: InputMaybe<OrganizationEntityFilterDTO>;
  OwnedOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  Parent?: InputMaybe<EntityFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  externalId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  identificationNumber?: InputMaybe<NumberFilter>;
  isVgs?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<StringFilter>;
  slug?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EntityGroupGroupByDTO = {
  AsEntity?: InputMaybe<EntityGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  Organization?: InputMaybe<OrganizationEntityGroupByDTO>;
  Parent?: InputMaybe<EntityGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type EntityGroupMemberCreateDTO = {
  canReadBrokerage?: InputMaybe<Scalars['Boolean']>;
  canReadBusiness?: InputMaybe<Scalars['Boolean']>;
  canReadFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canReadInsurance?: InputMaybe<Scalars['Boolean']>;
  canReadOperations?: InputMaybe<Scalars['Boolean']>;
  canReadSwap?: InputMaybe<Scalars['Boolean']>;
  canWriteBrokerage?: InputMaybe<Scalars['Boolean']>;
  canWriteBusiness?: InputMaybe<Scalars['Boolean']>;
  canWriteEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canWriteFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canWriteInsurance?: InputMaybe<Scalars['Boolean']>;
  canWriteOperations?: InputMaybe<Scalars['Boolean']>;
  canWriteSwap?: InputMaybe<Scalars['Boolean']>;
  entityId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type EntityGroupMemberUpdateDTO = {
  canReadBrokerage?: InputMaybe<Scalars['Boolean']>;
  canReadBusiness?: InputMaybe<Scalars['Boolean']>;
  canReadFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canReadInsurance?: InputMaybe<Scalars['Boolean']>;
  canReadOperations?: InputMaybe<Scalars['Boolean']>;
  canReadSwap?: InputMaybe<Scalars['Boolean']>;
  canWriteBrokerage?: InputMaybe<Scalars['Boolean']>;
  canWriteBusiness?: InputMaybe<Scalars['Boolean']>;
  canWriteEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canWriteFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canWriteInsurance?: InputMaybe<Scalars['Boolean']>;
  canWriteOperations?: InputMaybe<Scalars['Boolean']>;
  canWriteSwap?: InputMaybe<Scalars['Boolean']>;
};

export type EntityGroupSortByDTO = {
  AsEntity?: InputMaybe<EntitySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  Organization?: InputMaybe<OrganizationEntitySortByDTO>;
  Parent?: InputMaybe<EntitySortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  externalId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  identificationNumber?: InputMaybe<SortByDirection>;
  isVgs?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<SortByDirection>;
  organizationId?: InputMaybe<SortByDirection>;
  parentId?: InputMaybe<SortByDirection>;
  slug?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type EntityGroupUpdateDTO = {
  externalId?: InputMaybe<Scalars['String']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type EntityIntegrationFilterDTO = {
  AND?: InputMaybe<Array<EntityIntegrationFilterDTO>>;
  Installations?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  NOT?: InputMaybe<EntityIntegrationFilterDTO>;
  OR?: InputMaybe<Array<EntityIntegrationFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EntityIntegrationGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type EntityIntegrationInstallationFilterDTO = {
  AND?: InputMaybe<Array<EntityIntegrationInstallationFilterDTO>>;
  AccountBalances?: InputMaybe<AccountBalanceFilterDTO>;
  Accounts?: InputMaybe<AccountFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  Entity?: InputMaybe<EntityFilterDTO>;
  InsuranceEndorsements?: InputMaybe<InsuranceEndorsementFilterDTO>;
  InsuranceFiles?: InputMaybe<InsuranceFileFilterDTO>;
  InsurancePolicies?: InputMaybe<InsurancePolicyFilterDTO>;
  Integration?: InputMaybe<EntityIntegrationFilterDTO>;
  NOT?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  OR?: InputMaybe<Array<EntityIntegrationInstallationFilterDTO>>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  StaticPositions?: InputMaybe<StaticPositionFilterDTO>;
  TransactionFees?: InputMaybe<TransactionFeeFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<StringFilter>;
  healthcheckId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  integrationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EntityIntegrationInstallationGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  Integration?: InputMaybe<EntityIntegrationGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  healthcheckId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  integrationId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type EntityIntegrationInstallationSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  Integration?: InputMaybe<EntityIntegrationSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  healthcheckId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  integrationId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type EntityIntegrationSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type EntityMember = {
  __typename?: 'EntityMember';
  Entity: IEntity;
  User?: Maybe<User>;
  canReadBrokerage: Scalars['Boolean'];
  canReadBusiness: Scalars['Boolean'];
  /** @deprecated Use "canRead[Organization|EntityGroup|Business]" instead. This field is true if the membership has read access for all 3 entity resource types, otherwise it is false */
  canReadEntity: Scalars['Boolean'];
  canReadEntityGroup: Scalars['Boolean'];
  canReadFeedAndCropPlan: Scalars['Boolean'];
  canReadInsurance: Scalars['Boolean'];
  canReadOperations: Scalars['Boolean'];
  canReadOrganization: Scalars['Boolean'];
  canReadSwap: Scalars['Boolean'];
  canWriteBrokerage: Scalars['Boolean'];
  canWriteBusiness: Scalars['Boolean'];
  /** @deprecated Use "canWrite[Organization|EntityGroup|Business]" instead. This field is true if the membership has write access for all 3 entity resource types, otherwise it is false */
  canWriteEntity: Scalars['Boolean'];
  canWriteEntityGroup: Scalars['Boolean'];
  canWriteFeedAndCropPlan: Scalars['Boolean'];
  canWriteInsurance: Scalars['Boolean'];
  canWriteOperations: Scalars['Boolean'];
  canWriteOrganization: Scalars['Boolean'];
  canWriteSwap: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** @deprecated Use "permissions" instead. This field is set to READ_AND_WRITE if any combination of read & write permissions exist, it is set to READ if any read permissions exist, otherwise, it is set to WRITE */
  permission: TypeOfEntityMemberPermission;
  permissions: Array<TypeOfAclResourceAction>;
  updatedAt: Scalars['DateTime'];
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type EntityMemberDistinctOnDTO = {
  canReadBrokerage?: InputMaybe<Scalars['Boolean']>;
  canReadBusiness?: InputMaybe<Scalars['Boolean']>;
  canReadEntity?: InputMaybe<Scalars['Boolean']>;
  canReadEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canReadFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canReadInsurance?: InputMaybe<Scalars['Boolean']>;
  canReadOperations?: InputMaybe<Scalars['Boolean']>;
  canReadOrganization?: InputMaybe<Scalars['Boolean']>;
  canReadSwap?: InputMaybe<Scalars['Boolean']>;
  canWriteBrokerage?: InputMaybe<Scalars['Boolean']>;
  canWriteBusiness?: InputMaybe<Scalars['Boolean']>;
  canWriteEntity?: InputMaybe<Scalars['Boolean']>;
  canWriteEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canWriteFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canWriteInsurance?: InputMaybe<Scalars['Boolean']>;
  canWriteOperations?: InputMaybe<Scalars['Boolean']>;
  canWriteOrganization?: InputMaybe<Scalars['Boolean']>;
  canWriteSwap?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  permission?: InputMaybe<Scalars['Boolean']>;
  permissions?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userEmail?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type EntityMemberFilterDTO = {
  AND?: InputMaybe<Array<EntityMemberFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<EntityMemberFilterDTO>;
  OR?: InputMaybe<Array<EntityMemberFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  canReadBrokerage?: InputMaybe<BooleanFilter>;
  canReadBusiness?: InputMaybe<BooleanFilter>;
  canReadEntity?: InputMaybe<BooleanFilter>;
  canReadEntityGroup?: InputMaybe<BooleanFilter>;
  canReadFeedAndCropPlan?: InputMaybe<BooleanFilter>;
  canReadInsurance?: InputMaybe<BooleanFilter>;
  canReadOperations?: InputMaybe<BooleanFilter>;
  canReadOrganization?: InputMaybe<BooleanFilter>;
  canReadSwap?: InputMaybe<BooleanFilter>;
  canWriteBrokerage?: InputMaybe<BooleanFilter>;
  canWriteBusiness?: InputMaybe<BooleanFilter>;
  canWriteEntity?: InputMaybe<BooleanFilter>;
  canWriteEntityGroup?: InputMaybe<BooleanFilter>;
  canWriteFeedAndCropPlan?: InputMaybe<BooleanFilter>;
  canWriteInsurance?: InputMaybe<BooleanFilter>;
  canWriteOperations?: InputMaybe<BooleanFilter>;
  canWriteOrganization?: InputMaybe<BooleanFilter>;
  canWriteSwap?: InputMaybe<BooleanFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  permission?: InputMaybe<TypeOfEntityMemberPermissionFilter>;
  permissions?: InputMaybe<TypeOfAclResourceActionArrayFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userEmail?: InputMaybe<NullableStringFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export enum EntityMemberPermission {
  Read = 'Read',
  ReadAndWrite = 'ReadAndWrite',
  Write = 'Write'
}

export type EntityMemberSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  canReadBrokerage?: InputMaybe<SortByDirection>;
  canReadBusiness?: InputMaybe<SortByDirection>;
  canReadEntity?: InputMaybe<SortByDirection>;
  canReadEntityGroup?: InputMaybe<SortByDirection>;
  canReadFeedAndCropPlan?: InputMaybe<SortByDirection>;
  canReadInsurance?: InputMaybe<SortByDirection>;
  canReadOperations?: InputMaybe<SortByDirection>;
  canReadOrganization?: InputMaybe<SortByDirection>;
  canReadSwap?: InputMaybe<SortByDirection>;
  canWriteBrokerage?: InputMaybe<SortByDirection>;
  canWriteBusiness?: InputMaybe<SortByDirection>;
  canWriteEntity?: InputMaybe<SortByDirection>;
  canWriteEntityGroup?: InputMaybe<SortByDirection>;
  canWriteFeedAndCropPlan?: InputMaybe<SortByDirection>;
  canWriteInsurance?: InputMaybe<SortByDirection>;
  canWriteOperations?: InputMaybe<SortByDirection>;
  canWriteOrganization?: InputMaybe<SortByDirection>;
  canWriteSwap?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  permission?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userEmail?: InputMaybe<NullableSortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type EntityNote = {
  __typename?: 'EntityNote';
  Entity: IEntity;
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EntityNoteCreateDTO = {
  entityId: Scalars['String'];
  text: Scalars['String'];
};

export type EntityNoteDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type EntityNoteFilterDTO = {
  AND?: InputMaybe<Array<EntityNoteFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<EntityNoteFilterDTO>;
  OR?: InputMaybe<Array<EntityNoteFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EntityNoteSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  text?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type EntityNoteUpdateDTO = {
  entityId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type EntitySortByDTO = {
  AsCustomerEntity?: InputMaybe<CustomerEntitySortByDTO>;
  AsEntityGroup?: InputMaybe<EntityGroupSortByDTO>;
  AsLocationEntity?: InputMaybe<LocationEntitySortByDTO>;
  AsOrganizationEntity?: InputMaybe<OrganizationEntitySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  Parent?: InputMaybe<EntitySortByDTO>;
  averageFinishAgeInWeeks?: InputMaybe<NullableSortByDirection>;
  averageFinishWeightInLbs?: InputMaybe<NullableSortByDirection>;
  countyId?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  currentBasis?: InputMaybe<NullableSortByDirection>;
  customBasis?: InputMaybe<NullableSortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  deactivatedAt?: InputMaybe<NullableSortByDirection>;
  externalId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  identificationNumber?: InputMaybe<SortByDirection>;
  isVgs?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  milkOrderId?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<SortByDirection>;
  organizationId?: InputMaybe<NullableSortByDirection>;
  parentId?: InputMaybe<NullableSortByDirection>;
  relationshipOwnerId?: InputMaybe<NullableSortByDirection>;
  rollingBasisNumberOfMonths?: InputMaybe<NullableSortByDirection>;
  slug?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsCustomerId?: InputMaybe<NullableSortByDirection>;
};

export type EntityUser = {
  __typename?: 'EntityUser';
  Entity: IEntity;
  User: User;
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  roles: Array<EntityUserRole>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type EntityUserDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  roles?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type EntityUserFilterDTO = {
  AND?: InputMaybe<Array<EntityUserFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<EntityUserFilterDTO>;
  OR?: InputMaybe<Array<EntityUserFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  roles?: InputMaybe<EntityUserRoleArrayFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum EntityUserRole {
  BuyerRelations = 'BuyerRelations',
  GrainAdvisor = 'GrainAdvisor'
}

export type EntityUserRoleArrayFilterDTO = {
  /** Matches rows where the column contains ALL values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  contains?: InputMaybe<Array<EntityUserRole>>;
  /** Matches rows where the column array is an exact match to the given filter (order & duplicates matter!) */
  equals?: InputMaybe<Array<EntityUserRole>>;
  /** Matches rows where the column is contained within the values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  isContainedBy?: InputMaybe<Array<EntityUserRole>>;
  /** Filter results by the number of values contained within this array. `null` values will always be filtered out (even with a length filter = 0) */
  length?: InputMaybe<NumberFilter>;
  not?: InputMaybe<EntityUserRoleArrayFilterDTO>;
  /** Matches rows where the column contains any of the values included within this filter */
  overlapsWith?: InputMaybe<Array<EntityUserRole>>;
};

export type EntityUserSetInput = {
  email: Scalars['String'];
  entityId: Scalars['String'];
  roles?: InputMaybe<Array<EntityUserRole>>;
};

export type EntityUserSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<SortByDirection>;
};

export type EntityUserUpdateDTO = {
  roles?: InputMaybe<Array<EntityUserRole>>;
};

export type Fcm = {
  __typename?: 'Fcm';
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  /** The URL to access the FCM's account portal */
  portalUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type FcmDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  portalUrl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type FcmFilterDTO = {
  AND?: InputMaybe<Array<FcmFilterDTO>>;
  BrokerageAccounts?: InputMaybe<BrokerageAccountFilterDTO>;
  NOT?: InputMaybe<FcmFilterDTO>;
  OR?: InputMaybe<Array<FcmFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  externalId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  portalUrl?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FcmGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  portalUrl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type FcmSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  portalUrl?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type FeedCategory = {
  __typename?: 'FeedCategory';
  FeedIngredients: Array<FeedIngredient>;
  HedgeProduct?: Maybe<Product>;
  createdAt: Scalars['DateTime'];
  /** The default percentage basis/offset from a CME future that this FeedCategory will be priced. This value may be used _instead_ of flat pricing or a USD basis. */
  defaultCmePercentageBasis?: Maybe<Scalars['Float']>;
  /** The default USD basis/offset from a CME future that this FeedCategory will be priced. This value may be used _instead_ of flat pricing or a percentage basis. This value is a basis from the contract points _before_ any size and/or point-to-usd conversion. */
  defaultCmeUsdBasis?: Maybe<Scalars['Float']>;
  /** The percentage (by weight) of this feed category that is dry matter. The remaining percentage is assumed to be water weight. */
  defaultDryMatterPercentage: Scalars['Float'];
  /** The default USD value of 1 ton of DM of this FeedCategory. This value may be used _instead_ of a basis pricing. */
  defaultFlatPricePerTon?: Maybe<Scalars['Float']>;
  hedgeProductId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vgsIngredientIds: Array<Scalars['String']>;
};


export type FeedCategory_FeedIngredientsArgs = {
  orderBy?: InputMaybe<FeedIngredientSortByDTO>;
  where?: InputMaybe<FeedIngredientFilterDTO>;
};

export type FeedCategoryDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  defaultCmePercentageBasis?: InputMaybe<Scalars['Boolean']>;
  defaultCmeUsdBasis?: InputMaybe<Scalars['Boolean']>;
  defaultDryMatterPercentage?: InputMaybe<Scalars['Boolean']>;
  defaultFlatPricePerTon?: InputMaybe<Scalars['Boolean']>;
  hedgeProductId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsIngredientIds?: InputMaybe<Scalars['Boolean']>;
};

export type FeedCategoryFilterDTO = {
  AND?: InputMaybe<Array<FeedCategoryFilterDTO>>;
  FeedIngredients?: InputMaybe<FeedIngredientFilterDTO>;
  GrainFeedPlans?: InputMaybe<GrainFeedPlanFilterDTO>;
  HedgeProduct?: InputMaybe<ProductFilterDTO>;
  NOT?: InputMaybe<FeedCategoryFilterDTO>;
  OR?: InputMaybe<Array<FeedCategoryFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  defaultCmePercentageBasis?: InputMaybe<NullableNumberFilter>;
  defaultCmeUsdBasis?: InputMaybe<NullableNumberFilter>;
  defaultDryMatterPercentage?: InputMaybe<NumberFilter>;
  defaultFlatPricePerTon?: InputMaybe<NullableNumberFilter>;
  hedgeProductId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsIngredientIds?: InputMaybe<StringArrayFilter>;
};

export type FeedCategoryGroupByDTO = {
  HedgeProduct?: InputMaybe<ProductGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  defaultCmePercentageBasis?: InputMaybe<Scalars['Boolean']>;
  defaultCmeUsdBasis?: InputMaybe<Scalars['Boolean']>;
  defaultDryMatterPercentage?: InputMaybe<Scalars['Boolean']>;
  defaultFlatPricePerTon?: InputMaybe<Scalars['Boolean']>;
  hedgeProductId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsIngredientIds?: InputMaybe<Scalars['Boolean']>;
};

export type FeedCategorySortByDTO = {
  HedgeProduct?: InputMaybe<ProductSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  defaultCmePercentageBasis?: InputMaybe<NullableSortByDirection>;
  defaultCmeUsdBasis?: InputMaybe<NullableSortByDirection>;
  defaultDryMatterPercentage?: InputMaybe<SortByDirection>;
  defaultFlatPricePerTon?: InputMaybe<NullableSortByDirection>;
  hedgeProductId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type FeedIngredient = {
  __typename?: 'FeedIngredient';
  Customer: CustomerEntity;
  FeedCategory: FeedCategory;
  FeedIngredientConsumedAndPurchasedVolumes: Array<FeedIngredientConsumedAndPurchasedVolume>;
  FeedIngredientPrices: Array<FeedIngredientPrice>;
  FeedIngredientUsage: Array<FeedIngredientUsage>;
  HedgeProduct?: Maybe<Product>;
  LivestockGroupFeedUsages: Array<ILivestockGroupFeedUsage>;
  PhysicalFeedTransactions: Array<PhysicalFeedTransaction>;
  businessId: Scalars['String'];
  /** The percentage basis/offset from a CME future that will be used to price usage of this FeedIngredient. This value may be used _instead_ of flat pricing or a USD basis */
  cmePercentageBasis?: Maybe<Scalars['Float']>;
  /** The USD basis/offset from a CME future that will be used to price usage of this FeedIngredient. This value may be used _instead_ of flat pricing or a percentage basis. This value is a basis from the contract points _before_ any size and/or point-to-usd conversion. */
  cmeUsdBasis?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  /** @deprecated Use "businessId" instead */
  customerId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dryMatterPercent: Scalars['Float'];
  feedCategoryId: Scalars['String'];
  /** The USD value of 1 ton of DM usage of this FeedIngredient. This value may be used _instead_ of a basis pricing */
  flatPricePerTon?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  hedgeProductId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pricingMethodology: FeedIngredientPricingMethodology;
  updatedAt: Scalars['DateTime'];
  versionId: Scalars['String'];
  /** A UUID that will be shared across all feed ingredients that have been copied from each other upon the creation of a new version. */
  versionedConceptSeriesId: Scalars['String'];
};


export type FeedIngredient_FeedIngredientConsumedAndPurchasedVolumesArgs = {
  orderBy?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeSortByDTO>;
  where?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
};


export type FeedIngredient_FeedIngredientPricesArgs = {
  orderBy?: InputMaybe<FeedIngredientPriceSortByDTO>;
  where?: InputMaybe<FeedIngredientPriceFilterDTO>;
};


export type FeedIngredient_FeedIngredientUsageArgs = {
  orderBy?: InputMaybe<FeedIngredientUsageSortByDTO>;
  where?: InputMaybe<FeedIngredientUsageFilterDTO>;
};


export type FeedIngredient_LivestockGroupFeedUsagesArgs = {
  orderBy?: InputMaybe<LivestockGroupFeedUsageSortByDTO>;
  where?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
};


export type FeedIngredient_PhysicalFeedTransactionsArgs = {
  orderBy?: InputMaybe<PhysicalFeedTransactionSortByDTO>;
  where?: InputMaybe<PhysicalFeedTransactionFilterDTO>;
};

export type FeedIngredientActualUsage = IFeedIngredientUsage & {
  __typename?: 'FeedIngredientActualUsage';
  Entity: IEntity;
  FeedIngredient: FeedIngredient;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  /** @deprecated This field has been removed */
  basisPrice?: Maybe<Scalars['Float']>;
  /** @deprecated This field has been removed */
  cashPrice?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  /** @deprecated This field has been removed */
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb: Scalars['Float'];
  dmiUsageInTons: Scalars['Float'];
  entityId: Scalars['String'];
  feedIngredientId: Scalars['String'];
  /** A UUID that will be consistent across multiple feed ingredients that have been copied from each other upon the creation of a new version. */
  feedIngredientSeriesId: Scalars['String'];
  feedUsageInLb: Scalars['Float'];
  feedUsageInTons: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** @deprecated Use "dmiUsageInTons" instead */
  quantity: Scalars['Float'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['Int'];
  type: TypeOfFeedIngredientUsage;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type FeedIngredientActualUsageAggregateAllDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  cashPrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInLb?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInTons?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientSeriesId?: InputMaybe<Scalars['Boolean']>;
  feedUsageInLb?: InputMaybe<Scalars['Boolean']>;
  feedUsageInTons?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientActualUsageAggregateDTO = {
  avg?: InputMaybe<FeedIngredientActualUsageAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<FeedIngredientActualUsageAggregateAllDTO>;
  min?: InputMaybe<FeedIngredientActualUsageAggregateAllDTO>;
  sum?: InputMaybe<FeedIngredientActualUsageAggregateNumericDTO>;
};

export type FeedIngredientActualUsageAggregateNumericDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  cashPrice?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInLb?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInTons?: InputMaybe<Scalars['Boolean']>;
  feedUsageInLb?: InputMaybe<Scalars['Boolean']>;
  feedUsageInTons?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientActualUsageDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  cashPrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInLb?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInTons?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientSeriesId?: InputMaybe<Scalars['Boolean']>;
  feedUsageInLb?: InputMaybe<Scalars['Boolean']>;
  feedUsageInTons?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientActualUsageFilterDTO = {
  AND?: InputMaybe<Array<FeedIngredientActualUsageFilterDTO>>;
  AsFeedIngredientUsage?: InputMaybe<FeedIngredientUsageFilterDTO>;
  Entity?: InputMaybe<EntityFilterDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientFilterDTO>;
  NOT?: InputMaybe<FeedIngredientActualUsageFilterDTO>;
  OR?: InputMaybe<Array<FeedIngredientActualUsageFilterDTO>>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  basisPrice?: InputMaybe<NullableNumberFilter>;
  cashPrice?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  dmiUsageInLb?: InputMaybe<NumberFilter>;
  dmiUsageInTons?: InputMaybe<NumberFilter>;
  entityId?: InputMaybe<StringFilter>;
  feedIngredientId?: InputMaybe<StringFilter>;
  feedIngredientSeriesId?: InputMaybe<StringFilter>;
  feedUsageInLb?: InputMaybe<NumberFilter>;
  feedUsageInTons?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  quantity?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  readableQuarter?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type FeedIngredientActualUsageGroupByDTO = {
  AsFeedIngredientUsage?: InputMaybe<FeedIngredientUsageGroupByDTO>;
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  cashPrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInLb?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInTons?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientSeriesId?: InputMaybe<Scalars['Boolean']>;
  feedUsageInLb?: InputMaybe<Scalars['Boolean']>;
  feedUsageInTons?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientActualUsageSortByDTO = {
  AsFeedIngredientUsage?: InputMaybe<FeedIngredientUsageSortByDTO>;
  Entity?: InputMaybe<EntitySortByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientSortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  basisPrice?: InputMaybe<NullableSortByDirection>;
  cashPrice?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  dmiUsageInLb?: InputMaybe<SortByDirection>;
  dmiUsageInTons?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  feedIngredientId?: InputMaybe<SortByDirection>;
  feedIngredientSeriesId?: InputMaybe<SortByDirection>;
  feedUsageInLb?: InputMaybe<SortByDirection>;
  feedUsageInTons?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

/** This concept aggregates all forecasted dairy / livestock feed usage by ingredient + month and combines it with purchased feed data. A row will be returned for each month between the given start/end dates regardless of existing purchased or consumed feed that matches the given filters. */
export type FeedIngredientConsumedAndPurchasedVolume = {
  __typename?: 'FeedIngredientConsumedAndPurchasedVolume';
  Entity: IEntity;
  FeedIngredient: FeedIngredient;
  entityId: Scalars['String'];
  /** The id of the _current version_ of the associated feed ingredient. This value will always be the current version, even when the usage data was created with a different version. */
  feedIngredientId: Scalars['String'];
  forecastedConsumptionInTons?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The first date of the month for which this data is aggregated */
  monthStartDate: Scalars['String'];
  purchasedInTons?: Maybe<Scalars['Float']>;
  totalPurchasedCostInUsd?: Maybe<Scalars['Float']>;
  volumePurchasedWithBasisPriceOnlyInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithFlatPriceInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithFuturesPriceOnlyInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithoutPriceInTons?: Maybe<Scalars['Float']>;
};

export type FeedIngredientConsumedAndPurchasedVolumeAggregateAllDTO = {
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  forecastedConsumptionInTons?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  monthStartDate?: InputMaybe<Scalars['Boolean']>;
  purchasedInTons?: InputMaybe<Scalars['Boolean']>;
  totalPurchasedCostInUsd?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithBasisPriceOnlyInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithFlatPriceInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithFuturesPriceOnlyInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithoutPriceInTons?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientConsumedAndPurchasedVolumeAggregateDTO = {
  avg?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeAggregateAllDTO>;
  min?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeAggregateAllDTO>;
  sum?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeAggregateNumericDTO>;
};

export type FeedIngredientConsumedAndPurchasedVolumeAggregateNumericDTO = {
  forecastedConsumptionInTons?: InputMaybe<Scalars['Boolean']>;
  purchasedInTons?: InputMaybe<Scalars['Boolean']>;
  totalPurchasedCostInUsd?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithBasisPriceOnlyInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithFlatPriceInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithFuturesPriceOnlyInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithoutPriceInTons?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientConsumedAndPurchasedVolumeDistinctOnDTO = {
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  forecastedConsumptionInTons?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  monthStartDate?: InputMaybe<Scalars['Boolean']>;
  purchasedInTons?: InputMaybe<Scalars['Boolean']>;
  totalPurchasedCostInUsd?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithBasisPriceOnlyInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithFlatPriceInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithFuturesPriceOnlyInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithoutPriceInTons?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientConsumedAndPurchasedVolumeFilterDTO = {
  AND?: InputMaybe<Array<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientFilterDTO>;
  NOT?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
  OR?: InputMaybe<Array<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>>;
  /** The final date (inclusive) for which data will be returned */
  endDate: Scalars['String'];
  entityId?: InputMaybe<StringFilter>;
  feedIngredientId?: InputMaybe<StringFilter>;
  forecastedConsumptionInTons?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  monthStartDate?: InputMaybe<StringFilter>;
  purchasedInTons?: InputMaybe<NullableNumberFilter>;
  /** The first date (inclusive) for which data will be returned */
  startDate: Scalars['String'];
  totalPurchasedCostInUsd?: InputMaybe<NullableNumberFilter>;
  volumePurchasedWithBasisPriceOnlyInTons?: InputMaybe<NullableNumberFilter>;
  volumePurchasedWithFlatPriceInTons?: InputMaybe<NullableNumberFilter>;
  volumePurchasedWithFuturesPriceOnlyInTons?: InputMaybe<NullableNumberFilter>;
  volumePurchasedWithoutPriceInTons?: InputMaybe<NullableNumberFilter>;
};

export type FeedIngredientConsumedAndPurchasedVolumeGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientGroupByDTO>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  forecastedConsumptionInTons?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  monthStartDate?: InputMaybe<Scalars['Boolean']>;
  purchasedInTons?: InputMaybe<Scalars['Boolean']>;
  totalPurchasedCostInUsd?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithBasisPriceOnlyInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithFlatPriceInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithFuturesPriceOnlyInTons?: InputMaybe<Scalars['Boolean']>;
  volumePurchasedWithoutPriceInTons?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientConsumedAndPurchasedVolumeSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientSortByDTO>;
  entityId?: InputMaybe<SortByDirection>;
  feedIngredientId?: InputMaybe<SortByDirection>;
  forecastedConsumptionInTons?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  monthStartDate?: InputMaybe<SortByDirection>;
  purchasedInTons?: InputMaybe<NullableSortByDirection>;
  totalPurchasedCostInUsd?: InputMaybe<NullableSortByDirection>;
  volumePurchasedWithBasisPriceOnlyInTons?: InputMaybe<NullableSortByDirection>;
  volumePurchasedWithFlatPriceInTons?: InputMaybe<NullableSortByDirection>;
  volumePurchasedWithFuturesPriceOnlyInTons?: InputMaybe<NullableSortByDirection>;
  volumePurchasedWithoutPriceInTons?: InputMaybe<NullableSortByDirection>;
};

export type FeedIngredientCreateDTO = {
  businessId?: InputMaybe<Scalars['String']>;
  cmePercentageBasis?: InputMaybe<Scalars['Float']>;
  cmeUsdBasis?: InputMaybe<Scalars['Float']>;
  customerId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dryMatterPercent?: InputMaybe<Scalars['Float']>;
  feedCategoryId: Scalars['String'];
  flatPricePerTon?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type FeedIngredientDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  cmePercentageBasis?: InputMaybe<Scalars['Boolean']>;
  cmeUsdBasis?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  dryMatterPercent?: InputMaybe<Scalars['Boolean']>;
  feedCategoryId?: InputMaybe<Scalars['Boolean']>;
  flatPricePerTon?: InputMaybe<Scalars['Boolean']>;
  hedgeProductId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  pricingMethodology?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientFilterDTO = {
  AND?: InputMaybe<Array<FeedIngredientFilterDTO>>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  FeedCategory?: InputMaybe<FeedCategoryFilterDTO>;
  FeedIngredientConsumedAndPurchasedVolumes?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
  FeedIngredientPrices?: InputMaybe<FeedIngredientPriceFilterDTO>;
  FeedIngredientUsages?: InputMaybe<FeedIngredientUsageFilterDTO>;
  FeedIngredientVersion?: InputMaybe<FeedIngredientVersionFilterDTO>;
  FeedTransactions?: InputMaybe<FeedTransactionFilterDTO>;
  ForecastedLivestockFeedUsages?: InputMaybe<ForecastedLivestockFeedUsageFilterDTO>;
  GrainFeedPlans?: InputMaybe<GrainFeedPlanFilterDTO>;
  HedgeProduct?: InputMaybe<ProductFilterDTO>;
  LivestockGroupFeedUsages?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
  NOT?: InputMaybe<FeedIngredientFilterDTO>;
  OR?: InputMaybe<Array<FeedIngredientFilterDTO>>;
  PhysicalFeedTransactions?: InputMaybe<PhysicalFeedTransactionFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  cmePercentageBasis?: InputMaybe<NullableNumberFilter>;
  cmeUsdBasis?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  dryMatterPercent?: InputMaybe<NumberFilter>;
  feedCategoryId?: InputMaybe<StringFilter>;
  flatPricePerTon?: InputMaybe<NullableNumberFilter>;
  hedgeProductId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  pricingMethodology?: InputMaybe<FeedIngredientPricingMethodologyFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  versionId?: InputMaybe<StringFilter>;
  versionedConceptSeriesId?: InputMaybe<StringFilter>;
};

export type FeedIngredientForecastedUsage = IFeedIngredientUsage & {
  __typename?: 'FeedIngredientForecastedUsage';
  Entity: IEntity;
  FeedIngredient: FeedIngredient;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  /** @deprecated This field has been removed */
  basisPrice?: Maybe<Scalars['Float']>;
  /** @deprecated This field has been removed */
  cashPrice?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  /** @deprecated This field has been removed */
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb: Scalars['Float'];
  dmiUsageInTons: Scalars['Float'];
  entityId: Scalars['String'];
  feedIngredientId: Scalars['String'];
  /** A UUID that will be consistent across multiple feed ingredients that have been copied from each other upon the creation of a new version. */
  feedIngredientSeriesId: Scalars['String'];
  feedUsageInLb: Scalars['Float'];
  feedUsageInTons: Scalars['Float'];
  forecastedPerLbExpenseInUsd: Scalars['Float'];
  forecastedTotalExpenseInUsd: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** @deprecated Use "dmiUsageInTons" instead */
  quantity: Scalars['Float'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['Int'];
  type: TypeOfFeedIngredientUsage;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type FeedIngredientForecastedUsageAggregateAllDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  cashPrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInLb?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInTons?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientSeriesId?: InputMaybe<Scalars['Boolean']>;
  feedUsageInLb?: InputMaybe<Scalars['Boolean']>;
  feedUsageInTons?: InputMaybe<Scalars['Boolean']>;
  forecastedPerLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  forecastedTotalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientForecastedUsageAggregateDTO = {
  avg?: InputMaybe<FeedIngredientForecastedUsageAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<FeedIngredientForecastedUsageAggregateAllDTO>;
  min?: InputMaybe<FeedIngredientForecastedUsageAggregateAllDTO>;
  sum?: InputMaybe<FeedIngredientForecastedUsageAggregateNumericDTO>;
};

export type FeedIngredientForecastedUsageAggregateNumericDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  cashPrice?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInLb?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInTons?: InputMaybe<Scalars['Boolean']>;
  feedUsageInLb?: InputMaybe<Scalars['Boolean']>;
  feedUsageInTons?: InputMaybe<Scalars['Boolean']>;
  forecastedPerLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  forecastedTotalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientForecastedUsageDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  cashPrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInLb?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInTons?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientSeriesId?: InputMaybe<Scalars['Boolean']>;
  feedUsageInLb?: InputMaybe<Scalars['Boolean']>;
  feedUsageInTons?: InputMaybe<Scalars['Boolean']>;
  forecastedPerLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  forecastedTotalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientForecastedUsageFilterDTO = {
  AND?: InputMaybe<Array<FeedIngredientForecastedUsageFilterDTO>>;
  AsFeedIngredientUsage?: InputMaybe<FeedIngredientUsageFilterDTO>;
  Entity?: InputMaybe<EntityFilterDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientFilterDTO>;
  NOT?: InputMaybe<FeedIngredientForecastedUsageFilterDTO>;
  OR?: InputMaybe<Array<FeedIngredientForecastedUsageFilterDTO>>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  basisPrice?: InputMaybe<NullableNumberFilter>;
  cashPrice?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  dmiUsageInLb?: InputMaybe<NumberFilter>;
  dmiUsageInTons?: InputMaybe<NumberFilter>;
  entityId?: InputMaybe<StringFilter>;
  feedIngredientId?: InputMaybe<StringFilter>;
  feedIngredientSeriesId?: InputMaybe<StringFilter>;
  feedUsageInLb?: InputMaybe<NumberFilter>;
  feedUsageInTons?: InputMaybe<NumberFilter>;
  forecastedPerLbExpenseInUsd?: InputMaybe<NumberFilter>;
  forecastedTotalExpenseInUsd?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  quantity?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  readableQuarter?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type FeedIngredientForecastedUsageGroupByDTO = {
  AsFeedIngredientUsage?: InputMaybe<FeedIngredientUsageGroupByDTO>;
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  cashPrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInLb?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInTons?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientSeriesId?: InputMaybe<Scalars['Boolean']>;
  feedUsageInLb?: InputMaybe<Scalars['Boolean']>;
  feedUsageInTons?: InputMaybe<Scalars['Boolean']>;
  forecastedPerLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  forecastedTotalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientForecastedUsageSortByDTO = {
  AsFeedIngredientUsage?: InputMaybe<FeedIngredientUsageSortByDTO>;
  Entity?: InputMaybe<EntitySortByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientSortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  basisPrice?: InputMaybe<NullableSortByDirection>;
  cashPrice?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  dmiUsageInLb?: InputMaybe<SortByDirection>;
  dmiUsageInTons?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  feedIngredientId?: InputMaybe<SortByDirection>;
  feedIngredientSeriesId?: InputMaybe<SortByDirection>;
  feedUsageInLb?: InputMaybe<SortByDirection>;
  feedUsageInTons?: InputMaybe<SortByDirection>;
  forecastedPerLbExpenseInUsd?: InputMaybe<SortByDirection>;
  forecastedTotalExpenseInUsd?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type FeedIngredientGroupByDTO = {
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  FeedCategory?: InputMaybe<FeedCategoryGroupByDTO>;
  FeedIngredientVersion?: InputMaybe<FeedIngredientVersionGroupByDTO>;
  HedgeProduct?: InputMaybe<ProductGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  cmePercentageBasis?: InputMaybe<Scalars['Boolean']>;
  cmeUsdBasis?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  dryMatterPercent?: InputMaybe<Scalars['Boolean']>;
  feedCategoryId?: InputMaybe<Scalars['Boolean']>;
  flatPricePerTon?: InputMaybe<Scalars['Boolean']>;
  hedgeProductId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  pricingMethodology?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientPrice = {
  __typename?: 'FeedIngredientPrice';
  Business: CustomerEntity;
  FeedIngredient: FeedIngredient;
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  id: Scalars['ID'];
  ingredientId: Scalars['String'];
  price: Scalars['Float'];
  pricingMethodology: FeedIngredientPricingMethodology;
  updatedAt: Scalars['DateTime'];
};

export type FeedIngredientPriceByMonth = {
  __typename?: 'FeedIngredientPriceByMonth';
  date: Scalars['String'];
  feedIngredientPriceId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  ingredientId: Scalars['ID'];
  isDefaultPrice: Scalars['Boolean'];
  price: Scalars['Float'];
  pricingMethodology: FeedIngredientPricingMethodology;
};

export type FeedIngredientPriceByMonthInput = {
  endDate: Scalars['String'];
  feedIngredientId: Scalars['ID'];
  startDate: Scalars['String'];
};

export type FeedIngredientPriceByMonthSetDTO = {
  feedIngredientId: Scalars['String'];
  pricesByMonth: Array<FeedIngredientPriceMonth>;
};

export type FeedIngredientPriceFilterDTO = {
  AND?: InputMaybe<Array<FeedIngredientPriceFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientFilterDTO>;
  NOT?: InputMaybe<FeedIngredientPriceFilterDTO>;
  OR?: InputMaybe<Array<FeedIngredientPriceFilterDTO>>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  ingredientId?: InputMaybe<StringFilter>;
  price?: InputMaybe<NumberFilter>;
  pricingMethodology?: InputMaybe<FeedIngredientPricingMethodologyFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FeedIngredientPriceMonth = {
  date: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
};

export type FeedIngredientPriceSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  ingredientId?: InputMaybe<SortByDirection>;
  price?: InputMaybe<SortByDirection>;
  pricingMethodology?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export enum FeedIngredientPricingMethodology {
  CmePercentBasis = 'CmePercentBasis',
  CmeUsdBasis = 'CmeUsdBasis',
  Flat = 'Flat'
}

export type FeedIngredientPricingMethodologyFilter = {
  equals?: InputMaybe<FeedIngredientPricingMethodology>;
  in?: InputMaybe<Array<FeedIngredientPricingMethodology>>;
  not?: InputMaybe<FeedIngredientPricingMethodology>;
  notIn?: InputMaybe<Array<FeedIngredientPricingMethodology>>;
};

export type FeedIngredientSortByDTO = {
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  FeedCategory?: InputMaybe<FeedCategorySortByDTO>;
  FeedIngredientVersion?: InputMaybe<FeedIngredientVersionSortByDTO>;
  HedgeProduct?: InputMaybe<ProductSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  cmePercentageBasis?: InputMaybe<NullableSortByDirection>;
  cmeUsdBasis?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  dryMatterPercent?: InputMaybe<SortByDirection>;
  feedCategoryId?: InputMaybe<SortByDirection>;
  flatPricePerTon?: InputMaybe<NullableSortByDirection>;
  hedgeProductId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  pricingMethodology?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  versionId?: InputMaybe<SortByDirection>;
  versionedConceptSeriesId?: InputMaybe<SortByDirection>;
};

export type FeedIngredientUpdateDTO = {
  /** The percentage basis/offset from a CME future that will be used to price usage of this FeedIngredient. This value may be used _instead_ of flat pricing or a USD basis */
  cmePercentageBasis?: InputMaybe<Scalars['Float']>;
  /** The USD basis/offset from a CME future that will be used to price usage of this FeedIngredient. This value may be used _instead_ of flat pricing or a percentage basis. This value is a basis from the contract points _before_ any size and/or point-to-usd conversion. */
  cmeUsdBasis?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  dryMatterPercent?: InputMaybe<Scalars['Float']>;
  /** The USD value of 1 ton of DM usage of this FeedIngredient. This value may be used _instead_ of a basis pricing */
  flatPricePerTon?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FeedIngredientUsage = IFeedIngredientUsage & {
  __typename?: 'FeedIngredientUsage';
  Entity: IEntity;
  FeedIngredient: FeedIngredient;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  /** @deprecated This field has been removed */
  basisPrice?: Maybe<Scalars['Float']>;
  /** @deprecated This field has been removed */
  cashPrice?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  /** @deprecated This field has been removed */
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb: Scalars['Float'];
  dmiUsageInTons: Scalars['Float'];
  entityId: Scalars['String'];
  feedIngredientId: Scalars['String'];
  /** A UUID that will be consistent across multiple feed ingredients that have been copied from each other upon the creation of a new version. */
  feedIngredientSeriesId: Scalars['String'];
  feedUsageInLb: Scalars['Float'];
  feedUsageInTons: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** @deprecated Use "dmiUsageInTons" instead */
  quantity: Scalars['Float'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['Int'];
  type: TypeOfFeedIngredientUsage;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type FeedIngredientUsageDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  cashPrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInLb?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInTons?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientSeriesId?: InputMaybe<Scalars['Boolean']>;
  feedUsageInLb?: InputMaybe<Scalars['Boolean']>;
  feedUsageInTons?: InputMaybe<Scalars['Boolean']>;
  forecastedPerLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  forecastedTotalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientUsageFilterDTO = {
  AND?: InputMaybe<Array<FeedIngredientUsageFilterDTO>>;
  AsFeedIngredientActualUsage?: InputMaybe<FeedIngredientActualUsageFilterDTO>;
  AsFeedIngredientForecastedUsage?: InputMaybe<FeedIngredientForecastedUsageFilterDTO>;
  Entity?: InputMaybe<EntityFilterDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientFilterDTO>;
  NOT?: InputMaybe<FeedIngredientUsageFilterDTO>;
  OR?: InputMaybe<Array<FeedIngredientUsageFilterDTO>>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  basisPrice?: InputMaybe<NullableNumberFilter>;
  cashPrice?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  dmiUsageInLb?: InputMaybe<NumberFilter>;
  dmiUsageInTons?: InputMaybe<NumberFilter>;
  entityId?: InputMaybe<StringFilter>;
  feedIngredientId?: InputMaybe<StringFilter>;
  feedIngredientSeriesId?: InputMaybe<StringFilter>;
  feedUsageInLb?: InputMaybe<NumberFilter>;
  feedUsageInTons?: InputMaybe<NumberFilter>;
  forecastedPerLbExpenseInUsd?: InputMaybe<NullableNumberFilter>;
  forecastedTotalExpenseInUsd?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  quantity?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  readableQuarter?: InputMaybe<NumberFilter>;
  type?: InputMaybe<TypeOfFeedIngredientUsageFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type FeedIngredientUsageGroupByDTO = {
  AsFeedIngredientActualUsage?: InputMaybe<FeedIngredientActualUsageGroupByDTO>;
  AsFeedIngredientForecastedUsage?: InputMaybe<FeedIngredientForecastedUsageGroupByDTO>;
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  cashPrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInLb?: InputMaybe<Scalars['Boolean']>;
  dmiUsageInTons?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientSeriesId?: InputMaybe<Scalars['Boolean']>;
  feedUsageInLb?: InputMaybe<Scalars['Boolean']>;
  feedUsageInTons?: InputMaybe<Scalars['Boolean']>;
  forecastedPerLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  forecastedTotalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientUsageSetByMonthInput = {
  datePayloads: Array<FeedIngredientUsageSetByMonthPerDatePayload>;
  entityId: Scalars['String'];
};

export type FeedIngredientUsageSetByMonthPerDatePayload = {
  /** The first date of month for which this amount will be set. */
  date: Scalars['String'];
  /** The total volume of the feed ingredient DMI that is consumed in the given month */
  dmiUsageInLb: Scalars['Float'];
  ingredientId: Scalars['String'];
};

export type FeedIngredientUsageSetDto = {
  basisPrice?: InputMaybe<Scalars['Float']>;
  cashPrice?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['String'];
  entityId: Scalars['String'];
  increment: DateIncrement;
  ingredientId: Scalars['String'];
  quantity: Scalars['Float'];
  startDate: Scalars['String'];
};

export type FeedIngredientUsageSortByDTO = {
  AsFeedIngredientActualUsage?: InputMaybe<FeedIngredientActualUsageSortByDTO>;
  AsFeedIngredientForecastedUsage?: InputMaybe<FeedIngredientForecastedUsageSortByDTO>;
  Entity?: InputMaybe<EntitySortByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientSortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  basisPrice?: InputMaybe<NullableSortByDirection>;
  cashPrice?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  dmiUsageInLb?: InputMaybe<SortByDirection>;
  dmiUsageInTons?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  feedIngredientId?: InputMaybe<SortByDirection>;
  feedIngredientSeriesId?: InputMaybe<SortByDirection>;
  feedUsageInLb?: InputMaybe<SortByDirection>;
  feedUsageInTons?: InputMaybe<SortByDirection>;
  forecastedPerLbExpenseInUsd?: InputMaybe<NullableSortByDirection>;
  forecastedTotalExpenseInUsd?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type FeedIngredientVersion = IVersion & {
  __typename?: 'FeedIngredientVersion';
  Business: CustomerEntity;
  CreatedByUser?: Maybe<User>;
  LastUpdatedByUser?: Maybe<User>;
  /** The business entity (customer entity) on behalf of which this version exists */
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The user that triggered the creation of this version. If this value is null, then this version was created via the system. */
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The final date for which this version is in effect, inclusive. If this value is null, this version is in effect for all dates greater than startDate */
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  /** The user that last updated the concepts linked to this version. When a version is first created, the lastUpdatedByUserId value will equal the user id that triggered the version creation. If this value is null, then this version has only been created & updated via the system. */
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nextVersionId?: Maybe<Scalars['String']>;
  /** The version that preceeds this version. If this value is null, this is the first version for this businessId / type combination. */
  previousVersionId?: Maybe<Scalars['String']>;
  /** The first date for which this version is in effect (inclusive) */
  startDate: Scalars['String'];
  type: TypeOfVersion;
  updatedAt: Scalars['DateTime'];
};

export type FeedIngredientVersionFilterDTO = {
  AND?: InputMaybe<Array<FeedIngredientVersionFilterDTO>>;
  AsVersion?: InputMaybe<VersionFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  FeedIngredients?: InputMaybe<FeedIngredientFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  NOT?: InputMaybe<FeedIngredientVersionFilterDTO>;
  NextVersion?: InputMaybe<VersionFilterDTO>;
  OR?: InputMaybe<Array<FeedIngredientVersionFilterDTO>>;
  PreviousVersion?: InputMaybe<VersionFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isCurrent?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  name?: InputMaybe<StringFilter>;
  nextVersionId?: InputMaybe<NullableStringFilter>;
  previousVersionId?: InputMaybe<NullableStringFilter>;
  startDate?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FeedIngredientVersionGroupByDTO = {
  AsVersion?: InputMaybe<VersionGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  NextVersion?: InputMaybe<VersionGroupByDTO>;
  PreviousVersion?: InputMaybe<VersionGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  nextVersionId?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type FeedIngredientVersionSortByDTO = {
  AsVersion?: InputMaybe<VersionSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  NextVersion?: InputMaybe<VersionSortByDTO>;
  PreviousVersion?: InputMaybe<VersionSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isCurrent?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<SortByDirection>;
  nextVersionId?: InputMaybe<NullableSortByDirection>;
  previousVersionId?: InputMaybe<NullableSortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

/** A combination of both PhysicalFeedTransaction's and Feed-based GrainFillOrders */
export type FeedTransaction = {
  __typename?: 'FeedTransaction';
  Business: CustomerEntity;
  FeedIngredient: FeedIngredient;
  /** When set, indicates a locked in basis (+/- adjustment to some locked in or to-be-locked-in-later future) Can be set in conjunction with a futuresPrice */
  basisPrice?: Maybe<Scalars['Float']>;
  businessId: Scalars['String'];
  contractIdentifier?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deliveryEndDate: Scalars['String'];
  deliveryStartDate: Scalars['String'];
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  feedIngredientId: Scalars['String'];
  /** When explicitly set, indicates a fixed price in USD. When basisPrice & futuresPrice are set, will be calculated as (basisPrice + futuresPrice) */
  flatPrice?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  /** When set, indicates that a futures price has been locked in for this transaction. Can be set in conjunction with a basisPrice */
  futuresPrice?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  perMonthData: Array<Scalars['Object']>;
  pricingType: TypeOfPhysicalFeedTransactionPricing;
  seller?: Maybe<Scalars['String']>;
  tons: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type FeedTransactionDistinctOnDTO = {
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractIdentifier?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  deliveryTerms?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  futuresPrice?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  pricingType?: InputMaybe<Scalars['Boolean']>;
  seller?: InputMaybe<Scalars['Boolean']>;
  tons?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type FeedTransactionFilterDTO = {
  AND?: InputMaybe<Array<FeedTransactionFilterDTO>>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientFilterDTO>;
  NOT?: InputMaybe<FeedTransactionFilterDTO>;
  OR?: InputMaybe<Array<FeedTransactionFilterDTO>>;
  basisPrice?: InputMaybe<NullableNumberFilter>;
  businessId?: InputMaybe<StringFilter>;
  contractIdentifier?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deliveryEndDate?: InputMaybe<StringFilter>;
  deliveryStartDate?: InputMaybe<StringFilter>;
  deliveryTerms?: InputMaybe<NullableGrainOrderDeliveryTermsFilter>;
  feedIngredientId?: InputMaybe<StringFilter>;
  flatPrice?: InputMaybe<NullableNumberFilter>;
  forecastedPnl?: InputMaybe<NullableNumberFilter>;
  futuresPrice?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<NullableStringFilter>;
  pricingType?: InputMaybe<TypeOfPhysicalFeedTransactionPricingFilter>;
  seller?: InputMaybe<NullableStringFilter>;
  tons?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FeedTransactionSortByDTO = {
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientSortByDTO>;
  basisPrice?: InputMaybe<NullableSortByDirection>;
  businessId?: InputMaybe<SortByDirection>;
  contractIdentifier?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deliveryEndDate?: InputMaybe<SortByDirection>;
  deliveryStartDate?: InputMaybe<SortByDirection>;
  deliveryTerms?: InputMaybe<NullableSortByDirection>;
  feedIngredientId?: InputMaybe<SortByDirection>;
  flatPrice?: InputMaybe<NullableSortByDirection>;
  forecastedPnl?: InputMaybe<NullableSortByDirection>;
  futuresPrice?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  location?: InputMaybe<NullableSortByDirection>;
  pricingType?: InputMaybe<SortByDirection>;
  seller?: InputMaybe<NullableSortByDirection>;
  tons?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type Field = {
  __typename?: 'Field';
  Business: CustomerEntity;
  CropHarvests: Array<CropHarvest>;
  acres: Scalars['Float'];
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type Field_CropHarvestsArgs = {
  orderBy?: InputMaybe<CropHarvestSortByDTO>;
  where?: InputMaybe<CropHarvestFilterDTO>;
};

export type FieldAggregateAllDTO = {
  acres?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type FieldAggregateDTO = {
  avg?: InputMaybe<FieldAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<FieldAggregateAllDTO>;
  min?: InputMaybe<FieldAggregateAllDTO>;
  sum?: InputMaybe<FieldAggregateNumericDTO>;
};

export type FieldAggregateNumericDTO = {
  acres?: InputMaybe<Scalars['Boolean']>;
};

export type FieldCreateDTO = {
  acres: Scalars['Float'];
  businessId: Scalars['String'];
  name: Scalars['String'];
};

export type FieldDistinctOnDTO = {
  acres?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type FieldFilterDTO = {
  AND?: InputMaybe<Array<FieldFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  CropHarvests?: InputMaybe<CropHarvestFilterDTO>;
  NOT?: InputMaybe<FieldFilterDTO>;
  OR?: InputMaybe<Array<FieldFilterDTO>>;
  acres?: InputMaybe<NumberFilter>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FieldGroupByDTO = {
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  acres?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type FieldSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  acres?: InputMaybe<SortByDirection>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type FieldUpdateDTO = {
  acres?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FirebaseTokenNotificationSetting = INotificationSetting & {
  __typename?: 'FirebaseTokenNotificationSetting';
  Notification: Array<Notification>;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  /** A unique identifier for the device the fcmRegistrationToken was generated on. For iOS devices, this should be the device's vendor ID. */
  deviceId: Scalars['String'];
  /** A token generated client side via the Firebase Cloud Messaging SDK. Used to send push notifications to native devices via the Firebase Cloud Messaging service. */
  fcmRegistrationToken: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: TypeOfNotification;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type FirebaseTokenNotificationSetting_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type FirebaseTokenNotificationSettingCreateDTO = {
  deviceId?: InputMaybe<Scalars['String']>;
  fcmRegistrationToken: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type FirebaseTokenNotificationSettingFilterDTO = {
  AND?: InputMaybe<Array<FirebaseTokenNotificationSettingFilterDTO>>;
  AsNotificationSetting?: InputMaybe<NotificationSettingFilterDTO>;
  NOT?: InputMaybe<FirebaseTokenNotificationSettingFilterDTO>;
  Notifications?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<FirebaseTokenNotificationSettingFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceId?: InputMaybe<StringFilter>;
  fcmRegistrationToken?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export type FirebaseTokenNotificationSettingSortByDTO = {
  AsNotificationSetting?: InputMaybe<NotificationSettingSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  deviceId?: InputMaybe<SortByDirection>;
  fcmRegistrationToken?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type ForecastedHedgedAndCappedVolume = {
  __typename?: 'ForecastedHedgedAndCappedVolume';
  Entity: IEntity;
  Product: Product;
  contractType: TypeOfContract;
  date: Scalars['String'];
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  hedgeType: TypeOfHedge;
  instrumentType: TypeOfInstrument;
  naturallyLongCapped?: Maybe<Scalars['Float']>;
  naturallyLongHedged?: Maybe<Scalars['Float']>;
  naturallyShortCapped?: Maybe<Scalars['Float']>;
  naturallyShortHedged?: Maybe<Scalars['Float']>;
  optionType?: Maybe<TypeOfOption>;
  productId: Scalars['String'];
};

export type ForecastedHedgedAndCappedVolumeAggregateAllDTO = {
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  naturallyLongCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyLongHedged?: InputMaybe<Scalars['Boolean']>;
  naturallyShortCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyShortHedged?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedHedgedAndCappedVolumeAggregateDTO = {
  avg?: InputMaybe<ForecastedHedgedAndCappedVolumeAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ForecastedHedgedAndCappedVolumeAggregateAllDTO>;
  min?: InputMaybe<ForecastedHedgedAndCappedVolumeAggregateAllDTO>;
  sum?: InputMaybe<ForecastedHedgedAndCappedVolumeAggregateNumericDTO>;
};

export type ForecastedHedgedAndCappedVolumeAggregateNumericDTO = {
  naturallyLongCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyLongHedged?: InputMaybe<Scalars['Boolean']>;
  naturallyShortCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyShortHedged?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedHedgedAndCappedVolumeDistinctOnDTO = {
  contractType?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  hedgeType?: InputMaybe<Scalars['Boolean']>;
  instrumentType?: InputMaybe<Scalars['Boolean']>;
  naturallyLongCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyLongHedged?: InputMaybe<Scalars['Boolean']>;
  naturallyShortCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyShortHedged?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedHedgedAndCappedVolumeFilterDTO = {
  AND?: InputMaybe<Array<ForecastedHedgedAndCappedVolumeFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<ForecastedHedgedAndCappedVolumeFilterDTO>;
  OR?: InputMaybe<Array<ForecastedHedgedAndCappedVolumeFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  contractType?: InputMaybe<TypeOfContractFilter>;
  date?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  hedgeType?: InputMaybe<TypeOfHedgeFilter>;
  instrumentType?: InputMaybe<TypeOfInstrumentFilter>;
  naturallyLongCapped?: InputMaybe<NullableNumberFilter>;
  naturallyLongHedged?: InputMaybe<NullableNumberFilter>;
  naturallyShortCapped?: InputMaybe<NullableNumberFilter>;
  naturallyShortHedged?: InputMaybe<NullableNumberFilter>;
  optionType?: InputMaybe<NullableTypeOfOptionFilter>;
  productId?: InputMaybe<StringFilter>;
};

export type ForecastedHedgedAndCappedVolumeGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  contractType?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  hedgeType?: InputMaybe<Scalars['Boolean']>;
  instrumentType?: InputMaybe<Scalars['Boolean']>;
  naturallyLongCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyLongHedged?: InputMaybe<Scalars['Boolean']>;
  naturallyShortCapped?: InputMaybe<Scalars['Boolean']>;
  naturallyShortHedged?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedHedgedAndCappedVolumeSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  contractType?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  hedgeType?: InputMaybe<SortByDirection>;
  instrumentType?: InputMaybe<SortByDirection>;
  naturallyLongCapped?: InputMaybe<NullableSortByDirection>;
  naturallyLongHedged?: InputMaybe<NullableSortByDirection>;
  naturallyShortCapped?: InputMaybe<NullableSortByDirection>;
  naturallyShortHedged?: InputMaybe<NullableSortByDirection>;
  optionType?: InputMaybe<NullableSortByDirection>;
  productId?: InputMaybe<SortByDirection>;
};

export type ForecastedLivestockFeedUsage = IForecastedLivestockFeedUsage & {
  __typename?: 'ForecastedLivestockFeedUsage';
  Business: CustomerEntity;
  Ingredient: FeedIngredient;
  LivestockGroupFeedUsage: LivestockGroupFeedUsage;
  /** Generated field - This value will have a starting index of 1; e.g. the age in weeks in the week of the dob will be 1 */
  ageInWeeks: Scalars['Int'];
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  dob: Scalars['String'];
  /** Generated field - Will be used to aggregate forecasted feed usage by month. YYYY-MM-DD */
  firstDateOfMonth: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  hedgePriceInUsd: Scalars['Float'];
  id: Scalars['ID'];
  ingredientId: Scalars['String'];
  livestockGroupFeedUsageId: Scalars['String'];
  perLbExpenseInUsd: Scalars['Float'];
  quantityInLbs: Scalars['Float'];
  /** Generated field - (quantityInLbs / 2000) */
  quantityInTons: Scalars['Float'];
  /** Generated field - Contains the week from {date} in a "W[N] [YYYY]" format */
  readableWeek: Scalars['String'];
  /** The total expense of this forecasted usage, with DMI taken into consideration */
  totalExpenseInUsd: Scalars['Float'];
  type: TypeOfForecastedLivestockFeedUsage;
  updatedAt: Scalars['DateTime'];
};

export type ForecastedLivestockFeedUsageDistinctOnDTO = {
  ageInWeeks?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  hedgePriceInUsd?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  ingredientId?: InputMaybe<Scalars['Boolean']>;
  livestockGroupFeedUsageId?: InputMaybe<Scalars['Boolean']>;
  perLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  quantityInLbs?: InputMaybe<Scalars['Boolean']>;
  quantityInTons?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  totalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedLivestockFeedUsageFilterDTO = {
  AND?: InputMaybe<Array<ForecastedLivestockFeedUsageFilterDTO>>;
  AsForecastedSwineLivestockFeedUsage?: InputMaybe<ForecastedSwineLivestockFeedUsageFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  Ingredient?: InputMaybe<FeedIngredientFilterDTO>;
  LivestockGroupFeedUsage?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
  NOT?: InputMaybe<ForecastedLivestockFeedUsageFilterDTO>;
  OR?: InputMaybe<Array<ForecastedLivestockFeedUsageFilterDTO>>;
  ageInWeeks?: InputMaybe<NumberFilter>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  dob?: InputMaybe<StringFilter>;
  firstDateOfMonth?: InputMaybe<StringFilter>;
  hedgePriceInUsd?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  ingredientId?: InputMaybe<StringFilter>;
  livestockGroupFeedUsageId?: InputMaybe<StringFilter>;
  perLbExpenseInUsd?: InputMaybe<NumberFilter>;
  quantityInLbs?: InputMaybe<NumberFilter>;
  quantityInTons?: InputMaybe<NumberFilter>;
  readableWeek?: InputMaybe<StringFilter>;
  totalExpenseInUsd?: InputMaybe<NumberFilter>;
  type?: InputMaybe<TypeOfForecastedLivestockFeedUsageFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ForecastedLivestockFeedUsageGroupByDTO = {
  AsForecastedSwineLivestockFeedUsage?: InputMaybe<ForecastedSwineLivestockFeedUsageGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  Ingredient?: InputMaybe<FeedIngredientGroupByDTO>;
  LivestockGroupFeedUsage?: InputMaybe<LivestockGroupFeedUsageGroupByDTO>;
  LivestockGroupFeedUsageAsSwineLivestockGroupFeedUsage?: InputMaybe<SwineLivestockGroupFeedUsageGroupByDTO>;
  ageInWeeks?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  hedgePriceInUsd?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  ingredientId?: InputMaybe<Scalars['Boolean']>;
  livestockGroupFeedUsageId?: InputMaybe<Scalars['Boolean']>;
  perLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  quantityInLbs?: InputMaybe<Scalars['Boolean']>;
  quantityInTons?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  totalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedLivestockFeedUsageSortByDTO = {
  AsForecastedSwineLivestockFeedUsage?: InputMaybe<ForecastedSwineLivestockFeedUsageSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  Ingredient?: InputMaybe<FeedIngredientSortByDTO>;
  LivestockGroupFeedUsage?: InputMaybe<LivestockGroupFeedUsageSortByDTO>;
  ageInWeeks?: InputMaybe<SortByDirection>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  dob?: InputMaybe<SortByDirection>;
  firstDateOfMonth?: InputMaybe<SortByDirection>;
  hedgePriceInUsd?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  ingredientId?: InputMaybe<SortByDirection>;
  livestockGroupFeedUsageId?: InputMaybe<SortByDirection>;
  perLbExpenseInUsd?: InputMaybe<SortByDirection>;
  quantityInLbs?: InputMaybe<SortByDirection>;
  quantityInTons?: InputMaybe<SortByDirection>;
  readableWeek?: InputMaybe<SortByDirection>;
  totalExpenseInUsd?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type ForecastedMilkProduction = {
  __typename?: 'ForecastedMilkProduction';
  Location: LocationEntity;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  butterfatPercent: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  /** The first date of the month within which the "date" value falls */
  firstDateOfMonth: Scalars['String'];
  grossButterfat: Scalars['Float'];
  grossOtherSolids: Scalars['Float'];
  grossProduction: Scalars['Float'];
  grossProtein: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  locationId: Scalars['String'];
  month: Scalars['Int'];
  numberOfCows: Scalars['Int'];
  otherSolidsPercent: Scalars['Float'];
  productionPerCow: Scalars['Float'];
  proteinPercent: Scalars['Float'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type ForecastedMilkProductionAggregateAllDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossButterfat?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolids?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProtein?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  productionPerCow?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionAggregateDTO = {
  avg?: InputMaybe<ForecastedMilkProductionAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ForecastedMilkProductionAggregateAllDTO>;
  min?: InputMaybe<ForecastedMilkProductionAggregateAllDTO>;
  sum?: InputMaybe<ForecastedMilkProductionAggregateNumericDTO>;
};

export type ForecastedMilkProductionAggregateNumericDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  grossButterfat?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolids?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProtein?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  productionPerCow?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

/** The forecasted milk production for the given entity aggregated by month */
export type ForecastedMilkProductionByMonth = {
  __typename?: 'ForecastedMilkProductionByMonth';
  Entity: IEntity;
  UsdaActualMilkPrice?: Maybe<UsdaActualMilkPrice>;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  averageDailyProductionPerCow: Scalars['Float'];
  butterfatPercent: Scalars['Float'];
  classiUtilization: Scalars['Float'];
  classiiUtilization: Scalars['Float'];
  classiiiUtilization: Scalars['Float'];
  classivUtilization: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  /** The first date of the month for which this production occurs */
  date: Scalars['String'];
  entityId: Scalars['String'];
  /** The average number of cattle that exist per-day during the month */
  exactNumberOfCows: Scalars['Float'];
  /** This value is calculated using USDA-published pricing data, the class i differential, the location's monthly milk utilizations, and the computed component differential. This value will be null if any of the following are not yet available for the date/region: "USDA Actual Class Milk Prices" "USDA National Component Prices" */
  forecastedBlendedMilkPrice?: Maybe<Scalars['Float']>;
  grossButterfatProduction: Scalars['Float'];
  grossClassiProduction: Scalars['Float'];
  grossClassiiProduction: Scalars['Float'];
  grossClassiiiProduction: Scalars['Float'];
  grossClassivProduction: Scalars['Float'];
  grossOtherSolidsProduction: Scalars['Float'];
  /** Total milk production for the given entity + month, in lbs */
  grossProduction: Scalars['Float'];
  grossProteinProduction: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** The average number of cattle that exist per-day during the month, rounded to the nearest int */
  numberOfCows: Scalars['Int'];
  otherSolidsPercent: Scalars['Float'];
  proteinPercent: Scalars['Float'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type ForecastedMilkProductionByMonthAggregateAllDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  averageDailyProductionPerCow?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classivUtilization?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  forecastedBlendedMilkPrice?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassivProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionByMonthAggregateDTO = {
  avg?: InputMaybe<ForecastedMilkProductionByMonthAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ForecastedMilkProductionByMonthAggregateAllDTO>;
  min?: InputMaybe<ForecastedMilkProductionByMonthAggregateAllDTO>;
  sum?: InputMaybe<ForecastedMilkProductionByMonthAggregateNumericDTO>;
};

export type ForecastedMilkProductionByMonthAggregateNumericDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  averageDailyProductionPerCow?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classivUtilization?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  forecastedBlendedMilkPrice?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassivProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionByMonthDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  averageDailyProductionPerCow?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classivUtilization?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  forecastedBlendedMilkPrice?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassivProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionByMonthFilterDTO = {
  AND?: InputMaybe<Array<ForecastedMilkProductionByMonthFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<ForecastedMilkProductionByMonthFilterDTO>;
  OR?: InputMaybe<Array<ForecastedMilkProductionByMonthFilterDTO>>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  averageDailyProductionPerCow?: InputMaybe<NumberFilter>;
  butterfatPercent?: InputMaybe<NumberFilter>;
  classiUtilization?: InputMaybe<NumberFilter>;
  classiiUtilization?: InputMaybe<NumberFilter>;
  classiiiUtilization?: InputMaybe<NumberFilter>;
  classivUtilization?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  exactNumberOfCows?: InputMaybe<NumberFilter>;
  forecastedBlendedMilkPrice?: InputMaybe<NullableNumberFilter>;
  grossButterfatProduction?: InputMaybe<NumberFilter>;
  grossClassiProduction?: InputMaybe<NumberFilter>;
  grossClassiiProduction?: InputMaybe<NumberFilter>;
  grossClassiiiProduction?: InputMaybe<NumberFilter>;
  grossClassivProduction?: InputMaybe<NumberFilter>;
  grossOtherSolidsProduction?: InputMaybe<NumberFilter>;
  grossProduction?: InputMaybe<NumberFilter>;
  grossProteinProduction?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  numberOfCows?: InputMaybe<NumberFilter>;
  otherSolidsPercent?: InputMaybe<NumberFilter>;
  proteinPercent?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  readableQuarter?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type ForecastedMilkProductionByMonthGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  averageDailyProductionPerCow?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classivUtilization?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  forecastedBlendedMilkPrice?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassivProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

/** The ForecastedMilkProductionByMonth value for this entity as well as all of its children, aggregated per month. */
export type ForecastedMilkProductionByMonthRolledUp = {
  __typename?: 'ForecastedMilkProductionByMonthRolledUp';
  Entity: IEntity;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  /** The first date of the month for which this production occurs */
  date: Scalars['String'];
  entityId: Scalars['String'];
  /** The sum of the per-entity average number of cattle that exist per-day during the month */
  exactNumberOfCows: Scalars['Float'];
  grossButterfatProduction: Scalars['Float'];
  grossClassiProduction: Scalars['Float'];
  grossClassiiProduction: Scalars['Float'];
  grossClassiiiProduction: Scalars['Float'];
  grossClassivProduction: Scalars['Float'];
  grossOtherSolidsProduction: Scalars['Float'];
  /** Total milk production for the given entity + month, in lbs */
  grossProduction: Scalars['Float'];
  grossProteinProduction: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** The sum of ther per-entity average number of cattle that exist per-day during the month, rounded to the nearest int */
  numberOfCows: Scalars['Int'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['String'];
  year: Scalars['Int'];
};

export type ForecastedMilkProductionByMonthRolledUpAggregateAllDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassivProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionByMonthRolledUpAggregateDTO = {
  avg?: InputMaybe<ForecastedMilkProductionByMonthRolledUpAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ForecastedMilkProductionByMonthRolledUpAggregateAllDTO>;
  min?: InputMaybe<ForecastedMilkProductionByMonthRolledUpAggregateAllDTO>;
  sum?: InputMaybe<ForecastedMilkProductionByMonthRolledUpAggregateNumericDTO>;
};

export type ForecastedMilkProductionByMonthRolledUpAggregateNumericDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassivProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionByMonthRolledUpDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassivProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionByMonthRolledUpFilterDTO = {
  AND?: InputMaybe<Array<ForecastedMilkProductionByMonthRolledUpFilterDTO>>;
  Entity?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<ForecastedMilkProductionByMonthRolledUpFilterDTO>;
  OR?: InputMaybe<Array<ForecastedMilkProductionByMonthRolledUpFilterDTO>>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  date?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  exactNumberOfCows?: InputMaybe<NumberFilter>;
  grossButterfatProduction?: InputMaybe<NumberFilter>;
  grossClassiProduction?: InputMaybe<NumberFilter>;
  grossClassiiProduction?: InputMaybe<NumberFilter>;
  grossClassiiiProduction?: InputMaybe<NumberFilter>;
  grossClassivProduction?: InputMaybe<NumberFilter>;
  grossOtherSolidsProduction?: InputMaybe<NumberFilter>;
  grossProduction?: InputMaybe<NumberFilter>;
  grossProteinProduction?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  numberOfCows?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  readableQuarter?: InputMaybe<StringFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type ForecastedMilkProductionByMonthRolledUpGroupByDTO = {
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  exactNumberOfCows?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassivProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionByMonthRolledUpSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  exactNumberOfCows?: InputMaybe<SortByDirection>;
  grossButterfatProduction?: InputMaybe<SortByDirection>;
  grossClassiProduction?: InputMaybe<SortByDirection>;
  grossClassiiProduction?: InputMaybe<SortByDirection>;
  grossClassiiiProduction?: InputMaybe<SortByDirection>;
  grossClassivProduction?: InputMaybe<SortByDirection>;
  grossOtherSolidsProduction?: InputMaybe<SortByDirection>;
  grossProduction?: InputMaybe<SortByDirection>;
  grossProteinProduction?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  numberOfCows?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type ForecastedMilkProductionByMonthSortByDTO = {
  Entity?: InputMaybe<EntitySortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  averageDailyProductionPerCow?: InputMaybe<SortByDirection>;
  butterfatPercent?: InputMaybe<SortByDirection>;
  classiUtilization?: InputMaybe<SortByDirection>;
  classiiUtilization?: InputMaybe<SortByDirection>;
  classiiiUtilization?: InputMaybe<SortByDirection>;
  classivUtilization?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  exactNumberOfCows?: InputMaybe<SortByDirection>;
  forecastedBlendedMilkPrice?: InputMaybe<NullableSortByDirection>;
  grossButterfatProduction?: InputMaybe<SortByDirection>;
  grossClassiProduction?: InputMaybe<SortByDirection>;
  grossClassiiProduction?: InputMaybe<SortByDirection>;
  grossClassiiiProduction?: InputMaybe<SortByDirection>;
  grossClassivProduction?: InputMaybe<SortByDirection>;
  grossOtherSolidsProduction?: InputMaybe<SortByDirection>;
  grossProduction?: InputMaybe<SortByDirection>;
  grossProteinProduction?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  numberOfCows?: InputMaybe<SortByDirection>;
  otherSolidsPercent?: InputMaybe<SortByDirection>;
  proteinPercent?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type ForecastedMilkProductionCoverage = {
  __typename?: 'ForecastedMilkProductionCoverage';
  CustomerEntity: CustomerEntity;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  butterfatPounds?: Maybe<Scalars['Float']>;
  classIiiPounds?: Maybe<Scalars['Float']>;
  classIvPounds?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  entityId: Scalars['String'];
  /** @deprecated Use "forecastedPnl" instead */
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  /** @deprecated This field is no longer provided */
  excessProteinPoundsAfterCheese: Scalars['Float'];
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossClassIProduction?: Maybe<Scalars['Float']>;
  grossClassIiProduction?: Maybe<Scalars['Float']>;
  grossClassIiiProduction?: Maybe<Scalars['Float']>;
  grossClassIvProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  /** @deprecated Use "forecastedIndemnity" instead */
  indemnityAmount?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  percentInsured?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  quarterStartDate: Scalars['String'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['String'];
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  year: Scalars['Int'];
};

export type ForecastedMilkProductionCoverageAggregateAllDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  classIiiPounds?: InputMaybe<Scalars['Boolean']>;
  classIvPounds?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIvProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  percentInsured?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  quarterStartDate?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionCoverageAggregateDTO = {
  avg?: InputMaybe<ForecastedMilkProductionCoverageAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ForecastedMilkProductionCoverageAggregateAllDTO>;
  min?: InputMaybe<ForecastedMilkProductionCoverageAggregateAllDTO>;
  sum?: InputMaybe<ForecastedMilkProductionCoverageAggregateNumericDTO>;
};

export type ForecastedMilkProductionCoverageAggregateNumericDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  classIiiPounds?: InputMaybe<Scalars['Boolean']>;
  classIvPounds?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIvProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  percentInsured?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionCoverageDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  classIiiPounds?: InputMaybe<Scalars['Boolean']>;
  classIvPounds?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIvProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  percentInsured?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  quarterStartDate?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionCoverageFilterDTO = {
  AND?: InputMaybe<Array<ForecastedMilkProductionCoverageFilterDTO>>;
  CustomerEntity?: InputMaybe<CustomerEntityFilterDTO>;
  InsurancePolicies?: InputMaybe<InsurancePolicyFilterDTO>;
  NOT?: InputMaybe<ForecastedMilkProductionCoverageFilterDTO>;
  OR?: InputMaybe<Array<ForecastedMilkProductionCoverageFilterDTO>>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  butterfatPounds?: InputMaybe<NullableNumberFilter>;
  classIiiPounds?: InputMaybe<NullableNumberFilter>;
  classIvPounds?: InputMaybe<NullableNumberFilter>;
  effectiveCoveredMilkProduction?: InputMaybe<NullableNumberFilter>;
  /** An input used to determine the final date for which values will be included in the calculation. This value will be rounded to the final date of the quarter in which it falls. */
  endDate: Scalars['String'];
  entityId?: InputMaybe<StringFilter>;
  estimatedPl?: InputMaybe<NullableNumberFilter>;
  excessButterPounds?: InputMaybe<NullableNumberFilter>;
  excessCheesePounds?: InputMaybe<NullableNumberFilter>;
  excessProteinPoundsAfterCheese?: InputMaybe<NumberFilter>;
  forecastedIndemnity?: InputMaybe<NullableNumberFilter>;
  forecastedPnl?: InputMaybe<NullableNumberFilter>;
  grossButterfatProduction?: InputMaybe<NullableNumberFilter>;
  grossClassIProduction?: InputMaybe<NullableNumberFilter>;
  grossClassIiProduction?: InputMaybe<NullableNumberFilter>;
  grossClassIiiProduction?: InputMaybe<NullableNumberFilter>;
  grossClassIvProduction?: InputMaybe<NullableNumberFilter>;
  grossOtherSolidsProduction?: InputMaybe<NullableNumberFilter>;
  grossProduction?: InputMaybe<NullableNumberFilter>;
  grossProteinProduction?: InputMaybe<NullableNumberFilter>;
  indemnityAmount?: InputMaybe<NullableNumberFilter>;
  nonfatSolidsPounds?: InputMaybe<NullableNumberFilter>;
  otherSolidsPounds?: InputMaybe<NullableNumberFilter>;
  percentInsured?: InputMaybe<NullableNumberFilter>;
  producerPremiumAmount?: InputMaybe<NullableNumberFilter>;
  proteinPounds?: InputMaybe<NullableNumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  quarterStartDate?: InputMaybe<StringFilter>;
  readableQuarter?: InputMaybe<StringFilter>;
  /** An input used to determine the first date for which values will be included in the calculation. This value will be rounded to the first date of the quarter in which it falls. */
  startDate: Scalars['String'];
  totalPremiumAmount?: InputMaybe<NullableNumberFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type ForecastedMilkProductionCoverageGroupByDTO = {
  CustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  classIiiPounds?: InputMaybe<Scalars['Boolean']>;
  classIvPounds?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  grossButterfatProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIiiProduction?: InputMaybe<Scalars['Boolean']>;
  grossClassIvProduction?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolidsProduction?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProteinProduction?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  percentInsured?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  quarterStartDate?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionCoverageSortByDTO = {
  CustomerEntity?: InputMaybe<CustomerEntitySortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  butterfatPounds?: InputMaybe<NullableSortByDirection>;
  classIiiPounds?: InputMaybe<NullableSortByDirection>;
  classIvPounds?: InputMaybe<NullableSortByDirection>;
  effectiveCoveredMilkProduction?: InputMaybe<NullableSortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  estimatedPl?: InputMaybe<NullableSortByDirection>;
  excessButterPounds?: InputMaybe<NullableSortByDirection>;
  excessCheesePounds?: InputMaybe<NullableSortByDirection>;
  excessProteinPoundsAfterCheese?: InputMaybe<SortByDirection>;
  forecastedIndemnity?: InputMaybe<NullableSortByDirection>;
  forecastedPnl?: InputMaybe<NullableSortByDirection>;
  grossButterfatProduction?: InputMaybe<NullableSortByDirection>;
  grossClassIProduction?: InputMaybe<NullableSortByDirection>;
  grossClassIiProduction?: InputMaybe<NullableSortByDirection>;
  grossClassIiiProduction?: InputMaybe<NullableSortByDirection>;
  grossClassIvProduction?: InputMaybe<NullableSortByDirection>;
  grossOtherSolidsProduction?: InputMaybe<NullableSortByDirection>;
  grossProduction?: InputMaybe<NullableSortByDirection>;
  grossProteinProduction?: InputMaybe<NullableSortByDirection>;
  indemnityAmount?: InputMaybe<NullableSortByDirection>;
  nonfatSolidsPounds?: InputMaybe<NullableSortByDirection>;
  otherSolidsPounds?: InputMaybe<NullableSortByDirection>;
  percentInsured?: InputMaybe<NullableSortByDirection>;
  producerPremiumAmount?: InputMaybe<NullableSortByDirection>;
  proteinPounds?: InputMaybe<NullableSortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  quarterStartDate?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  totalPremiumAmount?: InputMaybe<NullableSortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type ForecastedMilkProductionDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossButterfat?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolids?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProtein?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  productionPerCow?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionFilterDTO = {
  AND?: InputMaybe<Array<ForecastedMilkProductionFilterDTO>>;
  Location?: InputMaybe<LocationEntityFilterDTO>;
  NOT?: InputMaybe<ForecastedMilkProductionFilterDTO>;
  OR?: InputMaybe<Array<ForecastedMilkProductionFilterDTO>>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  butterfatPercent?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  firstDateOfMonth?: InputMaybe<StringFilter>;
  grossButterfat?: InputMaybe<NumberFilter>;
  grossOtherSolids?: InputMaybe<NumberFilter>;
  grossProduction?: InputMaybe<NumberFilter>;
  grossProtein?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  numberOfCows?: InputMaybe<NumberFilter>;
  otherSolidsPercent?: InputMaybe<NumberFilter>;
  productionPerCow?: InputMaybe<NumberFilter>;
  proteinPercent?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  readableQuarter?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type ForecastedMilkProductionGroupByDTO = {
  Location?: InputMaybe<LocationEntityGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  butterfatPercent?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossButterfat?: InputMaybe<Scalars['Boolean']>;
  grossOtherSolids?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  grossProtein?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  numberOfCows?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  productionPerCow?: InputMaybe<Scalars['Boolean']>;
  proteinPercent?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkProductionSetDTO = {
  butterfatPercent?: InputMaybe<Scalars['Float']>;
  locationId: Scalars['String'];
  numberOfCows: Scalars['Int'];
  otherSolidsPercent?: InputMaybe<Scalars['Float']>;
  production: Scalars['Float'];
  proteinPercent?: InputMaybe<Scalars['Float']>;
};

export type ForecastedMilkProductionSortByDTO = {
  Location?: InputMaybe<LocationEntitySortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  butterfatPercent?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  firstDateOfMonth?: InputMaybe<SortByDirection>;
  grossButterfat?: InputMaybe<SortByDirection>;
  grossOtherSolids?: InputMaybe<SortByDirection>;
  grossProduction?: InputMaybe<SortByDirection>;
  grossProtein?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  locationId?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  numberOfCows?: InputMaybe<SortByDirection>;
  otherSolidsPercent?: InputMaybe<SortByDirection>;
  productionPerCow?: InputMaybe<SortByDirection>;
  proteinPercent?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

/** **This field is deprecated! This has been deprecated in favor of ForecastedMilkProductionByMonth** */
export type ForecastedMilkUtilization = {
  __typename?: 'ForecastedMilkUtilization';
  classiDifferential: Scalars['Float'];
  classiUtilization?: Maybe<Scalars['Float']>;
  classiiUtilization?: Maybe<Scalars['Float']>;
  classiiiUtilization?: Maybe<Scalars['Float']>;
  classivUtilization?: Maybe<Scalars['Float']>;
  countyId: Scalars['String'];
  date: Scalars['String'];
  grossClassiDifferentialPrice?: Maybe<Scalars['Float']>;
  grossClassiPounds?: Maybe<Scalars['Float']>;
  grossClassiiPounds?: Maybe<Scalars['Float']>;
  grossClassiiiPounds?: Maybe<Scalars['Float']>;
  grossClassivPounds?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  locationId: Scalars['String'];
  month: Scalars['Int'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  year: Scalars['Int'];
};

export type ForecastedMilkUtilizationAggregateAllDTO = {
  classiDifferential?: InputMaybe<Scalars['Boolean']>;
  classiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classivUtilization?: InputMaybe<Scalars['Boolean']>;
  countyId?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossClassiDifferentialPrice?: InputMaybe<Scalars['Boolean']>;
  grossClassiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassiiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassivPounds?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkUtilizationAggregateDTO = {
  avg?: InputMaybe<ForecastedMilkUtilizationAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ForecastedMilkUtilizationAggregateAllDTO>;
  min?: InputMaybe<ForecastedMilkUtilizationAggregateAllDTO>;
  sum?: InputMaybe<ForecastedMilkUtilizationAggregateNumericDTO>;
};

export type ForecastedMilkUtilizationAggregateNumericDTO = {
  classiDifferential?: InputMaybe<Scalars['Boolean']>;
  classiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classivUtilization?: InputMaybe<Scalars['Boolean']>;
  grossClassiDifferentialPrice?: InputMaybe<Scalars['Boolean']>;
  grossClassiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassiiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassivPounds?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkUtilizationDataFilterDTO = {
  AND?: InputMaybe<Array<ForecastedMilkUtilizationDataFilterDTO>>;
  Location?: InputMaybe<LocationEntityFilterDTO>;
  NOT?: InputMaybe<ForecastedMilkUtilizationDataFilterDTO>;
  OR?: InputMaybe<Array<ForecastedMilkUtilizationDataFilterDTO>>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  classiUtilization?: InputMaybe<NumberFilter>;
  classiiUtilization?: InputMaybe<NumberFilter>;
  classiiiUtilization?: InputMaybe<NumberFilter>;
  classivUtilization?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringFilter>;
  readableQuarter?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ForecastedMilkUtilizationDistinctOnDTO = {
  classiDifferential?: InputMaybe<Scalars['Boolean']>;
  classiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classivUtilization?: InputMaybe<Scalars['Boolean']>;
  countyId?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossClassiDifferentialPrice?: InputMaybe<Scalars['Boolean']>;
  grossClassiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassiiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassivPounds?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkUtilizationFilterDTO = {
  AND?: InputMaybe<Array<ForecastedMilkUtilizationFilterDTO>>;
  County?: InputMaybe<CountyFilterDTO>;
  Location?: InputMaybe<LocationEntityFilterDTO>;
  NOT?: InputMaybe<ForecastedMilkUtilizationFilterDTO>;
  OR?: InputMaybe<Array<ForecastedMilkUtilizationFilterDTO>>;
  classiDifferential?: InputMaybe<NumberFilter>;
  classiUtilization?: InputMaybe<NullableNumberFilter>;
  classiiUtilization?: InputMaybe<NullableNumberFilter>;
  classiiiUtilization?: InputMaybe<NullableNumberFilter>;
  classivUtilization?: InputMaybe<NullableNumberFilter>;
  countyId?: InputMaybe<StringFilter>;
  date?: InputMaybe<StringFilter>;
  grossClassiDifferentialPrice?: InputMaybe<NullableNumberFilter>;
  grossClassiPounds?: InputMaybe<NullableNumberFilter>;
  grossClassiiPounds?: InputMaybe<NullableNumberFilter>;
  grossClassiiiPounds?: InputMaybe<NullableNumberFilter>;
  grossClassivPounds?: InputMaybe<NullableNumberFilter>;
  grossProduction?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type ForecastedMilkUtilizationGroupByDTO = {
  County?: InputMaybe<CountyGroupByDTO>;
  Location?: InputMaybe<LocationEntityGroupByDTO>;
  classiDifferential?: InputMaybe<Scalars['Boolean']>;
  classiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilization?: InputMaybe<Scalars['Boolean']>;
  classivUtilization?: InputMaybe<Scalars['Boolean']>;
  countyId?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossClassiDifferentialPrice?: InputMaybe<Scalars['Boolean']>;
  grossClassiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassiiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassiiiPounds?: InputMaybe<Scalars['Boolean']>;
  grossClassivPounds?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedMilkUtilizationSetDTO = {
  classiUtilization: Scalars['Float'];
  classiiUtilization: Scalars['Float'];
  classiiiUtilization: Scalars['Float'];
  classivUtilization: Scalars['Float'];
  date: Scalars['String'];
  locationId: Scalars['String'];
};

export type ForecastedMilkUtilizationSetManyDTO = {
  classiUtilization: Scalars['Float'];
  classiiUtilization: Scalars['Float'];
  classiiiUtilization: Scalars['Float'];
  classivUtilization: Scalars['Float'];
  dates: Array<Scalars['String']>;
  locationId: Scalars['String'];
};

export type ForecastedMilkUtilizationSortByDTO = {
  County?: InputMaybe<CountySortByDTO>;
  Location?: InputMaybe<LocationEntitySortByDTO>;
  classiDifferential?: InputMaybe<SortByDirection>;
  classiUtilization?: InputMaybe<NullableSortByDirection>;
  classiiUtilization?: InputMaybe<NullableSortByDirection>;
  classiiiUtilization?: InputMaybe<NullableSortByDirection>;
  classivUtilization?: InputMaybe<NullableSortByDirection>;
  countyId?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  grossClassiDifferentialPrice?: InputMaybe<NullableSortByDirection>;
  grossClassiPounds?: InputMaybe<NullableSortByDirection>;
  grossClassiiPounds?: InputMaybe<NullableSortByDirection>;
  grossClassiiiPounds?: InputMaybe<NullableSortByDirection>;
  grossClassivPounds?: InputMaybe<NullableSortByDirection>;
  grossProduction?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  locationId?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type ForecastedProduction = {
  __typename?: 'ForecastedProduction';
  Location: LocationEntity;
  Product: Product;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  /** The first date of the month within which the "date" value falls */
  firstDateOfMonth: Scalars['String'];
  grossProduction: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  locationId: Scalars['String'];
  month: Scalars['Int'];
  productId: Scalars['String'];
  productionPerUnit: Scalars['Float'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['String'];
  units: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type ForecastedProductionAggregateAllDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionPerUnit?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedProductionAggregateDTO = {
  avg?: InputMaybe<ForecastedProductionAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ForecastedProductionAggregateAllDTO>;
  min?: InputMaybe<ForecastedProductionAggregateAllDTO>;
  sum?: InputMaybe<ForecastedProductionAggregateNumericDTO>;
};

export type ForecastedProductionAggregateNumericDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  productionPerUnit?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedProductionDistinctOnDTO = {
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionPerUnit?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedProductionFilterDTO = {
  AND?: InputMaybe<Array<ForecastedProductionFilterDTO>>;
  Location?: InputMaybe<LocationEntityFilterDTO>;
  NOT?: InputMaybe<ForecastedProductionFilterDTO>;
  OR?: InputMaybe<Array<ForecastedProductionFilterDTO>>;
  Products?: InputMaybe<ProductFilterDTO>;
  absoluteQuarter?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  firstDateOfMonth?: InputMaybe<StringFilter>;
  grossProduction?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  productId?: InputMaybe<StringFilter>;
  productionPerUnit?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  readableQuarter?: InputMaybe<StringFilter>;
  units?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type ForecastedProductionGroupByDTO = {
  Location?: InputMaybe<LocationEntityGroupByDTO>;
  Products?: InputMaybe<ProductGroupByDTO>;
  absoluteQuarter?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  grossProduction?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productionPerUnit?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  readableQuarter?: InputMaybe<Scalars['Boolean']>;
  units?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedProductionSetDTO = {
  locationId: Scalars['String'];
  productId: Scalars['String'];
  production: Scalars['Float'];
  units: Scalars['Float'];
};

export type ForecastedProductionSortByDTO = {
  Location?: InputMaybe<LocationEntitySortByDTO>;
  Products?: InputMaybe<ProductSortByDTO>;
  absoluteQuarter?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  firstDateOfMonth?: InputMaybe<SortByDirection>;
  grossProduction?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  locationId?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productionPerUnit?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  readableQuarter?: InputMaybe<SortByDirection>;
  units?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type ForecastedSwineLivestockFeedUsage = IForecastedLivestockFeedUsage & {
  __typename?: 'ForecastedSwineLivestockFeedUsage';
  Business: CustomerEntity;
  Ingredient: FeedIngredient;
  LivestockGroupFeedUsage: LivestockGroupFeedUsage;
  /** Generated field - This value will have a starting index of 1; e.g. the age in weeks in the week of the dob will be 1 */
  ageInWeeks: Scalars['Int'];
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  dob: Scalars['String'];
  /** Generated field - Will be used to aggregate forecasted feed usage by month. YYYY-MM-DD */
  firstDateOfMonth: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  hedgePriceInUsd: Scalars['Float'];
  id: Scalars['ID'];
  ingredientId: Scalars['String'];
  livestockGroupFeedUsageId: Scalars['String'];
  perLbExpenseInUsd: Scalars['Float'];
  quantityInLbs: Scalars['Float'];
  /** Generated field - (quantityInLbs / 2000) */
  quantityInTons: Scalars['Float'];
  /** Generated field - Contains the week from {date} in a "W[N] [YYYY]" format */
  readableWeek: Scalars['String'];
  /** The total expense of this forecasted usage, with DMI taken into consideration */
  totalExpenseInUsd: Scalars['Float'];
  type: TypeOfForecastedLivestockFeedUsage;
  updatedAt: Scalars['DateTime'];
};

export type ForecastedSwineLivestockFeedUsageAggregateAllDTO = {
  ageInWeeks?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  hedgePriceInUsd?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  ingredientId?: InputMaybe<Scalars['Boolean']>;
  livestockGroupFeedUsageId?: InputMaybe<Scalars['Boolean']>;
  perLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  quantityInLbs?: InputMaybe<Scalars['Boolean']>;
  quantityInTons?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  totalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedSwineLivestockFeedUsageAggregateDTO = {
  avg?: InputMaybe<ForecastedSwineLivestockFeedUsageAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ForecastedSwineLivestockFeedUsageAggregateAllDTO>;
  min?: InputMaybe<ForecastedSwineLivestockFeedUsageAggregateAllDTO>;
  sum?: InputMaybe<ForecastedSwineLivestockFeedUsageAggregateNumericDTO>;
};

export type ForecastedSwineLivestockFeedUsageAggregateNumericDTO = {
  ageInWeeks?: InputMaybe<Scalars['Boolean']>;
  hedgePriceInUsd?: InputMaybe<Scalars['Boolean']>;
  perLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  quantityInLbs?: InputMaybe<Scalars['Boolean']>;
  quantityInTons?: InputMaybe<Scalars['Boolean']>;
  totalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedSwineLivestockFeedUsageAtFinish = {
  __typename?: 'ForecastedSwineLivestockFeedUsageAtFinish';
  businessId: Scalars['String'];
  date: Scalars['String'];
  firstDateOfMonth: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  quantityInLbs: Scalars['Float'];
  quantityInTons: Scalars['Float'];
  totalExpenseInUsd: Scalars['Float'];
};

export type ForecastedSwineLivestockFeedUsageAtFinishAggregateAllDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  quantityInLbs?: InputMaybe<Scalars['Boolean']>;
  quantityInTons?: InputMaybe<Scalars['Boolean']>;
  totalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedSwineLivestockFeedUsageAtFinishAggregateDTO = {
  avg?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishAggregateAllDTO>;
  min?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishAggregateAllDTO>;
  sum?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishAggregateNumericDTO>;
};

export type ForecastedSwineLivestockFeedUsageAtFinishAggregateNumericDTO = {
  quantityInLbs?: InputMaybe<Scalars['Boolean']>;
  quantityInTons?: InputMaybe<Scalars['Boolean']>;
  totalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedSwineLivestockFeedUsageAtFinishDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  quantityInLbs?: InputMaybe<Scalars['Boolean']>;
  quantityInTons?: InputMaybe<Scalars['Boolean']>;
  totalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedSwineLivestockFeedUsageAtFinishFilterDTO = {
  AND?: InputMaybe<Array<ForecastedSwineLivestockFeedUsageAtFinishFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  NOT?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishFilterDTO>;
  OR?: InputMaybe<Array<ForecastedSwineLivestockFeedUsageAtFinishFilterDTO>>;
  businessId?: InputMaybe<StringFilter>;
  date?: InputMaybe<StringFilter>;
  firstDateOfMonth?: InputMaybe<StringFilter>;
  quantityInLbs?: InputMaybe<NumberFilter>;
  quantityInTons?: InputMaybe<NumberFilter>;
  totalExpenseInUsd?: InputMaybe<NumberFilter>;
};

export type ForecastedSwineLivestockFeedUsageAtFinishGroupByDTO = {
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  quantityInLbs?: InputMaybe<Scalars['Boolean']>;
  quantityInTons?: InputMaybe<Scalars['Boolean']>;
  totalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedSwineLivestockFeedUsageAtFinishSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  firstDateOfMonth?: InputMaybe<SortByDirection>;
  quantityInLbs?: InputMaybe<SortByDirection>;
  quantityInTons?: InputMaybe<SortByDirection>;
  totalExpenseInUsd?: InputMaybe<SortByDirection>;
};

export type ForecastedSwineLivestockFeedUsageDistinctOnDTO = {
  ageInWeeks?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  hedgePriceInUsd?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  ingredientId?: InputMaybe<Scalars['Boolean']>;
  livestockGroupFeedUsageId?: InputMaybe<Scalars['Boolean']>;
  perLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  quantityInLbs?: InputMaybe<Scalars['Boolean']>;
  quantityInTons?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  totalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedSwineLivestockFeedUsageFilterDTO = {
  AND?: InputMaybe<Array<ForecastedSwineLivestockFeedUsageFilterDTO>>;
  AsForecastedLivestockFeedUsage?: InputMaybe<ForecastedLivestockFeedUsageFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  Ingredient?: InputMaybe<FeedIngredientFilterDTO>;
  LivestockGroupFeedUsage?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
  NOT?: InputMaybe<ForecastedSwineLivestockFeedUsageFilterDTO>;
  OR?: InputMaybe<Array<ForecastedSwineLivestockFeedUsageFilterDTO>>;
  ageInWeeks?: InputMaybe<NumberFilter>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  dob?: InputMaybe<StringFilter>;
  firstDateOfMonth?: InputMaybe<StringFilter>;
  hedgePriceInUsd?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  ingredientId?: InputMaybe<StringFilter>;
  livestockGroupFeedUsageId?: InputMaybe<StringFilter>;
  perLbExpenseInUsd?: InputMaybe<NumberFilter>;
  quantityInLbs?: InputMaybe<NumberFilter>;
  quantityInTons?: InputMaybe<NumberFilter>;
  readableWeek?: InputMaybe<StringFilter>;
  totalExpenseInUsd?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ForecastedSwineLivestockFeedUsageGroupByDTO = {
  AsForecastedLivestockFeedUsage?: InputMaybe<ForecastedLivestockFeedUsageGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  Ingredient?: InputMaybe<FeedIngredientGroupByDTO>;
  LivestockGroupFeedUsage?: InputMaybe<LivestockGroupFeedUsageGroupByDTO>;
  LivestockGroupFeedUsageAsSwineLivestockGroupFeedUsage?: InputMaybe<SwineLivestockGroupFeedUsageGroupByDTO>;
  ageInWeeks?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  firstDateOfMonth?: InputMaybe<Scalars['Boolean']>;
  hedgePriceInUsd?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  ingredientId?: InputMaybe<Scalars['Boolean']>;
  livestockGroupFeedUsageId?: InputMaybe<Scalars['Boolean']>;
  perLbExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  quantityInLbs?: InputMaybe<Scalars['Boolean']>;
  quantityInTons?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  totalExpenseInUsd?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ForecastedSwineLivestockFeedUsageSortByDTO = {
  AsForecastedLivestockFeedUsage?: InputMaybe<ForecastedLivestockFeedUsageSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  Ingredient?: InputMaybe<FeedIngredientSortByDTO>;
  LivestockGroupFeedUsage?: InputMaybe<LivestockGroupFeedUsageSortByDTO>;
  ageInWeeks?: InputMaybe<SortByDirection>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  dob?: InputMaybe<SortByDirection>;
  firstDateOfMonth?: InputMaybe<SortByDirection>;
  hedgePriceInUsd?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  ingredientId?: InputMaybe<SortByDirection>;
  livestockGroupFeedUsageId?: InputMaybe<SortByDirection>;
  perLbExpenseInUsd?: InputMaybe<SortByDirection>;
  quantityInLbs?: InputMaybe<SortByDirection>;
  quantityInTons?: InputMaybe<SortByDirection>;
  readableWeek?: InputMaybe<SortByDirection>;
  totalExpenseInUsd?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type ForwardContractAccount = IAccount & {
  __typename?: 'ForwardContractAccount';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  CurrentPositions: Array<CurrentPosition>;
  Customer?: Maybe<CustomerEntity>;
  Notes: Array<AccountNote>;
  Orders: Array<Order>;
  /** @deprecated Use "Owner" instead */
  Organization?: Maybe<OrganizationEntity>;
  Owner?: Maybe<IEntity>;
  Product: Product;
  Transactions: Array<Transaction>;
  accountNumber?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  counterPartyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  inputSource: InputSource;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isClosed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  productId: Scalars['String'];
  type: TypeOfAccount;
  updatedAt: Scalars['DateTime'];
};


export type ForwardContractAccount_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type ForwardContractAccount_CurrentPositionsArgs = {
  orderBy?: InputMaybe<CurrentPositionSortByDTO>;
  where?: InputMaybe<CurrentPositionFilterDTO>;
};


export type ForwardContractAccount_NotesArgs = {
  orderBy?: InputMaybe<AccountNoteSortByDTO>;
  where?: InputMaybe<AccountNoteFilterDTO>;
};


export type ForwardContractAccount_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type ForwardContractAccount_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type ForwardContractAccountCreateDTO = {
  accountNumber?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  counterPartyId: Scalars['String'];
  customerId?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  productId: Scalars['String'];
};

export type ForwardContractAccountDistinctOnDTO = {
  accountNumber?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  counterPartyId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ForwardContractAccountFilterDTO = {
  AND?: InputMaybe<Array<ForwardContractAccountFilterDTO>>;
  AccountFiles?: InputMaybe<AccountFileFilterDTO>;
  AccountNotes?: InputMaybe<AccountNoteFilterDTO>;
  AsAccount?: InputMaybe<AccountFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  IntegrationMappings?: InputMaybe<IntegrationMappingFilterDTO>;
  NOT?: InputMaybe<ForwardContractAccountFilterDTO>;
  OR?: InputMaybe<Array<ForwardContractAccountFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  Owner?: InputMaybe<EntityFilterDTO>;
  PositionComponent?: InputMaybe<PositionComponentFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  accountNumber?: InputMaybe<NullableStringFilter>;
  contractNumber?: InputMaybe<NullableStringFilter>;
  counterPartyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  isClosed?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<NullableStringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ForwardContractAccountGroupByDTO = {
  AsAccount?: InputMaybe<AccountGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  Owner?: InputMaybe<EntityGroupByDTO>;
  OwnerAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  OwnerAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  OwnerAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  OwnerAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  accountNumber?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  counterPartyId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ForwardContractAccountSortByDTO = {
  AsAccount?: InputMaybe<AccountSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  Owner?: InputMaybe<EntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  accountNumber?: InputMaybe<NullableSortByDirection>;
  contractNumber?: InputMaybe<NullableSortByDirection>;
  counterPartyId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  isClosed?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  ownerId?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type ForwardContractAccountUpdateDTO = {
  accountNumber?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  counterPartyId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
};

export type Future = IInstrument & {
  __typename?: 'Future';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayExpiresAt: Scalars['String'];
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  firstNoticeDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  /** @deprecated Use "Instrument.type" instead */
  instrumentType: TypeOfInstrument;
  isStandardContractSize: Scalars['Boolean'];
  lastTradeAt: Scalars['DateTime'];
  name: Scalars['String'];
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  /** @deprecated Use "Instrument.type" instead */
  productType: TypeOfInstrument;
  type: TypeOfInstrument;
  unitsPerContract?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  velaInstrumentId?: Maybe<Scalars['Int']>;
};


export type Future_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type Future_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type Future_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type Future_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type FutureFilterDTO = {
  AND?: InputMaybe<Array<FutureFilterDTO>>;
  AsInstrument?: InputMaybe<InstrumentFilterDTO>;
  BasisInstruments?: InputMaybe<BasisInstrumentFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  DerivedOptionInstruments?: InputMaybe<OptionFilterDTO>;
  DerivedSpreadLegs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  LegacyFeeds?: InputMaybe<LegacyFeedFilterDTO>;
  LegacyScenarioData?: InputMaybe<LegacyScenarioDataFilterDTO>;
  NOT?: InputMaybe<FutureFilterDTO>;
  OR?: InputMaybe<Array<FutureFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  PhysicalInstruments?: InputMaybe<PhysicalInstrumentFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationFilterDTO>;
  SwapInstruments?: InputMaybe<SwapFilterDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  barchartSymbol?: InputMaybe<NullableStringFilter>;
  cqgSymbol?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayExpiresAt?: InputMaybe<StringFilter>;
  exchangeSymbol?: InputMaybe<NullableStringFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  firstNoticeDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentSymbolGroupId?: InputMaybe<StringFilter>;
  isStandardContractSize?: InputMaybe<BooleanFilter>;
  lastTradeAt?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  productLotSpecificationId?: InputMaybe<StringFilter>;
  unitsPerContract?: InputMaybe<NullableNumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  velaInstrumentId?: InputMaybe<NullableNumberFilter>;
};

export type FutureGroupByDTO = {
  AsInstrument?: InputMaybe<InstrumentGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationGroupByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  firstNoticeDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  unitsPerContract?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  velaInstrumentId?: InputMaybe<Scalars['Boolean']>;
};

export type FutureInstrumentDistinctOnDTO = {
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  firstNoticeDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  unitsPerContract?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  velaInstrumentId?: InputMaybe<Scalars['Boolean']>;
};

export type FutureSortByDTO = {
  AsInstrument?: InputMaybe<InstrumentSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationSortByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  barchartSymbol?: InputMaybe<NullableSortByDirection>;
  cqgSymbol?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  displayExpiresAt?: InputMaybe<SortByDirection>;
  exchangeSymbol?: InputMaybe<NullableSortByDirection>;
  expiresAt?: InputMaybe<SortByDirection>;
  firstNoticeDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentSymbolGroupId?: InputMaybe<SortByDirection>;
  isStandardContractSize?: InputMaybe<SortByDirection>;
  lastTradeAt?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productLotSpecificationId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  unitsPerContract?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  velaInstrumentId?: InputMaybe<NullableSortByDirection>;
};

export type FuturesCurvePointInput = {
  /** The YYYY-MM-DD representation of the futures month for the given price */
  contractMonth: Scalars['String'];
  /** The price to use in calculations for this product + contract month. This value should be the barchart market data magnitude */
  price: Scalars['Float'];
  productId: Scalars['String'];
};

export type GrainAdvisor = {
  __typename?: 'GrainAdvisor';
  Businesses: Array<CustomerEntity>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type GrainAdvisor_BusinessesArgs = {
  orderBy?: InputMaybe<CustomerEntitySortByDTO>;
  where?: InputMaybe<CustomerEntityFilterDTO>;
};

export type GrainAdvisorDistinctOnDTO = {
  email?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
};

export type GrainAdvisorFilterDTO = {
  AND?: InputMaybe<Array<GrainAdvisorFilterDTO>>;
  NOT?: InputMaybe<GrainAdvisorFilterDTO>;
  OR?: InputMaybe<Array<GrainAdvisorFilterDTO>>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type GrainAdvisorSortByDTO = {
  email?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
};

export type GrainBuyer = {
  __typename?: 'GrainBuyer';
  GrainBuyerLocations: Array<GrainBuyerLocation>;
  PhysicalSales?: Maybe<Array<PhysicalSale>>;
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputSource: InputSource;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  website: Scalars['String'];
};


export type GrainBuyer_GrainBuyerLocationsArgs = {
  orderBy?: InputMaybe<GrainBuyerLocationSortByDTO>;
  where?: InputMaybe<GrainBuyerLocationFilterDTO>;
};


export type GrainBuyer_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};

export type GrainBuyerCreateDTO = {
  externalId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  website: Scalars['String'];
};

export type GrainBuyerDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Scalars['Boolean']>;
};

export type GrainBuyerFilterDTO = {
  AND?: InputMaybe<Array<GrainBuyerFilterDTO>>;
  Locations?: InputMaybe<GrainBuyerLocationFilterDTO>;
  NOT?: InputMaybe<GrainBuyerFilterDTO>;
  OR?: InputMaybe<Array<GrainBuyerFilterDTO>>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  externalId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  name?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  website?: InputMaybe<StringFilter>;
};

export type GrainBuyerLocation = {
  __typename?: 'GrainBuyerLocation';
  GrainBuyer: GrainBuyer;
  PhysicalSales?: Maybe<Array<PhysicalSale>>;
  city: Scalars['String'];
  countyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  facilityType?: Maybe<Scalars['String']>;
  grainBuyerId: Scalars['String'];
  id: Scalars['ID'];
  inputSource: InputSource;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  stateId: Scalars['String'];
  streetAddress: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  zipCode?: Maybe<Scalars['String']>;
};


export type GrainBuyerLocation_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};

export type GrainBuyerLocationCreateDTO = {
  city: Scalars['String'];
  countyId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  facilityType?: InputMaybe<Scalars['String']>;
  grainBuyerId: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  stateId: Scalars['String'];
  streetAddress: Scalars['String'];
  zipCode?: InputMaybe<Scalars['String']>;
};

export type GrainBuyerLocationDistinctOnDTO = {
  city?: InputMaybe<Scalars['Boolean']>;
  countyId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  facilityType?: InputMaybe<Scalars['Boolean']>;
  grainBuyerId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Boolean']>;
  longitude?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  streetAddress?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  zipCode?: InputMaybe<Scalars['Boolean']>;
};

export type GrainBuyerLocationFilterDTO = {
  AND?: InputMaybe<Array<GrainBuyerLocationFilterDTO>>;
  County?: InputMaybe<CountyFilterDTO>;
  GrainBuyer?: InputMaybe<GrainBuyerFilterDTO>;
  NOT?: InputMaybe<GrainBuyerLocationFilterDTO>;
  OR?: InputMaybe<Array<GrainBuyerLocationFilterDTO>>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  State?: InputMaybe<StateFilterDTO>;
  city?: InputMaybe<StringFilter>;
  countyId?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  externalId?: InputMaybe<NullableStringFilter>;
  facilityType?: InputMaybe<NullableStringFilter>;
  grainBuyerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  latitude?: InputMaybe<NumberFilter>;
  longitude?: InputMaybe<NumberFilter>;
  name?: InputMaybe<StringFilter>;
  stateId?: InputMaybe<StringFilter>;
  streetAddress?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zipCode?: InputMaybe<NullableStringFilter>;
};

export type GrainBuyerLocationSortByDTO = {
  County?: InputMaybe<CountySortByDTO>;
  GrainBuyer?: InputMaybe<GrainBuyerSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  city?: InputMaybe<SortByDirection>;
  countyId?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<NullableSortByDirection>;
  facilityType?: InputMaybe<NullableSortByDirection>;
  grainBuyerId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  latitude?: InputMaybe<SortByDirection>;
  longitude?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  streetAddress?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  zipCode?: InputMaybe<NullableSortByDirection>;
};

export type GrainBuyerLocationUpdateDTO = {
  city?: InputMaybe<Scalars['String']>;
  countyId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  facilityType?: InputMaybe<Scalars['String']>;
  grainBuyerId?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type GrainBuyerSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  phoneNumber?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  website?: InputMaybe<SortByDirection>;
};

export type GrainBuyerUpdateDTO = {
  externalId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type GrainCropPlan = IGrainPlan & {
  __typename?: 'GrainCropPlan';
  Crop: CropCategory;
  CropYear: GrainCropYear;
  Customer?: Maybe<CustomerEntity>;
  Orders: Array<IGrainOrder>;
  Organization: OrganizationEntity;
  acres: Scalars['Float'];
  aph: Scalars['Float'];
  breakEven?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  cropId: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  enrolledPercent?: Maybe<Scalars['Float']>;
  goal?: Maybe<Scalars['Float']>;
  harvestBu?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  organizationId: Scalars['String'];
  storageBu?: Maybe<Scalars['Float']>;
  type: TypeOfGrainPlan;
  updatedAt: Scalars['DateTime'];
  vgsId?: Maybe<Scalars['Int']>;
  yearId: Scalars['String'];
};


export type GrainCropPlan_CropArgs = {
  orderBy?: InputMaybe<CropCategorySortByDTO>;
  where?: InputMaybe<CropCategoryFilterDTO>;
};


export type GrainCropPlan_CropYearArgs = {
  orderBy?: InputMaybe<GrainCropYearSortByDTO>;
  where?: InputMaybe<GrainCropYearFilterDTO>;
};


export type GrainCropPlan_OrdersArgs = {
  orderBy?: InputMaybe<GrainOrderSortByDTO>;
  where?: InputMaybe<GrainOrderFilterDTO>;
};

export type GrainCropPlanCreateDTO = {
  acres: Scalars['Float'];
  aph: Scalars['Float'];
  breakEven?: InputMaybe<Scalars['Float']>;
  cropId: Scalars['String'];
  customerId?: InputMaybe<Scalars['String']>;
  enrolledPercent?: InputMaybe<Scalars['Float']>;
  goal?: InputMaybe<Scalars['Float']>;
  harvestBu?: InputMaybe<Scalars['Float']>;
  organizationId: Scalars['String'];
  storageBu?: InputMaybe<Scalars['Float']>;
  vgsId?: InputMaybe<Scalars['Int']>;
  yearId: Scalars['String'];
};

export type GrainCropPlanDistinctOnDTO = {
  acres?: InputMaybe<Scalars['Boolean']>;
  aph?: InputMaybe<Scalars['Boolean']>;
  breakEven?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  cropId?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  enrolledPercent?: InputMaybe<Scalars['Boolean']>;
  goal?: InputMaybe<Scalars['Boolean']>;
  harvestBu?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  storageBu?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
  yearId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainCropPlanFilterDTO = {
  AND?: InputMaybe<Array<GrainCropPlanFilterDTO>>;
  AsGrainPlan?: InputMaybe<GrainPlanFilterDTO>;
  CropCategory?: InputMaybe<CropCategoryFilterDTO>;
  CropYear?: InputMaybe<GrainCropYearFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  GrainOrderActivity?: InputMaybe<GrainOrderActivityFilterDTO>;
  NOT?: InputMaybe<GrainCropPlanFilterDTO>;
  OR?: InputMaybe<Array<GrainCropPlanFilterDTO>>;
  Orders?: InputMaybe<GrainOrderFilterDTO>;
  Organization?: InputMaybe<OrganizationEntityFilterDTO>;
  acres?: InputMaybe<NumberFilter>;
  aph?: InputMaybe<NumberFilter>;
  breakEven?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  cropId?: InputMaybe<StringFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  enrolledPercent?: InputMaybe<NullableNumberFilter>;
  goal?: InputMaybe<NullableNumberFilter>;
  harvestBu?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  storageBu?: InputMaybe<NullableNumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
  yearId?: InputMaybe<StringFilter>;
};

export type GrainCropPlanGroupByDTO = {
  AsGrainPlan?: InputMaybe<GrainPlanGroupByDTO>;
  CropCategory?: InputMaybe<CropCategoryGroupByDTO>;
  CropYear?: InputMaybe<GrainCropYearGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  Organization?: InputMaybe<OrganizationEntityGroupByDTO>;
  acres?: InputMaybe<Scalars['Boolean']>;
  aph?: InputMaybe<Scalars['Boolean']>;
  breakEven?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  cropId?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  enrolledPercent?: InputMaybe<Scalars['Boolean']>;
  goal?: InputMaybe<Scalars['Boolean']>;
  harvestBu?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  storageBu?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
  yearId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainCropPlanSortByDTO = {
  AsGrainPlan?: InputMaybe<GrainPlanSortByDTO>;
  CropCategory?: InputMaybe<CropCategorySortByDTO>;
  CropYear?: InputMaybe<GrainCropYearSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  Organization?: InputMaybe<OrganizationEntitySortByDTO>;
  acres?: InputMaybe<SortByDirection>;
  aph?: InputMaybe<SortByDirection>;
  breakEven?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  cropId?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  enrolledPercent?: InputMaybe<NullableSortByDirection>;
  goal?: InputMaybe<NullableSortByDirection>;
  harvestBu?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  organizationId?: InputMaybe<SortByDirection>;
  storageBu?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
  yearId?: InputMaybe<SortByDirection>;
};

export type GrainCropPlanUpdateDTO = {
  acres?: InputMaybe<Scalars['Float']>;
  aph?: InputMaybe<Scalars['Float']>;
  breakEven?: InputMaybe<Scalars['Float']>;
  cropId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  enrolledPercent?: InputMaybe<Scalars['Float']>;
  goal?: InputMaybe<Scalars['Float']>;
  harvestBu?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['String']>;
  storageBu?: InputMaybe<Scalars['Float']>;
  vgsId?: InputMaybe<Scalars['Int']>;
  yearId?: InputMaybe<Scalars['String']>;
};

export type GrainCropYear = {
  __typename?: 'GrainCropYear';
  Plans: Array<GrainCropPlan>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  vgsId?: Maybe<Scalars['Int']>;
  year: Scalars['Int'];
};


export type GrainCropYear_PlansArgs = {
  orderBy?: InputMaybe<GrainCropPlanSortByDTO>;
  where?: InputMaybe<GrainCropPlanFilterDTO>;
};

export type GrainCropYearCreateDTO = {
  vgsId?: InputMaybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export type GrainCropYearDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type GrainCropYearFilterDTO = {
  AND?: InputMaybe<Array<GrainCropYearFilterDTO>>;
  NOT?: InputMaybe<GrainCropYearFilterDTO>;
  OR?: InputMaybe<Array<GrainCropYearFilterDTO>>;
  Plans?: InputMaybe<GrainCropPlanFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type GrainCropYearGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type GrainCropYearSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type GrainCropYearUpdateDTO = {
  vgsId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type GrainFeedPlan = IGrainPlan & {
  __typename?: 'GrainFeedPlan';
  Customer?: Maybe<CustomerEntity>;
  FeedCategory: FeedCategory;
  Orders: Array<IGrainOrder>;
  Organization: OrganizationEntity;
  basisExposure: Scalars['Float'];
  basisOnlyPurchased: Scalars['Float'];
  breakEven?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  feedCategoryId: Scalars['String'];
  feedIngredientId?: Maybe<Scalars['String']>;
  feedYear: Scalars['Int'];
  futuresExposure: Scalars['Float'];
  futuresOnlyPurchased: Scalars['Float'];
  goal?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  organizationId: Scalars['String'];
  percentBasisExposure: Scalars['Float'];
  percentFuturesExposure: Scalars['Float'];
  percentPriced: Scalars['Float'];
  percentTonsOpen: Scalars['Float'];
  startDate: Scalars['String'];
  startDateMonth: Scalars['Int'];
  tons: Scalars['Float'];
  totalTonsFlatPriced: Scalars['Float'];
  totalTonsOpen: Scalars['Float'];
  type: TypeOfGrainPlan;
  updatedAt: Scalars['DateTime'];
};


export type GrainFeedPlan_OrdersArgs = {
  orderBy?: InputMaybe<GrainOrderSortByDTO>;
  where?: InputMaybe<GrainOrderFilterDTO>;
};

export type GrainFeedPlanCreateDTO = {
  breakEven?: InputMaybe<Scalars['Float']>;
  customerId?: InputMaybe<Scalars['String']>;
  feedCategoryId: Scalars['String'];
  feedYear?: InputMaybe<Scalars['Int']>;
  goal?: InputMaybe<Scalars['Float']>;
  organizationId: Scalars['String'];
  startDate: Scalars['String'];
  tons: Scalars['Float'];
};

export type GrainFeedPlanDistinctOnDTO = {
  basisExposure?: InputMaybe<Scalars['Boolean']>;
  basisOnlyPurchased?: InputMaybe<Scalars['Boolean']>;
  breakEven?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  feedCategoryId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedYear?: InputMaybe<Scalars['Boolean']>;
  futuresExposure?: InputMaybe<Scalars['Boolean']>;
  futuresOnlyPurchased?: InputMaybe<Scalars['Boolean']>;
  goal?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  percentBasisExposure?: InputMaybe<Scalars['Boolean']>;
  percentFuturesExposure?: InputMaybe<Scalars['Boolean']>;
  percentPriced?: InputMaybe<Scalars['Boolean']>;
  percentTonsOpen?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  startDateMonth?: InputMaybe<Scalars['Boolean']>;
  tons?: InputMaybe<Scalars['Boolean']>;
  totalTonsFlatPriced?: InputMaybe<Scalars['Boolean']>;
  totalTonsOpen?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type GrainFeedPlanFilterDTO = {
  AND?: InputMaybe<Array<GrainFeedPlanFilterDTO>>;
  AsGrainPlan?: InputMaybe<GrainPlanFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  FeedCategory?: InputMaybe<FeedCategoryFilterDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientFilterDTO>;
  GrainOrderActivity?: InputMaybe<GrainOrderActivityFilterDTO>;
  NOT?: InputMaybe<GrainFeedPlanFilterDTO>;
  OR?: InputMaybe<Array<GrainFeedPlanFilterDTO>>;
  Orders?: InputMaybe<GrainOrderFilterDTO>;
  Organization?: InputMaybe<OrganizationEntityFilterDTO>;
  basisExposure?: InputMaybe<NumberFilter>;
  basisOnlyPurchased?: InputMaybe<NumberFilter>;
  breakEven?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  feedCategoryId?: InputMaybe<StringFilter>;
  feedIngredientId?: InputMaybe<NullableStringFilter>;
  feedYear?: InputMaybe<NumberFilter>;
  futuresExposure?: InputMaybe<NumberFilter>;
  futuresOnlyPurchased?: InputMaybe<NumberFilter>;
  goal?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  percentBasisExposure?: InputMaybe<NumberFilter>;
  percentFuturesExposure?: InputMaybe<NumberFilter>;
  percentPriced?: InputMaybe<NumberFilter>;
  percentTonsOpen?: InputMaybe<NumberFilter>;
  startDate?: InputMaybe<StringFilter>;
  startDateMonth?: InputMaybe<NumberFilter>;
  tons?: InputMaybe<NumberFilter>;
  totalTonsFlatPriced?: InputMaybe<NumberFilter>;
  totalTonsOpen?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GrainFeedPlanGroupByDTO = {
  AsGrainPlan?: InputMaybe<GrainPlanGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  FeedCategory?: InputMaybe<FeedCategoryGroupByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientGroupByDTO>;
  Organization?: InputMaybe<OrganizationEntityGroupByDTO>;
  basisExposure?: InputMaybe<Scalars['Boolean']>;
  basisOnlyPurchased?: InputMaybe<Scalars['Boolean']>;
  breakEven?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  feedCategoryId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedYear?: InputMaybe<Scalars['Boolean']>;
  futuresExposure?: InputMaybe<Scalars['Boolean']>;
  futuresOnlyPurchased?: InputMaybe<Scalars['Boolean']>;
  goal?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  percentBasisExposure?: InputMaybe<Scalars['Boolean']>;
  percentFuturesExposure?: InputMaybe<Scalars['Boolean']>;
  percentPriced?: InputMaybe<Scalars['Boolean']>;
  percentTonsOpen?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  startDateMonth?: InputMaybe<Scalars['Boolean']>;
  tons?: InputMaybe<Scalars['Boolean']>;
  totalTonsFlatPriced?: InputMaybe<Scalars['Boolean']>;
  totalTonsOpen?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type GrainFeedPlanSortByDTO = {
  AsGrainPlan?: InputMaybe<GrainPlanSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  FeedCategory?: InputMaybe<FeedCategorySortByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientSortByDTO>;
  Organization?: InputMaybe<OrganizationEntitySortByDTO>;
  basisExposure?: InputMaybe<SortByDirection>;
  basisOnlyPurchased?: InputMaybe<SortByDirection>;
  breakEven?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  feedCategoryId?: InputMaybe<SortByDirection>;
  feedIngredientId?: InputMaybe<NullableSortByDirection>;
  feedYear?: InputMaybe<SortByDirection>;
  futuresExposure?: InputMaybe<SortByDirection>;
  futuresOnlyPurchased?: InputMaybe<SortByDirection>;
  goal?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  organizationId?: InputMaybe<SortByDirection>;
  percentBasisExposure?: InputMaybe<SortByDirection>;
  percentFuturesExposure?: InputMaybe<SortByDirection>;
  percentPriced?: InputMaybe<SortByDirection>;
  percentTonsOpen?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  startDateMonth?: InputMaybe<SortByDirection>;
  tons?: InputMaybe<SortByDirection>;
  totalTonsFlatPriced?: InputMaybe<SortByDirection>;
  totalTonsOpen?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type GrainFeedPlanUpdateDTO = {
  breakEven?: InputMaybe<Scalars['Float']>;
  customerId?: InputMaybe<Scalars['String']>;
  feedYear?: InputMaybe<Scalars['Int']>;
  goal?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  tons?: InputMaybe<Scalars['Float']>;
};

export type GrainFillOrder = IGrainOrder & {
  __typename?: 'GrainFillOrder';
  Activity: Array<GrainOrderActivity>;
  Buyer?: Maybe<Buyer>;
  DeliveryLocation?: Maybe<GrainLocation>;
  GrainFillOrderActivity: Array<GrainFillOrderActivity>;
  GrainFillOrderModificationRequestActivity: Array<GrainFillOrderModificationRequestActivity>;
  GrainFillOrderModificationRequests: Array<GrainFillOrderModificationRequest>;
  GrainTargetOrder: GrainTargetOrder;
  Location?: Maybe<GrainLocation>;
  Notes: Array<GrainOrderNote>;
  Plan?: Maybe<IGrainPlan>;
  Seller?: Maybe<Seller>;
  StatusUpdates: Array<GrainOrderStatusUpdate>;
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** Only intended to be read for orders created against Crop plans. */
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  /** Only intended to be set or read for orders created against Feed plans. */
  feedFlatPrice?: Maybe<Scalars['Float']>;
  /** Only intended to be read for orders created against Feed plans. */
  feedPrice?: Maybe<Scalars['Float']>;
  fees: Scalars['Float'];
  /**
   * Only intended to be read for orders created against Crop plans.
   * @deprecated Use "cropFlatPrice" instead
   */
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee: Scalars['Float'];
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  htaFee: Scalars['Float'];
  id: Scalars['ID'];
  /** @deprecated Use deliveryTerms instead. */
  isFob: Scalars['Boolean'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  perMonthData: Array<Scalars['Object']>;
  planId: Scalars['String'];
  rollFee: Scalars['Float'];
  salesType: TypeOfGrainOrderSales;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  targetId: Scalars['String'];
  type: TypeOfGrainOrder;
  updatedAt: Scalars['DateTime'];
  vgsId?: Maybe<Scalars['Int']>;
};


export type GrainFillOrder_ActivityArgs = {
  orderBy?: InputMaybe<GrainOrderActivitySortByDTO>;
  where?: InputMaybe<GrainOrderActivityFilterDTO>;
};


export type GrainFillOrder_GrainFillOrderActivityArgs = {
  orderBy?: InputMaybe<GrainFillOrderActivitySortByDTO>;
  where?: InputMaybe<GrainFillOrderActivityFilterDTO>;
};


export type GrainFillOrder_GrainFillOrderModificationRequestActivityArgs = {
  orderBy?: InputMaybe<GrainFillOrderModificationRequestActivitySortByDTO>;
  where?: InputMaybe<GrainFillOrderModificationRequestActivityFilterDTO>;
};


export type GrainFillOrder_GrainFillOrderModificationRequestsArgs = {
  orderBy?: InputMaybe<GrainFillOrderModificationRequestSortByDTO>;
  where?: InputMaybe<GrainFillOrderModificationRequestFilterDTO>;
};


export type GrainFillOrder_NotesArgs = {
  orderBy?: InputMaybe<GrainOrderNoteSortByDTO>;
  where?: InputMaybe<GrainOrderNoteFilterDTO>;
};


export type GrainFillOrder_StatusUpdatesArgs = {
  orderBy?: InputMaybe<GrainOrderStatusUpdateSortByDTO>;
  where?: InputMaybe<GrainOrderStatusUpdateFilterDTO>;
};

export type GrainFillOrderActivity = IGrainOrderActivity & {
  __typename?: 'GrainFillOrderActivity';
  GrainFillOrder?: Maybe<GrainFillOrder>;
  GrainPlan?: Maybe<IGrainPlan>;
  User?: Maybe<User>;
  data: Scalars['Object'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  operationType: TypeOfPostgresOperation;
  orderId?: Maybe<Scalars['String']>;
  performedAt: Scalars['DateTime'];
  performedByUserId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  type: TypeOfGrainOrderActivity;
};

export type GrainFillOrderActivityDistinctOnDTO = {
  data?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  operationType?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['Boolean']>;
  performedAt?: InputMaybe<Scalars['Boolean']>;
  performedByUserId?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
};

export type GrainFillOrderActivityFilterDTO = {
  AND?: InputMaybe<Array<GrainFillOrderActivityFilterDTO>>;
  AsGrainOrderActivity?: InputMaybe<GrainOrderActivityFilterDTO>;
  GrainFillOrder?: InputMaybe<GrainFillOrderFilterDTO>;
  GrainOrder?: InputMaybe<GrainOrderFilterDTO>;
  GrainPlan?: InputMaybe<GrainPlanFilterDTO>;
  NOT?: InputMaybe<GrainFillOrderActivityFilterDTO>;
  OR?: InputMaybe<Array<GrainFillOrderActivityFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  id?: InputMaybe<StringFilter>;
  operationType?: InputMaybe<TypeOfPostgresOperationFilter>;
  orderId?: InputMaybe<NullableStringFilter>;
  performedAt?: InputMaybe<DateTimeFilter>;
  performedByUserId?: InputMaybe<NullableStringFilter>;
  planId?: InputMaybe<NullableStringFilter>;
};

export type GrainFillOrderActivitySortByDTO = {
  AsGrainOrderActivity?: InputMaybe<GrainOrderActivitySortByDTO>;
  GrainFillOrder?: InputMaybe<GrainFillOrderSortByDTO>;
  GrainOrder?: InputMaybe<GrainOrderSortByDTO>;
  GrainPlan?: InputMaybe<GrainPlanSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  data?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  operationType?: InputMaybe<SortByDirection>;
  orderId?: InputMaybe<NullableSortByDirection>;
  performedAt?: InputMaybe<SortByDirection>;
  performedByUserId?: InputMaybe<NullableSortByDirection>;
  planId?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
};

export type GrainFillOrderAggregateAllDTO = {
  basis?: InputMaybe<Scalars['Boolean']>;
  bushels?: InputMaybe<Scalars['Boolean']>;
  buyerId?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdBy?: InputMaybe<Scalars['Boolean']>;
  cropFlatPrice?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryId?: InputMaybe<Scalars['Boolean']>;
  deliveryMonth?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  feedFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedPrice?: InputMaybe<Scalars['Boolean']>;
  fees?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  flexFee?: InputMaybe<Scalars['Boolean']>;
  futurePrice?: InputMaybe<Scalars['Boolean']>;
  futuresMonth?: InputMaybe<Scalars['Boolean']>;
  htaFee?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedById?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  rollFee?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Scalars['Boolean']>;
  shortNote?: InputMaybe<Scalars['Boolean']>;
  spread?: InputMaybe<Scalars['Boolean']>;
  targetId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainFillOrderAggregateDTO = {
  avg?: InputMaybe<GrainFillOrderAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<GrainFillOrderAggregateAllDTO>;
  min?: InputMaybe<GrainFillOrderAggregateAllDTO>;
  sum?: InputMaybe<GrainFillOrderAggregateNumericDTO>;
};

export type GrainFillOrderAggregateNumericDTO = {
  basis?: InputMaybe<Scalars['Boolean']>;
  bushels?: InputMaybe<Scalars['Boolean']>;
  cropFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedPrice?: InputMaybe<Scalars['Boolean']>;
  fees?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  flexFee?: InputMaybe<Scalars['Boolean']>;
  futurePrice?: InputMaybe<Scalars['Boolean']>;
  htaFee?: InputMaybe<Scalars['Boolean']>;
  rollFee?: InputMaybe<Scalars['Boolean']>;
  spread?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainFillOrderCreateDTO = {
  basis?: InputMaybe<Scalars['Float']>;
  bushels?: InputMaybe<Scalars['Float']>;
  buyerId?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  deliveryEndDate?: InputMaybe<Scalars['String']>;
  deliveryId?: InputMaybe<Scalars['String']>;
  deliveryMonth?: InputMaybe<Scalars['String']>;
  deliveryStartDate?: InputMaybe<Scalars['String']>;
  deliveryTerms?: InputMaybe<GrainOrderDeliveryTerms>;
  feedFlatPrice?: InputMaybe<Scalars['Float']>;
  flexFee?: InputMaybe<Scalars['Float']>;
  futurePrice?: InputMaybe<Scalars['Float']>;
  futuresMonth?: InputMaybe<Scalars['String']>;
  htaFee?: InputMaybe<Scalars['Float']>;
  isFob?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
  lastUpdatedById?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  planId: Scalars['String'];
  rollFee?: InputMaybe<Scalars['Float']>;
  salesType: TypeOfGrainOrderSales;
  sellerId?: InputMaybe<Scalars['String']>;
  shortNote?: InputMaybe<Scalars['String']>;
  spread?: InputMaybe<Scalars['Float']>;
  targetId: Scalars['String'];
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type GrainFillOrderDistinctOnDTO = {
  basis?: InputMaybe<Scalars['Boolean']>;
  bushels?: InputMaybe<Scalars['Boolean']>;
  buyerId?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdBy?: InputMaybe<Scalars['Boolean']>;
  cropFlatPrice?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryId?: InputMaybe<Scalars['Boolean']>;
  deliveryMonth?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  deliveryTerms?: InputMaybe<Scalars['Boolean']>;
  feedFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedPrice?: InputMaybe<Scalars['Boolean']>;
  fees?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  flexFee?: InputMaybe<Scalars['Boolean']>;
  futurePrice?: InputMaybe<Scalars['Boolean']>;
  futuresMonth?: InputMaybe<Scalars['Boolean']>;
  htaFee?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isFob?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedById?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  rollFee?: InputMaybe<Scalars['Boolean']>;
  salesType?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Scalars['Boolean']>;
  shortNote?: InputMaybe<Scalars['Boolean']>;
  spread?: InputMaybe<Scalars['Boolean']>;
  targetId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainFillOrderFilterDTO = {
  AND?: InputMaybe<Array<GrainFillOrderFilterDTO>>;
  AsGrainOrder?: InputMaybe<GrainOrderFilterDTO>;
  Buyer?: InputMaybe<BuyerFilterDTO>;
  DeliveryLocation?: InputMaybe<GrainLocationFilterDTO>;
  GrainFillOrderActivity?: InputMaybe<GrainFillOrderActivityFilterDTO>;
  GrainFillOrderModificationRequestActivity?: InputMaybe<GrainFillOrderModificationRequestActivityFilterDTO>;
  GrainFillOrderModificationRequests?: InputMaybe<GrainFillOrderModificationRequestFilterDTO>;
  GrainOrderActivity?: InputMaybe<GrainOrderActivityFilterDTO>;
  GrainTargetOrder?: InputMaybe<GrainTargetOrderFilterDTO>;
  Location?: InputMaybe<GrainLocationFilterDTO>;
  NOT?: InputMaybe<GrainFillOrderFilterDTO>;
  Notes?: InputMaybe<GrainOrderNoteFilterDTO>;
  OR?: InputMaybe<Array<GrainFillOrderFilterDTO>>;
  Plan?: InputMaybe<GrainPlanFilterDTO>;
  Seller?: InputMaybe<SellerFilterDTO>;
  StatusUpdates?: InputMaybe<GrainOrderStatusUpdateFilterDTO>;
  basis?: InputMaybe<NullableNumberFilter>;
  bushels?: InputMaybe<NullableNumberFilter>;
  buyerId?: InputMaybe<NullableStringFilter>;
  contractNumber?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  cropFlatPrice?: InputMaybe<NullableNumberFilter>;
  deliveryEndDate?: InputMaybe<NullableStringFilter>;
  deliveryId?: InputMaybe<NullableStringFilter>;
  deliveryMonth?: InputMaybe<NullableStringFilter>;
  deliveryStartDate?: InputMaybe<NullableStringFilter>;
  deliveryTerms?: InputMaybe<NullableGrainOrderDeliveryTermsFilter>;
  feedFlatPrice?: InputMaybe<NullableNumberFilter>;
  feedPrice?: InputMaybe<NullableNumberFilter>;
  fees?: InputMaybe<NumberFilter>;
  flatPrice?: InputMaybe<NullableNumberFilter>;
  flexFee?: InputMaybe<NumberFilter>;
  futurePrice?: InputMaybe<NullableNumberFilter>;
  futuresMonth?: InputMaybe<NullableStringFilter>;
  htaFee?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  isFob?: InputMaybe<BooleanFilter>;
  lastUpdatedBy?: InputMaybe<NullableStringFilter>;
  lastUpdatedById?: InputMaybe<NullableStringFilter>;
  locationId?: InputMaybe<NullableStringFilter>;
  planId?: InputMaybe<StringFilter>;
  rollFee?: InputMaybe<NumberFilter>;
  salesType?: InputMaybe<TypeOfGrainOrderSalesFilter>;
  sellerId?: InputMaybe<NullableStringFilter>;
  shortNote?: InputMaybe<NullableStringFilter>;
  spread?: InputMaybe<NullableNumberFilter>;
  targetId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
};

export type GrainFillOrderGroupByDTO = {
  AsGrainOrder?: InputMaybe<GrainOrderGroupByDTO>;
  Buyer?: InputMaybe<BuyerGroupByDTO>;
  DeliveryLocation?: InputMaybe<GrainLocationGroupByDTO>;
  GrainTargetOrder?: InputMaybe<GrainTargetOrderGroupByDTO>;
  Location?: InputMaybe<GrainLocationGroupByDTO>;
  Plan?: InputMaybe<GrainPlanGroupByDTO>;
  PlanAsGrainCropPlan?: InputMaybe<GrainCropPlanGroupByDTO>;
  PlanAsGrainFeedPlan?: InputMaybe<GrainFeedPlanGroupByDTO>;
  Seller?: InputMaybe<SellerGroupByDTO>;
  basis?: InputMaybe<Scalars['Boolean']>;
  bushels?: InputMaybe<Scalars['Boolean']>;
  buyerId?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdBy?: InputMaybe<Scalars['Boolean']>;
  cropFlatPrice?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryId?: InputMaybe<Scalars['Boolean']>;
  deliveryMonth?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  deliveryTerms?: InputMaybe<Scalars['Boolean']>;
  feedFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedPrice?: InputMaybe<Scalars['Boolean']>;
  fees?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  flexFee?: InputMaybe<Scalars['Boolean']>;
  futurePrice?: InputMaybe<Scalars['Boolean']>;
  futuresMonth?: InputMaybe<Scalars['Boolean']>;
  htaFee?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isFob?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedById?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  rollFee?: InputMaybe<Scalars['Boolean']>;
  salesType?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Scalars['Boolean']>;
  shortNote?: InputMaybe<Scalars['Boolean']>;
  spread?: InputMaybe<Scalars['Boolean']>;
  targetId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainFillOrderModificationRequest = {
  __typename?: 'GrainFillOrderModificationRequest';
  Buyer?: Maybe<Buyer>;
  DeliveryLocation?: Maybe<GrainLocation>;
  GrainFillOrder: GrainFillOrder;
  GrainFillOrderModificationRequestActivity: Array<GrainFillOrderModificationRequestActivity>;
  Location?: Maybe<GrainLocation>;
  OwnedByUser?: Maybe<User>;
  RequestedByUser?: Maybe<User>;
  Seller?: Maybe<Seller>;
  buyerRelationsOwnerId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  data: GrainFillOrderModificationRequestDataDTO;
  fillId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  planId: Scalars['String'];
  requestedByUserId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  status: GrainFillOrderModificationStatus;
  type: TypeOfGrainFillOrderModification;
  updatedAt: Scalars['DateTime'];
};


export type GrainFillOrderModificationRequest_GrainFillOrderModificationRequestActivityArgs = {
  orderBy?: InputMaybe<GrainFillOrderModificationRequestActivitySortByDTO>;
  where?: InputMaybe<GrainFillOrderModificationRequestActivityFilterDTO>;
};

export type GrainFillOrderModificationRequestActivity = IGrainOrderActivity & {
  __typename?: 'GrainFillOrderModificationRequestActivity';
  GrainFillOrder?: Maybe<GrainFillOrder>;
  GrainFillOrderModificationRequest?: Maybe<GrainFillOrderModificationRequest>;
  GrainPlan?: Maybe<IGrainPlan>;
  User?: Maybe<User>;
  data: Scalars['Object'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  modificationRequestId?: Maybe<Scalars['String']>;
  operationType: TypeOfPostgresOperation;
  orderId?: Maybe<Scalars['String']>;
  performedAt: Scalars['DateTime'];
  performedByUserId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  type: TypeOfGrainOrderActivity;
};

export type GrainFillOrderModificationRequestActivityDistinctOnDTO = {
  data?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  modificationRequestId?: InputMaybe<Scalars['Boolean']>;
  operationType?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['Boolean']>;
  performedAt?: InputMaybe<Scalars['Boolean']>;
  performedByUserId?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
};

export type GrainFillOrderModificationRequestActivityFilterDTO = {
  AND?: InputMaybe<Array<GrainFillOrderModificationRequestActivityFilterDTO>>;
  AsGrainOrderActivity?: InputMaybe<GrainOrderActivityFilterDTO>;
  GrainFillOrder?: InputMaybe<GrainFillOrderFilterDTO>;
  GrainFillOrderModificationRequest?: InputMaybe<GrainFillOrderModificationRequestFilterDTO>;
  GrainOrder?: InputMaybe<GrainOrderFilterDTO>;
  GrainPlan?: InputMaybe<GrainPlanFilterDTO>;
  NOT?: InputMaybe<GrainFillOrderModificationRequestActivityFilterDTO>;
  OR?: InputMaybe<Array<GrainFillOrderModificationRequestActivityFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  id?: InputMaybe<StringFilter>;
  modificationRequestId?: InputMaybe<NullableStringFilter>;
  operationType?: InputMaybe<TypeOfPostgresOperationFilter>;
  orderId?: InputMaybe<NullableStringFilter>;
  performedAt?: InputMaybe<DateTimeFilter>;
  performedByUserId?: InputMaybe<NullableStringFilter>;
  planId?: InputMaybe<NullableStringFilter>;
};

export type GrainFillOrderModificationRequestActivitySortByDTO = {
  AsGrainOrderActivity?: InputMaybe<GrainOrderActivitySortByDTO>;
  GrainFillOrder?: InputMaybe<GrainFillOrderSortByDTO>;
  GrainFillOrderModificationRequest?: InputMaybe<GrainFillOrderModificationRequestSortByDTO>;
  GrainOrder?: InputMaybe<GrainOrderSortByDTO>;
  GrainPlan?: InputMaybe<GrainPlanSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  data?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  modificationRequestId?: InputMaybe<NullableSortByDirection>;
  operationType?: InputMaybe<SortByDirection>;
  orderId?: InputMaybe<NullableSortByDirection>;
  performedAt?: InputMaybe<SortByDirection>;
  performedByUserId?: InputMaybe<NullableSortByDirection>;
  planId?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
};

export type GrainFillOrderModificationRequestBuyerRelationsOwnerSetInput = {
  email?: InputMaybe<Scalars['String']>;
  grainFillOrderId: Scalars['String'];
};

export type GrainFillOrderModificationRequestCreateDTO = {
  buyerRelationsOwnerId?: InputMaybe<Scalars['String']>;
  data: GrainFillOrderModificationRequestDataInputDTO;
  fillId: Scalars['String'];
  shortNote?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<GrainFillOrderModificationStatus>;
  type: TypeOfGrainFillOrderModification;
};

export type GrainFillOrderModificationRequestDataDTO = {
  __typename?: 'GrainFillOrderModificationRequestDataDTO';
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  /** Only intended to be set or read for orders created against Feed plans. */
  feedFlatPrice?: Maybe<Scalars['Float']>;
  flexFee?: Maybe<Scalars['Float']>;
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  htaFee?: Maybe<Scalars['Float']>;
  /** @deprecated Use deliveryTerms instead. */
  isFob?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  rollFee?: Maybe<Scalars['Float']>;
  salesType?: Maybe<TypeOfGrainOrderSales>;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  vgsId?: Maybe<Scalars['Int']>;
};

export type GrainFillOrderModificationRequestDataInputDTO = {
  basis?: InputMaybe<Scalars['Float']>;
  bushels?: InputMaybe<Scalars['Float']>;
  buyerId?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  deliveryEndDate?: InputMaybe<Scalars['String']>;
  deliveryId?: InputMaybe<Scalars['String']>;
  deliveryMonth?: InputMaybe<Scalars['String']>;
  deliveryStartDate?: InputMaybe<Scalars['String']>;
  deliveryTerms?: InputMaybe<GrainOrderDeliveryTerms>;
  /** Only intended to be set or read for orders created against Feed plans. */
  feedFlatPrice?: InputMaybe<Scalars['Float']>;
  flexFee?: InputMaybe<Scalars['Float']>;
  futurePrice?: InputMaybe<Scalars['Float']>;
  futuresMonth?: InputMaybe<Scalars['String']>;
  htaFee?: InputMaybe<Scalars['Float']>;
  isFob?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
  lastUpdatedById?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  rollFee?: InputMaybe<Scalars['Float']>;
  salesType?: InputMaybe<TypeOfGrainOrderSales>;
  sellerId?: InputMaybe<Scalars['String']>;
  shortNote?: InputMaybe<Scalars['String']>;
  spread?: InputMaybe<Scalars['Float']>;
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type GrainFillOrderModificationRequestDistinctOnDTO = {
  buyerRelationsOwnerId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  fillId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  requestedByUserId?: InputMaybe<Scalars['Boolean']>;
  shortNote?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type GrainFillOrderModificationRequestFilterDTO = {
  AND?: InputMaybe<Array<GrainFillOrderModificationRequestFilterDTO>>;
  GrainFillOrder?: InputMaybe<GrainFillOrderFilterDTO>;
  GrainFillOrderModificationRequestActivity?: InputMaybe<GrainFillOrderModificationRequestActivityFilterDTO>;
  NOT?: InputMaybe<GrainFillOrderModificationRequestFilterDTO>;
  OR?: InputMaybe<Array<GrainFillOrderModificationRequestFilterDTO>>;
  OwnedByUser?: InputMaybe<UserFilterDTO>;
  RequestedByUser?: InputMaybe<UserFilterDTO>;
  buyerRelationsOwnerId?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fillId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  planId?: InputMaybe<StringFilter>;
  requestedByUserId?: InputMaybe<NullableStringFilter>;
  shortNote?: InputMaybe<NullableStringFilter>;
  status?: InputMaybe<GrainFillOrderModificationStatusFilter>;
  type?: InputMaybe<TypeOfGrainFillOrderModificationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GrainFillOrderModificationRequestSortByDTO = {
  GrainFillOrder?: InputMaybe<GrainFillOrderSortByDTO>;
  OwnedByUser?: InputMaybe<UserSortByDTO>;
  RequestedByUser?: InputMaybe<UserSortByDTO>;
  buyerRelationsOwnerId?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  fillId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  planId?: InputMaybe<SortByDirection>;
  requestedByUserId?: InputMaybe<NullableSortByDirection>;
  shortNote?: InputMaybe<NullableSortByDirection>;
  status?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type GrainFillOrderModificationRequestStatusUpdateInput = {
  grainFillOrderId: Scalars['String'];
  status: GrainFillOrderModificationStatus;
};

export enum GrainFillOrderModificationStatus {
  Cancelled = 'Cancelled',
  Filled = 'Filled',
  New = 'New',
  Pending = 'Pending',
  PendingCancellation = 'PendingCancellation',
  Rejected = 'Rejected',
  Submitted = 'Submitted',
  Working = 'Working'
}

export type GrainFillOrderModificationStatusFilter = {
  equals?: InputMaybe<GrainFillOrderModificationStatus>;
  in?: InputMaybe<Array<GrainFillOrderModificationStatus>>;
  not?: InputMaybe<GrainFillOrderModificationStatus>;
  notIn?: InputMaybe<Array<GrainFillOrderModificationStatus>>;
};

export type GrainFillOrderSortByDTO = {
  AsGrainOrder?: InputMaybe<GrainOrderSortByDTO>;
  Buyer?: InputMaybe<BuyerSortByDTO>;
  DeliveryLocation?: InputMaybe<GrainLocationSortByDTO>;
  GrainTargetOrder?: InputMaybe<GrainTargetOrderSortByDTO>;
  Location?: InputMaybe<GrainLocationSortByDTO>;
  Plan?: InputMaybe<GrainPlanSortByDTO>;
  Seller?: InputMaybe<SellerSortByDTO>;
  basis?: InputMaybe<NullableSortByDirection>;
  bushels?: InputMaybe<NullableSortByDirection>;
  buyerId?: InputMaybe<NullableSortByDirection>;
  contractNumber?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdBy?: InputMaybe<SortByDirection>;
  cropFlatPrice?: InputMaybe<NullableSortByDirection>;
  deliveryEndDate?: InputMaybe<NullableSortByDirection>;
  deliveryId?: InputMaybe<NullableSortByDirection>;
  deliveryMonth?: InputMaybe<NullableSortByDirection>;
  deliveryStartDate?: InputMaybe<NullableSortByDirection>;
  deliveryTerms?: InputMaybe<NullableSortByDirection>;
  feedFlatPrice?: InputMaybe<NullableSortByDirection>;
  feedPrice?: InputMaybe<NullableSortByDirection>;
  fees?: InputMaybe<SortByDirection>;
  flatPrice?: InputMaybe<NullableSortByDirection>;
  flexFee?: InputMaybe<SortByDirection>;
  futurePrice?: InputMaybe<NullableSortByDirection>;
  futuresMonth?: InputMaybe<NullableSortByDirection>;
  htaFee?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isFob?: InputMaybe<SortByDirection>;
  lastUpdatedBy?: InputMaybe<NullableSortByDirection>;
  lastUpdatedById?: InputMaybe<NullableSortByDirection>;
  locationId?: InputMaybe<NullableSortByDirection>;
  planId?: InputMaybe<SortByDirection>;
  rollFee?: InputMaybe<SortByDirection>;
  salesType?: InputMaybe<SortByDirection>;
  sellerId?: InputMaybe<NullableSortByDirection>;
  shortNote?: InputMaybe<NullableSortByDirection>;
  spread?: InputMaybe<NullableSortByDirection>;
  targetId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
};

export type GrainFillOrderUpdateDTO = {
  basis?: InputMaybe<Scalars['Float']>;
  bushels?: InputMaybe<Scalars['Float']>;
  buyerId?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  deliveryEndDate?: InputMaybe<Scalars['String']>;
  deliveryId?: InputMaybe<Scalars['String']>;
  deliveryMonth?: InputMaybe<Scalars['String']>;
  deliveryStartDate?: InputMaybe<Scalars['String']>;
  deliveryTerms?: InputMaybe<GrainOrderDeliveryTerms>;
  /** Only intended to be set or read for orders created against Feed plans. */
  feedFlatPrice?: InputMaybe<Scalars['Float']>;
  flexFee?: InputMaybe<Scalars['Float']>;
  futurePrice?: InputMaybe<Scalars['Float']>;
  futuresMonth?: InputMaybe<Scalars['String']>;
  htaFee?: InputMaybe<Scalars['Float']>;
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
  lastUpdatedById?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  rollFee?: InputMaybe<Scalars['Float']>;
  salesType?: InputMaybe<TypeOfGrainOrderSales>;
  sellerId?: InputMaybe<Scalars['String']>;
  shortNote?: InputMaybe<Scalars['String']>;
  spread?: InputMaybe<Scalars['Float']>;
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type GrainLocation = {
  __typename?: 'GrainLocation';
  DeliveryOrders: Array<IGrainOrder>;
  Orders: Array<IGrainOrder>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vgsId?: Maybe<Scalars['Int']>;
};


export type GrainLocation_DeliveryOrdersArgs = {
  orderBy?: InputMaybe<GrainOrderSortByDTO>;
  where?: InputMaybe<GrainOrderFilterDTO>;
};


export type GrainLocation_OrdersArgs = {
  orderBy?: InputMaybe<GrainOrderSortByDTO>;
  where?: InputMaybe<GrainOrderFilterDTO>;
};

export type GrainLocationCreateDTO = {
  name: Scalars['String'];
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type GrainLocationDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainLocationFilterDTO = {
  AND?: InputMaybe<Array<GrainLocationFilterDTO>>;
  DeliveryOrders?: InputMaybe<GrainOrderFilterDTO>;
  NOT?: InputMaybe<GrainLocationFilterDTO>;
  OR?: InputMaybe<Array<GrainLocationFilterDTO>>;
  Orders?: InputMaybe<GrainOrderFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
};

export type GrainLocationGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainLocationSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
};

export type GrainLocationUpdateDTO = {
  name?: InputMaybe<Scalars['String']>;
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type GrainOrder = IGrainOrder & {
  __typename?: 'GrainOrder';
  Activity: Array<GrainOrderActivity>;
  Buyer?: Maybe<Buyer>;
  DeliveryLocation?: Maybe<GrainLocation>;
  Location?: Maybe<GrainLocation>;
  Notes: Array<GrainOrderNote>;
  Plan?: Maybe<IGrainPlan>;
  Seller?: Maybe<Seller>;
  StatusUpdates: Array<GrainOrderStatusUpdate>;
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** Only intended to be read for orders created against Crop plans. */
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  /** Only intended to be set or read for orders created against Feed plans. */
  feedFlatPrice?: Maybe<Scalars['Float']>;
  /** Only intended to be read for orders created against Feed plans. */
  feedPrice?: Maybe<Scalars['Float']>;
  fees: Scalars['Float'];
  /**
   * Only intended to be read for orders created against Crop plans.
   * @deprecated Use "cropFlatPrice" instead
   */
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee: Scalars['Float'];
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  htaFee: Scalars['Float'];
  id: Scalars['ID'];
  /** @deprecated Use deliveryTerms instead. */
  isFob: Scalars['Boolean'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  planId: Scalars['String'];
  rollFee: Scalars['Float'];
  salesType: TypeOfGrainOrderSales;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  type: TypeOfGrainOrder;
  updatedAt: Scalars['DateTime'];
  vgsId?: Maybe<Scalars['Int']>;
};


export type GrainOrder_ActivityArgs = {
  orderBy?: InputMaybe<GrainOrderActivitySortByDTO>;
  where?: InputMaybe<GrainOrderActivityFilterDTO>;
};


export type GrainOrder_NotesArgs = {
  orderBy?: InputMaybe<GrainOrderNoteSortByDTO>;
  where?: InputMaybe<GrainOrderNoteFilterDTO>;
};


export type GrainOrder_StatusUpdatesArgs = {
  orderBy?: InputMaybe<GrainOrderStatusUpdateSortByDTO>;
  where?: InputMaybe<GrainOrderStatusUpdateFilterDTO>;
};

export type GrainOrderActivity = IGrainOrderActivity & {
  __typename?: 'GrainOrderActivity';
  GrainPlan?: Maybe<IGrainPlan>;
  User?: Maybe<User>;
  data: Scalars['Object'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  operationType: TypeOfPostgresOperation;
  orderId?: Maybe<Scalars['String']>;
  performedAt: Scalars['DateTime'];
  performedByUserId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  type: TypeOfGrainOrderActivity;
};

export type GrainOrderActivityDistinctOnDTO = {
  data?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  modificationRequestId?: InputMaybe<Scalars['Boolean']>;
  operationType?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['Boolean']>;
  performedAt?: InputMaybe<Scalars['Boolean']>;
  performedByUserId?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
};

export type GrainOrderActivityFilterDTO = {
  AND?: InputMaybe<Array<GrainOrderActivityFilterDTO>>;
  AsGrainFillOrderActivity?: InputMaybe<GrainFillOrderActivityFilterDTO>;
  AsGrainFillOrderModificationRequestActivity?: InputMaybe<GrainFillOrderModificationRequestActivityFilterDTO>;
  AsGrainTargetOrderActivity?: InputMaybe<GrainTargetOrderActivityFilterDTO>;
  GrainOrder?: InputMaybe<GrainOrderFilterDTO>;
  GrainPlan?: InputMaybe<GrainPlanFilterDTO>;
  NOT?: InputMaybe<GrainOrderActivityFilterDTO>;
  OR?: InputMaybe<Array<GrainOrderActivityFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  id?: InputMaybe<StringFilter>;
  modificationRequestId?: InputMaybe<NullableStringFilter>;
  operationType?: InputMaybe<TypeOfPostgresOperationFilter>;
  orderId?: InputMaybe<NullableStringFilter>;
  performedAt?: InputMaybe<DateTimeFilter>;
  performedByUserId?: InputMaybe<NullableStringFilter>;
  planId?: InputMaybe<NullableStringFilter>;
  type?: InputMaybe<TypeOfGrainOrderActivityFilter>;
};

export type GrainOrderActivitySortByDTO = {
  AsGrainFillOrderActivity?: InputMaybe<GrainFillOrderActivitySortByDTO>;
  AsGrainFillOrderModificationRequestActivity?: InputMaybe<GrainFillOrderModificationRequestActivitySortByDTO>;
  AsGrainTargetOrderActivity?: InputMaybe<GrainTargetOrderActivitySortByDTO>;
  GrainOrder?: InputMaybe<GrainOrderSortByDTO>;
  GrainPlan?: InputMaybe<GrainPlanSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  data?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  modificationRequestId?: InputMaybe<NullableSortByDirection>;
  operationType?: InputMaybe<SortByDirection>;
  orderId?: InputMaybe<NullableSortByDirection>;
  performedAt?: InputMaybe<SortByDirection>;
  performedByUserId?: InputMaybe<NullableSortByDirection>;
  planId?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
};

export enum GrainOrderDeliveryTerms {
  DAP = 'DAP',
  FOB = 'FOB'
}

export type GrainOrderDistinctOnDTO = {
  basis?: InputMaybe<Scalars['Boolean']>;
  bushels?: InputMaybe<Scalars['Boolean']>;
  buyerId?: InputMaybe<Scalars['Boolean']>;
  buyerRelationsOwnerId?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdBy?: InputMaybe<Scalars['Boolean']>;
  cropFlatPrice?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryId?: InputMaybe<Scalars['Boolean']>;
  deliveryMonth?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  deliveryTerms?: InputMaybe<Scalars['Boolean']>;
  expirationDate?: InputMaybe<Scalars['Boolean']>;
  feedFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedPrice?: InputMaybe<Scalars['Boolean']>;
  fees?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  flexFee?: InputMaybe<Scalars['Boolean']>;
  futurePrice?: InputMaybe<Scalars['Boolean']>;
  futuresMonth?: InputMaybe<Scalars['Boolean']>;
  htaFee?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isFob?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedById?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  rollFee?: InputMaybe<Scalars['Boolean']>;
  salesType?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Scalars['Boolean']>;
  shortNote?: InputMaybe<Scalars['Boolean']>;
  spread?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  targetId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainOrderFilterDTO = {
  AND?: InputMaybe<Array<GrainOrderFilterDTO>>;
  AsGrainFillOrder?: InputMaybe<GrainFillOrderFilterDTO>;
  AsGrainTargetOrder?: InputMaybe<GrainTargetOrderFilterDTO>;
  Buyer?: InputMaybe<BuyerFilterDTO>;
  DeliveryLocation?: InputMaybe<GrainLocationFilterDTO>;
  GrainOrderActivity?: InputMaybe<GrainOrderActivityFilterDTO>;
  Location?: InputMaybe<GrainLocationFilterDTO>;
  NOT?: InputMaybe<GrainOrderFilterDTO>;
  Notes?: InputMaybe<GrainOrderNoteFilterDTO>;
  OR?: InputMaybe<Array<GrainOrderFilterDTO>>;
  Plan?: InputMaybe<GrainPlanFilterDTO>;
  Seller?: InputMaybe<SellerFilterDTO>;
  StatusUpdates?: InputMaybe<GrainOrderStatusUpdateFilterDTO>;
  basis?: InputMaybe<NullableNumberFilter>;
  bushels?: InputMaybe<NullableNumberFilter>;
  buyerId?: InputMaybe<NullableStringFilter>;
  buyerRelationsOwnerId?: InputMaybe<NullableStringFilter>;
  contractNumber?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  cropFlatPrice?: InputMaybe<NullableNumberFilter>;
  deliveryEndDate?: InputMaybe<NullableStringFilter>;
  deliveryId?: InputMaybe<NullableStringFilter>;
  deliveryMonth?: InputMaybe<NullableStringFilter>;
  deliveryStartDate?: InputMaybe<NullableStringFilter>;
  deliveryTerms?: InputMaybe<NullableGrainOrderDeliveryTermsFilter>;
  expirationDate?: InputMaybe<NullableStringFilter>;
  feedFlatPrice?: InputMaybe<NullableNumberFilter>;
  feedPrice?: InputMaybe<NullableNumberFilter>;
  fees?: InputMaybe<NumberFilter>;
  flatPrice?: InputMaybe<NullableNumberFilter>;
  flexFee?: InputMaybe<NumberFilter>;
  futurePrice?: InputMaybe<NullableNumberFilter>;
  futuresMonth?: InputMaybe<NullableStringFilter>;
  htaFee?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  isFob?: InputMaybe<BooleanFilter>;
  lastUpdatedBy?: InputMaybe<NullableStringFilter>;
  lastUpdatedById?: InputMaybe<NullableStringFilter>;
  locationId?: InputMaybe<NullableStringFilter>;
  planId?: InputMaybe<StringFilter>;
  rollFee?: InputMaybe<NumberFilter>;
  salesType?: InputMaybe<TypeOfGrainOrderSalesFilter>;
  sellerId?: InputMaybe<NullableStringFilter>;
  shortNote?: InputMaybe<NullableStringFilter>;
  spread?: InputMaybe<NullableNumberFilter>;
  status?: InputMaybe<NullableTypeOfGrainOrderStatusFilter>;
  targetId?: InputMaybe<NullableStringFilter>;
  type?: InputMaybe<TypeOfGrainOrderFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
};

export type GrainOrderGroupByDTO = {
  AsGrainFillOrder?: InputMaybe<GrainFillOrderGroupByDTO>;
  AsGrainTargetOrder?: InputMaybe<GrainTargetOrderGroupByDTO>;
  Buyer?: InputMaybe<BuyerGroupByDTO>;
  DeliveryLocation?: InputMaybe<GrainLocationGroupByDTO>;
  Location?: InputMaybe<GrainLocationGroupByDTO>;
  Plan?: InputMaybe<GrainPlanGroupByDTO>;
  PlanAsGrainCropPlan?: InputMaybe<GrainCropPlanGroupByDTO>;
  PlanAsGrainFeedPlan?: InputMaybe<GrainFeedPlanGroupByDTO>;
  Seller?: InputMaybe<SellerGroupByDTO>;
  basis?: InputMaybe<Scalars['Boolean']>;
  bushels?: InputMaybe<Scalars['Boolean']>;
  buyerId?: InputMaybe<Scalars['Boolean']>;
  buyerRelationsOwnerId?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdBy?: InputMaybe<Scalars['Boolean']>;
  cropFlatPrice?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryId?: InputMaybe<Scalars['Boolean']>;
  deliveryMonth?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  deliveryTerms?: InputMaybe<Scalars['Boolean']>;
  expirationDate?: InputMaybe<Scalars['Boolean']>;
  feedFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedPrice?: InputMaybe<Scalars['Boolean']>;
  fees?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  flexFee?: InputMaybe<Scalars['Boolean']>;
  futurePrice?: InputMaybe<Scalars['Boolean']>;
  futuresMonth?: InputMaybe<Scalars['Boolean']>;
  htaFee?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isFob?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedById?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  rollFee?: InputMaybe<Scalars['Boolean']>;
  salesType?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Scalars['Boolean']>;
  shortNote?: InputMaybe<Scalars['Boolean']>;
  spread?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  targetId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainOrderNote = {
  __typename?: 'GrainOrderNote';
  Order: IGrainOrder;
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  note: Scalars['String'];
  orderId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vgsId?: Maybe<Scalars['Int']>;
  writtenBy: Scalars['String'];
};

export type GrainOrderNoteCreateDTO = {
  note: Scalars['String'];
  orderId: Scalars['String'];
  vgsId?: InputMaybe<Scalars['Int']>;
  writtenBy: Scalars['String'];
};

export type GrainOrderNoteDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
  writtenBy?: InputMaybe<Scalars['Boolean']>;
};

export type GrainOrderNoteFilterDTO = {
  AND?: InputMaybe<Array<GrainOrderNoteFilterDTO>>;
  NOT?: InputMaybe<GrainOrderNoteFilterDTO>;
  OR?: InputMaybe<Array<GrainOrderNoteFilterDTO>>;
  Order?: InputMaybe<GrainOrderFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  note?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
  writtenBy?: InputMaybe<StringFilter>;
};

export type GrainOrderNoteSortByDTO = {
  Order?: InputMaybe<GrainOrderSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  note?: InputMaybe<SortByDirection>;
  orderId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
  writtenBy?: InputMaybe<SortByDirection>;
};

export type GrainOrderNoteUpdateDTO = {
  note?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  vgsId?: InputMaybe<Scalars['Int']>;
  writtenBy?: InputMaybe<Scalars['String']>;
};

export type GrainOrderSortByDTO = {
  AsGrainFillOrder?: InputMaybe<GrainFillOrderSortByDTO>;
  AsGrainTargetOrder?: InputMaybe<GrainTargetOrderSortByDTO>;
  Buyer?: InputMaybe<BuyerSortByDTO>;
  DeliveryLocation?: InputMaybe<GrainLocationSortByDTO>;
  Location?: InputMaybe<GrainLocationSortByDTO>;
  Plan?: InputMaybe<GrainPlanSortByDTO>;
  Seller?: InputMaybe<SellerSortByDTO>;
  basis?: InputMaybe<NullableSortByDirection>;
  bushels?: InputMaybe<NullableSortByDirection>;
  buyerId?: InputMaybe<NullableSortByDirection>;
  buyerRelationsOwnerId?: InputMaybe<NullableSortByDirection>;
  contractNumber?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdBy?: InputMaybe<SortByDirection>;
  cropFlatPrice?: InputMaybe<NullableSortByDirection>;
  deliveryEndDate?: InputMaybe<NullableSortByDirection>;
  deliveryId?: InputMaybe<NullableSortByDirection>;
  deliveryMonth?: InputMaybe<NullableSortByDirection>;
  deliveryStartDate?: InputMaybe<NullableSortByDirection>;
  deliveryTerms?: InputMaybe<NullableSortByDirection>;
  expirationDate?: InputMaybe<NullableSortByDirection>;
  feedFlatPrice?: InputMaybe<NullableSortByDirection>;
  feedPrice?: InputMaybe<NullableSortByDirection>;
  fees?: InputMaybe<SortByDirection>;
  flatPrice?: InputMaybe<NullableSortByDirection>;
  flexFee?: InputMaybe<SortByDirection>;
  futurePrice?: InputMaybe<NullableSortByDirection>;
  futuresMonth?: InputMaybe<NullableSortByDirection>;
  htaFee?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isFob?: InputMaybe<SortByDirection>;
  lastUpdatedBy?: InputMaybe<NullableSortByDirection>;
  lastUpdatedById?: InputMaybe<NullableSortByDirection>;
  locationId?: InputMaybe<NullableSortByDirection>;
  planId?: InputMaybe<SortByDirection>;
  rollFee?: InputMaybe<SortByDirection>;
  salesType?: InputMaybe<SortByDirection>;
  sellerId?: InputMaybe<NullableSortByDirection>;
  shortNote?: InputMaybe<NullableSortByDirection>;
  spread?: InputMaybe<NullableSortByDirection>;
  status?: InputMaybe<NullableSortByDirection>;
  targetId?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
};

export type GrainOrderStatusUpdate = {
  __typename?: 'GrainOrderStatusUpdate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inputSource: InputSource;
  newStatus: TypeOfGrainOrderStatus;
  orderId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  vgsId?: Maybe<Scalars['Int']>;
};

export type GrainOrderStatusUpdateFilterDTO = {
  AND?: InputMaybe<Array<GrainOrderStatusUpdateFilterDTO>>;
  NOT?: InputMaybe<GrainOrderStatusUpdateFilterDTO>;
  OR?: InputMaybe<Array<GrainOrderStatusUpdateFilterDTO>>;
  Order?: InputMaybe<GrainOrderFilterDTO>;
  User?: InputMaybe<UserFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  newStatus?: InputMaybe<TypeOfGrainOrderStatusFilter>;
  orderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updatedBy?: InputMaybe<NullableStringFilter>;
  userId?: InputMaybe<NullableStringFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
};

export type GrainOrderStatusUpdateSortByDTO = {
  Order?: InputMaybe<GrainOrderSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  newStatus?: InputMaybe<SortByDirection>;
  orderId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  updatedBy?: InputMaybe<NullableSortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
};

export type GrainPlan = IGrainPlan & {
  __typename?: 'GrainPlan';
  Customer?: Maybe<CustomerEntity>;
  Orders: Array<IGrainOrder>;
  Organization: OrganizationEntity;
  breakEven?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  organizationId: Scalars['String'];
  type: TypeOfGrainPlan;
  updatedAt: Scalars['DateTime'];
};


export type GrainPlan_OrdersArgs = {
  orderBy?: InputMaybe<GrainOrderSortByDTO>;
  where?: InputMaybe<GrainOrderFilterDTO>;
};

export type GrainPlanDistinctOnDTO = {
  acres?: InputMaybe<Scalars['Boolean']>;
  aph?: InputMaybe<Scalars['Boolean']>;
  basisExposure?: InputMaybe<Scalars['Boolean']>;
  basisOnlyPurchased?: InputMaybe<Scalars['Boolean']>;
  breakEven?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  cropId?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  enrolledPercent?: InputMaybe<Scalars['Boolean']>;
  feedCategoryId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedYear?: InputMaybe<Scalars['Boolean']>;
  futuresExposure?: InputMaybe<Scalars['Boolean']>;
  futuresOnlyPurchased?: InputMaybe<Scalars['Boolean']>;
  goal?: InputMaybe<Scalars['Boolean']>;
  harvestBu?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  percentBasisExposure?: InputMaybe<Scalars['Boolean']>;
  percentFuturesExposure?: InputMaybe<Scalars['Boolean']>;
  percentPriced?: InputMaybe<Scalars['Boolean']>;
  percentTonsOpen?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  startDateMonth?: InputMaybe<Scalars['Boolean']>;
  storageBu?: InputMaybe<Scalars['Boolean']>;
  tons?: InputMaybe<Scalars['Boolean']>;
  totalTonsFlatPriced?: InputMaybe<Scalars['Boolean']>;
  totalTonsOpen?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
  yearId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainPlanFilterDTO = {
  AND?: InputMaybe<Array<GrainPlanFilterDTO>>;
  AsGrainCropPlan?: InputMaybe<GrainCropPlanFilterDTO>;
  AsGrainFeedPlan?: InputMaybe<GrainFeedPlanFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  GrainOrderActivity?: InputMaybe<GrainOrderActivityFilterDTO>;
  NOT?: InputMaybe<GrainPlanFilterDTO>;
  OR?: InputMaybe<Array<GrainPlanFilterDTO>>;
  Orders?: InputMaybe<GrainOrderFilterDTO>;
  Organization?: InputMaybe<OrganizationEntityFilterDTO>;
  acres?: InputMaybe<NullableNumberFilter>;
  aph?: InputMaybe<NullableNumberFilter>;
  basisExposure?: InputMaybe<NullableNumberFilter>;
  basisOnlyPurchased?: InputMaybe<NullableNumberFilter>;
  breakEven?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  cropId?: InputMaybe<NullableStringFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  enrolledPercent?: InputMaybe<NullableNumberFilter>;
  feedCategoryId?: InputMaybe<NullableStringFilter>;
  feedIngredientId?: InputMaybe<NullableStringFilter>;
  feedYear?: InputMaybe<NullableNumberFilter>;
  futuresExposure?: InputMaybe<NullableNumberFilter>;
  futuresOnlyPurchased?: InputMaybe<NullableNumberFilter>;
  goal?: InputMaybe<NullableNumberFilter>;
  harvestBu?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  percentBasisExposure?: InputMaybe<NullableNumberFilter>;
  percentFuturesExposure?: InputMaybe<NullableNumberFilter>;
  percentPriced?: InputMaybe<NullableNumberFilter>;
  percentTonsOpen?: InputMaybe<NullableNumberFilter>;
  startDate?: InputMaybe<NullableStringFilter>;
  startDateMonth?: InputMaybe<NullableNumberFilter>;
  storageBu?: InputMaybe<NullableNumberFilter>;
  tons?: InputMaybe<NullableNumberFilter>;
  totalTonsFlatPriced?: InputMaybe<NullableNumberFilter>;
  totalTonsOpen?: InputMaybe<NullableNumberFilter>;
  type?: InputMaybe<TypeOfGrainPlanFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
  yearId?: InputMaybe<NullableStringFilter>;
};

export type GrainPlanGroupByDTO = {
  AsGrainCropPlan?: InputMaybe<GrainCropPlanGroupByDTO>;
  AsGrainFeedPlan?: InputMaybe<GrainFeedPlanGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  Organization?: InputMaybe<OrganizationEntityGroupByDTO>;
  acres?: InputMaybe<Scalars['Boolean']>;
  aph?: InputMaybe<Scalars['Boolean']>;
  basisExposure?: InputMaybe<Scalars['Boolean']>;
  basisOnlyPurchased?: InputMaybe<Scalars['Boolean']>;
  breakEven?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  cropId?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  enrolledPercent?: InputMaybe<Scalars['Boolean']>;
  feedCategoryId?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  feedYear?: InputMaybe<Scalars['Boolean']>;
  futuresExposure?: InputMaybe<Scalars['Boolean']>;
  futuresOnlyPurchased?: InputMaybe<Scalars['Boolean']>;
  goal?: InputMaybe<Scalars['Boolean']>;
  harvestBu?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  percentBasisExposure?: InputMaybe<Scalars['Boolean']>;
  percentFuturesExposure?: InputMaybe<Scalars['Boolean']>;
  percentPriced?: InputMaybe<Scalars['Boolean']>;
  percentTonsOpen?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  startDateMonth?: InputMaybe<Scalars['Boolean']>;
  storageBu?: InputMaybe<Scalars['Boolean']>;
  tons?: InputMaybe<Scalars['Boolean']>;
  totalTonsFlatPriced?: InputMaybe<Scalars['Boolean']>;
  totalTonsOpen?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
  yearId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainPlanSortByDTO = {
  AsGrainCropPlan?: InputMaybe<GrainCropPlanSortByDTO>;
  AsGrainFeedPlan?: InputMaybe<GrainFeedPlanSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  Organization?: InputMaybe<OrganizationEntitySortByDTO>;
  acres?: InputMaybe<NullableSortByDirection>;
  aph?: InputMaybe<NullableSortByDirection>;
  basisExposure?: InputMaybe<NullableSortByDirection>;
  basisOnlyPurchased?: InputMaybe<NullableSortByDirection>;
  breakEven?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  cropId?: InputMaybe<NullableSortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  enrolledPercent?: InputMaybe<NullableSortByDirection>;
  feedCategoryId?: InputMaybe<NullableSortByDirection>;
  feedIngredientId?: InputMaybe<NullableSortByDirection>;
  feedYear?: InputMaybe<NullableSortByDirection>;
  futuresExposure?: InputMaybe<NullableSortByDirection>;
  futuresOnlyPurchased?: InputMaybe<NullableSortByDirection>;
  goal?: InputMaybe<NullableSortByDirection>;
  harvestBu?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  organizationId?: InputMaybe<SortByDirection>;
  percentBasisExposure?: InputMaybe<NullableSortByDirection>;
  percentFuturesExposure?: InputMaybe<NullableSortByDirection>;
  percentPriced?: InputMaybe<NullableSortByDirection>;
  percentTonsOpen?: InputMaybe<NullableSortByDirection>;
  startDate?: InputMaybe<NullableSortByDirection>;
  startDateMonth?: InputMaybe<NullableSortByDirection>;
  storageBu?: InputMaybe<NullableSortByDirection>;
  tons?: InputMaybe<NullableSortByDirection>;
  totalTonsFlatPriced?: InputMaybe<NullableSortByDirection>;
  totalTonsOpen?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
  yearId?: InputMaybe<NullableSortByDirection>;
};

export type GrainTargetOrder = IGrainOrder & {
  __typename?: 'GrainTargetOrder';
  Activity: Array<GrainOrderActivity>;
  Buyer?: Maybe<Buyer>;
  BuyerRelationsOwner?: Maybe<User>;
  DeliveryLocation?: Maybe<GrainLocation>;
  Location?: Maybe<GrainLocation>;
  Notes: Array<GrainOrderNote>;
  Plan?: Maybe<IGrainPlan>;
  Seller?: Maybe<Seller>;
  StatusUpdates: Array<GrainOrderStatusUpdate>;
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  buyerRelationsOwnerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** Only intended to be read for orders created against Crop plans. */
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  expirationDate?: Maybe<Scalars['String']>;
  /** Only intended to be set or read for orders created against Feed plans. */
  feedFlatPrice?: Maybe<Scalars['Float']>;
  /** Only intended to be read for orders created against Feed plans. */
  feedPrice?: Maybe<Scalars['Float']>;
  fees: Scalars['Float'];
  /**
   * Only intended to be read for orders created against Crop plans.
   * @deprecated Use "cropFlatPrice" instead
   */
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee: Scalars['Float'];
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  htaFee: Scalars['Float'];
  id: Scalars['ID'];
  /** @deprecated Use deliveryTerms instead. */
  isFob: Scalars['Boolean'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  planId: Scalars['String'];
  rollFee: Scalars['Float'];
  salesType: TypeOfGrainOrderSales;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  status: TypeOfGrainOrderStatus;
  type: TypeOfGrainOrder;
  updatedAt: Scalars['DateTime'];
  vgsId?: Maybe<Scalars['Int']>;
};


export type GrainTargetOrder_ActivityArgs = {
  orderBy?: InputMaybe<GrainOrderActivitySortByDTO>;
  where?: InputMaybe<GrainOrderActivityFilterDTO>;
};


export type GrainTargetOrder_NotesArgs = {
  orderBy?: InputMaybe<GrainOrderNoteSortByDTO>;
  where?: InputMaybe<GrainOrderNoteFilterDTO>;
};


export type GrainTargetOrder_StatusUpdatesArgs = {
  orderBy?: InputMaybe<GrainOrderStatusUpdateSortByDTO>;
  where?: InputMaybe<GrainOrderStatusUpdateFilterDTO>;
};

export type GrainTargetOrderActivity = IGrainOrderActivity & {
  __typename?: 'GrainTargetOrderActivity';
  GrainPlan?: Maybe<IGrainPlan>;
  GrainTargetOrder?: Maybe<GrainTargetOrder>;
  User?: Maybe<User>;
  data: Scalars['Object'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  operationType: TypeOfPostgresOperation;
  orderId?: Maybe<Scalars['String']>;
  performedAt: Scalars['DateTime'];
  performedByUserId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  type: TypeOfGrainOrderActivity;
};

export type GrainTargetOrderActivityDistinctOnDTO = {
  data?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  operationType?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['Boolean']>;
  performedAt?: InputMaybe<Scalars['Boolean']>;
  performedByUserId?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
};

export type GrainTargetOrderActivityFilterDTO = {
  AND?: InputMaybe<Array<GrainTargetOrderActivityFilterDTO>>;
  AsGrainOrderActivity?: InputMaybe<GrainOrderActivityFilterDTO>;
  GrainOrder?: InputMaybe<GrainOrderFilterDTO>;
  GrainPlan?: InputMaybe<GrainPlanFilterDTO>;
  GrainTargetOrder?: InputMaybe<GrainTargetOrderFilterDTO>;
  NOT?: InputMaybe<GrainTargetOrderActivityFilterDTO>;
  OR?: InputMaybe<Array<GrainTargetOrderActivityFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  id?: InputMaybe<StringFilter>;
  operationType?: InputMaybe<TypeOfPostgresOperationFilter>;
  orderId?: InputMaybe<NullableStringFilter>;
  performedAt?: InputMaybe<DateTimeFilter>;
  performedByUserId?: InputMaybe<NullableStringFilter>;
  planId?: InputMaybe<NullableStringFilter>;
};

export type GrainTargetOrderActivitySortByDTO = {
  AsGrainOrderActivity?: InputMaybe<GrainOrderActivitySortByDTO>;
  GrainOrder?: InputMaybe<GrainOrderSortByDTO>;
  GrainPlan?: InputMaybe<GrainPlanSortByDTO>;
  GrainTargetOrder?: InputMaybe<GrainTargetOrderSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  data?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  operationType?: InputMaybe<SortByDirection>;
  orderId?: InputMaybe<NullableSortByDirection>;
  performedAt?: InputMaybe<SortByDirection>;
  performedByUserId?: InputMaybe<NullableSortByDirection>;
  planId?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
};

export type GrainTargetOrderAggregateAllDTO = {
  basis?: InputMaybe<Scalars['Boolean']>;
  bushels?: InputMaybe<Scalars['Boolean']>;
  buyerId?: InputMaybe<Scalars['Boolean']>;
  buyerRelationsOwnerId?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdBy?: InputMaybe<Scalars['Boolean']>;
  cropFlatPrice?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryId?: InputMaybe<Scalars['Boolean']>;
  deliveryMonth?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  expirationDate?: InputMaybe<Scalars['Boolean']>;
  feedFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedPrice?: InputMaybe<Scalars['Boolean']>;
  fees?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  flexFee?: InputMaybe<Scalars['Boolean']>;
  futurePrice?: InputMaybe<Scalars['Boolean']>;
  futuresMonth?: InputMaybe<Scalars['Boolean']>;
  htaFee?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedById?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  rollFee?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Scalars['Boolean']>;
  shortNote?: InputMaybe<Scalars['Boolean']>;
  spread?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainTargetOrderAggregateDTO = {
  avg?: InputMaybe<GrainTargetOrderAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<GrainTargetOrderAggregateAllDTO>;
  min?: InputMaybe<GrainTargetOrderAggregateAllDTO>;
  sum?: InputMaybe<GrainTargetOrderAggregateNumericDTO>;
};

export type GrainTargetOrderAggregateNumericDTO = {
  basis?: InputMaybe<Scalars['Boolean']>;
  bushels?: InputMaybe<Scalars['Boolean']>;
  cropFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedPrice?: InputMaybe<Scalars['Boolean']>;
  fees?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  flexFee?: InputMaybe<Scalars['Boolean']>;
  futurePrice?: InputMaybe<Scalars['Boolean']>;
  htaFee?: InputMaybe<Scalars['Boolean']>;
  rollFee?: InputMaybe<Scalars['Boolean']>;
  spread?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainTargetOrderBuyerRelationsOwnerSetInput = {
  email?: InputMaybe<Scalars['String']>;
  grainTargetOrderId: Scalars['String'];
};

export type GrainTargetOrderCreateDTO = {
  basis?: InputMaybe<Scalars['Float']>;
  bushels?: InputMaybe<Scalars['Float']>;
  buyerId?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  deliveryEndDate?: InputMaybe<Scalars['String']>;
  deliveryId?: InputMaybe<Scalars['String']>;
  deliveryMonth?: InputMaybe<Scalars['String']>;
  deliveryStartDate?: InputMaybe<Scalars['String']>;
  deliveryTerms?: InputMaybe<GrainOrderDeliveryTerms>;
  expirationDate?: InputMaybe<Scalars['String']>;
  feedFlatPrice?: InputMaybe<Scalars['Float']>;
  flexFee?: InputMaybe<Scalars['Float']>;
  futurePrice?: InputMaybe<Scalars['Float']>;
  futuresMonth?: InputMaybe<Scalars['String']>;
  htaFee?: InputMaybe<Scalars['Float']>;
  isFob?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
  lastUpdatedById?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  planId: Scalars['String'];
  rollFee?: InputMaybe<Scalars['Float']>;
  salesType: TypeOfGrainOrderSales;
  sellerId?: InputMaybe<Scalars['String']>;
  shortNote?: InputMaybe<Scalars['String']>;
  spread?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<TypeOfGrainOrderStatus>;
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type GrainTargetOrderDistinctOnDTO = {
  basis?: InputMaybe<Scalars['Boolean']>;
  bushels?: InputMaybe<Scalars['Boolean']>;
  buyerId?: InputMaybe<Scalars['Boolean']>;
  buyerRelationsOwnerId?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdBy?: InputMaybe<Scalars['Boolean']>;
  cropFlatPrice?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryId?: InputMaybe<Scalars['Boolean']>;
  deliveryMonth?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  deliveryTerms?: InputMaybe<Scalars['Boolean']>;
  expirationDate?: InputMaybe<Scalars['Boolean']>;
  feedFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedPrice?: InputMaybe<Scalars['Boolean']>;
  fees?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  flexFee?: InputMaybe<Scalars['Boolean']>;
  futurePrice?: InputMaybe<Scalars['Boolean']>;
  futuresMonth?: InputMaybe<Scalars['Boolean']>;
  htaFee?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isFob?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedById?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  rollFee?: InputMaybe<Scalars['Boolean']>;
  salesType?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Scalars['Boolean']>;
  shortNote?: InputMaybe<Scalars['Boolean']>;
  spread?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainTargetOrderFilterDTO = {
  AND?: InputMaybe<Array<GrainTargetOrderFilterDTO>>;
  AsGrainOrder?: InputMaybe<GrainOrderFilterDTO>;
  Buyer?: InputMaybe<BuyerFilterDTO>;
  BuyerRelationsOwner?: InputMaybe<UserFilterDTO>;
  DeliveryLocation?: InputMaybe<GrainLocationFilterDTO>;
  GrainFillOrders?: InputMaybe<GrainFillOrderFilterDTO>;
  GrainOrderActivity?: InputMaybe<GrainOrderActivityFilterDTO>;
  GrainTargetOrderActivity?: InputMaybe<GrainTargetOrderActivityFilterDTO>;
  Location?: InputMaybe<GrainLocationFilterDTO>;
  NOT?: InputMaybe<GrainTargetOrderFilterDTO>;
  Notes?: InputMaybe<GrainOrderNoteFilterDTO>;
  OR?: InputMaybe<Array<GrainTargetOrderFilterDTO>>;
  Plan?: InputMaybe<GrainPlanFilterDTO>;
  Seller?: InputMaybe<SellerFilterDTO>;
  StatusUpdates?: InputMaybe<GrainOrderStatusUpdateFilterDTO>;
  basis?: InputMaybe<NullableNumberFilter>;
  bushels?: InputMaybe<NullableNumberFilter>;
  buyerId?: InputMaybe<NullableStringFilter>;
  buyerRelationsOwnerId?: InputMaybe<NullableStringFilter>;
  contractNumber?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  cropFlatPrice?: InputMaybe<NullableNumberFilter>;
  deliveryEndDate?: InputMaybe<NullableStringFilter>;
  deliveryId?: InputMaybe<NullableStringFilter>;
  deliveryMonth?: InputMaybe<NullableStringFilter>;
  deliveryStartDate?: InputMaybe<NullableStringFilter>;
  deliveryTerms?: InputMaybe<NullableGrainOrderDeliveryTermsFilter>;
  expirationDate?: InputMaybe<NullableStringFilter>;
  feedFlatPrice?: InputMaybe<NullableNumberFilter>;
  feedPrice?: InputMaybe<NullableNumberFilter>;
  fees?: InputMaybe<NumberFilter>;
  flatPrice?: InputMaybe<NullableNumberFilter>;
  flexFee?: InputMaybe<NumberFilter>;
  futurePrice?: InputMaybe<NullableNumberFilter>;
  futuresMonth?: InputMaybe<NullableStringFilter>;
  htaFee?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  isFob?: InputMaybe<BooleanFilter>;
  lastUpdatedBy?: InputMaybe<NullableStringFilter>;
  lastUpdatedById?: InputMaybe<NullableStringFilter>;
  locationId?: InputMaybe<NullableStringFilter>;
  planId?: InputMaybe<StringFilter>;
  rollFee?: InputMaybe<NumberFilter>;
  salesType?: InputMaybe<TypeOfGrainOrderSalesFilter>;
  sellerId?: InputMaybe<NullableStringFilter>;
  shortNote?: InputMaybe<NullableStringFilter>;
  spread?: InputMaybe<NullableNumberFilter>;
  status?: InputMaybe<TypeOfGrainOrderStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vgsId?: InputMaybe<NullableNumberFilter>;
};

export type GrainTargetOrderGroupByDTO = {
  AsGrainOrder?: InputMaybe<GrainOrderGroupByDTO>;
  Buyer?: InputMaybe<BuyerGroupByDTO>;
  BuyerRelationsOwner?: InputMaybe<UserGroupByDTO>;
  DeliveryLocation?: InputMaybe<GrainLocationGroupByDTO>;
  Location?: InputMaybe<GrainLocationGroupByDTO>;
  Plan?: InputMaybe<GrainPlanGroupByDTO>;
  PlanAsGrainCropPlan?: InputMaybe<GrainCropPlanGroupByDTO>;
  PlanAsGrainFeedPlan?: InputMaybe<GrainFeedPlanGroupByDTO>;
  Seller?: InputMaybe<SellerGroupByDTO>;
  basis?: InputMaybe<Scalars['Boolean']>;
  bushels?: InputMaybe<Scalars['Boolean']>;
  buyerId?: InputMaybe<Scalars['Boolean']>;
  buyerRelationsOwnerId?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdBy?: InputMaybe<Scalars['Boolean']>;
  cropFlatPrice?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryId?: InputMaybe<Scalars['Boolean']>;
  deliveryMonth?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  deliveryTerms?: InputMaybe<Scalars['Boolean']>;
  expirationDate?: InputMaybe<Scalars['Boolean']>;
  feedFlatPrice?: InputMaybe<Scalars['Boolean']>;
  feedPrice?: InputMaybe<Scalars['Boolean']>;
  fees?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  flexFee?: InputMaybe<Scalars['Boolean']>;
  futurePrice?: InputMaybe<Scalars['Boolean']>;
  futuresMonth?: InputMaybe<Scalars['Boolean']>;
  htaFee?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isFob?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedBy?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedById?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['Boolean']>;
  rollFee?: InputMaybe<Scalars['Boolean']>;
  salesType?: InputMaybe<Scalars['Boolean']>;
  sellerId?: InputMaybe<Scalars['Boolean']>;
  shortNote?: InputMaybe<Scalars['Boolean']>;
  spread?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vgsId?: InputMaybe<Scalars['Boolean']>;
};

export type GrainTargetOrderSortByDTO = {
  AsGrainOrder?: InputMaybe<GrainOrderSortByDTO>;
  Buyer?: InputMaybe<BuyerSortByDTO>;
  BuyerRelationsOwner?: InputMaybe<UserSortByDTO>;
  DeliveryLocation?: InputMaybe<GrainLocationSortByDTO>;
  Location?: InputMaybe<GrainLocationSortByDTO>;
  Plan?: InputMaybe<GrainPlanSortByDTO>;
  Seller?: InputMaybe<SellerSortByDTO>;
  basis?: InputMaybe<NullableSortByDirection>;
  bushels?: InputMaybe<NullableSortByDirection>;
  buyerId?: InputMaybe<NullableSortByDirection>;
  buyerRelationsOwnerId?: InputMaybe<NullableSortByDirection>;
  contractNumber?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdBy?: InputMaybe<SortByDirection>;
  cropFlatPrice?: InputMaybe<NullableSortByDirection>;
  deliveryEndDate?: InputMaybe<NullableSortByDirection>;
  deliveryId?: InputMaybe<NullableSortByDirection>;
  deliveryMonth?: InputMaybe<NullableSortByDirection>;
  deliveryStartDate?: InputMaybe<NullableSortByDirection>;
  deliveryTerms?: InputMaybe<NullableSortByDirection>;
  expirationDate?: InputMaybe<NullableSortByDirection>;
  feedFlatPrice?: InputMaybe<NullableSortByDirection>;
  feedPrice?: InputMaybe<NullableSortByDirection>;
  fees?: InputMaybe<SortByDirection>;
  flatPrice?: InputMaybe<NullableSortByDirection>;
  flexFee?: InputMaybe<SortByDirection>;
  futurePrice?: InputMaybe<NullableSortByDirection>;
  futuresMonth?: InputMaybe<NullableSortByDirection>;
  htaFee?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isFob?: InputMaybe<SortByDirection>;
  lastUpdatedBy?: InputMaybe<NullableSortByDirection>;
  lastUpdatedById?: InputMaybe<NullableSortByDirection>;
  locationId?: InputMaybe<NullableSortByDirection>;
  planId?: InputMaybe<SortByDirection>;
  rollFee?: InputMaybe<SortByDirection>;
  salesType?: InputMaybe<SortByDirection>;
  sellerId?: InputMaybe<NullableSortByDirection>;
  shortNote?: InputMaybe<NullableSortByDirection>;
  spread?: InputMaybe<NullableSortByDirection>;
  status?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  vgsId?: InputMaybe<NullableSortByDirection>;
};

export type GrainTargetOrderUpdateDTO = {
  basis?: InputMaybe<Scalars['Float']>;
  bushels?: InputMaybe<Scalars['Float']>;
  buyerId?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  deliveryEndDate?: InputMaybe<Scalars['String']>;
  deliveryId?: InputMaybe<Scalars['String']>;
  deliveryMonth?: InputMaybe<Scalars['String']>;
  deliveryStartDate?: InputMaybe<Scalars['String']>;
  deliveryTerms?: InputMaybe<GrainOrderDeliveryTerms>;
  expirationDate?: InputMaybe<Scalars['String']>;
  /** Only intended to be set or read for orders created against Feed plans. */
  feedFlatPrice?: InputMaybe<Scalars['Float']>;
  flexFee?: InputMaybe<Scalars['Float']>;
  futurePrice?: InputMaybe<Scalars['Float']>;
  futuresMonth?: InputMaybe<Scalars['String']>;
  htaFee?: InputMaybe<Scalars['Float']>;
  lastUpdatedBy?: InputMaybe<Scalars['String']>;
  lastUpdatedById?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  rollFee?: InputMaybe<Scalars['Float']>;
  salesType?: InputMaybe<TypeOfGrainOrderSales>;
  sellerId?: InputMaybe<Scalars['String']>;
  shortNote?: InputMaybe<Scalars['String']>;
  spread?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<TypeOfGrainOrderStatus>;
  vgsId?: InputMaybe<Scalars['Int']>;
};

export type GroupedByAccountDTO = {
  __typename?: 'GroupedByAccountDTO';
  AsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  Owner?: Maybe<GroupedByEntityDTO>;
  OwnerAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  OwnerAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  OwnerAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  OwnerAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  accountNumber?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  counterPartyId?: Maybe<Scalars['String']>;
  cqgId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentBalance?: Maybe<Scalars['Float']>;
  custodianAccountType?: Maybe<TypeOfCustodianAccount>;
  customerId?: Maybe<Scalars['String']>;
  fcmAccountLastActiveDate?: Maybe<Scalars['String']>;
  fcmAccountLastUpdatedDate?: Maybe<Scalars['String']>;
  fcmAccountOpenDate?: Maybe<Scalars['String']>;
  fcmId?: Maybe<Scalars['String']>;
  futuresMarketValue?: Maybe<Scalars['Float']>;
  hasTradedThisYear?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isDiscretionaryAccount?: Maybe<Scalars['Boolean']>;
  isHedgeAccount?: Maybe<Scalars['Boolean']>;
  isNetLiquidationZero?: Maybe<Scalars['Boolean']>;
  isParentAccount?: Maybe<Scalars['Boolean']>;
  liquidatingValue?: Maybe<Scalars['Float']>;
  marginCredit?: Maybe<Scalars['Float']>;
  marginExcessDeficit?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  openTradeEquity?: Maybe<Scalars['Float']>;
  optionsMarketValue?: Maybe<Scalars['Float']>;
  ownerId?: Maybe<Scalars['String']>;
  parentAccountId?: Maybe<Scalars['String']>;
  positionMargin?: Maybe<Scalars['Float']>;
  previousAccountBalance?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  purchasingPower?: Maybe<Scalars['Float']>;
  salesCode?: Maybe<Scalars['String']>;
  swapDealerId?: Maybe<Scalars['String']>;
  totalMargin?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfAccount>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByAipDTO = {
  __typename?: 'GroupedByAipDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rmaCode?: Maybe<RmaAipCode>;
  twoLetterRmaCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByBasisInstrumentDTO = {
  __typename?: 'GroupedByBasisInstrumentDTO';
  AsInstrument?: Maybe<GroupedByInstrumentDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  ProductLotSpecification?: Maybe<GroupedByProductLotSpecificationDTO>;
  SymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  UnderlyingFuture?: Maybe<GroupedByFutureDTO>;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEndAt?: Maybe<Scalars['DateTime']>;
  deliveryStartAt?: Maybe<Scalars['DateTime']>;
  displayDeliveryDate?: Maybe<Scalars['DateTime']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentSymbolGroupId?: Maybe<Scalars['String']>;
  isStandardContractSize?: Maybe<Scalars['Boolean']>;
  lastTradeAt?: Maybe<Scalars['DateTime']>;
  locationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productLotSpecificationId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfInstrument>;
  underlyingFutureId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByBrokerageAccountDTO = {
  __typename?: 'GroupedByBrokerageAccountDTO';
  AsAccount?: Maybe<GroupedByAccountDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  Fcm?: Maybe<GroupedByFcmDTO>;
  Owner?: Maybe<GroupedByEntityDTO>;
  OwnerAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  OwnerAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  OwnerAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  OwnerAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  ParentAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  accountNumber?: Maybe<Scalars['String']>;
  cqgId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentBalance?: Maybe<Scalars['Float']>;
  custodianAccountType?: Maybe<TypeOfCustodianAccount>;
  customerId?: Maybe<Scalars['String']>;
  fcmAccountLastActiveDate?: Maybe<Scalars['String']>;
  fcmAccountLastUpdatedDate?: Maybe<Scalars['String']>;
  fcmAccountOpenDate?: Maybe<Scalars['String']>;
  fcmId?: Maybe<Scalars['String']>;
  futuresMarketValue?: Maybe<Scalars['Float']>;
  hasTradedThisYear?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isDiscretionaryAccount?: Maybe<Scalars['Boolean']>;
  isHedgeAccount?: Maybe<Scalars['Boolean']>;
  isNetLiquidationZero?: Maybe<Scalars['Boolean']>;
  isParentAccount?: Maybe<Scalars['Boolean']>;
  liquidatingValue?: Maybe<Scalars['Float']>;
  marginCredit?: Maybe<Scalars['Float']>;
  marginExcessDeficit?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  openTradeEquity?: Maybe<Scalars['Float']>;
  optionsMarketValue?: Maybe<Scalars['Float']>;
  ownerId?: Maybe<Scalars['String']>;
  parentAccountId?: Maybe<Scalars['String']>;
  positionMargin?: Maybe<Scalars['Float']>;
  previousAccountBalance?: Maybe<Scalars['Float']>;
  purchasingPower?: Maybe<Scalars['Float']>;
  salesCode?: Maybe<Scalars['String']>;
  totalMargin?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfAccount>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByBusinessDataVersionDTO = {
  __typename?: 'GroupedByBusinessDataVersionDTO';
  AsVersion?: Maybe<GroupedByVersionDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  NextVersion?: Maybe<GroupedByVersionDTO>;
  PreviousVersion?: Maybe<GroupedByVersionDTO>;
  VersionedBusinessData?: Maybe<GroupedByVersionedBusinessDataDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nextVersionId?: Maybe<Scalars['String']>;
  previousVersionId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfVersion>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByBuyerDTO = {
  __typename?: 'GroupedByBuyerDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
};

export type GroupedByCashInstrumentDTO = {
  __typename?: 'GroupedByCashInstrumentDTO';
  AsInstrument?: Maybe<GroupedByInstrumentDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  ProductLotSpecification?: Maybe<GroupedByProductLotSpecificationDTO>;
  SymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  exchangeSymbol?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentSymbolGroupId?: Maybe<Scalars['String']>;
  isStandardContractSize?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productLotSpecificationId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfInstrument>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByCommitmentInstrumentDTO = {
  __typename?: 'GroupedByCommitmentInstrumentDTO';
  AsInstrument?: Maybe<GroupedByInstrumentDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  ProductLotSpecification?: Maybe<GroupedByProductLotSpecificationDTO>;
  SymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEndAt?: Maybe<Scalars['DateTime']>;
  deliveryStartAt?: Maybe<Scalars['DateTime']>;
  displayDeliveryDate?: Maybe<Scalars['DateTime']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentSymbolGroupId?: Maybe<Scalars['String']>;
  isStandardContractSize?: Maybe<Scalars['Boolean']>;
  lastTradeAt?: Maybe<Scalars['DateTime']>;
  locationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productLotSpecificationId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfInstrument>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByCountyDTO = {
  __typename?: 'GroupedByCountyDTO';
  MilkOrder?: Maybe<GroupedByMilkOrderDTO>;
  Region?: Maybe<GroupedByRegionDTO>;
  State?: Maybe<GroupedByStateDTO>;
  classIDifferential?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  milkOrderId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByCropCategoryDTO = {
  __typename?: 'GroupedByCropCategoryDTO';
  HedgeProduct?: Maybe<GroupedByProductDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hedgeProductId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
};

export type GroupedByCropDTO = {
  __typename?: 'GroupedByCropDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  Category?: Maybe<GroupedByCropCategoryDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cropCategoryId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByCurrentAllocationPositionDTO = {
  __typename?: 'GroupedByCurrentAllocationPositionDTO';
  Account?: Maybe<GroupedByAccountDTO>;
  AccountAsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AccountAsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AccountAsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  Owner?: Maybe<GroupedByEntityDTO>;
  OwnerAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  OwnerAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  OwnerAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  OwnerAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  accountId?: Maybe<Scalars['String']>;
  allocationPositionId?: Maybe<Scalars['String']>;
  businessId?: Maybe<Scalars['String']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentWeightedAveragePrice?: Maybe<Scalars['Float']>;
  effectiveHedgeDate?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  instrumentType?: Maybe<TypeOfInstrument>;
  lifetimeWeightedAveragePrice?: Maybe<Scalars['Float']>;
  openSideWeightedAveragePrice?: Maybe<Scalars['Float']>;
  openWeightedAveragePrice?: Maybe<Scalars['Float']>;
  optionType?: Maybe<TypeOfOption>;
  ownerId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  positionSide?: Maybe<Side>;
  productId?: Maybe<Scalars['String']>;
  unitQuantity?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByCurrentPositionDTO = {
  __typename?: 'GroupedByCurrentPositionDTO';
  Account?: Maybe<GroupedByAccountDTO>;
  AccountAsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AccountAsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AccountAsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  Owner?: Maybe<GroupedByEntityDTO>;
  OwnerAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  OwnerAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  OwnerAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  OwnerAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  PositionReconciliation?: Maybe<GroupedByPositionReconciliationDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  accountId?: Maybe<Scalars['String']>;
  accountType?: Maybe<TypeOfAccount>;
  calculatedRealizedPnl?: Maybe<Scalars['Float']>;
  calculatedUnrealizedPnl?: Maybe<Scalars['Float']>;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  currentWeightedAveragePrice?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['String']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  feeTotal?: Maybe<Scalars['Float']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  instrumentType?: Maybe<TypeOfInstrument>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isUnrealizedPnlFromFcmComponents?: Maybe<Scalars['Boolean']>;
  lifetimeWeightedAveragePrice?: Maybe<Scalars['Float']>;
  optionType?: Maybe<TypeOfOption>;
  ownerId?: Maybe<Scalars['String']>;
  positionComponentRealizedPnl?: Maybe<Scalars['Float']>;
  positionComponentUnrealizedPnl?: Maybe<Scalars['Float']>;
  positionSide?: Maybe<Side>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  totalTradeValue?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnlPriceDate?: Maybe<Scalars['String']>;
  weightedAveragePrice?: Maybe<Scalars['Float']>;
};

export type GroupedByCustomerEntityDTO = {
  __typename?: 'GroupedByCustomerEntityDTO';
  AsEntity?: Maybe<GroupedByEntityDTO>;
  County?: Maybe<GroupedByCountyDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  MilkOrder?: Maybe<GroupedByMilkOrderDTO>;
  Organization?: Maybe<GroupedByOrganizationEntityDTO>;
  Parent?: Maybe<GroupedByEntityDTO>;
  RelationshipOwner?: Maybe<GroupedByUserDTO>;
  averageFinishAgeInWeeks?: Maybe<Scalars['Int']>;
  averageFinishWeightInLbs?: Maybe<Scalars['Int']>;
  countyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identificationNumber?: Maybe<Scalars['Int']>;
  isVgs?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  milkOrderId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  relationshipOwnerId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfEntity>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsCustomerId?: Maybe<Scalars['Int']>;
};

export type GroupedByDrpEndorsementDTO = {
  __typename?: 'GroupedByDrpEndorsementDTO';
  Agent?: Maybe<GroupedByInsuranceAgentDTO>;
  AsInsuranceEndorsement?: Maybe<GroupedByInsuranceEndorsementDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  InsurancePolicy?: Maybe<GroupedByInsurancePolicyDTO>;
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  InsuredYieldAndPrice?: Maybe<GroupedByInsuredYieldAndPriceDTO>;
  Policy?: Maybe<GroupedByInsurancePolicyDTO>;
  Practice?: Maybe<GroupedByInsurancePracticeDTO>;
  RmaType?: Maybe<GroupedByRmaTypeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipDrpPremiumKey?: Maybe<Scalars['String']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueAmount?: Maybe<Scalars['String']>;
  expectedRevenueGuarantee?: Maybe<Scalars['String']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  inputSource?: Maybe<InputSource>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isShort?: Maybe<Scalars['Boolean']>;
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  rmaPracticeCode?: Maybe<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceEndorsement>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type GroupedByDrpInsuranceEndorsementDTO = {
  __typename?: 'GroupedByDrpInsuranceEndorsementDTO';
  Agent?: Maybe<GroupedByInsuranceAgentDTO>;
  AsInsuranceEndorsement?: Maybe<GroupedByInsuranceEndorsementDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  InsurancePolicy?: Maybe<GroupedByInsurancePolicyDTO>;
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  InsuredYieldAndPrice?: Maybe<GroupedByInsuredYieldAndPriceDTO>;
  Policy?: Maybe<GroupedByInsurancePolicyDTO>;
  Practice?: Maybe<GroupedByInsurancePracticeDTO>;
  RmaType?: Maybe<GroupedByRmaTypeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipDrpPremiumKey?: Maybe<Scalars['String']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueAmount?: Maybe<Scalars['String']>;
  expectedRevenueGuarantee?: Maybe<Scalars['String']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  inputSource?: Maybe<InputSource>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isShort?: Maybe<Scalars['Boolean']>;
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  rmaPracticeCode?: Maybe<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceEndorsement>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type GroupedByEntityDTO = {
  __typename?: 'GroupedByEntityDTO';
  AsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  AsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  AsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  AsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  Parent?: Maybe<GroupedByEntityDTO>;
  ParentAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  ParentAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  ParentAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  ParentAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  averageFinishAgeInWeeks?: Maybe<Scalars['Int']>;
  averageFinishWeightInLbs?: Maybe<Scalars['Int']>;
  countyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  currentBasis?: Maybe<Scalars['Float']>;
  customBasis?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['String']>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identificationNumber?: Maybe<Scalars['Int']>;
  isVgs?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  milkOrderId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  relationshipOwnerId?: Maybe<Scalars['String']>;
  rollingBasisNumberOfMonths?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfEntity>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsCustomerId?: Maybe<Scalars['Int']>;
};

export type GroupedByEntityGroupDTO = {
  __typename?: 'GroupedByEntityGroupDTO';
  AsEntity?: Maybe<GroupedByEntityDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  Organization?: Maybe<GroupedByOrganizationEntityDTO>;
  Parent?: Maybe<GroupedByEntityDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identificationNumber?: Maybe<Scalars['Int']>;
  isVgs?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfEntity>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByEntityIntegrationDTO = {
  __typename?: 'GroupedByEntityIntegrationDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByEntityIntegrationInstallationDTO = {
  __typename?: 'GroupedByEntityIntegrationInstallationDTO';
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  Integration?: Maybe<GroupedByEntityIntegrationDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['String']>;
  healthcheckId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByFcmDTO = {
  __typename?: 'GroupedByFcmDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  portalUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByFeedCategoryDTO = {
  __typename?: 'GroupedByFeedCategoryDTO';
  HedgeProduct?: Maybe<GroupedByProductDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultCmePercentageBasis?: Maybe<Scalars['Float']>;
  defaultCmeUsdBasis?: Maybe<Scalars['Float']>;
  defaultDryMatterPercentage?: Maybe<Scalars['Float']>;
  defaultFlatPricePerTon?: Maybe<Scalars['Float']>;
  hedgeProductId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByFeedIngredientActualUsageDTO = {
  __typename?: 'GroupedByFeedIngredientActualUsageDTO';
  AsFeedIngredientUsage?: Maybe<GroupedByFeedIngredientUsageDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  FeedIngredient?: Maybe<GroupedByFeedIngredientDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  basisPrice?: Maybe<Scalars['Float']>;
  cashPrice?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb?: Maybe<Scalars['Float']>;
  dmiUsageInTons?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  feedIngredientSeriesId?: Maybe<Scalars['String']>;
  feedUsageInLb?: Maybe<Scalars['Float']>;
  feedUsageInTons?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['Int']>;
  type?: Maybe<TypeOfFeedIngredientUsage>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type GroupedByFeedIngredientDTO = {
  __typename?: 'GroupedByFeedIngredientDTO';
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  FeedCategory?: Maybe<GroupedByFeedCategoryDTO>;
  FeedIngredientVersion?: Maybe<GroupedByFeedIngredientVersionDTO>;
  HedgeProduct?: Maybe<GroupedByProductDTO>;
  businessId?: Maybe<Scalars['String']>;
  cmePercentageBasis?: Maybe<Scalars['Float']>;
  cmeUsdBasis?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dryMatterPercent?: Maybe<Scalars['Float']>;
  feedCategoryId?: Maybe<Scalars['String']>;
  flatPricePerTon?: Maybe<Scalars['Float']>;
  hedgeProductId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pricingMethodology?: Maybe<FeedIngredientPricingMethodology>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['String']>;
  versionedConceptSeriesId?: Maybe<Scalars['String']>;
};

export type GroupedByFeedIngredientForecastedUsageDTO = {
  __typename?: 'GroupedByFeedIngredientForecastedUsageDTO';
  AsFeedIngredientUsage?: Maybe<GroupedByFeedIngredientUsageDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  FeedIngredient?: Maybe<GroupedByFeedIngredientDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  basisPrice?: Maybe<Scalars['Float']>;
  cashPrice?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb?: Maybe<Scalars['Float']>;
  dmiUsageInTons?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  feedIngredientSeriesId?: Maybe<Scalars['String']>;
  feedUsageInLb?: Maybe<Scalars['Float']>;
  feedUsageInTons?: Maybe<Scalars['Float']>;
  forecastedPerLbExpenseInUsd?: Maybe<Scalars['Float']>;
  forecastedTotalExpenseInUsd?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['Int']>;
  type?: Maybe<TypeOfFeedIngredientUsage>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type GroupedByFeedIngredientUsageDTO = {
  __typename?: 'GroupedByFeedIngredientUsageDTO';
  AsFeedIngredientActualUsage?: Maybe<GroupedByFeedIngredientActualUsageDTO>;
  AsFeedIngredientForecastedUsage?: Maybe<GroupedByFeedIngredientForecastedUsageDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  FeedIngredient?: Maybe<GroupedByFeedIngredientDTO>;
  absoluteQuarter?: Maybe<Scalars['Int']>;
  basisPrice?: Maybe<Scalars['Float']>;
  cashPrice?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb?: Maybe<Scalars['Float']>;
  dmiUsageInTons?: Maybe<Scalars['Float']>;
  entityId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  feedIngredientSeriesId?: Maybe<Scalars['String']>;
  feedUsageInLb?: Maybe<Scalars['Float']>;
  feedUsageInTons?: Maybe<Scalars['Float']>;
  forecastedPerLbExpenseInUsd?: Maybe<Scalars['Float']>;
  forecastedTotalExpenseInUsd?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Int']>;
  readableQuarter?: Maybe<Scalars['Int']>;
  type?: Maybe<TypeOfFeedIngredientUsage>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type GroupedByFeedIngredientVersionDTO = {
  __typename?: 'GroupedByFeedIngredientVersionDTO';
  AsVersion?: Maybe<GroupedByVersionDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  NextVersion?: Maybe<GroupedByVersionDTO>;
  PreviousVersion?: Maybe<GroupedByVersionDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nextVersionId?: Maybe<Scalars['String']>;
  previousVersionId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfVersion>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByFieldDTO = {
  __typename?: 'GroupedByFieldDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  acres?: Maybe<Scalars['Float']>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByForecastedLivestockFeedUsageDTO = {
  __typename?: 'GroupedByForecastedLivestockFeedUsageDTO';
  AsForecastedSwineLivestockFeedUsage?: Maybe<GroupedByForecastedSwineLivestockFeedUsageDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  Ingredient?: Maybe<GroupedByFeedIngredientDTO>;
  LivestockGroupFeedUsage?: Maybe<GroupedByLivestockGroupFeedUsageDTO>;
  LivestockGroupFeedUsageAsSwineLivestockGroupFeedUsage?: Maybe<GroupedBySwineLivestockGroupFeedUsageDTO>;
  ageInWeeks?: Maybe<Scalars['Int']>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  hedgePriceInUsd?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredientId?: Maybe<Scalars['String']>;
  livestockGroupFeedUsageId?: Maybe<Scalars['String']>;
  perLbExpenseInUsd?: Maybe<Scalars['Float']>;
  quantityInLbs?: Maybe<Scalars['Float']>;
  quantityInTons?: Maybe<Scalars['Float']>;
  readableWeek?: Maybe<Scalars['String']>;
  totalExpenseInUsd?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfForecastedLivestockFeedUsage>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByForecastedSwineLivestockFeedUsageDTO = {
  __typename?: 'GroupedByForecastedSwineLivestockFeedUsageDTO';
  AsForecastedLivestockFeedUsage?: Maybe<GroupedByForecastedLivestockFeedUsageDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  Ingredient?: Maybe<GroupedByFeedIngredientDTO>;
  LivestockGroupFeedUsage?: Maybe<GroupedByLivestockGroupFeedUsageDTO>;
  LivestockGroupFeedUsageAsSwineLivestockGroupFeedUsage?: Maybe<GroupedBySwineLivestockGroupFeedUsageDTO>;
  ageInWeeks?: Maybe<Scalars['Int']>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  firstDateOfMonth?: Maybe<Scalars['String']>;
  hedgePriceInUsd?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  ingredientId?: Maybe<Scalars['String']>;
  livestockGroupFeedUsageId?: Maybe<Scalars['String']>;
  perLbExpenseInUsd?: Maybe<Scalars['Float']>;
  quantityInLbs?: Maybe<Scalars['Float']>;
  quantityInTons?: Maybe<Scalars['Float']>;
  readableWeek?: Maybe<Scalars['String']>;
  totalExpenseInUsd?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfForecastedLivestockFeedUsage>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByForwardContractAccountDTO = {
  __typename?: 'GroupedByForwardContractAccountDTO';
  AsAccount?: Maybe<GroupedByAccountDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  Owner?: Maybe<GroupedByEntityDTO>;
  OwnerAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  OwnerAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  OwnerAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  OwnerAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  accountNumber?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  counterPartyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfAccount>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByFutureDTO = {
  __typename?: 'GroupedByFutureDTO';
  AsInstrument?: Maybe<GroupedByInstrumentDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  ProductLotSpecification?: Maybe<GroupedByProductLotSpecificationDTO>;
  SymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firstNoticeDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentSymbolGroupId?: Maybe<Scalars['String']>;
  isStandardContractSize?: Maybe<Scalars['Boolean']>;
  lastTradeAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productLotSpecificationId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfInstrument>;
  unitsPerContract?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  velaInstrumentId?: Maybe<Scalars['Int']>;
};

export type GroupedByGrainCropPlanDTO = {
  __typename?: 'GroupedByGrainCropPlanDTO';
  AsGrainPlan?: Maybe<GroupedByGrainPlanDTO>;
  CropCategory?: Maybe<GroupedByCropCategoryDTO>;
  CropYear?: Maybe<GroupedByGrainCropYearDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  Organization?: Maybe<GroupedByOrganizationEntityDTO>;
  acres?: Maybe<Scalars['Float']>;
  aph?: Maybe<Scalars['Float']>;
  breakEven?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cropId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  enrolledPercent?: Maybe<Scalars['Float']>;
  goal?: Maybe<Scalars['Float']>;
  harvestBu?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  storageBu?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfGrainPlan>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
  yearId?: Maybe<Scalars['String']>;
};

export type GroupedByGrainCropYearDTO = {
  __typename?: 'GroupedByGrainCropYearDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type GroupedByGrainFeedPlanDTO = {
  __typename?: 'GroupedByGrainFeedPlanDTO';
  AsGrainPlan?: Maybe<GroupedByGrainPlanDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  FeedCategory?: Maybe<GroupedByFeedCategoryDTO>;
  FeedIngredient?: Maybe<GroupedByFeedIngredientDTO>;
  Organization?: Maybe<GroupedByOrganizationEntityDTO>;
  basisExposure?: Maybe<Scalars['Float']>;
  basisOnlyPurchased?: Maybe<Scalars['Float']>;
  breakEven?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  feedCategoryId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  feedYear?: Maybe<Scalars['Int']>;
  futuresExposure?: Maybe<Scalars['Float']>;
  futuresOnlyPurchased?: Maybe<Scalars['Float']>;
  goal?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  percentBasisExposure?: Maybe<Scalars['Float']>;
  percentFuturesExposure?: Maybe<Scalars['Float']>;
  percentPriced?: Maybe<Scalars['Float']>;
  percentTonsOpen?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  startDateMonth?: Maybe<Scalars['Int']>;
  tons?: Maybe<Scalars['Float']>;
  totalTonsFlatPriced?: Maybe<Scalars['Float']>;
  totalTonsOpen?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfGrainPlan>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByGrainFillOrderDTO = {
  __typename?: 'GroupedByGrainFillOrderDTO';
  AsGrainOrder?: Maybe<GroupedByGrainOrderDTO>;
  Buyer?: Maybe<GroupedByBuyerDTO>;
  DeliveryLocation?: Maybe<GroupedByGrainLocationDTO>;
  GrainTargetOrder?: Maybe<GroupedByGrainTargetOrderDTO>;
  Location?: Maybe<GroupedByGrainLocationDTO>;
  Plan?: Maybe<GroupedByGrainPlanDTO>;
  PlanAsGrainCropPlan?: Maybe<GroupedByGrainCropPlanDTO>;
  PlanAsGrainFeedPlan?: Maybe<GroupedByGrainFeedPlanDTO>;
  Seller?: Maybe<GroupedBySellerDTO>;
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  feedFlatPrice?: Maybe<Scalars['Float']>;
  feedPrice?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee?: Maybe<Scalars['Float']>;
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  htaFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isFob?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  rollFee?: Maybe<Scalars['Float']>;
  salesType?: Maybe<TypeOfGrainOrderSales>;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  targetId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfGrainOrder>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
};

export type GroupedByGrainLocationDTO = {
  __typename?: 'GroupedByGrainLocationDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
};

export type GroupedByGrainOrderDTO = {
  __typename?: 'GroupedByGrainOrderDTO';
  AsGrainFillOrder?: Maybe<GroupedByGrainFillOrderDTO>;
  AsGrainTargetOrder?: Maybe<GroupedByGrainTargetOrderDTO>;
  Buyer?: Maybe<GroupedByBuyerDTO>;
  DeliveryLocation?: Maybe<GroupedByGrainLocationDTO>;
  Location?: Maybe<GroupedByGrainLocationDTO>;
  Plan?: Maybe<GroupedByGrainPlanDTO>;
  PlanAsGrainCropPlan?: Maybe<GroupedByGrainCropPlanDTO>;
  PlanAsGrainFeedPlan?: Maybe<GroupedByGrainFeedPlanDTO>;
  Seller?: Maybe<GroupedBySellerDTO>;
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  buyerRelationsOwnerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  expirationDate?: Maybe<Scalars['String']>;
  feedFlatPrice?: Maybe<Scalars['Float']>;
  feedPrice?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee?: Maybe<Scalars['Float']>;
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  htaFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isFob?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  rollFee?: Maybe<Scalars['Float']>;
  salesType?: Maybe<TypeOfGrainOrderSales>;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  status?: Maybe<TypeOfGrainOrderStatus>;
  targetId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfGrainOrder>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
};

export type GroupedByGrainPlanDTO = {
  __typename?: 'GroupedByGrainPlanDTO';
  AsGrainCropPlan?: Maybe<GroupedByGrainCropPlanDTO>;
  AsGrainFeedPlan?: Maybe<GroupedByGrainFeedPlanDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  Organization?: Maybe<GroupedByOrganizationEntityDTO>;
  acres?: Maybe<Scalars['Float']>;
  aph?: Maybe<Scalars['Float']>;
  basisExposure?: Maybe<Scalars['Float']>;
  basisOnlyPurchased?: Maybe<Scalars['Float']>;
  breakEven?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cropId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  enrolledPercent?: Maybe<Scalars['Float']>;
  feedCategoryId?: Maybe<Scalars['String']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  feedYear?: Maybe<Scalars['Int']>;
  futuresExposure?: Maybe<Scalars['Float']>;
  futuresOnlyPurchased?: Maybe<Scalars['Float']>;
  goal?: Maybe<Scalars['Float']>;
  harvestBu?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  percentBasisExposure?: Maybe<Scalars['Float']>;
  percentFuturesExposure?: Maybe<Scalars['Float']>;
  percentPriced?: Maybe<Scalars['Float']>;
  percentTonsOpen?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  startDateMonth?: Maybe<Scalars['Int']>;
  storageBu?: Maybe<Scalars['Float']>;
  tons?: Maybe<Scalars['Float']>;
  totalTonsFlatPriced?: Maybe<Scalars['Float']>;
  totalTonsOpen?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfGrainPlan>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
  yearId?: Maybe<Scalars['String']>;
};

export type GroupedByGrainTargetOrderDTO = {
  __typename?: 'GroupedByGrainTargetOrderDTO';
  AsGrainOrder?: Maybe<GroupedByGrainOrderDTO>;
  Buyer?: Maybe<GroupedByBuyerDTO>;
  BuyerRelationsOwner?: Maybe<GroupedByUserDTO>;
  DeliveryLocation?: Maybe<GroupedByGrainLocationDTO>;
  Location?: Maybe<GroupedByGrainLocationDTO>;
  Plan?: Maybe<GroupedByGrainPlanDTO>;
  PlanAsGrainCropPlan?: Maybe<GroupedByGrainCropPlanDTO>;
  PlanAsGrainFeedPlan?: Maybe<GroupedByGrainFeedPlanDTO>;
  Seller?: Maybe<GroupedBySellerDTO>;
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  buyerRelationsOwnerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  expirationDate?: Maybe<Scalars['String']>;
  feedFlatPrice?: Maybe<Scalars['Float']>;
  feedPrice?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee?: Maybe<Scalars['Float']>;
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  htaFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isFob?: Maybe<Scalars['Boolean']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  rollFee?: Maybe<Scalars['Float']>;
  salesType?: Maybe<TypeOfGrainOrderSales>;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  status?: Maybe<TypeOfGrainOrderStatus>;
  type?: Maybe<TypeOfGrainOrder>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vgsId?: Maybe<Scalars['Int']>;
};

export type GroupedByHedgeStrategyCurrentPositionDTO = {
  __typename?: 'GroupedByHedgeStrategyCurrentPositionDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  businessId?: Maybe<Scalars['String']>;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  feeTotal?: Maybe<Scalars['Float']>;
  firstTradeDate?: Maybe<Scalars['String']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  lastTradeDate?: Maybe<Scalars['String']>;
  positionSide?: Maybe<Side>;
  realizedPnl?: Maybe<Scalars['Float']>;
  unitQuantity?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnlPriceDate?: Maybe<Scalars['String']>;
};

export type GroupedByHedgeStrategyDTO = {
  __typename?: 'GroupedByHedgeStrategyDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  grossPnl?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  insuranceNetPremium?: Maybe<Scalars['Float']>;
  insuranceRealizedPnl?: Maybe<Scalars['Float']>;
  insuranceUnrealizedPnl?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  netPnl?: Maybe<Scalars['Float']>;
  netPremium?: Maybe<Scalars['Float']>;
  positionNetCommission?: Maybe<Scalars['Float']>;
  positionNetPremium?: Maybe<Scalars['Float']>;
  positionRealizedPnl?: Maybe<Scalars['Float']>;
  positionUnrealizedPnl?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<HedgeStrategyStatus>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByInstrumentDTO = {
  __typename?: 'GroupedByInstrumentDTO';
  AsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  AsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  AsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  AsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  AsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  AsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  AsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  AsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  AsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  ProductLotSpecification?: Maybe<GroupedByProductLotSpecificationDTO>;
  SymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEndAt?: Maybe<Scalars['DateTime']>;
  deliveryStartAt?: Maybe<Scalars['DateTime']>;
  displayDeliveryDate?: Maybe<Scalars['DateTime']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  firstNoticeDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentSymbolGroupId?: Maybe<Scalars['String']>;
  isStandardContractSize?: Maybe<Scalars['Boolean']>;
  lastTradeAt?: Maybe<Scalars['DateTime']>;
  locationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optionType?: Maybe<TypeOfOption>;
  priceInstrumentId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productLotSpecificationId?: Maybe<Scalars['String']>;
  settlementInstrumentId?: Maybe<Scalars['String']>;
  spreadType?: Maybe<TypeOfSpread>;
  strike?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInstrument>;
  underlyingFutureId?: Maybe<Scalars['String']>;
  underlyingInstrumentId?: Maybe<Scalars['String']>;
  unitsPerContract?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  velaInstrumentId?: Maybe<Scalars['Int']>;
};

export type GroupedByInstrumentSymbolGroupDTO = {
  __typename?: 'GroupedByInstrumentSymbolGroupDTO';
  Product?: Maybe<GroupedByProductDTO>;
  UnderlyingSymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  barchartRootSymbol?: Maybe<Scalars['String']>;
  contractType?: Maybe<TypeOfContract>;
  cqgRootSymbol?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayFactor?: Maybe<Scalars['Float']>;
  exchangeRootSymbol?: Maybe<Scalars['String']>;
  fractionDigits?: Maybe<Scalars['Int']>;
  gmiExchangeCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfInstrumentSymbolGroup>;
  underlyingSymbolGroupId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByInsuranceAgentDTO = {
  __typename?: 'GroupedByInsuranceAgentDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByInsuranceEndorsementDTO = {
  __typename?: 'GroupedByInsuranceEndorsementDTO';
  Agent?: Maybe<GroupedByInsuranceAgentDTO>;
  AsDrpEndorsement?: Maybe<GroupedByDrpEndorsementDTO>;
  AsDrpInsuranceEndorsement?: Maybe<GroupedByDrpInsuranceEndorsementDTO>;
  AsLgmInsuranceEndorsement?: Maybe<GroupedByLgmInsuranceEndorsementDTO>;
  AsLrpEndorsement?: Maybe<GroupedByLrpEndorsementDTO>;
  AsLrpInsuranceEndorsement?: Maybe<GroupedByLrpInsuranceEndorsementDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  InsurancePolicy?: Maybe<GroupedByInsurancePolicyDTO>;
  Policy?: Maybe<GroupedByInsurancePolicyDTO>;
  Practice?: Maybe<GroupedByInsurancePracticeDTO>;
  RmaType?: Maybe<GroupedByRmaTypeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipDrpPremiumKey?: Maybe<Scalars['String']>;
  aipLrpPremiumKey?: Maybe<Scalars['String']>;
  aoExpenseSubsidyAmount?: Maybe<Scalars['Float']>;
  baseSubsidyAmount?: Maybe<Scalars['Float']>;
  bfrVfrSubsidyAmount?: Maybe<Scalars['Float']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  ccSubsidyReductionAmount?: Maybe<Scalars['Float']>;
  ccSubsidyReductionPercent?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  commodityCode?: Maybe<Scalars['String']>;
  commodityCodeYear?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['String']>;
  commodityPrice?: Maybe<Scalars['Float']>;
  commodityPriceIsEstimated?: Maybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  cornTargetBushels?: Maybe<Scalars['Float']>;
  coverageEndDate?: Maybe<Scalars['String']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  coveragePrice?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentYield?: Maybe<Scalars['Float']>;
  dateOfForecastedIndemnity?: Maybe<Scalars['String']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  deductibleAmount?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  effectiveHedgeMonth?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueAmount?: Maybe<Scalars['String']>;
  expectedRevenueGuarantee?: Maybe<Scalars['String']>;
  feederCattleTargetWeight?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  grossMarginGuarantee?: Maybe<Scalars['Float']>;
  headCount?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  inputSource?: Maybe<InputSource>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredValue?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isShort?: Maybe<Scalars['Boolean']>;
  lastYield?: Maybe<Scalars['Float']>;
  lengthInWeeks?: Maybe<Scalars['Int']>;
  liability?: Maybe<Scalars['Float']>;
  liveCattleTargetWeight?: Maybe<Scalars['Float']>;
  livestockRate?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  revenueHedgeProductId?: Maybe<Scalars['String']>;
  rmaPracticeCode?: Maybe<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  rmaTypeId?: Maybe<Scalars['String']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  simulatedLossAmount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  targetWeightCwt?: Maybe<Scalars['Float']>;
  totalExpectedGrossMargin?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  totalTarget?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceEndorsement>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type GroupedByInsuranceHistoricalDrpPremiumDTO = {
  __typename?: 'GroupedByInsuranceHistoricalDrpPremiumDTO';
  AsInsuranceHistoricalPremium?: Maybe<GroupedByInsuranceHistoricalPremiumDTO>;
  InsuranceHistoricalPremiumInput?: Maybe<GroupedByInsuranceHistoricalPremiumInputDTO>;
  InsuranceHistoricalPremiumInputAsInsuranceHistoricalDrpPremiumInput?: Maybe<GroupedByInsuranceHistoricalDrpPremiumInputDTO>;
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  coverageLevel?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  grossPremium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  insurancePracticeDisplayDate?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  liability?: Maybe<Scalars['Float']>;
  netPremium?: Maybe<Scalars['Float']>;
  protectedPrice?: Maybe<Scalars['Float']>;
  revenueGuarantee?: Maybe<Scalars['Float']>;
  stateId?: Maybe<Scalars['String']>;
  subsidy?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceHistoricalPremium>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByInsuranceHistoricalDrpPremiumInputDTO = {
  __typename?: 'GroupedByInsuranceHistoricalDrpPremiumInputDTO';
  AsInsuranceHistoricalPremiumInput?: Maybe<GroupedByInsuranceHistoricalPremiumInputDTO>;
  classWeight?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isFullyClassIii?: Maybe<Scalars['Boolean']>;
  isFullyClassIv?: Maybe<Scalars['Boolean']>;
  production?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceHistoricalPremiumInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByInsuranceHistoricalPremiumDTO = {
  __typename?: 'GroupedByInsuranceHistoricalPremiumDTO';
  AsInsuranceHistoricalDrpPremium?: Maybe<GroupedByInsuranceHistoricalDrpPremiumDTO>;
  InsuranceHistoricalPremiumInput?: Maybe<GroupedByInsuranceHistoricalPremiumInputDTO>;
  InsuranceHistoricalPremiumInputAsInsuranceHistoricalDrpPremiumInput?: Maybe<GroupedByInsuranceHistoricalDrpPremiumInputDTO>;
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  coverageLevel?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  grossPremium?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  insurancePracticeDisplayDate?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  liability?: Maybe<Scalars['Float']>;
  netPremium?: Maybe<Scalars['Float']>;
  protectedPrice?: Maybe<Scalars['Float']>;
  revenueGuarantee?: Maybe<Scalars['Float']>;
  stateId?: Maybe<Scalars['String']>;
  subsidy?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceHistoricalPremium>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByInsuranceHistoricalPremiumInputDTO = {
  __typename?: 'GroupedByInsuranceHistoricalPremiumInputDTO';
  AsInsuranceHistoricalDrpPremiumInput?: Maybe<GroupedByInsuranceHistoricalDrpPremiumInputDTO>;
  classWeight?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isFullyClassIii?: Maybe<Scalars['Boolean']>;
  isFullyClassIv?: Maybe<Scalars['Boolean']>;
  production?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceHistoricalPremiumInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByInsurancePolicyDTO = {
  __typename?: 'GroupedByInsurancePolicyDTO';
  Aip?: Maybe<GroupedByAipDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  InsuranceAgent?: Maybe<GroupedByInsuranceAgentDTO>;
  Owner?: Maybe<GroupedByEntityDTO>;
  OwnerAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  OwnerAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  OwnerAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  OwnerAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  State?: Maybe<GroupedByStateDTO>;
  agencyName?: Maybe<Scalars['String']>;
  agentFirstName?: Maybe<Scalars['String']>;
  agentFullName?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  agentLastName?: Maybe<Scalars['String']>;
  aipId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  producerId?: Maybe<Scalars['String']>;
  producerName?: Maybe<Scalars['String']>;
  programType?: Maybe<TypeOfInsuranceProgram>;
  stateId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByInsurancePracticeDTO = {
  __typename?: 'GroupedByInsurancePracticeDTO';
  commodityCode?: Maybe<TypeOfInsuranceCommodity>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  practiceAbbreviation?: Maybe<Scalars['String']>;
  practiceCode?: Maybe<Scalars['String']>;
  programType?: Maybe<TypeOfInsuranceProgram>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByInsuredYieldAndPriceDTO = {
  __typename?: 'GroupedByInsuredYieldAndPriceDTO';
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  actualYield?: Maybe<Scalars['Float']>;
  classPriceWeightingFactorRestrictedValue?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactorRestrictedValue?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedClassIiiPrice?: Maybe<Scalars['Float']>;
  expectedClassIvPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedYield?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['DateTime']>;
  lastYield?: Maybe<Scalars['Float']>;
  lastYieldReleasedDate?: Maybe<Scalars['String']>;
  practiceCode?: Maybe<Scalars['String']>;
  practiceId?: Maybe<Scalars['String']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLedgerActualEntryDTO = {
  __typename?: 'GroupedByLedgerActualEntryDTO';
  AsLedgerEntry?: Maybe<GroupedByLedgerEntryDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  LedgerCategory?: Maybe<GroupedByLedgerCategoryDTO>;
  LedgerCategoryAsLedgerExpenseCategory?: Maybe<GroupedByLedgerExpenseCategoryDTO>;
  LedgerCategoryAsLedgerFeedCategory?: Maybe<GroupedByLedgerFeedCategoryDTO>;
  LedgerCategoryAsLedgerMilkCheckCategory?: Maybe<GroupedByLedgerMilkCheckCategoryDTO>;
  LedgerCategoryAsLedgerRevenueCategory?: Maybe<GroupedByLedgerRevenueCategoryDTO>;
  amount?: Maybe<Scalars['Float']>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  type?: Maybe<TypeOfLedgerEntry>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type GroupedByLedgerCategoryDTO = {
  __typename?: 'GroupedByLedgerCategoryDTO';
  AsLedgerExpenseCategory?: Maybe<GroupedByLedgerExpenseCategoryDTO>;
  AsLedgerFeedCategory?: Maybe<GroupedByLedgerFeedCategoryDTO>;
  AsLedgerMilkCheckCategory?: Maybe<GroupedByLedgerMilkCheckCategoryDTO>;
  AsLedgerRevenueCategory?: Maybe<GroupedByLedgerRevenueCategoryDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isStatic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfLedgerCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLedgerEntryDTO = {
  __typename?: 'GroupedByLedgerEntryDTO';
  AsLedgerActualEntry?: Maybe<GroupedByLedgerActualEntryDTO>;
  AsLedgerForecastedEntry?: Maybe<GroupedByLedgerForecastedEntryDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  LedgerCategory?: Maybe<GroupedByLedgerCategoryDTO>;
  LedgerCategoryAsLedgerExpenseCategory?: Maybe<GroupedByLedgerExpenseCategoryDTO>;
  LedgerCategoryAsLedgerFeedCategory?: Maybe<GroupedByLedgerFeedCategoryDTO>;
  LedgerCategoryAsLedgerMilkCheckCategory?: Maybe<GroupedByLedgerMilkCheckCategoryDTO>;
  LedgerCategoryAsLedgerRevenueCategory?: Maybe<GroupedByLedgerRevenueCategoryDTO>;
  amount?: Maybe<Scalars['Float']>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  type?: Maybe<TypeOfLedgerEntry>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type GroupedByLedgerExpenseCategoryDTO = {
  __typename?: 'GroupedByLedgerExpenseCategoryDTO';
  AsLedgerCategory?: Maybe<GroupedByLedgerCategoryDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isStatic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfLedgerCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLedgerFeedCategoryDTO = {
  __typename?: 'GroupedByLedgerFeedCategoryDTO';
  AsLedgerCategory?: Maybe<GroupedByLedgerCategoryDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isStatic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfLedgerCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLedgerForecastedEntryDTO = {
  __typename?: 'GroupedByLedgerForecastedEntryDTO';
  AsLedgerEntry?: Maybe<GroupedByLedgerEntryDTO>;
  Entity?: Maybe<GroupedByEntityDTO>;
  EntityAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  EntityAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  EntityAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  EntityAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  LedgerCategory?: Maybe<GroupedByLedgerCategoryDTO>;
  LedgerCategoryAsLedgerExpenseCategory?: Maybe<GroupedByLedgerExpenseCategoryDTO>;
  LedgerCategoryAsLedgerFeedCategory?: Maybe<GroupedByLedgerFeedCategoryDTO>;
  LedgerCategoryAsLedgerMilkCheckCategory?: Maybe<GroupedByLedgerMilkCheckCategoryDTO>;
  LedgerCategoryAsLedgerRevenueCategory?: Maybe<GroupedByLedgerRevenueCategoryDTO>;
  amount?: Maybe<Scalars['Float']>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  type?: Maybe<TypeOfLedgerEntry>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  year?: Maybe<Scalars['Int']>;
};

export type GroupedByLedgerMilkCheckCategoryDTO = {
  __typename?: 'GroupedByLedgerMilkCheckCategoryDTO';
  AsLedgerCategory?: Maybe<GroupedByLedgerCategoryDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isStatic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfLedgerCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLedgerRevenueCategoryDTO = {
  __typename?: 'GroupedByLedgerRevenueCategoryDTO';
  AsLedgerCategory?: Maybe<GroupedByLedgerCategoryDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isStatic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfLedgerCategory>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLegacyUserDTO = {
  __typename?: 'GroupedByLegacyUserDTO';
  User?: Maybe<GroupedByUserDTO>;
  acceptedEulaAt?: Maybe<Scalars['DateTime']>;
  active?: Maybe<Scalars['Boolean']>;
  baseAndOverbase?: Maybe<Scalars['Float']>;
  basisType?: Maybe<Scalars['Int']>;
  canTrade?: Maybe<Scalars['Boolean']>;
  costName0?: Maybe<Scalars['String']>;
  costName1?: Maybe<Scalars['String']>;
  costName2?: Maybe<Scalars['String']>;
  costName3?: Maybe<Scalars['String']>;
  costName4?: Maybe<Scalars['String']>;
  costName5?: Maybe<Scalars['String']>;
  costName6?: Maybe<Scalars['String']>;
  costName7?: Maybe<Scalars['String']>;
  costName8?: Maybe<Scalars['String']>;
  costName9?: Maybe<Scalars['String']>;
  costName10?: Maybe<Scalars['String']>;
  costName11?: Maybe<Scalars['String']>;
  costName12?: Maybe<Scalars['String']>;
  costName13?: Maybe<Scalars['String']>;
  costName14?: Maybe<Scalars['String']>;
  costName15?: Maybe<Scalars['String']>;
  costName16?: Maybe<Scalars['String']>;
  costName17?: Maybe<Scalars['String']>;
  costName18?: Maybe<Scalars['String']>;
  costName19?: Maybe<Scalars['String']>;
  costName20?: Maybe<Scalars['String']>;
  costName21?: Maybe<Scalars['String']>;
  costName22?: Maybe<Scalars['String']>;
  costName23?: Maybe<Scalars['String']>;
  costName24?: Maybe<Scalars['String']>;
  costName25?: Maybe<Scalars['String']>;
  costName26?: Maybe<Scalars['String']>;
  costName27?: Maybe<Scalars['String']>;
  costName28?: Maybe<Scalars['String']>;
  costName29?: Maybe<Scalars['String']>;
  costName30?: Maybe<Scalars['String']>;
  costName31?: Maybe<Scalars['String']>;
  costName32?: Maybe<Scalars['String']>;
  costName33?: Maybe<Scalars['String']>;
  costName34?: Maybe<Scalars['String']>;
  costName35?: Maybe<Scalars['String']>;
  costName36?: Maybe<Scalars['String']>;
  costName37?: Maybe<Scalars['String']>;
  costName38?: Maybe<Scalars['String']>;
  costName39?: Maybe<Scalars['String']>;
  costNameF?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fmmoDairyPrices?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isTrial?: Maybe<Scalars['Boolean']>;
  isTrialExpired?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  manualClassOneDifferential?: Maybe<Scalars['Float']>;
  optionshopId?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pricingModel?: Maybe<Scalars['String']>;
  quota?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Int']>;
  revenueNameA?: Maybe<Scalars['String']>;
  revenueNameB?: Maybe<Scalars['String']>;
  revenueNameC?: Maybe<Scalars['String']>;
  revenueNameD?: Maybe<Scalars['String']>;
  revenueNameE?: Maybe<Scalars['String']>;
  revenueNameF?: Maybe<Scalars['String']>;
  revenueNameG?: Maybe<Scalars['String']>;
  selectProducerId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  uiStyle?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vaultBasis?: Maybe<Scalars['Int']>;
  vaultBasisValue?: Maybe<Scalars['Float']>;
  vaultId?: Maybe<Scalars['String']>;
};

export type GroupedByLgmInsuranceEndorsementDTO = {
  __typename?: 'GroupedByLgmInsuranceEndorsementDTO';
  Agent?: Maybe<GroupedByInsuranceAgentDTO>;
  AsInsuranceEndorsement?: Maybe<GroupedByInsuranceEndorsementDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  InsurancePolicy?: Maybe<GroupedByInsurancePolicyDTO>;
  Policy?: Maybe<GroupedByInsurancePolicyDTO>;
  Practice?: Maybe<GroupedByInsurancePracticeDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  RmaType?: Maybe<GroupedByRmaTypeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aoExpenseSubsidyAmount?: Maybe<Scalars['Float']>;
  baseSubsidyAmount?: Maybe<Scalars['Float']>;
  bfrVfrSubsidyAmount?: Maybe<Scalars['Float']>;
  ccSubsidyReductionAmount?: Maybe<Scalars['Float']>;
  ccSubsidyReductionPercent?: Maybe<Scalars['Float']>;
  cornTargetBushels?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfForecastedIndemnity?: Maybe<Scalars['String']>;
  deductibleAmount?: Maybe<Scalars['Float']>;
  effectiveHedgeMonth?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  feederCattleTargetWeight?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  grossMarginGuarantee?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  inputSource?: Maybe<InputSource>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isShort?: Maybe<Scalars['Boolean']>;
  liability?: Maybe<Scalars['Float']>;
  liveCattleTargetWeight?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  revenueHedgeProductId?: Maybe<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  rmaTypeId?: Maybe<Scalars['String']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  simulatedLossAmount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  totalExpectedGrossMargin?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  totalTarget?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceEndorsement>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLivestockGroupContractPeriodAllocationDTO = {
  __typename?: 'GroupedByLivestockGroupContractPeriodAllocationDTO';
  LivestockGroupContract?: Maybe<GroupedBySwineLivestockGroupContractDTO>;
  allocatedQuantity?: Maybe<Scalars['Int']>;
  businessId?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  contractedQuantity?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['String']>;
  exactAllocatedQuantity?: Maybe<Scalars['Float']>;
  exactOpenQuantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  openQuantity?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLivestockGroupDTO = {
  __typename?: 'GroupedByLivestockGroupDTO';
  AsSwineLivestockGroup?: Maybe<GroupedBySwineLivestockGroupDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  LivestockGroupVersion?: Maybe<GroupedByLivestockGroupVersionDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deathRate?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  durationInWeeks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  maxAgeInWeeks?: Maybe<Scalars['Int']>;
  minAgeInWeeks?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfLivestockGroup>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['String']>;
  versionedConceptSeriesId?: Maybe<Scalars['String']>;
};

export type GroupedByLivestockGroupFeedUsageDTO = {
  __typename?: 'GroupedByLivestockGroupFeedUsageDTO';
  AsSwineLivestockGroupFeedUsage?: Maybe<GroupedBySwineLivestockGroupFeedUsageDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  FeedIngredient?: Maybe<GroupedByFeedIngredientDTO>;
  LivestockGroup?: Maybe<GroupedByLivestockGroupDTO>;
  LivestockGroupAsSwineLivestockGroup?: Maybe<GroupedBySwineLivestockGroupDTO>;
  Version?: Maybe<GroupedByLivestockGroupFeedUsageVersionDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  livestockGroupId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfLivestockGroupFeedUsage>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageInLbsPerHead?: Maybe<Scalars['Float']>;
  usageInTonsPerHead?: Maybe<Scalars['Float']>;
  versionId?: Maybe<Scalars['String']>;
  versionedConceptSeriesId?: Maybe<Scalars['String']>;
};

export type GroupedByLivestockGroupFeedUsageVersionDTO = {
  __typename?: 'GroupedByLivestockGroupFeedUsageVersionDTO';
  AsVersion?: Maybe<GroupedByVersionDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  NextVersion?: Maybe<GroupedByVersionDTO>;
  PreviousVersion?: Maybe<GroupedByVersionDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  livestockGroupFeedUsageType?: Maybe<TypeOfLivestockGroupFeedUsage>;
  name?: Maybe<Scalars['String']>;
  nextVersionId?: Maybe<Scalars['String']>;
  previousVersionId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfVersion>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLivestockGroupVersionDTO = {
  __typename?: 'GroupedByLivestockGroupVersionDTO';
  AsVersion?: Maybe<GroupedByVersionDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  NextVersion?: Maybe<GroupedByVersionDTO>;
  PreviousVersion?: Maybe<GroupedByVersionDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  livestockGroupType?: Maybe<TypeOfLivestockGroup>;
  name?: Maybe<Scalars['String']>;
  nextVersionId?: Maybe<Scalars['String']>;
  previousVersionId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfVersion>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLivestockPopulationChangeDTO = {
  __typename?: 'GroupedByLivestockPopulationChangeDTO';
  AsSwineLivestockPopulationChange?: Maybe<GroupedBySwineLivestockPopulationChangeDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  ContractPeriodAllocation?: Maybe<GroupedByLivestockGroupContractPeriodAllocationDTO>;
  businessId?: Maybe<Scalars['String']>;
  contractPeriodAllocationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  dobWeek?: Maybe<Scalars['Int']>;
  exactQuantity?: Maybe<Scalars['Float']>;
  exactTotalValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  marketingWeekStartDate?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  readableDobWeek?: Maybe<Scalars['String']>;
  readableWeek?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reasonType?: Maybe<TypeOfLivestockPopulationChangeReason>;
  startOfMonth?: Maybe<Scalars['String']>;
  systemComment?: Maybe<Scalars['String']>;
  totalValue?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfLivestockPopulationChange>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valuePerHead?: Maybe<Scalars['Float']>;
  valueType?: Maybe<TypeOfLivestockPopulationChangeValue>;
  week?: Maybe<Scalars['Int']>;
};

export type GroupedByLocationEntityDTO = {
  __typename?: 'GroupedByLocationEntityDTO';
  AsEntity?: Maybe<GroupedByEntityDTO>;
  County?: Maybe<GroupedByCountyDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  MilkOrder?: Maybe<GroupedByMilkOrderDTO>;
  Parent?: Maybe<GroupedByEntityDTO>;
  countyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  currentBasis?: Maybe<Scalars['Float']>;
  customBasis?: Maybe<Scalars['Float']>;
  customerId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identificationNumber?: Maybe<Scalars['Int']>;
  isVgs?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  milkOrderId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  rollingBasisNumberOfMonths?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfEntity>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLrpEndorsementDTO = {
  __typename?: 'GroupedByLrpEndorsementDTO';
  Agent?: Maybe<GroupedByInsuranceAgentDTO>;
  AsInsuranceEndorsement?: Maybe<GroupedByInsuranceEndorsementDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  InsurancePolicy?: Maybe<GroupedByInsurancePolicyDTO>;
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  Policy?: Maybe<GroupedByInsurancePolicyDTO>;
  Practice?: Maybe<GroupedByInsurancePracticeDTO>;
  RevenueHedgeProduct?: Maybe<GroupedByProductDTO>;
  RmaCommodity?: Maybe<GroupedByRmaCommodityDTO>;
  RmaType?: Maybe<GroupedByRmaTypeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipLrpPremiumKey?: Maybe<Scalars['String']>;
  commodityCode?: Maybe<Scalars['String']>;
  commodityCodeYear?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['String']>;
  commodityPrice?: Maybe<Scalars['Float']>;
  commodityPriceIsEstimated?: Maybe<Scalars['Boolean']>;
  coverageEndDate?: Maybe<Scalars['String']>;
  coveragePrice?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  declaredShare?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  headCount?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  inputSource?: Maybe<InputSource>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredValue?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isShort?: Maybe<Scalars['Boolean']>;
  lengthInWeeks?: Maybe<Scalars['Int']>;
  livestockRate?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  revenueHedgeProductId?: Maybe<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  rmaTypeId?: Maybe<Scalars['String']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  targetWeightCwt?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceEndorsement>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByLrpInsuranceEndorsementDTO = {
  __typename?: 'GroupedByLrpInsuranceEndorsementDTO';
  Agent?: Maybe<GroupedByInsuranceAgentDTO>;
  AsInsuranceEndorsement?: Maybe<GroupedByInsuranceEndorsementDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  InsurancePolicy?: Maybe<GroupedByInsurancePolicyDTO>;
  InsurancePractice?: Maybe<GroupedByInsurancePracticeDTO>;
  Policy?: Maybe<GroupedByInsurancePolicyDTO>;
  Practice?: Maybe<GroupedByInsurancePracticeDTO>;
  RevenueHedgeProduct?: Maybe<GroupedByProductDTO>;
  RmaCommodity?: Maybe<GroupedByRmaCommodityDTO>;
  RmaType?: Maybe<GroupedByRmaTypeDTO>;
  State?: Maybe<GroupedByStateDTO>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipLrpPremiumKey?: Maybe<Scalars['String']>;
  commodityCode?: Maybe<Scalars['String']>;
  commodityCodeYear?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['String']>;
  commodityPrice?: Maybe<Scalars['Float']>;
  commodityPriceIsEstimated?: Maybe<Scalars['Boolean']>;
  coverageEndDate?: Maybe<Scalars['String']>;
  coveragePrice?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  declaredShare?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  headCount?: Maybe<Scalars['Float']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  inputSource?: Maybe<InputSource>;
  insurancePolicyId?: Maybe<Scalars['String']>;
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  insuredValue?: Maybe<Scalars['Float']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong?: Maybe<Scalars['Boolean']>;
  isShort?: Maybe<Scalars['Boolean']>;
  lengthInWeeks?: Maybe<Scalars['Int']>;
  livestockRate?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  policyId?: Maybe<Scalars['String']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  revenueHedgeProductId?: Maybe<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  rmaTypeId?: Maybe<Scalars['String']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  targetWeightCwt?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInsuranceEndorsement>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByMilkOrderDTO = {
  __typename?: 'GroupedByMilkOrderDTO';
  Region?: Maybe<GroupedByRegionDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  federalOrderNumber?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByOptionDTO = {
  __typename?: 'GroupedByOptionDTO';
  AsInstrument?: Maybe<GroupedByInstrumentDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  ProductLotSpecification?: Maybe<GroupedByProductLotSpecificationDTO>;
  SymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  UnderlyingInstrument?: Maybe<GroupedByFutureDTO>;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentSymbolGroupId?: Maybe<Scalars['String']>;
  isStandardContractSize?: Maybe<Scalars['Boolean']>;
  lastTradeAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  optionType?: Maybe<TypeOfOption>;
  productId?: Maybe<Scalars['String']>;
  productLotSpecificationId?: Maybe<Scalars['String']>;
  strike?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInstrument>;
  underlyingInstrumentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  velaInstrumentId?: Maybe<Scalars['Int']>;
};

export type GroupedByOrderDTO = {
  __typename?: 'GroupedByOrderDTO';
  Account?: Maybe<GroupedByAccountDTO>;
  AccountAsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AccountAsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AccountAsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  TransactionGroup?: Maybe<GroupedByTransactionGroupDTO>;
  accountId?: Maybe<Scalars['String']>;
  atomicGroupId?: Maybe<Scalars['String']>;
  cqgId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentId?: Maybe<Scalars['String']>;
  orderType?: Maybe<TypeOfOrderExecution>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  status?: Maybe<OrderStatus>;
  timeInForce?: Maybe<TimeInForce>;
  transactionGroupId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByOrganizationEntityDTO = {
  __typename?: 'GroupedByOrganizationEntityDTO';
  AsEntity?: Maybe<GroupedByEntityDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  Parent?: Maybe<GroupedByEntityDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identificationNumber?: Maybe<Scalars['Int']>;
  isVgs?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfEntity>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByPhysicalInstrumentDTO = {
  __typename?: 'GroupedByPhysicalInstrumentDTO';
  AsInstrument?: Maybe<GroupedByInstrumentDTO>;
  FutureInstrument?: Maybe<GroupedByFutureDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  ProductLotSpecification?: Maybe<GroupedByProductLotSpecificationDTO>;
  SymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryEndAt?: Maybe<Scalars['DateTime']>;
  deliveryStartAt?: Maybe<Scalars['DateTime']>;
  displayDeliveryDate?: Maybe<Scalars['DateTime']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentSymbolGroupId?: Maybe<Scalars['String']>;
  isStandardContractSize?: Maybe<Scalars['Boolean']>;
  lastTradeAt?: Maybe<Scalars['DateTime']>;
  locationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productLotSpecificationId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfInstrument>;
  underlyingFutureId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByPositionReconciliationDTO = {
  __typename?: 'GroupedByPositionReconciliationDTO';
  Account?: Maybe<GroupedByBrokerageAccountDTO>;
  CurrentPosition?: Maybe<GroupedByCurrentPositionDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  accountId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  instrumentId?: Maybe<Scalars['String']>;
  isFullyReconciled?: Maybe<Scalars['Boolean']>;
  isUnsupported?: Maybe<Scalars['Boolean']>;
  positionId?: Maybe<Scalars['String']>;
  reasonUnsupported?: Maybe<Scalars['String']>;
  reconciledThroughDate?: Maybe<Scalars['String']>;
  unreconciledData?: Maybe<Scalars['Object']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByProductDTO = {
  __typename?: 'GroupedByProductDTO';
  ProductGroup?: Maybe<GroupedByProductGroupDTO>;
  Venue?: Maybe<GroupedByVenueDTO>;
  barchartDisplayFactor?: Maybe<Scalars['Float']>;
  barchartRootSymbol?: Maybe<Scalars['String']>;
  barchartSymbolStrikeDisplayFactor?: Maybe<Scalars['Float']>;
  contractName?: Maybe<Scalars['String']>;
  contractSize?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  cqgRootSymbol?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  dailyLimit?: Maybe<Scalars['String']>;
  displayFactor?: Maybe<Scalars['Float']>;
  exchangeMargin?: Maybe<Scalars['String']>;
  exchangeRootSymbol?: Maybe<Scalars['String']>;
  exchangeToGmiConversionFactor?: Maybe<Scalars['Float']>;
  fractionDigits?: Maybe<Scalars['Int']>;
  gmiExchangeCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  monthMapping?: Maybe<Scalars['Object']>;
  name?: Maybe<Scalars['String']>;
  optionsUnitValue?: Maybe<Scalars['Float']>;
  pointValue?: Maybe<Scalars['Float']>;
  productGroupId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tickValue?: Maybe<Scalars['String']>;
  tradingHours?: Maybe<Scalars['String']>;
  velaRootSymbol?: Maybe<Scalars['String']>;
  venueId?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
};

export type GroupedByProductGroupDTO = {
  __typename?: 'GroupedByProductGroupDTO';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GroupedByProductLotSpecificationDTO = {
  __typename?: 'GroupedByProductLotSpecificationDTO';
  Product?: Maybe<GroupedByProductDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isStandardLot?: Maybe<Scalars['Boolean']>;
  lotSize?: Maybe<Scalars['Float']>;
  pointValue?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  tickValue?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfProductLotSpecification>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByRegionDTO = {
  __typename?: 'GroupedByRegionDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  geom?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByRmaCommodityDTO = {
  __typename?: 'GroupedByRmaCommodityDTO';
  annualPlantingCode?: Maybe<Scalars['String']>;
  commodityAbbreviation?: Maybe<Scalars['String']>;
  commodityCode?: Maybe<Scalars['String']>;
  commodityCodeYear?: Maybe<Scalars['String']>;
  commodityName?: Maybe<Scalars['String']>;
  commodityYear?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastReleasedDate?: Maybe<Scalars['String']>;
  recordCategoryCode?: Maybe<Scalars['String']>;
  recordTypeCode?: Maybe<Scalars['String']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  releasedDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByRmaInsurancePlanDTO = {
  __typename?: 'GroupedByRmaInsurancePlanDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insurancePlanAbbreviation?: Maybe<Scalars['String']>;
  insurancePlanCode?: Maybe<Scalars['String']>;
  insurancePlanName?: Maybe<Scalars['String']>;
  lastReleasedDate?: Maybe<Scalars['String']>;
  recordCategoryCode?: Maybe<Scalars['String']>;
  recordTypeCode?: Maybe<Scalars['String']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  releasedDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByRmaTypeDTO = {
  __typename?: 'GroupedByRmaTypeDTO';
  RmaCommodity?: Maybe<GroupedByRmaCommodityDTO>;
  commodityCode?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastReleasedDate?: Maybe<Scalars['String']>;
  recordCategoryCode?: Maybe<Scalars['String']>;
  recordTypeCode?: Maybe<Scalars['String']>;
  reinsuranceYear?: Maybe<Scalars['Int']>;
  releasedDate?: Maybe<Scalars['String']>;
  typeAbbreviation?: Maybe<Scalars['String']>;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  typeName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedBySellerDTO = {
  __typename?: 'GroupedBySellerDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedBySpreadInstrumentDTO = {
  __typename?: 'GroupedBySpreadInstrumentDTO';
  AsInstrument?: Maybe<GroupedByInstrumentDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  ProductLotSpecification?: Maybe<GroupedByProductLotSpecificationDTO>;
  SymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentSymbolGroupId?: Maybe<Scalars['String']>;
  isStandardContractSize?: Maybe<Scalars['Boolean']>;
  lastTradeAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productLotSpecificationId?: Maybe<Scalars['String']>;
  spreadType?: Maybe<TypeOfSpread>;
  type?: Maybe<TypeOfInstrument>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByStateDTO = {
  __typename?: 'GroupedByStateDTO';
  abbreviation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<GroupedByRegionDTO>;
  regionId?: Maybe<Scalars['String']>;
  rmaStateCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedBySwapAccountDTO = {
  __typename?: 'GroupedBySwapAccountDTO';
  AsAccount?: Maybe<GroupedByAccountDTO>;
  Customer?: Maybe<GroupedByCustomerEntityDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  Owner?: Maybe<GroupedByEntityDTO>;
  OwnerAsCustomerEntity?: Maybe<GroupedByCustomerEntityDTO>;
  OwnerAsEntityGroup?: Maybe<GroupedByEntityGroupDTO>;
  OwnerAsLocationEntity?: Maybe<GroupedByLocationEntityDTO>;
  OwnerAsOrganizationEntity?: Maybe<GroupedByOrganizationEntityDTO>;
  SwapDealer?: Maybe<GroupedBySwapDealerDTO>;
  accountNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  swapDealerId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfAccount>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedBySwapDTO = {
  __typename?: 'GroupedBySwapDTO';
  AsInstrument?: Maybe<GroupedByInstrumentDTO>;
  PriceInstrument?: Maybe<GroupedByInstrumentDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  ProductLotSpecification?: Maybe<GroupedByProductLotSpecificationDTO>;
  SettlementInstrument?: Maybe<GroupedByFutureDTO>;
  SymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentSymbolGroupId?: Maybe<Scalars['String']>;
  isStandardContractSize?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  priceInstrumentId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productLotSpecificationId?: Maybe<Scalars['String']>;
  settlementInstrumentId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfInstrument>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedBySwapDealerDTO = {
  __typename?: 'GroupedBySwapDealerDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedBySwaptionDTO = {
  __typename?: 'GroupedBySwaptionDTO';
  AsInstrument?: Maybe<GroupedByInstrumentDTO>;
  PriceInstrument?: Maybe<GroupedByInstrumentDTO>;
  Product?: Maybe<GroupedByProductDTO>;
  ProductLotSpecification?: Maybe<GroupedByProductLotSpecificationDTO>;
  SettlementInstrument?: Maybe<GroupedByOptionDTO>;
  SymbolGroup?: Maybe<GroupedByInstrumentSymbolGroupDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayExpiresAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentSymbolGroupId?: Maybe<Scalars['String']>;
  isStandardContractSize?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  optionType?: Maybe<TypeOfOption>;
  priceInstrumentId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productLotSpecificationId?: Maybe<Scalars['String']>;
  settlementInstrumentId?: Maybe<Scalars['String']>;
  strike?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfInstrument>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedBySwineLivestockGroupContractDTO = {
  __typename?: 'GroupedBySwineLivestockGroupContractDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  allocatedQuantity?: Maybe<Scalars['Float']>;
  basisPricingType?: Maybe<TypeOfContractBasis>;
  businessId?: Maybe<Scalars['String']>;
  contractedQuantity?: Maybe<Scalars['Int']>;
  contractee?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  forecastPricingType?: Maybe<TypeOfContractPricing>;
  forecastPricingValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  openQuantity?: Maybe<Scalars['Float']>;
  periodAllocationType?: Maybe<TypeOfContractPeriodAllocation>;
  readableAllocatedQuantity?: Maybe<Scalars['Int']>;
  readableOpenQuantity?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedBySwineLivestockGroupDTO = {
  __typename?: 'GroupedBySwineLivestockGroupDTO';
  AsLivestockGroup?: Maybe<GroupedByLivestockGroupDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  LivestockGroupVersion?: Maybe<GroupedByLivestockGroupVersionDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deathRate?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  durationInWeeks?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  maxAgeInWeeks?: Maybe<Scalars['Int']>;
  minAgeInWeeks?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfLivestockGroup>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['String']>;
  versionedConceptSeriesId?: Maybe<Scalars['String']>;
};

export type GroupedBySwineLivestockGroupFeedUsageDTO = {
  __typename?: 'GroupedBySwineLivestockGroupFeedUsageDTO';
  AsLivestockGroupFeedUsage?: Maybe<GroupedByLivestockGroupFeedUsageDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  FeedIngredient?: Maybe<GroupedByFeedIngredientDTO>;
  LivestockGroup?: Maybe<GroupedByLivestockGroupDTO>;
  LivestockGroupAsSwineLivestockGroup?: Maybe<GroupedBySwineLivestockGroupDTO>;
  Version?: Maybe<GroupedByLivestockGroupFeedUsageVersionDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  feedIngredientId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  livestockGroupId?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfLivestockGroupFeedUsage>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageInLbsPerHead?: Maybe<Scalars['Float']>;
  usageInTonsPerHead?: Maybe<Scalars['Float']>;
  versionId?: Maybe<Scalars['String']>;
  versionedConceptSeriesId?: Maybe<Scalars['String']>;
};

export type GroupedBySwineLivestockPopulationChangeDTO = {
  __typename?: 'GroupedBySwineLivestockPopulationChangeDTO';
  AsLivestockPopulationChange?: Maybe<GroupedByLivestockPopulationChangeDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  ContractPeriodAllocation?: Maybe<GroupedByLivestockGroupContractPeriodAllocationDTO>;
  businessId?: Maybe<Scalars['String']>;
  contractPeriodAllocationId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  dobWeek?: Maybe<Scalars['Int']>;
  exactQuantity?: Maybe<Scalars['Float']>;
  exactTotalValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  marketingWeekStartDate?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  readableDobWeek?: Maybe<Scalars['String']>;
  readableWeek?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  reasonType?: Maybe<TypeOfLivestockPopulationChangeReason>;
  startOfMonth?: Maybe<Scalars['String']>;
  systemComment?: Maybe<Scalars['String']>;
  totalValue?: Maybe<Scalars['Float']>;
  type?: Maybe<TypeOfLivestockPopulationChange>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  valuePerHead?: Maybe<Scalars['Float']>;
  valueType?: Maybe<TypeOfLivestockPopulationChangeValue>;
  week?: Maybe<Scalars['Int']>;
};

export type GroupedByTransactionDTO = {
  __typename?: 'GroupedByTransactionDTO';
  Account?: Maybe<GroupedByAccountDTO>;
  AccountAsBrokerageAccount?: Maybe<GroupedByBrokerageAccountDTO>;
  AccountAsForwardContractAccount?: Maybe<GroupedByForwardContractAccountDTO>;
  AccountAsSwapAccount?: Maybe<GroupedBySwapAccountDTO>;
  CurrentPosition?: Maybe<GroupedByCurrentPositionDTO>;
  EntityIntegrationInstallation?: Maybe<GroupedByEntityIntegrationInstallationDTO>;
  HedgeStrategy?: Maybe<GroupedByHedgeStrategyDTO>;
  HedgeStrategyCurrentPosition?: Maybe<GroupedByHedgeStrategyCurrentPositionDTO>;
  Instrument?: Maybe<GroupedByInstrumentDTO>;
  InstrumentAsBasisInstrument?: Maybe<GroupedByBasisInstrumentDTO>;
  InstrumentAsCashInstrument?: Maybe<GroupedByCashInstrumentDTO>;
  InstrumentAsCommitmentInstrument?: Maybe<GroupedByCommitmentInstrumentDTO>;
  InstrumentAsFutureInstrument?: Maybe<GroupedByFutureDTO>;
  InstrumentAsOptionInstrument?: Maybe<GroupedByOptionDTO>;
  InstrumentAsPhysicalInstrument?: Maybe<GroupedByPhysicalInstrumentDTO>;
  InstrumentAsSpreadInstrument?: Maybe<GroupedBySpreadInstrumentDTO>;
  InstrumentAsSwapInstrument?: Maybe<GroupedBySwapDTO>;
  InstrumentAsSwaptionInstrument?: Maybe<GroupedBySwaptionDTO>;
  Order?: Maybe<GroupedByOrderDTO>;
  PositionReconciliation?: Maybe<GroupedByPositionReconciliationDTO>;
  TransactionGroup?: Maybe<GroupedByTransactionGroupDTO>;
  accountId?: Maybe<Scalars['String']>;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  executionType?: Maybe<TypeOfTransactionExecution>;
  feeTotal?: Maybe<Scalars['Float']>;
  hedgeStrategyCurrentPositionId?: Maybe<Scalars['String']>;
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inputSource?: Maybe<InputSource>;
  instrumentId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  occurredAt?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  tradeDate?: Maybe<Scalars['String']>;
  tradeMonth?: Maybe<Scalars['Int']>;
  tradeYear?: Maybe<Scalars['Int']>;
  transactionGroupId?: Maybe<Scalars['String']>;
  unitQuantity?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByTransactionGroupDTO = {
  __typename?: 'GroupedByTransactionGroupDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByUserDTO = {
  __typename?: 'GroupedByUserDTO';
  LegacyUser?: Maybe<GroupedByLegacyUserDTO>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isClaimed?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByVenueDTO = {
  __typename?: 'GroupedByVenueDTO';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByVersionDTO = {
  __typename?: 'GroupedByVersionDTO';
  AsBusinessDataVersion?: Maybe<GroupedByBusinessDataVersionDTO>;
  AsFeedIngredientVersion?: Maybe<GroupedByFeedIngredientVersionDTO>;
  AsLivestockGroupFeedUsageVersion?: Maybe<GroupedByLivestockGroupFeedUsageVersionDTO>;
  AsLivestockGroupVersion?: Maybe<GroupedByLivestockGroupVersionDTO>;
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  CreatedByUser?: Maybe<GroupedByUserDTO>;
  LastUpdatedByUser?: Maybe<GroupedByUserDTO>;
  NextVersion?: Maybe<GroupedByVersionDTO>;
  NextVersionAsBusinessDataVersion?: Maybe<GroupedByBusinessDataVersionDTO>;
  NextVersionAsFeedIngredientVersion?: Maybe<GroupedByFeedIngredientVersionDTO>;
  NextVersionAsLivestockGroupFeedUsageVersion?: Maybe<GroupedByLivestockGroupFeedUsageVersionDTO>;
  NextVersionAsLivestockGroupVersion?: Maybe<GroupedByLivestockGroupVersionDTO>;
  PreviousVersion?: Maybe<GroupedByVersionDTO>;
  PreviousVersionAsBusinessDataVersion?: Maybe<GroupedByBusinessDataVersionDTO>;
  PreviousVersionAsFeedIngredientVersion?: Maybe<GroupedByFeedIngredientVersionDTO>;
  PreviousVersionAsLivestockGroupFeedUsageVersion?: Maybe<GroupedByLivestockGroupFeedUsageVersionDTO>;
  PreviousVersionAsLivestockGroupVersion?: Maybe<GroupedByLivestockGroupVersionDTO>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  livestockGroupFeedUsageType?: Maybe<TypeOfLivestockGroupFeedUsage>;
  livestockGroupType?: Maybe<TypeOfLivestockGroup>;
  name?: Maybe<Scalars['String']>;
  nextVersionId?: Maybe<Scalars['String']>;
  previousVersionId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type?: Maybe<TypeOfVersion>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GroupedByVersionedBusinessDataDTO = {
  __typename?: 'GroupedByVersionedBusinessDataDTO';
  Business?: Maybe<GroupedByCustomerEntityDTO>;
  BusinessDataVersion?: Maybe<GroupedByBusinessDataVersionDTO>;
  averageFinishAgeInWeeks?: Maybe<Scalars['Int']>;
  averageFinishWeightInLbs?: Maybe<Scalars['Int']>;
  businessId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  versionId?: Maybe<Scalars['String']>;
  versionedConceptSeriesId?: Maybe<Scalars['String']>;
};

export type HedgeStrategy = {
  __typename?: 'HedgeStrategy';
  Business: CustomerEntity;
  HedgeStrategyHistoricalPnls: Array<HedgeStrategyHistoricalPnl>;
  InsuranceEndorsements: Array<IInsuranceEndorsement>;
  Product?: Maybe<Product>;
  Transactions: Array<Transaction>;
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  grossPnl: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  insuranceNetPremium: Scalars['Float'];
  insuranceRealizedPnl: Scalars['Float'];
  insuranceUnrealizedPnl: Scalars['Float'];
  name: Scalars['String'];
  netPnl: Scalars['Float'];
  netPremium: Scalars['Float'];
  positionNetCommission: Scalars['Float'];
  positionNetPremium: Scalars['Float'];
  positionRealizedPnl: Scalars['Float'];
  positionUnrealizedPnl: Scalars['Float'];
  productId?: Maybe<Scalars['String']>;
  realizedPnl: Scalars['Float'];
  startDate?: Maybe<Scalars['String']>;
  status: HedgeStrategyStatus;
  unrealizedPnl: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};


export type HedgeStrategy_HedgeStrategyHistoricalPnlsArgs = {
  orderBy?: InputMaybe<HedgeStrategyHistoricalPnlSortByDTO>;
  where?: InputMaybe<HedgeStrategyHistoricalPnlFilterDTO>;
};


export type HedgeStrategy_InsuranceEndorsementsArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementFilterDTO>;
};


export type HedgeStrategy_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type HedgeStrategyCreateDTO = {
  businessId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** The current positions, aggregated by hedge strategy & the given scopeId. This concept cannot be queried without a user & scopeId. The returned positions are dynamically computed on-demand, and will transcend accounts / businesses, if the given scope permits it. */
export type HedgeStrategyCurrentPosition = {
  __typename?: 'HedgeStrategyCurrentPosition';
  HedgeStrategy: HedgeStrategy;
  Instrument: IInstrument;
  Transactions: Array<Transaction>;
  businessId: Scalars['String'];
  commissionAndFeeTotal: Scalars['Float'];
  commissionTotal: Scalars['Float'];
  contractQuantity: Scalars['Float'];
  feeTotal: Scalars['Float'];
  /** The date of the earliest trade for this position */
  firstTradeDate: Scalars['String'];
  /** unrealizedPnl + realizedPnl */
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  hedgeStrategyId: Scalars['String'];
  id: Scalars['ID'];
  instrumentId: Scalars['String'];
  /** The date of the most recent trade for this position */
  lastTradeDate: Scalars['String'];
  positionSide: Side;
  /** The realized (locked-in) profit or loss for this position */
  realizedPnl?: Maybe<Scalars['Float']>;
  unitQuantity: Scalars['Float'];
  /** The unrealized (not yet locked-in) profit or loss for this position, computed using the most recently available EOD settlement. */
  unrealizedPnl?: Maybe<Scalars['Float']>;
  /** The date of the settlements used in the unrealized p/l calculation */
  unrealizedPnlPriceDate?: Maybe<Scalars['String']>;
};


/** The current positions, aggregated by hedge strategy & the given scopeId. This concept cannot be queried without a user & scopeId. The returned positions are dynamically computed on-demand, and will transcend accounts / businesses, if the given scope permits it. */
export type HedgeStrategyCurrentPosition_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type HedgeStrategyCurrentPositionAggregateAllDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  firstTradeDate?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  lastTradeDate?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnlPriceDate?: InputMaybe<Scalars['Boolean']>;
};

export type HedgeStrategyCurrentPositionAggregateDTO = {
  avg?: InputMaybe<HedgeStrategyCurrentPositionAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<HedgeStrategyCurrentPositionAggregateAllDTO>;
  min?: InputMaybe<HedgeStrategyCurrentPositionAggregateAllDTO>;
  sum?: InputMaybe<HedgeStrategyCurrentPositionAggregateNumericDTO>;
};

export type HedgeStrategyCurrentPositionAggregateNumericDTO = {
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
};

export type HedgeStrategyCurrentPositionDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  firstTradeDate?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  lastTradeDate?: InputMaybe<Scalars['Boolean']>;
  positionSide?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnlPriceDate?: InputMaybe<Scalars['Boolean']>;
};

export type HedgeStrategyCurrentPositionFilterDTO = {
  AND?: InputMaybe<Array<HedgeStrategyCurrentPositionFilterDTO>>;
  Account?: InputMaybe<BrokerageAccountFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyFilterDTO>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  OR?: InputMaybe<Array<HedgeStrategyCurrentPositionFilterDTO>>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  commissionAndFeeTotal?: InputMaybe<NumberFilter>;
  commissionTotal?: InputMaybe<NumberFilter>;
  contractQuantity?: InputMaybe<NumberFilter>;
  feeTotal?: InputMaybe<NumberFilter>;
  firstTradeDate?: InputMaybe<StringFilter>;
  grossPnl?: InputMaybe<NullableNumberFilter>;
  grossProtection?: InputMaybe<NullableNumberFilter>;
  hedgeStrategyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  lastTradeDate?: InputMaybe<StringFilter>;
  positionSide?: InputMaybe<SideFilter>;
  realizedPnl?: InputMaybe<NullableNumberFilter>;
  unitQuantity?: InputMaybe<NumberFilter>;
  unrealizedPnl?: InputMaybe<NullableNumberFilter>;
  unrealizedPnlPriceDate?: InputMaybe<NullableStringFilter>;
};

export type HedgeStrategyCurrentPositionGroupByDTO = {
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyGroupByDTO>;
  Instrument?: InputMaybe<InstrumentGroupByDTO>;
  InstrumentAsBasisInstrument?: InputMaybe<BasisInstrumentGroupByDTO>;
  InstrumentAsCashInstrument?: InputMaybe<CashInstrumentGroupByDTO>;
  InstrumentAsCommitmentInstrument?: InputMaybe<CommitmentInstrumentGroupByDTO>;
  InstrumentAsFutureInstrument?: InputMaybe<FutureGroupByDTO>;
  InstrumentAsOptionInstrument?: InputMaybe<OptionGroupByDTO>;
  InstrumentAsPhysicalInstrument?: InputMaybe<PhysicalInstrumentGroupByDTO>;
  InstrumentAsSpreadInstrument?: InputMaybe<SpreadInstrumentGroupByDTO>;
  InstrumentAsSwapInstrument?: InputMaybe<SwapGroupByDTO>;
  InstrumentAsSwaptionInstrument?: InputMaybe<SwaptionGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  firstTradeDate?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossProtection?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  lastTradeDate?: InputMaybe<Scalars['Boolean']>;
  positionSide?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnlPriceDate?: InputMaybe<Scalars['Boolean']>;
};

export type HedgeStrategyCurrentPositionSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategySortByDTO>;
  Instrument?: InputMaybe<InstrumentSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  commissionAndFeeTotal?: InputMaybe<SortByDirection>;
  commissionTotal?: InputMaybe<SortByDirection>;
  contractQuantity?: InputMaybe<SortByDirection>;
  feeTotal?: InputMaybe<SortByDirection>;
  firstTradeDate?: InputMaybe<SortByDirection>;
  grossPnl?: InputMaybe<NullableSortByDirection>;
  grossProtection?: InputMaybe<NullableSortByDirection>;
  hedgeStrategyId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  instrumentId?: InputMaybe<SortByDirection>;
  lastTradeDate?: InputMaybe<SortByDirection>;
  positionSide?: InputMaybe<SortByDirection>;
  realizedPnl?: InputMaybe<NullableSortByDirection>;
  unitQuantity?: InputMaybe<SortByDirection>;
  unrealizedPnl?: InputMaybe<NullableSortByDirection>;
  unrealizedPnlPriceDate?: InputMaybe<NullableSortByDirection>;
};

export type HedgeStrategyDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insuranceNetPremium?: InputMaybe<Scalars['Boolean']>;
  insuranceRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  insuranceUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  netPnl?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  positionNetCommission?: InputMaybe<Scalars['Boolean']>;
  positionNetPremium?: InputMaybe<Scalars['Boolean']>;
  positionRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type HedgeStrategyFilterDTO = {
  AND?: InputMaybe<Array<HedgeStrategyFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  HedgeStrategyHistoricalPnls?: InputMaybe<HedgeStrategyHistoricalPnlFilterDTO>;
  InsuranceEndorsements?: InputMaybe<InsuranceEndorsementFilterDTO>;
  NOT?: InputMaybe<HedgeStrategyFilterDTO>;
  OR?: InputMaybe<Array<HedgeStrategyFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<NullableStringFilter>;
  grossPnl?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  insuranceNetPremium?: InputMaybe<NumberFilter>;
  insuranceRealizedPnl?: InputMaybe<NumberFilter>;
  insuranceUnrealizedPnl?: InputMaybe<NumberFilter>;
  name?: InputMaybe<StringFilter>;
  netPnl?: InputMaybe<NumberFilter>;
  netPremium?: InputMaybe<NumberFilter>;
  positionNetCommission?: InputMaybe<NumberFilter>;
  positionNetPremium?: InputMaybe<NumberFilter>;
  positionRealizedPnl?: InputMaybe<NumberFilter>;
  positionUnrealizedPnl?: InputMaybe<NumberFilter>;
  productId?: InputMaybe<NullableStringFilter>;
  realizedPnl?: InputMaybe<NumberFilter>;
  startDate?: InputMaybe<NullableStringFilter>;
  status?: InputMaybe<HedgeStrategyStatusFilter>;
  unrealizedPnl?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type HedgeStrategyGroupByDTO = {
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insuranceNetPremium?: InputMaybe<Scalars['Boolean']>;
  insuranceRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  insuranceUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  netPnl?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  positionNetCommission?: InputMaybe<Scalars['Boolean']>;
  positionNetPremium?: InputMaybe<Scalars['Boolean']>;
  positionRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type HedgeStrategyHistoricalPnl = {
  __typename?: 'HedgeStrategyHistoricalPnl';
  Business: CustomerEntity;
  HedgeStrategy: HedgeStrategy;
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  grossPnl: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  hedgeStrategyId: Scalars['String'];
  id: Scalars['ID'];
  insuranceNetPremium: Scalars['Float'];
  insuranceRealizedPnl: Scalars['Float'];
  insuranceUnrealizedPnl: Scalars['Float'];
  netPnl: Scalars['Float'];
  netPremium: Scalars['Float'];
  positionNetPremium: Scalars['Float'];
  positionRealizedPnl: Scalars['Float'];
  positionUnrealizedPnl: Scalars['Float'];
  realizedPnl: Scalars['Float'];
  unrealizedPnl: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type HedgeStrategyHistoricalPnlAggregateAllDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insuranceNetPremium?: InputMaybe<Scalars['Boolean']>;
  insuranceRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  insuranceUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  netPnl?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  positionNetPremium?: InputMaybe<Scalars['Boolean']>;
  positionRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type HedgeStrategyHistoricalPnlAggregateDTO = {
  avg?: InputMaybe<HedgeStrategyHistoricalPnlAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<HedgeStrategyHistoricalPnlAggregateAllDTO>;
  min?: InputMaybe<HedgeStrategyHistoricalPnlAggregateAllDTO>;
  sum?: InputMaybe<HedgeStrategyHistoricalPnlAggregateNumericDTO>;
};

export type HedgeStrategyHistoricalPnlAggregateNumericDTO = {
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  insuranceNetPremium?: InputMaybe<Scalars['Boolean']>;
  insuranceRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  insuranceUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  netPnl?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  positionNetPremium?: InputMaybe<Scalars['Boolean']>;
  positionRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
};

export type HedgeStrategyHistoricalPnlDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insuranceNetPremium?: InputMaybe<Scalars['Boolean']>;
  insuranceRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  insuranceUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  netPnl?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  positionNetPremium?: InputMaybe<Scalars['Boolean']>;
  positionRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type HedgeStrategyHistoricalPnlFilterDTO = {
  AND?: InputMaybe<Array<HedgeStrategyHistoricalPnlFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyFilterDTO>;
  NOT?: InputMaybe<HedgeStrategyHistoricalPnlFilterDTO>;
  OR?: InputMaybe<Array<HedgeStrategyHistoricalPnlFilterDTO>>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  grossPnl?: InputMaybe<NumberFilter>;
  hedgeStrategyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  insuranceNetPremium?: InputMaybe<NumberFilter>;
  insuranceRealizedPnl?: InputMaybe<NumberFilter>;
  insuranceUnrealizedPnl?: InputMaybe<NumberFilter>;
  netPnl?: InputMaybe<NumberFilter>;
  netPremium?: InputMaybe<NumberFilter>;
  positionNetPremium?: InputMaybe<NumberFilter>;
  positionRealizedPnl?: InputMaybe<NumberFilter>;
  positionUnrealizedPnl?: InputMaybe<NumberFilter>;
  realizedPnl?: InputMaybe<NumberFilter>;
  unrealizedPnl?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type HedgeStrategyHistoricalPnlGroupByDTO = {
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insuranceNetPremium?: InputMaybe<Scalars['Boolean']>;
  insuranceRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  insuranceUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  netPnl?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  positionNetPremium?: InputMaybe<Scalars['Boolean']>;
  positionRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  positionUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type HedgeStrategyHistoricalPnlSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategySortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  grossPnl?: InputMaybe<SortByDirection>;
  hedgeStrategyId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insuranceNetPremium?: InputMaybe<SortByDirection>;
  insuranceRealizedPnl?: InputMaybe<SortByDirection>;
  insuranceUnrealizedPnl?: InputMaybe<SortByDirection>;
  netPnl?: InputMaybe<SortByDirection>;
  netPremium?: InputMaybe<SortByDirection>;
  positionNetPremium?: InputMaybe<SortByDirection>;
  positionRealizedPnl?: InputMaybe<SortByDirection>;
  positionUnrealizedPnl?: InputMaybe<SortByDirection>;
  realizedPnl?: InputMaybe<SortByDirection>;
  unrealizedPnl?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type HedgeStrategySortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<NullableSortByDirection>;
  grossPnl?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insuranceNetPremium?: InputMaybe<SortByDirection>;
  insuranceRealizedPnl?: InputMaybe<SortByDirection>;
  insuranceUnrealizedPnl?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  netPnl?: InputMaybe<SortByDirection>;
  netPremium?: InputMaybe<SortByDirection>;
  positionNetCommission?: InputMaybe<SortByDirection>;
  positionNetPremium?: InputMaybe<SortByDirection>;
  positionRealizedPnl?: InputMaybe<SortByDirection>;
  positionUnrealizedPnl?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<NullableSortByDirection>;
  realizedPnl?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<NullableSortByDirection>;
  status?: InputMaybe<SortByDirection>;
  unrealizedPnl?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export enum HedgeStrategyStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type HedgeStrategyStatusFilter = {
  equals?: InputMaybe<HedgeStrategyStatus>;
  in?: InputMaybe<Array<HedgeStrategyStatus>>;
  not?: InputMaybe<HedgeStrategyStatus>;
  notIn?: InputMaybe<Array<HedgeStrategyStatus>>;
};

export type HedgeStrategyUpdateDTO = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type HistoricalPnl = {
  __typename?: 'HistoricalPnl';
  commissionAndFeeTotal: Scalars['Float'];
  commissionTotal?: Maybe<Scalars['Float']>;
  /** The date for which the calculated P/L values were current. This value is _not_ inclusive, meaning that the values are effectively as of midnight on the given date. */
  date: Scalars['String'];
  displayExpirationDate: Scalars['String'];
  feeTotal?: Maybe<Scalars['Float']>;
  grossPnl?: Maybe<Scalars['Float']>;
  /** Equal to grossPnl - previousGrossPnl */
  grossPnlChange?: Maybe<Scalars['Float']>;
  instrumentType: TypeOfInstrument;
  marketValue?: Maybe<Scalars['Float']>;
  /** Equal to marketValue - previousMarketValue */
  marketValueChange?: Maybe<Scalars['Float']>;
  nextDate: Scalars['String'];
  optionPremium?: Maybe<Scalars['Float']>;
  optionType?: Maybe<TypeOfOption>;
  previousCommissionAndFeeTotal: Scalars['Float'];
  previousCommissionTotal?: Maybe<Scalars['Float']>;
  /** The date one month prior to each row's date value */
  previousDate: Scalars['String'];
  previousFeeTotal?: Maybe<Scalars['Float']>;
  /** The gross p/l value from one month prior */
  previousGrossPnl?: Maybe<Scalars['Float']>;
  /** The market value from one month prior */
  previousMarketValue?: Maybe<Scalars['Float']>;
  /** The unrealized p/l value from one month prior */
  previousRealizedPnl?: Maybe<Scalars['Float']>;
  /** The unrealized p/l value from one month prior */
  previousUnrealizedPnl?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  /** Equal to realizedPnl - previousRealizedPnl */
  realizedPnlChange?: Maybe<Scalars['Float']>;
  side: Side;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  /** Equal to unrealizedPnl - previousUnrealizedPnl */
  unrealizedPnlChange?: Maybe<Scalars['Float']>;
};

export type HistoricalPnlAggregateAllDTO = {
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  displayExpirationDate?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossPnlChange?: InputMaybe<Scalars['Boolean']>;
  marketValue?: InputMaybe<Scalars['Boolean']>;
  marketValueChange?: InputMaybe<Scalars['Boolean']>;
  nextDate?: InputMaybe<Scalars['Boolean']>;
  optionPremium?: InputMaybe<Scalars['Boolean']>;
  previousCommissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  previousCommissionTotal?: InputMaybe<Scalars['Boolean']>;
  previousDate?: InputMaybe<Scalars['Boolean']>;
  previousFeeTotal?: InputMaybe<Scalars['Boolean']>;
  previousGrossPnl?: InputMaybe<Scalars['Boolean']>;
  previousMarketValue?: InputMaybe<Scalars['Boolean']>;
  previousRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  previousUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnlChange?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnlChange?: InputMaybe<Scalars['Boolean']>;
};

export type HistoricalPnlAggregateDTO = {
  avg?: InputMaybe<HistoricalPnlAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<HistoricalPnlAggregateAllDTO>;
  min?: InputMaybe<HistoricalPnlAggregateAllDTO>;
  sum?: InputMaybe<HistoricalPnlAggregateNumericDTO>;
};

export type HistoricalPnlAggregateNumericDTO = {
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossPnlChange?: InputMaybe<Scalars['Boolean']>;
  marketValue?: InputMaybe<Scalars['Boolean']>;
  marketValueChange?: InputMaybe<Scalars['Boolean']>;
  optionPremium?: InputMaybe<Scalars['Boolean']>;
  previousCommissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  previousCommissionTotal?: InputMaybe<Scalars['Boolean']>;
  previousFeeTotal?: InputMaybe<Scalars['Boolean']>;
  previousGrossPnl?: InputMaybe<Scalars['Boolean']>;
  previousMarketValue?: InputMaybe<Scalars['Boolean']>;
  previousRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  previousUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnlChange?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnlChange?: InputMaybe<Scalars['Boolean']>;
};

export type HistoricalPnlDistinctOnDTO = {
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  displayExpirationDate?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossPnlChange?: InputMaybe<Scalars['Boolean']>;
  instrumentType?: InputMaybe<Scalars['Boolean']>;
  marketValue?: InputMaybe<Scalars['Boolean']>;
  marketValueChange?: InputMaybe<Scalars['Boolean']>;
  nextDate?: InputMaybe<Scalars['Boolean']>;
  optionPremium?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  previousCommissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  previousCommissionTotal?: InputMaybe<Scalars['Boolean']>;
  previousDate?: InputMaybe<Scalars['Boolean']>;
  previousFeeTotal?: InputMaybe<Scalars['Boolean']>;
  previousGrossPnl?: InputMaybe<Scalars['Boolean']>;
  previousMarketValue?: InputMaybe<Scalars['Boolean']>;
  previousRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  previousUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnlChange?: InputMaybe<Scalars['Boolean']>;
  side?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnlChange?: InputMaybe<Scalars['Boolean']>;
};

export type HistoricalPnlFilterDTO = {
  AND?: InputMaybe<Array<HistoricalPnlFilterDTO>>;
  NOT?: InputMaybe<HistoricalPnlFilterDTO>;
  OR?: InputMaybe<Array<HistoricalPnlFilterDTO>>;
  commissionAndFeeTotal?: InputMaybe<NumberFilter>;
  commissionTotal?: InputMaybe<NullableNumberFilter>;
  date?: InputMaybe<StringFilter>;
  displayExpirationDate?: InputMaybe<StringFilter>;
  /** The final date for which values will be returned. This value is rounded up to the next 1st of a month. If the given value is the 1st of a month, it will be increased to one month in the future. If this value exceedes the current date, the current date will be used instead. This value is similar to a function argument in that it determines the values that will be computed. */
  endDate: Scalars['String'];
  feeTotal?: InputMaybe<NullableNumberFilter>;
  grossPnl?: InputMaybe<NullableNumberFilter>;
  grossPnlChange?: InputMaybe<NullableNumberFilter>;
  instrumentType?: InputMaybe<TypeOfInstrumentFilter>;
  marketValue?: InputMaybe<NullableNumberFilter>;
  marketValueChange?: InputMaybe<NullableNumberFilter>;
  nextDate?: InputMaybe<StringFilter>;
  optionPremium?: InputMaybe<NullableNumberFilter>;
  optionType?: InputMaybe<NullableTypeOfOptionFilter>;
  previousCommissionAndFeeTotal?: InputMaybe<NumberFilter>;
  previousCommissionTotal?: InputMaybe<NullableNumberFilter>;
  previousDate?: InputMaybe<StringFilter>;
  previousFeeTotal?: InputMaybe<NullableNumberFilter>;
  previousGrossPnl?: InputMaybe<NullableNumberFilter>;
  previousMarketValue?: InputMaybe<NullableNumberFilter>;
  previousRealizedPnl?: InputMaybe<NullableNumberFilter>;
  previousUnrealizedPnl?: InputMaybe<NullableNumberFilter>;
  realizedPnl?: InputMaybe<NullableNumberFilter>;
  realizedPnlChange?: InputMaybe<NullableNumberFilter>;
  side?: InputMaybe<SideFilter>;
  /** The first date for which values will be returned. This value is rounded down to the previous 1st of a month. This value is similar to a function argument in that it determines the values that will be computed. */
  startDate: Scalars['String'];
  transactions?: InputMaybe<TransactionFilterDTO>;
  unrealizedPnl?: InputMaybe<NullableNumberFilter>;
  unrealizedPnlChange?: InputMaybe<NullableNumberFilter>;
};

export type HistoricalPnlGroupByDTO = {
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  displayExpirationDate?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  grossPnl?: InputMaybe<Scalars['Boolean']>;
  grossPnlChange?: InputMaybe<Scalars['Boolean']>;
  instrumentType?: InputMaybe<Scalars['Boolean']>;
  marketValue?: InputMaybe<Scalars['Boolean']>;
  marketValueChange?: InputMaybe<Scalars['Boolean']>;
  nextDate?: InputMaybe<Scalars['Boolean']>;
  optionPremium?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  previousCommissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  previousCommissionTotal?: InputMaybe<Scalars['Boolean']>;
  previousDate?: InputMaybe<Scalars['Boolean']>;
  previousFeeTotal?: InputMaybe<Scalars['Boolean']>;
  previousGrossPnl?: InputMaybe<Scalars['Boolean']>;
  previousMarketValue?: InputMaybe<Scalars['Boolean']>;
  previousRealizedPnl?: InputMaybe<Scalars['Boolean']>;
  previousUnrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnl?: InputMaybe<Scalars['Boolean']>;
  realizedPnlChange?: InputMaybe<Scalars['Boolean']>;
  side?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnl?: InputMaybe<Scalars['Boolean']>;
  unrealizedPnlChange?: InputMaybe<Scalars['Boolean']>;
};

export type HistoricalPnlSortByDTO = {
  commissionAndFeeTotal?: InputMaybe<SortByDirection>;
  commissionTotal?: InputMaybe<NullableSortByDirection>;
  date?: InputMaybe<SortByDirection>;
  displayExpirationDate?: InputMaybe<SortByDirection>;
  feeTotal?: InputMaybe<NullableSortByDirection>;
  grossPnl?: InputMaybe<NullableSortByDirection>;
  grossPnlChange?: InputMaybe<NullableSortByDirection>;
  instrumentType?: InputMaybe<SortByDirection>;
  marketValue?: InputMaybe<NullableSortByDirection>;
  marketValueChange?: InputMaybe<NullableSortByDirection>;
  nextDate?: InputMaybe<SortByDirection>;
  optionPremium?: InputMaybe<NullableSortByDirection>;
  optionType?: InputMaybe<NullableSortByDirection>;
  previousCommissionAndFeeTotal?: InputMaybe<SortByDirection>;
  previousCommissionTotal?: InputMaybe<NullableSortByDirection>;
  previousDate?: InputMaybe<SortByDirection>;
  previousFeeTotal?: InputMaybe<NullableSortByDirection>;
  previousGrossPnl?: InputMaybe<NullableSortByDirection>;
  previousMarketValue?: InputMaybe<NullableSortByDirection>;
  previousRealizedPnl?: InputMaybe<NullableSortByDirection>;
  previousUnrealizedPnl?: InputMaybe<NullableSortByDirection>;
  realizedPnl?: InputMaybe<NullableSortByDirection>;
  realizedPnlChange?: InputMaybe<NullableSortByDirection>;
  side?: InputMaybe<SortByDirection>;
  unrealizedPnl?: InputMaybe<NullableSortByDirection>;
  unrealizedPnlChange?: InputMaybe<NullableSortByDirection>;
};

export type HistoricalProductSettlementRangeFilterDTO = {
  AND?: InputMaybe<Array<HistoricalProductSettlementRangeFilterDTO>>;
  NOT?: InputMaybe<HistoricalProductSettlementRangeFilterDTO>;
  OR?: InputMaybe<Array<HistoricalProductSettlementRangeFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  historicalHigh?: InputMaybe<NumberFilter>;
  historicalLow?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  mostRecentSettlementDate?: InputMaybe<StringFilter>;
  potentialPriceLevels?: InputMaybe<NumberArrayFilter>;
  productId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type HypotheticalAllocationPnlAggregate = {
  __typename?: 'HypotheticalAllocationPnlAggregate';
  effectiveHedgeDate: Scalars['String'];
  entityId: Scalars['String'];
  hedgeType: TypeOfAllocatedHedge;
  id: Scalars['ID'];
  netPnl: Scalars['Float'];
  price: Scalars['Float'];
  productId: Scalars['String'];
  weightedAverageNetHedgePrice: Scalars['Float'];
};

export type HypotheticalAllocationPnlAggregateDistinctOnDTO = {
  effectiveHedgeDate?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  hedgeType?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  netPnl?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  weightedAverageNetHedgePrice?: InputMaybe<Scalars['Boolean']>;
};

export type HypotheticalAllocationPnlAggregateFilterDTO = {
  AND?: InputMaybe<Array<HypotheticalAllocationPnlAggregateFilterDTO>>;
  NOT?: InputMaybe<HypotheticalAllocationPnlAggregateFilterDTO>;
  OR?: InputMaybe<Array<HypotheticalAllocationPnlAggregateFilterDTO>>;
  effectiveHedgeDate?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  hedgeType?: InputMaybe<TypeOfAllocatedHedgeFilter>;
  id?: InputMaybe<StringFilter>;
  netPnl?: InputMaybe<NumberFilter>;
  price?: InputMaybe<NumberFilter>;
  productId?: InputMaybe<StringFilter>;
  productIdInput: Scalars['String'];
  weightedAverageNetHedgePrice?: InputMaybe<NumberFilter>;
};

export type HypotheticalAllocationPnlAggregateSortByDTO = {
  effectiveHedgeDate?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  hedgeType?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  netPnl?: InputMaybe<SortByDirection>;
  price?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  weightedAverageNetHedgePrice?: InputMaybe<SortByDirection>;
};

export type HypotheticalHedgeStrategyPnlAggregate = {
  __typename?: 'HypotheticalHedgeStrategyPnlAggregate';
  id: Scalars['ID'];
  netPnl: Scalars['Float'];
  price: Scalars['Float'];
  weightedAverageNetHedgePrice: Scalars['Float'];
};

export type HypotheticalHedgeStrategyPnlAggregateDistinctOnDTO = {
  id?: InputMaybe<Scalars['Boolean']>;
  netPnl?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  weightedAverageNetHedgePrice?: InputMaybe<Scalars['Boolean']>;
};

export type HypotheticalHedgeStrategyPnlAggregateFilterDTO = {
  AND?: InputMaybe<Array<HypotheticalHedgeStrategyPnlAggregateFilterDTO>>;
  NOT?: InputMaybe<HypotheticalHedgeStrategyPnlAggregateFilterDTO>;
  OR?: InputMaybe<Array<HypotheticalHedgeStrategyPnlAggregateFilterDTO>>;
  hedgeStrategyId: Scalars['String'];
  id?: InputMaybe<StringFilter>;
  netPnl?: InputMaybe<NumberFilter>;
  price?: InputMaybe<NumberFilter>;
  weightedAverageNetHedgePrice?: InputMaybe<NumberFilter>;
};

export type HypotheticalHedgeStrategyPnlAggregateSortByDTO = {
  id?: InputMaybe<SortByDirection>;
  netPnl?: InputMaybe<SortByDirection>;
  price?: InputMaybe<SortByDirection>;
  weightedAverageNetHedgePrice?: InputMaybe<SortByDirection>;
};

export type IAccount = {
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  CurrentPositions: Array<CurrentPosition>;
  Customer?: Maybe<CustomerEntity>;
  Notes: Array<AccountNote>;
  Orders: Array<Order>;
  /** @deprecated Use "Owner" instead */
  Organization?: Maybe<OrganizationEntity>;
  Owner?: Maybe<IEntity>;
  Transactions: Array<Transaction>;
  accountNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  inputSource: InputSource;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isClosed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  type: TypeOfAccount;
  updatedAt: Scalars['DateTime'];
};


export type IAccount_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type IAccount_CurrentPositionsArgs = {
  orderBy?: InputMaybe<CurrentPositionSortByDTO>;
  where?: InputMaybe<CurrentPositionFilterDTO>;
};


export type IAccount_NotesArgs = {
  orderBy?: InputMaybe<AccountNoteSortByDTO>;
  where?: InputMaybe<AccountNoteFilterDTO>;
};


export type IAccount_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type IAccount_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type IEntity = {
  ActiveProducts: Array<Product>;
  AgreedToOtcContracts: Array<OtcContract>;
  BrokerageAccounts: Array<BrokerageAccount>;
  CreatedByUser?: Maybe<User>;
  CurrentUserPermissions: UserPermissionsForTarget;
  EntityUsers: Array<EntityUser>;
  ExposureRatios: Array<EntityExposureRatio>;
  FeedIngredientConsumedAndPurchasedVolumes: Array<FeedIngredientConsumedAndPurchasedVolume>;
  ForwardContracts: Array<ForwardContractAccount>;
  GroupEntries: Array<EntityGroupEntry>;
  Groups: Array<EntityGroup>;
  InitiatedOtcContracts: Array<OtcContract>;
  LastUpdatedByUser?: Maybe<User>;
  Members: Array<EntityMember>;
  Notes: Array<EntityNote>;
  OwnedAccounts: Array<IAccount>;
  OwnedOtcContracts: Array<OtcContract>;
  PhysicalSales: Array<PhysicalSale>;
  createdAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  identificationNumber: Scalars['Int'];
  isVgs: Scalars['Boolean'];
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type: TypeOfEntity;
  updatedAt: Scalars['DateTime'];
};


export type IEntity_AgreedToOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type IEntity_BrokerageAccountsArgs = {
  orderBy?: InputMaybe<BrokerageAccountSortByDTO>;
  where?: InputMaybe<BrokerageAccountFilterDTO>;
};


export type IEntity_EntityUsersArgs = {
  orderBy?: InputMaybe<EntityUserSortByDTO>;
  where?: InputMaybe<EntityUserFilterDTO>;
};


export type IEntity_ExposureRatiosArgs = {
  orderBy?: InputMaybe<EntityExposureRatioSortByDTO>;
  where?: InputMaybe<EntityExposureRatioFilterDTO>;
};


export type IEntity_FeedIngredientConsumedAndPurchasedVolumesArgs = {
  orderBy?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeSortByDTO>;
  where?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
};


export type IEntity_ForwardContractsArgs = {
  orderBy?: InputMaybe<ForwardContractAccountSortByDTO>;
  where?: InputMaybe<ForwardContractAccountFilterDTO>;
};


export type IEntity_GroupEntriesArgs = {
  orderBy?: InputMaybe<EntityGroupEntrySortByDTO>;
  where?: InputMaybe<EntityGroupEntryFilterDTO>;
};


export type IEntity_InitiatedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type IEntity_MembersArgs = {
  orderBy?: InputMaybe<EntityMemberSortByDTO>;
  where?: InputMaybe<EntityMemberFilterDTO>;
};


export type IEntity_NotesArgs = {
  orderBy?: InputMaybe<EntityNoteSortByDTO>;
  where?: InputMaybe<EntityNoteFilterDTO>;
};


export type IEntity_OwnedAccountsArgs = {
  orderBy?: InputMaybe<AccountSortByDTO>;
  where?: InputMaybe<AccountFilterDTO>;
};


export type IEntity_OwnedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type IEntity_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};

export type IFeedIngredientUsage = {
  Entity: IEntity;
  FeedIngredient: FeedIngredient;
  /** An integer representing the absolute quarter in which the "date" falls (i.e. year * 4 + quarter) */
  absoluteQuarter: Scalars['Int'];
  /** @deprecated This field has been removed */
  basisPrice?: Maybe<Scalars['Float']>;
  /** @deprecated This field has been removed */
  cashPrice?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  /** @deprecated This field has been removed */
  description?: Maybe<Scalars['String']>;
  dmiUsageInLb: Scalars['Float'];
  dmiUsageInTons: Scalars['Float'];
  entityId: Scalars['String'];
  feedIngredientId: Scalars['String'];
  /** A UUID that will be consistent across multiple feed ingredients that have been copied from each other upon the creation of a new version. */
  feedIngredientSeriesId: Scalars['String'];
  feedUsageInLb: Scalars['Float'];
  feedUsageInTons: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** @deprecated Use "dmiUsageInTons" instead */
  quantity: Scalars['Float'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  /** A readable representation of the absolute quarter, in the 'QQ YYYY' format Example: 2020-01-01 becomes 'Q1 2020' */
  readableQuarter: Scalars['Int'];
  type: TypeOfFeedIngredientUsage;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type IForecastedLivestockFeedUsage = {
  Business: CustomerEntity;
  Ingredient: FeedIngredient;
  LivestockGroupFeedUsage: LivestockGroupFeedUsage;
  /** Generated field - This value will have a starting index of 1; e.g. the age in weeks in the week of the dob will be 1 */
  ageInWeeks: Scalars['Int'];
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  dob: Scalars['String'];
  /** Generated field - Will be used to aggregate forecasted feed usage by month. YYYY-MM-DD */
  firstDateOfMonth: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  hedgePriceInUsd: Scalars['Float'];
  id: Scalars['ID'];
  ingredientId: Scalars['String'];
  livestockGroupFeedUsageId: Scalars['String'];
  perLbExpenseInUsd: Scalars['Float'];
  quantityInLbs: Scalars['Float'];
  /** Generated field - (quantityInLbs / 2000) */
  quantityInTons: Scalars['Float'];
  /** Generated field - Contains the week from {date} in a "W[N] [YYYY]" format */
  readableWeek: Scalars['String'];
  /** The total expense of this forecasted usage, with DMI taken into consideration */
  totalExpenseInUsd: Scalars['Float'];
  type: TypeOfForecastedLivestockFeedUsage;
  updatedAt: Scalars['DateTime'];
};

export type IGrainOrder = {
  Activity: Array<GrainOrderActivity>;
  Buyer?: Maybe<Buyer>;
  DeliveryLocation?: Maybe<GrainLocation>;
  Location?: Maybe<GrainLocation>;
  Notes: Array<GrainOrderNote>;
  Plan?: Maybe<IGrainPlan>;
  Seller?: Maybe<Seller>;
  StatusUpdates: Array<GrainOrderStatusUpdate>;
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  buyerId?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** Only intended to be read for orders created against Crop plans. */
  cropFlatPrice?: Maybe<Scalars['Float']>;
  deliveryEndDate?: Maybe<Scalars['String']>;
  deliveryId?: Maybe<Scalars['String']>;
  deliveryMonth?: Maybe<Scalars['String']>;
  deliveryStartDate?: Maybe<Scalars['String']>;
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  /** Only intended to be set or read for orders created against Feed plans. */
  feedFlatPrice?: Maybe<Scalars['Float']>;
  /** Only intended to be read for orders created against Feed plans. */
  feedPrice?: Maybe<Scalars['Float']>;
  fees: Scalars['Float'];
  /**
   * Only intended to be read for orders created against Crop plans.
   * @deprecated Use "cropFlatPrice" instead
   */
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee: Scalars['Float'];
  futurePrice?: Maybe<Scalars['Float']>;
  futuresMonth?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  htaFee: Scalars['Float'];
  id: Scalars['ID'];
  /** @deprecated Use deliveryTerms instead. */
  isFob: Scalars['Boolean'];
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  planId: Scalars['String'];
  rollFee: Scalars['Float'];
  salesType: TypeOfGrainOrderSales;
  sellerId?: Maybe<Scalars['String']>;
  shortNote?: Maybe<Scalars['String']>;
  spread?: Maybe<Scalars['Float']>;
  type: TypeOfGrainOrder;
  updatedAt: Scalars['DateTime'];
  vgsId?: Maybe<Scalars['Int']>;
};


export type IGrainOrder_ActivityArgs = {
  orderBy?: InputMaybe<GrainOrderActivitySortByDTO>;
  where?: InputMaybe<GrainOrderActivityFilterDTO>;
};


export type IGrainOrder_NotesArgs = {
  orderBy?: InputMaybe<GrainOrderNoteSortByDTO>;
  where?: InputMaybe<GrainOrderNoteFilterDTO>;
};


export type IGrainOrder_StatusUpdatesArgs = {
  orderBy?: InputMaybe<GrainOrderStatusUpdateSortByDTO>;
  where?: InputMaybe<GrainOrderStatusUpdateFilterDTO>;
};

export type IGrainOrderActivity = {
  GrainPlan?: Maybe<IGrainPlan>;
  User?: Maybe<User>;
  data: Scalars['Object'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  operationType: TypeOfPostgresOperation;
  orderId?: Maybe<Scalars['String']>;
  performedAt: Scalars['DateTime'];
  performedByUserId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  type: TypeOfGrainOrderActivity;
};

export type IGrainPlan = {
  Customer?: Maybe<CustomerEntity>;
  Orders: Array<IGrainOrder>;
  Organization: OrganizationEntity;
  breakEven?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  organizationId: Scalars['String'];
  type: TypeOfGrainPlan;
  updatedAt: Scalars['DateTime'];
};


export type IGrainPlan_OrdersArgs = {
  orderBy?: InputMaybe<GrainOrderSortByDTO>;
  where?: InputMaybe<GrainOrderFilterDTO>;
};

export type IInstrument = {
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  isStandardContractSize: Scalars['Boolean'];
  name: Scalars['String'];
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  type: TypeOfInstrument;
  updatedAt: Scalars['DateTime'];
};


export type IInstrument_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type IInstrument_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type IInstrument_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type IInstrument_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type IInsuranceEndorsement = {
  AllocationRatios: Array<InsuranceEndorsementAllocationRatio>;
  HedgeStrategy?: Maybe<HedgeStrategy>;
  InsuranceAgent?: Maybe<InsuranceAgent>;
  InsurancePolicy: InsurancePolicy;
  InsurancePractice?: Maybe<InsurancePractice>;
  RevenueHedgeProducts: Array<Product>;
  RmaType?: Maybe<RmaType>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** The ending date (inclusive) of the endorsement's coverage period */
  endDate: Scalars['String'];
  /** @deprecated Use "forecastedPnl" instead */
  estimatedPl?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** This will contain either the actual indemnity value if provided or the forecasted indemnity value otherwise. */
  indemnity?: Maybe<Scalars['Float']>;
  /** @deprecated Use "forecastedIndemnity" instead */
  indemnityAmount?: Maybe<Scalars['Float']>;
  inputSource: InputSource;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong: Scalars['Boolean'];
  isShort: Scalars['Boolean'];
  /** This will contain either the actual pnl value if provided or the forecasted pnl value otherwise. */
  pnl?: Maybe<Scalars['Float']>;
  policyId: Scalars['String'];
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount: Scalars['Float'];
  reinsuranceYear: Scalars['Int'];
  revenueHedgeProductIds: Array<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  salesEffectiveDate: Scalars['String'];
  /** The starting date (inclusive) of the endorsement's coverage period */
  startDate: Scalars['String'];
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode: Scalars['String'];
  stateId: Scalars['String'];
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount: Scalars['Float'];
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount: Scalars['Float'];
  type: TypeOfInsuranceEndorsement;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type IInsuranceEndorsement_AllocationRatiosArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementAllocationRatioSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
};

export type IInsuranceHistoricalPremium = {
  InsuranceHistoricalPremiumInput: IInsuranceHistoricalPremiumInput;
  InsurancePractice: InsurancePractice;
  State: State;
  coverageLevel: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  grossPremium: Scalars['Float'];
  id: Scalars['ID'];
  insurancePracticeDisplayDate: Scalars['String'];
  insurancePracticeId: Scalars['String'];
  netPremium: Scalars['Float'];
  stateId: Scalars['String'];
  subsidy: Scalars['Float'];
  type: TypeOfInsuranceHistoricalPremium;
  updatedAt: Scalars['DateTime'];
};

export type IInsuranceHistoricalPremiumInput = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: TypeOfInsuranceHistoricalPremiumInput;
  updatedAt: Scalars['DateTime'];
};

export type ILedgerCategory = {
  Customer: CustomerEntity;
  LedgerEntries: Array<ILedgerEntry>;
  /** The type of value against which the ledger entry values will be computed. If left null, all entries will be assumed to be "static" values. */
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** A flag denoting whether or not all ledger entries for this category are static or dynamic */
  isStatic: Scalars['Boolean'];
  name: Scalars['String'];
  type: TypeOfLedgerCategory;
  updatedAt: Scalars['DateTime'];
};


export type ILedgerCategory_LedgerEntriesArgs = {
  orderBy?: InputMaybe<LedgerEntrySortByDTO>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};

export type ILedgerEntry = {
  Entity: IEntity;
  LedgerCategory: ILedgerCategory;
  /** The input ledger entry value. For static entries, this is the value that will be returned for 'calculatedAmount'. For dynamic entries, this value will be multipled by 'calculationQuantity' to determine 'calculatedAmount'. */
  amount: Scalars['Float'];
  /** For static entries, this value will be identical to the 'amount' value. For dynamic entries, this value will be the product of 'amount' & 'calculationQuantity'. */
  calculatedAmount: Scalars['Float'];
  /** The total quantity from all head/cwt/volume belonging to the same entity or child entities. This value is multiplied against 'amount' for dynamic ledger entries. If this value is NULL, the ledger entry is static and the given 'amount' will be returned from 'calculatedAmount'. */
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  type: TypeOfLedgerEntry;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type ILivestockGroup = {
  Business: CustomerEntity;
  FeedUsages: Array<ILivestockGroupFeedUsage>;
  Version: LivestockGroupVersion;
  /** The ID of the business to which this livestock group belongs. */
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The proportion of individuals which are expected to die over the age span this group represents. Ex. a value of 0.03 would mean that 3% of individuals are expected to die between the time they are minAgeInWeeks old and maxAgeInWeeks old. */
  deathRate: Scalars['Float'];
  /** The optional description of the livestock group. */
  description?: Maybe<Scalars['String']>;
  /** The number of weeks contained by this group, inclusive of the min & max. e.g. A max of 25 and a min of 21 would have a duration of 5 */
  durationInWeeks: Scalars['Int'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The maximum age in weeks for membership in this group. Ex. a value of 20 would mean an animal 21 weeks old or older would not be a member of this group, but an animal 20 weeks old would be. Must be greater than minAgeInWeeks. */
  maxAgeInWeeks: Scalars['Int'];
  /** The minimum age in weeks for membership in this group. Ex. a value a 4 would mean an animal 3 weeks old or younger would not be a member of this group, but an animal 4 weeks old would be. Must be less than maxAgeInWeeks. */
  minAgeInWeeks: Scalars['Int'];
  /** The name of the livestock group. */
  name: Scalars['String'];
  type: TypeOfLivestockGroup;
  updatedAt: Scalars['DateTime'];
  /** The version in which this LivestockGroup is a part of. The version will contain information regarding whether or not this swine group is "current" as well as the startDate & endDate in which it is in effect for data forcasting */
  versionId: Scalars['String'];
  /** A UUID that will be shared across all feed ingredients that have been copied from each other upon the creation of a new version. */
  versionedConceptSeriesId: Scalars['String'];
};


export type ILivestockGroup_FeedUsagesArgs = {
  orderBy?: InputMaybe<LivestockGroupFeedUsageSortByDTO>;
  where?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
};

export type ILivestockGroupFeedUsage = {
  Business: CustomerEntity;
  FeedIngredient: FeedIngredient;
  LivestockGroup: ILivestockGroup;
  Version: LivestockGroupFeedUsageVersion;
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  feedIngredientId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  livestockGroupId: Scalars['String'];
  type: TypeOfLivestockGroupFeedUsage;
  updatedAt: Scalars['DateTime'];
  usageInLbsPerHead: Scalars['Float'];
  /** computed as (usageInLbsPerHead/2000) */
  usageInTonsPerHead: Scalars['Float'];
  /** The version of feedUsage that this LivestockGroupFeedUsage uses. The version will contain information regarding whether or not this usage is "current" as well as the startDate & endDate in which it is in effect for data forcasting */
  versionId: Scalars['String'];
  /** A UUID that will be shared across all feed ingredients that have been copied from each other upon the creation of a new version. */
  versionedConceptSeriesId: Scalars['String'];
};

export type ILivestockPopulationChange = {
  Business: CustomerEntity;
  businessId: Scalars['String'];
  /** The livestock group contract period allocation unto which this population change has been added. This value will only be set for forecasted sales. */
  contractPeriodAllocationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** The date of the population change event. For ACTUAL rows, this date must be a Sunday. This is enforced with a constraint. */
  date: Scalars['String'];
  /** The date of birth for the cohort this row describes. This date must be a Sunday. This is enforced with a constraint. */
  dob: Scalars['String'];
  /** Generated field, The absolute INT of the week within the year in which these livestock were born */
  dobWeek: Scalars['Int'];
  exactQuantity: Scalars['Float'];
  /** Generated field, (exactQuantity * valuePerHead) */
  exactTotalValue: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Generated field, The first sunday of the week calculated from the value stored in 'date' */
  marketingWeekStartDate: Scalars['String'];
  /** The rounded quantity of population that has changed */
  quantity: Scalars['Int'];
  /** Generated field, A readable version of 'dobWeek' formatted "W[N] YYYY" */
  readableDobWeek: Scalars['String'];
  /** Generated field, A readable version of 'week' formatted "W[N] YYYY" */
  readableWeek: Scalars['String'];
  /** A custom description detailing the reason for this change, if applicable */
  reason?: Maybe<Scalars['String']>;
  reasonType: TypeOfLivestockPopulationChangeReason;
  startOfMonth: Scalars['String'];
  /** Intended to be populated when there is an issue related to the population change. */
  systemComment?: Maybe<Scalars['String']>;
  /** Generated field, (quantity * valuePerHead) */
  totalValue: Scalars['Float'];
  type: TypeOfLivestockPopulationChange;
  updatedAt: Scalars['DateTime'];
  valuePerHead: Scalars['Float'];
  valueType: TypeOfLivestockPopulationChangeValue;
  /** Generated field, The absolute INT of the week within the year in which this population change event occurred */
  week: Scalars['Int'];
};

export type INotificationSetting = {
  Notification: Array<Notification>;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: TypeOfNotification;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type INotificationSetting_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type INotificationSubscription = {
  Notification: Array<Notification>;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  deviceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: TypeOfSubscription;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type INotificationSubscription_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type IUsdaReport = {
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  externalSlug: Scalars['String'];
  id: Scalars['ID'];
  lastPublishedAt: Scalars['DateTime'];
  marketTypes: Array<Scalars['String']>;
  markets: Array<Scalars['String']>;
  name: Scalars['String'];
  offices: Array<Scalars['String']>;
  type: TypeOfUsdaReport;
  updatedAt: Scalars['DateTime'];
};

export type IVersion = {
  Business: CustomerEntity;
  CreatedByUser?: Maybe<User>;
  LastUpdatedByUser?: Maybe<User>;
  /** The business entity (customer entity) on behalf of which this version exists */
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The user that triggered the creation of this version. If this value is null, then this version was created via the system. */
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The final date for which this version is in effect, inclusive. If this value is null, this version is in effect for all dates greater than startDate */
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  /** The user that last updated the concepts linked to this version. When a version is first created, the lastUpdatedByUserId value will equal the user id that triggered the version creation. If this value is null, then this version has only been created & updated via the system. */
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nextVersionId?: Maybe<Scalars['String']>;
  /** The version that preceeds this version. If this value is null, this is the first version for this businessId / type combination. */
  previousVersionId?: Maybe<Scalars['String']>;
  /** The first date for which this version is in effect (inclusive) */
  startDate: Scalars['String'];
  type: TypeOfVersion;
  updatedAt: Scalars['DateTime'];
};

export enum InputSource {
  Adm = 'Adm',
  Barchart = 'Barchart',
  Cme = 'Cme',
  Cqg = 'Cqg',
  Marex = 'Marex',
  StoneX = 'StoneX',
  Straits = 'Straits',
  System = 'System',
  Udm = 'Udm',
  User = 'User',
  Vendor = 'Vendor'
}

export type InputSourceFilter = {
  equals?: InputMaybe<InputSource>;
  in?: InputMaybe<Array<InputSource>>;
  not?: InputMaybe<InputSource>;
  notIn?: InputMaybe<Array<InputSource>>;
};

export type Instrument = IInstrument & {
  __typename?: 'Instrument';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  isStandardContractSize: Scalars['Boolean'];
  name: Scalars['String'];
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  type: TypeOfInstrument;
  updatedAt: Scalars['DateTime'];
};


export type Instrument_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type Instrument_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type Instrument_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type Instrument_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type InstrumentDistinctOnDTO = {
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndAt?: InputMaybe<Scalars['Boolean']>;
  deliveryStartAt?: InputMaybe<Scalars['Boolean']>;
  displayDeliveryDate?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  firstNoticeDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  priceInstrumentId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  settlementInstrumentId?: InputMaybe<Scalars['Boolean']>;
  spreadType?: InputMaybe<Scalars['Boolean']>;
  strike?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  underlyingFutureId?: InputMaybe<Scalars['Boolean']>;
  underlyingInstrumentId?: InputMaybe<Scalars['Boolean']>;
  unitsPerContract?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  velaInstrumentId?: InputMaybe<Scalars['Boolean']>;
};

export type InstrumentFilterDTO = {
  AND?: InputMaybe<Array<InstrumentFilterDTO>>;
  AsBasisInstrument?: InputMaybe<BasisInstrumentFilterDTO>;
  AsCashInstrument?: InputMaybe<CashInstrumentFilterDTO>;
  AsCommitmentInstrument?: InputMaybe<CommitmentInstrumentFilterDTO>;
  AsFutureInstrument?: InputMaybe<FutureFilterDTO>;
  AsOptionInstrument?: InputMaybe<OptionFilterDTO>;
  AsPhysicalInstrument?: InputMaybe<PhysicalInstrumentFilterDTO>;
  AsSpreadInstrument?: InputMaybe<SpreadInstrumentFilterDTO>;
  AsSwapInstrument?: InputMaybe<SwapFilterDTO>;
  AsSwaptionInstrument?: InputMaybe<SwaptionFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  DerivedSpreadLegs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  DerivedSwapInstruments?: InputMaybe<SwapFilterDTO>;
  DerivedSwaptionInstruments?: InputMaybe<SwaptionFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  LegacyFeeds?: InputMaybe<LegacyFeedFilterDTO>;
  LegacyScenarioData?: InputMaybe<LegacyScenarioDataFilterDTO>;
  NOT?: InputMaybe<InstrumentFilterDTO>;
  OR?: InputMaybe<Array<InstrumentFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationFilterDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  barchartSymbol?: InputMaybe<NullableStringFilter>;
  cqgSymbol?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deliveryEndAt?: InputMaybe<NullableDateTimeFilter>;
  deliveryStartAt?: InputMaybe<NullableDateTimeFilter>;
  displayDeliveryDate?: InputMaybe<NullableDateTimeFilter>;
  displayExpiresAt?: InputMaybe<NullableStringFilter>;
  exchangeSymbol?: InputMaybe<NullableStringFilter>;
  expiresAt?: InputMaybe<NullableDateTimeFilter>;
  firstNoticeDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentSymbolGroupId?: InputMaybe<StringFilter>;
  isStandardContractSize?: InputMaybe<BooleanFilter>;
  lastTradeAt?: InputMaybe<NullableDateTimeFilter>;
  locationId?: InputMaybe<NullableStringFilter>;
  name?: InputMaybe<StringFilter>;
  optionType?: InputMaybe<NullableTypeOfOptionFilter>;
  priceInstrumentId?: InputMaybe<NullableStringFilter>;
  productId?: InputMaybe<StringFilter>;
  productLotSpecificationId?: InputMaybe<StringFilter>;
  settlementInstrumentId?: InputMaybe<NullableStringFilter>;
  spreadType?: InputMaybe<NullableTypeOfSpreadFilter>;
  strike?: InputMaybe<NullableNumberFilter>;
  type?: InputMaybe<TypeOfInstrumentFilter>;
  underlyingFutureId?: InputMaybe<NullableStringFilter>;
  underlyingInstrumentId?: InputMaybe<NullableStringFilter>;
  unitsPerContract?: InputMaybe<NullableNumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  velaInstrumentId?: InputMaybe<NullableNumberFilter>;
};

export type InstrumentGroupByDTO = {
  AsBasisInstrument?: InputMaybe<BasisInstrumentGroupByDTO>;
  AsCashInstrument?: InputMaybe<CashInstrumentGroupByDTO>;
  AsCommitmentInstrument?: InputMaybe<CommitmentInstrumentGroupByDTO>;
  AsFutureInstrument?: InputMaybe<FutureGroupByDTO>;
  AsOptionInstrument?: InputMaybe<OptionGroupByDTO>;
  AsPhysicalInstrument?: InputMaybe<PhysicalInstrumentGroupByDTO>;
  AsSpreadInstrument?: InputMaybe<SpreadInstrumentGroupByDTO>;
  AsSwapInstrument?: InputMaybe<SwapGroupByDTO>;
  AsSwaptionInstrument?: InputMaybe<SwaptionGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationGroupByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndAt?: InputMaybe<Scalars['Boolean']>;
  deliveryStartAt?: InputMaybe<Scalars['Boolean']>;
  displayDeliveryDate?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  firstNoticeDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  priceInstrumentId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  settlementInstrumentId?: InputMaybe<Scalars['Boolean']>;
  spreadType?: InputMaybe<Scalars['Boolean']>;
  strike?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  underlyingFutureId?: InputMaybe<Scalars['Boolean']>;
  underlyingInstrumentId?: InputMaybe<Scalars['Boolean']>;
  unitsPerContract?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  velaInstrumentId?: InputMaybe<Scalars['Boolean']>;
};

export type InstrumentSortByDTO = {
  AsBasisInstrument?: InputMaybe<BasisInstrumentSortByDTO>;
  AsCashInstrument?: InputMaybe<CashInstrumentSortByDTO>;
  AsCommitmentInstrument?: InputMaybe<CommitmentInstrumentSortByDTO>;
  AsFutureInstrument?: InputMaybe<FutureSortByDTO>;
  AsOptionInstrument?: InputMaybe<OptionSortByDTO>;
  AsPhysicalInstrument?: InputMaybe<PhysicalInstrumentSortByDTO>;
  AsSpreadInstrument?: InputMaybe<SpreadInstrumentSortByDTO>;
  AsSwapInstrument?: InputMaybe<SwapSortByDTO>;
  AsSwaptionInstrument?: InputMaybe<SwaptionSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationSortByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  barchartSymbol?: InputMaybe<NullableSortByDirection>;
  cqgSymbol?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deliveryEndAt?: InputMaybe<NullableSortByDirection>;
  deliveryStartAt?: InputMaybe<NullableSortByDirection>;
  displayDeliveryDate?: InputMaybe<NullableSortByDirection>;
  displayExpiresAt?: InputMaybe<NullableSortByDirection>;
  exchangeSymbol?: InputMaybe<NullableSortByDirection>;
  expiresAt?: InputMaybe<NullableSortByDirection>;
  firstNoticeDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentSymbolGroupId?: InputMaybe<SortByDirection>;
  isStandardContractSize?: InputMaybe<SortByDirection>;
  lastTradeAt?: InputMaybe<NullableSortByDirection>;
  locationId?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<SortByDirection>;
  optionType?: InputMaybe<NullableSortByDirection>;
  priceInstrumentId?: InputMaybe<NullableSortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productLotSpecificationId?: InputMaybe<SortByDirection>;
  settlementInstrumentId?: InputMaybe<NullableSortByDirection>;
  spreadType?: InputMaybe<NullableSortByDirection>;
  strike?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  underlyingFutureId?: InputMaybe<NullableSortByDirection>;
  underlyingInstrumentId?: InputMaybe<NullableSortByDirection>;
  unitsPerContract?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  velaInstrumentId?: InputMaybe<NullableSortByDirection>;
};

export type InstrumentSymbolGroup = {
  __typename?: 'InstrumentSymbolGroup';
  Instruments: Array<IInstrument>;
  Product: Product;
  barchartRootSymbol?: Maybe<Scalars['String']>;
  contractType?: Maybe<TypeOfContract>;
  cqgRootSymbol?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayFactor: Scalars['Float'];
  exchangeRootSymbol?: Maybe<Scalars['String']>;
  fractionDigits: Scalars['Int'];
  gmiExchangeCode?: Maybe<Scalars['String']>;
  gmiRootSymbols: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  productId: Scalars['String'];
  type: TypeOfInstrumentSymbolGroup;
  /** The id of the ISG that underlies this ISG. This field is only set for OPTION contract type ISGs. */
  underlyingSymbolGroupId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type InstrumentSymbolGroup_InstrumentsArgs = {
  orderBy?: InputMaybe<InstrumentSortByDTO>;
  where?: InputMaybe<InstrumentFilterDTO>;
};

export type InstrumentSymbolGroupDistinctOnDTO = {
  barchartRootSymbol?: InputMaybe<Scalars['Boolean']>;
  contractType?: InputMaybe<Scalars['Boolean']>;
  cqgRootSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayFactor?: InputMaybe<Scalars['Boolean']>;
  exchangeRootSymbol?: InputMaybe<Scalars['Boolean']>;
  fractionDigits?: InputMaybe<Scalars['Boolean']>;
  gmiExchangeCode?: InputMaybe<Scalars['Boolean']>;
  gmiRootSymbols?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  underlyingSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InstrumentSymbolGroupFilterDTO = {
  AND?: InputMaybe<Array<InstrumentSymbolGroupFilterDTO>>;
  Instruments?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  OR?: InputMaybe<Array<InstrumentSymbolGroupFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  UnderlainSymbolGroups?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  UnderlyingSymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  barchartRootSymbol?: InputMaybe<NullableStringFilter>;
  contractType?: InputMaybe<NullableTypeOfContractFilter>;
  cqgRootSymbol?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayFactor?: InputMaybe<NumberFilter>;
  exchangeRootSymbol?: InputMaybe<NullableStringFilter>;
  fractionDigits?: InputMaybe<NumberFilter>;
  gmiExchangeCode?: InputMaybe<NullableStringFilter>;
  gmiRootSymbols?: InputMaybe<StringArrayFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  type?: InputMaybe<TypeOfInstrumentSymbolGroupFilter>;
  underlyingSymbolGroupId?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstrumentSymbolGroupGroupByDTO = {
  Product?: InputMaybe<ProductGroupByDTO>;
  UnderlyingSymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  barchartRootSymbol?: InputMaybe<Scalars['Boolean']>;
  contractType?: InputMaybe<Scalars['Boolean']>;
  cqgRootSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayFactor?: InputMaybe<Scalars['Boolean']>;
  exchangeRootSymbol?: InputMaybe<Scalars['Boolean']>;
  fractionDigits?: InputMaybe<Scalars['Boolean']>;
  gmiExchangeCode?: InputMaybe<Scalars['Boolean']>;
  gmiRootSymbols?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  underlyingSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InstrumentSymbolGroupSortByDTO = {
  Product?: InputMaybe<ProductSortByDTO>;
  UnderlyingSymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  barchartRootSymbol?: InputMaybe<NullableSortByDirection>;
  contractType?: InputMaybe<NullableSortByDirection>;
  cqgRootSymbol?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  displayFactor?: InputMaybe<SortByDirection>;
  exchangeRootSymbol?: InputMaybe<NullableSortByDirection>;
  fractionDigits?: InputMaybe<SortByDirection>;
  gmiExchangeCode?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  underlyingSymbolGroupId?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InsuranceAgency = {
  __typename?: 'InsuranceAgency';
  Agents: Array<InsuranceAgent>;
  State: State;
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  stateId: Scalars['String'];
  street1Address: Scalars['String'];
  street2Address?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zipCode: Scalars['String'];
  zipExtensionCode?: Maybe<Scalars['String']>;
};


export type InsuranceAgency_AgentsArgs = {
  orderBy?: InputMaybe<InsuranceAgentSortByDTO>;
  where?: InputMaybe<InsuranceAgentFilterDTO>;
};

export type InsuranceAgencyDistinctOnDTO = {
  city?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  street1Address?: InputMaybe<Scalars['Boolean']>;
  street2Address?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  zipCode?: InputMaybe<Scalars['Boolean']>;
  zipExtensionCode?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceAgencyFilterDTO = {
  AND?: InputMaybe<Array<InsuranceAgencyFilterDTO>>;
  InsuranceAgentAgencies?: InputMaybe<InsuranceAgentAgencyFilterDTO>;
  NOT?: InputMaybe<InsuranceAgencyFilterDTO>;
  OR?: InputMaybe<Array<InsuranceAgencyFilterDTO>>;
  State?: InputMaybe<StateFilterDTO>;
  city?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  stateId?: InputMaybe<StringFilter>;
  street1Address?: InputMaybe<StringFilter>;
  street2Address?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zipCode?: InputMaybe<StringFilter>;
  zipExtensionCode?: InputMaybe<NullableStringFilter>;
};

export type InsuranceAgencySortByDTO = {
  State?: InputMaybe<StateSortByDTO>;
  city?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  street1Address?: InputMaybe<SortByDirection>;
  street2Address?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  zipCode?: InputMaybe<SortByDirection>;
  zipExtensionCode?: InputMaybe<NullableSortByDirection>;
};

export type InsuranceAgent = {
  __typename?: 'InsuranceAgent';
  Agencies: Array<InsuranceAgency>;
  /** All insurance policies for which this agent is currently responsible. */
  CurrentInsurancePolicies: Array<InsurancePolicy>;
  /** A linking concept between insurance agents and policies for which they are/have been responsible */
  InsuranceAgentPolicies: Array<InsuranceAgentPolicy>;
  InsuranceEndorsement: Array<InsuranceEndorsement>;
  /** All insurance policies for which this agent has ever been responsible. This agent may no longer be responsible for policies that are returned */
  InsurancePolicies: Array<InsurancePolicy>;
  createdAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type InsuranceAgent_AgenciesArgs = {
  orderBy?: InputMaybe<InsuranceAgencySortByDTO>;
  where?: InputMaybe<InsuranceAgencyFilterDTO>;
};


export type InsuranceAgent_CurrentInsurancePoliciesArgs = {
  orderBy?: InputMaybe<InsurancePolicySortByDTO>;
  where?: InputMaybe<InsurancePolicyFilterDTO>;
};


export type InsuranceAgent_InsuranceAgentPoliciesArgs = {
  orderBy?: InputMaybe<InsuranceAgentPolicySortByDTO>;
  where?: InputMaybe<InsuranceAgentPolicyFilterDTO>;
};


export type InsuranceAgent_InsuranceEndorsementArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementFilterDTO>;
};


export type InsuranceAgent_InsurancePoliciesArgs = {
  orderBy?: InputMaybe<InsurancePolicySortByDTO>;
  where?: InputMaybe<InsurancePolicyFilterDTO>;
};

export type InsuranceAgentAgencyFilterDTO = {
  AND?: InputMaybe<Array<InsuranceAgentAgencyFilterDTO>>;
  Agency?: InputMaybe<InsuranceAgencyFilterDTO>;
  Agent?: InputMaybe<InsuranceAgentFilterDTO>;
  NOT?: InputMaybe<InsuranceAgentAgencyFilterDTO>;
  OR?: InputMaybe<Array<InsuranceAgentAgencyFilterDTO>>;
  agencyId?: InputMaybe<StringFilter>;
  agentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsuranceAgentDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  middleName?: InputMaybe<Scalars['Boolean']>;
  suffix?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceAgentFilterDTO = {
  AND?: InputMaybe<Array<InsuranceAgentFilterDTO>>;
  InsuranceAgentAgencies?: InputMaybe<InsuranceAgentAgencyFilterDTO>;
  InsuranceAgentPolicies?: InputMaybe<InsuranceAgentPolicyFilterDTO>;
  InsuranceEndorsements?: InputMaybe<InsuranceEndorsementFilterDTO>;
  InsurancePolicies?: InputMaybe<InsurancePolicyFilterDTO>;
  NOT?: InputMaybe<InsuranceAgentFilterDTO>;
  OR?: InputMaybe<Array<InsuranceAgentFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  firstName?: InputMaybe<StringFilter>;
  fullName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
  middleName?: InputMaybe<NullableStringFilter>;
  suffix?: InputMaybe<NullableStringFilter>;
  title?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsuranceAgentGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  middleName?: InputMaybe<Scalars['Boolean']>;
  suffix?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceAgentPolicy = {
  __typename?: 'InsuranceAgentPolicy';
  Agent: InsuranceAgent;
  InsurancePolicy: InsurancePolicy;
  agentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The date (inclusive) that the listed agent was no longer responsible for this policy. */
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurancePolicyId: Scalars['String'];
  isCurrentAgentForPolicy: Scalars['Boolean'];
  /** The date (inclusive) that the listed agent became responsible for this policy. */
  startDate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InsuranceAgentPolicyFilterDTO = {
  AND?: InputMaybe<Array<InsuranceAgentPolicyFilterDTO>>;
  Agent?: InputMaybe<InsuranceAgentFilterDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyFilterDTO>;
  NOT?: InputMaybe<InsuranceAgentPolicyFilterDTO>;
  OR?: InputMaybe<Array<InsuranceAgentPolicyFilterDTO>>;
  agentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  insurancePolicyId?: InputMaybe<StringFilter>;
  isCurrentAgentForPolicy?: InputMaybe<BooleanFilter>;
  startDate?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsuranceAgentPolicySortByDTO = {
  Agent?: InputMaybe<InsuranceAgentSortByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicySortByDTO>;
  agentId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  endDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insurancePolicyId?: InputMaybe<SortByDirection>;
  isCurrentAgentForPolicy?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InsuranceAgentSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  firstName?: InputMaybe<SortByDirection>;
  fullName?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  lastName?: InputMaybe<SortByDirection>;
  middleName?: InputMaybe<NullableSortByDirection>;
  suffix?: InputMaybe<NullableSortByDirection>;
  title?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InsuranceEndorsement = IInsuranceEndorsement & {
  __typename?: 'InsuranceEndorsement';
  AllocationRatios: Array<InsuranceEndorsementAllocationRatio>;
  HedgeStrategy?: Maybe<HedgeStrategy>;
  InsuranceAgent?: Maybe<InsuranceAgent>;
  InsurancePolicy: InsurancePolicy;
  InsurancePractice?: Maybe<InsurancePractice>;
  RevenueHedgeProducts: Array<Product>;
  RmaType?: Maybe<RmaType>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** The ending date (inclusive) of the endorsement's coverage period */
  endDate: Scalars['String'];
  /** @deprecated Use "forecastedPnl" instead */
  estimatedPl?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** This will contain either the actual indemnity value if provided or the forecasted indemnity value otherwise. */
  indemnity?: Maybe<Scalars['Float']>;
  /** @deprecated Use "forecastedIndemnity" instead */
  indemnityAmount?: Maybe<Scalars['Float']>;
  inputSource: InputSource;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong: Scalars['Boolean'];
  isShort: Scalars['Boolean'];
  /** This will contain either the actual pnl value if provided or the forecasted pnl value otherwise. */
  pnl?: Maybe<Scalars['Float']>;
  policyId: Scalars['String'];
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount: Scalars['Float'];
  reinsuranceYear: Scalars['Int'];
  revenueHedgeProductIds: Array<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  salesEffectiveDate: Scalars['String'];
  /** The starting date (inclusive) of the endorsement's coverage period */
  startDate: Scalars['String'];
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode: Scalars['String'];
  stateId: Scalars['String'];
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount: Scalars['Float'];
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount: Scalars['Float'];
  type: TypeOfInsuranceEndorsement;
  typeCode?: Maybe<Scalars['String']>;
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type InsuranceEndorsement_AllocationRatiosArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementAllocationRatioSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
};

export type InsuranceEndorsementAllocationRatio = {
  __typename?: 'InsuranceEndorsementAllocationRatio';
  RatioAdjustedDerivedDrpInsuranceEndorsement?: Maybe<DerivedDrpEndorsement>;
  RatioAdjustedInsuranceEndorsement: IInsuranceEndorsement;
  allocationRatio: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  effectiveHedgeDate: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  insuranceEndorsementId: Scalars['String'];
  insurancePolicyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InsuranceEndorsementAllocationRatioDistinctOnDTO = {
  allocationRatio?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insuranceEndorsementId?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceEndorsementAllocationRatioFilterDTO = {
  AND?: InputMaybe<Array<InsuranceEndorsementAllocationRatioFilterDTO>>;
  InsuranceEndorsement?: InputMaybe<InsuranceEndorsementFilterDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyFilterDTO>;
  NOT?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
  OR?: InputMaybe<Array<InsuranceEndorsementAllocationRatioFilterDTO>>;
  allocationRatio?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  effectiveHedgeDate?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  insuranceEndorsementId?: InputMaybe<StringFilter>;
  insurancePolicyId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsuranceEndorsementAllocationRatioSortByDTO = {
  InsuranceEndorsement?: InputMaybe<InsuranceEndorsementSortByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicySortByDTO>;
  allocationRatio?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  effectiveHedgeDate?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insuranceEndorsementId?: InputMaybe<SortByDirection>;
  insurancePolicyId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InsuranceEndorsementDistinctOnDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  aoExpenseSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  baseSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  bfrVfrSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  ccSubsidyReductionAmount?: InputMaybe<Scalars['Boolean']>;
  ccSubsidyReductionPercent?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  classiiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classiiiPounds?: InputMaybe<Scalars['Boolean']>;
  classivGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classivPounds?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityCodeYear?: InputMaybe<Scalars['Boolean']>;
  commodityId?: InputMaybe<Scalars['Boolean']>;
  commodityPrice?: InputMaybe<Scalars['Boolean']>;
  commodityPriceIsEstimated?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  cornTargetBushels?: InputMaybe<Scalars['Boolean']>;
  coverageEndDate?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  coverageMonths?: InputMaybe<Scalars['Boolean']>;
  coveragePrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  dateOfForecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  deductibleAmount?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeMonth?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassivPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueAmount?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  feederCattleTargetWeight?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  grossMarginGuarantee?: InputMaybe<Scalars['Boolean']>;
  headCount?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassivPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredValue?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  lengthInWeeks?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  liveCattleTargetWeight?: InputMaybe<Scalars['Boolean']>;
  livestockRate?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductId?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  rmaTypeId?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  simulatedLossAmount?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  targetWeightCwt?: InputMaybe<Scalars['Boolean']>;
  totalExpectedGrossMargin?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  totalTarget?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceEndorsementFilterDTO = {
  AND?: InputMaybe<Array<InsuranceEndorsementFilterDTO>>;
  Agent?: InputMaybe<InsuranceAgentFilterDTO>;
  AsDrpEndorsement?: InputMaybe<DrpEndorsementFilterDTO>;
  AsDrpInsuranceEndorsement?: InputMaybe<DrpInsuranceEndorsementFilterDTO>;
  AsLgmInsuranceEndorsement?: InputMaybe<LgmInsuranceEndorsementFilterDTO>;
  AsLrpEndorsement?: InputMaybe<LrpEndorsementFilterDTO>;
  AsLrpInsuranceEndorsement?: InputMaybe<LrpInsuranceEndorsementFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyFilterDTO>;
  InsuranceEndorsementAllocationRatios?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyFilterDTO>;
  NOT?: InputMaybe<InsuranceEndorsementFilterDTO>;
  OR?: InputMaybe<Array<InsuranceEndorsementFilterDTO>>;
  Policy?: InputMaybe<InsurancePolicyFilterDTO>;
  Practice?: InputMaybe<InsurancePracticeFilterDTO>;
  RmaType?: InputMaybe<RmaTypeFilterDTO>;
  State?: InputMaybe<StateFilterDTO>;
  actualIndemnity?: InputMaybe<NullableNumberFilter>;
  actualPnl?: InputMaybe<NullableNumberFilter>;
  actualYield?: InputMaybe<NullableNumberFilter>;
  agentId?: InputMaybe<NullableStringFilter>;
  aipDrpPremiumKey?: InputMaybe<NullableStringFilter>;
  aipLrpPremiumKey?: InputMaybe<NullableStringFilter>;
  aoExpenseSubsidyAmount?: InputMaybe<NullableNumberFilter>;
  baseSubsidyAmount?: InputMaybe<NullableNumberFilter>;
  bfrVfrSubsidyAmount?: InputMaybe<NullableNumberFilter>;
  butterGrossProtection?: InputMaybe<NullableNumberFilter>;
  butterfatGrossProtection?: InputMaybe<NullableNumberFilter>;
  butterfatPounds?: InputMaybe<NullableNumberFilter>;
  ccSubsidyReductionAmount?: InputMaybe<NullableNumberFilter>;
  ccSubsidyReductionPercent?: InputMaybe<NullableNumberFilter>;
  cheeseGrossProtection?: InputMaybe<NullableNumberFilter>;
  classPriceWeightingFactor?: InputMaybe<NullableNumberFilter>;
  classiiiGrossProtection?: InputMaybe<NullableNumberFilter>;
  classiiiPounds?: InputMaybe<NullableNumberFilter>;
  classivGrossProtection?: InputMaybe<NullableNumberFilter>;
  classivPounds?: InputMaybe<NullableNumberFilter>;
  commodityCode?: InputMaybe<NullableStringFilter>;
  commodityCodeYear?: InputMaybe<NullableStringFilter>;
  commodityId?: InputMaybe<NullableStringFilter>;
  commodityPrice?: InputMaybe<NullableNumberFilter>;
  commodityPriceIsEstimated?: InputMaybe<NullableBooleanFilter>;
  componentPriceWeightingFactor?: InputMaybe<NullableNumberFilter>;
  cornTargetBushels?: InputMaybe<NullableNumberFilter>;
  coverageEndDate?: InputMaybe<NullableStringFilter>;
  coverageLevelPercent?: InputMaybe<NullableNumberFilter>;
  coverageMonths?: InputMaybe<StringArrayFilter>;
  coveragePrice?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentYield?: InputMaybe<NullableNumberFilter>;
  dateOfForecastedIndemnity?: InputMaybe<NullableStringFilter>;
  declaredButterfatTest?: InputMaybe<NullableNumberFilter>;
  declaredCoveredMilkProduction?: InputMaybe<NullableNumberFilter>;
  declaredProteinTest?: InputMaybe<NullableNumberFilter>;
  declaredShare?: InputMaybe<NullableNumberFilter>;
  deductibleAmount?: InputMaybe<NullableNumberFilter>;
  effectiveCoveredMilkProduction?: InputMaybe<NullableNumberFilter>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<NullableNumberFilter>;
  effectiveHedgeMonth?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<StringFilter>;
  estimatedPl?: InputMaybe<NullableNumberFilter>;
  excessButterPounds?: InputMaybe<NullableNumberFilter>;
  excessCheesePounds?: InputMaybe<NullableNumberFilter>;
  excessProteinPoundsAfterCheese?: InputMaybe<NullableNumberFilter>;
  expectedButterPrice?: InputMaybe<NullableNumberFilter>;
  expectedButterfatPrice?: InputMaybe<NullableNumberFilter>;
  expectedCheesePrice?: InputMaybe<NullableNumberFilter>;
  expectedClassiiiPrice?: InputMaybe<NullableNumberFilter>;
  expectedClassivPrice?: InputMaybe<NullableNumberFilter>;
  expectedNonfatSolidsPrice?: InputMaybe<NullableNumberFilter>;
  expectedOtherSolidsPrice?: InputMaybe<NullableNumberFilter>;
  expectedProteinPrice?: InputMaybe<NullableNumberFilter>;
  expectedRevenueAmount?: InputMaybe<NullableStringFilter>;
  expectedRevenueGuarantee?: InputMaybe<NullableStringFilter>;
  feederCattleTargetWeight?: InputMaybe<NullableNumberFilter>;
  forecastedIndemnity?: InputMaybe<NullableNumberFilter>;
  forecastedPnl?: InputMaybe<NullableNumberFilter>;
  grossMarginGuarantee?: InputMaybe<NullableNumberFilter>;
  headCount?: InputMaybe<NullableNumberFilter>;
  hedgeStrategyId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  indemnity?: InputMaybe<NullableNumberFilter>;
  indemnityAmount?: InputMaybe<NullableNumberFilter>;
  indemnityEffectButter?: InputMaybe<NullableNumberFilter>;
  indemnityEffectButterfat?: InputMaybe<NullableNumberFilter>;
  indemnityEffectCheese?: InputMaybe<NullableNumberFilter>;
  indemnityEffectClassiii?: InputMaybe<NullableNumberFilter>;
  indemnityEffectClassiv?: InputMaybe<NullableNumberFilter>;
  indemnityEffectExcessProtein?: InputMaybe<NullableNumberFilter>;
  indemnityEffectNonfatSolids?: InputMaybe<NullableNumberFilter>;
  indemnityEffectOtherSolids?: InputMaybe<NullableNumberFilter>;
  indemnityEffectProtein?: InputMaybe<NullableNumberFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  insurancePolicyId?: InputMaybe<StringFilter>;
  insurancePracticeCode?: InputMaybe<NullableStringFilter>;
  insurancePracticeId?: InputMaybe<NullableStringFilter>;
  insuredButterPrice?: InputMaybe<NullableNumberFilter>;
  insuredButterfatPrice?: InputMaybe<NullableNumberFilter>;
  insuredCheesePrice?: InputMaybe<NullableNumberFilter>;
  insuredClassiiiPrice?: InputMaybe<NullableNumberFilter>;
  insuredClassivPrice?: InputMaybe<NullableNumberFilter>;
  insuredNonfatSolidsPrice?: InputMaybe<NullableNumberFilter>;
  insuredOtherSolidsPrice?: InputMaybe<NullableNumberFilter>;
  insuredProteinPrice?: InputMaybe<NullableNumberFilter>;
  insuredValue?: InputMaybe<NullableNumberFilter>;
  insuredYield?: InputMaybe<NullableNumberFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  isLong?: InputMaybe<BooleanFilter>;
  isShort?: InputMaybe<BooleanFilter>;
  lastYield?: InputMaybe<NullableNumberFilter>;
  lengthInWeeks?: InputMaybe<NullableNumberFilter>;
  liability?: InputMaybe<NullableNumberFilter>;
  liveCattleTargetWeight?: InputMaybe<NullableNumberFilter>;
  livestockRate?: InputMaybe<NullableNumberFilter>;
  nonfatSolidsGrossProtection?: InputMaybe<NullableNumberFilter>;
  nonfatSolidsPounds?: InputMaybe<NullableNumberFilter>;
  otherPoundsGrossProtection?: InputMaybe<NullableNumberFilter>;
  otherSolidsPounds?: InputMaybe<NullableNumberFilter>;
  pnl?: InputMaybe<NullableNumberFilter>;
  policyId?: InputMaybe<StringFilter>;
  producerPremiumAmount?: InputMaybe<NumberFilter>;
  projectedRevenue?: InputMaybe<NullableNumberFilter>;
  protectionFactor?: InputMaybe<NullableNumberFilter>;
  proteinGrossProtection?: InputMaybe<NullableNumberFilter>;
  proteinPounds?: InputMaybe<NullableNumberFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  revenueHedgeProductId?: InputMaybe<NullableStringFilter>;
  revenueHedgeProductIds?: InputMaybe<StringArrayFilter>;
  rmaPracticeCode?: InputMaybe<NullableStringFilter>;
  rmaStatus?: InputMaybe<NullableTypeOfRmaStatusFilter>;
  rmaTypeId?: InputMaybe<NullableStringFilter>;
  salesEffectiveDate?: InputMaybe<StringFilter>;
  simulatedLossAmount?: InputMaybe<NullableNumberFilter>;
  startDate?: InputMaybe<StringFilter>;
  stateCode?: InputMaybe<StringFilter>;
  stateId?: InputMaybe<StringFilter>;
  subsidyAmount?: InputMaybe<NumberFilter>;
  targetWeightCwt?: InputMaybe<NullableNumberFilter>;
  totalExpectedGrossMargin?: InputMaybe<NullableNumberFilter>;
  totalPremiumAmount?: InputMaybe<NumberFilter>;
  totalTarget?: InputMaybe<NullableNumberFilter>;
  type?: InputMaybe<TypeOfInsuranceEndorsementFilter>;
  typeCode?: InputMaybe<NullableStringFilter>;
  typeCodeYear?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  yieldAdjustmentFactor?: InputMaybe<NullableNumberFilter>;
};

export type InsuranceEndorsementGroupByDTO = {
  Agent?: InputMaybe<InsuranceAgentGroupByDTO>;
  AsDrpEndorsement?: InputMaybe<DrpEndorsementGroupByDTO>;
  AsDrpInsuranceEndorsement?: InputMaybe<DrpInsuranceEndorsementGroupByDTO>;
  AsLgmInsuranceEndorsement?: InputMaybe<LgmInsuranceEndorsementGroupByDTO>;
  AsLrpEndorsement?: InputMaybe<LrpEndorsementGroupByDTO>;
  AsLrpInsuranceEndorsement?: InputMaybe<LrpInsuranceEndorsementGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyGroupByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyGroupByDTO>;
  Policy?: InputMaybe<InsurancePolicyGroupByDTO>;
  Practice?: InputMaybe<InsurancePracticeGroupByDTO>;
  RmaType?: InputMaybe<RmaTypeGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipDrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  aoExpenseSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  baseSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  bfrVfrSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  butterGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatGrossProtection?: InputMaybe<Scalars['Boolean']>;
  butterfatPounds?: InputMaybe<Scalars['Boolean']>;
  ccSubsidyReductionAmount?: InputMaybe<Scalars['Boolean']>;
  ccSubsidyReductionPercent?: InputMaybe<Scalars['Boolean']>;
  cheeseGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  classiiiGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classiiiPounds?: InputMaybe<Scalars['Boolean']>;
  classivGrossProtection?: InputMaybe<Scalars['Boolean']>;
  classivPounds?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityCodeYear?: InputMaybe<Scalars['Boolean']>;
  commodityId?: InputMaybe<Scalars['Boolean']>;
  commodityPrice?: InputMaybe<Scalars['Boolean']>;
  commodityPriceIsEstimated?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactor?: InputMaybe<Scalars['Boolean']>;
  cornTargetBushels?: InputMaybe<Scalars['Boolean']>;
  coverageEndDate?: InputMaybe<Scalars['Boolean']>;
  coverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  coverageMonths?: InputMaybe<Scalars['Boolean']>;
  coveragePrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  currentYield?: InputMaybe<Scalars['Boolean']>;
  dateOfForecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  declaredButterfatTest?: InputMaybe<Scalars['Boolean']>;
  declaredCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  declaredProteinTest?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  deductibleAmount?: InputMaybe<Scalars['Boolean']>;
  effectiveCoveredMilkProduction?: InputMaybe<Scalars['Boolean']>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeMonth?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  excessButterPounds?: InputMaybe<Scalars['Boolean']>;
  excessCheesePounds?: InputMaybe<Scalars['Boolean']>;
  excessProteinPoundsAfterCheese?: InputMaybe<Scalars['Boolean']>;
  expectedButterPrice?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedCheesePrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassivPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueAmount?: InputMaybe<Scalars['Boolean']>;
  expectedRevenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  feederCattleTargetWeight?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  grossMarginGuarantee?: InputMaybe<Scalars['Boolean']>;
  headCount?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButter?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectButterfat?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectCheese?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiii?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectClassiv?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectExcessProtein?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectNonfatSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectOtherSolids?: InputMaybe<Scalars['Boolean']>;
  indemnityEffectProtein?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredButterPrice?: InputMaybe<Scalars['Boolean']>;
  insuredButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  insuredCheesePrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassiiiPrice?: InputMaybe<Scalars['Boolean']>;
  insuredClassivPrice?: InputMaybe<Scalars['Boolean']>;
  insuredNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  insuredProteinPrice?: InputMaybe<Scalars['Boolean']>;
  insuredValue?: InputMaybe<Scalars['Boolean']>;
  insuredYield?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  lengthInWeeks?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  liveCattleTargetWeight?: InputMaybe<Scalars['Boolean']>;
  livestockRate?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  otherPoundsGrossProtection?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPounds?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  projectedRevenue?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  proteinGrossProtection?: InputMaybe<Scalars['Boolean']>;
  proteinPounds?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductId?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaPracticeCode?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  rmaTypeId?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  simulatedLossAmount?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  targetWeightCwt?: InputMaybe<Scalars['Boolean']>;
  totalExpectedGrossMargin?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  totalTarget?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  yieldAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceEndorsementSortByDTO = {
  Agent?: InputMaybe<InsuranceAgentSortByDTO>;
  AsDrpEndorsement?: InputMaybe<DrpEndorsementSortByDTO>;
  AsDrpInsuranceEndorsement?: InputMaybe<DrpInsuranceEndorsementSortByDTO>;
  AsLgmInsuranceEndorsement?: InputMaybe<LgmInsuranceEndorsementSortByDTO>;
  AsLrpEndorsement?: InputMaybe<LrpEndorsementSortByDTO>;
  AsLrpInsuranceEndorsement?: InputMaybe<LrpInsuranceEndorsementSortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategySortByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicySortByDTO>;
  Policy?: InputMaybe<InsurancePolicySortByDTO>;
  Practice?: InputMaybe<InsurancePracticeSortByDTO>;
  RmaType?: InputMaybe<RmaTypeSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  actualIndemnity?: InputMaybe<NullableSortByDirection>;
  actualPnl?: InputMaybe<NullableSortByDirection>;
  actualYield?: InputMaybe<NullableSortByDirection>;
  agentId?: InputMaybe<NullableSortByDirection>;
  aipDrpPremiumKey?: InputMaybe<NullableSortByDirection>;
  aipLrpPremiumKey?: InputMaybe<NullableSortByDirection>;
  aoExpenseSubsidyAmount?: InputMaybe<NullableSortByDirection>;
  baseSubsidyAmount?: InputMaybe<NullableSortByDirection>;
  bfrVfrSubsidyAmount?: InputMaybe<NullableSortByDirection>;
  butterGrossProtection?: InputMaybe<NullableSortByDirection>;
  butterfatGrossProtection?: InputMaybe<NullableSortByDirection>;
  butterfatPounds?: InputMaybe<NullableSortByDirection>;
  ccSubsidyReductionAmount?: InputMaybe<NullableSortByDirection>;
  ccSubsidyReductionPercent?: InputMaybe<NullableSortByDirection>;
  cheeseGrossProtection?: InputMaybe<NullableSortByDirection>;
  classPriceWeightingFactor?: InputMaybe<NullableSortByDirection>;
  classiiiGrossProtection?: InputMaybe<NullableSortByDirection>;
  classiiiPounds?: InputMaybe<NullableSortByDirection>;
  classivGrossProtection?: InputMaybe<NullableSortByDirection>;
  classivPounds?: InputMaybe<NullableSortByDirection>;
  commodityCode?: InputMaybe<NullableSortByDirection>;
  commodityCodeYear?: InputMaybe<NullableSortByDirection>;
  commodityId?: InputMaybe<NullableSortByDirection>;
  commodityPrice?: InputMaybe<NullableSortByDirection>;
  commodityPriceIsEstimated?: InputMaybe<NullableSortByDirection>;
  componentPriceWeightingFactor?: InputMaybe<NullableSortByDirection>;
  cornTargetBushels?: InputMaybe<NullableSortByDirection>;
  coverageEndDate?: InputMaybe<NullableSortByDirection>;
  coverageLevelPercent?: InputMaybe<NullableSortByDirection>;
  coveragePrice?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  currentYield?: InputMaybe<NullableSortByDirection>;
  dateOfForecastedIndemnity?: InputMaybe<NullableSortByDirection>;
  declaredButterfatTest?: InputMaybe<NullableSortByDirection>;
  declaredCoveredMilkProduction?: InputMaybe<NullableSortByDirection>;
  declaredProteinTest?: InputMaybe<NullableSortByDirection>;
  declaredShare?: InputMaybe<NullableSortByDirection>;
  deductibleAmount?: InputMaybe<NullableSortByDirection>;
  effectiveCoveredMilkProduction?: InputMaybe<NullableSortByDirection>;
  effectiveExpectedRevenueGuarantee?: InputMaybe<NullableSortByDirection>;
  effectiveHedgeMonth?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<SortByDirection>;
  estimatedPl?: InputMaybe<NullableSortByDirection>;
  excessButterPounds?: InputMaybe<NullableSortByDirection>;
  excessCheesePounds?: InputMaybe<NullableSortByDirection>;
  excessProteinPoundsAfterCheese?: InputMaybe<NullableSortByDirection>;
  expectedButterPrice?: InputMaybe<NullableSortByDirection>;
  expectedButterfatPrice?: InputMaybe<NullableSortByDirection>;
  expectedCheesePrice?: InputMaybe<NullableSortByDirection>;
  expectedClassiiiPrice?: InputMaybe<NullableSortByDirection>;
  expectedClassivPrice?: InputMaybe<NullableSortByDirection>;
  expectedNonfatSolidsPrice?: InputMaybe<NullableSortByDirection>;
  expectedOtherSolidsPrice?: InputMaybe<NullableSortByDirection>;
  expectedProteinPrice?: InputMaybe<NullableSortByDirection>;
  expectedRevenueAmount?: InputMaybe<NullableSortByDirection>;
  expectedRevenueGuarantee?: InputMaybe<NullableSortByDirection>;
  feederCattleTargetWeight?: InputMaybe<NullableSortByDirection>;
  forecastedIndemnity?: InputMaybe<NullableSortByDirection>;
  forecastedPnl?: InputMaybe<NullableSortByDirection>;
  grossMarginGuarantee?: InputMaybe<NullableSortByDirection>;
  headCount?: InputMaybe<NullableSortByDirection>;
  hedgeStrategyId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  indemnity?: InputMaybe<NullableSortByDirection>;
  indemnityAmount?: InputMaybe<NullableSortByDirection>;
  indemnityEffectButter?: InputMaybe<NullableSortByDirection>;
  indemnityEffectButterfat?: InputMaybe<NullableSortByDirection>;
  indemnityEffectCheese?: InputMaybe<NullableSortByDirection>;
  indemnityEffectClassiii?: InputMaybe<NullableSortByDirection>;
  indemnityEffectClassiv?: InputMaybe<NullableSortByDirection>;
  indemnityEffectExcessProtein?: InputMaybe<NullableSortByDirection>;
  indemnityEffectNonfatSolids?: InputMaybe<NullableSortByDirection>;
  indemnityEffectOtherSolids?: InputMaybe<NullableSortByDirection>;
  indemnityEffectProtein?: InputMaybe<NullableSortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  insurancePolicyId?: InputMaybe<SortByDirection>;
  insurancePracticeCode?: InputMaybe<NullableSortByDirection>;
  insurancePracticeId?: InputMaybe<NullableSortByDirection>;
  insuredButterPrice?: InputMaybe<NullableSortByDirection>;
  insuredButterfatPrice?: InputMaybe<NullableSortByDirection>;
  insuredCheesePrice?: InputMaybe<NullableSortByDirection>;
  insuredClassiiiPrice?: InputMaybe<NullableSortByDirection>;
  insuredClassivPrice?: InputMaybe<NullableSortByDirection>;
  insuredNonfatSolidsPrice?: InputMaybe<NullableSortByDirection>;
  insuredOtherSolidsPrice?: InputMaybe<NullableSortByDirection>;
  insuredProteinPrice?: InputMaybe<NullableSortByDirection>;
  insuredValue?: InputMaybe<NullableSortByDirection>;
  insuredYield?: InputMaybe<NullableSortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  isLong?: InputMaybe<SortByDirection>;
  isShort?: InputMaybe<SortByDirection>;
  lastYield?: InputMaybe<NullableSortByDirection>;
  lengthInWeeks?: InputMaybe<NullableSortByDirection>;
  liability?: InputMaybe<NullableSortByDirection>;
  liveCattleTargetWeight?: InputMaybe<NullableSortByDirection>;
  livestockRate?: InputMaybe<NullableSortByDirection>;
  nonfatSolidsGrossProtection?: InputMaybe<NullableSortByDirection>;
  nonfatSolidsPounds?: InputMaybe<NullableSortByDirection>;
  otherPoundsGrossProtection?: InputMaybe<NullableSortByDirection>;
  otherSolidsPounds?: InputMaybe<NullableSortByDirection>;
  pnl?: InputMaybe<NullableSortByDirection>;
  policyId?: InputMaybe<SortByDirection>;
  producerPremiumAmount?: InputMaybe<SortByDirection>;
  projectedRevenue?: InputMaybe<NullableSortByDirection>;
  protectionFactor?: InputMaybe<NullableSortByDirection>;
  proteinGrossProtection?: InputMaybe<NullableSortByDirection>;
  proteinPounds?: InputMaybe<NullableSortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  revenueHedgeProductId?: InputMaybe<NullableSortByDirection>;
  rmaPracticeCode?: InputMaybe<NullableSortByDirection>;
  rmaStatus?: InputMaybe<NullableSortByDirection>;
  rmaTypeId?: InputMaybe<NullableSortByDirection>;
  salesEffectiveDate?: InputMaybe<SortByDirection>;
  simulatedLossAmount?: InputMaybe<NullableSortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  stateCode?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  subsidyAmount?: InputMaybe<SortByDirection>;
  targetWeightCwt?: InputMaybe<NullableSortByDirection>;
  totalExpectedGrossMargin?: InputMaybe<NullableSortByDirection>;
  totalPremiumAmount?: InputMaybe<SortByDirection>;
  totalTarget?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  typeCode?: InputMaybe<NullableSortByDirection>;
  typeCodeYear?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  yieldAdjustmentFactor?: InputMaybe<NullableSortByDirection>;
};

export type InsuranceFile = {
  __typename?: 'InsuranceFile';
  InsurancePolicy: InsurancePolicy;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  fileDate?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  insurancePolicyId: Scalars['String'];
  integrationInstallationId?: Maybe<Scalars['String']>;
  lastModifiedAt: Scalars['DateTime'];
  reinsuranceYear: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type InsuranceFileDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  fileDate?: InputMaybe<Scalars['Boolean']>;
  fileName?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  lastModifiedAt?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceFileFilterDTO = {
  AND?: InputMaybe<Array<InsuranceFileFilterDTO>>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyFilterDTO>;
  NOT?: InputMaybe<InsuranceFileFilterDTO>;
  OR?: InputMaybe<Array<InsuranceFileFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  fileDate?: InputMaybe<NullableStringFilter>;
  fileName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  insurancePolicyId?: InputMaybe<StringFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  lastModifiedAt?: InputMaybe<DateTimeFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringFilter>;
};

export type InsuranceFileSortByDTO = {
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicySortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  description?: InputMaybe<SortByDirection>;
  fileDate?: InputMaybe<NullableSortByDirection>;
  fileName?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insurancePolicyId?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  lastModifiedAt?: InputMaybe<SortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  url?: InputMaybe<SortByDirection>;
};

export type InsuranceHistoricalDrpPremium = IInsuranceHistoricalPremium & {
  __typename?: 'InsuranceHistoricalDrpPremium';
  InsuranceHistoricalPremiumInput: IInsuranceHistoricalPremiumInput;
  InsurancePractice: InsurancePractice;
  State: State;
  coverageLevel: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  grossPremium: Scalars['Float'];
  id: Scalars['ID'];
  insurancePracticeDisplayDate: Scalars['String'];
  insurancePracticeId: Scalars['String'];
  liability: Scalars['Float'];
  netPremium: Scalars['Float'];
  protectedPrice: Scalars['Float'];
  revenueGuarantee: Scalars['Float'];
  stateId: Scalars['String'];
  subsidy: Scalars['Float'];
  type: TypeOfInsuranceHistoricalPremium;
  updatedAt: Scalars['DateTime'];
};

export type InsuranceHistoricalDrpPremiumAggregateAllDTO = {
  coverageLevel?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossPremium?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeDisplayDate?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  protectedPrice?: InputMaybe<Scalars['Boolean']>;
  revenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidy?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceHistoricalDrpPremiumAggregateDTO = {
  avg?: InputMaybe<InsuranceHistoricalDrpPremiumAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<InsuranceHistoricalDrpPremiumAggregateAllDTO>;
  min?: InputMaybe<InsuranceHistoricalDrpPremiumAggregateAllDTO>;
  sum?: InputMaybe<InsuranceHistoricalDrpPremiumAggregateNumericDTO>;
};

export type InsuranceHistoricalDrpPremiumAggregateNumericDTO = {
  coverageLevel?: InputMaybe<Scalars['Boolean']>;
  grossPremium?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  protectedPrice?: InputMaybe<Scalars['Boolean']>;
  revenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  subsidy?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceHistoricalDrpPremiumDistinctOnDTO = {
  coverageLevel?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossPremium?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeDisplayDate?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  protectedPrice?: InputMaybe<Scalars['Boolean']>;
  revenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidy?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceHistoricalDrpPremiumFilterDTO = {
  AND?: InputMaybe<Array<InsuranceHistoricalDrpPremiumFilterDTO>>;
  AsInsuranceHistoricalPremium?: InputMaybe<InsuranceHistoricalPremiumFilterDTO>;
  InsuranceHistoricalPremiumInput?: InputMaybe<InsuranceHistoricalPremiumInputFilterDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeFilterDTO>;
  NOT?: InputMaybe<InsuranceHistoricalDrpPremiumFilterDTO>;
  OR?: InputMaybe<Array<InsuranceHistoricalDrpPremiumFilterDTO>>;
  State?: InputMaybe<StateFilterDTO>;
  coverageLevel?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  grossPremium?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  insurancePracticeDisplayDate?: InputMaybe<StringFilter>;
  insurancePracticeId?: InputMaybe<StringFilter>;
  liability?: InputMaybe<NumberFilter>;
  netPremium?: InputMaybe<NumberFilter>;
  protectedPrice?: InputMaybe<NumberFilter>;
  revenueGuarantee?: InputMaybe<NumberFilter>;
  stateId?: InputMaybe<StringFilter>;
  subsidy?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsuranceHistoricalDrpPremiumGroupByDTO = {
  AsInsuranceHistoricalPremium?: InputMaybe<InsuranceHistoricalPremiumGroupByDTO>;
  InsuranceHistoricalPremiumInput?: InputMaybe<InsuranceHistoricalPremiumInputGroupByDTO>;
  InsuranceHistoricalPremiumInputAsInsuranceHistoricalDrpPremiumInput?: InputMaybe<InsuranceHistoricalDrpPremiumInputGroupByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  coverageLevel?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossPremium?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeDisplayDate?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  protectedPrice?: InputMaybe<Scalars['Boolean']>;
  revenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidy?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceHistoricalDrpPremiumInput = IInsuranceHistoricalPremiumInput & {
  __typename?: 'InsuranceHistoricalDrpPremiumInput';
  classWeight: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isFullyClassIii: Scalars['Boolean'];
  isFullyClassIv: Scalars['Boolean'];
  production: Scalars['Float'];
  protectionFactor: Scalars['Float'];
  type: TypeOfInsuranceHistoricalPremiumInput;
  updatedAt: Scalars['DateTime'];
};

export type InsuranceHistoricalDrpPremiumInputDistinctOnDTO = {
  classWeight?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isFullyClassIii?: InputMaybe<Scalars['Boolean']>;
  isFullyClassIv?: InputMaybe<Scalars['Boolean']>;
  production?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceHistoricalDrpPremiumInputFilterDTO = {
  AND?: InputMaybe<Array<InsuranceHistoricalDrpPremiumInputFilterDTO>>;
  AsInsuranceHistoricalPremiumInput?: InputMaybe<InsuranceHistoricalPremiumInputFilterDTO>;
  InsuranceHistoricalPremiums?: InputMaybe<InsuranceHistoricalPremiumFilterDTO>;
  NOT?: InputMaybe<InsuranceHistoricalDrpPremiumInputFilterDTO>;
  OR?: InputMaybe<Array<InsuranceHistoricalDrpPremiumInputFilterDTO>>;
  classWeight?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isFullyClassIii?: InputMaybe<BooleanFilter>;
  isFullyClassIv?: InputMaybe<BooleanFilter>;
  production?: InputMaybe<NumberFilter>;
  protectionFactor?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsuranceHistoricalDrpPremiumInputGroupByDTO = {
  AsInsuranceHistoricalPremiumInput?: InputMaybe<InsuranceHistoricalPremiumInputGroupByDTO>;
  classWeight?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isFullyClassIii?: InputMaybe<Scalars['Boolean']>;
  isFullyClassIv?: InputMaybe<Scalars['Boolean']>;
  production?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceHistoricalDrpPremiumInputSortByDTO = {
  AsInsuranceHistoricalPremiumInput?: InputMaybe<InsuranceHistoricalPremiumInputSortByDTO>;
  classWeight?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isFullyClassIii?: InputMaybe<SortByDirection>;
  isFullyClassIv?: InputMaybe<SortByDirection>;
  production?: InputMaybe<SortByDirection>;
  protectionFactor?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InsuranceHistoricalDrpPremiumSortByDTO = {
  AsInsuranceHistoricalPremium?: InputMaybe<InsuranceHistoricalPremiumSortByDTO>;
  InsuranceHistoricalPremiumInput?: InputMaybe<InsuranceHistoricalPremiumInputSortByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  coverageLevel?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  grossPremium?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insurancePracticeDisplayDate?: InputMaybe<SortByDirection>;
  insurancePracticeId?: InputMaybe<SortByDirection>;
  liability?: InputMaybe<SortByDirection>;
  netPremium?: InputMaybe<SortByDirection>;
  protectedPrice?: InputMaybe<SortByDirection>;
  revenueGuarantee?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  subsidy?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InsuranceHistoricalPremium = IInsuranceHistoricalPremium & {
  __typename?: 'InsuranceHistoricalPremium';
  InsuranceHistoricalPremiumInput: IInsuranceHistoricalPremiumInput;
  InsurancePractice: InsurancePractice;
  State: State;
  coverageLevel: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  grossPremium: Scalars['Float'];
  id: Scalars['ID'];
  insurancePracticeDisplayDate: Scalars['String'];
  insurancePracticeId: Scalars['String'];
  netPremium: Scalars['Float'];
  stateId: Scalars['String'];
  subsidy: Scalars['Float'];
  type: TypeOfInsuranceHistoricalPremium;
  updatedAt: Scalars['DateTime'];
};

export type InsuranceHistoricalPremiumDistinctOnDTO = {
  coverageLevel?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossPremium?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeDisplayDate?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  protectedPrice?: InputMaybe<Scalars['Boolean']>;
  revenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidy?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceHistoricalPremiumFilterDTO = {
  AND?: InputMaybe<Array<InsuranceHistoricalPremiumFilterDTO>>;
  AsInsuranceHistoricalDrpPremium?: InputMaybe<InsuranceHistoricalDrpPremiumFilterDTO>;
  InsuranceHistoricalPremiumInput?: InputMaybe<InsuranceHistoricalPremiumInputFilterDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeFilterDTO>;
  NOT?: InputMaybe<InsuranceHistoricalPremiumFilterDTO>;
  OR?: InputMaybe<Array<InsuranceHistoricalPremiumFilterDTO>>;
  State?: InputMaybe<StateFilterDTO>;
  coverageLevel?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  grossPremium?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  insurancePracticeDisplayDate?: InputMaybe<StringFilter>;
  insurancePracticeId?: InputMaybe<StringFilter>;
  liability?: InputMaybe<NullableNumberFilter>;
  netPremium?: InputMaybe<NumberFilter>;
  protectedPrice?: InputMaybe<NullableNumberFilter>;
  revenueGuarantee?: InputMaybe<NullableNumberFilter>;
  stateId?: InputMaybe<StringFilter>;
  subsidy?: InputMaybe<NumberFilter>;
  type?: InputMaybe<TypeOfInsuranceHistoricalPremiumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsuranceHistoricalPremiumGroupByDTO = {
  AsInsuranceHistoricalDrpPremium?: InputMaybe<InsuranceHistoricalDrpPremiumGroupByDTO>;
  InsuranceHistoricalPremiumInput?: InputMaybe<InsuranceHistoricalPremiumInputGroupByDTO>;
  InsuranceHistoricalPremiumInputAsInsuranceHistoricalDrpPremiumInput?: InputMaybe<InsuranceHistoricalDrpPremiumInputGroupByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  coverageLevel?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  grossPremium?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeDisplayDate?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  netPremium?: InputMaybe<Scalars['Boolean']>;
  protectedPrice?: InputMaybe<Scalars['Boolean']>;
  revenueGuarantee?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidy?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceHistoricalPremiumInput = IInsuranceHistoricalPremiumInput & {
  __typename?: 'InsuranceHistoricalPremiumInput';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: TypeOfInsuranceHistoricalPremiumInput;
  updatedAt: Scalars['DateTime'];
};

export type InsuranceHistoricalPremiumInputDistinctOnDTO = {
  classWeight?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isFullyClassIii?: InputMaybe<Scalars['Boolean']>;
  isFullyClassIv?: InputMaybe<Scalars['Boolean']>;
  production?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceHistoricalPremiumInputFilterDTO = {
  AND?: InputMaybe<Array<InsuranceHistoricalPremiumInputFilterDTO>>;
  AsInsuranceHistoricalDrpPremiumInput?: InputMaybe<InsuranceHistoricalDrpPremiumInputFilterDTO>;
  InsuranceHistoricalPremiums?: InputMaybe<InsuranceHistoricalPremiumFilterDTO>;
  NOT?: InputMaybe<InsuranceHistoricalPremiumInputFilterDTO>;
  OR?: InputMaybe<Array<InsuranceHistoricalPremiumInputFilterDTO>>;
  classWeight?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isFullyClassIii?: InputMaybe<NullableBooleanFilter>;
  isFullyClassIv?: InputMaybe<NullableBooleanFilter>;
  production?: InputMaybe<NullableNumberFilter>;
  protectionFactor?: InputMaybe<NullableNumberFilter>;
  type?: InputMaybe<TypeOfInsuranceHistoricalPremiumInputFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsuranceHistoricalPremiumInputGroupByDTO = {
  AsInsuranceHistoricalDrpPremiumInput?: InputMaybe<InsuranceHistoricalDrpPremiumInputGroupByDTO>;
  classWeight?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isFullyClassIii?: InputMaybe<Scalars['Boolean']>;
  isFullyClassIv?: InputMaybe<Scalars['Boolean']>;
  production?: InputMaybe<Scalars['Boolean']>;
  protectionFactor?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuranceHistoricalPremiumInputSortByDTO = {
  AsInsuranceHistoricalDrpPremiumInput?: InputMaybe<InsuranceHistoricalDrpPremiumInputSortByDTO>;
  classWeight?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isFullyClassIii?: InputMaybe<NullableSortByDirection>;
  isFullyClassIv?: InputMaybe<NullableSortByDirection>;
  production?: InputMaybe<NullableSortByDirection>;
  protectionFactor?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InsuranceHistoricalPremiumSortByDTO = {
  AsInsuranceHistoricalDrpPremium?: InputMaybe<InsuranceHistoricalDrpPremiumSortByDTO>;
  InsuranceHistoricalPremiumInput?: InputMaybe<InsuranceHistoricalPremiumInputSortByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  coverageLevel?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  grossPremium?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insurancePracticeDisplayDate?: InputMaybe<SortByDirection>;
  insurancePracticeId?: InputMaybe<SortByDirection>;
  liability?: InputMaybe<NullableSortByDirection>;
  netPremium?: InputMaybe<SortByDirection>;
  protectedPrice?: InputMaybe<NullableSortByDirection>;
  revenueGuarantee?: InputMaybe<NullableSortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  subsidy?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InsurancePolicy = {
  __typename?: 'InsurancePolicy';
  AIP: Aip;
  AllocationRatios: Array<InsuranceEndorsementAllocationRatio>;
  Customer?: Maybe<CustomerEntity>;
  DerivedDrpEndorsements: Array<DerivedDrpEndorsement>;
  /** @deprecated Use "DerivedDrpEndorsements" instead */
  DerivedEndorsements: Array<DerivedDrpEndorsement>;
  InsuranceAgentPolicies: Array<InsuranceAgentPolicy>;
  InsuranceEndorsements: Array<IInsuranceEndorsement>;
  InsuranceFiles: Array<InsuranceFile>;
  Organization: OrganizationEntity;
  /** @deprecated Use `Customer` instead */
  Owner?: Maybe<CustomerEntity>;
  State: State;
  agencyName?: Maybe<Scalars['String']>;
  /** @deprecated Use the InsuranceAgent relation instead */
  agentFirstName?: Maybe<Scalars['String']>;
  agentFullName?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  /** @deprecated Use the InsuranceAgent relation instead */
  agentLastName?: Maybe<Scalars['String']>;
  aipId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  integrationInstallationId?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  policyNumber: Scalars['String'];
  /** A stub definition to temproarily avoid breaking changes */
  producerId?: Maybe<Scalars['String']>;
  producerName: Scalars['String'];
  programType: TypeOfInsuranceProgram;
  stateId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type InsurancePolicy_AllocationRatiosArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementAllocationRatioSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
};


export type InsurancePolicy_DerivedDrpEndorsementsArgs = {
  orderBy?: InputMaybe<DerivedDrpEndorsementSortByDTO>;
  where?: InputMaybe<DerivedDrpEndorsementFilterDTO>;
};


export type InsurancePolicy_DerivedEndorsementsArgs = {
  orderBy?: InputMaybe<DerivedDrpEndorsementSortByDTO>;
  where?: InputMaybe<DerivedDrpEndorsementFilterDTO>;
};


export type InsurancePolicy_InsuranceAgentPoliciesArgs = {
  orderBy?: InputMaybe<InsuranceAgentPolicySortByDTO>;
  where?: InputMaybe<InsuranceAgentPolicyFilterDTO>;
};


export type InsurancePolicy_InsuranceEndorsementsArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementFilterDTO>;
};


export type InsurancePolicy_InsuranceFilesArgs = {
  orderBy?: InputMaybe<InsuranceFileSortByDTO>;
  where?: InputMaybe<InsuranceFileFilterDTO>;
};

export type InsurancePolicyCreateDTO = {
  agencyName?: InputMaybe<Scalars['String']>;
  agentFirstName?: InputMaybe<Scalars['String']>;
  agentId?: InputMaybe<Scalars['String']>;
  agentLastName?: InputMaybe<Scalars['String']>;
  aipId: Scalars['String'];
  customerId?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  policyNumber: Scalars['String'];
  producerName: Scalars['String'];
  programType?: InputMaybe<TypeOfInsuranceProgram>;
  stateId: Scalars['String'];
};

export type InsurancePolicyDistinctOnDTO = {
  agencyName?: InputMaybe<Scalars['Boolean']>;
  agentFirstName?: InputMaybe<Scalars['Boolean']>;
  agentFullName?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  agentLastName?: InputMaybe<Scalars['Boolean']>;
  aipId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  policyNumber?: InputMaybe<Scalars['Boolean']>;
  producerId?: InputMaybe<Scalars['Boolean']>;
  producerName?: InputMaybe<Scalars['Boolean']>;
  programType?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsurancePolicyFilterDTO = {
  AND?: InputMaybe<Array<InsurancePolicyFilterDTO>>;
  Aip?: InputMaybe<AipFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  DerivedDrpEndorsement?: InputMaybe<DerivedDrpEndorsementFilterDTO>;
  Endorsements?: InputMaybe<InsuranceEndorsementFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  InsuranceAgent?: InputMaybe<InsuranceAgentFilterDTO>;
  InsuranceAgentPolicies?: InputMaybe<InsuranceAgentPolicyFilterDTO>;
  InsuranceEndorsementAllocationRatios?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
  InsuranceFiles?: InputMaybe<InsuranceFileFilterDTO>;
  NOT?: InputMaybe<InsurancePolicyFilterDTO>;
  OR?: InputMaybe<Array<InsurancePolicyFilterDTO>>;
  Owner?: InputMaybe<EntityFilterDTO>;
  State?: InputMaybe<StateFilterDTO>;
  agencyName?: InputMaybe<NullableStringFilter>;
  agentFirstName?: InputMaybe<NullableStringFilter>;
  agentFullName?: InputMaybe<NullableStringFilter>;
  agentId?: InputMaybe<NullableStringFilter>;
  agentLastName?: InputMaybe<NullableStringFilter>;
  aipId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  policyNumber?: InputMaybe<StringFilter>;
  producerId?: InputMaybe<NullableStringFilter>;
  producerName?: InputMaybe<StringFilter>;
  programType?: InputMaybe<TypeOfInsuranceProgramFilter>;
  stateId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsurancePolicyGroupByDTO = {
  Aip?: InputMaybe<AipGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  InsuranceAgent?: InputMaybe<InsuranceAgentGroupByDTO>;
  Owner?: InputMaybe<EntityGroupByDTO>;
  OwnerAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  OwnerAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  OwnerAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  OwnerAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  agencyName?: InputMaybe<Scalars['Boolean']>;
  agentFirstName?: InputMaybe<Scalars['Boolean']>;
  agentFullName?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  agentLastName?: InputMaybe<Scalars['Boolean']>;
  aipId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  policyNumber?: InputMaybe<Scalars['Boolean']>;
  producerId?: InputMaybe<Scalars['Boolean']>;
  producerName?: InputMaybe<Scalars['Boolean']>;
  programType?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsurancePolicySortByDTO = {
  Aip?: InputMaybe<AipSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  InsuranceAgent?: InputMaybe<InsuranceAgentSortByDTO>;
  Owner?: InputMaybe<EntitySortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  agencyName?: InputMaybe<NullableSortByDirection>;
  agentFirstName?: InputMaybe<NullableSortByDirection>;
  agentFullName?: InputMaybe<NullableSortByDirection>;
  agentId?: InputMaybe<NullableSortByDirection>;
  agentLastName?: InputMaybe<NullableSortByDirection>;
  aipId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  ownerId?: InputMaybe<SortByDirection>;
  policyNumber?: InputMaybe<SortByDirection>;
  producerId?: InputMaybe<NullableSortByDirection>;
  producerName?: InputMaybe<SortByDirection>;
  programType?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InsurancePolicyUpdateDTO = {
  agencyName?: InputMaybe<Scalars['String']>;
  agentId?: InputMaybe<Scalars['String']>;
  aipId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  policyNumber?: InputMaybe<Scalars['String']>;
  producerName?: InputMaybe<Scalars['String']>;
  programType?: InputMaybe<TypeOfInsuranceProgram>;
  stateId?: InputMaybe<Scalars['String']>;
};

export type InsurancePractice = {
  __typename?: 'InsurancePractice';
  InsuranceEndorsements: Array<IInsuranceEndorsement>;
  commodityCode: TypeOfInsuranceCommodity;
  createdAt: Scalars['DateTime'];
  endDate: Scalars['String'];
  id: Scalars['ID'];
  practiceAbbreviation?: Maybe<Scalars['String']>;
  practiceCode: Scalars['String'];
  programType: TypeOfInsuranceProgram;
  reinsuranceYear: Scalars['Int'];
  startDate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type InsurancePractice_InsuranceEndorsementsArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementFilterDTO>;
};

export type InsurancePracticeDistinctOnDTO = {
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  practiceAbbreviation?: InputMaybe<Scalars['Boolean']>;
  practiceCode?: InputMaybe<Scalars['Boolean']>;
  programType?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsurancePracticeFilterDTO = {
  AND?: InputMaybe<Array<InsurancePracticeFilterDTO>>;
  DrpInsuranceEndorsements?: InputMaybe<DrpInsuranceEndorsementFilterDTO>;
  Endorsements?: InputMaybe<InsuranceEndorsementFilterDTO>;
  InsuranceHistoricalPremiums?: InputMaybe<InsuranceHistoricalPremiumFilterDTO>;
  InsuredYieldsAndPrices?: InputMaybe<InsuredYieldAndPriceFilterDTO>;
  LrpInsuranceEndorsements?: InputMaybe<LrpInsuranceEndorsementFilterDTO>;
  NOT?: InputMaybe<InsurancePracticeFilterDTO>;
  OR?: InputMaybe<Array<InsurancePracticeFilterDTO>>;
  commodityCode?: InputMaybe<TypeOfInsuranceCommodityFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  practiceAbbreviation?: InputMaybe<NullableStringFilter>;
  practiceCode?: InputMaybe<StringFilter>;
  programType?: InputMaybe<TypeOfInsuranceProgramFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  startDate?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsurancePracticeGroupByDTO = {
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  practiceAbbreviation?: InputMaybe<Scalars['Boolean']>;
  practiceCode?: InputMaybe<Scalars['Boolean']>;
  programType?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsurancePracticeSortByDTO = {
  commodityCode?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  endDate?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  practiceAbbreviation?: InputMaybe<NullableSortByDirection>;
  practiceCode?: InputMaybe<SortByDirection>;
  programType?: InputMaybe<SortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InsuranceRatioScenarioPricingFixedPriceInput = {
  fixedPricesByProduct: Array<InsuranceScenarioPricingFixedPricesByProductInput>;
  /** A filter used to determine the endorsement ratios for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
  insuranceEndorsementAllocationRatioFilter: InsuranceEndorsementAllocationRatioFilterDTO;
};

export type InsuranceRatioScenarioPricingFixedPriceSetsInput = {
  fixedPriceSets: Array<InsuranceScenarioPricingFixedPriceSetInput>;
  /** A filter used to determine the endorsement ratios for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
  insuranceEndorsementAllocationRatioFilter: InsuranceEndorsementAllocationRatioFilterDTO;
};

export type InsuranceRatioScenarioPricingFuturesCurveInput = {
  futuresCurve: Array<FuturesCurvePointInput>;
  /** A filter used to determine the endorsements for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
  insuranceEndorsementAllocationRatioFilter: InsuranceEndorsementAllocationRatioFilterDTO;
};

export type InsuranceRatioScenarioPricingOutput = {
  __typename?: 'InsuranceRatioScenarioPricingOutput';
  perEndorsementRatioPnl: Array<InsuranceRatioScenarioPricingPerEndorsementPnlOutput>;
  totalPnl: Scalars['Float'];
};

export type InsuranceRatioScenarioPricingPerEndorsementPnlOutput = {
  __typename?: 'InsuranceRatioScenarioPricingPerEndorsementPnlOutput';
  date: Scalars['String'];
  indemnity: Scalars['Float'];
  insuranceEndorsementAllocationRatioId: Scalars['String'];
  insuranceEndorsementId: Scalars['String'];
  insuranceEndorsementType: TypeOfInsuranceEndorsement;
  pnl: Scalars['Float'];
};

export type InsuranceScenarioPricingFixedPriceInput = {
  fixedPricesByProduct: Array<InsuranceScenarioPricingFixedPricesByProductInput>;
  /** A filter used to determine the endorsements for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
  insuranceEndorsementFilter: InsuranceEndorsementFilterDTO;
};

export type InsuranceScenarioPricingFixedPriceSetInput = {
  fixedPricesByProduct: Array<InsuranceScenarioPricingFixedPricesByProductInput>;
  /** An identifier of this price set that will be included in the associated result entry. This value must be unique within the input payload. */
  identifier: Scalars['String'];
};

export type InsuranceScenarioPricingFixedPriceSetsInput = {
  fixedPriceSets: Array<InsuranceScenarioPricingFixedPriceSetInput>;
  /** A filter used to determine the endorsements for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
  insuranceEndorsementFilter: InsuranceEndorsementFilterDTO;
};

export type InsuranceScenarioPricingFixedPricesByProductInput = {
  /** The price in this scenario calculation for this product across all futures months. This value should be in the barchart market data magnitude */
  price: Scalars['Float'];
  productId: Scalars['String'];
};

export type InsuranceScenarioPricingFuturesCurveInput = {
  futuresCurve: Array<FuturesCurvePointInput>;
  /** A filter used to determine the endorsements for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
  insuranceEndorsementFilter: InsuranceEndorsementFilterDTO;
};

export type InsuranceScenarioPricingOutput = {
  __typename?: 'InsuranceScenarioPricingOutput';
  perEndorsementPnl: Array<InsuranceScenarioPricingPerEndorsementPnlOutput>;
  totalPnl: Scalars['Float'];
};

export type InsuranceScenarioPricingPerEndorsementPnlOutput = {
  __typename?: 'InsuranceScenarioPricingPerEndorsementPnlOutput';
  indemnity: Scalars['Float'];
  insuranceEndorsementId: Scalars['String'];
  insuranceEndorsementType: TypeOfInsuranceEndorsement;
  pnl: Scalars['Float'];
};

export type InsuredYieldAndPrice = {
  __typename?: 'InsuredYieldAndPrice';
  InsurancePractice: InsurancePractice;
  actualYield?: Maybe<Scalars['Float']>;
  classPriceWeightingFactorRestrictedValue?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactorRestrictedValue?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedClassIiiPrice?: Maybe<Scalars['Float']>;
  expectedClassIvPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedYield: Scalars['Float'];
  id: Scalars['ID'];
  /** @deprecated Use "updatedAt" instead */
  lastUpdated: Scalars['DateTime'];
  lastYield: Scalars['Float'];
  lastYieldReleasedDate: Scalars['String'];
  practiceCode: Scalars['String'];
  practiceId: Scalars['String'];
  reinsuranceYear: Scalars['Int'];
  salesEffectiveDate: Scalars['String'];
  stateId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InsuredYieldAndPriceDistinctOnDTO = {
  actualYield?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactorRestrictedValue?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactorRestrictedValue?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIvPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedYield?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastUpdated?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  lastYieldReleasedDate?: InputMaybe<Scalars['Boolean']>;
  practiceCode?: InputMaybe<Scalars['Boolean']>;
  practiceId?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuredYieldAndPriceFilterDTO = {
  AND?: InputMaybe<Array<InsuredYieldAndPriceFilterDTO>>;
  DrpInsuranceEndorsement?: InputMaybe<DrpInsuranceEndorsementFilterDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeFilterDTO>;
  NOT?: InputMaybe<InsuredYieldAndPriceFilterDTO>;
  OR?: InputMaybe<Array<InsuredYieldAndPriceFilterDTO>>;
  actualYield?: InputMaybe<NullableNumberFilter>;
  classPriceWeightingFactorRestrictedValue?: InputMaybe<NullableNumberFilter>;
  componentPriceWeightingFactorRestrictedValue?: InputMaybe<NullableNumberFilter>;
  expectedButterfatPrice?: InputMaybe<NullableNumberFilter>;
  expectedClassIiiPrice?: InputMaybe<NullableNumberFilter>;
  expectedClassIvPrice?: InputMaybe<NullableNumberFilter>;
  expectedNonfatSolidsPrice?: InputMaybe<NullableNumberFilter>;
  expectedOtherSolidsPrice?: InputMaybe<NullableNumberFilter>;
  expectedProteinPrice?: InputMaybe<NullableNumberFilter>;
  expectedYield?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  lastUpdated?: InputMaybe<DateTimeFilter>;
  lastYield?: InputMaybe<NumberFilter>;
  lastYieldReleasedDate?: InputMaybe<StringFilter>;
  practiceCode?: InputMaybe<StringFilter>;
  practiceId?: InputMaybe<StringFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  salesEffectiveDate?: InputMaybe<StringFilter>;
  stateId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InsuredYieldAndPriceGroupByDTO = {
  InsurancePractice?: InputMaybe<InsurancePracticeGroupByDTO>;
  actualYield?: InputMaybe<Scalars['Boolean']>;
  classPriceWeightingFactorRestrictedValue?: InputMaybe<Scalars['Boolean']>;
  componentPriceWeightingFactorRestrictedValue?: InputMaybe<Scalars['Boolean']>;
  expectedButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIiiPrice?: InputMaybe<Scalars['Boolean']>;
  expectedClassIvPrice?: InputMaybe<Scalars['Boolean']>;
  expectedNonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedOtherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  expectedProteinPrice?: InputMaybe<Scalars['Boolean']>;
  expectedYield?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastUpdated?: InputMaybe<Scalars['Boolean']>;
  lastYield?: InputMaybe<Scalars['Boolean']>;
  lastYieldReleasedDate?: InputMaybe<Scalars['Boolean']>;
  practiceCode?: InputMaybe<Scalars['Boolean']>;
  practiceId?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InsuredYieldAndPriceSortByDTO = {
  InsurancePractice?: InputMaybe<InsurancePracticeSortByDTO>;
  actualYield?: InputMaybe<NullableSortByDirection>;
  classPriceWeightingFactorRestrictedValue?: InputMaybe<NullableSortByDirection>;
  componentPriceWeightingFactorRestrictedValue?: InputMaybe<NullableSortByDirection>;
  expectedButterfatPrice?: InputMaybe<NullableSortByDirection>;
  expectedClassIiiPrice?: InputMaybe<NullableSortByDirection>;
  expectedClassIvPrice?: InputMaybe<NullableSortByDirection>;
  expectedNonfatSolidsPrice?: InputMaybe<NullableSortByDirection>;
  expectedOtherSolidsPrice?: InputMaybe<NullableSortByDirection>;
  expectedProteinPrice?: InputMaybe<NullableSortByDirection>;
  expectedYield?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  lastUpdated?: InputMaybe<SortByDirection>;
  lastYield?: InputMaybe<SortByDirection>;
  lastYieldReleasedDate?: InputMaybe<SortByDirection>;
  practiceCode?: InputMaybe<SortByDirection>;
  practiceId?: InputMaybe<SortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  salesEffectiveDate?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type IntegrationMapping = {
  __typename?: 'IntegrationMapping';
  Account: IAccount;
  accountId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  emailId: Scalars['String'];
  fileType: TypeOfAccountFile;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  parserType: TypeOfPartnerIntegrationParser;
  updatedAt: Scalars['DateTime'];
};

export type IntegrationMappingDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  emailId?: InputMaybe<Scalars['Boolean']>;
  fileType?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  parserType?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type IntegrationMappingFilterDTO = {
  AND?: InputMaybe<Array<IntegrationMappingFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  NOT?: InputMaybe<IntegrationMappingFilterDTO>;
  OR?: InputMaybe<Array<IntegrationMappingFilterDTO>>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  emailId?: InputMaybe<StringFilter>;
  fileType?: InputMaybe<TypeOfAccountFileFilter>;
  id?: InputMaybe<StringFilter>;
  parserType?: InputMaybe<TypeOfPartnerIntegrationParserFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type IntegrationMappingSortByDTO = {
  Account?: InputMaybe<AccountSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  emailId?: InputMaybe<SortByDirection>;
  fileType?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  parserType?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InventoryLevel = {
  __typename?: 'InventoryLevel';
  Location: LocationEntity;
  Product: Product;
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  locationId: Scalars['String'];
  productId: Scalars['String'];
  quantity: Scalars['Float'];
  timestamp: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type InventoryLevelAggregateAllDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  timestamp?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryLevelAggregateDTO = {
  avg?: InputMaybe<InventoryLevelAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<InventoryLevelAggregateAllDTO>;
  min?: InputMaybe<InventoryLevelAggregateAllDTO>;
  sum?: InputMaybe<InventoryLevelAggregateNumericDTO>;
};

export type InventoryLevelAggregateNumericDTO = {
  quantity?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryLevelCreateDTO = {
  locationId: Scalars['String'];
  productId: Scalars['String'];
  quantity: Scalars['Float'];
  timestamp?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryLevelDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  timestamp?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryLevelFilterDTO = {
  AND?: InputMaybe<Array<InventoryLevelFilterDTO>>;
  Location?: InputMaybe<LocationEntityFilterDTO>;
  NOT?: InputMaybe<InventoryLevelFilterDTO>;
  OR?: InputMaybe<Array<InventoryLevelFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  locationId?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<NumberFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InventoryLevelGroupByDTO = {
  Location?: InputMaybe<LocationEntityGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  timestamp?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryLevelSortByDTO = {
  Location?: InputMaybe<LocationEntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  locationId?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  timestamp?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type InventoryLevelUpdateDTO = {
  locationId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};

export type LedgerActualEntry = ILedgerEntry & {
  __typename?: 'LedgerActualEntry';
  Entity: IEntity;
  LedgerCategory: ILedgerCategory;
  /** The input ledger entry value. For static entries, this is the value that will be returned for 'calculatedAmount'. For dynamic entries, this value will be multipled by 'calculationQuantity' to determine 'calculatedAmount'. */
  amount: Scalars['Float'];
  /** For static entries, this value will be identical to the 'amount' value. For dynamic entries, this value will be the product of 'amount' & 'calculationQuantity'. */
  calculatedAmount: Scalars['Float'];
  /** The total quantity from all head/cwt/volume belonging to the same entity or child entities. This value is multiplied against 'amount' for dynamic ledger entries. If this value is NULL, the ledger entry is static and the given 'amount' will be returned from 'calculatedAmount'. */
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  type: TypeOfLedgerEntry;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type LedgerActualEntryAggregateAllDTO = {
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerActualEntryAggregateDTO = {
  avg?: InputMaybe<LedgerActualEntryAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<LedgerActualEntryAggregateAllDTO>;
  min?: InputMaybe<LedgerActualEntryAggregateAllDTO>;
  sum?: InputMaybe<LedgerActualEntryAggregateNumericDTO>;
};

export type LedgerActualEntryAggregateNumericDTO = {
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerActualEntryDistinctOnDTO = {
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerActualEntryFilterDTO = {
  AND?: InputMaybe<Array<LedgerActualEntryFilterDTO>>;
  AsLedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  Entity?: InputMaybe<EntityFilterDTO>;
  LedgerCategory?: InputMaybe<LedgerCategoryFilterDTO>;
  NOT?: InputMaybe<LedgerActualEntryFilterDTO>;
  OR?: InputMaybe<Array<LedgerActualEntryFilterDTO>>;
  amount?: InputMaybe<NumberFilter>;
  calculatedAmount?: InputMaybe<NumberFilter>;
  calculationQuantity?: InputMaybe<NullableNumberFilter>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type LedgerActualEntryGroupByDTO = {
  AsLedgerEntry?: InputMaybe<LedgerEntryGroupByDTO>;
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  LedgerCategory?: InputMaybe<LedgerCategoryGroupByDTO>;
  LedgerCategoryAsLedgerExpenseCategory?: InputMaybe<LedgerExpenseCategoryGroupByDTO>;
  LedgerCategoryAsLedgerFeedCategory?: InputMaybe<LedgerFeedCategoryGroupByDTO>;
  LedgerCategoryAsLedgerMilkCheckCategory?: InputMaybe<LedgerMilkCheckCategoryGroupByDTO>;
  LedgerCategoryAsLedgerRevenueCategory?: InputMaybe<LedgerRevenueCategoryGroupByDTO>;
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerActualEntrySortByDTO = {
  AsLedgerEntry?: InputMaybe<LedgerEntrySortByDTO>;
  Entity?: InputMaybe<EntitySortByDTO>;
  LedgerCategory?: InputMaybe<LedgerCategorySortByDTO>;
  amount?: InputMaybe<SortByDirection>;
  calculatedAmount?: InputMaybe<SortByDirection>;
  calculationQuantity?: InputMaybe<NullableSortByDirection>;
  categoryId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type LedgerCategory = ILedgerCategory & {
  __typename?: 'LedgerCategory';
  Customer: CustomerEntity;
  LedgerEntries: Array<ILedgerEntry>;
  /** The type of value against which the ledger entry values will be computed. If left null, all entries will be assumed to be "static" values. */
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** A flag denoting whether or not all ledger entries for this category are static or dynamic */
  isStatic: Scalars['Boolean'];
  name: Scalars['String'];
  type: TypeOfLedgerCategory;
  updatedAt: Scalars['DateTime'];
};


export type LedgerCategory_LedgerEntriesArgs = {
  orderBy?: InputMaybe<LedgerEntrySortByDTO>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};

export type LedgerCategoryDistinctOnDTO = {
  calculationType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerCategoryFilterDTO = {
  AND?: InputMaybe<Array<LedgerCategoryFilterDTO>>;
  AsLedgerExpenseCategory?: InputMaybe<LedgerExpenseCategoryFilterDTO>;
  AsLedgerFeedCategory?: InputMaybe<LedgerFeedCategoryFilterDTO>;
  AsLedgerMilkCheckCategory?: InputMaybe<LedgerMilkCheckCategoryFilterDTO>;
  AsLedgerRevenueCategory?: InputMaybe<LedgerRevenueCategoryFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  LedgerEntries?: InputMaybe<LedgerEntryFilterDTO>;
  LedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  NOT?: InputMaybe<LedgerCategoryFilterDTO>;
  OR?: InputMaybe<Array<LedgerCategoryFilterDTO>>;
  calculationType?: InputMaybe<NullableTypeOfLedgerCalculationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isStatic?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<TypeOfLedgerCategoryFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LedgerCategoryGroupByDTO = {
  AsLedgerExpenseCategory?: InputMaybe<LedgerExpenseCategoryGroupByDTO>;
  AsLedgerFeedCategory?: InputMaybe<LedgerFeedCategoryGroupByDTO>;
  AsLedgerMilkCheckCategory?: InputMaybe<LedgerMilkCheckCategoryGroupByDTO>;
  AsLedgerRevenueCategory?: InputMaybe<LedgerRevenueCategoryGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  calculationType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerCategorySortByDTO = {
  AsLedgerExpenseCategory?: InputMaybe<LedgerExpenseCategorySortByDTO>;
  AsLedgerFeedCategory?: InputMaybe<LedgerFeedCategorySortByDTO>;
  AsLedgerMilkCheckCategory?: InputMaybe<LedgerMilkCheckCategorySortByDTO>;
  AsLedgerRevenueCategory?: InputMaybe<LedgerRevenueCategorySortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  calculationType?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isStatic?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LedgerEntry = ILedgerEntry & {
  __typename?: 'LedgerEntry';
  Entity: IEntity;
  LedgerCategory: ILedgerCategory;
  /** The input ledger entry value. For static entries, this is the value that will be returned for 'calculatedAmount'. For dynamic entries, this value will be multipled by 'calculationQuantity' to determine 'calculatedAmount'. */
  amount: Scalars['Float'];
  /** For static entries, this value will be identical to the 'amount' value. For dynamic entries, this value will be the product of 'amount' & 'calculationQuantity'. */
  calculatedAmount: Scalars['Float'];
  /** The total quantity from all head/cwt/volume belonging to the same entity or child entities. This value is multiplied against 'amount' for dynamic ledger entries. If this value is NULL, the ledger entry is static and the given 'amount' will be returned from 'calculatedAmount'. */
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  type: TypeOfLedgerEntry;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type LedgerEntryAggregateAllDTO = {
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerEntryAggregateDTO = {
  avg?: InputMaybe<LedgerEntryAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<LedgerEntryAggregateAllDTO>;
  min?: InputMaybe<LedgerEntryAggregateAllDTO>;
  sum?: InputMaybe<LedgerEntryAggregateNumericDTO>;
};

export type LedgerEntryAggregateNumericDTO = {
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerEntryDistinctOnDTO = {
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerEntryFilterDTO = {
  AND?: InputMaybe<Array<LedgerEntryFilterDTO>>;
  AsLedgerActualEntry?: InputMaybe<LedgerActualEntryFilterDTO>;
  AsLedgerForecastedEntry?: InputMaybe<LedgerForecastedEntryFilterDTO>;
  Entity?: InputMaybe<EntityFilterDTO>;
  LedgerCategory?: InputMaybe<LedgerCategoryFilterDTO>;
  NOT?: InputMaybe<LedgerEntryFilterDTO>;
  OR?: InputMaybe<Array<LedgerEntryFilterDTO>>;
  amount?: InputMaybe<NumberFilter>;
  calculatedAmount?: InputMaybe<NumberFilter>;
  calculationQuantity?: InputMaybe<NullableNumberFilter>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  type?: InputMaybe<TypeOfLedgerEntryFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type LedgerEntryGroupByDTO = {
  AsLedgerActualEntry?: InputMaybe<LedgerActualEntryGroupByDTO>;
  AsLedgerForecastedEntry?: InputMaybe<LedgerForecastedEntryGroupByDTO>;
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  LedgerCategory?: InputMaybe<LedgerCategoryGroupByDTO>;
  LedgerCategoryAsLedgerExpenseCategory?: InputMaybe<LedgerExpenseCategoryGroupByDTO>;
  LedgerCategoryAsLedgerFeedCategory?: InputMaybe<LedgerFeedCategoryGroupByDTO>;
  LedgerCategoryAsLedgerMilkCheckCategory?: InputMaybe<LedgerMilkCheckCategoryGroupByDTO>;
  LedgerCategoryAsLedgerRevenueCategory?: InputMaybe<LedgerRevenueCategoryGroupByDTO>;
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerEntrySetByMonth = {
  categoryId: Scalars['String'];
  datesAndAmounts: Array<LedgerEntrySetByMonthDateAndAmount>;
  description?: InputMaybe<Scalars['String']>;
  entityId: Scalars['String'];
};

export type LedgerEntrySetByMonthDateAndAmount = {
  /** The total amount for the given month. */
  amount: Scalars['Float'];
  /** The first date of month for which this amount will be set. */
  date: Scalars['String'];
};

export type LedgerEntrySetDTO = {
  amount: Scalars['Float'];
  categoryId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['String'];
  entityId: Scalars['String'];
  increment: DateIncrement;
  startDate: Scalars['String'];
};

export type LedgerEntrySortByDTO = {
  AsLedgerActualEntry?: InputMaybe<LedgerActualEntrySortByDTO>;
  AsLedgerForecastedEntry?: InputMaybe<LedgerForecastedEntrySortByDTO>;
  Entity?: InputMaybe<EntitySortByDTO>;
  LedgerCategory?: InputMaybe<LedgerCategorySortByDTO>;
  amount?: InputMaybe<SortByDirection>;
  calculatedAmount?: InputMaybe<SortByDirection>;
  calculationQuantity?: InputMaybe<NullableSortByDirection>;
  categoryId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

export type LedgerExpenseCategory = ILedgerCategory & {
  __typename?: 'LedgerExpenseCategory';
  Customer: CustomerEntity;
  LedgerEntries: Array<ILedgerEntry>;
  /** The type of value against which the ledger entry values will be computed. If left null, all entries will be assumed to be "static" values. */
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** A flag denoting whether or not all ledger entries for this category are static or dynamic */
  isStatic: Scalars['Boolean'];
  name: Scalars['String'];
  type: TypeOfLedgerCategory;
  updatedAt: Scalars['DateTime'];
};


export type LedgerExpenseCategory_LedgerEntriesArgs = {
  orderBy?: InputMaybe<LedgerEntrySortByDTO>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};

export type LedgerExpenseCategoryCreateDTO = {
  calculationType?: InputMaybe<TypeOfLedgerCalculation>;
  customerId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type LedgerExpenseCategoryDistinctOnDTO = {
  calculationType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerExpenseCategoryFilterDTO = {
  AND?: InputMaybe<Array<LedgerExpenseCategoryFilterDTO>>;
  AsLedgerCategory?: InputMaybe<LedgerCategoryFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  LedgerEntries?: InputMaybe<LedgerEntryFilterDTO>;
  LedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  NOT?: InputMaybe<LedgerExpenseCategoryFilterDTO>;
  OR?: InputMaybe<Array<LedgerExpenseCategoryFilterDTO>>;
  calculationType?: InputMaybe<NullableTypeOfLedgerCalculationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isStatic?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LedgerExpenseCategoryGroupByDTO = {
  AsLedgerCategory?: InputMaybe<LedgerCategoryGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  calculationType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerExpenseCategorySortByDTO = {
  AsLedgerCategory?: InputMaybe<LedgerCategorySortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  calculationType?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isStatic?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LedgerExpenseCategoryUpdateDTO = {
  customerId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Ledger categories representing feed deductions and premiums */
export type LedgerFeedCategory = ILedgerCategory & {
  __typename?: 'LedgerFeedCategory';
  Customer: CustomerEntity;
  LedgerEntries: Array<ILedgerEntry>;
  /** The type of value against which the ledger entry values will be computed. If left null, all entries will be assumed to be "static" values. */
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** A flag denoting whether or not all ledger entries for this category are static or dynamic */
  isStatic: Scalars['Boolean'];
  name: Scalars['String'];
  type: TypeOfLedgerCategory;
  updatedAt: Scalars['DateTime'];
};


/** Ledger categories representing feed deductions and premiums */
export type LedgerFeedCategory_LedgerEntriesArgs = {
  orderBy?: InputMaybe<LedgerEntrySortByDTO>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};

export type LedgerFeedCategoryCreateDTO = {
  calculationType?: InputMaybe<TypeOfLedgerCalculation>;
  customerId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type LedgerFeedCategoryDistinctOnDTO = {
  calculationType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerFeedCategoryFilterDTO = {
  AND?: InputMaybe<Array<LedgerFeedCategoryFilterDTO>>;
  AsLedgerCategory?: InputMaybe<LedgerCategoryFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  LedgerEntries?: InputMaybe<LedgerEntryFilterDTO>;
  LedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  NOT?: InputMaybe<LedgerFeedCategoryFilterDTO>;
  OR?: InputMaybe<Array<LedgerFeedCategoryFilterDTO>>;
  calculationType?: InputMaybe<NullableTypeOfLedgerCalculationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isStatic?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LedgerFeedCategoryGroupByDTO = {
  AsLedgerCategory?: InputMaybe<LedgerCategoryGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  calculationType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerFeedCategorySortByDTO = {
  AsLedgerCategory?: InputMaybe<LedgerCategorySortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  calculationType?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isStatic?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LedgerFeedCategoryUpdateDTO = {
  customerId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LedgerForecastedEntry = ILedgerEntry & {
  __typename?: 'LedgerForecastedEntry';
  Entity: IEntity;
  LedgerCategory: ILedgerCategory;
  /** The input ledger entry value. For static entries, this is the value that will be returned for 'calculatedAmount'. For dynamic entries, this value will be multipled by 'calculationQuantity' to determine 'calculatedAmount'. */
  amount: Scalars['Float'];
  /** For static entries, this value will be identical to the 'amount' value. For dynamic entries, this value will be the product of 'amount' & 'calculationQuantity'. */
  calculatedAmount: Scalars['Float'];
  /** The total quantity from all head/cwt/volume belonging to the same entity or child entities. This value is multiplied against 'amount' for dynamic ledger entries. If this value is NULL, the ledger entry is static and the given 'amount' will be returned from 'calculatedAmount'. */
  calculationQuantity?: Maybe<Scalars['Float']>;
  categoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  month: Scalars['Int'];
  /** An integer 1-4 representing the quarter in which the date resides */
  quarter: Scalars['Int'];
  type: TypeOfLedgerEntry;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};

export type LedgerForecastedEntryAggregateAllDTO = {
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerForecastedEntryAggregateDTO = {
  avg?: InputMaybe<LedgerForecastedEntryAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<LedgerForecastedEntryAggregateAllDTO>;
  min?: InputMaybe<LedgerForecastedEntryAggregateAllDTO>;
  sum?: InputMaybe<LedgerForecastedEntryAggregateNumericDTO>;
};

export type LedgerForecastedEntryAggregateNumericDTO = {
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerForecastedEntryDistinctOnDTO = {
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerForecastedEntryFilterDTO = {
  AND?: InputMaybe<Array<LedgerForecastedEntryFilterDTO>>;
  AsLedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  Entity?: InputMaybe<EntityFilterDTO>;
  LedgerCategory?: InputMaybe<LedgerCategoryFilterDTO>;
  NOT?: InputMaybe<LedgerForecastedEntryFilterDTO>;
  OR?: InputMaybe<Array<LedgerForecastedEntryFilterDTO>>;
  amount?: InputMaybe<NumberFilter>;
  calculatedAmount?: InputMaybe<NumberFilter>;
  calculationQuantity?: InputMaybe<NullableNumberFilter>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  month?: InputMaybe<NumberFilter>;
  quarter?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<NumberFilter>;
};

export type LedgerForecastedEntryGroupByDTO = {
  AsLedgerEntry?: InputMaybe<LedgerEntryGroupByDTO>;
  Entity?: InputMaybe<EntityGroupByDTO>;
  EntityAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  EntityAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  EntityAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  LedgerCategory?: InputMaybe<LedgerCategoryGroupByDTO>;
  LedgerCategoryAsLedgerExpenseCategory?: InputMaybe<LedgerExpenseCategoryGroupByDTO>;
  LedgerCategoryAsLedgerFeedCategory?: InputMaybe<LedgerFeedCategoryGroupByDTO>;
  LedgerCategoryAsLedgerMilkCheckCategory?: InputMaybe<LedgerMilkCheckCategoryGroupByDTO>;
  LedgerCategoryAsLedgerRevenueCategory?: InputMaybe<LedgerRevenueCategoryGroupByDTO>;
  amount?: InputMaybe<Scalars['Boolean']>;
  calculatedAmount?: InputMaybe<Scalars['Boolean']>;
  calculationQuantity?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<Scalars['Boolean']>;
  quarter?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  year?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerForecastedEntrySortByDTO = {
  AsLedgerEntry?: InputMaybe<LedgerEntrySortByDTO>;
  Entity?: InputMaybe<EntitySortByDTO>;
  LedgerCategory?: InputMaybe<LedgerCategorySortByDTO>;
  amount?: InputMaybe<SortByDirection>;
  calculatedAmount?: InputMaybe<SortByDirection>;
  calculationQuantity?: InputMaybe<NullableSortByDirection>;
  categoryId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  month?: InputMaybe<SortByDirection>;
  quarter?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  year?: InputMaybe<SortByDirection>;
};

/** Ledger categories representing milk check deductions and premiums */
export type LedgerMilkCheckCategory = ILedgerCategory & {
  __typename?: 'LedgerMilkCheckCategory';
  Customer: CustomerEntity;
  LedgerEntries: Array<ILedgerEntry>;
  /** The type of value against which the ledger entry values will be computed. If left null, all entries will be assumed to be "static" values. */
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** A flag denoting whether or not all ledger entries for this category are static or dynamic */
  isStatic: Scalars['Boolean'];
  name: Scalars['String'];
  type: TypeOfLedgerCategory;
  updatedAt: Scalars['DateTime'];
};


/** Ledger categories representing milk check deductions and premiums */
export type LedgerMilkCheckCategory_LedgerEntriesArgs = {
  orderBy?: InputMaybe<LedgerEntrySortByDTO>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};

export type LedgerMilkCheckCategoryCreateDTO = {
  calculationType?: InputMaybe<TypeOfLedgerCalculation>;
  customerId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type LedgerMilkCheckCategoryDistinctOnDTO = {
  calculationType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerMilkCheckCategoryFilterDTO = {
  AND?: InputMaybe<Array<LedgerMilkCheckCategoryFilterDTO>>;
  AsLedgerCategory?: InputMaybe<LedgerCategoryFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  LedgerEntries?: InputMaybe<LedgerEntryFilterDTO>;
  LedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  NOT?: InputMaybe<LedgerMilkCheckCategoryFilterDTO>;
  OR?: InputMaybe<Array<LedgerMilkCheckCategoryFilterDTO>>;
  calculationType?: InputMaybe<NullableTypeOfLedgerCalculationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isStatic?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LedgerMilkCheckCategoryGroupByDTO = {
  AsLedgerCategory?: InputMaybe<LedgerCategoryGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  calculationType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerMilkCheckCategorySortByDTO = {
  AsLedgerCategory?: InputMaybe<LedgerCategorySortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  calculationType?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isStatic?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LedgerMilkCheckCategoryUpdateDTO = {
  customerId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LedgerRevenueCategory = ILedgerCategory & {
  __typename?: 'LedgerRevenueCategory';
  Customer: CustomerEntity;
  LedgerEntries: Array<ILedgerEntry>;
  /** The type of value against which the ledger entry values will be computed. If left null, all entries will be assumed to be "static" values. */
  calculationType?: Maybe<TypeOfLedgerCalculation>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** A flag denoting whether or not all ledger entries for this category are static or dynamic */
  isStatic: Scalars['Boolean'];
  name: Scalars['String'];
  type: TypeOfLedgerCategory;
  updatedAt: Scalars['DateTime'];
};


export type LedgerRevenueCategory_LedgerEntriesArgs = {
  orderBy?: InputMaybe<LedgerEntrySortByDTO>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};

export type LedgerRevenueCategoryCreateDTO = {
  calculationType?: InputMaybe<TypeOfLedgerCalculation>;
  customerId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type LedgerRevenueCategoryDistinctOnDTO = {
  calculationType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerRevenueCategoryFilterDTO = {
  AND?: InputMaybe<Array<LedgerRevenueCategoryFilterDTO>>;
  AsLedgerCategory?: InputMaybe<LedgerCategoryFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  LedgerEntries?: InputMaybe<LedgerEntryFilterDTO>;
  LedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  NOT?: InputMaybe<LedgerRevenueCategoryFilterDTO>;
  OR?: InputMaybe<Array<LedgerRevenueCategoryFilterDTO>>;
  calculationType?: InputMaybe<NullableTypeOfLedgerCalculationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isStatic?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LedgerRevenueCategoryGroupByDTO = {
  AsLedgerCategory?: InputMaybe<LedgerCategoryGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  calculationType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LedgerRevenueCategorySortByDTO = {
  AsLedgerCategory?: InputMaybe<LedgerCategorySortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  calculationType?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isStatic?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LedgerRevenueCategoryUpdateDTO = {
  customerId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LegacyFeedFilterDTO = {
  AND?: InputMaybe<Array<LegacyFeedFilterDTO>>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<LegacyFeedFilterDTO>;
  OR?: InputMaybe<Array<LegacyFeedFilterDTO>>;
  basisInstrumentId?: InputMaybe<NullableStringFilter>;
  costType?: InputMaybe<NullableStringFilter>;
  cowCategoryId?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<NullableDateTimeFilter>;
  feedCategory?: InputMaybe<NullableStringFilter>;
  feedRollover?: InputMaybe<NullableStringFilter>;
  feedTypeId?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<NumberFilter>;
  ingredientId?: InputMaybe<NullableNumberFilter>;
  monthId?: InputMaybe<NullableNumberFilter>;
  priceInTons?: InputMaybe<NullableNumberFilter>;
  updatedAt?: InputMaybe<NullableDateTimeFilter>;
  usageInTons?: InputMaybe<NullableNumberFilter>;
  velaBasisInstrumentId?: InputMaybe<NullableNumberFilter>;
};

export type LegacyScenarioDataFilterDTO = {
  AND?: InputMaybe<Array<LegacyScenarioDataFilterDTO>>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<LegacyScenarioDataFilterDTO>;
  OR?: InputMaybe<Array<LegacyScenarioDataFilterDTO>>;
  createdAt?: InputMaybe<NullableDateTimeFilter>;
  id?: InputMaybe<NumberFilter>;
  instrumentId?: InputMaybe<NullableStringFilter>;
  price?: InputMaybe<NullableNumberFilter>;
  updatedAt?: InputMaybe<NullableDateTimeFilter>;
  userId?: InputMaybe<NullableNumberFilter>;
  velaInstrumentId?: InputMaybe<NullableNumberFilter>;
};

export type LegacyUserFilterDTO = {
  AND?: InputMaybe<Array<LegacyUserFilterDTO>>;
  NOT?: InputMaybe<LegacyUserFilterDTO>;
  OR?: InputMaybe<Array<LegacyUserFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  acceptedEulaAt?: InputMaybe<NullableDateTimeFilter>;
  active?: InputMaybe<NullableBooleanFilter>;
  baseAndOverbase?: InputMaybe<NullableNumberFilter>;
  basisType?: InputMaybe<NullableNumberFilter>;
  canTrade?: InputMaybe<NullableBooleanFilter>;
  costName0?: InputMaybe<NullableStringFilter>;
  costName1?: InputMaybe<NullableStringFilter>;
  costName2?: InputMaybe<NullableStringFilter>;
  costName3?: InputMaybe<NullableStringFilter>;
  costName4?: InputMaybe<NullableStringFilter>;
  costName5?: InputMaybe<NullableStringFilter>;
  costName6?: InputMaybe<NullableStringFilter>;
  costName7?: InputMaybe<NullableStringFilter>;
  costName8?: InputMaybe<NullableStringFilter>;
  costName9?: InputMaybe<NullableStringFilter>;
  costName10?: InputMaybe<NullableStringFilter>;
  costName11?: InputMaybe<NullableStringFilter>;
  costName12?: InputMaybe<NullableStringFilter>;
  costName13?: InputMaybe<NullableStringFilter>;
  costName14?: InputMaybe<NullableStringFilter>;
  costName15?: InputMaybe<NullableStringFilter>;
  costName16?: InputMaybe<NullableStringFilter>;
  costName17?: InputMaybe<NullableStringFilter>;
  costName18?: InputMaybe<NullableStringFilter>;
  costName19?: InputMaybe<NullableStringFilter>;
  costName20?: InputMaybe<NullableStringFilter>;
  costName21?: InputMaybe<NullableStringFilter>;
  costName22?: InputMaybe<NullableStringFilter>;
  costName23?: InputMaybe<NullableStringFilter>;
  costName24?: InputMaybe<NullableStringFilter>;
  costName25?: InputMaybe<NullableStringFilter>;
  costName26?: InputMaybe<NullableStringFilter>;
  costName27?: InputMaybe<NullableStringFilter>;
  costName28?: InputMaybe<NullableStringFilter>;
  costName29?: InputMaybe<NullableStringFilter>;
  costName30?: InputMaybe<NullableStringFilter>;
  costName31?: InputMaybe<NullableStringFilter>;
  costName32?: InputMaybe<NullableStringFilter>;
  costName33?: InputMaybe<NullableStringFilter>;
  costName34?: InputMaybe<NullableStringFilter>;
  costName35?: InputMaybe<NullableStringFilter>;
  costName36?: InputMaybe<NullableStringFilter>;
  costName37?: InputMaybe<NullableStringFilter>;
  costName38?: InputMaybe<NullableStringFilter>;
  costName39?: InputMaybe<NullableStringFilter>;
  costNameF?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<NullableDateTimeFilter>;
  email?: InputMaybe<NullableStringFilter>;
  firstName?: InputMaybe<NullableStringFilter>;
  fmmoDairyPrices?: InputMaybe<NullableBooleanFilter>;
  id?: InputMaybe<NumberFilter>;
  isActive?: InputMaybe<NullableBooleanFilter>;
  isAdmin?: InputMaybe<NullableBooleanFilter>;
  isTrial?: InputMaybe<BooleanFilter>;
  isTrialExpired?: InputMaybe<BooleanFilter>;
  lastName?: InputMaybe<NullableStringFilter>;
  locationId?: InputMaybe<NullableNumberFilter>;
  manualClassOneDifferential?: InputMaybe<NullableNumberFilter>;
  optionshopId?: InputMaybe<NullableNumberFilter>;
  permissions?: InputMaybe<NullableStringFilter>;
  phone?: InputMaybe<NullableStringFilter>;
  pricingModel?: InputMaybe<NullableStringFilter>;
  quota?: InputMaybe<NullableNumberFilter>;
  regionId?: InputMaybe<NullableNumberFilter>;
  revenueNameA?: InputMaybe<NullableStringFilter>;
  revenueNameB?: InputMaybe<NullableStringFilter>;
  revenueNameC?: InputMaybe<NullableStringFilter>;
  revenueNameD?: InputMaybe<NullableStringFilter>;
  revenueNameE?: InputMaybe<NullableStringFilter>;
  revenueNameF?: InputMaybe<NullableStringFilter>;
  revenueNameG?: InputMaybe<NullableStringFilter>;
  selectProducerId?: InputMaybe<NullableStringFilter>;
  stripeCustomerId?: InputMaybe<NullableStringFilter>;
  uiStyle?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<NullableDateTimeFilter>;
  vaultBasis?: InputMaybe<NullableNumberFilter>;
  vaultBasisValue?: InputMaybe<NullableNumberFilter>;
  vaultId?: InputMaybe<StringFilter>;
};

export type LegacyUserGroupByDTO = {
  User?: InputMaybe<UserGroupByDTO>;
  acceptedEulaAt?: InputMaybe<Scalars['Boolean']>;
  active?: InputMaybe<Scalars['Boolean']>;
  baseAndOverbase?: InputMaybe<Scalars['Boolean']>;
  basisType?: InputMaybe<Scalars['Boolean']>;
  canTrade?: InputMaybe<Scalars['Boolean']>;
  costName0?: InputMaybe<Scalars['Boolean']>;
  costName1?: InputMaybe<Scalars['Boolean']>;
  costName2?: InputMaybe<Scalars['Boolean']>;
  costName3?: InputMaybe<Scalars['Boolean']>;
  costName4?: InputMaybe<Scalars['Boolean']>;
  costName5?: InputMaybe<Scalars['Boolean']>;
  costName6?: InputMaybe<Scalars['Boolean']>;
  costName7?: InputMaybe<Scalars['Boolean']>;
  costName8?: InputMaybe<Scalars['Boolean']>;
  costName9?: InputMaybe<Scalars['Boolean']>;
  costName10?: InputMaybe<Scalars['Boolean']>;
  costName11?: InputMaybe<Scalars['Boolean']>;
  costName12?: InputMaybe<Scalars['Boolean']>;
  costName13?: InputMaybe<Scalars['Boolean']>;
  costName14?: InputMaybe<Scalars['Boolean']>;
  costName15?: InputMaybe<Scalars['Boolean']>;
  costName16?: InputMaybe<Scalars['Boolean']>;
  costName17?: InputMaybe<Scalars['Boolean']>;
  costName18?: InputMaybe<Scalars['Boolean']>;
  costName19?: InputMaybe<Scalars['Boolean']>;
  costName20?: InputMaybe<Scalars['Boolean']>;
  costName21?: InputMaybe<Scalars['Boolean']>;
  costName22?: InputMaybe<Scalars['Boolean']>;
  costName23?: InputMaybe<Scalars['Boolean']>;
  costName24?: InputMaybe<Scalars['Boolean']>;
  costName25?: InputMaybe<Scalars['Boolean']>;
  costName26?: InputMaybe<Scalars['Boolean']>;
  costName27?: InputMaybe<Scalars['Boolean']>;
  costName28?: InputMaybe<Scalars['Boolean']>;
  costName29?: InputMaybe<Scalars['Boolean']>;
  costName30?: InputMaybe<Scalars['Boolean']>;
  costName31?: InputMaybe<Scalars['Boolean']>;
  costName32?: InputMaybe<Scalars['Boolean']>;
  costName33?: InputMaybe<Scalars['Boolean']>;
  costName34?: InputMaybe<Scalars['Boolean']>;
  costName35?: InputMaybe<Scalars['Boolean']>;
  costName36?: InputMaybe<Scalars['Boolean']>;
  costName37?: InputMaybe<Scalars['Boolean']>;
  costName38?: InputMaybe<Scalars['Boolean']>;
  costName39?: InputMaybe<Scalars['Boolean']>;
  costNameF?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  fmmoDairyPrices?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isTrial?: InputMaybe<Scalars['Boolean']>;
  isTrialExpired?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  manualClassOneDifferential?: InputMaybe<Scalars['Boolean']>;
  optionshopId?: InputMaybe<Scalars['Boolean']>;
  permissions?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['Boolean']>;
  pricingModel?: InputMaybe<Scalars['Boolean']>;
  quota?: InputMaybe<Scalars['Boolean']>;
  regionId?: InputMaybe<Scalars['Boolean']>;
  revenueNameA?: InputMaybe<Scalars['Boolean']>;
  revenueNameB?: InputMaybe<Scalars['Boolean']>;
  revenueNameC?: InputMaybe<Scalars['Boolean']>;
  revenueNameD?: InputMaybe<Scalars['Boolean']>;
  revenueNameE?: InputMaybe<Scalars['Boolean']>;
  revenueNameF?: InputMaybe<Scalars['Boolean']>;
  revenueNameG?: InputMaybe<Scalars['Boolean']>;
  selectProducerId?: InputMaybe<Scalars['Boolean']>;
  stripeCustomerId?: InputMaybe<Scalars['Boolean']>;
  uiStyle?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  vaultBasis?: InputMaybe<Scalars['Boolean']>;
  vaultBasisValue?: InputMaybe<Scalars['Boolean']>;
  vaultId?: InputMaybe<Scalars['Boolean']>;
};

export type LegacyUserSortByDTO = {
  User?: InputMaybe<UserSortByDTO>;
  acceptedEulaAt?: InputMaybe<NullableSortByDirection>;
  active?: InputMaybe<NullableSortByDirection>;
  baseAndOverbase?: InputMaybe<NullableSortByDirection>;
  basisType?: InputMaybe<NullableSortByDirection>;
  canTrade?: InputMaybe<NullableSortByDirection>;
  costName0?: InputMaybe<NullableSortByDirection>;
  costName1?: InputMaybe<NullableSortByDirection>;
  costName2?: InputMaybe<NullableSortByDirection>;
  costName3?: InputMaybe<NullableSortByDirection>;
  costName4?: InputMaybe<NullableSortByDirection>;
  costName5?: InputMaybe<NullableSortByDirection>;
  costName6?: InputMaybe<NullableSortByDirection>;
  costName7?: InputMaybe<NullableSortByDirection>;
  costName8?: InputMaybe<NullableSortByDirection>;
  costName9?: InputMaybe<NullableSortByDirection>;
  costName10?: InputMaybe<NullableSortByDirection>;
  costName11?: InputMaybe<NullableSortByDirection>;
  costName12?: InputMaybe<NullableSortByDirection>;
  costName13?: InputMaybe<NullableSortByDirection>;
  costName14?: InputMaybe<NullableSortByDirection>;
  costName15?: InputMaybe<NullableSortByDirection>;
  costName16?: InputMaybe<NullableSortByDirection>;
  costName17?: InputMaybe<NullableSortByDirection>;
  costName18?: InputMaybe<NullableSortByDirection>;
  costName19?: InputMaybe<NullableSortByDirection>;
  costName20?: InputMaybe<NullableSortByDirection>;
  costName21?: InputMaybe<NullableSortByDirection>;
  costName22?: InputMaybe<NullableSortByDirection>;
  costName23?: InputMaybe<NullableSortByDirection>;
  costName24?: InputMaybe<NullableSortByDirection>;
  costName25?: InputMaybe<NullableSortByDirection>;
  costName26?: InputMaybe<NullableSortByDirection>;
  costName27?: InputMaybe<NullableSortByDirection>;
  costName28?: InputMaybe<NullableSortByDirection>;
  costName29?: InputMaybe<NullableSortByDirection>;
  costName30?: InputMaybe<NullableSortByDirection>;
  costName31?: InputMaybe<NullableSortByDirection>;
  costName32?: InputMaybe<NullableSortByDirection>;
  costName33?: InputMaybe<NullableSortByDirection>;
  costName34?: InputMaybe<NullableSortByDirection>;
  costName35?: InputMaybe<NullableSortByDirection>;
  costName36?: InputMaybe<NullableSortByDirection>;
  costName37?: InputMaybe<NullableSortByDirection>;
  costName38?: InputMaybe<NullableSortByDirection>;
  costName39?: InputMaybe<NullableSortByDirection>;
  costNameF?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<NullableSortByDirection>;
  email?: InputMaybe<NullableSortByDirection>;
  firstName?: InputMaybe<NullableSortByDirection>;
  fmmoDairyPrices?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isActive?: InputMaybe<NullableSortByDirection>;
  isAdmin?: InputMaybe<NullableSortByDirection>;
  isTrial?: InputMaybe<SortByDirection>;
  isTrialExpired?: InputMaybe<SortByDirection>;
  lastName?: InputMaybe<NullableSortByDirection>;
  locationId?: InputMaybe<NullableSortByDirection>;
  manualClassOneDifferential?: InputMaybe<NullableSortByDirection>;
  optionshopId?: InputMaybe<NullableSortByDirection>;
  permissions?: InputMaybe<NullableSortByDirection>;
  phone?: InputMaybe<NullableSortByDirection>;
  pricingModel?: InputMaybe<NullableSortByDirection>;
  quota?: InputMaybe<NullableSortByDirection>;
  regionId?: InputMaybe<NullableSortByDirection>;
  revenueNameA?: InputMaybe<NullableSortByDirection>;
  revenueNameB?: InputMaybe<NullableSortByDirection>;
  revenueNameC?: InputMaybe<NullableSortByDirection>;
  revenueNameD?: InputMaybe<NullableSortByDirection>;
  revenueNameE?: InputMaybe<NullableSortByDirection>;
  revenueNameF?: InputMaybe<NullableSortByDirection>;
  revenueNameG?: InputMaybe<NullableSortByDirection>;
  selectProducerId?: InputMaybe<NullableSortByDirection>;
  stripeCustomerId?: InputMaybe<NullableSortByDirection>;
  uiStyle?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<NullableSortByDirection>;
  vaultBasis?: InputMaybe<NullableSortByDirection>;
  vaultBasisValue?: InputMaybe<NullableSortByDirection>;
  vaultId?: InputMaybe<SortByDirection>;
};

export type LgmGrossMargin = {
  __typename?: 'LgmGrossMargin';
  actualGrossMarginAmount?: Maybe<Scalars['Float']>;
  calendarMonthNumber?: Maybe<Scalars['Int']>;
  classCode: Scalars['String'];
  commodityCode: Scalars['String'];
  commodityTypeCode: Scalars['String'];
  commodityYear: Scalars['Int'];
  cornEquivalentDefaultValue?: Maybe<Scalars['Float']>;
  countyCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  croppingPracticeCode: Scalars['String'];
  deletedDate?: Maybe<Scalars['String']>;
  expectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  feederCattleEquivalentDefaultValue?: Maybe<Scalars['Float']>;
  filingDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurancePlanCode: Scalars['String'];
  intendedUseCode: Scalars['String'];
  intervalCode: Scalars['String'];
  irrigationPracticeCode: Scalars['String'];
  lastModified?: Maybe<Scalars['DateTime']>;
  lastReleasedDate?: Maybe<Scalars['String']>;
  liabilityMilkPrice?: Maybe<Scalars['Float']>;
  liveCattleEquivalentDefaultValue?: Maybe<Scalars['Float']>;
  marketSymbolCode?: Maybe<Scalars['String']>;
  month2ActualGrossMarginAmount?: Maybe<Scalars['Float']>;
  month2ExpectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  month3ActualGrossMarginAmount?: Maybe<Scalars['Float']>;
  month3ExpectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  month4ActualGrossMarginAmount?: Maybe<Scalars['Float']>;
  month4ExpectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  month5ActualGrossMarginAmount?: Maybe<Scalars['Float']>;
  month5ExpectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  month6ActualGrossMarginAmount?: Maybe<Scalars['Float']>;
  month6ExpectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  month7ActualGrossMarginAmount?: Maybe<Scalars['Float']>;
  month7ExpectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  month8ActualGrossMarginAmount?: Maybe<Scalars['Float']>;
  month8ExpectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  month9ActualGrossMarginAmount?: Maybe<Scalars['Float']>;
  month9ExpectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  month10ActualGrossMarginAmount?: Maybe<Scalars['Float']>;
  month10ExpectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  month11ActualGrossMarginAmount?: Maybe<Scalars['Float']>;
  month11ExpectedGrossMarginAmount?: Maybe<Scalars['Float']>;
  organicPracticeCode: Scalars['String'];
  practiceCode: Scalars['String'];
  recordCategoryCode?: Maybe<Scalars['String']>;
  recordTypeCode?: Maybe<Scalars['String']>;
  reinsuranceYear: Scalars['Int'];
  releasedDate?: Maybe<Scalars['String']>;
  salesEffectiveDate: Scalars['String'];
  soybeanMealEquivalentDefaultValue?: Maybe<Scalars['Float']>;
  stateCode: Scalars['String'];
  subClassCode: Scalars['String'];
  threeDayCmeCwtPrice?: Maybe<Scalars['Float']>;
  typeCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LgmGrossMarginDistinctOnDTO = {
  actualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  calendarMonthNumber?: InputMaybe<Scalars['Boolean']>;
  classCode?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityTypeCode?: InputMaybe<Scalars['Boolean']>;
  commodityYear?: InputMaybe<Scalars['Boolean']>;
  cornEquivalentDefaultValue?: InputMaybe<Scalars['Boolean']>;
  countyCode?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  croppingPracticeCode?: InputMaybe<Scalars['Boolean']>;
  deletedDate?: InputMaybe<Scalars['Boolean']>;
  expectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  feederCattleEquivalentDefaultValue?: InputMaybe<Scalars['Boolean']>;
  filingDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insurancePlanCode?: InputMaybe<Scalars['Boolean']>;
  intendedUseCode?: InputMaybe<Scalars['Boolean']>;
  intervalCode?: InputMaybe<Scalars['Boolean']>;
  irrigationPracticeCode?: InputMaybe<Scalars['Boolean']>;
  lastModified?: InputMaybe<Scalars['Boolean']>;
  lastReleasedDate?: InputMaybe<Scalars['Boolean']>;
  liabilityMilkPrice?: InputMaybe<Scalars['Boolean']>;
  liveCattleEquivalentDefaultValue?: InputMaybe<Scalars['Boolean']>;
  marketSymbolCode?: InputMaybe<Scalars['Boolean']>;
  month2ActualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month2ExpectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month3ActualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month3ExpectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month4ActualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month4ExpectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month5ActualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month5ExpectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month6ActualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month6ExpectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month7ActualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month7ExpectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month8ActualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month8ExpectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month9ActualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month9ExpectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month10ActualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month10ExpectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month11ActualGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  month11ExpectedGrossMarginAmount?: InputMaybe<Scalars['Boolean']>;
  organicPracticeCode?: InputMaybe<Scalars['Boolean']>;
  practiceCode?: InputMaybe<Scalars['Boolean']>;
  recordCategoryCode?: InputMaybe<Scalars['Boolean']>;
  recordTypeCode?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  releasedDate?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  soybeanMealEquivalentDefaultValue?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  subClassCode?: InputMaybe<Scalars['Boolean']>;
  threeDayCmeCwtPrice?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LgmGrossMarginFilterDTO = {
  AND?: InputMaybe<Array<LgmGrossMarginFilterDTO>>;
  NOT?: InputMaybe<LgmGrossMarginFilterDTO>;
  OR?: InputMaybe<Array<LgmGrossMarginFilterDTO>>;
  actualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  calendarMonthNumber?: InputMaybe<NullableNumberFilter>;
  classCode?: InputMaybe<StringFilter>;
  commodityCode?: InputMaybe<StringFilter>;
  commodityTypeCode?: InputMaybe<StringFilter>;
  commodityYear?: InputMaybe<NumberFilter>;
  cornEquivalentDefaultValue?: InputMaybe<NullableNumberFilter>;
  countyCode?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  croppingPracticeCode?: InputMaybe<StringFilter>;
  deletedDate?: InputMaybe<NullableStringFilter>;
  expectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  feederCattleEquivalentDefaultValue?: InputMaybe<NullableNumberFilter>;
  filingDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  insurancePlanCode?: InputMaybe<StringFilter>;
  intendedUseCode?: InputMaybe<StringFilter>;
  intervalCode?: InputMaybe<StringFilter>;
  irrigationPracticeCode?: InputMaybe<StringFilter>;
  lastModified?: InputMaybe<NullableDateTimeFilter>;
  lastReleasedDate?: InputMaybe<NullableStringFilter>;
  liabilityMilkPrice?: InputMaybe<NullableNumberFilter>;
  liveCattleEquivalentDefaultValue?: InputMaybe<NullableNumberFilter>;
  marketSymbolCode?: InputMaybe<NullableStringFilter>;
  month2ActualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month2ExpectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month3ActualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month3ExpectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month4ActualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month4ExpectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month5ActualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month5ExpectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month6ActualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month6ExpectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month7ActualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month7ExpectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month8ActualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month8ExpectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month9ActualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month9ExpectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month10ActualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month10ExpectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month11ActualGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  month11ExpectedGrossMarginAmount?: InputMaybe<NullableNumberFilter>;
  organicPracticeCode?: InputMaybe<StringFilter>;
  practiceCode?: InputMaybe<StringFilter>;
  recordCategoryCode?: InputMaybe<NullableStringFilter>;
  recordTypeCode?: InputMaybe<NullableStringFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  releasedDate?: InputMaybe<NullableStringFilter>;
  salesEffectiveDate?: InputMaybe<StringFilter>;
  soybeanMealEquivalentDefaultValue?: InputMaybe<NullableNumberFilter>;
  stateCode?: InputMaybe<StringFilter>;
  subClassCode?: InputMaybe<StringFilter>;
  threeDayCmeCwtPrice?: InputMaybe<NullableNumberFilter>;
  typeCode?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LgmGrossMarginSortByDTO = {
  actualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  calendarMonthNumber?: InputMaybe<NullableSortByDirection>;
  classCode?: InputMaybe<SortByDirection>;
  commodityCode?: InputMaybe<SortByDirection>;
  commodityTypeCode?: InputMaybe<SortByDirection>;
  commodityYear?: InputMaybe<SortByDirection>;
  cornEquivalentDefaultValue?: InputMaybe<NullableSortByDirection>;
  countyCode?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  croppingPracticeCode?: InputMaybe<SortByDirection>;
  deletedDate?: InputMaybe<NullableSortByDirection>;
  expectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  feederCattleEquivalentDefaultValue?: InputMaybe<NullableSortByDirection>;
  filingDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insurancePlanCode?: InputMaybe<SortByDirection>;
  intendedUseCode?: InputMaybe<SortByDirection>;
  intervalCode?: InputMaybe<SortByDirection>;
  irrigationPracticeCode?: InputMaybe<SortByDirection>;
  lastModified?: InputMaybe<NullableSortByDirection>;
  lastReleasedDate?: InputMaybe<NullableSortByDirection>;
  liabilityMilkPrice?: InputMaybe<NullableSortByDirection>;
  liveCattleEquivalentDefaultValue?: InputMaybe<NullableSortByDirection>;
  marketSymbolCode?: InputMaybe<NullableSortByDirection>;
  month2ActualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month2ExpectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month3ActualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month3ExpectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month4ActualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month4ExpectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month5ActualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month5ExpectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month6ActualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month6ExpectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month7ActualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month7ExpectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month8ActualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month8ExpectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month9ActualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month9ExpectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month10ActualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month10ExpectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month11ActualGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  month11ExpectedGrossMarginAmount?: InputMaybe<NullableSortByDirection>;
  organicPracticeCode?: InputMaybe<SortByDirection>;
  practiceCode?: InputMaybe<SortByDirection>;
  recordCategoryCode?: InputMaybe<NullableSortByDirection>;
  recordTypeCode?: InputMaybe<NullableSortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  releasedDate?: InputMaybe<NullableSortByDirection>;
  salesEffectiveDate?: InputMaybe<SortByDirection>;
  soybeanMealEquivalentDefaultValue?: InputMaybe<NullableSortByDirection>;
  stateCode?: InputMaybe<SortByDirection>;
  subClassCode?: InputMaybe<SortByDirection>;
  threeDayCmeCwtPrice?: InputMaybe<NullableSortByDirection>;
  typeCode?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LgmInsuranceEndorsement = IInsuranceEndorsement & {
  __typename?: 'LgmInsuranceEndorsement';
  AllocationRatios: Array<InsuranceEndorsementAllocationRatio>;
  HedgeStrategy?: Maybe<HedgeStrategy>;
  InsuranceAgent?: Maybe<InsuranceAgent>;
  InsurancePolicy: InsurancePolicy;
  InsurancePractice?: Maybe<InsurancePractice>;
  RevenueHedgeProduct: Product;
  RevenueHedgeProducts: Array<Product>;
  RmaType: RmaType;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aoExpenseSubsidyAmount: Scalars['Float'];
  baseSubsidyAmount: Scalars['Float'];
  /** Beginning Farmer Rancher/Veteran Farmer Rancher Subsidy Amount. 10% of the Total Premium Amount added to the base Subsidy Amount. */
  bfrVfrSubsidyAmount: Scalars['Float'];
  /** The amount of subsidy reduced due to Conservation Compliance. */
  ccSubsidyReductionAmount: Scalars['Float'];
  /** The percentage of the base subsidy amount that will */
  ccSubsidyReductionPercent: Scalars['Float'];
  /** The target number of bushels of corn to be feed to each cattle from feeder stage to finished stage */
  cornTargetBushels?: Maybe<Scalars['Float']>;
  /** The months within the start / end date range for which coverage exists. LGM endorsements cover a 1-year period within which 2+ months must have coverage. These months need-not be sequential. */
  coverageMonths: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateOfForecastedIndemnity?: Maybe<Scalars['String']>;
  deductibleAmount: Scalars['Float'];
  /** The first date of a month for which this endorsement effectively exclusively hedges. LGM endorsements _must_ include coverage for 2+ months per USDA regulation, but often an endorsement is weighted to _effectively_ hedge only a single month (e.g. having a target of 10,000 hogs in November and 1 hog in December). This value will only be set if an endorsement is inferred to be weighted in this way. */
  effectiveHedgeMonth?: Maybe<Scalars['String']>;
  /** The ending date (inclusive) of the endorsement's coverage period */
  endDate: Scalars['String'];
  /** @deprecated Use "forecastedPnl" instead */
  estimatedPl?: Maybe<Scalars['Float']>;
  /** The target weight (in cwt) of each feeder cattle for cattle endorsements */
  feederCattleTargetWeight?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  /** The Gross Margin Guarantee (dollar and cents) for swine or cattle is the sum of (Target Marketings by month times Expected Gross Margins by month) minus (Deductible times Total Target Marketings). The Gross Margin Guarantee (dollar and cents) for dairy is the sum of Expected Gross Margins by month minus (Deductible times Total Target Marketings). */
  grossMarginGuarantee: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** This will contain either the actual indemnity value if provided or the forecasted indemnity value otherwise. */
  indemnity?: Maybe<Scalars['Float']>;
  /** @deprecated Use "forecastedIndemnity" instead */
  indemnityAmount?: Maybe<Scalars['Float']>;
  inputSource: InputSource;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode?: Maybe<Scalars['String']>;
  insurancePracticeId?: Maybe<Scalars['String']>;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong: Scalars['Boolean'];
  isShort: Scalars['Boolean'];
  /** Insured Value of the endorsement */
  liability: Scalars['Float'];
  /** The target weight (in cwt) of each finished cattle for cattle endorsements */
  liveCattleTargetWeight?: Maybe<Scalars['Float']>;
  /** Monthy endorsement data for coverage month 2 - month 11. This property will contain an array of objects. _All_ objects will have the following properties: - startDate (string): The first date of the month unto which the object's data pertains - endDate (string): The last date of the month unto which the object's data pertains - hedgeMonths (string[]): Display expiration dates of the futures used to hedge this month's (startDate through endDate) coverage - monthIndex (int): The relative month offset from the month within which the sales effective date falls (ie an integer 2 - 11) - target (float): The target value for the endorsement / month. This value represents the number of cattle, hogs, or hundredweight of milk (depending on the commodity). Additionaly, Dairy LGM endorsements will also contain the following poperties within each object - corn (float | null): The expected tons of corn or corn equivalent feed for the month - sbm (float | null): The expected tons of soybean meal or soybean meal equivalent feed for the month - expectedFeedCost (float): The expected cost of feed for this month - expectedGrossMargin (float): The expected gross margin for this month (difference between milk revenue & feed costs) */
  perMonthData: Array<Scalars['Object']>;
  /** This will contain either the actual pnl value if provided or the forecasted pnl value otherwise. */
  pnl?: Maybe<Scalars['Float']>;
  policyId: Scalars['String'];
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount: Scalars['Float'];
  reinsuranceYear: Scalars['Int'];
  revenueHedgeProductId: Scalars['String'];
  revenueHedgeProductIds: Array<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  rmaTypeId: Scalars['String'];
  salesEffectiveDate: Scalars['String'];
  simulatedLossAmount: Scalars['Float'];
  /** The starting date (inclusive) of the endorsement's coverage period */
  startDate: Scalars['String'];
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode: Scalars['String'];
  stateId: Scalars['String'];
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount: Scalars['Float'];
  totalExpectedGrossMargin: Scalars['Float'];
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount: Scalars['Float'];
  totalTarget: Scalars['Float'];
  type: TypeOfInsuranceEndorsement;
  /** The RMA commodity type code */
  typeCode: Scalars['String'];
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type LgmInsuranceEndorsement_AllocationRatiosArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementAllocationRatioSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
};

export type LgmInsuranceEndorsementCreateDTO = {
  agentId?: InputMaybe<Scalars['String']>;
  aoExpenseSubsidyAmount: Scalars['Float'];
  baseSubsidyAmount: Scalars['Float'];
  bfrVfrSubsidyAmount: Scalars['Float'];
  ccSubsidyReductionAmount: Scalars['Float'];
  ccSubsidyReductionPercent: Scalars['Float'];
  cornTargetBushels?: InputMaybe<Scalars['Float']>;
  coverageMonths: Array<Scalars['String']>;
  dateOfForecastedIndemnity?: InputMaybe<Scalars['String']>;
  deductibleAmount: Scalars['Float'];
  effectiveHedgeMonth?: InputMaybe<Scalars['String']>;
  feederCattleTargetWeight?: InputMaybe<Scalars['Float']>;
  grossMarginGuarantee: Scalars['Float'];
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode?: InputMaybe<Scalars['String']>;
  insurancePracticeId?: InputMaybe<Scalars['String']>;
  isLong: Scalars['Boolean'];
  liability: Scalars['Float'];
  liveCattleTargetWeight?: InputMaybe<Scalars['Float']>;
  perMonthData: Array<Scalars['Object']>;
  producerPremiumAmount: Scalars['Float'];
  reinsuranceYear: Scalars['Int'];
  revenueHedgeProductId: Scalars['String'];
  rmaStatus?: InputMaybe<TypeOfRmaStatus>;
  rmaTypeId: Scalars['String'];
  salesEffectiveDate: Scalars['String'];
  simulatedLossAmount: Scalars['Float'];
  stateCode?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  subsidyAmount: Scalars['Float'];
  totalExpectedGrossMargin: Scalars['Float'];
  totalPremiumAmount: Scalars['Float'];
  totalTarget: Scalars['Float'];
  typeCode?: InputMaybe<Scalars['String']>;
};

export type LgmInsuranceEndorsementDistinctOnDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aoExpenseSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  baseSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  bfrVfrSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  ccSubsidyReductionAmount?: InputMaybe<Scalars['Boolean']>;
  ccSubsidyReductionPercent?: InputMaybe<Scalars['Boolean']>;
  cornTargetBushels?: InputMaybe<Scalars['Boolean']>;
  coverageMonths?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  dateOfForecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  deductibleAmount?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeMonth?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  feederCattleTargetWeight?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  grossMarginGuarantee?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  liveCattleTargetWeight?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductId?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  rmaTypeId?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  simulatedLossAmount?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  totalExpectedGrossMargin?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  totalTarget?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LgmInsuranceEndorsementFilterDTO = {
  AND?: InputMaybe<Array<LgmInsuranceEndorsementFilterDTO>>;
  Agent?: InputMaybe<InsuranceAgentFilterDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyFilterDTO>;
  InsuranceEndorsementAllocationRatios?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyFilterDTO>;
  NOT?: InputMaybe<LgmInsuranceEndorsementFilterDTO>;
  OR?: InputMaybe<Array<LgmInsuranceEndorsementFilterDTO>>;
  Policy?: InputMaybe<InsurancePolicyFilterDTO>;
  Practice?: InputMaybe<InsurancePracticeFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  RmaType?: InputMaybe<RmaTypeFilterDTO>;
  State?: InputMaybe<StateFilterDTO>;
  actualIndemnity?: InputMaybe<NullableNumberFilter>;
  actualPnl?: InputMaybe<NullableNumberFilter>;
  agentId?: InputMaybe<NullableStringFilter>;
  aoExpenseSubsidyAmount?: InputMaybe<NumberFilter>;
  baseSubsidyAmount?: InputMaybe<NumberFilter>;
  bfrVfrSubsidyAmount?: InputMaybe<NumberFilter>;
  ccSubsidyReductionAmount?: InputMaybe<NumberFilter>;
  ccSubsidyReductionPercent?: InputMaybe<NumberFilter>;
  cornTargetBushels?: InputMaybe<NullableNumberFilter>;
  coverageMonths?: InputMaybe<StringArrayFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfForecastedIndemnity?: InputMaybe<NullableStringFilter>;
  deductibleAmount?: InputMaybe<NumberFilter>;
  effectiveHedgeMonth?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<StringFilter>;
  estimatedPl?: InputMaybe<NullableNumberFilter>;
  feederCattleTargetWeight?: InputMaybe<NullableNumberFilter>;
  forecastedIndemnity?: InputMaybe<NullableNumberFilter>;
  forecastedPnl?: InputMaybe<NullableNumberFilter>;
  grossMarginGuarantee?: InputMaybe<NumberFilter>;
  hedgeStrategyId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  indemnity?: InputMaybe<NullableNumberFilter>;
  indemnityAmount?: InputMaybe<NullableNumberFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  insurancePolicyId?: InputMaybe<StringFilter>;
  insurancePracticeCode?: InputMaybe<NullableStringFilter>;
  insurancePracticeId?: InputMaybe<NullableStringFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  isLong?: InputMaybe<BooleanFilter>;
  isShort?: InputMaybe<BooleanFilter>;
  liability?: InputMaybe<NumberFilter>;
  liveCattleTargetWeight?: InputMaybe<NullableNumberFilter>;
  pnl?: InputMaybe<NullableNumberFilter>;
  policyId?: InputMaybe<StringFilter>;
  producerPremiumAmount?: InputMaybe<NumberFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  revenueHedgeProductId?: InputMaybe<StringFilter>;
  revenueHedgeProductIds?: InputMaybe<StringArrayFilter>;
  rmaStatus?: InputMaybe<NullableTypeOfRmaStatusFilter>;
  rmaTypeId?: InputMaybe<StringFilter>;
  salesEffectiveDate?: InputMaybe<StringFilter>;
  simulatedLossAmount?: InputMaybe<NumberFilter>;
  startDate?: InputMaybe<StringFilter>;
  stateCode?: InputMaybe<StringFilter>;
  stateId?: InputMaybe<StringFilter>;
  subsidyAmount?: InputMaybe<NumberFilter>;
  totalExpectedGrossMargin?: InputMaybe<NumberFilter>;
  totalPremiumAmount?: InputMaybe<NumberFilter>;
  totalTarget?: InputMaybe<NumberFilter>;
  typeCode?: InputMaybe<StringFilter>;
  typeCodeYear?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LgmInsuranceEndorsementGroupByDTO = {
  Agent?: InputMaybe<InsuranceAgentGroupByDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyGroupByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyGroupByDTO>;
  Policy?: InputMaybe<InsurancePolicyGroupByDTO>;
  Practice?: InputMaybe<InsurancePracticeGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  RmaType?: InputMaybe<RmaTypeGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aoExpenseSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  baseSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  bfrVfrSubsidyAmount?: InputMaybe<Scalars['Boolean']>;
  ccSubsidyReductionAmount?: InputMaybe<Scalars['Boolean']>;
  ccSubsidyReductionPercent?: InputMaybe<Scalars['Boolean']>;
  cornTargetBushels?: InputMaybe<Scalars['Boolean']>;
  coverageMonths?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  dateOfForecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  deductibleAmount?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeMonth?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  feederCattleTargetWeight?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  grossMarginGuarantee?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  liveCattleTargetWeight?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductId?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  rmaTypeId?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  simulatedLossAmount?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  totalExpectedGrossMargin?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  totalTarget?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LgmInsuranceEndorsementSortByDTO = {
  Agent?: InputMaybe<InsuranceAgentSortByDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementSortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategySortByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicySortByDTO>;
  Policy?: InputMaybe<InsurancePolicySortByDTO>;
  Practice?: InputMaybe<InsurancePracticeSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  RmaType?: InputMaybe<RmaTypeSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  actualIndemnity?: InputMaybe<NullableSortByDirection>;
  actualPnl?: InputMaybe<NullableSortByDirection>;
  agentId?: InputMaybe<NullableSortByDirection>;
  aoExpenseSubsidyAmount?: InputMaybe<SortByDirection>;
  baseSubsidyAmount?: InputMaybe<SortByDirection>;
  bfrVfrSubsidyAmount?: InputMaybe<SortByDirection>;
  ccSubsidyReductionAmount?: InputMaybe<SortByDirection>;
  ccSubsidyReductionPercent?: InputMaybe<SortByDirection>;
  cornTargetBushels?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  dateOfForecastedIndemnity?: InputMaybe<NullableSortByDirection>;
  deductibleAmount?: InputMaybe<SortByDirection>;
  effectiveHedgeMonth?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<SortByDirection>;
  estimatedPl?: InputMaybe<NullableSortByDirection>;
  feederCattleTargetWeight?: InputMaybe<NullableSortByDirection>;
  forecastedIndemnity?: InputMaybe<NullableSortByDirection>;
  forecastedPnl?: InputMaybe<NullableSortByDirection>;
  grossMarginGuarantee?: InputMaybe<SortByDirection>;
  hedgeStrategyId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  indemnity?: InputMaybe<NullableSortByDirection>;
  indemnityAmount?: InputMaybe<NullableSortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  insurancePolicyId?: InputMaybe<SortByDirection>;
  insurancePracticeCode?: InputMaybe<NullableSortByDirection>;
  insurancePracticeId?: InputMaybe<NullableSortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  isLong?: InputMaybe<SortByDirection>;
  isShort?: InputMaybe<SortByDirection>;
  liability?: InputMaybe<SortByDirection>;
  liveCattleTargetWeight?: InputMaybe<NullableSortByDirection>;
  pnl?: InputMaybe<NullableSortByDirection>;
  policyId?: InputMaybe<SortByDirection>;
  producerPremiumAmount?: InputMaybe<SortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  revenueHedgeProductId?: InputMaybe<SortByDirection>;
  rmaStatus?: InputMaybe<NullableSortByDirection>;
  rmaTypeId?: InputMaybe<SortByDirection>;
  salesEffectiveDate?: InputMaybe<SortByDirection>;
  simulatedLossAmount?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  stateCode?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  subsidyAmount?: InputMaybe<SortByDirection>;
  totalExpectedGrossMargin?: InputMaybe<SortByDirection>;
  totalPremiumAmount?: InputMaybe<SortByDirection>;
  totalTarget?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  typeCode?: InputMaybe<SortByDirection>;
  typeCodeYear?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LgmInsuranceEndorsementUpdateDTO = {
  agentId?: InputMaybe<Scalars['String']>;
  aoExpenseSubsidyAmount?: InputMaybe<Scalars['Float']>;
  baseSubsidyAmount?: InputMaybe<Scalars['Float']>;
  /** Beginning Farmer Rancher/Veteran Farmer Rancher Subsidy Amount. 10% of the Total Premium Amount added to the base Subsidy Amount. */
  bfrVfrSubsidyAmount?: InputMaybe<Scalars['Float']>;
  /** The amount of subsidy reduced due to Conservation Compliance. */
  ccSubsidyReductionAmount?: InputMaybe<Scalars['Float']>;
  /** The percentage of the base subsidy amount that will */
  ccSubsidyReductionPercent?: InputMaybe<Scalars['Float']>;
  /** The target number of bushels of corn to be feed to each cattle from feeder stage to finished stage */
  cornTargetBushels?: InputMaybe<Scalars['Float']>;
  /** The months within the start / end date range for which coverage exists. LGM endorsements cover a 1-year period within which 2+ months must have coverage. These months need-not be sequential. */
  coverageMonths?: InputMaybe<Array<Scalars['String']>>;
  dateOfForecastedIndemnity?: InputMaybe<Scalars['String']>;
  deductibleAmount?: InputMaybe<Scalars['Float']>;
  /** The first date of a month for which this endorsement effectively exclusively hedges. LGM endorsements _must_ include coverage for 2+ months per USDA regulation, but often an endorsement is weighted to _effectively_ hedge only a single month (e.g. having a target of 10,000 hogs in November and 1 hog in December). This value will only be set if an endorsement is inferred to be weighted in this way. */
  effectiveHedgeMonth?: InputMaybe<Scalars['String']>;
  /** The target weight (in cwt) of each feeder cattle for cattle endorsements */
  feederCattleTargetWeight?: InputMaybe<Scalars['Float']>;
  /** The Gross Margin Guarantee (dollar and cents) for swine or cattle is the sum of (Target Marketings by month times Expected Gross Margins by month) minus (Deductible times Total Target Marketings). The Gross Margin Guarantee (dollar and cents) for dairy is the sum of Expected Gross Margins by month minus (Deductible times Total Target Marketings). */
  grossMarginGuarantee?: InputMaybe<Scalars['Float']>;
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insurancePolicyId?: InputMaybe<Scalars['String']>;
  insurancePracticeCode?: InputMaybe<Scalars['String']>;
  insurancePracticeId?: InputMaybe<Scalars['String']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  /** Insured Value of the endorsement */
  liability?: InputMaybe<Scalars['Float']>;
  /** The target weight (in cwt) of each finished cattle for cattle endorsements */
  liveCattleTargetWeight?: InputMaybe<Scalars['Float']>;
  /** Monthy endorsement data for coverage month 2 - month 11. This property will contain an array of objects. _All_ objects will have the following properties: - startDate (string): The first date of the month unto which the object's data pertains - endDate (string): The last date of the month unto which the object's data pertains - hedgeMonths (string[]): Display expiration dates of the futures used to hedge this month's (startDate through endDate) coverage - monthIndex (int): The relative month offset from the month within which the sales effective date falls (ie an integer 2 - 11) - target (float): The target value for the endorsement / month. This value represents the number of cattle, hogs, or hundredweight of milk (depending on the commodity). Additionaly, Dairy LGM endorsements will also contain the following poperties within each object - corn (float | null): The expected tons of corn or corn equivalent feed for the month - sbm (float | null): The expected tons of soybean meal or soybean meal equivalent feed for the month - expectedFeedCost (float): The expected cost of feed for this month - expectedGrossMargin (float): The expected gross margin for this month (difference between milk revenue & feed costs) */
  perMonthData?: InputMaybe<Array<Scalars['Object']>>;
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount?: InputMaybe<Scalars['Float']>;
  reinsuranceYear?: InputMaybe<Scalars['Int']>;
  revenueHedgeProductId?: InputMaybe<Scalars['String']>;
  rmaStatus?: InputMaybe<TypeOfRmaStatus>;
  rmaTypeId?: InputMaybe<Scalars['String']>;
  salesEffectiveDate?: InputMaybe<Scalars['String']>;
  simulatedLossAmount?: InputMaybe<Scalars['Float']>;
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount?: InputMaybe<Scalars['Float']>;
  totalExpectedGrossMargin?: InputMaybe<Scalars['Float']>;
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount?: InputMaybe<Scalars['Float']>;
  totalTarget?: InputMaybe<Scalars['Float']>;
  /** The RMA commodity type code */
  typeCode?: InputMaybe<Scalars['String']>;
};

export type LivestockGroup = ILivestockGroup & {
  __typename?: 'LivestockGroup';
  Business: CustomerEntity;
  FeedUsages: Array<ILivestockGroupFeedUsage>;
  Version: LivestockGroupVersion;
  /** The ID of the business to which this livestock group belongs. */
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The proportion of individuals which are expected to die over the age span this group represents. Ex. a value of 0.03 would mean that 3% of individuals are expected to die between the time they are minAgeInWeeks old and maxAgeInWeeks old. */
  deathRate: Scalars['Float'];
  /** The optional description of the livestock group. */
  description?: Maybe<Scalars['String']>;
  /** The number of weeks contained by this group, inclusive of the min & max. e.g. A max of 25 and a min of 21 would have a duration of 5 */
  durationInWeeks: Scalars['Int'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The maximum age in weeks for membership in this group. Ex. a value of 20 would mean an animal 21 weeks old or older would not be a member of this group, but an animal 20 weeks old would be. Must be greater than minAgeInWeeks. */
  maxAgeInWeeks: Scalars['Int'];
  /** The minimum age in weeks for membership in this group. Ex. a value a 4 would mean an animal 3 weeks old or younger would not be a member of this group, but an animal 4 weeks old would be. Must be less than maxAgeInWeeks. */
  minAgeInWeeks: Scalars['Int'];
  /** The name of the livestock group. */
  name: Scalars['String'];
  type: TypeOfLivestockGroup;
  updatedAt: Scalars['DateTime'];
  /** The version in which this LivestockGroup is a part of. The version will contain information regarding whether or not this swine group is "current" as well as the startDate & endDate in which it is in effect for data forcasting */
  versionId: Scalars['String'];
  /** A UUID that will be shared across all feed ingredients that have been copied from each other upon the creation of a new version. */
  versionedConceptSeriesId: Scalars['String'];
};


export type LivestockGroup_FeedUsagesArgs = {
  orderBy?: InputMaybe<LivestockGroupFeedUsageSortByDTO>;
  where?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
};

/** The allocations for a given LG contract for each of the periods between the contract start & end date as appropriate for the contract's periodAllocationType. */
export type LivestockGroupContractPeriodAllocation = {
  __typename?: 'LivestockGroupContractPeriodAllocation';
  LivestockGroupContract: SwineLivestockGroupContract;
  /** The quantity head of swine that have been added to this contract period allocation, rounded to the nearest int. */
  allocatedQuantity: Scalars['Int'];
  businessId: Scalars['String'];
  contractId: Scalars['String'];
  /** The quantity head of swine contracted for sale within this period */
  contractedQuantity: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /** The final date for which swine can be added to this contract period allocation, inclusive */
  endDate: Scalars['String'];
  /** The quantity head of swine that have been added to this contract period allocation. This value may be a decimal, as we project forcasted deaths using decimal values. */
  exactAllocatedQuantity: Scalars['Float'];
  /** The remaining head of swine that can be added to this contract allocation. This value may be a decimal. */
  exactOpenQuantity: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The remaining head of swine that can be added to this contract allocation, rounded to the nearest int. */
  openQuantity: Scalars['Int'];
  /** The first date for which swine can be added to this contract period allocation, inclusive */
  startDate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LivestockGroupContractPeriodAllocationAggregateAllDTO = {
  allocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractId?: InputMaybe<Scalars['Boolean']>;
  contractedQuantity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  exactAllocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  exactOpenQuantity?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  openQuantity?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupContractPeriodAllocationAggregateDTO = {
  avg?: InputMaybe<LivestockGroupContractPeriodAllocationAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<LivestockGroupContractPeriodAllocationAggregateAllDTO>;
  min?: InputMaybe<LivestockGroupContractPeriodAllocationAggregateAllDTO>;
  sum?: InputMaybe<LivestockGroupContractPeriodAllocationAggregateNumericDTO>;
};

export type LivestockGroupContractPeriodAllocationAggregateNumericDTO = {
  allocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  contractedQuantity?: InputMaybe<Scalars['Boolean']>;
  exactAllocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  exactOpenQuantity?: InputMaybe<Scalars['Boolean']>;
  openQuantity?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupContractPeriodAllocationDistinctOnDTO = {
  allocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractId?: InputMaybe<Scalars['Boolean']>;
  contractedQuantity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  exactAllocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  exactOpenQuantity?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  openQuantity?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupContractPeriodAllocationFilterDTO = {
  AND?: InputMaybe<Array<LivestockGroupContractPeriodAllocationFilterDTO>>;
  LivestockGroupContract?: InputMaybe<SwineLivestockGroupContractFilterDTO>;
  NOT?: InputMaybe<LivestockGroupContractPeriodAllocationFilterDTO>;
  OR?: InputMaybe<Array<LivestockGroupContractPeriodAllocationFilterDTO>>;
  PopulationChanges?: InputMaybe<LivestockPopulationChangeFilterDTO>;
  allocatedQuantity?: InputMaybe<NumberFilter>;
  businessId?: InputMaybe<StringFilter>;
  contractId?: InputMaybe<StringFilter>;
  contractedQuantity?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<StringFilter>;
  exactAllocatedQuantity?: InputMaybe<NumberFilter>;
  exactOpenQuantity?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  openQuantity?: InputMaybe<NumberFilter>;
  startDate?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LivestockGroupContractPeriodAllocationGroupByDTO = {
  LivestockGroupContract?: InputMaybe<SwineLivestockGroupContractGroupByDTO>;
  allocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractId?: InputMaybe<Scalars['Boolean']>;
  contractedQuantity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  exactAllocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  exactOpenQuantity?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  openQuantity?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupContractPeriodAllocationSortByDTO = {
  LivestockGroupContract?: InputMaybe<SwineLivestockGroupContractSortByDTO>;
  allocatedQuantity?: InputMaybe<SortByDirection>;
  businessId?: InputMaybe<SortByDirection>;
  contractId?: InputMaybe<SortByDirection>;
  contractedQuantity?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  endDate?: InputMaybe<SortByDirection>;
  exactAllocatedQuantity?: InputMaybe<SortByDirection>;
  exactOpenQuantity?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  openQuantity?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LivestockGroupDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deathRate?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  durationInWeeks?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  maxAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  minAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupFeedUsage = ILivestockGroupFeedUsage & {
  __typename?: 'LivestockGroupFeedUsage';
  Business: CustomerEntity;
  FeedIngredient: FeedIngredient;
  LivestockGroup: ILivestockGroup;
  Version: LivestockGroupFeedUsageVersion;
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  feedIngredientId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  livestockGroupId: Scalars['String'];
  type: TypeOfLivestockGroupFeedUsage;
  updatedAt: Scalars['DateTime'];
  usageInLbsPerHead: Scalars['Float'];
  /** computed as (usageInLbsPerHead/2000) */
  usageInTonsPerHead: Scalars['Float'];
  /** The version of feedUsage that this LivestockGroupFeedUsage uses. The version will contain information regarding whether or not this usage is "current" as well as the startDate & endDate in which it is in effect for data forcasting */
  versionId: Scalars['String'];
  /** A UUID that will be shared across all feed ingredients that have been copied from each other upon the creation of a new version. */
  versionedConceptSeriesId: Scalars['String'];
};

export type LivestockGroupFeedUsageCreateDTO = {
  businessId: Scalars['String'];
  feedIngredientId: Scalars['String'];
  livestockGroupId: Scalars['String'];
  type: TypeOfLivestockGroupFeedUsage;
  usageInLbsPerHead: Scalars['Float'];
};

export type LivestockGroupFeedUsageDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  livestockGroupId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  usageInLbsPerHead?: InputMaybe<Scalars['Boolean']>;
  usageInTonsPerHead?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupFeedUsageFilterDTO = {
  AND?: InputMaybe<Array<LivestockGroupFeedUsageFilterDTO>>;
  AsSwineLivestockGroupFeedUsage?: InputMaybe<SwineLivestockGroupFeedUsageFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientFilterDTO>;
  ForecastedLivestockFeedUsages?: InputMaybe<ForecastedLivestockFeedUsageFilterDTO>;
  LivestockGroup?: InputMaybe<LivestockGroupFilterDTO>;
  NOT?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
  OR?: InputMaybe<Array<LivestockGroupFeedUsageFilterDTO>>;
  Version?: InputMaybe<LivestockGroupFeedUsageVersionFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  feedIngredientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  livestockGroupId?: InputMaybe<StringFilter>;
  type?: InputMaybe<TypeOfLivestockGroupFeedUsageFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  usageInLbsPerHead?: InputMaybe<NumberFilter>;
  usageInTonsPerHead?: InputMaybe<NumberFilter>;
  versionId?: InputMaybe<StringFilter>;
  versionedConceptSeriesId?: InputMaybe<StringFilter>;
};

export type LivestockGroupFeedUsageGroupByDTO = {
  AsSwineLivestockGroupFeedUsage?: InputMaybe<SwineLivestockGroupFeedUsageGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientGroupByDTO>;
  LivestockGroup?: InputMaybe<LivestockGroupGroupByDTO>;
  LivestockGroupAsSwineLivestockGroup?: InputMaybe<SwineLivestockGroupGroupByDTO>;
  Version?: InputMaybe<LivestockGroupFeedUsageVersionGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  livestockGroupId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  usageInLbsPerHead?: InputMaybe<Scalars['Boolean']>;
  usageInTonsPerHead?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupFeedUsageResults = {
  __typename?: 'LivestockGroupFeedUsageResults';
  allCurrentUsages: Array<ILivestockGroupFeedUsage>;
  alteredFeedUsage: ILivestockGroupFeedUsage;
  currentVersion: LivestockGroupFeedUsageVersion;
};

export type LivestockGroupFeedUsageSet = {
  businessId: Scalars['String'];
  type: TypeOfLivestockGroupFeedUsage;
  usages: Array<LivestockGroupFeedUsageValuesSet>;
};

export type LivestockGroupFeedUsageSetResults = {
  __typename?: 'LivestockGroupFeedUsageSetResults';
  allCurrentUsages: Array<ILivestockGroupFeedUsage>;
  alteredFeedUsages: Array<ILivestockGroupFeedUsage>;
  currentVersion: LivestockGroupFeedUsageVersion;
};

export type LivestockGroupFeedUsageSortByDTO = {
  AsSwineLivestockGroupFeedUsage?: InputMaybe<SwineLivestockGroupFeedUsageSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientSortByDTO>;
  LivestockGroup?: InputMaybe<LivestockGroupSortByDTO>;
  Version?: InputMaybe<LivestockGroupFeedUsageVersionSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  feedIngredientId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  livestockGroupId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  usageInLbsPerHead?: InputMaybe<SortByDirection>;
  usageInTonsPerHead?: InputMaybe<SortByDirection>;
  versionId?: InputMaybe<SortByDirection>;
  versionedConceptSeriesId?: InputMaybe<SortByDirection>;
};

export type LivestockGroupFeedUsageUpdateDTO = {
  usageInLbsPerHead?: InputMaybe<Scalars['Float']>;
};

export type LivestockGroupFeedUsageValuesSet = {
  feedIngredientId: Scalars['String'];
  livestockGroupId: Scalars['String'];
  usageInLbsPerHead: Scalars['Float'];
};

export type LivestockGroupFeedUsageVersion = IVersion & {
  __typename?: 'LivestockGroupFeedUsageVersion';
  Business: CustomerEntity;
  CreatedByUser?: Maybe<User>;
  LastUpdatedByUser?: Maybe<User>;
  LivestockGroupFeedUsages: Array<ILivestockGroupFeedUsage>;
  NextVersion?: Maybe<LivestockGroupFeedUsageVersion>;
  PreviousVersion?: Maybe<LivestockGroupFeedUsageVersion>;
  /** The business entity (customer entity) on behalf of which this version exists */
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The user that triggered the creation of this version. If this value is null, then this version was created via the system. */
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The final date for which this version is in effect, inclusive. If this value is null, this version is in effect for all dates greater than startDate */
  endDate?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  /** The user that last updated the concepts linked to this version. When a version is first created, the lastUpdatedByUserId value will equal the user id that triggered the version creation. If this value is null, then this version has only been created & updated via the system. */
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  livestockGroupFeedUsageType: TypeOfLivestockGroupFeedUsage;
  name: Scalars['String'];
  nextVersionId?: Maybe<Scalars['String']>;
  /** The version that preceeds this version. If this value is null, this is the first version for this businessId / type combination. */
  previousVersionId?: Maybe<Scalars['String']>;
  /** The first date for which this version is in effect (inclusive) */
  startDate: Scalars['String'];
  type: TypeOfVersion;
  updatedAt: Scalars['DateTime'];
};


export type LivestockGroupFeedUsageVersion_LivestockGroupFeedUsagesArgs = {
  orderBy?: InputMaybe<LivestockGroupFeedUsageSortByDTO>;
  where?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
};

export type LivestockGroupFeedUsageVersionDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  livestockGroupFeedUsageType?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  nextVersionId?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupFeedUsageVersionFilterDTO = {
  AND?: InputMaybe<Array<LivestockGroupFeedUsageVersionFilterDTO>>;
  AsVersion?: InputMaybe<VersionFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  LivestockGroupFeedUsages?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
  NOT?: InputMaybe<LivestockGroupFeedUsageVersionFilterDTO>;
  NextVersion?: InputMaybe<VersionFilterDTO>;
  OR?: InputMaybe<Array<LivestockGroupFeedUsageVersionFilterDTO>>;
  PreviousVersion?: InputMaybe<VersionFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isCurrent?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  livestockGroupFeedUsageType?: InputMaybe<TypeOfLivestockGroupFeedUsageFilter>;
  name?: InputMaybe<StringFilter>;
  nextVersionId?: InputMaybe<NullableStringFilter>;
  previousVersionId?: InputMaybe<NullableStringFilter>;
  startDate?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LivestockGroupFeedUsageVersionGroupByDTO = {
  AsVersion?: InputMaybe<VersionGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  NextVersion?: InputMaybe<VersionGroupByDTO>;
  PreviousVersion?: InputMaybe<VersionGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  livestockGroupFeedUsageType?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  nextVersionId?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupFeedUsageVersionSortByDTO = {
  AsVersion?: InputMaybe<VersionSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  NextVersion?: InputMaybe<VersionSortByDTO>;
  PreviousVersion?: InputMaybe<VersionSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isCurrent?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  livestockGroupFeedUsageType?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  nextVersionId?: InputMaybe<NullableSortByDirection>;
  previousVersionId?: InputMaybe<NullableSortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LivestockGroupFilterDTO = {
  AND?: InputMaybe<Array<LivestockGroupFilterDTO>>;
  AsSwineLivestockGroup?: InputMaybe<SwineLivestockGroupFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  LivestockGroupFeedUsages?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
  LivestockGroupVersion?: InputMaybe<LivestockGroupVersionFilterDTO>;
  NOT?: InputMaybe<LivestockGroupFilterDTO>;
  OR?: InputMaybe<Array<LivestockGroupFilterDTO>>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deathRate?: InputMaybe<NumberFilter>;
  description?: InputMaybe<NullableStringFilter>;
  durationInWeeks?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  maxAgeInWeeks?: InputMaybe<NumberFilter>;
  minAgeInWeeks?: InputMaybe<NumberFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<TypeOfLivestockGroupFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  versionId?: InputMaybe<StringFilter>;
  versionedConceptSeriesId?: InputMaybe<StringFilter>;
};

export type LivestockGroupGroupByDTO = {
  AsSwineLivestockGroup?: InputMaybe<SwineLivestockGroupGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  LivestockGroupVersion?: InputMaybe<LivestockGroupVersionGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deathRate?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  durationInWeeks?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  maxAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  minAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupMergeDTO = {
  fromGroupId: Scalars['String'];
  intoGroupId: Scalars['String'];
  resultingMortalityRate: Scalars['Float'];
};

export type LivestockGroupMergeResults = {
  __typename?: 'LivestockGroupMergeResults';
  allCurrentGroups: Array<LivestockGroup>;
  currentVersion: LivestockGroupVersion;
  fromGroup: LivestockGroup;
  intoGroup: LivestockGroup;
  mergedGroup: LivestockGroup;
};

export type LivestockGroupSortByDTO = {
  AsSwineLivestockGroup?: InputMaybe<SwineLivestockGroupSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  LivestockGroupVersion?: InputMaybe<LivestockGroupVersionSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deathRate?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  durationInWeeks?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  maxAgeInWeeks?: InputMaybe<SortByDirection>;
  minAgeInWeeks?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  versionId?: InputMaybe<SortByDirection>;
  versionedConceptSeriesId?: InputMaybe<SortByDirection>;
};

export type LivestockGroupSplitDTO = {
  olderGroupMortality: Scalars['Float'];
  olderGroupName: Scalars['String'];
  splitWeek: Scalars['Float'];
  youngerGroupMortality: Scalars['Float'];
  youngerGroupName: Scalars['String'];
};

export type LivestockGroupSplitResults = {
  __typename?: 'LivestockGroupSplitResults';
  allCurrentGroups: Array<LivestockGroup>;
  currentVersion: LivestockGroupVersion;
  olderGroup: LivestockGroup;
  originalSplitGroupId: LivestockGroup;
  splitGroup: LivestockGroup;
  youngerGroup: LivestockGroup;
};

export type LivestockGroupUpdateDTO = {
  /** The proportion of individuals which are expected to die over the age span this group represents. Ex. a value of 0.03 would mean that 3% of individuals are expected to die between the time they are minAgeInWeeks old and maxAgeInWeeks old. */
  deathRate?: InputMaybe<Scalars['Float']>;
  /** The optional description of the livestock group. */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the livestock group. */
  name?: InputMaybe<Scalars['String']>;
};

export type LivestockGroupUpdateResults = {
  __typename?: 'LivestockGroupUpdateResults';
  allCurrentGroups: Array<LivestockGroup>;
  currentVersion: LivestockGroupVersion;
  updatedGroup: LivestockGroup;
};

export type LivestockGroupVersion = IVersion & {
  __typename?: 'LivestockGroupVersion';
  Business: CustomerEntity;
  CreatedByUser?: Maybe<User>;
  LastUpdatedByUser?: Maybe<User>;
  LivestockGroups: Array<ILivestockGroup>;
  NextVersion?: Maybe<LivestockGroupVersion>;
  PreviousVersion?: Maybe<LivestockGroupVersion>;
  /** The business entity (customer entity) on behalf of which this version exists */
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The user that triggered the creation of this version. If this value is null, then this version was created via the system. */
  createdByUserId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The final date for which this version is in effect, inclusive. If this value is null, this version is in effect for all dates greater than startDate */
  endDate?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  /** The user that last updated the concepts linked to this version. When a version is first created, the lastUpdatedByUserId value will equal the user id that triggered the version creation. If this value is null, then this version has only been created & updated via the system. */
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  livestockGroupType: TypeOfLivestockGroup;
  name: Scalars['String'];
  nextVersionId?: Maybe<Scalars['String']>;
  /** The version that preceeds this version. If this value is null, this is the first version for this businessId / type combination. */
  previousVersionId?: Maybe<Scalars['String']>;
  /** The first date for which this version is in effect (inclusive) */
  startDate: Scalars['String'];
  type: TypeOfVersion;
  updatedAt: Scalars['DateTime'];
};


export type LivestockGroupVersion_LivestockGroupsArgs = {
  orderBy?: InputMaybe<LivestockGroupSortByDTO>;
  where?: InputMaybe<LivestockGroupFilterDTO>;
};

export type LivestockGroupVersionDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  livestockGroupType?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  nextVersionId?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupVersionFilterDTO = {
  AND?: InputMaybe<Array<LivestockGroupVersionFilterDTO>>;
  AsVersion?: InputMaybe<VersionFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  LivestockGroups?: InputMaybe<LivestockGroupFilterDTO>;
  NOT?: InputMaybe<LivestockGroupVersionFilterDTO>;
  NextVersion?: InputMaybe<VersionFilterDTO>;
  OR?: InputMaybe<Array<LivestockGroupVersionFilterDTO>>;
  PreviousVersion?: InputMaybe<VersionFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isCurrent?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  livestockGroupType?: InputMaybe<TypeOfLivestockGroupFilter>;
  name?: InputMaybe<StringFilter>;
  nextVersionId?: InputMaybe<NullableStringFilter>;
  previousVersionId?: InputMaybe<NullableStringFilter>;
  startDate?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LivestockGroupVersionGroupByDTO = {
  AsVersion?: InputMaybe<VersionGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  NextVersion?: InputMaybe<VersionGroupByDTO>;
  PreviousVersion?: InputMaybe<VersionGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  livestockGroupType?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  nextVersionId?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockGroupVersionSortByDTO = {
  AsVersion?: InputMaybe<VersionSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  NextVersion?: InputMaybe<VersionSortByDTO>;
  PreviousVersion?: InputMaybe<VersionSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isCurrent?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  livestockGroupType?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  nextVersionId?: InputMaybe<NullableSortByDirection>;
  previousVersionId?: InputMaybe<NullableSortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LivestockPopulationChange = ILivestockPopulationChange & {
  __typename?: 'LivestockPopulationChange';
  Business: CustomerEntity;
  businessId: Scalars['String'];
  /** The livestock group contract period allocation unto which this population change has been added. This value will only be set for forecasted sales. */
  contractPeriodAllocationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** The date of the population change event. For ACTUAL rows, this date must be a Sunday. This is enforced with a constraint. */
  date: Scalars['String'];
  /** The date of birth for the cohort this row describes. This date must be a Sunday. This is enforced with a constraint. */
  dob: Scalars['String'];
  /** Generated field, The absolute INT of the week within the year in which these livestock were born */
  dobWeek: Scalars['Int'];
  exactQuantity: Scalars['Float'];
  /** Generated field, (exactQuantity * valuePerHead) */
  exactTotalValue: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Generated field, The first sunday of the week calculated from the value stored in 'date' */
  marketingWeekStartDate: Scalars['String'];
  /** The rounded quantity of population that has changed */
  quantity: Scalars['Int'];
  /** Generated field, A readable version of 'dobWeek' formatted "W[N] YYYY" */
  readableDobWeek: Scalars['String'];
  /** Generated field, A readable version of 'week' formatted "W[N] YYYY" */
  readableWeek: Scalars['String'];
  /** A custom description detailing the reason for this change, if applicable */
  reason?: Maybe<Scalars['String']>;
  reasonType: TypeOfLivestockPopulationChangeReason;
  startOfMonth: Scalars['String'];
  /** Intended to be populated when there is an issue related to the population change. */
  systemComment?: Maybe<Scalars['String']>;
  /** Generated field, (quantity * valuePerHead) */
  totalValue: Scalars['Float'];
  type: TypeOfLivestockPopulationChange;
  updatedAt: Scalars['DateTime'];
  valuePerHead: Scalars['Float'];
  valueType: TypeOfLivestockPopulationChangeValue;
  /** Generated field, The absolute INT of the week within the year in which this population change event occurred */
  week: Scalars['Int'];
};

export type LivestockPopulationChangeDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractPeriodAllocationId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  dobWeek?: InputMaybe<Scalars['Boolean']>;
  exactQuantity?: InputMaybe<Scalars['Boolean']>;
  exactTotalValue?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  marketingWeekStartDate?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  readableDobWeek?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['Boolean']>;
  reasonType?: InputMaybe<Scalars['Boolean']>;
  startOfMonth?: InputMaybe<Scalars['Boolean']>;
  systemComment?: InputMaybe<Scalars['Boolean']>;
  totalValue?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  valuePerHead?: InputMaybe<Scalars['Boolean']>;
  valueType?: InputMaybe<Scalars['Boolean']>;
  week?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockPopulationChangeFilterDTO = {
  AND?: InputMaybe<Array<LivestockPopulationChangeFilterDTO>>;
  AsSwineLivestockPopulationChange?: InputMaybe<SwineLivestockPopulationChangeFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  ContractPeriodAllocation?: InputMaybe<LivestockGroupContractPeriodAllocationFilterDTO>;
  NOT?: InputMaybe<LivestockPopulationChangeFilterDTO>;
  OR?: InputMaybe<Array<LivestockPopulationChangeFilterDTO>>;
  businessId?: InputMaybe<StringFilter>;
  contractPeriodAllocationId?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  dob?: InputMaybe<StringFilter>;
  dobWeek?: InputMaybe<NumberFilter>;
  exactQuantity?: InputMaybe<NumberFilter>;
  exactTotalValue?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  marketingWeekStartDate?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<NumberFilter>;
  readableDobWeek?: InputMaybe<StringFilter>;
  readableWeek?: InputMaybe<StringFilter>;
  reason?: InputMaybe<NullableStringFilter>;
  reasonType?: InputMaybe<TypeOfLivestockPopulationChangeReasonFilter>;
  startOfMonth?: InputMaybe<StringFilter>;
  systemComment?: InputMaybe<NullableStringFilter>;
  totalValue?: InputMaybe<NumberFilter>;
  type?: InputMaybe<TypeOfLivestockPopulationChangeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valuePerHead?: InputMaybe<NumberFilter>;
  valueType?: InputMaybe<TypeOfLivestockPopulationChangeValueFilter>;
  week?: InputMaybe<NumberFilter>;
};

export type LivestockPopulationChangeGroupByDTO = {
  AsSwineLivestockPopulationChange?: InputMaybe<SwineLivestockPopulationChangeGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  ContractPeriodAllocation?: InputMaybe<LivestockGroupContractPeriodAllocationGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractPeriodAllocationId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  dobWeek?: InputMaybe<Scalars['Boolean']>;
  exactQuantity?: InputMaybe<Scalars['Boolean']>;
  exactTotalValue?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  marketingWeekStartDate?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  readableDobWeek?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['Boolean']>;
  reasonType?: InputMaybe<Scalars['Boolean']>;
  startOfMonth?: InputMaybe<Scalars['Boolean']>;
  systemComment?: InputMaybe<Scalars['Boolean']>;
  totalValue?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  valuePerHead?: InputMaybe<Scalars['Boolean']>;
  valueType?: InputMaybe<Scalars['Boolean']>;
  week?: InputMaybe<Scalars['Boolean']>;
};

export type LivestockPopulationChangeSetDates = {
  date: Scalars['String'];
  dob: Scalars['String'];
};

export type LivestockPopulationChangeSortByDTO = {
  AsSwineLivestockPopulationChange?: InputMaybe<SwineLivestockPopulationChangeSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  ContractPeriodAllocation?: InputMaybe<LivestockGroupContractPeriodAllocationSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  contractPeriodAllocationId?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  dob?: InputMaybe<SortByDirection>;
  dobWeek?: InputMaybe<SortByDirection>;
  exactQuantity?: InputMaybe<SortByDirection>;
  exactTotalValue?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  marketingWeekStartDate?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  readableDobWeek?: InputMaybe<SortByDirection>;
  readableWeek?: InputMaybe<SortByDirection>;
  reason?: InputMaybe<NullableSortByDirection>;
  reasonType?: InputMaybe<SortByDirection>;
  startOfMonth?: InputMaybe<SortByDirection>;
  systemComment?: InputMaybe<NullableSortByDirection>;
  totalValue?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  valuePerHead?: InputMaybe<SortByDirection>;
  valueType?: InputMaybe<SortByDirection>;
  week?: InputMaybe<SortByDirection>;
};

export type LocationEntity = IEntity & {
  __typename?: 'LocationEntity';
  ActiveProducts: Array<Product>;
  ActualMilkProduction: Array<ActualMilkProduction>;
  ActualProduction: Array<ActualProduction>;
  AgreedToOtcContracts: Array<OtcContract>;
  BrokerageAccounts: Array<BrokerageAccount>;
  County: County;
  CreatedByUser?: Maybe<User>;
  CurrentUserPermissions: UserPermissionsForTarget;
  Customer?: Maybe<CustomerEntity>;
  EntityUsers: Array<EntityUser>;
  ExposureRatios: Array<EntityExposureRatio>;
  FeedIngredientConsumedAndPurchasedVolumes: Array<FeedIngredientConsumedAndPurchasedVolume>;
  ForecastedMilkProduction: Array<ForecastedMilkProduction>;
  ForecastedMilkUtilizations: Array<ForecastedMilkUtilization>;
  ForecastedProduction: Array<ForecastedProduction>;
  ForwardContracts: Array<ForwardContractAccount>;
  GroupEntries: Array<EntityGroupEntry>;
  Groups: Array<EntityGroup>;
  InitiatedOtcContracts: Array<OtcContract>;
  LastUpdatedByUser?: Maybe<User>;
  LedgerCategories: Array<ILedgerCategory>;
  LedgerEntries: Array<ILedgerEntry>;
  Members: Array<EntityMember>;
  MilkOrder: MilkOrder;
  Notes: Array<EntityNote>;
  OwnedAccounts: Array<IAccount>;
  OwnedOtcContracts: Array<OtcContract>;
  PhysicalSales: Array<PhysicalSale>;
  countyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  /** The current basis for the given entity. This value is identical to customBasis, if set, otherwise this value is computed for the past rollingBasisNumberOfMonths number of months' blended milk basis value. */
  currentBasis?: Maybe<Scalars['Float']>;
  /** A custom fixed basis value that will be used for currentBasis instead of a computed average of the past rollingBasisNumberOfMonths months' blended milk basis values. */
  customBasis?: Maybe<Scalars['Float']>;
  customerId: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  identificationNumber: Scalars['Int'];
  isVgs: Scalars['Boolean'];
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  milkOrderId: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  /** The number of previous months accross which blended milk basis values will be averaged to compute the current basis. This value is disregarded if customBasis is set. */
  rollingBasisNumberOfMonths: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  type: TypeOfEntity;
  updatedAt: Scalars['DateTime'];
};


export type LocationEntity_ActualMilkProductionArgs = {
  orderBy?: InputMaybe<ActualMilkProductionSortByDTO>;
  where?: InputMaybe<ActualMilkProductionFilterDTO>;
};


export type LocationEntity_ActualProductionArgs = {
  orderBy?: InputMaybe<ActualProductionSortByDTO>;
  where?: InputMaybe<ActualProductionFilterDTO>;
};


export type LocationEntity_AgreedToOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type LocationEntity_BrokerageAccountsArgs = {
  orderBy?: InputMaybe<BrokerageAccountSortByDTO>;
  where?: InputMaybe<BrokerageAccountFilterDTO>;
};


export type LocationEntity_EntityUsersArgs = {
  orderBy?: InputMaybe<EntityUserSortByDTO>;
  where?: InputMaybe<EntityUserFilterDTO>;
};


export type LocationEntity_ExposureRatiosArgs = {
  orderBy?: InputMaybe<EntityExposureRatioSortByDTO>;
  where?: InputMaybe<EntityExposureRatioFilterDTO>;
};


export type LocationEntity_FeedIngredientConsumedAndPurchasedVolumesArgs = {
  orderBy?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeSortByDTO>;
  where?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
};


export type LocationEntity_ForecastedMilkProductionArgs = {
  orderBy?: InputMaybe<ForecastedMilkProductionSortByDTO>;
  where?: InputMaybe<ForecastedMilkProductionFilterDTO>;
};


export type LocationEntity_ForecastedMilkUtilizationsArgs = {
  orderBy?: InputMaybe<ForecastedMilkUtilizationSortByDTO>;
  where?: InputMaybe<ForecastedMilkUtilizationFilterDTO>;
};


export type LocationEntity_ForecastedProductionArgs = {
  orderBy?: InputMaybe<ForecastedProductionSortByDTO>;
  where?: InputMaybe<ForecastedProductionFilterDTO>;
};


export type LocationEntity_ForwardContractsArgs = {
  orderBy?: InputMaybe<ForwardContractAccountSortByDTO>;
  where?: InputMaybe<ForwardContractAccountFilterDTO>;
};


export type LocationEntity_GroupEntriesArgs = {
  orderBy?: InputMaybe<EntityGroupEntrySortByDTO>;
  where?: InputMaybe<EntityGroupEntryFilterDTO>;
};


export type LocationEntity_InitiatedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type LocationEntity_LedgerCategoriesArgs = {
  orderBy?: InputMaybe<LedgerCategorySortByDTO>;
  where?: InputMaybe<LedgerCategoryFilterDTO>;
};


export type LocationEntity_LedgerEntriesArgs = {
  orderBy?: InputMaybe<LedgerEntrySortByDTO>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};


export type LocationEntity_MembersArgs = {
  orderBy?: InputMaybe<EntityMemberSortByDTO>;
  where?: InputMaybe<EntityMemberFilterDTO>;
};


export type LocationEntity_NotesArgs = {
  orderBy?: InputMaybe<EntityNoteSortByDTO>;
  where?: InputMaybe<EntityNoteFilterDTO>;
};


export type LocationEntity_OwnedAccountsArgs = {
  orderBy?: InputMaybe<AccountSortByDTO>;
  where?: InputMaybe<AccountFilterDTO>;
};


export type LocationEntity_OwnedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type LocationEntity_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};

export type LocationEntityCreateDTO = {
  countyId: Scalars['String'];
  customBasis?: InputMaybe<Scalars['Float']>;
  customerId: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  rollingBasisNumberOfMonths?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type LocationEntityDistinctOnDTO = {
  countyId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  currentBasis?: InputMaybe<Scalars['Boolean']>;
  customBasis?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Boolean']>;
  rollingBasisNumberOfMonths?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LocationEntityFilterDTO = {
  AND?: InputMaybe<Array<LocationEntityFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  ActualBlendedMilkPrices?: InputMaybe<ActualBlendedMilkPriceFilterDTO>;
  ActualMilkProduction?: InputMaybe<ActualMilkProductionFilterDTO>;
  ActualMilkProductionByMonth?: InputMaybe<ActualMilkProductionByMonthFilterDTO>;
  ActualProduction?: InputMaybe<ActualProductionFilterDTO>;
  AgreedToOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  AllocatedForecastedHedgedAndCappedVolumes?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>;
  AsEntity?: InputMaybe<EntityFilterDTO>;
  Children?: InputMaybe<EntityFilterDTO>;
  County?: InputMaybe<CountyFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentInventoryLevels?: InputMaybe<CurrentInventoryLevelFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  EntityActiveProducts?: InputMaybe<EntityActiveProductFilterDTO>;
  EntityAllocatedExposureRatios?: InputMaybe<EntityAllocatedExposureRatioFilterDTO>;
  EntityAllocatedExposureRatiosByOwner?: InputMaybe<EntityAllocatedExposureRatioByOwnerFilterDTO>;
  EntityAllocatedForecastedBrokerageVolumesHedged?: InputMaybe<EntityAllocatedForecastedBrokerageVolumeHedgedFilterDTO>;
  EntityAllocatedForecastedVolumesHedgedByOwner?: InputMaybe<EntityAllocatedForecastedVolumeHedgedByOwnerFilterDTO>;
  EntityForecastedPhysicalVolumeHedged?: InputMaybe<EntityForecastedPhysicalVolumeHedgedFilterDTO>;
  EntityUsers?: InputMaybe<EntityUserFilterDTO>;
  ExposureRatios?: InputMaybe<EntityExposureRatioFilterDTO>;
  FeedIngredientConsumedAndPurchasedVolumes?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
  FeedIngredientUsages?: InputMaybe<FeedIngredientUsageFilterDTO>;
  ForecastedHedgedAndCappedVolume?: InputMaybe<ForecastedHedgedAndCappedVolumeFilterDTO>;
  ForecastedMilkProduction?: InputMaybe<ForecastedMilkProductionFilterDTO>;
  ForecastedMilkProductionByMonth?: InputMaybe<ForecastedMilkProductionByMonthFilterDTO>;
  ForecastedMilkProductionByMonthRolledUp?: InputMaybe<ForecastedMilkProductionByMonthRolledUpFilterDTO>;
  ForecastedMilkUtilizationData?: InputMaybe<ForecastedMilkUtilizationDataFilterDTO>;
  ForecastedMilkUtilizations?: InputMaybe<ForecastedMilkUtilizationFilterDTO>;
  ForecastedProduction?: InputMaybe<ForecastedProductionFilterDTO>;
  GroupEntries?: InputMaybe<EntityGroupEntryFilterDTO>;
  InitiatedOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  InsurancePolicies?: InputMaybe<InsurancePolicyFilterDTO>;
  IntegrationInstallations?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  InventoryLevels?: InputMaybe<InventoryLevelFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  LedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  Members?: InputMaybe<EntityMemberFilterDTO>;
  MilkOrder?: InputMaybe<MilkOrderFilterDTO>;
  NOT?: InputMaybe<LocationEntityFilterDTO>;
  Notes?: InputMaybe<EntityNoteFilterDTO>;
  OR?: InputMaybe<Array<LocationEntityFilterDTO>>;
  OwnedOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  Parent?: InputMaybe<EntityFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  countyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  currentBasis?: InputMaybe<NullableNumberFilter>;
  customBasis?: InputMaybe<NullableNumberFilter>;
  customerId?: InputMaybe<StringFilter>;
  externalId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  identificationNumber?: InputMaybe<NumberFilter>;
  isVgs?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  milkOrderId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<NullableStringFilter>;
  rollingBasisNumberOfMonths?: InputMaybe<NumberFilter>;
  slug?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LocationEntityGroupByDTO = {
  AsEntity?: InputMaybe<EntityGroupByDTO>;
  County?: InputMaybe<CountyGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  MilkOrder?: InputMaybe<MilkOrderGroupByDTO>;
  Parent?: InputMaybe<EntityGroupByDTO>;
  countyId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  currentBasis?: InputMaybe<Scalars['Boolean']>;
  customBasis?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Boolean']>;
  rollingBasisNumberOfMonths?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LocationEntitySortByDTO = {
  AsEntity?: InputMaybe<EntitySortByDTO>;
  County?: InputMaybe<CountySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  MilkOrder?: InputMaybe<MilkOrderSortByDTO>;
  Parent?: InputMaybe<EntitySortByDTO>;
  countyId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  currentBasis?: InputMaybe<NullableSortByDirection>;
  customBasis?: InputMaybe<NullableSortByDirection>;
  customerId?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  identificationNumber?: InputMaybe<SortByDirection>;
  isVgs?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  milkOrderId?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  organizationId?: InputMaybe<SortByDirection>;
  parentId?: InputMaybe<NullableSortByDirection>;
  rollingBasisNumberOfMonths?: InputMaybe<SortByDirection>;
  slug?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LocationEntityUpdateDTO = {
  countyId?: InputMaybe<Scalars['String']>;
  /** A custom fixed basis value that will be used for currentBasis instead of a computed average of the past rollingBasisNumberOfMonths months' blended milk basis values. */
  customBasis?: InputMaybe<Scalars['Float']>;
  customerId?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  /** The number of previous months accross which blended milk basis values will be averaged to compute the current basis. This value is disregarded if customBasis is set. */
  rollingBasisNumberOfMonths?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type LrpActualValues = {
  __typename?: 'LrpActualValues';
  actualEndingValueAmount?: Maybe<Scalars['Float']>;
  classCode?: Maybe<Scalars['String']>;
  commodityCode?: Maybe<Scalars['String']>;
  commodityTypeCode?: Maybe<Scalars['String']>;
  commodityYear?: Maybe<Scalars['Int']>;
  countyCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  croppingPracticeCode?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  filingDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurancePlanCode?: Maybe<Scalars['String']>;
  intendedUseCode?: Maybe<Scalars['String']>;
  intervalCode?: Maybe<Scalars['String']>;
  irrigationPracticeCode?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['DateTime']>;
  organicPracticeCode?: Maybe<Scalars['String']>;
  practiceCode?: Maybe<Scalars['String']>;
  recordCategoryCode?: Maybe<Scalars['String']>;
  recordTypeCode?: Maybe<Scalars['String']>;
  reinsuranceYear: Scalars['Int'];
  releasedDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  subClassCode?: Maybe<Scalars['String']>;
  typeCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type LrpActualValuesDistinctOnDTO = {
  actualEndingValueAmount?: InputMaybe<Scalars['Boolean']>;
  classCode?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityTypeCode?: InputMaybe<Scalars['Boolean']>;
  commodityYear?: InputMaybe<Scalars['Boolean']>;
  countyCode?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  croppingPracticeCode?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  filingDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insurancePlanCode?: InputMaybe<Scalars['Boolean']>;
  intendedUseCode?: InputMaybe<Scalars['Boolean']>;
  intervalCode?: InputMaybe<Scalars['Boolean']>;
  irrigationPracticeCode?: InputMaybe<Scalars['Boolean']>;
  lastModified?: InputMaybe<Scalars['Boolean']>;
  organicPracticeCode?: InputMaybe<Scalars['Boolean']>;
  practiceCode?: InputMaybe<Scalars['Boolean']>;
  recordCategoryCode?: InputMaybe<Scalars['Boolean']>;
  recordTypeCode?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  releasedDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  subClassCode?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LrpActualValuesFilterDTO = {
  AND?: InputMaybe<Array<LrpActualValuesFilterDTO>>;
  NOT?: InputMaybe<LrpActualValuesFilterDTO>;
  OR?: InputMaybe<Array<LrpActualValuesFilterDTO>>;
  actualEndingValueAmount?: InputMaybe<NullableNumberFilter>;
  classCode?: InputMaybe<NullableStringFilter>;
  commodityCode?: InputMaybe<NullableStringFilter>;
  commodityTypeCode?: InputMaybe<NullableStringFilter>;
  commodityYear?: InputMaybe<NullableNumberFilter>;
  countyCode?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  croppingPracticeCode?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<NullableStringFilter>;
  filingDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  insurancePlanCode?: InputMaybe<NullableStringFilter>;
  intendedUseCode?: InputMaybe<NullableStringFilter>;
  intervalCode?: InputMaybe<NullableStringFilter>;
  irrigationPracticeCode?: InputMaybe<NullableStringFilter>;
  lastModified?: InputMaybe<NullableDateTimeFilter>;
  organicPracticeCode?: InputMaybe<NullableStringFilter>;
  practiceCode?: InputMaybe<NullableStringFilter>;
  recordCategoryCode?: InputMaybe<NullableStringFilter>;
  recordTypeCode?: InputMaybe<NullableStringFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  releasedDate?: InputMaybe<NullableStringFilter>;
  stateCode?: InputMaybe<NullableStringFilter>;
  subClassCode?: InputMaybe<NullableStringFilter>;
  typeCode?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LrpActualValuesSortByDTO = {
  actualEndingValueAmount?: InputMaybe<NullableSortByDirection>;
  classCode?: InputMaybe<NullableSortByDirection>;
  commodityCode?: InputMaybe<NullableSortByDirection>;
  commodityTypeCode?: InputMaybe<NullableSortByDirection>;
  commodityYear?: InputMaybe<NullableSortByDirection>;
  countyCode?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  croppingPracticeCode?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<NullableSortByDirection>;
  filingDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insurancePlanCode?: InputMaybe<NullableSortByDirection>;
  intendedUseCode?: InputMaybe<NullableSortByDirection>;
  intervalCode?: InputMaybe<NullableSortByDirection>;
  irrigationPracticeCode?: InputMaybe<NullableSortByDirection>;
  lastModified?: InputMaybe<NullableSortByDirection>;
  organicPracticeCode?: InputMaybe<NullableSortByDirection>;
  practiceCode?: InputMaybe<NullableSortByDirection>;
  recordCategoryCode?: InputMaybe<NullableSortByDirection>;
  recordTypeCode?: InputMaybe<NullableSortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  releasedDate?: InputMaybe<NullableSortByDirection>;
  stateCode?: InputMaybe<NullableSortByDirection>;
  subClassCode?: InputMaybe<NullableSortByDirection>;
  typeCode?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LrpDailyPriceDistinctOnDTO = {
  classCode?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityTypeCode?: InputMaybe<Scalars['Boolean']>;
  commodityYear?: InputMaybe<Scalars['Boolean']>;
  costPerCwtAmount?: InputMaybe<Scalars['Boolean']>;
  countyCode?: InputMaybe<Scalars['Boolean']>;
  coveragePrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  croppingPracticeCode?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  endorsementLengthCode?: InputMaybe<Scalars['Boolean']>;
  endorsementLengthCount?: InputMaybe<Scalars['Boolean']>;
  expectedEndingValueAmount?: InputMaybe<Scalars['Boolean']>;
  filingDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insurancePlanCode?: InputMaybe<Scalars['Boolean']>;
  intendedUseCode?: InputMaybe<Scalars['Boolean']>;
  intervalCode?: InputMaybe<Scalars['Boolean']>;
  irrigationPracticeCode?: InputMaybe<Scalars['Boolean']>;
  lastModified?: InputMaybe<Scalars['Boolean']>;
  livestockCoverageLevelPercent?: InputMaybe<Scalars['Boolean']>;
  livestockRate?: InputMaybe<Scalars['Boolean']>;
  organicPracticeCode?: InputMaybe<Scalars['Boolean']>;
  practiceCode?: InputMaybe<Scalars['Boolean']>;
  priceAdjustmentFactor?: InputMaybe<Scalars['Boolean']>;
  recordCategoryCode?: InputMaybe<Scalars['Boolean']>;
  recordTypeCode?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  releasedDate?: InputMaybe<Scalars['Boolean']>;
  rmaNoteNumber?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  subClassCode?: InputMaybe<Scalars['Boolean']>;
  targetHighWeight?: InputMaybe<Scalars['Boolean']>;
  targetLowWeight?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LrpDailyPrices = {
  __typename?: 'LrpDailyPrices';
  classCode?: Maybe<Scalars['String']>;
  commodityCode?: Maybe<Scalars['String']>;
  commodityTypeCode?: Maybe<Scalars['String']>;
  commodityYear?: Maybe<Scalars['Int']>;
  costPerCwtAmount?: Maybe<Scalars['Float']>;
  countyCode?: Maybe<Scalars['String']>;
  coveragePrice?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  croppingPracticeCode?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  endorsementLengthCode?: Maybe<Scalars['String']>;
  endorsementLengthCount?: Maybe<Scalars['Int']>;
  expectedEndingValueAmount?: Maybe<Scalars['Float']>;
  filingDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurancePlanCode?: Maybe<Scalars['String']>;
  intendedUseCode?: Maybe<Scalars['String']>;
  intervalCode?: Maybe<Scalars['String']>;
  irrigationPracticeCode?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['DateTime']>;
  livestockCoverageLevelPercent?: Maybe<Scalars['Float']>;
  livestockRate?: Maybe<Scalars['Float']>;
  organicPracticeCode?: Maybe<Scalars['String']>;
  practiceCode?: Maybe<Scalars['String']>;
  priceAdjustmentFactor?: Maybe<Scalars['Float']>;
  recordCategoryCode?: Maybe<Scalars['String']>;
  recordTypeCode?: Maybe<Scalars['String']>;
  reinsuranceYear: Scalars['Int'];
  releasedDate?: Maybe<Scalars['String']>;
  rmaNoteNumber?: Maybe<Scalars['Int']>;
  salesEffectiveDate?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  subClassCode?: Maybe<Scalars['String']>;
  targetHighWeight?: Maybe<Scalars['Float']>;
  targetLowWeight?: Maybe<Scalars['Float']>;
  typeCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type LrpDailyPricesFilterDTO = {
  AND?: InputMaybe<Array<LrpDailyPricesFilterDTO>>;
  NOT?: InputMaybe<LrpDailyPricesFilterDTO>;
  OR?: InputMaybe<Array<LrpDailyPricesFilterDTO>>;
  classCode?: InputMaybe<NullableStringFilter>;
  commodityCode?: InputMaybe<NullableStringFilter>;
  commodityTypeCode?: InputMaybe<NullableStringFilter>;
  commodityYear?: InputMaybe<NullableNumberFilter>;
  costPerCwtAmount?: InputMaybe<NullableNumberFilter>;
  countyCode?: InputMaybe<NullableStringFilter>;
  coveragePrice?: InputMaybe<NullableNumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  croppingPracticeCode?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<NullableStringFilter>;
  endorsementLengthCode?: InputMaybe<NullableStringFilter>;
  endorsementLengthCount?: InputMaybe<NullableNumberFilter>;
  expectedEndingValueAmount?: InputMaybe<NullableNumberFilter>;
  filingDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  insurancePlanCode?: InputMaybe<NullableStringFilter>;
  intendedUseCode?: InputMaybe<NullableStringFilter>;
  intervalCode?: InputMaybe<NullableStringFilter>;
  irrigationPracticeCode?: InputMaybe<NullableStringFilter>;
  lastModified?: InputMaybe<NullableDateTimeFilter>;
  livestockCoverageLevelPercent?: InputMaybe<NullableNumberFilter>;
  livestockRate?: InputMaybe<NullableNumberFilter>;
  organicPracticeCode?: InputMaybe<NullableStringFilter>;
  practiceCode?: InputMaybe<NullableStringFilter>;
  priceAdjustmentFactor?: InputMaybe<NullableNumberFilter>;
  recordCategoryCode?: InputMaybe<NullableStringFilter>;
  recordTypeCode?: InputMaybe<NullableStringFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  releasedDate?: InputMaybe<NullableStringFilter>;
  rmaNoteNumber?: InputMaybe<NullableNumberFilter>;
  salesEffectiveDate?: InputMaybe<NullableStringFilter>;
  stateCode?: InputMaybe<NullableStringFilter>;
  subClassCode?: InputMaybe<NullableStringFilter>;
  targetHighWeight?: InputMaybe<NullableNumberFilter>;
  targetLowWeight?: InputMaybe<NullableNumberFilter>;
  typeCode?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LrpDailyPricesSortByDTO = {
  classCode?: InputMaybe<NullableSortByDirection>;
  commodityCode?: InputMaybe<NullableSortByDirection>;
  commodityTypeCode?: InputMaybe<NullableSortByDirection>;
  commodityYear?: InputMaybe<NullableSortByDirection>;
  costPerCwtAmount?: InputMaybe<NullableSortByDirection>;
  countyCode?: InputMaybe<NullableSortByDirection>;
  coveragePrice?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  croppingPracticeCode?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<NullableSortByDirection>;
  endorsementLengthCode?: InputMaybe<NullableSortByDirection>;
  endorsementLengthCount?: InputMaybe<NullableSortByDirection>;
  expectedEndingValueAmount?: InputMaybe<NullableSortByDirection>;
  filingDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insurancePlanCode?: InputMaybe<NullableSortByDirection>;
  intendedUseCode?: InputMaybe<NullableSortByDirection>;
  intervalCode?: InputMaybe<NullableSortByDirection>;
  irrigationPracticeCode?: InputMaybe<NullableSortByDirection>;
  lastModified?: InputMaybe<NullableSortByDirection>;
  livestockCoverageLevelPercent?: InputMaybe<NullableSortByDirection>;
  livestockRate?: InputMaybe<NullableSortByDirection>;
  organicPracticeCode?: InputMaybe<NullableSortByDirection>;
  practiceCode?: InputMaybe<NullableSortByDirection>;
  priceAdjustmentFactor?: InputMaybe<NullableSortByDirection>;
  recordCategoryCode?: InputMaybe<NullableSortByDirection>;
  recordTypeCode?: InputMaybe<NullableSortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  releasedDate?: InputMaybe<NullableSortByDirection>;
  rmaNoteNumber?: InputMaybe<NullableSortByDirection>;
  salesEffectiveDate?: InputMaybe<NullableSortByDirection>;
  stateCode?: InputMaybe<NullableSortByDirection>;
  subClassCode?: InputMaybe<NullableSortByDirection>;
  targetHighWeight?: InputMaybe<NullableSortByDirection>;
  targetLowWeight?: InputMaybe<NullableSortByDirection>;
  typeCode?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

/** **This field is deprecated! Use "LrpInsuranceEndorsement" instead** */
export type LrpEndorsement = IInsuranceEndorsement & {
  __typename?: 'LrpEndorsement';
  AllocationRatios: Array<InsuranceEndorsementAllocationRatio>;
  HedgeStrategy?: Maybe<HedgeStrategy>;
  InsuranceAgent?: Maybe<InsuranceAgent>;
  InsurancePolicy: InsurancePolicy;
  InsurancePractice?: Maybe<InsurancePractice>;
  RevenueHedgeProducts: Array<Product>;
  RmaType?: Maybe<RmaType>;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipLrpPremiumKey?: Maybe<Scalars['String']>;
  commodityCode: Scalars['String'];
  commodityCodeYear?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['String']>;
  commodityPrice?: Maybe<Scalars['Float']>;
  commodityPriceIsEstimated?: Maybe<Scalars['Boolean']>;
  coverageEndDate: Scalars['String'];
  coveragePrice: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  declaredShare: Scalars['Float'];
  /** The ending date (inclusive) of the endorsement's coverage period */
  endDate: Scalars['String'];
  /** @deprecated Use "forecastedPnl" instead */
  estimatedPl?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  /** This is an Int at the database layer, but at the GraphQL layer to support ratio adjusted endorsements. Any manual inserts or updates to this field should be done with an Int value. */
  headCount: Scalars['Float'];
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** This will contain either the actual indemnity value if provided or the forecasted indemnity value otherwise. */
  indemnity?: Maybe<Scalars['Float']>;
  /** @deprecated Use "forecastedIndemnity" instead */
  indemnityAmount?: Maybe<Scalars['Float']>;
  inputSource: InputSource;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode: Scalars['String'];
  insurancePracticeId: Scalars['String'];
  insuredValue: Scalars['Float'];
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong: Scalars['Boolean'];
  isShort: Scalars['Boolean'];
  lengthInWeeks: Scalars['Int'];
  livestockRate: Scalars['Float'];
  /** This will contain either the actual pnl value if provided or the forecasted pnl value otherwise. */
  pnl?: Maybe<Scalars['Float']>;
  policyId: Scalars['String'];
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount: Scalars['Float'];
  reinsuranceYear: Scalars['Int'];
  /** This field recieves a default value from the transformer layer, not the database */
  revenueHedgeProductId: Scalars['String'];
  revenueHedgeProductIds: Array<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  rmaTypeId: Scalars['String'];
  salesEffectiveDate: Scalars['String'];
  /** The starting date (inclusive) of the endorsement's coverage period */
  startDate: Scalars['String'];
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode: Scalars['String'];
  stateId: Scalars['String'];
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount: Scalars['Float'];
  targetWeightCwt: Scalars['Float'];
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount: Scalars['Float'];
  type: TypeOfInsuranceEndorsement;
  typeCode: Scalars['String'];
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** **This field is deprecated! Use "LrpInsuranceEndorsement" instead** */
export type LrpEndorsement_AllocationRatiosArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementAllocationRatioSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
};

export type LrpEndorsementCreateDTO = {
  agentId?: InputMaybe<Scalars['String']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['String']>;
  commodityCode: Scalars['String'];
  coverageEndDate: Scalars['String'];
  coveragePrice: Scalars['Float'];
  declaredShare: Scalars['Float'];
  headCount: Scalars['Float'];
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode?: InputMaybe<Scalars['String']>;
  insurancePracticeId: Scalars['String'];
  insuredValue: Scalars['Float'];
  isLong: Scalars['Boolean'];
  lengthInWeeks: Scalars['Int'];
  livestockRate: Scalars['Float'];
  producerPremiumAmount: Scalars['Float'];
  reinsuranceYear: Scalars['Int'];
  revenueHedgeProductId?: InputMaybe<Scalars['String']>;
  rmaStatus?: InputMaybe<TypeOfRmaStatus>;
  rmaTypeId?: InputMaybe<Scalars['String']>;
  salesEffectiveDate: Scalars['String'];
  stateCode?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  subsidyAmount: Scalars['Float'];
  targetWeightCwt: Scalars['Float'];
  totalPremiumAmount: Scalars['Float'];
  typeCode: Scalars['String'];
};

export type LrpEndorsementDistinctOnDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityCodeYear?: InputMaybe<Scalars['Boolean']>;
  commodityId?: InputMaybe<Scalars['Boolean']>;
  commodityPrice?: InputMaybe<Scalars['Boolean']>;
  commodityPriceIsEstimated?: InputMaybe<Scalars['Boolean']>;
  coverageEndDate?: InputMaybe<Scalars['Boolean']>;
  coveragePrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  headCount?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredValue?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  lengthInWeeks?: InputMaybe<Scalars['Boolean']>;
  livestockRate?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductId?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  rmaTypeId?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  targetWeightCwt?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LrpEndorsementFilterDTO = {
  AND?: InputMaybe<Array<LrpEndorsementFilterDTO>>;
  Agent?: InputMaybe<InsuranceAgentFilterDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyFilterDTO>;
  InsuranceEndorsementAllocationRatios?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyFilterDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeFilterDTO>;
  NOT?: InputMaybe<LrpEndorsementFilterDTO>;
  OR?: InputMaybe<Array<LrpEndorsementFilterDTO>>;
  Policy?: InputMaybe<InsurancePolicyFilterDTO>;
  Practice?: InputMaybe<InsurancePracticeFilterDTO>;
  RevenueHedgeProduct?: InputMaybe<ProductFilterDTO>;
  RmaCommodity?: InputMaybe<RmaCommodityFilterDTO>;
  RmaType?: InputMaybe<RmaTypeFilterDTO>;
  State?: InputMaybe<StateFilterDTO>;
  actualIndemnity?: InputMaybe<NullableNumberFilter>;
  actualPnl?: InputMaybe<NullableNumberFilter>;
  agentId?: InputMaybe<NullableStringFilter>;
  aipLrpPremiumKey?: InputMaybe<NullableStringFilter>;
  commodityCode?: InputMaybe<StringFilter>;
  commodityCodeYear?: InputMaybe<NullableStringFilter>;
  commodityId?: InputMaybe<NullableStringFilter>;
  commodityPrice?: InputMaybe<NullableNumberFilter>;
  commodityPriceIsEstimated?: InputMaybe<NullableBooleanFilter>;
  coverageEndDate?: InputMaybe<StringFilter>;
  coveragePrice?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  declaredShare?: InputMaybe<NumberFilter>;
  endDate?: InputMaybe<StringFilter>;
  estimatedPl?: InputMaybe<NullableNumberFilter>;
  forecastedIndemnity?: InputMaybe<NullableNumberFilter>;
  forecastedPnl?: InputMaybe<NullableNumberFilter>;
  headCount?: InputMaybe<NumberFilter>;
  hedgeStrategyId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  indemnity?: InputMaybe<NullableNumberFilter>;
  indemnityAmount?: InputMaybe<NullableNumberFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  insurancePolicyId?: InputMaybe<StringFilter>;
  insurancePracticeCode?: InputMaybe<StringFilter>;
  insurancePracticeId?: InputMaybe<StringFilter>;
  insuredValue?: InputMaybe<NumberFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  isLong?: InputMaybe<BooleanFilter>;
  isShort?: InputMaybe<BooleanFilter>;
  lengthInWeeks?: InputMaybe<NumberFilter>;
  livestockRate?: InputMaybe<NumberFilter>;
  pnl?: InputMaybe<NullableNumberFilter>;
  policyId?: InputMaybe<StringFilter>;
  producerPremiumAmount?: InputMaybe<NumberFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  revenueHedgeProductId?: InputMaybe<StringFilter>;
  revenueHedgeProductIds?: InputMaybe<StringArrayFilter>;
  rmaStatus?: InputMaybe<NullableTypeOfRmaStatusFilter>;
  rmaTypeId?: InputMaybe<StringFilter>;
  salesEffectiveDate?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<StringFilter>;
  stateCode?: InputMaybe<StringFilter>;
  stateId?: InputMaybe<StringFilter>;
  subsidyAmount?: InputMaybe<NumberFilter>;
  targetWeightCwt?: InputMaybe<NumberFilter>;
  totalPremiumAmount?: InputMaybe<NumberFilter>;
  typeCode?: InputMaybe<StringFilter>;
  typeCodeYear?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LrpEndorsementGroupByDTO = {
  Agent?: InputMaybe<InsuranceAgentGroupByDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyGroupByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyGroupByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeGroupByDTO>;
  Policy?: InputMaybe<InsurancePolicyGroupByDTO>;
  Practice?: InputMaybe<InsurancePracticeGroupByDTO>;
  RevenueHedgeProduct?: InputMaybe<ProductGroupByDTO>;
  RmaCommodity?: InputMaybe<RmaCommodityGroupByDTO>;
  RmaType?: InputMaybe<RmaTypeGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityCodeYear?: InputMaybe<Scalars['Boolean']>;
  commodityId?: InputMaybe<Scalars['Boolean']>;
  commodityPrice?: InputMaybe<Scalars['Boolean']>;
  commodityPriceIsEstimated?: InputMaybe<Scalars['Boolean']>;
  coverageEndDate?: InputMaybe<Scalars['Boolean']>;
  coveragePrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  headCount?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredValue?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  lengthInWeeks?: InputMaybe<Scalars['Boolean']>;
  livestockRate?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductId?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  rmaTypeId?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  targetWeightCwt?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LrpEndorsementSortByDTO = {
  Agent?: InputMaybe<InsuranceAgentSortByDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementSortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategySortByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicySortByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeSortByDTO>;
  Policy?: InputMaybe<InsurancePolicySortByDTO>;
  Practice?: InputMaybe<InsurancePracticeSortByDTO>;
  RevenueHedgeProduct?: InputMaybe<ProductSortByDTO>;
  RmaCommodity?: InputMaybe<RmaCommoditySortByDTO>;
  RmaType?: InputMaybe<RmaTypeSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  actualIndemnity?: InputMaybe<NullableSortByDirection>;
  actualPnl?: InputMaybe<NullableSortByDirection>;
  agentId?: InputMaybe<NullableSortByDirection>;
  aipLrpPremiumKey?: InputMaybe<NullableSortByDirection>;
  commodityCode?: InputMaybe<SortByDirection>;
  commodityCodeYear?: InputMaybe<NullableSortByDirection>;
  commodityId?: InputMaybe<NullableSortByDirection>;
  commodityPrice?: InputMaybe<NullableSortByDirection>;
  commodityPriceIsEstimated?: InputMaybe<NullableSortByDirection>;
  coverageEndDate?: InputMaybe<SortByDirection>;
  coveragePrice?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  declaredShare?: InputMaybe<SortByDirection>;
  endDate?: InputMaybe<SortByDirection>;
  estimatedPl?: InputMaybe<NullableSortByDirection>;
  forecastedIndemnity?: InputMaybe<NullableSortByDirection>;
  forecastedPnl?: InputMaybe<NullableSortByDirection>;
  headCount?: InputMaybe<SortByDirection>;
  hedgeStrategyId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  indemnity?: InputMaybe<NullableSortByDirection>;
  indemnityAmount?: InputMaybe<NullableSortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  insurancePolicyId?: InputMaybe<SortByDirection>;
  insurancePracticeCode?: InputMaybe<SortByDirection>;
  insurancePracticeId?: InputMaybe<SortByDirection>;
  insuredValue?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  isLong?: InputMaybe<SortByDirection>;
  isShort?: InputMaybe<SortByDirection>;
  lengthInWeeks?: InputMaybe<SortByDirection>;
  livestockRate?: InputMaybe<SortByDirection>;
  pnl?: InputMaybe<NullableSortByDirection>;
  policyId?: InputMaybe<SortByDirection>;
  producerPremiumAmount?: InputMaybe<SortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  revenueHedgeProductId?: InputMaybe<SortByDirection>;
  rmaStatus?: InputMaybe<NullableSortByDirection>;
  rmaTypeId?: InputMaybe<SortByDirection>;
  salesEffectiveDate?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  stateCode?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  subsidyAmount?: InputMaybe<SortByDirection>;
  targetWeightCwt?: InputMaybe<SortByDirection>;
  totalPremiumAmount?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  typeCode?: InputMaybe<SortByDirection>;
  typeCodeYear?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LrpEndorsementUpdateDTO = {
  agentId?: InputMaybe<Scalars['String']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['String']>;
  commodityCode?: InputMaybe<Scalars['String']>;
  coverageEndDate?: InputMaybe<Scalars['String']>;
  coveragePrice?: InputMaybe<Scalars['Float']>;
  declaredShare?: InputMaybe<Scalars['Float']>;
  /** This is an Int at the database layer, but at the GraphQL layer to support ratio adjusted endorsements. Any manual inserts or updates to this field should be done with an Int value. */
  headCount?: InputMaybe<Scalars['Float']>;
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insurancePolicyId?: InputMaybe<Scalars['String']>;
  insurancePracticeCode?: InputMaybe<Scalars['String']>;
  insurancePracticeId?: InputMaybe<Scalars['String']>;
  insuredValue?: InputMaybe<Scalars['Float']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  lengthInWeeks?: InputMaybe<Scalars['Int']>;
  livestockRate?: InputMaybe<Scalars['Float']>;
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount?: InputMaybe<Scalars['Float']>;
  reinsuranceYear?: InputMaybe<Scalars['Int']>;
  /** This field recieves a default value from the transformer layer, not the database */
  revenueHedgeProductId?: InputMaybe<Scalars['String']>;
  rmaStatus?: InputMaybe<TypeOfRmaStatus>;
  rmaTypeId?: InputMaybe<Scalars['String']>;
  salesEffectiveDate?: InputMaybe<Scalars['String']>;
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount?: InputMaybe<Scalars['Float']>;
  targetWeightCwt?: InputMaybe<Scalars['Float']>;
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount?: InputMaybe<Scalars['Float']>;
  typeCode?: InputMaybe<Scalars['String']>;
};

export type LrpInsuranceEndorsement = IInsuranceEndorsement & {
  __typename?: 'LrpInsuranceEndorsement';
  AllocationRatios: Array<InsuranceEndorsementAllocationRatio>;
  HedgeStrategy?: Maybe<HedgeStrategy>;
  InsuranceAgent?: Maybe<InsuranceAgent>;
  InsurancePolicy: InsurancePolicy;
  InsurancePractice?: Maybe<InsurancePractice>;
  RevenueHedgeProduct: Product;
  RevenueHedgeProducts: Array<Product>;
  RmaCommodity?: Maybe<RmaCommodity>;
  RmaType?: Maybe<RmaType>;
  State: State;
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  agentId?: Maybe<Scalars['String']>;
  aipLrpPremiumKey?: Maybe<Scalars['String']>;
  commodityCode: Scalars['String'];
  commodityCodeYear?: Maybe<Scalars['String']>;
  commodityId?: Maybe<Scalars['String']>;
  commodityPrice?: Maybe<Scalars['Float']>;
  commodityPriceIsEstimated?: Maybe<Scalars['Boolean']>;
  coverageEndDate: Scalars['String'];
  coveragePrice: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  declaredShare: Scalars['Float'];
  /** The ending date (inclusive) of the endorsement's coverage period */
  endDate: Scalars['String'];
  /** @deprecated Use "forecastedPnl" instead */
  estimatedPl?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  /** This is an Int at the database layer, but at the GraphQL layer to support ratio adjusted endorsements. Any manual inserts or updates to this field should be done with an Int value. */
  headCount: Scalars['Float'];
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** This will contain either the actual indemnity value if provided or the forecasted indemnity value otherwise. */
  indemnity?: Maybe<Scalars['Float']>;
  /** @deprecated Use "forecastedIndemnity" instead */
  indemnityAmount?: Maybe<Scalars['Float']>;
  inputSource: InputSource;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode: Scalars['String'];
  insurancePracticeId: Scalars['String'];
  insuredValue: Scalars['Float'];
  integrationInstallationId?: Maybe<Scalars['String']>;
  isLong: Scalars['Boolean'];
  isShort: Scalars['Boolean'];
  lengthInWeeks: Scalars['Int'];
  livestockRate: Scalars['Float'];
  /** This will contain either the actual pnl value if provided or the forecasted pnl value otherwise. */
  pnl?: Maybe<Scalars['Float']>;
  policyId: Scalars['String'];
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount: Scalars['Float'];
  reinsuranceYear: Scalars['Int'];
  /** This field recieves a default value from the transformer layer, not the database */
  revenueHedgeProductId: Scalars['String'];
  revenueHedgeProductIds: Array<Scalars['String']>;
  rmaStatus?: Maybe<TypeOfRmaStatus>;
  rmaTypeId: Scalars['String'];
  salesEffectiveDate: Scalars['String'];
  /** The starting date (inclusive) of the endorsement's coverage period */
  startDate: Scalars['String'];
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode: Scalars['String'];
  stateId: Scalars['String'];
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount: Scalars['Float'];
  targetWeightCwt: Scalars['Float'];
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount: Scalars['Float'];
  type: TypeOfInsuranceEndorsement;
  typeCode: Scalars['String'];
  typeCodeYear?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type LrpInsuranceEndorsement_AllocationRatiosArgs = {
  orderBy?: InputMaybe<InsuranceEndorsementAllocationRatioSortByDTO>;
  where?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
};

export type LrpInsuranceEndorsementAggregateAllDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityCodeYear?: InputMaybe<Scalars['Boolean']>;
  commodityId?: InputMaybe<Scalars['Boolean']>;
  commodityPrice?: InputMaybe<Scalars['Boolean']>;
  coverageEndDate?: InputMaybe<Scalars['Boolean']>;
  coveragePrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  headCount?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredValue?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  lengthInWeeks?: InputMaybe<Scalars['Boolean']>;
  livestockRate?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductId?: InputMaybe<Scalars['Boolean']>;
  rmaTypeId?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  targetWeightCwt?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LrpInsuranceEndorsementAggregateDTO = {
  avg?: InputMaybe<LrpInsuranceEndorsementAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<LrpInsuranceEndorsementAggregateAllDTO>;
  min?: InputMaybe<LrpInsuranceEndorsementAggregateAllDTO>;
  sum?: InputMaybe<LrpInsuranceEndorsementAggregateNumericDTO>;
};

export type LrpInsuranceEndorsementAggregateNumericDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  commodityPrice?: InputMaybe<Scalars['Boolean']>;
  coveragePrice?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  headCount?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  insuredValue?: InputMaybe<Scalars['Boolean']>;
  lengthInWeeks?: InputMaybe<Scalars['Boolean']>;
  livestockRate?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  targetWeightCwt?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
};

export type LrpInsuranceEndorsementCreateDTO = {
  agentId?: InputMaybe<Scalars['String']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['String']>;
  commodityCode: Scalars['String'];
  coverageEndDate: Scalars['String'];
  coveragePrice: Scalars['Float'];
  declaredShare: Scalars['Float'];
  headCount: Scalars['Float'];
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insurancePolicyId: Scalars['String'];
  insurancePracticeCode?: InputMaybe<Scalars['String']>;
  insurancePracticeId: Scalars['String'];
  insuredValue: Scalars['Float'];
  isLong: Scalars['Boolean'];
  lengthInWeeks: Scalars['Int'];
  livestockRate: Scalars['Float'];
  producerPremiumAmount: Scalars['Float'];
  reinsuranceYear: Scalars['Int'];
  revenueHedgeProductId?: InputMaybe<Scalars['String']>;
  rmaStatus?: InputMaybe<TypeOfRmaStatus>;
  rmaTypeId?: InputMaybe<Scalars['String']>;
  salesEffectiveDate: Scalars['String'];
  stateCode?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  subsidyAmount: Scalars['Float'];
  targetWeightCwt: Scalars['Float'];
  totalPremiumAmount: Scalars['Float'];
  typeCode: Scalars['String'];
};

export type LrpInsuranceEndorsementDistinctOnDTO = {
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityCodeYear?: InputMaybe<Scalars['Boolean']>;
  commodityId?: InputMaybe<Scalars['Boolean']>;
  commodityPrice?: InputMaybe<Scalars['Boolean']>;
  commodityPriceIsEstimated?: InputMaybe<Scalars['Boolean']>;
  coverageEndDate?: InputMaybe<Scalars['Boolean']>;
  coveragePrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  headCount?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredValue?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  lengthInWeeks?: InputMaybe<Scalars['Boolean']>;
  livestockRate?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductId?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  rmaTypeId?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  targetWeightCwt?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LrpInsuranceEndorsementFilterDTO = {
  AND?: InputMaybe<Array<LrpInsuranceEndorsementFilterDTO>>;
  Agent?: InputMaybe<InsuranceAgentFilterDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyFilterDTO>;
  InsuranceEndorsementAllocationRatios?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyFilterDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeFilterDTO>;
  NOT?: InputMaybe<LrpInsuranceEndorsementFilterDTO>;
  OR?: InputMaybe<Array<LrpInsuranceEndorsementFilterDTO>>;
  Policy?: InputMaybe<InsurancePolicyFilterDTO>;
  Practice?: InputMaybe<InsurancePracticeFilterDTO>;
  RevenueHedgeProduct?: InputMaybe<ProductFilterDTO>;
  RmaCommodity?: InputMaybe<RmaCommodityFilterDTO>;
  RmaType?: InputMaybe<RmaTypeFilterDTO>;
  State?: InputMaybe<StateFilterDTO>;
  actualIndemnity?: InputMaybe<NullableNumberFilter>;
  actualPnl?: InputMaybe<NullableNumberFilter>;
  agentId?: InputMaybe<NullableStringFilter>;
  aipLrpPremiumKey?: InputMaybe<NullableStringFilter>;
  commodityCode?: InputMaybe<StringFilter>;
  commodityCodeYear?: InputMaybe<NullableStringFilter>;
  commodityId?: InputMaybe<NullableStringFilter>;
  commodityPrice?: InputMaybe<NullableNumberFilter>;
  commodityPriceIsEstimated?: InputMaybe<NullableBooleanFilter>;
  coverageEndDate?: InputMaybe<StringFilter>;
  coveragePrice?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  declaredShare?: InputMaybe<NumberFilter>;
  endDate?: InputMaybe<StringFilter>;
  estimatedPl?: InputMaybe<NullableNumberFilter>;
  forecastedIndemnity?: InputMaybe<NullableNumberFilter>;
  forecastedPnl?: InputMaybe<NullableNumberFilter>;
  headCount?: InputMaybe<NumberFilter>;
  hedgeStrategyId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  indemnity?: InputMaybe<NullableNumberFilter>;
  indemnityAmount?: InputMaybe<NullableNumberFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  insurancePolicyId?: InputMaybe<StringFilter>;
  insurancePracticeCode?: InputMaybe<StringFilter>;
  insurancePracticeId?: InputMaybe<StringFilter>;
  insuredValue?: InputMaybe<NumberFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  isLong?: InputMaybe<BooleanFilter>;
  isShort?: InputMaybe<BooleanFilter>;
  lengthInWeeks?: InputMaybe<NumberFilter>;
  livestockRate?: InputMaybe<NumberFilter>;
  pnl?: InputMaybe<NullableNumberFilter>;
  policyId?: InputMaybe<StringFilter>;
  producerPremiumAmount?: InputMaybe<NumberFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  revenueHedgeProductId?: InputMaybe<StringFilter>;
  revenueHedgeProductIds?: InputMaybe<StringArrayFilter>;
  rmaStatus?: InputMaybe<NullableTypeOfRmaStatusFilter>;
  rmaTypeId?: InputMaybe<StringFilter>;
  salesEffectiveDate?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<StringFilter>;
  stateCode?: InputMaybe<StringFilter>;
  stateId?: InputMaybe<StringFilter>;
  subsidyAmount?: InputMaybe<NumberFilter>;
  targetWeightCwt?: InputMaybe<NumberFilter>;
  totalPremiumAmount?: InputMaybe<NumberFilter>;
  typeCode?: InputMaybe<StringFilter>;
  typeCodeYear?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LrpInsuranceEndorsementGroupByDTO = {
  Agent?: InputMaybe<InsuranceAgentGroupByDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyGroupByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicyGroupByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeGroupByDTO>;
  Policy?: InputMaybe<InsurancePolicyGroupByDTO>;
  Practice?: InputMaybe<InsurancePracticeGroupByDTO>;
  RevenueHedgeProduct?: InputMaybe<ProductGroupByDTO>;
  RmaCommodity?: InputMaybe<RmaCommodityGroupByDTO>;
  RmaType?: InputMaybe<RmaTypeGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  actualIndemnity?: InputMaybe<Scalars['Boolean']>;
  actualPnl?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['Boolean']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityCodeYear?: InputMaybe<Scalars['Boolean']>;
  commodityId?: InputMaybe<Scalars['Boolean']>;
  commodityPrice?: InputMaybe<Scalars['Boolean']>;
  commodityPriceIsEstimated?: InputMaybe<Scalars['Boolean']>;
  coverageEndDate?: InputMaybe<Scalars['Boolean']>;
  coveragePrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  declaredShare?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  estimatedPl?: InputMaybe<Scalars['Boolean']>;
  forecastedIndemnity?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  headCount?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  indemnityAmount?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  insurancePolicyId?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeCode?: InputMaybe<Scalars['Boolean']>;
  insurancePracticeId?: InputMaybe<Scalars['Boolean']>;
  insuredValue?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  isShort?: InputMaybe<Scalars['Boolean']>;
  lengthInWeeks?: InputMaybe<Scalars['Boolean']>;
  livestockRate?: InputMaybe<Scalars['Boolean']>;
  pnl?: InputMaybe<Scalars['Boolean']>;
  policyId?: InputMaybe<Scalars['Boolean']>;
  producerPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductId?: InputMaybe<Scalars['Boolean']>;
  revenueHedgeProductIds?: InputMaybe<Scalars['Boolean']>;
  rmaStatus?: InputMaybe<Scalars['Boolean']>;
  rmaTypeId?: InputMaybe<Scalars['Boolean']>;
  salesEffectiveDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidyAmount?: InputMaybe<Scalars['Boolean']>;
  targetWeightCwt?: InputMaybe<Scalars['Boolean']>;
  totalPremiumAmount?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type LrpInsuranceEndorsementSortByDTO = {
  Agent?: InputMaybe<InsuranceAgentSortByDTO>;
  AsInsuranceEndorsement?: InputMaybe<InsuranceEndorsementSortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategySortByDTO>;
  InsurancePolicy?: InputMaybe<InsurancePolicySortByDTO>;
  InsurancePractice?: InputMaybe<InsurancePracticeSortByDTO>;
  Policy?: InputMaybe<InsurancePolicySortByDTO>;
  Practice?: InputMaybe<InsurancePracticeSortByDTO>;
  RevenueHedgeProduct?: InputMaybe<ProductSortByDTO>;
  RmaCommodity?: InputMaybe<RmaCommoditySortByDTO>;
  RmaType?: InputMaybe<RmaTypeSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  actualIndemnity?: InputMaybe<NullableSortByDirection>;
  actualPnl?: InputMaybe<NullableSortByDirection>;
  agentId?: InputMaybe<NullableSortByDirection>;
  aipLrpPremiumKey?: InputMaybe<NullableSortByDirection>;
  commodityCode?: InputMaybe<SortByDirection>;
  commodityCodeYear?: InputMaybe<NullableSortByDirection>;
  commodityId?: InputMaybe<NullableSortByDirection>;
  commodityPrice?: InputMaybe<NullableSortByDirection>;
  commodityPriceIsEstimated?: InputMaybe<NullableSortByDirection>;
  coverageEndDate?: InputMaybe<SortByDirection>;
  coveragePrice?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  declaredShare?: InputMaybe<SortByDirection>;
  endDate?: InputMaybe<SortByDirection>;
  estimatedPl?: InputMaybe<NullableSortByDirection>;
  forecastedIndemnity?: InputMaybe<NullableSortByDirection>;
  forecastedPnl?: InputMaybe<NullableSortByDirection>;
  headCount?: InputMaybe<SortByDirection>;
  hedgeStrategyId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  indemnity?: InputMaybe<NullableSortByDirection>;
  indemnityAmount?: InputMaybe<NullableSortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  insurancePolicyId?: InputMaybe<SortByDirection>;
  insurancePracticeCode?: InputMaybe<SortByDirection>;
  insurancePracticeId?: InputMaybe<SortByDirection>;
  insuredValue?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  isLong?: InputMaybe<SortByDirection>;
  isShort?: InputMaybe<SortByDirection>;
  lengthInWeeks?: InputMaybe<SortByDirection>;
  livestockRate?: InputMaybe<SortByDirection>;
  pnl?: InputMaybe<NullableSortByDirection>;
  policyId?: InputMaybe<SortByDirection>;
  producerPremiumAmount?: InputMaybe<SortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  revenueHedgeProductId?: InputMaybe<SortByDirection>;
  rmaStatus?: InputMaybe<NullableSortByDirection>;
  rmaTypeId?: InputMaybe<SortByDirection>;
  salesEffectiveDate?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  stateCode?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  subsidyAmount?: InputMaybe<SortByDirection>;
  targetWeightCwt?: InputMaybe<SortByDirection>;
  totalPremiumAmount?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  typeCode?: InputMaybe<SortByDirection>;
  typeCodeYear?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type LrpInsuranceEndorsementUpdateDTO = {
  agentId?: InputMaybe<Scalars['String']>;
  aipLrpPremiumKey?: InputMaybe<Scalars['String']>;
  commodityCode?: InputMaybe<Scalars['String']>;
  coverageEndDate?: InputMaybe<Scalars['String']>;
  coveragePrice?: InputMaybe<Scalars['Float']>;
  declaredShare?: InputMaybe<Scalars['Float']>;
  /** This is an Int at the database layer, but at the GraphQL layer to support ratio adjusted endorsements. Any manual inserts or updates to this field should be done with an Int value. */
  headCount?: InputMaybe<Scalars['Float']>;
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insurancePolicyId?: InputMaybe<Scalars['String']>;
  insurancePracticeCode?: InputMaybe<Scalars['String']>;
  insurancePracticeId?: InputMaybe<Scalars['String']>;
  insuredValue?: InputMaybe<Scalars['Float']>;
  isLong?: InputMaybe<Scalars['Boolean']>;
  lengthInWeeks?: InputMaybe<Scalars['Int']>;
  livestockRate?: InputMaybe<Scalars['Float']>;
  /** The net premium for after adjusting for subsidies */
  producerPremiumAmount?: InputMaybe<Scalars['Float']>;
  reinsuranceYear?: InputMaybe<Scalars['Int']>;
  /** This field recieves a default value from the transformer layer, not the database */
  revenueHedgeProductId?: InputMaybe<Scalars['String']>;
  rmaStatus?: InputMaybe<TypeOfRmaStatus>;
  rmaTypeId?: InputMaybe<Scalars['String']>;
  salesEffectiveDate?: InputMaybe<Scalars['String']>;
  /** The RMA code for the endorsement's state. Single-digit state codes will be prefixed with a leading 0. */
  stateCode?: InputMaybe<Scalars['String']>;
  stateId?: InputMaybe<Scalars['String']>;
  /** The amount by which the premiums for this endorsement are subsidized */
  subsidyAmount?: InputMaybe<Scalars['Float']>;
  targetWeightCwt?: InputMaybe<Scalars['Float']>;
  /** The original total premium for this endorsement, sans any adjustments */
  totalPremiumAmount?: InputMaybe<Scalars['Float']>;
  typeCode?: InputMaybe<Scalars['String']>;
};

export type LrpNewDailyPricesSubscription = INotificationSubscription & {
  __typename?: 'LrpNewDailyPricesSubscription';
  Notification: Array<Notification>;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  deviceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: TypeOfSubscription;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type LrpNewDailyPricesSubscription_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type LrpNewDailyPricesSubscriptionCreateDTO = {
  name?: InputMaybe<Scalars['String']>;
};

export type LrpNewDailyPricesSubscriptionDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deviceId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type LrpNewDailyPricesSubscriptionFilterDTO = {
  AND?: InputMaybe<Array<LrpNewDailyPricesSubscriptionFilterDTO>>;
  AsNotificationSubscription?: InputMaybe<NotificationSubscriptionFilterDTO>;
  NOT?: InputMaybe<LrpNewDailyPricesSubscriptionFilterDTO>;
  Notifications?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<LrpNewDailyPricesSubscriptionFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export type LrpNewDailyPricesSubscriptionSortByDTO = {
  AsNotificationSubscription?: InputMaybe<NotificationSubscriptionSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  deviceId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type LrpNewDailyPricesSubscriptionUpdateDTO = {
  name?: InputMaybe<Scalars['String']>;
};

export type LrpSubscription = INotificationSubscription & {
  __typename?: 'LrpSubscription';
  Notification: Array<Notification>;
  User?: Maybe<User>;
  commodityCode: Scalars['String'];
  commodityTypeCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deviceId?: Maybe<Scalars['String']>;
  headCount: Scalars['Int'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  stateCode: Scalars['String'];
  targetWeightCwt: Scalars['Float'];
  type: TypeOfSubscription;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type LrpSubscription_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type LrpSubscriptionCreateDTO = {
  commodityCode: Scalars['String'];
  commodityTypeCode: Scalars['String'];
  headCount: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  stateCode: Scalars['String'];
  targetWeightCwt: Scalars['Float'];
};

export type LrpSubscriptionDistinctOnDTO = {
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityTypeCode?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deviceId?: InputMaybe<Scalars['Boolean']>;
  headCount?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  targetWeightCwt?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type LrpSubscriptionFilterDTO = {
  AND?: InputMaybe<Array<LrpSubscriptionFilterDTO>>;
  AsNotificationSubscription?: InputMaybe<NotificationSubscriptionFilterDTO>;
  NOT?: InputMaybe<LrpSubscriptionFilterDTO>;
  Notifications?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<LrpSubscriptionFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  commodityCode?: InputMaybe<StringFilter>;
  commodityTypeCode?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceId?: InputMaybe<NullableStringFilter>;
  headCount?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<NullableStringFilter>;
  stateCode?: InputMaybe<StringFilter>;
  targetWeightCwt?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export type LrpSubscriptionSortByDTO = {
  AsNotificationSubscription?: InputMaybe<NotificationSubscriptionSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  commodityCode?: InputMaybe<SortByDirection>;
  commodityTypeCode?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deviceId?: InputMaybe<NullableSortByDirection>;
  headCount?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  stateCode?: InputMaybe<SortByDirection>;
  targetWeightCwt?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type LrpSubscriptionUpdateDTO = {
  commodityCode?: InputMaybe<Scalars['String']>;
  commodityTypeCode?: InputMaybe<Scalars['String']>;
  headCount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  targetWeightCwt?: InputMaybe<Scalars['Float']>;
};

export type MilkOrder = {
  __typename?: 'MilkOrder';
  Businesses: Array<CustomerEntity>;
  Counties: Array<County>;
  Locations: Array<LocationEntity>;
  createdAt: Scalars['DateTime'];
  federalOrderNumber?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type MilkOrder_BusinessesArgs = {
  orderBy?: InputMaybe<CustomerEntitySortByDTO>;
  where?: InputMaybe<CustomerEntityFilterDTO>;
};


export type MilkOrder_CountiesArgs = {
  orderBy?: InputMaybe<CountySortByDTO>;
  where?: InputMaybe<CountyFilterDTO>;
};


export type MilkOrder_LocationsArgs = {
  orderBy?: InputMaybe<LocationEntitySortByDTO>;
  where?: InputMaybe<LocationEntityFilterDTO>;
};

export type MilkOrderCreateDTO = {
  federalOrderNumber?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  regionId?: InputMaybe<Scalars['String']>;
};

export type MilkOrderDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  federalOrderNumber?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  regionId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type MilkOrderFilterDTO = {
  AND?: InputMaybe<Array<MilkOrderFilterDTO>>;
  Businesses?: InputMaybe<CustomerEntityFilterDTO>;
  Counties?: InputMaybe<CountyFilterDTO>;
  Locations?: InputMaybe<LocationEntityFilterDTO>;
  MilkUtilization?: InputMaybe<MilkUtilizationFilterDTO>;
  MilkUtilizations?: InputMaybe<MilkUtilizationFilterDTO>;
  NOT?: InputMaybe<MilkOrderFilterDTO>;
  OR?: InputMaybe<Array<MilkOrderFilterDTO>>;
  Region?: InputMaybe<RegionFilterDTO>;
  UsdaActualMilkPrices?: InputMaybe<UsdaActualMilkPriceFilterDTO>;
  UsdaMonthlyRegionalMilkComponentPrices?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceFilterDTO>;
  UsdaMonthlyRegionalMilkUtilizations?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  federalOrderNumber?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  regionId?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MilkOrderGroupByDTO = {
  Region?: InputMaybe<RegionGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  federalOrderNumber?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  regionId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type MilkOrderSortByDTO = {
  Region?: InputMaybe<RegionSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  federalOrderNumber?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  regionId?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type MilkOrderUpdateDTO = {
  federalOrderNumber?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['String']>;
};

export type MilkUtilizationFilterDTO = {
  AND?: InputMaybe<Array<MilkUtilizationFilterDTO>>;
  MilkOrder?: InputMaybe<MilkOrderFilterDTO>;
  NOT?: InputMaybe<MilkUtilizationFilterDTO>;
  OR?: InputMaybe<Array<MilkUtilizationFilterDTO>>;
  classiUtilization?: InputMaybe<NumberFilter>;
  classiiUtilization?: InputMaybe<NumberFilter>;
  classiiiUtilization?: InputMaybe<NumberFilter>;
  classivUtilization?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  milkOrderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum MonthSymbol {
  April = 'April',
  August = 'August',
  Cash = 'Cash',
  December = 'December',
  February = 'February',
  January = 'January',
  July = 'July',
  June = 'June',
  March = 'March',
  May = 'May',
  November = 'November',
  October = 'October',
  September = 'September'
}

export type MonthSymbolArrayFilterDTO = {
  /** Matches rows where the column contains ALL values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  contains?: InputMaybe<Array<MonthSymbol>>;
  /** Matches rows where the column array is an exact match to the given filter (order & duplicates matter!) */
  equals?: InputMaybe<Array<MonthSymbol>>;
  /** Matches rows where the column is contained within the values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  isContainedBy?: InputMaybe<Array<MonthSymbol>>;
  /** Filter results by the number of values contained within this array. `null` values will always be filtered out (even with a length filter = 0) */
  length?: InputMaybe<NumberFilter>;
  not?: InputMaybe<MonthSymbolArrayFilterDTO>;
  /** Matches rows where the column contains any of the values included within this filter */
  overlapsWith?: InputMaybe<Array<MonthSymbol>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cloneGrainFillOrderNotes?: Maybe<Array<GrainOrderNote>>;
  /** Create a new AccountNote row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'accountId'. Creating this concept requires WRITE access unto the same resource-type as the Account that owns this AccountNote (one of the following: [BROKERAGE, SWAP]). It also requires [BROKERAGE, SWAP] WRITE access unto the rows belonging to the 'accountId' column value. */
  createAccountNote?: Maybe<AccountNote>;
  createAnonymousNotification: Notification;
  /** Create a new ApplePushNotificationSetting row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createApplePushNotificationSetting?: Maybe<ApplePushNotificationSetting>;
  /** Create a new BasisInstrument row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createBasisInstrument?: Maybe<BasisInstrument>;
  /** Create a new BrokerageAccount row. null is returned if the user does not have appropriate write access for the given payload.  This resource is explicitly scoped via id. Creating this concept requires BROKERAGE WRITE access at the scope of this resource. It also requires BROKERAGE WRITE access unto the rows belonging to the permissioned foreign key columns ('ownerId', 'customerId'). */
  createBrokerageAccount?: Maybe<BrokerageAccount>;
  /** Create a new BusinessContact row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. Creating this concept requires BUSINESS WRITE access at the scope of this resource. It also requires BUSINESS WRITE access unto the rows belonging to the 'businessId' column value. */
  createBusinessContact?: Maybe<BusinessContact>;
  createBusinessMember?: Maybe<EntityMember>;
  /** Create a new Buyer row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createBuyer?: Maybe<Buyer>;
  /** Create a new CommitmentInstrument row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createCommitmentInstrument?: Maybe<CommitmentInstrument>;
  /** Create a new Crop row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. Creating this concept requires FEED_AND_CROP_PLAN WRITE access at the scope of this resource. It also requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the 'businessId' column value. */
  createCrop?: Maybe<Crop>;
  /** Create a new CropCategory row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createCropCategory?: Maybe<CropCategory>;
  /** Create a new CropHarvest row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'businessId' column value. */
  createCropHarvest?: Maybe<CropHarvest>;
  /** Create a new Customer row. null is returned if the user does not have appropriate write access for the given payload.  This resource is explicitly scoped via id. Creating this concept requires BUSINESS WRITE access at the scope of this resource. It also requires BUSINESS WRITE access unto the rows belonging to the 'parentId' column value. */
  createCustomer?: Maybe<CustomerEntity>;
  /**
   * Create a new DrpEndorsement row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Creating this concept requires INSURANCE WRITE access at the scope of this resource. It also requires INSURANCE WRITE access unto the rows belonging to the 'insurancePolicyId' column value.
   * @deprecated Use "DrpInsuranceEndorsement" instead
   */
  createDrpEndorsement?: Maybe<DrpInsuranceEndorsement>;
  /** Create a new DrpInsuranceEndorsement row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Creating this concept requires INSURANCE WRITE access at the scope of this resource. It also requires INSURANCE WRITE access unto the rows belonging to the 'insurancePolicyId' column value. */
  createDrpInsuranceEndorsement?: Maybe<DrpInsuranceEndorsement>;
  /** Create a new DrpNewDailyPricesSubscription row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createDrpNewDailyPricesSubscription?: Maybe<DrpNewDailyPricesSubscription>;
  /** Create a new DrpSubscription row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createDrpSubscription?: Maybe<DrpSubscription>;
  /** Create a new EmailNotificationSetting row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createEmailNotificationSetting?: Maybe<EmailNotificationSetting>;
  /** Create a new EntityGroup row. null is returned if the user does not have appropriate write access for the given payload.  This resource is explicitly scoped via id. Creating this concept requires ENTITY_GROUP WRITE access at the scope of this resource. It also requires ENTITY_GROUP WRITE access unto the rows belonging to the 'organizationId' column value. */
  createEntityGroup?: Maybe<EntityGroup>;
  /** Create a new EntityGroupEntry row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'entityGroupId' referencing EntityGroup. Creating this concept requires ENTITY_GROUP WRITE access at the scope of this resource. It also requires ENTITY_GROUP WRITE access unto the rows belonging to the 'entityGroupId' column value. */
  createEntityGroupEntry?: Maybe<EntityGroupEntry>;
  createEntityGroupMember?: Maybe<EntityMember>;
  /** @deprecated Deprecated in favor of type specific entity member create mutations. */
  createEntityMember?: Maybe<EntityMember>;
  /** Create a new EntityNote row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'entityId' referencing Entity. Creating this concept requires WRITE access unto the same resource-type as the Entity that owns this EntityNote (one of the following: [ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS]). It also requires [ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS] WRITE access unto the rows belonging to the 'entityId' column value. */
  createEntityNote?: Maybe<EntityNote>;
  createFeedIngredient?: Maybe<CurrentFeedIngredients>;
  /** Create a new Field row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. Creating this concept requires FEED_AND_CROP_PLAN WRITE access at the scope of this resource. It also requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the 'businessId' column value. */
  createField?: Maybe<Field>;
  /** Create a new ForwardContract row. null is returned if the user does not have appropriate write access for the given payload.  This resource is explicitly scoped via id. Creating this concept requires NEVER WRITE access at the scope of this resource. It also requires NEVER WRITE access unto the rows belonging to the permissioned foreign key columns ('ownerId', 'customerId'). */
  createForwardContract?: Maybe<ForwardContractAccount>;
  /** Create a new GrainBuyer row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createGrainBuyer?: Maybe<GrainBuyer>;
  /** Create a new GrainBuyerLocation row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createGrainBuyerLocation?: Maybe<GrainBuyerLocation>;
  /** Create a new GrainCropPlan row. null is returned if the user does not have appropriate write access for the given payload.  This resource is explicitly scoped via id. Creating this concept requires FEED_AND_CROP_PLAN WRITE access at the scope of this resource. It also requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the permissioned foreign key columns ('organizationId', 'customerId'). */
  createGrainCropPlan?: Maybe<GrainCropPlan>;
  /** Create a new GrainCropYear row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createGrainCropYear?: Maybe<GrainCropYear>;
  /** Create a new GrainFeedPlan row. null is returned if the user does not have appropriate write access for the given payload.  This resource is explicitly scoped via id. Creating this concept requires FEED_AND_CROP_PLAN WRITE access at the scope of this resource. It also requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the permissioned foreign key columns ('organizationId', 'customerId'). */
  createGrainFeedPlan?: Maybe<GrainFeedPlan>;
  /** Create a new GrainFillOrder row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'planId'. Creating this concept requires FEED_AND_CROP_PLAN WRITE access at the scope of this resource. It also requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the 'planId' column value. */
  createGrainFillOrder?: Maybe<GrainFillOrder>;
  /** Create a new GrainFillOrderModificationRequest row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. Creating this concept requires FEED_AND_CROP_PLAN WRITE access at the scope of this resource. It also requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the 'planId' column value. */
  createGrainFillOrderModificationRequest?: Maybe<GrainFillOrderModificationRequest>;
  /** Create a new GrainLocation row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createGrainLocation?: Maybe<GrainLocation>;
  /** Create a new GrainOrderNote row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. Creating this concept requires FEED_AND_CROP_PLAN WRITE access at the scope of this resource. It also requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the 'planId' column value. */
  createGrainOrderNote?: Maybe<GrainOrderNote>;
  /** Create a new GrainTargetOrder row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'planId'. Creating this concept requires FEED_AND_CROP_PLAN WRITE access at the scope of this resource. It also requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the 'planId' column value. */
  createGrainTargetOrder?: Maybe<GrainTargetOrder>;
  /** Create a new HedgeStrategy row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. Creating this concept requires Persisted WRITE access at the scope of this resource. It also requires Persisted WRITE access unto the rows belonging to the 'businessId' column value. */
  createHedgeStrategy?: Maybe<HedgeStrategy>;
  /** Create a new InsurancePolicy row. null is returned if the user does not have appropriate write access for the given payload.  This resource is explicitly scoped via id. Creating this concept requires INSURANCE WRITE access at the scope of this resource. It also requires INSURANCE WRITE access unto the rows belonging to the permissioned foreign key columns ('ownerId', 'customerId'). */
  createInsurancePolicy?: Maybe<InsurancePolicy>;
  /**
   * Set actual ledger entries for the given date range. THIS ENDPOINT SHOULD ONLY BE USED FOR SETTING INTRA-MONTH VALUES. Will return the number of daily entries created for the given date range.
   * @deprecated Use setLedgerActualEntriesByMonth instead
   */
  createLedgerActualEntries: Scalars['Int'];
  /** Create a new LedgerExpenseCategory row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'customerId' column value. */
  createLedgerExpenseCategory?: Maybe<LedgerExpenseCategory>;
  /** Create a new LedgerFeedCategory row. null is returned if the user does not have appropriate write access for the given payload. Ledger categories representing feed deductions and premiums. This resource is implicitly scoped by 'customerId'. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'customerId' column value. */
  createLedgerFeedCategory?: Maybe<LedgerFeedCategory>;
  /**
   * Set forecasted ledger entries for the given date range. THIS ENDPOINT SHOULD ONLY BE USED FOR SETTING INTRA-MONTH VALUES. Will return the number of daily entries created for the given date range.
   * @deprecated Use setLedgerForecastedEntriesByMonth instead
   */
  createLedgerForecastedEntries: Scalars['Int'];
  /** Create a new LedgerMilkCheckCategory row. null is returned if the user does not have appropriate write access for the given payload. Ledger categories representing milk check deductions and premiums. This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'customerId' column value. */
  createLedgerMilkCheckCategory?: Maybe<LedgerMilkCheckCategory>;
  /** Create a new LedgerRevenueCategory row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'customerId' column value. */
  createLedgerRevenueCategory?: Maybe<LedgerRevenueCategory>;
  /** Create a new LgmInsuranceEndorsement row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Creating this concept requires INSURANCE WRITE access at the scope of this resource. It also requires INSURANCE WRITE access unto the rows belonging to the 'insurancePolicyId' column value. */
  createLgmInsuranceEndorsement?: Maybe<LgmInsuranceEndorsement>;
  /** Create a new Location row. null is returned if the user does not have appropriate write access for the given payload.  This resource is explicitly scoped via id. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'customerId' column value. */
  createLocation?: Maybe<LocationEntity>;
  /**
   * Create a new LrpEndorsement row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Creating this concept requires INSURANCE WRITE access at the scope of this resource. It also requires INSURANCE WRITE access unto the rows belonging to the 'insurancePolicyId' column value.
   * @deprecated Use "LrpInsuranceEndorsement" instead
   */
  createLrpEndorsement?: Maybe<LrpInsuranceEndorsement>;
  /** Create a new LrpInsuranceEndorsement row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Creating this concept requires INSURANCE WRITE access at the scope of this resource. It also requires INSURANCE WRITE access unto the rows belonging to the 'insurancePolicyId' column value. */
  createLrpInsuranceEndorsement?: Maybe<LrpInsuranceEndorsement>;
  /** Create a new LrpNewDailyPricesSubscription row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createLrpNewDailyPricesSubscription?: Maybe<LrpNewDailyPricesSubscription>;
  /** Create a new LrpSubscription row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createLrpSubscription?: Maybe<LrpSubscription>;
  /** Create a new MilkOrder row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createMilkOrder?: Maybe<MilkOrder>;
  /** Create a new Notification row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createNotification?: Maybe<Notification>;
  createOneLivestockGroupFeedUsageForMaybeNewVersion?: Maybe<LivestockGroupFeedUsageResults>;
  /** Create a new OrderNote row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'accountId'. Creating this concept requires WRITE access unto the same resource-type as the Account that owns this OrderNote (one of the following: [BROKERAGE, SWAP]). It also requires [BROKERAGE, SWAP] WRITE access unto the rows belonging to the 'accountId' column value. */
  createOrderNote?: Maybe<OrderNote>;
  /** Creates an new organization entity and adds an entity membership for the requesting user. */
  createOrganizationEntity: OrganizationEntity;
  createOrganizationMember?: Maybe<EntityMember>;
  /** Create a new OtcContract row. null is returned if the user does not have appropriate write access for the given payload.  This resource is explicitly scoped via id. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the permissioned foreign key columns ('initiatingPartyId', 'agreeingPartyId'). */
  createOtcContract?: Maybe<OtcContract>;
  /** Create a new PhysicalFeedTransaction row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'businessId'. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'businessId' column value. */
  createPhysicalFeedTransaction?: Maybe<PhysicalFeedTransaction>;
  /** Create a new PhysicalSale row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'entityId' referencing Entity. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'entityId' column value. */
  createPhysicalSale?: Maybe<PhysicalSale>;
  /** Create a new PhysicalSaleFee row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'entityId' referencing Entity. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'entityId' column value. */
  createPhysicalSaleFee?: Maybe<PhysicalSaleFee>;
  /** Create a new Seller row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createSeller?: Maybe<Seller>;
  /** Create a new SmsNotificationSetting row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createSmsNotificationSetting?: Maybe<SmsNotificationSetting>;
  /** Create a new SpreadInstrument row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createSpreadInstrument?: Maybe<SpreadInstrument>;
  /** Create a new SpreadInstrumentLeg row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createSpreadInstrumentLeg?: Maybe<SpreadInstrumentLeg>;
  createSwap: Swap;
  /** Create a new SwapAccount row. null is returned if the user does not have appropriate write access for the given payload.  This resource is explicitly scoped via id. Creating this concept requires SWAP WRITE access at the scope of this resource. It also requires SWAP WRITE access unto the rows belonging to the permissioned foreign key columns ('ownerId', 'customerId'). */
  createSwapAccount?: Maybe<SwapAccount>;
  /** Create a new SwapDealer row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createSwapDealer?: Maybe<SwapDealer>;
  createSwaption: Swaption;
  /** Create a new SwineLivestockGroupContract row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'businessId' column value. */
  createSwineLivestockGroupContract?: Maybe<SwineLivestockGroupContract>;
  /** Create a new Transaction row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'accountId'. Creating this concept requires WRITE access unto the same resource-type as the Account that owns this Transaction (one of the following: [BROKERAGE, SWAP]). It also requires [BROKERAGE, SWAP] WRITE access unto the rows belonging to the 'accountId' column value. */
  createTransaction?: Maybe<Transaction>;
  /** Create a new TransactionFee row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'accountId'. Creating this concept requires WRITE access unto the same resource-type as the Account that owns this TransactionFee (one of the following: [BROKERAGE, SWAP]). It also requires [BROKERAGE, SWAP] WRITE access unto the rows belonging to the 'accountId' column value. */
  createTransactionFee?: Maybe<TransactionFee>;
  /** Create a new TransactionGroup row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. Creating this concept requires BROKERAGE WRITE access at the scope of this resource. It also requires BROKERAGE WRITE access unto the rows belonging to the 'id' column value. */
  createTransactionGroup?: Maybe<TransactionGroup>;
  /** Create a new TransactionNote row. null is returned if the user does not have appropriate write access for the given payload.  This resource is implicitly scoped by 'accountId'. Creating this concept requires WRITE access unto the same resource-type as the Account that owns this TransactionNote (one of the following: [BROKERAGE, SWAP]). It also requires [BROKERAGE, SWAP] WRITE access unto the rows belonging to the 'accountId' column value. */
  createTransactionNote?: Maybe<TransactionNote>;
  /** Create a new UsdaMarsReport row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createUsdaMarsReport?: Maybe<UsdaMarsReport>;
  /** Create a new UsdaMprReport row. null is returned if the user does not have appropriate write access for the given payload.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  createUsdaMprReport?: Maybe<UsdaMprReport>;
  deactivateNotification?: Maybe<Notification>;
  /** Delete an existing AccountNote row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'accountId'. Deleting this concept requires [BROKERAGE, SWAP] WRITE access unto the row that is being deleted. */
  deleteAccountNote?: Maybe<DeletedResource>;
  /** Delete an existing ActualBlendedMilkPrice row by id. null is returned if the given id doesn't exist or the user does not have write access unto it. The actual blended milk price for the given entity / date. This resource is implicitly scoped by 'entityId'. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deleteActualBlendedMilkPrice?: Maybe<DeletedResource>;
  deleteActualMilkProduction?: Maybe<DeletedResource>;
  deleteActualProduction?: Maybe<DeletedResource>;
  /** Delete an existing ApplePushNotificationSetting row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteApplePushNotificationSetting?: Maybe<DeletedResource>;
  /** Delete an existing BasisInstrument row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteBasisInstrument?: Maybe<DeletedResource>;
  /** Delete an existing BrokerageAccount row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Deleting this concept requires BROKERAGE WRITE access unto the row that is being deleted. */
  deleteBrokerageAccount?: Maybe<DeletedResource>;
  /** Delete an existing BusinessContact row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Deleting this concept requires BUSINESS WRITE access unto the row that is being deleted. */
  deleteBusinessContact?: Maybe<DeletedResource>;
  /** Delete an existing Buyer row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteBuyer?: Maybe<DeletedResource>;
  /** Delete an existing CommitmentInstrument row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteCommitmentInstrument?: Maybe<DeletedResource>;
  /** Delete an existing Crop row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Deleting this concept requires FEED_AND_CROP_PLAN WRITE access unto the row that is being deleted. */
  deleteCrop?: Maybe<DeletedResource>;
  /** Delete an existing CropCategory row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteCropCategory?: Maybe<DeletedResource>;
  /** Delete an existing CropHarvest row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deleteCropHarvest?: Maybe<DeletedResource>;
  /** Delete an existing Customer row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Deleting this concept requires BUSINESS WRITE access unto the row that is being deleted. */
  deleteCustomer?: Maybe<DeletedResource>;
  /**
   * Delete an existing DrpEndorsement row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Deleting this concept requires INSURANCE WRITE access unto the row that is being deleted.
   * @deprecated Use "DrpInsuranceEndorsement" instead
   */
  deleteDrpEndorsement?: Maybe<DeletedResource>;
  /** Delete an existing DrpInsuranceEndorsement row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Deleting this concept requires INSURANCE WRITE access unto the row that is being deleted. */
  deleteDrpInsuranceEndorsement?: Maybe<DeletedResource>;
  /** Delete an existing DrpNewDailyPricesSubscription row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteDrpNewDailyPricesSubscription?: Maybe<DeletedResource>;
  /** Delete an existing DrpSubscription row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteDrpSubscription?: Maybe<DeletedResource>;
  /** Delete an existing EmailNotificationSetting row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteEmailNotificationSetting?: Maybe<DeletedResource>;
  /** Delete an existing EntityGroup row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Deleting this concept requires ENTITY_GROUP WRITE access unto the row that is being deleted. */
  deleteEntityGroup?: Maybe<DeletedResource>;
  /** Delete an existing EntityGroupEntry row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'entityGroupId' referencing EntityGroup. Deleting this concept requires ENTITY_GROUP WRITE access unto the row that is being deleted. */
  deleteEntityGroupEntry?: Maybe<DeletedResource>;
  deleteEntityMember?: Maybe<DeletedResource>;
  /** Delete an existing EntityNote row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'entityId' referencing Entity. Deleting this concept requires [ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS] WRITE access unto the row that is being deleted. */
  deleteEntityNote?: Maybe<DeletedResource>;
  /** Delete an existing EntityUser row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Deleting this concept requires [ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS] WRITE access unto the row that is being deleted. */
  deleteEntityUser?: Maybe<DeletedResource>;
  deleteFeedIngredient?: Maybe<CurrentFeedIngredients>;
  /** Delete an existing Field row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Deleting this concept requires FEED_AND_CROP_PLAN WRITE access unto the row that is being deleted. */
  deleteField?: Maybe<DeletedResource>;
  /** Delete an existing FirebaseTokenNotificationSetting row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteFirebaseTokenNotificationSetting?: Maybe<DeletedResource>;
  deleteForecastedMilkProduction?: Maybe<DeletedResource>;
  /** @deprecated This mutation has been replaced by deleteForecastedMilkUtilizations */
  deleteForecastedMilkUtilization?: Maybe<DeletedResource>;
  deleteForecastedMilkUtilizations?: Maybe<DeletedResource>;
  deleteForecastedProduction?: Maybe<DeletedResource>;
  /** Delete an existing ForwardContract row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Deleting this concept requires NEVER WRITE access unto the row that is being deleted. */
  deleteForwardContract?: Maybe<DeletedResource>;
  /** Delete an existing GrainBuyer row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteGrainBuyer?: Maybe<DeletedResource>;
  /** Delete an existing GrainBuyerLocation row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteGrainBuyerLocation?: Maybe<DeletedResource>;
  /** Delete an existing GrainCropPlan row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Deleting this concept requires FEED_AND_CROP_PLAN WRITE access unto the row that is being deleted. */
  deleteGrainCropPlan?: Maybe<DeletedResource>;
  /** Delete an existing GrainCropYear row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteGrainCropYear?: Maybe<DeletedResource>;
  /** Delete an existing GrainFeedPlan row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Deleting this concept requires FEED_AND_CROP_PLAN WRITE access unto the row that is being deleted. */
  deleteGrainFeedPlan?: Maybe<DeletedResource>;
  /** Delete an existing GrainFillOrder row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'planId'. Deleting this concept requires FEED_AND_CROP_PLAN WRITE access unto the row that is being deleted. */
  deleteGrainFillOrder?: Maybe<DeletedResource>;
  /** Delete an existing GrainFillOrderModificationRequest row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Deleting this concept requires FEED_AND_CROP_PLAN WRITE access unto the row that is being deleted. */
  deleteGrainFillOrderModificationRequest?: Maybe<DeletedResource>;
  /** Delete an existing GrainLocation row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteGrainLocation?: Maybe<DeletedResource>;
  /** Delete an existing GrainOrderNote row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Deleting this concept requires FEED_AND_CROP_PLAN WRITE access unto the row that is being deleted. */
  deleteGrainOrderNote?: Maybe<DeletedResource>;
  /** Delete an existing GrainTargetOrder row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'planId'. Deleting this concept requires FEED_AND_CROP_PLAN WRITE access unto the row that is being deleted. */
  deleteGrainTargetOrder?: Maybe<DeletedResource>;
  /** Delete an existing HedgeStrategy row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Deleting this concept requires Persisted WRITE access unto the row that is being deleted. */
  deleteHedgeStrategy?: Maybe<DeletedResource>;
  /** Delete an existing InsurancePolicy row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Deleting this concept requires INSURANCE WRITE access unto the row that is being deleted. */
  deleteInsurancePolicy?: Maybe<DeletedResource>;
  /** Delete an existing InventoryLevel row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'locationId'. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deleteInventoryLevel?: Maybe<DeletedResource>;
  /** Delete an existing LedgerExpenseCategory row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deleteLedgerExpenseCategory?: Maybe<DeletedResource>;
  /** Delete an existing LedgerFeedCategory row by id. null is returned if the given id doesn't exist or the user does not have write access unto it. Ledger categories representing feed deductions and premiums. This resource is implicitly scoped by 'customerId'. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deleteLedgerFeedCategory?: Maybe<DeletedResource>;
  /** Delete an existing LedgerMilkCheckCategory row by id. null is returned if the given id doesn't exist or the user does not have write access unto it. Ledger categories representing milk check deductions and premiums. This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deleteLedgerMilkCheckCategory?: Maybe<DeletedResource>;
  /** Delete an existing LedgerRevenueCategory row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deleteLedgerRevenueCategory?: Maybe<DeletedResource>;
  /** Delete an existing LgmInsuranceEndorsement row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Deleting this concept requires INSURANCE WRITE access unto the row that is being deleted. */
  deleteLgmInsuranceEndorsement?: Maybe<DeletedResource>;
  /** Delete an existing Location row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deleteLocation?: Maybe<DeletedResource>;
  /**
   * Delete an existing LrpEndorsement row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Deleting this concept requires INSURANCE WRITE access unto the row that is being deleted.
   * @deprecated Use "LrpInsuranceEndorsement" instead
   */
  deleteLrpEndorsement?: Maybe<DeletedResource>;
  /** Delete an existing LrpInsuranceEndorsement row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Deleting this concept requires INSURANCE WRITE access unto the row that is being deleted. */
  deleteLrpInsuranceEndorsement?: Maybe<DeletedResource>;
  /** Delete an existing LrpNewDailyPricesSubscription row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteLrpNewDailyPricesSubscription?: Maybe<DeletedResource>;
  /** Delete an existing LrpSubscription row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteLrpSubscription?: Maybe<DeletedResource>;
  /** Delete an existing MilkOrder row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteMilkOrder?: Maybe<DeletedResource>;
  /** Delete an existing Notification row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteNotification?: Maybe<DeletedResource>;
  deleteOneLivestockGroupFeedUsageForMaybeNewVersion?: Maybe<LivestockGroupFeedUsageResults>;
  /** Delete an existing OrderNote row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'accountId'. Deleting this concept requires [BROKERAGE, SWAP] WRITE access unto the row that is being deleted. */
  deleteOrderNote?: Maybe<DeletedResource>;
  /** Delete an existing OtcContract row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deleteOtcContract?: Maybe<DeletedResource>;
  /** Delete an existing PhysicalFeedTransaction row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'businessId'. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deletePhysicalFeedTransaction?: Maybe<DeletedResource>;
  /** Delete an existing PhysicalSale row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'entityId' referencing Entity. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deletePhysicalSale?: Maybe<DeletedResource>;
  /** Delete an existing PhysicalSaleFee row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'entityId' referencing Entity. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deletePhysicalSaleFee?: Maybe<DeletedResource>;
  /** Delete an existing Seller row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteSeller?: Maybe<DeletedResource>;
  /** Delete an existing SmsNotificationSetting row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteSmsNotificationSetting?: Maybe<DeletedResource>;
  /** Delete an existing SwapAccount row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Deleting this concept requires SWAP WRITE access unto the row that is being deleted. */
  deleteSwapAccount?: Maybe<DeletedResource>;
  /** Delete an existing SwapDealer row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteSwapDealer?: Maybe<DeletedResource>;
  /** Delete an existing SwineLivestockGroupContract row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Deleting this concept requires OPERATIONS WRITE access unto the row that is being deleted. */
  deleteSwineLivestockGroupContract?: Maybe<DeletedResource>;
  /** Delete an existing Transaction row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'accountId'. Deleting this concept requires [BROKERAGE, SWAP] WRITE access unto the row that is being deleted. */
  deleteTransaction?: Maybe<DeletedResource>;
  /** Delete an existing TransactionFee row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'accountId'. Deleting this concept requires [BROKERAGE, SWAP] WRITE access unto the row that is being deleted. */
  deleteTransactionFee?: Maybe<DeletedResource>;
  /** Delete an existing TransactionGroup row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Deleting this concept requires BROKERAGE WRITE access unto the row that is being deleted. */
  deleteTransactionGroup?: Maybe<DeletedResource>;
  /** Delete an existing TransactionNote row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'accountId'. Deleting this concept requires [BROKERAGE, SWAP] WRITE access unto the row that is being deleted. */
  deleteTransactionNote?: Maybe<DeletedResource>;
  /** Delete an existing UsdaMarsReport row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteUsdaMarsReport?: Maybe<DeletedResource>;
  /** Delete an existing UsdaMprReport row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  deleteUsdaMprReport?: Maybe<DeletedResource>;
  mergeLivestockGroups: LivestockGroupMergeResults;
  reactivateNotification?: Maybe<Notification>;
  requestGrainTargetOrderCancellation?: Maybe<GrainTargetOrder>;
  /** Create or update existing ActualBlendedMilkPrice row by unique index. null is returned if the the user does not have write access for the given payload. The actual blended milk price for the given entity / date. This resource is implicitly scoped by 'entityId'. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'entityId' column value. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('entityId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'entityId' value. */
  setActualBlendedMilkPrice?: Maybe<ActualBlendedMilkPrice>;
  setAverageActualMilkProduction: Array<ActualMilkProduction>;
  setAverageActualProduction: Array<ActualProduction>;
  setAverageForecastedMilkProduction: Array<ForecastedMilkProduction>;
  setAverageForecastedProduction: Array<ForecastedProduction>;
  /** Sets the business relationship owner for the given customer entity. Must be an ever.ag email. If no email is provided, the relationship owner will be unset. */
  setBusinessRelationshipOwner?: Maybe<CustomerEntity>;
  /** Sets the buyer relations owner for the given target. Must be an ever.ag email. If no email is provided, the buyer relations owner will be unset. */
  setBuyerRelationsOwner?: Maybe<GrainTargetOrder>;
  setEntityUser?: Maybe<EntityUser>;
  /**
   * Will return the number of daily usage entries set for the given date range.
   * @deprecated Use setFeedIngredientActualUsageByMonth instead
   */
  setFeedIngredientActualUsage: Scalars['Int'];
  /** Will return the number of daily usage entries updated or created for the given months. */
  setFeedIngredientActualUsageByMonth: Scalars['Int'];
  /**
   * Will return the number of daily usage entries set for the given date range. Will throw if the customer is enrolled in VGS.
   * @deprecated Use setFeedIngredientForecastedUsageByMonth instead
   */
  setFeedIngredientForecastedUsage: Scalars['Int'];
  /** Will return the number of daily usage entries updated or created for the given months. Will throw if the customer is enrolled in VGS. */
  setFeedIngredientForecastedUsageByMonth: Scalars['Int'];
  /** Creates or updates pricing data for the provided ingredient */
  setFeedIngredientPriceByMonth: Scalars['Int'];
  /** Upsert a Firebase Registration Token. */
  setFirebaseTokenNotificationSetting?: Maybe<FirebaseTokenNotificationSetting>;
  /** @deprecated This mutation has been replaced by setManyForecastedMilkUtilizations. */
  setForecastedMilkUtilization?: Maybe<ForecastedMilkUtilization>;
  setGrainFillOrderModificationRequestBuyerRelationsOwner?: Maybe<GrainFillOrderModificationRequest>;
  /** Create or update existing InventoryLevel row by unique index. null is returned if the the user does not have write access for the given payload.  This resource is implicitly scoped by 'locationId'. Creating this concept requires OPERATIONS WRITE access at the scope of this resource. It also requires OPERATIONS WRITE access unto the rows belonging to the 'locationId' column value. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('locationId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'locationId' value. */
  setInventoryLevel?: Maybe<InventoryLevel>;
  /** Will return the number of daily entries created for the given date range. */
  setLedgerActualEntriesByMonth: Scalars['Int'];
  /** Will return the number of daily entries created for the given date range. */
  setLedgerForecastedEntriesByMonth: Scalars['Int'];
  setLivestockGroupFeedUsagesForBusiness: LivestockGroupFeedUsageSetResults;
  setManyForecastedMilkUtilizations?: Maybe<Array<ForecastedMilkUtilization>>;
  setMobileNotifications: Array<Notification>;
  setPositionComponentAllocations?: Maybe<Array<PositionComponentAllocation>>;
  setSwineLivestockPopulationChanges: SwineLivestockPopulationChangeSetResults;
  setTotalActualMilkProduction: Array<ActualMilkProduction>;
  setTotalActualProduction: Array<ActualProduction>;
  setTotalForecastedMilkProduction: Array<ForecastedMilkProduction>;
  setTotalForecastedProduction: Array<ForecastedProduction>;
  splitLivestockGroup: LivestockGroupSplitResults;
  /** Update an existing AccountNote row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'accountId'. Updating this concept requires WRITE access unto the same resource-type as the Account that owns this AccountNote (one of the following: [BROKERAGE, SWAP]). Updating this concept's foreign key ('accountId') requires [BROKERAGE, SWAP] WRITE access unto the rows belonging to the previous and new 'accountId' value. */
  updateAccountNote?: Maybe<AccountNote>;
  updateAnonymousNotification?: Maybe<Notification>;
  /** Update an existing ApplePushNotificationSetting row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateApplePushNotificationSetting?: Maybe<ApplePushNotificationSetting>;
  updateApplePushSubscription: VaultSubscription;
  /** Update an existing BasisInstrument row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateBasisInstrument?: Maybe<BasisInstrument>;
  /** Update an existing BrokerageAccount row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Updating this concept requires BROKERAGE WRITE access unto the row being updated. Updating any of this concept's foreign keys ('ownerId', 'customerId') requires BROKERAGE WRITE access unto the row(s) belonging to the new & old foreign key values. */
  updateBrokerageAccount?: Maybe<BrokerageAccount>;
  /** Update an existing BusinessContact row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Updating this concept requires BUSINESS WRITE access unto the row being updated. Updating this concept's foreign key ('businessId') requires BUSINESS WRITE access unto the rows belonging to the previous and new 'businessId' value. */
  updateBusinessContact?: Maybe<BusinessContact>;
  updateBusinessMember?: Maybe<EntityMember>;
  /** Update an existing Buyer row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateBuyer?: Maybe<Buyer>;
  /** Update an existing CommitmentInstrument row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateCommitmentInstrument?: Maybe<CommitmentInstrument>;
  /** Update an existing Crop row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Updating this concept requires FEED_AND_CROP_PLAN WRITE access unto the row being updated. Updating this concept's foreign key ('businessId') requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the previous and new 'businessId' value. */
  updateCrop?: Maybe<Crop>;
  /** Update an existing CropCategory row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateCropCategory?: Maybe<CropCategory>;
  /** Update an existing CropHarvest row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('businessId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'businessId' value. */
  updateCropHarvest?: Maybe<CropHarvest>;
  /** Update an existing Customer row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Updating this concept requires BUSINESS WRITE access unto the row being updated. Updating this concept's foreign key ('parentId') requires BUSINESS WRITE access unto the rows belonging to the previous and new 'parentId' value. */
  updateCustomer?: Maybe<CustomerEntity>;
  /**
   * Update an existing DrpEndorsement row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Updating this concept requires INSURANCE WRITE access unto the row being updated. Updating this concept's foreign key ('insurancePolicyId') requires INSURANCE WRITE access unto the rows belonging to the previous and new 'insurancePolicyId' value.
   * @deprecated Use "DrpInsuranceEndorsement" instead
   */
  updateDrpEndorsement?: Maybe<DrpInsuranceEndorsement>;
  /** Update an existing DrpInsuranceEndorsement row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Updating this concept requires INSURANCE WRITE access unto the row being updated. Updating this concept's foreign key ('insurancePolicyId') requires INSURANCE WRITE access unto the rows belonging to the previous and new 'insurancePolicyId' value. */
  updateDrpInsuranceEndorsement?: Maybe<DrpInsuranceEndorsement>;
  /** Update an existing DrpNewDailyPricesSubscription row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateDrpNewDailyPricesSubscription?: Maybe<DrpNewDailyPricesSubscription>;
  /** Update an existing DrpSubscription row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateDrpSubscription?: Maybe<DrpSubscription>;
  /** Update an existing EmailNotificationSetting row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateEmailNotificationSetting?: Maybe<EmailNotificationSetting>;
  /** Update an existing EntityGroup row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Updating this concept requires ENTITY_GROUP WRITE access unto the row being updated. Updating this concept's foreign key ('organizationId') requires ENTITY_GROUP WRITE access unto the rows belonging to the previous and new 'organizationId' value. */
  updateEntityGroup?: Maybe<EntityGroup>;
  /** Update an existing EntityGroupEntry row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'entityGroupId' referencing EntityGroup. Updating this concept requires ENTITY_GROUP WRITE access unto the row being updated. Updating this concept's foreign key ('entityGroupId') requires ENTITY_GROUP WRITE access unto the rows belonging to the previous and new 'entityGroupId' value. */
  updateEntityGroupEntry?: Maybe<EntityGroupEntry>;
  updateEntityGroupMember?: Maybe<EntityMember>;
  /** @deprecated Deprecated in favor of type specific entity member update mutations. */
  updateEntityMember?: Maybe<EntityMember>;
  /** Update an existing EntityNote row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'entityId' referencing Entity. Updating this concept requires WRITE access unto the same resource-type as the Entity that owns this EntityNote (one of the following: [ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS]). Updating this concept's foreign key ('entityId') requires [ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS] WRITE access unto the rows belonging to the previous and new 'entityId' value. */
  updateEntityNote?: Maybe<EntityNote>;
  /** Update an existing EntityUser row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Updating this concept requires WRITE access unto the same resource-type as the Entity that owns this EntityUser (one of the following: [ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS]). Updating this concept's foreign key ('entityId') requires [ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS] WRITE access unto the rows belonging to the previous and new 'entityId' value. */
  updateEntityUser?: Maybe<EntityUser>;
  updateFeedIngredient?: Maybe<CurrentFeedIngredients>;
  /** Update an existing Field row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Updating this concept requires FEED_AND_CROP_PLAN WRITE access unto the row being updated. Updating this concept's foreign key ('businessId') requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the previous and new 'businessId' value. */
  updateField?: Maybe<Field>;
  /** Update an existing ForwardContract row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Updating this concept requires NEVER WRITE access unto the row being updated. Updating any of this concept's foreign keys ('ownerId', 'customerId') requires NEVER WRITE access unto the row(s) belonging to the new & old foreign key values. */
  updateForwardContract?: Maybe<ForwardContractAccount>;
  /** Update an existing GrainBuyer row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateGrainBuyer?: Maybe<GrainBuyer>;
  /** Update an existing GrainBuyerLocation row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateGrainBuyerLocation?: Maybe<GrainBuyerLocation>;
  /** Update an existing GrainCropPlan row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Updating this concept requires FEED_AND_CROP_PLAN WRITE access unto the row being updated. Updating any of this concept's foreign keys ('organizationId', 'customerId') requires FEED_AND_CROP_PLAN WRITE access unto the row(s) belonging to the new & old foreign key values. */
  updateGrainCropPlan?: Maybe<GrainCropPlan>;
  /** Update an existing GrainCropYear row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateGrainCropYear?: Maybe<GrainCropYear>;
  /** Update an existing GrainFeedPlan row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Updating this concept requires FEED_AND_CROP_PLAN WRITE access unto the row being updated. Updating any of this concept's foreign keys ('organizationId', 'customerId') requires FEED_AND_CROP_PLAN WRITE access unto the row(s) belonging to the new & old foreign key values. */
  updateGrainFeedPlan?: Maybe<GrainFeedPlan>;
  /** Update an existing GrainFillOrder row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'planId'. Updating this concept requires FEED_AND_CROP_PLAN WRITE access unto the row being updated. Updating this concept's foreign key ('planId') requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the previous and new 'planId' value. */
  updateGrainFillOrder?: Maybe<GrainFillOrder>;
  updateGrainFillOrderModificationRequestStatus?: Maybe<GrainFillOrderModificationRequest>;
  /** Update an existing GrainLocation row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateGrainLocation?: Maybe<GrainLocation>;
  /** Update an existing GrainOrderNote row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Updating this concept requires FEED_AND_CROP_PLAN WRITE access unto the row being updated. Updating this concept's foreign key ('planId') requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the previous and new 'planId' value. */
  updateGrainOrderNote?: Maybe<GrainOrderNote>;
  /** Update an existing GrainTargetOrder row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'planId'. Updating this concept requires FEED_AND_CROP_PLAN WRITE access unto the row being updated. Updating this concept's foreign key ('planId') requires FEED_AND_CROP_PLAN WRITE access unto the rows belonging to the previous and new 'planId' value. */
  updateGrainTargetOrder?: Maybe<GrainTargetOrder>;
  /** Update an existing HedgeStrategy row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Updating this concept requires Persisted WRITE access unto the row being updated. Updating this concept's foreign key ('businessId') requires Persisted WRITE access unto the rows belonging to the previous and new 'businessId' value. */
  updateHedgeStrategy?: Maybe<HedgeStrategy>;
  /** Update an existing InsurancePolicy row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Updating this concept requires INSURANCE WRITE access unto the row being updated. Updating any of this concept's foreign keys ('ownerId', 'customerId') requires INSURANCE WRITE access unto the row(s) belonging to the new & old foreign key values. */
  updateInsurancePolicy?: Maybe<InsurancePolicy>;
  /** Update an existing InventoryLevel row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'locationId'. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('locationId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'locationId' value. */
  updateInventoryLevel?: Maybe<InventoryLevel>;
  /** Update an existing LedgerExpenseCategory row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('customerId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'customerId' value. */
  updateLedgerExpenseCategory?: Maybe<LedgerExpenseCategory>;
  /** Update an existing LedgerFeedCategory row by id. null is returned if the given id doesn't exist or the user does not have write access unto it. Ledger categories representing feed deductions and premiums. This resource is implicitly scoped by 'customerId'. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('customerId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'customerId' value. */
  updateLedgerFeedCategory?: Maybe<LedgerFeedCategory>;
  /** Update an existing LedgerMilkCheckCategory row by id. null is returned if the given id doesn't exist or the user does not have write access unto it. Ledger categories representing milk check deductions and premiums. This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('customerId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'customerId' value. */
  updateLedgerMilkCheckCategory?: Maybe<LedgerMilkCheckCategory>;
  /** Update an existing LedgerRevenueCategory row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('customerId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'customerId' value. */
  updateLedgerRevenueCategory?: Maybe<LedgerRevenueCategory>;
  /** Update an existing LgmInsuranceEndorsement row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Updating this concept requires INSURANCE WRITE access unto the row being updated. Updating this concept's foreign key ('insurancePolicyId') requires INSURANCE WRITE access unto the rows belonging to the previous and new 'insurancePolicyId' value. */
  updateLgmInsuranceEndorsement?: Maybe<LgmInsuranceEndorsement>;
  updateLivestockGroup: LivestockGroupUpdateResults;
  /** Update an existing Location row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('customerId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'customerId' value. */
  updateLocation?: Maybe<LocationEntity>;
  /**
   * Update an existing LrpEndorsement row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Updating this concept requires INSURANCE WRITE access unto the row being updated. Updating this concept's foreign key ('insurancePolicyId') requires INSURANCE WRITE access unto the rows belonging to the previous and new 'insurancePolicyId' value.
   * @deprecated Use "LrpInsuranceEndorsement" instead
   */
  updateLrpEndorsement?: Maybe<LrpInsuranceEndorsement>;
  /** Update an existing LrpInsuranceEndorsement row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Updating this concept requires INSURANCE WRITE access unto the row being updated. Updating this concept's foreign key ('insurancePolicyId') requires INSURANCE WRITE access unto the rows belonging to the previous and new 'insurancePolicyId' value. */
  updateLrpInsuranceEndorsement?: Maybe<LrpInsuranceEndorsement>;
  /** Update an existing LrpNewDailyPricesSubscription row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateLrpNewDailyPricesSubscription?: Maybe<LrpNewDailyPricesSubscription>;
  /** Update an existing LrpSubscription row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateLrpSubscription?: Maybe<LrpSubscription>;
  updateManyInsuranceEndorsementsHedgeStrategy: Count;
  updateManyTransactionsHedgeStrategy: UpdateManyTransactionsHedgeStrategyResults;
  /** Update an existing MilkOrder row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateMilkOrder?: Maybe<MilkOrder>;
  /** Update an existing Notification row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateNotification?: Maybe<Notification>;
  updateOneLivestockGroupFeedUsageForMaybeNewVersion?: Maybe<LivestockGroupFeedUsageResults>;
  /** Update an existing OrderNote row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'accountId'. Updating this concept requires WRITE access unto the same resource-type as the Account that owns this OrderNote (one of the following: [BROKERAGE, SWAP]). Updating this concept's foreign key ('accountId') requires [BROKERAGE, SWAP] WRITE access unto the rows belonging to the previous and new 'accountId' value. */
  updateOrderNote?: Maybe<OrderNote>;
  updateOrganizationMember?: Maybe<EntityMember>;
  /** Update an existing OtcContract row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating any of this concept's foreign keys ('initiatingPartyId', 'agreeingPartyId') requires OPERATIONS WRITE access unto the row(s) belonging to the new & old foreign key values. */
  updateOtcContract?: Maybe<OtcContract>;
  /** Update an existing PhysicalFeedTransaction row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'businessId'. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('businessId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'businessId' value. */
  updatePhysicalFeedTransaction?: Maybe<PhysicalFeedTransaction>;
  /** Update an existing PhysicalSale row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'entityId' referencing Entity. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('entityId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'entityId' value. */
  updatePhysicalSale?: Maybe<PhysicalSale>;
  /** Update an existing PhysicalSaleFee row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'entityId' referencing Entity. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('entityId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'entityId' value. */
  updatePhysicalSaleFee?: Maybe<PhysicalSaleFee>;
  /** Update an existing Seller row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateSeller?: Maybe<Seller>;
  /** Update an existing SmsNotificationSetting row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateSmsNotificationSetting?: Maybe<SmsNotificationSetting>;
  /** Update an existing SpreadInstrument row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateSpreadInstrument?: Maybe<SpreadInstrument>;
  /** Update an existing SpreadInstrumentLeg row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateSpreadInstrumentLeg?: Maybe<SpreadInstrumentLeg>;
  /** Update an existing SwapAccount row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is explicitly scoped via id. Updating this concept requires SWAP WRITE access unto the row being updated. Updating any of this concept's foreign keys ('ownerId', 'customerId') requires SWAP WRITE access unto the row(s) belonging to the new & old foreign key values. */
  updateSwapAccount?: Maybe<SwapAccount>;
  /** Update an existing SwapDealer row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateSwapDealer?: Maybe<SwapDealer>;
  /** Update an existing SwineLivestockGroupContract row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Updating this concept requires OPERATIONS WRITE access unto the row being updated. Updating this concept's foreign key ('businessId') requires OPERATIONS WRITE access unto the rows belonging to the previous and new 'businessId' value. */
  updateSwineLivestockGroupContract?: Maybe<SwineLivestockGroupContract>;
  /** Update an existing Transaction row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'accountId'. Updating this concept requires WRITE access unto the same resource-type as the Account that owns this Transaction (one of the following: [BROKERAGE, SWAP]). Updating this concept's foreign key ('accountId') requires [BROKERAGE, SWAP] WRITE access unto the rows belonging to the previous and new 'accountId' value. */
  updateTransaction?: Maybe<Transaction>;
  /** Update an existing TransactionFee row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'accountId'. Updating this concept requires WRITE access unto the same resource-type as the Account that owns this TransactionFee (one of the following: [BROKERAGE, SWAP]). Updating this concept's foreign key ('accountId') requires [BROKERAGE, SWAP] WRITE access unto the rows belonging to the previous and new 'accountId' value. */
  updateTransactionFee?: Maybe<TransactionFee>;
  /** Update an existing TransactionGroup row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. Updating this concept requires BROKERAGE WRITE access unto the row being updated. Updating this concept's foreign key ('id') requires BROKERAGE WRITE access unto the rows belonging to the previous and new 'id' value. */
  updateTransactionGroup?: Maybe<TransactionGroup>;
  /** Update an existing TransactionNote row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This resource is implicitly scoped by 'accountId'. Updating this concept requires WRITE access unto the same resource-type as the Account that owns this TransactionNote (one of the following: [BROKERAGE, SWAP]). Updating this concept's foreign key ('accountId') requires [BROKERAGE, SWAP] WRITE access unto the rows belonging to the previous and new 'accountId' value. */
  updateTransactionNote?: Maybe<TransactionNote>;
  /** Update an existing UsdaMarsReport row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateUsdaMarsReport?: Maybe<UsdaMarsReport>;
  /** Update an existing UsdaMprReport row by id. null is returned if the given id doesn't exist or the user does not have write access unto it.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  updateUsdaMprReport?: Maybe<UsdaMprReport>;
  updateUser?: Maybe<User>;
};


export type Mutation_cloneGrainFillOrderNotesArgs = {
  data: CloneGrainFillOrderNoteInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createAccountNoteArgs = {
  data: AccountNoteCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createAnonymousNotificationArgs = {
  Setting: NotificationSettingCreateDTO;
  Subscription: NotificationSubscriptionCreateDTO;
};


export type Mutation_createApplePushNotificationSettingArgs = {
  data: ApplePushNotificationSettingCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createBasisInstrumentArgs = {
  data: BasisInstrumentCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createBrokerageAccountArgs = {
  data: BrokerageAccountCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createBusinessContactArgs = {
  data: BusinessContactCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createBusinessMemberArgs = {
  data: BusinessMemberCreateDTO;
};


export type Mutation_createBuyerArgs = {
  data: BuyerCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createCommitmentInstrumentArgs = {
  data: CommitmentInstrumentCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createCropArgs = {
  data: CropCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createCropCategoryArgs = {
  data: CropCategoryCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createCropHarvestArgs = {
  data: CropHarvestCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createCustomerArgs = {
  data: CustomerEntityCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createDrpEndorsementArgs = {
  data: DrpEndorsementCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createDrpInsuranceEndorsementArgs = {
  data: DrpInsuranceEndorsementCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createDrpNewDailyPricesSubscriptionArgs = {
  data: DrpNewDailyPricesSubscriptionCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createDrpSubscriptionArgs = {
  data: DrpSubscriptionCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createEmailNotificationSettingArgs = {
  data: EmailNotificationSettingCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createEntityGroupArgs = {
  data: EntityGroupCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createEntityGroupEntryArgs = {
  data: EntityGroupEntryCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createEntityGroupMemberArgs = {
  data: EntityGroupMemberCreateDTO;
};


export type Mutation_createEntityMemberArgs = {
  data: CreateEntityMemberDTO;
};


export type Mutation_createEntityNoteArgs = {
  data: EntityNoteCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createFeedIngredientArgs = {
  data: FeedIngredientCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createFieldArgs = {
  data: FieldCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createForwardContractArgs = {
  data: ForwardContractAccountCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createGrainBuyerArgs = {
  data: GrainBuyerCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createGrainBuyerLocationArgs = {
  data: GrainBuyerLocationCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createGrainCropPlanArgs = {
  data: GrainCropPlanCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createGrainCropYearArgs = {
  data: GrainCropYearCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createGrainFeedPlanArgs = {
  data: GrainFeedPlanCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createGrainFillOrderArgs = {
  data: GrainFillOrderCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createGrainFillOrderModificationRequestArgs = {
  data: GrainFillOrderModificationRequestCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createGrainLocationArgs = {
  data: GrainLocationCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createGrainOrderNoteArgs = {
  data: GrainOrderNoteCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createGrainTargetOrderArgs = {
  data: GrainTargetOrderCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createHedgeStrategyArgs = {
  data: HedgeStrategyCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createInsurancePolicyArgs = {
  data: InsurancePolicyCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createLedgerActualEntriesArgs = {
  data: LedgerEntrySetDTO;
};


export type Mutation_createLedgerExpenseCategoryArgs = {
  data: LedgerExpenseCategoryCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createLedgerFeedCategoryArgs = {
  data: LedgerFeedCategoryCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createLedgerForecastedEntriesArgs = {
  data: LedgerEntrySetDTO;
};


export type Mutation_createLedgerMilkCheckCategoryArgs = {
  data: LedgerMilkCheckCategoryCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createLedgerRevenueCategoryArgs = {
  data: LedgerRevenueCategoryCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createLgmInsuranceEndorsementArgs = {
  data: LgmInsuranceEndorsementCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createLocationArgs = {
  data: LocationEntityCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createLrpEndorsementArgs = {
  data: LrpEndorsementCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createLrpInsuranceEndorsementArgs = {
  data: LrpInsuranceEndorsementCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createLrpNewDailyPricesSubscriptionArgs = {
  data: LrpNewDailyPricesSubscriptionCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createLrpSubscriptionArgs = {
  data: LrpSubscriptionCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createMilkOrderArgs = {
  data: MilkOrderCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createNotificationArgs = {
  data: NotificationCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createOneLivestockGroupFeedUsageForMaybeNewVersionArgs = {
  data: LivestockGroupFeedUsageCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createOrderNoteArgs = {
  data: OrderNoteCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createOrganizationEntityArgs = {
  data: OrganizationEntityCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createOrganizationMemberArgs = {
  data: OrganizationMemberCreateDTO;
};


export type Mutation_createOtcContractArgs = {
  data: OtcContractCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createPhysicalFeedTransactionArgs = {
  data: PhysicalFeedTransactionCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createPhysicalSaleArgs = {
  data: PhysicalSaleCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createPhysicalSaleFeeArgs = {
  data: PhysicalSaleFeeCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createSellerArgs = {
  data: SellerCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createSmsNotificationSettingArgs = {
  data: SmsNotificationSettingCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createSpreadInstrumentArgs = {
  data: SpreadInstrumentCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createSpreadInstrumentLegArgs = {
  data: SpreadInstrumentLegCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createSwapArgs = {
  data: SwapCreateDTO;
};


export type Mutation_createSwapAccountArgs = {
  data: SwapAccountCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createSwapDealerArgs = {
  data: SwapDealerCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createSwaptionArgs = {
  data: SwaptionCreateDTO;
};


export type Mutation_createSwineLivestockGroupContractArgs = {
  data: SwineLivestockGroupContractCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createTransactionArgs = {
  data: TransactionCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createTransactionFeeArgs = {
  data: TransactionFeeCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createTransactionGroupArgs = {
  data: TransactionGroupCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createTransactionNoteArgs = {
  data: TransactionNoteCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createUsdaMarsReportArgs = {
  data: UsdaMarsReportCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_createUsdaMprReportArgs = {
  data: UsdaMprReportCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deactivateNotificationArgs = {
  id: Scalars['String'];
};


export type Mutation_deleteAccountNoteArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteActualBlendedMilkPriceArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteActualMilkProductionArgs = {
  id: Scalars['String'];
};


export type Mutation_deleteActualProductionArgs = {
  id: Scalars['String'];
};


export type Mutation_deleteApplePushNotificationSettingArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteBasisInstrumentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteBrokerageAccountArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteBusinessContactArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteBuyerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteCommitmentInstrumentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteCropArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteCropCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteCropHarvestArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteCustomerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteDrpEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteDrpInsuranceEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteDrpNewDailyPricesSubscriptionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteDrpSubscriptionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteEmailNotificationSettingArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteEntityGroupArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteEntityGroupEntryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteEntityMemberArgs = {
  id: Scalars['String'];
};


export type Mutation_deleteEntityNoteArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteEntityUserArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteFeedIngredientArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteFieldArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteFirebaseTokenNotificationSettingArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteForecastedMilkProductionArgs = {
  id: Scalars['String'];
};


export type Mutation_deleteForecastedMilkUtilizationArgs = {
  date: Scalars['String'];
  locationId: Scalars['String'];
};


export type Mutation_deleteForecastedMilkUtilizationsArgs = {
  dates: Array<Scalars['String']>;
  locationId: Scalars['String'];
};


export type Mutation_deleteForecastedProductionArgs = {
  id: Scalars['String'];
};


export type Mutation_deleteForwardContractArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteGrainBuyerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteGrainBuyerLocationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteGrainCropPlanArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteGrainCropYearArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteGrainFeedPlanArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteGrainFillOrderArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteGrainFillOrderModificationRequestArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteGrainLocationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteGrainOrderNoteArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteGrainTargetOrderArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteHedgeStrategyArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteInsurancePolicyArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteInventoryLevelArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteLedgerExpenseCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteLedgerFeedCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteLedgerMilkCheckCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteLedgerRevenueCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteLgmInsuranceEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteLocationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteLrpEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteLrpInsuranceEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteLrpNewDailyPricesSubscriptionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteLrpSubscriptionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteMilkOrderArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteNotificationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteOneLivestockGroupFeedUsageForMaybeNewVersionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteOrderNoteArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteOtcContractArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deletePhysicalFeedTransactionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deletePhysicalSaleArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deletePhysicalSaleFeeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteSellerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteSmsNotificationSettingArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteSwapAccountArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteSwapDealerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteSwineLivestockGroupContractArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteTransactionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteTransactionFeeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteTransactionGroupArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteTransactionNoteArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteUsdaMarsReportArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_deleteUsdaMprReportArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_mergeLivestockGroupsArgs = {
  data: LivestockGroupMergeDTO;
};


export type Mutation_reactivateNotificationArgs = {
  id: Scalars['String'];
};


export type Mutation_requestGrainTargetOrderCancellationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setActualBlendedMilkPriceArgs = {
  data: ActualBlendedMilkPriceCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setAverageActualMilkProductionArgs = {
  endDate: Scalars['String'];
  grossProduction?: InputMaybe<ActualMilkProductionSetDTO>;
  productionPerCow?: InputMaybe<ActualMilkProductionSetDTO>;
  startDate: Scalars['String'];
};


export type Mutation_setAverageActualProductionArgs = {
  endDate: Scalars['String'];
  grossProduction?: InputMaybe<ActualProductionSetDTO>;
  productionPerUnit?: InputMaybe<ActualProductionSetDTO>;
  startDate: Scalars['String'];
};


export type Mutation_setAverageForecastedMilkProductionArgs = {
  endDate: Scalars['String'];
  grossProduction?: InputMaybe<ForecastedMilkProductionSetDTO>;
  productionPerCow?: InputMaybe<ForecastedMilkProductionSetDTO>;
  startDate: Scalars['String'];
};


export type Mutation_setAverageForecastedProductionArgs = {
  endDate: Scalars['String'];
  grossProduction?: InputMaybe<ForecastedProductionSetDTO>;
  productionPerUnit?: InputMaybe<ForecastedProductionSetDTO>;
  startDate: Scalars['String'];
};


export type Mutation_setBusinessRelationshipOwnerArgs = {
  data: CustomerEntityBusinessRelationshipOwnerSetInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setBuyerRelationsOwnerArgs = {
  data: GrainTargetOrderBuyerRelationsOwnerSetInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setEntityUserArgs = {
  data: EntityUserSetInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setFeedIngredientActualUsageArgs = {
  data: FeedIngredientUsageSetDto;
};


export type Mutation_setFeedIngredientActualUsageByMonthArgs = {
  data: FeedIngredientUsageSetByMonthInput;
};


export type Mutation_setFeedIngredientForecastedUsageArgs = {
  data: FeedIngredientUsageSetDto;
};


export type Mutation_setFeedIngredientForecastedUsageByMonthArgs = {
  data: FeedIngredientUsageSetByMonthInput;
};


export type Mutation_setFeedIngredientPriceByMonthArgs = {
  data: FeedIngredientPriceByMonthSetDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setFirebaseTokenNotificationSettingArgs = {
  data: FirebaseTokenNotificationSettingCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setForecastedMilkUtilizationArgs = {
  data: ForecastedMilkUtilizationSetDTO;
};


export type Mutation_setGrainFillOrderModificationRequestBuyerRelationsOwnerArgs = {
  data: GrainFillOrderModificationRequestBuyerRelationsOwnerSetInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setInventoryLevelArgs = {
  data: InventoryLevelCreateDTO;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setLedgerActualEntriesByMonthArgs = {
  data: LedgerEntrySetByMonth;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setLedgerForecastedEntriesByMonthArgs = {
  data: LedgerEntrySetByMonth;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setLivestockGroupFeedUsagesForBusinessArgs = {
  data: LivestockGroupFeedUsageSet;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_setManyForecastedMilkUtilizationsArgs = {
  data: ForecastedMilkUtilizationSetManyDTO;
};


export type Mutation_setMobileNotificationsArgs = {
  scopeId?: InputMaybe<Scalars['String']>;
  setting: FirebaseTokenNotificationSettingCreateDTO;
  subscriptions: NotificationSubscriptionSetManyInput;
};


export type Mutation_setPositionComponentAllocationsArgs = {
  data: PositionComponentAllocationsSetInput;
};


export type Mutation_setSwineLivestockPopulationChangesArgs = {
  data: SwineLivestockPopulationChangeSet;
};


export type Mutation_setTotalActualMilkProductionArgs = {
  endDate: Scalars['String'];
  grossProduction?: InputMaybe<ActualMilkProductionSetDTO>;
  productionPerCow?: InputMaybe<ActualMilkProductionSetDTO>;
  startDate: Scalars['String'];
};


export type Mutation_setTotalActualProductionArgs = {
  endDate: Scalars['String'];
  grossProduction?: InputMaybe<ActualProductionSetDTO>;
  productionPerUnit?: InputMaybe<ActualProductionSetDTO>;
  startDate: Scalars['String'];
};


export type Mutation_setTotalForecastedMilkProductionArgs = {
  endDate: Scalars['String'];
  grossProduction?: InputMaybe<ForecastedMilkProductionSetDTO>;
  productionPerCow?: InputMaybe<ForecastedMilkProductionSetDTO>;
  startDate: Scalars['String'];
};


export type Mutation_setTotalForecastedProductionArgs = {
  endDate: Scalars['String'];
  grossProduction?: InputMaybe<ForecastedProductionSetDTO>;
  productionPerUnit?: InputMaybe<ForecastedProductionSetDTO>;
  startDate: Scalars['String'];
};


export type Mutation_splitLivestockGroupArgs = {
  data: LivestockGroupSplitDTO;
  id: Scalars['String'];
};


export type Mutation_updateAccountNoteArgs = {
  data: AccountNoteUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateAnonymousNotificationArgs = {
  Setting: NotificationSettingCreateDTO;
  Subscription: NotificationSubscriptionCreateDTO;
  id: Scalars['String'];
};


export type Mutation_updateApplePushNotificationSettingArgs = {
  data: ApplePushNotificationSettingUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateApplePushSubscriptionArgs = {
  data: UpdateSubscriptionDTO;
  deviceId: Scalars['String'];
};


export type Mutation_updateBasisInstrumentArgs = {
  data: BasisInstrumentUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateBrokerageAccountArgs = {
  data: BrokerageAccountUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateBusinessContactArgs = {
  data: BusinessContactUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateBusinessMemberArgs = {
  data: BusinessMemberUpdateDTO;
  id: Scalars['String'];
};


export type Mutation_updateBuyerArgs = {
  data: BuyerUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateCommitmentInstrumentArgs = {
  data: CommitmentInstrumentUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateCropArgs = {
  data: CropUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateCropCategoryArgs = {
  data: CropCategoryUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateCropHarvestArgs = {
  data: CropHarvestUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateCustomerArgs = {
  data: CustomerEntityUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateDrpEndorsementArgs = {
  data: DrpEndorsementUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateDrpInsuranceEndorsementArgs = {
  data: DrpInsuranceEndorsementUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateDrpNewDailyPricesSubscriptionArgs = {
  data: DrpNewDailyPricesSubscriptionUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateDrpSubscriptionArgs = {
  data: DrpSubscriptionUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateEmailNotificationSettingArgs = {
  data: EmailNotificationSettingUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateEntityGroupArgs = {
  data: EntityGroupUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateEntityGroupEntryArgs = {
  data: EntityGroupEntryUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateEntityGroupMemberArgs = {
  data: EntityGroupMemberUpdateDTO;
  id: Scalars['String'];
};


export type Mutation_updateEntityMemberArgs = {
  data: UpdateEntityMemberDTO;
  id: Scalars['String'];
};


export type Mutation_updateEntityNoteArgs = {
  data: EntityNoteUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateEntityUserArgs = {
  data: EntityUserUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateFeedIngredientArgs = {
  data: FeedIngredientUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateFieldArgs = {
  data: FieldUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateForwardContractArgs = {
  data: ForwardContractAccountUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateGrainBuyerArgs = {
  data: GrainBuyerUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateGrainBuyerLocationArgs = {
  data: GrainBuyerLocationUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateGrainCropPlanArgs = {
  data: GrainCropPlanUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateGrainCropYearArgs = {
  data: GrainCropYearUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateGrainFeedPlanArgs = {
  data: GrainFeedPlanUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateGrainFillOrderArgs = {
  data: GrainFillOrderUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateGrainFillOrderModificationRequestStatusArgs = {
  data: GrainFillOrderModificationRequestStatusUpdateInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateGrainLocationArgs = {
  data: GrainLocationUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateGrainOrderNoteArgs = {
  data: GrainOrderNoteUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateGrainTargetOrderArgs = {
  data: GrainTargetOrderUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateHedgeStrategyArgs = {
  data: HedgeStrategyUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateInsurancePolicyArgs = {
  data: InsurancePolicyUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateInventoryLevelArgs = {
  data: InventoryLevelUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateLedgerExpenseCategoryArgs = {
  data: LedgerExpenseCategoryUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateLedgerFeedCategoryArgs = {
  data: LedgerFeedCategoryUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateLedgerMilkCheckCategoryArgs = {
  data: LedgerMilkCheckCategoryUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateLedgerRevenueCategoryArgs = {
  data: LedgerRevenueCategoryUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateLgmInsuranceEndorsementArgs = {
  data: LgmInsuranceEndorsementUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateLivestockGroupArgs = {
  data: LivestockGroupUpdateDTO;
  id: Scalars['String'];
};


export type Mutation_updateLocationArgs = {
  data: LocationEntityUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateLrpEndorsementArgs = {
  data: LrpEndorsementUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateLrpInsuranceEndorsementArgs = {
  data: LrpInsuranceEndorsementUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateLrpNewDailyPricesSubscriptionArgs = {
  data: LrpNewDailyPricesSubscriptionUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateLrpSubscriptionArgs = {
  data: LrpSubscriptionUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateManyInsuranceEndorsementsHedgeStrategyArgs = {
  data: UpdateManyInsuranceEndorsementsHedgeStrategyInput;
};


export type Mutation_updateManyTransactionsHedgeStrategyArgs = {
  data: UpdateManyTransactionsHedgeStrategyInput;
};


export type Mutation_updateMilkOrderArgs = {
  data: MilkOrderUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateNotificationArgs = {
  data: NotificationUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateOneLivestockGroupFeedUsageForMaybeNewVersionArgs = {
  data: LivestockGroupFeedUsageUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateOrderNoteArgs = {
  data: OrderNoteUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateOrganizationMemberArgs = {
  data: OrganizationMemberUpdateDTO;
  id: Scalars['String'];
};


export type Mutation_updateOtcContractArgs = {
  data: OtcContractUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updatePhysicalFeedTransactionArgs = {
  data: PhysicalFeedTransactionUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updatePhysicalSaleArgs = {
  data: PhysicalSaleUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updatePhysicalSaleFeeArgs = {
  data: PhysicalSaleFeeUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateSellerArgs = {
  data: SellerUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateSmsNotificationSettingArgs = {
  data: SmsNotificationSettingUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateSpreadInstrumentArgs = {
  data: SpreadInstrumentUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateSpreadInstrumentLegArgs = {
  data: SpreadInstrumentLegUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateSwapAccountArgs = {
  data: SwapAccountUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateSwapDealerArgs = {
  data: SwapDealerUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateSwineLivestockGroupContractArgs = {
  data: SwineLivestockGroupContractUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateTransactionArgs = {
  data: TransactionUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateTransactionFeeArgs = {
  data: TransactionFeeUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateTransactionGroupArgs = {
  data: TransactionGroupUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateTransactionNoteArgs = {
  data: TransactionNoteUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateUsdaMarsReportArgs = {
  data: UsdaMarsReportUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateUsdaMprReportArgs = {
  data: UsdaMprReportUpdateDTO;
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Mutation_updateUserArgs = {
  data: UserUpdateDTO;
  id: Scalars['String'];
};

export type NamedResource = {
  __typename?: 'NamedResource';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  Setting: INotificationSetting;
  Subscription: INotificationSubscription;
  createdAt: Scalars['DateTime'];
  /** Set to true when there have been multiple or specific kinds of errors when sending this notification */
  disabledByServer: Scalars['Boolean'];
  /** Set by DB via trigger when disabledByServer is set to true */
  disabledByServerAt?: Maybe<Scalars['DateTime']>;
  disabledByUser: Scalars['Boolean'];
  errorCount: Scalars['Int'];
  id: Scalars['ID'];
  /** Generated column: disabledByServer IS FALSE AND disabledByUser IS FALSE */
  isEnabled: Scalars['Boolean'];
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  settingId: Scalars['String'];
  subscriptionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationCreateDTO = {
  disabledByUser?: InputMaybe<Scalars['Boolean']>;
  settingId: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type NotificationDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  disabledByServer?: InputMaybe<Scalars['Boolean']>;
  disabledByServerAt?: InputMaybe<Scalars['Boolean']>;
  disabledByUser?: InputMaybe<Scalars['Boolean']>;
  errorCount?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  lastErrorAt?: InputMaybe<Scalars['Boolean']>;
  lastNotifiedAt?: InputMaybe<Scalars['Boolean']>;
  settingId?: InputMaybe<Scalars['Boolean']>;
  subscriptionId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationFilterDTO = {
  AND?: InputMaybe<Array<NotificationFilterDTO>>;
  NOT?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<NotificationFilterDTO>>;
  Setting?: InputMaybe<NotificationSettingFilterDTO>;
  Subscription?: InputMaybe<NotificationSubscriptionFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  disabledByServer?: InputMaybe<BooleanFilter>;
  disabledByServerAt?: InputMaybe<NullableDateTimeFilter>;
  disabledByUser?: InputMaybe<BooleanFilter>;
  errorCount?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  isEnabled?: InputMaybe<BooleanFilter>;
  lastErrorAt?: InputMaybe<NullableDateTimeFilter>;
  lastNotifiedAt?: InputMaybe<NullableDateTimeFilter>;
  settingId?: InputMaybe<StringFilter>;
  subscriptionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NotificationSetting = INotificationSetting & {
  __typename?: 'NotificationSetting';
  Notification: Array<Notification>;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: TypeOfNotification;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type NotificationSetting_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type NotificationSettingCreateDTO = {
  apnToken?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fcmRegistrationToken?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  type: TypeOfNotification;
};

export type NotificationSettingDistinctOnDTO = {
  apnToken?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deviceId?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  fcmRegistrationToken?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationSettingFilterDTO = {
  AND?: InputMaybe<Array<NotificationSettingFilterDTO>>;
  AsApplePushNotificationSetting?: InputMaybe<ApplePushNotificationSettingFilterDTO>;
  AsEmailNotificationSetting?: InputMaybe<EmailNotificationSettingFilterDTO>;
  AsFirebaseTokenNotificationSetting?: InputMaybe<FirebaseTokenNotificationSettingFilterDTO>;
  AsSmsNotificationSetting?: InputMaybe<SmsNotificationSettingFilterDTO>;
  NOT?: InputMaybe<NotificationSettingFilterDTO>;
  Notifications?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<NotificationSettingFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  apnToken?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceId?: InputMaybe<NullableStringFilter>;
  email?: InputMaybe<NullableStringFilter>;
  fcmRegistrationToken?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<NullableStringFilter>;
  phoneNumber?: InputMaybe<NullableStringFilter>;
  type?: InputMaybe<TypeOfNotificationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export type NotificationSettingSortByDTO = {
  AsApplePushNotificationSetting?: InputMaybe<ApplePushNotificationSettingSortByDTO>;
  AsEmailNotificationSetting?: InputMaybe<EmailNotificationSettingSortByDTO>;
  AsFirebaseTokenNotificationSetting?: InputMaybe<FirebaseTokenNotificationSettingSortByDTO>;
  AsSmsNotificationSetting?: InputMaybe<SmsNotificationSettingSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  apnToken?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deviceId?: InputMaybe<NullableSortByDirection>;
  email?: InputMaybe<NullableSortByDirection>;
  fcmRegistrationToken?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  phoneNumber?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type NotificationSortByDTO = {
  Setting?: InputMaybe<NotificationSettingSortByDTO>;
  Subscription?: InputMaybe<NotificationSubscriptionSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  disabledByServer?: InputMaybe<SortByDirection>;
  disabledByServerAt?: InputMaybe<NullableSortByDirection>;
  disabledByUser?: InputMaybe<SortByDirection>;
  errorCount?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isEnabled?: InputMaybe<SortByDirection>;
  lastErrorAt?: InputMaybe<NullableSortByDirection>;
  lastNotifiedAt?: InputMaybe<NullableSortByDirection>;
  settingId?: InputMaybe<SortByDirection>;
  subscriptionId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type NotificationSubscription = INotificationSubscription & {
  __typename?: 'NotificationSubscription';
  Notification: Array<Notification>;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  deviceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  type: TypeOfSubscription;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type NotificationSubscription_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type NotificationSubscriptionCreateDTO = {
  butterfat?: InputMaybe<Scalars['Float']>;
  commodityCode?: InputMaybe<Scalars['String']>;
  commodityTypeCode?: InputMaybe<Scalars['String']>;
  headCount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  protein?: InputMaybe<Scalars['Float']>;
  stateCode?: InputMaybe<Scalars['String']>;
  targetWeightCwt?: InputMaybe<Scalars['Float']>;
  type: TypeOfSubscription;
};

export type NotificationSubscriptionFilterDTO = {
  AND?: InputMaybe<Array<NotificationSubscriptionFilterDTO>>;
  AsDrpNewDailyPricesSubscription?: InputMaybe<DrpNewDailyPricesSubscriptionFilterDTO>;
  AsDrpSubscription?: InputMaybe<DrpSubscriptionFilterDTO>;
  AsLrpNewDailyPricesSubscription?: InputMaybe<LrpNewDailyPricesSubscriptionFilterDTO>;
  AsLrpSubscription?: InputMaybe<LrpSubscriptionFilterDTO>;
  NOT?: InputMaybe<NotificationSubscriptionFilterDTO>;
  Notifications?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<NotificationSubscriptionFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  butterfat?: InputMaybe<NullableNumberFilter>;
  commodityCode?: InputMaybe<NullableStringFilter>;
  commodityTypeCode?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceId?: InputMaybe<NullableStringFilter>;
  headCount?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<NullableStringFilter>;
  protein?: InputMaybe<NullableNumberFilter>;
  stateCode?: InputMaybe<NullableStringFilter>;
  targetWeightCwt?: InputMaybe<NullableNumberFilter>;
  type?: InputMaybe<TypeOfSubscriptionFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export type NotificationSubscriptionSetManyInput = {
  drpNewDailyPricesSubscription?: InputMaybe<DrpNewDailyPricesSubscriptionCreateDTO>;
  drpSubscription?: InputMaybe<DrpSubscriptionCreateDTO>;
  lrpNewDailyPricesSubscription?: InputMaybe<LrpNewDailyPricesSubscriptionCreateDTO>;
  lrpSubscription?: InputMaybe<LrpSubscriptionCreateDTO>;
};

export type NotificationSubscriptionSortByDTO = {
  AsDrpNewDailyPricesSubscription?: InputMaybe<DrpNewDailyPricesSubscriptionSortByDTO>;
  AsDrpSubscription?: InputMaybe<DrpSubscriptionSortByDTO>;
  AsLrpNewDailyPricesSubscription?: InputMaybe<LrpNewDailyPricesSubscriptionSortByDTO>;
  AsLrpSubscription?: InputMaybe<LrpSubscriptionSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  butterfat?: InputMaybe<NullableSortByDirection>;
  commodityCode?: InputMaybe<NullableSortByDirection>;
  commodityTypeCode?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deviceId?: InputMaybe<NullableSortByDirection>;
  headCount?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  protein?: InputMaybe<NullableSortByDirection>;
  stateCode?: InputMaybe<NullableSortByDirection>;
  targetWeightCwt?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type NotificationUpdateDTO = {
  disabledByUser?: InputMaybe<Scalars['Boolean']>;
  settingId?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['String']>;
};

export type NullableBooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NullableBooleanFilter>;
};

export type NullableDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NullableDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NullableGrainOrderDeliveryTermsFilter = {
  equals?: InputMaybe<GrainOrderDeliveryTerms>;
  in?: InputMaybe<Array<GrainOrderDeliveryTerms>>;
  not?: InputMaybe<GrainOrderDeliveryTerms>;
  notIn?: InputMaybe<Array<GrainOrderDeliveryTerms>>;
};

export type NullableNumberFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NullableNumberFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export enum NullableSortByDirection {
  Asc = 'Asc',
  AscNullsFirst = 'AscNullsFirst',
  AscNullsLast = 'AscNullsLast',
  Desc = 'Desc',
  DescNullsFirst = 'DescNullsFirst',
  DescNullsLast = 'DescNullsLast'
}

export type NullableStringFilter = {
  /** A case-insensitive filter for values that contain with the given string. _ and % may be provided as single and multi character wildcards, respectively. */
  contains?: InputMaybe<Scalars['String']>;
  /** A case-insensitive filter for values that end  with the given string. _ and % may be provided as single and multi character wildcards, respectively. */
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NullableStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Returns true or false depending on whether its pattern matches the given string. The given string pattern is interpreted using the SQL standard's definition of a regular expression. This operator only returns true if its pattern matches the entire string. Like LIKE, _ and % are used as wildcard characters denoting any single character and any string, respectively. */
  similar?: InputMaybe<Scalars['String']>;
  /** A case-insensitive filter for values that start with the given string. _ and % may be provided as single and multi character wildcards, respectively. */
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableTypeOfContractBasisFilter = {
  equals?: InputMaybe<TypeOfContractBasis>;
  in?: InputMaybe<Array<TypeOfContractBasis>>;
  not?: InputMaybe<TypeOfContractBasis>;
  notIn?: InputMaybe<Array<TypeOfContractBasis>>;
};

export type NullableTypeOfContractFilter = {
  equals?: InputMaybe<TypeOfContract>;
  in?: InputMaybe<Array<TypeOfContract>>;
  not?: InputMaybe<TypeOfContract>;
  notIn?: InputMaybe<Array<TypeOfContract>>;
};

export type NullableTypeOfCustodianAccountFilter = {
  equals?: InputMaybe<TypeOfCustodianAccount>;
  in?: InputMaybe<Array<TypeOfCustodianAccount>>;
  not?: InputMaybe<TypeOfCustodianAccount>;
  notIn?: InputMaybe<Array<TypeOfCustodianAccount>>;
};

export type NullableTypeOfGrainOrderStatusFilter = {
  equals?: InputMaybe<TypeOfGrainOrderStatus>;
  in?: InputMaybe<Array<TypeOfGrainOrderStatus>>;
  not?: InputMaybe<TypeOfGrainOrderStatus>;
  notIn?: InputMaybe<Array<TypeOfGrainOrderStatus>>;
};

export type NullableTypeOfLedgerCalculationFilter = {
  equals?: InputMaybe<TypeOfLedgerCalculation>;
  in?: InputMaybe<Array<TypeOfLedgerCalculation>>;
  not?: InputMaybe<TypeOfLedgerCalculation>;
  notIn?: InputMaybe<Array<TypeOfLedgerCalculation>>;
};

export type NullableTypeOfLivestockGroupFeedUsageFilter = {
  equals?: InputMaybe<TypeOfLivestockGroupFeedUsage>;
  in?: InputMaybe<Array<TypeOfLivestockGroupFeedUsage>>;
  not?: InputMaybe<TypeOfLivestockGroupFeedUsage>;
  notIn?: InputMaybe<Array<TypeOfLivestockGroupFeedUsage>>;
};

export type NullableTypeOfLivestockGroupFilter = {
  equals?: InputMaybe<TypeOfLivestockGroup>;
  in?: InputMaybe<Array<TypeOfLivestockGroup>>;
  not?: InputMaybe<TypeOfLivestockGroup>;
  notIn?: InputMaybe<Array<TypeOfLivestockGroup>>;
};

export type NullableTypeOfOptionFilter = {
  equals?: InputMaybe<TypeOfOption>;
  in?: InputMaybe<Array<TypeOfOption>>;
  not?: InputMaybe<TypeOfOption>;
  notIn?: InputMaybe<Array<TypeOfOption>>;
};

export type NullableTypeOfRmaStatusFilter = {
  equals?: InputMaybe<TypeOfRmaStatus>;
  in?: InputMaybe<Array<TypeOfRmaStatus>>;
  not?: InputMaybe<TypeOfRmaStatus>;
  notIn?: InputMaybe<Array<TypeOfRmaStatus>>;
};

export type NullableTypeOfSpreadFilter = {
  equals?: InputMaybe<TypeOfSpread>;
  in?: InputMaybe<Array<TypeOfSpread>>;
  not?: InputMaybe<TypeOfSpread>;
  notIn?: InputMaybe<Array<TypeOfSpread>>;
};

export type NumberArrayFilter = {
  /** Matches rows where the column contains ALL values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  contains?: InputMaybe<Array<Scalars['Float']>>;
  /** Matches rows where the column array is an exact match to the given filter (order & duplicates matter!) */
  equals?: InputMaybe<Array<Scalars['Float']>>;
  /** Matches rows where the column is contained within the values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  isContainedBy?: InputMaybe<Array<Scalars['Float']>>;
  /** Filter results by the number of values contained within this array. `null` values will always be filtered out (even with a length filter = 0) */
  length?: InputMaybe<NumberFilter>;
  not?: InputMaybe<NumberArrayFilter>;
  /** Matches rows where the column contains any of the values included within this filter */
  overlapsWith?: InputMaybe<Array<Scalars['Float']>>;
};

export type NumberFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NumberFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NumericAggregateActualBlendedMilkPriceDTO = {
  __typename?: 'NumericAggregateActualBlendedMilkPriceDTO';
  basis?: Maybe<Scalars['Float']>;
  classiiiBasis?: Maybe<Scalars['Float']>;
  classivBasis?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type NumericAggregateActualMilkProductionByMonthDTO = {
  __typename?: 'NumericAggregateActualMilkProductionByMonthDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  averageDailyProductionPerCow?: Maybe<Scalars['Float']>;
  butterfatPercent?: Maybe<Scalars['Float']>;
  exactNumberOfCows?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  numberOfCows?: Maybe<Scalars['Float']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateActualMilkProductionDTO = {
  __typename?: 'NumericAggregateActualMilkProductionDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  butterfatPercent?: Maybe<Scalars['Float']>;
  grossButterfat?: Maybe<Scalars['Float']>;
  grossOtherSolids?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProtein?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  numberOfCows?: Maybe<Scalars['Float']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  productionPerCow?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateActualProductionDTO = {
  __typename?: 'NumericAggregateActualProductionDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  productionPerUnit?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateAllocatedForecastedHedgedAndCappedVolumeDTO = {
  __typename?: 'NumericAggregateAllocatedForecastedHedgedAndCappedVolumeDTO';
  naturallyLongCapped?: Maybe<Scalars['Float']>;
  naturallyLongHedged?: Maybe<Scalars['Float']>;
  naturallyShortCapped?: Maybe<Scalars['Float']>;
  naturallyShortHedged?: Maybe<Scalars['Float']>;
};

export type NumericAggregateBrokerageAccountDTO = {
  __typename?: 'NumericAggregateBrokerageAccountDTO';
  cqgId?: Maybe<Scalars['Float']>;
  currentBalance?: Maybe<Scalars['Float']>;
  futuresMarketValue?: Maybe<Scalars['Float']>;
  liquidatingValue?: Maybe<Scalars['Float']>;
  marginCredit?: Maybe<Scalars['Float']>;
  marginExcessDeficit?: Maybe<Scalars['Float']>;
  openTradeEquity?: Maybe<Scalars['Float']>;
  optionsMarketValue?: Maybe<Scalars['Float']>;
  positionMargin?: Maybe<Scalars['Float']>;
  previousAccountBalance?: Maybe<Scalars['Float']>;
  purchasingPower?: Maybe<Scalars['Float']>;
  totalMargin?: Maybe<Scalars['Float']>;
};

export type NumericAggregateCropHarvestDTO = {
  __typename?: 'NumericAggregateCropHarvestDTO';
  acres?: Maybe<Scalars['Float']>;
  yieldPerAcre?: Maybe<Scalars['Float']>;
};

export type NumericAggregateCurrentAllocationPositionDTO = {
  __typename?: 'NumericAggregateCurrentAllocationPositionDTO';
  contractQuantity?: Maybe<Scalars['Float']>;
  currentWeightedAveragePrice?: Maybe<Scalars['Float']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  lifetimeWeightedAveragePrice?: Maybe<Scalars['Float']>;
  openSideWeightedAveragePrice?: Maybe<Scalars['Float']>;
  openWeightedAveragePrice?: Maybe<Scalars['Float']>;
  unitQuantity?: Maybe<Scalars['Float']>;
};

export type NumericAggregateCurrentPositionComponentDTO = {
  __typename?: 'NumericAggregateCurrentPositionComponentDTO';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
};

export type NumericAggregateCurrentPositionDTO = {
  __typename?: 'NumericAggregateCurrentPositionDTO';
  calculatedRealizedPnl?: Maybe<Scalars['Float']>;
  calculatedUnrealizedPnl?: Maybe<Scalars['Float']>;
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  currentWeightedAveragePrice?: Maybe<Scalars['Float']>;
  feeTotal?: Maybe<Scalars['Float']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  lifetimeWeightedAveragePrice?: Maybe<Scalars['Float']>;
  positionComponentRealizedPnl?: Maybe<Scalars['Float']>;
  positionComponentUnrealizedPnl?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  totalTradeValue?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  weightedAveragePrice?: Maybe<Scalars['Float']>;
};

export type NumericAggregateDerivedDrpEndorsementDTO = {
  __typename?: 'NumericAggregateDerivedDrpEndorsementDTO';
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classIiiGrossProtection?: Maybe<Scalars['Float']>;
  classIiiPounds?: Maybe<Scalars['Float']>;
  classIvGrossProtection?: Maybe<Scalars['Float']>;
  classIvPounds?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassIiiPrice?: Maybe<Scalars['Float']>;
  expectedClassIvPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  expectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassIii?: Maybe<Scalars['Float']>;
  indemnityEffectClassIv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassIiiPrice?: Maybe<Scalars['Float']>;
  insuredClassIvPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Float']>;
  rmaPracticeCode?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type NumericAggregateDrpEndorsementDTO = {
  __typename?: 'NumericAggregateDrpEndorsementDTO';
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Float']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type NumericAggregateDrpInsuranceEndorsementDTO = {
  __typename?: 'NumericAggregateDrpInsuranceEndorsementDTO';
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  actualYield?: Maybe<Scalars['Float']>;
  butterGrossProtection?: Maybe<Scalars['Float']>;
  butterfatGrossProtection?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  cheeseGrossProtection?: Maybe<Scalars['Float']>;
  classPriceWeightingFactor?: Maybe<Scalars['Float']>;
  classiiiGrossProtection?: Maybe<Scalars['Float']>;
  classiiiPounds?: Maybe<Scalars['Float']>;
  classivGrossProtection?: Maybe<Scalars['Float']>;
  classivPounds?: Maybe<Scalars['Float']>;
  componentPriceWeightingFactor?: Maybe<Scalars['Float']>;
  coverageLevelPercent?: Maybe<Scalars['Float']>;
  currentYield?: Maybe<Scalars['Float']>;
  declaredButterfatTest?: Maybe<Scalars['Float']>;
  declaredCoveredMilkProduction?: Maybe<Scalars['Float']>;
  declaredProteinTest?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  effectiveExpectedRevenueGuarantee?: Maybe<Scalars['Float']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  expectedButterPrice?: Maybe<Scalars['Float']>;
  expectedButterfatPrice?: Maybe<Scalars['Float']>;
  expectedCheesePrice?: Maybe<Scalars['Float']>;
  expectedClassiiiPrice?: Maybe<Scalars['Float']>;
  expectedClassivPrice?: Maybe<Scalars['Float']>;
  expectedNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  expectedOtherSolidsPrice?: Maybe<Scalars['Float']>;
  expectedProteinPrice?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  indemnityEffectButter?: Maybe<Scalars['Float']>;
  indemnityEffectButterfat?: Maybe<Scalars['Float']>;
  indemnityEffectCheese?: Maybe<Scalars['Float']>;
  indemnityEffectClassiii?: Maybe<Scalars['Float']>;
  indemnityEffectClassiv?: Maybe<Scalars['Float']>;
  indemnityEffectExcessProtein?: Maybe<Scalars['Float']>;
  indemnityEffectNonfatSolids?: Maybe<Scalars['Float']>;
  indemnityEffectOtherSolids?: Maybe<Scalars['Float']>;
  indemnityEffectProtein?: Maybe<Scalars['Float']>;
  insuredButterPrice?: Maybe<Scalars['Float']>;
  insuredButterfatPrice?: Maybe<Scalars['Float']>;
  insuredCheesePrice?: Maybe<Scalars['Float']>;
  insuredClassiiiPrice?: Maybe<Scalars['Float']>;
  insuredClassivPrice?: Maybe<Scalars['Float']>;
  insuredNonfatSolidsPrice?: Maybe<Scalars['Float']>;
  insuredOtherSolidsPrice?: Maybe<Scalars['Float']>;
  insuredProteinPrice?: Maybe<Scalars['Float']>;
  insuredYield?: Maybe<Scalars['Float']>;
  lastYield?: Maybe<Scalars['Float']>;
  nonfatSolidsGrossProtection?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherPoundsGrossProtection?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  protectionFactor?: Maybe<Scalars['Float']>;
  proteinGrossProtection?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Float']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  yieldAdjustmentFactor?: Maybe<Scalars['Float']>;
};

export type NumericAggregateEntityAllocatedExposureRatioByOwnerDTO = {
  __typename?: 'NumericAggregateEntityAllocatedExposureRatioByOwnerDTO';
  brokerageVolumeCapped?: Maybe<Scalars['Float']>;
  brokerageVolumeHedged?: Maybe<Scalars['Float']>;
  drpVolumeCapped?: Maybe<Scalars['Float']>;
  drpVolumeHedged?: Maybe<Scalars['Float']>;
  exposure?: Maybe<Scalars['Float']>;
  inventoryExposure?: Maybe<Scalars['Float']>;
  lgmVolumeCapped?: Maybe<Scalars['Float']>;
  lgmVolumeHedged?: Maybe<Scalars['Float']>;
  lrpVolumeCapped?: Maybe<Scalars['Float']>;
  lrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeCapped?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeHedged?: Maybe<Scalars['Float']>;
  percentLgmVolumeCapped?: Maybe<Scalars['Float']>;
  percentLgmVolumeHedged?: Maybe<Scalars['Float']>;
  percentLrpVolumeCapped?: Maybe<Scalars['Float']>;
  percentLrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeCapped?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeHedged?: Maybe<Scalars['Float']>;
  physicalVolumeCapped?: Maybe<Scalars['Float']>;
  physicalVolumeHedged?: Maybe<Scalars['Float']>;
  productionExposure?: Maybe<Scalars['Float']>;
  totalPercentOpen?: Maybe<Scalars['Float']>;
  totalPercentVolumeCapped?: Maybe<Scalars['Float']>;
  totalPercentVolumeHedged?: Maybe<Scalars['Float']>;
  totalVolumeCapped?: Maybe<Scalars['Float']>;
  totalVolumeHedged?: Maybe<Scalars['Float']>;
};

export type NumericAggregateEntityAllocatedExposureRatioDTO = {
  __typename?: 'NumericAggregateEntityAllocatedExposureRatioDTO';
  brokerageVolumeCapped?: Maybe<Scalars['Float']>;
  brokerageVolumeHedged?: Maybe<Scalars['Float']>;
  drpVolumeCapped?: Maybe<Scalars['Float']>;
  drpVolumeHedged?: Maybe<Scalars['Float']>;
  exposure?: Maybe<Scalars['Float']>;
  inventoryExposure?: Maybe<Scalars['Float']>;
  lgmVolumeCapped?: Maybe<Scalars['Float']>;
  lgmVolumeHedged?: Maybe<Scalars['Float']>;
  lrpVolumeCapped?: Maybe<Scalars['Float']>;
  lrpVolumeHedged?: Maybe<Scalars['Float']>;
  netProductionExposure?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeCapped?: Maybe<Scalars['Float']>;
  percentBrokerageVolumeHedged?: Maybe<Scalars['Float']>;
  percentLgmVolumeCapped?: Maybe<Scalars['Float']>;
  percentLgmVolumeHedged?: Maybe<Scalars['Float']>;
  percentLrpVolumeCapped?: Maybe<Scalars['Float']>;
  percentLrpVolumeHedged?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeCapped?: Maybe<Scalars['Float']>;
  percentPhysicalVolumeHedged?: Maybe<Scalars['Float']>;
  physicalVolumeCapped?: Maybe<Scalars['Float']>;
  physicalVolumeHedged?: Maybe<Scalars['Float']>;
  productionExposure?: Maybe<Scalars['Float']>;
  totalPercentOpen?: Maybe<Scalars['Float']>;
  totalPercentVolumeCapped?: Maybe<Scalars['Float']>;
  totalPercentVolumeHedged?: Maybe<Scalars['Float']>;
  totalVolumeCapped?: Maybe<Scalars['Float']>;
  totalVolumeHedged?: Maybe<Scalars['Float']>;
};

export type NumericAggregateEntityExposureRatioDTO = {
  __typename?: 'NumericAggregateEntityExposureRatioDTO';
  exposure?: Maybe<Scalars['Float']>;
  inventoryExposure?: Maybe<Scalars['Float']>;
  netProductionExposure?: Maybe<Scalars['Float']>;
  percentCapped?: Maybe<Scalars['Float']>;
  percentCappedCumulative?: Maybe<Scalars['Float']>;
  percentHedged?: Maybe<Scalars['Float']>;
  percentHedgedCumulative?: Maybe<Scalars['Float']>;
  percentOpen?: Maybe<Scalars['Float']>;
  percentOpenCumulative?: Maybe<Scalars['Float']>;
  productionExposure?: Maybe<Scalars['Float']>;
  volumeCapped?: Maybe<Scalars['Float']>;
  volumeCappedCumulative?: Maybe<Scalars['Float']>;
  volumeHedged?: Maybe<Scalars['Float']>;
  volumeHedgedCumulative?: Maybe<Scalars['Float']>;
};

export type NumericAggregateFeedIngredientActualUsageDTO = {
  __typename?: 'NumericAggregateFeedIngredientActualUsageDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  basisPrice?: Maybe<Scalars['Float']>;
  cashPrice?: Maybe<Scalars['Float']>;
  dmiUsageInLb?: Maybe<Scalars['Float']>;
  dmiUsageInTons?: Maybe<Scalars['Float']>;
  feedUsageInLb?: Maybe<Scalars['Float']>;
  feedUsageInTons?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateFeedIngredientConsumedAndPurchasedVolumeDTO = {
  __typename?: 'NumericAggregateFeedIngredientConsumedAndPurchasedVolumeDTO';
  forecastedConsumptionInTons?: Maybe<Scalars['Float']>;
  purchasedInTons?: Maybe<Scalars['Float']>;
  totalPurchasedCostInUsd?: Maybe<Scalars['Float']>;
  volumePurchasedWithBasisPriceOnlyInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithFlatPriceInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithFuturesPriceOnlyInTons?: Maybe<Scalars['Float']>;
  volumePurchasedWithoutPriceInTons?: Maybe<Scalars['Float']>;
};

export type NumericAggregateFeedIngredientForecastedUsageDTO = {
  __typename?: 'NumericAggregateFeedIngredientForecastedUsageDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  basisPrice?: Maybe<Scalars['Float']>;
  cashPrice?: Maybe<Scalars['Float']>;
  dmiUsageInLb?: Maybe<Scalars['Float']>;
  dmiUsageInTons?: Maybe<Scalars['Float']>;
  feedUsageInLb?: Maybe<Scalars['Float']>;
  feedUsageInTons?: Maybe<Scalars['Float']>;
  forecastedPerLbExpenseInUsd?: Maybe<Scalars['Float']>;
  forecastedTotalExpenseInUsd?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  readableQuarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateFieldDTO = {
  __typename?: 'NumericAggregateFieldDTO';
  acres?: Maybe<Scalars['Float']>;
};

export type NumericAggregateForecastedHedgedAndCappedVolumeDTO = {
  __typename?: 'NumericAggregateForecastedHedgedAndCappedVolumeDTO';
  naturallyLongCapped?: Maybe<Scalars['Float']>;
  naturallyLongHedged?: Maybe<Scalars['Float']>;
  naturallyShortCapped?: Maybe<Scalars['Float']>;
  naturallyShortHedged?: Maybe<Scalars['Float']>;
};

export type NumericAggregateForecastedMilkProductionByMonthDTO = {
  __typename?: 'NumericAggregateForecastedMilkProductionByMonthDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  averageDailyProductionPerCow?: Maybe<Scalars['Float']>;
  butterfatPercent?: Maybe<Scalars['Float']>;
  classiUtilization?: Maybe<Scalars['Float']>;
  classiiUtilization?: Maybe<Scalars['Float']>;
  classiiiUtilization?: Maybe<Scalars['Float']>;
  classivUtilization?: Maybe<Scalars['Float']>;
  exactNumberOfCows?: Maybe<Scalars['Float']>;
  forecastedBlendedMilkPrice?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossClassiProduction?: Maybe<Scalars['Float']>;
  grossClassiiProduction?: Maybe<Scalars['Float']>;
  grossClassiiiProduction?: Maybe<Scalars['Float']>;
  grossClassivProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  numberOfCows?: Maybe<Scalars['Float']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateForecastedMilkProductionByMonthRolledUpDTO = {
  __typename?: 'NumericAggregateForecastedMilkProductionByMonthRolledUpDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  exactNumberOfCows?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossClassiProduction?: Maybe<Scalars['Float']>;
  grossClassiiProduction?: Maybe<Scalars['Float']>;
  grossClassiiiProduction?: Maybe<Scalars['Float']>;
  grossClassivProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  numberOfCows?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateForecastedMilkProductionCoverageDTO = {
  __typename?: 'NumericAggregateForecastedMilkProductionCoverageDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  butterfatPounds?: Maybe<Scalars['Float']>;
  classIiiPounds?: Maybe<Scalars['Float']>;
  classIvPounds?: Maybe<Scalars['Float']>;
  effectiveCoveredMilkProduction?: Maybe<Scalars['Float']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  excessButterPounds?: Maybe<Scalars['Float']>;
  excessCheesePounds?: Maybe<Scalars['Float']>;
  excessProteinPoundsAfterCheese?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  grossButterfatProduction?: Maybe<Scalars['Float']>;
  grossClassIProduction?: Maybe<Scalars['Float']>;
  grossClassIiProduction?: Maybe<Scalars['Float']>;
  grossClassIiiProduction?: Maybe<Scalars['Float']>;
  grossClassIvProduction?: Maybe<Scalars['Float']>;
  grossOtherSolidsProduction?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProteinProduction?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  nonfatSolidsPounds?: Maybe<Scalars['Float']>;
  otherSolidsPounds?: Maybe<Scalars['Float']>;
  percentInsured?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  proteinPounds?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateForecastedMilkProductionDTO = {
  __typename?: 'NumericAggregateForecastedMilkProductionDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  butterfatPercent?: Maybe<Scalars['Float']>;
  grossButterfat?: Maybe<Scalars['Float']>;
  grossOtherSolids?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  grossProtein?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  numberOfCows?: Maybe<Scalars['Float']>;
  otherSolidsPercent?: Maybe<Scalars['Float']>;
  productionPerCow?: Maybe<Scalars['Float']>;
  proteinPercent?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateForecastedMilkUtilizationDTO = {
  __typename?: 'NumericAggregateForecastedMilkUtilizationDTO';
  classiDifferential?: Maybe<Scalars['Float']>;
  classiUtilization?: Maybe<Scalars['Float']>;
  classiiUtilization?: Maybe<Scalars['Float']>;
  classiiiUtilization?: Maybe<Scalars['Float']>;
  classivUtilization?: Maybe<Scalars['Float']>;
  grossClassiDifferentialPrice?: Maybe<Scalars['Float']>;
  grossClassiPounds?: Maybe<Scalars['Float']>;
  grossClassiiPounds?: Maybe<Scalars['Float']>;
  grossClassiiiPounds?: Maybe<Scalars['Float']>;
  grossClassivPounds?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateForecastedProductionDTO = {
  __typename?: 'NumericAggregateForecastedProductionDTO';
  absoluteQuarter?: Maybe<Scalars['Float']>;
  grossProduction?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  productionPerUnit?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateForecastedSwineLivestockFeedUsageAtFinishDTO = {
  __typename?: 'NumericAggregateForecastedSwineLivestockFeedUsageAtFinishDTO';
  quantityInLbs?: Maybe<Scalars['Float']>;
  quantityInTons?: Maybe<Scalars['Float']>;
  totalExpenseInUsd?: Maybe<Scalars['Float']>;
};

export type NumericAggregateForecastedSwineLivestockFeedUsageDTO = {
  __typename?: 'NumericAggregateForecastedSwineLivestockFeedUsageDTO';
  ageInWeeks?: Maybe<Scalars['Float']>;
  hedgePriceInUsd?: Maybe<Scalars['Float']>;
  perLbExpenseInUsd?: Maybe<Scalars['Float']>;
  quantityInLbs?: Maybe<Scalars['Float']>;
  quantityInTons?: Maybe<Scalars['Float']>;
  totalExpenseInUsd?: Maybe<Scalars['Float']>;
};

export type NumericAggregateGrainFillOrderDTO = {
  __typename?: 'NumericAggregateGrainFillOrderDTO';
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  cropFlatPrice?: Maybe<Scalars['Float']>;
  feedFlatPrice?: Maybe<Scalars['Float']>;
  feedPrice?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee?: Maybe<Scalars['Float']>;
  futurePrice?: Maybe<Scalars['Float']>;
  htaFee?: Maybe<Scalars['Float']>;
  rollFee?: Maybe<Scalars['Float']>;
  spread?: Maybe<Scalars['Float']>;
  vgsId?: Maybe<Scalars['Float']>;
};

export type NumericAggregateGrainTargetOrderDTO = {
  __typename?: 'NumericAggregateGrainTargetOrderDTO';
  basis?: Maybe<Scalars['Float']>;
  bushels?: Maybe<Scalars['Float']>;
  cropFlatPrice?: Maybe<Scalars['Float']>;
  feedFlatPrice?: Maybe<Scalars['Float']>;
  feedPrice?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  flatPrice?: Maybe<Scalars['Float']>;
  flexFee?: Maybe<Scalars['Float']>;
  futurePrice?: Maybe<Scalars['Float']>;
  htaFee?: Maybe<Scalars['Float']>;
  rollFee?: Maybe<Scalars['Float']>;
  spread?: Maybe<Scalars['Float']>;
  vgsId?: Maybe<Scalars['Float']>;
};

export type NumericAggregateHedgeStrategyCurrentPositionDTO = {
  __typename?: 'NumericAggregateHedgeStrategyCurrentPositionDTO';
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  feeTotal?: Maybe<Scalars['Float']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossProtection?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  unitQuantity?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
};

export type NumericAggregateHedgeStrategyHistoricalPnlDTO = {
  __typename?: 'NumericAggregateHedgeStrategyHistoricalPnlDTO';
  grossPnl?: Maybe<Scalars['Float']>;
  insuranceNetPremium?: Maybe<Scalars['Float']>;
  insuranceRealizedPnl?: Maybe<Scalars['Float']>;
  insuranceUnrealizedPnl?: Maybe<Scalars['Float']>;
  netPnl?: Maybe<Scalars['Float']>;
  netPremium?: Maybe<Scalars['Float']>;
  positionNetPremium?: Maybe<Scalars['Float']>;
  positionRealizedPnl?: Maybe<Scalars['Float']>;
  positionUnrealizedPnl?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
};

export type NumericAggregateHistoricalPnlDTO = {
  __typename?: 'NumericAggregateHistoricalPnlDTO';
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  feeTotal?: Maybe<Scalars['Float']>;
  grossPnl?: Maybe<Scalars['Float']>;
  grossPnlChange?: Maybe<Scalars['Float']>;
  marketValue?: Maybe<Scalars['Float']>;
  marketValueChange?: Maybe<Scalars['Float']>;
  optionPremium?: Maybe<Scalars['Float']>;
  previousCommissionAndFeeTotal?: Maybe<Scalars['Float']>;
  previousCommissionTotal?: Maybe<Scalars['Float']>;
  previousFeeTotal?: Maybe<Scalars['Float']>;
  previousGrossPnl?: Maybe<Scalars['Float']>;
  previousMarketValue?: Maybe<Scalars['Float']>;
  previousRealizedPnl?: Maybe<Scalars['Float']>;
  previousUnrealizedPnl?: Maybe<Scalars['Float']>;
  realizedPnl?: Maybe<Scalars['Float']>;
  realizedPnlChange?: Maybe<Scalars['Float']>;
  unrealizedPnl?: Maybe<Scalars['Float']>;
  unrealizedPnlChange?: Maybe<Scalars['Float']>;
};

export type NumericAggregateInsuranceHistoricalDrpPremiumDTO = {
  __typename?: 'NumericAggregateInsuranceHistoricalDrpPremiumDTO';
  coverageLevel?: Maybe<Scalars['Float']>;
  grossPremium?: Maybe<Scalars['Float']>;
  liability?: Maybe<Scalars['Float']>;
  netPremium?: Maybe<Scalars['Float']>;
  protectedPrice?: Maybe<Scalars['Float']>;
  revenueGuarantee?: Maybe<Scalars['Float']>;
  subsidy?: Maybe<Scalars['Float']>;
};

export type NumericAggregateInventoryLevelDTO = {
  __typename?: 'NumericAggregateInventoryLevelDTO';
  quantity?: Maybe<Scalars['Float']>;
};

export type NumericAggregateLedgerActualEntryDTO = {
  __typename?: 'NumericAggregateLedgerActualEntryDTO';
  amount?: Maybe<Scalars['Float']>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateLedgerEntryDTO = {
  __typename?: 'NumericAggregateLedgerEntryDTO';
  amount?: Maybe<Scalars['Float']>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateLedgerForecastedEntryDTO = {
  __typename?: 'NumericAggregateLedgerForecastedEntryDTO';
  amount?: Maybe<Scalars['Float']>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  calculationQuantity?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quarter?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type NumericAggregateLivestockGroupContractPeriodAllocationDTO = {
  __typename?: 'NumericAggregateLivestockGroupContractPeriodAllocationDTO';
  allocatedQuantity?: Maybe<Scalars['Float']>;
  contractedQuantity?: Maybe<Scalars['Float']>;
  exactAllocatedQuantity?: Maybe<Scalars['Float']>;
  exactOpenQuantity?: Maybe<Scalars['Float']>;
  openQuantity?: Maybe<Scalars['Float']>;
};

export type NumericAggregateLrpInsuranceEndorsementDTO = {
  __typename?: 'NumericAggregateLrpInsuranceEndorsementDTO';
  actualIndemnity?: Maybe<Scalars['Float']>;
  actualPnl?: Maybe<Scalars['Float']>;
  commodityPrice?: Maybe<Scalars['Float']>;
  coveragePrice?: Maybe<Scalars['Float']>;
  declaredShare?: Maybe<Scalars['Float']>;
  estimatedPl?: Maybe<Scalars['Float']>;
  forecastedIndemnity?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  headCount?: Maybe<Scalars['Float']>;
  indemnity?: Maybe<Scalars['Float']>;
  indemnityAmount?: Maybe<Scalars['Float']>;
  insuredValue?: Maybe<Scalars['Float']>;
  lengthInWeeks?: Maybe<Scalars['Float']>;
  livestockRate?: Maybe<Scalars['Float']>;
  pnl?: Maybe<Scalars['Float']>;
  producerPremiumAmount?: Maybe<Scalars['Float']>;
  reinsuranceYear?: Maybe<Scalars['Float']>;
  subsidyAmount?: Maybe<Scalars['Float']>;
  targetWeightCwt?: Maybe<Scalars['Float']>;
  totalPremiumAmount?: Maybe<Scalars['Float']>;
};

export type NumericAggregatePhysicalFeedTransactionDTO = {
  __typename?: 'NumericAggregatePhysicalFeedTransactionDTO';
  basisPrice?: Maybe<Scalars['Float']>;
  flatPrice?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  futuresPrice?: Maybe<Scalars['Float']>;
  tons?: Maybe<Scalars['Float']>;
};

export type NumericAggregatePositionComponentAllocationDTO = {
  __typename?: 'NumericAggregatePositionComponentAllocationDTO';
  longContractQuantity?: Maybe<Scalars['Float']>;
  longUnitQuantity?: Maybe<Scalars['Float']>;
  netContractQuantity?: Maybe<Scalars['Float']>;
  netUnitQuantity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shortContractQuantity?: Maybe<Scalars['Float']>;
  shortUnitQuantity?: Maybe<Scalars['Float']>;
};

export type NumericAggregatePositionComponentDTO = {
  __typename?: 'NumericAggregatePositionComponentDTO';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type NumericAggregateRmaSummaryOfBusinessDTO = {
  __typename?: 'NumericAggregateRmaSummaryOfBusinessDTO';
  coverageLevel?: Maybe<Scalars['Float']>;
  cropYear?: Maybe<Scalars['Float']>;
  indemnity?: Maybe<Scalars['Float']>;
  liability?: Maybe<Scalars['Float']>;
  netAcres?: Maybe<Scalars['Float']>;
  policiesEarningPremium?: Maybe<Scalars['Float']>;
  policiesIndemnified?: Maybe<Scalars['Float']>;
  policiesSold?: Maybe<Scalars['Float']>;
  subsidy?: Maybe<Scalars['Float']>;
  totalPremium?: Maybe<Scalars['Float']>;
  unitsEarningPremium?: Maybe<Scalars['Float']>;
  unitsIndemnified?: Maybe<Scalars['Float']>;
};

export type NumericAggregateSwineLivestockPopulationChangeDTO = {
  __typename?: 'NumericAggregateSwineLivestockPopulationChangeDTO';
  dobWeek?: Maybe<Scalars['Float']>;
  exactQuantity?: Maybe<Scalars['Float']>;
  exactTotalValue?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  totalValue?: Maybe<Scalars['Float']>;
  valuePerHead?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
};

export type NumericAggregateTransactionDTO = {
  __typename?: 'NumericAggregateTransactionDTO';
  commissionAndFeeTotal?: Maybe<Scalars['Float']>;
  commissionTotal?: Maybe<Scalars['Float']>;
  contractQuantity?: Maybe<Scalars['Float']>;
  feeTotal?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  tradeMonth?: Maybe<Scalars['Float']>;
  tradeYear?: Maybe<Scalars['Float']>;
  unitQuantity?: Maybe<Scalars['Float']>;
};

export type NumericAggregateTransactionFeeDTO = {
  __typename?: 'NumericAggregateTransactionFeeDTO';
  value?: Maybe<Scalars['Float']>;
};

export type NumericAggregateUsdaMonthlyNationalAverageDairyPriceDTO = {
  __typename?: 'NumericAggregateUsdaMonthlyNationalAverageDairyPriceDTO';
  advancedSkimMilkClassIiPrice?: Maybe<Scalars['Float']>;
  butterMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  butterfatPrice?: Maybe<Scalars['Float']>;
  cheeseMonthly40Price?: Maybe<Scalars['Float']>;
  cheeseMonthly500Price?: Maybe<Scalars['Float']>;
  cheeseMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  classiiButterfatPrice?: Maybe<Scalars['Float']>;
  classiiPrice?: Maybe<Scalars['Float']>;
  classiiiPrice?: Maybe<Scalars['Float']>;
  classiiiSkimMilkPrice?: Maybe<Scalars['Float']>;
  classivPrice?: Maybe<Scalars['Float']>;
  classivSkimMilkPrice?: Maybe<Scalars['Float']>;
  nfdmMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  nonfatSolidsPrice?: Maybe<Scalars['Float']>;
  otherSolidsPrice?: Maybe<Scalars['Float']>;
  proteinPrice?: Maybe<Scalars['Float']>;
  somaticCellAdjustmentRate?: Maybe<Scalars['Float']>;
  wheyMonthlyAvgPrice?: Maybe<Scalars['Float']>;
};

export type NumericAggregateUsdaMonthlyRegionalMilkComponentPriceDTO = {
  __typename?: 'NumericAggregateUsdaMonthlyRegionalMilkComponentPriceDTO';
  butterfat?: Maybe<Scalars['Float']>;
  fmmoRegionCode?: Maybe<Scalars['Float']>;
  nfs?: Maybe<Scalars['Float']>;
  otherSolids?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Scalars['Float']>;
  somcell?: Maybe<Scalars['Float']>;
};

export type NumericAggregateUsdaMonthlyRegionalMilkUtilizationDTO = {
  __typename?: 'NumericAggregateUsdaMonthlyRegionalMilkUtilizationDTO';
  classiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiUtilizationPercent?: Maybe<Scalars['Float']>;
  classiiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiiCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classiiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiiUtilizationPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionOtherSolidsPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionProteinPercent?: Maybe<Scalars['Float']>;
  classiiiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiiiUtilizationPercent?: Maybe<Scalars['Float']>;
  classivCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classivCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classivUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classivUtilizationPercent?: Maybe<Scalars['Float']>;
  fmmoRegionCode?: Maybe<Scalars['Float']>;
};

export type NumericAggregateUsdaRetailSummaryDTO = {
  __typename?: 'NumericAggregateUsdaRetailSummaryDTO';
  activityIndex?: Maybe<Scalars['Float']>;
  featureRate?: Maybe<Scalars['Float']>;
  locallyGrownPercentage?: Maybe<Scalars['Float']>;
  priceHigh?: Maybe<Scalars['Float']>;
  priceLow?: Maybe<Scalars['Float']>;
  specialRate?: Maybe<Scalars['Float']>;
  storeOutlets?: Maybe<Scalars['Float']>;
  storesWithAds?: Maybe<Scalars['Float']>;
  weightedAveragePrice?: Maybe<Scalars['Float']>;
};

export type Option = IInstrument & {
  __typename?: 'Option';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  UnderlyingInstrument: Future;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayExpiresAt: Scalars['String'];
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  isStandardContractSize: Scalars['Boolean'];
  lastTradeAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  optionType: TypeOfOption;
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  strike: Scalars['Float'];
  type: TypeOfInstrument;
  underlyingInstrumentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  velaInstrumentId?: Maybe<Scalars['Int']>;
};


export type Option_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type Option_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type Option_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type Option_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type OptionFilterDTO = {
  AND?: InputMaybe<Array<OptionFilterDTO>>;
  AsInstrument?: InputMaybe<InstrumentFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  DerivedSpreadLegs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  LegacyFeeds?: InputMaybe<LegacyFeedFilterDTO>;
  LegacyScenarioData?: InputMaybe<LegacyScenarioDataFilterDTO>;
  NOT?: InputMaybe<OptionFilterDTO>;
  OR?: InputMaybe<Array<OptionFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationFilterDTO>;
  SwaptionInstruments?: InputMaybe<SwaptionFilterDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  UnderlyingInstrument?: InputMaybe<FutureFilterDTO>;
  barchartSymbol?: InputMaybe<NullableStringFilter>;
  cqgSymbol?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayExpiresAt?: InputMaybe<StringFilter>;
  exchangeSymbol?: InputMaybe<NullableStringFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentSymbolGroupId?: InputMaybe<StringFilter>;
  isStandardContractSize?: InputMaybe<BooleanFilter>;
  lastTradeAt?: InputMaybe<NullableDateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  optionType?: InputMaybe<TypeOfOptionFilter>;
  productId?: InputMaybe<StringFilter>;
  productLotSpecificationId?: InputMaybe<StringFilter>;
  strike?: InputMaybe<NumberFilter>;
  underlyingInstrumentId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  velaInstrumentId?: InputMaybe<NullableNumberFilter>;
};

export type OptionGroupByDTO = {
  AsInstrument?: InputMaybe<InstrumentGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationGroupByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  UnderlyingInstrument?: InputMaybe<FutureGroupByDTO>;
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  strike?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  underlyingInstrumentId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  velaInstrumentId?: InputMaybe<Scalars['Boolean']>;
};

export type OptionInstrumentDistinctOnDTO = {
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  strike?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  underlyingInstrumentId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  velaInstrumentId?: InputMaybe<Scalars['Boolean']>;
};

export type OptionSortByDTO = {
  AsInstrument?: InputMaybe<InstrumentSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationSortByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  UnderlyingInstrument?: InputMaybe<FutureSortByDTO>;
  barchartSymbol?: InputMaybe<NullableSortByDirection>;
  cqgSymbol?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  displayExpiresAt?: InputMaybe<SortByDirection>;
  exchangeSymbol?: InputMaybe<NullableSortByDirection>;
  expiresAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentSymbolGroupId?: InputMaybe<SortByDirection>;
  isStandardContractSize?: InputMaybe<SortByDirection>;
  lastTradeAt?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<SortByDirection>;
  optionType?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productLotSpecificationId?: InputMaybe<SortByDirection>;
  strike?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  underlyingInstrumentId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  velaInstrumentId?: InputMaybe<NullableSortByDirection>;
};

export type Order = {
  __typename?: 'Order';
  Account: IAccount;
  Instrument: IInstrument;
  Notes: Array<OrderNote>;
  TransactionGroup: TransactionGroup;
  Transactions: Array<Transaction>;
  accountId: Scalars['String'];
  atomicGroupId?: Maybe<Scalars['String']>;
  cqgId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentId: Scalars['String'];
  orderType: TypeOfOrderExecution;
  price?: Maybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  status: OrderStatus;
  timeInForce: TimeInForce;
  transactionGroupId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type Order_NotesArgs = {
  orderBy?: InputMaybe<OrderNoteSortByDTO>;
  where?: InputMaybe<OrderNoteFilterDTO>;
};


export type Order_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type OrderDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  atomicGroupId?: InputMaybe<Scalars['Boolean']>;
  cqgId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  orderType?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  timeInForce?: InputMaybe<Scalars['Boolean']>;
  transactionGroupId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type OrderFilterDTO = {
  AND?: InputMaybe<Array<OrderFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<OrderFilterDTO>;
  OR?: InputMaybe<Array<OrderFilterDTO>>;
  OrderNotes?: InputMaybe<OrderNoteFilterDTO>;
  TransactionGroup?: InputMaybe<TransactionGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  accountId?: InputMaybe<StringFilter>;
  atomicGroupId?: InputMaybe<NullableStringFilter>;
  cqgId?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  orderType?: InputMaybe<TypeOfOrderExecutionFilter>;
  price?: InputMaybe<NullableNumberFilter>;
  quantity?: InputMaybe<NumberFilter>;
  status?: InputMaybe<OrderStatusFilter>;
  timeInForce?: InputMaybe<TimeInForceFilter>;
  transactionGroupId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderGroupByDTO = {
  Account?: InputMaybe<AccountGroupByDTO>;
  AccountAsBrokerageAccount?: InputMaybe<BrokerageAccountGroupByDTO>;
  AccountAsForwardContractAccount?: InputMaybe<ForwardContractAccountGroupByDTO>;
  AccountAsSwapAccount?: InputMaybe<SwapAccountGroupByDTO>;
  Instrument?: InputMaybe<InstrumentGroupByDTO>;
  InstrumentAsBasisInstrument?: InputMaybe<BasisInstrumentGroupByDTO>;
  InstrumentAsCashInstrument?: InputMaybe<CashInstrumentGroupByDTO>;
  InstrumentAsCommitmentInstrument?: InputMaybe<CommitmentInstrumentGroupByDTO>;
  InstrumentAsFutureInstrument?: InputMaybe<FutureGroupByDTO>;
  InstrumentAsOptionInstrument?: InputMaybe<OptionGroupByDTO>;
  InstrumentAsPhysicalInstrument?: InputMaybe<PhysicalInstrumentGroupByDTO>;
  InstrumentAsSpreadInstrument?: InputMaybe<SpreadInstrumentGroupByDTO>;
  InstrumentAsSwapInstrument?: InputMaybe<SwapGroupByDTO>;
  InstrumentAsSwaptionInstrument?: InputMaybe<SwaptionGroupByDTO>;
  TransactionGroup?: InputMaybe<TransactionGroupGroupByDTO>;
  accountId?: InputMaybe<Scalars['Boolean']>;
  atomicGroupId?: InputMaybe<Scalars['Boolean']>;
  cqgId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  orderType?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['Boolean']>;
  timeInForce?: InputMaybe<Scalars['Boolean']>;
  transactionGroupId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type OrderNote = {
  __typename?: 'OrderNote';
  Order: Order;
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  orderId: Scalars['String'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrderNoteCreateDTO = {
  orderId: Scalars['String'];
  text: Scalars['String'];
};

export type OrderNoteDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type OrderNoteFilterDTO = {
  AND?: InputMaybe<Array<OrderNoteFilterDTO>>;
  NOT?: InputMaybe<OrderNoteFilterDTO>;
  OR?: InputMaybe<Array<OrderNoteFilterDTO>>;
  Order?: InputMaybe<OrderFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderNoteSortByDTO = {
  Order?: InputMaybe<OrderSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  orderId?: InputMaybe<SortByDirection>;
  text?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type OrderNoteUpdateDTO = {
  orderId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type OrderSortByDTO = {
  Account?: InputMaybe<AccountSortByDTO>;
  Instrument?: InputMaybe<InstrumentSortByDTO>;
  TransactionGroup?: InputMaybe<TransactionGroupSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  atomicGroupId?: InputMaybe<NullableSortByDirection>;
  cqgId?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentId?: InputMaybe<SortByDirection>;
  orderType?: InputMaybe<SortByDirection>;
  price?: InputMaybe<NullableSortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  status?: InputMaybe<SortByDirection>;
  timeInForce?: InputMaybe<SortByDirection>;
  transactionGroupId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export enum OrderStatus {
  Cancelled = 'Cancelled',
  Filled = 'Filled',
  Open = 'Open',
  Pending = 'Pending',
  Submitted = 'Submitted'
}

export type OrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<OrderStatus>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type OrganizationEntity = IEntity & {
  __typename?: 'OrganizationEntity';
  ActiveProducts: Array<Product>;
  AgreedToOtcContracts: Array<OtcContract>;
  BrokerageAccounts: Array<BrokerageAccount>;
  CreatedByUser?: Maybe<User>;
  CurrentUserPermissions: UserPermissionsForTarget;
  Customers: Array<CustomerEntity>;
  EntityUsers: Array<EntityUser>;
  ExposureRatios: Array<EntityExposureRatio>;
  FeedIngredientConsumedAndPurchasedVolumes: Array<FeedIngredientConsumedAndPurchasedVolume>;
  ForwardContracts: Array<ForwardContractAccount>;
  GroupEntries: Array<EntityGroupEntry>;
  Groups: Array<EntityGroup>;
  InitiatedOtcContracts: Array<OtcContract>;
  LastUpdatedByUser?: Maybe<User>;
  Members: Array<EntityMember>;
  Notes: Array<EntityNote>;
  OwnedAccounts: Array<IAccount>;
  OwnedOtcContracts: Array<OtcContract>;
  PhysicalSales: Array<PhysicalSale>;
  createdAt: Scalars['DateTime'];
  createdByUserId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  identificationNumber: Scalars['Int'];
  isVgs: Scalars['Boolean'];
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type: TypeOfEntity;
  updatedAt: Scalars['DateTime'];
};


export type OrganizationEntity_AgreedToOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type OrganizationEntity_BrokerageAccountsArgs = {
  orderBy?: InputMaybe<BrokerageAccountSortByDTO>;
  where?: InputMaybe<BrokerageAccountFilterDTO>;
};


export type OrganizationEntity_CustomersArgs = {
  orderBy?: InputMaybe<CustomerEntitySortByDTO>;
  where?: InputMaybe<CustomerEntityFilterDTO>;
};


export type OrganizationEntity_EntityUsersArgs = {
  orderBy?: InputMaybe<EntityUserSortByDTO>;
  where?: InputMaybe<EntityUserFilterDTO>;
};


export type OrganizationEntity_ExposureRatiosArgs = {
  orderBy?: InputMaybe<EntityExposureRatioSortByDTO>;
  where?: InputMaybe<EntityExposureRatioFilterDTO>;
};


export type OrganizationEntity_FeedIngredientConsumedAndPurchasedVolumesArgs = {
  orderBy?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeSortByDTO>;
  where?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
};


export type OrganizationEntity_ForwardContractsArgs = {
  orderBy?: InputMaybe<ForwardContractAccountSortByDTO>;
  where?: InputMaybe<ForwardContractAccountFilterDTO>;
};


export type OrganizationEntity_GroupEntriesArgs = {
  orderBy?: InputMaybe<EntityGroupEntrySortByDTO>;
  where?: InputMaybe<EntityGroupEntryFilterDTO>;
};


export type OrganizationEntity_InitiatedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type OrganizationEntity_MembersArgs = {
  orderBy?: InputMaybe<EntityMemberSortByDTO>;
  where?: InputMaybe<EntityMemberFilterDTO>;
};


export type OrganizationEntity_NotesArgs = {
  orderBy?: InputMaybe<EntityNoteSortByDTO>;
  where?: InputMaybe<EntityNoteFilterDTO>;
};


export type OrganizationEntity_OwnedAccountsArgs = {
  orderBy?: InputMaybe<AccountSortByDTO>;
  where?: InputMaybe<AccountFilterDTO>;
};


export type OrganizationEntity_OwnedOtcContractsArgs = {
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type OrganizationEntity_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};

export type OrganizationEntityCreateDTO = {
  externalId?: InputMaybe<Scalars['String']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type OrganizationEntityDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationEntityFilterDTO = {
  AND?: InputMaybe<Array<OrganizationEntityFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  ActualBlendedMilkPrices?: InputMaybe<ActualBlendedMilkPriceFilterDTO>;
  ActualMilkProductionByMonth?: InputMaybe<ActualMilkProductionByMonthFilterDTO>;
  AgreedToOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  AllocatedForecastedHedgedAndCappedVolumes?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>;
  AsEntity?: InputMaybe<EntityFilterDTO>;
  Children?: InputMaybe<EntityFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  CustomerEntities?: InputMaybe<CustomerEntityFilterDTO>;
  EntityActiveProducts?: InputMaybe<EntityActiveProductFilterDTO>;
  EntityAllocatedExposureRatios?: InputMaybe<EntityAllocatedExposureRatioFilterDTO>;
  EntityAllocatedExposureRatiosByOwner?: InputMaybe<EntityAllocatedExposureRatioByOwnerFilterDTO>;
  EntityAllocatedForecastedBrokerageVolumesHedged?: InputMaybe<EntityAllocatedForecastedBrokerageVolumeHedgedFilterDTO>;
  EntityAllocatedForecastedVolumesHedgedByOwner?: InputMaybe<EntityAllocatedForecastedVolumeHedgedByOwnerFilterDTO>;
  EntityForecastedPhysicalVolumeHedged?: InputMaybe<EntityForecastedPhysicalVolumeHedgedFilterDTO>;
  EntityGroup?: InputMaybe<EntityGroupFilterDTO>;
  EntityGroups?: InputMaybe<EntityGroupFilterDTO>;
  EntityUsers?: InputMaybe<EntityUserFilterDTO>;
  ExposureRatios?: InputMaybe<EntityExposureRatioFilterDTO>;
  FeedIngredientConsumedAndPurchasedVolumes?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
  FeedIngredientUsages?: InputMaybe<FeedIngredientUsageFilterDTO>;
  ForecastedHedgedAndCappedVolume?: InputMaybe<ForecastedHedgedAndCappedVolumeFilterDTO>;
  ForecastedMilkProductionByMonth?: InputMaybe<ForecastedMilkProductionByMonthFilterDTO>;
  ForecastedMilkProductionByMonthRolledUp?: InputMaybe<ForecastedMilkProductionByMonthRolledUpFilterDTO>;
  GrainPlan?: InputMaybe<GrainPlanFilterDTO>;
  GrainPlans?: InputMaybe<GrainPlanFilterDTO>;
  GroupEntries?: InputMaybe<EntityGroupEntryFilterDTO>;
  InitiatedOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  InsurancePolicies?: InputMaybe<InsurancePolicyFilterDTO>;
  IntegrationInstallations?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  LedgerEntry?: InputMaybe<LedgerEntryFilterDTO>;
  Members?: InputMaybe<EntityMemberFilterDTO>;
  NOT?: InputMaybe<OrganizationEntityFilterDTO>;
  Notes?: InputMaybe<EntityNoteFilterDTO>;
  OR?: InputMaybe<Array<OrganizationEntityFilterDTO>>;
  OwnedOtcContracts?: InputMaybe<OtcContractFilterDTO>;
  Parent?: InputMaybe<EntityFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  RdrsOrganizations?: InputMaybe<RdrsOrganizationFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  externalId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  identificationNumber?: InputMaybe<NumberFilter>;
  isVgs?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  name?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<NullableStringFilter>;
  slug?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrganizationEntityGroupByDTO = {
  AsEntity?: InputMaybe<EntityGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  Parent?: InputMaybe<EntityGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']>;
  isVgs?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationEntitySortByDTO = {
  AsEntity?: InputMaybe<EntitySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  Parent?: InputMaybe<EntitySortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  externalId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  identificationNumber?: InputMaybe<SortByDirection>;
  isVgs?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<SortByDirection>;
  parentId?: InputMaybe<NullableSortByDirection>;
  slug?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type OrganizationMemberCreateDTO = {
  canReadBrokerage?: InputMaybe<Scalars['Boolean']>;
  canReadBusiness?: InputMaybe<Scalars['Boolean']>;
  canReadEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canReadFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canReadInsurance?: InputMaybe<Scalars['Boolean']>;
  canReadOperations?: InputMaybe<Scalars['Boolean']>;
  canReadSwap?: InputMaybe<Scalars['Boolean']>;
  canWriteBrokerage?: InputMaybe<Scalars['Boolean']>;
  canWriteBusiness?: InputMaybe<Scalars['Boolean']>;
  canWriteEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canWriteFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canWriteInsurance?: InputMaybe<Scalars['Boolean']>;
  canWriteOperations?: InputMaybe<Scalars['Boolean']>;
  canWriteOrganization?: InputMaybe<Scalars['Boolean']>;
  canWriteSwap?: InputMaybe<Scalars['Boolean']>;
  entityId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type OrganizationMemberUpdateDTO = {
  canReadBrokerage?: InputMaybe<Scalars['Boolean']>;
  canReadBusiness?: InputMaybe<Scalars['Boolean']>;
  canReadEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canReadFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canReadInsurance?: InputMaybe<Scalars['Boolean']>;
  canReadOperations?: InputMaybe<Scalars['Boolean']>;
  canReadSwap?: InputMaybe<Scalars['Boolean']>;
  canWriteBrokerage?: InputMaybe<Scalars['Boolean']>;
  canWriteBusiness?: InputMaybe<Scalars['Boolean']>;
  canWriteEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canWriteFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canWriteInsurance?: InputMaybe<Scalars['Boolean']>;
  canWriteOperations?: InputMaybe<Scalars['Boolean']>;
  canWriteOrganization?: InputMaybe<Scalars['Boolean']>;
  canWriteSwap?: InputMaybe<Scalars['Boolean']>;
};

export type OtcContract = {
  __typename?: 'OtcContract';
  AgreeingParty?: Maybe<IEntity>;
  InitiatingParty: IEntity;
  Owner: IEntity;
  agreeingPartyName?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  initiatingPartyId: Scalars['String'];
  ownerId: Scalars['String'];
};

export type OtcContractCreateDTO = {
  agreeingPartyName?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  initiatingPartyId: Scalars['String'];
};

export type OtcContractDistinctOnDTO = {
  agreeingPartyName?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  initiatingPartyId?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
};

export type OtcContractFilterDTO = {
  AND?: InputMaybe<Array<OtcContractFilterDTO>>;
  AgreeingParty?: InputMaybe<EntityFilterDTO>;
  InitiatingParty?: InputMaybe<EntityFilterDTO>;
  NOT?: InputMaybe<OtcContractFilterDTO>;
  OR?: InputMaybe<Array<OtcContractFilterDTO>>;
  Owner?: InputMaybe<EntityFilterDTO>;
  agreeingPartyName?: InputMaybe<NullableStringFilter>;
  contractNumber?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  initiatingPartyId?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringFilter>;
};

export type OtcContractSortByDTO = {
  AgreeingParty?: InputMaybe<EntitySortByDTO>;
  InitiatingParty?: InputMaybe<EntitySortByDTO>;
  Owner?: InputMaybe<EntitySortByDTO>;
  agreeingPartyName?: InputMaybe<NullableSortByDirection>;
  contractNumber?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  initiatingPartyId?: InputMaybe<SortByDirection>;
  ownerId?: InputMaybe<SortByDirection>;
};

export type OtcContractUpdateDTO = {
  agreeingPartyName?: InputMaybe<Scalars['String']>;
  contractNumber?: InputMaybe<Scalars['String']>;
};

export enum PaginationDirection {
  NextPageFromKeyset = 'NextPageFromKeyset',
  NextPageIncludingKeyset = 'NextPageIncludingKeyset',
  PreviousPagIncludingKeyset = 'PreviousPagIncludingKeyset',
  PreviousPageFromKeyset = 'PreviousPageFromKeyset'
}

export type PaginationInput = {
  /** The direction in which to paginate. A pagination direction that "includes" a keyset will return a page that includes the row with the "previousRowId" value. */
  direction: PaginationDirection;
  /** The id of the row from which to paginate. If paginating forward, this value should be the id of the last row from the current page. If paginating backward, this value should be the id of the first row first row on the current page. If this value is null, then the first page will be returned, regardless of the pagination direction. If a row matching this id does not exist or can be accessed by the requesting user an error will be thrown. */
  previousRowId?: InputMaybe<Scalars['String']>;
  /** The number of rows to return per page. This value works the same way as "limit" in offset pagination. */
  take: Scalars['Int'];
};

export type PhysicalFeedTransaction = {
  __typename?: 'PhysicalFeedTransaction';
  Business: CustomerEntity;
  FeedIngredient: FeedIngredient;
  /** When set, indicates a locked in basis (+/- adjustment to some locked in or to-be-locked-in-later future) Can be set in conjunction with a futuresPrice */
  basisPrice?: Maybe<Scalars['Float']>;
  businessId: Scalars['String'];
  contractIdentifier?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deliveryEndDate: Scalars['String'];
  deliveryStartDate: Scalars['String'];
  deliveryTerms?: Maybe<GrainOrderDeliveryTerms>;
  feedIngredientId: Scalars['String'];
  /** When explicitly set, indicates a fixed price in USD. When basisPrice & futuresPrice are set, will be calculated as (basisPrice + futuresPrice) */
  flatPrice?: Maybe<Scalars['Float']>;
  forecastedPnl?: Maybe<Scalars['Float']>;
  /** When set, indicates that a futures price has been locked in for this transaction. Can be set in conjunction with a basisPrice */
  futuresPrice?: Maybe<Scalars['Float']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  perMonthData: Array<Scalars['Object']>;
  pricingType: TypeOfPhysicalFeedTransactionPricing;
  seller?: Maybe<Scalars['String']>;
  tons: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type PhysicalFeedTransactionAggregateAllDTO = {
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractIdentifier?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  futuresPrice?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  seller?: InputMaybe<Scalars['Boolean']>;
  tons?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PhysicalFeedTransactionAggregateDTO = {
  avg?: InputMaybe<PhysicalFeedTransactionAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<PhysicalFeedTransactionAggregateAllDTO>;
  min?: InputMaybe<PhysicalFeedTransactionAggregateAllDTO>;
  sum?: InputMaybe<PhysicalFeedTransactionAggregateNumericDTO>;
};

export type PhysicalFeedTransactionAggregateNumericDTO = {
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  futuresPrice?: InputMaybe<Scalars['Boolean']>;
  tons?: InputMaybe<Scalars['Boolean']>;
};

export type PhysicalFeedTransactionCreateDTO = {
  basisPrice?: InputMaybe<Scalars['Float']>;
  contractIdentifier?: InputMaybe<Scalars['String']>;
  deliveryEndDate: Scalars['String'];
  deliveryStartDate: Scalars['String'];
  deliveryTerms?: InputMaybe<GrainOrderDeliveryTerms>;
  feedIngredientId: Scalars['String'];
  flatPrice?: InputMaybe<Scalars['Float']>;
  futuresPrice?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  pricingType?: InputMaybe<TypeOfPhysicalFeedTransactionPricing>;
  seller?: InputMaybe<Scalars['String']>;
  tons: Scalars['Float'];
};

export type PhysicalFeedTransactionDistinctOnDTO = {
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractIdentifier?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  deliveryTerms?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  futuresPrice?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  pricingType?: InputMaybe<Scalars['Boolean']>;
  seller?: InputMaybe<Scalars['Boolean']>;
  tons?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PhysicalFeedTransactionFilterDTO = {
  AND?: InputMaybe<Array<PhysicalFeedTransactionFilterDTO>>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientFilterDTO>;
  NOT?: InputMaybe<PhysicalFeedTransactionFilterDTO>;
  OR?: InputMaybe<Array<PhysicalFeedTransactionFilterDTO>>;
  basisPrice?: InputMaybe<NullableNumberFilter>;
  businessId?: InputMaybe<StringFilter>;
  contractIdentifier?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deliveryEndDate?: InputMaybe<StringFilter>;
  deliveryStartDate?: InputMaybe<StringFilter>;
  deliveryTerms?: InputMaybe<NullableGrainOrderDeliveryTermsFilter>;
  feedIngredientId?: InputMaybe<StringFilter>;
  flatPrice?: InputMaybe<NullableNumberFilter>;
  forecastedPnl?: InputMaybe<NullableNumberFilter>;
  futuresPrice?: InputMaybe<NullableNumberFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<NullableStringFilter>;
  pricingType?: InputMaybe<TypeOfPhysicalFeedTransactionPricingFilter>;
  seller?: InputMaybe<NullableStringFilter>;
  tons?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PhysicalFeedTransactionGroupByDTO = {
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientGroupByDTO>;
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractIdentifier?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  deliveryTerms?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  flatPrice?: InputMaybe<Scalars['Boolean']>;
  forecastedPnl?: InputMaybe<Scalars['Boolean']>;
  futuresPrice?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  perMonthData?: InputMaybe<Scalars['Boolean']>;
  pricingType?: InputMaybe<Scalars['Boolean']>;
  seller?: InputMaybe<Scalars['Boolean']>;
  tons?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PhysicalFeedTransactionSortByDTO = {
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientSortByDTO>;
  basisPrice?: InputMaybe<NullableSortByDirection>;
  businessId?: InputMaybe<SortByDirection>;
  contractIdentifier?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deliveryEndDate?: InputMaybe<SortByDirection>;
  deliveryStartDate?: InputMaybe<SortByDirection>;
  deliveryTerms?: InputMaybe<NullableSortByDirection>;
  feedIngredientId?: InputMaybe<SortByDirection>;
  flatPrice?: InputMaybe<NullableSortByDirection>;
  forecastedPnl?: InputMaybe<NullableSortByDirection>;
  futuresPrice?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  location?: InputMaybe<NullableSortByDirection>;
  pricingType?: InputMaybe<SortByDirection>;
  seller?: InputMaybe<NullableSortByDirection>;
  tons?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type PhysicalFeedTransactionUpdateDTO = {
  /** When set, indicates a locked in basis (+/- adjustment to some locked in or to-be-locked-in-later future) Can be set in conjunction with a futuresPrice */
  basisPrice?: InputMaybe<Scalars['Float']>;
  contractIdentifier?: InputMaybe<Scalars['String']>;
  deliveryEndDate?: InputMaybe<Scalars['String']>;
  deliveryStartDate?: InputMaybe<Scalars['String']>;
  deliveryTerms?: InputMaybe<GrainOrderDeliveryTerms>;
  /** When explicitly set, indicates a fixed price in USD. When basisPrice & futuresPrice are set, will be calculated as (basisPrice + futuresPrice) */
  flatPrice?: InputMaybe<Scalars['Float']>;
  /** When set, indicates that a futures price has been locked in for this transaction. Can be set in conjunction with a basisPrice */
  futuresPrice?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  pricingType?: InputMaybe<TypeOfPhysicalFeedTransactionPricing>;
  seller?: InputMaybe<Scalars['String']>;
  tons?: InputMaybe<Scalars['Float']>;
};

export type PhysicalInstrument = IInstrument & {
  __typename?: 'PhysicalInstrument';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  UnderlyingFuture: Future;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deliveryEndAt: Scalars['DateTime'];
  deliveryStartAt: Scalars['DateTime'];
  displayDeliveryDate: Scalars['DateTime'];
  displayExpiresAt: Scalars['String'];
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  isStandardContractSize: Scalars['Boolean'];
  lastTradeAt: Scalars['DateTime'];
  locationId: Scalars['String'];
  name: Scalars['String'];
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  type: TypeOfInstrument;
  underlyingFutureId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type PhysicalInstrument_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type PhysicalInstrument_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type PhysicalInstrument_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type PhysicalInstrument_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type PhysicalInstrumentDistinctOnDTO = {
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndAt?: InputMaybe<Scalars['Boolean']>;
  deliveryStartAt?: InputMaybe<Scalars['Boolean']>;
  displayDeliveryDate?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  underlyingFutureId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PhysicalInstrumentFilterDTO = {
  AND?: InputMaybe<Array<PhysicalInstrumentFilterDTO>>;
  AsInstrument?: InputMaybe<InstrumentFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  DerivedSpreadLegs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  FutureInstrument?: InputMaybe<FutureFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  LegacyFeeds?: InputMaybe<LegacyFeedFilterDTO>;
  LegacyScenarioData?: InputMaybe<LegacyScenarioDataFilterDTO>;
  NOT?: InputMaybe<PhysicalInstrumentFilterDTO>;
  OR?: InputMaybe<Array<PhysicalInstrumentFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationFilterDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  barchartSymbol?: InputMaybe<NullableStringFilter>;
  cqgSymbol?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deliveryEndAt?: InputMaybe<DateTimeFilter>;
  deliveryStartAt?: InputMaybe<DateTimeFilter>;
  displayDeliveryDate?: InputMaybe<DateTimeFilter>;
  displayExpiresAt?: InputMaybe<StringFilter>;
  exchangeSymbol?: InputMaybe<NullableStringFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentSymbolGroupId?: InputMaybe<StringFilter>;
  isStandardContractSize?: InputMaybe<BooleanFilter>;
  lastTradeAt?: InputMaybe<DateTimeFilter>;
  locationId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  productLotSpecificationId?: InputMaybe<StringFilter>;
  underlyingFutureId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PhysicalInstrumentGroupByDTO = {
  AsInstrument?: InputMaybe<InstrumentGroupByDTO>;
  FutureInstrument?: InputMaybe<FutureGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationGroupByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndAt?: InputMaybe<Scalars['Boolean']>;
  deliveryStartAt?: InputMaybe<Scalars['Boolean']>;
  displayDeliveryDate?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  underlyingFutureId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PhysicalInstrumentSortByDTO = {
  AsInstrument?: InputMaybe<InstrumentSortByDTO>;
  FutureInstrument?: InputMaybe<FutureSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationSortByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  barchartSymbol?: InputMaybe<NullableSortByDirection>;
  cqgSymbol?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deliveryEndAt?: InputMaybe<SortByDirection>;
  deliveryStartAt?: InputMaybe<SortByDirection>;
  displayDeliveryDate?: InputMaybe<SortByDirection>;
  displayExpiresAt?: InputMaybe<SortByDirection>;
  exchangeSymbol?: InputMaybe<NullableSortByDirection>;
  expiresAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentSymbolGroupId?: InputMaybe<SortByDirection>;
  isStandardContractSize?: InputMaybe<SortByDirection>;
  lastTradeAt?: InputMaybe<SortByDirection>;
  locationId?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productLotSpecificationId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  underlyingFutureId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type PhysicalSale = {
  __typename?: 'PhysicalSale';
  Counterparty?: Maybe<GrainBuyer>;
  CounterpartyLocation?: Maybe<GrainBuyerLocation>;
  Entity: IEntity;
  Fees: Array<PhysicalSaleFee>;
  Product: Product;
  UnderlyingInstrument?: Maybe<IInstrument>;
  basisPrice?: Maybe<Scalars['Float']>;
  contractNumber?: Maybe<Scalars['String']>;
  counterpartyId?: Maybe<Scalars['String']>;
  counterpartyLocationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deliveryEndDate: Scalars['String'];
  deliveryStartDate: Scalars['String'];
  entityId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  productId: Scalars['String'];
  quantity: Scalars['Float'];
  saleDate: Scalars['String'];
  staticPrice?: Maybe<Scalars['Float']>;
  underlyingInstrumentId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type PhysicalSale_FeesArgs = {
  orderBy?: InputMaybe<PhysicalSaleFeeSortByDTO>;
  where?: InputMaybe<PhysicalSaleFeeFilterDTO>;
};

export type PhysicalSaleCreateDTO = {
  Fees?: InputMaybe<Array<PhysicalSaleFeeForPhysicalSaleCreateDTO>>;
  basisPrice?: InputMaybe<Scalars['Float']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  counterpartyId?: InputMaybe<Scalars['String']>;
  counterpartyLocationId?: InputMaybe<Scalars['String']>;
  deliveryEndDate: Scalars['String'];
  deliveryStartDate: Scalars['String'];
  entityId: Scalars['String'];
  productId: Scalars['String'];
  quantity: Scalars['Float'];
  saleDate: Scalars['String'];
  staticPrice?: InputMaybe<Scalars['Float']>;
  underlyingInstrumentId?: InputMaybe<Scalars['String']>;
};

export type PhysicalSaleDistinctOnDTO = {
  basisPrice?: InputMaybe<Scalars['Boolean']>;
  contractNumber?: InputMaybe<Scalars['Boolean']>;
  counterpartyId?: InputMaybe<Scalars['Boolean']>;
  counterpartyLocationId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deliveryEndDate?: InputMaybe<Scalars['Boolean']>;
  deliveryStartDate?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  saleDate?: InputMaybe<Scalars['Boolean']>;
  staticPrice?: InputMaybe<Scalars['Boolean']>;
  underlyingInstrumentId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PhysicalSaleFee = {
  __typename?: 'PhysicalSaleFee';
  PhysicalSale: PhysicalSale;
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  physicalSaleId: Scalars['String'];
  type: TypeOfPhysicalSaleFee;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type PhysicalSaleFeeCreateDTO = {
  physicalSaleId: Scalars['String'];
  type: TypeOfPhysicalSaleFee;
  value: Scalars['Float'];
};

export type PhysicalSaleFeeDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  physicalSaleId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type PhysicalSaleFeeFilterDTO = {
  AND?: InputMaybe<Array<PhysicalSaleFeeFilterDTO>>;
  NOT?: InputMaybe<PhysicalSaleFeeFilterDTO>;
  OR?: InputMaybe<Array<PhysicalSaleFeeFilterDTO>>;
  PhysicalSale?: InputMaybe<PhysicalSaleFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  physicalSaleId?: InputMaybe<StringFilter>;
  type?: InputMaybe<TypeOfPhysicalSaleFeeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<NumberFilter>;
};

export type PhysicalSaleFeeForPhysicalSaleCreateDTO = {
  type: TypeOfPhysicalSaleFee;
  value: Scalars['Float'];
};

export type PhysicalSaleFeeSortByDTO = {
  PhysicalSale?: InputMaybe<PhysicalSaleSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  physicalSaleId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  value?: InputMaybe<SortByDirection>;
};

export type PhysicalSaleFeeUpdateDTO = {
  physicalSaleId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TypeOfPhysicalSaleFee>;
  value?: InputMaybe<Scalars['Float']>;
};

export type PhysicalSaleFilterDTO = {
  AND?: InputMaybe<Array<PhysicalSaleFilterDTO>>;
  Counterparty?: InputMaybe<GrainBuyerFilterDTO>;
  CounterpartyLocation?: InputMaybe<GrainBuyerLocationFilterDTO>;
  Entity?: InputMaybe<EntityFilterDTO>;
  Fees?: InputMaybe<PhysicalSaleFeeFilterDTO>;
  NOT?: InputMaybe<PhysicalSaleFilterDTO>;
  OR?: InputMaybe<Array<PhysicalSaleFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  UnderlyingInstrument?: InputMaybe<InstrumentFilterDTO>;
  basisPrice?: InputMaybe<NullableNumberFilter>;
  contractNumber?: InputMaybe<NullableStringFilter>;
  counterpartyId?: InputMaybe<NullableStringFilter>;
  counterpartyLocationId?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deliveryEndDate?: InputMaybe<StringFilter>;
  deliveryStartDate?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<NumberFilter>;
  saleDate?: InputMaybe<StringFilter>;
  staticPrice?: InputMaybe<NullableNumberFilter>;
  underlyingInstrumentId?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PhysicalSaleSortByDTO = {
  Counterparty?: InputMaybe<GrainBuyerSortByDTO>;
  CounterpartyLocation?: InputMaybe<GrainBuyerLocationSortByDTO>;
  Entity?: InputMaybe<EntitySortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  UnderlyingInstrument?: InputMaybe<InstrumentSortByDTO>;
  basisPrice?: InputMaybe<NullableSortByDirection>;
  contractNumber?: InputMaybe<NullableSortByDirection>;
  counterpartyId?: InputMaybe<NullableSortByDirection>;
  counterpartyLocationId?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deliveryEndDate?: InputMaybe<SortByDirection>;
  deliveryStartDate?: InputMaybe<SortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  saleDate?: InputMaybe<SortByDirection>;
  staticPrice?: InputMaybe<NullableSortByDirection>;
  underlyingInstrumentId?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type PhysicalSaleUpdateDTO = {
  basisPrice?: InputMaybe<Scalars['Float']>;
  contractNumber?: InputMaybe<Scalars['String']>;
  counterpartyId?: InputMaybe<Scalars['String']>;
  counterpartyLocationId?: InputMaybe<Scalars['String']>;
  deliveryEndDate?: InputMaybe<Scalars['String']>;
  deliveryStartDate?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  saleDate?: InputMaybe<Scalars['String']>;
  staticPrice?: InputMaybe<Scalars['Float']>;
  underlyingInstrumentId?: InputMaybe<Scalars['String']>;
};

/** The components of a position for the given account / instrument for each date. The position components represent the sub-quantities of each position by price & trade date. */
export type PositionComponent = {
  __typename?: 'PositionComponent';
  Account: IAccount;
  CurrentPosition: CurrentPosition;
  Instrument: IInstrument;
  accountId: Scalars['String'];
  aclResourceType: TypeOfAclResource;
  createdAt: Scalars['DateTime'];
  /** The trade date for which this position component is current */
  date: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  instrumentId: Scalars['String'];
  integrationInstallationId: Scalars['String'];
  /** A generated field containing accountId + '+' + instrumentId. This matches the position id format used by current positions and transactions. */
  positionId: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  /** The trade date upon which this price/quantity position was created */
  tradeDate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PositionComponentAggregateAllDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  tradeDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PositionComponentAggregateDTO = {
  avg?: InputMaybe<PositionComponentAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<PositionComponentAggregateAllDTO>;
  min?: InputMaybe<PositionComponentAggregateAllDTO>;
  sum?: InputMaybe<PositionComponentAggregateNumericDTO>;
};

export type PositionComponentAggregateNumericDTO = {
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
};

export type PositionComponentAllocation = {
  __typename?: 'PositionComponentAllocation';
  Account: IAccount;
  /** This will almost always be set, but it is possible for a request to be made at a time when there is no current allocation position yet */
  CurrentAllocationPosition?: Maybe<CurrentAllocationPosition>;
  CurrentPosition: CurrentPosition;
  Instrument: IInstrument;
  accountId: Scalars['String'];
  /** A generated field containing accountId + '+' + instrumentId + '+' + effectiveHedgeDate. This matches the position id format used by current position allocations. */
  allocationPositionId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  effectiveHedgeDate: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  instrumentId: Scalars['String'];
  longContractQuantity: Scalars['Float'];
  longUnitQuantity: Scalars['Float'];
  netContractQuantity: Scalars['Float'];
  netUnitQuantity: Scalars['Float'];
  /** A generated field containing accountId + '+' + instrumentId. This matches the position id format used by current positions and transactions. */
  positionId: Scalars['String'];
  price: Scalars['Float'];
  shortContractQuantity: Scalars['Float'];
  shortUnitQuantity: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type PositionComponentAllocationAggregateAllDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  allocationPositionId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  longContractQuantity?: InputMaybe<Scalars['Boolean']>;
  longUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  netContractQuantity?: InputMaybe<Scalars['Boolean']>;
  netUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  shortContractQuantity?: InputMaybe<Scalars['Boolean']>;
  shortUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PositionComponentAllocationAggregateDTO = {
  avg?: InputMaybe<PositionComponentAllocationAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<PositionComponentAllocationAggregateAllDTO>;
  min?: InputMaybe<PositionComponentAllocationAggregateAllDTO>;
  sum?: InputMaybe<PositionComponentAllocationAggregateNumericDTO>;
};

export type PositionComponentAllocationAggregateNumericDTO = {
  longContractQuantity?: InputMaybe<Scalars['Boolean']>;
  longUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  netContractQuantity?: InputMaybe<Scalars['Boolean']>;
  netUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  shortContractQuantity?: InputMaybe<Scalars['Boolean']>;
  shortUnitQuantity?: InputMaybe<Scalars['Boolean']>;
};

export type PositionComponentAllocationDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  allocationPositionId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  longContractQuantity?: InputMaybe<Scalars['Boolean']>;
  longUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  netContractQuantity?: InputMaybe<Scalars['Boolean']>;
  netUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  shortContractQuantity?: InputMaybe<Scalars['Boolean']>;
  shortUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PositionComponentAllocationFilterDTO = {
  AND?: InputMaybe<Array<PositionComponentAllocationFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  CurrentAllocationPosition?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionFilterDTO>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<PositionComponentAllocationFilterDTO>;
  OR?: InputMaybe<Array<PositionComponentAllocationFilterDTO>>;
  accountId?: InputMaybe<StringFilter>;
  allocationPositionId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  effectiveHedgeDate?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  longContractQuantity?: InputMaybe<NumberFilter>;
  longUnitQuantity?: InputMaybe<NumberFilter>;
  netContractQuantity?: InputMaybe<NumberFilter>;
  netUnitQuantity?: InputMaybe<NumberFilter>;
  positionId?: InputMaybe<StringFilter>;
  price?: InputMaybe<NumberFilter>;
  shortContractQuantity?: InputMaybe<NumberFilter>;
  shortUnitQuantity?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PositionComponentAllocationGroupByDTO = {
  Account?: InputMaybe<AccountGroupByDTO>;
  AccountAsBrokerageAccount?: InputMaybe<BrokerageAccountGroupByDTO>;
  AccountAsForwardContractAccount?: InputMaybe<ForwardContractAccountGroupByDTO>;
  AccountAsSwapAccount?: InputMaybe<SwapAccountGroupByDTO>;
  CurrentAllocationPosition?: InputMaybe<CurrentAllocationPositionGroupByDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionGroupByDTO>;
  Instrument?: InputMaybe<InstrumentGroupByDTO>;
  InstrumentAsBasisInstrument?: InputMaybe<BasisInstrumentGroupByDTO>;
  InstrumentAsCashInstrument?: InputMaybe<CashInstrumentGroupByDTO>;
  InstrumentAsCommitmentInstrument?: InputMaybe<CommitmentInstrumentGroupByDTO>;
  InstrumentAsFutureInstrument?: InputMaybe<FutureGroupByDTO>;
  InstrumentAsOptionInstrument?: InputMaybe<OptionGroupByDTO>;
  InstrumentAsPhysicalInstrument?: InputMaybe<PhysicalInstrumentGroupByDTO>;
  InstrumentAsSpreadInstrument?: InputMaybe<SpreadInstrumentGroupByDTO>;
  InstrumentAsSwapInstrument?: InputMaybe<SwapGroupByDTO>;
  InstrumentAsSwaptionInstrument?: InputMaybe<SwaptionGroupByDTO>;
  accountId?: InputMaybe<Scalars['Boolean']>;
  allocationPositionId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  effectiveHedgeDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  longContractQuantity?: InputMaybe<Scalars['Boolean']>;
  longUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  netContractQuantity?: InputMaybe<Scalars['Boolean']>;
  netUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  shortContractQuantity?: InputMaybe<Scalars['Boolean']>;
  shortUnitQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PositionComponentAllocationSortByDTO = {
  Account?: InputMaybe<AccountSortByDTO>;
  CurrentAllocationPosition?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionSortByDTO>;
  Instrument?: InputMaybe<InstrumentSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  allocationPositionId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  effectiveHedgeDate?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  instrumentId?: InputMaybe<SortByDirection>;
  longContractQuantity?: InputMaybe<SortByDirection>;
  longUnitQuantity?: InputMaybe<SortByDirection>;
  netContractQuantity?: InputMaybe<SortByDirection>;
  netUnitQuantity?: InputMaybe<SortByDirection>;
  positionId?: InputMaybe<SortByDirection>;
  price?: InputMaybe<SortByDirection>;
  shortContractQuantity?: InputMaybe<SortByDirection>;
  shortUnitQuantity?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type PositionComponentAllocationsSetInput = {
  accountId: Scalars['String'];
  componentAllocations: Array<ComponentAllocationsInput>;
  instrumentId: Scalars['String'];
  price: Scalars['Float'];
};

export type PositionComponentDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  aclResourceType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  tradeDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PositionComponentFilterDTO = {
  AND?: InputMaybe<Array<PositionComponentFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionFilterDTO>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  IntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  NOT?: InputMaybe<PositionComponentFilterDTO>;
  OR?: InputMaybe<Array<PositionComponentFilterDTO>>;
  accountId?: InputMaybe<StringFilter>;
  aclResourceType?: InputMaybe<TypeOfAclResourceFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  integrationInstallationId?: InputMaybe<StringFilter>;
  positionId?: InputMaybe<StringFilter>;
  price?: InputMaybe<NumberFilter>;
  quantity?: InputMaybe<NumberFilter>;
  tradeDate?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PositionComponentGroupByDTO = {
  Account?: InputMaybe<AccountGroupByDTO>;
  AccountAsBrokerageAccount?: InputMaybe<BrokerageAccountGroupByDTO>;
  AccountAsForwardContractAccount?: InputMaybe<ForwardContractAccountGroupByDTO>;
  AccountAsSwapAccount?: InputMaybe<SwapAccountGroupByDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionGroupByDTO>;
  Instrument?: InputMaybe<InstrumentGroupByDTO>;
  InstrumentAsBasisInstrument?: InputMaybe<BasisInstrumentGroupByDTO>;
  InstrumentAsCashInstrument?: InputMaybe<CashInstrumentGroupByDTO>;
  InstrumentAsCommitmentInstrument?: InputMaybe<CommitmentInstrumentGroupByDTO>;
  InstrumentAsFutureInstrument?: InputMaybe<FutureGroupByDTO>;
  InstrumentAsOptionInstrument?: InputMaybe<OptionGroupByDTO>;
  InstrumentAsPhysicalInstrument?: InputMaybe<PhysicalInstrumentGroupByDTO>;
  InstrumentAsSpreadInstrument?: InputMaybe<SpreadInstrumentGroupByDTO>;
  InstrumentAsSwapInstrument?: InputMaybe<SwapGroupByDTO>;
  InstrumentAsSwaptionInstrument?: InputMaybe<SwaptionGroupByDTO>;
  IntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  accountId?: InputMaybe<Scalars['Boolean']>;
  aclResourceType?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  tradeDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PositionComponentSortByDTO = {
  Account?: InputMaybe<AccountSortByDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionSortByDTO>;
  Instrument?: InputMaybe<InstrumentSortByDTO>;
  IntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  aclResourceType?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  instrumentId?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<SortByDirection>;
  positionId?: InputMaybe<SortByDirection>;
  price?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  tradeDate?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type PositionReconciliationFilterDTO = {
  AND?: InputMaybe<Array<PositionReconciliationFilterDTO>>;
  Account?: InputMaybe<BrokerageAccountFilterDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionFilterDTO>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<PositionReconciliationFilterDTO>;
  OR?: InputMaybe<Array<PositionReconciliationFilterDTO>>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  isFullyReconciled?: InputMaybe<BooleanFilter>;
  isUnsupported?: InputMaybe<BooleanFilter>;
  positionId?: InputMaybe<StringFilter>;
  reasonUnsupported?: InputMaybe<NullableStringFilter>;
  reconciledThroughDate?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PositionReconciliationGroupByDTO = {
  Account?: InputMaybe<BrokerageAccountGroupByDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionGroupByDTO>;
  Instrument?: InputMaybe<InstrumentGroupByDTO>;
  InstrumentAsBasisInstrument?: InputMaybe<BasisInstrumentGroupByDTO>;
  InstrumentAsCashInstrument?: InputMaybe<CashInstrumentGroupByDTO>;
  InstrumentAsCommitmentInstrument?: InputMaybe<CommitmentInstrumentGroupByDTO>;
  InstrumentAsFutureInstrument?: InputMaybe<FutureGroupByDTO>;
  InstrumentAsOptionInstrument?: InputMaybe<OptionGroupByDTO>;
  InstrumentAsPhysicalInstrument?: InputMaybe<PhysicalInstrumentGroupByDTO>;
  InstrumentAsSpreadInstrument?: InputMaybe<SpreadInstrumentGroupByDTO>;
  InstrumentAsSwapInstrument?: InputMaybe<SwapGroupByDTO>;
  InstrumentAsSwaptionInstrument?: InputMaybe<SwaptionGroupByDTO>;
  accountId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  isFullyReconciled?: InputMaybe<Scalars['Boolean']>;
  isUnsupported?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  reasonUnsupported?: InputMaybe<Scalars['Boolean']>;
  reconciledThroughDate?: InputMaybe<Scalars['Boolean']>;
  unreconciledData?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type PositionReconciliationSortByDTO = {
  Account?: InputMaybe<BrokerageAccountSortByDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionSortByDTO>;
  Instrument?: InputMaybe<InstrumentSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  instrumentId?: InputMaybe<SortByDirection>;
  isFullyReconciled?: InputMaybe<SortByDirection>;
  isUnsupported?: InputMaybe<SortByDirection>;
  positionId?: InputMaybe<SortByDirection>;
  reasonUnsupported?: InputMaybe<NullableSortByDirection>;
  reconciledThroughDate?: InputMaybe<NullableSortByDirection>;
  unreconciledData?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type PracticeRequestDTO = {
  salesEffectiveDate?: InputMaybe<Scalars['String']>;
  stateAbbreviation: Scalars['String'];
};

export type ProducerAndPolicyFilterDTO = {
  AND?: InputMaybe<Array<ProducerAndPolicyFilterDTO>>;
  Aip?: InputMaybe<AipFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  DerivedDrpEndorsement?: InputMaybe<DerivedDrpEndorsementFilterDTO>;
  Endorsements?: InputMaybe<InsuranceEndorsementFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  InsuranceAgent?: InputMaybe<InsuranceAgentFilterDTO>;
  InsuranceAgentPolicies?: InputMaybe<InsuranceAgentPolicyFilterDTO>;
  InsuranceEndorsementAllocationRatios?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
  InsuranceFiles?: InputMaybe<InsuranceFileFilterDTO>;
  NOT?: InputMaybe<ProducerAndPolicyFilterDTO>;
  OR?: InputMaybe<Array<ProducerAndPolicyFilterDTO>>;
  Owner?: InputMaybe<EntityFilterDTO>;
  State?: InputMaybe<StateFilterDTO>;
  agencyName?: InputMaybe<NullableStringFilter>;
  agentFirstName?: InputMaybe<NullableStringFilter>;
  agentFullName?: InputMaybe<NullableStringFilter>;
  agentId?: InputMaybe<NullableStringFilter>;
  agentLastName?: InputMaybe<NullableStringFilter>;
  aipId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  entityId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  policyNumber?: InputMaybe<StringFilter>;
  producerId?: InputMaybe<NullableStringFilter>;
  producerName?: InputMaybe<StringFilter>;
  programType?: InputMaybe<TypeOfInsuranceProgramFilter>;
  stateId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProducerAndPolicySortByDTO = {
  Aip?: InputMaybe<AipSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  InsuranceAgent?: InputMaybe<InsuranceAgentSortByDTO>;
  Owner?: InputMaybe<EntitySortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  agencyName?: InputMaybe<NullableSortByDirection>;
  agentFirstName?: InputMaybe<NullableSortByDirection>;
  agentFullName?: InputMaybe<NullableSortByDirection>;
  agentId?: InputMaybe<NullableSortByDirection>;
  agentLastName?: InputMaybe<NullableSortByDirection>;
  aipId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  entityId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  ownerId?: InputMaybe<SortByDirection>;
  policyNumber?: InputMaybe<SortByDirection>;
  producerId?: InputMaybe<NullableSortByDirection>;
  producerName?: InputMaybe<SortByDirection>;
  programType?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type Product = {
  __typename?: 'Product';
  CashInstrument?: Maybe<CashInstrument>;
  Crops: Array<CropCategory>;
  CurrentFutures: Array<Future>;
  FeedIngredients: Array<FeedIngredient>;
  InstrumentSymbolGroups: Array<InstrumentSymbolGroup>;
  MostCurrentFuture?: Maybe<Future>;
  MostRecentlyExpiredFuture?: Maybe<Future>;
  NextSession: TradingSession;
  NextSessionFromTimestamp: TradingSession;
  PhysicalSales: Array<PhysicalSale>;
  ProductGroup: ProductGroup;
  /** Returns all product lot specifications for this product, including abnormal size types like mini and micro sizes. If you only want the standard-sized lot specification, use StandardProductLotSpecification */
  ProductLotSpecifications: Array<ProductLotSpecification>;
  /** Returns the standard-sized product lot specification for this product. */
  StandardProductLotSpecification: ProductLotSpecification;
  /** @deprecated Use InstrumentSymbolGroup.gmiRootSymbols instead */
  admRootSymbols: Array<Scalars['String']>;
  barchartDisplayFactor: Scalars['Float'];
  /** @deprecated Use InstrumentSymbolGroup.barchartRootSymbol instead */
  barchartRootSymbol?: Maybe<Scalars['String']>;
  barchartSymbolStrikeDisplayFactor: Scalars['Float'];
  contractName?: Maybe<Scalars['String']>;
  contractSize?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  /** @deprecated Use InstrumentSymbolGroup.cqgRootSymbol instead */
  cqgRootSymbol?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  /** @deprecated Use "CurrentFutures instead" */
  currentFutures: Array<Future>;
  dailyLimit?: Maybe<Scalars['String']>;
  /** @deprecated Use InstrumentSymbolGroup.displayFactor instead */
  displayFactor: Scalars['Float'];
  exchangeMargin?: Maybe<Scalars['String']>;
  /** @deprecated Use InstrumentSymbolGroup.exchangeRootSymbol instead */
  exchangeRootSymbol?: Maybe<Scalars['String']>;
  exchangeToGmiConversionFactor: Scalars['Float'];
  /** @deprecated Use InstrumentSymbolGroup.fractionDigits instead */
  fractionDigits: Scalars['Int'];
  /** @deprecated Use InstrumentSymbolGroup.gmiExchangeCode instead */
  gmiExchangeCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  monthMapping: Scalars['Object'];
  monthsTraded: Array<MonthSymbol>;
  /** @deprecated Use "MostCurrentFuture" instead */
  mostCurrentFuture?: Maybe<Future>;
  name: Scalars['String'];
  optionsUnitValue?: Maybe<Scalars['Float']>;
  /** @deprecated Use ProductLotSpecification.pointValue instead */
  pointValue?: Maybe<Scalars['Float']>;
  productGroupId: Scalars['String'];
  slug: Scalars['String'];
  tickValue?: Maybe<Scalars['String']>;
  tradingHours?: Maybe<Scalars['String']>;
  tradingSchedule: Array<Scalars['Object']>;
  /** @deprecated This value should not be used in any new code */
  velaRootSymbol?: Maybe<Scalars['String']>;
  venueId: Scalars['String'];
  /** @deprecated Use the Venue relation instead */
  venueName: Scalars['String'];
};


export type Product_CropsArgs = {
  orderBy?: InputMaybe<CropCategorySortByDTO>;
  where?: InputMaybe<CropCategoryFilterDTO>;
};


export type Product_CurrentFuturesArgs = {
  where?: InputMaybe<FutureFilterDTO>;
};


export type Product_FeedIngredientsArgs = {
  orderBy?: InputMaybe<FeedIngredientSortByDTO>;
  where?: InputMaybe<FeedIngredientFilterDTO>;
};


export type Product_MostRecentlyExpiredFutureArgs = {
  where?: InputMaybe<FutureFilterDTO>;
};


export type Product_NextSessionFromTimestampArgs = {
  timestamp: Scalars['DateTime'];
};


export type Product_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type Product_ProductLotSpecificationsArgs = {
  orderBy?: InputMaybe<ProductLotSpecificationSortByDTO>;
  where?: InputMaybe<ProductLotSpecificationFilterDTO>;
};


export type Product_currentFuturesArgs = {
  where?: InputMaybe<FutureFilterDTO>;
};

export type ProductDistinctOnDTO = {
  admRootSymbols?: InputMaybe<Scalars['Boolean']>;
  barchartDisplayFactor?: InputMaybe<Scalars['Boolean']>;
  barchartRootSymbol?: InputMaybe<Scalars['Boolean']>;
  barchartSymbolStrikeDisplayFactor?: InputMaybe<Scalars['Boolean']>;
  contractName?: InputMaybe<Scalars['Boolean']>;
  contractSize?: InputMaybe<Scalars['Boolean']>;
  countryCode?: InputMaybe<Scalars['Boolean']>;
  cqgRootSymbol?: InputMaybe<Scalars['Boolean']>;
  currency?: InputMaybe<Scalars['Boolean']>;
  dailyLimit?: InputMaybe<Scalars['Boolean']>;
  displayFactor?: InputMaybe<Scalars['Boolean']>;
  exchangeMargin?: InputMaybe<Scalars['Boolean']>;
  exchangeRootSymbol?: InputMaybe<Scalars['Boolean']>;
  exchangeToGmiConversionFactor?: InputMaybe<Scalars['Boolean']>;
  fractionDigits?: InputMaybe<Scalars['Boolean']>;
  gmiExchangeCode?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  monthMapping?: InputMaybe<Scalars['Boolean']>;
  monthsTraded?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  optionsUnitValue?: InputMaybe<Scalars['Boolean']>;
  pointValue?: InputMaybe<Scalars['Boolean']>;
  productGroupId?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  tickValue?: InputMaybe<Scalars['Boolean']>;
  tradingHours?: InputMaybe<Scalars['Boolean']>;
  tradingSchedule?: InputMaybe<Scalars['Boolean']>;
  velaRootSymbol?: InputMaybe<Scalars['Boolean']>;
  venueId?: InputMaybe<Scalars['Boolean']>;
  venueName?: InputMaybe<Scalars['Boolean']>;
};

export type ProductFilterDTO = {
  AND?: InputMaybe<Array<ProductFilterDTO>>;
  ActualProduction?: InputMaybe<ActualProductionFilterDTO>;
  AllocatedForecastedHedgedAndCappedVolumes?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>;
  Crops?: InputMaybe<CropCategoryFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentInventoryLevels?: InputMaybe<CurrentInventoryLevelFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  EntityActiveProducts?: InputMaybe<EntityActiveProductFilterDTO>;
  EntityAllocatedExposureRatios?: InputMaybe<EntityAllocatedExposureRatioFilterDTO>;
  EntityAllocatedExposureRatiosByOwner?: InputMaybe<EntityAllocatedExposureRatioByOwnerFilterDTO>;
  EntityAllocatedForecastedBrokerageVolumesHedged?: InputMaybe<EntityAllocatedForecastedBrokerageVolumeHedgedFilterDTO>;
  EntityAllocatedForecastedVolumesHedgedByOwner?: InputMaybe<EntityAllocatedForecastedVolumeHedgedByOwnerFilterDTO>;
  EntityExposureRatios?: InputMaybe<EntityExposureRatioFilterDTO>;
  EntityForecastedPhysicalVolumesHedged?: InputMaybe<EntityForecastedPhysicalVolumeHedgedFilterDTO>;
  FeedCategories?: InputMaybe<FeedCategoryFilterDTO>;
  FeedCategory?: InputMaybe<FeedCategoryFilterDTO>;
  FeedIngredients?: InputMaybe<FeedIngredientFilterDTO>;
  ForecastedHedgedAndCappedVolume?: InputMaybe<ForecastedHedgedAndCappedVolumeFilterDTO>;
  ForecastedProduction?: InputMaybe<ForecastedProductionFilterDTO>;
  ForwardContractAccounts?: InputMaybe<ForwardContractAccountFilterDTO>;
  HedgeStrategies?: InputMaybe<HedgeStrategyFilterDTO>;
  HistoricalProductSettlementRanges?: InputMaybe<HistoricalProductSettlementRangeFilterDTO>;
  InstrumentSymbolGroups?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Instruments?: InputMaybe<InstrumentFilterDTO>;
  InventoryLevels?: InputMaybe<InventoryLevelFilterDTO>;
  LgmInsuranceEndorsements?: InputMaybe<LgmInsuranceEndorsementFilterDTO>;
  LrpInsuranceEndorsements?: InputMaybe<LrpInsuranceEndorsementFilterDTO>;
  NOT?: InputMaybe<ProductFilterDTO>;
  OR?: InputMaybe<Array<ProductFilterDTO>>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  ProductGroup?: InputMaybe<ProductGroupFilterDTO>;
  ProductLotSpecifications?: InputMaybe<ProductLotSpecificationFilterDTO>;
  Venue?: InputMaybe<VenueFilterDTO>;
  admRootSymbols?: InputMaybe<StringArrayFilter>;
  barchartDisplayFactor?: InputMaybe<NumberFilter>;
  barchartRootSymbol?: InputMaybe<NullableStringFilter>;
  barchartSymbolStrikeDisplayFactor?: InputMaybe<NumberFilter>;
  contractName?: InputMaybe<NullableStringFilter>;
  contractSize?: InputMaybe<NullableStringFilter>;
  countryCode?: InputMaybe<NullableStringFilter>;
  cqgRootSymbol?: InputMaybe<NullableStringFilter>;
  currency?: InputMaybe<NullableStringFilter>;
  dailyLimit?: InputMaybe<NullableStringFilter>;
  displayFactor?: InputMaybe<NumberFilter>;
  exchangeMargin?: InputMaybe<NullableStringFilter>;
  exchangeRootSymbol?: InputMaybe<NullableStringFilter>;
  exchangeToGmiConversionFactor?: InputMaybe<NumberFilter>;
  fractionDigits?: InputMaybe<NumberFilter>;
  gmiExchangeCode?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  monthsTraded?: InputMaybe<MonthSymbolArrayFilterDTO>;
  name?: InputMaybe<StringFilter>;
  optionsUnitValue?: InputMaybe<NullableNumberFilter>;
  pointValue?: InputMaybe<NullableNumberFilter>;
  productGroupId?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  tickValue?: InputMaybe<NullableStringFilter>;
  tradingHours?: InputMaybe<NullableStringFilter>;
  velaRootSymbol?: InputMaybe<NullableStringFilter>;
  venueId?: InputMaybe<StringFilter>;
  venueName?: InputMaybe<StringFilter>;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  CashInstruments: Array<CashInstrument>;
  Products: Array<Product>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type ProductGroup_ProductsArgs = {
  orderBy?: InputMaybe<ProductSortByDTO>;
  where?: InputMaybe<ProductFilterDTO>;
};

export type ProductGroupByDTO = {
  ProductGroup?: InputMaybe<ProductGroupGroupByDTO>;
  Venue?: InputMaybe<VenueGroupByDTO>;
  admRootSymbols?: InputMaybe<Scalars['Boolean']>;
  barchartDisplayFactor?: InputMaybe<Scalars['Boolean']>;
  barchartRootSymbol?: InputMaybe<Scalars['Boolean']>;
  barchartSymbolStrikeDisplayFactor?: InputMaybe<Scalars['Boolean']>;
  contractName?: InputMaybe<Scalars['Boolean']>;
  contractSize?: InputMaybe<Scalars['Boolean']>;
  countryCode?: InputMaybe<Scalars['Boolean']>;
  cqgRootSymbol?: InputMaybe<Scalars['Boolean']>;
  currency?: InputMaybe<Scalars['Boolean']>;
  dailyLimit?: InputMaybe<Scalars['Boolean']>;
  displayFactor?: InputMaybe<Scalars['Boolean']>;
  exchangeMargin?: InputMaybe<Scalars['Boolean']>;
  exchangeRootSymbol?: InputMaybe<Scalars['Boolean']>;
  exchangeToGmiConversionFactor?: InputMaybe<Scalars['Boolean']>;
  fractionDigits?: InputMaybe<Scalars['Boolean']>;
  gmiExchangeCode?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  monthMapping?: InputMaybe<Scalars['Boolean']>;
  monthsTraded?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  optionsUnitValue?: InputMaybe<Scalars['Boolean']>;
  pointValue?: InputMaybe<Scalars['Boolean']>;
  productGroupId?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['Boolean']>;
  tickValue?: InputMaybe<Scalars['Boolean']>;
  tradingHours?: InputMaybe<Scalars['Boolean']>;
  tradingSchedule?: InputMaybe<Scalars['Boolean']>;
  velaRootSymbol?: InputMaybe<Scalars['Boolean']>;
  venueId?: InputMaybe<Scalars['Boolean']>;
  venueName?: InputMaybe<Scalars['Boolean']>;
};

export type ProductGroupDistinctOnDTO = {
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
};

export type ProductGroupFilterDTO = {
  AND?: InputMaybe<Array<ProductGroupFilterDTO>>;
  NOT?: InputMaybe<ProductGroupFilterDTO>;
  OR?: InputMaybe<Array<ProductGroupFilterDTO>>;
  Products?: InputMaybe<ProductFilterDTO>;
  VenueHolidays?: InputMaybe<VenueHolidayFilterDTO>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ProductGroupGroupByDTO = {
  description?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
};

export type ProductGroupSortByDTO = {
  description?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
};

export type ProductLotSpecification = {
  __typename?: 'ProductLotSpecification';
  Product: Product;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isStandardLot: Scalars['Boolean'];
  lotSize: Scalars['Float'];
  pointValue: Scalars['Float'];
  productId: Scalars['String'];
  /** Tick value is calculated as per-contract value / point value and is represented as points. */
  tickValue: Scalars['Float'];
  type: TypeOfProductLotSpecification;
  updatedAt: Scalars['DateTime'];
};

export type ProductLotSpecificationFilterDTO = {
  AND?: InputMaybe<Array<ProductLotSpecificationFilterDTO>>;
  Instruments?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<ProductLotSpecificationFilterDTO>;
  OR?: InputMaybe<Array<ProductLotSpecificationFilterDTO>>;
  Product?: InputMaybe<ProductFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isStandardLot?: InputMaybe<BooleanFilter>;
  lotSize?: InputMaybe<NumberFilter>;
  pointValue?: InputMaybe<NumberFilter>;
  productId?: InputMaybe<StringFilter>;
  tickValue?: InputMaybe<NumberFilter>;
  type?: InputMaybe<TypeOfProductLotSpecificationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductLotSpecificationGroupByDTO = {
  Product?: InputMaybe<ProductGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isStandardLot?: InputMaybe<Scalars['Boolean']>;
  lotSize?: InputMaybe<Scalars['Boolean']>;
  pointValue?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  tickValue?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type ProductLotSpecificationSortByDTO = {
  Product?: InputMaybe<ProductSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isStandardLot?: InputMaybe<SortByDirection>;
  lotSize?: InputMaybe<SortByDirection>;
  pointValue?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  tickValue?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type ProductSortByDTO = {
  ProductGroup?: InputMaybe<ProductGroupSortByDTO>;
  Venue?: InputMaybe<VenueSortByDTO>;
  barchartDisplayFactor?: InputMaybe<SortByDirection>;
  barchartRootSymbol?: InputMaybe<NullableSortByDirection>;
  barchartSymbolStrikeDisplayFactor?: InputMaybe<SortByDirection>;
  contractName?: InputMaybe<NullableSortByDirection>;
  contractSize?: InputMaybe<NullableSortByDirection>;
  countryCode?: InputMaybe<NullableSortByDirection>;
  cqgRootSymbol?: InputMaybe<NullableSortByDirection>;
  currency?: InputMaybe<NullableSortByDirection>;
  dailyLimit?: InputMaybe<NullableSortByDirection>;
  displayFactor?: InputMaybe<SortByDirection>;
  exchangeMargin?: InputMaybe<NullableSortByDirection>;
  exchangeRootSymbol?: InputMaybe<NullableSortByDirection>;
  exchangeToGmiConversionFactor?: InputMaybe<SortByDirection>;
  fractionDigits?: InputMaybe<SortByDirection>;
  gmiExchangeCode?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  monthMapping?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  optionsUnitValue?: InputMaybe<NullableSortByDirection>;
  pointValue?: InputMaybe<NullableSortByDirection>;
  productGroupId?: InputMaybe<SortByDirection>;
  slug?: InputMaybe<SortByDirection>;
  tickValue?: InputMaybe<NullableSortByDirection>;
  tradingHours?: InputMaybe<NullableSortByDirection>;
  velaRootSymbol?: InputMaybe<NullableSortByDirection>;
  venueId?: InputMaybe<SortByDirection>;
  venueName?: InputMaybe<SortByDirection>;
};

export type Query = {
  __typename?: 'Query';
  /** Find one AIP by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  AIP?: Maybe<Aip>;
  /** Return the number of AIP rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  AIPCount: Count;
  /** Find many AIP rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  AIPs: Array<Aip>;
  /** Find one Account by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ access unto the same resource type the Account subtype being returned (one of the following: BROKERAGE, SWAP) */
  Account?: Maybe<IAccount>;
  /** Find one AccountBalance by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  AccountBalance?: Maybe<AccountBalance>;
  /** Return the number of AccountBalance rows that match the given filter.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  AccountBalanceCount: Count;
  /** Find many AccountBalance rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  AccountBalances: Array<AccountBalance>;
  /** Return the number of Account rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ access unto the same resource type the Account subtype being returned (one of the following: BROKERAGE, SWAP) */
  AccountCount: Count;
  /** Find one AccountFile by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this AccountFile (one of the following: BROKERAGE, SWAP). */
  AccountFile?: Maybe<AccountFile>;
  /** Return the number of AccountFile rows that match the given filter.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this AccountFile (one of the following: BROKERAGE, SWAP). */
  AccountFileCount: Count;
  /** Find many AccountFile rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this AccountFile (one of the following: BROKERAGE, SWAP). */
  AccountFiles: Array<AccountFile>;
  /** Find one AccountNote by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this AccountNote (one of the following: BROKERAGE, SWAP). */
  AccountNote?: Maybe<AccountNote>;
  /** Return the number of AccountNote rows that match the given filter.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this AccountNote (one of the following: BROKERAGE, SWAP). */
  AccountNoteCount: Count;
  /** Find many AccountNote rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this AccountNote (one of the following: BROKERAGE, SWAP). */
  AccountNotes: Array<AccountNote>;
  /** Find many Account rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ access unto the same resource type the Account subtype being returned (one of the following: BROKERAGE, SWAP) */
  Accounts: Array<IAccount>;
  /** Find one ActualBlendedMilkPrice by id. null is returned if no value with the given id is available to the current user. The actual blended milk price for the given entity / date. This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualBlendedMilkPrice?: Maybe<ActualBlendedMilkPrice>;
  /** Return the number of ActualBlendedMilkPrice rows that match the given filter. The actual blended milk price for the given entity / date. This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualBlendedMilkPriceCount: Count;
  /** Find many ActualBlendedMilkPrice rows that match the given filter. If no limit is provided, a default limit of 500 will be used. The actual blended milk price for the given entity / date. This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualBlendedMilkPrices: Array<ActualBlendedMilkPrice>;
  /** Find many ActualMilkProductionRow rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualMilkProduction: Array<ActualMilkProduction>;
  /** Find many ActualMilkProductionByMonthRow rows that match the given filter. If no limit is provided, a default limit of 500 will be used. The actual milk production for the given entity aggregated by month. This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualMilkProductionByMonth: Array<ActualMilkProductionByMonth>;
  /** Find one ActualMilkProductionByMonthRow by id. null is returned if no value with the given id is available to the current user. The actual milk production for the given entity aggregated by month. This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualMilkProductionByMonthRow?: Maybe<ActualMilkProductionByMonth>;
  /** Return the number of ActualMilkProductionByMonthRow rows that match the given filter. The actual milk production for the given entity aggregated by month. This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualMilkProductionByMonthRowCount: Count;
  /** Find one ActualMilkProductionRow by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualMilkProductionRow?: Maybe<ActualMilkProduction>;
  /** Return the number of ActualMilkProductionRow rows that match the given filter.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualMilkProductionRowCount: Count;
  /** Find many ActualProductionRow rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualProduction: Array<ActualProduction>;
  /** Find one ActualProductionRow by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualProductionRow?: Maybe<ActualProduction>;
  /** Return the number of ActualProductionRow rows that match the given filter.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ActualProductionRowCount: Count;
  /** Perform an aggregate query against the ActualBlendedMilkPrice concept. The actual blended milk price for the given entity / date. This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateActualBlendedMilkPrices: Array<AggregateActualBlendedMilkPriceDTO>;
  /** Perform an aggregate query against the ActualMilkProductionRow concept.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateActualMilkProduction: Array<AggregateActualMilkProductionDTO>;
  /** Perform an aggregate query against the ActualMilkProductionByMonthRow concept. The actual milk production for the given entity aggregated by month. This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateActualMilkProductionByMonth: Array<AggregateActualMilkProductionByMonthDTO>;
  /** Perform an aggregate query against the ActualProductionRow concept.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateActualProduction: Array<AggregateActualProductionDTO>;
  /** Perform an aggregate query against the AllocatedForecastedHedgedAndCappedVolume concept.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateAllocatedForecastedHedgedAndCappedVolumes: Array<AggregateAllocatedForecastedHedgedAndCappedVolumeDTO>;
  /** Perform an aggregate query against the BrokerageAccount concept.  This resource is explicitly scoped via id. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  AggregateBrokerageAccounts: Array<AggregateBrokerageAccountDTO>;
  /** Perform an aggregate query against the CropHarvest concept.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateCropHarvests: Array<AggregateCropHarvestDTO>;
  /** Perform an aggregate query against the CurrentAllocationPosition concept.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ Persisted access at the scope of this resource. */
  AggregateCurrentAllocationPositions: Array<AggregateCurrentAllocationPositionDTO>;
  /** Perform an aggregate query against the DoNotUse__CurrentInventoryLevel concept.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateCurrentInventoryLevels: Array<AggregateInventoryLevelDTO>;
  /** Perform an aggregate query against the CurrentPositionComponent concept. The most current components of a position for the given account / instrument. The position components represent the sub-quantities of each position by price & trade date. This resource is implicitly scoped by 'accountId' referencing BrokerageAccount. Reading this concept requires READ access unto the same resource-type as the Account that owns this CurrentPositionComponent (one of the following: BROKERAGE, SWAP). */
  AggregateCurrentPositionComponents: Array<AggregateCurrentPositionComponentDTO>;
  /** Perform an aggregate query against the CurrentPosition concept. The most current position for the given account / instrument. The current position values are aggregated by account / instrument, with no per-day or per-price data available. This resource is implicitly scoped by 'accountId' referencing Account. Reading this concept requires READ access unto the same resource-type as the Account that owns this CurrentPosition (one of the following: BROKERAGE, SWAP). */
  AggregateCurrentPositions: Array<AggregateCurrentPositionDTO>;
  /**
   * Perform an aggregate query against the DerivedDrpEndorsement concept.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated All fields on this model are now available on DrpInsuranceEndorsement
   */
  AggregateDerivedDrpEndorsements: Array<AggregateDerivedDrpEndorsementDTO>;
  /**
   * Perform an aggregate query against the DrpEndorsement concept.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated Use "DrpInsuranceEndorsement" instead
   */
  AggregateDrpEndorsements: Array<AggregateDrpEndorsementDTO>;
  /** Perform an aggregate query against the DrpInsuranceEndorsement concept.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  AggregateDrpInsuranceEndorsements: Array<AggregateDrpInsuranceEndorsementDTO>;
  /** Perform an aggregate query against the EntityAllocatedExposureRatio concept.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateEntityAllocatedExposureRatios: Array<AggregateEntityAllocatedExposureRatioDTO>;
  /** Perform an aggregate query against the EntityAllocatedExposureRatioByOwner concept.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateEntityAllocatedExposureRatiosByOwner: Array<AggregateEntityAllocatedExposureRatioByOwnerDTO>;
  /** Perform an aggregate query against the EntityExposureRatio concept.  This resource is scoped directly by filtering on 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateEntityExposureRatios: Array<AggregateEntityExposureRatioDTO>;
  /** Perform an aggregate query against the FeedIngredientActualUsage concept.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateFeedIngredientActualUsages: Array<AggregateFeedIngredientActualUsageDTO>;
  /** Perform an aggregate query against the FeedIngredientConsumedAndPurchasedVolume concept. This concept aggregates all forecasted dairy / livestock feed usage by ingredient + month and combines it with purchased feed data. A row will be returned for each month between the given start/end dates regardless of existing purchased or consumed feed that matches the given filters. This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateFeedIngredientConsumedAndPurchasedVolumes: Array<AggregateFeedIngredientConsumedAndPurchasedVolumeDTO>;
  /** Perform an aggregate query against the FeedIngredientForecastedUsage concept.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateFeedIngredientForecastedUsages: Array<AggregateFeedIngredientForecastedUsageDTO>;
  /** Perform an aggregate query against the Field concept.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  AggregateFields: Array<AggregateFieldDTO>;
  /** Perform an aggregate query against the ForecastedHedgedAndCappedVolume concept.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  AggregateForecastedHedgedAndCappedVolumes: Array<AggregateForecastedHedgedAndCappedVolumeDTO>;
  /** Perform an aggregate query against the ForecastedMilkProductionRow concept.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateForecastedMilkProduction: Array<AggregateForecastedMilkProductionDTO>;
  /** Perform an aggregate query against the ForecastedMilkProductionByMonthRolledUpRow concept. The ForecastedMilkProductionByMonth value for this entity as well as all of its children, aggregated per month. This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateForecastedMilkProductionByMonthRolledUp: Array<AggregateForecastedMilkProductionByMonthRolledUpDTO>;
  /** Perform an aggregate query against the ForecastedMilkProductionByMonth concept. The forecasted milk production for the given entity aggregated by month. This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateForecastedMilkProductionByMonths: Array<AggregateForecastedMilkProductionByMonthDTO>;
  /** Perform an aggregate query against the ForecastedMilkProductionCoverageRow concept.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  AggregateForecastedMilkProductionCoverage: Array<AggregateForecastedMilkProductionCoverageDTO>;
  /**
   * Perform an aggregate query against the ForecastedMilkUtilization concept.  This resource is implicitly scoped by 'locationId' referencing LocationEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource.
   * @deprecated This has been deprecated in favor of ForecastedMilkProductionByMonth
   */
  AggregateForecastedMilkUtilizations: Array<AggregateForecastedMilkUtilizationDTO>;
  /** Perform an aggregate query against the ForecastedProductionRow concept.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateForecastedProduction: Array<AggregateForecastedProductionDTO>;
  /** Perform an aggregate query against the ForecastedSwineLivestockFeedUsageRowAtFinish concept.  This resource is implicitly scoped by 'businessId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateForecastedSwineLivestockFeedUsageAtFinish: Array<AggregateForecastedSwineLivestockFeedUsageAtFinishDTO>;
  /** Perform an aggregate query against the ForecastedSwineLivestockFeedUsage concept.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateForecastedSwineLivestockFeedUsages: Array<AggregateForecastedSwineLivestockFeedUsageDTO>;
  /** Perform an aggregate query against the GrainFillOrder concept.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  AggregateGrainFillOrders: Array<AggregateGrainFillOrderDTO>;
  /** Perform an aggregate query against the GrainTargetOrder concept.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  AggregateGrainTargetOrders: Array<AggregateGrainTargetOrderDTO>;
  /** Perform an aggregate query against the HedgeStrategyCurrentPosition concept. The current positions, aggregated by hedge strategy & the given scopeId. This concept cannot be queried without a user & scopeId. The returned positions are dynamically computed on-demand, and will transcend accounts / businesses, if the given scope permits it. This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  AggregateHedgeStrategyCurrentPositions: Array<AggregateHedgeStrategyCurrentPositionDTO>;
  /** Perform an aggregate query against the HedgeStrategyHistoricalPnl concept.  This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  AggregateHedgeStrategyHistoricalPnls: Array<AggregateHedgeStrategyHistoricalPnlDTO>;
  /** Perform an aggregate query against the HistoricalPnlRow concept.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  AggregateHistoricalPnl: Array<AggregateHistoricalPnlDTO>;
  /** Perform an aggregate query against the InsuranceHistoricalDrpPremium concept.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  AggregateInsuranceHistoricalDrpPremiums: Array<AggregateInsuranceHistoricalDrpPremiumDTO>;
  /** Perform an aggregate query against the InventoryLevel concept.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateInventoryLevels: Array<AggregateInventoryLevelDTO>;
  /** Perform an aggregate query against the LedgerActualEntry concept.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateLedgerActualEntries: Array<AggregateLedgerActualEntryDTO>;
  /** Perform an aggregate query against the LedgerEntry concept.  This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateLedgerEntries: Array<AggregateLedgerEntryDTO>;
  /** Perform an aggregate query against the LedgerForecastedEntry concept.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateLedgerForecastedEntries: Array<AggregateLedgerForecastedEntryDTO>;
  /** Perform an aggregate query against the LivestockGroupContractPeriodAllocation concept. The allocations for a given LG contract for each of the periods between the contract start & end date as appropriate for the contract's periodAllocationType. This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateLivestockGroupContractPeriodAllocations: Array<AggregateLivestockGroupContractPeriodAllocationDTO>;
  /** Perform an aggregate query against the LrpInsuranceEndorsement concept.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  AggregateLrpInsuranceEndorsements: Array<AggregateLrpInsuranceEndorsementDTO>;
  /** Perform an aggregate query against the PhysicalFeedTransaction concept.  This resource is implicitly scoped by 'businessId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregatePhysicalFeedTransactions: Array<AggregatePhysicalFeedTransactionDTO>;
  /** Perform an aggregate query against the PositionComponentAllocation concept.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  AggregatePositionComponentAllocations: Array<AggregatePositionComponentAllocationDTO>;
  /** Perform an aggregate query against the PositionComponent concept. The components of a position for the given account / instrument for each date. The position components represent the sub-quantities of each position by price & trade date. This resource is implicitly scoped by 'accountId' referencing BrokerageAccount. Reading this concept requires READ access unto the same resource-type as the Account that owns this PositionComponent (one of the following: BROKERAGE, SWAP). */
  AggregatePositionComponents: Array<AggregatePositionComponentDTO>;
  /** Perform an aggregate query against the RmaBusinessSummary concept.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  AggregateRmaBusinessSummaries: Array<AggregateRmaSummaryOfBusinessDTO>;
  /** Perform an aggregate query against the SwineLivestockPopulationChange concept.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AggregateSwineLivestockPopulationChanges: Array<AggregateSwineLivestockPopulationChangeDTO>;
  /** Perform an aggregate query against the TransactionFee concept.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this TransactionFee (one of the following: BROKERAGE, SWAP). */
  AggregateTransactionFees: Array<AggregateTransactionFeeDTO>;
  /** Perform an aggregate query against the Transaction concept.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this Transaction (one of the following: BROKERAGE, SWAP). */
  AggregateTransactions: Array<AggregateTransactionDTO>;
  /** Perform an aggregate query against the UsdaMonthlyNationalAverageDairyPrice concept.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  AggregateUsdaMonthlyNationalAverageDairyPrices: Array<AggregateUsdaMonthlyNationalAverageDairyPriceDTO>;
  /** Perform an aggregate query against the UsdaMonthlyRegionalMilkComponentPrice concept.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  AggregateUsdaMonthlyRegionalMilkComponentPrices: Array<AggregateUsdaMonthlyRegionalMilkComponentPriceDTO>;
  /** Perform an aggregate query against the UsdaMonthlyRegionalMilkUtilization concept.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  AggregateUsdaMonthlyRegionalMilkUtilizations: Array<AggregateUsdaMonthlyRegionalMilkUtilizationDTO>;
  /** Perform an aggregate query against the UsdaRetailSummary concept.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  AggregateUsdaRetailSummaries: Array<AggregateUsdaRetailSummaryDTO>;
  /** Find one AllocatedForecastedHedgedAndCappedVolume by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AllocatedForecastedHedgedAndCappedVolume?: Maybe<AllocatedForecastedHedgedAndCappedVolume>;
  /** Return the number of AllocatedForecastedHedgedAndCappedVolume rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AllocatedForecastedHedgedAndCappedVolumeCount: Count;
  /** Find many AllocatedForecastedHedgedAndCappedVolume rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  AllocatedForecastedHedgedAndCappedVolumes: Array<AllocatedForecastedHedgedAndCappedVolume>;
  /** Find one ApplePushNotificationSetting by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  ApplePushNotificationSetting?: Maybe<ApplePushNotificationSetting>;
  /** Return the number of ApplePushNotificationSetting rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  ApplePushNotificationSettingCount: Count;
  /** Find many ApplePushNotificationSetting rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  ApplePushNotificationSettings: Array<ApplePushNotificationSetting>;
  /** Find one BasisInstrument by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  BasisInstrument?: Maybe<BasisInstrument>;
  /** Return the number of BasisInstrument rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  BasisInstrumentCount: Count;
  /** Find many BasisInstrument rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  BasisInstruments: Array<BasisInstrument>;
  /** Find one BrokerageAccount by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  BrokerageAccount?: Maybe<BrokerageAccount>;
  /** Return the number of BrokerageAccount rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  BrokerageAccountCount: Count;
  /** Find many BrokerageAccount rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  BrokerageAccounts: Array<BrokerageAccount>;
  /** Find one BusinessContact by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  BusinessContact?: Maybe<BusinessContact>;
  /** Return the number of BusinessContact rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  BusinessContactCount: Count;
  /** Find many BusinessContact rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  BusinessContacts: Array<BusinessContact>;
  /** Find one BusinessDataVersion by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  BusinessDataVersion?: Maybe<BusinessDataVersion>;
  /** Return the number of BusinessDataVersion rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  BusinessDataVersionCount: Count;
  /** Find many BusinessDataVersion rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  BusinessDataVersions: Array<BusinessDataVersion>;
  /** Returns an array of all business roles that exist for the given scope id. If no scope id is provided, an empty array is returned. */
  BusinessRolesInScope: Array<BusinessEntityRole>;
  /** Find one Buyer by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Buyer?: Maybe<Buyer>;
  /** Return the number of Buyer rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  BuyerCount: Count;
  /** Find many Buyer rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Buyers: Array<Buyer>;
  /** Find one CashInstrument by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  CashInstrument?: Maybe<CashInstrument>;
  /** Return the number of CashInstrument rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  CashInstrumentCount: Count;
  /** Find many CashInstrument rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  CashInstruments: Array<CashInstrument>;
  /** Find one CommitmentInstrument by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  CommitmentInstrument?: Maybe<CommitmentInstrument>;
  /** Return the number of CommitmentInstrument rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  CommitmentInstrumentCount: Count;
  /** Find many CommitmentInstrument rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  CommitmentInstruments: Array<CommitmentInstrument>;
  /** Find many County rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Counties: Array<County>;
  /** Find one County by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  County?: Maybe<County>;
  /** Return the number of County rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  CountyCount: Count;
  /** Find one Crop by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  Crop?: Maybe<Crop>;
  /** Find many CropCategory rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  CropCategories: Array<CropCategory>;
  /** Find one CropCategory by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  CropCategory?: Maybe<CropCategory>;
  /** Return the number of CropCategory rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  CropCategoryCount: Count;
  /** Return the number of Crop rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  CropCount: Count;
  /** Find one CropHarvest by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  CropHarvest?: Maybe<CropHarvest>;
  /** Return the number of CropHarvest rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  CropHarvestCount: Count;
  /** Find many CropHarvest rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  CropHarvests: Array<CropHarvest>;
  /** Find many Crop rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  Crops: Array<Crop>;
  /** Find one CurrentAllocationPosition by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ Persisted access at the scope of this resource. */
  CurrentAllocationPosition?: Maybe<CurrentAllocationPosition>;
  /** Return the number of CurrentAllocationPosition rows that match the given filter.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ Persisted access at the scope of this resource. */
  CurrentAllocationPositionCount: Count;
  /** Find many CurrentAllocationPosition rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ Persisted access at the scope of this resource. */
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  /** Find many DoNotUse__CurrentInventoryLevel rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  CurrentInventoryLevels: Array<InventoryLevel>;
  /** Find one CurrentPosition by id. null is returned if no value with the given id is available to the current user. The most current position for the given account / instrument. The current position values are aggregated by account / instrument, with no per-day or per-price data available. This resource is implicitly scoped by 'accountId' referencing Account. Reading this concept requires READ access unto the same resource-type as the Account that owns this CurrentPosition (one of the following: BROKERAGE, SWAP). */
  CurrentPosition?: Maybe<CurrentPosition>;
  /** Find one CurrentPositionComponent by id. null is returned if no value with the given id is available to the current user. The most current components of a position for the given account / instrument. The position components represent the sub-quantities of each position by price & trade date. This resource is implicitly scoped by 'accountId' referencing BrokerageAccount. Reading this concept requires READ access unto the same resource-type as the Account that owns this CurrentPositionComponent (one of the following: BROKERAGE, SWAP). */
  CurrentPositionComponent?: Maybe<CurrentPositionComponent>;
  /** Return the number of CurrentPositionComponent rows that match the given filter. The most current components of a position for the given account / instrument. The position components represent the sub-quantities of each position by price & trade date. This resource is implicitly scoped by 'accountId' referencing BrokerageAccount. Reading this concept requires READ access unto the same resource-type as the Account that owns this CurrentPositionComponent (one of the following: BROKERAGE, SWAP). */
  CurrentPositionComponentCount: Count;
  /** Find many CurrentPositionComponent rows that match the given filter. If no limit is provided, a default limit of 500 will be used. The most current components of a position for the given account / instrument. The position components represent the sub-quantities of each position by price & trade date. This resource is implicitly scoped by 'accountId' referencing BrokerageAccount. Reading this concept requires READ access unto the same resource-type as the Account that owns this CurrentPositionComponent (one of the following: BROKERAGE, SWAP). */
  CurrentPositionComponents: Array<CurrentPositionComponent>;
  /** Return the number of CurrentPosition rows that match the given filter. The most current position for the given account / instrument. The current position values are aggregated by account / instrument, with no per-day or per-price data available. This resource is implicitly scoped by 'accountId' referencing Account. Reading this concept requires READ access unto the same resource-type as the Account that owns this CurrentPosition (one of the following: BROKERAGE, SWAP). */
  CurrentPositionCount: Count;
  /** Find many CurrentPosition rows that match the given filter. If no limit is provided, a default limit of 500 will be used. The most current position for the given account / instrument. The current position values are aggregated by account / instrument, with no per-day or per-price data available. This resource is implicitly scoped by 'accountId' referencing Account. Reading this concept requires READ access unto the same resource-type as the Account that owns this CurrentPosition (one of the following: BROKERAGE, SWAP). */
  CurrentPositions: Array<CurrentPosition>;
  /** Find one Customer by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  Customer?: Maybe<CustomerEntity>;
  /** Return the number of Customer rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  CustomerCount: Count;
  /** Find many Customer rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  Customers: Array<CustomerEntity>;
  /**
   * Find one DerivedDrpEndorsement by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated All fields on this model are now available on DrpInsuranceEndorsement
   */
  DerivedDrpEndorsement?: Maybe<DerivedDrpEndorsement>;
  /**
   * Return the number of DerivedDrpEndorsement rows that match the given filter.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated All fields on this model are now available on DrpInsuranceEndorsement
   */
  DerivedDrpEndorsementCount: Count;
  /**
   * Find many DerivedDrpEndorsement rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated All fields on this model are now available on DrpInsuranceEndorsement
   */
  DerivedDrpEndorsements: Array<DerivedDrpEndorsement>;
  /** Find one DoNotUse__CurrentInventoryLevel by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  DoNotUse__CurrentInventoryLevel?: Maybe<InventoryLevel>;
  /** Return the number of DoNotUse__CurrentInventoryLevel rows that match the given filter.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  DoNotUse__CurrentInventoryLevelCount: Count;
  /**
   * Find one DrpEndorsement by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated Use "DrpInsuranceEndorsement" instead
   */
  DrpEndorsement?: Maybe<DrpInsuranceEndorsement>;
  /**
   * Return the number of DrpEndorsement rows that match the given filter.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated Use "DrpInsuranceEndorsement" instead
   */
  DrpEndorsementCount: Count;
  /**
   * Find many DrpEndorsement rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated Use "DrpInsuranceEndorsement" instead
   */
  DrpEndorsements: Array<DrpInsuranceEndorsement>;
  /** Find one DrpInsuranceEndorsement by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  DrpInsuranceEndorsement?: Maybe<DrpInsuranceEndorsement>;
  /** Return the number of DrpInsuranceEndorsement rows that match the given filter.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  DrpInsuranceEndorsementCount: Count;
  /** Find many DrpInsuranceEndorsement rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  DrpInsuranceEndorsements: Array<DrpInsuranceEndorsement>;
  /** Find one DrpNewDailyPricesSubscription by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  DrpNewDailyPricesSubscription?: Maybe<DrpNewDailyPricesSubscription>;
  /** Return the number of DrpNewDailyPricesSubscription rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  DrpNewDailyPricesSubscriptionCount: Count;
  /** Find many DrpNewDailyPricesSubscription rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  DrpNewDailyPricesSubscriptions: Array<DrpNewDailyPricesSubscription>;
  /** Find one DrpSubscription by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  DrpSubscription?: Maybe<DrpSubscription>;
  /** Return the number of DrpSubscription rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  DrpSubscriptionCount: Count;
  /** Find many DrpSubscription rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  DrpSubscriptions: Array<DrpSubscription>;
  /** Find one EmailNotificationSetting by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  EmailNotificationSetting?: Maybe<EmailNotificationSetting>;
  /** Return the number of EmailNotificationSetting rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  EmailNotificationSettingCount: Count;
  /** Find many EmailNotificationSetting rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  EmailNotificationSettings: Array<EmailNotificationSetting>;
  /** Find many Entity rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ access unto the same resource type the Entity subtype being returned (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS) */
  Entities: Array<IEntity>;
  /** Find one Entity by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ access unto the same resource type the Entity subtype being returned (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS) */
  Entity?: Maybe<IEntity>;
  /** Find one EntityAllocatedExposureRatio by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  EntityAllocatedExposureRatio?: Maybe<EntityAllocatedExposureRatio>;
  /** Find one EntityAllocatedExposureRatioByOwner by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  EntityAllocatedExposureRatioByOwner?: Maybe<EntityAllocatedExposureRatioByOwner>;
  /** Return the number of EntityAllocatedExposureRatioByOwner rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  EntityAllocatedExposureRatioByOwnerCount: Count;
  /** Return the number of EntityAllocatedExposureRatio rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  EntityAllocatedExposureRatioCount: Count;
  /** Find many EntityAllocatedExposureRatio rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  EntityAllocatedExposureRatios: Array<EntityAllocatedExposureRatio>;
  /** Find many EntityAllocatedExposureRatioByOwner rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  EntityAllocatedExposureRatiosByOwner: Array<EntityAllocatedExposureRatioByOwner>;
  /** Return the number of Entity rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ access unto the same resource type the Entity subtype being returned (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS) */
  EntityCount: Count;
  /** Find one EntityExposureRatio by id. null is returned if no value with the given id is available to the current user.  This resource is scoped directly by filtering on 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  EntityExposureRatio?: Maybe<EntityExposureRatio>;
  /** Return the number of EntityExposureRatio rows that match the given filter.  This resource is scoped directly by filtering on 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  EntityExposureRatioCount: Count;
  /** Find many EntityExposureRatio rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is scoped directly by filtering on 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  EntityExposureRatios: Array<EntityExposureRatio>;
  /** Find one EntityGroup by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ ENTITY_GROUP access at the scope of this resource. */
  EntityGroup?: Maybe<EntityGroup>;
  /** Return the number of EntityGroup rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ ENTITY_GROUP access at the scope of this resource. */
  EntityGroupCount: Count;
  /** Find many EntityGroupEntry rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityGroupId' referencing EntityGroup. Reading this concept requires READ ENTITY_GROUP access at the scope of this resource. */
  EntityGroupEntries: Array<EntityGroupEntry>;
  /** Find one EntityGroupEntry by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityGroupId' referencing EntityGroup. Reading this concept requires READ ENTITY_GROUP access at the scope of this resource. */
  EntityGroupEntry?: Maybe<EntityGroupEntry>;
  /** Return the number of EntityGroupEntry rows that match the given filter.  This resource is implicitly scoped by 'entityGroupId' referencing EntityGroup. Reading this concept requires READ ENTITY_GROUP access at the scope of this resource. */
  EntityGroupEntryCount: Count;
  /** Find many EntityGroup rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ ENTITY_GROUP access at the scope of this resource. */
  EntityGroups: Array<EntityGroup>;
  /** Find one EntityMember by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ access unto the same resource-type as the Entity that owns this EntityMember (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS). */
  EntityMember?: Maybe<EntityMember>;
  /** Return the number of EntityMember rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ access unto the same resource-type as the Entity that owns this EntityMember (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS). */
  EntityMemberCount: Count;
  /** Find many EntityMember rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ access unto the same resource-type as the Entity that owns this EntityMember (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS). */
  EntityMembers: Array<EntityMember>;
  /** Find one EntityNote by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ access unto the same resource-type as the Entity that owns this EntityNote (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS). */
  EntityNote?: Maybe<EntityNote>;
  /** Return the number of EntityNote rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ access unto the same resource-type as the Entity that owns this EntityNote (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS). */
  EntityNoteCount: Count;
  /** Find many EntityNote rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ access unto the same resource-type as the Entity that owns this EntityNote (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS). */
  EntityNotes: Array<EntityNote>;
  /** Find one EntityUser by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ access unto the same resource-type as the Entity that owns this EntityUser (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS). */
  EntityUser?: Maybe<EntityUser>;
  /** Return the number of EntityUser rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ access unto the same resource-type as the Entity that owns this EntityUser (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS). */
  EntityUserCount: Count;
  /** Find many EntityUser rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ access unto the same resource-type as the Entity that owns this EntityUser (one of the following: ORGANIZATION, ENTITY_GROUP, BUSINESS, OPERATIONS). */
  EntityUsers: Array<EntityUser>;
  /** Find one FCM by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  FCM?: Maybe<Fcm>;
  /** Return the number of FCM rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  FCMCount: Count;
  /** Find many FCM rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  FCMs: Array<Fcm>;
  /** Find many FeedCategory rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  FeedCategories: Array<FeedCategory>;
  /** Find one FeedCategory by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  FeedCategory?: Maybe<FeedCategory>;
  /** Return the number of FeedCategory rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  FeedCategoryCount: Count;
  /** Find one FeedIngredient by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredient?: Maybe<FeedIngredient>;
  /** Find one FeedIngredientActualUsage by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientActualUsage?: Maybe<FeedIngredientActualUsage>;
  /** Return the number of FeedIngredientActualUsage rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientActualUsageCount: Count;
  /** Find many FeedIngredientActualUsage rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientActualUsages: Array<FeedIngredientActualUsage>;
  /** Find one FeedIngredientConsumedAndPurchasedVolume by id. null is returned if no value with the given id is available to the current user. This concept aggregates all forecasted dairy / livestock feed usage by ingredient + month and combines it with purchased feed data. A row will be returned for each month between the given start/end dates regardless of existing purchased or consumed feed that matches the given filters. This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientConsumedAndPurchasedVolume?: Maybe<FeedIngredientConsumedAndPurchasedVolume>;
  /** Return the number of FeedIngredientConsumedAndPurchasedVolume rows that match the given filter. This concept aggregates all forecasted dairy / livestock feed usage by ingredient + month and combines it with purchased feed data. A row will be returned for each month between the given start/end dates regardless of existing purchased or consumed feed that matches the given filters. This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientConsumedAndPurchasedVolumeCount: Count;
  /** Find many FeedIngredientConsumedAndPurchasedVolume rows that match the given filter. If no limit is provided, a default limit of 500 will be used. This concept aggregates all forecasted dairy / livestock feed usage by ingredient + month and combines it with purchased feed data. A row will be returned for each month between the given start/end dates regardless of existing purchased or consumed feed that matches the given filters. This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientConsumedAndPurchasedVolumes: Array<FeedIngredientConsumedAndPurchasedVolume>;
  /** Return the number of FeedIngredient rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientCount: Count;
  /** Find one FeedIngredientForecastedUsage by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientForecastedUsage?: Maybe<FeedIngredientForecastedUsage>;
  /** Return the number of FeedIngredientForecastedUsage rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientForecastedUsageCount: Count;
  /** Find many FeedIngredientForecastedUsage rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientForecastedUsages: Array<FeedIngredientForecastedUsage>;
  /** Find one FeedIngredientUsage by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientUsage?: Maybe<IFeedIngredientUsage>;
  /** Return the number of FeedIngredientUsage rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientUsageCount: Count;
  /** Find many FeedIngredientUsage rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredientUsages: Array<IFeedIngredientUsage>;
  /** Find many FeedIngredient rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  FeedIngredients: Array<FeedIngredient>;
  /** Find one FeedTransaction by id. null is returned if no value with the given id is available to the current user. A combination of both PhysicalFeedTransaction's and Feed-based GrainFillOrders. This resource is implicitly scoped by 'businessId'. This concept is not permissioned. */
  FeedTransaction?: Maybe<FeedTransaction>;
  /** Return the number of FeedTransaction rows that match the given filter. A combination of both PhysicalFeedTransaction's and Feed-based GrainFillOrders. This resource is implicitly scoped by 'businessId'. This concept is not permissioned. */
  FeedTransactionCount: Count;
  /** Find many FeedTransaction rows that match the given filter. If no limit is provided, a default limit of 500 will be used. A combination of both PhysicalFeedTransaction's and Feed-based GrainFillOrders. This resource is implicitly scoped by 'businessId'. This concept is not permissioned. */
  FeedTransactions: Array<FeedTransaction>;
  /** Find one Field by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  Field?: Maybe<Field>;
  /** Return the number of Field rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  FieldCount: Count;
  /** Find many Field rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  Fields: Array<Field>;
  /** Find one ForecastedHedgedAndCappedVolume by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  ForecastedHedgedAndCappedVolume?: Maybe<ForecastedHedgedAndCappedVolume>;
  /** Return the number of ForecastedHedgedAndCappedVolume rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  ForecastedHedgedAndCappedVolumeCount: Count;
  /** Find many ForecastedHedgedAndCappedVolume rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  ForecastedHedgedAndCappedVolumes: Array<ForecastedHedgedAndCappedVolume>;
  /** Find one ForecastedLivestockFeedUsage by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedLivestockFeedUsage?: Maybe<IForecastedLivestockFeedUsage>;
  /** Return the number of ForecastedLivestockFeedUsage rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedLivestockFeedUsageCount: Count;
  /** Find many ForecastedLivestockFeedUsage rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedLivestockFeedUsages: Array<IForecastedLivestockFeedUsage>;
  /** Find many ForecastedMilkProductionRow rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedMilkProduction: Array<ForecastedMilkProduction>;
  /** Find one ForecastedMilkProductionByMonth by id. null is returned if no value with the given id is available to the current user. The forecasted milk production for the given entity aggregated by month. This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedMilkProductionByMonth?: Maybe<ForecastedMilkProductionByMonth>;
  /** Return the number of ForecastedMilkProductionByMonth rows that match the given filter. The forecasted milk production for the given entity aggregated by month. This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedMilkProductionByMonthCount: Count;
  /** Find many ForecastedMilkProductionByMonthRolledUpRow rows that match the given filter. If no limit is provided, a default limit of 500 will be used. The ForecastedMilkProductionByMonth value for this entity as well as all of its children, aggregated per month. This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedMilkProductionByMonthRolledUp: Array<ForecastedMilkProductionByMonthRolledUp>;
  /** Return the number of ForecastedMilkProductionByMonthRolledUpRow rows that match the given filter. The ForecastedMilkProductionByMonth value for this entity as well as all of its children, aggregated per month. This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedMilkProductionByMonthRolledUpRowCount: Count;
  /** Find many ForecastedMilkProductionByMonth rows that match the given filter. If no limit is provided, a default limit of 500 will be used. The forecasted milk production for the given entity aggregated by month. This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedMilkProductionByMonths: Array<ForecastedMilkProductionByMonth>;
  /** Find many ForecastedMilkProductionCoverageRow rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  ForecastedMilkProductionCoverage: Array<ForecastedMilkProductionCoverage>;
  /** Find one ForecastedMilkProductionCoverageRow by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  ForecastedMilkProductionCoverageRow?: Maybe<ForecastedMilkProductionCoverage>;
  /** Return the number of ForecastedMilkProductionCoverageRow rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  ForecastedMilkProductionCoverageRowCount: Count;
  /** Find one ForecastedMilkProductionRow by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedMilkProductionRow?: Maybe<ForecastedMilkProduction>;
  /** Return the number of ForecastedMilkProductionRow rows that match the given filter.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedMilkProductionRowCount: Count;
  /**
   * Find one ForecastedMilkUtilization by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'locationId' referencing LocationEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource.
   * @deprecated This has been deprecated in favor of ForecastedMilkProductionByMonth
   */
  ForecastedMilkUtilization?: Maybe<ForecastedMilkUtilization>;
  /**
   * Return the number of ForecastedMilkUtilization rows that match the given filter.  This resource is implicitly scoped by 'locationId' referencing LocationEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource.
   * @deprecated This has been deprecated in favor of ForecastedMilkProductionByMonth
   */
  ForecastedMilkUtilizationCount: Count;
  /**
   * Find many ForecastedMilkUtilization rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'locationId' referencing LocationEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource.
   * @deprecated This has been deprecated in favor of ForecastedMilkProductionByMonth
   */
  ForecastedMilkUtilizations: Array<ForecastedMilkUtilization>;
  /** Find many ForecastedProductionRow rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedProduction: Array<ForecastedProduction>;
  /** Find one ForecastedProductionRow by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedProductionRow?: Maybe<ForecastedProduction>;
  /** Return the number of ForecastedProductionRow rows that match the given filter.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedProductionRowCount: Count;
  /** Find one ForecastedSwineLivestockFeedUsage by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedSwineLivestockFeedUsage?: Maybe<ForecastedSwineLivestockFeedUsage>;
  /** Find many ForecastedSwineLivestockFeedUsageRowAtFinish rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedSwineLivestockFeedUsageAtFinish: Array<ForecastedSwineLivestockFeedUsageAtFinish>;
  /** Return the number of ForecastedSwineLivestockFeedUsage rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedSwineLivestockFeedUsageCount: Count;
  /** Find one ForecastedSwineLivestockFeedUsageRowAtFinish by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedSwineLivestockFeedUsageRowAtFinish?: Maybe<ForecastedSwineLivestockFeedUsageAtFinish>;
  /** Return the number of ForecastedSwineLivestockFeedUsageRowAtFinish rows that match the given filter.  This resource is implicitly scoped by 'businessId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedSwineLivestockFeedUsageRowAtFinishCount: Count;
  /** Find many ForecastedSwineLivestockFeedUsage rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  ForecastedSwineLivestockFeedUsages: Array<ForecastedSwineLivestockFeedUsage>;
  /** Find one ForwardContract by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. This concept is currently disabled and will never return data. */
  ForwardContract?: Maybe<ForwardContractAccount>;
  /** Return the number of ForwardContract rows that match the given filter.  This resource is explicitly scoped via id. This concept is currently disabled and will never return data. */
  ForwardContractCount: Count;
  /** Find many ForwardContract rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. This concept is currently disabled and will never return data. */
  ForwardContracts: Array<ForwardContractAccount>;
  /** Find one Future by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Future?: Maybe<Future>;
  /** Return the number of Future rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  FutureCount: Count;
  /** Find many Future rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Futures: Array<Future>;
  /** Find one GrainAdvisor by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainAdvisor?: Maybe<GrainAdvisor>;
  /** Return the number of GrainAdvisor rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainAdvisorCount: Count;
  /** Find many GrainAdvisor rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainAdvisors: Array<GrainAdvisor>;
  /** Find one GrainBuyer by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainBuyer?: Maybe<GrainBuyer>;
  /** Return the number of GrainBuyer rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainBuyerCount: Count;
  /** Find one GrainBuyerLocation by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainBuyerLocation?: Maybe<GrainBuyerLocation>;
  /** Return the number of GrainBuyerLocation rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainBuyerLocationCount: Count;
  /** Find many GrainBuyerLocation rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainBuyerLocations: Array<GrainBuyerLocation>;
  /** Find many GrainBuyer rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainBuyers: Array<GrainBuyer>;
  /** Find one GrainCropPlan by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainCropPlan?: Maybe<GrainCropPlan>;
  /** Return the number of GrainCropPlan rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainCropPlanCount: Count;
  /** Find many GrainCropPlan rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainCropPlans: Array<GrainCropPlan>;
  /** Find one GrainCropYear by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainCropYear?: Maybe<GrainCropYear>;
  /** Return the number of GrainCropYear rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainCropYearCount: Count;
  /** Find many GrainCropYear rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainCropYears: Array<GrainCropYear>;
  /** Find one GrainFeedPlan by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFeedPlan?: Maybe<GrainFeedPlan>;
  /** Return the number of GrainFeedPlan rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFeedPlanCount: Count;
  /** Find many GrainFeedPlan rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFeedPlans: Array<GrainFeedPlan>;
  /** Find one GrainFillOrder by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrder?: Maybe<GrainFillOrder>;
  /** Find many GrainFillOrderActivity rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrderActivities: Array<GrainFillOrderActivity>;
  /** Find one GrainFillOrderActivity by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrderActivity?: Maybe<GrainFillOrderActivity>;
  /** Return the number of GrainFillOrderActivity rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrderActivityCount: Count;
  /** Return the number of GrainFillOrder rows that match the given filter.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrderCount: Count;
  /** Find one GrainFillOrderModificationRequest by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrderModificationRequest?: Maybe<GrainFillOrderModificationRequest>;
  /** Find many GrainFillOrderModificationRequestActivity rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrderModificationRequestActivities: Array<GrainFillOrderModificationRequestActivity>;
  /** Find one GrainFillOrderModificationRequestActivity by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrderModificationRequestActivity?: Maybe<GrainFillOrderModificationRequestActivity>;
  /** Return the number of GrainFillOrderModificationRequestActivity rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrderModificationRequestActivityCount: Count;
  /** Return the number of GrainFillOrderModificationRequest rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrderModificationRequestCount: Count;
  /** Find many GrainFillOrderModificationRequest rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrderModificationRequests: Array<GrainFillOrderModificationRequest>;
  /** Find many GrainFillOrder rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainFillOrders: Array<GrainFillOrder>;
  /** Find one GrainLocation by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainLocation?: Maybe<GrainLocation>;
  /** Return the number of GrainLocation rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainLocationCount: Count;
  /** Find many GrainLocation rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  GrainLocations: Array<GrainLocation>;
  /** Find one GrainOrder by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ access unto the same resource type the GrainOrder subtype being returned (one of the following: FEED_AND_CROP_PLAN) */
  GrainOrder?: Maybe<IGrainOrder>;
  /** Find many GrainOrderActivity rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainOrderActivities: Array<IGrainOrderActivity>;
  /** Find one GrainOrderActivity by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainOrderActivity?: Maybe<IGrainOrderActivity>;
  /** Return the number of GrainOrderActivity rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainOrderActivityCount: Count;
  /** Return the number of GrainOrder rows that match the given filter.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ access unto the same resource type the GrainOrder subtype being returned (one of the following: FEED_AND_CROP_PLAN) */
  GrainOrderCount: Count;
  /** Find one GrainOrderNote by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainOrderNote?: Maybe<GrainOrderNote>;
  /** Return the number of GrainOrderNote rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainOrderNoteCount: Count;
  /** Find many GrainOrderNote rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainOrderNotes: Array<GrainOrderNote>;
  /** Find many GrainOrder rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ access unto the same resource type the GrainOrder subtype being returned (one of the following: FEED_AND_CROP_PLAN) */
  GrainOrders: Array<IGrainOrder>;
  /** Find one GrainPlan by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ access unto the same resource type the GrainPlan subtype being returned (one of the following: FEED_AND_CROP_PLAN) */
  GrainPlan?: Maybe<IGrainPlan>;
  /** Return the number of GrainPlan rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ access unto the same resource type the GrainPlan subtype being returned (one of the following: FEED_AND_CROP_PLAN) */
  GrainPlanCount: Count;
  /** Find many GrainPlan rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ access unto the same resource type the GrainPlan subtype being returned (one of the following: FEED_AND_CROP_PLAN) */
  GrainPlans: Array<IGrainPlan>;
  /** Find one GrainTargetOrder by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainTargetOrder?: Maybe<GrainTargetOrder>;
  /** Find many GrainTargetOrderActivity rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainTargetOrderActivities: Array<GrainTargetOrderActivity>;
  /** Find one GrainTargetOrderActivity by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainTargetOrderActivity?: Maybe<GrainTargetOrderActivity>;
  /** Return the number of GrainTargetOrderActivity rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainTargetOrderActivityCount: Count;
  /** Return the number of GrainTargetOrder rows that match the given filter.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainTargetOrderCount: Count;
  /** Find many GrainTargetOrder rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'planId'. Reading this concept requires READ FEED_AND_CROP_PLAN access at the scope of this resource. */
  GrainTargetOrders: Array<GrainTargetOrder>;
  /** Find many HedgeStrategy rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  HedgeStrategies: Array<HedgeStrategy>;
  /** Find one HedgeStrategy by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  HedgeStrategy?: Maybe<HedgeStrategy>;
  /** Return the number of HedgeStrategy rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  HedgeStrategyCount: Count;
  /** Find one HedgeStrategyCurrentPosition by id. null is returned if no value with the given id is available to the current user. The current positions, aggregated by hedge strategy & the given scopeId. This concept cannot be queried without a user & scopeId. The returned positions are dynamically computed on-demand, and will transcend accounts / businesses, if the given scope permits it. This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  HedgeStrategyCurrentPosition?: Maybe<HedgeStrategyCurrentPosition>;
  /** Return the number of HedgeStrategyCurrentPosition rows that match the given filter. The current positions, aggregated by hedge strategy & the given scopeId. This concept cannot be queried without a user & scopeId. The returned positions are dynamically computed on-demand, and will transcend accounts / businesses, if the given scope permits it. This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  HedgeStrategyCurrentPositionCount: Count;
  /** Find many HedgeStrategyCurrentPosition rows that match the given filter. If no limit is provided, a default limit of 500 will be used. The current positions, aggregated by hedge strategy & the given scopeId. This concept cannot be queried without a user & scopeId. The returned positions are dynamically computed on-demand, and will transcend accounts / businesses, if the given scope permits it. This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  HedgeStrategyCurrentPositions: Array<HedgeStrategyCurrentPosition>;
  /** Find one HedgeStrategyHistoricalPnl by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  HedgeStrategyHistoricalPnl?: Maybe<HedgeStrategyHistoricalPnl>;
  /** Return the number of HedgeStrategyHistoricalPnl rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  HedgeStrategyHistoricalPnlCount: Count;
  /** Find many HedgeStrategyHistoricalPnl rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ Persisted access at the scope of this resource. */
  HedgeStrategyHistoricalPnls: Array<HedgeStrategyHistoricalPnl>;
  /** Find many HistoricalPnlRow rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  HistoricalPnl: Array<HistoricalPnl>;
  /** Find one HistoricalPnlRow by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  HistoricalPnlRow?: Maybe<HistoricalPnl>;
  /** Return the number of HistoricalPnlRow rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  HistoricalPnlRowCount: Count;
  /** Find one HypotheticalAllocationPnlAggregate by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  HypotheticalAllocationPnlAggregate?: Maybe<HypotheticalAllocationPnlAggregate>;
  /** Return the number of HypotheticalAllocationPnlAggregate rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  HypotheticalAllocationPnlAggregateCount: Count;
  /** Find many HypotheticalAllocationPnlAggregate rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  HypotheticalAllocationPnlAggregates: Array<HypotheticalAllocationPnlAggregate>;
  /** Find one HypotheticalHedgeStrategyPnlAggregate by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  HypotheticalHedgeStrategyPnlAggregate?: Maybe<HypotheticalHedgeStrategyPnlAggregate>;
  /** Return the number of HypotheticalHedgeStrategyPnlAggregate rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  HypotheticalHedgeStrategyPnlAggregateCount: Count;
  /** Find many HypotheticalHedgeStrategyPnlAggregate rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  HypotheticalHedgeStrategyPnlAggregates: Array<HypotheticalHedgeStrategyPnlAggregate>;
  /** Find one Instrument by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Instrument?: Maybe<IInstrument>;
  /** Return the number of Instrument rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InstrumentCount: Count;
  /** Find one InstrumentSymbolGroup by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InstrumentSymbolGroup?: Maybe<InstrumentSymbolGroup>;
  /** Return the number of InstrumentSymbolGroup rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InstrumentSymbolGroupCount: Count;
  /** Find many InstrumentSymbolGroup rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InstrumentSymbolGroups: Array<InstrumentSymbolGroup>;
  /** Find many Instrument rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Instruments: Array<IInstrument>;
  /** Find many InsuranceAgency rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceAgencies: Array<InsuranceAgency>;
  /** Find one InsuranceAgency by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceAgency?: Maybe<InsuranceAgency>;
  /** Return the number of InsuranceAgency rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceAgencyCount: Count;
  /** Find one InsuranceAgent by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceAgent?: Maybe<InsuranceAgent>;
  /** Return the number of InsuranceAgent rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceAgentCount: Count;
  /** Find many InsuranceAgent rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceAgents: Array<InsuranceAgent>;
  /** Find one InsuranceEndorsement by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsuranceEndorsement?: Maybe<IInsuranceEndorsement>;
  /** Find one InsuranceEndorsementAllocationRatio by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'insurancePolicyId'. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsuranceEndorsementAllocationRatio?: Maybe<InsuranceEndorsementAllocationRatio>;
  /** Return the number of InsuranceEndorsementAllocationRatio rows that match the given filter.  This resource is implicitly scoped by 'insurancePolicyId'. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsuranceEndorsementAllocationRatioCount: Count;
  /** Find many InsuranceEndorsementAllocationRatio rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'insurancePolicyId'. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsuranceEndorsementAllocationRatios: Array<InsuranceEndorsementAllocationRatio>;
  /** Return the number of InsuranceEndorsement rows that match the given filter.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsuranceEndorsementCount: Count;
  /** Find many InsuranceEndorsement rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsuranceEndorsements: Array<IInsuranceEndorsement>;
  /** Find one InsuranceFile by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'insurancePolicyId'. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsuranceFile?: Maybe<InsuranceFile>;
  /** Return the number of InsuranceFile rows that match the given filter.  This resource is implicitly scoped by 'insurancePolicyId'. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsuranceFileCount: Count;
  /** Find many InsuranceFile rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'insurancePolicyId'. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsuranceFiles: Array<InsuranceFile>;
  /** Find one InsuranceHistoricalDrpPremium by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalDrpPremium?: Maybe<InsuranceHistoricalDrpPremium>;
  /** Return the number of InsuranceHistoricalDrpPremium rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalDrpPremiumCount: Count;
  /** Find one InsuranceHistoricalDrpPremiumInput by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalDrpPremiumInput?: Maybe<InsuranceHistoricalDrpPremiumInput>;
  /** Return the number of InsuranceHistoricalDrpPremiumInput rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalDrpPremiumInputCount: Count;
  /** Find many InsuranceHistoricalDrpPremiumInput rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalDrpPremiumInputs: Array<InsuranceHistoricalDrpPremiumInput>;
  /** Find many InsuranceHistoricalDrpPremium rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalDrpPremiums: Array<InsuranceHistoricalDrpPremium>;
  /** Find one InsuranceHistoricalPremium by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalPremium?: Maybe<IInsuranceHistoricalPremium>;
  /** Return the number of InsuranceHistoricalPremium rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalPremiumCount: Count;
  /** Find one InsuranceHistoricalPremiumInput by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalPremiumInput?: Maybe<IInsuranceHistoricalPremiumInput>;
  /** Return the number of InsuranceHistoricalPremiumInput rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalPremiumInputCount: Count;
  /** Find many InsuranceHistoricalPremiumInput rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalPremiumInputs: Array<IInsuranceHistoricalPremiumInput>;
  /** Find many InsuranceHistoricalPremium rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuranceHistoricalPremiums: Array<IInsuranceHistoricalPremium>;
  /** Find many InsurancePolicy rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsurancePolicies: Array<InsurancePolicy>;
  /** Find one InsurancePolicy by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsurancePolicy?: Maybe<InsurancePolicy>;
  /** Return the number of InsurancePolicy rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  InsurancePolicyCount: Count;
  /** Find one InsurancePractice by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsurancePractice?: Maybe<InsurancePractice>;
  /** Return the number of InsurancePractice rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsurancePracticeCount: Count;
  /** Find many InsurancePractice rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsurancePractices: Array<InsurancePractice>;
  /** Find one InsuredYieldAndPrice by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuredYieldAndPrice?: Maybe<InsuredYieldAndPrice>;
  /** Return the number of InsuredYieldAndPrice rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuredYieldAndPriceCount: Count;
  /** Find many InsuredYieldAndPrice rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  InsuredYieldsAndPrices: Array<InsuredYieldAndPrice>;
  /** Find one IntegrationMapping by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId' referencing Account. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  IntegrationMapping?: Maybe<IntegrationMapping>;
  /** Return the number of IntegrationMapping rows that match the given filter.  This resource is implicitly scoped by 'accountId' referencing Account. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  IntegrationMappingCount: Count;
  /** Find many IntegrationMapping rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId' referencing Account. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  IntegrationMappings: Array<IntegrationMapping>;
  /** Find one InventoryLevel by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  InventoryLevel?: Maybe<InventoryLevel>;
  /** Return the number of InventoryLevel rows that match the given filter.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  InventoryLevelCount: Count;
  /** Find many InventoryLevel rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'locationId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  InventoryLevels: Array<InventoryLevel>;
  /** Find many LedgerActualEntry rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerActualEntries: Array<LedgerActualEntry>;
  /** Find one LedgerActualEntry by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerActualEntry?: Maybe<LedgerActualEntry>;
  /** Return the number of LedgerActualEntry rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerActualEntryCount: Count;
  /** Find many LedgerCategory rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerCategories: Array<ILedgerCategory>;
  /** Find one LedgerCategory by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerCategory?: Maybe<ILedgerCategory>;
  /** Return the number of LedgerCategory rows that match the given filter.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerCategoryCount: Count;
  /** Find many LedgerEntry rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerEntries: Array<ILedgerEntry>;
  /** Find one LedgerEntry by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerEntry?: Maybe<ILedgerEntry>;
  /** Return the number of LedgerEntry rows that match the given filter.  This resource is implicitly scoped by 'entityId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerEntryCount: Count;
  /** Find many LedgerExpenseCategory rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerExpenseCategories: Array<LedgerExpenseCategory>;
  /** Find one LedgerExpenseCategory by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerExpenseCategory?: Maybe<LedgerExpenseCategory>;
  /** Return the number of LedgerExpenseCategory rows that match the given filter.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerExpenseCategoryCount: Count;
  /** Find many LedgerFeedCategory rows that match the given filter. If no limit is provided, a default limit of 500 will be used. Ledger categories representing feed deductions and premiums. This resource is implicitly scoped by 'customerId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerFeedCategories: Array<LedgerFeedCategory>;
  /** Find one LedgerFeedCategory by id. null is returned if no value with the given id is available to the current user. Ledger categories representing feed deductions and premiums. This resource is implicitly scoped by 'customerId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerFeedCategory?: Maybe<LedgerFeedCategory>;
  /** Return the number of LedgerFeedCategory rows that match the given filter. Ledger categories representing feed deductions and premiums. This resource is implicitly scoped by 'customerId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerFeedCategoryCount: Count;
  /** Find many LedgerForecastedEntry rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerForecastedEntries: Array<LedgerForecastedEntry>;
  /** Find one LedgerForecastedEntry by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerForecastedEntry?: Maybe<LedgerForecastedEntry>;
  /** Return the number of LedgerForecastedEntry rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerForecastedEntryCount: Count;
  /** Find many LedgerMilkCheckCategory rows that match the given filter. If no limit is provided, a default limit of 500 will be used. Ledger categories representing milk check deductions and premiums. This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerMilkCheckCategories: Array<LedgerMilkCheckCategory>;
  /** Find one LedgerMilkCheckCategory by id. null is returned if no value with the given id is available to the current user. Ledger categories representing milk check deductions and premiums. This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerMilkCheckCategory?: Maybe<LedgerMilkCheckCategory>;
  /** Return the number of LedgerMilkCheckCategory rows that match the given filter. Ledger categories representing milk check deductions and premiums. This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerMilkCheckCategoryCount: Count;
  /** Find many LedgerRevenueCategory rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerRevenueCategories: Array<LedgerRevenueCategory>;
  /** Find one LedgerRevenueCategory by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerRevenueCategory?: Maybe<LedgerRevenueCategory>;
  /** Return the number of LedgerRevenueCategory rows that match the given filter.  This resource is implicitly scoped by 'customerId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LedgerRevenueCategoryCount: Count;
  /** Find one LgmGrossMargin by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LgmGrossMargin?: Maybe<LgmGrossMargin>;
  /** Return the number of LgmGrossMargin rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LgmGrossMarginCount: Count;
  /** Find many LgmGrossMargin rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LgmGrossMargins: Array<LgmGrossMargin>;
  /** Find one LgmInsuranceEndorsement by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  LgmInsuranceEndorsement?: Maybe<LgmInsuranceEndorsement>;
  /** Return the number of LgmInsuranceEndorsement rows that match the given filter.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  LgmInsuranceEndorsementCount: Count;
  /** Find many LgmInsuranceEndorsement rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  LgmInsuranceEndorsements: Array<LgmInsuranceEndorsement>;
  /** Find one LivestockGroup by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroup?: Maybe<ILivestockGroup>;
  /** Find one LivestockGroupContractPeriodAllocation by id. null is returned if no value with the given id is available to the current user. The allocations for a given LG contract for each of the periods between the contract start & end date as appropriate for the contract's periodAllocationType. This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupContractPeriodAllocation?: Maybe<LivestockGroupContractPeriodAllocation>;
  /** Return the number of LivestockGroupContractPeriodAllocation rows that match the given filter. The allocations for a given LG contract for each of the periods between the contract start & end date as appropriate for the contract's periodAllocationType. This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupContractPeriodAllocationCount: Count;
  /** Find many LivestockGroupContractPeriodAllocation rows that match the given filter. If no limit is provided, a default limit of 500 will be used. The allocations for a given LG contract for each of the periods between the contract start & end date as appropriate for the contract's periodAllocationType. This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupContractPeriodAllocations: Array<LivestockGroupContractPeriodAllocation>;
  /** Return the number of LivestockGroup rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupCount: Count;
  /** Find one LivestockGroupFeedUsage by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupFeedUsage?: Maybe<ILivestockGroupFeedUsage>;
  /** Return the number of LivestockGroupFeedUsage rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupFeedUsageCount: Count;
  /** Find one LivestockGroupFeedUsageVersion by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupFeedUsageVersion?: Maybe<LivestockGroupFeedUsageVersion>;
  /** Return the number of LivestockGroupFeedUsageVersion rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupFeedUsageVersionCount: Count;
  /** Find many LivestockGroupFeedUsageVersion rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupFeedUsageVersions: Array<LivestockGroupFeedUsageVersion>;
  /** Find many LivestockGroupFeedUsage rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupFeedUsages: Array<ILivestockGroupFeedUsage>;
  /** Find one LivestockGroupVersion by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupVersion?: Maybe<LivestockGroupVersion>;
  /** Return the number of LivestockGroupVersion rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupVersionCount: Count;
  /** Find many LivestockGroupVersion rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroupVersions: Array<LivestockGroupVersion>;
  /** Find many LivestockGroup rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockGroups: Array<ILivestockGroup>;
  /** Find one LivestockPopulationChange by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockPopulationChange?: Maybe<ILivestockPopulationChange>;
  /** Return the number of LivestockPopulationChange rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockPopulationChangeCount: Count;
  /** Find many LivestockPopulationChange rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LivestockPopulationChanges: Array<ILivestockPopulationChange>;
  /** Find one Location by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  Location?: Maybe<LocationEntity>;
  /** Return the number of Location rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  LocationCount: Count;
  /** Find many Location rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  Locations: Array<LocationEntity>;
  /** Find one LrpActualValue by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpActualValue?: Maybe<LrpActualValues>;
  /** Return the number of LrpActualValue rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpActualValueCount: Count;
  /** Find many LrpActualValue rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpActualValues: Array<LrpActualValues>;
  /** Find one LrpDailyPrice by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpDailyPrice?: Maybe<LrpDailyPrices>;
  /** Return the number of LrpDailyPrice rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpDailyPriceCount: Count;
  /** Find many LrpDailyPrice rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpDailyPrices: Array<LrpDailyPrices>;
  /**
   * Find one LrpEndorsement by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated Use "LrpInsuranceEndorsement" instead
   */
  LrpEndorsement?: Maybe<LrpInsuranceEndorsement>;
  /**
   * Return the number of LrpEndorsement rows that match the given filter.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated Use "LrpInsuranceEndorsement" instead
   */
  LrpEndorsementCount: Count;
  /**
   * Find many LrpEndorsement rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource.
   * @deprecated Use "LrpInsuranceEndorsement" instead
   */
  LrpEndorsements: Array<LrpInsuranceEndorsement>;
  /** Find one LrpInsuranceEndorsement by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  LrpInsuranceEndorsement?: Maybe<LrpInsuranceEndorsement>;
  /** Return the number of LrpInsuranceEndorsement rows that match the given filter.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  LrpInsuranceEndorsementCount: Count;
  /** Find many LrpInsuranceEndorsement rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'insurancePolicyId' referencing InsurancePolicy. Reading this concept requires READ INSURANCE access at the scope of this resource. */
  LrpInsuranceEndorsements: Array<LrpInsuranceEndorsement>;
  /** Find one LrpNewDailyPricesSubscription by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpNewDailyPricesSubscription?: Maybe<LrpNewDailyPricesSubscription>;
  /** Return the number of LrpNewDailyPricesSubscription rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpNewDailyPricesSubscriptionCount: Count;
  /** Find many LrpNewDailyPricesSubscription rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpNewDailyPricesSubscriptions: Array<LrpNewDailyPricesSubscription>;
  /** Find one LrpSubscription by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpSubscription?: Maybe<LrpSubscription>;
  /** Return the number of LrpSubscription rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpSubscriptionCount: Count;
  /** Find many LrpSubscription rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  LrpSubscriptions: Array<LrpSubscription>;
  /** Returns the Vault user for the current user. If the access token used to authorize the request is valid but does not have a corresponding Vault user, null will be returned. */
  Me?: Maybe<User>;
  /** Find one MilkOrder by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  MilkOrder?: Maybe<MilkOrder>;
  /** Return the number of MilkOrder rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  MilkOrderCount: Count;
  /** Find many MilkOrder rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  MilkOrders: Array<MilkOrder>;
  MostRecentLgmGrossMargins: Array<LgmGrossMargin>;
  MostRecentLrpDailyPrices: Array<LrpDailyPrices>;
  /** Find one Notification by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Notification?: Maybe<Notification>;
  /** Return the number of Notification rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  NotificationCount: Count;
  /** Find one NotificationSetting by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  NotificationSetting?: Maybe<INotificationSetting>;
  /** Return the number of NotificationSetting rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  NotificationSettingCount: Count;
  /** Find many NotificationSetting rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  NotificationSettings: Array<INotificationSetting>;
  /** Find many Notification rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Notifications: Array<Notification>;
  /** Find one Option by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Option?: Maybe<Option>;
  /** Return the number of Option rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  OptionCount: Count;
  /** Find many Option rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Options: Array<Option>;
  /** Find one Order by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this Order (one of the following: BROKERAGE, SWAP). */
  Order?: Maybe<Order>;
  /** Return the number of Order rows that match the given filter.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this Order (one of the following: BROKERAGE, SWAP). */
  OrderCount: Count;
  /** Find one OrderNote by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this OrderNote (one of the following: BROKERAGE, SWAP). */
  OrderNote?: Maybe<OrderNote>;
  /** Return the number of OrderNote rows that match the given filter.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this OrderNote (one of the following: BROKERAGE, SWAP). */
  OrderNoteCount: Count;
  /** Find many OrderNote rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this OrderNote (one of the following: BROKERAGE, SWAP). */
  OrderNotes: Array<OrderNote>;
  /** Find many Order rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this Order (one of the following: BROKERAGE, SWAP). */
  Orders: Array<Order>;
  /** Find one Organization by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ ORGANIZATION access at the scope of this resource. */
  Organization?: Maybe<OrganizationEntity>;
  /** Return the number of Organization rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ ORGANIZATION access at the scope of this resource. */
  OrganizationCount: Count;
  /** Find many Organization rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ ORGANIZATION access at the scope of this resource. */
  Organizations: Array<OrganizationEntity>;
  /** Find one OtcContract by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  OtcContract?: Maybe<OtcContract>;
  /** Return the number of OtcContract rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  OtcContractCount: Count;
  /** Find many OtcContract rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  OtcContracts: Array<OtcContract>;
  /** Find one PhysicalFeedTransaction by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  PhysicalFeedTransaction?: Maybe<PhysicalFeedTransaction>;
  /** Return the number of PhysicalFeedTransaction rows that match the given filter.  This resource is implicitly scoped by 'businessId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  PhysicalFeedTransactionCount: Count;
  /** Find many PhysicalFeedTransaction rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId'. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  PhysicalFeedTransactions: Array<PhysicalFeedTransaction>;
  /** Find one PhysicalInstrument by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  PhysicalInstrument?: Maybe<PhysicalInstrument>;
  /** Return the number of PhysicalInstrument rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  PhysicalInstrumentCount: Count;
  /** Find many PhysicalInstrument rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  PhysicalInstruments: Array<PhysicalInstrument>;
  /** Find one PhysicalSale by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  PhysicalSale?: Maybe<PhysicalSale>;
  /** Return the number of PhysicalSale rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  PhysicalSaleCount: Count;
  /** Find one PhysicalSaleFee by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  PhysicalSaleFee?: Maybe<PhysicalSaleFee>;
  /** Return the number of PhysicalSaleFee rows that match the given filter.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  PhysicalSaleFeeCount: Count;
  /** Find many PhysicalSaleFee rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  PhysicalSaleFees: Array<PhysicalSaleFee>;
  /** Find many PhysicalSale rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'entityId' referencing Entity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  PhysicalSales: Array<PhysicalSale>;
  /** Find one PositionComponent by id. null is returned if no value with the given id is available to the current user. The components of a position for the given account / instrument for each date. The position components represent the sub-quantities of each position by price & trade date. This resource is implicitly scoped by 'accountId' referencing BrokerageAccount. Reading this concept requires READ access unto the same resource-type as the Account that owns this PositionComponent (one of the following: BROKERAGE, SWAP). */
  PositionComponent?: Maybe<PositionComponent>;
  /** Find one PositionComponentAllocation by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  PositionComponentAllocation?: Maybe<PositionComponentAllocation>;
  /** Return the number of PositionComponentAllocation rows that match the given filter.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  PositionComponentAllocationCount: Count;
  /** Find many PositionComponentAllocation rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  PositionComponentAllocations: Array<PositionComponentAllocation>;
  /** Return the number of PositionComponent rows that match the given filter. The components of a position for the given account / instrument for each date. The position components represent the sub-quantities of each position by price & trade date. This resource is implicitly scoped by 'accountId' referencing BrokerageAccount. Reading this concept requires READ access unto the same resource-type as the Account that owns this PositionComponent (one of the following: BROKERAGE, SWAP). */
  PositionComponentCount: Count;
  /** Find many PositionComponent rows that match the given filter. If no limit is provided, a default limit of 500 will be used. The components of a position for the given account / instrument for each date. The position components represent the sub-quantities of each position by price & trade date. This resource is implicitly scoped by 'accountId' referencing BrokerageAccount. Reading this concept requires READ access unto the same resource-type as the Account that owns this PositionComponent (one of the following: BROKERAGE, SWAP). */
  PositionComponents: Array<PositionComponent>;
  /** Find one Product by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Product?: Maybe<Product>;
  /** Return the number of Product rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  ProductCount: Count;
  /** Find one ProductGroup by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  ProductGroup?: Maybe<ProductGroup>;
  /** Return the number of ProductGroup rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  ProductGroupCount: Count;
  /** Find many ProductGroup rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  ProductGroups: Array<ProductGroup>;
  /** Find one ProductLotSpecification by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  ProductLotSpecification?: Maybe<ProductLotSpecification>;
  /** Return the number of ProductLotSpecification rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  ProductLotSpecificationCount: Count;
  /** Find many ProductLotSpecification rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  ProductLotSpecifications: Array<ProductLotSpecification>;
  /** Find many Product rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Products: Array<Product>;
  /** Find one Region by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Region?: Maybe<Region>;
  /** Return the number of Region rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  RegionCount: Count;
  /** Find many Region rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Regions: Array<Region>;
  /** Find many RmaBusinessSummary rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  RmaBusinessSummaries: Array<RmaSummaryOfBusiness>;
  /** Find one RmaBusinessSummary by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  RmaBusinessSummary?: Maybe<RmaSummaryOfBusiness>;
  /** Return the number of RmaBusinessSummary rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  RmaBusinessSummaryCount: Count;
  /** Find many RmaCommodity rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  RmaCommodities: Array<RmaCommodity>;
  /** Find one RmaCommodity by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  RmaCommodity?: Maybe<RmaCommodity>;
  /** Return the number of RmaCommodity rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  RmaCommodityCount: Count;
  /** Find one RmaType by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  RmaType?: Maybe<RmaType>;
  /** Return the number of RmaType rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  RmaTypeCount: Count;
  /** Find many RmaType rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  RmaTypes: Array<RmaType>;
  Search: Array<SearchItem>;
  /** Find one Seller by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Seller?: Maybe<Seller>;
  /** Return the number of Seller rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SellerCount: Count;
  /** Find many Seller rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Sellers: Array<Seller>;
  /** Find one SmsNotificationSetting by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SmsNotificationSetting?: Maybe<SmsNotificationSetting>;
  /** Return the number of SmsNotificationSetting rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SmsNotificationSettingCount: Count;
  /** Find many SmsNotificationSetting rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SmsNotificationSettings: Array<SmsNotificationSetting>;
  /** Find one SpreadInstrument by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SpreadInstrument?: Maybe<SpreadInstrument>;
  /** Return the number of SpreadInstrument rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SpreadInstrumentCount: Count;
  /** Find one SpreadInstrumentLeg by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SpreadInstrumentLeg?: Maybe<SpreadInstrumentLeg>;
  /** Return the number of SpreadInstrumentLeg rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SpreadInstrumentLegCount: Count;
  /** Find many SpreadInstrumentLeg rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SpreadInstrumentLegs: Array<SpreadInstrumentLeg>;
  /** Find many SpreadInstrument rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SpreadInstruments: Array<SpreadInstrument>;
  /** Find one State by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  State?: Maybe<State>;
  /** Return the number of State rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  StateCount: Count;
  /** Find many State rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  States: Array<State>;
  /** Find one Swap by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Swap?: Maybe<Swap>;
  /** Find one SwapAccount by id. null is returned if no value with the given id is available to the current user.  This resource is explicitly scoped via id. Reading this concept requires READ SWAP access at the scope of this resource. */
  SwapAccount?: Maybe<SwapAccount>;
  /** Return the number of SwapAccount rows that match the given filter.  This resource is explicitly scoped via id. Reading this concept requires READ SWAP access at the scope of this resource. */
  SwapAccountCount: Count;
  /** Find many SwapAccount rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is explicitly scoped via id. Reading this concept requires READ SWAP access at the scope of this resource. */
  SwapAccounts: Array<SwapAccount>;
  /** Return the number of Swap rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SwapCount: Count;
  /** Find one SwapDealer by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SwapDealer?: Maybe<SwapDealer>;
  /** Return the number of SwapDealer rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SwapDealerCount: Count;
  /** Find many SwapDealer rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SwapDealers: Array<SwapDealer>;
  /** Find many Swap rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Swaps: Array<Swap>;
  /** Find one Swaption by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Swaption?: Maybe<Swaption>;
  /** Return the number of Swaption rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  SwaptionCount: Count;
  /** Find many Swaption rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  Swaptions: Array<Swaption>;
  /** Find one SwineLivestockGroup by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockGroup?: Maybe<SwineLivestockGroup>;
  /** Find one SwineLivestockGroupContract by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockGroupContract?: Maybe<SwineLivestockGroupContract>;
  /** Return the number of SwineLivestockGroupContract rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockGroupContractCount: Count;
  /** Find many SwineLivestockGroupContract rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockGroupContracts: Array<SwineLivestockGroupContract>;
  /** Return the number of SwineLivestockGroup rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockGroupCount: Count;
  /** Find one SwineLivestockGroupFeedUsage by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockGroupFeedUsage?: Maybe<SwineLivestockGroupFeedUsage>;
  /** Return the number of SwineLivestockGroupFeedUsage rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockGroupFeedUsageCount: Count;
  /** Find many SwineLivestockGroupFeedUsage rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockGroupFeedUsages: Array<SwineLivestockGroupFeedUsage>;
  /** Find many SwineLivestockGroup rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockGroups: Array<SwineLivestockGroup>;
  /** Find one SwineLivestockPopulationChange by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockPopulationChange?: Maybe<SwineLivestockPopulationChange>;
  /** Return the number of SwineLivestockPopulationChange rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockPopulationChangeCount: Count;
  /** Find many SwineLivestockPopulationChange rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockPopulationChanges: Array<SwineLivestockPopulationChange>;
  /** Find one SwineLivestockPopulationForecastByWeek by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockPopulationForecastByWeek?: Maybe<SwineLivestockPopulationForecastByWeek>;
  /** Return the number of SwineLivestockPopulationForecastByWeek rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockPopulationForecastByWeekCount: Count;
  /** Find many SwineLivestockPopulationForecastByWeek rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ OPERATIONS access at the scope of this resource. */
  SwineLivestockPopulationForecastsByWeek: Array<SwineLivestockPopulationForecastByWeek>;
  /** Find one Transaction by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this Transaction (one of the following: BROKERAGE, SWAP). */
  Transaction?: Maybe<Transaction>;
  /** Return the number of Transaction rows that match the given filter.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this Transaction (one of the following: BROKERAGE, SWAP). */
  TransactionCount: Count;
  /** Find one TransactionFee by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this TransactionFee (one of the following: BROKERAGE, SWAP). */
  TransactionFee?: Maybe<TransactionFee>;
  /** Return the number of TransactionFee rows that match the given filter.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this TransactionFee (one of the following: BROKERAGE, SWAP). */
  TransactionFeeCount: Count;
  /** Find many TransactionFee rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this TransactionFee (one of the following: BROKERAGE, SWAP). */
  TransactionFees: Array<TransactionFee>;
  /** Find one TransactionGroup by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  TransactionGroup?: Maybe<TransactionGroup>;
  /** Return the number of TransactionGroup rows that match the given filter.  This concept does not support filtering by scopeId. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  TransactionGroupCount: Count;
  /** Find many TransactionGroup rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. Reading this concept requires READ BROKERAGE access at the scope of this resource. */
  TransactionGroups: Array<TransactionGroup>;
  /** Find one TransactionNote by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this TransactionNote (one of the following: BROKERAGE, SWAP). */
  TransactionNote?: Maybe<TransactionNote>;
  /** Return the number of TransactionNote rows that match the given filter.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this TransactionNote (one of the following: BROKERAGE, SWAP). */
  TransactionNoteCount: Count;
  /** Find many TransactionNote rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this TransactionNote (one of the following: BROKERAGE, SWAP). */
  TransactionNotes: Array<TransactionNote>;
  /** Find many Transaction rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'accountId'. Reading this concept requires READ access unto the same resource-type as the Account that owns this Transaction (one of the following: BROKERAGE, SWAP). */
  Transactions: Array<Transaction>;
  /** Find one UsdaActualMilkPrice by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaActualMilkPrice?: Maybe<UsdaActualMilkPrice>;
  /** Return the number of UsdaActualMilkPrice rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaActualMilkPriceCount: Count;
  /** Find many UsdaActualMilkPrice rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaActualMilkPrices: Array<UsdaActualMilkPrice>;
  /** Find one UsdaFinalCheeseBlockPriceAndSales by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaFinalCheeseBlockPriceAndSales?: Maybe<UsdaFinalCheeseBlockPricesAndSales>;
  /** Return the number of UsdaFinalCheeseBlockPriceAndSales rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaFinalCheeseBlockPriceAndSalesCount: Count;
  /** Find many UsdaFinalCheeseBlockPriceAndSales rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaFinalCheeseBlockPricesAndSales: Array<UsdaFinalCheeseBlockPricesAndSales>;
  /** Find one UsdaFluidMilkAndCreamCentralUsReport by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaFluidMilkAndCreamCentralUsReport?: Maybe<UsdaFluidMilkAndCreamCentralUs>;
  /** Return the number of UsdaFluidMilkAndCreamCentralUsReport rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaFluidMilkAndCreamCentralUsReportCount: Count;
  /** Find many UsdaFluidMilkAndCreamCentralUsReport rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaFluidMilkAndCreamCentralUsReports: Array<UsdaFluidMilkAndCreamCentralUs>;
  /** Find one UsdaMarsReport by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMarsReport?: Maybe<UsdaMarsReport>;
  /** Return the number of UsdaMarsReport rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMarsReportCount: Count;
  /** Find many UsdaMarsReport rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMarsReports: Array<UsdaMarsReport>;
  /** Find one UsdaMonthlyNationalAverageDairyPrice by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMonthlyNationalAverageDairyPrice?: Maybe<UsdaMonthlyNationalAverageDairyPrice>;
  /** Return the number of UsdaMonthlyNationalAverageDairyPrice rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMonthlyNationalAverageDairyPriceCount: Count;
  /** Find many UsdaMonthlyNationalAverageDairyPrice rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMonthlyNationalAverageDairyPrices: Array<UsdaMonthlyNationalAverageDairyPrice>;
  /** Find one UsdaMonthlyRegionalMilkComponentPrice by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMonthlyRegionalMilkComponentPrice?: Maybe<UsdaMonthlyRegionalMilkComponentPrice>;
  /** Return the number of UsdaMonthlyRegionalMilkComponentPrice rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMonthlyRegionalMilkComponentPriceCount: Count;
  /** Find many UsdaMonthlyRegionalMilkComponentPrice rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMonthlyRegionalMilkComponentPrices: Array<UsdaMonthlyRegionalMilkComponentPrice>;
  /** Find one UsdaMonthlyRegionalMilkUtilization by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMonthlyRegionalMilkUtilization?: Maybe<UsdaMonthlyRegionalMilkUtilization>;
  /** Return the number of UsdaMonthlyRegionalMilkUtilization rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMonthlyRegionalMilkUtilizationCount: Count;
  /** Find many UsdaMonthlyRegionalMilkUtilization rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMonthlyRegionalMilkUtilizations: Array<UsdaMonthlyRegionalMilkUtilization>;
  /** Find one UsdaMprReport by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMprReport?: Maybe<UsdaMprReport>;
  /** Return the number of UsdaMprReport rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMprReportCount: Count;
  /** Find many UsdaMprReport rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaMprReports: Array<UsdaMprReport>;
  /** Find one UsdaRecord by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaRecord?: Maybe<UsdaRecord>;
  /** Return the number of UsdaRecord rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaRecordCount: Count;
  /** Find many UsdaRecord rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaRecords: Array<UsdaRecord>;
  /** Find one UsdaReport by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaReport?: Maybe<IUsdaReport>;
  /** Return the number of UsdaReport rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaReportCount: Count;
  /** Find many UsdaReport rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaReports: Array<IUsdaReport>;
  /** Find many UsdaRetailSummary rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaRetailSummaries: Array<UsdaRetailSummary>;
  /** Find one UsdaRetailSummary by id. null is returned if no value with the given id is available to the current user.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaRetailSummary?: Maybe<UsdaRetailSummary>;
  /** Return the number of UsdaRetailSummary rows that match the given filter.  This concept does not support filtering by scopeId. This concept is not permissioned. */
  UsdaRetailSummaryCount: Count;
  User?: Maybe<User>;
  Users: Array<User>;
  /** Find many VersionedBusinessDatum rows that match the given filter. If no limit is provided, a default limit of 500 will be used.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  VersionedBusinessData: Array<VersionedBusinessData>;
  /** Find one VersionedBusinessDatum by id. null is returned if no value with the given id is available to the current user.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  VersionedBusinessDatum?: Maybe<VersionedBusinessData>;
  /** Return the number of VersionedBusinessDatum rows that match the given filter.  This resource is implicitly scoped by 'businessId' referencing CustomerEntity. Reading this concept requires READ BUSINESS access at the scope of this resource. */
  VersionedBusinessDatumCount: Count;
  /** Fetches the monthly prices for a given feed ingredient. If specific monthly feed prices for an ingredient have been specified then they will be returned, otherwise the default price for the ingredient will be returned. Also returns whether or not the price was a default, the ID of the specific monthly price (if applicable), and the pricing methodology. */
  feedIngredientPricesByMonth: Array<FeedIngredientPriceByMonth>;
  fetchDrpCoverageLevels: Array<DairyRpEndorsement>;
  fetchDrpPractices: Array<DairyRPPracticeCodeType>;
  fetchDrpQuote?: Maybe<DairyRpQuote>;
  findAnonymousNotification?: Maybe<Notification>;
  getApplePushSubscription?: Maybe<VaultSubscription>;
  insuranceRatioScenarioPricingForFixedPriceSets: Array<InsuranceRatioScenarioPricingOutput>;
  insuranceRatioScenarioPricingForFixedPrices: InsuranceRatioScenarioPricingOutput;
  insuranceRatioScenarioPricingForFuturesCurve: InsuranceRatioScenarioPricingOutput;
  insuranceScenarioPricingForFixedPriceSets: Array<InsuranceScenarioPricingOutput>;
  insuranceScenarioPricingForFixedPrices: InsuranceScenarioPricingOutput;
  insuranceScenarioPricingForFuturesCurve: InsuranceScenarioPricingOutput;
  userPermissionsForTarget: UserPermissionsForTarget;
  userPermissionsForTargets: Array<UserPermissionsForTarget>;
};


export type Query_AIPArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_AIPCountArgs = {
  distinctOn?: InputMaybe<AipDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AipFilterDTO>;
};


export type Query_AIPsArgs = {
  distinctOn?: InputMaybe<AipDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<AipSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AipFilterDTO>;
};


export type Query_AccountArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_AccountBalanceArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_AccountBalanceCountArgs = {
  distinctOn?: InputMaybe<AccountBalanceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AccountBalanceFilterDTO>;
};


export type Query_AccountBalancesArgs = {
  distinctOn?: InputMaybe<AccountBalanceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<AccountBalanceSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AccountBalanceFilterDTO>;
};


export type Query_AccountCountArgs = {
  distinctOn?: InputMaybe<AccountDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AccountFilterDTO>;
};


export type Query_AccountFileArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_AccountFileCountArgs = {
  distinctOn?: InputMaybe<AccountFileDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AccountFileFilterDTO>;
};


export type Query_AccountFilesArgs = {
  distinctOn?: InputMaybe<AccountFileDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<AccountFileSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AccountFileFilterDTO>;
};


export type Query_AccountNoteArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_AccountNoteCountArgs = {
  distinctOn?: InputMaybe<AccountNoteDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AccountNoteFilterDTO>;
};


export type Query_AccountNotesArgs = {
  distinctOn?: InputMaybe<AccountNoteDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<AccountNoteSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AccountNoteFilterDTO>;
};


export type Query_AccountsArgs = {
  distinctOn?: InputMaybe<AccountDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<AccountSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AccountFilterDTO>;
};


export type Query_ActualBlendedMilkPriceArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ActualBlendedMilkPriceCountArgs = {
  distinctOn?: InputMaybe<ActualBlendedMilkPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ActualBlendedMilkPriceFilterDTO>;
};


export type Query_ActualBlendedMilkPricesArgs = {
  distinctOn?: InputMaybe<ActualBlendedMilkPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ActualBlendedMilkPriceSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ActualBlendedMilkPriceFilterDTO>;
};


export type Query_ActualMilkProductionArgs = {
  distinctOn?: InputMaybe<ActualMilkProductionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ActualMilkProductionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ActualMilkProductionFilterDTO>;
};


export type Query_ActualMilkProductionByMonthArgs = {
  distinctOn?: InputMaybe<ActualMilkProductionByMonthDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ActualMilkProductionByMonthSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ActualMilkProductionByMonthFilterDTO>;
};


export type Query_ActualMilkProductionByMonthRowArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ActualMilkProductionByMonthRowCountArgs = {
  distinctOn?: InputMaybe<ActualMilkProductionByMonthDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ActualMilkProductionByMonthFilterDTO>;
};


export type Query_ActualMilkProductionRowArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ActualMilkProductionRowCountArgs = {
  distinctOn?: InputMaybe<ActualMilkProductionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ActualMilkProductionFilterDTO>;
};


export type Query_ActualProductionArgs = {
  distinctOn?: InputMaybe<ActualProductionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ActualProductionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ActualProductionFilterDTO>;
};


export type Query_ActualProductionRowArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ActualProductionRowCountArgs = {
  distinctOn?: InputMaybe<ActualProductionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ActualProductionFilterDTO>;
};


export type Query_AggregateActualBlendedMilkPricesArgs = {
  calc: ActualBlendedMilkPriceAggregateDTO;
  distinctOn?: InputMaybe<ActualBlendedMilkPriceDistinctOnDTO>;
  groupBy: ActualBlendedMilkPriceGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ActualBlendedMilkPriceSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ActualBlendedMilkPriceSortByDTO>;
  where?: InputMaybe<ActualBlendedMilkPriceFilterDTO>;
};


export type Query_AggregateActualMilkProductionArgs = {
  calc: ActualMilkProductionAggregateDTO;
  distinctOn?: InputMaybe<ActualMilkProductionDistinctOnDTO>;
  groupBy: ActualMilkProductionGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ActualMilkProductionSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ActualMilkProductionSortByDTO>;
  where?: InputMaybe<ActualMilkProductionFilterDTO>;
};


export type Query_AggregateActualMilkProductionByMonthArgs = {
  calc: ActualMilkProductionByMonthAggregateDTO;
  distinctOn?: InputMaybe<ActualMilkProductionByMonthDistinctOnDTO>;
  groupBy: ActualMilkProductionByMonthGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ActualMilkProductionByMonthSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ActualMilkProductionByMonthSortByDTO>;
  where?: InputMaybe<ActualMilkProductionByMonthFilterDTO>;
};


export type Query_AggregateActualProductionArgs = {
  calc: ActualProductionAggregateDTO;
  distinctOn?: InputMaybe<ActualProductionDistinctOnDTO>;
  groupBy: ActualProductionGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ActualProductionSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ActualProductionSortByDTO>;
  where?: InputMaybe<ActualProductionFilterDTO>;
};


export type Query_AggregateAllocatedForecastedHedgedAndCappedVolumesArgs = {
  calc: AllocatedForecastedHedgedAndCappedVolumeAggregateDTO;
  distinctOn?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeDistinctOnDTO>;
  groupBy: AllocatedForecastedHedgedAndCappedVolumeGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeSortByDTO>;
  where?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>;
};


export type Query_AggregateBrokerageAccountsArgs = {
  calc: BrokerageAccountAggregateDTO;
  distinctOn?: InputMaybe<BrokerageAccountDistinctOnDTO>;
  groupBy: BrokerageAccountGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<BrokerageAccountSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<BrokerageAccountSortByDTO>;
  where?: InputMaybe<BrokerageAccountFilterDTO>;
};


export type Query_AggregateCropHarvestsArgs = {
  calc: CropHarvestAggregateDTO;
  distinctOn?: InputMaybe<CropHarvestDistinctOnDTO>;
  groupBy: CropHarvestGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CropHarvestSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CropHarvestSortByDTO>;
  where?: InputMaybe<CropHarvestFilterDTO>;
};


export type Query_AggregateCurrentAllocationPositionsArgs = {
  calc: CurrentAllocationPositionAggregateDTO;
  distinctOn?: InputMaybe<CurrentAllocationPositionDistinctOnDTO>;
  groupBy: CurrentAllocationPositionGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type Query_AggregateCurrentInventoryLevelsArgs = {
  calc: InventoryLevelAggregateDTO;
  distinctOn?: InputMaybe<InventoryLevelDistinctOnDTO>;
  groupBy: InventoryLevelGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InventoryLevelSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<InventoryLevelSortByDTO>;
  where?: InputMaybe<InventoryLevelFilterDTO>;
};


export type Query_AggregateCurrentPositionComponentsArgs = {
  calc: CurrentPositionComponentAggregateDTO;
  distinctOn?: InputMaybe<CurrentPositionComponentDistinctOnDTO>;
  groupBy: CurrentPositionComponentGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CurrentPositionComponentSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CurrentPositionComponentSortByDTO>;
  where?: InputMaybe<CurrentPositionComponentFilterDTO>;
};


export type Query_AggregateCurrentPositionsArgs = {
  calc: CurrentPositionAggregateDTO;
  distinctOn?: InputMaybe<CurrentPositionDistinctOnDTO>;
  groupBy: CurrentPositionGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CurrentPositionSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CurrentPositionSortByDTO>;
  where?: InputMaybe<CurrentPositionFilterDTO>;
};


export type Query_AggregateDerivedDrpEndorsementsArgs = {
  calc: DerivedDrpEndorsementAggregateDTO;
  distinctOn?: InputMaybe<DerivedDrpEndorsementDistinctOnDTO>;
  groupBy: DerivedDrpEndorsementGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<DerivedDrpEndorsementSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<DerivedDrpEndorsementSortByDTO>;
  where?: InputMaybe<DerivedDrpEndorsementFilterDTO>;
};


export type Query_AggregateDrpEndorsementsArgs = {
  calc: DrpEndorsementAggregateDTO;
  distinctOn?: InputMaybe<DrpEndorsementDistinctOnDTO>;
  groupBy: DrpEndorsementGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<DrpEndorsementSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<DrpEndorsementSortByDTO>;
  where?: InputMaybe<DrpEndorsementFilterDTO>;
};


export type Query_AggregateDrpInsuranceEndorsementsArgs = {
  calc: DrpInsuranceEndorsementAggregateDTO;
  distinctOn?: InputMaybe<DrpInsuranceEndorsementDistinctOnDTO>;
  groupBy: DrpInsuranceEndorsementGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<DrpInsuranceEndorsementSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<DrpInsuranceEndorsementSortByDTO>;
  where?: InputMaybe<DrpInsuranceEndorsementFilterDTO>;
};


export type Query_AggregateEntityAllocatedExposureRatiosArgs = {
  calc: EntityAllocatedExposureRatioAggregateDTO;
  distinctOn?: InputMaybe<EntityAllocatedExposureRatioDistinctOnDTO>;
  groupBy: EntityAllocatedExposureRatioGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityAllocatedExposureRatioSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<EntityAllocatedExposureRatioSortByDTO>;
  where?: InputMaybe<EntityAllocatedExposureRatioFilterDTO>;
};


export type Query_AggregateEntityAllocatedExposureRatiosByOwnerArgs = {
  calc: EntityAllocatedExposureRatioByOwnerAggregateDTO;
  distinctOn?: InputMaybe<EntityAllocatedExposureRatioByOwnerDistinctOnDTO>;
  groupBy: EntityAllocatedExposureRatioByOwnerGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityAllocatedExposureRatioByOwnerSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<EntityAllocatedExposureRatioByOwnerSortByDTO>;
  where?: InputMaybe<EntityAllocatedExposureRatioByOwnerFilterDTO>;
};


export type Query_AggregateEntityExposureRatiosArgs = {
  calc: EntityExposureRatioAggregateDTO;
  distinctOn?: InputMaybe<EntityExposureRatioDistinctOnDTO>;
  groupBy: EntityExposureRatioGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityExposureRatioSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<EntityExposureRatioSortByDTO>;
  where?: InputMaybe<EntityExposureRatioFilterDTO>;
};


export type Query_AggregateFeedIngredientActualUsagesArgs = {
  calc: FeedIngredientActualUsageAggregateDTO;
  distinctOn?: InputMaybe<FeedIngredientActualUsageDistinctOnDTO>;
  groupBy: FeedIngredientActualUsageGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FeedIngredientActualUsageSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<FeedIngredientActualUsageSortByDTO>;
  where?: InputMaybe<FeedIngredientActualUsageFilterDTO>;
};


export type Query_AggregateFeedIngredientConsumedAndPurchasedVolumesArgs = {
  calc: FeedIngredientConsumedAndPurchasedVolumeAggregateDTO;
  distinctOn?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeDistinctOnDTO>;
  groupBy: FeedIngredientConsumedAndPurchasedVolumeGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeSortByDTO>;
  where?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
};


export type Query_AggregateFeedIngredientForecastedUsagesArgs = {
  calc: FeedIngredientForecastedUsageAggregateDTO;
  distinctOn?: InputMaybe<FeedIngredientForecastedUsageDistinctOnDTO>;
  groupBy: FeedIngredientForecastedUsageGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FeedIngredientForecastedUsageSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<FeedIngredientForecastedUsageSortByDTO>;
  where?: InputMaybe<FeedIngredientForecastedUsageFilterDTO>;
};


export type Query_AggregateFieldsArgs = {
  calc: FieldAggregateDTO;
  distinctOn?: InputMaybe<FieldDistinctOnDTO>;
  groupBy: FieldGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FieldSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<FieldSortByDTO>;
  where?: InputMaybe<FieldFilterDTO>;
};


export type Query_AggregateForecastedHedgedAndCappedVolumesArgs = {
  calc: ForecastedHedgedAndCappedVolumeAggregateDTO;
  distinctOn?: InputMaybe<ForecastedHedgedAndCappedVolumeDistinctOnDTO>;
  groupBy: ForecastedHedgedAndCappedVolumeGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedHedgedAndCappedVolumeSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ForecastedHedgedAndCappedVolumeSortByDTO>;
  where?: InputMaybe<ForecastedHedgedAndCappedVolumeFilterDTO>;
};


export type Query_AggregateForecastedMilkProductionArgs = {
  calc: ForecastedMilkProductionAggregateDTO;
  distinctOn?: InputMaybe<ForecastedMilkProductionDistinctOnDTO>;
  groupBy: ForecastedMilkProductionGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedMilkProductionSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ForecastedMilkProductionSortByDTO>;
  where?: InputMaybe<ForecastedMilkProductionFilterDTO>;
};


export type Query_AggregateForecastedMilkProductionByMonthRolledUpArgs = {
  calc: ForecastedMilkProductionByMonthRolledUpAggregateDTO;
  distinctOn?: InputMaybe<ForecastedMilkProductionByMonthRolledUpDistinctOnDTO>;
  groupBy: ForecastedMilkProductionByMonthRolledUpGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedMilkProductionByMonthRolledUpSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ForecastedMilkProductionByMonthRolledUpSortByDTO>;
  where?: InputMaybe<ForecastedMilkProductionByMonthRolledUpFilterDTO>;
};


export type Query_AggregateForecastedMilkProductionByMonthsArgs = {
  calc: ForecastedMilkProductionByMonthAggregateDTO;
  distinctOn?: InputMaybe<ForecastedMilkProductionByMonthDistinctOnDTO>;
  groupBy: ForecastedMilkProductionByMonthGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedMilkProductionByMonthSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ForecastedMilkProductionByMonthSortByDTO>;
  where?: InputMaybe<ForecastedMilkProductionByMonthFilterDTO>;
};


export type Query_AggregateForecastedMilkProductionCoverageArgs = {
  calc: ForecastedMilkProductionCoverageAggregateDTO;
  distinctOn?: InputMaybe<ForecastedMilkProductionCoverageDistinctOnDTO>;
  groupBy: ForecastedMilkProductionCoverageGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedMilkProductionCoverageSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ForecastedMilkProductionCoverageSortByDTO>;
  where?: InputMaybe<ForecastedMilkProductionCoverageFilterDTO>;
};


export type Query_AggregateForecastedMilkUtilizationsArgs = {
  calc: ForecastedMilkUtilizationAggregateDTO;
  distinctOn?: InputMaybe<ForecastedMilkUtilizationDistinctOnDTO>;
  groupBy: ForecastedMilkUtilizationGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedMilkUtilizationSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ForecastedMilkUtilizationSortByDTO>;
  where?: InputMaybe<ForecastedMilkUtilizationFilterDTO>;
};


export type Query_AggregateForecastedProductionArgs = {
  calc: ForecastedProductionAggregateDTO;
  distinctOn?: InputMaybe<ForecastedProductionDistinctOnDTO>;
  groupBy: ForecastedProductionGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedProductionSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ForecastedProductionSortByDTO>;
  where?: InputMaybe<ForecastedProductionFilterDTO>;
};


export type Query_AggregateForecastedSwineLivestockFeedUsageAtFinishArgs = {
  calc: ForecastedSwineLivestockFeedUsageAtFinishAggregateDTO;
  distinctOn?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishDistinctOnDTO>;
  groupBy: ForecastedSwineLivestockFeedUsageAtFinishGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishSortByDTO>;
  where?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishFilterDTO>;
};


export type Query_AggregateForecastedSwineLivestockFeedUsagesArgs = {
  calc: ForecastedSwineLivestockFeedUsageAggregateDTO;
  distinctOn?: InputMaybe<ForecastedSwineLivestockFeedUsageDistinctOnDTO>;
  groupBy: ForecastedSwineLivestockFeedUsageGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedSwineLivestockFeedUsageSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ForecastedSwineLivestockFeedUsageSortByDTO>;
  where?: InputMaybe<ForecastedSwineLivestockFeedUsageFilterDTO>;
};


export type Query_AggregateGrainFillOrdersArgs = {
  calc: GrainFillOrderAggregateDTO;
  distinctOn?: InputMaybe<GrainFillOrderDistinctOnDTO>;
  groupBy: GrainFillOrderGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainFillOrderSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<GrainFillOrderSortByDTO>;
  where?: InputMaybe<GrainFillOrderFilterDTO>;
};


export type Query_AggregateGrainTargetOrdersArgs = {
  calc: GrainTargetOrderAggregateDTO;
  distinctOn?: InputMaybe<GrainTargetOrderDistinctOnDTO>;
  groupBy: GrainTargetOrderGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainTargetOrderSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<GrainTargetOrderSortByDTO>;
  where?: InputMaybe<GrainTargetOrderFilterDTO>;
};


export type Query_AggregateHedgeStrategyCurrentPositionsArgs = {
  calc: HedgeStrategyCurrentPositionAggregateDTO;
  distinctOn?: InputMaybe<HedgeStrategyCurrentPositionDistinctOnDTO>;
  groupBy: HedgeStrategyCurrentPositionGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<HedgeStrategyCurrentPositionSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<HedgeStrategyCurrentPositionSortByDTO>;
  where?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
};


export type Query_AggregateHedgeStrategyHistoricalPnlsArgs = {
  calc: HedgeStrategyHistoricalPnlAggregateDTO;
  distinctOn?: InputMaybe<HedgeStrategyHistoricalPnlDistinctOnDTO>;
  groupBy: HedgeStrategyHistoricalPnlGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<HedgeStrategyHistoricalPnlSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<HedgeStrategyHistoricalPnlSortByDTO>;
  where?: InputMaybe<HedgeStrategyHistoricalPnlFilterDTO>;
};


export type Query_AggregateHistoricalPnlArgs = {
  calc: HistoricalPnlAggregateDTO;
  distinctOn?: InputMaybe<HistoricalPnlDistinctOnDTO>;
  groupBy: HistoricalPnlGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<HistoricalPnlSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<HistoricalPnlSortByDTO>;
  where?: InputMaybe<HistoricalPnlFilterDTO>;
};


export type Query_AggregateInsuranceHistoricalDrpPremiumsArgs = {
  calc: InsuranceHistoricalDrpPremiumAggregateDTO;
  distinctOn?: InputMaybe<InsuranceHistoricalDrpPremiumDistinctOnDTO>;
  groupBy: InsuranceHistoricalDrpPremiumGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuranceHistoricalDrpPremiumSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<InsuranceHistoricalDrpPremiumSortByDTO>;
  where?: InputMaybe<InsuranceHistoricalDrpPremiumFilterDTO>;
};


export type Query_AggregateInventoryLevelsArgs = {
  calc: InventoryLevelAggregateDTO;
  distinctOn?: InputMaybe<InventoryLevelDistinctOnDTO>;
  groupBy: InventoryLevelGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InventoryLevelSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<InventoryLevelSortByDTO>;
  where?: InputMaybe<InventoryLevelFilterDTO>;
};


export type Query_AggregateLedgerActualEntriesArgs = {
  calc: LedgerActualEntryAggregateDTO;
  distinctOn?: InputMaybe<LedgerActualEntryDistinctOnDTO>;
  groupBy: LedgerActualEntryGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerActualEntrySortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<LedgerActualEntrySortByDTO>;
  where?: InputMaybe<LedgerActualEntryFilterDTO>;
};


export type Query_AggregateLedgerEntriesArgs = {
  calc: LedgerEntryAggregateDTO;
  distinctOn?: InputMaybe<LedgerEntryDistinctOnDTO>;
  groupBy: LedgerEntryGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerEntrySortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<LedgerEntrySortByDTO>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};


export type Query_AggregateLedgerForecastedEntriesArgs = {
  calc: LedgerForecastedEntryAggregateDTO;
  distinctOn?: InputMaybe<LedgerForecastedEntryDistinctOnDTO>;
  groupBy: LedgerForecastedEntryGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerForecastedEntrySortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<LedgerForecastedEntrySortByDTO>;
  where?: InputMaybe<LedgerForecastedEntryFilterDTO>;
};


export type Query_AggregateLivestockGroupContractPeriodAllocationsArgs = {
  calc: LivestockGroupContractPeriodAllocationAggregateDTO;
  distinctOn?: InputMaybe<LivestockGroupContractPeriodAllocationDistinctOnDTO>;
  groupBy: LivestockGroupContractPeriodAllocationGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LivestockGroupContractPeriodAllocationSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<LivestockGroupContractPeriodAllocationSortByDTO>;
  where?: InputMaybe<LivestockGroupContractPeriodAllocationFilterDTO>;
};


export type Query_AggregateLrpInsuranceEndorsementsArgs = {
  calc: LrpInsuranceEndorsementAggregateDTO;
  distinctOn?: InputMaybe<LrpInsuranceEndorsementDistinctOnDTO>;
  groupBy: LrpInsuranceEndorsementGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LrpInsuranceEndorsementSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<LrpInsuranceEndorsementSortByDTO>;
  where?: InputMaybe<LrpInsuranceEndorsementFilterDTO>;
};


export type Query_AggregatePhysicalFeedTransactionsArgs = {
  calc: PhysicalFeedTransactionAggregateDTO;
  distinctOn?: InputMaybe<PhysicalFeedTransactionDistinctOnDTO>;
  groupBy: PhysicalFeedTransactionGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<PhysicalFeedTransactionSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PhysicalFeedTransactionSortByDTO>;
  where?: InputMaybe<PhysicalFeedTransactionFilterDTO>;
};


export type Query_AggregatePositionComponentAllocationsArgs = {
  calc: PositionComponentAllocationAggregateDTO;
  distinctOn?: InputMaybe<PositionComponentAllocationDistinctOnDTO>;
  groupBy: PositionComponentAllocationGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<PositionComponentAllocationSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PositionComponentAllocationSortByDTO>;
  where?: InputMaybe<PositionComponentAllocationFilterDTO>;
};


export type Query_AggregatePositionComponentsArgs = {
  calc: PositionComponentAggregateDTO;
  distinctOn?: InputMaybe<PositionComponentDistinctOnDTO>;
  groupBy: PositionComponentGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<PositionComponentSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PositionComponentSortByDTO>;
  where?: InputMaybe<PositionComponentFilterDTO>;
};


export type Query_AggregateRmaBusinessSummariesArgs = {
  calc: RmaSummaryOfBusinessAggregateDTO;
  distinctOn?: InputMaybe<RmaSummaryOfBusinessDistinctOnDTO>;
  groupBy: RmaSummaryOfBusinessGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<RmaSummaryOfBusinessSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<RmaSummaryOfBusinessSortByDTO>;
  where?: InputMaybe<RmaSummaryOfBusinessFilterDTO>;
};


export type Query_AggregateSwineLivestockPopulationChangesArgs = {
  calc: SwineLivestockPopulationChangeAggregateDTO;
  distinctOn?: InputMaybe<SwineLivestockPopulationChangeDistinctOnDTO>;
  groupBy: SwineLivestockPopulationChangeGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SwineLivestockPopulationChangeSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SwineLivestockPopulationChangeSortByDTO>;
  where?: InputMaybe<SwineLivestockPopulationChangeFilterDTO>;
};


export type Query_AggregateTransactionFeesArgs = {
  calc: TransactionFeeAggregateDTO;
  distinctOn?: InputMaybe<TransactionFeeDistinctOnDTO>;
  groupBy: TransactionFeeGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<TransactionFeeSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<TransactionFeeSortByDTO>;
  where?: InputMaybe<TransactionFeeFilterDTO>;
};


export type Query_AggregateTransactionsArgs = {
  calc: TransactionAggregateDTO;
  distinctOn?: InputMaybe<TransactionDistinctOnDTO>;
  groupBy: TransactionGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<TransactionSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};


export type Query_AggregateUsdaMonthlyNationalAverageDairyPricesArgs = {
  calc: UsdaMonthlyNationalAverageDairyPriceAggregateDTO;
  distinctOn?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceDistinctOnDTO>;
  groupBy: UsdaMonthlyNationalAverageDairyPriceGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceSortByDTO>;
  where?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceFilterDTO>;
};


export type Query_AggregateUsdaMonthlyRegionalMilkComponentPricesArgs = {
  calc: UsdaMonthlyRegionalMilkComponentPriceAggregateDTO;
  distinctOn?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceDistinctOnDTO>;
  groupBy: UsdaMonthlyRegionalMilkComponentPriceGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceSortByDTO>;
  where?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceFilterDTO>;
};


export type Query_AggregateUsdaMonthlyRegionalMilkUtilizationsArgs = {
  calc: UsdaMonthlyRegionalMilkUtilizationAggregateDTO;
  distinctOn?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationDistinctOnDTO>;
  groupBy: UsdaMonthlyRegionalMilkUtilizationGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationSortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationSortByDTO>;
  where?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationFilterDTO>;
};


export type Query_AggregateUsdaRetailSummariesArgs = {
  calc: UsdaRetailSummaryAggregateDTO;
  distinctOn?: InputMaybe<UsdaRetailSummaryDistinctOnDTO>;
  groupBy: UsdaRetailSummaryGroupByDTO;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaRetailSummarySortByDTO>;
  resolveNestedNodes?: InputMaybe<Scalars['Boolean']>;
  scopeId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<UsdaRetailSummarySortByDTO>;
  where?: InputMaybe<UsdaRetailSummaryFilterDTO>;
};


export type Query_AllocatedForecastedHedgedAndCappedVolumeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_AllocatedForecastedHedgedAndCappedVolumeCountArgs = {
  distinctOn?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>;
};


export type Query_AllocatedForecastedHedgedAndCappedVolumesArgs = {
  distinctOn?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AllocatedForecastedHedgedAndCappedVolumeFilterDTO>;
};


export type Query_ApplePushNotificationSettingArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ApplePushNotificationSettingCountArgs = {
  distinctOn?: InputMaybe<ApplePushNotificationSettingDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ApplePushNotificationSettingFilterDTO>;
};


export type Query_ApplePushNotificationSettingsArgs = {
  distinctOn?: InputMaybe<ApplePushNotificationSettingDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ApplePushNotificationSettingSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ApplePushNotificationSettingFilterDTO>;
};


export type Query_BasisInstrumentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_BasisInstrumentCountArgs = {
  distinctOn?: InputMaybe<BasisInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BasisInstrumentFilterDTO>;
};


export type Query_BasisInstrumentsArgs = {
  distinctOn?: InputMaybe<BasisInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<BasisInstrumentSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BasisInstrumentFilterDTO>;
};


export type Query_BrokerageAccountArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_BrokerageAccountCountArgs = {
  distinctOn?: InputMaybe<BrokerageAccountDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BrokerageAccountFilterDTO>;
};


export type Query_BrokerageAccountsArgs = {
  distinctOn?: InputMaybe<BrokerageAccountDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<BrokerageAccountSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BrokerageAccountFilterDTO>;
};


export type Query_BusinessContactArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_BusinessContactCountArgs = {
  distinctOn?: InputMaybe<BusinessContactDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BusinessContactFilterDTO>;
};


export type Query_BusinessContactsArgs = {
  distinctOn?: InputMaybe<BusinessContactDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<BusinessContactSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BusinessContactFilterDTO>;
};


export type Query_BusinessDataVersionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_BusinessDataVersionCountArgs = {
  distinctOn?: InputMaybe<BusinessDataVersionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BusinessDataVersionFilterDTO>;
};


export type Query_BusinessDataVersionsArgs = {
  distinctOn?: InputMaybe<BusinessDataVersionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<BusinessDataVersionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BusinessDataVersionFilterDTO>;
};


export type Query_BusinessRolesInScopeArgs = {
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_BuyerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_BuyerCountArgs = {
  distinctOn?: InputMaybe<BuyerDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BuyerFilterDTO>;
};


export type Query_BuyersArgs = {
  distinctOn?: InputMaybe<BuyerDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<BuyerSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BuyerFilterDTO>;
};


export type Query_CashInstrumentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_CashInstrumentCountArgs = {
  distinctOn?: InputMaybe<CashInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CashInstrumentFilterDTO>;
};


export type Query_CashInstrumentsArgs = {
  distinctOn?: InputMaybe<CashInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CashInstrumentSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CashInstrumentFilterDTO>;
};


export type Query_CommitmentInstrumentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_CommitmentInstrumentCountArgs = {
  distinctOn?: InputMaybe<CommitmentInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CommitmentInstrumentFilterDTO>;
};


export type Query_CommitmentInstrumentsArgs = {
  distinctOn?: InputMaybe<CommitmentInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CommitmentInstrumentSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CommitmentInstrumentFilterDTO>;
};


export type Query_CountiesArgs = {
  distinctOn?: InputMaybe<CountyDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CountySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CountyFilterDTO>;
};


export type Query_CountyArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_CountyCountArgs = {
  distinctOn?: InputMaybe<CountyDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CountyFilterDTO>;
};


export type Query_CropArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_CropCategoriesArgs = {
  distinctOn?: InputMaybe<CropCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CropCategorySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CropCategoryFilterDTO>;
};


export type Query_CropCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_CropCategoryCountArgs = {
  distinctOn?: InputMaybe<CropCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CropCategoryFilterDTO>;
};


export type Query_CropCountArgs = {
  distinctOn?: InputMaybe<CropDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CropFilterDTO>;
};


export type Query_CropHarvestArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_CropHarvestCountArgs = {
  distinctOn?: InputMaybe<CropHarvestDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CropHarvestFilterDTO>;
};


export type Query_CropHarvestsArgs = {
  distinctOn?: InputMaybe<CropHarvestDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CropHarvestSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CropHarvestFilterDTO>;
};


export type Query_CropsArgs = {
  distinctOn?: InputMaybe<CropDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CropSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CropFilterDTO>;
};


export type Query_CurrentAllocationPositionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_CurrentAllocationPositionCountArgs = {
  distinctOn?: InputMaybe<CurrentAllocationPositionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type Query_CurrentAllocationPositionsArgs = {
  distinctOn?: InputMaybe<CurrentAllocationPositionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type Query_CurrentInventoryLevelsArgs = {
  distinctOn?: InputMaybe<InventoryLevelDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InventoryLevelSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InventoryLevelFilterDTO>;
};


export type Query_CurrentPositionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_CurrentPositionComponentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_CurrentPositionComponentCountArgs = {
  distinctOn?: InputMaybe<CurrentPositionComponentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CurrentPositionComponentFilterDTO>;
};


export type Query_CurrentPositionComponentsArgs = {
  distinctOn?: InputMaybe<CurrentPositionComponentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CurrentPositionComponentSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CurrentPositionComponentFilterDTO>;
};


export type Query_CurrentPositionCountArgs = {
  distinctOn?: InputMaybe<CurrentPositionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CurrentPositionFilterDTO>;
};


export type Query_CurrentPositionsArgs = {
  distinctOn?: InputMaybe<CurrentPositionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CurrentPositionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CurrentPositionFilterDTO>;
};


export type Query_CustomerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_CustomerCountArgs = {
  distinctOn?: InputMaybe<CustomerEntityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CustomerEntityFilterDTO>;
};


export type Query_CustomersArgs = {
  distinctOn?: InputMaybe<CustomerEntityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<CustomerEntitySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CustomerEntityFilterDTO>;
};


export type Query_DerivedDrpEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_DerivedDrpEndorsementCountArgs = {
  distinctOn?: InputMaybe<DerivedDrpEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DerivedDrpEndorsementFilterDTO>;
};


export type Query_DerivedDrpEndorsementsArgs = {
  distinctOn?: InputMaybe<DerivedDrpEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<DerivedDrpEndorsementSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DerivedDrpEndorsementFilterDTO>;
};


export type Query_DoNotUse__CurrentInventoryLevelArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_DoNotUse__CurrentInventoryLevelCountArgs = {
  distinctOn?: InputMaybe<InventoryLevelDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InventoryLevelFilterDTO>;
};


export type Query_DrpEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_DrpEndorsementCountArgs = {
  distinctOn?: InputMaybe<DrpEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DrpEndorsementFilterDTO>;
};


export type Query_DrpEndorsementsArgs = {
  distinctOn?: InputMaybe<DrpEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<DrpEndorsementSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DrpEndorsementFilterDTO>;
};


export type Query_DrpInsuranceEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_DrpInsuranceEndorsementCountArgs = {
  distinctOn?: InputMaybe<DrpInsuranceEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DrpInsuranceEndorsementFilterDTO>;
};


export type Query_DrpInsuranceEndorsementsArgs = {
  distinctOn?: InputMaybe<DrpInsuranceEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<DrpInsuranceEndorsementSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DrpInsuranceEndorsementFilterDTO>;
};


export type Query_DrpNewDailyPricesSubscriptionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_DrpNewDailyPricesSubscriptionCountArgs = {
  distinctOn?: InputMaybe<DrpNewDailyPricesSubscriptionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DrpNewDailyPricesSubscriptionFilterDTO>;
};


export type Query_DrpNewDailyPricesSubscriptionsArgs = {
  distinctOn?: InputMaybe<DrpNewDailyPricesSubscriptionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<DrpNewDailyPricesSubscriptionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DrpNewDailyPricesSubscriptionFilterDTO>;
};


export type Query_DrpSubscriptionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_DrpSubscriptionCountArgs = {
  distinctOn?: InputMaybe<DrpSubscriptionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DrpSubscriptionFilterDTO>;
};


export type Query_DrpSubscriptionsArgs = {
  distinctOn?: InputMaybe<DrpSubscriptionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<DrpSubscriptionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DrpSubscriptionFilterDTO>;
};


export type Query_EmailNotificationSettingArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_EmailNotificationSettingCountArgs = {
  distinctOn?: InputMaybe<EmailNotificationSettingDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EmailNotificationSettingFilterDTO>;
};


export type Query_EmailNotificationSettingsArgs = {
  distinctOn?: InputMaybe<EmailNotificationSettingDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EmailNotificationSettingSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EmailNotificationSettingFilterDTO>;
};


export type Query_EntitiesArgs = {
  distinctOn?: InputMaybe<EntityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntitySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityFilterDTO>;
};


export type Query_EntityArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_EntityAllocatedExposureRatioArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_EntityAllocatedExposureRatioByOwnerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_EntityAllocatedExposureRatioByOwnerCountArgs = {
  distinctOn?: InputMaybe<EntityAllocatedExposureRatioByOwnerDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityAllocatedExposureRatioByOwnerFilterDTO>;
};


export type Query_EntityAllocatedExposureRatioCountArgs = {
  distinctOn?: InputMaybe<EntityAllocatedExposureRatioDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityAllocatedExposureRatioFilterDTO>;
};


export type Query_EntityAllocatedExposureRatiosArgs = {
  distinctOn?: InputMaybe<EntityAllocatedExposureRatioDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityAllocatedExposureRatioSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where: EntityAllocatedExposureRatioFilterDTO;
};


export type Query_EntityAllocatedExposureRatiosByOwnerArgs = {
  distinctOn?: InputMaybe<EntityAllocatedExposureRatioByOwnerDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityAllocatedExposureRatioByOwnerSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where: EntityAllocatedExposureRatioByOwnerFilterDTO;
};


export type Query_EntityCountArgs = {
  distinctOn?: InputMaybe<EntityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityFilterDTO>;
};


export type Query_EntityExposureRatioArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_EntityExposureRatioCountArgs = {
  distinctOn?: InputMaybe<EntityExposureRatioDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityExposureRatioFilterDTO>;
};


export type Query_EntityExposureRatiosArgs = {
  distinctOn?: InputMaybe<EntityExposureRatioDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityExposureRatioSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where: EntityExposureRatioFilterDTO;
};


export type Query_EntityGroupArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_EntityGroupCountArgs = {
  distinctOn?: InputMaybe<EntityGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityGroupFilterDTO>;
};


export type Query_EntityGroupEntriesArgs = {
  distinctOn?: InputMaybe<EntityGroupEntryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityGroupEntrySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityGroupEntryFilterDTO>;
};


export type Query_EntityGroupEntryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_EntityGroupEntryCountArgs = {
  distinctOn?: InputMaybe<EntityGroupEntryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityGroupEntryFilterDTO>;
};


export type Query_EntityGroupsArgs = {
  distinctOn?: InputMaybe<EntityGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityGroupSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityGroupFilterDTO>;
};


export type Query_EntityMemberArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_EntityMemberCountArgs = {
  distinctOn?: InputMaybe<EntityMemberDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityMemberFilterDTO>;
};


export type Query_EntityMembersArgs = {
  distinctOn?: InputMaybe<EntityMemberDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityMemberSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityMemberFilterDTO>;
};


export type Query_EntityNoteArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_EntityNoteCountArgs = {
  distinctOn?: InputMaybe<EntityNoteDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityNoteFilterDTO>;
};


export type Query_EntityNotesArgs = {
  distinctOn?: InputMaybe<EntityNoteDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityNoteSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityNoteFilterDTO>;
};


export type Query_EntityUserArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_EntityUserCountArgs = {
  distinctOn?: InputMaybe<EntityUserDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityUserFilterDTO>;
};


export type Query_EntityUsersArgs = {
  distinctOn?: InputMaybe<EntityUserDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<EntityUserSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<EntityUserFilterDTO>;
};


export type Query_FCMArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_FCMCountArgs = {
  distinctOn?: InputMaybe<FcmDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FcmFilterDTO>;
};


export type Query_FCMsArgs = {
  distinctOn?: InputMaybe<FcmDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FcmSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FcmFilterDTO>;
};


export type Query_FeedCategoriesArgs = {
  distinctOn?: InputMaybe<FeedCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FeedCategorySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedCategoryFilterDTO>;
};


export type Query_FeedCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_FeedCategoryCountArgs = {
  distinctOn?: InputMaybe<FeedCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedCategoryFilterDTO>;
};


export type Query_FeedIngredientArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_FeedIngredientActualUsageArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_FeedIngredientActualUsageCountArgs = {
  distinctOn?: InputMaybe<FeedIngredientActualUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedIngredientActualUsageFilterDTO>;
};


export type Query_FeedIngredientActualUsagesArgs = {
  distinctOn?: InputMaybe<FeedIngredientActualUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FeedIngredientActualUsageSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedIngredientActualUsageFilterDTO>;
};


export type Query_FeedIngredientConsumedAndPurchasedVolumeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_FeedIngredientConsumedAndPurchasedVolumeCountArgs = {
  distinctOn?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeFilterDTO>;
};


export type Query_FeedIngredientConsumedAndPurchasedVolumesArgs = {
  distinctOn?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FeedIngredientConsumedAndPurchasedVolumeSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where: FeedIngredientConsumedAndPurchasedVolumeFilterDTO;
};


export type Query_FeedIngredientCountArgs = {
  distinctOn?: InputMaybe<FeedIngredientDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedIngredientFilterDTO>;
};


export type Query_FeedIngredientForecastedUsageArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_FeedIngredientForecastedUsageCountArgs = {
  distinctOn?: InputMaybe<FeedIngredientForecastedUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedIngredientForecastedUsageFilterDTO>;
};


export type Query_FeedIngredientForecastedUsagesArgs = {
  distinctOn?: InputMaybe<FeedIngredientForecastedUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FeedIngredientForecastedUsageSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedIngredientForecastedUsageFilterDTO>;
};


export type Query_FeedIngredientUsageArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_FeedIngredientUsageCountArgs = {
  distinctOn?: InputMaybe<FeedIngredientUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedIngredientUsageFilterDTO>;
};


export type Query_FeedIngredientUsagesArgs = {
  distinctOn?: InputMaybe<FeedIngredientUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FeedIngredientUsageSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedIngredientUsageFilterDTO>;
};


export type Query_FeedIngredientsArgs = {
  distinctOn?: InputMaybe<FeedIngredientDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FeedIngredientSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedIngredientFilterDTO>;
};


export type Query_FeedTransactionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_FeedTransactionCountArgs = {
  distinctOn?: InputMaybe<FeedTransactionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedTransactionFilterDTO>;
};


export type Query_FeedTransactionsArgs = {
  distinctOn?: InputMaybe<FeedTransactionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FeedTransactionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FeedTransactionFilterDTO>;
};


export type Query_FieldArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_FieldCountArgs = {
  distinctOn?: InputMaybe<FieldDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FieldFilterDTO>;
};


export type Query_FieldsArgs = {
  distinctOn?: InputMaybe<FieldDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FieldSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FieldFilterDTO>;
};


export type Query_ForecastedHedgedAndCappedVolumeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ForecastedHedgedAndCappedVolumeCountArgs = {
  distinctOn?: InputMaybe<ForecastedHedgedAndCappedVolumeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedHedgedAndCappedVolumeFilterDTO>;
};


export type Query_ForecastedHedgedAndCappedVolumesArgs = {
  distinctOn?: InputMaybe<ForecastedHedgedAndCappedVolumeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedHedgedAndCappedVolumeSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedHedgedAndCappedVolumeFilterDTO>;
};


export type Query_ForecastedLivestockFeedUsageArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ForecastedLivestockFeedUsageCountArgs = {
  distinctOn?: InputMaybe<ForecastedLivestockFeedUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedLivestockFeedUsageFilterDTO>;
};


export type Query_ForecastedLivestockFeedUsagesArgs = {
  distinctOn?: InputMaybe<ForecastedLivestockFeedUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedLivestockFeedUsageSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedLivestockFeedUsageFilterDTO>;
};


export type Query_ForecastedMilkProductionArgs = {
  distinctOn?: InputMaybe<ForecastedMilkProductionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedMilkProductionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedMilkProductionFilterDTO>;
};


export type Query_ForecastedMilkProductionByMonthArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ForecastedMilkProductionByMonthCountArgs = {
  distinctOn?: InputMaybe<ForecastedMilkProductionByMonthDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedMilkProductionByMonthFilterDTO>;
};


export type Query_ForecastedMilkProductionByMonthRolledUpArgs = {
  distinctOn?: InputMaybe<ForecastedMilkProductionByMonthRolledUpDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedMilkProductionByMonthRolledUpSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedMilkProductionByMonthRolledUpFilterDTO>;
};


export type Query_ForecastedMilkProductionByMonthRolledUpRowCountArgs = {
  distinctOn?: InputMaybe<ForecastedMilkProductionByMonthRolledUpDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedMilkProductionByMonthRolledUpFilterDTO>;
};


export type Query_ForecastedMilkProductionByMonthsArgs = {
  distinctOn?: InputMaybe<ForecastedMilkProductionByMonthDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedMilkProductionByMonthSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedMilkProductionByMonthFilterDTO>;
};


export type Query_ForecastedMilkProductionCoverageArgs = {
  distinctOn?: InputMaybe<ForecastedMilkProductionCoverageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedMilkProductionCoverageSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where: ForecastedMilkProductionCoverageFilterDTO;
};


export type Query_ForecastedMilkProductionCoverageRowArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ForecastedMilkProductionCoverageRowCountArgs = {
  distinctOn?: InputMaybe<ForecastedMilkProductionCoverageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedMilkProductionCoverageFilterDTO>;
};


export type Query_ForecastedMilkProductionRowArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ForecastedMilkProductionRowCountArgs = {
  distinctOn?: InputMaybe<ForecastedMilkProductionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedMilkProductionFilterDTO>;
};


export type Query_ForecastedMilkUtilizationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ForecastedMilkUtilizationCountArgs = {
  distinctOn?: InputMaybe<ForecastedMilkUtilizationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedMilkUtilizationFilterDTO>;
};


export type Query_ForecastedMilkUtilizationsArgs = {
  distinctOn?: InputMaybe<ForecastedMilkUtilizationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedMilkUtilizationSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedMilkUtilizationFilterDTO>;
};


export type Query_ForecastedProductionArgs = {
  distinctOn?: InputMaybe<ForecastedProductionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedProductionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedProductionFilterDTO>;
};


export type Query_ForecastedProductionRowArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ForecastedProductionRowCountArgs = {
  distinctOn?: InputMaybe<ForecastedProductionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedProductionFilterDTO>;
};


export type Query_ForecastedSwineLivestockFeedUsageArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ForecastedSwineLivestockFeedUsageAtFinishArgs = {
  distinctOn?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishFilterDTO>;
};


export type Query_ForecastedSwineLivestockFeedUsageCountArgs = {
  distinctOn?: InputMaybe<ForecastedSwineLivestockFeedUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedSwineLivestockFeedUsageFilterDTO>;
};


export type Query_ForecastedSwineLivestockFeedUsageRowAtFinishArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ForecastedSwineLivestockFeedUsageRowAtFinishCountArgs = {
  distinctOn?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedSwineLivestockFeedUsageAtFinishFilterDTO>;
};


export type Query_ForecastedSwineLivestockFeedUsagesArgs = {
  distinctOn?: InputMaybe<ForecastedSwineLivestockFeedUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForecastedSwineLivestockFeedUsageSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForecastedSwineLivestockFeedUsageFilterDTO>;
};


export type Query_ForwardContractArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ForwardContractCountArgs = {
  distinctOn?: InputMaybe<ForwardContractAccountDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForwardContractAccountFilterDTO>;
};


export type Query_ForwardContractsArgs = {
  distinctOn?: InputMaybe<ForwardContractAccountDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ForwardContractAccountSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ForwardContractAccountFilterDTO>;
};


export type Query_FutureArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_FutureCountArgs = {
  distinctOn?: InputMaybe<FutureInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FutureFilterDTO>;
};


export type Query_FuturesArgs = {
  distinctOn?: InputMaybe<FutureInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<FutureSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FutureFilterDTO>;
};


export type Query_GrainAdvisorArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainAdvisorCountArgs = {
  distinctOn?: InputMaybe<GrainAdvisorDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainAdvisorFilterDTO>;
};


export type Query_GrainAdvisorsArgs = {
  distinctOn?: InputMaybe<GrainAdvisorDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainAdvisorSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainAdvisorFilterDTO>;
};


export type Query_GrainBuyerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainBuyerCountArgs = {
  distinctOn?: InputMaybe<GrainBuyerDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainBuyerFilterDTO>;
};


export type Query_GrainBuyerLocationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainBuyerLocationCountArgs = {
  distinctOn?: InputMaybe<GrainBuyerLocationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainBuyerLocationFilterDTO>;
};


export type Query_GrainBuyerLocationsArgs = {
  distinctOn?: InputMaybe<GrainBuyerLocationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainBuyerLocationSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainBuyerLocationFilterDTO>;
};


export type Query_GrainBuyersArgs = {
  distinctOn?: InputMaybe<GrainBuyerDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainBuyerSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainBuyerFilterDTO>;
};


export type Query_GrainCropPlanArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainCropPlanCountArgs = {
  distinctOn?: InputMaybe<GrainCropPlanDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainCropPlanFilterDTO>;
};


export type Query_GrainCropPlansArgs = {
  distinctOn?: InputMaybe<GrainCropPlanDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainCropPlanSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainCropPlanFilterDTO>;
};


export type Query_GrainCropYearArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainCropYearCountArgs = {
  distinctOn?: InputMaybe<GrainCropYearDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainCropYearFilterDTO>;
};


export type Query_GrainCropYearsArgs = {
  distinctOn?: InputMaybe<GrainCropYearDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainCropYearSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainCropYearFilterDTO>;
};


export type Query_GrainFeedPlanArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainFeedPlanCountArgs = {
  distinctOn?: InputMaybe<GrainFeedPlanDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainFeedPlanFilterDTO>;
};


export type Query_GrainFeedPlansArgs = {
  distinctOn?: InputMaybe<GrainFeedPlanDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainFeedPlanSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainFeedPlanFilterDTO>;
};


export type Query_GrainFillOrderArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainFillOrderActivitiesArgs = {
  distinctOn?: InputMaybe<GrainFillOrderActivityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainFillOrderActivitySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainFillOrderActivityFilterDTO>;
};


export type Query_GrainFillOrderActivityArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainFillOrderActivityCountArgs = {
  distinctOn?: InputMaybe<GrainFillOrderActivityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainFillOrderActivityFilterDTO>;
};


export type Query_GrainFillOrderCountArgs = {
  distinctOn?: InputMaybe<GrainFillOrderDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainFillOrderFilterDTO>;
};


export type Query_GrainFillOrderModificationRequestArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainFillOrderModificationRequestActivitiesArgs = {
  distinctOn?: InputMaybe<GrainFillOrderModificationRequestActivityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainFillOrderModificationRequestActivitySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainFillOrderModificationRequestActivityFilterDTO>;
};


export type Query_GrainFillOrderModificationRequestActivityArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainFillOrderModificationRequestActivityCountArgs = {
  distinctOn?: InputMaybe<GrainFillOrderModificationRequestActivityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainFillOrderModificationRequestActivityFilterDTO>;
};


export type Query_GrainFillOrderModificationRequestCountArgs = {
  distinctOn?: InputMaybe<GrainFillOrderModificationRequestDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainFillOrderModificationRequestFilterDTO>;
};


export type Query_GrainFillOrderModificationRequestsArgs = {
  distinctOn?: InputMaybe<GrainFillOrderModificationRequestDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainFillOrderModificationRequestSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainFillOrderModificationRequestFilterDTO>;
};


export type Query_GrainFillOrdersArgs = {
  distinctOn?: InputMaybe<GrainFillOrderDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainFillOrderSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainFillOrderFilterDTO>;
};


export type Query_GrainLocationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainLocationCountArgs = {
  distinctOn?: InputMaybe<GrainLocationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainLocationFilterDTO>;
};


export type Query_GrainLocationsArgs = {
  distinctOn?: InputMaybe<GrainLocationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainLocationSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainLocationFilterDTO>;
};


export type Query_GrainOrderArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainOrderActivitiesArgs = {
  distinctOn?: InputMaybe<GrainOrderActivityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainOrderActivitySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainOrderActivityFilterDTO>;
};


export type Query_GrainOrderActivityArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainOrderActivityCountArgs = {
  distinctOn?: InputMaybe<GrainOrderActivityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainOrderActivityFilterDTO>;
};


export type Query_GrainOrderCountArgs = {
  distinctOn?: InputMaybe<GrainOrderDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainOrderFilterDTO>;
};


export type Query_GrainOrderNoteArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainOrderNoteCountArgs = {
  distinctOn?: InputMaybe<GrainOrderNoteDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainOrderNoteFilterDTO>;
};


export type Query_GrainOrderNotesArgs = {
  distinctOn?: InputMaybe<GrainOrderNoteDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainOrderNoteSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainOrderNoteFilterDTO>;
};


export type Query_GrainOrdersArgs = {
  distinctOn?: InputMaybe<GrainOrderDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainOrderSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainOrderFilterDTO>;
};


export type Query_GrainPlanArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainPlanCountArgs = {
  distinctOn?: InputMaybe<GrainPlanDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainPlanFilterDTO>;
};


export type Query_GrainPlansArgs = {
  distinctOn?: InputMaybe<GrainPlanDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainPlanSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainPlanFilterDTO>;
};


export type Query_GrainTargetOrderArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainTargetOrderActivitiesArgs = {
  distinctOn?: InputMaybe<GrainTargetOrderActivityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainTargetOrderActivitySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainTargetOrderActivityFilterDTO>;
};


export type Query_GrainTargetOrderActivityArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_GrainTargetOrderActivityCountArgs = {
  distinctOn?: InputMaybe<GrainTargetOrderActivityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainTargetOrderActivityFilterDTO>;
};


export type Query_GrainTargetOrderCountArgs = {
  distinctOn?: InputMaybe<GrainTargetOrderDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainTargetOrderFilterDTO>;
};


export type Query_GrainTargetOrdersArgs = {
  distinctOn?: InputMaybe<GrainTargetOrderDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<GrainTargetOrderSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<GrainTargetOrderFilterDTO>;
};


export type Query_HedgeStrategiesArgs = {
  distinctOn?: InputMaybe<HedgeStrategyDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<HedgeStrategySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<HedgeStrategyFilterDTO>;
};


export type Query_HedgeStrategyArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_HedgeStrategyCountArgs = {
  distinctOn?: InputMaybe<HedgeStrategyDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<HedgeStrategyFilterDTO>;
};


export type Query_HedgeStrategyCurrentPositionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_HedgeStrategyCurrentPositionCountArgs = {
  distinctOn?: InputMaybe<HedgeStrategyCurrentPositionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
};


export type Query_HedgeStrategyCurrentPositionsArgs = {
  distinctOn?: InputMaybe<HedgeStrategyCurrentPositionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<HedgeStrategyCurrentPositionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
};


export type Query_HedgeStrategyHistoricalPnlArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_HedgeStrategyHistoricalPnlCountArgs = {
  distinctOn?: InputMaybe<HedgeStrategyHistoricalPnlDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<HedgeStrategyHistoricalPnlFilterDTO>;
};


export type Query_HedgeStrategyHistoricalPnlsArgs = {
  distinctOn?: InputMaybe<HedgeStrategyHistoricalPnlDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<HedgeStrategyHistoricalPnlSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<HedgeStrategyHistoricalPnlFilterDTO>;
};


export type Query_HistoricalPnlArgs = {
  distinctOn?: InputMaybe<HistoricalPnlDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<HistoricalPnlSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where: HistoricalPnlFilterDTO;
};


export type Query_HistoricalPnlRowArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_HistoricalPnlRowCountArgs = {
  distinctOn?: InputMaybe<HistoricalPnlDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<HistoricalPnlFilterDTO>;
};


export type Query_HypotheticalAllocationPnlAggregateArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_HypotheticalAllocationPnlAggregateCountArgs = {
  distinctOn?: InputMaybe<HypotheticalAllocationPnlAggregateDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<HypotheticalAllocationPnlAggregateFilterDTO>;
};


export type Query_HypotheticalAllocationPnlAggregatesArgs = {
  distinctOn?: InputMaybe<HypotheticalAllocationPnlAggregateDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<HypotheticalAllocationPnlAggregateSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where: HypotheticalAllocationPnlAggregateFilterDTO;
};


export type Query_HypotheticalHedgeStrategyPnlAggregateArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_HypotheticalHedgeStrategyPnlAggregateCountArgs = {
  distinctOn?: InputMaybe<HypotheticalHedgeStrategyPnlAggregateDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<HypotheticalHedgeStrategyPnlAggregateFilterDTO>;
};


export type Query_HypotheticalHedgeStrategyPnlAggregatesArgs = {
  distinctOn?: InputMaybe<HypotheticalHedgeStrategyPnlAggregateDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<HypotheticalHedgeStrategyPnlAggregateSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where: HypotheticalHedgeStrategyPnlAggregateFilterDTO;
};


export type Query_InstrumentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InstrumentCountArgs = {
  distinctOn?: InputMaybe<InstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InstrumentFilterDTO>;
};


export type Query_InstrumentSymbolGroupArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InstrumentSymbolGroupCountArgs = {
  distinctOn?: InputMaybe<InstrumentSymbolGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
};


export type Query_InstrumentSymbolGroupsArgs = {
  distinctOn?: InputMaybe<InstrumentSymbolGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
};


export type Query_InstrumentsArgs = {
  distinctOn?: InputMaybe<InstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InstrumentSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InstrumentFilterDTO>;
};


export type Query_InsuranceAgenciesArgs = {
  distinctOn?: InputMaybe<InsuranceAgencyDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuranceAgencySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceAgencyFilterDTO>;
};


export type Query_InsuranceAgencyArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsuranceAgencyCountArgs = {
  distinctOn?: InputMaybe<InsuranceAgencyDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceAgencyFilterDTO>;
};


export type Query_InsuranceAgentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsuranceAgentCountArgs = {
  distinctOn?: InputMaybe<InsuranceAgentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceAgentFilterDTO>;
};


export type Query_InsuranceAgentsArgs = {
  distinctOn?: InputMaybe<InsuranceAgentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuranceAgentSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceAgentFilterDTO>;
};


export type Query_InsuranceEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsuranceEndorsementAllocationRatioArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsuranceEndorsementAllocationRatioCountArgs = {
  distinctOn?: InputMaybe<InsuranceEndorsementAllocationRatioDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
};


export type Query_InsuranceEndorsementAllocationRatiosArgs = {
  distinctOn?: InputMaybe<InsuranceEndorsementAllocationRatioDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuranceEndorsementAllocationRatioSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceEndorsementAllocationRatioFilterDTO>;
};


export type Query_InsuranceEndorsementCountArgs = {
  distinctOn?: InputMaybe<InsuranceEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceEndorsementFilterDTO>;
};


export type Query_InsuranceEndorsementsArgs = {
  distinctOn?: InputMaybe<InsuranceEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuranceEndorsementSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceEndorsementFilterDTO>;
};


export type Query_InsuranceFileArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsuranceFileCountArgs = {
  distinctOn?: InputMaybe<InsuranceFileDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceFileFilterDTO>;
};


export type Query_InsuranceFilesArgs = {
  distinctOn?: InputMaybe<InsuranceFileDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuranceFileSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceFileFilterDTO>;
};


export type Query_InsuranceHistoricalDrpPremiumArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsuranceHistoricalDrpPremiumCountArgs = {
  distinctOn?: InputMaybe<InsuranceHistoricalDrpPremiumDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceHistoricalDrpPremiumFilterDTO>;
};


export type Query_InsuranceHistoricalDrpPremiumInputArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsuranceHistoricalDrpPremiumInputCountArgs = {
  distinctOn?: InputMaybe<InsuranceHistoricalDrpPremiumInputDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceHistoricalDrpPremiumInputFilterDTO>;
};


export type Query_InsuranceHistoricalDrpPremiumInputsArgs = {
  distinctOn?: InputMaybe<InsuranceHistoricalDrpPremiumInputDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuranceHistoricalDrpPremiumInputSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceHistoricalDrpPremiumInputFilterDTO>;
};


export type Query_InsuranceHistoricalDrpPremiumsArgs = {
  distinctOn?: InputMaybe<InsuranceHistoricalDrpPremiumDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuranceHistoricalDrpPremiumSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceHistoricalDrpPremiumFilterDTO>;
};


export type Query_InsuranceHistoricalPremiumArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsuranceHistoricalPremiumCountArgs = {
  distinctOn?: InputMaybe<InsuranceHistoricalPremiumDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceHistoricalPremiumFilterDTO>;
};


export type Query_InsuranceHistoricalPremiumInputArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsuranceHistoricalPremiumInputCountArgs = {
  distinctOn?: InputMaybe<InsuranceHistoricalPremiumInputDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceHistoricalPremiumInputFilterDTO>;
};


export type Query_InsuranceHistoricalPremiumInputsArgs = {
  distinctOn?: InputMaybe<InsuranceHistoricalPremiumInputDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuranceHistoricalPremiumInputSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceHistoricalPremiumInputFilterDTO>;
};


export type Query_InsuranceHistoricalPremiumsArgs = {
  distinctOn?: InputMaybe<InsuranceHistoricalPremiumDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuranceHistoricalPremiumSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuranceHistoricalPremiumFilterDTO>;
};


export type Query_InsurancePoliciesArgs = {
  distinctOn?: InputMaybe<InsurancePolicyDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ProducerAndPolicySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ProducerAndPolicyFilterDTO>;
};


export type Query_InsurancePolicyArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsurancePolicyCountArgs = {
  distinctOn?: InputMaybe<InsurancePolicyDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ProducerAndPolicyFilterDTO>;
};


export type Query_InsurancePracticeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsurancePracticeCountArgs = {
  distinctOn?: InputMaybe<InsurancePracticeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsurancePracticeFilterDTO>;
};


export type Query_InsurancePracticesArgs = {
  distinctOn?: InputMaybe<InsurancePracticeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsurancePracticeSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsurancePracticeFilterDTO>;
};


export type Query_InsuredYieldAndPriceArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InsuredYieldAndPriceCountArgs = {
  distinctOn?: InputMaybe<InsuredYieldAndPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuredYieldAndPriceFilterDTO>;
};


export type Query_InsuredYieldsAndPricesArgs = {
  distinctOn?: InputMaybe<InsuredYieldAndPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InsuredYieldAndPriceSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InsuredYieldAndPriceFilterDTO>;
};


export type Query_IntegrationMappingArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_IntegrationMappingCountArgs = {
  distinctOn?: InputMaybe<IntegrationMappingDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<IntegrationMappingFilterDTO>;
};


export type Query_IntegrationMappingsArgs = {
  distinctOn?: InputMaybe<IntegrationMappingDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<IntegrationMappingSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<IntegrationMappingFilterDTO>;
};


export type Query_InventoryLevelArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_InventoryLevelCountArgs = {
  distinctOn?: InputMaybe<InventoryLevelDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InventoryLevelFilterDTO>;
};


export type Query_InventoryLevelsArgs = {
  distinctOn?: InputMaybe<InventoryLevelDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<InventoryLevelSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<InventoryLevelFilterDTO>;
};


export type Query_LedgerActualEntriesArgs = {
  distinctOn?: InputMaybe<LedgerActualEntryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerActualEntrySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerActualEntryFilterDTO>;
};


export type Query_LedgerActualEntryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LedgerActualEntryCountArgs = {
  distinctOn?: InputMaybe<LedgerActualEntryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerActualEntryFilterDTO>;
};


export type Query_LedgerCategoriesArgs = {
  distinctOn?: InputMaybe<LedgerCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerCategorySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerCategoryFilterDTO>;
};


export type Query_LedgerCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LedgerCategoryCountArgs = {
  distinctOn?: InputMaybe<LedgerCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerCategoryFilterDTO>;
};


export type Query_LedgerEntriesArgs = {
  distinctOn?: InputMaybe<LedgerEntryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerEntrySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};


export type Query_LedgerEntryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LedgerEntryCountArgs = {
  distinctOn?: InputMaybe<LedgerEntryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerEntryFilterDTO>;
};


export type Query_LedgerExpenseCategoriesArgs = {
  distinctOn?: InputMaybe<LedgerExpenseCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerExpenseCategorySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerExpenseCategoryFilterDTO>;
};


export type Query_LedgerExpenseCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LedgerExpenseCategoryCountArgs = {
  distinctOn?: InputMaybe<LedgerExpenseCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerExpenseCategoryFilterDTO>;
};


export type Query_LedgerFeedCategoriesArgs = {
  distinctOn?: InputMaybe<LedgerFeedCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerFeedCategorySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerFeedCategoryFilterDTO>;
};


export type Query_LedgerFeedCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LedgerFeedCategoryCountArgs = {
  distinctOn?: InputMaybe<LedgerFeedCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerFeedCategoryFilterDTO>;
};


export type Query_LedgerForecastedEntriesArgs = {
  distinctOn?: InputMaybe<LedgerForecastedEntryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerForecastedEntrySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerForecastedEntryFilterDTO>;
};


export type Query_LedgerForecastedEntryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LedgerForecastedEntryCountArgs = {
  distinctOn?: InputMaybe<LedgerForecastedEntryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerForecastedEntryFilterDTO>;
};


export type Query_LedgerMilkCheckCategoriesArgs = {
  distinctOn?: InputMaybe<LedgerMilkCheckCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerMilkCheckCategorySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerMilkCheckCategoryFilterDTO>;
};


export type Query_LedgerMilkCheckCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LedgerMilkCheckCategoryCountArgs = {
  distinctOn?: InputMaybe<LedgerMilkCheckCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerMilkCheckCategoryFilterDTO>;
};


export type Query_LedgerRevenueCategoriesArgs = {
  distinctOn?: InputMaybe<LedgerRevenueCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LedgerRevenueCategorySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerRevenueCategoryFilterDTO>;
};


export type Query_LedgerRevenueCategoryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LedgerRevenueCategoryCountArgs = {
  distinctOn?: InputMaybe<LedgerRevenueCategoryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LedgerRevenueCategoryFilterDTO>;
};


export type Query_LgmGrossMarginArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LgmGrossMarginCountArgs = {
  distinctOn?: InputMaybe<LgmGrossMarginDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LgmGrossMarginFilterDTO>;
};


export type Query_LgmGrossMarginsArgs = {
  distinctOn?: InputMaybe<LgmGrossMarginDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LgmGrossMarginSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LgmGrossMarginFilterDTO>;
};


export type Query_LgmInsuranceEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LgmInsuranceEndorsementCountArgs = {
  distinctOn?: InputMaybe<LgmInsuranceEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LgmInsuranceEndorsementFilterDTO>;
};


export type Query_LgmInsuranceEndorsementsArgs = {
  distinctOn?: InputMaybe<LgmInsuranceEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LgmInsuranceEndorsementSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LgmInsuranceEndorsementFilterDTO>;
};


export type Query_LivestockGroupArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LivestockGroupContractPeriodAllocationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LivestockGroupContractPeriodAllocationCountArgs = {
  distinctOn?: InputMaybe<LivestockGroupContractPeriodAllocationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockGroupContractPeriodAllocationFilterDTO>;
};


export type Query_LivestockGroupContractPeriodAllocationsArgs = {
  distinctOn?: InputMaybe<LivestockGroupContractPeriodAllocationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LivestockGroupContractPeriodAllocationSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockGroupContractPeriodAllocationFilterDTO>;
};


export type Query_LivestockGroupCountArgs = {
  distinctOn?: InputMaybe<LivestockGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockGroupFilterDTO>;
};


export type Query_LivestockGroupFeedUsageArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LivestockGroupFeedUsageCountArgs = {
  distinctOn?: InputMaybe<LivestockGroupFeedUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
};


export type Query_LivestockGroupFeedUsageVersionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LivestockGroupFeedUsageVersionCountArgs = {
  distinctOn?: InputMaybe<LivestockGroupFeedUsageVersionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockGroupFeedUsageVersionFilterDTO>;
};


export type Query_LivestockGroupFeedUsageVersionsArgs = {
  distinctOn?: InputMaybe<LivestockGroupFeedUsageVersionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LivestockGroupFeedUsageVersionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockGroupFeedUsageVersionFilterDTO>;
};


export type Query_LivestockGroupFeedUsagesArgs = {
  distinctOn?: InputMaybe<LivestockGroupFeedUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LivestockGroupFeedUsageSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
};


export type Query_LivestockGroupVersionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LivestockGroupVersionCountArgs = {
  distinctOn?: InputMaybe<LivestockGroupVersionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockGroupVersionFilterDTO>;
};


export type Query_LivestockGroupVersionsArgs = {
  distinctOn?: InputMaybe<LivestockGroupVersionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LivestockGroupVersionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockGroupVersionFilterDTO>;
};


export type Query_LivestockGroupsArgs = {
  distinctOn?: InputMaybe<LivestockGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LivestockGroupSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockGroupFilterDTO>;
};


export type Query_LivestockPopulationChangeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LivestockPopulationChangeCountArgs = {
  distinctOn?: InputMaybe<LivestockPopulationChangeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockPopulationChangeFilterDTO>;
};


export type Query_LivestockPopulationChangesArgs = {
  distinctOn?: InputMaybe<LivestockPopulationChangeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LivestockPopulationChangeSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LivestockPopulationChangeFilterDTO>;
};


export type Query_LocationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LocationCountArgs = {
  distinctOn?: InputMaybe<LocationEntityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LocationEntityFilterDTO>;
};


export type Query_LocationsArgs = {
  distinctOn?: InputMaybe<LocationEntityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LocationEntitySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LocationEntityFilterDTO>;
};


export type Query_LrpActualValueArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LrpActualValueCountArgs = {
  distinctOn?: InputMaybe<LrpActualValuesDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpActualValuesFilterDTO>;
};


export type Query_LrpActualValuesArgs = {
  distinctOn?: InputMaybe<LrpActualValuesDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LrpActualValuesSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpActualValuesFilterDTO>;
};


export type Query_LrpDailyPriceArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LrpDailyPriceCountArgs = {
  distinctOn?: InputMaybe<LrpDailyPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpDailyPricesFilterDTO>;
};


export type Query_LrpDailyPricesArgs = {
  distinctOn?: InputMaybe<LrpDailyPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LrpDailyPricesSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpDailyPricesFilterDTO>;
};


export type Query_LrpEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LrpEndorsementCountArgs = {
  distinctOn?: InputMaybe<LrpEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpEndorsementFilterDTO>;
};


export type Query_LrpEndorsementsArgs = {
  distinctOn?: InputMaybe<LrpEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LrpEndorsementSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpEndorsementFilterDTO>;
};


export type Query_LrpInsuranceEndorsementArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LrpInsuranceEndorsementCountArgs = {
  distinctOn?: InputMaybe<LrpInsuranceEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpInsuranceEndorsementFilterDTO>;
};


export type Query_LrpInsuranceEndorsementsArgs = {
  distinctOn?: InputMaybe<LrpInsuranceEndorsementDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LrpInsuranceEndorsementSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpInsuranceEndorsementFilterDTO>;
};


export type Query_LrpNewDailyPricesSubscriptionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LrpNewDailyPricesSubscriptionCountArgs = {
  distinctOn?: InputMaybe<LrpNewDailyPricesSubscriptionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpNewDailyPricesSubscriptionFilterDTO>;
};


export type Query_LrpNewDailyPricesSubscriptionsArgs = {
  distinctOn?: InputMaybe<LrpNewDailyPricesSubscriptionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LrpNewDailyPricesSubscriptionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpNewDailyPricesSubscriptionFilterDTO>;
};


export type Query_LrpSubscriptionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_LrpSubscriptionCountArgs = {
  distinctOn?: InputMaybe<LrpSubscriptionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpSubscriptionFilterDTO>;
};


export type Query_LrpSubscriptionsArgs = {
  distinctOn?: InputMaybe<LrpSubscriptionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LrpSubscriptionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<LrpSubscriptionFilterDTO>;
};


export type Query_MilkOrderArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_MilkOrderCountArgs = {
  distinctOn?: InputMaybe<MilkOrderDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<MilkOrderFilterDTO>;
};


export type Query_MilkOrdersArgs = {
  distinctOn?: InputMaybe<MilkOrderDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<MilkOrderSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<MilkOrderFilterDTO>;
};


export type Query_MostRecentLgmGrossMarginsArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LgmGrossMarginSortByDTO>;
  where?: InputMaybe<LgmGrossMarginFilterDTO>;
};


export type Query_MostRecentLrpDailyPricesArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<LrpDailyPricesSortByDTO>;
  where?: InputMaybe<LrpDailyPricesFilterDTO>;
};


export type Query_NotificationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_NotificationCountArgs = {
  distinctOn?: InputMaybe<NotificationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<NotificationFilterDTO>;
};


export type Query_NotificationSettingArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_NotificationSettingCountArgs = {
  distinctOn?: InputMaybe<NotificationSettingDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<NotificationSettingFilterDTO>;
};


export type Query_NotificationSettingsArgs = {
  distinctOn?: InputMaybe<NotificationSettingDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<NotificationSettingSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<NotificationSettingFilterDTO>;
};


export type Query_NotificationsArgs = {
  distinctOn?: InputMaybe<NotificationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<NotificationSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<NotificationFilterDTO>;
};


export type Query_OptionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_OptionCountArgs = {
  distinctOn?: InputMaybe<OptionInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OptionFilterDTO>;
};


export type Query_OptionsArgs = {
  distinctOn?: InputMaybe<OptionInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<OptionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OptionFilterDTO>;
};


export type Query_OrderArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_OrderCountArgs = {
  distinctOn?: InputMaybe<OrderDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type Query_OrderNoteArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_OrderNoteCountArgs = {
  distinctOn?: InputMaybe<OrderNoteDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OrderNoteFilterDTO>;
};


export type Query_OrderNotesArgs = {
  distinctOn?: InputMaybe<OrderNoteDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<OrderNoteSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OrderNoteFilterDTO>;
};


export type Query_OrdersArgs = {
  distinctOn?: InputMaybe<OrderDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<OrderSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type Query_OrganizationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_OrganizationCountArgs = {
  distinctOn?: InputMaybe<OrganizationEntityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OrganizationEntityFilterDTO>;
};


export type Query_OrganizationsArgs = {
  distinctOn?: InputMaybe<OrganizationEntityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<OrganizationEntitySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OrganizationEntityFilterDTO>;
};


export type Query_OtcContractArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_OtcContractCountArgs = {
  distinctOn?: InputMaybe<OtcContractDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type Query_OtcContractsArgs = {
  distinctOn?: InputMaybe<OtcContractDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<OtcContractSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OtcContractFilterDTO>;
};


export type Query_PhysicalFeedTransactionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_PhysicalFeedTransactionCountArgs = {
  distinctOn?: InputMaybe<PhysicalFeedTransactionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PhysicalFeedTransactionFilterDTO>;
};


export type Query_PhysicalFeedTransactionsArgs = {
  distinctOn?: InputMaybe<PhysicalFeedTransactionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<PhysicalFeedTransactionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PhysicalFeedTransactionFilterDTO>;
};


export type Query_PhysicalInstrumentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_PhysicalInstrumentCountArgs = {
  distinctOn?: InputMaybe<PhysicalInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PhysicalInstrumentFilterDTO>;
};


export type Query_PhysicalInstrumentsArgs = {
  distinctOn?: InputMaybe<PhysicalInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<PhysicalInstrumentSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PhysicalInstrumentFilterDTO>;
};


export type Query_PhysicalSaleArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_PhysicalSaleCountArgs = {
  distinctOn?: InputMaybe<PhysicalSaleDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type Query_PhysicalSaleFeeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_PhysicalSaleFeeCountArgs = {
  distinctOn?: InputMaybe<PhysicalSaleFeeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PhysicalSaleFeeFilterDTO>;
};


export type Query_PhysicalSaleFeesArgs = {
  distinctOn?: InputMaybe<PhysicalSaleFeeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<PhysicalSaleFeeSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PhysicalSaleFeeFilterDTO>;
};


export type Query_PhysicalSalesArgs = {
  distinctOn?: InputMaybe<PhysicalSaleDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type Query_PositionComponentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_PositionComponentAllocationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_PositionComponentAllocationCountArgs = {
  distinctOn?: InputMaybe<PositionComponentAllocationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PositionComponentAllocationFilterDTO>;
};


export type Query_PositionComponentAllocationsArgs = {
  distinctOn?: InputMaybe<PositionComponentAllocationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<PositionComponentAllocationSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PositionComponentAllocationFilterDTO>;
};


export type Query_PositionComponentCountArgs = {
  distinctOn?: InputMaybe<PositionComponentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PositionComponentFilterDTO>;
};


export type Query_PositionComponentsArgs = {
  distinctOn?: InputMaybe<PositionComponentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<PositionComponentSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<PositionComponentFilterDTO>;
};


export type Query_ProductArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ProductCountArgs = {
  distinctOn?: InputMaybe<ProductDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ProductFilterDTO>;
};


export type Query_ProductGroupArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ProductGroupCountArgs = {
  distinctOn?: InputMaybe<ProductGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ProductGroupFilterDTO>;
};


export type Query_ProductGroupsArgs = {
  distinctOn?: InputMaybe<ProductGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ProductGroupSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ProductGroupFilterDTO>;
};


export type Query_ProductLotSpecificationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_ProductLotSpecificationCountArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ProductLotSpecificationFilterDTO>;
};


export type Query_ProductLotSpecificationsArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ProductLotSpecificationSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ProductLotSpecificationFilterDTO>;
};


export type Query_ProductsArgs = {
  distinctOn?: InputMaybe<ProductDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<ProductSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ProductFilterDTO>;
};


export type Query_RegionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_RegionCountArgs = {
  distinctOn?: InputMaybe<RegionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<RegionFilterDTO>;
};


export type Query_RegionsArgs = {
  distinctOn?: InputMaybe<RegionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<RegionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<RegionFilterDTO>;
};


export type Query_RmaBusinessSummariesArgs = {
  distinctOn?: InputMaybe<RmaSummaryOfBusinessDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<RmaSummaryOfBusinessSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<RmaSummaryOfBusinessFilterDTO>;
};


export type Query_RmaBusinessSummaryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_RmaBusinessSummaryCountArgs = {
  distinctOn?: InputMaybe<RmaSummaryOfBusinessDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<RmaSummaryOfBusinessFilterDTO>;
};


export type Query_RmaCommoditiesArgs = {
  distinctOn?: InputMaybe<RmaCommodityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<RmaCommoditySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<RmaCommodityFilterDTO>;
};


export type Query_RmaCommodityArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_RmaCommodityCountArgs = {
  distinctOn?: InputMaybe<RmaCommodityDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<RmaCommodityFilterDTO>;
};


export type Query_RmaTypeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_RmaTypeCountArgs = {
  distinctOn?: InputMaybe<RmaTypeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<RmaTypeFilterDTO>;
};


export type Query_RmaTypesArgs = {
  distinctOn?: InputMaybe<RmaTypeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<RmaTypeSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<RmaTypeFilterDTO>;
};


export type Query_SearchArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  query: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
  typesToExclude?: InputMaybe<Array<TypeOfSearchItem>>;
  typesToInclude?: InputMaybe<Array<TypeOfSearchItem>>;
};


export type Query_SellerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SellerCountArgs = {
  distinctOn?: InputMaybe<SellerDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SellerFilterDTO>;
};


export type Query_SellersArgs = {
  distinctOn?: InputMaybe<SellerDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SellerSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SellerFilterDTO>;
};


export type Query_SmsNotificationSettingArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SmsNotificationSettingCountArgs = {
  distinctOn?: InputMaybe<SmsNotificationSettingDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SmsNotificationSettingFilterDTO>;
};


export type Query_SmsNotificationSettingsArgs = {
  distinctOn?: InputMaybe<SmsNotificationSettingDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SmsNotificationSettingSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SmsNotificationSettingFilterDTO>;
};


export type Query_SpreadInstrumentArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SpreadInstrumentCountArgs = {
  distinctOn?: InputMaybe<SpreadInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SpreadInstrumentFilterDTO>;
};


export type Query_SpreadInstrumentLegArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SpreadInstrumentLegCountArgs = {
  distinctOn?: InputMaybe<SpreadInstrumentLegDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SpreadInstrumentLegFilterDTO>;
};


export type Query_SpreadInstrumentLegsArgs = {
  distinctOn?: InputMaybe<SpreadInstrumentLegDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SpreadInstrumentLegSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SpreadInstrumentLegFilterDTO>;
};


export type Query_SpreadInstrumentsArgs = {
  distinctOn?: InputMaybe<SpreadInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SpreadInstrumentSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SpreadInstrumentFilterDTO>;
};


export type Query_StateArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_StateCountArgs = {
  distinctOn?: InputMaybe<StateDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<StateFilterDTO>;
};


export type Query_StatesArgs = {
  distinctOn?: InputMaybe<StateDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<StateSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<StateFilterDTO>;
};


export type Query_SwapArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SwapAccountArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SwapAccountCountArgs = {
  distinctOn?: InputMaybe<SwapAccountDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwapAccountFilterDTO>;
};


export type Query_SwapAccountsArgs = {
  distinctOn?: InputMaybe<SwapAccountDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SwapAccountSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwapAccountFilterDTO>;
};


export type Query_SwapCountArgs = {
  distinctOn?: InputMaybe<SwapInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwapFilterDTO>;
};


export type Query_SwapDealerArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SwapDealerCountArgs = {
  distinctOn?: InputMaybe<SwapDealerDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwapDealerFilterDTO>;
};


export type Query_SwapDealersArgs = {
  distinctOn?: InputMaybe<SwapDealerDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SwapDealerSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwapDealerFilterDTO>;
};


export type Query_SwapsArgs = {
  distinctOn?: InputMaybe<SwapInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SwapSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwapFilterDTO>;
};


export type Query_SwaptionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SwaptionCountArgs = {
  distinctOn?: InputMaybe<SwaptionInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwaptionFilterDTO>;
};


export type Query_SwaptionsArgs = {
  distinctOn?: InputMaybe<SwaptionInstrumentDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SwaptionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwaptionFilterDTO>;
};


export type Query_SwineLivestockGroupArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SwineLivestockGroupContractArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SwineLivestockGroupContractCountArgs = {
  distinctOn?: InputMaybe<SwineLivestockGroupContractDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwineLivestockGroupContractFilterDTO>;
};


export type Query_SwineLivestockGroupContractsArgs = {
  distinctOn?: InputMaybe<SwineLivestockGroupContractDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SwineLivestockGroupContractSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwineLivestockGroupContractFilterDTO>;
};


export type Query_SwineLivestockGroupCountArgs = {
  distinctOn?: InputMaybe<SwineLivestockGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwineLivestockGroupFilterDTO>;
};


export type Query_SwineLivestockGroupFeedUsageArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SwineLivestockGroupFeedUsageCountArgs = {
  distinctOn?: InputMaybe<SwineLivestockGroupFeedUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwineLivestockGroupFeedUsageFilterDTO>;
};


export type Query_SwineLivestockGroupFeedUsagesArgs = {
  distinctOn?: InputMaybe<SwineLivestockGroupFeedUsageDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SwineLivestockGroupFeedUsageSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwineLivestockGroupFeedUsageFilterDTO>;
};


export type Query_SwineLivestockGroupsArgs = {
  distinctOn?: InputMaybe<SwineLivestockGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SwineLivestockGroupSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwineLivestockGroupFilterDTO>;
};


export type Query_SwineLivestockPopulationChangeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SwineLivestockPopulationChangeCountArgs = {
  distinctOn?: InputMaybe<SwineLivestockPopulationChangeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwineLivestockPopulationChangeFilterDTO>;
};


export type Query_SwineLivestockPopulationChangesArgs = {
  distinctOn?: InputMaybe<SwineLivestockPopulationChangeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SwineLivestockPopulationChangeSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwineLivestockPopulationChangeFilterDTO>;
};


export type Query_SwineLivestockPopulationForecastByWeekArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_SwineLivestockPopulationForecastByWeekCountArgs = {
  distinctOn?: InputMaybe<SwineLivestockPopulationForecastByWeekDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwineLivestockPopulationForecastByWeekFilterDTO>;
};


export type Query_SwineLivestockPopulationForecastsByWeekArgs = {
  distinctOn?: InputMaybe<SwineLivestockPopulationForecastByWeekDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<SwineLivestockPopulationForecastByWeekSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<SwineLivestockPopulationForecastByWeekFilterDTO>;
};


export type Query_TransactionArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_TransactionCountArgs = {
  distinctOn?: InputMaybe<TransactionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<TransactionFilterDTO>;
};


export type Query_TransactionFeeArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_TransactionFeeCountArgs = {
  distinctOn?: InputMaybe<TransactionFeeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<TransactionFeeFilterDTO>;
};


export type Query_TransactionFeesArgs = {
  distinctOn?: InputMaybe<TransactionFeeDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<TransactionFeeSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<TransactionFeeFilterDTO>;
};


export type Query_TransactionGroupArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_TransactionGroupCountArgs = {
  distinctOn?: InputMaybe<TransactionGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<TransactionGroupFilterDTO>;
};


export type Query_TransactionGroupsArgs = {
  distinctOn?: InputMaybe<TransactionGroupDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<TransactionGroupSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<TransactionGroupFilterDTO>;
};


export type Query_TransactionNoteArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_TransactionNoteCountArgs = {
  distinctOn?: InputMaybe<TransactionNoteDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<TransactionNoteFilterDTO>;
};


export type Query_TransactionNotesArgs = {
  distinctOn?: InputMaybe<TransactionNoteDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<TransactionNoteSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<TransactionNoteFilterDTO>;
};


export type Query_TransactionsArgs = {
  distinctOn?: InputMaybe<TransactionDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<TransactionSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<TransactionFilterDTO>;
};


export type Query_UsdaActualMilkPriceArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaActualMilkPriceCountArgs = {
  distinctOn?: InputMaybe<UsdaActualMilkPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaActualMilkPriceFilterDTO>;
};


export type Query_UsdaActualMilkPricesArgs = {
  distinctOn?: InputMaybe<UsdaActualMilkPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaActualMilkPriceSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaActualMilkPriceFilterDTO>;
};


export type Query_UsdaFinalCheeseBlockPriceAndSalesArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaFinalCheeseBlockPriceAndSalesCountArgs = {
  distinctOn?: InputMaybe<UsdaFinalCheeseBlockPricesAndSalesDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaFinalCheeseBlockPricesAndSalesFilterDTO>;
};


export type Query_UsdaFinalCheeseBlockPricesAndSalesArgs = {
  distinctOn?: InputMaybe<UsdaFinalCheeseBlockPricesAndSalesDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaFinalCheeseBlockPricesAndSalesSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaFinalCheeseBlockPricesAndSalesFilterDTO>;
};


export type Query_UsdaFluidMilkAndCreamCentralUsReportArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaFluidMilkAndCreamCentralUsReportCountArgs = {
  distinctOn?: InputMaybe<UsdaFluidMilkAndCreamCentralUsDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaFluidMilkAndCreamCentralUsFilterDTO>;
};


export type Query_UsdaFluidMilkAndCreamCentralUsReportsArgs = {
  distinctOn?: InputMaybe<UsdaFluidMilkAndCreamCentralUsDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaFluidMilkAndCreamCentralUsSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaFluidMilkAndCreamCentralUsFilterDTO>;
};


export type Query_UsdaMarsReportArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaMarsReportCountArgs = {
  distinctOn?: InputMaybe<UsdaMarsReportDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaMarsReportFilterDTO>;
};


export type Query_UsdaMarsReportsArgs = {
  distinctOn?: InputMaybe<UsdaMarsReportDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaMarsReportSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaMarsReportFilterDTO>;
};


export type Query_UsdaMonthlyNationalAverageDairyPriceArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaMonthlyNationalAverageDairyPriceCountArgs = {
  distinctOn?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceFilterDTO>;
};


export type Query_UsdaMonthlyNationalAverageDairyPricesArgs = {
  distinctOn?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceFilterDTO>;
};


export type Query_UsdaMonthlyRegionalMilkComponentPriceArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaMonthlyRegionalMilkComponentPriceCountArgs = {
  distinctOn?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceFilterDTO>;
};


export type Query_UsdaMonthlyRegionalMilkComponentPricesArgs = {
  distinctOn?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceFilterDTO>;
};


export type Query_UsdaMonthlyRegionalMilkUtilizationArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaMonthlyRegionalMilkUtilizationCountArgs = {
  distinctOn?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationFilterDTO>;
};


export type Query_UsdaMonthlyRegionalMilkUtilizationsArgs = {
  distinctOn?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationSortByDTO>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationFilterDTO>;
};


export type Query_UsdaMprReportArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaMprReportCountArgs = {
  distinctOn?: InputMaybe<UsdaMprReportDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaMprReportFilterDTO>;
};


export type Query_UsdaMprReportsArgs = {
  distinctOn?: InputMaybe<UsdaMprReportDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaMprReportSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaMprReportFilterDTO>;
};


export type Query_UsdaRecordArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaRecordCountArgs = {
  distinctOn?: InputMaybe<UsdaRecordDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaRecordFilterDTO>;
};


export type Query_UsdaRecordsArgs = {
  distinctOn?: InputMaybe<UsdaRecordDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaRecordSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where: UsdaRecordFilterDTO;
};


export type Query_UsdaReportArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaReportCountArgs = {
  distinctOn?: InputMaybe<UsdaReportDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaReportFilterDTO>;
};


export type Query_UsdaReportsArgs = {
  distinctOn?: InputMaybe<UsdaReportDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaReportSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaReportFilterDTO>;
};


export type Query_UsdaRetailSummariesArgs = {
  distinctOn?: InputMaybe<UsdaRetailSummaryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<UsdaRetailSummarySortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaRetailSummaryFilterDTO>;
};


export type Query_UsdaRetailSummaryArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_UsdaRetailSummaryCountArgs = {
  distinctOn?: InputMaybe<UsdaRetailSummaryDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UsdaRetailSummaryFilterDTO>;
};


export type Query_UserArgs = {
  id: Scalars['String'];
};


export type Query_UsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserSortByDTO>;
  where?: InputMaybe<UserFilterDTO>;
};


export type Query_VersionedBusinessDataArgs = {
  distinctOn?: InputMaybe<VersionedBusinessDataDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<VersionedBusinessDataSortByDTO>;
  pagination?: InputMaybe<PaginationInput>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<VersionedBusinessDataFilterDTO>;
};


export type Query_VersionedBusinessDatumArgs = {
  id: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_VersionedBusinessDatumCountArgs = {
  distinctOn?: InputMaybe<VersionedBusinessDataDistinctOnDTO>;
  limit?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<VersionedBusinessDataFilterDTO>;
};


export type Query_feedIngredientPricesByMonthArgs = {
  data: FeedIngredientPriceByMonthInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_fetchDrpCoverageLevelsArgs = {
  where: CoverageLevelsRequestDTO;
};


export type Query_fetchDrpPracticesArgs = {
  where: PracticeRequestDTO;
};


export type Query_fetchDrpQuoteArgs = {
  where: QuoteRequestDTO;
};


export type Query_findAnonymousNotificationArgs = {
  id: Scalars['String'];
};


export type Query_getApplePushSubscriptionArgs = {
  deviceId: Scalars['String'];
};


export type Query_insuranceRatioScenarioPricingForFixedPriceSetsArgs = {
  input: InsuranceRatioScenarioPricingFixedPriceSetsInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_insuranceRatioScenarioPricingForFixedPricesArgs = {
  input: InsuranceRatioScenarioPricingFixedPriceInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_insuranceRatioScenarioPricingForFuturesCurveArgs = {
  input: InsuranceRatioScenarioPricingFuturesCurveInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_insuranceScenarioPricingForFixedPriceSetsArgs = {
  input: InsuranceScenarioPricingFixedPriceSetsInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_insuranceScenarioPricingForFixedPricesArgs = {
  input: InsuranceScenarioPricingFixedPriceInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_insuranceScenarioPricingForFuturesCurveArgs = {
  input: InsuranceScenarioPricingFuturesCurveInput;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type Query_userPermissionsForTargetArgs = {
  targetId: Scalars['String'];
};


export type Query_userPermissionsForTargetsArgs = {
  targetIds: Array<Scalars['String']>;
};

export type QuoteRequestDTO = {
  butterfat?: InputMaybe<Scalars['Float']>;
  classThree?: InputMaybe<Scalars['Float']>;
  componentWeightingFactor?: InputMaybe<Scalars['Float']>;
  coverageLevel: Scalars['Float'];
  declaredProduction: Scalars['Float'];
  practiceCode: Scalars['String'];
  protectionFactor: Scalars['Float'];
  protein?: InputMaybe<Scalars['Float']>;
  salesEffectiveDate?: InputMaybe<Scalars['String']>;
  stateAbbreviation: Scalars['String'];
};

export type RdrsOrganizationFilterDTO = {
  AND?: InputMaybe<Array<RdrsOrganizationFilterDTO>>;
  NOT?: InputMaybe<RdrsOrganizationFilterDTO>;
  OR?: InputMaybe<Array<RdrsOrganizationFilterDTO>>;
  OrganizationEntity?: InputMaybe<OrganizationEntityFilterDTO>;
  appUrl?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<NullableDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  organizationEntityId?: InputMaybe<NullableStringFilter>;
  stripeCustomerId?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Region = {
  __typename?: 'Region';
  createdAt: Scalars['DateTime'];
  geom: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RegionDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  geom?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RegionFilterDTO = {
  AND?: InputMaybe<Array<RegionFilterDTO>>;
  Counties?: InputMaybe<CountyFilterDTO>;
  MilkOrders?: InputMaybe<MilkOrderFilterDTO>;
  NOT?: InputMaybe<RegionFilterDTO>;
  OR?: InputMaybe<Array<RegionFilterDTO>>;
  States?: InputMaybe<StateFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  geom?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RegionGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  geom?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RegionSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  geom?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export enum RmaAipCode {
  Afbis = 'Afbis',
  Agrisompo = 'Agrisompo',
  Diversified = 'Diversified',
  Hudson = 'Hudson',
  Nau = 'Nau',
  ProAg = 'ProAg',
  RainAndHail = 'RainAndHail',
  Rcis = 'Rcis'
}

export type RmaAipCodeFilter = {
  equals?: InputMaybe<RmaAipCode>;
  in?: InputMaybe<Array<RmaAipCode>>;
  not?: InputMaybe<RmaAipCode>;
  notIn?: InputMaybe<Array<RmaAipCode>>;
};

export type RmaCommodity = {
  __typename?: 'RmaCommodity';
  RmaBusinessSummaries: Array<RmaSummaryOfBusiness>;
  RmaTypes: Array<RmaType>;
  annualPlantingCode: Scalars['String'];
  commodityAbbreviation: Scalars['String'];
  commodityCode: Scalars['String'];
  commodityCodeYear?: Maybe<Scalars['String']>;
  commodityName: Scalars['String'];
  commodityYear: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastReleasedDate?: Maybe<Scalars['String']>;
  recordCategoryCode: Scalars['String'];
  recordTypeCode: Scalars['String'];
  reinsuranceYear: Scalars['Int'];
  releasedDate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type RmaCommodity_RmaBusinessSummariesArgs = {
  orderBy?: InputMaybe<RmaSummaryOfBusinessSortByDTO>;
  where?: InputMaybe<RmaSummaryOfBusinessFilterDTO>;
};


export type RmaCommodity_RmaTypesArgs = {
  orderBy?: InputMaybe<RmaTypeSortByDTO>;
  where?: InputMaybe<RmaTypeFilterDTO>;
};

export type RmaCommodityDistinctOnDTO = {
  annualPlantingCode?: InputMaybe<Scalars['Boolean']>;
  commodityAbbreviation?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityCodeYear?: InputMaybe<Scalars['Boolean']>;
  commodityName?: InputMaybe<Scalars['Boolean']>;
  commodityYear?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deletedDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastReleasedDate?: InputMaybe<Scalars['Boolean']>;
  recordCategoryCode?: InputMaybe<Scalars['Boolean']>;
  recordTypeCode?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  releasedDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RmaCommodityFilterDTO = {
  AND?: InputMaybe<Array<RmaCommodityFilterDTO>>;
  LrpInsuranceEndorsements?: InputMaybe<LrpInsuranceEndorsementFilterDTO>;
  NOT?: InputMaybe<RmaCommodityFilterDTO>;
  OR?: InputMaybe<Array<RmaCommodityFilterDTO>>;
  RmaBusinessSummaries?: InputMaybe<RmaSummaryOfBusinessFilterDTO>;
  RmaTypes?: InputMaybe<RmaTypeFilterDTO>;
  annualPlantingCode?: InputMaybe<StringFilter>;
  commodityAbbreviation?: InputMaybe<StringFilter>;
  commodityCode?: InputMaybe<StringFilter>;
  commodityCodeYear?: InputMaybe<NullableStringFilter>;
  commodityName?: InputMaybe<StringFilter>;
  commodityYear?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  lastReleasedDate?: InputMaybe<NullableStringFilter>;
  recordCategoryCode?: InputMaybe<StringFilter>;
  recordTypeCode?: InputMaybe<StringFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  releasedDate?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RmaCommodityGroupByDTO = {
  annualPlantingCode?: InputMaybe<Scalars['Boolean']>;
  commodityAbbreviation?: InputMaybe<Scalars['Boolean']>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityCodeYear?: InputMaybe<Scalars['Boolean']>;
  commodityName?: InputMaybe<Scalars['Boolean']>;
  commodityYear?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deletedDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastReleasedDate?: InputMaybe<Scalars['Boolean']>;
  recordCategoryCode?: InputMaybe<Scalars['Boolean']>;
  recordTypeCode?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  releasedDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RmaCommoditySortByDTO = {
  annualPlantingCode?: InputMaybe<SortByDirection>;
  commodityAbbreviation?: InputMaybe<SortByDirection>;
  commodityCode?: InputMaybe<SortByDirection>;
  commodityCodeYear?: InputMaybe<NullableSortByDirection>;
  commodityName?: InputMaybe<SortByDirection>;
  commodityYear?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deletedDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  lastReleasedDate?: InputMaybe<NullableSortByDirection>;
  recordCategoryCode?: InputMaybe<SortByDirection>;
  recordTypeCode?: InputMaybe<SortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  releasedDate?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type RmaInsurancePlan = {
  __typename?: 'RmaInsurancePlan';
  createdAt: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurancePlanAbbreviation: Scalars['String'];
  insurancePlanCode: Scalars['String'];
  insurancePlanName: Scalars['String'];
  lastReleasedDate?: Maybe<Scalars['String']>;
  recordCategoryCode: Scalars['String'];
  recordTypeCode: Scalars['String'];
  reinsuranceYear: Scalars['Int'];
  releasedDate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type RmaInsurancePlanFilterDTO = {
  AND?: InputMaybe<Array<RmaInsurancePlanFilterDTO>>;
  NOT?: InputMaybe<RmaInsurancePlanFilterDTO>;
  OR?: InputMaybe<Array<RmaInsurancePlanFilterDTO>>;
  RmaBusinessSummaries?: InputMaybe<RmaSummaryOfBusinessFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  insurancePlanAbbreviation?: InputMaybe<StringFilter>;
  insurancePlanCode?: InputMaybe<StringFilter>;
  insurancePlanName?: InputMaybe<StringFilter>;
  lastReleasedDate?: InputMaybe<NullableStringFilter>;
  recordCategoryCode?: InputMaybe<StringFilter>;
  recordTypeCode?: InputMaybe<StringFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  releasedDate?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RmaInsurancePlanGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deletedDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  insurancePlanAbbreviation?: InputMaybe<Scalars['Boolean']>;
  insurancePlanCode?: InputMaybe<Scalars['Boolean']>;
  insurancePlanName?: InputMaybe<Scalars['Boolean']>;
  lastReleasedDate?: InputMaybe<Scalars['Boolean']>;
  recordCategoryCode?: InputMaybe<Scalars['Boolean']>;
  recordTypeCode?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  releasedDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RmaInsurancePlanSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  deletedDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  insurancePlanAbbreviation?: InputMaybe<SortByDirection>;
  insurancePlanCode?: InputMaybe<SortByDirection>;
  insurancePlanName?: InputMaybe<SortByDirection>;
  lastReleasedDate?: InputMaybe<NullableSortByDirection>;
  recordCategoryCode?: InputMaybe<SortByDirection>;
  recordTypeCode?: InputMaybe<SortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  releasedDate?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type RmaSummaryOfBusiness = {
  __typename?: 'RmaSummaryOfBusiness';
  Commodity: RmaCommodity;
  InsurancePlan: RmaInsurancePlan;
  State: State;
  commodityId: Scalars['String'];
  countyCode: Scalars['String'];
  coverageFlag: Scalars['String'];
  coverageLevel: Scalars['Int'];
  cropYear: Scalars['Int'];
  deliverySystem: Scalars['String'];
  id: Scalars['ID'];
  indemnity: Scalars['Int'];
  insurancePlanId: Scalars['String'];
  liability: Scalars['Int'];
  netAcres: Scalars['Int'];
  policiesEarningPremium: Scalars['Int'];
  policiesIndemnified: Scalars['Int'];
  policiesSold: Scalars['Int'];
  stateId: Scalars['String'];
  subsidy: Scalars['Int'];
  totalPremium: Scalars['Int'];
  unitsEarningPremium: Scalars['Int'];
  unitsIndemnified: Scalars['Int'];
};

export type RmaSummaryOfBusinessAggregateAllDTO = {
  commodityId?: InputMaybe<Scalars['Boolean']>;
  countyCode?: InputMaybe<Scalars['Boolean']>;
  coverageFlag?: InputMaybe<Scalars['Boolean']>;
  coverageLevel?: InputMaybe<Scalars['Boolean']>;
  cropYear?: InputMaybe<Scalars['Boolean']>;
  deliverySystem?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  insurancePlanId?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  netAcres?: InputMaybe<Scalars['Boolean']>;
  policiesEarningPremium?: InputMaybe<Scalars['Boolean']>;
  policiesIndemnified?: InputMaybe<Scalars['Boolean']>;
  policiesSold?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidy?: InputMaybe<Scalars['Boolean']>;
  totalPremium?: InputMaybe<Scalars['Boolean']>;
  unitsEarningPremium?: InputMaybe<Scalars['Boolean']>;
  unitsIndemnified?: InputMaybe<Scalars['Boolean']>;
};

export type RmaSummaryOfBusinessAggregateDTO = {
  avg?: InputMaybe<RmaSummaryOfBusinessAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<RmaSummaryOfBusinessAggregateAllDTO>;
  min?: InputMaybe<RmaSummaryOfBusinessAggregateAllDTO>;
  sum?: InputMaybe<RmaSummaryOfBusinessAggregateNumericDTO>;
};

export type RmaSummaryOfBusinessAggregateNumericDTO = {
  coverageLevel?: InputMaybe<Scalars['Boolean']>;
  cropYear?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  netAcres?: InputMaybe<Scalars['Boolean']>;
  policiesEarningPremium?: InputMaybe<Scalars['Boolean']>;
  policiesIndemnified?: InputMaybe<Scalars['Boolean']>;
  policiesSold?: InputMaybe<Scalars['Boolean']>;
  subsidy?: InputMaybe<Scalars['Boolean']>;
  totalPremium?: InputMaybe<Scalars['Boolean']>;
  unitsEarningPremium?: InputMaybe<Scalars['Boolean']>;
  unitsIndemnified?: InputMaybe<Scalars['Boolean']>;
};

export type RmaSummaryOfBusinessDistinctOnDTO = {
  commodityId?: InputMaybe<Scalars['Boolean']>;
  countyCode?: InputMaybe<Scalars['Boolean']>;
  coverageFlag?: InputMaybe<Scalars['Boolean']>;
  coverageLevel?: InputMaybe<Scalars['Boolean']>;
  cropYear?: InputMaybe<Scalars['Boolean']>;
  deliverySystem?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  insurancePlanId?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  netAcres?: InputMaybe<Scalars['Boolean']>;
  policiesEarningPremium?: InputMaybe<Scalars['Boolean']>;
  policiesIndemnified?: InputMaybe<Scalars['Boolean']>;
  policiesSold?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidy?: InputMaybe<Scalars['Boolean']>;
  totalPremium?: InputMaybe<Scalars['Boolean']>;
  unitsEarningPremium?: InputMaybe<Scalars['Boolean']>;
  unitsIndemnified?: InputMaybe<Scalars['Boolean']>;
};

export type RmaSummaryOfBusinessFilterDTO = {
  AND?: InputMaybe<Array<RmaSummaryOfBusinessFilterDTO>>;
  Commodity?: InputMaybe<RmaCommodityFilterDTO>;
  InsurancePlan?: InputMaybe<RmaInsurancePlanFilterDTO>;
  NOT?: InputMaybe<RmaSummaryOfBusinessFilterDTO>;
  OR?: InputMaybe<Array<RmaSummaryOfBusinessFilterDTO>>;
  State?: InputMaybe<StateFilterDTO>;
  commodityId?: InputMaybe<StringFilter>;
  countyCode?: InputMaybe<StringFilter>;
  coverageFlag?: InputMaybe<StringFilter>;
  coverageLevel?: InputMaybe<NumberFilter>;
  cropYear?: InputMaybe<NumberFilter>;
  deliverySystem?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  indemnity?: InputMaybe<NumberFilter>;
  insurancePlanId?: InputMaybe<StringFilter>;
  liability?: InputMaybe<NumberFilter>;
  netAcres?: InputMaybe<NumberFilter>;
  policiesEarningPremium?: InputMaybe<NumberFilter>;
  policiesIndemnified?: InputMaybe<NumberFilter>;
  policiesSold?: InputMaybe<NumberFilter>;
  stateId?: InputMaybe<StringFilter>;
  subsidy?: InputMaybe<NumberFilter>;
  totalPremium?: InputMaybe<NumberFilter>;
  unitsEarningPremium?: InputMaybe<NumberFilter>;
  unitsIndemnified?: InputMaybe<NumberFilter>;
};

export type RmaSummaryOfBusinessGroupByDTO = {
  Commodity?: InputMaybe<RmaCommodityGroupByDTO>;
  InsurancePlan?: InputMaybe<RmaInsurancePlanGroupByDTO>;
  State?: InputMaybe<StateGroupByDTO>;
  commodityId?: InputMaybe<Scalars['Boolean']>;
  countyCode?: InputMaybe<Scalars['Boolean']>;
  coverageFlag?: InputMaybe<Scalars['Boolean']>;
  coverageLevel?: InputMaybe<Scalars['Boolean']>;
  cropYear?: InputMaybe<Scalars['Boolean']>;
  deliverySystem?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  indemnity?: InputMaybe<Scalars['Boolean']>;
  insurancePlanId?: InputMaybe<Scalars['Boolean']>;
  liability?: InputMaybe<Scalars['Boolean']>;
  netAcres?: InputMaybe<Scalars['Boolean']>;
  policiesEarningPremium?: InputMaybe<Scalars['Boolean']>;
  policiesIndemnified?: InputMaybe<Scalars['Boolean']>;
  policiesSold?: InputMaybe<Scalars['Boolean']>;
  stateId?: InputMaybe<Scalars['Boolean']>;
  subsidy?: InputMaybe<Scalars['Boolean']>;
  totalPremium?: InputMaybe<Scalars['Boolean']>;
  unitsEarningPremium?: InputMaybe<Scalars['Boolean']>;
  unitsIndemnified?: InputMaybe<Scalars['Boolean']>;
};

export type RmaSummaryOfBusinessSortByDTO = {
  Commodity?: InputMaybe<RmaCommoditySortByDTO>;
  InsurancePlan?: InputMaybe<RmaInsurancePlanSortByDTO>;
  State?: InputMaybe<StateSortByDTO>;
  commodityId?: InputMaybe<SortByDirection>;
  countyCode?: InputMaybe<SortByDirection>;
  coverageFlag?: InputMaybe<SortByDirection>;
  coverageLevel?: InputMaybe<SortByDirection>;
  cropYear?: InputMaybe<SortByDirection>;
  deliverySystem?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  indemnity?: InputMaybe<SortByDirection>;
  insurancePlanId?: InputMaybe<SortByDirection>;
  liability?: InputMaybe<SortByDirection>;
  netAcres?: InputMaybe<SortByDirection>;
  policiesEarningPremium?: InputMaybe<SortByDirection>;
  policiesIndemnified?: InputMaybe<SortByDirection>;
  policiesSold?: InputMaybe<SortByDirection>;
  stateId?: InputMaybe<SortByDirection>;
  subsidy?: InputMaybe<SortByDirection>;
  totalPremium?: InputMaybe<SortByDirection>;
  unitsEarningPremium?: InputMaybe<SortByDirection>;
  unitsIndemnified?: InputMaybe<SortByDirection>;
};

export type RmaType = {
  __typename?: 'RmaType';
  RmaCommodity: RmaCommodity;
  commodityCode: Scalars['String'];
  commodityId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastReleasedDate?: Maybe<Scalars['String']>;
  recordCategoryCode: Scalars['String'];
  recordTypeCode: Scalars['String'];
  reinsuranceYear: Scalars['Int'];
  releasedDate?: Maybe<Scalars['String']>;
  typeAbbreviation: Scalars['String'];
  typeCode: Scalars['String'];
  typeCodeYear?: Maybe<Scalars['String']>;
  typeName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RmaTypeDistinctOnDTO = {
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deletedDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastReleasedDate?: InputMaybe<Scalars['Boolean']>;
  recordCategoryCode?: InputMaybe<Scalars['Boolean']>;
  recordTypeCode?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  releasedDate?: InputMaybe<Scalars['Boolean']>;
  typeAbbreviation?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  typeName?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RmaTypeFilterDTO = {
  AND?: InputMaybe<Array<RmaTypeFilterDTO>>;
  InsuranceEndorsements?: InputMaybe<InsuranceEndorsementFilterDTO>;
  LgmInsuranceEndorsements?: InputMaybe<LgmInsuranceEndorsementFilterDTO>;
  LrpInsuranceEndorsements?: InputMaybe<LrpInsuranceEndorsementFilterDTO>;
  NOT?: InputMaybe<RmaTypeFilterDTO>;
  OR?: InputMaybe<Array<RmaTypeFilterDTO>>;
  RmaCommodity?: InputMaybe<RmaCommodityFilterDTO>;
  commodityCode?: InputMaybe<StringFilter>;
  commodityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  lastReleasedDate?: InputMaybe<NullableStringFilter>;
  recordCategoryCode?: InputMaybe<StringFilter>;
  recordTypeCode?: InputMaybe<StringFilter>;
  reinsuranceYear?: InputMaybe<NumberFilter>;
  releasedDate?: InputMaybe<NullableStringFilter>;
  typeAbbreviation?: InputMaybe<StringFilter>;
  typeCode?: InputMaybe<StringFilter>;
  typeCodeYear?: InputMaybe<NullableStringFilter>;
  typeName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RmaTypeGroupByDTO = {
  RmaCommodity?: InputMaybe<RmaCommodityGroupByDTO>;
  commodityCode?: InputMaybe<Scalars['Boolean']>;
  commodityId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deletedDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastReleasedDate?: InputMaybe<Scalars['Boolean']>;
  recordCategoryCode?: InputMaybe<Scalars['Boolean']>;
  recordTypeCode?: InputMaybe<Scalars['Boolean']>;
  reinsuranceYear?: InputMaybe<Scalars['Boolean']>;
  releasedDate?: InputMaybe<Scalars['Boolean']>;
  typeAbbreviation?: InputMaybe<Scalars['Boolean']>;
  typeCode?: InputMaybe<Scalars['Boolean']>;
  typeCodeYear?: InputMaybe<Scalars['Boolean']>;
  typeName?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type RmaTypeSortByDTO = {
  RmaCommodity?: InputMaybe<RmaCommoditySortByDTO>;
  commodityCode?: InputMaybe<SortByDirection>;
  commodityId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deletedDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  lastReleasedDate?: InputMaybe<NullableSortByDirection>;
  recordCategoryCode?: InputMaybe<SortByDirection>;
  recordTypeCode?: InputMaybe<SortByDirection>;
  reinsuranceYear?: InputMaybe<SortByDirection>;
  releasedDate?: InputMaybe<NullableSortByDirection>;
  typeAbbreviation?: InputMaybe<SortByDirection>;
  typeCode?: InputMaybe<SortByDirection>;
  typeCodeYear?: InputMaybe<NullableSortByDirection>;
  typeName?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type SearchItem = {
  __typename?: 'SearchItem';
  attributes: Scalars['Object'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: TypeOfSearchItem;
};

export type Seller = {
  __typename?: 'Seller';
  GrainOrders: Array<IGrainOrder>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type Seller_GrainOrdersArgs = {
  orderBy?: InputMaybe<GrainOrderSortByDTO>;
  where?: InputMaybe<GrainOrderFilterDTO>;
};

export type SellerCreateDTO = {
  name: Scalars['String'];
};

export type SellerDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SellerFilterDTO = {
  AND?: InputMaybe<Array<SellerFilterDTO>>;
  GrainOrders?: InputMaybe<GrainOrderFilterDTO>;
  NOT?: InputMaybe<SellerFilterDTO>;
  OR?: InputMaybe<Array<SellerFilterDTO>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SellerGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SellerSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type SellerUpdateDTO = {
  name?: InputMaybe<Scalars['String']>;
};

export enum Side {
  Closed = 'Closed',
  Long = 'Long',
  Short = 'Short'
}

export type SideFilter = {
  equals?: InputMaybe<Side>;
  in?: InputMaybe<Array<Side>>;
  not?: InputMaybe<Side>;
  notIn?: InputMaybe<Array<Side>>;
};

export type SmsNotificationSetting = INotificationSetting & {
  __typename?: 'SmsNotificationSetting';
  Notification: Array<Notification>;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  type: TypeOfNotification;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};


export type SmsNotificationSetting_NotificationArgs = {
  orderBy?: InputMaybe<NotificationSortByDTO>;
  where?: InputMaybe<NotificationFilterDTO>;
};

export type SmsNotificationSettingCreateDTO = {
  name?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type SmsNotificationSettingDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

export type SmsNotificationSettingFilterDTO = {
  AND?: InputMaybe<Array<SmsNotificationSettingFilterDTO>>;
  AsNotificationSetting?: InputMaybe<NotificationSettingFilterDTO>;
  NOT?: InputMaybe<SmsNotificationSettingFilterDTO>;
  Notifications?: InputMaybe<NotificationFilterDTO>;
  OR?: InputMaybe<Array<SmsNotificationSettingFilterDTO>>;
  User?: InputMaybe<UserFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<NullableStringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<NullableStringFilter>;
};

export type SmsNotificationSettingSortByDTO = {
  AsNotificationSetting?: InputMaybe<NotificationSettingSortByDTO>;
  User?: InputMaybe<UserSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  phoneNumber?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  userId?: InputMaybe<NullableSortByDirection>;
};

export type SmsNotificationSettingUpdateDTO = {
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export enum SortByDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SpreadInstrument = IInstrument & {
  __typename?: 'SpreadInstrument';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Legs: Array<SpreadInstrumentLeg>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  barchartSymbol?: Maybe<Scalars['String']>;
  cqgSymbol?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayExpiresAt: Scalars['String'];
  exchangeSymbol?: Maybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  isStandardContractSize: Scalars['Boolean'];
  lastTradeAt: Scalars['DateTime'];
  name: Scalars['String'];
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  spreadType: TypeOfSpread;
  type: TypeOfInstrument;
  updatedAt: Scalars['DateTime'];
};


export type SpreadInstrument_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type SpreadInstrument_LegsArgs = {
  orderBy?: InputMaybe<SpreadInstrumentLegSortByDTO>;
  where?: InputMaybe<SpreadInstrumentLegFilterDTO>;
};


export type SpreadInstrument_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type SpreadInstrument_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type SpreadInstrument_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type SpreadInstrumentCreateDTO = {
  barchartSymbol?: InputMaybe<Scalars['String']>;
  cqgSymbol?: InputMaybe<Scalars['String']>;
  displayExpiresAt: Scalars['String'];
  exchangeSymbol?: InputMaybe<Scalars['String']>;
  expiresAt: Scalars['DateTime'];
  lastTradeAt: Scalars['DateTime'];
  name: Scalars['String'];
  productId: Scalars['String'];
  spreadType: TypeOfSpread;
};

export type SpreadInstrumentDistinctOnDTO = {
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  spreadType?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SpreadInstrumentFilterDTO = {
  AND?: InputMaybe<Array<SpreadInstrumentFilterDTO>>;
  AsInstrument?: InputMaybe<InstrumentFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  DerivedSpreadLegs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  LegacyFeeds?: InputMaybe<LegacyFeedFilterDTO>;
  LegacyScenarioData?: InputMaybe<LegacyScenarioDataFilterDTO>;
  Legs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  NOT?: InputMaybe<SpreadInstrumentFilterDTO>;
  OR?: InputMaybe<Array<SpreadInstrumentFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationFilterDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  barchartSymbol?: InputMaybe<NullableStringFilter>;
  cqgSymbol?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayExpiresAt?: InputMaybe<StringFilter>;
  exchangeSymbol?: InputMaybe<NullableStringFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentSymbolGroupId?: InputMaybe<StringFilter>;
  isStandardContractSize?: InputMaybe<BooleanFilter>;
  lastTradeAt?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  productId?: InputMaybe<StringFilter>;
  productLotSpecificationId?: InputMaybe<StringFilter>;
  spreadType?: InputMaybe<TypeOfSpreadFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SpreadInstrumentGroupByDTO = {
  AsInstrument?: InputMaybe<InstrumentGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationGroupByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  barchartSymbol?: InputMaybe<Scalars['Boolean']>;
  cqgSymbol?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  exchangeSymbol?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  lastTradeAt?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  spreadType?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SpreadInstrumentLeg = {
  __typename?: 'SpreadInstrumentLeg';
  Instrument: IInstrument;
  SpreadInstrument: SpreadInstrument;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  instrumentId: Scalars['String'];
  quantity: Scalars['Int'];
  spreadInstrumentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SpreadInstrumentLegCreateDTO = {
  instrumentId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
  spreadInstrumentId: Scalars['String'];
};

export type SpreadInstrumentLegDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  spreadInstrumentId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SpreadInstrumentLegFilterDTO = {
  AND?: InputMaybe<Array<SpreadInstrumentLegFilterDTO>>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  OR?: InputMaybe<Array<SpreadInstrumentLegFilterDTO>>;
  SpreadInstrument?: InputMaybe<SpreadInstrumentFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<NumberFilter>;
  spreadInstrumentId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SpreadInstrumentLegSortByDTO = {
  Instrument?: InputMaybe<InstrumentSortByDTO>;
  SpreadInstrument?: InputMaybe<SpreadInstrumentSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  instrumentId?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  spreadInstrumentId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type SpreadInstrumentLegUpdateDTO = {
  instrumentId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  spreadInstrumentId?: InputMaybe<Scalars['String']>;
};

export type SpreadInstrumentSortByDTO = {
  AsInstrument?: InputMaybe<InstrumentSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationSortByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  barchartSymbol?: InputMaybe<NullableSortByDirection>;
  cqgSymbol?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  displayExpiresAt?: InputMaybe<SortByDirection>;
  exchangeSymbol?: InputMaybe<NullableSortByDirection>;
  expiresAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentSymbolGroupId?: InputMaybe<SortByDirection>;
  isStandardContractSize?: InputMaybe<SortByDirection>;
  lastTradeAt?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productLotSpecificationId?: InputMaybe<SortByDirection>;
  spreadType?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type SpreadInstrumentUpdateDTO = {
  barchartSymbol?: InputMaybe<Scalars['String']>;
  cqgSymbol?: InputMaybe<Scalars['String']>;
  displayExpiresAt?: InputMaybe<Scalars['String']>;
  exchangeSymbol?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  lastTradeAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  spreadType?: InputMaybe<TypeOfSpread>;
};

export type State = {
  __typename?: 'State';
  Region?: Maybe<Region>;
  abbreviation: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId?: Maybe<Scalars['String']>;
  rmaStateCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type StateDistinctOnDTO = {
  abbreviation?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  regionId?: InputMaybe<Scalars['Boolean']>;
  rmaStateCode?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type StateFilterDTO = {
  AND?: InputMaybe<Array<StateFilterDTO>>;
  Counties?: InputMaybe<CountyFilterDTO>;
  GrainBuyerLocations?: InputMaybe<GrainBuyerLocationFilterDTO>;
  InsuranceAgencies?: InputMaybe<InsuranceAgencyFilterDTO>;
  InsuranceEndorsements?: InputMaybe<InsuranceEndorsementFilterDTO>;
  InsuranceHistoricalPremiums?: InputMaybe<InsuranceHistoricalPremiumFilterDTO>;
  InsurancePolicies?: InputMaybe<InsurancePolicyFilterDTO>;
  NOT?: InputMaybe<StateFilterDTO>;
  OR?: InputMaybe<Array<StateFilterDTO>>;
  RmaBusinessSummaries?: InputMaybe<RmaSummaryOfBusinessFilterDTO>;
  abbreviation?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  region?: InputMaybe<RegionFilterDTO>;
  regionId?: InputMaybe<NullableStringFilter>;
  rmaStateCode?: InputMaybe<NullableStringFilter>;
  stateCode?: InputMaybe<NullableNumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StateGroupByDTO = {
  abbreviation?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<RegionGroupByDTO>;
  regionId?: InputMaybe<Scalars['Boolean']>;
  rmaStateCode?: InputMaybe<Scalars['Boolean']>;
  stateCode?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type StateSortByDTO = {
  abbreviation?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  region?: InputMaybe<RegionSortByDTO>;
  regionId?: InputMaybe<NullableSortByDirection>;
  rmaStateCode?: InputMaybe<NullableSortByDirection>;
  stateCode?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type StaticPositionFilterDTO = {
  AND?: InputMaybe<Array<StaticPositionFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  EntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<StaticPositionFilterDTO>;
  OR?: InputMaybe<Array<StaticPositionFilterDTO>>;
  accountId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  quantity?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StringArrayFilter = {
  /** Matches rows where the column contains ALL values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  contains?: InputMaybe<Array<Scalars['String']>>;
  /** Matches rows where the column array is an exact match to the given filter (order & duplicates matter!) */
  equals?: InputMaybe<Array<Scalars['String']>>;
  /** Matches rows where the column is contained within the values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  isContainedBy?: InputMaybe<Array<Scalars['String']>>;
  /** Filter results by the number of values contained within this array. `null` values will always be filtered out (even with a length filter = 0) */
  length?: InputMaybe<NumberFilter>;
  not?: InputMaybe<StringArrayFilter>;
  /** Matches rows where the column contains any of the values included within this filter */
  overlapsWith?: InputMaybe<Array<Scalars['String']>>;
};

export type StringFilter = {
  /** A case-insensitive filter for values that contain with the given string. _ and % may be provided as single and multi character wildcards, respectively. */
  contains?: InputMaybe<Scalars['String']>;
  /** A case-insensitive filter for values that end with the given string. _ and % may be provided as single and multi character wildcards, respectively. */
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<StringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Returns true or false depending on whether its pattern matches the given string. The given string pattern is interpreted using the SQL standard's definition of a regular expression. This operator only returns true if its pattern matches the entire string. Like LIKE, _ and % are used as wildcard characters denoting any single character and any string, respectively. */
  similar?: InputMaybe<Scalars['String']>;
  /** A case-insensitive filter for values that start with the given string. _ and % may be provided as single and multi character wildcards, respectively. */
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Swap = IInstrument & {
  __typename?: 'Swap';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  PriceInstrument?: Maybe<IInstrument>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  createdAt: Scalars['DateTime'];
  displayExpiresAt: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  isStandardContractSize: Scalars['Boolean'];
  name: Scalars['String'];
  priceInstrumentId?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  settlementInstrumentId?: Maybe<Scalars['String']>;
  type: TypeOfInstrument;
  updatedAt: Scalars['DateTime'];
};


export type Swap_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type Swap_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type Swap_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type Swap_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type SwapAccount = IAccount & {
  __typename?: 'SwapAccount';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  CurrentPositions: Array<CurrentPosition>;
  Customer?: Maybe<CustomerEntity>;
  Notes: Array<AccountNote>;
  Orders: Array<Order>;
  /** @deprecated Use "Owner" instead */
  Organization?: Maybe<OrganizationEntity>;
  Owner?: Maybe<IEntity>;
  SwapDealer: SwapDealer;
  Transactions: Array<Transaction>;
  accountNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  inputSource: InputSource;
  integrationInstallationId?: Maybe<Scalars['String']>;
  isClosed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  swapDealerId: Scalars['String'];
  type: TypeOfAccount;
  updatedAt: Scalars['DateTime'];
};


export type SwapAccount_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type SwapAccount_CurrentPositionsArgs = {
  orderBy?: InputMaybe<CurrentPositionSortByDTO>;
  where?: InputMaybe<CurrentPositionFilterDTO>;
};


export type SwapAccount_NotesArgs = {
  orderBy?: InputMaybe<AccountNoteSortByDTO>;
  where?: InputMaybe<AccountNoteFilterDTO>;
};


export type SwapAccount_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type SwapAccount_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type SwapAccountCreateDTO = {
  accountNumber?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  swapDealerId: Scalars['String'];
};

export type SwapAccountDistinctOnDTO = {
  accountNumber?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  swapDealerId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwapAccountFilterDTO = {
  AND?: InputMaybe<Array<SwapAccountFilterDTO>>;
  AccountFiles?: InputMaybe<AccountFileFilterDTO>;
  AccountNotes?: InputMaybe<AccountNoteFilterDTO>;
  AsAccount?: InputMaybe<AccountFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  Customer?: InputMaybe<CustomerEntityFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  IntegrationMappings?: InputMaybe<IntegrationMappingFilterDTO>;
  NOT?: InputMaybe<SwapAccountFilterDTO>;
  OR?: InputMaybe<Array<SwapAccountFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  Owner?: InputMaybe<EntityFilterDTO>;
  PositionComponent?: InputMaybe<PositionComponentFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  SwapDealer?: InputMaybe<SwapDealerFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  accountNumber?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  isClosed?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<NullableStringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  swapDealerId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SwapAccountGroupByDTO = {
  AsAccount?: InputMaybe<AccountGroupByDTO>;
  Customer?: InputMaybe<CustomerEntityGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  Owner?: InputMaybe<EntityGroupByDTO>;
  OwnerAsCustomerEntity?: InputMaybe<CustomerEntityGroupByDTO>;
  OwnerAsEntityGroup?: InputMaybe<EntityGroupGroupByDTO>;
  OwnerAsLocationEntity?: InputMaybe<LocationEntityGroupByDTO>;
  OwnerAsOrganizationEntity?: InputMaybe<OrganizationEntityGroupByDTO>;
  SwapDealer?: InputMaybe<SwapDealerGroupByDTO>;
  accountNumber?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  customerId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Boolean']>;
  swapDealerId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwapAccountSortByDTO = {
  AsAccount?: InputMaybe<AccountSortByDTO>;
  Customer?: InputMaybe<CustomerEntitySortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  Owner?: InputMaybe<EntitySortByDTO>;
  SwapDealer?: InputMaybe<SwapDealerSortByDTO>;
  accountNumber?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  customerId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  isClosed?: InputMaybe<SortByDirection>;
  name?: InputMaybe<NullableSortByDirection>;
  ownerId?: InputMaybe<SortByDirection>;
  swapDealerId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type SwapAccountUpdateDTO = {
  accountNumber?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  swapDealerId?: InputMaybe<Scalars['String']>;
};

export type SwapCreateDTO = {
  displayExpiresAt: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  name: Scalars['String'];
  priceInstrumentId?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
};

export type SwapDealer = {
  __typename?: 'SwapDealer';
  SwapAccounts: Array<SwapAccount>;
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type SwapDealer_SwapAccountsArgs = {
  orderBy?: InputMaybe<SwapAccountSortByDTO>;
  where?: InputMaybe<SwapAccountFilterDTO>;
};

export type SwapDealerCreateDTO = {
  externalId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type SwapDealerDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwapDealerFilterDTO = {
  AND?: InputMaybe<Array<SwapDealerFilterDTO>>;
  NOT?: InputMaybe<SwapDealerFilterDTO>;
  OR?: InputMaybe<Array<SwapDealerFilterDTO>>;
  SwapAccounts?: InputMaybe<SwapAccountFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  externalId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SwapDealerGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwapDealerSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type SwapDealerUpdateDTO = {
  externalId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type SwapFilterDTO = {
  AND?: InputMaybe<Array<SwapFilterDTO>>;
  AsInstrument?: InputMaybe<InstrumentFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  DerivedSpreadLegs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  LegacyFeeds?: InputMaybe<LegacyFeedFilterDTO>;
  LegacyScenarioData?: InputMaybe<LegacyScenarioDataFilterDTO>;
  NOT?: InputMaybe<SwapFilterDTO>;
  OR?: InputMaybe<Array<SwapFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  PriceInstrument?: InputMaybe<InstrumentFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationFilterDTO>;
  SettlementInstrument?: InputMaybe<FutureFilterDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayExpiresAt?: InputMaybe<StringFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentSymbolGroupId?: InputMaybe<StringFilter>;
  isStandardContractSize?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  priceInstrumentId?: InputMaybe<NullableStringFilter>;
  productId?: InputMaybe<StringFilter>;
  productLotSpecificationId?: InputMaybe<StringFilter>;
  settlementInstrumentId?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SwapGroupByDTO = {
  AsInstrument?: InputMaybe<InstrumentGroupByDTO>;
  PriceInstrument?: InputMaybe<InstrumentGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationGroupByDTO>;
  SettlementInstrument?: InputMaybe<FutureGroupByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  priceInstrumentId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  settlementInstrumentId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwapInstrumentDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  priceInstrumentId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  settlementInstrumentId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwapSortByDTO = {
  AsInstrument?: InputMaybe<InstrumentSortByDTO>;
  PriceInstrument?: InputMaybe<InstrumentSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationSortByDTO>;
  SettlementInstrument?: InputMaybe<FutureSortByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  displayExpiresAt?: InputMaybe<SortByDirection>;
  expiresAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentSymbolGroupId?: InputMaybe<SortByDirection>;
  isStandardContractSize?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  priceInstrumentId?: InputMaybe<NullableSortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productLotSpecificationId?: InputMaybe<SortByDirection>;
  settlementInstrumentId?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type Swaption = IInstrument & {
  __typename?: 'Swaption';
  CurrentAllocationPositions: Array<CurrentAllocationPosition>;
  Orders: Array<Order>;
  PhysicalSales: Array<PhysicalSale>;
  PriceInstrument?: Maybe<IInstrument>;
  Product: Product;
  SymbolGroup: InstrumentSymbolGroup;
  Transactions: Array<Transaction>;
  createdAt: Scalars['DateTime'];
  displayExpiresAt: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentSymbolGroupId: Scalars['String'];
  isStandardContractSize: Scalars['Boolean'];
  name: Scalars['String'];
  optionType: TypeOfOption;
  priceInstrumentId?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  productLotSpecificationId: Scalars['String'];
  settlementInstrumentId?: Maybe<Scalars['String']>;
  strike: Scalars['Float'];
  type: TypeOfInstrument;
  updatedAt: Scalars['DateTime'];
};


export type Swaption_CurrentAllocationPositionsArgs = {
  orderBy?: InputMaybe<CurrentAllocationPositionSortByDTO>;
  where?: InputMaybe<CurrentAllocationPositionFilterDTO>;
};


export type Swaption_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type Swaption_PhysicalSalesArgs = {
  orderBy?: InputMaybe<PhysicalSaleSortByDTO>;
  where?: InputMaybe<PhysicalSaleFilterDTO>;
};


export type Swaption_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type SwaptionCreateDTO = {
  displayExpiresAt: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  name: Scalars['String'];
  optionType: TypeOfOption;
  priceInstrumentId?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  strike: Scalars['Float'];
};

export type SwaptionFilterDTO = {
  AND?: InputMaybe<Array<SwaptionFilterDTO>>;
  AsInstrument?: InputMaybe<InstrumentFilterDTO>;
  CurrentAllocationPositions?: InputMaybe<CurrentAllocationPositionFilterDTO>;
  CurrentPositionComponents?: InputMaybe<CurrentPositionComponentFilterDTO>;
  CurrentPositions?: InputMaybe<CurrentPositionFilterDTO>;
  DerivedSpreadLegs?: InputMaybe<SpreadInstrumentLegFilterDTO>;
  HedgeStrategyCurrentPositions?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  ImportedPositions?: InputMaybe<StaticPositionFilterDTO>;
  LegacyFeeds?: InputMaybe<LegacyFeedFilterDTO>;
  LegacyScenarioData?: InputMaybe<LegacyScenarioDataFilterDTO>;
  NOT?: InputMaybe<SwaptionFilterDTO>;
  OR?: InputMaybe<Array<SwaptionFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  PhysicalSales?: InputMaybe<PhysicalSaleFilterDTO>;
  PositionComponentAllocations?: InputMaybe<PositionComponentAllocationFilterDTO>;
  PositionComponents?: InputMaybe<PositionComponentFilterDTO>;
  PositionReconciliations?: InputMaybe<PositionReconciliationFilterDTO>;
  PriceInstrument?: InputMaybe<InstrumentFilterDTO>;
  Product?: InputMaybe<ProductFilterDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationFilterDTO>;
  SettlementInstrument?: InputMaybe<OptionFilterDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayExpiresAt?: InputMaybe<StringFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentSymbolGroupId?: InputMaybe<StringFilter>;
  isStandardContractSize?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  optionType?: InputMaybe<TypeOfOptionFilter>;
  priceInstrumentId?: InputMaybe<NullableStringFilter>;
  productId?: InputMaybe<StringFilter>;
  productLotSpecificationId?: InputMaybe<StringFilter>;
  settlementInstrumentId?: InputMaybe<NullableStringFilter>;
  strike?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SwaptionGroupByDTO = {
  AsInstrument?: InputMaybe<InstrumentGroupByDTO>;
  PriceInstrument?: InputMaybe<InstrumentGroupByDTO>;
  Product?: InputMaybe<ProductGroupByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationGroupByDTO>;
  SettlementInstrument?: InputMaybe<OptionGroupByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  priceInstrumentId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  settlementInstrumentId?: InputMaybe<Scalars['Boolean']>;
  strike?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwaptionInstrumentDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  displayExpiresAt?: InputMaybe<Scalars['Boolean']>;
  expiresAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentSymbolGroupId?: InputMaybe<Scalars['Boolean']>;
  isStandardContractSize?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  optionType?: InputMaybe<Scalars['Boolean']>;
  priceInstrumentId?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['Boolean']>;
  productLotSpecificationId?: InputMaybe<Scalars['Boolean']>;
  settlementInstrumentId?: InputMaybe<Scalars['Boolean']>;
  strike?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwaptionSortByDTO = {
  AsInstrument?: InputMaybe<InstrumentSortByDTO>;
  PriceInstrument?: InputMaybe<InstrumentSortByDTO>;
  Product?: InputMaybe<ProductSortByDTO>;
  ProductLotSpecification?: InputMaybe<ProductLotSpecificationSortByDTO>;
  SettlementInstrument?: InputMaybe<OptionSortByDTO>;
  SymbolGroup?: InputMaybe<InstrumentSymbolGroupSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  displayExpiresAt?: InputMaybe<SortByDirection>;
  expiresAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentSymbolGroupId?: InputMaybe<SortByDirection>;
  isStandardContractSize?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  optionType?: InputMaybe<SortByDirection>;
  priceInstrumentId?: InputMaybe<NullableSortByDirection>;
  productId?: InputMaybe<SortByDirection>;
  productLotSpecificationId?: InputMaybe<SortByDirection>;
  settlementInstrumentId?: InputMaybe<NullableSortByDirection>;
  strike?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type SwineLivestockGroup = ILivestockGroup & {
  __typename?: 'SwineLivestockGroup';
  Business: CustomerEntity;
  FeedUsages: Array<ILivestockGroupFeedUsage>;
  SwineLivestockPopulationForecastsByWeek: SwineLivestockPopulationForecastByWeek;
  Version: LivestockGroupVersion;
  /** The ID of the business to which this livestock group belongs. */
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The proportion of individuals which are expected to die over the age span this group represents. Ex. a value of 0.03 would mean that 3% of individuals are expected to die between the time they are minAgeInWeeks old and maxAgeInWeeks old. */
  deathRate: Scalars['Float'];
  /** The optional description of the livestock group. */
  description?: Maybe<Scalars['String']>;
  /** The number of weeks contained by this group, inclusive of the min & max. e.g. A max of 25 and a min of 21 would have a duration of 5 */
  durationInWeeks: Scalars['Int'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The maximum age in weeks for membership in this group. Ex. a value of 20 would mean an animal 21 weeks old or older would not be a member of this group, but an animal 20 weeks old would be. Must be greater than minAgeInWeeks. */
  maxAgeInWeeks: Scalars['Int'];
  /** The minimum age in weeks for membership in this group. Ex. a value a 4 would mean an animal 3 weeks old or younger would not be a member of this group, but an animal 4 weeks old would be. Must be less than maxAgeInWeeks. */
  minAgeInWeeks: Scalars['Int'];
  /** The name of the livestock group. */
  name: Scalars['String'];
  type: TypeOfLivestockGroup;
  updatedAt: Scalars['DateTime'];
  /** The version in which this LivestockGroup is a part of. The version will contain information regarding whether or not this swine group is "current" as well as the startDate & endDate in which it is in effect for data forcasting */
  versionId: Scalars['String'];
  /** A UUID that will be shared across all feed ingredients that have been copied from each other upon the creation of a new version. */
  versionedConceptSeriesId: Scalars['String'];
};


export type SwineLivestockGroup_FeedUsagesArgs = {
  orderBy?: InputMaybe<LivestockGroupFeedUsageSortByDTO>;
  where?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
};


export type SwineLivestockGroup_SwineLivestockPopulationForecastsByWeekArgs = {
  orderBy?: InputMaybe<SwineLivestockPopulationForecastByWeekSortByDTO>;
  where?: InputMaybe<SwineLivestockPopulationForecastByWeekFilterDTO>;
};

export type SwineLivestockGroupContract = {
  __typename?: 'SwineLivestockGroupContract';
  Business: CustomerEntity;
  /** The user that initially created this contract. This value is nullable in case the user is deleted. */
  CreatedByUser?: Maybe<User>;
  /** The user that last updated this contract. This value is nullable in case the user is deleted. */
  LastUpdatedByUser?: Maybe<User>;
  /** The period allocations for this contract, as appropriate for the periodAllocationType between the contract start and end dates */
  LivestockGroupContractPeriodAllocations: Array<LivestockGroupContractPeriodAllocation>;
  /** @deprecated This field has been moved to LivestockGroupContractPeriodAllocation */
  allocatedQuantity: Scalars['Float'];
  /** The basis type that will be applied to this contract's pricing mechanism. This value will be null only when forecastPricingType is flat price, otherwise it will be required. */
  basisPricingType?: Maybe<TypeOfContractBasis>;
  businessId: Scalars['String'];
  /** The quantity head of swine contracted for sale */
  contractedQuantity: Scalars['Int'];
  /** The name of the contractee / contract counterparty */
  contractee: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The user that initially created this contract. This value is nullable in case the user is deleted. */
  createdByUserId?: Maybe<Scalars['String']>;
  /** The last date for which this contract is in effect, inclusive. Also known as the "expiration date" */
  endDate: Scalars['String'];
  /** The pricing mechanism used by this contract */
  forecastPricingType: TypeOfContractPricing;
  /** The pricing value of this contract. If forecastPricingType is flat price, this value will be the flat price per lb, otherwise this value will be a basis value. */
  forecastPricingValue: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** A boolean indicating if the endDate is prior to the current date */
  isExpired: Scalars['Boolean'];
  /** The user that last updated this contract. This value is nullable in case the user is deleted. */
  lastUpdatedByUserId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** A note / comment on this contract. This is the only field within this mdoel that can be updated via graphql. */
  note: Scalars['String'];
  /** @deprecated This field has been moved to LivestockGroupContractPeriodAllocation */
  openQuantity: Scalars['Float'];
  /** The interval for which the contracted quantity can be allocated to this contract. For example, a periodAllocationType of Weekly indicate that the contract's contractedQuantity can be allocated in once per week. This field may also be referred to as the delivery frequency of a contract. */
  periodAllocationType: TypeOfContractPeriodAllocation;
  /** @deprecated This field has been moved to LivestockGroupContractPeriodAllocation */
  readableAllocatedQuantity: Scalars['Int'];
  /** @deprecated This field has been moved to LivestockGroupContractPeriodAllocation */
  readableOpenQuantity: Scalars['Int'];
  /** The first date upon which this contract is in effect, inclusive */
  startDate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type SwineLivestockGroupContract_LivestockGroupContractPeriodAllocationsArgs = {
  orderBy?: InputMaybe<LivestockGroupContractPeriodAllocationSortByDTO>;
  where?: InputMaybe<LivestockGroupContractPeriodAllocationFilterDTO>;
};

export type SwineLivestockGroupContractCreateDTO = {
  basisPricingType?: InputMaybe<TypeOfContractBasis>;
  businessId: Scalars['String'];
  contractedQuantity: Scalars['Int'];
  contractee: Scalars['String'];
  endDate: Scalars['String'];
  forecastPricingType: TypeOfContractPricing;
  forecastPricingValue: Scalars['Float'];
  note?: InputMaybe<Scalars['String']>;
  periodAllocationType?: InputMaybe<TypeOfContractPeriodAllocation>;
  startDate: Scalars['String'];
};

export type SwineLivestockGroupContractDistinctOnDTO = {
  allocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  basisPricingType?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractedQuantity?: InputMaybe<Scalars['Boolean']>;
  contractee?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  forecastPricingType?: InputMaybe<Scalars['Boolean']>;
  forecastPricingValue?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['Boolean']>;
  openQuantity?: InputMaybe<Scalars['Boolean']>;
  periodAllocationType?: InputMaybe<Scalars['Boolean']>;
  readableAllocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  readableOpenQuantity?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockGroupContractFilterDTO = {
  AND?: InputMaybe<Array<SwineLivestockGroupContractFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  LivestockGroupContractPeriodAllocations?: InputMaybe<LivestockGroupContractPeriodAllocationFilterDTO>;
  NOT?: InputMaybe<SwineLivestockGroupContractFilterDTO>;
  OR?: InputMaybe<Array<SwineLivestockGroupContractFilterDTO>>;
  allocatedQuantity?: InputMaybe<NumberFilter>;
  basisPricingType?: InputMaybe<NullableTypeOfContractBasisFilter>;
  businessId?: InputMaybe<StringFilter>;
  contractedQuantity?: InputMaybe<NumberFilter>;
  contractee?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<StringFilter>;
  forecastPricingType?: InputMaybe<TypeOfContractPricingFilter>;
  forecastPricingValue?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  isExpired?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  name?: InputMaybe<StringFilter>;
  note?: InputMaybe<StringFilter>;
  openQuantity?: InputMaybe<NumberFilter>;
  periodAllocationType?: InputMaybe<TypeOfContractPeriodAllocationFilter>;
  readableAllocatedQuantity?: InputMaybe<NumberFilter>;
  readableOpenQuantity?: InputMaybe<NumberFilter>;
  startDate?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SwineLivestockGroupContractGroupByDTO = {
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  allocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  basisPricingType?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractedQuantity?: InputMaybe<Scalars['Boolean']>;
  contractee?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  forecastPricingType?: InputMaybe<Scalars['Boolean']>;
  forecastPricingValue?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isExpired?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['Boolean']>;
  openQuantity?: InputMaybe<Scalars['Boolean']>;
  periodAllocationType?: InputMaybe<Scalars['Boolean']>;
  readableAllocatedQuantity?: InputMaybe<Scalars['Boolean']>;
  readableOpenQuantity?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockGroupContractSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  allocatedQuantity?: InputMaybe<SortByDirection>;
  basisPricingType?: InputMaybe<NullableSortByDirection>;
  businessId?: InputMaybe<SortByDirection>;
  contractedQuantity?: InputMaybe<SortByDirection>;
  contractee?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<SortByDirection>;
  forecastPricingType?: InputMaybe<SortByDirection>;
  forecastPricingValue?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isExpired?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<SortByDirection>;
  note?: InputMaybe<SortByDirection>;
  openQuantity?: InputMaybe<SortByDirection>;
  periodAllocationType?: InputMaybe<SortByDirection>;
  readableAllocatedQuantity?: InputMaybe<SortByDirection>;
  readableOpenQuantity?: InputMaybe<SortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type SwineLivestockGroupContractUpdateDTO = {
  /** A note / comment on this contract. This is the only field within this mdoel that can be updated via graphql. */
  note?: InputMaybe<Scalars['String']>;
};

export type SwineLivestockGroupDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deathRate?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  durationInWeeks?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  maxAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  minAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockGroupFeedUsage = ILivestockGroupFeedUsage & {
  __typename?: 'SwineLivestockGroupFeedUsage';
  Business: CustomerEntity;
  FeedIngredient: FeedIngredient;
  LivestockGroup: ILivestockGroup;
  Version: LivestockGroupFeedUsageVersion;
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  feedIngredientId: Scalars['String'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  livestockGroupId: Scalars['String'];
  type: TypeOfLivestockGroupFeedUsage;
  updatedAt: Scalars['DateTime'];
  usageInLbsPerHead: Scalars['Float'];
  /** computed as (usageInLbsPerHead/2000) */
  usageInTonsPerHead: Scalars['Float'];
  /** The version of feedUsage that this LivestockGroupFeedUsage uses. The version will contain information regarding whether or not this usage is "current" as well as the startDate & endDate in which it is in effect for data forcasting */
  versionId: Scalars['String'];
  /** A UUID that will be shared across all feed ingredients that have been copied from each other upon the creation of a new version. */
  versionedConceptSeriesId: Scalars['String'];
};

export type SwineLivestockGroupFeedUsageDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  livestockGroupId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  usageInLbsPerHead?: InputMaybe<Scalars['Boolean']>;
  usageInTonsPerHead?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockGroupFeedUsageFilterDTO = {
  AND?: InputMaybe<Array<SwineLivestockGroupFeedUsageFilterDTO>>;
  AsLivestockGroupFeedUsage?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientFilterDTO>;
  ForecastedLivestockFeedUsages?: InputMaybe<ForecastedLivestockFeedUsageFilterDTO>;
  LivestockGroup?: InputMaybe<LivestockGroupFilterDTO>;
  NOT?: InputMaybe<SwineLivestockGroupFeedUsageFilterDTO>;
  OR?: InputMaybe<Array<SwineLivestockGroupFeedUsageFilterDTO>>;
  Version?: InputMaybe<LivestockGroupFeedUsageVersionFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  feedIngredientId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  livestockGroupId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  usageInLbsPerHead?: InputMaybe<NumberFilter>;
  usageInTonsPerHead?: InputMaybe<NumberFilter>;
  versionId?: InputMaybe<StringFilter>;
  versionedConceptSeriesId?: InputMaybe<StringFilter>;
};

export type SwineLivestockGroupFeedUsageGroupByDTO = {
  AsLivestockGroupFeedUsage?: InputMaybe<LivestockGroupFeedUsageGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientGroupByDTO>;
  LivestockGroup?: InputMaybe<LivestockGroupGroupByDTO>;
  LivestockGroupAsSwineLivestockGroup?: InputMaybe<SwineLivestockGroupGroupByDTO>;
  Version?: InputMaybe<LivestockGroupFeedUsageVersionGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  feedIngredientId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  livestockGroupId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  usageInLbsPerHead?: InputMaybe<Scalars['Boolean']>;
  usageInTonsPerHead?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockGroupFeedUsageSortByDTO = {
  AsLivestockGroupFeedUsage?: InputMaybe<LivestockGroupFeedUsageSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  FeedIngredient?: InputMaybe<FeedIngredientSortByDTO>;
  LivestockGroup?: InputMaybe<LivestockGroupSortByDTO>;
  Version?: InputMaybe<LivestockGroupFeedUsageVersionSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  feedIngredientId?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  livestockGroupId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  usageInLbsPerHead?: InputMaybe<SortByDirection>;
  usageInTonsPerHead?: InputMaybe<SortByDirection>;
  versionId?: InputMaybe<SortByDirection>;
  versionedConceptSeriesId?: InputMaybe<SortByDirection>;
};

export type SwineLivestockGroupFilterDTO = {
  AND?: InputMaybe<Array<SwineLivestockGroupFilterDTO>>;
  AsLivestockGroup?: InputMaybe<LivestockGroupFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  LivestockGroupFeedUsages?: InputMaybe<LivestockGroupFeedUsageFilterDTO>;
  LivestockGroupVersion?: InputMaybe<LivestockGroupVersionFilterDTO>;
  NOT?: InputMaybe<SwineLivestockGroupFilterDTO>;
  OR?: InputMaybe<Array<SwineLivestockGroupFilterDTO>>;
  SwineLivestockPopulationForecastsByWeek?: InputMaybe<SwineLivestockPopulationForecastByWeekFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deathRate?: InputMaybe<NumberFilter>;
  description?: InputMaybe<NullableStringFilter>;
  durationInWeeks?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  maxAgeInWeeks?: InputMaybe<NumberFilter>;
  minAgeInWeeks?: InputMaybe<NumberFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  versionId?: InputMaybe<StringFilter>;
  versionedConceptSeriesId?: InputMaybe<StringFilter>;
};

export type SwineLivestockGroupGroupByDTO = {
  AsLivestockGroup?: InputMaybe<LivestockGroupGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  LivestockGroupVersion?: InputMaybe<LivestockGroupVersionGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deathRate?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  durationInWeeks?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  maxAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  minAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockGroupSortByDTO = {
  AsLivestockGroup?: InputMaybe<LivestockGroupSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  LivestockGroupVersion?: InputMaybe<LivestockGroupVersionSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  deathRate?: InputMaybe<SortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  durationInWeeks?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  maxAgeInWeeks?: InputMaybe<SortByDirection>;
  minAgeInWeeks?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  versionId?: InputMaybe<SortByDirection>;
  versionedConceptSeriesId?: InputMaybe<SortByDirection>;
};

export type SwineLivestockPopulationChange = ILivestockPopulationChange & {
  __typename?: 'SwineLivestockPopulationChange';
  Business: CustomerEntity;
  businessId: Scalars['String'];
  /** The livestock group contract period allocation unto which this population change has been added. This value will only be set for forecasted sales. */
  contractPeriodAllocationId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** The date of the population change event. For ACTUAL rows, this date must be a Sunday. This is enforced with a constraint. */
  date: Scalars['String'];
  /** The date of birth for the cohort this row describes. This date must be a Sunday. This is enforced with a constraint. */
  dob: Scalars['String'];
  /** Generated field, The absolute INT of the week within the year in which these livestock were born */
  dobWeek: Scalars['Int'];
  exactQuantity: Scalars['Float'];
  /** Generated field, (exactQuantity * valuePerHead) */
  exactTotalValue: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Generated field, The first sunday of the week calculated from the value stored in 'date' */
  marketingWeekStartDate: Scalars['String'];
  /** The rounded quantity of population that has changed */
  quantity: Scalars['Int'];
  /** Generated field, A readable version of 'dobWeek' formatted "W[N] YYYY" */
  readableDobWeek: Scalars['String'];
  /** Generated field, A readable version of 'week' formatted "W[N] YYYY" */
  readableWeek: Scalars['String'];
  /** A custom description detailing the reason for this change, if applicable */
  reason?: Maybe<Scalars['String']>;
  reasonType: TypeOfLivestockPopulationChangeReason;
  startOfMonth: Scalars['String'];
  /** Intended to be populated when there is an issue related to the population change. */
  systemComment?: Maybe<Scalars['String']>;
  /** Generated field, (quantity * valuePerHead) */
  totalValue: Scalars['Float'];
  type: TypeOfLivestockPopulationChange;
  updatedAt: Scalars['DateTime'];
  valuePerHead: Scalars['Float'];
  valueType: TypeOfLivestockPopulationChangeValue;
  /** Generated field, The absolute INT of the week within the year in which this population change event occurred */
  week: Scalars['Int'];
};

export type SwineLivestockPopulationChangeAggregateAllDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractPeriodAllocationId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  dobWeek?: InputMaybe<Scalars['Boolean']>;
  exactQuantity?: InputMaybe<Scalars['Boolean']>;
  exactTotalValue?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  marketingWeekStartDate?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  readableDobWeek?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['Boolean']>;
  startOfMonth?: InputMaybe<Scalars['Boolean']>;
  systemComment?: InputMaybe<Scalars['Boolean']>;
  totalValue?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  valuePerHead?: InputMaybe<Scalars['Boolean']>;
  week?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockPopulationChangeAggregateDTO = {
  avg?: InputMaybe<SwineLivestockPopulationChangeAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<SwineLivestockPopulationChangeAggregateAllDTO>;
  min?: InputMaybe<SwineLivestockPopulationChangeAggregateAllDTO>;
  sum?: InputMaybe<SwineLivestockPopulationChangeAggregateNumericDTO>;
};

export type SwineLivestockPopulationChangeAggregateNumericDTO = {
  dobWeek?: InputMaybe<Scalars['Boolean']>;
  exactQuantity?: InputMaybe<Scalars['Boolean']>;
  exactTotalValue?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  totalValue?: InputMaybe<Scalars['Boolean']>;
  valuePerHead?: InputMaybe<Scalars['Boolean']>;
  week?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockPopulationChangeDistinctOnDTO = {
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractPeriodAllocationId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  dobWeek?: InputMaybe<Scalars['Boolean']>;
  exactQuantity?: InputMaybe<Scalars['Boolean']>;
  exactTotalValue?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  marketingWeekStartDate?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  readableDobWeek?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['Boolean']>;
  reasonType?: InputMaybe<Scalars['Boolean']>;
  startOfMonth?: InputMaybe<Scalars['Boolean']>;
  systemComment?: InputMaybe<Scalars['Boolean']>;
  totalValue?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  valuePerHead?: InputMaybe<Scalars['Boolean']>;
  valueType?: InputMaybe<Scalars['Boolean']>;
  week?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockPopulationChangeFilterDTO = {
  AND?: InputMaybe<Array<SwineLivestockPopulationChangeFilterDTO>>;
  AsLivestockPopulationChange?: InputMaybe<LivestockPopulationChangeFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  ContractPeriodAllocation?: InputMaybe<LivestockGroupContractPeriodAllocationFilterDTO>;
  NOT?: InputMaybe<SwineLivestockPopulationChangeFilterDTO>;
  OR?: InputMaybe<Array<SwineLivestockPopulationChangeFilterDTO>>;
  businessId?: InputMaybe<StringFilter>;
  contractPeriodAllocationId?: InputMaybe<NullableStringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  dob?: InputMaybe<StringFilter>;
  dobWeek?: InputMaybe<NumberFilter>;
  exactQuantity?: InputMaybe<NumberFilter>;
  exactTotalValue?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  marketingWeekStartDate?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<NumberFilter>;
  readableDobWeek?: InputMaybe<StringFilter>;
  readableWeek?: InputMaybe<StringFilter>;
  reason?: InputMaybe<NullableStringFilter>;
  reasonType?: InputMaybe<TypeOfLivestockPopulationChangeReasonFilter>;
  startOfMonth?: InputMaybe<StringFilter>;
  systemComment?: InputMaybe<NullableStringFilter>;
  totalValue?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  valuePerHead?: InputMaybe<NumberFilter>;
  valueType?: InputMaybe<TypeOfLivestockPopulationChangeValueFilter>;
  week?: InputMaybe<NumberFilter>;
};

export type SwineLivestockPopulationChangeGroupByDTO = {
  AsLivestockPopulationChange?: InputMaybe<LivestockPopulationChangeGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  ContractPeriodAllocation?: InputMaybe<LivestockGroupContractPeriodAllocationGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  contractPeriodAllocationId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  dobWeek?: InputMaybe<Scalars['Boolean']>;
  exactQuantity?: InputMaybe<Scalars['Boolean']>;
  exactTotalValue?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  marketingWeekStartDate?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  readableDobWeek?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  reason?: InputMaybe<Scalars['Boolean']>;
  reasonType?: InputMaybe<Scalars['Boolean']>;
  startOfMonth?: InputMaybe<Scalars['Boolean']>;
  systemComment?: InputMaybe<Scalars['Boolean']>;
  totalValue?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  valuePerHead?: InputMaybe<Scalars['Boolean']>;
  valueType?: InputMaybe<Scalars['Boolean']>;
  week?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockPopulationChangeSet = {
  businessId: Scalars['String'];
  dates: Array<LivestockPopulationChangeSetDates>;
  producedValuePerHead: Scalars['Float'];
  purchasedValuePerHead: Scalars['Float'];
  quantityProduced: Scalars['Int'];
  quantityPurchased: Scalars['Int'];
};

export type SwineLivestockPopulationChangeSetResults = {
  __typename?: 'SwineLivestockPopulationChangeSetResults';
  set: Array<LivestockPopulationChange>;
  /** Previous changes that were set to zero by the set operation */
  unset: Array<LivestockPopulationChange>;
};

export type SwineLivestockPopulationChangeSortByDTO = {
  AsLivestockPopulationChange?: InputMaybe<LivestockPopulationChangeSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  ContractPeriodAllocation?: InputMaybe<LivestockGroupContractPeriodAllocationSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  contractPeriodAllocationId?: InputMaybe<NullableSortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  dob?: InputMaybe<SortByDirection>;
  dobWeek?: InputMaybe<SortByDirection>;
  exactQuantity?: InputMaybe<SortByDirection>;
  exactTotalValue?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  marketingWeekStartDate?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  readableDobWeek?: InputMaybe<SortByDirection>;
  readableWeek?: InputMaybe<SortByDirection>;
  reason?: InputMaybe<NullableSortByDirection>;
  reasonType?: InputMaybe<SortByDirection>;
  startOfMonth?: InputMaybe<SortByDirection>;
  systemComment?: InputMaybe<NullableSortByDirection>;
  totalValue?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  valuePerHead?: InputMaybe<SortByDirection>;
  valueType?: InputMaybe<SortByDirection>;
  week?: InputMaybe<SortByDirection>;
};

export type SwineLivestockPopulationForecastByWeek = {
  __typename?: 'SwineLivestockPopulationForecastByWeek';
  Business: CustomerEntity;
  PopulationChanges: Array<SwineLivestockPopulationChange>;
  SwineLivestockGroup: SwineLivestockGroup;
  /** Generated based on "dob". This value will have a starting index of 1; e.g. the age in weeks in the week of the dob will be 1 */
  ageInWeeks: Scalars['Int'];
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** The first date of the week in YYYY-MM-DD format, using Sundays. */
  date: Scalars['String'];
  dob: Scalars['String'];
  /** The quantity as of the end of the week, rounded to the nearest Int */
  endQuantity: Scalars['Int'];
  /** The exact quantity at the end of the week. This value may be a float, and should not be rendered directly in the UI. */
  exactEndQuantity: Scalars['Float'];
  /** The exact quantity at the start of the week. This value may be a float, and should not be rendered directly in the UI. */
  exactStartQuantity: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  livestockGroupId: Scalars['String'];
  /** Contains the week in a "W[N] [YYYY]" format */
  readableWeek: Scalars['String'];
  /** The quantity as of the start of the week, rounded to the nearest Int */
  startQuantity: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type SwineLivestockPopulationForecastByWeekDistinctOnDTO = {
  ageInWeeks?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  dob?: InputMaybe<Scalars['Boolean']>;
  endQuantity?: InputMaybe<Scalars['Boolean']>;
  exactEndQuantity?: InputMaybe<Scalars['Boolean']>;
  exactStartQuantity?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  livestockGroupId?: InputMaybe<Scalars['Boolean']>;
  readableWeek?: InputMaybe<Scalars['Boolean']>;
  startQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type SwineLivestockPopulationForecastByWeekFilterDTO = {
  AND?: InputMaybe<Array<SwineLivestockPopulationForecastByWeekFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  NOT?: InputMaybe<SwineLivestockPopulationForecastByWeekFilterDTO>;
  OR?: InputMaybe<Array<SwineLivestockPopulationForecastByWeekFilterDTO>>;
  SwineLivestockGroup?: InputMaybe<SwineLivestockGroupFilterDTO>;
  ageInWeeks?: InputMaybe<NumberFilter>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  dob?: InputMaybe<StringFilter>;
  endQuantity?: InputMaybe<NumberFilter>;
  exactEndQuantity?: InputMaybe<NumberFilter>;
  exactStartQuantity?: InputMaybe<NumberFilter>;
  id?: InputMaybe<StringFilter>;
  livestockGroupId?: InputMaybe<StringFilter>;
  readableWeek?: InputMaybe<StringFilter>;
  startQuantity?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SwineLivestockPopulationForecastByWeekSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  SwineLivestockGroup?: InputMaybe<SwineLivestockGroupSortByDTO>;
  ageInWeeks?: InputMaybe<SortByDirection>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  dob?: InputMaybe<SortByDirection>;
  endQuantity?: InputMaybe<SortByDirection>;
  exactEndQuantity?: InputMaybe<SortByDirection>;
  exactStartQuantity?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  livestockGroupId?: InputMaybe<SortByDirection>;
  readableWeek?: InputMaybe<SortByDirection>;
  startQuantity?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export enum TimeInForce {
  Day = 'Day',
  Fok = 'Fok',
  Gtc = 'Gtc'
}

export type TimeInForceFilter = {
  equals?: InputMaybe<TimeInForce>;
  in?: InputMaybe<Array<TimeInForce>>;
  not?: InputMaybe<TimeInForce>;
  notIn?: InputMaybe<Array<TimeInForce>>;
};

export type TradingSession = {
  __typename?: 'TradingSession';
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type Transaction = {
  __typename?: 'Transaction';
  Account: IAccount;
  Fees: Array<TransactionFee>;
  HedgeStrategy?: Maybe<HedgeStrategy>;
  Instrument: IInstrument;
  Notes: Array<TransactionNote>;
  Order?: Maybe<Order>;
  TransactionGroup?: Maybe<TransactionGroup>;
  accountId: Scalars['String'];
  commissionAndFeeTotal: Scalars['Float'];
  commissionTotal: Scalars['Float'];
  /** The number of exchange contracts purchased or sold via this transaction. */
  contractQuantity: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  executionType: TypeOfTransactionExecution;
  feeTotal: Scalars['Float'];
  hasWriteAccess: Scalars['Boolean'];
  hedgeStrategyCurrentPositionId: Scalars['String'];
  hedgeStrategyId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inputSource: InputSource;
  instrumentId: Scalars['String'];
  integrationInstallationId?: Maybe<Scalars['String']>;
  occurredAt?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['String']>;
  positionId: Scalars['String'];
  price: Scalars['Float'];
  /**
   * If this transaction is for a swap[tion] instrument, the quantity will be the unitQuantity. If this transaction is for a future or option instrument, the quantity will be the contractQuantity.
   * @deprecated Use contractQuantity and/or unitQuantity instead.
   */
  quantity: Scalars['Float'];
  /** quantity * price */
  total: Scalars['Float'];
  tradeDate?: Maybe<Scalars['String']>;
  tradeMonth?: Maybe<Scalars['Int']>;
  tradeYear?: Maybe<Scalars['Int']>;
  transactionGroupId?: Maybe<Scalars['String']>;
  /** The volume of units purchased or sold via this transaction. What this value represents is dependent upon the product and/or instrument symbol group for unto which the instrument belongs. For example, a transaction for a CME cheese future with a contractQuantity of 2 would have a unitQuantity of 40,000 (20,000 lbs per contract). */
  unitQuantity: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};


export type Transaction_FeesArgs = {
  orderBy?: InputMaybe<TransactionFeeSortByDTO>;
  where?: InputMaybe<TransactionFeeFilterDTO>;
};


export type Transaction_NotesArgs = {
  orderBy?: InputMaybe<TransactionNoteSortByDTO>;
  where?: InputMaybe<TransactionNoteFilterDTO>;
};

export type TransactionAggregateAllDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyCurrentPositionId?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  occurredAt?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  total?: InputMaybe<Scalars['Boolean']>;
  tradeDate?: InputMaybe<Scalars['Boolean']>;
  tradeMonth?: InputMaybe<Scalars['Boolean']>;
  tradeYear?: InputMaybe<Scalars['Boolean']>;
  transactionGroupId?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionAggregateDTO = {
  avg?: InputMaybe<TransactionAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<TransactionAggregateAllDTO>;
  min?: InputMaybe<TransactionAggregateAllDTO>;
  sum?: InputMaybe<TransactionAggregateNumericDTO>;
};

export type TransactionAggregateNumericDTO = {
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  total?: InputMaybe<Scalars['Boolean']>;
  tradeMonth?: InputMaybe<Scalars['Boolean']>;
  tradeYear?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionCreateDTO = {
  TransactionFees?: InputMaybe<Array<TransactionFeeForTransactionCreateDTO>>;
  accountId: Scalars['String'];
  contractQuantity?: InputMaybe<Scalars['Float']>;
  executionType?: InputMaybe<TypeOfTransactionExecution>;
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  instrumentId: Scalars['String'];
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  orderId?: InputMaybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity?: InputMaybe<Scalars['Float']>;
  tradeDate?: InputMaybe<Scalars['String']>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
  unitQuantity?: InputMaybe<Scalars['Float']>;
};

export type TransactionDistinctOnDTO = {
  accountId?: InputMaybe<Scalars['Boolean']>;
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  executionType?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyCurrentPositionId?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  occurredAt?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  total?: InputMaybe<Scalars['Boolean']>;
  tradeDate?: InputMaybe<Scalars['Boolean']>;
  tradeMonth?: InputMaybe<Scalars['Boolean']>;
  tradeYear?: InputMaybe<Scalars['Boolean']>;
  transactionGroupId?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionFee = {
  __typename?: 'TransactionFee';
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  inputSource: InputSource;
  integrationInstallationId?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  type: TypeOfTransactionFee;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type TransactionFeeAggregateAllDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  transactionId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionFeeAggregateDTO = {
  avg?: InputMaybe<TransactionFeeAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<TransactionFeeAggregateAllDTO>;
  min?: InputMaybe<TransactionFeeAggregateAllDTO>;
  sum?: InputMaybe<TransactionFeeAggregateNumericDTO>;
};

export type TransactionFeeAggregateNumericDTO = {
  value?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionFeeCreateDTO = {
  transactionId: Scalars['String'];
  type: TypeOfTransactionFee;
  value: Scalars['Float'];
};

export type TransactionFeeDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  transactionId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionFeeFilterDTO = {
  AND?: InputMaybe<Array<TransactionFeeFilterDTO>>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  NOT?: InputMaybe<TransactionFeeFilterDTO>;
  OR?: InputMaybe<Array<TransactionFeeFilterDTO>>;
  Transaction?: InputMaybe<TransactionFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  transactionId?: InputMaybe<StringFilter>;
  type?: InputMaybe<TypeOfTransactionFeeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<NumberFilter>;
};

export type TransactionFeeForTransactionCreateDTO = {
  type: TypeOfTransactionFee;
  value: Scalars['Float'];
};

export type TransactionFeeGroupByDTO = {
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  Transaction?: InputMaybe<TransactionGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  transactionId?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionFeeSortByDTO = {
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  Transaction?: InputMaybe<TransactionSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  transactionId?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  value?: InputMaybe<SortByDirection>;
};

export type TransactionFeeUpdateDTO = {
  transactionId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TypeOfTransactionFee>;
  value?: InputMaybe<Scalars['Float']>;
};

export type TransactionFilterDTO = {
  AND?: InputMaybe<Array<TransactionFilterDTO>>;
  Account?: InputMaybe<AccountFilterDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionFilterDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationFilterDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyFilterDTO>;
  HedgeStrategyCurrentPosition?: InputMaybe<HedgeStrategyCurrentPositionFilterDTO>;
  Instrument?: InputMaybe<InstrumentFilterDTO>;
  NOT?: InputMaybe<TransactionFilterDTO>;
  OR?: InputMaybe<Array<TransactionFilterDTO>>;
  Order?: InputMaybe<OrderFilterDTO>;
  PositionReconciliation?: InputMaybe<PositionReconciliationFilterDTO>;
  TransactionFees?: InputMaybe<TransactionFeeFilterDTO>;
  TransactionGroup?: InputMaybe<TransactionGroupFilterDTO>;
  TransactionNotes?: InputMaybe<TransactionNoteFilterDTO>;
  accountId?: InputMaybe<StringFilter>;
  commissionAndFeeTotal?: InputMaybe<NumberFilter>;
  commissionTotal?: InputMaybe<NumberFilter>;
  contractQuantity?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  executionType?: InputMaybe<TypeOfTransactionExecutionFilter>;
  feeTotal?: InputMaybe<NumberFilter>;
  hedgeStrategyCurrentPositionId?: InputMaybe<StringFilter>;
  hedgeStrategyId?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  inputSource?: InputMaybe<InputSourceFilter>;
  instrumentId?: InputMaybe<StringFilter>;
  integrationInstallationId?: InputMaybe<NullableStringFilter>;
  occurredAt?: InputMaybe<NullableDateTimeFilter>;
  orderId?: InputMaybe<NullableStringFilter>;
  positionId?: InputMaybe<StringFilter>;
  price?: InputMaybe<NumberFilter>;
  quantity?: InputMaybe<NumberFilter>;
  total?: InputMaybe<NumberFilter>;
  tradeDate?: InputMaybe<NullableStringFilter>;
  tradeMonth?: InputMaybe<NullableNumberFilter>;
  tradeYear?: InputMaybe<NullableNumberFilter>;
  transactionGroupId?: InputMaybe<NullableStringFilter>;
  unitQuantity?: InputMaybe<NumberFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TransactionGroup = {
  __typename?: 'TransactionGroup';
  Orders: Array<Order>;
  Transactions: IInstrument;
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type TransactionGroup_OrdersArgs = {
  orderBy?: InputMaybe<OrderSortByDTO>;
  where?: InputMaybe<OrderFilterDTO>;
};


export type TransactionGroup_TransactionsArgs = {
  orderBy?: InputMaybe<TransactionSortByDTO>;
  where?: InputMaybe<TransactionFilterDTO>;
};

export type TransactionGroupByDTO = {
  Account?: InputMaybe<AccountGroupByDTO>;
  AccountAsBrokerageAccount?: InputMaybe<BrokerageAccountGroupByDTO>;
  AccountAsForwardContractAccount?: InputMaybe<ForwardContractAccountGroupByDTO>;
  AccountAsSwapAccount?: InputMaybe<SwapAccountGroupByDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionGroupByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationGroupByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategyGroupByDTO>;
  HedgeStrategyCurrentPosition?: InputMaybe<HedgeStrategyCurrentPositionGroupByDTO>;
  Instrument?: InputMaybe<InstrumentGroupByDTO>;
  InstrumentAsBasisInstrument?: InputMaybe<BasisInstrumentGroupByDTO>;
  InstrumentAsCashInstrument?: InputMaybe<CashInstrumentGroupByDTO>;
  InstrumentAsCommitmentInstrument?: InputMaybe<CommitmentInstrumentGroupByDTO>;
  InstrumentAsFutureInstrument?: InputMaybe<FutureGroupByDTO>;
  InstrumentAsOptionInstrument?: InputMaybe<OptionGroupByDTO>;
  InstrumentAsPhysicalInstrument?: InputMaybe<PhysicalInstrumentGroupByDTO>;
  InstrumentAsSpreadInstrument?: InputMaybe<SpreadInstrumentGroupByDTO>;
  InstrumentAsSwapInstrument?: InputMaybe<SwapGroupByDTO>;
  InstrumentAsSwaptionInstrument?: InputMaybe<SwaptionGroupByDTO>;
  Order?: InputMaybe<OrderGroupByDTO>;
  PositionReconciliation?: InputMaybe<PositionReconciliationGroupByDTO>;
  TransactionGroup?: InputMaybe<TransactionGroupGroupByDTO>;
  accountId?: InputMaybe<Scalars['Boolean']>;
  commissionAndFeeTotal?: InputMaybe<Scalars['Boolean']>;
  commissionTotal?: InputMaybe<Scalars['Boolean']>;
  contractQuantity?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  executionType?: InputMaybe<Scalars['Boolean']>;
  feeTotal?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyCurrentPositionId?: InputMaybe<Scalars['Boolean']>;
  hedgeStrategyId?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  inputSource?: InputMaybe<Scalars['Boolean']>;
  instrumentId?: InputMaybe<Scalars['Boolean']>;
  integrationInstallationId?: InputMaybe<Scalars['Boolean']>;
  occurredAt?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['Boolean']>;
  positionId?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Boolean']>;
  total?: InputMaybe<Scalars['Boolean']>;
  tradeDate?: InputMaybe<Scalars['Boolean']>;
  tradeMonth?: InputMaybe<Scalars['Boolean']>;
  tradeYear?: InputMaybe<Scalars['Boolean']>;
  transactionGroupId?: InputMaybe<Scalars['Boolean']>;
  unitQuantity?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionGroupCreateDTO = {
  name: Scalars['String'];
};

export type TransactionGroupDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionGroupFilterDTO = {
  AND?: InputMaybe<Array<TransactionGroupFilterDTO>>;
  NOT?: InputMaybe<TransactionGroupFilterDTO>;
  OR?: InputMaybe<Array<TransactionGroupFilterDTO>>;
  Orders?: InputMaybe<OrderFilterDTO>;
  Transactions?: InputMaybe<TransactionFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TransactionGroupGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionGroupSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type TransactionGroupUpdateDTO = {
  name?: InputMaybe<Scalars['String']>;
};

export type TransactionNote = {
  __typename?: 'TransactionNote';
  Transaction: Transaction;
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  text: Scalars['String'];
  transactionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TransactionNoteCreateDTO = {
  text: Scalars['String'];
  transactionId: Scalars['String'];
};

export type TransactionNoteDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['Boolean']>;
  transactionId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionNoteFilterDTO = {
  AND?: InputMaybe<Array<TransactionNoteFilterDTO>>;
  NOT?: InputMaybe<TransactionNoteFilterDTO>;
  OR?: InputMaybe<Array<TransactionNoteFilterDTO>>;
  Transaction?: InputMaybe<TransactionFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
  transactionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TransactionNoteSortByDTO = {
  Transaction?: InputMaybe<TransactionSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  text?: InputMaybe<SortByDirection>;
  transactionId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type TransactionNoteUpdateDTO = {
  text?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
};

export type TransactionSortByDTO = {
  Account?: InputMaybe<AccountSortByDTO>;
  CurrentPosition?: InputMaybe<CurrentPositionSortByDTO>;
  EntityIntegrationInstallation?: InputMaybe<EntityIntegrationInstallationSortByDTO>;
  HedgeStrategy?: InputMaybe<HedgeStrategySortByDTO>;
  HedgeStrategyCurrentPosition?: InputMaybe<HedgeStrategyCurrentPositionSortByDTO>;
  Instrument?: InputMaybe<InstrumentSortByDTO>;
  Order?: InputMaybe<OrderSortByDTO>;
  PositionReconciliation?: InputMaybe<PositionReconciliationSortByDTO>;
  TransactionGroup?: InputMaybe<TransactionGroupSortByDTO>;
  accountId?: InputMaybe<SortByDirection>;
  commissionAndFeeTotal?: InputMaybe<SortByDirection>;
  commissionTotal?: InputMaybe<SortByDirection>;
  contractQuantity?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  executionType?: InputMaybe<SortByDirection>;
  feeTotal?: InputMaybe<SortByDirection>;
  hedgeStrategyCurrentPositionId?: InputMaybe<SortByDirection>;
  hedgeStrategyId?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  inputSource?: InputMaybe<SortByDirection>;
  instrumentId?: InputMaybe<SortByDirection>;
  integrationInstallationId?: InputMaybe<NullableSortByDirection>;
  occurredAt?: InputMaybe<NullableSortByDirection>;
  orderId?: InputMaybe<NullableSortByDirection>;
  positionId?: InputMaybe<SortByDirection>;
  price?: InputMaybe<SortByDirection>;
  quantity?: InputMaybe<SortByDirection>;
  total?: InputMaybe<SortByDirection>;
  tradeDate?: InputMaybe<NullableSortByDirection>;
  tradeMonth?: InputMaybe<NullableSortByDirection>;
  tradeYear?: InputMaybe<NullableSortByDirection>;
  transactionGroupId?: InputMaybe<NullableSortByDirection>;
  unitQuantity?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type TransactionUpdateDTO = {
  accountId?: InputMaybe<Scalars['String']>;
  /** The number of exchange contracts purchased or sold via this transaction. */
  contractQuantity?: InputMaybe<Scalars['Float']>;
  executionType?: InputMaybe<TypeOfTransactionExecution>;
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  instrumentId?: InputMaybe<Scalars['String']>;
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  orderId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  tradeDate?: InputMaybe<Scalars['String']>;
  transactionGroupId?: InputMaybe<Scalars['String']>;
  /** The volume of units purchased or sold via this transaction. What this value represents is dependent upon the product and/or instrument symbol group for unto which the instrument belongs. For example, a transaction for a CME cheese future with a contractQuantity of 2 would have a unitQuantity of 40,000 (20,000 lbs per contract). */
  unitQuantity?: InputMaybe<Scalars['Float']>;
};

export enum TypeOfAccount {
  Brokerage = 'Brokerage',
  ForwardContract = 'ForwardContract',
  Swap = 'Swap'
}

export enum TypeOfAccountFile {
  DailyStatement = 'DailyStatement',
  MonthlyStatement = 'MonthlyStatement'
}

export type TypeOfAccountFileFilter = {
  equals?: InputMaybe<TypeOfAccountFile>;
  in?: InputMaybe<Array<TypeOfAccountFile>>;
  not?: InputMaybe<TypeOfAccountFile>;
  notIn?: InputMaybe<Array<TypeOfAccountFile>>;
};

export type TypeOfAccountFilter = {
  equals?: InputMaybe<TypeOfAccount>;
  in?: InputMaybe<Array<TypeOfAccount>>;
  not?: InputMaybe<TypeOfAccount>;
  notIn?: InputMaybe<Array<TypeOfAccount>>;
};

export enum TypeOfAclAction {
  Read = 'Read',
  Write = 'Write'
}

export type TypeOfAclActionFilter = {
  equals?: InputMaybe<TypeOfAclAction>;
  in?: InputMaybe<Array<TypeOfAclAction>>;
  not?: InputMaybe<TypeOfAclAction>;
  notIn?: InputMaybe<Array<TypeOfAclAction>>;
};

export enum TypeOfAclResource {
  Brokerage = 'Brokerage',
  Business = 'Business',
  EntityGroup = 'EntityGroup',
  FeedAndCropPlan = 'FeedAndCropPlan',
  Insurance = 'Insurance',
  Never = 'Never',
  Operations = 'Operations',
  Organization = 'Organization',
  Persisted = 'Persisted',
  Swap = 'Swap'
}

export enum TypeOfAclResourceAction {
  BrokerageRead = 'BrokerageRead',
  BrokerageWrite = 'BrokerageWrite',
  BusinessRead = 'BusinessRead',
  BusinessWrite = 'BusinessWrite',
  EntityGroupRead = 'EntityGroupRead',
  EntityGroupWrite = 'EntityGroupWrite',
  FeedAndCropPlanRead = 'FeedAndCropPlanRead',
  FeedAndCropPlanWrite = 'FeedAndCropPlanWrite',
  InsuranceRead = 'InsuranceRead',
  InsuranceWrite = 'InsuranceWrite',
  OperationsRead = 'OperationsRead',
  OperationsWrite = 'OperationsWrite',
  OrganizationRead = 'OrganizationRead',
  OrganizationWrite = 'OrganizationWrite',
  SwapRead = 'SwapRead',
  SwapWrite = 'SwapWrite'
}

export type TypeOfAclResourceActionArrayFilterDTO = {
  /** Matches rows where the column contains ALL values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  contains?: InputMaybe<Array<TypeOfAclResourceAction>>;
  /** Matches rows where the column array is an exact match to the given filter (order & duplicates matter!) */
  equals?: InputMaybe<Array<TypeOfAclResourceAction>>;
  /** Matches rows where the column is contained within the values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  isContainedBy?: InputMaybe<Array<TypeOfAclResourceAction>>;
  /** Filter results by the number of values contained within this array. `null` values will always be filtered out (even with a length filter = 0) */
  length?: InputMaybe<NumberFilter>;
  not?: InputMaybe<TypeOfAclResourceActionArrayFilterDTO>;
  /** Matches rows where the column contains any of the values included within this filter */
  overlapsWith?: InputMaybe<Array<TypeOfAclResourceAction>>;
};

export type TypeOfAclResourceArrayFilterDTO = {
  /** Matches rows where the column contains ALL values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  contains?: InputMaybe<Array<TypeOfAclResource>>;
  /** Matches rows where the column array is an exact match to the given filter (order & duplicates matter!) */
  equals?: InputMaybe<Array<TypeOfAclResource>>;
  /** Matches rows where the column is contained within the values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  isContainedBy?: InputMaybe<Array<TypeOfAclResource>>;
  /** Filter results by the number of values contained within this array. `null` values will always be filtered out (even with a length filter = 0) */
  length?: InputMaybe<NumberFilter>;
  not?: InputMaybe<TypeOfAclResourceArrayFilterDTO>;
  /** Matches rows where the column contains any of the values included within this filter */
  overlapsWith?: InputMaybe<Array<TypeOfAclResource>>;
};

export type TypeOfAclResourceFilter = {
  equals?: InputMaybe<TypeOfAclResource>;
  in?: InputMaybe<Array<TypeOfAclResource>>;
  not?: InputMaybe<TypeOfAclResource>;
  notIn?: InputMaybe<Array<TypeOfAclResource>>;
};

export enum TypeOfAllocatedHedge {
  Brokerage = 'Brokerage',
  Drp = 'Drp',
  Lgm = 'Lgm',
  Lrp = 'Lrp',
  Physical = 'Physical'
}

export type TypeOfAllocatedHedgeFilter = {
  equals?: InputMaybe<TypeOfAllocatedHedge>;
  in?: InputMaybe<Array<TypeOfAllocatedHedge>>;
  not?: InputMaybe<TypeOfAllocatedHedge>;
  notIn?: InputMaybe<Array<TypeOfAllocatedHedge>>;
};

export enum TypeOfContract {
  Future = 'Future',
  Option = 'Option'
}

export enum TypeOfContractBasis {
  Percent = 'Percent',
  USD = 'USD'
}

export type TypeOfContractFilter = {
  equals?: InputMaybe<TypeOfContract>;
  in?: InputMaybe<Array<TypeOfContract>>;
  not?: InputMaybe<TypeOfContract>;
  notIn?: InputMaybe<Array<TypeOfContract>>;
};

export enum TypeOfContractPeriodAllocation {
  Monthly = 'Monthly',
  Once = 'Once',
  Quarterly = 'Quarterly',
  Weekly = 'Weekly'
}

export type TypeOfContractPeriodAllocationFilter = {
  equals?: InputMaybe<TypeOfContractPeriodAllocation>;
  in?: InputMaybe<Array<TypeOfContractPeriodAllocation>>;
  not?: InputMaybe<TypeOfContractPeriodAllocation>;
  notIn?: InputMaybe<Array<TypeOfContractPeriodAllocation>>;
};

export enum TypeOfContractPricing {
  CutoutUsda = 'CutoutUsda',
  FlatPricePerLb = 'FlatPricePerLb',
  LeanHogFuture = 'LeanHogFuture',
  LeanHogIndex = 'LeanHogIndex',
  LeanHogUsdaEasternCornbelt = 'LeanHogUsdaEasternCornbelt',
  LeanHogUsdaIowaMinnesota = 'LeanHogUsdaIowaMinnesota',
  LeanHogUsdaNational = 'LeanHogUsdaNational',
  LeanHogUsdaWesternCornbelt = 'LeanHogUsdaWesternCornbelt'
}

export type TypeOfContractPricingFilter = {
  equals?: InputMaybe<TypeOfContractPricing>;
  in?: InputMaybe<Array<TypeOfContractPricing>>;
  not?: InputMaybe<TypeOfContractPricing>;
  notIn?: InputMaybe<Array<TypeOfContractPricing>>;
};

export enum TypeOfCustodianAccount {
  Corporate = 'Corporate',
  Individual = 'Individual',
  Partnership = 'Partnership',
  Trust = 'Trust'
}

export enum TypeOfEntity {
  Customer = 'Customer',
  EntityGroup = 'EntityGroup',
  Location = 'Location',
  Organization = 'Organization'
}

export type TypeOfEntityFilter = {
  equals?: InputMaybe<TypeOfEntity>;
  in?: InputMaybe<Array<TypeOfEntity>>;
  not?: InputMaybe<TypeOfEntity>;
  notIn?: InputMaybe<Array<TypeOfEntity>>;
};

export enum TypeOfEntityMemberPermission {
  Read = 'Read',
  ReadAndWrite = 'ReadAndWrite',
  Write = 'Write'
}

export type TypeOfEntityMemberPermissionFilter = {
  equals?: InputMaybe<TypeOfEntityMemberPermission>;
  in?: InputMaybe<Array<TypeOfEntityMemberPermission>>;
  not?: InputMaybe<TypeOfEntityMemberPermission>;
  notIn?: InputMaybe<Array<TypeOfEntityMemberPermission>>;
};

export enum TypeOfFeedIngredientUsage {
  Actual = 'Actual',
  Forecasted = 'Forecasted'
}

export type TypeOfFeedIngredientUsageFilter = {
  equals?: InputMaybe<TypeOfFeedIngredientUsage>;
  in?: InputMaybe<Array<TypeOfFeedIngredientUsage>>;
  not?: InputMaybe<TypeOfFeedIngredientUsage>;
  notIn?: InputMaybe<Array<TypeOfFeedIngredientUsage>>;
};

export enum TypeOfForecastedLivestockFeedUsage {
  Swine = 'Swine'
}

export type TypeOfForecastedLivestockFeedUsageFilter = {
  equals?: InputMaybe<TypeOfForecastedLivestockFeedUsage>;
  in?: InputMaybe<Array<TypeOfForecastedLivestockFeedUsage>>;
  not?: InputMaybe<TypeOfForecastedLivestockFeedUsage>;
  notIn?: InputMaybe<Array<TypeOfForecastedLivestockFeedUsage>>;
};

export enum TypeOfGrainFillOrderModification {
  Cancel = 'Cancel',
  ExchangeCash = 'ExchangeCash',
  ExchangeHta = 'ExchangeHta',
  LiftAndSellCash = 'LiftAndSellCash',
  LiftAndSellHta = 'LiftAndSellHta',
  Roll = 'Roll',
  RollMarket = 'RollMarket',
  RollMarketAndSetBasis = 'RollMarketAndSetBasis',
  RollMarketAndSetFuturesMarket = 'RollMarketAndSetFuturesMarket',
  RollMarketAndSetFuturesOffer = 'RollMarketAndSetFuturesOffer',
  RollOffer = 'RollOffer',
  SetBasis = 'SetBasis',
  SetCashMarket = 'SetCashMarket',
  SetCashOffer = 'SetCashOffer',
  SetFuturesMarket = 'SetFuturesMarket',
  SetFuturesOffer = 'SetFuturesOffer'
}

export type TypeOfGrainFillOrderModificationFilter = {
  equals?: InputMaybe<TypeOfGrainFillOrderModification>;
  in?: InputMaybe<Array<TypeOfGrainFillOrderModification>>;
  not?: InputMaybe<TypeOfGrainFillOrderModification>;
  notIn?: InputMaybe<Array<TypeOfGrainFillOrderModification>>;
};

export enum TypeOfGrainOrder {
  Fill = 'Fill',
  Target = 'Target'
}

export enum TypeOfGrainOrderActivity {
  Fill = 'Fill',
  FillModificationRequest = 'FillModificationRequest',
  Target = 'Target'
}

export type TypeOfGrainOrderActivityFilter = {
  equals?: InputMaybe<TypeOfGrainOrderActivity>;
  in?: InputMaybe<Array<TypeOfGrainOrderActivity>>;
  not?: InputMaybe<TypeOfGrainOrderActivity>;
  notIn?: InputMaybe<Array<TypeOfGrainOrderActivity>>;
};

export type TypeOfGrainOrderFilter = {
  equals?: InputMaybe<TypeOfGrainOrder>;
  in?: InputMaybe<Array<TypeOfGrainOrder>>;
  not?: InputMaybe<TypeOfGrainOrder>;
  notIn?: InputMaybe<Array<TypeOfGrainOrder>>;
};

export enum TypeOfGrainOrderSales {
  Basis = 'Basis',
  BasisOnly = 'BasisOnly',
  Cbot = 'Cbot',
  Flat = 'Flat',
  FuturesOnly = 'FuturesOnly',
  Hta = 'Hta',
  MarketFlat = 'MarketFlat',
  MarketHta = 'MarketHta',
  Npe = 'Npe',
  SetBasis = 'SetBasis',
  SetFutures = 'SetFutures'
}

export type TypeOfGrainOrderSalesFilter = {
  equals?: InputMaybe<TypeOfGrainOrderSales>;
  in?: InputMaybe<Array<TypeOfGrainOrderSales>>;
  not?: InputMaybe<TypeOfGrainOrderSales>;
  notIn?: InputMaybe<Array<TypeOfGrainOrderSales>>;
};

export enum TypeOfGrainOrderStatus {
  Cancelled = 'Cancelled',
  Filled = 'Filled',
  New = 'New',
  Pending = 'Pending',
  PendingCancellation = 'PendingCancellation',
  Submitted = 'Submitted',
  Working = 'Working'
}

export type TypeOfGrainOrderStatusFilter = {
  equals?: InputMaybe<TypeOfGrainOrderStatus>;
  in?: InputMaybe<Array<TypeOfGrainOrderStatus>>;
  not?: InputMaybe<TypeOfGrainOrderStatus>;
  notIn?: InputMaybe<Array<TypeOfGrainOrderStatus>>;
};

export enum TypeOfGrainPlan {
  Crop = 'Crop',
  Feed = 'Feed'
}

export type TypeOfGrainPlanFilter = {
  equals?: InputMaybe<TypeOfGrainPlan>;
  in?: InputMaybe<Array<TypeOfGrainPlan>>;
  not?: InputMaybe<TypeOfGrainPlan>;
  notIn?: InputMaybe<Array<TypeOfGrainPlan>>;
};

export enum TypeOfHedge {
  Brokerage = 'Brokerage',
  Insurance = 'Insurance',
  Physical = 'Physical'
}

export type TypeOfHedgeFilter = {
  equals?: InputMaybe<TypeOfHedge>;
  in?: InputMaybe<Array<TypeOfHedge>>;
  not?: InputMaybe<TypeOfHedge>;
  notIn?: InputMaybe<Array<TypeOfHedge>>;
};

export enum TypeOfInstrument {
  Basis = 'Basis',
  Cash = 'Cash',
  Commitment = 'Commitment',
  Future = 'Future',
  Option = 'Option',
  Physical = 'Physical',
  Spread = 'Spread',
  Swap = 'Swap',
  Swaption = 'Swaption'
}

export type TypeOfInstrumentFilter = {
  equals?: InputMaybe<TypeOfInstrument>;
  in?: InputMaybe<Array<TypeOfInstrument>>;
  not?: InputMaybe<TypeOfInstrument>;
  notIn?: InputMaybe<Array<TypeOfInstrument>>;
};

export enum TypeOfInstrumentSymbolGroup {
  Cash = 'Cash',
  Future = 'Future',
  FutureMicro = 'FutureMicro',
  FutureMini = 'FutureMini',
  Option = 'Option',
  OptionCalendarSpread = 'OptionCalendarSpread',
  OptionEom = 'OptionEom',
  OptionIntercommoditySpread = 'OptionIntercommoditySpread',
  OptionMondayWeekly = 'OptionMondayWeekly',
  OptionShortDated = 'OptionShortDated',
  OptionWednesdayWeekly = 'OptionWednesdayWeekly',
  OptionWeekly = 'OptionWeekly',
  Spread = 'Spread',
  Swap = 'Swap',
  Swaption = 'Swaption'
}

export type TypeOfInstrumentSymbolGroupFilter = {
  equals?: InputMaybe<TypeOfInstrumentSymbolGroup>;
  in?: InputMaybe<Array<TypeOfInstrumentSymbolGroup>>;
  not?: InputMaybe<TypeOfInstrumentSymbolGroup>;
  notIn?: InputMaybe<Array<TypeOfInstrumentSymbolGroup>>;
};

export enum TypeOfInsuranceCommodity {
  Cattle = 'Cattle',
  DairyCattle = 'DairyCattle',
  FedCattle = 'FedCattle',
  FeederCattle = 'FeederCattle',
  Milk = 'Milk',
  Swine = 'Swine'
}

export type TypeOfInsuranceCommodityFilter = {
  equals?: InputMaybe<TypeOfInsuranceCommodity>;
  in?: InputMaybe<Array<TypeOfInsuranceCommodity>>;
  not?: InputMaybe<TypeOfInsuranceCommodity>;
  notIn?: InputMaybe<Array<TypeOfInsuranceCommodity>>;
};

export enum TypeOfInsuranceEndorsement {
  Drp = 'Drp',
  Lgm = 'Lgm',
  Lrp = 'Lrp'
}

export type TypeOfInsuranceEndorsementFilter = {
  equals?: InputMaybe<TypeOfInsuranceEndorsement>;
  in?: InputMaybe<Array<TypeOfInsuranceEndorsement>>;
  not?: InputMaybe<TypeOfInsuranceEndorsement>;
  notIn?: InputMaybe<Array<TypeOfInsuranceEndorsement>>;
};

export enum TypeOfInsuranceHistoricalPremium {
  Drp = 'Drp'
}

export type TypeOfInsuranceHistoricalPremiumFilter = {
  equals?: InputMaybe<TypeOfInsuranceHistoricalPremium>;
  in?: InputMaybe<Array<TypeOfInsuranceHistoricalPremium>>;
  not?: InputMaybe<TypeOfInsuranceHistoricalPremium>;
  notIn?: InputMaybe<Array<TypeOfInsuranceHistoricalPremium>>;
};

export enum TypeOfInsuranceHistoricalPremiumInput {
  Drp = 'Drp'
}

export type TypeOfInsuranceHistoricalPremiumInputFilter = {
  equals?: InputMaybe<TypeOfInsuranceHistoricalPremiumInput>;
  in?: InputMaybe<Array<TypeOfInsuranceHistoricalPremiumInput>>;
  not?: InputMaybe<TypeOfInsuranceHistoricalPremiumInput>;
  notIn?: InputMaybe<Array<TypeOfInsuranceHistoricalPremiumInput>>;
};

export enum TypeOfInsuranceProgram {
  Drp = 'Drp',
  Lgm = 'Lgm',
  Lrp = 'Lrp'
}

export type TypeOfInsuranceProgramFilter = {
  equals?: InputMaybe<TypeOfInsuranceProgram>;
  in?: InputMaybe<Array<TypeOfInsuranceProgram>>;
  not?: InputMaybe<TypeOfInsuranceProgram>;
  notIn?: InputMaybe<Array<TypeOfInsuranceProgram>>;
};

export enum TypeOfLedgerCalculation {
  DairyCwt = 'DairyCwt',
  DairyHead = 'DairyHead',
  SwineHead = 'SwineHead'
}

export enum TypeOfLedgerCategory {
  Expense = 'Expense',
  Feed = 'Feed',
  MilkCheck = 'MilkCheck',
  Revenue = 'Revenue'
}

export type TypeOfLedgerCategoryFilter = {
  equals?: InputMaybe<TypeOfLedgerCategory>;
  in?: InputMaybe<Array<TypeOfLedgerCategory>>;
  not?: InputMaybe<TypeOfLedgerCategory>;
  notIn?: InputMaybe<Array<TypeOfLedgerCategory>>;
};

export enum TypeOfLedgerEntry {
  Actual = 'Actual',
  Forecasted = 'Forecasted'
}

export type TypeOfLedgerEntryFilter = {
  equals?: InputMaybe<TypeOfLedgerEntry>;
  in?: InputMaybe<Array<TypeOfLedgerEntry>>;
  not?: InputMaybe<TypeOfLedgerEntry>;
  notIn?: InputMaybe<Array<TypeOfLedgerEntry>>;
};

export enum TypeOfLivestockGroup {
  Swine = 'Swine'
}

export enum TypeOfLivestockGroupFeedUsage {
  Swine = 'Swine'
}

export type TypeOfLivestockGroupFeedUsageFilter = {
  equals?: InputMaybe<TypeOfLivestockGroupFeedUsage>;
  in?: InputMaybe<Array<TypeOfLivestockGroupFeedUsage>>;
  not?: InputMaybe<TypeOfLivestockGroupFeedUsage>;
  notIn?: InputMaybe<Array<TypeOfLivestockGroupFeedUsage>>;
};

export type TypeOfLivestockGroupFilter = {
  equals?: InputMaybe<TypeOfLivestockGroup>;
  in?: InputMaybe<Array<TypeOfLivestockGroup>>;
  not?: InputMaybe<TypeOfLivestockGroup>;
  notIn?: InputMaybe<Array<TypeOfLivestockGroup>>;
};

export enum TypeOfLivestockPopulationChange {
  Swine = 'Swine'
}

export type TypeOfLivestockPopulationChangeFilter = {
  equals?: InputMaybe<TypeOfLivestockPopulationChange>;
  in?: InputMaybe<Array<TypeOfLivestockPopulationChange>>;
  not?: InputMaybe<TypeOfLivestockPopulationChange>;
  notIn?: InputMaybe<Array<TypeOfLivestockPopulationChange>>;
};

export enum TypeOfLivestockPopulationChangeReason {
  Birth = 'Birth',
  Death = 'Death',
  Purchase = 'Purchase',
  Sale = 'Sale'
}

export type TypeOfLivestockPopulationChangeReasonFilter = {
  equals?: InputMaybe<TypeOfLivestockPopulationChangeReason>;
  in?: InputMaybe<Array<TypeOfLivestockPopulationChangeReason>>;
  not?: InputMaybe<TypeOfLivestockPopulationChangeReason>;
  notIn?: InputMaybe<Array<TypeOfLivestockPopulationChangeReason>>;
};

export enum TypeOfLivestockPopulationChangeValue {
  Actual = 'Actual',
  Forecasted = 'Forecasted'
}

export type TypeOfLivestockPopulationChangeValueFilter = {
  equals?: InputMaybe<TypeOfLivestockPopulationChangeValue>;
  in?: InputMaybe<Array<TypeOfLivestockPopulationChangeValue>>;
  not?: InputMaybe<TypeOfLivestockPopulationChangeValue>;
  notIn?: InputMaybe<Array<TypeOfLivestockPopulationChangeValue>>;
};

export enum TypeOfNotification {
  ApplePush = 'ApplePush',
  Email = 'Email',
  FirebaseToken = 'FirebaseToken',
  Sms = 'Sms'
}

export type TypeOfNotificationFilter = {
  equals?: InputMaybe<TypeOfNotification>;
  in?: InputMaybe<Array<TypeOfNotification>>;
  not?: InputMaybe<TypeOfNotification>;
  notIn?: InputMaybe<Array<TypeOfNotification>>;
};

export enum TypeOfOption {
  Call = 'Call',
  Put = 'Put'
}

export type TypeOfOptionFilter = {
  equals?: InputMaybe<TypeOfOption>;
  in?: InputMaybe<Array<TypeOfOption>>;
  not?: InputMaybe<TypeOfOption>;
  notIn?: InputMaybe<Array<TypeOfOption>>;
};

export enum TypeOfOrderExecution {
  Limit = 'Limit',
  Market = 'Market'
}

export type TypeOfOrderExecutionFilter = {
  equals?: InputMaybe<TypeOfOrderExecution>;
  in?: InputMaybe<Array<TypeOfOrderExecution>>;
  not?: InputMaybe<TypeOfOrderExecution>;
  notIn?: InputMaybe<Array<TypeOfOrderExecution>>;
};

export enum TypeOfPartnerIntegrationParser {
  HilmarCheese = 'HilmarCheese',
  WellsFargo = 'WellsFargo'
}

export type TypeOfPartnerIntegrationParserFilter = {
  equals?: InputMaybe<TypeOfPartnerIntegrationParser>;
  in?: InputMaybe<Array<TypeOfPartnerIntegrationParser>>;
  not?: InputMaybe<TypeOfPartnerIntegrationParser>;
  notIn?: InputMaybe<Array<TypeOfPartnerIntegrationParser>>;
};

export enum TypeOfPhysicalFeedTransactionPricing {
  BasisOnly = 'BasisOnly',
  CalculatedFlat = 'CalculatedFlat',
  ExplicitlyFlat = 'ExplicitlyFlat',
  FuturesOnly = 'FuturesOnly',
  NoPrice = 'NoPrice'
}

export type TypeOfPhysicalFeedTransactionPricingFilter = {
  equals?: InputMaybe<TypeOfPhysicalFeedTransactionPricing>;
  in?: InputMaybe<Array<TypeOfPhysicalFeedTransactionPricing>>;
  not?: InputMaybe<TypeOfPhysicalFeedTransactionPricing>;
  notIn?: InputMaybe<Array<TypeOfPhysicalFeedTransactionPricing>>;
};

export enum TypeOfPhysicalSaleFee {
  Flex = 'Flex',
  Hta = 'Hta',
  Other = 'Other',
  Roll = 'Roll'
}

export type TypeOfPhysicalSaleFeeFilter = {
  equals?: InputMaybe<TypeOfPhysicalSaleFee>;
  in?: InputMaybe<Array<TypeOfPhysicalSaleFee>>;
  not?: InputMaybe<TypeOfPhysicalSaleFee>;
  notIn?: InputMaybe<Array<TypeOfPhysicalSaleFee>>;
};

export enum TypeOfPositionComponentAllocationQuantity {
  Contract = 'Contract',
  Unit = 'Unit'
}

export enum TypeOfPostgresOperation {
  Delete = 'Delete',
  Insert = 'Insert',
  Update = 'Update'
}

export type TypeOfPostgresOperationFilter = {
  equals?: InputMaybe<TypeOfPostgresOperation>;
  in?: InputMaybe<Array<TypeOfPostgresOperation>>;
  not?: InputMaybe<TypeOfPostgresOperation>;
  notIn?: InputMaybe<Array<TypeOfPostgresOperation>>;
};

export enum TypeOfProductLotSpecification {
  Micro = 'Micro',
  Mini = 'Mini',
  Standard = 'Standard'
}

export type TypeOfProductLotSpecificationFilter = {
  equals?: InputMaybe<TypeOfProductLotSpecification>;
  in?: InputMaybe<Array<TypeOfProductLotSpecification>>;
  not?: InputMaybe<TypeOfProductLotSpecification>;
  notIn?: InputMaybe<Array<TypeOfProductLotSpecification>>;
};

export enum TypeOfRmaStatus {
  Accepted = 'Accepted',
  AcceptedWithMessage = 'AcceptedWithMessage',
  AcceptedWithWarning = 'AcceptedWithWarning',
  Rejected = 'Rejected',
  RejectedWithLrr = 'RejectedWithLrr',
  Suspended = 'Suspended'
}

export enum TypeOfSearchItem {
  Account = 'Account',
  Aip = 'Aip',
  BasisInstrument = 'BasisInstrument',
  BrokerageAccount = 'BrokerageAccount',
  Business = 'Business',
  CashInstrument = 'CashInstrument',
  CommitmentInstrument = 'CommitmentInstrument',
  County = 'County',
  Customer = 'Customer',
  EntityGroup = 'EntityGroup',
  ForwardContract = 'ForwardContract',
  Future = 'Future',
  InsuranceAgency = 'InsuranceAgency',
  InsuranceAgent = 'InsuranceAgent',
  InsurancePolicy = 'InsurancePolicy',
  Location = 'Location',
  Option = 'Option',
  Organization = 'Organization',
  OtcContract = 'OtcContract',
  PhysicalInstrument = 'PhysicalInstrument',
  Product = 'Product',
  SpreadInstrument = 'SpreadInstrument',
  State = 'State',
  SwapAccount = 'SwapAccount',
  SwapInstrument = 'SwapInstrument',
  SwaptionInstrument = 'SwaptionInstrument',
  Usda = 'Usda',
  UsdaReport = 'UsdaReport'
}

export enum TypeOfSpread {
  Calendar = 'Calendar',
  Custom = 'Custom',
  Strip = 'Strip'
}

export type TypeOfSpreadFilter = {
  equals?: InputMaybe<TypeOfSpread>;
  in?: InputMaybe<Array<TypeOfSpread>>;
  not?: InputMaybe<TypeOfSpread>;
  notIn?: InputMaybe<Array<TypeOfSpread>>;
};

export enum TypeOfSubscription {
  DrpNewDailyPrices = 'DrpNewDailyPrices',
  InsuranceQuote = 'InsuranceQuote',
  Lrp = 'Lrp',
  LrpNewDailyPrices = 'LrpNewDailyPrices'
}

export type TypeOfSubscriptionFilter = {
  equals?: InputMaybe<TypeOfSubscription>;
  in?: InputMaybe<Array<TypeOfSubscription>>;
  not?: InputMaybe<TypeOfSubscription>;
  notIn?: InputMaybe<Array<TypeOfSubscription>>;
};

export enum TypeOfSubscriptionNotification {
  ApplePush = 'ApplePush',
  Email = 'Email',
  SMS = 'SMS'
}

export enum TypeOfTransactionExecution {
  Adjustment = 'Adjustment',
  Assignment = 'Assignment',
  Bust = 'Bust',
  Cancellation = 'Cancellation',
  ConfirmationReversal = 'ConfirmationReversal',
  Exercise = 'Exercise',
  PurchaseAndSaleReversal = 'PurchaseAndSaleReversal',
  Reconciliation = 'Reconciliation',
  Trade = 'Trade',
  Transfer = 'Transfer'
}

export type TypeOfTransactionExecutionFilter = {
  equals?: InputMaybe<TypeOfTransactionExecution>;
  in?: InputMaybe<Array<TypeOfTransactionExecution>>;
  not?: InputMaybe<TypeOfTransactionExecution>;
  notIn?: InputMaybe<Array<TypeOfTransactionExecution>>;
};

export enum TypeOfTransactionFee {
  BackOffice = 'BackOffice',
  Brokerage = 'Brokerage',
  CbtElectronicTrading = 'CbtElectronicTrading',
  Clearing = 'Clearing',
  Commission = 'Commission',
  DeskCharge = 'DeskCharge',
  Exchange = 'Exchange',
  ExecutionCharge = 'ExecutionCharge',
  FloorCharge = 'FloorCharge',
  GiveIn = 'GiveIn',
  GiveOut = 'GiveOut',
  GlobexJointVenture = 'GlobexJointVenture',
  Maintenance = 'Maintenance',
  MinimumCommissionCharge = 'MinimumCommissionCharge',
  Nfa = 'Nfa',
  Other = 'Other',
  PAndSTransaction = 'PAndSTransaction',
  PriceAlignmentInterest = 'PriceAlignmentInterest',
  PtmLevy = 'PtmLevy',
  Sec = 'Sec',
  Transaction = 'Transaction',
  TransactionTax = 'TransactionTax',
  ValueAddedTax = 'ValueAddedTax',
  WireCharge = 'WireCharge'
}

export type TypeOfTransactionFeeFilter = {
  equals?: InputMaybe<TypeOfTransactionFee>;
  in?: InputMaybe<Array<TypeOfTransactionFee>>;
  not?: InputMaybe<TypeOfTransactionFee>;
  notIn?: InputMaybe<Array<TypeOfTransactionFee>>;
};

export enum TypeOfUsdaReport {
  Mars = 'Mars',
  Mpr = 'Mpr'
}

export type TypeOfUsdaReportFilter = {
  equals?: InputMaybe<TypeOfUsdaReport>;
  in?: InputMaybe<Array<TypeOfUsdaReport>>;
  not?: InputMaybe<TypeOfUsdaReport>;
  notIn?: InputMaybe<Array<TypeOfUsdaReport>>;
};

export enum TypeOfVersion {
  BusinessData = 'BusinessData',
  FeedIngredient = 'FeedIngredient',
  LivestockGroup = 'LivestockGroup',
  LivestockGroupFeedUsage = 'LivestockGroupFeedUsage'
}

export type TypeOfVersionFilter = {
  equals?: InputMaybe<TypeOfVersion>;
  in?: InputMaybe<Array<TypeOfVersion>>;
  not?: InputMaybe<TypeOfVersion>;
  notIn?: InputMaybe<Array<TypeOfVersion>>;
};

export type UpdateEntityMemberDTO = {
  canReadBrokerage?: InputMaybe<Scalars['Boolean']>;
  canReadBusiness?: InputMaybe<Scalars['Boolean']>;
  canReadEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canReadFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canReadInsurance?: InputMaybe<Scalars['Boolean']>;
  canReadOperations?: InputMaybe<Scalars['Boolean']>;
  canReadOrganization?: InputMaybe<Scalars['Boolean']>;
  canReadSwap?: InputMaybe<Scalars['Boolean']>;
  canWriteBrokerage?: InputMaybe<Scalars['Boolean']>;
  canWriteBusiness?: InputMaybe<Scalars['Boolean']>;
  canWriteEntityGroup?: InputMaybe<Scalars['Boolean']>;
  canWriteFeedAndCropPlan?: InputMaybe<Scalars['Boolean']>;
  canWriteInsurance?: InputMaybe<Scalars['Boolean']>;
  canWriteOperations?: InputMaybe<Scalars['Boolean']>;
  canWriteOrganization?: InputMaybe<Scalars['Boolean']>;
  canWriteSwap?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateManyInsuranceEndorsementsHedgeStrategyInput = {
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  insuranceEndorsementIds: Array<Scalars['String']>;
};

export type UpdateManyTransactionsHedgeStrategyInput = {
  hedgeStrategyId?: InputMaybe<Scalars['String']>;
  transactionIds: Array<Scalars['String']>;
};

export type UpdateManyTransactionsHedgeStrategyResults = {
  __typename?: 'UpdateManyTransactionsHedgeStrategyResults';
  updatedCount: Scalars['Float'];
};

export type UpdateSubscriptionDTO = {
  apnToken?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  type: TypeOfSubscription;
};

export type UsdaActualMilkPrice = {
  __typename?: 'UsdaActualMilkPrice';
  MilkOrder: MilkOrder;
  classiPrice: Scalars['Float'];
  classiiPrice: Scalars['Float'];
  classiiiPrice: Scalars['Float'];
  classivPrice: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  id: Scalars['ID'];
  milkOrderId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UsdaActualMilkPriceDistinctOnDTO = {
  classiPrice?: InputMaybe<Scalars['Boolean']>;
  classiiPrice?: InputMaybe<Scalars['Boolean']>;
  classiiiPrice?: InputMaybe<Scalars['Boolean']>;
  classivPrice?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  milkOrderId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaActualMilkPriceFilterDTO = {
  AND?: InputMaybe<Array<UsdaActualMilkPriceFilterDTO>>;
  MilkOrder?: InputMaybe<MilkOrderFilterDTO>;
  NOT?: InputMaybe<UsdaActualMilkPriceFilterDTO>;
  OR?: InputMaybe<Array<UsdaActualMilkPriceFilterDTO>>;
  classiPrice?: InputMaybe<NumberFilter>;
  classiiPrice?: InputMaybe<NumberFilter>;
  classiiiPrice?: InputMaybe<NumberFilter>;
  classivPrice?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  milkOrderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UsdaActualMilkPriceSortByDTO = {
  MilkOrder?: InputMaybe<MilkOrderSortByDTO>;
  classiPrice?: InputMaybe<SortByDirection>;
  classiiPrice?: InputMaybe<SortByDirection>;
  classiiiPrice?: InputMaybe<SortByDirection>;
  classivPrice?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  date?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  milkOrderId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type UsdaFinalCheeseBlockPricesAndSales = {
  __typename?: 'UsdaFinalCheeseBlockPricesAndSales';
  createdDate: Scalars['String'];
  externalId: Scalars['Float'];
  externalSlug: Scalars['String'];
  id: Scalars['ID'];
  marketLocationCity: Scalars['String'];
  marketLocationName: Scalars['String'];
  marketLocationState: Scalars['String'];
  marketType: Scalars['String'];
  marketTypeCategory: Scalars['String'];
  narrative?: Maybe<Scalars['String']>;
  officeCity: Scalars['String'];
  officeCode: Scalars['String'];
  officeState: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  publishedAt: Scalars['DateTime'];
  reportEndDate: Scalars['String'];
  reportName: Scalars['String'];
  sales: Scalars['Float'];
  weekEndingDate: Scalars['String'];
};

export type UsdaFinalCheeseBlockPricesAndSalesDistinctOnDTO = {
  createdDate?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  externalSlug?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  marketLocationCity?: InputMaybe<Scalars['Boolean']>;
  marketLocationName?: InputMaybe<Scalars['Boolean']>;
  marketLocationState?: InputMaybe<Scalars['Boolean']>;
  marketType?: InputMaybe<Scalars['Boolean']>;
  marketTypeCategory?: InputMaybe<Scalars['Boolean']>;
  narrative?: InputMaybe<Scalars['Boolean']>;
  officeCity?: InputMaybe<Scalars['Boolean']>;
  officeCode?: InputMaybe<Scalars['Boolean']>;
  officeState?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['Boolean']>;
  reportEndDate?: InputMaybe<Scalars['Boolean']>;
  reportName?: InputMaybe<Scalars['Boolean']>;
  sales?: InputMaybe<Scalars['Boolean']>;
  weekEndingDate?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaFinalCheeseBlockPricesAndSalesFilterDTO = {
  AND?: InputMaybe<Array<UsdaFinalCheeseBlockPricesAndSalesFilterDTO>>;
  NOT?: InputMaybe<UsdaFinalCheeseBlockPricesAndSalesFilterDTO>;
  OR?: InputMaybe<Array<UsdaFinalCheeseBlockPricesAndSalesFilterDTO>>;
  createdDate?: InputMaybe<StringFilter>;
  externalId?: InputMaybe<NumberFilter>;
  externalSlug?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  marketLocationCity?: InputMaybe<StringFilter>;
  marketLocationName?: InputMaybe<StringFilter>;
  marketLocationState?: InputMaybe<StringFilter>;
  marketType?: InputMaybe<StringFilter>;
  marketTypeCategory?: InputMaybe<StringFilter>;
  narrative?: InputMaybe<NullableStringFilter>;
  officeCity?: InputMaybe<StringFilter>;
  officeCode?: InputMaybe<StringFilter>;
  officeState?: InputMaybe<StringFilter>;
  price?: InputMaybe<NullableNumberFilter>;
  publishedAt?: InputMaybe<DateTimeFilter>;
  reportEndDate?: InputMaybe<StringFilter>;
  reportName?: InputMaybe<StringFilter>;
  sales?: InputMaybe<NumberFilter>;
  weekEndingDate?: InputMaybe<StringFilter>;
};

export type UsdaFinalCheeseBlockPricesAndSalesSortByDTO = {
  createdDate?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<SortByDirection>;
  externalSlug?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  marketLocationCity?: InputMaybe<SortByDirection>;
  marketLocationName?: InputMaybe<SortByDirection>;
  marketLocationState?: InputMaybe<SortByDirection>;
  marketType?: InputMaybe<SortByDirection>;
  marketTypeCategory?: InputMaybe<SortByDirection>;
  narrative?: InputMaybe<NullableSortByDirection>;
  officeCity?: InputMaybe<SortByDirection>;
  officeCode?: InputMaybe<SortByDirection>;
  officeState?: InputMaybe<SortByDirection>;
  price?: InputMaybe<NullableSortByDirection>;
  publishedAt?: InputMaybe<SortByDirection>;
  reportEndDate?: InputMaybe<SortByDirection>;
  reportName?: InputMaybe<SortByDirection>;
  sales?: InputMaybe<SortByDirection>;
  weekEndingDate?: InputMaybe<SortByDirection>;
};

export type UsdaFluidMilkAndCreamCentralUs = {
  __typename?: 'UsdaFluidMilkAndCreamCentralUs';
  application: Scalars['String'];
  category: Scalars['String'];
  commodity: Scalars['String'];
  commodityNarrative: Scalars['String'];
  community: Scalars['String'];
  externalId: Scalars['Float'];
  externalSlug: Scalars['String'];
  finalInd: Scalars['String'];
  freight: Scalars['String'];
  grade: Scalars['String'];
  group: Scalars['String'];
  id: Scalars['ID'];
  lotDesc?: Maybe<Scalars['String']>;
  marketLocationCity: Scalars['String'];
  marketLocationName: Scalars['String'];
  marketLocationState: Scalars['String'];
  marketType: Scalars['String'];
  marketTypeCategory: Scalars['String'];
  methodology: Scalars['String'];
  mostlyHighPrice?: Maybe<Scalars['Boolean']>;
  mostlyLowPrice?: Maybe<Scalars['Boolean']>;
  officeCode: Scalars['String'];
  officeName: Scalars['String'];
  officeState: Scalars['String'];
  organic: Scalars['String'];
  origin: Scalars['String'];
  otherGrades: Scalars['String'];
  package: Scalars['String'];
  priceMax: Scalars['Float'];
  priceMin: Scalars['Float'];
  priceUnit: Scalars['String'];
  publishedAt: Scalars['DateTime'];
  quality: Scalars['String'];
  region: Scalars['String'];
  reportBeginDate: Scalars['String'];
  reportDate: Scalars['String'];
  reportEndDate: Scalars['String'];
  reportName: Scalars['String'];
  reportNarrative?: Maybe<Scalars['String']>;
  saleType: Scalars['String'];
  secondarySourceInfo?: Maybe<Scalars['String']>;
  volumeUnit: Scalars['String'];
};

export type UsdaFluidMilkAndCreamCentralUsDistinctOnDTO = {
  application?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['Boolean']>;
  commodity?: InputMaybe<Scalars['Boolean']>;
  commodityNarrative?: InputMaybe<Scalars['Boolean']>;
  community?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  externalSlug?: InputMaybe<Scalars['Boolean']>;
  finalInd?: InputMaybe<Scalars['Boolean']>;
  freight?: InputMaybe<Scalars['Boolean']>;
  grade?: InputMaybe<Scalars['Boolean']>;
  group?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lotDesc?: InputMaybe<Scalars['Boolean']>;
  marketLocationCity?: InputMaybe<Scalars['Boolean']>;
  marketLocationName?: InputMaybe<Scalars['Boolean']>;
  marketLocationState?: InputMaybe<Scalars['Boolean']>;
  marketType?: InputMaybe<Scalars['Boolean']>;
  marketTypeCategory?: InputMaybe<Scalars['Boolean']>;
  methodology?: InputMaybe<Scalars['Boolean']>;
  mostlyHighPrice?: InputMaybe<Scalars['Boolean']>;
  mostlyLowPrice?: InputMaybe<Scalars['Boolean']>;
  officeCode?: InputMaybe<Scalars['Boolean']>;
  officeName?: InputMaybe<Scalars['Boolean']>;
  officeState?: InputMaybe<Scalars['Boolean']>;
  organic?: InputMaybe<Scalars['Boolean']>;
  origin?: InputMaybe<Scalars['Boolean']>;
  otherGrades?: InputMaybe<Scalars['Boolean']>;
  package?: InputMaybe<Scalars['Boolean']>;
  priceMax?: InputMaybe<Scalars['Boolean']>;
  priceMin?: InputMaybe<Scalars['Boolean']>;
  priceUnit?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['Boolean']>;
  quality?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['Boolean']>;
  reportBeginDate?: InputMaybe<Scalars['Boolean']>;
  reportDate?: InputMaybe<Scalars['Boolean']>;
  reportEndDate?: InputMaybe<Scalars['Boolean']>;
  reportName?: InputMaybe<Scalars['Boolean']>;
  reportNarrative?: InputMaybe<Scalars['Boolean']>;
  saleType?: InputMaybe<Scalars['Boolean']>;
  secondarySourceInfo?: InputMaybe<Scalars['Boolean']>;
  volumeUnit?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaFluidMilkAndCreamCentralUsFilterDTO = {
  AND?: InputMaybe<Array<UsdaFluidMilkAndCreamCentralUsFilterDTO>>;
  NOT?: InputMaybe<UsdaFluidMilkAndCreamCentralUsFilterDTO>;
  OR?: InputMaybe<Array<UsdaFluidMilkAndCreamCentralUsFilterDTO>>;
  application?: InputMaybe<StringFilter>;
  category?: InputMaybe<StringFilter>;
  commodity?: InputMaybe<StringFilter>;
  commodityNarrative?: InputMaybe<StringFilter>;
  community?: InputMaybe<StringFilter>;
  externalId?: InputMaybe<NumberFilter>;
  externalSlug?: InputMaybe<StringFilter>;
  finalInd?: InputMaybe<StringFilter>;
  freight?: InputMaybe<StringFilter>;
  grade?: InputMaybe<StringFilter>;
  group?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lotDesc?: InputMaybe<NullableStringFilter>;
  marketLocationCity?: InputMaybe<StringFilter>;
  marketLocationName?: InputMaybe<StringFilter>;
  marketLocationState?: InputMaybe<StringFilter>;
  marketType?: InputMaybe<StringFilter>;
  marketTypeCategory?: InputMaybe<StringFilter>;
  methodology?: InputMaybe<StringFilter>;
  mostlyHighPrice?: InputMaybe<NullableBooleanFilter>;
  mostlyLowPrice?: InputMaybe<NullableBooleanFilter>;
  officeCode?: InputMaybe<StringFilter>;
  officeName?: InputMaybe<StringFilter>;
  officeState?: InputMaybe<StringFilter>;
  organic?: InputMaybe<StringFilter>;
  origin?: InputMaybe<StringFilter>;
  otherGrades?: InputMaybe<StringFilter>;
  package?: InputMaybe<StringFilter>;
  priceMax?: InputMaybe<NumberFilter>;
  priceMin?: InputMaybe<NumberFilter>;
  priceUnit?: InputMaybe<StringFilter>;
  publishedAt?: InputMaybe<DateTimeFilter>;
  quality?: InputMaybe<StringFilter>;
  region?: InputMaybe<StringFilter>;
  reportBeginDate?: InputMaybe<StringFilter>;
  reportDate?: InputMaybe<StringFilter>;
  reportEndDate?: InputMaybe<StringFilter>;
  reportName?: InputMaybe<StringFilter>;
  reportNarrative?: InputMaybe<NullableStringFilter>;
  saleType?: InputMaybe<StringFilter>;
  secondarySourceInfo?: InputMaybe<NullableStringFilter>;
  volumeUnit?: InputMaybe<StringFilter>;
};

export type UsdaFluidMilkAndCreamCentralUsSortByDTO = {
  application?: InputMaybe<SortByDirection>;
  category?: InputMaybe<SortByDirection>;
  commodity?: InputMaybe<SortByDirection>;
  commodityNarrative?: InputMaybe<SortByDirection>;
  community?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<SortByDirection>;
  externalSlug?: InputMaybe<SortByDirection>;
  finalInd?: InputMaybe<SortByDirection>;
  freight?: InputMaybe<SortByDirection>;
  grade?: InputMaybe<SortByDirection>;
  group?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  lotDesc?: InputMaybe<NullableSortByDirection>;
  marketLocationCity?: InputMaybe<SortByDirection>;
  marketLocationName?: InputMaybe<SortByDirection>;
  marketLocationState?: InputMaybe<SortByDirection>;
  marketType?: InputMaybe<SortByDirection>;
  marketTypeCategory?: InputMaybe<SortByDirection>;
  methodology?: InputMaybe<SortByDirection>;
  mostlyHighPrice?: InputMaybe<NullableSortByDirection>;
  mostlyLowPrice?: InputMaybe<NullableSortByDirection>;
  officeCode?: InputMaybe<SortByDirection>;
  officeName?: InputMaybe<SortByDirection>;
  officeState?: InputMaybe<SortByDirection>;
  organic?: InputMaybe<SortByDirection>;
  origin?: InputMaybe<SortByDirection>;
  otherGrades?: InputMaybe<SortByDirection>;
  package?: InputMaybe<SortByDirection>;
  priceMax?: InputMaybe<SortByDirection>;
  priceMin?: InputMaybe<SortByDirection>;
  priceUnit?: InputMaybe<SortByDirection>;
  publishedAt?: InputMaybe<SortByDirection>;
  quality?: InputMaybe<SortByDirection>;
  region?: InputMaybe<SortByDirection>;
  reportBeginDate?: InputMaybe<SortByDirection>;
  reportDate?: InputMaybe<SortByDirection>;
  reportEndDate?: InputMaybe<SortByDirection>;
  reportName?: InputMaybe<SortByDirection>;
  reportNarrative?: InputMaybe<NullableSortByDirection>;
  saleType?: InputMaybe<SortByDirection>;
  secondarySourceInfo?: InputMaybe<NullableSortByDirection>;
  volumeUnit?: InputMaybe<SortByDirection>;
};

export type UsdaMarsReport = IUsdaReport & {
  __typename?: 'UsdaMarsReport';
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  externalSlug: Scalars['String'];
  id: Scalars['ID'];
  lastPublishedAt: Scalars['DateTime'];
  marketTypes: Array<Scalars['String']>;
  markets: Array<Scalars['String']>;
  name: Scalars['String'];
  offices: Array<Scalars['String']>;
  type: TypeOfUsdaReport;
  updatedAt: Scalars['DateTime'];
};

export type UsdaMarsReportCreateDTO = {
  externalId: Scalars['String'];
  externalSlug: Scalars['String'];
  lastPublishedAt: Scalars['DateTime'];
  marketTypes: Array<Scalars['String']>;
  markets: Array<Scalars['String']>;
  name: Scalars['String'];
  offices: Array<Scalars['String']>;
};

export type UsdaMarsReportDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  externalSlug?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastPublishedAt?: InputMaybe<Scalars['Boolean']>;
  marketTypes?: InputMaybe<Scalars['Boolean']>;
  markets?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  offices?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMarsReportFilterDTO = {
  AND?: InputMaybe<Array<UsdaMarsReportFilterDTO>>;
  AsUsdaReport?: InputMaybe<UsdaReportFilterDTO>;
  NOT?: InputMaybe<UsdaMarsReportFilterDTO>;
  OR?: InputMaybe<Array<UsdaMarsReportFilterDTO>>;
  Records?: InputMaybe<UsdaRecordFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  externalId?: InputMaybe<StringFilter>;
  externalSlug?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastPublishedAt?: InputMaybe<DateTimeFilter>;
  marketTypes?: InputMaybe<StringArrayFilter>;
  markets?: InputMaybe<StringArrayFilter>;
  name?: InputMaybe<StringFilter>;
  offices?: InputMaybe<StringArrayFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UsdaMarsReportSortByDTO = {
  AsUsdaReport?: InputMaybe<UsdaReportSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<SortByDirection>;
  externalSlug?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  lastPublishedAt?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type UsdaMarsReportUpdateDTO = {
  externalId?: InputMaybe<Scalars['String']>;
  externalSlug?: InputMaybe<Scalars['String']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']>;
  marketTypes?: InputMaybe<Array<Scalars['String']>>;
  markets?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  offices?: InputMaybe<Array<Scalars['String']>>;
};

export type UsdaMonthlyNationalAverageDairyPrice = {
  __typename?: 'UsdaMonthlyNationalAverageDairyPrice';
  advancedSkimMilkClassIiPrice?: Maybe<Scalars['Float']>;
  butterMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  butterfatPrice?: Maybe<Scalars['Float']>;
  cheeseMonthly40Price?: Maybe<Scalars['Float']>;
  cheeseMonthly500Price?: Maybe<Scalars['Float']>;
  cheeseMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  classiiButterfatPrice?: Maybe<Scalars['Float']>;
  classiiPrice?: Maybe<Scalars['Float']>;
  classiiiPrice?: Maybe<Scalars['Float']>;
  classiiiSkimMilkPrice?: Maybe<Scalars['Float']>;
  classivPrice?: Maybe<Scalars['Float']>;
  classivSkimMilkPrice?: Maybe<Scalars['Float']>;
  date: Scalars['String'];
  nfdmMonthlyAvgPrice?: Maybe<Scalars['Float']>;
  nonfatSolidsPrice?: Maybe<Scalars['Float']>;
  otherSolidsPrice?: Maybe<Scalars['Float']>;
  proteinPrice?: Maybe<Scalars['Float']>;
  somaticCellAdjustmentRate?: Maybe<Scalars['Float']>;
  wheyMonthlyAvgPrice?: Maybe<Scalars['Float']>;
};

export type UsdaMonthlyNationalAverageDairyPriceAggregateAllDTO = {
  advancedSkimMilkClassIiPrice?: InputMaybe<Scalars['Boolean']>;
  butterMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  butterfatPrice?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthly40Price?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthly500Price?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  classiiButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  classiiPrice?: InputMaybe<Scalars['Boolean']>;
  classiiiPrice?: InputMaybe<Scalars['Boolean']>;
  classiiiSkimMilkPrice?: InputMaybe<Scalars['Boolean']>;
  classivPrice?: InputMaybe<Scalars['Boolean']>;
  classivSkimMilkPrice?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  nfdmMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  proteinPrice?: InputMaybe<Scalars['Boolean']>;
  somaticCellAdjustmentRate?: InputMaybe<Scalars['Boolean']>;
  wheyMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyNationalAverageDairyPriceAggregateDTO = {
  avg?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceAggregateAllDTO>;
  min?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceAggregateAllDTO>;
  sum?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceAggregateNumericDTO>;
};

export type UsdaMonthlyNationalAverageDairyPriceAggregateNumericDTO = {
  advancedSkimMilkClassIiPrice?: InputMaybe<Scalars['Boolean']>;
  butterMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  butterfatPrice?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthly40Price?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthly500Price?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  classiiButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  classiiPrice?: InputMaybe<Scalars['Boolean']>;
  classiiiPrice?: InputMaybe<Scalars['Boolean']>;
  classiiiSkimMilkPrice?: InputMaybe<Scalars['Boolean']>;
  classivPrice?: InputMaybe<Scalars['Boolean']>;
  classivSkimMilkPrice?: InputMaybe<Scalars['Boolean']>;
  nfdmMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  proteinPrice?: InputMaybe<Scalars['Boolean']>;
  somaticCellAdjustmentRate?: InputMaybe<Scalars['Boolean']>;
  wheyMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyNationalAverageDairyPriceDistinctOnDTO = {
  advancedSkimMilkClassIiPrice?: InputMaybe<Scalars['Boolean']>;
  butterMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  butterfatPrice?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthly40Price?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthly500Price?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  classiiButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  classiiPrice?: InputMaybe<Scalars['Boolean']>;
  classiiiPrice?: InputMaybe<Scalars['Boolean']>;
  classiiiSkimMilkPrice?: InputMaybe<Scalars['Boolean']>;
  classivPrice?: InputMaybe<Scalars['Boolean']>;
  classivSkimMilkPrice?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  nfdmMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  proteinPrice?: InputMaybe<Scalars['Boolean']>;
  somaticCellAdjustmentRate?: InputMaybe<Scalars['Boolean']>;
  wheyMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyNationalAverageDairyPriceFilterDTO = {
  AND?: InputMaybe<Array<UsdaMonthlyNationalAverageDairyPriceFilterDTO>>;
  NOT?: InputMaybe<UsdaMonthlyNationalAverageDairyPriceFilterDTO>;
  OR?: InputMaybe<Array<UsdaMonthlyNationalAverageDairyPriceFilterDTO>>;
  advancedSkimMilkClassIiPrice?: InputMaybe<NullableNumberFilter>;
  butterMonthlyAvgPrice?: InputMaybe<NullableNumberFilter>;
  butterfatPrice?: InputMaybe<NullableNumberFilter>;
  cheeseMonthly40Price?: InputMaybe<NullableNumberFilter>;
  cheeseMonthly500Price?: InputMaybe<NullableNumberFilter>;
  cheeseMonthlyAvgPrice?: InputMaybe<NullableNumberFilter>;
  classiiButterfatPrice?: InputMaybe<NullableNumberFilter>;
  classiiPrice?: InputMaybe<NullableNumberFilter>;
  classiiiPrice?: InputMaybe<NullableNumberFilter>;
  classiiiSkimMilkPrice?: InputMaybe<NullableNumberFilter>;
  classivPrice?: InputMaybe<NullableNumberFilter>;
  classivSkimMilkPrice?: InputMaybe<NullableNumberFilter>;
  date?: InputMaybe<StringFilter>;
  nfdmMonthlyAvgPrice?: InputMaybe<NullableNumberFilter>;
  nonfatSolidsPrice?: InputMaybe<NullableNumberFilter>;
  otherSolidsPrice?: InputMaybe<NullableNumberFilter>;
  proteinPrice?: InputMaybe<NullableNumberFilter>;
  somaticCellAdjustmentRate?: InputMaybe<NullableNumberFilter>;
  wheyMonthlyAvgPrice?: InputMaybe<NullableNumberFilter>;
};

export type UsdaMonthlyNationalAverageDairyPriceGroupByDTO = {
  advancedSkimMilkClassIiPrice?: InputMaybe<Scalars['Boolean']>;
  butterMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  butterfatPrice?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthly40Price?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthly500Price?: InputMaybe<Scalars['Boolean']>;
  cheeseMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  classiiButterfatPrice?: InputMaybe<Scalars['Boolean']>;
  classiiPrice?: InputMaybe<Scalars['Boolean']>;
  classiiiPrice?: InputMaybe<Scalars['Boolean']>;
  classiiiSkimMilkPrice?: InputMaybe<Scalars['Boolean']>;
  classivPrice?: InputMaybe<Scalars['Boolean']>;
  classivSkimMilkPrice?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  nfdmMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
  nonfatSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  otherSolidsPrice?: InputMaybe<Scalars['Boolean']>;
  proteinPrice?: InputMaybe<Scalars['Boolean']>;
  somaticCellAdjustmentRate?: InputMaybe<Scalars['Boolean']>;
  wheyMonthlyAvgPrice?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyNationalAverageDairyPriceSortByDTO = {
  advancedSkimMilkClassIiPrice?: InputMaybe<NullableSortByDirection>;
  butterMonthlyAvgPrice?: InputMaybe<NullableSortByDirection>;
  butterfatPrice?: InputMaybe<NullableSortByDirection>;
  cheeseMonthly40Price?: InputMaybe<NullableSortByDirection>;
  cheeseMonthly500Price?: InputMaybe<NullableSortByDirection>;
  cheeseMonthlyAvgPrice?: InputMaybe<NullableSortByDirection>;
  classiiButterfatPrice?: InputMaybe<NullableSortByDirection>;
  classiiPrice?: InputMaybe<NullableSortByDirection>;
  classiiiPrice?: InputMaybe<NullableSortByDirection>;
  classiiiSkimMilkPrice?: InputMaybe<NullableSortByDirection>;
  classivPrice?: InputMaybe<NullableSortByDirection>;
  classivSkimMilkPrice?: InputMaybe<NullableSortByDirection>;
  date?: InputMaybe<SortByDirection>;
  nfdmMonthlyAvgPrice?: InputMaybe<NullableSortByDirection>;
  nonfatSolidsPrice?: InputMaybe<NullableSortByDirection>;
  otherSolidsPrice?: InputMaybe<NullableSortByDirection>;
  proteinPrice?: InputMaybe<NullableSortByDirection>;
  somaticCellAdjustmentRate?: InputMaybe<NullableSortByDirection>;
  wheyMonthlyAvgPrice?: InputMaybe<NullableSortByDirection>;
};

export type UsdaMonthlyRegionalMilkComponentPrice = {
  __typename?: 'UsdaMonthlyRegionalMilkComponentPrice';
  MilkOrder: MilkOrder;
  butterfat?: Maybe<Scalars['Float']>;
  date: Scalars['String'];
  fmmoRegionCode: Scalars['Float'];
  fmmoRegionId: Scalars['String'];
  nfs?: Maybe<Scalars['Float']>;
  otherSolids?: Maybe<Scalars['Float']>;
  protein?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Scalars['Float']>;
  somcell?: Maybe<Scalars['Float']>;
};

export type UsdaMonthlyRegionalMilkComponentPriceAggregateAllDTO = {
  butterfat?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionCode?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionId?: InputMaybe<Scalars['Boolean']>;
  nfs?: InputMaybe<Scalars['Boolean']>;
  otherSolids?: InputMaybe<Scalars['Boolean']>;
  protein?: InputMaybe<Scalars['Boolean']>;
  receipts?: InputMaybe<Scalars['Boolean']>;
  somcell?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyRegionalMilkComponentPriceAggregateDTO = {
  avg?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceAggregateAllDTO>;
  min?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceAggregateAllDTO>;
  sum?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceAggregateNumericDTO>;
};

export type UsdaMonthlyRegionalMilkComponentPriceAggregateNumericDTO = {
  butterfat?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionCode?: InputMaybe<Scalars['Boolean']>;
  nfs?: InputMaybe<Scalars['Boolean']>;
  otherSolids?: InputMaybe<Scalars['Boolean']>;
  protein?: InputMaybe<Scalars['Boolean']>;
  receipts?: InputMaybe<Scalars['Boolean']>;
  somcell?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyRegionalMilkComponentPriceDistinctOnDTO = {
  butterfat?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionCode?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionId?: InputMaybe<Scalars['Boolean']>;
  nfs?: InputMaybe<Scalars['Boolean']>;
  otherSolids?: InputMaybe<Scalars['Boolean']>;
  protein?: InputMaybe<Scalars['Boolean']>;
  receipts?: InputMaybe<Scalars['Boolean']>;
  somcell?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyRegionalMilkComponentPriceFilterDTO = {
  AND?: InputMaybe<Array<UsdaMonthlyRegionalMilkComponentPriceFilterDTO>>;
  MilkOrder?: InputMaybe<MilkOrderFilterDTO>;
  NOT?: InputMaybe<UsdaMonthlyRegionalMilkComponentPriceFilterDTO>;
  OR?: InputMaybe<Array<UsdaMonthlyRegionalMilkComponentPriceFilterDTO>>;
  butterfat?: InputMaybe<NullableNumberFilter>;
  date?: InputMaybe<StringFilter>;
  fmmoRegionCode?: InputMaybe<NumberFilter>;
  fmmoRegionId?: InputMaybe<StringFilter>;
  nfs?: InputMaybe<NullableNumberFilter>;
  otherSolids?: InputMaybe<NullableNumberFilter>;
  protein?: InputMaybe<NullableNumberFilter>;
  receipts?: InputMaybe<NullableNumberFilter>;
  somcell?: InputMaybe<NullableNumberFilter>;
};

export type UsdaMonthlyRegionalMilkComponentPriceGroupByDTO = {
  MilkOrder?: InputMaybe<MilkOrderGroupByDTO>;
  butterfat?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionCode?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionId?: InputMaybe<Scalars['Boolean']>;
  nfs?: InputMaybe<Scalars['Boolean']>;
  otherSolids?: InputMaybe<Scalars['Boolean']>;
  protein?: InputMaybe<Scalars['Boolean']>;
  receipts?: InputMaybe<Scalars['Boolean']>;
  somcell?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyRegionalMilkComponentPriceSortByDTO = {
  MilkOrder?: InputMaybe<MilkOrderSortByDTO>;
  butterfat?: InputMaybe<NullableSortByDirection>;
  date?: InputMaybe<SortByDirection>;
  fmmoRegionCode?: InputMaybe<SortByDirection>;
  fmmoRegionId?: InputMaybe<SortByDirection>;
  nfs?: InputMaybe<NullableSortByDirection>;
  otherSolids?: InputMaybe<NullableSortByDirection>;
  protein?: InputMaybe<NullableSortByDirection>;
  receipts?: InputMaybe<NullableSortByDirection>;
  somcell?: InputMaybe<NullableSortByDirection>;
};

export type UsdaMonthlyRegionalMilkUtilization = {
  __typename?: 'UsdaMonthlyRegionalMilkUtilization';
  MilkOrder: MilkOrder;
  classiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiUtilizationPercent?: Maybe<Scalars['Float']>;
  classiiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiiCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classiiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiiUtilizationPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionOtherSolidsPercent?: Maybe<Scalars['Float']>;
  classiiiCompositionProteinPercent?: Maybe<Scalars['Float']>;
  classiiiUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classiiiUtilizationPercent?: Maybe<Scalars['Float']>;
  classivCompositionButterfatPercent?: Maybe<Scalars['Float']>;
  classivCompositionNfsPercent?: Maybe<Scalars['Float']>;
  classivUtilizationInMillionLbs?: Maybe<Scalars['Float']>;
  classivUtilizationPercent?: Maybe<Scalars['Float']>;
  date: Scalars['String'];
  fmmoRegionCode: Scalars['Float'];
  fmmoRegionId: Scalars['String'];
};

export type UsdaMonthlyRegionalMilkUtilizationAggregateAllDTO = {
  classiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classiiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiiCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classiiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionOtherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionProteinPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classivCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classivCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classivUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classivUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionCode?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionId?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyRegionalMilkUtilizationAggregateDTO = {
  avg?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationAggregateAllDTO>;
  min?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationAggregateAllDTO>;
  sum?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationAggregateNumericDTO>;
};

export type UsdaMonthlyRegionalMilkUtilizationAggregateNumericDTO = {
  classiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classiiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiiCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classiiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionOtherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionProteinPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classivCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classivCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classivUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classivUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionCode?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyRegionalMilkUtilizationDistinctOnDTO = {
  classiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classiiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiiCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classiiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionOtherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionProteinPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classivCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classivCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classivUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classivUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionCode?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionId?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyRegionalMilkUtilizationFilterDTO = {
  AND?: InputMaybe<Array<UsdaMonthlyRegionalMilkUtilizationFilterDTO>>;
  MilkOrder?: InputMaybe<MilkOrderFilterDTO>;
  NOT?: InputMaybe<UsdaMonthlyRegionalMilkUtilizationFilterDTO>;
  OR?: InputMaybe<Array<UsdaMonthlyRegionalMilkUtilizationFilterDTO>>;
  classiCompositionButterfatPercent?: InputMaybe<NullableNumberFilter>;
  classiCompositionNfsPercent?: InputMaybe<NullableNumberFilter>;
  classiUtilizationInMillionLbs?: InputMaybe<NullableNumberFilter>;
  classiUtilizationPercent?: InputMaybe<NullableNumberFilter>;
  classiiCompositionButterfatPercent?: InputMaybe<NullableNumberFilter>;
  classiiCompositionNfsPercent?: InputMaybe<NullableNumberFilter>;
  classiiUtilizationInMillionLbs?: InputMaybe<NullableNumberFilter>;
  classiiUtilizationPercent?: InputMaybe<NullableNumberFilter>;
  classiiiCompositionButterfatPercent?: InputMaybe<NullableNumberFilter>;
  classiiiCompositionOtherSolidsPercent?: InputMaybe<NullableNumberFilter>;
  classiiiCompositionProteinPercent?: InputMaybe<NullableNumberFilter>;
  classiiiUtilizationInMillionLbs?: InputMaybe<NullableNumberFilter>;
  classiiiUtilizationPercent?: InputMaybe<NullableNumberFilter>;
  classivCompositionButterfatPercent?: InputMaybe<NullableNumberFilter>;
  classivCompositionNfsPercent?: InputMaybe<NullableNumberFilter>;
  classivUtilizationInMillionLbs?: InputMaybe<NullableNumberFilter>;
  classivUtilizationPercent?: InputMaybe<NullableNumberFilter>;
  date?: InputMaybe<StringFilter>;
  fmmoRegionCode?: InputMaybe<NumberFilter>;
  fmmoRegionId?: InputMaybe<StringFilter>;
};

export type UsdaMonthlyRegionalMilkUtilizationGroupByDTO = {
  MilkOrder?: InputMaybe<MilkOrderGroupByDTO>;
  classiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classiiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiiCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classiiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionOtherSolidsPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiCompositionProteinPercent?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classiiiUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  classivCompositionButterfatPercent?: InputMaybe<Scalars['Boolean']>;
  classivCompositionNfsPercent?: InputMaybe<Scalars['Boolean']>;
  classivUtilizationInMillionLbs?: InputMaybe<Scalars['Boolean']>;
  classivUtilizationPercent?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionCode?: InputMaybe<Scalars['Boolean']>;
  fmmoRegionId?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMonthlyRegionalMilkUtilizationSortByDTO = {
  MilkOrder?: InputMaybe<MilkOrderSortByDTO>;
  classiCompositionButterfatPercent?: InputMaybe<NullableSortByDirection>;
  classiCompositionNfsPercent?: InputMaybe<NullableSortByDirection>;
  classiUtilizationInMillionLbs?: InputMaybe<NullableSortByDirection>;
  classiUtilizationPercent?: InputMaybe<NullableSortByDirection>;
  classiiCompositionButterfatPercent?: InputMaybe<NullableSortByDirection>;
  classiiCompositionNfsPercent?: InputMaybe<NullableSortByDirection>;
  classiiUtilizationInMillionLbs?: InputMaybe<NullableSortByDirection>;
  classiiUtilizationPercent?: InputMaybe<NullableSortByDirection>;
  classiiiCompositionButterfatPercent?: InputMaybe<NullableSortByDirection>;
  classiiiCompositionOtherSolidsPercent?: InputMaybe<NullableSortByDirection>;
  classiiiCompositionProteinPercent?: InputMaybe<NullableSortByDirection>;
  classiiiUtilizationInMillionLbs?: InputMaybe<NullableSortByDirection>;
  classiiiUtilizationPercent?: InputMaybe<NullableSortByDirection>;
  classivCompositionButterfatPercent?: InputMaybe<NullableSortByDirection>;
  classivCompositionNfsPercent?: InputMaybe<NullableSortByDirection>;
  classivUtilizationInMillionLbs?: InputMaybe<NullableSortByDirection>;
  classivUtilizationPercent?: InputMaybe<NullableSortByDirection>;
  date?: InputMaybe<SortByDirection>;
  fmmoRegionCode?: InputMaybe<SortByDirection>;
  fmmoRegionId?: InputMaybe<SortByDirection>;
};

export type UsdaMprReport = IUsdaReport & {
  __typename?: 'UsdaMprReport';
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  externalSlug: Scalars['String'];
  id: Scalars['ID'];
  lastPublishedAt: Scalars['DateTime'];
  marketTypes: Array<Scalars['String']>;
  markets: Array<Scalars['String']>;
  name: Scalars['String'];
  offices: Array<Scalars['String']>;
  section: Scalars['String'];
  type: TypeOfUsdaReport;
  updatedAt: Scalars['DateTime'];
};

export type UsdaMprReportCreateDTO = {
  externalId: Scalars['String'];
  externalSlug: Scalars['String'];
  lastPublishedAt: Scalars['DateTime'];
  marketTypes: Array<Scalars['String']>;
  markets: Array<Scalars['String']>;
  name: Scalars['String'];
  offices: Array<Scalars['String']>;
  section: Scalars['String'];
};

export type UsdaMprReportDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  externalSlug?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastPublishedAt?: InputMaybe<Scalars['Boolean']>;
  marketTypes?: InputMaybe<Scalars['Boolean']>;
  markets?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  offices?: InputMaybe<Scalars['Boolean']>;
  section?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaMprReportFilterDTO = {
  AND?: InputMaybe<Array<UsdaMprReportFilterDTO>>;
  AsUsdaReport?: InputMaybe<UsdaReportFilterDTO>;
  NOT?: InputMaybe<UsdaMprReportFilterDTO>;
  OR?: InputMaybe<Array<UsdaMprReportFilterDTO>>;
  Records?: InputMaybe<UsdaRecordFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  externalId?: InputMaybe<StringFilter>;
  externalSlug?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastPublishedAt?: InputMaybe<DateTimeFilter>;
  marketTypes?: InputMaybe<StringArrayFilter>;
  markets?: InputMaybe<StringArrayFilter>;
  name?: InputMaybe<StringFilter>;
  offices?: InputMaybe<StringArrayFilter>;
  section?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UsdaMprReportSortByDTO = {
  AsUsdaReport?: InputMaybe<UsdaReportSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<SortByDirection>;
  externalSlug?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  lastPublishedAt?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  section?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type UsdaMprReportUpdateDTO = {
  externalId?: InputMaybe<Scalars['String']>;
  externalSlug?: InputMaybe<Scalars['String']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']>;
  marketTypes?: InputMaybe<Array<Scalars['String']>>;
  markets?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  offices?: InputMaybe<Array<Scalars['String']>>;
  section?: InputMaybe<Scalars['String']>;
};

export type UsdaRecord = {
  __typename?: 'UsdaRecord';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  publishedAt: Scalars['DateTime'];
  record: Scalars['Object'];
  reportBeginDate?: Maybe<Scalars['String']>;
  reportEndDate: Scalars['String'];
  reportId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UsdaRecordDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['Boolean']>;
  record?: InputMaybe<Scalars['Boolean']>;
  reportBeginDate?: InputMaybe<Scalars['Boolean']>;
  reportEndDate?: InputMaybe<Scalars['Boolean']>;
  reportId?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaRecordFilterDTO = {
  AND?: InputMaybe<Array<UsdaRecordFilterDTO>>;
  NOT?: InputMaybe<UsdaRecordFilterDTO>;
  OR?: InputMaybe<Array<UsdaRecordFilterDTO>>;
  Report?: InputMaybe<UsdaReportFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  publishedAt?: InputMaybe<DateTimeFilter>;
  reportBeginDate?: InputMaybe<NullableStringFilter>;
  reportEndDate?: InputMaybe<StringFilter>;
  reportId: StringFilter;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UsdaRecordSortByDTO = {
  Report?: InputMaybe<UsdaReportSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  publishedAt?: InputMaybe<SortByDirection>;
  record?: InputMaybe<SortByDirection>;
  reportBeginDate?: InputMaybe<NullableSortByDirection>;
  reportEndDate?: InputMaybe<SortByDirection>;
  reportId?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type UsdaReport = IUsdaReport & {
  __typename?: 'UsdaReport';
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  externalSlug: Scalars['String'];
  id: Scalars['ID'];
  lastPublishedAt: Scalars['DateTime'];
  marketTypes: Array<Scalars['String']>;
  markets: Array<Scalars['String']>;
  name: Scalars['String'];
  offices: Array<Scalars['String']>;
  type: TypeOfUsdaReport;
  updatedAt: Scalars['DateTime'];
};

export type UsdaReportDistinctOnDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  externalId?: InputMaybe<Scalars['Boolean']>;
  externalSlug?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  lastPublishedAt?: InputMaybe<Scalars['Boolean']>;
  marketTypes?: InputMaybe<Scalars['Boolean']>;
  markets?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  offices?: InputMaybe<Scalars['Boolean']>;
  section?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaReportFilterDTO = {
  AND?: InputMaybe<Array<UsdaReportFilterDTO>>;
  AsUsdaMarsReport?: InputMaybe<UsdaMarsReportFilterDTO>;
  AsUsdaMprReport?: InputMaybe<UsdaMprReportFilterDTO>;
  NOT?: InputMaybe<UsdaReportFilterDTO>;
  OR?: InputMaybe<Array<UsdaReportFilterDTO>>;
  Records?: InputMaybe<UsdaRecordFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  externalId?: InputMaybe<StringFilter>;
  externalSlug?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastPublishedAt?: InputMaybe<DateTimeFilter>;
  marketTypes?: InputMaybe<StringArrayFilter>;
  markets?: InputMaybe<StringArrayFilter>;
  name?: InputMaybe<StringFilter>;
  offices?: InputMaybe<StringArrayFilter>;
  section?: InputMaybe<NullableStringFilter>;
  type?: InputMaybe<TypeOfUsdaReportFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UsdaReportSortByDTO = {
  AsUsdaMarsReport?: InputMaybe<UsdaMarsReportSortByDTO>;
  AsUsdaMprReport?: InputMaybe<UsdaMprReportSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  externalId?: InputMaybe<SortByDirection>;
  externalSlug?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  lastPublishedAt?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  section?: InputMaybe<NullableSortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type UsdaRetailSummary = {
  __typename?: 'UsdaRetailSummary';
  /** A measure of the absolute frequency of feature activity equal to the total number of stores for each advertised item. (e.g. a retailer with 100 outlet featuring 3 items has an activity index of 300) */
  activityIndex: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  /** The amount of sampled stores advertising and reporting items during the current week, expressed as a percentage of the total sample. */
  featureRate: Scalars['Float'];
  /** Dataset reports weekly data for all commodities and bi-weekly data for Dairy historical data (data prior to January 2015). */
  frequency: Scalars['String'];
  id: Scalars['ID'];
  isOrganic?: Maybe<Scalars['Boolean']>;
  /** Non conventional items to describe PY products such as: Vegetarian Feed, Cage Free, Omega 3, Antibiotic free, Hormone free, Air-chilled etc. */
  isSpecialty?: Maybe<Scalars['Boolean']>;
  issuingOffice: Scalars['String'];
  /** Subcategory of Poultry Level 4 data. Not used by Specialty Crops (Fruits and Vegetables), Livestock, and Dairy. */
  levelFive?: Maybe<Scalars['String']>;
  /** Poultry item specification. Not used by Specialty Crops (Fruits and Vegetables), Livestock and Dairy */
  levelFour?: Maybe<Scalars['String']>;
  levelOfTrade: Scalars['String'];
  /** For Livestock, this represents Species (Beef, Pork, Lamb/Veal, chicken, turkey, eggs); For Specialty Crops (Fruits and Vegetables) and Dairy this is the commodity name. */
  levelOne?: Maybe<Scalars['String']>;
  /** Not Used by Livestock and Dairy; For Specialty Crops (Fruits and Vegetables) this is used for environment (GG = greenhouse grown; FG = field grown; null = not specified). For chicken and turkey it is the form that the product is sold in either whole, parts, individually quick frozen (IQF), fried and/or baked, rotisserie, ground, self-service deli or service deli. For eggs that state is either shell, liquid or egg nog. */
  levelThree?: Maybe<Scalars['String']>;
  /** Hierarchy For Livestock, this is the nomenclature for red meat retail items (Beef, Pork, Lamb, and Veal). For Specialty Crops (Fruits and Vegetables) this is the variety name. For chicken and turkey it is the state that the product is sold in either fresh, frozen, or prepared. For eggs the state is either shell, liquid or egg nog. For Dairy this is the type (description of Commodity) */
  levelTwo?: Maybe<Scalars['String']>;
  /** The percentage of Specialty Crops (Fruits and Vegetables) ads that are labeled as locally grown. */
  locallyGrownPercentage: Scalars['Float'];
  /** Commodity high price */
  priceHigh: Scalars['Float'];
  /** Commodity low price */
  priceLow: Scalars['Float'];
  /** Product Quality only applies to Beef retail items. Beef Cuts that are advertised with USDA Quality Grades (Choice and Select) and without USDA Quality Grades (Other). Branded includes any advertised beef cuts marketed under a corporate trademark, or under one of Meat Grading & Certification Branch’s Certified Beef Programs. Summary is a composite of all of the above */
  productQuality?: Maybe<Scalars['String']>;
  /** Program reporting retail data. */
  program: Scalars['String'];
  /** Regions represented in this dataset include: Alaska, Hawaii, Midwest, Northeast, Northwest, South Central, Southeast, Southwest, Western, Central, and Eastern. National is a summary of all the regions. Note: For Lamb/Veal only, Eastern U.S., Central U.S., and Western U.S. regions were used prior to Jan 2015. */
  region: Scalars['String'];
  /** Date the report was issued. */
  reportDate: Scalars['String'];
  /** The percentage of sampled stores with a price promotion (e.g. buy 1 get 1 free etc.) */
  specialRate: Scalars['Float'];
  /** The total number of stores represented by the chain stores that we contact. */
  storeOutlets: Scalars['Int'];
  /** The number of stores that have an ad for a certain unique product. */
  storesWithAds: Scalars['Int'];
  unit?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Price based on number of stores that have an ad. */
  weightedAveragePrice: Scalars['Float'];
};

export type UsdaRetailSummaryAggregateAllDTO = {
  activityIndex?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  featureRate?: InputMaybe<Scalars['Boolean']>;
  frequency?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  issuingOffice?: InputMaybe<Scalars['Boolean']>;
  levelFive?: InputMaybe<Scalars['Boolean']>;
  levelFour?: InputMaybe<Scalars['Boolean']>;
  levelOfTrade?: InputMaybe<Scalars['Boolean']>;
  levelOne?: InputMaybe<Scalars['Boolean']>;
  levelThree?: InputMaybe<Scalars['Boolean']>;
  levelTwo?: InputMaybe<Scalars['Boolean']>;
  locallyGrownPercentage?: InputMaybe<Scalars['Boolean']>;
  priceHigh?: InputMaybe<Scalars['Boolean']>;
  priceLow?: InputMaybe<Scalars['Boolean']>;
  productQuality?: InputMaybe<Scalars['Boolean']>;
  program?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['Boolean']>;
  reportDate?: InputMaybe<Scalars['Boolean']>;
  specialRate?: InputMaybe<Scalars['Boolean']>;
  storeOutlets?: InputMaybe<Scalars['Boolean']>;
  storesWithAds?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  weightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaRetailSummaryAggregateDTO = {
  avg?: InputMaybe<UsdaRetailSummaryAggregateNumericDTO>;
  count?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<UsdaRetailSummaryAggregateAllDTO>;
  min?: InputMaybe<UsdaRetailSummaryAggregateAllDTO>;
  sum?: InputMaybe<UsdaRetailSummaryAggregateNumericDTO>;
};

export type UsdaRetailSummaryAggregateNumericDTO = {
  activityIndex?: InputMaybe<Scalars['Boolean']>;
  featureRate?: InputMaybe<Scalars['Boolean']>;
  locallyGrownPercentage?: InputMaybe<Scalars['Boolean']>;
  priceHigh?: InputMaybe<Scalars['Boolean']>;
  priceLow?: InputMaybe<Scalars['Boolean']>;
  specialRate?: InputMaybe<Scalars['Boolean']>;
  storeOutlets?: InputMaybe<Scalars['Boolean']>;
  storesWithAds?: InputMaybe<Scalars['Boolean']>;
  weightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaRetailSummaryDistinctOnDTO = {
  activityIndex?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  featureRate?: InputMaybe<Scalars['Boolean']>;
  frequency?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isOrganic?: InputMaybe<Scalars['Boolean']>;
  isSpecialty?: InputMaybe<Scalars['Boolean']>;
  issuingOffice?: InputMaybe<Scalars['Boolean']>;
  levelFive?: InputMaybe<Scalars['Boolean']>;
  levelFour?: InputMaybe<Scalars['Boolean']>;
  levelOfTrade?: InputMaybe<Scalars['Boolean']>;
  levelOne?: InputMaybe<Scalars['Boolean']>;
  levelThree?: InputMaybe<Scalars['Boolean']>;
  levelTwo?: InputMaybe<Scalars['Boolean']>;
  locallyGrownPercentage?: InputMaybe<Scalars['Boolean']>;
  priceHigh?: InputMaybe<Scalars['Boolean']>;
  priceLow?: InputMaybe<Scalars['Boolean']>;
  productQuality?: InputMaybe<Scalars['Boolean']>;
  program?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['Boolean']>;
  reportDate?: InputMaybe<Scalars['Boolean']>;
  specialRate?: InputMaybe<Scalars['Boolean']>;
  storeOutlets?: InputMaybe<Scalars['Boolean']>;
  storesWithAds?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  weightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaRetailSummaryFilterDTO = {
  AND?: InputMaybe<Array<UsdaRetailSummaryFilterDTO>>;
  NOT?: InputMaybe<UsdaRetailSummaryFilterDTO>;
  OR?: InputMaybe<Array<UsdaRetailSummaryFilterDTO>>;
  activityIndex?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  featureRate?: InputMaybe<NumberFilter>;
  frequency?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isOrganic?: InputMaybe<NullableBooleanFilter>;
  isSpecialty?: InputMaybe<NullableBooleanFilter>;
  issuingOffice?: InputMaybe<StringFilter>;
  levelFive?: InputMaybe<NullableStringFilter>;
  levelFour?: InputMaybe<NullableStringFilter>;
  levelOfTrade?: InputMaybe<StringFilter>;
  levelOne?: InputMaybe<NullableStringFilter>;
  levelThree?: InputMaybe<NullableStringFilter>;
  levelTwo?: InputMaybe<NullableStringFilter>;
  locallyGrownPercentage?: InputMaybe<NumberFilter>;
  priceHigh?: InputMaybe<NumberFilter>;
  priceLow?: InputMaybe<NumberFilter>;
  productQuality?: InputMaybe<NullableStringFilter>;
  program?: InputMaybe<StringFilter>;
  region?: InputMaybe<StringFilter>;
  reportDate?: InputMaybe<StringFilter>;
  specialRate?: InputMaybe<NumberFilter>;
  storeOutlets?: InputMaybe<NumberFilter>;
  storesWithAds?: InputMaybe<NumberFilter>;
  unit?: InputMaybe<NullableStringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  weightedAveragePrice?: InputMaybe<NumberFilter>;
};

export type UsdaRetailSummaryGroupByDTO = {
  activityIndex?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  featureRate?: InputMaybe<Scalars['Boolean']>;
  frequency?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isOrganic?: InputMaybe<Scalars['Boolean']>;
  isSpecialty?: InputMaybe<Scalars['Boolean']>;
  issuingOffice?: InputMaybe<Scalars['Boolean']>;
  levelFive?: InputMaybe<Scalars['Boolean']>;
  levelFour?: InputMaybe<Scalars['Boolean']>;
  levelOfTrade?: InputMaybe<Scalars['Boolean']>;
  levelOne?: InputMaybe<Scalars['Boolean']>;
  levelThree?: InputMaybe<Scalars['Boolean']>;
  levelTwo?: InputMaybe<Scalars['Boolean']>;
  locallyGrownPercentage?: InputMaybe<Scalars['Boolean']>;
  priceHigh?: InputMaybe<Scalars['Boolean']>;
  priceLow?: InputMaybe<Scalars['Boolean']>;
  productQuality?: InputMaybe<Scalars['Boolean']>;
  program?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['Boolean']>;
  reportDate?: InputMaybe<Scalars['Boolean']>;
  specialRate?: InputMaybe<Scalars['Boolean']>;
  storeOutlets?: InputMaybe<Scalars['Boolean']>;
  storesWithAds?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  weightedAveragePrice?: InputMaybe<Scalars['Boolean']>;
};

export type UsdaRetailSummarySortByDTO = {
  activityIndex?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  featureRate?: InputMaybe<SortByDirection>;
  frequency?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isOrganic?: InputMaybe<NullableSortByDirection>;
  isSpecialty?: InputMaybe<NullableSortByDirection>;
  issuingOffice?: InputMaybe<SortByDirection>;
  levelFive?: InputMaybe<NullableSortByDirection>;
  levelFour?: InputMaybe<NullableSortByDirection>;
  levelOfTrade?: InputMaybe<SortByDirection>;
  levelOne?: InputMaybe<NullableSortByDirection>;
  levelThree?: InputMaybe<NullableSortByDirection>;
  levelTwo?: InputMaybe<NullableSortByDirection>;
  locallyGrownPercentage?: InputMaybe<SortByDirection>;
  priceHigh?: InputMaybe<SortByDirection>;
  priceLow?: InputMaybe<SortByDirection>;
  productQuality?: InputMaybe<NullableSortByDirection>;
  program?: InputMaybe<SortByDirection>;
  region?: InputMaybe<SortByDirection>;
  reportDate?: InputMaybe<SortByDirection>;
  specialRate?: InputMaybe<SortByDirection>;
  storeOutlets?: InputMaybe<SortByDirection>;
  storesWithAds?: InputMaybe<SortByDirection>;
  unit?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  weightedAveragePrice?: InputMaybe<SortByDirection>;
};

export type User = {
  __typename?: 'User';
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles: Array<UserRole>;
};

export type UserFilterDTO = {
  AND?: InputMaybe<Array<UserFilterDTO>>;
  AccessControlListItems?: InputMaybe<AccessControlListItemFilterDTO>;
  CreatedGrainFillOrderModificationsRequests?: InputMaybe<GrainFillOrderModificationRequestFilterDTO>;
  EntitiesCreatedByUser?: InputMaybe<EntityFilterDTO>;
  EntitiesLastUpdatedByUser?: InputMaybe<EntityFilterDTO>;
  EntityMembers?: InputMaybe<EntityMemberFilterDTO>;
  EntityUsers?: InputMaybe<EntityUserFilterDTO>;
  GrainOrderActivity?: InputMaybe<GrainOrderActivityFilterDTO>;
  GrainOrderStatusUpdates?: InputMaybe<GrainOrderStatusUpdateFilterDTO>;
  GrainTargetOrders?: InputMaybe<GrainTargetOrderFilterDTO>;
  LegacyUser?: InputMaybe<LegacyUserFilterDTO>;
  NOT?: InputMaybe<UserFilterDTO>;
  NotificationSettings?: InputMaybe<NotificationSettingFilterDTO>;
  OR?: InputMaybe<Array<UserFilterDTO>>;
  OwnedGrainFillOrderModificationRequests?: InputMaybe<GrainFillOrderModificationRequestFilterDTO>;
  Subscriptions?: InputMaybe<NotificationSubscriptionFilterDTO>;
  SwineLivestockGroupContractsCreated?: InputMaybe<SwineLivestockGroupContractFilterDTO>;
  SwineLivestockGroupContractsLastEdited?: InputMaybe<SwineLivestockGroupContractFilterDTO>;
  VersionsCreated?: InputMaybe<VersionFilterDTO>;
  VersionsLastEdited?: InputMaybe<VersionFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerEntities?: InputMaybe<CustomerEntityFilterDTO>;
  deletedAt?: InputMaybe<NullableDateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isClaimed?: InputMaybe<BooleanFilter>;
  isDeleted?: InputMaybe<BooleanFilter>;
  lastLoginAt?: InputMaybe<NullableDateTimeFilter>;
  lastName?: InputMaybe<NullableStringFilter>;
  phoneNumber?: InputMaybe<NullableStringFilter>;
  roles?: InputMaybe<UserRoleArrayFilterDTO>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserGroupByDTO = {
  LegacyUser?: InputMaybe<LegacyUserGroupByDTO>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isClaimed?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lastLoginAt?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']>;
  roles?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type UserPermissionsForTarget = {
  __typename?: 'UserPermissionsForTarget';
  canReadBrokerage: Scalars['Boolean'];
  canReadBusiness: Scalars['Boolean'];
  /** @deprecated Use canRead[Organization|EntityGroup|Business] instead */
  canReadEntity: Scalars['Boolean'];
  canReadEntityGroup: Scalars['Boolean'];
  canReadFeedAndCropPlan: Scalars['Boolean'];
  canReadInsurance: Scalars['Boolean'];
  canReadOperations: Scalars['Boolean'];
  canReadOrganization: Scalars['Boolean'];
  canReadSwap: Scalars['Boolean'];
  canWriteBrokerage: Scalars['Boolean'];
  canWriteBusiness: Scalars['Boolean'];
  /** @deprecated Use canWrite[Organization|EntityGroup|Business] instead */
  canWriteEntity: Scalars['Boolean'];
  canWriteEntityGroup: Scalars['Boolean'];
  canWriteFeedAndCropPlan: Scalars['Boolean'];
  canWriteInsurance: Scalars['Boolean'];
  canWriteOperations: Scalars['Boolean'];
  canWriteOrganization: Scalars['Boolean'];
  canWriteSwap: Scalars['Boolean'];
  targetId: Scalars['String'];
};

export enum UserRole {
  Admin = 'Admin',
  BuyerRelations = 'BuyerRelations',
  Commercial = 'Commercial',
  Crop = 'Crop',
  Dairy = 'Dairy',
  Internal = 'Internal'
}

export type UserRoleArrayFilterDTO = {
  /** Matches rows where the column contains ALL values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  contains?: InputMaybe<Array<UserRole>>;
  /** Matches rows where the column array is an exact match to the given filter (order & duplicates matter!) */
  equals?: InputMaybe<Array<UserRole>>;
  /** Matches rows where the column is contained within the values included in this filter. (Duplicates are not treated specially, thus [1] contains [1, 1]) */
  isContainedBy?: InputMaybe<Array<UserRole>>;
  /** Filter results by the number of values contained within this array. `null` values will always be filtered out (even with a length filter = 0) */
  length?: InputMaybe<NumberFilter>;
  not?: InputMaybe<UserRoleArrayFilterDTO>;
  /** Matches rows where the column contains any of the values included within this filter */
  overlapsWith?: InputMaybe<Array<UserRole>>;
};

export type UserSortByDTO = {
  LegacyUser?: InputMaybe<LegacyUserSortByDTO>;
  createdAt?: InputMaybe<SortByDirection>;
  deletedAt?: InputMaybe<NullableSortByDirection>;
  email?: InputMaybe<SortByDirection>;
  firstName?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isClaimed?: InputMaybe<SortByDirection>;
  isDeleted?: InputMaybe<SortByDirection>;
  lastLoginAt?: InputMaybe<NullableSortByDirection>;
  lastName?: InputMaybe<NullableSortByDirection>;
  phoneNumber?: InputMaybe<NullableSortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type UserUpdateDTO = {
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  /** Inidicates if a user account has been claimed. A "claimed" user has completed a signup or registration process, and is linked to an Auth0 user (if applicable). Unclaimed users cannot log in. */
  isClaimed?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<UserRole>>;
};

export type VaultSubscription = {
  __typename?: 'VaultSubscription';
  User?: Maybe<User>;
  apnToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deviceId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  errorCount: Scalars['Float'];
  id: Scalars['ID'];
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  notificationType: TypeOfSubscriptionNotification;
  phoneNumber?: Maybe<Scalars['String']>;
  type: TypeOfSubscription;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type VenueFilterDTO = {
  AND?: InputMaybe<Array<VenueFilterDTO>>;
  NOT?: InputMaybe<VenueFilterDTO>;
  OR?: InputMaybe<Array<VenueFilterDTO>>;
  Products?: InputMaybe<ProductFilterDTO>;
  VenueHolidays?: InputMaybe<VenueHolidayFilterDTO>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  timeZone?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type VenueGroupByDTO = {
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  timeZone?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type VenueHolidayFilterDTO = {
  AND?: InputMaybe<Array<VenueHolidayFilterDTO>>;
  NOT?: InputMaybe<VenueHolidayFilterDTO>;
  OR?: InputMaybe<Array<VenueHolidayFilterDTO>>;
  ProductGroup?: InputMaybe<ProductGroupFilterDTO>;
  Venue?: InputMaybe<VenueFilterDTO>;
  calendarYear?: InputMaybe<NumberFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  productGroupId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  venueId?: InputMaybe<StringFilter>;
};

export type VenueSortByDTO = {
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  name?: InputMaybe<SortByDirection>;
  timeZone?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type VersionFilterDTO = {
  AND?: InputMaybe<Array<VersionFilterDTO>>;
  AsBusinessDataVersion?: InputMaybe<BusinessDataVersionFilterDTO>;
  AsFeedIngredientVersion?: InputMaybe<FeedIngredientVersionFilterDTO>;
  AsLivestockGroupFeedUsageVersion?: InputMaybe<LivestockGroupFeedUsageVersionFilterDTO>;
  AsLivestockGroupVersion?: InputMaybe<LivestockGroupVersionFilterDTO>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  CreatedByUser?: InputMaybe<UserFilterDTO>;
  LastUpdatedByUser?: InputMaybe<UserFilterDTO>;
  NOT?: InputMaybe<VersionFilterDTO>;
  NextVersion?: InputMaybe<VersionFilterDTO>;
  OR?: InputMaybe<Array<VersionFilterDTO>>;
  PreviousVersion?: InputMaybe<VersionFilterDTO>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByUserId?: InputMaybe<NullableStringFilter>;
  description?: InputMaybe<NullableStringFilter>;
  endDate?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<StringFilter>;
  isCurrent?: InputMaybe<BooleanFilter>;
  lastUpdatedByUserId?: InputMaybe<NullableStringFilter>;
  livestockGroupFeedUsageType?: InputMaybe<NullableTypeOfLivestockGroupFeedUsageFilter>;
  livestockGroupType?: InputMaybe<NullableTypeOfLivestockGroupFilter>;
  name?: InputMaybe<StringFilter>;
  nextVersionId?: InputMaybe<NullableStringFilter>;
  previousVersionId?: InputMaybe<NullableStringFilter>;
  startDate?: InputMaybe<StringFilter>;
  type?: InputMaybe<TypeOfVersionFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type VersionGroupByDTO = {
  AsBusinessDataVersion?: InputMaybe<BusinessDataVersionGroupByDTO>;
  AsFeedIngredientVersion?: InputMaybe<FeedIngredientVersionGroupByDTO>;
  AsLivestockGroupFeedUsageVersion?: InputMaybe<LivestockGroupFeedUsageVersionGroupByDTO>;
  AsLivestockGroupVersion?: InputMaybe<LivestockGroupVersionGroupByDTO>;
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  CreatedByUser?: InputMaybe<UserGroupByDTO>;
  LastUpdatedByUser?: InputMaybe<UserGroupByDTO>;
  NextVersion?: InputMaybe<VersionGroupByDTO>;
  NextVersionAsBusinessDataVersion?: InputMaybe<BusinessDataVersionGroupByDTO>;
  NextVersionAsFeedIngredientVersion?: InputMaybe<FeedIngredientVersionGroupByDTO>;
  NextVersionAsLivestockGroupFeedUsageVersion?: InputMaybe<LivestockGroupFeedUsageVersionGroupByDTO>;
  NextVersionAsLivestockGroupVersion?: InputMaybe<LivestockGroupVersionGroupByDTO>;
  PreviousVersion?: InputMaybe<VersionGroupByDTO>;
  PreviousVersionAsBusinessDataVersion?: InputMaybe<BusinessDataVersionGroupByDTO>;
  PreviousVersionAsFeedIngredientVersion?: InputMaybe<FeedIngredientVersionGroupByDTO>;
  PreviousVersionAsLivestockGroupFeedUsageVersion?: InputMaybe<LivestockGroupFeedUsageVersionGroupByDTO>;
  PreviousVersionAsLivestockGroupVersion?: InputMaybe<LivestockGroupVersionGroupByDTO>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  createdByUserId?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  isCurrent?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedByUserId?: InputMaybe<Scalars['Boolean']>;
  livestockGroupFeedUsageType?: InputMaybe<Scalars['Boolean']>;
  livestockGroupType?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  nextVersionId?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
};

export type VersionSortByDTO = {
  AsBusinessDataVersion?: InputMaybe<BusinessDataVersionSortByDTO>;
  AsFeedIngredientVersion?: InputMaybe<FeedIngredientVersionSortByDTO>;
  AsLivestockGroupFeedUsageVersion?: InputMaybe<LivestockGroupFeedUsageVersionSortByDTO>;
  AsLivestockGroupVersion?: InputMaybe<LivestockGroupVersionSortByDTO>;
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  CreatedByUser?: InputMaybe<UserSortByDTO>;
  LastUpdatedByUser?: InputMaybe<UserSortByDTO>;
  NextVersion?: InputMaybe<VersionSortByDTO>;
  PreviousVersion?: InputMaybe<VersionSortByDTO>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  createdByUserId?: InputMaybe<NullableSortByDirection>;
  description?: InputMaybe<NullableSortByDirection>;
  endDate?: InputMaybe<NullableSortByDirection>;
  id?: InputMaybe<SortByDirection>;
  isCurrent?: InputMaybe<SortByDirection>;
  lastUpdatedByUserId?: InputMaybe<NullableSortByDirection>;
  livestockGroupFeedUsageType?: InputMaybe<NullableSortByDirection>;
  livestockGroupType?: InputMaybe<NullableSortByDirection>;
  name?: InputMaybe<SortByDirection>;
  nextVersionId?: InputMaybe<NullableSortByDirection>;
  previousVersionId?: InputMaybe<NullableSortByDirection>;
  startDate?: InputMaybe<SortByDirection>;
  type?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
};

export type VersionedBusinessData = {
  __typename?: 'VersionedBusinessData';
  Business: CustomerEntity;
  BusinessDataVersion: BusinessDataVersion;
  /** The average age in weeks at which a pig is ready for market */
  averageFinishAgeInWeeks: Scalars['Int'];
  /** The average weight in pounds at which a pig is ready for market */
  averageFinishWeightInLbs: Scalars['Int'];
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  hasWriteAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  versionId: Scalars['String'];
  /** A UUID that will be shared across all versioned business data rows that have been copied from each other upon the creation of a new version. Because there will only ever be one versioned business data per version + business, this field is a bit redundant, and is included to maintain iterface compatability with the other versioned concepts. */
  versionedConceptSeriesId: Scalars['String'];
};

export type VersionedBusinessDataDistinctOnDTO = {
  averageFinishAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  averageFinishWeightInLbs?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type VersionedBusinessDataFilterDTO = {
  AND?: InputMaybe<Array<VersionedBusinessDataFilterDTO>>;
  Business?: InputMaybe<CustomerEntityFilterDTO>;
  BusinessDataVersion?: InputMaybe<BusinessDataVersionFilterDTO>;
  NOT?: InputMaybe<VersionedBusinessDataFilterDTO>;
  OR?: InputMaybe<Array<VersionedBusinessDataFilterDTO>>;
  averageFinishAgeInWeeks?: InputMaybe<NumberFilter>;
  averageFinishWeightInLbs?: InputMaybe<NumberFilter>;
  businessId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  versionId?: InputMaybe<StringFilter>;
  versionedConceptSeriesId?: InputMaybe<StringFilter>;
};

export type VersionedBusinessDataGroupByDTO = {
  Business?: InputMaybe<CustomerEntityGroupByDTO>;
  BusinessDataVersion?: InputMaybe<BusinessDataVersionGroupByDTO>;
  averageFinishAgeInWeeks?: InputMaybe<Scalars['Boolean']>;
  averageFinishWeightInLbs?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Boolean']>;
  versionId?: InputMaybe<Scalars['Boolean']>;
  versionedConceptSeriesId?: InputMaybe<Scalars['Boolean']>;
};

export type VersionedBusinessDataSortByDTO = {
  Business?: InputMaybe<CustomerEntitySortByDTO>;
  BusinessDataVersion?: InputMaybe<BusinessDataVersionSortByDTO>;
  averageFinishAgeInWeeks?: InputMaybe<SortByDirection>;
  averageFinishWeightInLbs?: InputMaybe<SortByDirection>;
  businessId?: InputMaybe<SortByDirection>;
  createdAt?: InputMaybe<SortByDirection>;
  id?: InputMaybe<SortByDirection>;
  updatedAt?: InputMaybe<SortByDirection>;
  versionId?: InputMaybe<SortByDirection>;
  versionedConceptSeriesId?: InputMaybe<SortByDirection>;
};
