import { gql } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import BaseDeleteModalComponent from 'vault-client/components/base-delete-modal';
import { PhysicalFeedTransaction } from 'vault-client/types/graphql-types';

const DELETE_PHYSICAL_FEED_TRANSACTION = gql`
	mutation DeletePhysicalFeedTransaction($id: String!) {
		deletePhysicalFeedTransaction(id: $id) {
			id
		}
	}
`;
export default class DeletePhysicalFeedTransactionModalComponent extends BaseDeleteModalComponent {
	mutation = DELETE_PHYSICAL_FEED_TRANSACTION;
	evictionFields: string[] = [
		'PhysicalFeedTransactions',
		'PhysicalFeedTransactionsCount',
		'FeedIngredientConsumedAndPurchasedVolumes',
		'AggregateFeedIngredientConsumedAndPurchasedVolumes',
	];

	get feedFill() {
		return this.args.itemToDelete as PhysicalFeedTransaction | undefined;
	}

	get titleText() {
		return 'Delete Feed Fill';
	}

	get bodyText() {
		return 'Are you sure you want to delete this Feed Fill?';
	}

	get deleteButtonText() {
		return 'Delete Feed Fill';
	}

	get label() {
		if (!this.feedFill) return '';

		const { FeedIngredient, deliveryStartDate, deliveryEndDate } = this.feedFill;

		const formattedStartDate = DateTime.fromISO(deliveryStartDate).toLocaleString(DateTime.DATE_MED);
		const formattedEndDate = DateTime.fromISO(deliveryEndDate).toLocaleString(DateTime.DATE_MED);
		return `${FeedIngredient?.name}: ${formattedStartDate} to ${formattedEndDate}`;
	}
}
