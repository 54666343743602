import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isLoading}}\n\t<Vault::UiLoadingSpinner />\n{{else}}\n\t<SingleLineChart\n\t\t...attributes\n\t\t@id={{@id}}\n\t\t@data={{this.data}}\n\t\t@labels={{this.labels}}\n\t\t@tooltipBodyTextCallback={{this.tooltipBodyTextCallback}}\n\t\t@yScaleOptions={{this.yScaleOptions}}\n\t/>\n{{/if}}", {"contents":"{{#if this.isLoading}}\n\t<Vault::UiLoadingSpinner />\n{{else}}\n\t<SingleLineChart\n\t\t...attributes\n\t\t@id={{@id}}\n\t\t@data={{this.data}}\n\t\t@labels={{this.labels}}\n\t\t@tooltipBodyTextCallback={{this.tooltipBodyTextCallback}}\n\t\t@yScaleOptions={{this.yScaleOptions}}\n\t/>\n{{/if}}","moduleName":"vault-client/components/historical-prices-chart.hbs","parseOptions":{"srcName":"vault-client/components/historical-prices-chart.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { DateTime } from 'luxon';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';

interface HistoricalPricesChartArgs {
	symbol: string;
	fractionDigits: number;
	startDate: string;
	endDate: string;
	displayFactor?: number;
}

interface GetHistoryItem {
	symbol: string;
	timestamp: string;
	tradingDay: string;
	close: number;
}

export default class HistoricalPricesChart extends Component<HistoricalPricesChartArgs> {
	@service marketData: any;

	@tracked historicalPrices: GetHistoryItem[] = [];

	constructor(owner: any, args: HistoricalPricesChartArgs) {
		super(owner, args);
		this.fetchHistoricalPricesTask.perform();
	}

	get isLoading() {
		return this.fetchHistoricalPricesTask.last?.isRunning;
	}

	get labels(): string[] {
		return this.historicalPrices.map((item) => {
			const luxonDate = DateTime.fromISO(item.tradingDay);
			const date = luxonDate.toJSDate();

			const formattedDate = new Intl.DateTimeFormat('en-US', {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
			}).format(date);
			return formattedDate;
		});
	}

	get data(): number[] {
		return this.historicalPrices.map((item) => (this.args.displayFactor ? item.close * this.args.displayFactor : item.close));
	}

	yScaleOptions = {
		ticks: {
			callback: (value: number) => {
				return value.toFixed(this.args.fractionDigits);
			},
		},
	};

	@action
	tooltipBodyTextCallback(bodyText: string) {
		return Intl.NumberFormat(undefined, {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: this.args.fractionDigits,
			maximumFractionDigits: this.args.fractionDigits,
		}).format(+bodyText);
	}

	fetchHistoricalPricesTask = task(this, async () => {
		const symbol = this.args.symbol;
		const startDate = this.args.startDate;
		const endDate = this.args.endDate;

		await fetch(
			`https://ondemand.websol.barchart.com/getHistory.json?` +
				new URLSearchParams({
					apikey: 'e1e7b7d8187322d75e97b1c91fa2839d',
					symbol,
					startDate,
					endDate,
					type: 'daily',
				}),
			{
				method: 'GET',
				headers: {},
			}
		)
			.then(async (response) => {
				const contentType = response.headers.get('content-type');
				if (!response.ok || !contentType || !contentType.includes('application/json')) {
					throw new Error();
				}

				await response
					.json()
					.then((response) => response.results)
					.then((data: GetHistoryItem[]) => {
						if (!data) return;

						this.historicalPrices = data;
					});
			})
			.catch((error) => {
				console.error('Failed to retrieve Historical Prices. ' + error);

				this.historicalPrices = [];
			});
	});
}
