import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'vault-client/config/environment';
import * as Sentry from '@sentry/ember';
import mixpanel from 'mixpanel-browser';
import ENV from 'vault-client/config/environment';
import 'ember-power-select/styles';
import posthog from 'posthog-js';
import { removeSensitiveParams } from 'vault-client/utils/analytics-tracking';

import {
	Chart,
	LineController,
	LineElement,
	PointElement,
	LinearScale,
	Title,
	CategoryScale,
	TimeSeriesScale,
	Tooltip,
	Legend,
	Filler,
	BarController,
	BarElement,
} from 'chart.js';

import { BoxPlotController, BoxAndWiskers } from '@sgratzl/chartjs-chart-boxplot';
import 'chartjs-plugin-dragdata';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-luxon';
// Register once at app level to avoid issues with plugins
Chart.register(
	LineController,
	LineElement,
	PointElement,
	LinearScale,
	Title,
	CategoryScale,
	TimeSeriesScale,
	Tooltip,
	Legend,
	Filler,
	BarController,
	BarElement,
	BoxPlotController,
	BoxAndWiskers,
	annotationPlugin,
	ChartDataLabels
);

// Do not show data labels by default
Chart.defaults.set('plugins.datalabels', {
	display: false,
});

Sentry.init({
	dsn: 'https://d6666cad04294a3b95488b2b7a309e49@o32766.ingest.sentry.io/5883321',
	// Added to eliminate Failed to Fetch errors. Check if flag affects Sentry replays
	transportOptions: { fetchOptions: { keepalive: false } },
	// Could suppress CORS errors from reporting to Sentry
	ignoreErrors: [
		'TypeError: Failed to fetch',
		'TypeError: NetworkError when attempting to fetch resource.',
		'ApolloError: Response not successful: Received status code 401',
		'ApolloError: Failed to fetch',
	],
	beforeSend(event, hint) {
		let error = hint.originalException;

		// ignore aborted route transitions from the Ember.js router
		// ignore invalid token errors
		if (error && (error.name === 'TransitionAborted' || error.name === 'InvalidTokenError')) {
			return null;
		}

		return event;
	},
	// Sets sample rate for *all* sessions
	replaysSessionSampleRate: ENV.environment === 'production' ? 0.1 : 0.5,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	integrations: [new Sentry.Replay()],
});

if (ENV.environment === 'production') {
	mixpanel.init(ENV.mixpanelToken, {
		debug: false,
	});
}

//PostHog will be in all environments Prod/Non-Prod
const isProd = window?.location?.hostname === ENV.APP.PRODUCTION_HOST_NAME && ENV.environment === 'production';
posthog.init(isProd ? ENV.postHogTokenProd : ENV.postHogToken,
  {
    api_host: ENV.postHogApi,
    sanitize_properties: function (properties, _event) {
      if (properties[`$current_url`]) {
        properties[`$current_url`] = removeSensitiveParams(properties[`$current_url`]);
      }
    return properties;
  }
});

export default class App extends Application {
	modulePrefix = config.modulePrefix;
	podModulePrefix = config.podModulePrefix;
	Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
