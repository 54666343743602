import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isLoading}}\n\t<div ...attributes>\n\t\t<Vault::UiLoadingSpinner />\n\t</div>\n{{else if this.isDataEmpty}}\n\t<div ...attributes>\n\t\t<Vault::UiSimpleWell class='max-w-120 relative transform -translate-y-1/2 top-1/2 m-auto'>\n\t\t\t<:header>\n\t\t\t\t<div class='text-center align-middle'>Data Not Found</div>\n\t\t\t</:header>\n\t\t</Vault::UiSimpleWell>\n\t</div>\n{{else}}\n\t<div id={{this.legendId}}></div>\n\t<div class='relative' ...attributes>\n\t\t<Chart\n\t\t\t@id={{this.chartId}}\n\t\t\t@type='line'\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@data={{this.historicalFuturesData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@plugins={{this.plugins}}\n\t\t/>\n\t</div>\n{{/if}}", {"contents":"{{#if this.isLoading}}\n\t<div ...attributes>\n\t\t<Vault::UiLoadingSpinner />\n\t</div>\n{{else if this.isDataEmpty}}\n\t<div ...attributes>\n\t\t<Vault::UiSimpleWell class='max-w-120 relative transform -translate-y-1/2 top-1/2 m-auto'>\n\t\t\t<:header>\n\t\t\t\t<div class='text-center align-middle'>Data Not Found</div>\n\t\t\t</:header>\n\t\t</Vault::UiSimpleWell>\n\t</div>\n{{else}}\n\t<div id={{this.legendId}}></div>\n\t<div class='relative' ...attributes>\n\t\t<Chart\n\t\t\t@id={{this.chartId}}\n\t\t\t@type='line'\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@data={{this.historicalFuturesData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@plugins={{this.plugins}}\n\t\t/>\n\t</div>\n{{/if}}","moduleName":"vault-client/components/seasonal-futures-chart.hbs","parseOptions":{"srcName":"vault-client/components/seasonal-futures-chart.hbs"}});
import Component from '@glimmer/component';
import { Chart, ChartData, ChartOptions, ScriptableTooltipContext } from 'chart.js';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime, Interval } from 'luxon';
import {
	FutureFilterDTO,
	Query_FuturesArgs,
	Future,
	Product,
	ProductFilterDTO,
	Query_ProductsArgs,
	Maybe,
} from 'vault-client/types/graphql-types';
import { task } from 'ember-concurrency';
import { guidFor } from '@ember/object/internals';
import { CMEMonthCodes } from 'vault-client/utils/cme-month-codes';
import { tracked } from '@glimmer/tracking';
import getCSSVariable from 'vault-client/utils/get-css-variable';
import { calculateAutoLabelYOffset } from 'vault-client/utils/chart-utils';
interface SessionalFuturesChartArgs {
	id: string;
	productSlug: string;
	futureExpirationDate: string;
	displayTitle?: boolean;
	colors?: string[];
}

const QUERY_FUTURES = gql`
	query queryFutures($where: FutureFilterDTO!, $limit: Float) {
		Futures(where: $where, limit: $limit, orderBy: { displayExpiresAt: Desc }) {
			id
			name
			expiresAt
			displayExpiresAt
			barchartSymbol
			Product {
				id
				name
			}
		}
	}
`;

const Query_Product = gql`
	query queryProduct($where: ProductFilterDTO) {
		Products(where: $where) {
			id
			barchartRootSymbol
			name
		}
	}
`;

type historicalPriceResponse = {
	symbol: string;
	timestamp: string;
	tradingDay: string;
	close: number;
}[];

type FuturesGetQuoteResponse = {
	symbol: string;
	expirationDate: string;
	year: string;
};

type FuturesQuery = {
	__typename?: 'Query';
	Futures: Future[];
};

type ProductsQuery = {
	__typename?: 'Query';
	Products: Product[];
};

enum AverageLabels {
	fiveYear = '5 Year Avg',
	tenYear = '10 Year Avg',
	fifteenYear = '15 Year Avg',
}

const DEFAULT_CHART_COLORS = [
	getCSSVariable('--brand-interactive-blue-70'),
	getCSSVariable('--brand-orange-40'),
	getCSSVariable('--brand-lime-40'),
	getCSSVariable('--brand-lemon-40'),
	getCSSVariable('--brand-teal-60'),
	getCSSVariable('--brand-purple-50'),
];

export default class thisSessionalFuturesChart extends Component<SessionalFuturesChartArgs> {
	queryFutures = useQuery<FuturesQuery, Query_FuturesArgs>(this, () => [QUERY_FUTURES, { variables: { where: this.futuresWhere } }]);
	queryProduct = useQuery<ProductsQuery, Query_ProductsArgs>(this, () => [
		Query_Product,
		{
			variables: { where: this.productsWhere },
		},
	]);

	@tracked futures: FuturesGetQuoteResponse[] = [];
	@tracked historicalFuturesData: { [year: string]: { day: string; price: number | null }[] } | null = null;

	constructor(owner: any, args: SessionalFuturesChartArgs) {
		super(owner, args);
		this.fetchData.perform();
	}

	get colors(): string[] {
		return this.args.colors ?? DEFAULT_CHART_COLORS;
	}

	get month(): number {
		return DateTime.fromISO(this.args.futureExpirationDate).month;
	}

	get year(): number {
		return DateTime.fromISO(this.args.futureExpirationDate).year;
	}

	get product(): Product | null {
		return this.queryProduct.data?.Products[0] ?? null;
	}

	get isDataEmpty() {
		return !Object.keys(this.yearAverageData).some(
			(key: AverageLabels) => !!this.yearAverageData[key] && this.yearAverageData[key].length > 0
		);
	}

	get annotationIndex() {
		let minOffset = Infinity;
		let minOffsetIndex = 0;
		const now = DateTime.now();

		this.labels.forEach((date, index) => {
			const labelDate = DateTime.fromFormat(date, 'LLL dd');
			const diff = labelDate < now ? Interval.fromDateTimes(labelDate, now) : Interval.fromDateTimes(now, labelDate);
			const diffDays = diff.length('days');
			if (diffDays <= minOffset) {
				minOffset = diffDays;
				minOffsetIndex = index;
			}
		});

		return minOffsetIndex;
	}

	get plugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;
		const annotationIndex = this.annotationIndex;

		return [
			{
				afterUpdate: function (chart: Chart) {
					function toggleDataset(event: PointerEvent): void {
						const index = (event.currentTarget as HTMLElement).dataset.index;
						if (index) {
							const meta = chart.getDatasetMeta(+index);
							meta.hidden = !meta.hidden ? true : false;
							chart.update();
						}
					}

					// Make sure we're applying the legend to the right chart
					if (chart.canvas.id === chartId) {
						const legend = document.getElementById(legendId)!;
						const ul = legend?.querySelector('ul') ?? document.createElement('ul');
						ul.classList.add('flex');

						// Remove old legend items
						while (ul.firstChild) {
							ul.firstChild.remove();
						}

						while (legend.firstChild) {
							legend.firstChild.remove();
						}

						chart.data.datasets.forEach((dataset, index) => {
							const legendItem = document.createElement('li');
							legendItem.classList.add('flex', 'flex-col', 'mr-6', 'text-brand-blue-80', 'cursor-pointer');

							// Strike out legend item if hidden
							const meta = chart.getDatasetMeta(index);
							legendItem.dataset.index = index.toString(10);
							legendItem.onclick = toggleDataset;

							const todaysValue = dataset.data[annotationIndex] as number | null;

							legendItem.innerHTML += `
								<div class="text-sm flex items-center ${meta.hidden ? 'line-through' : ''}">
									<span style="background-color: ${dataset.backgroundColor}" class="w-2 h-2 rounded-full inline-block mr-1"></span>
									${dataset.label}
								</div>
								<div class="ml-2 font-bold text-brand-gray-60">
									${
										todaysValue
											? // Find the average of the data in the dataset, excluding null and undefined values
											  Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2 }).format(todaysValue)
											: '\u2013' // En dash unicode
									}
								</div>
								`;

							ul.append(legendItem);
						});

						return legend?.insertBefore(ul, legend.firstChild);
					}
					return;
				},
			},
		];
	}

	get chartId() {
		return this.args.id ?? `${guidFor(this)}-seasonal-futures-chart`;
	}

	get legendId() {
		return `${this.chartId}-legend`;
	}

	get tooltipId() {
		return `${this.chartId}-tooltip`;
	}

	get isLoading() {
		return this.fetchfuturesBarchartDataTask.last?.isRunning || this.fetchFuturesTask.last?.isRunning;
	}

	get yearAverageData() {
		const averages: { [k in AverageLabels]: (number | null)[] } = {
			[AverageLabels.fiveYear]: [],
			[AverageLabels.tenYear]: [],
			[AverageLabels.fifteenYear]: [],
		};

		if (this.historicalFuturesData && Object.keys(this.historicalFuturesData).length > 0) {
			this.labels.forEach((_label, labelIndex) => {
				let fiveYearTotal = 0;
				let tenYearTotal = 0;
				let fifteenYearTotal = 0;
				let yearCount = 0;

				const years = Object.keys(this.historicalFuturesData!).sort((a, b) => (a < b ? 1 : -1));

				for (const year of years) {
					// Return early if yearCount is satisfied
					if (yearCount >= 15) break;

					// Skip this loop if value is null
					const value = this.historicalFuturesData![year]?.[labelIndex]?.price ?? null;
					if (value === null) continue;

					if (yearCount < 5) {
						fiveYearTotal += value;
					}

					if (yearCount < 10) {
						tenYearTotal += value;
					}

					if (yearCount < 15) {
						fifteenYearTotal += value;
					}

					yearCount++;
				}

				const fiveYearAverage = fiveYearTotal / (yearCount < 5 ? yearCount : 5);
				averages[AverageLabels.fiveYear].push(fiveYearAverage);

				const tenYearAverage = tenYearTotal / (yearCount < 10 ? yearCount : 10);
				averages[AverageLabels.tenYear].push(tenYearAverage);

				const fifteenYearAverage = fifteenYearTotal / (yearCount < 15 ? yearCount : 15);
				averages[AverageLabels.fifteenYear].push(fifteenYearAverage);
			});
		}

		return averages;
	}

	get chartData(): ChartData {
		const datasets = [
			{
				label: AverageLabels.fiveYear,
				data: this.normalize(this.yearAverageData[AverageLabels.fiveYear]),
				backgroundColor: this.colors[1],
				borderColor: this.colors[1],
			},
			{
				label: AverageLabels.tenYear,
				data: this.normalize(this.yearAverageData[AverageLabels.tenYear]),
				backgroundColor: this.colors[2],
				borderColor: this.colors[2],
			},
			{
				label: AverageLabels.fifteenYear,
				data: this.normalize(this.yearAverageData[AverageLabels.fifteenYear]),
				backgroundColor: this.colors[3],
				borderColor: this.colors[3],
			},
		];

		return {
			labels: this.labels,
			datasets,
		};
	}

	get chartOptions(): ChartOptions<'line'> {
		const getOrCreateTooltip = (chart: Chart) => {
			let tooltipEl: HTMLDivElement | null | undefined = chart.canvas.parentNode?.querySelector('div');
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = this.tooltipId;
				tooltipEl.classList.add(
					'line-chart-tooltip',
					'line-chart-tooltip-bottom',
					'border',
					'border-brand-blue-80',
					'bg-white',
					'text-brand-gray-90',
					'absolute',
					'pointer-events-none',
					'transform',
					'-translate-x-2/4',
					'transition-all',
					'ease-linear',
					'duration-50',
					'opacity-1',
					'z-10',
					'text-left',
					'p-2',
					'rounded-sm'
				);

				const tooltipUL = document.createElement('ul');
				tooltipUL.classList.add('tooltipul');

				tooltipEl.append(tooltipUL);
				chart.canvas.parentNode?.append(tooltipEl);
			}
			return tooltipEl;
		};

		const customTooltip = (context: ScriptableTooltipContext<'line'>) => {
			const { chart, tooltip } = context;
			const tooltipEl = getOrCreateTooltip(chart);
			const tooltipUL = tooltipEl.querySelector('ul')!;

			if (tooltip.opacity === 0) {
				tooltipEl.classList.replace('opacity-1', 'opacity-0');
				return;
			}

			if (tooltip.body) {
				const titleLines = tooltip.title || [];
				const dataPoints = tooltip.dataPoints;
				const tooltipLI = document.createElement('li');
				tooltipLI.classList.add('whitespace-nowrap');

				// Title Loop
				titleLines.forEach((title: string) => {
					const formattedTitle = title;
					tooltipUL.appendChild(tooltipLI);

					const tooltipSpan = document.createElement('span');
					tooltipSpan.classList.add('text-sm', 'font-semibold', 'text-brand-blue-80', 'block');
					tooltipLI.appendChild(tooltipSpan);

					const tooltipTitle = document.createTextNode(formattedTitle);
					tooltipSpan.appendChild(tooltipTitle);
				});

				// Body Loop
				const tooltipBodyP = document.createElement('p');
				dataPoints.forEach((dataPoint, i: number) => {
					const displayBlockSpan = document.createElement('span');
					displayBlockSpan.classList.add('block', 'text-xs');
					const colors = tooltip.labelColors[i];
					const colorCircle = document.createElement('span');
					colorCircle.classList.add('rounded-full', 'w-2', 'h-2', 'inline-block', 'mr-2');
					colorCircle.style.background = colors.borderColor as string;
					colorCircle.style.border = colors.borderColor as string;

					const formattedValue = Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2 }).format(dataPoint.raw as number);

					const textLabel = document.createTextNode(`${formattedValue} ${dataPoint.dataset.label}`);

					// Append color label and text
					displayBlockSpan.appendChild(colorCircle);
					displayBlockSpan.appendChild(textLabel);
					tooltipBodyP.appendChild(displayBlockSpan);
				});

				// Remove old children
				while (tooltipUL.firstChild) {
					tooltipUL.firstChild.remove();
				}

				// Add new children
				tooltipUL.appendChild(tooltipLI);
				tooltipLI.appendChild(tooltipBodyP);
				tooltipEl.classList.replace('opacity-0', 'opacity-1');

				// Position tooltip
				const position = context.chart.canvas.getBoundingClientRect();

				tooltipEl.style.left = context.chart.canvas.offsetLeft + tooltip.caretX + 'px';
				tooltipEl.style.bottom = position.height - 24 + 'px';
			}
		};

		return {
			maintainAspectRatio: false,
			spanGaps: true,
			responsive: true,
			normalized: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					top: 35,
				},
			},
			datasets: {
				line: {
					pointRadius: 0,
					tension: 0.3,
				},
			},
			scales: {
				y: {
					min: 0,
					max: 1,
					ticks: {
						font: {
							size: 12,
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-90'),
						callback: (value: number) => {
							return new Intl.NumberFormat('en-US', { style: 'percent' }).format(value);
						},
					},
				},
				x: {
					grid: {
						display: false,
					},
					ticks: {
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-90'),
						font: {
							size: 12,
						},
					},
				},
			},
			plugins: {
				title: {
					display: this.args.displayTitle ?? true,
					text: this.product ? `${this.monthNumbertoMonthName(this.month, 'long')} ${this.product.name} Seasonality` : '',
				},
				legend: {
					display: false,
				},
				tooltip: {
					external: customTooltip,
					enabled: false,
					displayColors: false,
					callbacks: {
						label: (context) => {
							return `${context.dataset.label}: ${Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2 }).format(
								context.parsed.y
							)}`;
						},
					},
				},
				annotation: {
					clip: false,
					annotations: {
						line1: {
							display: true,
							type: 'line',
							adjustScaleRange: true,
							xMin: this.annotationIndex,
							xMax: this.annotationIndex,
							borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-blue-70'),
							borderDash: [5],
							label: {
								content: 'Today',
								display: true,
								position: 'end',
								color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-90'),
								borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-blue-70'),
								borderWidth: 1,
								backgroundColor: 'white',
								drawTime: 'afterDraw',
								yAdjust: (ctx) => {
									return calculateAutoLabelYOffset(ctx, this.annotationIndex);
								},
								font: {
									size: 12,
								},
							},
						},
					},
				},
			},
		};
	}

	fetchData = task(this, async () => {
		await this.queryProduct.promise;

		await this.fetchFuturesTask.perform();

		await this.fetchfuturesBarchartDataTask.perform();
	});

	fetchFuturesTask = task(this, async () => {
		const futurePromises = this.futureBarchartSymbols.map((symbol) => {
			return fetch(
				'https://ondemand.websol.barchart.com/getQuote.json?' +
					new URLSearchParams({
						apikey: 'e1e7b7d8187322d75e97b1c91fa2839d',
						symbols: symbol,
						type: 'daily',
						fields: 'expirationDate,year',
					}),
				{
					method: 'GET',
					headers: {},
				}
			);
		});

		const tempFutures = await Promise.all(futurePromises).then(async (responses) => {
			return Promise.all(
				responses.map(async (response) => {
					const contentType = response.headers.get('content-type');
					if (!response.ok || !contentType || !contentType.includes('application/json')) {
						console.warn(`Failed to Retrieve Future Information Data`);
						return null;
					}
					return await response
						.json()
						.then((response) => response.results)
						.then((data: FuturesGetQuoteResponse[]) => {
							if (!data || data.length === 0) return null;
							return data[0];
						});
				})
			);
		});

		this.futures = tempFutures.flatMap((x) => (x ? [x] : [])).sortBy('expirationDate');
	});

	get earliestExpirationDate() {
		const earliestFuture = this.futures.find((future) => {
			return future.expirationDate;
		});

		if (earliestFuture == undefined) {
			throw new Error('No future with expiration found');
		}

		return DateTime.fromISO(earliestFuture.expirationDate);
	}

	fetchfuturesBarchartDataTask = task(this, { restartable: true }, async () => {
		const historicalFuturesDataPromises = this.futures.map((future) => {
			const barchartSymbol = future.symbol;
			if (!barchartSymbol) return Promise.resolve(new Response(null, { status: 400 }));

			const futureExpiresAtDateTime = DateTime.fromISO(future.expirationDate);
			const isExpired = futureExpiresAtDateTime < DateTime.now();

			const endDate = this.earliestExpirationDate.set({ year: isExpired ? futureExpiresAtDateTime.year : DateTime.now().year });
			const startDate = endDate.minus({ days: 364 });

			return fetch(
				'https://ondemand.websol.barchart.com/getHistory.json?' +
					new URLSearchParams({
						apikey: 'e1e7b7d8187322d75e97b1c91fa2839d',
						symbol: barchartSymbol,
						type: 'daily',
						startDate: startDate.toFormat('yyyyLLdd'),
						endDate: endDate.toFormat('yyyyLLdd'),
						close: 'true',
					}),
				{
					method: 'GET',
					headers: {},
				}
			);
		});

		const historicalFuturesData: { [year: string]: { day: string; price: number | null }[] } = {};

		await Promise.all(historicalFuturesDataPromises).then(async (responses) => {
			for (const response of responses) {
				const contentType = response.headers.get('content-type');
				if (!response.ok || !contentType || !contentType.includes('application/json')) {
					console.warn(`Failed to Retrieve Historical Future Data`);
					continue;
				}

				await response
					.json()
					.then((response) => response.results)
					.then((data: historicalPriceResponse) => {
						if (!data) return;

						let [min, max] = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];

						for (const day of data) {
							if (day.close < min) {
								min = day.close;
							}

							if (day.close > max) {
								max = day.close;
							}
						}

						const year = this.futureSymbolToYear[data[data.length - 1].symbol];

						data.map((day) => {
							const dayString = DateTime.fromISO(day.tradingDay).toFormat('LLL dd');
							if (historicalFuturesData[year.toString()] == undefined) {
								historicalFuturesData[year.toString()] = [];
							}
							const close: number | null = day.close;

							let normalizedPrice: number | null = null;

							if ((close === min && close === max) || close > max) {
								normalizedPrice = 1;
							} else if (close < min) {
								normalizedPrice = 0;
							} else {
								normalizedPrice = close ? (close - min) / (max - min) : null;
							}

							historicalFuturesData[year.toString()].push({ day: dayString, price: normalizedPrice });
						});
					});
			}
		});
		this.historicalFuturesData = historicalFuturesData;
	});

	get labels(): string[] {
		if (!this.futures || this.futures.length === 0) return [];
		if (!this.historicalFuturesData || Object.keys(this.historicalFuturesData).length == 0) return [];

		const expiredFutureYears = this.futures
			.filter((future) => {
				return DateTime.fromISO(future.expirationDate) < DateTime.now();
			})
			.map((future) => this.futureSymbolToYear[future.symbol]);

		const availableYears = Object.keys(this.historicalFuturesData);

		const years = expiredFutureYears ?? availableYears;

		const medianLength = years.reduce((acc, year) => acc + (this.historicalFuturesData![year]?.length ?? 0), 0) / years.length;

		let shortestLength = Infinity;
		let shortestYear = expiredFutureYears[0];

		// Use the median Length to check that the selected labels aren't outliers (for example missing data on an old future)
		for (const year of years) {
			const data = this.historicalFuturesData[year];
			if (data && data.length < shortestLength && medianLength - data.length <= 10) {
				shortestLength = data.length;
				shortestYear = year;
			}
		}

		const labels = this.historicalFuturesData[shortestYear]?.map((tradingDay) => tradingDay.day) ?? [];

		return labels;
	}

	get productsWhere(): ProductFilterDTO {
		return { slug: { equals: this.args.productSlug } };
	}

	get futuresWhere() {
		const where: FutureFilterDTO = {};
		where.isStandardContractSize = { equals: true };
		where.Product = {
			slug: {
				equals: this.args.productSlug,
			},
		};

		where.OR = [];

		const futureDateTime = DateTime.fromISO(this.args.futureExpirationDate);

		for (let i = 0; i < 15; i++) {
			where.OR.push({
				displayExpiresAt: {
					gte: futureDateTime.minus({ years: i }).startOf('month').toISODate(),
					lte: futureDateTime.minus({ years: i }).endOf('month').toISODate(),
				},
			});
		}

		return where;
	}

	get futureBarchartSymbols() {
		if (!this.product) return [];
		const root = (this.product.barchartRootSymbol ?? '') + CMEMonthCodes[this.month] ?? null;

		return Array.from(Array(17), (_, i) => root + (this.year - i));
	}

	get futureSymbolToYear() {
		return this.futures.reduce((acc, future) => {
			if (future.symbol) {
				acc[future.symbol] = +future.year;
			}
			return acc;
		}, {} as { [futureSymbol: string]: number });
	}

	monthNumbertoMonthName(monthNumber: number, format: 'short' | 'long' = 'short') {
		const date = new Date();
		date.setMonth(monthNumber - 1);

		return date.toLocaleString('en-US', {
			month: format,
		});
	}

	normalize(arr: Maybe<number>[]): Maybe<number>[] {
		let [min, max] = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];

		for (const num of arr) {
			if (!num) continue;

			if (num < min) {
				min = num;
			}

			if (num > max) {
				max = num;
			}
		}

		return arr.map((x) => (x ? (x - min) / (max - min) : x));
	}
}
