import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import BusinessesBusinessFeedIngredientsDetailRoute from 'vault-client/routes/businesses/business/feed-ingredient';
import {
	BusinessEntityRole,
	FeedIngredient,
	FeedIngredientPricingMethodology,
	FeedIngredientUpdateDTO,
	FeedIngredientUsageSetByMonthPerDatePayload,
	Future,
	GrainFeedPlan,
	Maybe,
	Mutation,
	Mutation_setFeedIngredientForecastedUsageByMonthArgs,
	Mutation_setFeedIngredientPriceByMonthArgs,
	Mutation_setLivestockGroupFeedUsagesForBusinessArgs,
	PhysicalFeedTransaction,
	Product,
	SwineLivestockGroup,
	TypeOfLivestockGroup,
} from 'vault-client/types/graphql-types';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { FeedIngredientBase } from 'vault-client/types/vault-client';
import RouterService from '@ember/routing/router-service';
import { guidFor } from '@ember/object/internals';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import MarketDataService from 'vault-client/services/market-data';
import {
	UnitOfMeasure,
	generateCreateEditFeedIngredientData,
	parseCreateEditFeedIngredientData,
	setLocallyStoredUnitOfMeasure,
	updateCreateEditFeedIngredientFormData,
	updateFeedIngredient,
	getIngredientTableId,
	FeedPriceType,
	getFeedIngredientMarketPrice,
	convertTonsToPricingUnit,
	convertTonsToUnitOfMeasure,
	convertPerPricingUnitToPerUnitOfMeasure,
	PhysicalContractType,
	createTrackedEmptyPhysicalFeedTransactionData,
	updatePhysicalFeedTransaction,
	validateEditPhysicalFeedTransactionFormData,
	validateAddFormData,
	addPhysicalFeedTransaction,
	setFeedIngredientForecastedUsageByMonth,
	setLivestockGroupFeedUsageForBusiness,
	setLocallyStoredFeedIngredientValue,
	editFeedIngredientPrice,
	FEED_YEAR_OPTIONS,
	CURRENT_FEED_YEAR,
} from 'vault-client/utils/feed-utils';
import { CreateEditFeedIngredientData } from 'vault-client/components/create-edit-feed-ingredient-form';
import { task } from 'ember-concurrency';
import { getInvalidElements, isFormValid } from 'vault-client/utils/form-validation';
import { PricingUnit } from 'vault-client/utils/product-slug-to-pricing-unit';
import { FeedPercentPurchasedChartDataItem } from 'vault-client/components/feed-percent-purchased-chart';
import { getOwner, setOwner } from '@ember/application';
import { PercentPurchasedByMonthDataItem } from 'vault-client/components/percent-purchased-by-month-chart';
import { AddEditPhysicalFeedTransactionFormData } from 'vault-client/components/add-edit-physical-feed-transaction-form';
import { TrackedObject } from 'tracked-built-ins/.';
import { getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';
import Big from 'big.js';

type PurchasedVsNotPurchasedRow = {
	month: string;
	usageInUnitsOfMeasure: number | null;
	purchasedInUnitsOfMeasure: number;
	avgPurchasedPricePerUnitOfMeasure: number;
	totalPurchasedCost: number;
	notPurchasedInUnitsOfMeasure: number;
	displayExpiresAt: string | null;
	latestPricePerUnitOfMeasure: number | null;
	fractionDigits: number | null;
	displayFactor: number | null;
	flatPricePerUnitOfMeasure: number | null;
	cmeUsdBasisInUnitOfMeasure: number | null;
	cmePercentageBasis: number | null;
	estimatedTotalCostNotPurchased: number | null;
	totalFeedExpense: number | null;
};

type PurchasedVsNotPurchasedTotalRow = {
	month: 'Total';
	purchasedInUnitsOfMeasure: number;
	notPurchasedInUnitsOfMeasure: number;
	estimatedTotalCostNotPurchased: number;
	totalFeedExpense: number;
};

type FeedPercentPurchasedByMonthRow = {
	month: string;
	percentPurchasedFlatContracts: number | null;
	purchasedFlatContracts: number | null;
	// TODO: Commented out until support is added for other contract types
	// percentPurchasedHTAContracts: number | null;
	// purchasedHTAContracts: number | null;
	// percentPurchasedBasisContracts: number | null;
	// purchasedBasisContracts: number | null;
	// percentPurchasedNoPriceContracts: number | null;
	// purchasedNoPriceContracts: number | null;
	percentNotPurchased: number | null;
	notPurchased: number | null;
};

type PurchasedVsNotPurchasedMonthOptions = {
	month: string;
	feedIngredient: FeedIngredient;
	currentUnitOfMeasure: CurrentUnitOfMeasure;
	forecastedConsumptionInTons?: number;
	totalPurchasedCostInUsd?: number;
	purchasedInTons?: number;
	physicalFeedTransactions?: PhysicalFeedTransaction[];
	future?: Future | null;
};

type MonthlyContractData = {
	type: PhysicalContractType;
	percentage: number;
	amount: number;
	avgPrice: number | null;
	totalCost: number;
};

type ContractsTableRow = {
	id: string;
	type: 'Flat' | 'HTA' | 'Basis' | 'No Price';
	business?: string;
	ingredient?: string;
	contractNumber?: string;
	futureInstrument?: Future;
	amount?: number;
	basis?: number;
	flatPrice?: number;
	deliveryStartDate: string;
	deliveryEndDate: string;
	seller?: string;
	location?: string;
};

type TargetRow = {
	id: string;
	orderType: string | null;
	business?: Maybe<string>;
	ingredient?: Maybe<string>;
	contractNumber?: Maybe<string>;
	futurePrice?: Maybe<number>;
	futuresMonth?: Maybe<string>;
	symbol?: Maybe<string>;
	tons?: Maybe<number>;
	basis?: Maybe<number>;
	flatPrice?: Maybe<number>;
	deliveryStartDate?: Maybe<string>;
	deliveryEndDate?: Maybe<string>;
	seller?: Maybe<string>;
	location?: Maybe<string>;
};

class PurchasedVsNotPurchasedMonth {
	POUNDS_PER_TON = 2000;

	_month: string;
	_feedIngredient: FeedIngredient;
	_currentUnitOfMeasure: CurrentUnitOfMeasure;
	_forecastedConsumptionInTons: number;
	_totalPurchasedCostInUsd: number;
	_purchasedInTons: number;

	_physicalFeedTransactions: PhysicalFeedTransaction[];
	_future: Future | null;

	@service declare marketData: MarketDataService;

	constructor(context: Controller, options: PurchasedVsNotPurchasedMonthOptions) {
		setOwner(this, getOwner(context));

		this._month = options.month;
		this._feedIngredient = options.feedIngredient;
		this._currentUnitOfMeasure = options.currentUnitOfMeasure;
		this._forecastedConsumptionInTons = options.forecastedConsumptionInTons ?? 0;
		this._totalPurchasedCostInUsd = options.totalPurchasedCostInUsd ?? 0;
		this._purchasedInTons = options.purchasedInTons ?? 0;
		this._physicalFeedTransactions = options.physicalFeedTransactions ?? [];
		this._future = options.future ?? null;

		this.registerFuture(this._future);
	}

	get month() {
		return this._month;
	}

	get futurePricingUnit(): PricingUnit {
		// This view currently only supports corn, soybean meal, and non-product specific ingredients
		const hedgeProductSlug = this.hedgeProduct?.slug;
		if (hedgeProductSlug === 'grain-corn') {
			return 'Bushel';
		} else {
			return 'Ton';
		}
	}

	get unitOfMeasure() {
		return this._currentUnitOfMeasure.value;
	}

	// Ingredient
	get feedIngredient() {
		return this._feedIngredient;
	}

	get feedCategory() {
		return this.feedIngredient.FeedCategory;
	}

	get hedgeProduct() {
		return this.feedCategory.HedgeProduct ?? null;
	}

	get hedgeProductSlug() {
		return this.hedgeProduct?.slug ?? null;
	}

	get customMonthPricing() {
		return this.feedIngredient?.FeedIngredientPrices.find((price) => price.date === this.month) ?? null;
	}

	get flatPricePerTon() {
		return (
			this.getCustomMonthPrice(FeedIngredientPricingMethodology.Flat) ??
			this.feedIngredient.flatPricePerTon ??
			this.feedCategory?.defaultFlatPricePerTon ??
			null
		);
	}

	get flatPricePerUnitOfMeasure() {
		const flatPricePerTon = this.flatPricePerTon;
		return flatPricePerTon != null
			? convertPerPricingUnitToPerUnitOfMeasure(flatPricePerTon, 'Ton', this.unitOfMeasure, this.hedgeProductSlug)
			: null;
	}

	get cmeUsdBasis() {
		return (
			this.getCustomMonthPrice(FeedIngredientPricingMethodology.CmeUsdBasis) ??
			this.feedIngredient.cmeUsdBasis ??
			this.feedCategory?.defaultCmeUsdBasis ??
			null
		);
	}

	get cmeUsdBasisInUnitOfMeasure() {
		const cmeUsdBasis = this.cmeUsdBasis;
		return cmeUsdBasis != null
			? convertPerPricingUnitToPerUnitOfMeasure(cmeUsdBasis, this.futurePricingUnit, this.unitOfMeasure, this.hedgeProductSlug)
			: null;
	}

	get cmePercentageBasis() {
		return (
			this.getCustomMonthPrice(FeedIngredientPricingMethodology.CmePercentBasis) ??
			this.feedIngredient.cmePercentageBasis ??
			this.feedCategory?.defaultCmePercentageBasis ??
			null
		);
	}

	// Future
	get future() {
		return this._future;
	}

	get displayExpiresAt() {
		return this.future?.displayExpiresAt ?? null;
	}

	get displayFactor() {
		return this.future?.SymbolGroup.displayFactor ?? 1;
	}

	get fractionDigits() {
		return this.future?.SymbolGroup.fractionDigits ?? 2;
	}

	get barchartSymbol() {
		return this.future?.barchartSymbol;
	}

	get marketDatum() {
		const barchartSymbol = this.barchartSymbol;
		if (!barchartSymbol) return null;

		return this.marketData.getMarketDatum(this.barchartSymbol) ?? null;
	}

	get fiftyTwoWeekHigh() {
		return this.marketDatum?.fiftyTwoWkHigh as number | null;
	}

	get fiftyTwoWeekHighPerUnitOfMeasure() {
		return this.convertPerPricingUnitToPerUOM(this.fiftyTwoWeekHigh);
	}

	get fiftyTwoWeekLow() {
		return this.marketDatum?.fiftyTwoWkLow as number | null;
	}

	get fiftyTwoWeekLowPerUnitOfMeasure() {
		return this.convertPerPricingUnitToPerUOM(this.fiftyTwoWeekLow);
	}

	get latestPrice() {
		const barchartSymbol = this.barchartSymbol;
		if (!barchartSymbol) return null;

		return this.marketData.getLatestPrice(barchartSymbol) ?? null;
	}

	get latestPricePerUnitOfMeasure() {
		return this.convertPerPricingUnitToPerUOM(this.latestPrice);
	}

	// Feed Price
	get feedMarketPrice() {
		// Returns the market price for the feed ingredient, either based on the future price adjusted by basis, or the flat price per ton
		return getFeedIngredientMarketPrice(this.feedIngredient, this.latestPrice, this.displayFactor, this.month);
	}

	// Volumes
	get forecastedConsumptionInTons() {
		return this._forecastedConsumptionInTons;
	}

	get forecastedConsumptionInLbs() {
		return this._forecastedConsumptionInTons * this.POUNDS_PER_TON;
	}

	get forecastedConsumptionInUnitsOfMeasure() {
		return this.convertTonsToUOM(this.forecastedConsumptionInTons);
	}

	get purchasedInTons() {
		return this._purchasedInTons;
	}

	get purchasedInUnitsOfMeasure() {
		return this.convertTonsToUOM(this.purchasedInTons);
	}

	get notPurchasedInTons() {
		return Math.max(this.forecastedConsumptionInTons - this.purchasedInTons, 0);
	}

	get notPurchasedInUnitsOfMeasure() {
		return this.convertTonsToUOM(this.notPurchasedInTons);
	}

	get notPurchasedInPricingUnits() {
		// Pricing units here will either be tons or bushels, based on the feed ingredient price settings (corn basis is bushels, everything else is tons)
		return this.convertTonsToPricingUnits(this.notPurchasedInTons);
	}

	get notPurchasedPercentage() {
		return this.forecastedConsumptionInTons ? this.notPurchasedInTons / this.forecastedConsumptionInTons : 0;
	}

	// Costs
	get totalPurchasedCostInUsd() {
		return this._totalPurchasedCostInUsd;
	}

	get avgPurchasedPricePerUnitOfMeasure() {
		return this.purchasedInUnitsOfMeasure ? this.totalPurchasedCostInUsd / this.purchasedInUnitsOfMeasure : null;
	}

	get weightedAvgPricePerUnitOfMeasure() {
		const purchasedAndNotPurchasedInUOM = Big(this.purchasedInUnitsOfMeasure ?? 0).plus(this.notPurchasedInUnitsOfMeasure ?? 0);
		if (purchasedAndNotPurchasedInUOM.eq(0)) return 0;
		return Big(this.totalFeedExpense).div(purchasedAndNotPurchasedInUOM).toNumber();
	}

	get estimatedTotalCostNotPurchased() {
		const notPurchasedInPricingUnits = this.notPurchasedInPricingUnits;
		const feedMarketPrice = this.feedMarketPrice;

		let estimatedTotalCostNotPurchased: number | null;

		if (!notPurchasedInPricingUnits) {
			estimatedTotalCostNotPurchased = 0;
		} else if (feedMarketPrice != null) {
			estimatedTotalCostNotPurchased = notPurchasedInPricingUnits * feedMarketPrice;
		} else {
			// If we don't have a market price, we can't calculate the estimated cost
			estimatedTotalCostNotPurchased = null;
		}

		return estimatedTotalCostNotPurchased;
	}

	get totalFeedExpense() {
		return this.totalPurchasedCostInUsd + (this.estimatedTotalCostNotPurchased ?? 0);
	}

	// Flat Contracts
	// TODO: Use this once we get allocated contracts
	get flatContracts() {
		return this._physicalFeedTransactions.filter((transaction) => transaction.flatPrice !== null);
	}

	get flatContractsDataInUnitsOfMeasure(): MonthlyContractData {
		// TODO: Calculate this info from the transactions directly once other contract types are added
		const amount = this.purchasedInUnitsOfMeasure ?? 0;
		// Tons used to avoid an extra conversion. This should all be refactored later
		const forecastedConsumptionInTons = this.forecastedConsumptionInTons;
		const percentage = forecastedConsumptionInTons ? this.purchasedInTons / forecastedConsumptionInTons : 0;
		const avgPrice = this.avgPurchasedPricePerUnitOfMeasure;
		const totalCost = this.totalPurchasedCostInUsd;
		return {
			type: 'Flat Contracts',
			percentage,
			amount,
			avgPrice,
			totalCost,
		};
	}

	getCustomMonthPrice(pricingMethodology: FeedIngredientPricingMethodology) {
		const customMonthPricing = this.customMonthPricing;
		return customMonthPricing?.pricingMethodology === pricingMethodology ? customMonthPricing.price : null;
	}

	registerFuture(future: Future | null) {
		const barchartSymbol = future?.barchartSymbol;

		if (!barchartSymbol) return;

		this.marketData.register(barchartSymbol);
	}

	convertPerPricingUnitToPerUOM(value: number | null) {
		let convertedValue: number | null = null;

		if (value != null) {
			try {
				convertedValue = convertPerPricingUnitToPerUnitOfMeasure(value, this.futurePricingUnit, this.unitOfMeasure, this.hedgeProductSlug);
			} catch (e) {
				console.error(e?.message);
			}
		}

		return convertedValue;
	}

	convertTonsToUOM(tons: number | null) {
		let convertedValue: number | null = null;

		if (tons !== null) {
			try {
				convertedValue = convertTonsToUnitOfMeasure(tons, this.unitOfMeasure, this.hedgeProductSlug);
			} catch (e) {
				console.error(e?.message);
			}
		}

		return convertedValue;
	}

	convertTonsToPricingUnits(tons: number | null) {
		let convertedValue: number | null = null;

		if (tons !== null) {
			try {
				convertedValue = convertTonsToPricingUnit(this.feedIngredient, tons, this.hedgeProductSlug);
			} catch (e) {
				console.error(e?.message);
			}
		}

		return convertedValue;
	}
}

// Singleton class used so that the current unit of measure can be shared between month classes
class CurrentUnitOfMeasure {
	DEFAULT_VALUE: UnitOfMeasure = 'Tons';

	@tracked value: UnitOfMeasure;

	constructor(unitOfMeasure: UnitOfMeasure = 'Tons') {
		this.value = unitOfMeasure;
	}

	reset() {
		this.value = this.DEFAULT_VALUE;
	}
}

export default class FeedIngredientDetailController extends Controller {
	currentUnitOfMeasure: CurrentUnitOfMeasure = new CurrentUnitOfMeasure();

	@service declare router: RouterService;
	@service declare marketData: MarketDataService;

	@tracked startDate: string = CURRENT_FEED_YEAR.startDate;
	@tracked endDate: string = CURRENT_FEED_YEAR.endDate;
	@tracked registeredFutures: Record<string, number> = {};
	@tracked showPercentPurchasedByMonthTable = false;

	// Side panel
	@tracked showEditIngredientTray = false;
	@tracked showEditUsageTray = false;
	@tracked showAddEditPhysicalFeedTransactionTray = false;
	@tracked showEditPriceTray = false;
	@tracked errorMessage: string | null = null;

	// Delete Modal
	@tracked showDeleteIngredientModal = false;

	// Add Edit Physical Feed Transactions
	@tracked editFeedIngredientFormData: CreateEditFeedIngredientData = generateCreateEditFeedIngredientData(
		this.feedIngredient ?? null,
		this.unitOfMeasure,
	);
	@tracked physicalFeedTransactionFormData: AddEditPhysicalFeedTransactionFormData = this.createTrackedEmptyPhysicalFeedTransactionData();
	@tracked physicalFeedTransactionOriginalEditValues: AddEditPhysicalFeedTransactionFormData | null = null;
	@tracked physicalFeedTransactionToDelete: PhysicalFeedTransaction | null = null;

	// Edit Feed Usage
	// Dairy
	@tracked editDairyFeedUsageVariables: Mutation_setFeedIngredientForecastedUsageByMonthArgs | null = null;
	// Swine
	@tracked editLivestockFeedUsageVariables: Mutation_setLivestockGroupFeedUsagesForBusinessArgs | null = null;

	// Edit Price
	@tracked editPriceFormData: Mutation_setFeedIngredientPriceByMonthArgs | null = null;

	id = guidFor(this);
	queryParams = ['startDate', 'endDate'];
	feedIngredientRoutePath = '';
	feedOverviewRoutePath = '';
	declare model: ModelFrom<BusinessesBusinessFeedIngredientsDetailRoute>;

	// TODO: Update this link when contracts/targets page is added
	contractsRoutePath = 'businesses.business.feed-fills';
	targetsRoutePath = 'businesses.business.feed-targets';

	timePeriodOptions = FEED_YEAR_OPTIONS.NUMBER;

	get editIngredientFormId() {
		return `edit-ingredient-form-${this.id}`;
	}

	get tableId() {
		if (!this.feedIngredient) {
			return guidFor(this);
		}
		return getIngredientTableId(this.feedIngredient);
	}

	get customer() {
		return this.model.customer;
	}

	get customerId() {
		return this.customer.id;
	}

	get canWriteOperations() {
		// At this time, foundations clients are prevented from using the write operations
		return this.customer.CurrentUserPermissions.canWriteOperations && !this.isFoundationsClient;
	}

	get percentPurchasedByMonthChartId() {
		return `percent-purchased-by-month-chart-${guidFor(this)}`;
	}

	get addEditPhysicalFeedTransactionFormId() {
		return `add-edit-physical-feed-transaction-form-${guidFor(this)}`;
	}

	get addEditPhysicalFeedTransactionForm() {
		return document.getElementById(this.addEditPhysicalFeedTransactionFormId) as HTMLFormElement | null;
	}

	@action
	setShowPercentPurchasedByMonthTable(value: boolean) {
		this.showPercentPurchasedByMonthTable = value;
		setLocallyStoredFeedIngredientValue(value, 'showPercentPurchasedByMonthTable', this.versionedConceptSeriesId, this.customerId);
	}

	get isFoundationsClient() {
		return this.model.customer.isVgs;
	}

	get customerRole():
		| BusinessEntityRole.DairyProducer
		| BusinessEntityRole.GrainProducer
		| BusinessEntityRole.HogProducer
		| 'Unsupported Role' {
		const roles = this.customer?.businessRoles ?? [];

		if (roles.includes(BusinessEntityRole.HogProducer)) {
			return BusinessEntityRole.HogProducer;
		} else if (roles.includes(BusinessEntityRole.DairyProducer)) {
			return BusinessEntityRole.DairyProducer;
		} else if (roles.includes(BusinessEntityRole.GrainProducer)) {
			return BusinessEntityRole.GrainProducer;
		}

		return 'Unsupported Role';
	}

	get isSwineProducer() {
		return this.customerRole === BusinessEntityRole.HogProducer;
	}

	get hideTargetsTable() {
		// Do not show targets for swine producers
		return this.isSwineProducer;
	}

	get displayFactor() {
		return this.purchasedVsNotPurchasedMonths.find((month) => month.displayFactor)?.displayFactor ?? 1;
	}

	get fractionDigits() {
		return this.purchasedVsNotPurchasedMonths.find((month) => month.fractionDigits)?.fractionDigits ?? 2;
	}

	get unitOfMeasure() {
		return this.currentUnitOfMeasure.value;
	}

	get currentTimePeriodOption() {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	get ingredient(): FeedIngredient | null {
		return this.model.getFeedIngredient.data?.FeedIngredient ?? null;
	}

	get ingredientId(): string {
		return this.ingredient?.id ?? '';
	}

	get versionedConceptSeriesId(): string | null {
		return this.ingredient?.versionedConceptSeriesId ?? null;
	}

	get ingredientName(): string {
		return this.ingredient?.name ?? '';
	}

	get ingredientBase(): FeedIngredientBase | null {
		return this.model.ingredientBase;
	}

	get hedgeProduct(): Product | null {
		return this.ingredient?.FeedCategory.HedgeProduct ?? null;
	}

	get hedgeProductSlug(): string | null {
		return this.hedgeProduct?.slug ?? null;
	}

	get isMarketTraded() {
		return !!this.hedgeProduct;
	}

	get lastUpdatedAtString() {
		return `Last Updated: ${this.model.lastUpdatedAt}`;
	}

	get feedIngredient() {
		return this.model.getFeedIngredient.data?.FeedIngredient;
	}

	get futures() {
		return this.model.getFeedUsageAndRelatedData.data?.Futures ?? [];
	}

	get feedIngredients() {
		return this.model.getFeedIngredient.data?.FeedIngredients ?? [];
	}

	get pricingUnit(): PricingUnit {
		// This view currently only supports corn, soybean meal, and non-product specific ingredients
		const hedgeProductSlug = this.hedgeProduct?.slug;
		if (hedgeProductSlug === 'grain-corn') {
			return 'Bushel';
		} else {
			return 'Ton';
		}
	}

	get feedPriceType(): FeedPriceType {
		if (this.feedIngredient?.flatPricePerTon != undefined) {
			return 'Spot Price';
		} else if (this.feedIngredient?.cmeUsdBasis != undefined) {
			return 'CME Basis (USD)';
		} else if (this.feedIngredient?.cmePercentageBasis != undefined) {
			return 'CME Basis (%)';
		} else if (this.feedIngredient?.FeedCategory?.defaultFlatPricePerTon != undefined) {
			return 'Spot Price';
		} else if (this.feedIngredient?.FeedCategory?.defaultCmeUsdBasis != undefined) {
			return 'CME Basis (USD)';
		} else if (this.feedIngredient?.FeedCategory?.defaultCmePercentageBasis != undefined) {
			return 'CME Basis (%)';
		} else {
			return 'Spot Price';
		}
	}

	get unitsOfMeasure(): UnitOfMeasure[] {
		if (this.ingredientBase === FeedIngredientBase.Corn) {
			return ['Bushels', 'Tons', 'LBS'];
		} else {
			return ['Tons', 'LBS'];
		}
	}

	get totalUsageInTons() {
		return (
			this.model.getFeedUsageAndRelatedData.data?.FeedIngredientConsumedAndPurchasedVolumes.reduce(
				(acc, month) => acc + (month.forecastedConsumptionInTons ?? 0),
				0,
			) ?? 0
		);
	}

	get totalUsageInUnitsOfMeasure() {
		return convertTonsToUnitOfMeasure(this.totalUsageInTons, this.unitOfMeasure, this.hedgeProductSlug);
	}

	@cached
	get purchasedVsNotPurchasedMonths(): PurchasedVsNotPurchasedMonth[] {
		const feedIngredient = this.feedIngredient;

		if (!feedIngredient) return [];

		const currentUnitOfMeasure = this.currentUnitOfMeasure;

		const purchasedVsNotPurchasedMonths = this.model.getFeedUsageAndRelatedData.data?.FeedIngredientConsumedAndPurchasedVolumes?.map(
			(month) => {
				const nearestFuture = this.findNearestFuture(this.hedgeProduct?.slug ?? null, month.monthStartDate);
				return new PurchasedVsNotPurchasedMonth(this, {
					month: month.monthStartDate,
					feedIngredient,
					currentUnitOfMeasure,
					forecastedConsumptionInTons: month.forecastedConsumptionInTons ?? 0,
					totalPurchasedCostInUsd: month.totalPurchasedCostInUsd ?? 0,
					purchasedInTons: month.purchasedInTons ?? 0,
					future: nearestFuture,
				});
			},
		).sortBy('month');

		return purchasedVsNotPurchasedMonths ?? [];
	}

	get percentPurchasedByMonthChartData(): PercentPurchasedByMonthDataItem[] {
		return this.purchasedVsNotPurchasedMonths.map<PercentPurchasedByMonthDataItem>((purchasedVsNotPurchasedMonth) => {
			// Month Data
			const month = purchasedVsNotPurchasedMonth.month;
			const fiftyTwoWeekHigh = purchasedVsNotPurchasedMonth.fiftyTwoWeekHighPerUnitOfMeasure;
			const fiftyTwoWeekLow = purchasedVsNotPurchasedMonth.fiftyTwoWeekLowPerUnitOfMeasure;
			const marketPrice = purchasedVsNotPurchasedMonth.latestPricePerUnitOfMeasure;
			const avgPrice = purchasedVsNotPurchasedMonth.weightedAvgPricePerUnitOfMeasure;

			// Percentages
			const flatContractsPercentage = purchasedVsNotPurchasedMonth.flatContractsDataInUnitsOfMeasure.percentage;
			const notPurchasedPercentage = purchasedVsNotPurchasedMonth.notPurchasedPercentage;

			return {
				month,
				fiftyTwoWeekHigh,
				fiftyTwoWeekLow,
				'Avg Price': avgPrice,
				// Only show market price for market traded ingredients
				...(this.isMarketTraded && { 'Market Price': marketPrice }),
				'Flat Contracts': flatContractsPercentage,
				'Not Purchased': notPurchasedPercentage,
			};
		});
	}

	get feedPercentPurchasedChartData(): FeedPercentPurchasedChartDataItem[] {
		// amount is in unitsOfMeasure
		const aggregatedFlatContractsData: { amount: number; totalCost: number } = {
			amount: 0,
			totalCost: 0,
		};

		const aggregatedNotPurchasedData: { amount: number; totalCost: number } = {
			amount: 0,
			totalCost: 0,
		};

		this.purchasedVsNotPurchasedMonths.forEach((month) => {
			// Flat Contracts
			const flatContractsDataInUnitsOfMeasure = month.flatContractsDataInUnitsOfMeasure;

			aggregatedFlatContractsData.amount += flatContractsDataInUnitsOfMeasure.amount;
			aggregatedFlatContractsData.totalCost += flatContractsDataInUnitsOfMeasure.totalCost;

			// Not Purchased
			aggregatedNotPurchasedData.amount += month.notPurchasedInUnitsOfMeasure ?? 0;
			aggregatedNotPurchasedData.totalCost += month.estimatedTotalCostNotPurchased ?? 0;
		});

		return [
			{
				type: 'Flat Contracts',
				amount: aggregatedFlatContractsData.amount,
				avgPrice: aggregatedFlatContractsData.amount ? aggregatedFlatContractsData.totalCost / aggregatedFlatContractsData.amount : 0,
			},
			{
				type: 'Not Purchased',
				amount: aggregatedNotPurchasedData.amount,
				avgPrice: aggregatedNotPurchasedData.amount ? aggregatedNotPurchasedData.totalCost / aggregatedNotPurchasedData.amount : 0,
			},
		];
	}

	get percentPurchasedByMonthColumns(): TableColumn[] {
		const unitOfMeasure = this.unitOfMeasure;

		return [
			{
				id: '29de7377-8962-4dd4-8849-7d4d44b8c13e',
				name: 'Month',
				valuePath: 'month',
				textAlign: 'left',
				width: 100,
				cellComponent: CellComponents.MonthFormat,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0e5650b8-fe35-4235-ae04-6e764dc19695',
				name: 'Flat Contracts',
				valuePath: '',
				textAlign: 'center',
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '4517fdbf-546c-4392-b5c4-6bd1c2419a3b',
						name: '%',
						valuePath: 'percentPurchasedFlatContracts',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						width: 100,
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'd0c42bf2-0997-4183-944b-171e0be4ba9a',
						name: unitOfMeasure,
						valuePath: 'purchasedFlatContracts',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						width: 100,
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			// TODO: Commented out until support is added for other contract types
			// {
			// 	id: '82a824b5-09eb-4bc9-8ec3-8ce2b7eab90c',
			// 	name: 'HTA Contracts',
			// 	valuePath: '',
			// 	textAlign: 'center',
			// 	cellComponent: CellComponents.String,
			// 	isSortable: false,
			// 	isFixed: '',
			// 	isVisible: true,
			// 	subcolumns: [
			// 		{
			// 			id: '807230c5-5c57-4b0a-a056-534742b15f25',
			// 			name: '%',
			// 			valuePath: 'percentPurchasedHTAContracts',
			// 			textAlign: 'right',
			// 			cellComponent: CellComponents.IntlNumberFormat,
			// 			componentArgs: {
			// 				style: 'percent',
			// 				minimumFractionDigits: 0,
			// 				maximumFractionDigits: 0,
			// 			},
			// 			width: 100,
			// 			isSortable: false,
			// 			isFixed: '',
			// 			isVisible: true,
			// 		},
			// 		{
			// 			id: 'b61fb02a-2a0b-4bd8-a560-444f4c0cb66a',
			// 			name: unitOfMeasure,
			// 			valuePath: 'purchasedHTAContracts',
			// 			textAlign: 'right',
			// 			cellComponent: CellComponents.IntlNumberFormat,
			// 			componentArgs: {
			// 				minimumFractionDigits: 0,
			// 				maximumFractionDigits: 0,
			// 			},
			// 			width: 100,
			// 			isSortable: false,
			// 			isFixed: '',
			// 			isVisible: true,
			// 		},
			// 	],
			// },
			// {
			// 	id: 'e195acba-afe1-4dbb-9872-2875c6808c1c',
			// 	name: 'Basis Contracts',
			// 	valuePath: '',
			// 	textAlign: 'center',
			// 	cellComponent: CellComponents.String,
			// 	isSortable: false,
			// 	isFixed: '',
			// 	isVisible: true,
			// 	subcolumns: [
			// 		{
			// 			id: '19cabbe7-2abc-474b-af56-5b6a33e8117f',
			// 			name: '%',
			// 			valuePath: 'percentPurchasedBasisContracts',
			// 			textAlign: 'right',
			// 			cellComponent: CellComponents.IntlNumberFormat,
			// 			componentArgs: {
			// 				style: 'percent',
			// 				minimumFractionDigits: 0,
			// 				maximumFractionDigits: 0,
			// 			},
			// 			width: 100,
			// 			isSortable: false,
			// 			isFixed: '',
			// 			isVisible: true,
			// 		},
			// 		{
			// 			id: 'e0292443-9ae6-49fd-b561-be7db1a607cc',
			// 			name: unitOfMeasure,
			// 			valuePath: 'purchasedBasisContracts',
			// 			textAlign: 'right',
			// 			cellComponent: CellComponents.IntlNumberFormat,
			// 			componentArgs: {
			// 				minimumFractionDigits: 0,
			// 				maximumFractionDigits: 0,
			// 			},
			// 			width: 100,
			// 			isSortable: false,
			// 			isFixed: '',
			// 			isVisible: true,
			// 		},
			// 	],
			// },
			// {
			// 	id: '11ecab89-0f50-49dd-8d1f-91c9a912b19d',
			// 	name: 'No Price Contracts',
			// 	valuePath: '',
			// 	textAlign: 'center',
			// 	cellComponent: CellComponents.String,
			// 	isSortable: false,
			// 	isFixed: '',
			// 	isVisible: true,
			// 	subcolumns: [
			// 		{
			// 			id: 'a1375e0c-1a06-4f48-9ed0-7c24c66ad019',
			// 			name: '%',
			// 			valuePath: 'percentPurchasedNoPriceContracts',
			// 			textAlign: 'right',
			// 			cellComponent: CellComponents.IntlNumberFormat,
			// 			componentArgs: {
			// 				style: 'percent',
			// 				minimumFractionDigits: 0,
			// 				maximumFractionDigits: 0,
			// 			},
			// 			width: 100,
			// 			isSortable: false,
			// 			isFixed: '',
			// 			isVisible: true,
			// 		},
			// 		{
			// 			id: 'ea4fd335-ba3f-467a-889d-a4ed443a1e74',
			// 			name: unitOfMeasure,
			// 			valuePath: 'purchasedNoPriceContracts',
			// 			textAlign: 'right',
			// 			cellComponent: CellComponents.IntlNumberFormat,
			// 			componentArgs: {
			// 				minimumFractionDigits: 0,
			// 				maximumFractionDigits: 0,
			// 			},
			// 			width: 100,
			// 			isSortable: false,
			// 			isFixed: '',
			// 			isVisible: true,
			// 		},
			// 	],
			// },
			{
				id: '2249ea90-fe80-4c2a-9df6-ef79e63bb5b0',
				name: 'Not Purchased',
				valuePath: '',
				textAlign: 'center',
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '7b277747-ed62-4aae-95ca-46783c3f64da',
						name: '%',
						valuePath: 'percentNotPurchased',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						width: 100,
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '772ded6a-abc3-40c3-876e-e55cb6b30ede',
						name: unitOfMeasure,
						valuePath: 'notPurchased',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						width: 100,
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
	}

	get percentPurchasedByMonthRows(): FeedPercentPurchasedByMonthRow[] {
		return this.purchasedVsNotPurchasedMonths.map((purchasedVsNotPurchasedMonth) => {
			// Month Data
			const month = purchasedVsNotPurchasedMonth.month;

			// Flat Contracts
			const purchasedFlatContracts = purchasedVsNotPurchasedMonth.flatContractsDataInUnitsOfMeasure.amount;
			const percentPurchasedFlatContracts = purchasedVsNotPurchasedMonth.flatContractsDataInUnitsOfMeasure.percentage;

			// Not Purchased
			const notPurchased = purchasedVsNotPurchasedMonth.notPurchasedInUnitsOfMeasure;
			const percentNotPurchased = purchasedVsNotPurchasedMonth.notPurchasedPercentage;

			return {
				month,
				purchasedFlatContracts,
				percentPurchasedFlatContracts,
				notPurchased,
				percentNotPurchased,
			};
		});
	}

	get contractColumns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '20adc39c-c463-4392-bc1b-bf0368f1a9ca',
				name: 'Order Type',
				cellComponent: CellComponents.String,
				valuePath: 'type',
				textAlign: 'left',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '7d007a55-4849-4099-942f-c529520d7e3c',
				name: 'Business',
				cellComponent: CellComponents.String,
				valuePath: 'business',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8ab0ad8f-516c-44e3-a7a3-33d2be2f3c97',
				name: 'Contract Number',
				cellComponent: CellComponents.String,
				valuePath: 'contractNumber',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4b839f6d-81d0-465f-9349-895859a5b9c2',
				name: 'Ingredient',
				cellComponent: CellComponents.String,
				valuePath: 'ingredient',
				textAlign: 'left',
				minWidth: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd90c72ff-5de2-4f9f-a856-b2ae5b9e31d3',
				name: this.unitOfMeasure,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					maximumFractionDigits: 0,
				},
				valuePath: 'amount',
				textAlign: 'right',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			// TODO: Commented out until support for non flat contracts are added
			// {
			// 	id: '29005994-fed6-463e-9ab4-9c0efaf0d778',
			// 	name: 'Futures Price',
			// 	cellComponent: CellComponents.MarketPriceFormat,
			// 	valuePath: 'futureInstrument',
			// 	textAlign: 'right',
			// 	width: 150,
			// 	isSortable: false,
			// 	isFixed: '',
			// 	isVisible: true,
			// },
			// {
			// 	id: 'd108a6bd-9349-44cd-b08e-faa542e30f7f',
			// 	name: 'Basis',
			// 	cellComponent: CellComponents.IntlNumberFormat,
			// 	componentArgs: {
			// 		style: 'currency',
			// 		currency: 'USD',
			// 	},
			// 	valuePath: 'basis',
			// 	textAlign: 'right',
			// 	width: 80,
			// 	isSortable: false,
			// 	isFixed: '',
			// 	isVisible: true,
			// },
			{
				id: '6ab75cf7-0ec8-4686-951d-82afc7aa4327',
				name: 'Flat Price',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					maximumFractionDigits: this.unitOfMeasure === 'LBS' ? 4 : 2,
				},
				valuePath: 'flatPrice',
				textAlign: 'right',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b47d8631-20a7-4946-beeb-3e6947e3c37e',
				name: 'Delivery Start',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { day: 'numeric', month: 'numeric', year: 'numeric' },
				valuePath: 'deliveryStartDate',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '86c6ec06-44ca-4145-b13d-39d36adb7b9f',
				name: 'Delivery End',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { day: 'numeric', month: 'numeric', year: 'numeric' },
				valuePath: 'deliveryEndDate',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '40215a04-f3ef-40a8-b580-7db7c8f61d37',
				name: 'Seller',
				cellComponent: CellComponents.String,
				valuePath: 'seller',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'da4582ec-883b-46e4-81a2-2badfbb73323',
				name: 'Location',
				cellComponent: CellComponents.String,
				valuePath: 'location',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];

		let finalColumns = baseColumns;

		if (this.canWriteOperations) {
			finalColumns = [
				{
					id: '9105c9a8-fad2-482a-886d-16e872d98a66',
					name: '',
					cellComponent: CellComponents.Button,
					componentArgs: {
						size: 'xs',
						style: 'plain',
						fn: (row: ContractsTableRow) => {
							const contractId = row.id;
							const contract = this.contracts.find((contract) => contract.id === contractId);

							this.resetSidePanelState();
							if (contract) {
								this.editPhysicalFeedTransaction(contract);
							}
						},
						rightIconHref: '/icons/Edit-Outline.svg#edit-outline',
						rightIconClass: 'icon-interactive-stroke',
					},
					valuePath: 'type',
					textAlign: 'left',
					width: 75,
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				...finalColumns,
				{
					id: 'dd32ea29-db2f-46ae-a7b8-4dcc79d087ed',
					name: '',
					cellComponent: CellComponents.Button,
					componentArgs: {
						size: 'xs',
						style: 'plain',
						fn: this.deletePhysicalFeedTransactionTableCallback,
						rightIconHref: '/icons/Delete-Outline.svg#delete-outline',
						rightIconClass: 'icon-interactive-stroke',
					},
					valuePath: 'type',
					textAlign: 'left',
					width: 75,
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			];
		}

		return finalColumns;
	}

	@action
	deletePhysicalFeedTransactionTableCallback(row: ContractsTableRow) {
		const contractId = row.id;
		const contract = this.contracts.find((contract) => contract.id === contractId);
		if (contract) {
			this.physicalFeedTransactionToDelete = contract;
		}
	}

	get contracts() {
		return this.model.getFeedUsageAndRelatedData.data?.PhysicalFeedTransactions ?? [];
	}

	get flatContractTableRows(): ContractsTableRow[] {
		const business = this.customer.name;
		const ingredient = this.feedIngredient?.name;
		const unitOfMeasure = this.unitOfMeasure;

		return (
			this.model.getFeedUsageAndRelatedData.data?.PhysicalFeedTransactions.map<ContractsTableRow>((contract) => ({
				id: contract.id,
				type: 'Flat',
				business,
				ingredient,
				contractNumber: contract.contractIdentifier ?? undefined,
				amount: convertTonsToUnitOfMeasure(contract.tons, unitOfMeasure, this.hedgeProductSlug),
				flatPrice: convertPerPricingUnitToPerUnitOfMeasure(
					contract.flatPrice ? contract.flatPrice : 0,
					'Ton',
					unitOfMeasure,
					this.hedgeProductSlug,
				),
				deliveryStartDate: contract.deliveryStartDate,
				deliveryEndDate: contract.deliveryEndDate,
				seller: contract.seller ?? undefined,
				location: contract.location ?? undefined,
			})) ?? []
		);
	}

	get contractRows(): ContractsTableRow[] {
		return this.flatContractTableRows;
	}

	get purchasedVsNotPurchasedColumns(): TableColumn[] {
		const feedPriceType = this.feedPriceType;
		const unitOfMeasure = this.unitOfMeasure;
		return [
			{
				id: '6db7ccbc-90ba-4bfd-aa98-e032f1aff67a',
				name: 'Month',
				valuePath: 'month',
				textAlign: 'left',
				width: 100,
				cellComponent: CellComponents.MonthFormat,
				isSortable: false,
				isFixed: 'left',
				isVisible: true,
				footerIsString: true,
			},
			{
				id: 'd2ff1ed0-96f7-48a0-973a-714148cd4427',
				name: 'Total Forecasted Usage',
				valuePath: 'usageInUnitsOfMeasure',
				textAlign: 'left',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5e5e0664-7414-4060-8aab-5ab475404cf5',
				name: 'Purchased',
				textAlign: 'center',
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '53d42477-59ee-41fb-92f2-d9f898982f50',
						name: unitOfMeasure,
						valuePath: 'purchasedInUnitsOfMeasure',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						width: 100,
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '35203ce6-c2db-4fcf-aa40-30287b45a07e',
						name: 'Avg Price',
						valuePath: 'avgPurchasedPricePerUnitOfMeasure',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							// If displaying lbs, allow 4 decimal places since the values can get small
							...(this.unitOfMeasure === 'LBS' && { maximumFractionDigits: 4 }),
						},
						width: 100,
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '0637120b-59a3-4d97-9b83-2b09e7164ab1',
						name: 'Total Cost',
						valuePath: 'totalPurchasedCost',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
						},
						width: 100,
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '35740356-4f3d-4c3a-80b3-1f25d43fd6ac',
				name: 'Not Purchased',
				textAlign: 'center',
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '6a302fca-b336-4ada-a05f-49714ca6ad80',
						name: unitOfMeasure,
						valuePath: 'notPurchasedInUnitsOfMeasure',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
						width: 100,
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					...(feedPriceType === 'Spot Price'
						? [
								{
									id: '3b43871c-8458-400a-8a9f-745bdcf40810',
									name: 'Flat Price',
									valuePath: 'flatPricePerUnitOfMeasure',
									textAlign: 'right',
									cellComponent: CellComponents.IntlNumberFormat,
									componentArgs: {
										style: 'currency',
										currency: 'USD',
										// If displaying lbs, allow 4 decimal places since the values can get small
										...(this.unitOfMeasure === 'LBS' && { maximumFractionDigits: 4 }),
									},
									width: 130,
									isSortable: false,
									isFixed: '',
									isVisible: true,
								},
							]
						: []),
					...(feedPriceType === 'CME Basis (%)' || feedPriceType === 'CME Basis (USD)'
						? [
								{
									id: '2cd3fe28-70b5-4d79-8675-e4333195644c',
									name: 'Futures Month',
									valuePath: 'displayExpiresAt',
									textAlign: 'left',
									cellComponent: CellComponents.MonthFormat,
									width: 130,
									isSortable: false,
									isFixed: '',
									isVisible: true,
								},
								{
									id: '8e357c68-fa29-41d5-9566-222446e226e2',
									name: 'Futures Price',
									valuePath: 'latestPricePerUnitOfMeasure',
									textAlign: 'right',
									cellComponent: CellComponents.PriceFormat,
									componentArgs: {
										fractionDigitsPath: 'fractionDigits',
										displayFactorPath: 'displayFactor',
									},
									width: 130,
									isSortable: false,
									isFixed: '',
									isVisible: true,
								},
								{
									id: 'be79cbdc-387a-4f90-99b0-0d9bce70a032',
									name: `Basis (${this.feedPriceType === 'CME Basis (%)' ? '%' : '$'})`,
									valuePath: this.feedPriceType === 'CME Basis (%)' ? 'cmePercentageBasis' : 'cmeUsdBasisInUnitOfMeasure',
									textAlign: 'right',
									cellComponent: CellComponents.IntlNumberFormat,
									componentArgs: {
										...(this.feedPriceType === 'CME Basis (%)'
											? { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }
											: {
													minimumFractionDigits: this.fractionDigits,
													// If displaying lbs, allow 4 decimal places since the values can get small
													maximumFractionDigits: this.unitOfMeasure === 'LBS' ? Math.max(4, this.fractionDigits) : this.fractionDigits,
												}),
									},
									width: 130,
									isSortable: false,
									isFixed: '',
									isVisible: true,
								},
							]
						: []),
					{
						id: 'e0f1fae3-1d84-4398-a391-4af663b591f6',
						name: 'Est. Total Cost',
						valuePath: 'estimatedTotalCostNotPurchased',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
						},
						width: 150,
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'a45efad8-b92f-4e80-84e9-04083d27cf61',
				name: 'Total Feed Expense',
				valuePath: 'totalFeedExpense',
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	@cached
	get purchasedVsNotPurchasedRows(): PurchasedVsNotPurchasedRow[] {
		const feedIngredient = this.feedIngredient;

		if (!feedIngredient) return [];

		return this.purchasedVsNotPurchasedMonths.map((month) => {
			return {
				month: month.month,
				usageInUnitsOfMeasure: month.forecastedConsumptionInUnitsOfMeasure,
				purchasedInUnitsOfMeasure: month.purchasedInUnitsOfMeasure ?? 0,
				avgPurchasedPricePerUnitOfMeasure: month.avgPurchasedPricePerUnitOfMeasure ?? 0,
				totalPurchasedCost: month.totalPurchasedCostInUsd,
				notPurchasedInUnitsOfMeasure: month.notPurchasedInUnitsOfMeasure ?? 0,
				displayExpiresAt: month.displayExpiresAt,
				latestPricePerUnitOfMeasure: month.latestPricePerUnitOfMeasure,
				fractionDigits: month.fractionDigits,
				displayFactor: month.displayFactor,
				flatPricePerUnitOfMeasure: month.flatPricePerUnitOfMeasure,
				cmeUsdBasisInUnitOfMeasure:
					month.cmeUsdBasisInUnitOfMeasure != null ? Big(month.cmeUsdBasisInUnitOfMeasure).times(month.displayFactor).toNumber() : null,
				cmePercentageBasis: month.cmePercentageBasis,
				estimatedTotalCostNotPurchased: month.estimatedTotalCostNotPurchased,
				totalFeedExpense: month.totalFeedExpense,
			};
		});
	}

	@cached
	get purchasedVsNotPurchasedTotals(): [PurchasedVsNotPurchasedTotalRow] {
		const purchasedVsNotPurchasedRows = this.purchasedVsNotPurchasedRows;
		const totals = purchasedVsNotPurchasedRows.reduce<PurchasedVsNotPurchasedTotalRow>(
			(acc, row) => {
				acc.purchasedInUnitsOfMeasure += row.purchasedInUnitsOfMeasure;
				acc.notPurchasedInUnitsOfMeasure += row.notPurchasedInUnitsOfMeasure;
				acc.estimatedTotalCostNotPurchased += row.estimatedTotalCostNotPurchased ?? 0;
				acc.totalFeedExpense += row.totalFeedExpense ?? 0;
				return acc;
			},
			{
				month: 'Total',
				purchasedInUnitsOfMeasure: 0,
				notPurchasedInUnitsOfMeasure: 0,
				estimatedTotalCostNotPurchased: 0,
				totalFeedExpense: 0,
			},
		);

		return [totals];
	}

	get targetsColumns(): TableColumn[] {
		return [
			{
				id: 'e747b7a3-f8d3-47cd-818e-505f67e0fa42',
				name: 'Order Type',
				cellComponent: CellComponents.String,
				valuePath: 'orderType',
				textAlign: 'left',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '729714fa-d441-4ed6-a8c8-9d7ac8e358a6',
				name: 'Status',
				cellComponent: CellComponents.String,
				valuePath: 'status',
				textAlign: 'left',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '477d0ec0-22c1-43d9-b8bb-9aa1a0593219',
				name: 'Contract Number',
				cellComponent: CellComponents.String,
				valuePath: 'contractNumber',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'eef3fe71-cd59-47f3-a39a-281245810f84',
				name: 'Ingredient',
				cellComponent: CellComponents.String,
				valuePath: 'category',
				textAlign: 'left',
				minWidth: 100,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'b9fc5704-8d73-41bd-8d2c-a1a15cd3932a',
				name: this.unitOfMeasure,
				cellComponent: CellComponents.IntlNumberFormat,
				valuePath: 'amount',
				textAlign: 'right',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2d7ea6aa-87f3-46d9-8086-37e6bb11cd7e',
				name: 'Futures Month',
				cellComponent: CellComponents.MonthFormat,
				valuePath: 'futuresMonth',
				textAlign: 'left',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '4ba82236-6b96-4585-a83e-7419294fc782',
				name: 'Futures Price',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: this.fractionDigits,
					displayFactor: this.displayFactor,
				},
				valuePath: 'futurePrice',
				textAlign: 'right',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '129469d8-222b-422d-a989-99695fb8213d',
				name: 'Symbol',
				cellComponent: CellComponents.String,
				valuePath: 'symbol',
				textAlign: 'left',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '94195f4f-b4d2-4d03-8871-257878aff68b',
				name: 'Basis',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				valuePath: 'basis',
				textAlign: 'right',
				width: 80,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '464c41fc-8c35-4976-b2ef-ec0dd9488f23',
				name: 'Flat Price',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				valuePath: 'flatPrice',
				textAlign: 'right',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e4ca528e-affd-49e7-b035-2e6343152847',
				name: 'Delivery Start',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { day: 'numeric', month: 'numeric', year: 'numeric' },
				valuePath: 'deliveryStartDate',
				textAlign: 'left',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '805044d2-d240-42f6-9b34-f02c147d0329',
				name: 'Delivery End',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { day: 'numeric', month: 'numeric', year: 'numeric' },
				valuePath: 'deliveryEndDate',
				textAlign: 'left',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '165efec8-2fde-420d-a2f1-393f91c8398d',
				name: 'Seller',
				cellComponent: CellComponents.String,
				valuePath: 'seller',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2e5c52f0-bd79-41be-93b8-7d07edd822b5',
				name: 'Location',
				cellComponent: CellComponents.String,
				valuePath: 'location',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
		];
	}

	get targets(): TargetRow[] {
		const hedgeProductSlug = this.hedgeProductSlug;
		const unitOfMeasure = this.unitOfMeasure;
		const pricingUnit = this.pricingUnit;

		return (
			this.model.getFeedUsageAndRelatedData.data?.GrainTargetOrders.map((target) => {
				const feedPlan = target.Plan as GrainFeedPlan;

				// Feed Plan "bushels" are actually tons
				const tons = target.bushels ?? 0;
				return {
					id: target.id,
					orderType: getSalesTypeDisplayValue(target.salesType),
					business: feedPlan.Customer?.name,
					category: feedPlan.FeedCategory.name,
					contractNumber: target.contractNumber,
					symbol: target.futuresMonth ? this.findNearestFuture(this.hedgeProductSlug, target.futuresMonth)?.barchartSymbol : null,
					futuresMonth: target.futuresMonth,
					futurePrice: target.futurePrice
						? convertPerPricingUnitToPerUnitOfMeasure(target.futurePrice, pricingUnit, unitOfMeasure, hedgeProductSlug)
						: null,
					amount: convertTonsToUnitOfMeasure(tons, unitOfMeasure, hedgeProductSlug),
					basis: target.basis,
					flatPrice: target.feedPrice
						? convertPerPricingUnitToPerUnitOfMeasure(target.feedPrice, 'Ton', unitOfMeasure, hedgeProductSlug)
						: null,
					deliveryStartDate: target.deliveryStartDate,
					deliveryEndDate: target.deliveryEndDate,
					seller: target.Seller?.name,
					location: target.Location?.name,
					status: target.status,
				};
			}) ?? []
		);
	}

	createTrackedEmptyPhysicalFeedTransactionData() {
		const data = createTrackedEmptyPhysicalFeedTransactionData();
		data.feedIngredient = this.feedIngredient ?? null;
		return data;
	}

	@action
	updateEditFeedIngredientFormData(
		key: keyof CreateEditFeedIngredientData,
		value: CreateEditFeedIngredientData[keyof CreateEditFeedIngredientData],
	) {
		updateCreateEditFeedIngredientFormData(this.editFeedIngredientFormData, key, value);
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}

	@action
	transitionToFeedIngredientDetail(id: string) {
		this.router.transitionTo(this.feedIngredientRoutePath, id);
		this.currentUnitOfMeasure.reset();
	}

	@action
	setUnitOfMeasure(unitOfMeasure: UnitOfMeasure) {
		this.currentUnitOfMeasure.value = unitOfMeasure;
		setLocallyStoredUnitOfMeasure(this.versionedConceptSeriesId, this.customerId ?? null, unitOfMeasure);

		// If the edit ingredient tray is open, trigger a refresh of the data to match the new unit of measure
		if (this.showEditIngredientTray) {
			this.editFeedIngredientFormData = generateCreateEditFeedIngredientData(this.feedIngredient ?? null, unitOfMeasure);
		}
	}

	// Side Panel State Management
	get isSidePanelOpen() {
		return this.showEditIngredientTray || this.showEditUsageTray || this.showAddEditPhysicalFeedTransactionTray || this.showEditPriceTray;
	}

	get sidePanelSubmitButtonText() {
		return this.submitEditFeedIngredientForm.isRunning ||
			this.submitEditDairyFeedUsageForm.isRunning ||
			this.submitEditLivestockFeedUsageForm.isRunning ||
			this.updateExistingPhysicalFeedTransaction.isRunning ||
			this.createPhysicalFeedTransaction.isRunning ||
			this.submitAddEditPhysicalFeedTransactionForm.isRunning ||
			this.submitEditPriceForm.isRunning
			? 'Editing...'
			: 'Submit';
	}

	get sidePanelTitle() {
		if (this.showEditIngredientTray) {
			return 'Edit Feed Ingredient';
		} else if (this.showEditUsageTray) {
			return 'Usage Settings';
		} else if (this.showAddEditPhysicalFeedTransactionTray && this.isPhysicalFeedTransactionEditMode) {
			return 'Edit Physical Feed Transaction';
		} else if (this.showAddEditPhysicalFeedTransactionTray && !this.isPhysicalFeedTransactionEditMode) {
			return 'Add Physical Feed Transaction';
		} else if (this.showEditPriceTray) {
			return 'Price Settings';
		}
		return '';
	}

	get onSidePanelClose() {
		if (this.showEditIngredientTray) {
			return this.closeEditIngredientTray;
		} else if (this.showEditUsageTray) {
			return this.closeEditUsageTray;
		} else if (this.showAddEditPhysicalFeedTransactionTray) {
			return this.closeAddEditPhysicalFeedTransactionTray;
		} else if (this.showEditPriceTray) {
			return this.closeEditPriceTray;
		}

		return this.noopAction;
	}

	get sidePanelSubmitAction() {
		if (this.showEditIngredientTray) {
			return this.submitEditFeedIngredientForm;
		} else if (this.showEditUsageTray && this.isLivestockGroupFeedUsage) {
			return this.submitEditLivestockFeedUsageForm;
		} else if (this.showEditUsageTray && !this.isLivestockGroupFeedUsage) {
			return this.submitEditDairyFeedUsageForm;
		} else if (this.showAddEditPhysicalFeedTransactionTray) {
			return this.submitAddEditPhysicalFeedTransactionForm;
		} else if (this.showEditPriceTray) {
			return this.submitEditPriceForm;
		}

		return this.noopTask;
	}

	get isSidePanelSubmitDisabled() {
		return (
			this.disableEditIngredientSubmitButton ||
			this.disableEditDairyUsageSubmitButton ||
			this.disableEditLivestockUsageSubmitButton ||
			this.disableAddEditPhysicalFeedTransactionSubmit ||
			this.disableEditPriceSubmitButton
		);
	}

	// Edit Ingredient

	get disableEditIngredientSubmitButton() {
		// If the editIngredientTray is closed, always return false
		if (!this.showEditIngredientTray) return false;

		return this.submitEditFeedIngredientForm.isRunning;
	}

	@action
	resetSidePanelState() {
		this.closeEditIngredientTray();
		this.closeEditUsageTray();
		this.closeAddEditPhysicalFeedTransactionTray();
		this.closeEditPriceTray();
		this.errorMessage = null;
	}

	@action
	closeEditIngredientTray() {
		this.editFeedIngredientFormData = generateCreateEditFeedIngredientData(this.feedIngredient ?? null, this.unitOfMeasure);
		this.showEditIngredientTray = false;
	}

	submitEditFeedIngredientForm = task({ drop: true }, async () => {
		if (!isFormValid(document)) {
			getInvalidElements(document);
			return;
		}

		const customerId = this.customerId;
		if (!customerId) {
			set(this.editFeedIngredientFormData, 'error', 'Customer ID is missing');
			return;
		}

		try {
			const { name, dryMatterPercent, flatPricePerTon, cmeUsdBasis, cmePercentageBasis } = parseCreateEditFeedIngredientData(
				this.editFeedIngredientFormData,
				this.unitOfMeasure,
			);

			const editFeedIngredientData: FeedIngredientUpdateDTO = {
				...(this.feedIngredient?.name !== name ? { name } : {}),
				...(this.feedIngredient?.dryMatterPercent !== dryMatterPercent ? { dryMatterPercent } : {}),
				...(this.feedIngredient?.flatPricePerTon !== flatPricePerTon ? { flatPricePerTon } : {}),
				...(this.feedIngredient?.cmeUsdBasis !== cmeUsdBasis ? { cmeUsdBasis } : {}),
				...(this.feedIngredient?.cmePercentageBasis !== cmePercentageBasis ? { cmePercentageBasis } : {}),
			};

			const response = await (updateFeedIngredient(
				this,
				{
					data: editFeedIngredientData,
					id: this.ingredientId,
				},
				this.customerRole,
			) as Promise<
				Maybe<{
					updateFeedIngredient: Mutation['updateFeedIngredient'];
				}>
			>);

			const updatedIngredient = response?.updateFeedIngredient?.FeedIngredients.find(
				(ingredient) => ingredient.versionedConceptSeriesId === this.feedIngredient?.versionedConceptSeriesId,
			);

			if (updatedIngredient && updatedIngredient.id !== this.ingredientId) {
				this.transitionToFeedIngredientDetail(updatedIngredient.id);
			}
		} catch (e) {
			set(this.editFeedIngredientFormData, 'error', e.message);
			return;
		}

		this.closeEditIngredientTray();
	});

	// Edit Feed Ingredient Usage
	@action
	closeEditUsageTray() {
		this.showEditUsageTray = false;
		this.errorMessage = null;
		this.editDairyFeedUsageVariables = null;
		this.editLivestockFeedUsageVariables = null;
	}

	// Edit Feed Ingredient Usage: Dairy
	get months(): string[] {
		return this.purchasedVsNotPurchasedMonths.map((month) => month.month);
	}

	get editDairyFeedUsageIsDirty() {
		return this.getUpdatedDairyPayloads(this.editDairyFeedUsageVariables?.data.datePayloads ?? []).length > 0;
	}

	get disableEditDairyUsageSubmitButton() {
		// If the editUsageTray is closed or we are using livestock feed usages, always return false
		if (!this.showEditUsageTray || this.isLivestockGroupFeedUsage) return false;

		return !this.editDairyFeedUsageIsDirty || this.submitEditDairyFeedUsageForm.isRunning;
	}

	submitEditDairyFeedUsageForm = task({ drop: true }, async () => {
		const entityId = this.editDairyFeedUsageVariables?.data.entityId;
		const updatedPayloads = this.getUpdatedDairyPayloads(this.editDairyFeedUsageVariables?.data.datePayloads ?? []);

		if (!entityId) {
			this.errorMessage = 'Update Dairy Feed Usage: Entity Id was not found';
			return;
		}

		if (updatedPayloads.length == 0) {
			this.errorMessage = 'Update Dairy Feed Usage: No Changes Detected';
			return;
		}

		const variables: Mutation_setFeedIngredientForecastedUsageByMonthArgs = {
			data: {
				entityId,
				datePayloads: updatedPayloads,
			},
		};

		try {
			await setFeedIngredientForecastedUsageByMonth(this, variables);
			this.closeEditUsageTray();
		} catch (e) {
			this.errorMessage = e.message;
		}
	});

	@action
	onUpdateEditDairyFeedUsage(variables: Mutation_setFeedIngredientForecastedUsageByMonthArgs) {
		this.editDairyFeedUsageVariables = variables;
	}

	getUpdatedDairyPayloads(payloads: FeedIngredientUsageSetByMonthPerDatePayload[]) {
		return payloads.filter((payload) => {
			const month = payload.date;
			const newUsageForMonthInLbs = payload.dmiUsageInLb;
			const currentUsageForMonthInLbs =
				this.purchasedVsNotPurchasedMonths.find((purchasedVsNotPurchasedMonth) => purchasedVsNotPurchasedMonth.month === month)
					?.forecastedConsumptionInLbs ?? 0;

			return newUsageForMonthInLbs !== currentUsageForMonthInLbs;
		});
	}

	// Edit Feed Ingredient Usage: Livestock
	get disableEditLivestockUsageSubmitButton() {
		// If the editUsageTray is closed or we are using dairy feed usages, always return false
		if (!this.showEditUsageTray || !this.isLivestockGroupFeedUsage) return false;

		return !this.editLivestockFeedUsageIsDirty || this.submitEditLivestockFeedUsageForm.isRunning;
	}

	get editLivestockFeedUsageIsDirty() {
		return this.editLivestockFeedUsageVariables && this.editLivestockFeedUsageVariables?.data.usages.length > 0;
	}

	get isLivestockGroupFeedUsage() {
		return this.isSwineProducer;
	}

	get typeOfLivestockGroup(): TypeOfLivestockGroup {
		return TypeOfLivestockGroup.Swine;
	}

	get livestockGroups(): SwineLivestockGroup[] {
		return this.model.getFeedUsageAndRelatedData.data?.SwineLivestockGroups ?? [];
	}

	@action
	onUpdateEditLivestockFeedUsage(variables: Mutation_setLivestockGroupFeedUsagesForBusinessArgs) {
		this.editLivestockFeedUsageVariables = variables;
	}

	submitEditLivestockFeedUsageForm = task({ drop: true }, async () => {
		const variables = this.editLivestockFeedUsageVariables;

		if (!variables) {
			this.errorMessage = 'Update Livestock Feed Usage: Variables not set. No changes detected';
			return;
		}

		try {
			await setLivestockGroupFeedUsageForBusiness(this, variables);
			this.closeEditUsageTray();
		} catch (e) {
			this.errorMessage = e.message;
		}
	});

	// Add/Edit Physical Feed Transaction

	get isPhysicalFeedTransactionEditMode() {
		return this.physicalFeedTransactionFormData.id !== undefined;
	}

	get disableAddEditPhysicalFeedTransactionSubmit() {
		// If the showAddEditPhysicalFeedTransactionTray is closed, always return false
		if (!this.showAddEditPhysicalFeedTransactionTray) return false;

		let formIsInvalid;
		if (this.isPhysicalFeedTransactionEditMode) {
			const validatedData = validateEditPhysicalFeedTransactionFormData(
				this.physicalFeedTransactionFormData,
				this.physicalFeedTransactionOriginalEditValues,
			);
			formIsInvalid = validatedData === false || Object.keys(validatedData).length === 0;
		} else {
			formIsInvalid = validateAddFormData(this.physicalFeedTransactionFormData) === false;
		}
		return formIsInvalid || this.createPhysicalFeedTransaction.isRunning || this.updateExistingPhysicalFeedTransaction.isRunning;
	}

	@action
	closeAddEditPhysicalFeedTransactionTray() {
		this.errorMessage = null;
		this.physicalFeedTransactionFormData = this.createTrackedEmptyPhysicalFeedTransactionData();
		this.physicalFeedTransactionOriginalEditValues = null;
		this.showAddEditPhysicalFeedTransactionTray = false;
	}

	@action
	async editPhysicalFeedTransaction(physicalFeedTransaction: PhysicalFeedTransaction) {
		const data = {
			id: physicalFeedTransaction.id,
			contractNumber: physicalFeedTransaction.contractIdentifier ?? null,
			feedIngredient: this.feedIngredient ?? null,
			tons: physicalFeedTransaction.tons.toString(),
			deliveryStartDate: physicalFeedTransaction.deliveryStartDate,
			deliveryEndDate: physicalFeedTransaction.deliveryEndDate,
			flatPrice: physicalFeedTransaction.flatPrice ? physicalFeedTransaction.flatPrice.toString() : null,
			seller: physicalFeedTransaction.seller ?? null,
			location: physicalFeedTransaction.location ?? null,
		};

		this.physicalFeedTransactionOriginalEditValues = { ...data };
		this.physicalFeedTransactionFormData = new TrackedObject(data);

		if (!this.isSidePanelOpen) this.showAddEditPhysicalFeedTransactionTray = true;
	}

	updateExistingPhysicalFeedTransaction = task({ drop: true }, async (data: AddEditPhysicalFeedTransactionFormData) => {
		const { id } = data;
		const validatedData = validateEditPhysicalFeedTransactionFormData(data, this.physicalFeedTransactionOriginalEditValues);

		if (validatedData === false) {
			this.errorMessage = 'Please fill out all required fields';
			return;
		}

		if (Object.keys(validatedData).length === 0) {
			this.errorMessage = 'No fields have been updated';
			return;
		}

		if (!id) {
			this.errorMessage = 'No ID found for existing Physical Feed Transaction';
			return;
		}

		try {
			await updatePhysicalFeedTransaction(this, { id, data: validatedData });
			this.closeAddEditPhysicalFeedTransactionTray();
		} catch (e) {
			this.errorMessage = e?.message;
		}
	});

	createPhysicalFeedTransaction = task({ drop: true }, async (data: AddEditPhysicalFeedTransactionFormData) => {
		const validatedData = validateAddFormData(data);

		if (validatedData === false) {
			this.errorMessage = 'Please fill out all required fields';
			return;
		}

		try {
			await addPhysicalFeedTransaction(this, { data: validatedData });
			this.closeAddEditPhysicalFeedTransactionTray();
		} catch (e) {
			this.errorMessage = e?.message;
		}
	});

	@action
	updatePhysicalFeedTransactionFormData(key: keyof AddEditPhysicalFeedTransactionFormData, value: any) {
		set(this.physicalFeedTransactionFormData, key, value);
	}

	submitAddEditPhysicalFeedTransactionForm = task({ drop: true }, async () => {
		if (this.addEditPhysicalFeedTransactionForm) {
			this.addEditPhysicalFeedTransactionForm.dispatchEvent(new Event('submit'));
		} else {
			console.warn('Add/Edit Physical Feed Transaction Form not found');
		}
	});

	// Edit Price
	get disableEditPriceSubmitButton() {
		// If the showEditPriceTray is closed, always return false
		if (!this.showEditPriceTray) return false;

		// If dataPayload is empty the form is not dirty, disable submit
		if (!this.editPriceFormData?.data?.pricesByMonth.length) return true;

		return this.submitEditPriceForm.isRunning;
	}

	@action
	closeEditPriceTray() {
		this.errorMessage = null;
		this.editPriceFormData = null;
		this.showEditPriceTray = false;
	}

	@action
	onUpdateFeedPriceForm(variables: Mutation_setFeedIngredientPriceByMonthArgs) {
		this.editPriceFormData = variables;
	}

	submitEditPriceForm = task({ drop: true }, async () => {
		const variables = this.editPriceFormData;
		if (!variables) {
			this.errorMessage = 'Edit price form data was not set';
			return;
		}

		// TODO: Implement full logic once API support is added
		console.log(variables);

		try {
			await editFeedIngredientPrice(this, variables);
			this.closeEditPriceTray();
		} catch (e) {
			this.errorMessage = e?.message;
		}
	});

	@action
	transitionToFeedOverview() {
		this.router.transitionTo(this.feedOverviewRoutePath);
	}

	findNearestFuture(productSlug: string | null, month: string) {
		// Futures are sorted from API, allowing a >= check for finding the nearest future
		// If the date is too far in the future, return null
		const futures = this.futures.filter((future) => future.Product.slug === productSlug) ?? [];
		return futures?.find((future) => future.displayExpiresAt >= month) ?? null;
	}

	fetchFuturePrice(symbol: string | null) {
		if (!symbol) return null;

		if (!this.registeredFutures[symbol]) {
			this.marketData.register(symbol);
			this.registeredFutures[symbol] = 1;
			return null;
		} else {
			return this.marketData.getLatestPrice(symbol);
		}
	}

	@action
	noopAction() {}

	noopTask = task(async () => {});
}
