import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import FeedIngredientDetailRoute, {
	FeedIngredientQueryArgs,
	GetFeedIngredientQuery,
	GetFeedUsageQuery,
	GetFeedUsageQueryArgs,
} from 'vault-client/routes/feed/ingredients/detail';
import { FeedIngredientBase } from 'vault-client/types/vault-client';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessRoute from '../business';

interface ModelParams {
	ingredient_id: string;
	startDate: string;
	endDate: string;
}

const GET_FEED_INGREDIENT = gql`
	query FeedIngredient($id: String!, $feedIngredientsWhere: FeedIngredientFilterDTO) {
		FeedIngredient(id: $id) {
			id
			name
			description
			dryMatterPercent
			flatPricePerTon
			cmeUsdBasis
			cmePercentageBasis
			versionedConceptSeriesId
			FeedIngredientPrices {
				id
				date
				price
				pricingMethodology
			}
			FeedCategory {
				id
				name
				defaultFlatPricePerTon
				defaultCmePercentageBasis
				defaultCmeUsdBasis
				HedgeProduct {
					id
					slug
					name
					MostCurrentFuture {
						id
						SymbolGroup {
							id
							displayFactor
							fractionDigits
						}
					}
					CurrentFutures {
						id
						type
						barchartSymbol
						displayExpiresAt
						SymbolGroup {
							id
							displayFactor
							fractionDigits
						}
					}
				}
			}
		}
		FeedIngredients(where: $feedIngredientsWhere, orderBy: { name: Asc }) {
			id
			name
			versionedConceptSeriesId
		}
	}
`;

const GET_FEED_USAGE_AND_RELATED_DATA = gql`
	query FeedUsageAndFutures(
		$scopeId: String!
		$feedIngredientId: String!
		$versionedConceptSeriesId: String!
		$feedCategoryId: String!
		$startDate: String!
		$endDate: String!
		$futuresWhere: FutureFilterDTO!
	) {
		FeedIngredientConsumedAndPurchasedVolumes(
			where: {
				startDate: $startDate
				endDate: $endDate
				FeedIngredient: { versionedConceptSeriesId: { equals: $versionedConceptSeriesId } }
			}
			orderBy: { monthStartDate: Asc }
			scopeId: $scopeId
			limit: 2000
		) {
			id
			feedIngredientId
			monthStartDate
			purchasedInTons
			totalPurchasedCostInUsd
			forecastedConsumptionInTons
			FeedIngredient {
				id
				versionedConceptSeriesId
				name
				cmePercentageBasis
				cmeUsdBasis
				updatedAt
				flatPricePerTon
				FeedCategory {
					id
					name
					updatedAt
					defaultFlatPricePerTon
					defaultCmePercentageBasis
					defaultCmeUsdBasis
					HedgeProduct {
						id
						slug
					}
				}
			}
		}
		SwineLivestockGroups(
			scopeId: $scopeId
			where: { LivestockGroupVersion: { isCurrent: { equals: true } } }
			orderBy: { minAgeInWeeks: Asc }
		) {
			id
			name
			durationInWeeks
		}
		Futures(where: $futuresWhere, orderBy: { displayExpiresAt: Asc }) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
				slug
			}
			SymbolGroup {
				id
				displayFactor
				fractionDigits
			}
		}
		PhysicalFeedTransactions(
			scopeId: $scopeId
			where: {
				feedIngredientId: { equals: $feedIngredientId }
				OR: [{ deliveryStartDate: { gte: $startDate, lte: $endDate } }, { deliveryEndDate: { gte: $startDate, lte: $endDate } }]
			}
			orderBy: { deliveryEndDate: Asc }
		) {
			id
			tons
			seller
			location
			flatPrice
			deliveryStartDate
			deliveryEndDate
			contractIdentifier
			FeedIngredient {
				id
				name
			}
		}
		GrainTargetOrders(
			scopeId: $scopeId
			orderBy: { deliveryEndDate: Asc }
			where: {
				Plan: { type: { equals: Feed }, feedCategoryId: { equals: $feedCategoryId } }
				status: { notIn: [Cancelled, PendingCancellation, Filled] }
				OR: [{ deliveryStartDate: { gte: $startDate, lte: $endDate } }, { deliveryEndDate: { gte: $startDate, lte: $endDate } }]
			}
		) {
			id
			deliveryTerms
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			basis
			feedPrice
			expirationDate
			contractNumber
			status
			updatedAt
			shortNote
			Seller {
				id
				name
			}
			Location {
				id
				name
			}
			Plan {
				id
				type
				... on GrainFeedPlan {
					customerId
					feedCategoryId
					startDate
					hasWriteAccess
					feedYear
					Customer {
						id
						name
						RelationshipOwner {
							id
							firstName
							lastName
						}
					}
					FeedCategory {
						id
						name
					}
				}
			}
		}
	}
`;

export default class BusinessesBusinessFeedIngredientDetailRoute extends FeedIngredientDetailRoute {
	async model(params: ModelParams) {
		const { businessId, Customer } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;

		this.feedIngredientVariables = {
			id: params.ingredient_id,
			feedIngredientsWhere: this.generateFeedIngredientsWhere(businessId),
		};

		// Queries are defined in the model to ensure they are refetched each time the model in refreshed. Ensures Feed ingredient data does not get out of date after mutations (removes previous hack).
		// See `update-feed-usage-button` for comment on fetchPolicy
		const getFeedIngredient = useQuery<GetFeedIngredientQuery, FeedIngredientQueryArgs>(this, () => [
			GET_FEED_INGREDIENT,
			{ variables: this.feedIngredientVariables, fetchPolicy: 'network-only' },
		]);

		await getFeedIngredient.promise;

		const hedgedProductSlug = getFeedIngredient.data?.FeedIngredient?.FeedCategory.HedgeProduct?.slug;

		const ingredientBase = ((slug: string | undefined) => {
			switch (slug) {
				case 'grain-corn':
					return FeedIngredientBase.Corn;
				case 'grain-soybean-meal':
					return FeedIngredientBase.SoybeanMeal;
				default:
					return FeedIngredientBase.Other;
			}
		})(hedgedProductSlug);

		const versionedConceptSeriesId = getFeedIngredient.data?.FeedIngredient?.versionedConceptSeriesId || '';
		const feedCategoryId = getFeedIngredient.data?.FeedIngredient?.FeedCategory.id ?? '';

		this.feedUsageVariables = {
			scopeId: businessId,
			feedIngredientId: params.ingredient_id,
			versionedConceptSeriesId,
			feedCategoryId,
			startDate: params.startDate,
			endDate: params.endDate,
			futuresWhere: this.generateFuturesWhere(ingredientBase, params.startDate, params.endDate),
		};

		const getFeedUsageAndRelatedData = useQuery<GetFeedUsageQuery, GetFeedUsageQueryArgs>(this, () => [
			GET_FEED_USAGE_AND_RELATED_DATA,
			{ variables: this.feedUsageVariables, fetchPolicy: 'network-only' },
		]);

		await getFeedUsageAndRelatedData.promise;

		return {
			getFeedIngredient,
			getFeedUsageAndRelatedData,
			ingredientBase,
			customer: Customer,
			lastUpdatedAt: DateTime.now().toFormat("LL/dd/yyyy 'at' t"),
			startDate: params.startDate,
			endDate: params.endDate,
		};
	}
}
