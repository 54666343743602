//
//  This should be functionally similar to the LrpEndorsementModel, but with the necessary changes to work with the LgmInsuranceEndorsement and LgmProduct types.
//  To calculate delta, we'll need to determine the futures price for the given month at the sales effective date.
//  Then, we'll reduce that by the endorsement's deductable.  This will give us the strike price to use for the options.
//  We won't need to worry about multiple futures for each month.  Instead, we'll just need two options to determine the weighted average delta.
//

import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { Future, LgmInsuranceEndorsement, Option, Product, Query } from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';
import DeltaService from 'vault-client/services/delta';
import { setOwner } from '@ember/application';
import { queryManager } from 'ember-apollo-client';
import { isStringExcludeEmpty, isFiniteNumber } from 'vault-client/utils/logic-utils';
import { task } from 'ember-concurrency';
// This query fetches the current futures for a product
const CURRENT_FUTURES = gql`
	query currentFutures($id: String!) {
		Product(id: $id) {
			id
			slug
			CurrentFutures {
				id
				barchartSymbol
				displayExpiresAt
			}
			StandardProductLotSpecification {
				id
				lotSize
			}
		}
	}
`;

export const GET_PRODUCT = gql`
	query getProduct($id: String!) {
		Product(id: $id) {
			id
			slug
			StandardProductLotSpecification {
				id
				lotSize
			}
		}
	}
`;

type CurrentFuturesQuery = {
	Product: Query['Product'];
};

type Product_Args = {
	id: string;
};

type CoverageMonthSettlements = {
	coverageMonth?: string;
	closePrice: number;
	coveragePrice: number;
	future?: Future;
	topOption?: Option;
	bottomOption?: Option;
	topOptionSymbol?: string;
	bottomOptionSymbol?: string;
	target: number;
};

type LastClosePrice = {
	future: Future;
	coverageMonth: string;
	closePrice: number;
	coveragePrice: number;
	target: number;
};

function isLastClosePrice(price: any): price is LastClosePrice {
	return (
		price.future !== undefined &&
		isStringExcludeEmpty(price.coverageMonth) &&
		isFiniteNumber(price.closePrice) &&
		isFiniteNumber(price.closePrice) &&
		isFiniteNumber(price.coveragePrice) &&
		isFiniteNumber(price.target)
	);
}

// This query fetches the two put options that are closest to the coverage price
const NEAR_OPTIONS = gql`
	query nearOptions($underlyingInstrumentId: String!, $coveragePrice: Float!) {
		topOption: Options(
			where: { underlyingInstrumentId: { equals: $underlyingInstrumentId }, strike: { gte: $coveragePrice }, optionType: { equals: Put } }
			limit: 1
			orderBy: { strike: Asc }
		) {
			id
			barchartSymbol
			expiresAt
		}
		bottomOption: Options(
			where: { underlyingInstrumentId: { equals: $underlyingInstrumentId }, strike: { lte: $coveragePrice }, optionType: { equals: Put } }
			limit: 1
			orderBy: { strike: Desc }
		) {
			id
			barchartSymbol
			expiresAt
		}
	}
`;

export default class LgmEndorsementModel {
	@service('delta') declare deltaService: DeltaService;
	@queryManager apollo: any;

	@tracked endorsement: LgmInsuranceEndorsement;
	@tracked product: Product | undefined | null;
	@tracked productFromModelClass: Product | undefined | null = null;
	@tracked coverageMonthPrices: CoverageMonthSettlements[] = [];

	constructor(owner: any, endorsement: LgmInsuranceEndorsement, product?: Product | undefined) {
		setOwner(this, owner);
		this.endorsement = endorsement;

		this.product = product;
		this.fetchProduct.perform();
		this.fetchOptions.perform();
	}

	fetchProduct = task(async () => {
		const productQuery = useQuery<CurrentFuturesQuery, Product_Args>(this, () => [
			GET_PRODUCT,
			{
				variables: {
					id: this.endorsement.revenueHedgeProductId,
				},
				onComplete: (response): void => {
					this.productFromModelClass = response?.Product ?? null;
				},
				onError: (error): void => {
					console.error('Error while attempting to retrieve product.', error.message);
				},
			},
		]);
		await productQuery.promise;
	});

	get deltaPerCoverageMonth() {
		const product = this.product || this.productFromModelClass;
		if (!product) return [];
		const lotSize = product?.StandardProductLotSpecification?.lotSize || 1;

		return this.coverageMonthPrices.map((price) => {
			// Handle cases where the target isn't set.
			if (!price.target) {
				return {
					...price,
					delta: 0,
				};
			}

			const topDelta = this.deltaService.getDelta(price.topOptionSymbol || '');
			const bottomDelta = this.deltaService.getDelta(price.bottomOptionSymbol || '');

			const bottomDeltaWeight = price.coveragePrice - (price.bottomOption?.strike || price.coveragePrice);
			const topDeltaWeight = (price.topOption?.strike || price.coveragePrice) - price.coveragePrice;
			const totalWeight = topDeltaWeight + bottomDeltaWeight;

			// Calculate the weighted average of the deltas for the front and back options
			const contractDelta =
				topDeltaWeight && bottomDeltaWeight
					? (topDelta * topDeltaWeight + bottomDelta * bottomDeltaWeight) / totalWeight
					: topDelta ?? bottomDelta;
			// TODO: We need to correct the delta for the volume.
			let lgmQuantity = 0;

			// LGM logic for Lean Hog strategies
			// We need to figure out a better source for this data, especially with the use of the magic value.
			// Can the API surface it?
			if (product?.slug === 'livestock-lean-hogs') {
				const lgmSwineTargetWeight = 260;
				lgmQuantity = price.target * lgmSwineTargetWeight || 0;
			}

			// LGM Type Codes
			//Live Cattle
			//807 (calf finishing),
			//808 (yearling finishing),

			//Feeder Cattle without Unborn Steers & Heifers (I'm uncertain how Unborn Steers & Heifers affects the quantity of feeder cattle.)
			//809 (Steers Weight 1)
			//810 (Steers Weight 2)
			//811 (Heifers Weight 1)
			//812 (Heifers Weight 2)
			//813 (Brahman Weight 1)
			//814 (Brahman Weight 2)
			//815 (Dairy Weight 1)
			//816 (Dairy Weight 2)
			//817 (Unborn Steers & Heifers) - not used just for reference incase it is needed in the future
			// LGM logic for Feeder Cattle
			// Source: https://extension.missouri.edu/media/wysiwyg/Extensiondata/Pub/pdf/agguides/agecon/g00461.pdf

			if (product?.slug === 'livestock-feeder-cattle') {
				const yearlingFinishingTargetWeight = 750;
				const calfFinishingTargetWeight = 550;

				// Type Code 810, 812, 814, 816 should be Yearling
				if (
					this.endorsement.RmaType.typeCode == '810' ||
					this.endorsement.RmaType.typeCode == '814' ||
					this.endorsement.RmaType.typeCode == '812' ||
					this.endorsement.RmaType.typeCode == '816'
				) {
					lgmQuantity = price.target * yearlingFinishingTargetWeight;
				}

				// Type Code 809, 811, 813, 815 should be Calf
				if (
					this.endorsement.RmaType.typeCode == '809' ||
					this.endorsement.RmaType.typeCode == '811' ||
					this.endorsement.RmaType.typeCode == '813' ||
					this.endorsement.RmaType.typeCode == '815'
				) {
					lgmQuantity = price.target * calfFinishingTargetWeight;
				}
			}

			// LGM logic for Live Cattle
			// Source: https://extension.missouri.edu/media/wysiwyg/Extensiondata/Pub/pdf/agguides/agecon/g00461.pdf
			if (product?.slug === 'livestock-live-cattle') {
				const yearlingFinishingTargetWeight = 1250;
				const calfFinishingTargetWeight = 1150;

				// Type Code 808 should be Yearling
				if (this.endorsement.RmaType.typeCode == '808') {
					lgmQuantity = price.target * yearlingFinishingTargetWeight;
				}

				// Type Code 807 should be Calf
				if (this.endorsement.RmaType.typeCode == '807') {
					lgmQuantity = price.target * calfFinishingTargetWeight;
				}
			}

			const effectiveNumberOfContracts = lgmQuantity / lotSize;
			const delta =
				typeof effectiveNumberOfContracts === 'number' && typeof contractDelta === 'number'
					? effectiveNumberOfContracts * contractDelta
					: null;

			return {
				...price,
				delta,
			};
		});
	}

	get delta() {
		return this.deltaPerCoverageMonth.reduce((sum, price) => sum + (typeof price.delta === 'number' ? price.delta : 0), 0);
	}

	fetchOptions = task(async () => {
		const productVariables = {
			id: this.endorsement.revenueHedgeProductId,
		};

		const product: CurrentFuturesQuery = await this.apollo.query({ query: CURRENT_FUTURES, variables: productVariables });
		const futures: Future[] = product.Product?.CurrentFutures || [];

		const coverageFutures = this.endorsement.coverageMonths
			.map((month) => {
				return futures.find((future) => future.displayExpiresAt >= month);
			})
			.filter((future) => future != undefined) as Future[];

		const barchartFutureSymbols = coverageFutures.map((future) => future.barchartSymbol).uniq();

		const historyPromises = barchartFutureSymbols.map(async (symbol) => {
			return await fetch(
				`https://ondemand.websol.barchart.com/getHistory.json?apikey=e1e7b7d8187322d75e97b1c91fa2839d&symbol=${symbol}&type=daily&order=desc&endDate=${this.endorsement.salesEffectiveDate}`,
				{
					method: 'GET',
					headers: {},
				}
			);
		});

		const responses = await Promise.all(historyPromises);
		const responseJson = await Promise.all(responses.map((response) => response.json()));

		const lastClosePrices = coverageFutures
			.map((future, index) => {
				const response = responseJson.find((r) => r.results?.firstObject?.symbol == future.barchartSymbol);
				const closePrice = response?.results?.firstObject?.close as number | undefined;
				const coveragePrice = closePrice ? closePrice - this.endorsement.deductibleAmount : null;
				const coverageMonth = this.endorsement.coverageMonths[index];
				const perMonthData = this.endorsement.perMonthData?.find((data) => data.startDate == coverageMonth);
				return {
					future,
					coverageMonth,
					closePrice,
					coveragePrice,
					target: perMonthData?.target as number | undefined,
				};
			})
			// Filter out any items that don't have all of their properties set
			.filter(isLastClosePrice);

		// For each item in lastClosePrices, fetch the put options that are closest to the coverage price
		const coverageMonthOptionPromises = lastClosePrices.map((price) => {
			const variables = {
				underlyingInstrumentId: price.future.id,
				coveragePrice: price.coveragePrice,
			};

			return this.apollo.query({ query: NEAR_OPTIONS, variables });
		});

		const coverageMonthOptions = await Promise.all(coverageMonthOptionPromises);

		const coverage = coverageMonthOptions.map((options, index) => {
			const topOptionSymbol = options?.topOption?.firstObject?.barchartSymbol;
			const bottomOptionSymbol = options?.bottomOption?.firstObject?.barchartSymbol;

			if (topOptionSymbol) {
				this.deltaService.register(topOptionSymbol);
			}

			if (bottomOptionSymbol) {
				this.deltaService.register(bottomOptionSymbol);
			}

			return {
				...lastClosePrices[index],
				topOption: options?.topOption?.firstObject,
				bottomOption: options?.bottomOption?.firstObject,
				topOptionSymbol,
				bottomOptionSymbol,
			};
		});

		this.coverageMonthPrices = coverage;
	});
}
