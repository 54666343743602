import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='relative' ...attributes>\n\t<canvas id={{@id}} {{did-insert this.createChart}}></canvas>\n</div>", {"contents":"<div class='relative' ...attributes>\n\t<canvas id={{@id}} {{did-insert this.createChart}}></canvas>\n</div>","moduleName":"vault-client/components/volatility-curve-chart.hbs","parseOptions":{"srcName":"vault-client/components/volatility-curve-chart.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
// @ts-ignore
import fetch from 'fetch';

import { Chart, ChartConfiguration, ScriptableTooltipContext } from 'chart.js';

interface OptionStrike {
	strike: number;
	options: any;
}

interface ImpliedVolatilityChartArgs {
	id: string;
	barchartSymbol?: string;
	optionChain?: OptionStrike[];
	displayFactor?: number;
	fractionDigits?: number;
}

export default class ImpliedVolatilityChart extends Component<ImpliedVolatilityChartArgs> {
	@tracked chart: Chart | null = null;
	@tracked optionChain: Array<OptionStrike> = [];

	get chartOptions() {
		const getOrCreateTooltip = (chart: Chart) => {
			let tooltipEl: HTMLDivElement | null | undefined = chart.canvas.parentNode?.querySelector('div');
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.classList.add(
					'line-chart-tooltip',
					'line-chart-tooltip-bottom',
					'border',
					'border-brand-blue-80',
					'bg-white',
					'text-brand-gray-90',
					'absolute',
					'pointer-events-none',
					'transform',
					'-translate-x-2/4',
					'transition-all',
					'ease-linear',
					'duration-50',
					'opacity-1',
					'z-10',
					'text-left',
					'p-2',
					'rounded-sm'
				);

				const tooltipUL = document.createElement('ul');
				tooltipUL.classList.add('tooltipul');

				tooltipEl.append(tooltipUL);
				chart.canvas.parentNode?.append(tooltipEl);
			}
			return tooltipEl;
		};

		const customTooltip = (context: ScriptableTooltipContext<'line'>) => {
			const { chart, tooltip } = context;
			const tooltipEl = getOrCreateTooltip(chart);
			const tooltipUL = tooltipEl.querySelector('ul')!;

			if (tooltip.opacity === 0) {
				tooltipEl.classList.replace('opacity-1', 'opacity-0');
				return;
			}

			if (tooltip.body) {
				const titleLines = tooltip.title || [];
				const dataPoints = tooltip.dataPoints;
				const tooltipLI = document.createElement('li');
				tooltipLI.classList.add('whitespace-nowrap');

				// Title Loop
				titleLines.forEach((title: string) => {
					const formattedTitle = title;

					tooltipUL.appendChild(tooltipLI);

					const tooltipSpan = document.createElement('span');
					tooltipSpan.classList.add('text-sm', 'font-semibold', 'text-brand-blue-80', 'block');
					tooltipLI.appendChild(tooltipSpan);

					const tooltipTitle = document.createTextNode(formattedTitle);
					tooltipSpan.appendChild(tooltipTitle);
				});

				// Body Loop
				const tooltipBodyP = document.createElement('p');
				dataPoints.forEach((dataPoint, i: number) => {
					const displayBlockSpan = document.createElement('span');
					displayBlockSpan.classList.add('block', 'text-xs');
					const colors = tooltip.labelColors[i];
					const colorCircle = document.createElement('span');
					colorCircle.classList.add('rounded-full', 'w-2', 'h-2', 'inline-block', 'mr-2');
					colorCircle.style.background = colors.borderColor as string;
					colorCircle.style.border = colors.borderColor as string;

					const formattedValue = `IV: ${Intl.NumberFormat(undefined, { style: 'percent' }).format(dataPoint.raw as number)}`;

					const textLabel = document.createTextNode(formattedValue);

					// Append color label and text
					displayBlockSpan.appendChild(colorCircle);
					displayBlockSpan.appendChild(textLabel);
					tooltipBodyP.appendChild(displayBlockSpan);
				});

				// Remove old children
				while (tooltipUL.firstChild) {
					tooltipUL.firstChild.remove();
				}

				// Add new children
				tooltipUL.appendChild(tooltipLI);
				tooltipLI.appendChild(tooltipBodyP);
				tooltipEl.classList.replace('opacity-0', 'opacity-1');

				// Position tooltip
				const position = context.chart.canvas.getBoundingClientRect();
				const { width: tooltipElWidth, height: tooltipElHeight } = tooltipEl.getBoundingClientRect();

				let offsetX = 0;
				let offsetY = 0;

				if (tooltip.caretX < tooltipElWidth / 2) {
					offsetX = tooltip.caretX + tooltipElWidth / 2 + 12;
					offsetY = tooltip.caretY - tooltipElHeight / 2;

					tooltipEl.classList.remove('line-chart-tooltip-bottom', 'line-chart-tooltip-right');
					tooltipEl.classList.add('line-chart-tooltip-left');
				} else if (position.width - tooltip.caretX < tooltipElWidth / 2) {
					offsetX = tooltip.caretX - tooltipElWidth / 2 - 12;
					offsetY = tooltip.caretY - tooltipElHeight / 2;

					tooltipEl.classList.remove('line-chart-tooltip-bottom', 'line-chart-tooltip-left');
					tooltipEl.classList.add('line-chart-tooltip-right');
				} else {
					offsetX = tooltip.caretX;
					offsetY = tooltip.caretY - tooltipElHeight - 12;
					tooltipEl.classList.remove('line-chart-tooltip-right', 'line-chart-tooltip-left');
					tooltipEl.classList.add('line-chart-tooltip-bottom');
				}

				tooltipEl.style.left = offsetX + 'px';
				tooltipEl.style.top = offsetY + 'px';
			}
		};

		return {
			maintainAspectRatio: false,
			responsive: true,
			plugins: {
				tooltip: {
					enabled: false,
					external: customTooltip,
					displayColors: false,
					callbacks: {
						title: (context: any) => {
							return `Strike: ${Intl.NumberFormat(undefined, {
								minimumFractionDigits: this.args.fractionDigits || 0,
							}).format(context[0].parsed?.x)}`;
						},
						label: (context: any) => {
							return `IV: ${Intl.NumberFormat(undefined, { style: 'percent' }).format(context.parsed.y)}`;
						},
					},
				},
				legend: {
					display: false,
				},
			},
			interaction: {
				intersect: false,
			},
			scales: {
				x: {
					type: 'linear',
					ticks: {
						fontColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-800'),
						fontSize: 12,
						callback: (value: number) => {
							return Intl.NumberFormat(undefined, {
								minimumFractionDigits: this.args.fractionDigits || 0,
							}).format(value);
						},
					},
					title: {
						display: true,
						text: 'Strike Price',
					},
					grid: {
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-300'),
					},
				},
				y: {
					title: {
						display: true,
						text: 'Implied Volatility',
					},
					ticks: {
						font: {
							color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-800'),
							size: 12,
						},
						callback: (value: number) => {
							return Intl.NumberFormat(undefined, { style: 'percent' }).format(value);
						},
					},
				},
			},
		};
	}

	@action
	async createChart(element: HTMLCanvasElement) {
		this.optionChain = [...(this.args.optionChain ?? (await this.retrieveOptionsChain()))].sort((a, b) => (a.strike < b.strike ? -1 : 1));

		const data = {
			labels: this.optionChain?.map((strikeLevel: OptionStrike) => {
				return strikeLevel.strike * (this.args.displayFactor || 0.01);
			}),
			datasets: [
				{
					backgroundColor: '#ffca8a',
					borderColor: '#ffa72c',
					borderWidth: 2,
					pointRadius: 0,
					fill: false,
					label: 'Price',
					data: this.optionChain?.map((strikeLevel: OptionStrike) => {
						const callVolume = +strikeLevel.options.Call?.volume,
							callOI = strikeLevel.options.Call?.openInterest,
							putVolume = +strikeLevel.options.Put?.volume,
							putOI = strikeLevel.options.Put?.openInterest;

						if (callVolume === putVolume) {
							return callOI > putOI
								? strikeLevel.options.Call?.impliedVolatility / 100
								: strikeLevel.options.Put?.impliedVolatility / 100 || 0;
						} else {
							return callVolume > putVolume
								? strikeLevel.options.Call?.impliedVolatility / 100
								: strikeLevel.options.Put?.impliedVolatility / 100 || 0;
						}
					}),
				},
			],
		};

		const config: ChartConfiguration = {
			type: 'line',
			data,
			options: this.chartOptions as any,
		};

		this.chart = new Chart(element, config);
	}

	@action
	async retrieveOptionsChain(): Promise<OptionStrike[]> {
		if (!this.args.barchartSymbol) {
			return [];
		}

		const optionResponse = await fetch(
			`https://ondemand.websol.barchart.com/getFuturesOptions.json?apikey=e1e7b7d8187322d75e97b1c91fa2839d&contract=${this.args.barchartSymbol}`
		);

		const options = (await optionResponse.json()).results || [];

		const optionsMap = new Map();

		options.forEach((option: any) => {
			if (optionsMap.has(option.strike)) {
				optionsMap.get(option.strike)[option.type] = option;
			} else {
				optionsMap.set(option.strike, {});
				optionsMap.get(option.strike)[option.type] = option;
			}
		});

		const optionsArray = Array.from(optionsMap, ([strike, options]) => ({
			strike,
			options,
		}));

		return optionsArray;
	}
}
