import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n\t{{#unless @legendId}}\n\t\t<div class='mb-2' id={{this.legendId}}></div>\n\t{{/unless}}\n\t<div class='relative {{if @containerHeight @containerHeight \"h-full\"}} {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t{{#if this.dataIsEmpty}}\n\t\t\t<div class='h-full flex flex-col justify-center items-center'>\n\t\t\t\t<Vault::UiSimpleWell class='mx-4 max-w-96'>\n\t\t\t\t\t<:header>\n\t\t\t\t\t\t<div class='text-center align-middle'>No Data Found</div>\n\t\t\t\t\t</:header>\n\t\t\t\t</Vault::UiSimpleWell>\n\t\t\t</div>\n\t\t{{else}}\n\t\t\t<BarChart\n\t\t\t\t@chartData={{this.chartData}}\n\t\t\t\t@options={{this.chartOptions}}\n\t\t\t\t@data={{this.data}}\n\t\t\t\t@id={{this.chartId}}\n\t\t\t\t@plugins={{this.plugins}}\n\t\t\t\t@updateChart={{this.updateChart}}\n\t\t\t/>\n\t\t{{/if}}\n\t</div>\n</div>", {"contents":"<div ...attributes>\n\t{{#unless @legendId}}\n\t\t<div class='mb-2' id={{this.legendId}}></div>\n\t{{/unless}}\n\t<div class='relative {{if @containerHeight @containerHeight \"h-full\"}} {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t{{#if this.dataIsEmpty}}\n\t\t\t<div class='h-full flex flex-col justify-center items-center'>\n\t\t\t\t<Vault::UiSimpleWell class='mx-4 max-w-96'>\n\t\t\t\t\t<:header>\n\t\t\t\t\t\t<div class='text-center align-middle'>No Data Found</div>\n\t\t\t\t\t</:header>\n\t\t\t\t</Vault::UiSimpleWell>\n\t\t\t</div>\n\t\t{{else}}\n\t\t\t<BarChart\n\t\t\t\t@chartData={{this.chartData}}\n\t\t\t\t@options={{this.chartOptions}}\n\t\t\t\t@data={{this.data}}\n\t\t\t\t@id={{this.chartId}}\n\t\t\t\t@plugins={{this.plugins}}\n\t\t\t\t@updateChart={{this.updateChart}}\n\t\t\t/>\n\t\t{{/if}}\n\t</div>\n</div>","moduleName":"vault-client/components/dashboard-pnl-chart.hbs","parseOptions":{"srcName":"vault-client/components/dashboard-pnl-chart.hbs"}});
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import getCSSVariable from 'vault-client/utils/get-css-variable';
import { service } from '@ember/service';
import { getCustomTooltip, getCustomLegend, CustomTooltipOptions } from 'vault-client/utils/chart-utils';
import { action } from '@ember/object';

interface DashboardPnlChartArgs {
	data: DataValues;
	labels: string[];
	legendId?: string;
	containerClasses?: string;
	containerHeight?: string;
	sharedLegend?: boolean;
	chartId?: string;
	yMin?: number | string;
	fractionDigits?: number;
}

const DEFAULT_CHART_COLORS = [
	getCSSVariable('--brand-interactive-blue-70'),
	getCSSVariable('--brand-orange-40'),
	getCSSVariable('--brand-lime-40'),
	getCSSVariable('--brand-lemon-40'),
	getCSSVariable('--brand-teal-60'),
	getCSSVariable('--brand-purple-50'),
];

type DataValues = { [label in string]?: (number | null)[] };

export default class DashboardPnlChart extends Component<DashboardPnlChartArgs> {
	constructor(owner: unknown, args: DashboardPnlChartArgs) {
		super(owner, args);
	}

	@service media: any;

	id = guidFor(this);

	get chartId() {
		return this.args.chartId ?? `dashboard-chart-${this.id}`;
	}

	get data() {
		return this.args.data;
	}

	get tooltipId() {
		return `${this.chartId}-tooltip`;
	}

	get legendId() {
		return this.args.legendId ?? `${this.chartId}-legend`;
	}

	get labels() {
		return this.args.labels;
	}

	get chartOptions(): ChartOptions<'bar'> {
		const customTooltipOptions: CustomTooltipOptions = {
			valueFormatter: (val: number | string) => {
				if (val == null) return '-';
				if (typeof val === 'string') return val;
				return Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: this.args.fractionDigits ?? (val !== 0 && val > -1 && val < 1 ? 2 : 0),
					maximumFractionDigits: this.args.fractionDigits ?? (val !== 0 && val > -1 && val < 1 ? 2 : 0),
					currencySign: 'accounting',
				}).format(val);
			},
		};

		const chartOptions: ChartOptions<'bar'> = {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				tooltip: {
					mode: 'index',
					external: getCustomTooltip(customTooltipOptions),
					enabled: false,
					displayColors: false,
				},
				legend: {
					display: false,
				},
			},
			interaction: {
				intersect: false,
				mode: 'index',
			},
			datasets: {
				bar: {
					borderRadius: 2,
					categoryPercentage: 0.8,
				},
			},
			scales: {
				x: {
					grid: {
						display: false,
					},
					stacked: true,
				},
				y: {
					stacked: true,
					ticks: {
						callback: (tickValue) => {
							if (typeof tickValue === 'string') {
								return tickValue;
							} else {
								return Intl.NumberFormat('en-US', {
									minimumFractionDigits: this.args.fractionDigits,
									maximumFractionDigits: this.args.fractionDigits,
									style: 'currency',
									currency: 'USD',
									currencySign: 'accounting',
									notation: 'compact',
									compactDisplay: 'short',
								}).format(tickValue);
							}
						},
					},
				},
			},
		};

		if (this.args.yMin) {
			chartOptions.scales!.y!.min = this.args.yMin;
		}

		return chartOptions;
	}

	get chartData(): ChartData {
		const datasets = Object.keys(this.data ?? {}).map((label, i) => ({
			label: label,
			data: this.data[label as string] ?? [],
			borderColor: DEFAULT_CHART_COLORS[i],
			backgroundColor: DEFAULT_CHART_COLORS[i],
			fill: true,
		}));

		return {
			labels: this.labels,
			datasets,
		};
	}

	get plugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;
		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId),
			},
		];
	}

	get dataIsEmpty() {
		return !Object.keys(this.data).some((key) => this.data[key]?.some((x) => !!x));
	}

	@action
	updateChart(chart: Chart<'line'>, data: Record<string, number[]>) {
		chart.data.datasets.forEach((dataset) => {
			if (dataset.label) {
				dataset.data = data[dataset.label];
			}
		});

		// Update the yMin to accommodate switching between totals and cwt
		if (chart.options?.scales?.y?.min && this.args.yMin) {
			chart.options.scales.y.min = this.args.yMin;
		}

		chart.update('none');
	}
}
