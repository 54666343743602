import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { Maybe } from 'graphql/jsutils/Maybe';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import BusinessesBusinessFeedTargetsRoute from 'vault-client/routes/businesses/business/feed-targets';
import { GrainFeedPlan, GrainTargetOrder } from 'vault-client/types/graphql-types';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { CURRENT_FEED_YEAR, FEED_YEAR_OPTIONS } from 'vault-client/utils/feed-utils';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';

type TargetRow = {
	id: string;
	orderType: string | null;
	business?: Maybe<string>;
	ingredient?: Maybe<string>;
	contractNumber?: Maybe<string>;
	futurePrice?: Maybe<number>;
	futuresMonth?: Maybe<string>;
	symbol?: Maybe<string>;
	tons?: Maybe<number>;
	basis?: Maybe<number>;
	flatPrice?: Maybe<number>;
	deliveryStartDate?: Maybe<string>;
	deliveryEndDate?: Maybe<string>;
	seller?: Maybe<string>;
	location?: Maybe<string>;
};

export default class FeedTargetsIndexController extends Controller {
	declare model: ModelFrom<BusinessesBusinessFeedTargetsRoute>;

	@tracked page = 0;
	@tracked size = 100;
	@tracked startDate: string = CURRENT_FEED_YEAR.startDate;
	@tracked endDate: string = CURRENT_FEED_YEAR.endDate;

	queryParams = ['startDate', 'endDate'];

	timePeriodOptions = FEED_YEAR_OPTIONS.NUMBER;

	get currentTimePeriodOption() {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	get totalNumFeedFills() {
		return this.model.getFeedTargets.data?.GrainTargetOrderCount?.count ?? 0;
	}

	get targetsColumns(): TableColumn[] {
		return [
			{
				id: 'b4d5e014-3b9d-42ac-8bd7-b3bfb12fbeca',
				name: 'Order Type',
				cellComponent: CellComponents.String,
				valuePath: 'orderType',
				textAlign: 'left',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '21cd54f4-4e53-4a04-993d-50ea5ad54de6',
				name: 'Category',
				cellComponent: CellComponents.String,
				valuePath: 'category',
				textAlign: 'left',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f03f471b-0462-4157-bf35-6a79e41971b0',
				name: 'Status',
				cellComponent: CellComponents.String,
				valuePath: 'status',
				textAlign: 'left',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f7aaac7c-3709-4436-9e2f-8a4898db4b2f',
				name: 'Contract Number',
				cellComponent: CellComponents.String,
				valuePath: 'contractNumber',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '32f9135e-60a4-496b-a8a9-ee61060a9c4e',
				name: 'Ingredient',
				cellComponent: CellComponents.String,
				valuePath: 'category',
				textAlign: 'left',
				minWidth: 100,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '65ff86f2-ecb2-4d40-a463-1227e571b6e7',
				name: 'Tons',
				cellComponent: CellComponents.IntlNumberFormat,
				valuePath: 'amount',
				textAlign: 'right',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a3c83046-2f9c-4abc-b567-22d67df5914d',
				name: 'Futures Month',
				cellComponent: CellComponents.MonthFormat,
				valuePath: 'futuresMonth',
				textAlign: 'left',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '937999f7-cf85-49e7-bd94-1bff752e85c5',
				name: 'Futures Price',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 2,
					displayFactor: 2,
				},
				valuePath: 'futurePrice',
				textAlign: 'right',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'e40181f2-4141-45a8-ac14-fb764b2c28dd',
				name: 'Basis',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				valuePath: 'basis',
				textAlign: 'right',
				width: 80,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'f513fada-c803-482c-a66b-128b0ec51eaa',
				name: 'Flat Price',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				valuePath: 'flatPrice',
				textAlign: 'right',
				width: 100,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd6c54e72-b057-4bc2-b0c3-88605542bfaf',
				name: 'Delivery Start',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { day: 'numeric', month: 'numeric', year: 'numeric' },
				valuePath: 'deliveryStartDate',
				textAlign: 'left',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f75ca341-f215-46e7-8129-37ea8f16575c',
				name: 'Delivery End',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { day: 'numeric', month: 'numeric', year: 'numeric' },
				valuePath: 'deliveryEndDate',
				textAlign: 'left',
				width: 130,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '780768da-d388-478f-995d-e29057bb4646',
				name: 'Seller',
				cellComponent: CellComponents.String,
				valuePath: 'seller',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6783dea5-fc59-4a2a-a615-94ce3e8d2e06',
				name: 'Location',
				cellComponent: CellComponents.String,
				valuePath: 'location',
				textAlign: 'left',
				width: 150,
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
		];
	}

	/* Using the items fn to call the targets to get the translated values for the CSV download */
	@action
	formatAsTargetRows(grainTargetOrders: Maybe<GrainTargetOrder[]>): TargetRow[] {
		return (
			grainTargetOrders?.map((target) => {
				const feedPlan = target.Plan as GrainFeedPlan;

				// Feed Plan "bushels" are actually tons
				const tons = target.bushels ?? 0;
				return {
					id: target.id,
					orderType: getSalesTypeDisplayValue(target.salesType),
					business: feedPlan.Customer?.name,
					category: feedPlan.FeedCategory.name,
					contractNumber: target.contractNumber,
					futuresMonth: target.futuresMonth,
					futurePrice: target.futurePrice,
					amount: tons,
					basis: target.basis,
					flatPrice: target.feedPrice,
					deliveryStartDate: target.deliveryStartDate,
					deliveryEndDate: target.deliveryEndDate,
					seller: target.Seller?.name,
					location: target.Location?.name,
					status: target.status,
				};
			}) ?? []
		);
	}

	get targets(): TargetRow[] {
		return this.formatAsTargetRows(this.model.getFeedTargets.data?.GrainTargetOrders);
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}
}
