import Controller from '@ember/controller';
import { CellComponents } from 'vault-client/types/vault-table';

export default class FeedController extends Controller {
	feedOverviewRoutePath = '';
	feedCostsRoutePath = '';
	feedExpensesRoutePath = '';
	feedFillsRoutePath = '';
  feedTargetsRoutePath = '';

	get columns() {
		const baseColumns = [
			{
				id: 'd2bd7193-28e5-4916-b7e4-624921588898',
				name: 'Name',
				valuePath: 'name',
				minWidth: 225,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoutePath: 'linkRoute',
				linkModelPath: 'id',
			},
		];

		return baseColumns;
	}

	get rows() {
		return [
			{
				name: 'Feed Overview',
				linkRoute: this.feedOverviewRoutePath,
			},
			{
				name: 'Fixed Costs',
				linkRoute: this.feedCostsRoutePath,
			},
			{
				name: 'Total Feed Expenses',
				linkRoute: this.feedExpensesRoutePath,
			},
			{
				name: 'Feed Contracts',
				linkRoute: this.feedFillsRoutePath,
			},
      {
				name: 'Feed Targets',
				linkRoute: this.feedTargetsRoutePath,
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/feed': FeedController;
	}
}
