import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';
import { getClient, gql, useQuery } from 'glimmer-apollo';
import { action } from '@ember/object';
import PermissionsService from './permissions';
import { GrainCropPlan, GrainFeedPlan, GrainTargetOrder, Query, TypeOfGrainPlan } from 'vault-client/types/graphql-types';

const NEW_TARGETS = gql`
	query NewTargets {
		GrainTargetOrders(where: { status: { equals: New } }) {
			id
			createdBy
			status
			Plan {
				id
				type
				... on GrainCropPlan {
					CropYear {
						id
						year
					}
					Crop {
						id
						name
					}
				}
				... on GrainFeedPlan {
					startDate
					FeedCategory {
						id
						name
					}
				}
			}
		}
	}
`;

type GetNewTargets = {
	GrainTargetOrders: Query['GrainTargetOrders'];
};

export default class NewTargetMonitor extends Service {
	@service declare permissions: PermissionsService;
	@tracked previouslyExistingTargets: Record<string, number> | null = null;

	cache = getClient(this).cache;

	getNewTargets = useQuery<GetNewTargets>(this, () => [
		NEW_TARGETS,
		{ pollInterval: 10000, onComplete: (data) => this.updateTargets(data?.GrainTargetOrders ?? []) },
	]);

	get data() {
		return this.getNewTargets.data;
	}

	get grainCount() {
		return this.data?.GrainTargetOrders?.filter((target) => target.Plan?.type === TypeOfGrainPlan.Crop).length ?? null;
	}

	get feedCount() {
		return this.data?.GrainTargetOrders?.filter((target) => target.Plan?.type === TypeOfGrainPlan.Feed).length ?? null;
	}

	@action
	updateTargets(grainTargetOrders: GrainTargetOrder[]) {
		if (this.previouslyExistingTargets === null) {
			this.setPreviouslyExistingTargets(grainTargetOrders);
			return;
		}

		let updated = false;
		const newTargets = grainTargetOrders.filter((target) => {
			return !this.previouslyExistingTargets?.[target.id];
		});

		newTargets.forEach((target) => {
			if (target.createdBy === this.permissions.userName) return;

			updated = true;
			if (target.Plan?.type === TypeOfGrainPlan.Crop) {
				const cropPlan = target.Plan as GrainCropPlan;
				new Notification(`${target.createdBy} created a new ${cropPlan?.CropYear?.year} ${cropPlan?.Crop?.name} target.`);
			}
			if (target.Plan?.type === TypeOfGrainPlan.Feed) {
				const feedPlan = target.Plan as GrainFeedPlan;
				new Notification(`${target.createdBy} created a new ${feedPlan?.startDate} ${feedPlan?.FeedCategory?.name} target.`);
			}
		});

		this.setPreviouslyExistingTargets(grainTargetOrders);

		if (updated) {
			this.evictTargetsCache();
		}
	}

	evictTargetsCache() {
		this.cache.evict({ fieldName: 'GrainTargetOrders' });
		this.cache.evict({ fieldName: 'GrainTargetOrder' });
		this.cache.evict({ fieldName: 'AggregateGrainTargetOrders' });
		this.cache.gc();
	}

	setPreviouslyExistingTargets(newTargets: GrainTargetOrder[]) {
		this.previouslyExistingTargets = newTargets.reduce<Record<string, number>>((acc, target) => {
			acc[target.id] = 1;
			return acc;
		}, {});
	}
}
// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'new-target-monitor': NewTargetMonitor;
	}
}
