import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n\tclass='mb-4'\n\t{{did-insert (perform this.fetchHistoricalData)}}\n\t{{did-update (perform this.fetchHistoricalData) @numberOfYears}}\n\t...attributes\n>\n\t{{#if this.isLoadingData}}\n\t\t<Vault::UiLoadingSpinner />\n\t{{else}}\n\t\t<div id={{this.legendId}} class='ml-6'></div>\n\t\t<div class='relative h-64'>\n\t\t\t<Chart\n\t\t\t\t@id={{this.chartId}}\n\t\t\t\t@chartData={{this.chartData}}\n\t\t\t\t@options={{this.chartOptions}}\n\t\t\t\t@plugins={{this.plugins}}\n\t\t\t\t@type='line'\n\t\t\t\t@data={{this.historicalFuturesData}}\n\t\t\t\tdata-chart-type={{this.internalChartType}}\n\t\t\t/>\n\t\t</div>\n\t{{/if}}\n</div>", {"contents":"<div\n\tclass='mb-4'\n\t{{did-insert (perform this.fetchHistoricalData)}}\n\t{{did-update (perform this.fetchHistoricalData) @numberOfYears}}\n\t...attributes\n>\n\t{{#if this.isLoadingData}}\n\t\t<Vault::UiLoadingSpinner />\n\t{{else}}\n\t\t<div id={{this.legendId}} class='ml-6'></div>\n\t\t<div class='relative h-64'>\n\t\t\t<Chart\n\t\t\t\t@id={{this.chartId}}\n\t\t\t\t@chartData={{this.chartData}}\n\t\t\t\t@options={{this.chartOptions}}\n\t\t\t\t@plugins={{this.plugins}}\n\t\t\t\t@type='line'\n\t\t\t\t@data={{this.historicalFuturesData}}\n\t\t\t\tdata-chart-type={{this.internalChartType}}\n\t\t\t/>\n\t\t</div>\n\t{{/if}}\n</div>","moduleName":"vault-client/components/historical-percentile-chart.hbs","parseOptions":{"srcName":"vault-client/components/historical-percentile-chart.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { DateTime } from 'luxon';
import { percentRank } from 'vault-client/utils/percentile';
import { Future } from 'vault-client/types/graphql-types';
import { guidFor } from '@ember/object/internals';

import { Chart, ScriptableTooltipContext } from 'chart.js';
import { CMEMonthCodes } from 'vault-client/utils/cme-month-codes';
import getCSSVariable from 'vault-client/utils/get-css-variable';
import { task } from 'ember-concurrency';
import { TrackedObject } from 'tracked-built-ins';
import { InternalChartType } from 'vault-client/types/vault-client';

interface ChartArgs {
	title: string;
	numberOfYears: number;
	fractionDigits: number;
	displayFactor?: number;
	futures: Future[];
	chartId: string;
}

export enum HistoricalPercentileChartDatasetLabel {
	CurrentFuture = 'Current Future',
	HistoricalRange = 'Historical Range',
}
export default class HistoricalPercentileChart extends Component<ChartArgs> {
	@service marketData: any;
	historicalFuturesData: { [symbol: string]: { price: number | null; historicalPrices: (number | null)[] } } = new TrackedObject({});
	internalChartType = InternalChartType.HistoricalPercentile;
	title = this.args.title;
	fractionDigits = this.args.fractionDigits ?? 2;
	displayFactor = this.args.displayFactor ?? 1;

	get futures() {
		return this.args.futures.slice().sortBy('displayExpiresAt');
	}

	get isLoadingData() {
		return this.fetchHistoricalData.isRunning;
	}

	get plugins() {
		const chartId = this.args.chartId;
		const legendId = this.legendId;

		return [
			{
				afterUpdate: function (chart: Chart) {
					function toggleDataset(event: PointerEvent): void {
						const index = (event.currentTarget as HTMLElement).dataset.index;
						if (index) {
							const meta = chart.getDatasetMeta(+index);
							meta.hidden = !meta.hidden ? true : false;
							chart.update();
						}
					}
					// Make sure we're applying the legend to the right chart
					if (chart.canvas.id === chartId) {
						const legend = document.getElementById(legendId)!;
						const ul = legend?.querySelector('ul') ?? document.createElement('ul');
						ul.classList.add('flex');

						// Remove old legend items
						while (ul.firstChild) {
							ul.firstChild.remove();
						}

						while (legend.firstChild) {
							legend.firstChild.remove();
						}

						chart.data.datasets.forEach((dataset, index) => {
							const legendItem = document.createElement('li');
							legendItem.classList.add('flex', 'flex-col', 'items-center', 'mr-6', 'text-brand-gray-60', 'cursor-pointer');

							// Strike out legend item if hidden
							const meta = chart.getDatasetMeta(index);
							if (meta.hidden) {
								legendItem.classList.add('line-through');
							}

							legendItem.dataset.index = index.toString(10);
							legendItem.onclick = toggleDataset;
							legendItem.innerHTML += `
								<div class="text-sm flex items-center">
									<span style="background-color: ${dataset.borderColor}" class="w-2 h-2 rounded-full inline-block mr-1"></span>
									${dataset.label}
								<div/>
							`;

							ul.append(legendItem);
						});

						return legend?.insertBefore(ul, legend.firstChild);
					}
					return;
				},
			},
		];
	}

	get chartId() {
		return this.args.chartId ?? `${guidFor(this)}-historical-percentile-chart`;
	}

	get legendId() {
		return `${this.chartId}-legend`;
	}

	get tooltipId() {
		return `${this.chartId}-tooltip`;
	}

	get chartDataRaw() {
		const size = Object.keys(this.historicalFuturesData).length;
		if (size < 2) return [[], []];

		const currentFuturePrices: (null | number)[] = [];
		const historicalFuturePrices: Array<Array<number | null>> = [];
		this.futures.forEach((future) => {
			const barchartSymbol = future.barchartSymbol;
			if (!barchartSymbol) {
				currentFuturePrices.push(null);
				historicalFuturePrices.push([]);
				return;
			}

			const data = this.historicalFuturesData[barchartSymbol];
			currentFuturePrices.push(data?.price ?? null);
			historicalFuturePrices.push(data?.historicalPrices ?? []);
		});

		return [currentFuturePrices, historicalFuturePrices];
	}

	fetchHistoricalData = task(this, { restartable: true }, async () => {
		const historicalDataPromises = this.futures.map(async (future) => {
			const barchartSymbol = future.barchartSymbol;
			if (!barchartSymbol) return Promise.resolve();

			if (!this.args.numberOfYears) {
				throw new Error('numberOfYears not provided to historical percentile chart');
			}

			const historicalFutureSymbols: string[] = [barchartSymbol];

			for (let i = 0; i < this.args.numberOfYears; i++) {
				const displayExpiresAt = DateTime.fromISO(future.displayExpiresAt);
				const root = future.SymbolGroup.barchartRootSymbol;
				const month = displayExpiresAt.month;
				const year = displayExpiresAt.year - (i + 1);

				if (!root || !year || !month) continue;

				const symbol = root + CMEMonthCodes[month] + (year % 100);
				historicalFutureSymbols.push(symbol);
			}

			return fetch(
				`https://ondemand.websol.barchart.com/getQuote.json?apikey=e1e7b7d8187322d75e97b1c91fa2839d&symbols=${historicalFutureSymbols.join(
					','
				)}&fields=expirationDate,fiftyTwoWkHigh,fiftyTwoWkLow`,
				{
					method: 'GET',
					headers: {},
				}
			).then(async (response) => {
				const contentType = response.headers.get('content-type');
				if (!response.ok || !contentType || !contentType.includes('application/json')) {
					throw new Error();
				}

				await response
					.json()
					.then((response) => response.results)
					.then((data: any[]) => {
						if (!data) return;

						const currentFutureQuote = data.find((quote) => quote.symbol === barchartSymbol) as
							| {
									close?: number;
									lastPrice?: number;
									settlement?: number;
									fiftyTwoWkLow?: number;
									fiftyTwoWkHigh?: number;
							  }
							| undefined;

						const historicalFuturesQuotes = data.filter((quote) => quote.symbol !== barchartSymbol) as {
							close?: number;
							lastPrice?: number;
							settlement?: number;
							fiftyTwoWkLow?: number;
							fiftyTwoWkHigh?: number;
						}[];

						const currentFuturePrice: number | null = (() => {
							if (currentFutureQuote) {
								const { close, lastPrice, settlement, fiftyTwoWkLow, fiftyTwoWkHigh } = currentFutureQuote;

								const currentSessionValue = close ?? lastPrice ?? settlement;
								const fiftyTwoWeekHighOrLow = fiftyTwoWkLow || fiftyTwoWkHigh;

								// Return currentValue if not zero, or if the future has had a value other than zero in the past year
								if ((currentSessionValue !== 0 || (currentSessionValue === 0 && fiftyTwoWeekHighOrLow)) && currentSessionValue != null)
									return currentSessionValue * this.displayFactor;
							}
							return null;
						})();

						this.historicalFuturesData[barchartSymbol] = {
							price: currentFuturePrice,
							historicalPrices: historicalFuturesQuotes.map((quote) => {
								const price = quote?.close ?? quote?.lastPrice ?? quote?.settlement ?? null;
								return price ? price * this.displayFactor : null;
							}),
						};
					});
			});
		});

		await Promise.all(historicalDataPromises);
	});

	get chartOptions() {
		const getOrCreateTooltip = (chart: Chart) => {
			let tooltipEl: HTMLDivElement | null | undefined = chart.canvas.parentNode?.querySelector('div');
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = this.tooltipId;
				tooltipEl.classList.add(
					'line-chart-tooltip',
					'line-chart-tooltip-bottom',
					'border',
					'border-brand-interactive-blue-70',
					'bg-white',
					'text-brand-grey-90',
					'absolute',
					'pointer-events-none',
					'transform',
					'-translate-x-2/4',
					'transition-all',
					'ease-linear',
					'duration-50',
					'opacity-1',
					'z-10',
					'text-left',
					'p-2',
					'rounded-sm'
				);

				const tooltipUL = document.createElement('ul');
				tooltipUL.classList.add('tooltipul');

				tooltipEl.append(tooltipUL);
				chart.canvas.parentNode?.append(tooltipEl);
			}
			return tooltipEl;
		};

		const customTooltip = (context: ScriptableTooltipContext<'line'>) => {
			const { chart, tooltip } = context;
			const tooltipEl = getOrCreateTooltip(chart);
			const tooltipUL = tooltipEl.querySelector('ul')!;

			if (tooltip.opacity === 0) {
				tooltipEl.classList.replace('opacity-1', 'opacity-0');
				return;
			}

			if (tooltip.body) {
				const titleLines = tooltip.title || [];
				const dataPoints = tooltip.dataPoints;
				const tooltipLI = document.createElement('li');
				tooltipLI.classList.add('whitespace-nowrap');

				// Title Loop
				titleLines.forEach((title: string) => {
					const formattedTitle = title;
					tooltipUL.appendChild(tooltipLI);

					const tooltipSpan = document.createElement('span');
					tooltipSpan.classList.add('text-sm', 'font-semibold', 'text-brand-interactive-blue-70', 'block');
					tooltipLI.appendChild(tooltipSpan);

					const tooltipTitle = document.createTextNode(formattedTitle);
					tooltipSpan.appendChild(tooltipTitle);
				});

				// Body Loop
				const tooltipBodyP = document.createElement('p');

				dataPoints.forEach((dataPoint: any, i: number) => {
					const displayBlockSpan = document.createElement('span');
					displayBlockSpan.classList.add('text-xs', 'flex', 'flex-row', 'items-center');
					const colors = tooltip.labelColors[i];

					const colorCircle = document.createElement('span');
					colorCircle.classList.add('rounded-full', 'w-2', 'h-2', 'inline-block', 'mr-2');
					colorCircle.style.background = colors.borderColor as string;
					colorCircle.style.border = colors.borderColor as string;

					const formattedValue = Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: this.args.fractionDigits,
						maximumFractionDigits: this.args.fractionDigits,
					}).format(dataPoint.raw as number);
					let textLabel;
					if (dataPoint.formattedValue.items) {
						const rangeItems = [
							`Max: ${Intl.NumberFormat(undefined, {
								minimumFractionDigits: this.args.fractionDigits,
								maximumFractionDigits: this.args.fractionDigits,
							}).format(dataPoint.formattedValue.raw.max)}`,
							`75%: ${Intl.NumberFormat(undefined, {
								minimumFractionDigits: this.args.fractionDigits,
								maximumFractionDigits: this.args.fractionDigits,
							}).format(dataPoint.formattedValue.raw.q3)}`,
							`50%: ${Intl.NumberFormat(undefined, {
								minimumFractionDigits: this.args.fractionDigits,
								maximumFractionDigits: this.args.fractionDigits,
							}).format(dataPoint.formattedValue.raw.median)}`,
							`25%: ${Intl.NumberFormat(undefined, {
								minimumFractionDigits: this.args.fractionDigits,
								maximumFractionDigits: this.args.fractionDigits,
							}).format(dataPoint.formattedValue.raw.q1)}`,
							`min: ${Intl.NumberFormat(undefined, {
								minimumFractionDigits: this.args.fractionDigits,
								maximumFractionDigits: this.args.fractionDigits,
							}).format(dataPoint.formattedValue.raw.min)}`,
						];

						const list = document.createElement('ul');

						rangeItems.forEach((item) => {
							const li = document.createElement('li');
							li.textContent = item;
							list.appendChild(li);
						});
						textLabel = list;
					} else {
						const future = this.args.futures[dataPoint.dataIndex];
						let text: string | null = null;

						if (future) {
							const percentileString = this.generateLineTooltipText(future);
							text = percentileString ? ` ${formattedValue}  ${percentileString}` : null;
						}

						textLabel = document.createTextNode(text ?? formattedValue);
					}

					// Append color label and text
					displayBlockSpan.appendChild(textLabel);
					tooltipBodyP.appendChild(displayBlockSpan);
				});

				// Remove old children
				while (tooltipUL.firstChild) {
					tooltipUL.firstChild.remove();
				}

				// Add new children
				tooltipUL.appendChild(tooltipLI);
				tooltipLI.appendChild(tooltipBodyP);
				tooltipEl.classList.replace('opacity-0', 'opacity-1');

				// Position tooltip
				const position = context.chart.canvas.getBoundingClientRect();

				tooltipEl.style.left = context.chart.canvas.offsetLeft + tooltip.caretX + 'px';
				tooltipEl.style.bottom = position.height - 24 + 'px';
			}
		};

		const chartOptions = {
			maintainAspectRatio: false,
			responsive: true,
			animation: false,
			spanGaps: true,
			interaction: {
				mode: 'nearest',
			},
			outlierRadius: 3,
			coef: 0,
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					external: customTooltip,
					enabled: false,
					displayColors: false,
					callbacks: {
						title: (tooltipItem: any) => {
							if (tooltipItem[0].dataset.type === 'boxplot') {
								return this.generateBoxPlotTitle(tooltipItem);
							}
							return `${tooltipItem[0].label}`;
						},
						label: (context: any) => {
							if (context.dataset.type === 'boxplot') {
								return this.generateBoxPlotLabel(context);
							} else {
								return `Price: ${Intl.NumberFormat(undefined, {
									minimumFractionDigits: this.args.fractionDigits,
									maximumFractionDigits: this.args.fractionDigits,
								}).format(context.parsed.y)}`;
							}
						},
						afterLabel: (tooltipItem: any) => {
							if (tooltipItem.dataset.afterLabel) {
								return this.futures
									.map((future) => {
										this.generateLineTooltipText(future);
									})
									.find((percentile, index) => {
										if (tooltipItem.dataIndex === index) {
											return percentile;
										} else {
											return null;
										}
									});
							} else if (tooltipItem.dataset.afterLabel === false) {
								return;
							} else {
								return tooltipItem.dataset.label;
							}
						},
					},
				},
			},
			scales: {
				x: {
					type: 'category',
					stacked: false,
					ticks: {
						fontColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-60'),
						fontSize: 12,
					},
				},
				y: {
					title: {
						display: true,
						text: 'Price',
					},
					ticks: {
						fontColor: getCSSVariable('--brand-gray-60'),
						fontSize: 12,
						callback: (value: number) => {
							return new Intl.NumberFormat(undefined, {
								minimumFractionDigits: this.fractionDigits,
								maximumFractionDigits: this.fractionDigits,
							}).format(value);
						},
					},
					beginAtZero: false,
					offset: true,
				},
			},
		};

		return chartOptions;
	}

	get labels() {
		return this.futures.map((future: { displayExpiresAt: string }) => {
			const luxonDate = DateTime.fromISO(future.displayExpiresAt);
			const date = luxonDate.toJSDate();

			const formattedDate = new Intl.DateTimeFormat('en-US', {
				month: 'short',
				year: 'numeric',
			}).format(date);
			return formattedDate;
		});
	}

	get chartData() {
		const datasets = [
			{
				backgroundColor: getCSSVariable('--brand-interactive-blue-70'),
				borderColor: getCSSVariable('--brand-interactive-blue-70'),
				borderWidth: 4,
				data: this.chartDataRaw[0],
				pointStyle: 'circle',
				fill: false,
				type: 'line',
				label: HistoricalPercentileChartDatasetLabel.CurrentFuture,
				afterLabel: true,
			},
			{
				backgroundColor: '#f2f2f2', // brand-gray-12
				borderColor: '#c5c6c7', // brand-gray-30
				borderWidth: 2,
				radius: 0,
				data: this.chartDataRaw[1],
				type: 'boxplot',
				fill: false,
				label: HistoricalPercentileChartDatasetLabel.HistoricalRange,
				afterLabel: false,
			},
		];

		return {
			datasets,
			labels: this.labels,
		};
	}

	generateBoxPlotLabel(context: any): string[] {
		const label = [
			`Max: ${Intl.NumberFormat(undefined, {
				minimumFractionDigits: this.args.fractionDigits,
				maximumFractionDigits: this.args.fractionDigits,
			}).format(context.formattedValue.raw.max)}`,
			`75%: ${Intl.NumberFormat(undefined, {
				minimumFractionDigits: this.args.fractionDigits,
				maximumFractionDigits: this.args.fractionDigits,
			}).format(context.formattedValue.raw.q3)}`,
			`50%: ${Intl.NumberFormat(undefined, {
				minimumFractionDigits: this.args.fractionDigits,
				maximumFractionDigits: this.args.fractionDigits,
			}).format(context.formattedValue.raw.median)}`,
			`25%: ${Intl.NumberFormat(undefined, {
				minimumFractionDigits: this.args.fractionDigits,
				maximumFractionDigits: this.args.fractionDigits,
			}).format(context.formattedValue.raw.q1)}`,
			`Min: ${Intl.NumberFormat(undefined, {
				minimumFractionDigits: this.args.fractionDigits,
				maximumFractionDigits: this.args.fractionDigits,
			}).format(context.formattedValue.raw.min)}`,
		];

		return label;
	}

	generateBoxPlotTitle(tooltipItem: any) {
		const numYears = tooltipItem[0].dataset.data[0].length;
		const date = DateTime.fromFormat(tooltipItem[0].label, 'MMM y');
		const formattedMonth = Intl.DateTimeFormat('en-US', {
			month: 'short',
		}).format(date.toJSDate());

		const formattedDateRange = numYears > 1 ? `${date.year - numYears} – ${date.year - 1}` : `${date.year - 1}`;

		return `${formattedMonth} ${formattedDateRange}`;
	}

	generateLineTooltipText(future: Future) {
		const barchartSymbol = future.barchartSymbol;
		if (!barchartSymbol) return null;
		const { price: lastPrice, historicalPrices: historicalDataValues } = this.historicalFuturesData[barchartSymbol];

		if (historicalDataValues && lastPrice) {
			const rank = percentRank(
				historicalDataValues.filter((x): x is number => !!x),
				lastPrice
			);

			if (rank !== null) {
				return `Percentile: ${new Intl.NumberFormat(undefined, {
					style: 'percent',
				}).format(rank)}`;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}
