import ExposureExposureHedgeMonthDetailController from 'vault-client/controllers/exposure/exposure-hedge-month-detail';

export default class OrganizationsOrganizationExposureHedgeMonthDetailController extends ExposureExposureHedgeMonthDetailController {
	exposuresRoutePath = 'organizations.organization.exposures';
	exposureRoutePath = 'organizations.organization.exposure';
	exposureHedgeMonthDetailRoutePath = 'organizations.organization.exposure-hedge-month-detail';
	lgmInsuranceEndorsementsRoutePath = 'organizations.organization.lgm-insurance-endorsements';
	lgmInsuranceEndorsementRoutePath = 'organizations.organization.lgm-insurance-endorsement';
	lrpInsuranceEndorsementsRoutePath = 'organizations.organization.lrp-insurance-endorsements';
	lrpInsuranceEndorsementRoutePath = 'organizations.organization.lrp-insurance-endorsement';
	drpInsuranceEndorsementsRoutePath = 'organizations.organization.drp-insurance-endorsements';
	drpInsuranceEndorsementRoutePath = 'organizations.organization.drp-insurance-endorsement';
	accountsRoutePath = 'organizations.organization.accounts';
	positionDetailRoutePath = 'organizations.organization.position';
	positionsRoutePath = 'organizations.organization.positions';
	feedFillsRoutePath = 'organizations.organization.feed-fills';
}
