import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import { TrackedObject } from 'tracked-built-ins/.';
import { AddEditPhysicalFeedTransactionFormData } from 'vault-client/components/add-edit-physical-feed-transaction-form';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import FeedFillsRoute from 'vault-client/routes/feed-fills';
import {
	Mutation,
	Mutation_createPhysicalFeedTransactionArgs,
	PhysicalFeedTransactionCreateDTO,
	Query,
	Query_SearchArgs,
	Query_PhysicalFeedTransactionsArgs,
	TypeOfSearchItem,
	PhysicalFeedTransaction,
	Mutation_updatePhysicalFeedTransactionArgs,
	PhysicalFeedTransactionUpdateDTO,
} from 'vault-client/types/graphql-types';
import { SearchResult } from 'vault-client/types/vault-client';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { waitForUserButtonClickChoice } from 'vault-client/utils/await-user-button-click';
import { createTrackedEmptyPhysicalFeedTransactionData } from 'vault-client/utils/feed-utils';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { ModelFrom } from 'vault-client/utils/type-utils';

type SearchQueryParam = 'customerId' | 'location' | 'seller';

const GET_SEARCH_QUERY = gql`
	query Search($query: String!, $typesToInclude: [TypeOfSearchItem!]) {
		Search(query: $query, typesToInclude: $typesToInclude) {
			id
			name
			type
		}
	}
`;

const GET_FEED_FILLS_SEARCH = gql`
	query GetFeedFills($where: PhysicalFeedTransactionFilterDTO, $scopeId: String) {
		PhysicalFeedTransactions(scopeId: $scopeId, where: $where) {
			id
			seller
			location
		}
	}
`;

const ADD_PHYSICAL_FEED_TRANSACTION = gql`
	mutation CreatePhysicalFeedTransaction($data: PhysicalFeedTransactionCreateDTO!) {
		createPhysicalFeedTransaction(data: $data) {
			id
		}
	}
`;

const UPDATE_PHYSICAL_FEED_TRANSACTION = gql`
	mutation UpdatePhysicalFeedTransaction($id: String!, $data: PhysicalFeedTransactionUpdateDTO!) {
		updatePhysicalFeedTransaction(id: $id, data: $data) {
			id
		}
	}
`;

export default class FeedFillsController extends Controller {
	declare model: ModelFrom<FeedFillsRoute>;
	guid = guidFor(this);

	@service declare router: RouterService;

	// Physical Feed Transactions can currently only be created at the business level. This value allows to to disable
	// the ability to create a physical feed transaction when not in the business scope.
	enableMutatingPhysicalFeedTransactions = false;

	// Customer filter should only be enabled in the organization scope
	enableCustomerFilter = false;

	@tracked page = 0;
	@tracked size = 100;
	@tracked deliveryStartDateStart: string | null = '1900-01-01';
	@tracked deliveryStartDateEnd: string | null =  '2999-12-31';
	@tracked deliveryEndDateStart: string | null = '1900-01-01';
	@tracked deliveryEndDateEnd: string | null = '2999-12-31';
	@tracked ingredientIds: string[] = [];
	@tracked customerId: string | null = null;
	@tracked errorMessage: string | null = null;
	@tracked isSidePanelOpen = false;
	@tracked location: string | null = null;
	@tracked seller: string | null = null;
	@tracked sorts: SortObj[] = [];
	@tracked physicalFeedTransactionFormData: AddEditPhysicalFeedTransactionFormData = createTrackedEmptyPhysicalFeedTransactionData();
	@tracked showConfirmation = false;
	@tracked originalEditValues: AddEditPhysicalFeedTransactionFormData | null = null;
	@tracked fillToDelete: PhysicalFeedTransaction | null = null;
	@tracked transition: Transition | null = null;

	addPhysicalFeedTransaction = useMutation<
		{ createPhysicalFeedTransaction: Mutation['createPhysicalFeedTransaction'] },
		Mutation_createPhysicalFeedTransactionArgs
	>(this, () => [
		ADD_PHYSICAL_FEED_TRANSACTION,
		{
			onError: (error) => {
				this.errorMessage = error.message;
			},
			onComplete: () => {
				this.resetForm();
				this.closeSidePanel();
			},
			update: (cache) => {
				cache.evict({ fieldName: 'PhysicalFeedTransactions' });
				cache.evict({ fieldName: 'PhysicalFeedTransactionsCount' });
				cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
				cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
				cache.gc();
			},
		},
	]);

	updatePhysicalFeedTransaction = useMutation<
		{ updatePhysicalFeedTransaction: Mutation['updatePhysicalFeedTransaction'] },
		Mutation_updatePhysicalFeedTransactionArgs
	>(this, () => [
		UPDATE_PHYSICAL_FEED_TRANSACTION,
		{
			onError: (error) => {
				this.errorMessage = error.message;
			},
			onComplete: () => {
				this.resetForm();
				this.closeSidePanel();
			},
			update: (cache) => {
				cache.evict({ fieldName: 'PhysicalFeedTransactions' });
				cache.evict({ fieldName: 'PhysicalFeedTransactionsCount' });
				cache.evict({ fieldName: 'FeedIngredientConsumedAndPurchasedVolumes' });
				cache.evict({ fieldName: 'AggregateFeedIngredientConsumedAndPurchasedVolumes' });
				cache.gc();
			},
		},
	]);

	businessFeedFillsRoutePath: string | null = null;

	queryParams = [
		'page',
		'sorts',
		'size',
		'deliveryStartDateStart',
		'deliveryStartDateEnd',
		'deliveryEndDateStart',
		'deliveryEndDateEnd',
		'ingredientIds',
		'customerId',
		'location',
		'seller',
	];

	get allowMutations() {
		return this.enableMutatingPhysicalFeedTransactions && this.hasWriteAccess && !this.isFoundationsClient;
	}

	get confirmationCancelButtonId() {
		return `confirmation-cancel-${this.guid}`;
	}

	get confirmationSubmitButtonId() {
		return `confirmation-submit-${this.guid}`;
	}

	// Foundations clients are not allowed to edit Feed Contracts here
	get isFoundationsClient() {
		return this.model.getFeedFills.data?.Entity?.isVgs ?? false;
	}

	get hasWriteAccess() {
		return this.model.getFeedFills.data?.Entity?.CurrentUserPermissions.canWriteOperations ?? false;
	}

	get physicalFeedTransactionFormId() {
		return `${this.guid}-add-physical-feed-transaction-form`;
	}

	get addEditPhysicalFeedTransactionForm() {
		return document.getElementById(this.physicalFeedTransactionFormId) as HTMLFormElement | null;
	}

	get isFormDirty() {
		if (this.editMode) {
			return Object.keys(this.physicalFeedTransactionFormData).some((key) => {
				const value = this.physicalFeedTransactionFormData[key as keyof AddEditPhysicalFeedTransactionFormData];
				const originalValue = this.originalEditValues?.[key as keyof AddEditPhysicalFeedTransactionFormData];

				return value !== originalValue;
			});
		} else {
			return Object.keys(this.physicalFeedTransactionFormData).some((key) => {
				const value = this.physicalFeedTransactionFormData[key as keyof AddEditPhysicalFeedTransactionFormData];
				return value !== null && value !== '';
			});
		}
	}

	get editMode() {
		return this.physicalFeedTransactionFormData.id !== undefined;
	}

	get dateRangeOptions(): UiDateFilterOption[] {
		return [
			{
				displayName: 'All Dates',
				startDate: '1900-01-01',
				endDate: '2999-12-31',
			},
			{
				displayName: 'Current Month',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().endOf('month').toISODate(),
			},
			{
				displayName: 'Next 3 Months',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
			},
			{
				displayName: 'Next 6 Months',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
			},
			{
				displayName: 'Next 12 Months',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
			},
			{
				displayName: 'Next 24 Months',
				startDate: DateTime.local().startOf('month').toISODate(),
				endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
			},
		];
	}

	get searchPlaceholder() {
		return this.enableCustomerFilter ? 'Filter by Customer, Location, or Seller' : 'Filter by Location or Seller';
	}

	get searchPrompt() {
		return this.enableCustomerFilter
			? 'Type a search term to filter data by Customer, Location, or Seller.'
			: 'Type a search term to filter data by Location or Seller.';
	}

	get query() {
		return this.model.query;
	}

	get variables() {
		return this.model.variables;
	}

	get columns(): TableColumn[] {
		return [
			{
				id: '586ba254-7641-4ac1-9191-da7ea0c8a3ec',
				name: 'Business',
				cellComponent: CellComponents.String,
				width: 200,
				...(this.businessFeedFillsRoutePath && {
					linkRoute: this.businessFeedFillsRoutePath,
					linkModelPath: 'Business.id',
				}),
				valuePath: 'Business.name',
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '8c48571a-3fe6-4d18-95d4-554b7167c36e',
				name: 'Contract Number',
				cellComponent: CellComponents.String,
				valuePath: 'contractIdentifier',
				width: 150,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: 'ce3a5237-4b49-4682-84d8-ee3cd7370720',
				name: 'Ingredient',
				cellComponent: CellComponents.String,
				valuePath: 'FeedIngredient.name',
				width: 150,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '1708e389-68a2-499b-bbf3-453c88981f95',
				name: 'Tons',
				cellComponent: CellComponents.IntlNumberFormat,
				valuePath: 'tons',
				width: 70,
				textAlign: 'right',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '08f58275-a997-48c6-9245-302e59307419',
				name: 'Flat Price',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				valuePath: 'flatPrice',
				textAlign: 'right',
				width: 100,
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '7b9ae3dd-f024-4b8e-9d1d-5646c5dc9209',
				name: 'Delivery Start Date',
				cellComponent: CellComponents.IntlDateTimeFormat,
				valuePath: 'deliveryStartDate',
				width: 160,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '029f76a0-f1fc-4d8c-8754-1fb3c5fa4f90',
				name: 'Delivery End Date',
				cellComponent: CellComponents.IntlDateTimeFormat,
				valuePath: 'deliveryEndDate',
				width: 160,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '3c66c21d-c009-4c55-b1f9-38ddee5464b2',
				name: 'Seller',
				cellComponent: CellComponents.String,
				valuePath: 'seller',
				width: 125,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '18890d8c-4d8e-4d8b-b6d2-9f325d8fba45',
				name: 'Location',
				cellComponent: CellComponents.String,
				valuePath: 'location',
				width: 160,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			...(this.allowMutations
				? [
						{
							id: 'b3671cae-fc8d-4d1e-aa66-a6af398daae9',
							name: '',
							cellComponent: CellComponents.Button,
							valuePath: '',
							componentArgs: {
								size: 'xs',
								style: 'utility',
								text: 'Edit',
								fn: this.editPhysicalFeedTransaction,
							},
							width: 80,
							textAlign: 'left',
							isFixed: '',
							isSortable: false,
							isVisible: true,
						},
						{
							id: 'bcb04638-03e2-4fd4-97be-a49e842d95da',
							name: '',
							cellComponent: CellComponents.Button,
							valuePath: '',
							componentArgs: {
								size: 'xs',
								style: 'destructive',
								text: 'Delete',
								fn: this.setFillToDelete,
							},
							width: 100,
							textAlign: 'left',
							isFixed: '',
							isSortable: false,
							isVisible: true,
						},
					]
				: []),
		];
	}

	get feedFills() {
		return this.model.getFeedFills.data?.PhysicalFeedTransactions ?? [];
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['customerId', 'location', 'seller'] as const;
		searchQueryParams.forEach((param) => {
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get totalNumFeedFills() {
		return this.model.getFeedFills.data?.PhysicalFeedTransactionsCount?.[0]?.count ?? 0;
	}

	get ingredients() {
		return this.model.getFeedFills.data?.FeedIngredients ?? [];
	}

	get selectedIngredientsString() {
		if (this.ingredientIds.length === 0) {
			return 'All';
		}

		if (this.ingredientIds.length === 1) {
			return this.ingredients.find((ingredient) => ingredient.id === this.ingredientIds[0])?.name ?? '';
		}

		return `${this.ingredientIds.length} Selected`;
	}

	get deliveryStartDate() {
		return {
			startDate: this.deliveryStartDateStart ?? '1900-01-01',
			endDate: this.deliveryStartDateEnd ?? '2999-12-31',
		};
	}

	get deliveryEndDate() {
		return {
			startDate: this.deliveryEndDateStart ?? '1900-01-01',
			endDate: this.deliveryEndDateEnd ?? '2999-12-31',
		};
	}

	get disableSubmit() {
		let formIsInvalid;
		if (this.editMode) {
			const validatedData = this.validateEditFormData(this.physicalFeedTransactionFormData);
			formIsInvalid = validatedData === false || Object.keys(validatedData).length === 0;
		} else {
			formIsInvalid = this.validateAddFormData(this.physicalFeedTransactionFormData) === false;
		}
		return formIsInvalid || this.addPhysicalFeedTransaction.loading || this.updatePhysicalFeedTransaction.loading;
	}

	@action
	setFillToDelete(fill: PhysicalFeedTransaction) {
		this.fillToDelete = fill;
	}

	@action
	closeDeleteModal() {
		this.fillToDelete = null;
	}

	@action
	updatePhysicalFeedTransactionFormData(key: keyof AddEditPhysicalFeedTransactionFormData, value: any) {
		set(this.physicalFeedTransactionFormData, key, value);
	}

	@action
	async editPhysicalFeedTransaction(physicalFeedTransaction: PhysicalFeedTransaction) {
		const continueWithEdit = await this.showDirtyFormConfirmationIfNeeded();
		if (!continueWithEdit) return;
		const data = {
			id: physicalFeedTransaction.id,
			contractNumber: physicalFeedTransaction.contractIdentifier ?? null,
			feedIngredient: physicalFeedTransaction.FeedIngredient,
			tons: physicalFeedTransaction.tons.toString(),
			deliveryStartDate: physicalFeedTransaction.deliveryStartDate,
			deliveryEndDate: physicalFeedTransaction.deliveryEndDate,
			flatPrice: physicalFeedTransaction.flatPrice ? physicalFeedTransaction.flatPrice.toString() : null,
			seller: physicalFeedTransaction.seller ?? null,
			location: physicalFeedTransaction.location ?? null,
		};

		this.originalEditValues = { ...data };
		this.physicalFeedTransactionFormData = new TrackedObject(data);

		if (!this.isSidePanelOpen) this.isSidePanelOpen = true;
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: SearchQueryParam) {
		this[filterIdentifier] = null;
		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.page = newPageVal;
		resetVaultTableScroll('feed-fills-index-table');
	}

	@action
	structureSearchResults(searchResults: SearchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	async fetchSearchResults(searchText: string): Promise<SearchResult[]> {
		const searchResults: SearchResult[] = [];
		const sellerResults: SearchResult[] = [];
		const locationResults: SearchResult[] = [];

		const promises: Promise<void>[] = [];

		if (this.enableCustomerFilter) {
			const searchQuery = useQuery<{ Search: Query['Search'] }, Query_SearchArgs>(this, () => [
				GET_SEARCH_QUERY,
				{
					variables: {
						query: searchText,
						typesToInclude: [TypeOfSearchItem.Customer],
					},
					onComplete: (data): void => {
						searchResults.push(
							...(data?.Search.map((item) => {
								switch (item.type) {
									case TypeOfSearchItem.Customer:
										return {
											type: 'Customer',
											name: item.name,
											id: item.id,
										};
									default:
										return { type: '', name: '', id: '' };
								}
							}) ?? []),
						);
					},
				},
			]);

			promises.push(searchQuery.promise);
		}

		const sellerQuery = useQuery<
			{
				PhysicalFeedTransactions: Query['PhysicalFeedTransactions'];
			},
			Query_PhysicalFeedTransactionsArgs
		>(this, () => [
			GET_FEED_FILLS_SEARCH,
			{
				variables: {
					where: {
						seller: { contains: searchText },
					},
					scopeId: this.model.scopeId,
				},
				onComplete: (data): void => {
					sellerResults.push(
						...(data?.PhysicalFeedTransactions.map((physicalFeedTransaction) => ({
							type: 'Seller',
							name: physicalFeedTransaction?.seller ?? '',
						})) ?? []),
					);
				},
			},
		]);

		promises.push(sellerQuery.promise);

		const locationQuery = useQuery<
			{
				PhysicalFeedTransactions: Query['PhysicalFeedTransactions'];
			},
			Query_PhysicalFeedTransactionsArgs
		>(this, () => [
			GET_FEED_FILLS_SEARCH,
			{
				variables: {
					where: {
						location: { contains: searchText },
					},
					scopeId: this.model.scopeId,
				},
				onComplete: (data): void => {
					locationResults.push(
						...(data?.PhysicalFeedTransactions.map((physicalFeedTransaction) => ({
							type: 'Location',
							name: physicalFeedTransaction?.location ?? '',
						})) ?? []),
					);
				},
			},
		]);

		promises.push(locationQuery.promise);

		await Promise.all(promises);

		// Seller and Locations can have duplicates, so we need to filter them out
		// Does not preserve order
		const uniqueSellerResults = [...new Map(sellerResults.map((item) => [item.name, item])).values()];
		const uniqueLocationResults = [...new Map(locationResults.map((item) => [item.name, item])).values()];

		// return combined set
		return [...searchResults, ...uniqueSellerResults, ...uniqueLocationResults];
	}

	@action
	setSearchFilterQueryParam(searchResult: SearchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (!mappedSearchFilter.filterIdentifier) return;

		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
	}

	@action
	addIngredientId(ingredientId: string | null) {
		if (ingredientId === null) {
			this.ingredientIds = [];
		} else if (this.ingredientIds.includes(ingredientId)) {
			this.ingredientIds = this.ingredientIds.filter((v) => v !== ingredientId);
		} else {
			this.ingredientIds = [...this.ingredientIds, ingredientId];
		}

		if (this.ingredientIds.length > 1 && this.ingredientIds.length === this.ingredients.length) {
			this.ingredientIds = [];
		}
	}

	@action
	setDeliveryStartDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.deliveryStartDateStart = startDate;
		this.deliveryStartDateEnd = endDate;
	}

	@action
	setDeliveryEndDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.deliveryEndDateStart = startDate;
		this.deliveryEndDateEnd = endDate;
	}

	mapSearchResult(searchResult: SearchResult) {
		let filterIdentifier: SearchQueryParam | null = null;

		switch (searchResult.type) {
			case 'Location':
				filterIdentifier = 'location';
				break;
			case 'Seller':
				filterIdentifier = 'seller';
				break;
			case 'Customer':
				filterIdentifier = 'customerId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id || searchResult.name || '',
		};
	}

	@action
	createPhysicalFeedTransaction(data: AddEditPhysicalFeedTransactionFormData) {
		const validatedData = this.validateAddFormData(data);

		if (validatedData === false) {
			this.errorMessage = 'Please fill out all required fields';
			return;
		}

		this.addPhysicalFeedTransaction.mutate({
			data: validatedData,
		});
	}

	@action
	updateExistingPhysicalFeedTransaction(data: AddEditPhysicalFeedTransactionFormData) {
		const { id } = data;
		const validatedData = this.validateEditFormData(data);

		if (validatedData === false) {
			this.errorMessage = 'Please fill out all required fields';
			return;
		}

		if (Object.keys(validatedData).length === 0) {
			this.errorMessage = 'No fields have been updated';
			return;
		}

		if (!id) {
			this.errorMessage = 'No ID found for existing Physical Feed Transaction';
			return;
		}

		this.updatePhysicalFeedTransaction.mutate({
			id,
			data: validatedData,
		});
	}

	@action
	submitAddEditPhysicalFeedTransactionForm() {
		if (this.addEditPhysicalFeedTransactionForm) {
			this.addEditPhysicalFeedTransactionForm.dispatchEvent(new Event('submit'));
		} else {
			console.warn('Add/Edit Physical Feed Transaction Form not found');
		}
	}

	openSidePanelFn = async () => {
		const changeSidePanelContent = await this.showDirtyFormConfirmationIfNeeded();
		if (changeSidePanelContent) {
			if (this.isSidePanelOpen) {
				this.resetForm();
				return;
			}
			if (!this.isSidePanelOpen) this.isSidePanelOpen = true;
		}
	};

	@action
	async closeSidePanel() {
		const closeSidePanel = await this.showDirtyFormConfirmationIfNeeded();
		if (closeSidePanel) {
			this.isSidePanelOpen = false;
			this.resetForm();
		}
	}

	@action
	resetForm() {
		this.errorMessage = null;
		this.physicalFeedTransactionFormData = createTrackedEmptyPhysicalFeedTransactionData();
		this.originalEditValues = null;
	}

	@action
	async showDirtyFormConfirmationIfNeeded() {
		if (!this.isFormDirty) {
			return true;
		} else {
			this.showConfirmation = true;
			//Adds a 200ms delay to allow the rendering of the confirmation modal
			await new Promise((resolve) => setTimeout(resolve, 200));

			const buttonClickResult = await waitForUserButtonClickChoice(this.confirmationCancelButtonId, this.confirmationSubmitButtonId);
			this.showConfirmation = false;

			switch (buttonClickResult) {
				case 'confirm':
					return true;
				case 'cancel':
					return false;
				default:
					console.warn(buttonClickResult);
					return false;
			}
		}
	}

	@action
	async showConfirmationIfNeededAndTransitionFn() {
		await this.closeSidePanel();
		if (!this.isSidePanelOpen && this.transition) {
			this.transition.retry();
		}

		this.transition = null;
	}

	@action
	validateAddFormData(data: AddEditPhysicalFeedTransactionFormData): PhysicalFeedTransactionCreateDTO | false {
		if (!data.contractNumber || !data.feedIngredient || !data.tons || !data.deliveryStartDate || !data.deliveryEndDate || !data.flatPrice)
			return false;

		return {
			contractIdentifier: data.contractNumber,
			feedIngredientId: data.feedIngredient.id,
			tons: parseFloat(data.tons),
			deliveryStartDate: data.deliveryStartDate,
			deliveryEndDate: data.deliveryEndDate,
			flatPrice: parseFloat(data.flatPrice),
			...(data.seller && { seller: data.seller }),
			...(data.location && { location: data.location }),
		};
	}

	@action
	validateEditFormData(data: AddEditPhysicalFeedTransactionFormData): PhysicalFeedTransactionUpdateDTO | false {
		if (!data.contractNumber || !data.tons || !data.deliveryStartDate || !data.deliveryEndDate || !data.flatPrice) return false;

		const valueUpdated = (key: keyof AddEditPhysicalFeedTransactionFormData) => {
			return this.originalEditValues?.[key] !== data[key];
		};

		// Only send fields that have been updated
		return {
			...(valueUpdated('contractNumber') && { contractIdentifier: data.contractNumber }),
			...(valueUpdated('tons') && { tons: parseFloat(data.tons) }),
			...(valueUpdated('deliveryStartDate') && { deliveryStartDate: data.deliveryStartDate }),
			...(valueUpdated('deliveryEndDate') && { deliveryEndDate: data.deliveryEndDate }),
			...(valueUpdated('flatPrice') && { flatPrice: parseFloat(data.flatPrice) }),
			...(valueUpdated('seller') && { seller: data.seller }),
			...(valueUpdated('location') && { location: data.location }),
		};
	}
}
