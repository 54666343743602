import Controller from '@ember/controller';
import FutureTransaction from 'vault-client/models/future-transaction';
import OptionTransaction from 'vault-client/models/option-transaction';
import SwapTransaction from 'vault-client/models/swap-transaction';
import SwaptionTransaction from 'vault-client/models/swaption-transaction';
import { getOwner } from '@ember/application';
import FuturePosition from 'vault-client/models/future-position';
import OptionPosition from 'vault-client/models/option-position';
import SwapPosition from 'vault-client/models/swap-position';
import SwaptionPosition from 'vault-client/models/swaption-position';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessAccount from 'vault-client/routes/businesses/business/account';
import OrganizationsOrganizationAccount from 'vault-client/routes/organizations/organization/account';
import { gql, useQuery } from 'glimmer-apollo';
import { BrokerageAccount, Query, Query_AccountArgs } from 'vault-client/types/graphql-types';

const GET_PARENT_ACCOUNT = gql`
	query Account($id: String!) {
		Account(id: $id) {
			id
			name
		}
	}
`;

export default class AccountsShowController extends Controller {
	declare model: ModelFrom<BusinessesBusinessAccount> | ModelFrom<OrganizationsOrganizationAccount>;

	getParentAccount = useQuery<{ Account: Query['Account'] }, Query_AccountArgs>(this, () => [
		GET_PARENT_ACCOUNT,
		{
			variables: { id: this.parentAccountId ?? '' },
			onError: (error) => {
				console.error('Error fetching parent account', error.message);
			},
		},
	]);

	transactionItemsFn = (rows: any) => {
		const owner = getOwner(this);

		return rows.map((row: any) => {
			if (row.Instrument?.__typename === 'Future') {
				return new FutureTransaction(owner, row, null, null);
			}

			if (row.Instrument?.__typename === 'Option') {
				return new OptionTransaction(owner, row, null, null);
			}

			if (row.Instrument?.__typename === 'Swap') {
				return new SwapTransaction(owner, row, null, null);
			}

			if (row.Instrument?.__typename === 'Swaption') {
				return new SwaptionTransaction(owner, row, null, null);
			}
			return null;
		});
	};

	positionItemsFn = (rows: any) => {
		const owner = getOwner(this);
		return rows.map((row: any) => {
			if (row.Instrument?.__typename === 'Future') {
				return new FuturePosition(owner, row);
			}

			if (row.Instrument?.__typename === 'Option') {
				return new OptionPosition(owner, row);
			}

			if (row.Instrument?.__typename === 'Swap') {
				return new SwapPosition(owner, row);
			}

			if (row.Instrument?.__typename === 'Swaption') {
				return new SwaptionPosition(owner, row);
			}
			return;
		});
	};

	accountsRoute = '';
	positionsRoute = '';
	transactionsRoute = '';
	transactionRoute = '';

	get transactionColumns() {
		const baseColumns = [
			{
				id: '2f3a0d01-7b8d-4180-ae97-f26ae52e94a8',
				name: 'Trade Day',
				valuePath: 'tradeDate',
				width: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: this.transactionRoute,
				linkModelPath: 'id',
			},
			{
				id: '6701f303-64bd-4682-a733-f3d0152ba401',
				name: 'Commodity',
				valuePath: 'Instrument.Product.name',
				width: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '48766a11-c354-453b-b3cc-e0d64342ad6e',
				name: 'Exp Month',
				valuePath: 'Instrument.displayExpiresAt',
				width: 120,
				cellComponent: 'MonthFormat',
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'de1eb4f0-20a4-433e-9082-3c793231478f',
				name: 'B / S',
				valuePath: 'side',
				width: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b43ed27e-b165-42f3-bb53-ec544e57adbd',
				name: 'Contracts',
				valuePath: 'quantityInContracts',
				width: 110,
				textAlign: 'right',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '58699e03-a269-4a37-9379-3cdd1a5b3430',
				name: 'Type',
				valuePath: 'Instrument.instrumentType',
				width: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2a7957a6-5e3d-430a-bb6f-8da8aebfedae',
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				width: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6e3212cf-642d-449e-b63c-2a219ec45609',
				name: 'Strike',
				valuePath: 'Instrument.strike',
				width: 80,
				cellComponent: 'PriceFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b05dd33f-eda5-4f4f-a461-77e7e7ef45f7',
				name: 'Trade Price',
				valuePath: 'price',
				width: 130,
				cellComponent: 'PriceFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '39342fe0-c014-4afc-bd82-6d9244fc88a0',
				name: 'Commission',
				valuePath: 'commission',
				width: 120,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1f66f67c-d756-4231-b5ba-3517201da3bd',
				name: 'Fees',
				valuePath: 'nonCommissionFees',
				width: 100,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c274d020-48ae-4d4e-bd93-22ca404fb198',
				name: 'Gross P/L',
				valuePath: 'grossPl',
				width: 120,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '253ab129-0681-4481-8c34-b354714fa940',
				name: 'Net P/L',
				valuePath: 'netPl',
				width: 120,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];

		return baseColumns;
	}

	get positionColumns() {
		const baseColumns = [
			{
				id: '92db1142-063c-496c-808b-d0307c929483',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 100,
				cellComponent: 'MonthFormat',
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '97619c5e-2bf4-4fc5-a8d4-77e07381b4b8',
				name: 'Quantity',
				valuePath: 'quantityInContracts',
				minWidth: 100,
				textAlign: 'right',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: this.transactionsRoute,
				linkQuery: {
					accountId: {
						staticValue: this.model.data?.Account?.id,
					},
					instrumentId: {
						rowKey: 'Instrument.id',
					},
				},
			},
			{
				id: '3924e79f-86ec-40fb-9a1d-f4142d346493',
				name: 'Type',
				valuePath: 'instrumentType',
				minWidth: 70,
				textAlign: 'left',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1d4fe441-8769-4f81-bbb1-5dde7ae1fd49',
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8c34e4c0-f931-4ce0-a319-47a79e104935',
				name: 'Commodity',
				valuePath: 'Instrument.Product.name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'f083cccd-9e01-4322-b8d3-26e4f882ce86',
				name: 'Avg. Trade Price',
				valuePath: 'currentWeightedAveragePrice',
				minWidth: 120,
				cellComponent: 'PriceFormat',
				componentArgs: {
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
				},
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '463fdb30-5781-4e61-870e-788a50852a6c',
				name: 'Strike',
				valuePath: 'Instrument.strike',
				minWidth: 100,
				cellComponent: 'PriceFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '70a5e84e-7810-4504-adfc-401c3475bfb1',
				name: 'Last Price',
				valuePath: 'marketDataInstrument',
				minWidth: 120,
				cellComponent: 'MarketPriceFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd6d0e4f0-4c36-4f66-b4b4-2942e1b29a93',
				name: 'Session Change',
				valuePath: 'marketDataInstrument',
				minWidth: 150,
				cellComponent: 'SessionChangeFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'bb34a490-1ad3-43fb-86e6-26515adee7da',
				name: 'Unrealized P/L (EOD)',
				valuePath: 'unrealizedPl',
				minWidth: 180,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3c213d65-f7d4-4182-88ff-e7c0a801b7e4',
				name: 'Realized P/L (EOD)',
				valuePath: 'realizedPl',
				minWidth: 160,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b27881f5-3634-4c19-ab45-6befe49a0045',
				name: 'Net P/L (EOD)',
				valuePath: 'netPl',
				minWidth: 120,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get transactions() {
		return this.transactionItemsFn(this.model.data?.Transactions);
	}

	get positionData() {
		return this.positionItemsFn(this.model.data?.CurrentPositions);
	}

	get childAccountIds() {
		return this.model.data?.ChildAccounts.map((account) => account.id);
	}

	get accountType() {
		return this.model.data?.Account.type ?? null;
	}

	get parentAccountId() {
		return (this.model.data?.Account as BrokerageAccount)?.parentAccountId ?? null;
	}

	get parentAccount() {
		if (this.parentAccountId == null || this.parentAccountId === '') {
			return null;
		}

		return this.getParentAccount.data?.Account ?? null;
	}
}
