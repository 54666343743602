import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='ml-6 mb-3'>\n\t<div class='mb-4' id={{@legendId}}></div>\n</div>\n<div class='relative h-56'>\n\t<Chart\n\t\t@type='line'\n\t\tclass='h-56 ml-2'\n\t\t@id={{@chartId}}\n\t\t@legendId={{@legendId}}\n\t\t@data={{@rawData}}\n\t\t@chartData={{@chartData}}\n\t\t@options={{this.chartOptions}}\n\t\t@plugins={{this.chartPlugins}}\n\t\t@updateChart={{this.updateChart}}\n\t/>\n</div>", {"contents":"<div class='ml-6 mb-3'>\n\t<div class='mb-4' id={{@legendId}}></div>\n</div>\n<div class='relative h-56'>\n\t<Chart\n\t\t@type='line'\n\t\tclass='h-56 ml-2'\n\t\t@id={{@chartId}}\n\t\t@legendId={{@legendId}}\n\t\t@data={{@rawData}}\n\t\t@chartData={{@chartData}}\n\t\t@options={{this.chartOptions}}\n\t\t@plugins={{this.chartPlugins}}\n\t\t@updateChart={{this.updateChart}}\n\t/>\n</div>","moduleName":"vault-client/components/hedge-analysis-chart.hbs","parseOptions":{"srcName":"vault-client/components/hedge-analysis-chart.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { ChartOptions, ChartData, Chart } from 'chart.js';
import { getCustomTooltip, CustomTooltipOptions, getCustomLegend } from 'vault-client/utils/chart-utils';
import arraysEqual from 'vault-client/utils/arrays-equal';

interface HedgeAnalysisChartArgs {
	chartId: string;
	legendId: string;
	data: ChartData<'line'>;
	fractionDigits: number;
	chartData: ChartData<'line'>;
}
export default class HedgeAnalysisChartComponent extends Component<HedgeAnalysisChartArgs> {
	get chartOptions(): ChartOptions<'line'> {
		const tooltipOptions: CustomTooltipOptions = {
			titleFormatter: (val: string) => {
				return (+val).toFixed(this.args.fractionDigits ?? 0);
			},
			valueFormatter: (val: { x: number; y: number }) => {
				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
				}).format(val.y);
			},
		};
		return {
			spanGaps: true,
			maintainAspectRatio: false,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					top: 12,
					left: 12,
					right: 12,
				},
			},
			scales: {
				y: {
					grid: {
						display: true,
					},
					ticks: {
						autoSkip: true,
						font: {
							size: 12,
						},
						callback: (val) => {
							if (typeof val === 'string') {
								return val;
							} else {
								return new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'USD',
									notation: 'compact',
								}).format(val);
							}
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('brand-gray-60'),
					},
				},
				x: {
					type: 'linear',
					grid: {
						display: false,
					},
					ticks: {
						callback: (val: number) => {
							return val.toFixed(this.args.fractionDigits ?? 0);
						},
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
			},
		};
	}

	get chartPlugins() {
		return [
			{
				afterUpdate: getCustomLegend(this.args.chartId, this.args.legendId),
			},
		];
	}

	@action
	updateChart(chart: Chart<'line'>) {
		const newDatasetLabels = this.args.chartData.datasets.map((set) => set.label).sort();
		const currentDatasetLabels = chart.data.datasets.map((set) => set.label).sort();

		if (arraysEqual(newDatasetLabels, currentDatasetLabels)) {
			chart.data.datasets.forEach((dataset) => {
				dataset.data = this.args.chartData.datasets.find((set) => set.label === dataset.label)?.data ?? [];
			});
		} else {
			chart.data = this.args.chartData;
		}

		chart.options = this.chartOptions;
		chart.update('none');
	}
}
